import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAccessLevelsState, IAccount, INewUser, IRole, IUpdateRolesParams } from "Modules/Core/SettingsTs/SettingsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";

const initialAccessLevelsState: IAccessLevelsState = {
    getAccountListApiStatus: 'idle',
    getAccountListApiResponse: '',
    accountList: [],
    getRolesListApiStatus: 'idle',
    getRolesListApiResponse: '',
    rolesList: [],
    addUserApiStatus: 'idle',
    addUserApiResponse: '',
    updateRolesApiStatus: 'idle',
    updateRolesApiResponse: '',
    deleteUserApiResponse: '',
    deleteUserApiStatus: 'idle'
};

export const getAccountList = createAsyncThunk<IBaseResponse<IAccount[]>, void, { rejectValue: IBaseResponse }>(
    "getAccountList",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_ACCOUNTS}`)
            .then((response: AxiosResponse<IBaseResponse<IAccount[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const addUser = createAsyncThunk<IBaseResponse, INewUser, { rejectValue: IBaseResponse }>(
    "addUser",
    async (user, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.ADD_USER}`, user)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getRoles = createAsyncThunk<IBaseResponse<IRole[]>, void, { rejectValue: IBaseResponse }>(
    "getRoles",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.ROLES}`)
            .then((response: AxiosResponse<IBaseResponse<IRole[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateRoles = createAsyncThunk<IBaseResponse, IUpdateRolesParams, { rejectValue: IBaseResponse }>(
    "updateRoles",
    async ({ employerId, updatedRoles }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.ROLES}/${employerId}`, updatedRoles)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const deleteUser = createAsyncThunk<IBaseResponse, number, { rejectValue: IBaseResponse }>(
    "deleteUser",
    async (userId, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${EmployerApiEndpoints.EMPLOYER_ACCOUNTS}/${userId}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const accessLevelsSlice = createSlice({
    name: "accessLevels",
    initialState: initialAccessLevelsState,
    reducers: {
        resetAddUser: (state) => { state.addUserApiStatus = 'idle'; state.addUserApiResponse = '' },
        resetDeleteUser: (state) => { state.deleteUserApiStatus = 'idle'; state.deleteUserApiResponse = '' },
        resetUpdateUserRoles: (state) => { state.updateRolesApiStatus = 'idle'; state.updateRolesApiResponse = '' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialAccessLevelsState;
        });
        //handle account list
        builder.addCase(getAccountList.pending, (state) => {
            state.getAccountListApiStatus = 'pending';
        });
        builder.addCase(getAccountList.fulfilled, (state, action) => {
            state.getAccountListApiStatus = 'success';
            state.getAccountListApiResponse = action?.payload?.message;
            state.accountList = action?.payload?.data;

            // Add fullName to each account for displaying to user.
            state.accountList.forEach(acc => {
                acc.fullName = acc.account?.first_name + ' ' +
                    (acc.account?.middle_name ? acc.account?.middle_name + ' ' : ' ') +
                    acc.account?.last_name ?? ''
            });
        });
        builder.addCase(getAccountList.rejected, (state, action) => {
            state.getAccountListApiStatus = 'failed';
            state.getAccountListApiResponse = action?.payload?.message;
            state.accountList = [];
        });
        //handle add user
        builder.addCase(addUser.pending, (state) => {
            state.addUserApiStatus = 'pending';
        });
        builder.addCase(addUser.fulfilled, (state, action) => {
            state.addUserApiStatus = 'success';
            state.addUserApiResponse = action?.payload?.message;
        });
        builder.addCase(addUser.rejected, (state, action) => {
            state.addUserApiStatus = 'failed';
            state.addUserApiResponse = action?.payload?.message;
        });
        //handle remove user
        builder.addCase(deleteUser.pending, (state) => {
            state.deleteUserApiStatus = 'pending';
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.deleteUserApiStatus = 'success';
            state.deleteUserApiResponse = action?.payload?.message;
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.deleteUserApiStatus = 'failed';
            state.deleteUserApiResponse = action?.payload?.message;
        });
        //handle get roles
        builder.addCase(getRoles.pending, (state) => {
            state.getRolesListApiStatus = 'pending';
        });
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.getRolesListApiStatus = 'success';
            state.getRolesListApiResponse = action?.payload?.message;
            state.rolesList = action?.payload?.data;
        });
        builder.addCase(getRoles.rejected, (state, action) => {
            state.getRolesListApiStatus = 'failed';
            state.getRolesListApiResponse = action?.payload?.message;
        });
        //handle role update
        builder.addCase(updateRoles.pending, (state) => {
            state.updateRolesApiStatus = 'pending';
        });
        builder.addCase(updateRoles.fulfilled, (state, action) => {
            state.updateRolesApiStatus = 'success';
            state.updateRolesApiResponse = action?.payload?.message;
        });
        builder.addCase(updateRoles.rejected, (state, action) => {
            state.updateRolesApiStatus = 'failed';
            state.updateRolesApiResponse = action?.payload?.message;
        });
    },
});

export const { resetAddUser, resetUpdateUserRoles, resetDeleteUser } = accessLevelsSlice.actions;
export default accessLevelsSlice;
