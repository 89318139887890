import React, { useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  CircularProgress,
  Typography,
  Chip,
  Rating,
  CardContent,
} from '@mui/material';
import { CompleteInterviewDialogStyled } from 'Modules/Core/Interviews/Interviews.styled';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getInterviewDetails } from 'store/slices/employer/interviews/interviews-details-slice';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FeedbackIcon from '@mui/icons-material/Feedback';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IInterviewDetails } from './InterviewsModel';
import PeopleIcon from '@mui/icons-material/People';
import { getEmployerDetails } from 'store/slices/employer/employer/employer-details-slice';
import { isEmpty } from 'lodash';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';

const ratingLookup: { [index: number]: string } = {
  1: 'Very Poor',
  2: 'Poor',
  3: 'Average',
  4: 'Good',
  5: 'Excellent',
};

const interviewTypeIcons: {
  [key in IInterviewDetails['type_of_interview']]: React.ReactNode;
} = {
  Video: <VideoCameraFrontIcon color="primary" />,
  'In-person': <LocationOnIcon color="primary" />,
  Phone: <PhoneIcon color="primary" />,
};

interface ViewInterviewDetailsPopupProps {
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
  interviewId: number | undefined;
  applicantName: string;
  employerJobName: string;
}

const ViewInterviewDetailsPopup: React.FC<ViewInterviewDetailsPopupProps> = ({
  isDialogOpen,
  setIsDialogOpen,
  interviewId,
  applicantName,
  employerJobName,
}) => {
  const dispatch = useAppDispatch();
  const isSmScreen = IsSmScreen();
  const { interviewDetails, getInterviewDetailsStatus } = useAppSelector(
    (state) => state.employer.interviews.interviewsDetails
  );
  const { employerDetails } = useAppSelector(
    (state) => state.employer.employerDetails
  );

  useEffect(() => {
    if (interviewId) {
      dispatch(getInterviewDetails({ id: interviewId }));
    }
  }, [dispatch, interviewId]);

  useEffect(() => {
    if (interviewDetails?.employer_id && isEmpty(employerDetails)) {
      dispatch(getEmployerDetails({ empId: interviewDetails?.employer_id }));
    }
  }, [dispatch, interviewDetails?.employer_id, employerDetails]);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const formatTo12Hour = (time: string) => {
    const [hours, minutes] = time.split(':').map((num) => +num);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12;
    return `${String(adjustedHours).padStart(2, '0')}:${minutes} ${period}`;
  };

  const getTime = () => {
    if (interviewDetails) {
      const formattedFromTime = formatTo12Hour(interviewDetails.from_time);
      const formattedToTime = formatTo12Hour(interviewDetails.to_time);
      return interviewDetails.timezone
        ? `${formattedFromTime} - ${formattedToTime} ${interviewDetails.timezone}`
        : `${formattedFromTime} - ${formattedToTime}`;
    }
  };

  return (
    <CompleteInterviewDialogStyled
      open={isDialogOpen}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="interview-details-title"
      fullScreen={isSmScreen}>
        <DialogTitle
          id="interview-details-title"
          textAlign="center"
          fontWeight={600}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            columnGap={1}
          >
            <Box>{`Interview Details for ${applicantName}`}</Box>
            <IconButton onClick={closeDialog}>
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent dividers>
          {getInterviewDetailsStatus === 'pending' ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <CircularProgress />
            </Box>
          ) : interviewDetails ? (
            <Stack spacing={1}>
              <ShPaper variant="outlined">
                <CardContent>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <WorkOutlineIcon color="primary" />
                      </Stack>
                      <Stack direction="column">
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Job Title
                        </Typography>
                        <Typography variant="body2" color={'GrayText'}>
                          {employerJobName}
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction="row" spacing={1}>
                      <Stack direction="row" alignItems="center">
                        <PersonOutlineIcon color="primary" />
                      </Stack>
                      <Stack direction="column">
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Candidate Name
                        </Typography>
                        <Typography variant="body2" color={'GrayText'}>
                          {applicantName}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </CardContent>
              </ShPaper>

              <ShPaper variant="outlined">
                <CardContent>
                  <Stack spacing={1}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <EventIcon color="primary" fontSize="medium" />
                      <Box>
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Scheduled Date
                        </Typography>
                        <Typography variant="body2" color={'GrayText'}>
                          {interviewDetails.schedule_date}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AccessTimeIcon color="primary" fontSize="medium" />
                      <Box>
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Time
                        </Typography>
                        <Typography variant="body2" color={'GrayText'}>
                          {getTime()}
                        </Typography>
                      </Box>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <EventIcon color="primary" fontSize="medium" />
                      <Box>
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Completed At
                        </Typography>
                        <Typography variant="body2" color={'GrayText'}>
                          {new Date(interviewDetails.updated_at)
                            .toUTCString()
                            .replace(' GMT', '')}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </CardContent>
              </ShPaper>

              <ShPaper variant="outlined">
                <CardContent>
                  <Box marginBottom={1.5}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="body1" color="black" fontWeight={600}>
                        Status
                      </Typography>
                      <Chip
                        icon={<CheckCircleIcon color="success" />}
                        label="Completed"
                        size='small'
                        color="success"
                        variant="outlined"
                      />
                    </Stack>
                  </Box>

                  <Box>
                    <Typography variant="body2" color="black" fontWeight={600}>
                      Interview Rating
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Rating 
                        value={
                          Number(
                            Object.keys(ratingLookup).find(
                              (key) =>
                                ratingLookup[Number(key)] ===
                                interviewDetails.interview_rating
                            )
                          ) || 0
                        }
                        size="medium"
                        readOnly
                      />
                      {interviewDetails.interview_rating !== null && (
                        <Typography variant="body2" color={'GrayText'}>
                          {interviewDetails.interview_rating}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </CardContent>
              </ShPaper>

              <ShPaper variant="outlined">
                <CardContent>
                  <Stack spacing={2}>
                    <Box>
                      <Stack direction="row" spacing={1}>
                        <Stack alignItems="center" direction="row">
                          <FeedbackIcon color="primary" />
                        </Stack>
                        <Typography variant="body1" color="black" fontWeight={600}>
                          Feedback
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Stack direction="row" alignItems="center">
                          <PersonIcon color="primary" />
                        </Stack>
                        <Stack direction="column">
                          <Typography variant="body2" color={'GrayText'}>
                            {`${employerDetails?.first_name} ${employerDetails?.last_name}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            fontWeight={200}
                            fontSize={10}
                          >
                            Feedback given by
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack>
                        {interviewDetails.feedback ? (
                          interviewDetails.feedback.split('\n').map((line, index) => (
                            <Typography variant="body2" color={'GrayText'} key={index}>
                              {line}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body2" color={'GrayText'}>
                            No feedback provided
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </ShPaper>

              <ShPaper variant="outlined">
                <CardContent>
                  <Stack direction="column">
                    <Stack direction="row" spacing={1}>
                      <Stack alignItems="center" direction="row">
                        <ContentPasteSearchIcon color="primary" />
                      </Stack>
                      <Typography variant="body1" color="black" fontWeight={600}>
                        Interview Type
                      </Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {interviewTypeIcons[interviewDetails.type_of_interview]}
                      <Typography variant="body2" color={'GrayText'}>
                        {interviewDetails.type_of_interview}
                      </Typography>
                    </Stack>
                  </Stack>
                  {interviewDetails.type_of_interview === 'Video' && (
                    <Typography variant="body2" color="textSecondary" marginTop={1}>
                      {interviewDetails.zoom_meeting ? (
                        <>Source: Zoom</>
                      ) : (
                        <>Source: Other Video Link</>
                      )}
                    </Typography>
                  )}
                  {interviewDetails.type_of_interview === 'Phone' && (
                    <Typography variant="body2" color="textSecondary" marginTop={1}>
                      {interviewDetails.additional_interview_info ||
                        'No phone number provided'}
                    </Typography>
                  )}
                  {interviewDetails.type_of_interview === 'In-person' && (
                    <Typography variant="body2" color="textSecondary" marginTop={1}>
                      Address:{' '}
                      {interviewDetails.additional_interview_info
                        ? interviewDetails.additional_interview_info
                        : 'No address provided'}
                    </Typography>
                  )}
                </CardContent>
              </ShPaper>

              <ShPaper>
                <CardContent>
                  <Stack direction="row" spacing={1}>
                    <Stack alignItems="center" direction="row">
                      <PeopleIcon color="primary" />
                    </Stack>
                    <Typography variant="body1" color="black" fontWeight={600}>
                      Attendees
                    </Typography>
                  </Stack>

                  <Stack marginTop={1} direction="row" columnGap={1} flexWrap="wrap" rowGap={1}>
                    {interviewDetails.additional_attendees_email &&
                    interviewDetails.additional_attendees_email.length > 0 ? (
                      interviewDetails.additional_attendees_email.map((email, index) => (
                        <Chip
                          key={index}
                          label={
                            <Typography color="textSecondary" fontSize={14}>
                              {email}
                            </Typography>
                          }
                          size="small"
                          icon={<PersonIcon color="primary" fontSize="small" />}
                        />
                      ))
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        No attendees available
                      </Typography>
                    )}
                  </Stack>
                </CardContent>
              </ShPaper>
            </Stack>
          ) : (
            <Typography>No interview details available.</Typography>
          )}
        </DialogContent>

        <DialogActions>
          <ShGreenBtn
            variant="contained"
            disableElevation
            size="small"
            onClick={closeDialog}
            className="self-right"
          >
            Close
          </ShGreenBtn>
        </DialogActions>
    </CompleteInterviewDialogStyled>

  );
};

export default ViewInterviewDetailsPopup;
