import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IApplyJobQuestionnaireState, IJobApplicationRequirement } from "Modules/Core/ApplyJob/ApplyJobModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";

const initialApplyJobQuestionnaireState: IApplyJobQuestionnaireState = {};

export const getJobRequirements = createAsyncThunk<IBaseResponse<IJobApplicationRequirement[]>,
    { jobId: number }, { rejectValue: IBaseResponse }>(
        "getJobRequirements",
        async ({ jobId }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobId}/requirements`)
                .then((response: AxiosResponse<IBaseResponse<IJobApplicationRequirement[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const updateJobRequirementAnswer = createAsyncThunk<IBaseResponse<IJobApplicationRequirement>, {
    jobId: number, payload: { requirementId: number, requirementLevelId: number }
}, { rejectValue: IBaseResponse }>(
    "updateJobRequirementAnswer",
    async ({ jobId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOB_REQUIREMENTS}/${jobId}/requirements`, payload)
            .then((response: AxiosResponse<IBaseResponse<IJobApplicationRequirement>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const completeAtsJobApplication = createAsyncThunk<{ message: string }, number, { rejectValue: IBaseResponse }>(
    "completeAtsJobApplication",
    async (jobApplicationId: number, { rejectWithValue }) => {
        return httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOB_APPLY_COMPLETE}/${jobApplicationId}/ats_complete`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                throw rejectWithValue(error.response.data)
            })
    }
);

const applyJobQuestionnaireSlice = createSlice({
    name: "applyJobQuestionnaire",
    initialState: initialApplyJobQuestionnaireState,
    reducers: {
        resetGetJobRequirements: (state) => {
            state.getJobApplicationRequirementsStatus = 'idle';
            state.getJobApplicationRequirementsResponse = ''
        },
        resetCompleteAtsJobApplication: (state) => {
            state.completeAtsJobApplicationStatus = 'idle';
            state.completeAtsJobApplicationResponse = ''
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplyJobQuestionnaireState;
        });
        // get job requirements
        builder.addCase(getJobRequirements.pending, (state) => {
            state.getJobApplicationRequirementsStatus = 'pending';
        });
        builder.addCase(getJobRequirements.fulfilled, (state, action) => {
            state.getJobApplicationRequirementsStatus = 'success';
            state.jobApplicationRequirements = action.payload?.data;
            // If no "applicant_requirement_level_id" is null then Questionnaire is complete.
            state.isQuestionnaireComplete = action.payload?.data
                ?.filter(req => req.applicant_requirement_level_id === null)?.length === 0;
        });
        builder.addCase(getJobRequirements.rejected, (state, action) => {
            state.getJobApplicationRequirementsStatus = 'failed';
            state.getJobApplicationRequirementsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update job requirements
        builder.addCase(updateJobRequirementAnswer.pending, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'pending';
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerId = action.meta.arg.payload.requirementLevelId;
            }
        });
        builder.addCase(updateJobRequirementAnswer.fulfilled, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'success';
            }
            // Update candidate answer in "jobApplicationRequirements"
            state.jobApplicationRequirements?.forEach(jobReq => {
                if (jobReq.id === action.payload.data.requirement_id) {
                    jobReq.applicant_requirement_level_id = action.payload.data.applicant_requirement_level_id;
                }
            });
            // Update 'isQuestionnaireComplete' as candidate answers.
            // If no "applicant_requirement_level_id" is null then Questionnaire is complete.
            state.isQuestionnaireComplete = state.jobApplicationRequirements
                ?.filter(req => req.applicant_requirement_level_id === null)?.length === 0;
        });
        builder.addCase(updateJobRequirementAnswer.rejected, (state, action) => {
            // Update api status on requirement level
            const updateIndex = state.jobApplicationRequirements
                ?.findIndex(jobReq => jobReq.id === action.meta.arg.payload.requirementId);
            if (updateIndex !== undefined && updateIndex >= 0 && state.jobApplicationRequirements) {
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerStatus = 'failed';
                state.jobApplicationRequirements[updateIndex].updateJobRequirementAnswerResponse =
                    action?.payload?.message ?? DefaultAPIErrorMsg;
            }
        });
        // complete ats job application
        builder.addCase(completeAtsJobApplication.pending, (state) => {
            state.completeAtsJobApplicationStatus = 'pending';
        });
        builder.addCase(completeAtsJobApplication.fulfilled, (state, action) => {
            state.completeAtsJobApplicationStatus = 'success';
            state.completeAtsJobApplicationResponse = action?.payload?.message;
        });
        builder.addCase(completeAtsJobApplication.rejected, (state, action) => {
            state.completeAtsJobApplicationStatus = 'failed';
            state.completeAtsJobApplicationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetJobRequirements, resetCompleteAtsJobApplication } = applyJobQuestionnaireSlice.actions;
export default applyJobQuestionnaireSlice;
