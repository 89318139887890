import { Alert, Snackbar } from "@mui/material";
import { useNotification } from "Modules/Core/Notification/useNotification";
import { useAppSelector } from "helpers/hooks";

export const Notification = () => {
    const notification = useAppSelector((state) => state.app.notification);
    const { clearNotification } = useNotification();

    return (
        <Snackbar open={notification?.open} autoHideDuration={notification?.timeout ?? 5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={e => { clearNotification(); e?.stopPropagation() }}>
            <Alert variant="filled" onClose={e => { clearNotification(); e?.stopPropagation() }} severity={notification.type}>
                {notification?.message}
            </Alert>
        </Snackbar>
    );
};