import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICandidateInfoInResume, ICandidateResume, IResumeSliceState } from "Modules/Core/ApplyJob/ApplyJobModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";

const initialResumeSliceState: IResumeSliceState = {};

export const getCandidateInfoFromResume = createAsyncThunk<IBaseResponse<ICandidateInfoInResume>, { payload: FormData }, { rejectValue: IBaseResponse }>(
    "getCandidateInfoFromResume",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INFO_BY_RESUME}`, payload)
            .then((response: AxiosResponse<IBaseResponse<ICandidateInfoInResume>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const uploadApplicantResume = createAsyncThunk<string, { applicationId: number, resume: FormData, isResumeUpdate: boolean },
    { rejectValue: string }>(
        "uploadApplicantResume",
        async ({ resume, applicationId }, { rejectWithValue }) => {
            return httpAdapterInstance
                .post(`${EmployerApiEndpoints.CANDIDATES}/${applicationId}/resume`, resume, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then((response) => response.data.message)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const getCandidateResumes = createAsyncThunk<IBaseResponse<ICandidateResume[]>, { candidateId: number },
    { rejectValue: string }>(
        "getCandidateResumes",
        async ({ candidateId }, { rejectWithValue }) => {
            return httpAdapterInstance
                .get(`${EmployerApiEndpoints.CANDIDATES}/${candidateId}/resumes`)
                .then((response) => response.data.message)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const updateCandidateResume = createAsyncThunk<{ message: string }, { candidateId: number, resumeId: number },
    { rejectValue: { message: string } }>(
        "updateCandidateResume",
        async ({ candidateId, resumeId }, { rejectWithValue }) => {
            return httpAdapterInstance
                .put(`${EmployerApiEndpoints.CANDIDATES}/${candidateId}/resume?resumeId=${resumeId}`)
                .then((response) => response.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const resumeSlice = createSlice({
    name: "resume",
    initialState: initialResumeSliceState,
    reducers: {
        resetGetCandidateInfoFromResume: (state) => {
            state.getCandidateInfoFromResumeStatus = 'idle';
            state.getCandidateInfoFromResumeResponse = '';
            state.candidateInfoInResume = undefined;
        },
        resetUploadApplicantResume: (state) => {
            state.uploadResumeStatus = 'idle';
            state.uploadResumeResponse = '';
        },
        resetGetCandidateResumes: (state) => {
            state.getResumesStatus = 'idle';
            state.getResumesResponse = '';
        },
        resetUpdateCandidateResume: (state) => {
            state.updateCandidateResumeStatus = 'idle';
            state.updateCandidateResumeResponse = '';
        },
        setIsResumeUpdatedToTrue: (state) => {
            state.isResumeUpdated = true;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialResumeSliceState;
        });
        // get Candidate Info From Resume
        builder.addCase(getCandidateInfoFromResume.pending, (state) => {
            state.getCandidateInfoFromResumeStatus = 'pending'
        });
        builder.addCase(getCandidateInfoFromResume.fulfilled, (state, action) => {
            state.getCandidateInfoFromResumeStatus = 'success';
            state.candidateInfoInResume = action.payload?.data;
        });
        builder.addCase(getCandidateInfoFromResume.rejected, (state, action) => {
            state.getCandidateInfoFromResumeStatus = 'failed';
            state.getCandidateInfoFromResumeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // uploadApplicantResume
        builder.addCase(uploadApplicantResume.pending, (state) => {
            state.uploadResumeStatus = "pending";
        })
        builder.addCase(uploadApplicantResume.fulfilled, (state, action) => {
            state.uploadResumeStatus = "success";
            state.uploadResumeResponse = action?.payload;
            state.isResumeUpdated = action.meta.arg.isResumeUpdate;
        });
        builder.addCase(uploadApplicantResume.rejected, (state, action) => {
            state.uploadResumeStatus = "failed";
            state.uploadResumeResponse = action?.payload ?? DefaultAPIErrorMsg;
        });
        // get all candidate resumes
        builder.addCase(getCandidateResumes.pending, (state) => {
            state.getResumesStatus = "pending";
        })
        builder.addCase(getCandidateResumes.fulfilled, (state, action) => {
            state.getResumesStatus = "success";
            state.candidateResumes = action.payload.data;
        });
        builder.addCase(getCandidateResumes.rejected, (state, action) => {
            state.getResumesStatus = "failed";
            state.getResumesResponse = action?.payload ?? DefaultAPIErrorMsg;
        });
        // update candidate resumes
        builder.addCase(updateCandidateResume.pending, (state) => {
            state.updateCandidateResumeStatus = "pending";
        })
        builder.addCase(updateCandidateResume.fulfilled, (state, action) => {
            state.updateCandidateResumeStatus = "success";
            state.updateCandidateResumeResponse = action?.payload.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(updateCandidateResume.rejected, (state, action) => {
            state.updateCandidateResumeStatus = "failed";
            state.updateCandidateResumeResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetCandidateInfoFromResume, resetUploadApplicantResume, resetGetCandidateResumes, resetUpdateCandidateResume, setIsResumeUpdatedToTrue } = resumeSlice.actions;
export default resumeSlice;
