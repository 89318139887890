import { TResourcesRedirectUrlKey, TToolsRedirectUrlKey } from "./SharedModels";
import { EmployerResourcesRedirectUrlMap, ResourcesRedirectUrlMap, ToolsRedirectUrlMap } from "./constants";

/**
 * Updates an array by adding or removing an element identified by its unique identifier.
 *
 * @template I - Type of the unique identifier.
 * @template A - Type of the array elements.
 * 
 * @param {I} id - The unique identifier of the element to be added or removed.
 * @param {A[]} array - The array to be updated.
 * 
 * @returns {A[]} - The updated array after adding or removing the specified element.
 */
export const updateArrayById = <I, A>(id: I, array: A[]): A[] => {
    // Create a deep copy of the array to avoid modifying the original array.
    const updatedArray = JSON.parse(JSON.stringify(array));

    // Check if the element with the given id is already present in the array.
    if (updatedArray?.includes(id)) {
        // If present, remove the element from the array.
        updatedArray.splice(updatedArray.findIndex((sI: I) => id === sI), 1);
    } else {
        // If not present, add the element to the array.
        updatedArray.push(id);
    }

    // Return the updated array.
    return updatedArray;
};

export const areAllElementsInArrayXInArrayY = (x: (number | string)[], y: (number | string)[]) => {
    // Check if all elements in array x are in array y
    return x.every(elementX => y.includes(elementX));
}

export const areSomeElementsInArrayXInArrayY = (x: (number | string)[], y: (number | string)[]) => {
    // Check if some elements in array x are in array y
    return x.some(elementX => y.includes(elementX));
}

/**
 * Converts a given string into Title case.
 * Ex: "hello world" gets converted to "Hello World"
 * Also converts strings like 'hello_world', 'HELLO_WORLD', 'hello-world' to 'Hello World'
 */
export const toTitleCase = (string: string): string => {
    string = string.trim();
    // Split the string by space, underscore, or hyphen
    const words = string?.split(/[\s_-]+/);
    if (words) {
        for (let i = 0; i < words?.length; i++) {
            words[i] = words[i][0]?.toUpperCase() + words[i]?.substring(1)?.toLowerCase();
        }
        return words?.join(" ");
    } else {
        return '';
    }
}

/**
 * Gets the initials from a given name string.
 * 
 * @param {string} name - The name string to extract initials from.
 * @param {boolean} [noSpace=false] - Whether to exclude spaces in the returned initials.
 * @param {boolean} [excludeMiddleName=false] - Whether to exclude the middle name in the returned initials.
 * @returns {string} The initials of the given name.
 * 
 * @example
 * getInitialsFromName("My Name Hello", true);
 * // returns "MNH"
 * 
 * @example
 * getInitialsFromName("My Name Hello", false, true);
 * // returns "M H"
 * 
 * @example
 * getInitialsFromName("My Name Hello", true, true);
 * // returns "MH"
 */
export const getInitialsFromName = (name: string, noSpace: boolean = false, excludeMiddleName: boolean = false): string => {
    // Split the name by spaces
    const nameParts = name?.split(' ');
    if (nameParts) {
        // If excludeMiddleName is true and there are more than 2 parts, filter out the middle parts
        let initials;
        if (excludeMiddleName && nameParts?.length > 2) {
            initials = [nameParts[0], nameParts[nameParts.length - 1]].map(part => part.charAt(0).toUpperCase());
        } else {
            initials = nameParts?.map(part => part.charAt(0).toUpperCase());
        }

        // Join the initials based on the noSpace parameter
        return initials?.join(noSpace ? '' : ' ');
    } else return '';
}

/**
 * Get resource redirect urls by key.
 * Redirect urls are retrieved from the constants "ResourcesRedirectUrlMap"*/
export const getResourcesRedirect = (redirectKey: TResourcesRedirectUrlKey): string => {
    return ResourcesRedirectUrlMap[redirectKey];
}

/**
 * FOR EMPLOYERS
 * Get resource redirect urls by key.
 * Redirect urls are retrieved from the constants "EmployerResourcesRedirectUrlMap"*/
export const getEmployerResourcesRedirect = (redirectKey: TResourcesRedirectUrlKey): string => {
    return EmployerResourcesRedirectUrlMap[redirectKey];
}

/**
 * Get Tools redirect urls by key.
 * Redirect urls are retrieved from the constants "ToolsRedirectUrlMap"*/
export const getToolsRedirect = (redirectKey: TToolsRedirectUrlKey): string => {
    return ToolsRedirectUrlMap[redirectKey];
}

/**
 * Truncates a given text to a specified maximum length, adding an ellipsis ('...') at the end if the text exceeds the maximum length.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @returns {string} The truncated text with an ellipsis if it exceeds the specified maximum length.
 *
 * @example
 * // returns 'Hello, Wo...'
 * truncateText('Hello, World!', 10);
 *
 * @example
 * // returns 'Hello'
 * truncateText('Hello', 10);
 */
export const truncateText = (text: string, maxLength: number): string => {
    if (text.length <= maxLength) {
        return text;
    }
    return text.slice(0, maxLength) + '...';
};

/**
 * Compares two objects to determine if they are equal by their JSON string representations.
 * 
 * This function converts both objects to their JSON string representations and checks if they are identical.
 * 
 * @param obj1 - The first object to compare. This parameter can be of any type.
 * @param obj2 - The second object to compare. This parameter can be of any type.
 * @returns `true` if the JSON string representations of `obj1` and `obj2` are identical, indicating that the objects are equal. Returns `false` otherwise.
 * 
 * @remarks
 * - This function uses `JSON.stringify` for comparison, which may not be efficient for very large objects or objects with circular references.
 * - The order of properties in the JSON string representation affects the comparison. This may be an issue if property order is not guaranteed.
 * - The function does not handle objects with circular references and will throw an error if such objects are passed.
 * - Non-enumerable properties and properties with `symbol` keys are not included in the comparison.
 * - Functions and `undefined` values are not included in JSON string representations and will be ignored.
 */
export const areBothObjectsIdentical = (obj1: unknown, obj2: unknown): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const getWelcomeText = (name?: string): string => {
    let welcomeText = 'Welcome';
    if (name) {
        welcomeText += ` back, ${name}`;
    }
    welcomeText += '!';
    return welcomeText;
}