import { combineReducers } from '@reduxjs/toolkit';
import jobInfoSlice from 'store/slices/employer/jobs/job-info-slice';
import jobsListActionsSlice from 'store/slices/employer/jobs/jobs-list-actions-slice';
import jobsListSlice from 'store/slices/employer/jobs/jobs-list-slice';

const employerJobsReducer = combineReducers({
    jobInfo: jobInfoSlice.reducer,
    jobsListActions: jobsListActionsSlice.reducer,
    jobsList: jobsListSlice.reducer,
});
export default employerJobsReducer;
