import React from 'react';
import { Typography, List, ListItem, ListItemText, Divider, Stack, Chip } from '@mui/material';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';

export const JobDescriptionTemplate: React.FC = () => {
  return (
    <Stack paddingBottom={3}>
    <ShContainer >
        <Chip label="Sample Description" color = 'primary' />
        <Typography variant="h6" component="h1" gutterBottom paddingTop={2}> 
          Senior Marketing Manager - Pet Industry
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />
        
        <Typography variant="h6" component="h2" gutterBottom>
          Company Overview
        </Typography>
        <Typography variant="body2" paragraph>
          [Company Name] is a leading pet industry company dedicated to providing high-quality products and services for pets and pet owners. With a strong focus on innovation and customer satisfaction, we strive to enhance the lives of pets and their owners through our range of products, including pet food, toys, accessories, and grooming services.
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Job Overview
        </Typography>
        <Typography variant="body2" paragraph>
          We are seeking a dynamic and experienced Senior Marketing Manager to join our marketing team and drive the growth and success of our pet industry business. Reporting directly to the Director of Marketing, the Senior Marketing Manager will be responsible for developing and implementing strategic marketing plans to increase brand awareness, drive customer acquisition, and foster customer loyalty.
        </Typography>
        
        <Typography variant="h6" component="h2" gutterBottom>
          Responsibilities
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Develop and execute comprehensive marketing strategies to drive brand growth, increase market share, and achieve revenue targets in the pet industry." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Conduct market research and analysis to identify customer needs, market trends, and competitive landscape, and leverage insights to develop effective marketing campaigns and product positioning." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Collaborate with cross-functional teams, including product development, sales, and operations, to ensure alignment and successful execution of marketing initiatives." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Lead the development and execution of marketing campaigns across multiple channels, including traditional, digital, social media, and influencers, to drive brand awareness and customer engagement." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Develop and maintain relationships with key stakeholders and industry partners to support marketing initiatives, collaborations, and sponsorships." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Manage the marketing budget and allocate resources effectively to maximize ROI and achieve marketing objectives." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Analyze marketing and sales data to track performance, identify areas for improvement, and make data-driven recommendations to optimize marketing strategies." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Stay up-to-date with industry trends, emerging technologies, and best practices in marketing to identify new opportunities and innovations for our pet industry business." />
          </ListItem>
        </List>

        <Typography variant="h6" component="h2" gutterBottom>
          Qualifications
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="Bachelor's degree in marketing, business administration, or a related field. MBA is a plus." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Minimum of 5 years of experience in marketing, preferably within the pet industry or consumer packaged goods industry." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Proven track record of developing and executing successful marketing strategies and campaigns that drive brand growth and achieve revenue targets." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Strong knowledge of digital marketing strategies and tools, including social media, SEO, PPC, email marketing, and content marketing." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Excellent analytical and problem-solving skills, with the ability to analyze data, draw insights, and make data-driven decisions." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Strong project management skills, with the ability to prioritize and manage multiple projects simultaneously within deadlines." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Exceptional communication and interpersonal skills, with the ability to collaborate effectively with cross-functional teams and build relationships with stakeholders." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Passion for pets and a genuine interest in the pet industry." />
          </ListItem>
        </List>

        <Typography variant="h6" component="h2" gutterBottom>
          Benefits
        </Typography>
        <Typography variant="body2" paragraph>
          We offer a competitive salary package, including benefits and opportunities for career growth in the pet industry. If you are a strategic thinker, have a passion for marketing, and love pets, we invite you to apply for the Senior Marketing Manager position at [Company Name]. Join our team and contribute to making a positive impact in the lives of pets and their owners.
        </Typography>
    </ShContainer>
    </Stack>
  );
};

export default JobDescriptionTemplate;
