import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip
} from '@mui/material';
import { IAccount, IAccountActions } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { MouseEvent, useState } from 'react';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { IConfirmationDialogLabels } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialogModel';
import { deleteUser, resetUpdateUserRoles } from 'store/slices/employer/settingsTs/access-levels-slice';

export const AccountActions = ({ account, editUser }: IAccountActions) => {

    const dispatch = useAppDispatch();
    const { rolesList } = useAppSelector((state) => state.employer.employerSettings.accessLevels);

    /**
     * Using map with Job id as key and element ref as value since the menu's ref is present in multiple locations opening one
     * menu toggles multiple menus to open. With the help of this map toggling menu for appropriate element is possible.             
    */
    const [moreActionsAnchorEl, setMoreActionsAnchorEl] = useState<{ [key: number]: HTMLElement | null }>({ 0: null });
    /*
          User delete confirmation dialog.
       */
    const [confirmDialogLabels, setConfirmDialogLabels] = useState<IConfirmationDialogLabels | undefined>(undefined);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [userIdToBeDeleted, setUserIdToBeDeleted] = useState<number | undefined>(undefined);

    const openActions = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => {
        e.stopPropagation();
        setMoreActionsAnchorEl({ ...moreActionsAnchorEl, [indx]: e.currentTarget });
    };

    const deleteUserAccount = (user: IAccount) => {
        // Set confirmation dialog's labels, appropriately with user name etc.
        setConfirmDialogLabels({
            title: 'Confirm User Deletion',
            contentText: `Please confirm that you want to delete ${user.account.first_name}'s account. \nThis action is irreversible.`
        });
        // Set userIdToBeDeleted which will be passed to delete API if user confirm's the action.
        setUserIdToBeDeleted(user.id);
        setIsConfirmDialogOpen(true);
    };

    const cancelDeleteUser = () => {
        setIsConfirmDialogOpen(false);
        setUserIdToBeDeleted(undefined);
        setConfirmDialogLabels({ ...confirmDialogLabels, contentText: '' });
    };

    return (<>
        {account.role_id !== rolesList.find(r => r.name === 'owner')?.id && <>
            <Tooltip title="More Actions" placement='left' onClick={e => e.stopPropagation()}>
                <IconButton className='more-btn' aria-label='More options' onClick={e => { openActions(e, account.id) }}>
                    <MoreVertIcon />
                </IconButton>
            </Tooltip>
            {moreActionsAnchorEl[account.id] &&
                <Menu id="more-options" aria-label='More options menu' anchorEl={moreActionsAnchorEl[account.id]}
                    open={moreActionsAnchorEl[account.id] !== null}
                    onClose={() => setMoreActionsAnchorEl({ ...moreActionsAnchorEl, [account.id]: null })}
                    onClick={e => { setMoreActionsAnchorEl({ ...moreActionsAnchorEl, [account.id]: null }); e.stopPropagation() }}>
                    <MenuItem onClick={() => editUser(account)}>
                        <ListItemIcon><EditIcon color='primary' /></ListItemIcon>
                        <ListItemText>Edit User</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => deleteUserAccount(account)}>
                        <ListItemIcon><DeleteIcon color='error' /></ListItemIcon>
                        <ListItemText>Delete User</ListItemText>
                    </MenuItem>
                </Menu>}
            {/* ConfirmationDialog will be rendered only is 'userIdToBeDeleted' is present. */}
            {userIdToBeDeleted &&
                <ConfirmationDialog contentText={confirmDialogLabels?.contentText} title={confirmDialogLabels?.title}
                    isDialogOpen={isConfirmDialogOpen} onCancel={cancelDeleteUser}
                    onConfirm={() => {
                        dispatch(resetUpdateUserRoles());
                        dispatch(deleteUser(userIdToBeDeleted));
                    }} />}
        </>}

    </>);
}