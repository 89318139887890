import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { ApiStates } from "shared/constants";

const initialCreditsState = {
    fetchingCredits: ApiStates.idle,
    availableCredits: [],
    updatingCreditsRspnse: '',
    updatingCredits: ApiStates.idle
};

export const fetchAvailableCredits = createAsyncThunk(
    "availableCredits",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/credits`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateAvailableCredits = createAsyncThunk(
    "updateAvailableCredits",
    async ({ employerId, credits }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/credits`, credits)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const creditSlice = createSlice({
    name: "credits",
    initialState: initialCreditsState,
    reducers: {
        resetCreditUpdation: (state) => { state.updatingCredits = ApiStates.idle; state.updatingCreditsRspnse = '' }
    },
    extraReducers: (builder) => {
        //available credits
        builder.addCase(fetchAvailableCredits.pending, (state, action) => {
            state.fetchingCredits = ApiStates.pending;
        });
        builder.addCase(fetchAvailableCredits.fulfilled, (state, action) => {
            state.availableCredits = action?.payload?.data;
            state.fetchingCredits = ApiStates.success;
        });
        builder.addCase(fetchAvailableCredits.rejected, (state, action) => {
            state.availableCredits = [];
            state.fetchingCredits = ApiStates.failed;
        });
        //update available credits
        builder.addCase(updateAvailableCredits.pending, (state, action) => {
            state.updatingCredits = ApiStates.pending;
        });
        builder.addCase(updateAvailableCredits.fulfilled, (state, action) => {
            state.updatingCredits = ApiStates.success;
            state.updatingCreditsRspnse = action?.payload?.message;
        });
        builder.addCase(updateAvailableCredits.rejected, (state, action) => {
            state.updatingCredits = ApiStates.failed;
        });
    },
});

export const { resetCreditUpdation } = creditSlice.actions;
export default creditSlice;
