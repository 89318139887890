import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IApplyJobHomeState } from "Modules/Core/ApplyJob/ApplyJobModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";

const initialApplyJobHomeState: IApplyJobHomeState = {};

export const applyForJob = createAsyncThunk<{ job_application_id: number, message: string },
    { jobId: string, postingSource?: string }, { rejectValue: IBaseResponse }>(
        "applyForJob",
        async ({ jobId, postingSource }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .put(`${EmployerApiEndpoints.JOB_APPLY}?jobId=${jobId}${postingSource ? ('&ps=' + postingSource) : ''}`)
                .then((response: AxiosResponse<{ job_application_id: number, message: string }>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const applyJobHomeSlice = createSlice({
    name: "applyJobHome",
    initialState: initialApplyJobHomeState,
    reducers: {
        resetApplyForJob: (state) => {
            state.jobApplicationStatus = 'idle';
            state.jobApplicationResponse = '';
        },
        setPostingSource: (state, action: PayloadAction<string>) => {
            state.postingSource = action.payload;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplyJobHomeState;
        });
        // apply for job
        builder.addCase(applyForJob.pending, (state) => {
            state.jobApplicationStatus = 'pending';
        });
        builder.addCase(applyForJob.fulfilled, (state, action) => {
            state.jobApplicationStatus = 'success';
            state.jobApplicationId = action.payload.job_application_id;
        });
        builder.addCase(applyForJob.rejected, (state, action) => {
            state.jobApplicationStatus = 'failed';
            state.jobApplicationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});
export const { resetApplyForJob, setPostingSource } = applyJobHomeSlice.actions;
export default applyJobHomeSlice;
