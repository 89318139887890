import { ClickAwayListener, Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { IsSmScreen } from "helpers/hooks";
import { useState } from "react";

interface IShTooltip {
    title: string | JSX.Element,
    children: JSX.Element,
    placement: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start" | undefined
}

// To set tooltip Width.
const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 290,
    },
});

export const ShTooltip = ({ title, children, placement }: IShTooltip) => {
    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const isSmScreen = IsSmScreen();

    return (
        <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
            <div>
                <CustomWidthTooltip
                    title={title}
                    open={openTooltip}
                    onClose={() => setOpenTooltip(!openTooltip)}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement={placement ?? 'bottom'}
                    arrow
                >
                    <span
                        onMouseEnter={() => !isSmScreen && setOpenTooltip(true)}
                        onMouseLeave={() => !isSmScreen && setOpenTooltip(false)}
                        onClick={() => setOpenTooltip(!openTooltip)}
                    >
                        {children}
                    </span>
                </CustomWidthTooltip>
            </div>
        </ClickAwayListener>
    );
};