
export const InputTypes = {
    checkbox: 'checkbox'
};

export const AlertsTimeout = 5000;

export const EmployerDetailsInitial = {
    avatar: '',
    calendly_token: '',
    campaign_discount_amount_used: '',
    campaign_id: '',
    clone_employees: false,
    company_name: '',
    createdAt: '',
    description: '',
    discount_amount: 0,
    discount_amount_used: false,
    discount_rate: 0,
    display_currently_hiring_jobs_feed: false,
    employer_code: '',
    employer_pricing_group_id: 0,
    first_name: '',
    franchise_id: 0,
    franchise_jobs_label: '',
    franchisees_label: '',
    franchisor_id: 0,
    has_template_jobs: false,
    hiring_coach_name: '',
    industry_id: 0,
    is_disabled: false,
    last_name: '',
    new_apply_flow: false,
    no_notifications: false,
    num_employees: '',
    phone_area: '',
    phone_number: '',
    promotioncode_id: 0,
    referral: false,
    require_resumes: false,
    sends_candidate_alerts: false,
    sfdc_account_id: '',
    type: '',
    updatedAt: ''
}

export const ExternalIdTypes = {
    owner_marketo: 'owner_marketo',
    sdfc_contact: 'sdfc_contact',
    sdfc_account: 'sdfc_account'
}

export const EmpSubsTypes = {
    employee_fit_subscription: 'EmployeeFitSubscription',
    discount_package_subscription: 'DiscountPackageSubscription'
}