import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ActionsCell, BlackDivider, FixedTable, StyledStack, StyledTableCell, TableActionsContainer, ThemedBtn } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails.styled';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { SelectMenuProps } from 'shared/SharedStyles/ShInputs';
import { ApiStates } from 'shared/constants';
import { fetchAvailableCredits } from 'store/slices/admin/employer/credits-slice';
import { fetchBundlesAndAddOns, purchaseInventory, resetPrchseInvntry, resetUpsellPrchse, upSellInventory } from 'store/slices/admin/employer/products-slice';
import { fetchJobs } from 'store/slices/jobs-slice';

export const Products = ({ employerId }) => {
    const dispatch = useDispatch();
    const empAccId = useSelector((state) => state.employer.employer.empAccId);
    const empPricingGrpId = useSelector((state) => state.employer.employer.empPricingGrpId);
    const { bundles, addons, purchasingInvntry, purchasingInvntryRspnse, upsellPurchase, upsellPurchaseRspnse } = useSelector((state) => state.employer.products);
    const { jobsList } = useSelector((state) => state.jobs);
    const [prdctsAndPrchs, setPrdctsAndPrchs] = useState([]);
    const [upSell, setUpSell] = useState({ upsell_job_id: '', upsell_product_id: '', upsell_amount: 0 });
    const [curInvntry, setCurInvntry] = useState([]);

    useEffect(() => {
        if (empPricingGrpId !== undefined) {
            dispatch(fetchBundlesAndAddOns({ employerId }));
        }
    }, [dispatch, employerId, empPricingGrpId]);

    useEffect(() => {
        dispatch(fetchJobs({
            sort: 'asc',
            pageNumber: 1,
            pageSize: 1000000,
            sortBy: 'recent',
            jobType: 'active'
        }));
    }, [dispatch]);

    useEffect(() => {
        if (bundles) {
            //Updating the Products and Purchases table data array based on bundle size. The array size is same as bundle size.
            setPrdctsAndPrchs(bundles.map(() => { return { 'bundle_id': 0, 'quantity': 0, 'unit_price': 0 } }));
        }
    }, [bundles]);

    const updateTableCellValues = (e, indx) => {
        const { name, value } = e.target;
        const newArr = prdctsAndPrchs.map((pnp, ind) => {
            if (indx === ind) {
                pnp[name] = isNaN(parseInt(value)) ? value : parseInt(value);
            }
            return pnp;
        });
        setPrdctsAndPrchs(newArr);
        const newInv = newArr.filter(pnp => pnp.bundle_id > 0 && pnp.quantity > 0)
            .map(ci => `${bundles.find(bn => bn.id === ci.bundle_id).name}(${ci.quantity})`);
        setCurInvntry(newInv);
    };

    const purchaseInvntry = (purchase_type) => {
        const payload = {
            'order_items': prdctsAndPrchs.filter(pnp => pnp.bundle_id > 0)
        }
        dispatch(purchaseInventory({ employerId, purchase_type, account_id: empAccId, orderItems: payload }));
    };

    useEffect(() => {
        if (purchasingInvntry === ApiStates.success) {
            dispatch(fetchAvailableCredits({ employerId }));
        }
    }, [dispatch, employerId, purchasingInvntry]);

    const updateUpsell = (e) => {
        const { name, value } = e.target;
        setUpSell({ ...upSell, [name]: value });
    };

    const purchaseUpSell = (purchase_type) => {
        dispatch(upSellInventory({
            employerId,

            purchase_type,
            account_id: empAccId,
            orderItems: upSell
        }));
    };

    useEffect(() => {
        if (upsellPurchase === ApiStates.success) {
            dispatch(fetchAvailableCredits({ employerId }));
        }
    }, [dispatch, employerId, upsellPurchase]);

    const PurchaseAlert = () => {
        switch (purchasingInvntry) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Purchasing...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetPrchseInvntry()) }} severity="success">{purchasingInvntryRspnse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetPrchseInvntry()) }} severity="error">Error occurred while purchasing!</ShAlert>);
            default:
                return (<></>);
        }
    };

    const UpsellAlert = () => {
        switch (upsellPurchase) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Purchasing...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetUpsellPrchse()) }} severity="success">{upsellPurchaseRspnse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetUpsellPrchse()) }} severity="error">Error occurred while purchasing!</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <StyledStack id='products-and-purchases'>
            <Typography variant="h6" gutterBottom>
                Products and Purchases
            </Typography>
            <FixedTable size="small" aria-label="Products and Purchases">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Bundle</StyledTableCell>
                        <StyledTableCell>Quantity</StyledTableCell>
                        <StyledTableCell>Unit Price</StyledTableCell>
                        <StyledTableCell>Total Price</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {prdctsAndPrchs.map((pnp, indx) => (
                        <TableRow key={indx}>
                            <StyledTableCell>
                                <Select size='small' variant='standard' name='bundle_id' value={pnp.bundle_id}
                                    onChange={(e) => updateTableCellValues(e, indx)} fullWidth>
                                    <MenuItem key='_' value={0}>-</MenuItem>
                                    {bundles && bundles.map(b => (<MenuItem key={b.id}
                                        disabled={prdctsAndPrchs.findIndex(pn => pn.bundle_id === b.id) >= 0}
                                        value={b.id}>{b.name}</MenuItem>))}
                                </Select>
                            </StyledTableCell>
                            <StyledTableCell>
                                <InputBase type='number' name='quantity' value={pnp.quantity}
                                    onKeyDown={event => event.key === '.' ? event.preventDefault() : ''}
                                    onChange={(e) => updateTableCellValues(e, indx)}
                                    placeholder="Quantity"
                                    inputProps={{ 'aria-label': 'Quantity' }}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                <InputBase type='number' name='unit_price' value={pnp.unit_price}
                                    onChange={(e) => updateTableCellValues(e, indx)}
                                    placeholder="Unit Price"
                                    inputProps={{ 'aria-label': 'Unit Price' }}
                                />
                            </StyledTableCell>
                            <StyledTableCell>
                                ${pnp.quantity * pnp.unit_price}
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <StyledTableCell colSpan={3}>
                            Total Price
                        </StyledTableCell>
                        <StyledTableCell>
                            ${prdctsAndPrchs.reduce((prev, cur) => prev += cur.quantity * cur.unit_price, 0)}
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <ActionsCell colSpan={4}>
                            <TableActionsContainer direction='row' spacing={1}>
                                <ThemedBtn variant="contained"
                                    disabled={purchasingInvntry === ApiStates.pending || prdctsAndPrchs.filter(pnp => pnp.bundle_id > 0 && pnp.quantity > 0)?.length === 0}
                                    onClick={() => purchaseInvntry('credit_card')}>Credit Card</ThemedBtn>
                                <ThemedBtn variant="contained"
                                    disabled={purchasingInvntry === ApiStates.pending || prdctsAndPrchs.filter(pnp => pnp.bundle_id > 0 && pnp.quantity > 0)?.length === 0}
                                    onClick={() => purchaseInvntry('invoice')}>Invoice</ThemedBtn>
                            </TableActionsContainer>
                            {PurchaseAlert()}
                        </ActionsCell>
                    </TableRow>
                </TableBody>
            </FixedTable>
        </StyledStack>
        <StyledStack>
            <Table size="small" aria-label="Products and Purchases">
                <TableHead>
                    <TableRow>
                        <StyledTableCell colSpan={2}>Upsell</StyledTableCell>
                        <StyledTableCell>Current Inventory</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>
                            Current Jobs
                        </StyledTableCell>
                        <StyledTableCell>
                            <Select size='small' variant='standard' MenuProps={SelectMenuProps} fullWidth name='upsell_job_id'
                                value={upSell.upsell_job_id} onChange={updateUpsell}>
                                {jobsList?.results?.map(jl => (<MenuItem key={jl.id} value={jl.id}>{jl.title}</MenuItem>))}
                            </Select>
                        </StyledTableCell>
                        <StyledTableCell rowSpan={3}>
                            {curInvntry?.length > 0 ? curInvntry.map(ci => (<Typography key={ci} variant='body2'>{ci}</Typography>)) : '$0'}
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>
                            Addons
                        </StyledTableCell>
                        <StyledTableCell>
                            <Select size='small' variant='standard' name='upsell_product_id' MenuProps={SelectMenuProps} fullWidth
                                value={upSell.upsell_product_id} onChange={updateUpsell}>
                                {addons && addons.map(a => (<MenuItem key={a.id} value={a.id}>{a.name}</MenuItem>))}
                            </Select>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>
                            Price
                        </StyledTableCell>
                        <StyledTableCell>
                            <InputBase type='number' name='upsell_amount' value={upSell.upsell_amount}
                                onChange={updateUpsell}
                                placeholder="Price"
                                inputProps={{ 'aria-label': 'Price' }}
                            />
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <ActionsCell colSpan={3}>
                            <TableActionsContainer direction='row' spacing={1}>
                                <ThemedBtn variant="contained" disabled={upsellPurchase === ApiStates.pending || upSell.upsell_job_id <= 0 || upSell.upsell_product_id <= 0}
                                    onClick={() => purchaseUpSell('credit_card')}>Credit Card</ThemedBtn>
                                <ThemedBtn variant="contained" disabled={upsellPurchase === ApiStates.pending || upSell.upsell_job_id <= 0 || upSell.upsell_product_id <= 0}
                                    onClick={() => purchaseUpSell('invoice')}>Invoice</ThemedBtn>
                            </TableActionsContainer>
                            {UpsellAlert()}
                        </ActionsCell>
                    </TableRow>
                </TableBody>
            </Table>
        </StyledStack>
        <BlackDivider />
    </>);
}