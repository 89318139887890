import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EmpSubsTypes } from 'Modules/Core/Admin/EmployerProfile/EmpProfileConstants';
import { BlackDivider, ThemedBtn, ThemedBtnRed } from "Modules/Core/Admin/EmployerProfile/EmployerDetails.styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ApiStates } from 'shared/constants';
import { addEmployeeSubscriptions, fetchEmployeeSubscriptions, removeEmployeeSubscriptions, resetAddOrRemoveSubs } from 'store/slices/admin/employer/subs-slice';

export const Subscriptions = ({ employerId }) => {
    const dispatch = useDispatch();
    const { employeeSubs, addOrRemoveSubsRspnse, addingOrRemovingSubs } = useSelector((state) => state.employer.subs);
    const [isEmpFitSubs, setIsEmpFitSubs] = useState(false);
    const [isDiscPkgSubs, setIsDiscPkgSubs] = useState(false);

    useEffect(() => {
        dispatch(fetchEmployeeSubscriptions({ employerId }));
    }, [dispatch, employerId])

    useEffect(() => {
        if (employeeSubs) {
            setIsEmpFitSubs(employeeSubs.find(es => es.type === EmpSubsTypes.employee_fit_subscription) !== undefined);
            setIsDiscPkgSubs(employeeSubs.find(es => es.type === EmpSubsTypes.discount_package_subscription) !== undefined);
        }
    }, [employeeSubs]);

    const addSubs = (subsType) => {
        dispatch(addEmployeeSubscriptions({ employerId, subsType }));
    };

    const removeSubs = (subsType) => {
        dispatch(removeEmployeeSubscriptions({ employerId, subsType }));
    };

    useEffect(() => {
        if (addingOrRemovingSubs === ApiStates.success) {
            dispatch(fetchEmployeeSubscriptions({ employerId }));
        }
    }, [addingOrRemovingSubs, dispatch, employerId]);

    const Alerts = () => {
        switch (addingOrRemovingSubs) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Updating...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetAddOrRemoveSubs()) }} severity="success">{addOrRemoveSubsRspnse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetAddOrRemoveSubs()) }} severity="error">`Error occurred!`</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <Typography id='subscriptions' variant="subtitle2" gutterBottom>
            Subscriptions (Pending: Currently toggles visibility of employee fit, enables discount package)
        </Typography>
        <Box>
            {isEmpFitSubs ? <ThemedBtnRed variant="contained" disabled={addingOrRemovingSubs === ApiStates.pending} onClick={() => removeSubs('employee_fit')}>Remove Employee Fit Subscription</ThemedBtnRed> :
                <ThemedBtn variant="contained" disabled={addingOrRemovingSubs === ApiStates.pending} onClick={() => addSubs('employee_fit')}>Add Employee Fit Subscription</ThemedBtn>}
        </Box>
        <Box>
            {isDiscPkgSubs ? <ThemedBtnRed variant="contained" disabled={addingOrRemovingSubs === ApiStates.pending} onClick={() => removeSubs('discount_package')}>Remove Discount Package Subscription</ThemedBtnRed> :
                <ThemedBtn variant="contained" disabled={addingOrRemovingSubs === ApiStates.pending} onClick={() => addSubs('discount_package')}>Add Discount Package Subscription</ThemedBtn>}
        </Box>
        {Alerts()}
        <BlackDivider />
    </>);
};