import { Box, CircularProgress, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { getCandidateCareerReport } from 'store/slices/candidate/candidate-profile-slice';
import { CandidateLoginRedirect } from "../AuthV2/AuthConstants";

export const CandidateReport = () => {

    const { getApplicantCareerReportStatus, getApplicantCareerReportResponse,
        careerReport } = useAppSelector(state => state.candidate.candidateProfile);
    const { candidateId } = useAppSelector((state) => state.auth.auth);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (candidateId) {
            dispatch(getCandidateCareerReport({ applicantId: candidateId }));
        }
    }, [candidateId, dispatch])

    return (<>
        <Stack padding={1} height='100%' rowGap={1}>
            {getApplicantCareerReportStatus === 'failed' &&
                <>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity='error'>
                            {getApplicantCareerReportResponse}
                        </ShAlert>
                    </Box>
                </>}
            {getApplicantCareerReportStatus === 'pending' ?
                <Box height='80%' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                    <CircularProgress size={50} />
                </Box> :
                careerReport &&
                <embed
                    title="Career Report"
                    src={careerReport}
                    type="application/pdf"
                    width="100%"
                    height="98%"
                />
            }
            <ShButton variant="outlined" component={RouterLink} to={CandidateLoginRedirect}>Back</ShButton>
        </Stack>
    </>);
}