import { Box, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { MouseEvent, useCallback, useEffect, useState } from "react";
import { EmailRegEx } from "shared/constants";
import { ShReactQuill } from "shared/SharedComponents/ShReactQuill/ShReactQuill";
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { resetRejectionEmail, sendRejectionEmail } from "store/slices/employer/applicants/applicant-actions-slice";
import { fetchJobSummary, resetFetchJobSummary } from "store/slices/employer/jobs/jobs-list-slice";
import { RejectionEmailPayload } from "../ApplicantsConstants";
import { IRejectionEmailDialog, IRejectionEmailPayload } from "../ApplicantsModel";

export const RejectionEmailDialog = ({ isRejectionEmailDialogOpen, setIsRejectionEmailDialogOpen,
    applicant, jobId }: IRejectionEmailDialog) => {

    const dispatch = useAppDispatch();
    const notification = useNotification();
    const isXsScreen = IsXsScreen();
    const { rejectionEmailStatus, rejectionEmailResponse } = useAppSelector(state => state.employer.applicants.applicantActions);
    const { jobsSummary } = useAppSelector(state => state.employer.employerJobs.jobsList);
    const [rejectionEmailPayload, setRejectionEmailPayload] = useState<IRejectionEmailPayload>(() => {
        return { ...RejectionEmailPayload, to: applicant.email }
    });
    const [emailBodyEditorValue, setEmailBodyEditorValue] = useState<string>('');
    const [isSendEmailDisabled, setIsSendEmailDisabled] = useState<boolean>(true);
    const [isToEmailInvalid, setIsToEmailInvalid] = useState<boolean>(false);
    const [isCcEmailInvalid, setIsCcEmailInvalid] = useState<boolean>(false);
    const [isBccEmailInvalid, setIsBccEmailInvalid] = useState<boolean>(false);

    const _sendRejectionEmail = () => {
        dispatch(sendRejectionEmail({ applicationId: applicant.candidateEmployerJobId, jobId: jobId, payload: rejectionEmailPayload }));
    }

    // Get Job summary to pre-populate email template.
    useEffect(() => {
        if (jobId) {
            if (!(jobId in jobsSummary) && jobsSummary[jobId]?.getSummaryStatus !== 'success') {
                dispatch(fetchJobSummary({ jobId: jobId }));
            }
        }
    }, [dispatch, jobId, jobsSummary]);

    useEffect(() => {
        if (jobsSummary[jobId]?.employerName && !rejectionEmailPayload.data) {
            setEmailBodyEditorValue(`<p>Dear ${applicant.fullName},</p>
                                <br/>
                                <p>Thank you for applying to ${jobsSummary[jobId].name} at ${jobsSummary[jobId].employerName}. 
                                We wanted to let you know that we have reviewed your resume.
                                Even though you have a great background we found that you would not be a good match for this position.
                                We regret to inform you that you are not shortlisted for the position of ${jobsSummary[jobId].name}.</p>
                                <br/>
                                <p>We wish you all the best in your job search and future professional endeavors.</p>
                                <br/>
                                <p>Regards, <br/>The Team at ${jobsSummary[jobId]?.employerName}</p>`);
            setRejectionEmailPayload(prevPayload => ({
                ...prevPayload,
                subject: prevPayload.subject || `Regarding your application at ${jobsSummary[jobId]?.employerName}`
            }));
            dispatch(resetFetchJobSummary(jobId));
        }
    }, [applicant.fullName, dispatch, jobId, jobsSummary, rejectionEmailPayload.data]);

    // Update editor value in payload as it changes.
    useEffect(() => {
        setRejectionEmailPayload((prev) => { return { ...prev, data: emailBodyEditorValue } });
    }, [emailBodyEditorValue]);

    const closeRejectEmailDialog = useCallback((e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        if (e) {
            e.stopPropagation();
        }
        setIsRejectionEmailDialogOpen({ [applicant.candidateEmployerJobId]: false });
        setRejectionEmailPayload({ ...RejectionEmailPayload, to: applicant.email });
        setEmailBodyEditorValue('');
    }, [applicant.candidateEmployerJobId, applicant.email, setIsRejectionEmailDialogOpen]);

    useEffect(() => {
        if (rejectionEmailStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: rejectionEmailResponse ?? ''
            });
            dispatch(resetRejectionEmail());
            closeRejectEmailDialog();
            // dispatch(changeApplicantStageInList({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
            // dispatch(changeApplicantStageInProfile({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
        }
    }, [applicant.candidateEmployerJobId, closeRejectEmailDialog, dispatch, notification,
        rejectionEmailResponse, rejectionEmailStatus]);

    // Disable send button.
    useEffect(() => {
        const { to, data, subject, cc, bcc } = rejectionEmailPayload;
        setIsSendEmailDisabled(!to || !data || !subject || isToEmailInvalid ||
            (cc?.length > 0 && isCcEmailInvalid) || (bcc?.length > 0 && isBccEmailInvalid));
    }, [isBccEmailInvalid, isCcEmailInvalid, isToEmailInvalid, rejectionEmailPayload]);

    // Email validation function with Regular Expression.
    const validateEmail = (e: { target: { value: string; }; }, field: 'to' | 'cc' | 'bcc') => {
        const reg = new RegExp(EmailRegEx);
        setRejectionEmailPayload({ ...rejectionEmailPayload, [field]: e.target.value })
        switch (field) {
            case 'to':
                setIsToEmailInvalid(!reg.test(e.target.value));
                break;
            case 'cc':
                setIsCcEmailInvalid(!reg.test(e.target.value));
                break;
            case 'bcc':
                setIsBccEmailInvalid(!reg.test(e.target.value));
                break;
            default:
                break;
        }
    };

    return (<>
        <ShDialog open={isRejectionEmailDialogOpen} fullScreen={isXsScreen} onClose={() => closeRejectEmailDialog()}
            aria-labelledby="title" aria-describedby="sub_title" onClick={e => e.stopPropagation()}>
            <DialogTitle id='title' textAlign='center'>Rejection Email</DialogTitle>
            <DialogContent dividers>
                <Stack rowGap={2} height={'100%'}>
                    <TextField id="email_to" label="To" variant="outlined" size='small' required
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.to}
                        onChange={e => validateEmail(e, 'to')} error={rejectionEmailPayload.to?.length > 0 && isToEmailInvalid}
                        helperText={rejectionEmailPayload.to?.length > 0 && isToEmailInvalid && 'Please enter a valid email'}
                    />
                    <TextField id="email_cc" label="Cc" variant="outlined" size='small'
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.cc}
                        onChange={e => validateEmail(e, 'cc')} error={rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid}
                        helperText={rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid && 'Please enter a valid email'}
                    />
                    <TextField id="email_bcc" label="Bcc" variant="outlined" size='small'
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.bcc}
                        onChange={e => validateEmail(e, 'bcc')} error={rejectionEmailPayload.bcc?.length > 0 && isBccEmailInvalid}
                        helperText={rejectionEmailPayload.bcc?.length > 0 && isBccEmailInvalid && 'Please enter a valid email'}
                    />
                    <TextField id="email_subject" label="Subject" variant="outlined" size='small' required
                        fullWidth InputLabelProps={{ shrink: true }}
                        value={rejectionEmailPayload.subject}
                        onChange={e => { setRejectionEmailPayload({ ...rejectionEmailPayload, subject: e.target.value }) }}
                    />

                    {jobsSummary[jobId]?.getSummaryStatus === 'pending' ?
                        <>
                            <ShAlert severity="info">Prefilling email template ...</ShAlert>
                        </> :
                        <Box flex={1}>
                            <ShReactQuill quillEditorValue={emailBodyEditorValue} setQuillEditorValue={setEmailBodyEditorValue} />
                        </Box>
                    }

                </Stack>
            </DialogContent>
            <DialogActions>
                <ShButton size='small' onClick={(e) => closeRejectEmailDialog(e)}>
                    Cancel
                </ShButton>
                <ShGreenBtn size='small' onClick={_sendRejectionEmail}
                    disabled={rejectionEmailStatus === 'pending' || isSendEmailDisabled}>
                    {rejectionEmailStatus === 'pending' ? 'Sending Email ...' : 'Send Email'}
                </ShGreenBtn>
            </DialogActions>
        </ShDialog>
    </>);
}