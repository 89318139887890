import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IApplicantsApiParams, IApplicantsListResponse, IApplicantsListState } from 'Modules/Core/Applicants/ApplicantsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';

const initialDashboardApplicantsListState: IApplicantsListState = {
    getApplicantsListStatus: 'idle',
    getApplicantsListResponse: '',
    applicantsList: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    },
    applicantsSummary: {}
};

export const fetchDashboardApplicantsList = createAsyncThunk<IApplicantsListResponse, IApplicantsApiParams, { rejectValue: IBaseResponse }>(
    'dashboardApplicantsList',
    async ({ jobId, pgNo, pgSize, sortCol, sortDir, search, finalMatches, stages, keywords, ratings }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_JOBS}/${jobId}/applicants?pageNo=${pgNo}&pageSize=${pgSize}&sortColumn=${sortCol}&sortDirection=${sortDir}${search ? `&search=${search}` : ''}${finalMatches ? `&finalMatches=${finalMatches}` : ''}${stages ? `&stages=${stages}` : ''}${keywords ? `&keywords=${keywords}` : ''}${ratings ? `&ratings=${ratings}` : ''} `)
            .then((response: AxiosResponse<IApplicantsListResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const dashboardApplicantsSlice = createSlice({
    name: 'dashboardApplicants',
    initialState: initialDashboardApplicantsListState,
    reducers: {
        toggleApplicantSummary: (state, action: PayloadAction<number>) => {
            state.applicantsList[action.payload].isExpanded = !state.applicantsList[action.payload].isExpanded;
        },
        resetApplicantsState: (state) => {
            state = { ...initialDashboardApplicantsListState };
        },
        resetStageChange: (state, action: PayloadAction<number>) => {
            const indx = state.applicantsList.findIndex(applicant => applicant.candidateEmployerJobId === action.payload);
            if (indx >= 0) {
                state.applicantsList[indx].changeStateStatus = 'idle';
                state.applicantsList[indx].changeStateResponse = '';
            }
        },
        resetUpdateApplicantRatingInList: (state, action: PayloadAction<number>) => {
            const indx = state.applicantsList.findIndex(applicant => applicant.candidateEmployerJobId === action.payload);
            if (indx >= 0) {
                state.applicantsList[indx].updateRatingStatus = 'idle';
                state.applicantsList[indx].updateRatingResponse = '';
            }
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialDashboardApplicantsListState;
        });
        // fetch applicants list
        builder.addCase(fetchDashboardApplicantsList.pending, (state) => {
            state.getApplicantsListStatus = 'pending'
        });
        builder.addCase(fetchDashboardApplicantsList.fulfilled, (state, action) => {
            state.getApplicantsListStatus = 'success';
            state.applicantsList = action?.payload.data;
            // Form full names by combining first and last names 
            if (state.applicantsList?.length > 0) {
                state.applicantsList.forEach(applicant => {
                    applicant.fullName = `${applicant.firstName ?? ''} ${applicant.lastName ?? ''}`
                });
            }
            state.paginationParams = action?.payload.stats;
            state.isAtsPurchased = action.payload?.stats?.atsPurchased;
        });
        builder.addCase(fetchDashboardApplicantsList.rejected, (state, action) => {
            state.getApplicantsListStatus = 'failed';
            state.getApplicantsListResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { toggleApplicantSummary, resetApplicantsState, resetStageChange, resetUpdateApplicantRatingInList } = dashboardApplicantsSlice.actions;
export default dashboardApplicantsSlice;
