import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";
import { IEmployerSignUpPayload, ISignUpEmployerState } from "./auth-v2-model";

const initialEmployerSignupState: ISignUpEmployerState = {
    employerCountries: [],
    referenceSources: [],
    employerCountriesApiStatus: 'idle',
    getSourcesApiStatus: 'idle',
    signUpEmployerApiStatus: 'idle',
    signUpEmployerApiResponse: ''
};

// employer countries
export const getEmployerCountries = createAsyncThunk<IBaseResponse, void, { rejectValue: IBaseResponse }>(
    "countries",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_COUNTRIES}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// get referenceSources
export const getSources = createAsyncThunk<IBaseResponse, void, { rejectValue: IBaseResponse }>(
    "referenceSources",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.SOURCES}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// sign up new employer
export const signUpEmployer = createAsyncThunk<IBaseResponse, { payload: IEmployerSignUpPayload }, { rejectValue: IBaseResponse }>(
    "employer",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.EMPLOYERS}`, payload)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const employerSignUpSlice = createSlice({
    name: "employerSignUp",
    initialState: initialEmployerSignupState,
    reducers: {
        resetSignUpApiStates: (state) => { state.signUpEmployerApiStatus = 'idle'; state.signUpEmployerApiResponse = '' },
        resetSignUp: () => initialEmployerSignupState
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialEmployerSignupState;
        });
        //handle employer countries
        builder.addCase(getEmployerCountries.pending, (state) => {
            state.employerCountriesApiStatus = 'pending';
        });
        builder.addCase(getEmployerCountries.fulfilled, (state, action) => {
            state.employerCountriesApiStatus = 'success';
            state.employerCountries = action?.payload?.data;
        });
        builder.addCase(getEmployerCountries.rejected, (state) => {
            state.employerCountriesApiStatus = 'failed';
        });
        //handle get referenceSources
        builder.addCase(getSources.pending, (state) => {
            state.getSourcesApiStatus = 'pending';
        });
        builder.addCase(getSources.fulfilled, (state, action) => {
            state.getSourcesApiStatus = 'success';
            state.referenceSources = action?.payload?.data;
        });
        builder.addCase(getSources.rejected, (state) => {
            state.getSourcesApiStatus = 'failed';
        });
        //handle sign up employer
        builder.addCase(signUpEmployer.pending, (state) => {
            state.signUpEmployerApiStatus = 'pending';
        });
        builder.addCase(signUpEmployer.fulfilled, (state, action) => {
            state.signUpEmployerApiStatus = 'success';
            state.signUpEmployerApiResponse = action?.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(signUpEmployer.rejected, (state, action) => {
            state.signUpEmployerApiStatus = 'failed';
            state.signUpEmployerApiResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetSignUpApiStates, resetSignUp } = employerSignUpSlice.actions;
export default employerSignUpSlice;
