import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { ApiStates } from "shared/constants";

const initialProductsState = {
    bundles: [],
    addons: [],
    purchasingInvntry: ApiStates.idle,
    purchasingInvntryRspnse: '',
    upsellPurchase: ApiStates.idle,
    upsellPurchaseRspnse: ''
};

export const fetchBundlesAndAddOns = createAsyncThunk(
    "bundlesAndAddOns",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/bundles`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const purchaseInventory = createAsyncThunk(
    "purchaseInventory",
    async ({ employerId, purchase_type, account_id, orderItems }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/inventory?purchase_type=${purchase_type}&account_id=${account_id}`, orderItems)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const upSellInventory = createAsyncThunk(
    "upSellInventory",
    async ({ employerId, purchase_type, account_id, orderItems }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/upsell?purchase_type=${purchase_type}&account_id=${account_id}`,
                orderItems)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const productsSlice = createSlice({
    name: "products",
    initialState: initialProductsState,
    reducers: {
        resetPrchseInvntry: (state) => { state.purchasingInvntry = ApiStates.idle; state.purchasingInvntryRspnse = '' },
        resetUpsellPrchse: (state) => { state.upsellPurchase = ApiStates.idle; state.upsellPurchaseRspnse = '' }
    },
    extraReducers: (builder) => {
        //bundles and addons
        builder.addCase(fetchBundlesAndAddOns.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(fetchBundlesAndAddOns.fulfilled, (state, action) => {
            state.bundles = action?.payload?.data?.bundles;
            state.addons = action?.payload?.data?.addons;
        });
        builder.addCase(fetchBundlesAndAddOns.rejected, (state, action) => {
            state.bundles = [];
            state.addons = [];
        });
        //purchases
        builder.addCase(purchaseInventory.pending, (state, action) => {
            state.purchasingInvntry = ApiStates.pending;
        });
        builder.addCase(purchaseInventory.fulfilled, (state, action) => {
            state.purchasingInvntry = ApiStates.success;
            state.purchasingInvntryRspnse = action?.payload?.message;
        });
        builder.addCase(purchaseInventory.rejected, (state, action) => {
            state.purchasingInvntry = ApiStates.failed;
        });
        //upsell
        builder.addCase(upSellInventory.pending, (state, action) => {
            state.upsellPurchase = ApiStates.pending;
        });
        builder.addCase(upSellInventory.fulfilled, (state, action) => {
            state.upsellPurchase = ApiStates.success;
            state.upsellPurchaseRspnse = action?.payload?.message;
        });
        builder.addCase(upSellInventory.rejected, (state, action) => {
            state.upsellPurchase = ApiStates.failed;
        });
    },
});

export const { resetPrchseInvntry, resetUpsellPrchse } = productsSlice.actions;
export default productsSlice;
