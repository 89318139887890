export const CorporateLawyerJobDescription = {
    title: "Corporate Lawyer Job Description",
    jobRole: "A Corporate Lawyer provides legal advice and representation to corporations on various legal matters, including corporate governance, mergers and acquisitions, contract negotiations, and regulatory compliance. They ensure that corporate activities comply with legal requirements and support the organization's strategic objectives.",
    preRead: "Use this Corporate Lawyer job description template to advertise open positions for a Corporate Lawyer in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Corporate Lawyer, you will be responsible for providing legal advice and representation to corporations on various legal matters. Your role will involve advising clients on corporate governance issues, negotiating contracts, handling mergers and acquisitions, and ensuring compliance with applicable laws and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to corporations on corporate governance matters.",
                "Draft, review, and negotiate contracts, agreements, and other legal documents.",
                "Participate in corporate transactions, such as mergers, acquisitions, and joint ventures.",
                "Conduct legal research and analysis on corporate legal issues.",
                "Ensure compliance with laws and regulations governing corporate activities.",
                "Represent clients in negotiations, mediations, and other legal proceedings.",
                "Advise on legal risks and potential liabilities associated with corporate activities.",
                "Collaborate with internal departments and external stakeholders on legal matters.",
                "Stay updated on changes in laws, regulations, and industry trends.",
                "Provide training and guidance to company employees on legal issues."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Law or related field; Juris Doctor (JD) degree preferred.",
                "Admission to the bar in the relevant jurisdiction.",
                "Proven experience as a corporate lawyer or in a similar legal role.",
                "Strong knowledge of corporate law, contract law, and commercial transactions.",
                "Excellent communication and negotiation skills.",
                "Analytical and problem-solving abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in legal analysis and documentation.",
                "Ethical behavior and integrity in dealing with confidential information.",
                "Adaptability and willingness to learn and grow in a dynamic legal environment."
            ]
        }
    ]
};

export const ParalegalJobDescription = {
    title: "Paralegal Job Description",
    jobRole: "A Paralegal provides administrative and research support to lawyers and legal teams in law firms, corporate legal departments, or government agencies. They assist with legal research, document preparation, case management, and client communication to facilitate the delivery of legal services.",
    preRead: "Use this Paralegal job description template to advertise open positions for a Paralegal in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Paralegal, you will provide essential support to lawyers and legal teams in various legal matters. Your role will involve conducting legal research, preparing legal documents, managing case files, and assisting with client communication. This position requires strong organizational skills, attention to detail, and a solid understanding of legal procedures and terminology."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct legal research and analysis on relevant laws, regulations, and case precedents.",
                "Assist with the preparation and filing of legal documents, such as briefs, pleadings, and contracts.",
                "Manage case files and maintain organized records of legal documents and correspondence.",
                "Coordinate communication between clients, attorneys, and other parties involved in legal proceedings.",
                "Draft correspondence, memos, and other written communications.",
                "Prepare and organize exhibits, evidence, and trial materials for court proceedings.",
                "Assist with the scheduling of meetings, appointments, and court appearances.",
                "Provide administrative support to legal teams, including calendar management, travel arrangements, and expense tracking.",
                "Maintain confidentiality of sensitive legal information and client communications.",
                "Stay updated on changes in laws, regulations, and legal technology."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Paralegal Studies, Legal Studies, or related field; certification from an accredited paralegal program preferred.",
                "Previous experience as a paralegal or in a legal support role.",
                "Strong research and analytical skills, with attention to detail.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in legal research tools and software.",
                "Ability to prioritize tasks and manage multiple projects simultaneously.",
                "Understanding of legal terminology, procedures, and court systems.",
                "Collaborative and proactive approach to teamwork and problem-solving.",
                "Adaptability and willingness to learn new skills and technologies.",
                "Ethical behavior and integrity in handling confidential information and client matters."
            ]
        }
    ]
};

export const LegalSecretaryJobDescription = {
    title: "Legal Secretary Job Description",
    jobRole: "A Legal Secretary provides administrative support to lawyers and legal teams in law firms, corporate legal departments, or government agencies. They assist with document preparation, scheduling, client communication, and other administrative tasks to ensure the efficient operation of legal offices.",
    preRead: "Use this Legal Secretary job description template to advertise open positions for a Legal Secretary in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Secretary, you will play a vital role in supporting lawyers and legal teams in their daily activities. Your responsibilities will include preparing legal documents, managing correspondence, scheduling appointments, and providing administrative assistance to ensure the smooth functioning of legal offices. This position requires strong organizational skills, attention to detail, and the ability to handle confidential information with discretion."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and format legal documents, such as briefs, pleadings, and contracts.",
                "Manage correspondence, including drafting letters, emails, and memos.",
                "Schedule appointments, meetings, and court appearances for attorneys.",
                "Maintain organized files and records of legal documents and client information.",
                "Assist with document production and discovery processes in legal proceedings.",
                "Handle incoming calls and inquiries from clients, courts, and other parties.",
                "Coordinate communication between attorneys, clients, and other stakeholders.",
                "Perform general administrative tasks, such as filing, copying, and faxing documents.",
                "Assist with billing and invoicing tasks, including timekeeping and expense tracking.",
                "Maintain confidentiality of sensitive legal information and client communications."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High School Diploma or equivalent; additional certification in office administration or legal studies is a plus.",
                "Previous experience as a legal secretary, administrative assistant, or similar role.",
                "Proficiency in Microsoft Office applications, including Word, Excel, and Outlook.",
                "Strong organizational and time management skills, with attention to detail.",
                "Excellent communication and interpersonal abilities.",
                "Ability to handle confidential information with discretion and professionalism.",
                "Team player with the ability to collaborate effectively with colleagues and clients.",
                "Adaptability and willingness to learn new tasks and procedures.",
                "Legal terminology and basic understanding of legal procedures.",
                "Knowledge of legal document formatting and citation styles."
            ]
        }
    ]
};

export const ComplianceOfficerJobDescription = {
    title: "Compliance Officer Job Description",
    jobRole: "A Compliance Officer ensures that organizations comply with legal and regulatory requirements, industry standards, and internal policies. They develop and implement compliance programs, conduct audits and assessments, and provide guidance to management and staff on compliance issues.",
    preRead: "Use this Compliance Officer job description template to advertise open positions for a Compliance Officer in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Compliance Officer, you will be responsible for overseeing and ensuring compliance with laws, regulations, and company policies. Your role will involve developing compliance programs, conducting risk assessments, monitoring compliance activities, and providing training and guidance to employees. This position requires strong analytical skills, attention to detail, and a thorough understanding of regulatory requirements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop, implement, and maintain compliance policies and procedures.",
                "Conduct compliance risk assessments and audits to identify areas of non-compliance.",
                "Monitor changes in laws, regulations, and industry standards affecting the organization's operations.",
                "Provide guidance and support to management and staff on compliance matters.",
                "Investigate complaints and reports of non-compliance and take corrective actions as necessary.",
                "Develop and deliver compliance training programs for employees.",
                "Review contracts, agreements, and other legal documents for compliance implications.",
                "Prepare and submit compliance reports to regulatory agencies and senior management.",
                "Collaborate with internal departments and external stakeholders on compliance initiatives.",
                "Stay updated on emerging trends and best practices in compliance management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration, Law, Finance, or related field.",
                "Previous experience in compliance, risk management, or related field.",
                "Strong knowledge of laws, regulations, and industry standards relevant to the organization.",
                "Analytical and problem-solving skills, with attention to detail.",
                "Excellent communication and interpersonal abilities.",
                "Ability to interpret complex legal and regulatory requirements.",
                "Experience developing and implementing compliance programs.",
                "Certification in compliance, such as Certified Regulatory Compliance Manager (CRCM), is a plus.",
                "Ethical behavior and integrity in handling confidential information.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        }
    ]
};

export const ContractManagerJobDescription = {
    title: "Contract Manager Job Description",
    jobRole: "A Contract Manager oversees the creation, negotiation, and execution of contracts between organizations and their clients, vendors, or partners. They ensure that contracts comply with legal requirements, mitigate risks, and support the organization's objectives. Contract Managers play a crucial role in managing contractual relationships and ensuring compliance with contractual terms and conditions.",
    preRead: "Use this Contract Manager job description template to advertise open positions for a Contract Manager in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Contract Manager, you will be responsible for managing all stages of the contract lifecycle, from drafting and negotiation to contract administration and compliance. Your role will involve collaborating with internal departments, external stakeholders, and legal counsel to develop contracts that meet the organization's needs while mitigating risks. This position requires strong negotiation skills, attention to detail, and the ability to manage multiple contracts simultaneously."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Draft, review, and negotiate contracts, agreements, and other legal documents.",
                "Ensure that contracts comply with legal requirements, company policies, and industry standards.",
                "Collaborate with internal stakeholders, including legal, finance, and procurement departments, to develop contract terms and conditions.",
                "Manage contract administration processes, including contract tracking, filing, and reporting.",
                "Identify and mitigate contractual risks and issues.",
                "Conduct contract negotiations with clients, vendors, and partners to achieve favorable terms and conditions.",
                "Coordinate contract execution and implementation processes.",
                "Monitor contract performance and compliance with contractual obligations.",
                "Resolve contract disputes and issues in a timely and effective manner.",
                "Maintain accurate and organized contract records and documentation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration, Law, Finance, or related field.",
                "Previous experience in contract management, procurement, or related field.",
                "Strong understanding of contract law and legal principles.",
                "Excellent negotiation and communication skills.",
                "Analytical and problem-solving abilities, with attention to detail.",
                "Ability to prioritize tasks and manage multiple contracts simultaneously.",
                "Familiarity with contract management software and tools.",
                "Knowledge of industry-specific regulations and standards.",
                "Ethical behavior and integrity in handling confidential information.",
                "Team player with the ability to collaborate effectively with internal and external stakeholders."
            ]
        }
    ]
};

export const LitigationAttorneyJobDescription = {
    title: "Litigation Attorney Job Description",
    jobRole: "A Litigation Attorney is responsible for representing clients in civil and criminal litigation proceedings. They handle all phases of the litigation process, including investigation, pleadings, discovery, trial, and appeal. Litigation Attorneys provide legal advice, draft legal documents, and advocate on behalf of their clients in court.",
    preRead: "Use this Litigation Attorney job description template to advertise open positions for a Litigation Attorney in your law firm or legal department. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Litigation Attorney, you will represent clients in various legal proceedings, including civil lawsuits, criminal trials, and administrative hearings. You will conduct legal research, draft pleadings and motions, and prepare for trial. This role requires excellent advocacy skills, attention to detail, and the ability to work under pressure."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Represent clients in court proceedings, including trials, hearings, and motions.",
                "Conduct legal research and analysis to support case strategy.",
                "Draft legal documents, including complaints, motions, briefs, and appeals.",
                "Interview clients and witnesses to gather evidence and prepare testimony.",
                "Negotiate settlements and plea bargains on behalf of clients.",
                "Prepare for trial by organizing evidence, preparing witnesses, and developing trial strategy.",
                "Advise clients on legal rights, obligations, and options.",
                "Maintain accurate and organized case files and documentation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (JD) degree from an accredited law school.",
                "Admission to the state bar and in good standing.",
                "Proven experience as a Litigation Attorney, preferably in a law firm setting.",
                "Strong litigation skills, including trial experience.",
                "Excellent written and verbal communication skills.",
                "Ability to work independently and as part of a team.",
                "Strong analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Ability to manage multiple cases and prioritize tasks."
            ]
        }
    ]
};

export const LegalAssistantJobDescription = {
    title: "Legal Assistant Job Description",
    jobRole: "A Legal Assistant provides administrative and clerical support to attorneys and legal teams in law firms, corporate legal departments, or government agencies. They assist with legal research, document preparation, case management, and client communication, helping to ensure the efficient operation of legal proceedings.",
    preRead: "Use this Legal Assistant job description template to advertise open positions for a Legal Assistant in your law firm, legal department, or organization. Customize the responsibilities and qualifications to align with your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Assistant, you will work closely with attorneys and legal professionals to provide administrative assistance and support in legal matters. You will help prepare legal documents, maintain case files, conduct legal research, and assist with client communication. This role requires strong organizational skills, attention to detail, and knowledge of legal procedures and terminology."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist attorneys with the preparation and filing of legal documents, such as pleadings, motions, and briefs.",
                "Conduct legal research using online databases and library resources.",
                "Organize and maintain legal files and documentation.",
                "Prepare and distribute correspondence, memos, and other legal documents.",
                "Schedule meetings, hearings, and appointments for attorneys and clients.",
                "Assist in the drafting and editing of legal documents, including contracts, agreements, and letters.",
                "Communicate with clients, witnesses, and court personnel as directed by attorneys.",
                "Perform administrative tasks, such as answering phones, managing calendars, and handling mail."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree or certificate in paralegal studies or related field preferred.",
                "Proven experience as a Legal Assistant or similar role.",
                "Knowledge of legal terminology, procedures, and documentation.",
                "Proficiency in legal research and writing.",
                "Strong computer skills, including proficiency with legal software and Microsoft Office suite.",
                "Excellent organizational and time management skills.",
                "Attention to detail and accuracy in work.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Strong communication and interpersonal skills."
            ]
        }
    ]
};

export const LegalCounselJobDescription = {
    title: "Legal Counsel Job Description",
    jobRole: "A Legal Counsel provides expert legal advice and support to an organization, ensuring compliance with laws and regulations and minimizing legal risks. They handle a variety of legal matters, including contracts, negotiations, litigation, and regulatory compliance, and provide strategic guidance to senior management on legal issues.",
    preRead: "Use this Legal Counsel job description template to advertise open positions for a Legal Counsel in your organization. Customize the responsibilities and qualifications to align with your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Counsel, you will be responsible for providing legal advice and support to the organization on a wide range of legal matters. You will draft and review contracts, negotiate agreements, and provide counsel on corporate governance and regulatory compliance. This role requires excellent legal knowledge, analytical skills, and the ability to communicate complex legal concepts effectively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and guidance to senior management and other departments on legal matters affecting the organization.",
                "Draft, review, and negotiate a variety of legal agreements, contracts, and documents.",
                "Manage and oversee litigation matters, including working with external counsel as needed.",
                "Ensure compliance with relevant laws and regulations in all aspects of the organization's operations.",
                "Conduct legal research and analysis to stay updated on legal developments and precedents.",
                "Develop and implement legal policies and procedures to mitigate legal risks and ensure compliance.",
                "Advise on corporate governance matters and participate in board meetings and governance initiatives.",
                "Collaborate with external legal counsel and other advisors as needed.",
                "Provide training and guidance to employees on legal issues and compliance matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (JD) degree from an accredited law school and admission to the bar.",
                "Several years of experience practicing law, preferably in a corporate or commercial setting.",
                "Strong knowledge of corporate law, contracts, litigation, and regulatory compliance.",
                "Excellent analytical and problem-solving skills.",
                "Strong negotiation and communication skills, both written and verbal.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Attention to detail and ability to manage multiple priorities.",
                "Familiarity with relevant legal software and tools.",
                "Understanding of business operations and objectives.",
                "Experience in salary negotiation and benefits administration is preferred."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const IntellectualPropertyLawyerJobDescription = {
    title: "Intellectual Property Lawyer Job Description",
    jobRole: "An Intellectual Property Lawyer specializes in providing legal advice and representation related to intellectual property (IP) matters, including patents, trademarks, copyrights, and trade secrets. They help clients protect their IP rights, manage IP portfolios, and enforce their rights through litigation or alternative dispute resolution.",
    preRead: "Use this Intellectual Property Lawyer job description template to advertise open positions for an Intellectual Property Lawyer in your organization. Customize the responsibilities and qualifications to align with your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Intellectual Property Lawyer, you will be responsible for advising clients on all aspects of intellectual property law, including patent, trademark, copyright, and trade secret matters. You will provide legal counsel on IP protection, licensing, enforcement, and litigation. This role requires expertise in IP law, strong analytical skills, and the ability to communicate complex legal concepts effectively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and representation to clients on intellectual property matters, including patents, trademarks, copyrights, and trade secrets.",
                "Draft, review, and negotiate IP-related agreements, such as licensing agreements, assignment agreements, and non-disclosure agreements.",
                "Conduct IP searches and due diligence reviews to assess the availability, validity, and enforceability of IP rights.",
                "Manage and oversee the preparation and prosecution of patent and trademark applications.",
                "Advise clients on IP enforcement strategies and represent them in IP litigation and dispute resolution proceedings.",
                "Assist clients in developing and implementing IP protection and management strategies.",
                "Stay updated on changes and developments in IP law and advise clients accordingly.",
                "Collaborate with other attorneys, paralegals, and external counsel as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (JD) degree from an accredited law school and admission to the bar.",
                "Several years of experience practicing intellectual property law, preferably in a law firm or corporate legal department.",
                "Strong knowledge of patent, trademark, copyright, and trade secret law.",
                "Experience in patent and trademark prosecution and litigation.",
                "Excellent analytical and problem-solving skills.",
                "Strong negotiation and communication skills, both written and verbal.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Attention to detail and ability to manage multiple priorities.",
                "Familiarity with relevant legal software and tools."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const LegalAnalystJobDescription = {
    title: "Legal Analyst Job Description",
    jobRole: "A Legal Analyst supports legal teams by conducting research, analyzing legal documents, and assisting in various legal tasks. They review contracts, statutes, regulations, and case law to provide insights and support decision-making. Legal Analysts also help draft legal documents, prepare reports, and maintain legal databases.",
    preRead: "Use this Legal Analyst job description template to advertise open positions for a Legal Analyst in your organization. Customize the responsibilities and qualifications to align with your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Analyst, you will play a key role in supporting legal teams by conducting research, analyzing legal documents, and assisting in various legal tasks. You will review contracts, statutes, regulations, and case law to provide insights and support decision-making. This role requires strong analytical skills, attention to detail, and the ability to work collaboratively with legal professionals."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct legal research and analysis on various topics, including statutes, regulations, case law, and legal precedent.",
                "Review and analyze contracts, agreements, and other legal documents to identify key terms, risks, and compliance requirements.",
                "Assist in the preparation of legal documents, including briefs, memos, and correspondence.",
                "Maintain and organize legal databases, libraries, and filing systems.",
                "Prepare reports, summaries, and presentations based on legal research and analysis.",
                "Collaborate with legal teams and other stakeholders to provide support on legal matters.",
                "Stay updated on changes and developments in relevant laws and regulations.",
                "Provide general administrative support to legal teams as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Law, Legal Studies, or related field.",
                "Previous experience in a legal environment or similar role is preferred.",
                "Strong research and analytical skills, with the ability to analyze complex legal issues and concepts.",
                "Familiarity with legal research tools and databases.",
                "Excellent written and verbal communication skills.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Attention to detail and accuracy in work.",
                "Proficiency in Microsoft Office Suite and other relevant software.",
                "Ability to handle confidential information with discretion."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range commensurate with experience and qualifications.",
                "Health, dental, and vision insurance coverage.",
                "Retirement savings plan options.",
                "Paid time off and holidays.",
                "Opportunities for professional development and training.",
                "Flexible work arrangements."
            ]
        }
    ]
};

export const TaxAttorneyJobDescription = {
    title: "Tax Attorney Job Description",
    jobRole: "A Tax Attorney specializes in providing legal advice and guidance on tax-related matters to individuals, businesses, and organizations. They interpret tax laws, regulations, and rulings to help clients navigate complex tax issues, minimize tax liabilities, and ensure compliance with tax laws. Tax Attorneys also represent clients in tax disputes, negotiations, and audits with tax authorities.",
    preRead: "Use this Tax Attorney job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tax Attorney, you will be responsible for providing legal counsel and representation to clients on various tax matters. You will interpret tax laws, regulations, and rulings to help clients navigate complex tax issues and minimize tax liabilities. This role requires strong analytical skills, legal expertise, and the ability to effectively communicate complex tax concepts to clients."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and guidance to clients on tax planning, compliance, and reporting.",
                "Interpret and apply tax laws, regulations, rulings, and court decisions to specific client situations.",
                "Assist clients in structuring transactions to minimize tax liabilities and maximize tax benefits.",
                "Represent clients in tax audits, disputes, and negotiations with tax authorities.",
                "Prepare and review tax-related documents, including tax returns, filings, and correspondence with tax authorities.",
                "Research and analyze complex tax issues and provide recommendations to clients.",
                "Stay updated on changes and developments in tax laws, regulations, and rulings.",
                "Collaborate with other legal professionals and tax advisors to provide comprehensive tax advice and solutions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in tax law is preferred.",
                "Previous experience as a Tax Attorney or related role is advantageous.",
                "Strong knowledge of federal, state, and local tax laws and regulations.",
                "Excellent analytical, research, and problem-solving skills.",
                "Effective communication and negotiation skills.",
                "Attention to detail and accuracy in legal work.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The average salary range for Tax Attorneys varies depending on factors such as experience, location, and employer. On average, Tax Attorneys can expect to earn between $80,000 and $150,000 per year."
        }
    ]
};

export const ImmigrationLawyerJobDescription = {
    title: "Immigration Lawyer Job Description",
    jobRole: "An Immigration Lawyer specializes in providing legal advice and assistance to individuals, families, and businesses navigating immigration laws and processes. They help clients with visa applications, citizenship issues, deportation defense, and other immigration-related matters. Immigration Lawyers represent clients before immigration authorities, courts, and agencies, advocating for their rights and interests.",
    preRead: "Use this Immigration Lawyer job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Immigration Lawyer, you will be responsible for providing legal counsel and representation to clients on a wide range of immigration matters. You will assist clients with visa applications, citizenship issues, deportation defense, and other immigration-related issues. This role requires strong legal expertise, advocacy skills, and a deep understanding of immigration laws and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and assistance to clients on immigration matters, including visa applications, citizenship issues, deportation defense, and immigration-related disputes.",
                "Represent clients in immigration proceedings before immigration authorities, courts, and agencies.",
                "Prepare and file immigration petitions, applications, and other legal documents on behalf of clients.",
                "Conduct legal research and analysis on immigration laws, regulations, and precedent cases.",
                "Advocate for clients' rights and interests in immigration matters, including asylum cases and refugee status.",
                "Stay updated on changes and developments in immigration laws, policies, and procedures.",
                "Collaborate with clients, government officials, and other legal professionals to achieve favorable outcomes for clients.",
                "Provide guidance and support to clients throughout the immigration process, ensuring compliance with all legal requirements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in immigration law is preferred.",
                "Previous experience as an Immigration Lawyer or related role is advantageous.",
                "Deep understanding of immigration laws, regulations, and procedures.",
                "Strong advocacy, negotiation, and communication skills.",
                "Ability to work effectively with clients from diverse backgrounds.",
                "Attention to detail and accuracy in legal work.",
                "Ability to handle multiple cases and prioritize tasks effectively."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The average salary range for Immigration Lawyers varies depending on factors such as experience, location, and employer. On average, Immigration Lawyers can expect to earn between $60,000 and $120,000 per year."
        },
        {
            heading: "Benefits",
            paragraph: "In addition to the salary, benefits for Immigration Lawyers may include health insurance, retirement plans, paid time off, professional development opportunities, and flexible work arrangements."
        }
    ]
};

export const RealEstateAttorneyJobDescription = {
    title: "Real Estate Attorney Job Description",
    jobRole: "A Real Estate Attorney specializes in providing legal advice and assistance related to real estate transactions, property laws, and regulations. They represent clients involved in buying, selling, leasing, or financing real estate properties. Real Estate Attorneys review and draft contracts, negotiate deals, conduct title searches, and ensure compliance with zoning and land use regulations.",
    preRead: "Use this Real Estate Attorney job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Real Estate Attorney, you will be responsible for providing legal counsel and representation to clients involved in real estate transactions and disputes. You will review contracts, conduct due diligence, and ensure compliance with real estate laws and regulations. This role requires strong legal expertise, negotiation skills, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and assistance to clients on real estate matters, including property transactions, leasing agreements, and zoning issues.",
                "Review and draft real estate contracts, agreements, and other legal documents.",
                "Conduct due diligence, title searches, and property assessments to identify potential legal issues or risks.",
                "Negotiate terms and conditions of real estate deals on behalf of clients.",
                "Ensure compliance with local, state, and federal real estate laws, regulations, and ordinances.",
                "Represent clients in real estate closings, hearings, and litigation proceedings.",
                "Collaborate with clients, real estate agents, lenders, and other stakeholders to facilitate smooth transactions.",
                "Stay updated on changes and developments in real estate laws, market trends, and industry practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in real estate law is preferred.",
                "Previous experience as a Real Estate Attorney or related role is advantageous.",
                "Deep understanding of real estate laws, contracts, and transactions.",
                "Strong negotiation, communication, and analytical skills.",
                "Ability to work effectively with clients, colleagues, and other stakeholders.",
                "Attention to detail and accuracy in legal work.",
                "Ability to handle multiple cases and prioritize tasks effectively."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The average salary range for Real Estate Attorneys varies depending on factors such as experience, location, and employer. On average, Real Estate Attorneys can expect to earn between $80,000 and $150,000 per year."
        },
        {
            heading: "Benefits",
            paragraph: "In addition to the salary, benefits for Real Estate Attorneys may include health insurance, retirement plans, paid time off, professional development opportunities, and flexible work arrangements."
        }
    ]
};

export const EnvironmentalLawyerJobDescription = {
    title: "Environmental Lawyer Job Description",
    jobRole: "An Environmental Lawyer specializes in providing legal advice and representation on matters related to environmental law and regulations. They advise clients, including corporations, government agencies, and nonprofit organizations, on compliance with environmental laws, regulations, and policies. Environmental Lawyers handle cases involving environmental litigation, land use, pollution control, and natural resource management.",
    preRead: "Use this Environmental Lawyer job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Environmental Lawyer, you will be responsible for providing legal counsel and representation to clients on environmental issues and regulations. You will advise clients on compliance with environmental laws, represent them in regulatory proceedings and litigation, and negotiate agreements related to environmental matters. This role requires strong legal expertise, analytical skills, and a commitment to environmental protection."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and guidance to clients on environmental laws, regulations, and compliance requirements.",
                "Conduct legal research and analysis on environmental issues and precedents.",
                "Prepare legal documents, including contracts, agreements, and regulatory filings, related to environmental matters.",
                "Represent clients in administrative hearings, regulatory proceedings, and litigation involving environmental issues.",
                "Negotiate settlements and agreements with government agencies, stakeholders, and opposing parties on behalf of clients.",
                "Advise clients on environmental impact assessments, land use planning, and natural resource management.",
                "Stay updated on changes and developments in environmental laws, regulations, and policies.",
                "Collaborate with other attorneys, experts, and consultants on complex environmental cases and projects."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in environmental law is preferred.",
                "Previous experience as an Environmental Lawyer or related role is advantageous.",
                "Deep understanding of environmental laws, regulations, and policies.",
                "Strong analytical, research, and writing skills.",
                "Excellent negotiation and communication skills.",
                "Ability to work effectively with clients, government agencies, and other stakeholders.",
                "Commitment to environmental protection and sustainability."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The average salary range for Environmental Lawyers varies depending on factors such as experience, location, and employer. On average, Environmental Lawyers can expect to earn between $70,000 and $150,000 per year."
        },
        {
            heading: "Benefits",
            paragraph: "In addition to the salary, benefits for Environmental Lawyers may include health insurance, retirement plans, paid time off, professional development opportunities, and flexible work arrangements."
        }
    ]
};


export const LaborAndEmploymentLawyerJobDescription = {
    title: "Labor and Employment Lawyer Job Description",
    jobRole: "A Labor and Employment Lawyer specializes in providing legal advice and representation on matters related to labor and employment law. They advise clients, including employers and employees, on issues such as workplace discrimination, wage and hour laws, employee benefits, labor union relations, and wrongful termination. Labor and Employment Lawyers handle cases involving employment litigation, arbitration, mediation, and regulatory compliance.",
    preRead: "Use this Labor and Employment Lawyer job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Labor and Employment Lawyer, you will be responsible for providing legal counsel and representation to clients on various labor and employment matters. You will advise clients on compliance with labor laws, represent them in employment-related disputes, negotiate employment contracts, and provide training on employment policies and regulations. This role requires strong legal expertise, advocacy skills, and knowledge of labor and employment laws."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and guidance to clients on labor and employment laws, regulations, and best practices.",
                "Draft, review, and negotiate employment contracts, agreements, and policies.",
                "Represent clients in employment-related litigation, arbitration, mediation, and administrative proceedings.",
                "Advise clients on workplace investigations, disciplinary actions, and termination procedures.",
                "Assist clients in resolving disputes with employees, unions, and regulatory agencies.",
                "Conduct legal research and analysis on labor and employment issues and precedents.",
                "Provide training and education to clients and their employees on labor laws, workplace rights, and compliance requirements.",
                "Stay updated on changes and developments in labor and employment laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in labor and employment law is preferred.",
                "Previous experience as a Labor and Employment Lawyer or related role is advantageous.",
                "Deep understanding of labor and employment laws, regulations, and court rulings.",
                "Strong advocacy, negotiation, and communication skills.",
                "Excellent research, writing, and analytical skills.",
                "Ability to work effectively with clients, colleagues, and opposing parties.",
                "Commitment to upholding labor rights and promoting fair employment practices."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The average salary range for Labor and Employment Lawyers varies depending on factors such as experience, location, and employer. On average, Labor and Employment Lawyers can expect to earn between $80,000 and $180,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans",
                "Paid time off",
                "Professional development opportunities",
                "Flexible work arrangements"
            ]
        }
    ]
};

export const FamilyLawyerJobDescription = {
    title: "Family Lawyer Job Description",
    jobRole: "A Family Lawyer specializes in providing legal services and representation to clients in matters related to family law. They handle cases involving divorce, child custody, adoption, domestic violence, property division, and other family-related legal issues. Family Lawyers work closely with clients to understand their needs, provide legal advice, negotiate settlements, and represent them in court proceedings.",
    preRead: "Use this Family Lawyer job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Family Lawyer, you will be responsible for representing clients in various family law matters, including divorce, child custody, alimony, and property division. You will work closely with clients to assess their legal needs, provide guidance on their rights and options, and advocate for their interests in legal proceedings. This role requires strong communication skills, empathy, and knowledge of family law statutes and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on matters related to family law.",
                "Handle divorce proceedings, including asset division, spousal support, and child custody matters.",
                "Negotiate settlements and agreements on behalf of clients.",
                "Represent clients in court hearings, mediation sessions, and arbitration proceedings.",
                "Assist clients with adoption, guardianship, and paternity matters.",
                "Draft legal documents, such as petitions, complaints, motions, and agreements.",
                "Conduct legal research and analysis on relevant statutes, case law, and legal precedents.",
                "Maintain client confidentiality and ethical standards in all interactions and representations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or certification in family law is preferred.",
                "Previous experience as a Family Lawyer or related role is advantageous.",
                "Comprehensive understanding of family law statutes, regulations, and court procedures.",
                "Strong negotiation, mediation, and conflict resolution skills.",
                "Excellent communication, interpersonal, and client management skills.",
                "Ability to work effectively under pressure and manage multiple cases simultaneously.",
                "Empathy, compassion, and sensitivity to clients' emotional needs and concerns."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Family Lawyers varies depending on factors such as experience, location, and employer. On average, Family Lawyers can expect to earn between $60,000 and $150,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans",
                "Paid time off",
                "Professional development opportunities",
                "Flexible work arrangements"
            ]
        }
    ]
};

export const CriminalDefenseAttorneyJobDescription = {
    title: "Criminal Defense Attorney Job Description",
    jobRole: "A Criminal Defense Attorney specializes in providing legal representation to individuals or organizations facing criminal charges. They defend clients in various criminal cases, including misdemeanors and felonies, and advocate for their rights throughout the legal process. Criminal Defense Attorneys conduct legal research, analyze evidence, negotiate plea deals, and represent clients in court proceedings.",
    preRead: "Use this Criminal Defense Attorney job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Criminal Defense Attorney, you will be responsible for defending clients accused of criminal offenses, including DUI/DWI, drug offenses, assault, theft, and other charges. You will work closely with clients to assess their legal options, develop defense strategies, and represent them in court proceedings. This role requires strong advocacy skills, legal expertise, and the ability to navigate complex criminal law statutes and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal counsel and representation to clients facing criminal charges.",
                "Conduct legal research and analysis on relevant statutes, case law, and legal precedents.",
                "Interview clients, witnesses, and law enforcement officials to gather evidence and information.",
                "Develop defense strategies and tactics to challenge prosecution evidence and arguments.",
                "Negotiate plea deals and settlements with prosecutors to minimize charges and penalties.",
                "Prepare legal documents, including motions, briefs, and appeals, for court proceedings.",
                "Represent clients in pre-trial hearings, trials, and sentencing proceedings.",
                "Advocate for clients' rights and interests throughout the legal process."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in criminal defense law is preferred.",
                "Proven track record of success in criminal defense cases.",
                "Thorough understanding of criminal law statutes, procedures, and court rules.",
                "Strong analytical, negotiation, and trial advocacy skills.",
                "Excellent communication, interpersonal, and client management abilities.",
                "Ability to work effectively under pressure and manage multiple cases simultaneously."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Healthcare coverage including medical, dental, and vision insurance",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including continuing legal education (CLE)",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Criminal Defense Attorneys varies depending on factors such as experience, location, and employer. On average, Criminal Defense Attorneys can expect to earn between $60,000 and $150,000 per year."
        }
    ]
};

export const HealthcareComplianceOfficerJobDescription = {
    title: "Healthcare Compliance Officer Job Description",
    jobRole: "A Healthcare Compliance Officer ensures that healthcare facilities and organizations comply with regulatory requirements and ethical standards. They develop, implement, and oversee compliance programs to prevent violations and promote adherence to laws and regulations governing healthcare operations. Healthcare Compliance Officers conduct audits, assess risks, and provide guidance to ensure ethical and lawful practices within the organization.",
    preRead: "Use this Healthcare Compliance Officer job description template to attract qualified candidates for your healthcare organization. Customize the responsibilities and qualifications to match your specific compliance needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Healthcare Compliance Officer, you will be responsible for ensuring that our healthcare organization complies with all relevant laws, regulations, and industry standards. You will develop, implement, and oversee compliance programs to prevent violations and promote ethical conduct. This role requires strong knowledge of healthcare regulations, excellent communication skills, and the ability to collaborate with internal stakeholders to maintain compliance."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement compliance policies, procedures, and protocols for the organization.",
                "Conduct regular audits and assessments to monitor compliance with healthcare laws and regulations.",
                "Provide guidance and training to staff on compliance-related matters, including HIPAA, Stark Law, and Anti-Kickback Statute.",
                "Investigate compliance violations, breaches, and complaints, and develop corrective action plans as needed.",
                "Collaborate with legal counsel and regulatory agencies on compliance issues and investigations.",
                "Monitor changes in healthcare laws and regulations and ensure timely updates to compliance programs.",
                "Maintain records of compliance activities, reports, and documentation.",
                "Develop and deliver compliance reports and presentations to senior management and governing bodies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Healthcare Administration, Business Administration, or related field; Master's degree preferred.",
                "Certification in Healthcare Compliance (CHC) or Certified in Healthcare Privacy Compliance (CHPC) preferred.",
                "Proven experience in healthcare compliance, regulatory affairs, or related field.",
                "Strong knowledge of healthcare laws and regulations, including HIPAA, Stark Law, and Anti-Kickback Statute.",
                "Excellent analytical, problem-solving, and decision-making skills.",
                "Effective communication and interpersonal skills, with the ability to collaborate with diverse stakeholders.",
                "Attention to detail and ability to work independently with minimal supervision.",
                "Ethical and professional conduct, with a commitment to upholding compliance standards."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Healthcare Compliance Officers varies depending on factors such as experience, location, and employer. On average, Healthcare Compliance Officers can expect to earn between $60,000 and $120,000 per year."
        }
    ]
};

export const CorporateComplianceSpecialistJobDescription = {
    title: "Corporate Compliance Specialist Job Description",
    jobRole: "A Corporate Compliance Specialist ensures that organizations comply with laws, regulations, and ethical standards governing their operations. They develop, implement, and manage compliance programs to prevent violations and promote ethical conduct within the organization. Corporate Compliance Specialists conduct audits, assess risks, and provide training and guidance to employees to maintain compliance with legal and regulatory requirements.",
    preRead: "Use this Corporate Compliance Specialist job description template to attract qualified candidates for your organization. Customize the responsibilities and qualifications to match your specific compliance needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Corporate Compliance Specialist, you will play a key role in ensuring that our organization operates in accordance with applicable laws, regulations, and industry standards. You will develop, implement, and oversee compliance programs to mitigate risks and promote ethical behavior. This role requires strong analytical skills, attention to detail, and the ability to communicate effectively with stakeholders at all levels of the organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop, implement, and manage compliance policies, procedures, and controls for the organization.",
                "Conduct regular compliance audits and assessments to identify areas of risk and non-compliance.",
                "Provide training and guidance to employees on compliance-related matters, including code of conduct, anti-corruption, and data privacy.",
                "Investigate compliance issues, breaches, and complaints, and develop corrective action plans as needed.",
                "Monitor changes in laws, regulations, and industry standards to ensure timely updates to compliance programs.",
                "Collaborate with legal counsel and regulatory agencies on compliance matters and investigations.",
                "Maintain accurate records of compliance activities, reports, and documentation.",
                "Prepare and deliver compliance reports and presentations to senior management and board of directors."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Finance, Legal Studies, or related field; Master's degree preferred.",
                "Certification in Compliance (e.g., Certified Compliance & Ethics Professional - CCEP) preferred.",
                "Proven experience in corporate compliance, risk management, or related field.",
                "Strong knowledge of laws, regulations, and industry standards applicable to the organization.",
                "Excellent analytical, problem-solving, and decision-making skills.",
                "Effective communication and interpersonal skills, with the ability to collaborate with diverse stakeholders.",
                "Attention to detail and ability to work independently with minimal supervision.",
                "Ethical and professional conduct, with a commitment to upholding compliance standards."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Corporate Compliance Specialists varies depending on factors such as experience, location, and employer. On average, Corporate Compliance Specialists can expect to earn between $60,000 and $120,000 per year."
        }
    ]
};

export const BankruptcyLawyerJobDescription = {
    title: "Bankruptcy Lawyer Job Description",
    jobRole: "A Bankruptcy Lawyer specializes in providing legal advice and representation to individuals or businesses facing financial difficulties. They assist clients in navigating bankruptcy proceedings, including filing for bankruptcy, negotiating with creditors, and developing debt repayment plans. Bankruptcy Lawyers advocate for their clients' interests in court and provide guidance on the best course of action to achieve financial relief and debt resolution.",
    preRead: "Use this Bankruptcy Lawyer job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Bankruptcy Lawyer, you will be responsible for representing individuals or businesses in bankruptcy proceedings. You will provide legal advice, develop strategies, and advocate for your clients' interests throughout the bankruptcy process. This role requires strong knowledge of bankruptcy laws, excellent negotiation skills, and the ability to work effectively under pressure."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients considering bankruptcy options.",
                "Assist clients in filing for bankruptcy and completing necessary documentation.",
                "Negotiate with creditors on behalf of clients to restructure debts and develop repayment plans.",
                "Represent clients in bankruptcy court proceedings, hearings, and negotiations.",
                "Advocate for clients' interests and rights throughout the bankruptcy process.",
                "Conduct legal research and analysis on bankruptcy laws, regulations, and precedents.",
                "Educate clients on their rights, obligations, and options under bankruptcy law.",
                "Collaborate with other legal professionals, including paralegals and support staff, to manage caseload and client matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in bankruptcy law is preferred.",
                "Proven track record of success in bankruptcy cases.",
                "Strong negotiation, communication, and advocacy skills.",
                "Excellent legal research and analytical abilities.",
                "Ability to work effectively under pressure and manage multiple cases simultaneously.",
                "Ethical and professional conduct, with a commitment to upholding clients' interests and rights."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Bankruptcy Lawyers varies depending on factors such as experience, location, and employer. On average, Bankruptcy Lawyers can expect to earn between $80,000 and $150,000 per year."
        }
    ]
};

export const CivilRightsAttorneyJobDescription = {
    title: "Civil Rights Attorney Job Description",
    jobRole: "A Civil Rights Attorney specializes in representing individuals or groups whose civil rights have been violated. They advocate for justice and equality by providing legal representation in cases involving discrimination, harassment, police misconduct, and other civil rights violations. Civil Rights Attorneys investigate claims, file lawsuits, and negotiate settlements to protect their clients' rights and seek remedies for damages.",
    preRead: "Use this Civil Rights Attorney job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Civil Rights Attorney, you will be responsible for advocating for individuals or groups whose civil rights have been infringed upon. You will provide legal representation, counsel, and support to clients in cases involving discrimination, harassment, and other civil rights violations. This role requires a passion for social justice, strong advocacy skills, and the ability to navigate complex legal issues."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on civil rights matters.",
                "Investigate claims of civil rights violations, including discrimination, harassment, and police misconduct.",
                "File lawsuits and legal complaints on behalf of clients to seek remedies for civil rights violations.",
                "Negotiate settlements with opposing parties to resolve civil rights disputes.",
                "Represent clients in court proceedings, hearings, and mediations.",
                "Conduct legal research and analysis on civil rights laws, regulations, and precedents.",
                "Educate clients on their rights, legal options, and potential outcomes.",
                "Collaborate with advocacy groups, community organizations, and other legal professionals to advance civil rights causes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in civil rights law is preferred.",
                "Proven track record of success in civil rights cases.",
                "Strong advocacy, negotiation, and litigation skills.",
                "Excellent legal research and writing abilities.",
                "Empathy, compassion, and a commitment to social justice.",
                "Ability to work effectively with clients from diverse backgrounds."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Civil Rights Attorneys varies depending on factors such as experience, location, and employer. On average, Civil Rights Attorneys can expect to earn between $70,000 and $130,000 per year."
        }
    ]
};

export const EstatePlanningLawyerJobDescription = {
    title: "Estate Planning Lawyer Job Description",
    jobRole: "An Estate Planning Lawyer specializes in assisting clients with the creation and implementation of estate plans to manage their assets and affairs during their lifetime and after death. They provide legal advice and services related to wills, trusts, powers of attorney, and other estate planning documents. Estate Planning Lawyers help clients minimize taxes, protect assets, and ensure their wishes are carried out according to their estate plan.",
    preRead: "Use this Estate Planning Lawyer job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Estate Planning Lawyer, you will work with individuals and families to create comprehensive estate plans that reflect their wishes and protect their assets. You will provide legal advice, draft estate planning documents, and assist clients in navigating complex legal and financial issues. This role requires strong communication skills, attention to detail, and a thorough understanding of estate planning laws and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on estate planning matters.",
                "Draft wills, trusts, powers of attorney, and other estate planning documents.",
                "Review and update existing estate plans to ensure they reflect clients' current wishes and circumstances.",
                "Advise clients on strategies to minimize estate taxes and maximize asset protection.",
                "Assist clients in selecting trustees, executors, and other fiduciaries to manage their affairs.",
                "Educate clients on the probate process and alternatives to probate, such as trusts.",
                "Address clients' concerns and questions regarding their estate plans and legal rights.",
                "Collaborate with other legal professionals, financial advisors, and estate planners to meet clients' needs."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in estate planning law is preferred.",
                "Knowledge of relevant laws, regulations, and tax implications related to estate planning.",
                "Strong drafting and legal writing skills.",
                "Excellent communication and interpersonal abilities.",
                "Attention to detail and accuracy in document preparation.",
                "Ability to work independently and prioritize tasks effectively."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Estate Planning Lawyers varies depending on factors such as experience, location, and employer. On average, Estate Planning Lawyers can expect to earn between $80,000 and $150,000 per year."
        }
    ]
};

export const InsuranceDefenseAttorneyJobDescription = {
    title: "Insurance Defense Attorney Job Description",
    jobRole: "An Insurance Defense Attorney represents insurance companies and their policyholders in legal disputes related to insurance claims. They defend clients against claims of negligence, liability, or wrongdoing and work to minimize financial losses for insurance companies. Insurance Defense Attorneys handle a variety of cases, including personal injury claims, property damage claims, and professional liability claims.",
    preRead: "Use this Insurance Defense Attorney job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Insurance Defense Attorney, you will be responsible for defending insurance companies and policyholders in legal matters involving insurance claims. You will provide legal advice, conduct investigations, and represent clients in court proceedings and negotiations. This role requires strong litigation skills, knowledge of insurance laws, and the ability to assess legal risks and develop effective defense strategies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal representation to insurance companies and policyholders in insurance claim disputes.",
                "Review insurance policies, contracts, and claim documentation to assess liability and coverage issues.",
                "Investigate claims, gather evidence, and interview witnesses to build a defense case.",
                "Develop and implement defense strategies to protect clients' interests and minimize financial exposure.",
                "Negotiate settlements with claimants or their legal representatives to resolve disputes.",
                "Represent clients in court proceedings, hearings, and mediations.",
                "Prepare and present legal arguments, motions, and briefs in support of clients' positions.",
                "Collaborate with insurance adjusters, investigators, and other experts to evaluate claims and assess damages."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in insurance defense law is preferred.",
                "Knowledge of insurance laws, regulations, and industry practices.",
                "Strong litigation and trial advocacy skills.",
                "Excellent legal research, writing, and analytical abilities.",
                "Ability to work independently and manage a caseload effectively.",
                "Effective communication and negotiation skills."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Insurance Defense Attorneys varies depending on factors such as experience, location, and employer. On average, Insurance Defense Attorneys can expect to earn between $90,000 and $170,000 per year."
        }
    ]
};

export const WorkersCompensationAttorneyJobDescription = {
    title: "Workers' Compensation Attorney Job Description",
    jobRole: "A Workers' Compensation Attorney specializes in representing employees who have been injured or disabled in the workplace. They provide legal advice and advocacy to help injured workers obtain compensation for medical expenses, lost wages, and other benefits under workers' compensation laws. Workers' Compensation Attorneys handle claims from initial filing through appeal, advocating for their clients' rights and interests throughout the process.",
    preRead: "Use this Workers' Compensation Attorney job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Workers' Compensation Attorney, you will advocate for the rights of injured workers and help them navigate the complex process of filing and pursuing workers' compensation claims. You will provide legal representation, negotiate settlements, and advocate for clients in hearings and appeals. This role requires strong advocacy skills, knowledge of workers' compensation laws, and the ability to effectively communicate with clients and other stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and representation to injured workers seeking workers' compensation benefits.",
                "Assist clients with filing initial workers' compensation claims and gathering necessary documentation.",
                "Investigate workplace accidents, injuries, and occupational illnesses to establish liability and damages.",
                "Negotiate settlements with insurance companies on behalf of injured workers.",
                "Prepare and present cases at workers' compensation hearings and appeals.",
                "Advocate for clients' rights and interests throughout the claims process.",
                "Educate clients on their legal rights and options under workers' compensation laws.",
                "Collaborate with medical experts, vocational rehabilitation specialists, and other professionals to support clients' claims."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in workers' compensation law is preferred.",
                "Knowledge of workers' compensation laws, regulations, and procedures.",
                "Strong negotiation, advocacy, and trial advocacy skills.",
                "Excellent communication and interpersonal abilities.",
                "Ability to work independently and manage a caseload effectively.",
                "Compassion and empathy for injured workers and their families."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Workers' Compensation Attorneys varies depending on factors such as experience, location, and employer. On average, Workers' Compensation Attorneys can expect to earn between $70,000 and $130,000 per year."
        }
    ]
};

export const SecuritiesLawyerJobDescription = {
    title: "Securities Lawyer Job Description",
    jobRole: "A Securities Lawyer specializes in providing legal advice and representation to clients in matters related to securities regulation and compliance. They advise companies on securities laws and regulations, help them navigate complex regulatory requirements, and ensure compliance with applicable securities regulations. Securities Lawyers also assist clients in securities offerings, mergers and acquisitions, and other transactions involving securities.",
    preRead: "Use this Securities Lawyer job description template to attract qualified candidates for your law firm or legal practice. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Securities Lawyer, you will advise clients on legal issues related to securities regulation, compliance, and transactions. You will provide guidance on securities offerings, disclosure requirements, corporate governance, and regulatory filings. This role requires a strong understanding of securities laws and regulations, excellent analytical and communication skills, and the ability to work effectively in a fast-paced, dynamic environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on securities laws, regulations, and compliance matters.",
                "Assist companies with securities offerings, including initial public offerings (IPOs), private placements, and secondary offerings.",
                "Draft and review securities offering documents, including prospectuses, registration statements, and private placement memoranda.",
                "Advise clients on corporate governance best practices and compliance with securities exchange rules and regulations.",
                "Conduct due diligence reviews for securities transactions, mergers and acquisitions, and other corporate transactions.",
                "Assist clients in responding to regulatory inquiries, investigations, and enforcement actions by securities regulators.",
                "Stay informed about developments in securities laws and regulations and advise clients on potential impacts and implications.",
                "Negotiate and draft contracts, agreements, and other legal documents related to securities transactions and regulatory matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in securities law is preferred.",
                "Knowledge of federal and state securities laws, regulations, and regulatory agencies.",
                "Strong analytical, research, and problem-solving skills.",
                "Excellent written and verbal communication abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in legal analysis and document preparation."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Securities Lawyers varies depending on factors such as experience, location, and employer. On average, Securities Lawyers can expect to earn between $100,000 and $200,000 per year."
        }
    ]
};

export const GovernmentLawyerJobDescription = {
    title: "Government Lawyer Job Description",
    jobRole: "A Government Lawyer provides legal advice and representation to government agencies, departments, or entities. They handle a wide range of legal matters, including regulatory compliance, litigation, contract negotiation, and policy development. Government Lawyers work closely with government officials, legislators, and other stakeholders to ensure compliance with laws and regulations and to protect the interests of the government.",
    preRead: "Use this Government Lawyer job description template to attract qualified candidates for legal positions within government agencies or departments. Customize the responsibilities and qualifications to match the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Government Lawyer, you will serve as legal counsel to government agencies or departments, providing advice on legal matters and representing the government in legal proceedings. You will draft and review legal documents, interpret laws and regulations, and provide guidance on compliance issues. This role requires a strong understanding of government operations, laws, and regulations, as well as excellent analytical, communication, and advocacy skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to government agencies or departments on a wide range of legal matters.",
                "Draft and review legal documents, including contracts, regulations, policies, and legislation.",
                "Represent the government in administrative hearings, negotiations, and litigation proceedings.",
                "Conduct legal research and analysis to support government decision-making and policy development.",
                "Advise government officials and employees on legal issues, ethical considerations, and risk management.",
                "Participate in legislative and regulatory processes by drafting proposed legislation and regulations, and providing legal analysis and testimony.",
                "Monitor and assess legal developments, court decisions, and legislative changes affecting government operations.",
                "Collaborate with other government agencies, external stakeholders, and legal professionals on legal matters and initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Experience working as a lawyer in government, public service, or related fields.",
                "Knowledge of government operations, laws, regulations, and procedures.",
                "Strong analytical, research, and problem-solving skills.",
                "Excellent written and verbal communication abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Integrity, professionalism, and commitment to public service."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Government Lawyers varies depending on factors such as experience, location, and level of government. On average, Government Lawyers can expect to earn between $60,000 and $150,000 per year."
        }
    ]
};

export const BankingAndFinanceLawyerJobDescription = {
    title: "Banking and Finance Lawyer Job Description",
    jobRole: "A Banking and Finance Lawyer specializes in providing legal advice and representation to clients in the banking and financial services industry. They advise banks, financial institutions, corporations, and individuals on a wide range of legal matters related to banking, finance, and securities regulation. Banking and Finance Lawyers handle transactions such as loan agreements, mergers and acquisitions, securities offerings, and regulatory compliance.",
    preRead: "Use this Banking and Finance Lawyer job description template to attract qualified candidates for legal positions in banking and finance law firms, corporate legal departments, or government agencies. Customize the responsibilities and qualifications to match your specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Banking and Finance Lawyer, you will provide legal advice and counsel to clients in the banking and financial services industry. You will handle a variety of legal matters, including transactional work, regulatory compliance, and litigation. This role requires a strong understanding of banking and finance laws and regulations, excellent analytical and negotiation skills, and the ability to work effectively in a fast-paced, dynamic environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and representation to clients in banking and finance transactions and matters.",
                "Draft, review, and negotiate loan agreements, financing documents, and other financial instruments.",
                "Advise clients on regulatory compliance issues related to banking, finance, and securities laws.",
                "Assist clients with mergers and acquisitions, corporate finance transactions, and securities offerings.",
                "Conduct due diligence reviews for banking and finance transactions and regulatory compliance.",
                "Represent clients in banking and finance-related litigation, arbitration, and regulatory proceedings.",
                "Stay informed about developments in banking and finance laws, regulations, and industry trends.",
                "Collaborate with other legal professionals, financial advisors, and stakeholders on legal matters and initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in banking and finance law is preferred.",
                "Knowledge of banking, finance, and securities laws, regulations, and industry standards.",
                "Strong analytical, research, and problem-solving skills.",
                "Excellent negotiation and communication abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in legal analysis and document preparation."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Banking and Finance Lawyers varies depending on factors such as experience, location, and employer. On average, Banking and Finance Lawyers can expect to earn between $100,000 and $250,000 per year."
        }
    ]
};

export const PrivacyLawyerJobDescription = {
    title: "Privacy Lawyer Job Description",
    jobRole: "A Privacy Lawyer specializes in providing legal advice and guidance on matters related to privacy, data protection, and information security. They help organizations navigate complex privacy laws and regulations to ensure compliance and protect sensitive information. Privacy Lawyers advise on issues such as data collection, storage, sharing, and breach response. They also assist with drafting privacy policies, conducting privacy impact assessments, and negotiating privacy-related contracts.",
    preRead: "Use this Privacy Lawyer job description template to attract qualified candidates for legal positions focusing on privacy law and data protection. Customize the responsibilities and qualifications to match your organization's specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Privacy Lawyer, you will advise clients on legal matters related to privacy, data protection, and information security. You will help organizations develop and implement privacy compliance programs, policies, and procedures. This role requires a deep understanding of privacy laws and regulations, excellent analytical and communication skills, and the ability to navigate complex legal and technical issues."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel on privacy, data protection, and information security matters.",
                "Assist organizations with developing and implementing privacy compliance programs, policies, and procedures.",
                "Draft and review privacy policies, terms of service, data processing agreements, and other privacy-related documents.",
                "Conduct privacy impact assessments (PIAs) and assist with regulatory filings and notifications.",
                "Advise on data collection, use, sharing, and retention practices to ensure compliance with privacy laws and regulations.",
                "Assist with responding to data breaches, conducting investigations, and coordinating with regulators and law enforcement.",
                "Stay informed about developments in privacy laws, regulations, and industry best practices.",
                "Educate clients and internal stakeholders on privacy-related legal requirements and risks."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in privacy law, data protection, or information security.",
                "Knowledge of privacy laws and regulations, including GDPR, CCPA, and other relevant standards.",
                "Strong analytical, research, and problem-solving skills.",
                "Excellent written and verbal communication abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in legal analysis and document drafting."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Privacy Lawyers varies depending on factors such as experience, location, and employer. On average, Privacy Lawyers can expect to earn between $80,000 and $200,000 per year."
        }
    ]
};

export const AntitrustLawyerJobDescription = {
    title: "Antitrust Lawyer Job Description",
    jobRole: "An Antitrust Lawyer specializes in providing legal advice and representation on matters related to antitrust and competition law. They help clients navigate antitrust regulations and enforcement actions, advise on mergers and acquisitions, and represent clients in antitrust litigation and investigations. Antitrust Lawyers work with corporations, government agencies, and industry associations to ensure compliance with antitrust laws and regulations and to protect competition in the marketplace.",
    preRead: "Use this Antitrust Lawyer job description template to attract qualified candidates for legal positions focusing on antitrust and competition law. Customize the responsibilities and qualifications to match your organization's specific legal needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Antitrust Lawyer, you will provide legal advice and representation on matters related to antitrust and competition law. You will help clients navigate complex antitrust regulations, assess the competitive impact of mergers and acquisitions, and represent clients in antitrust litigation and investigations. This role requires a deep understanding of antitrust laws and regulations, strong analytical and advocacy skills, and the ability to work effectively in a dynamic and challenging legal environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel on antitrust and competition law matters.",
                "Assist clients with antitrust compliance, including assessing the competitive impact of mergers, acquisitions, and business practices.",
                "Represent clients in antitrust litigation, arbitration, and regulatory investigations.",
                "Conduct legal research and analysis to support antitrust counseling and litigation strategies.",
                "Advise on antitrust aspects of commercial agreements, joint ventures, and strategic alliances.",
                "Monitor and analyze developments in antitrust laws, regulations, and enforcement actions.",
                "Collaborate with clients, government agencies, and industry stakeholders on antitrust-related initiatives and advocacy efforts.",
                "Provide antitrust training and education to clients and internal stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Specialization or experience in antitrust and competition law.",
                "Knowledge of antitrust laws and regulations, including the Sherman Act, Clayton Act, and FTC Act.",
                "Strong analytical, research, and problem-solving skills.",
                "Excellent written and verbal communication abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in legal analysis and document drafting."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Antitrust Lawyers varies depending on factors such as experience, location, and employer. On average, Antitrust Lawyers can expect to earn between $100,000 and $250,000 per year."
        }
    ]
};

export const LegalResearcherJobDescription = {
    title: "Legal Researcher Job Description",
    jobRole: "A Legal Researcher conducts in-depth legal research and analysis to support legal proceedings, regulatory compliance, and decision-making. They gather and analyze legal precedents, statutes, regulations, and case law to provide insights and recommendations to attorneys, policymakers, and other stakeholders. Legal Researchers use a variety of resources, including online databases, libraries, and archives, to gather relevant legal information and synthesize complex legal issues.",
    preRead: "Use this Legal Researcher job description template to attract qualified candidates for legal research positions in your organization. Customize the responsibilities and qualifications to match your specific legal research needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Researcher, you will be responsible for conducting legal research and analysis to support legal proceedings, regulatory compliance, and decision-making. You will gather and analyze legal sources such as statutes, regulations, case law, and legal literature to provide insights and recommendations to attorneys, policymakers, and other stakeholders. This role requires strong research, analytical, and writing skills, as well as a solid understanding of legal principles and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct legal research and analysis on a wide range of legal issues and topics.",
                "Gather and analyze legal sources, including statutes, regulations, case law, and legal literature.",
                "Prepare legal memoranda, briefs, and other written documents summarizing research findings and recommendations.",
                "Assist attorneys and other stakeholders with legal strategy, case preparation, and regulatory compliance.",
                "Monitor legal developments and trends, and provide updates and analysis to internal stakeholders.",
                "Maintain accurate and up-to-date legal research databases and libraries.",
                "Collaborate with attorneys, paralegals, and other legal professionals on research projects and initiatives.",
                "Assist with drafting and reviewing legal documents, contracts, and agreements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Law, Legal Studies, or related field; advanced degree (e.g., J.D., LL.M.) preferred.",
                "Strong research, analytical, and critical thinking skills.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in legal research tools and databases, such as Westlaw, LexisNexis, and Bloomberg Law.",
                "Familiarity with legal writing conventions and citation formats.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Attention to detail and accuracy in legal analysis and document drafting."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Researchers varies depending on factors such as experience, location, and employer. On average, Legal Researchers can expect to earn between $50,000 and $100,000 per year."
        }
    ]
};

export const ArbitratorJobDescription = {
    title: "Arbitrator Job Description",
    jobRole: "An Arbitrator facilitates alternative dispute resolution processes by serving as a neutral third party to resolve legal conflicts outside of the courtroom. They conduct hearings, review evidence, and make decisions or recommendations to settle disputes between parties. Arbitrators may specialize in various areas of law, such as commercial arbitration, labor arbitration, or international arbitration, and they play a crucial role in resolving legal conflicts efficiently and impartially.",
    preRead: "Use this Arbitrator job description template to attract qualified candidates for arbitration and dispute resolution positions in your organization. Customize the responsibilities and qualifications to match your specific arbitration needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Arbitrator, you will serve as a neutral third party to facilitate alternative dispute resolution processes and help parties reach mutually acceptable resolutions to their legal conflicts. You will conduct hearings, review evidence, and render decisions or recommendations to settle disputes outside of the courtroom. This role requires strong communication, negotiation, and conflict resolution skills, as well as a thorough understanding of relevant legal principles and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct arbitration hearings and proceedings in accordance with applicable laws, rules, and procedures.",
                "Review evidence, legal arguments, and other materials submitted by parties to the arbitration.",
                "Listen to testimony, question witnesses, and evaluate the credibility and relevance of evidence presented.",
                "Render decisions or recommendations to resolve disputes and issues raised by the parties.",
                "Ensure that arbitration proceedings are conducted fairly, impartially, and efficiently.",
                "Maintain accurate records of arbitration hearings, decisions, and awards.",
                "Facilitate settlement negotiations and encourage parties to reach mutually acceptable resolutions.",
                "Communicate arbitration decisions and awards to parties in a clear and timely manner."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school; active license to practice law preferred.",
                "Certification or training in arbitration or alternative dispute resolution (ADR) preferred.",
                "Extensive experience in legal practice, litigation, or dispute resolution.",
                "Strong communication, negotiation, and conflict resolution skills.",
                "Impartiality, fairness, and integrity in conducting arbitration proceedings.",
                "Ability to analyze complex legal issues and make reasoned decisions.",
                "Attention to detail and accuracy in documenting arbitration proceedings and decisions."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Arbitrators varies depending on factors such as experience, location, and employer. On average, Arbitrators can expect to earn between $80,000 and $150,000 per year."
        }
    ]
};

export const MediatorJobDescription = {
    title: "Mediator Job Description",
    jobRole: "A Mediator facilitates negotiations and helps parties resolve disputes through mediation, a voluntary and confidential process aimed at finding mutually acceptable solutions. Mediators remain neutral and impartial, guiding parties through constructive dialogue, identifying common interests, and facilitating communication to reach agreements. Mediators may specialize in various areas, including family mediation, workplace mediation, or community mediation, and they play a vital role in promoting peaceful resolution of conflicts.",
    preRead: "Use this Mediator job description template to attract qualified candidates for mediation and conflict resolution positions in your organization. Customize the responsibilities and qualifications to match your specific mediation needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mediator, you will facilitate negotiations and assist parties in resolving disputes through mediation, a voluntary and confidential process designed to find mutually acceptable solutions. You will remain neutral and impartial, guiding parties through constructive dialogue, helping them identify common interests, and facilitating communication to reach agreements. This role requires strong communication, negotiation, and conflict resolution skills, as well as a commitment to promoting peaceful resolution of conflicts."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct mediation sessions and facilitate negotiations between parties to resolve disputes.",
                "Create a supportive and collaborative environment conducive to productive dialogue and problem-solving.",
                "Listen actively to parties' concerns, interests, and perspectives, and help them communicate effectively.",
                "Identify common ground and areas of agreement, and assist parties in exploring options for resolution.",
                "Remain neutral and impartial throughout the mediation process, refraining from providing legal advice or taking sides.",
                "Help parties generate creative solutions and draft written agreements reflecting their mutual decisions.",
                "Maintain confidentiality and uphold ethical standards in accordance with mediation guidelines and principles.",
                "Provide information and resources to parties to help them make informed decisions and move forward collaboratively."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Law, Conflict Resolution, Psychology, or related field; advanced degree or certification in mediation preferred.",
                "Training or experience in mediation, conflict resolution, or alternative dispute resolution (ADR).",
                "Strong communication, negotiation, and interpersonal skills.",
                "Empathy, patience, and cultural sensitivity in working with diverse parties and issues.",
                "Ability to remain neutral, impartial, and calm under pressure.",
                "Problem-solving skills and the ability to facilitate constructive dialogue and consensus-building.",
                "Knowledge of legal principles, ethics, and confidentiality requirements in mediation.",
                "Experience working in conflict resolution, counseling, social work, or related fields is advantageous."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Mediators varies depending on factors such as experience, location, and employer. On average, Mediators can expect to earn between $50,000 and $100,000 per year."
        }
    ]
};

export const LegalInvestigatorJobDescription = {
    title: "Legal Investigator Job Description",
    jobRole: "A Legal Investigator conducts research, gathers evidence, and analyzes facts to support legal proceedings, investigations, and litigation. They interview witnesses, gather documents, and conduct surveillance to gather relevant information for attorneys, law enforcement agencies, or corporate clients. Legal Investigators may specialize in various areas, such as criminal investigations, civil litigation, insurance fraud, or corporate due diligence, and they play a crucial role in uncovering facts and preparing cases for legal action.",
    preRead: "Use this Legal Investigator job description template to attract qualified candidates for investigative and research positions in your organization. Customize the responsibilities and qualifications to match your specific legal investigation needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Investigator, you will conduct research, gather evidence, and analyze facts to support legal proceedings, investigations, and litigation. You will interview witnesses, gather documents, and conduct surveillance to gather relevant information for attorneys, law enforcement agencies, or corporate clients. This role requires strong investigative, analytical, and communication skills, as well as a keen attention to detail and knowledge of legal procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct research and gather evidence to support legal cases, investigations, or litigation.",
                "Interview witnesses, clients, and other relevant parties to collect information and statements.",
                "Review and analyze documents, records, and other sources of information to uncover relevant facts and patterns.",
                "Conduct surveillance and gather photographic or video evidence as needed.",
                "Prepare detailed investigative reports summarizing findings, conclusions, and recommendations.",
                "Assist attorneys, law enforcement agencies, or corporate clients with case preparation, trial strategy, and evidence presentation.",
                "Testify in court or other legal proceedings as an expert witness or fact witness.",
                "Maintain confidentiality and adhere to ethical standards in conducting investigations and handling sensitive information."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Criminal Justice, Law, or related field; advanced degree or relevant certification preferred.",
                "Experience in investigative work, law enforcement, or related fields.",
                "Knowledge of investigative techniques, legal procedures, and rules of evidence.",
                "Strong analytical and critical thinking skills, with the ability to evaluate complex information and draw conclusions.",
                "Excellent communication and interpersonal skills, with the ability to interview witnesses and gather information effectively.",
                "Attention to detail and accuracy in documenting investigative findings and preparing reports.",
                "Ability to work independently, manage multiple tasks, and meet deadlines.",
                "Familiarity with legal research tools, databases, and software is advantageous."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including training and certifications",
                "Flexible work schedule and remote work options",
                "Employee assistance program (EAP) for mental health and wellness support"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Investigators varies depending on factors such as experience, location, and employer. On average, Legal Investigators can expect to earn between $40,000 and $80,000 per year."
        }
    ]
};

export const JudicialClerkJobDescription = {
    title: "Judicial Clerk Job Description",
    jobRole: "A Judicial Clerk provides administrative and research support to judges and justices in various court settings, including federal, state, and appellate courts. They assist with legal research, draft court opinions, manage case files, and coordinate court proceedings. Judicial Clerks play a crucial role in ensuring the efficient operation of the court system and supporting judges in their decision-making process.",
    preRead: "Use this Judicial Clerk job description template to attract qualified candidates for clerkship positions in your court or legal organization. Customize the responsibilities and qualifications to match your specific clerkship needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Judicial Clerk, you will provide administrative and research support to judges and justices in various court settings. You will assist with legal research, draft court opinions, manage case files, and coordinate court proceedings. This role requires strong organizational, analytical, and communication skills, as well as a keen attention to detail and knowledge of legal procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct legal research and analysis on issues before the court, including statutes, case law, and legal precedents.",
                "Draft court opinions, orders, and memoranda for judges and justices based on research findings.",
                "Manage case files, including organizing documents, updating case information, and ensuring compliance with court rules.",
                "Coordinate court proceedings, including scheduling hearings, notifying parties, and managing courtroom logistics.",
                "Assist judges and justices in preparing for hearings, trials, and other court proceedings.",
                "Provide administrative support, such as answering phones, responding to inquiries, and maintaining court records.",
                "Collaborate with court staff, attorneys, and other stakeholders to facilitate the resolution of legal matters.",
                "Maintain confidentiality and adhere to ethical standards in handling sensitive information and court proceedings."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Law, Paralegal Studies, or related field; Juris Doctor (J.D.) degree or legal certification preferred.",
                "Experience working in a legal environment, such as a law firm, court, or legal department.",
                "Strong research and analytical skills, with the ability to interpret and apply legal principles and precedents.",
                "Excellent written and verbal communication skills, with the ability to draft legal documents and communicate effectively with judges, attorneys, and court personnel.",
                "Organizational skills and attention to detail in managing case files, court records, and administrative tasks.",
                "Ability to work independently, prioritize tasks, and meet deadlines in a fast-paced environment.",
                "Knowledge of court procedures, rules of evidence, and legal standards applicable to court proceedings.",
                "Proficiency in legal research tools, databases, and software applications is advantageous."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including legal education and training",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the court system or legal profession"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Judicial Clerks varies depending on factors such as court jurisdiction, level of experience, and location. On average, Judicial Clerks can expect to earn between $40,000 and $70,000 per year."
        }
    ]
};

export const LawFirmAdministratorJobDescription = {
    title: "Law Firm Administrator Job Description",
    jobRole: "A Law Firm Administrator oversees the administrative and operational functions of a law firm, including office management, human resources, finance, and technology. They manage day-to-day operations, ensure compliance with legal regulations and firm policies, and support attorneys and staff in delivering high-quality legal services to clients. Law Firm Administrators play a critical role in the efficient operation and growth of law firms.",
    preRead: "Use this Law Firm Administrator job description template to attract qualified candidates for administrative leadership positions in your law firm. Customize the responsibilities and qualifications to match your specific firm needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Law Firm Administrator, you will oversee the administrative and operational functions of a law firm, including office management, human resources, finance, and technology. You will manage day-to-day operations, ensure compliance with legal regulations and firm policies, and support attorneys and staff in delivering high-quality legal services to clients. This role requires strong leadership, organizational, and communication skills, as well as a thorough understanding of law firm operations and business management principles."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage day-to-day operations of the law firm, including office facilities, technology systems, and administrative staff.",
                "Develop and implement firm policies, procedures, and guidelines to ensure compliance with legal regulations and industry standards.",
                "Oversee human resources functions, including recruitment, hiring, onboarding, performance management, and employee relations.",
                "Manage financial operations, including budgeting, billing, accounts payable/receivable, and financial reporting.",
                "Collaborate with attorneys and practice groups to support business development, client relations, and marketing initiatives.",
                "Negotiate and manage vendor contracts and relationships for services and supplies.",
                "Implement technology solutions and tools to streamline firm operations, improve efficiency, and enhance client service delivery.",
                "Stay updated on industry trends, regulatory changes, and best practices in law firm management and administration."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Law, or related field; Master of Business Administration (MBA) or Certified Legal Manager (CLM) designation preferred.",
                "Proven experience in law firm management, legal administration, or related roles.",
                "Strong leadership and management skills, with the ability to motivate and lead a diverse team of professionals.",
                "Excellent organizational and multitasking abilities, with the ability to prioritize tasks and meet deadlines in a fast-paced environment.",
                "Knowledge of legal regulations, ethics, and compliance requirements applicable to law firms.",
                "Proficiency in office management software, financial management systems, and legal technology tools.",
                "Effective communication and interpersonal skills, with the ability to collaborate with attorneys, staff, clients, and external stakeholders.",
                "Problem-solving skills and strategic thinking in identifying and addressing operational challenges and opportunities."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including management training and certifications",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the law firm or legal industry"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Law Firm Administrators varies depending on factors such as firm size, location, and level of experience. On average, Law Firm Administrators can expect to earn between $60,000 and $120,000 per year."
        }
    ]
};


export const RegulatoryAffairsSpecialistJobDescription = {
    title: "Regulatory Affairs Specialist Job Description",
    jobRole: "A Regulatory Affairs Specialist is responsible for ensuring that products, processes, and operations comply with regulatory requirements and standards set by government agencies and regulatory bodies. They collaborate with cross-functional teams to navigate regulatory pathways, obtain approvals, and maintain compliance throughout the product lifecycle. Regulatory Affairs Specialists play a crucial role in enabling companies to bring safe and effective products to market while meeting regulatory obligations.",
    preRead: "Use this Regulatory Affairs Specialist job description template to attract qualified candidates for regulatory compliance positions in your company. Customize the responsibilities and qualifications to align with your organization's specific regulatory needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Regulatory Affairs Specialist, you will ensure that products, processes, and operations comply with regulatory requirements and standards set by government agencies and regulatory bodies. You will collaborate with cross-functional teams to navigate regulatory pathways, obtain approvals, and maintain compliance throughout the product lifecycle. This role requires strong attention to detail, analytical skills, and knowledge of regulatory frameworks and requirements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Monitor and interpret regulatory requirements and changes applicable to products, processes, and operations.",
                "Prepare and submit regulatory filings, submissions, and documentation to obtain approvals and maintain compliance.",
                "Collaborate with cross-functional teams to develop regulatory strategies and plans for product development, registration, and commercialization.",
                "Conduct regulatory assessments and risk evaluations to identify compliance gaps and develop mitigation plans.",
                "Provide regulatory guidance and support to internal stakeholders on matters related to product development, quality assurance, and manufacturing.",
                "Interact with regulatory agencies and notified bodies to address inquiries, inspections, and audits.",
                "Maintain regulatory documentation, records, and databases to ensure accuracy, completeness, and accessibility.",
                "Stay updated on industry trends, best practices, and regulatory developments to inform decision-making and strategy."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Regulatory Affairs, Life Sciences, Pharmacy, or related field; advanced degree or certification is advantageous.",
                "Proven experience in regulatory affairs, quality assurance, or related roles within the healthcare, pharmaceutical, medical device, or biotechnology industry.",
                "Knowledge of regulatory frameworks and requirements, including FDA regulations, EU directives, and international standards (e.g., ISO, ICH).",
                "Strong analytical and problem-solving skills, with the ability to interpret and apply regulatory requirements to products and processes.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with internal teams and external stakeholders.",
                "Detail-oriented and organized approach to managing regulatory documentation, submissions, and compliance activities.",
                "Ability to work independently, prioritize tasks, and meet deadlines in a fast-paced environment.",
                "Proficiency in regulatory software tools, databases, and document management systems is advantageous."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including regulatory training and certifications",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the regulatory affairs profession or related industries"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Regulatory Affairs Specialists varies depending on factors such as industry, experience, and location. On average, Regulatory Affairs Specialists can expect to earn between $60,000 and $100,000 per year."
        }
    ]
};

export const PatentAttorneyJobDescription = {
    title: "Patent Attorney Job Description",
    jobRole: "A Patent Attorney specializes in intellectual property law and is responsible for advising clients on patent matters, conducting patent searches, drafting patent applications, and prosecuting patents before patent offices. They provide legal counsel on patent infringement, licensing, and litigation, and help clients protect their inventions and innovations through patents. Patent Attorneys play a crucial role in safeguarding intellectual property rights and supporting innovation across various industries.",
    preRead: "Use this Patent Attorney job description template to attract qualified candidates for intellectual property law positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific patent law needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Patent Attorney, you will specialize in intellectual property law and advise clients on patent matters, conduct patent searches, draft patent applications, and prosecute patents before patent offices. You will provide legal counsel on patent infringement, licensing, and litigation, and help clients protect their inventions and innovations through patents. This role requires strong legal expertise, analytical skills, and technical knowledge of patent law and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on patent matters, including patentability, infringement, validity, and enforcement.",
                "Conduct patent searches and analyses to assess the patentability of inventions and evaluate prior art.",
                "Draft and prosecute patent applications before patent offices, including preparing responses to office actions and amendments.",
                "Manage patent portfolios, including filing, maintenance, and enforcement of patents domestically and internationally.",
                "Negotiate and draft patent license agreements, assignments, and other intellectual property transactions.",
                "Assist clients in patent litigation matters, including pre-litigation counseling, discovery, and settlement negotiations.",
                "Stay updated on patent laws, regulations, and case law developments to provide informed legal advice and support.",
                "Collaborate with inventors, engineers, and technical experts to understand inventions and innovations for patent drafting and prosecution."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school; admission to the state bar as a practicing attorney.",
                "Registration to practice before the United States Patent and Trademark Office (USPTO) as a patent attorney or agent.",
                "Specialization or experience in intellectual property law, with a focus on patent prosecution and litigation.",
                "Strong technical background in a relevant field such as engineering, computer science, or life sciences.",
                "Excellent legal research, writing, and analytical skills, with the ability to interpret and apply complex patent laws and regulations.",
                "Effective communication and negotiation skills, with the ability to interact professionally with clients, colleagues, and patent examiners.",
                "Detail-oriented and organized approach to managing patent matters, including drafting, prosecution, and portfolio management.",
                "Ability to work independently, prioritize tasks, and meet deadlines in a fast-paced legal environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including legal education and training",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal profession or intellectual property field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Patent Attorneys varies depending on factors such as experience, specialization, and location. On average, Patent Attorneys can expect to earn between $100,000 and $200,000 per year."
        }
    ]
};

export const TrademarkAttorneyJobDescription = {
    title: "Trademark Attorney Job Description",
    jobRole: "A Trademark Attorney specializes in intellectual property law, focusing on the protection of trademarks and brand identity. They advise clients on trademark selection, conduct searches, file trademark applications, and handle trademark opposition and infringement cases. Trademark Attorneys help clients protect their brand assets and navigate the legal complexities of trademark law.",
    preRead: "Use this Trademark Attorney job description template to attract qualified candidates for intellectual property law positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific trademark law needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Trademark Attorney, you will specialize in intellectual property law, focusing on the protection of trademarks and brand identity. You will advise clients on trademark selection, conduct searches, file trademark applications, and handle trademark opposition and infringement cases. This role requires strong legal expertise, analytical skills, and a deep understanding of trademark law and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on trademark selection, registration, and protection.",
                "Conduct trademark searches and analyses to assess the availability and registrability of trademarks.",
                "Draft, file, and prosecute trademark applications before the United States Patent and Trademark Office (USPTO) and international trademark offices.",
                "Handle trademark opposition, cancellation, and infringement cases, including litigation and settlement negotiations.",
                "Manage trademark portfolios, including monitoring, maintenance, and enforcement of trademarks domestically and internationally.",
                "Negotiate and draft trademark license agreements, assignments, and other intellectual property transactions.",
                "Stay updated on trademark laws, regulations, and case law developments to provide informed legal advice and support.",
                "Collaborate with clients, marketing teams, and other stakeholders to ensure brand protection and compliance."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school; admission to the state bar as a practicing attorney.",
                "Registration to practice before the United States Patent and Trademark Office (USPTO) is advantageous.",
                "Specialization or experience in intellectual property law, with a focus on trademark prosecution and litigation.",
                "Strong legal research, writing, and analytical skills, with the ability to interpret and apply complex trademark laws and regulations.",
                "Effective communication and negotiation skills, with the ability to interact professionally with clients, colleagues, and trademark examiners.",
                "Detail-oriented and organized approach to managing trademark matters, including drafting, prosecution, and portfolio management.",
                "Ability to work independently, prioritize tasks, and meet deadlines in a fast-paced legal environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including legal education and training",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal profession or intellectual property field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Trademark Attorneys varies depending on factors such as experience, specialization, and location. On average, Trademark Attorneys can expect to earn between $90,000 and $180,000 per year."
        }
    ]
};

export const InternationalLawAttorneyJobDescription = {
    title: "International Law Attorney Job Description",
    jobRole: "An International Law Attorney specializes in legal matters that cross national borders, including international trade, business transactions, human rights, and immigration. They provide legal counsel on international regulations, treaties, and agreements, and represent clients in international disputes and negotiations.",
    preRead: "Use this International Law Attorney job description template to attract qualified candidates for positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific international law needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an International Law Attorney, you will specialize in legal matters that cross national borders, including international trade, business transactions, human rights, and immigration. You will provide legal counsel on international regulations, treaties, and agreements, and represent clients in international disputes and negotiations. This role requires strong legal expertise, analytical skills, and a deep understanding of international law and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on international trade, business transactions, and compliance with international regulations.",
                "Draft, review, and negotiate international contracts, agreements, and treaties.",
                "Represent clients in international disputes, arbitrations, and negotiations.",
                "Conduct legal research and analysis on international laws, regulations, and case law.",
                "Advise clients on matters related to international human rights, immigration, and asylum.",
                "Assist clients with cross-border mergers, acquisitions, and business transactions.",
                "Stay updated on international law developments, treaties, and agreements to provide informed legal advice and support.",
                "Collaborate with clients, foreign legal counsel, and government agencies to ensure compliance with international laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school; admission to the state bar as a practicing attorney.",
                "Specialization or experience in international law, with a focus on trade, business transactions, or human rights.",
                "Strong legal research, writing, and analytical skills, with the ability to interpret and apply complex international laws and regulations.",
                "Effective communication and negotiation skills, with the ability to interact professionally with clients, colleagues, and international legal professionals.",
                "Detail-oriented and organized approach to managing international legal matters, including drafting, negotiation, and dispute resolution.",
                "Ability to work independently, prioritize tasks, and meet deadlines in a fast-paced legal environment.",
                "Proficiency in multiple languages is advantageous."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities, including legal education and training",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal profession or international law field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for International Law Attorneys varies depending on factors such as experience, specialization, and location. On average, International Law Attorneys can expect to earn between $100,000 and $200,000 per year."
        }
    ]
};

export const LegalRecruiterJobDescription = {
    title: "Legal Recruiter Job Description",
    jobRole: "A Legal Recruiter is responsible for sourcing, attracting, and hiring legal professionals for law firms, corporate legal departments, and other organizations. They manage the recruitment process from job posting to onboarding, ensuring the best legal talent is hired to meet the organization’s needs.",
    preRead: "Use this Legal Recruiter job description template to attract qualified candidates for recruitment positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific recruitment needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Recruiter, you will be responsible for sourcing, attracting, and hiring legal professionals for law firms, corporate legal departments, and other organizations. You will manage the recruitment process from job posting to onboarding, ensuring the best legal talent is hired to meet the organization’s needs. This role requires strong recruitment skills, an understanding of the legal industry, and excellent communication abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement effective recruitment strategies to attract qualified legal professionals.",
                "Source and identify potential candidates through various channels, including job boards, social media, and professional networks.",
                "Conduct initial candidate screenings and interviews to assess qualifications and fit for the organization.",
                "Coordinate and schedule interviews between candidates and hiring managers.",
                "Manage the offer and negotiation process, including extending job offers and conducting reference checks.",
                "Maintain a database of potential candidates and build a talent pipeline for future hiring needs.",
                "Collaborate with hiring managers to understand their staffing needs and provide recruitment support.",
                "Stay updated on industry trends and best practices in legal recruitment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or a related field.",
                "Proven experience as a recruiter, preferably in the legal industry.",
                "Strong understanding of legal roles and qualifications.",
                "Excellent communication, interpersonal, and negotiation skills.",
                "Ability to build and maintain professional relationships.",
                "Strong organizational and time-management skills.",
                "Proficiency in using recruitment software and applicant tracking systems (ATS).",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the recruitment field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Recruiters varies depending on factors such as experience and location. On average, Legal Recruiters can expect to earn between $60,000 and $90,000 per year."
        }
    ]
};

export const LegalComplianceAnalystJobDescription = {
    title: "Legal Compliance Analyst Job Description",
    jobRole: "A Legal Compliance Analyst ensures that an organization adheres to legal standards and in-house policies. They are responsible for developing compliance programs, reviewing company policies, and advising management on possible risks. Their goal is to ensure the organization operates within the law and meets current regulatory standards.",
    preRead: "Use this Legal Compliance Analyst job description template to attract qualified candidates for compliance positions in your organization. Customize the responsibilities and qualifications to match your specific compliance needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Compliance Analyst, you will ensure that an organization adheres to legal standards and in-house policies. You will be responsible for developing compliance programs, reviewing company policies, and advising management on possible risks. This role requires strong analytical skills, attention to detail, and a deep understanding of legal and regulatory standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement compliance programs to ensure adherence to legal standards and internal policies.",
                "Review and revise company policies and procedures to ensure compliance with regulations.",
                "Monitor and audit company activities to identify and address potential compliance issues.",
                "Conduct risk assessments and advise management on potential legal risks.",
                "Prepare and present reports on compliance activities and findings to senior management.",
                "Provide training and guidance to employees on compliance-related matters.",
                "Stay updated on changes in laws and regulations that may affect the organization.",
                "Collaborate with external auditors and regulatory agencies as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Law, Business Administration, or a related field.",
                "Proven experience in a compliance role, preferably within a corporate setting.",
                "Strong knowledge of legal requirements and standards.",
                "Excellent analytical, research, and problem-solving skills.",
                "Strong attention to detail and organizational skills.",
                "Effective communication and interpersonal skills.",
                "Ability to work independently and manage multiple tasks simultaneously.",
                "Proficiency in using compliance management software and tools."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the compliance field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Compliance Analysts varies depending on factors such as experience and location. On average, Legal Compliance Analysts can expect to earn between $70,000 and $100,000 per year."
        }
    ]
};

export const ImmigrationParalegalJobDescription = {
    title: "Immigration Paralegal Job Description",
    jobRole: "An Immigration Paralegal supports immigration attorneys by preparing and filing legal documents, conducting research, and communicating with clients. They assist with various immigration cases, including visas, green cards, and naturalization processes.",
    preRead: "Use this Immigration Paralegal job description template to attract qualified candidates for paralegal positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Immigration Paralegal, you will support immigration attorneys by preparing and filing legal documents, conducting research, and communicating with clients. You will assist with various immigration cases, including visas, green cards, and naturalization processes. This role requires strong organizational skills, attention to detail, and a thorough understanding of immigration law and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and file immigration forms and legal documents.",
                "Conduct legal research and gather relevant information for immigration cases.",
                "Communicate with clients to collect necessary information and provide updates on case status.",
                "Assist attorneys in preparing for court appearances, hearings, and client meetings.",
                "Maintain and organize client files and case documents.",
                "Monitor deadlines and ensure timely submission of documents.",
                "Stay updated on changes in immigration laws and regulations.",
                "Coordinate with government agencies and other entities involved in the immigration process."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate's or Bachelor's degree in Paralegal Studies or a related field.",
                "Paralegal certification is preferred.",
                "Proven experience as a paralegal, preferably in immigration law.",
                "Strong knowledge of immigration laws and procedures.",
                "Excellent organizational and time-management skills.",
                "Strong communication and interpersonal skills.",
                "Proficiency in legal research and document preparation.",
                "Ability to handle sensitive and confidential information with discretion."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Immigration Paralegals varies depending on factors such as experience and location. On average, Immigration Paralegals can expect to earn between $45,000 and $65,000 per year."
        }
    ]
};

export const CourtReporterJobDescription = {
    title: "Court Reporter Job Description",
    jobRole: "A Court Reporter is responsible for transcribing spoken words during legal proceedings into written records. They create verbatim transcripts of court hearings, depositions, trials, and other legal events, ensuring an accurate and complete record is maintained.",
    preRead: "Use this Court Reporter job description template to attract qualified candidates for court reporter positions. Customize the responsibilities and qualifications to match your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Court Reporter, you will be responsible for transcribing spoken words during legal proceedings into written records. You will create verbatim transcripts of court hearings, depositions, trials, and other legal events, ensuring an accurate and complete record is maintained. This role requires excellent listening skills, attention to detail, and proficiency in transcription equipment and software."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Transcribe spoken words during legal proceedings into written records.",
                "Create verbatim transcripts of court hearings, depositions, trials, and other legal events.",
                "Review and edit transcripts for accuracy and completeness.",
                "Provide real-time transcription services during court proceedings when required.",
                "Maintain and organize records of transcripts and other legal documents.",
                "Prepare and distribute transcripts to judges, attorneys, and other parties as requested.",
                "Ensure confidentiality and security of all transcribed information.",
                "Stay updated on changes in transcription technology and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; certification in court reporting is preferred.",
                "Proven experience as a court reporter or stenographer.",
                "Proficiency in transcription equipment and software.",
                "Excellent listening and typing skills.",
                "Strong attention to detail and accuracy.",
                "Ability to work under pressure and meet deadlines.",
                "Excellent organizational and time-management skills.",
                "Strong understanding of legal terminology and procedures."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Court Reporters varies depending on factors such as experience and location. On average, Court Reporters can expect to earn between $50,000 and $80,000 per year."
        }
    ]
};

export const LegalEthicsConsultantJobDescription = {
    title: "Legal Ethics Consultant Job Description",
    jobRole: "A Legal Ethics Consultant provides guidance and advice to law firms and legal departments on matters of professional responsibility and ethical standards. They ensure compliance with legal ethics regulations and help manage risks associated with ethical breaches.",
    preRead: "Use this Legal Ethics Consultant job description template to attract qualified candidates for consultant positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Ethics Consultant, you will provide guidance and advice to law firms and legal departments on matters of professional responsibility and ethical standards. You will ensure compliance with legal ethics regulations and help manage risks associated with ethical breaches. This role requires a deep understanding of legal ethics, strong analytical skills, and excellent communication abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide guidance on professional responsibility and ethical standards to attorneys and legal staff.",
                "Develop and implement policies and procedures to ensure compliance with legal ethics regulations.",
                "Conduct training sessions on legal ethics and professional responsibility.",
                "Investigate and address potential ethical breaches or conflicts of interest.",
                "Advise on matters related to client confidentiality, conflict of interest, and attorney-client privilege.",
                "Assist in drafting and reviewing legal documents to ensure they meet ethical standards.",
                "Monitor changes in legal ethics regulations and update policies accordingly.",
                "Collaborate with legal and compliance teams to manage ethical risks."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (J.D.) degree from an accredited law school.",
                "Active license to practice law in the relevant jurisdiction.",
                "Proven experience in legal ethics or professional responsibility.",
                "Deep understanding of legal ethics regulations and professional responsibility standards.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to handle sensitive and confidential information with discretion.",
                "Experience in conducting training sessions is preferred."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Ethics Consultants varies depending on factors such as experience and location. On average, Legal Ethics Consultants can expect to earn between $80,000 and $120,000 per year."
        }
    ]
};

export const LegalOperationsManagerJobDescription = {
    title: "Legal Operations Manager Job Description",
    jobRole: "A Legal Operations Manager oversees the administrative and operational functions of a law firm or legal department. They ensure the efficiency and effectiveness of legal processes, manage budgets, and implement technology solutions to streamline operations.",
    preRead: "Use this Legal Operations Manager job description template to attract qualified candidates for management positions in your law firm or legal department. Customize the responsibilities and qualifications to match your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Operations Manager, you will oversee the administrative and operational functions of a law firm or legal department. You will ensure the efficiency and effectiveness of legal processes, manage budgets, and implement technology solutions to streamline operations. This role requires strong organizational skills, a deep understanding of legal operations, and the ability to manage multiple projects simultaneously."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the day-to-day operations of the legal department or law firm.",
                "Develop and implement strategies to improve operational efficiency.",
                "Manage budgets and financial planning for the legal department.",
                "Implement technology solutions to streamline legal processes.",
                "Coordinate with other departments to ensure seamless operations.",
                "Manage vendor relationships and negotiate contracts.",
                "Monitor compliance with legal regulations and internal policies.",
                "Prepare and present reports on operational performance to senior management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Law, or a related field.",
                "Proven experience in legal operations, administration, or a related role.",
                "Strong organizational and project management skills.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in legal technology solutions and software.",
                "Strong financial acumen and budget management experience.",
                "Ability to manage multiple projects and prioritize tasks effectively.",
                "Understanding of legal regulations and compliance requirements."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage",
                "Retirement savings plan with employer match",
                "Paid time off and holidays",
                "Professional development opportunities",
                "Flexible work schedule and remote work options",
                "Opportunities for career advancement within the legal field"
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legal Operations Managers varies depending on factors such as experience and location. On average, Legal Operations Managers can expect to earn between $90,000 and $130,000 per year."
        }
    ]
};

export const LegislativeAssistantJobDescription = {
    title: "Legislative Assistant Job Description",
    jobRole: "A Legislative Assistant supports legislators by conducting research, drafting legislation, and assisting with various administrative tasks. They play a critical role in the legislative process by providing detailed information and analysis on policy issues.",
    preRead: "Use this Legislative Assistant job description template to attract qualified candidates for legislative support positions. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legislative Assistant, you will support legislators by conducting research, drafting legislation, and assisting with various administrative tasks. You will play a critical role in the legislative process by providing detailed information and analysis on policy issues. This role requires strong research skills, excellent written and verbal communication abilities, and a thorough understanding of the legislative process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct research on policy issues and provide detailed reports.",
                "Draft and edit legislative documents, including bills, amendments, and resolutions.",
                "Monitor legislative activities and update legislators on relevant developments.",
                "Assist in the preparation of speeches, statements, and correspondence.",
                "Coordinate with other legislative staff and stakeholders to gather information and support legislative initiatives.",
                "Maintain legislative records and databases.",
                "Schedule and coordinate meetings, hearings, and other legislative events.",
                "Respond to constituent inquiries and provide information on legislative matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Political Science, Public Administration, Law, or a related field.",
                "Proven experience in a legislative or policy research role.",
                "Strong research and analytical skills.",
                "Excellent written and verbal communication abilities.",
                "Thorough understanding of the legislative process and government operations.",
                "Ability to manage multiple tasks and prioritize effectively.",
                "Strong attention to detail and organizational skills.",
                "Proficiency in using office software and legislative tracking systems."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance including medical, dental, and vision coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options.",
                "Opportunities for career advancement within the legislative field."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Legislative Assistants varies depending on factors such as experience and location. On average, Legislative Assistants can expect to earn between $45,000 and $70,000 per year."
        }
    ]
};
