export const TextFieldsInfo = [
  "Role*",
  "Job Title*",
  "Hiring Manager*",
  "Additional Hiring Manager",
  "Additional Hiring Manager",
  "Job Location*",
  "Zip Code",
  "City",
  "State*",
  "Neighborhood",
  "Neighborhood",
  "Employment type*",
  "Experience Level*"
];


export const ApplicantsStagesInfo = [
  { label: 'New', value: 'new' },
  { label: 'Reviewed', value: 'reviewed' },
  { label: 'Phone Screening', value: 'phone_screening' },
  { label: 'Interview', value: 'interview' },
  { label: 'Second Interview', value: 'second_interview' },
  { label: 'Offer', value: 'offer' },
  { label: 'Hired', value: 'hired' },
  { label: 'Not qualified', value: 'not_qualified' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Auto Rejected', value: 'auto_rejected' }
];


// Define the User interface
export interface User {
  userName: string;
  email: string;
  role: string;
  allJobs: boolean;
  create: boolean;
  assign: boolean;
  view: boolean;
  comment: boolean;
}

// Define the user data constant
export const users: User[] = [
  {
    userName: 'John Doe',
    email: 'john.doe@example.com',
    role: 'Owner',
    allJobs: true,
    create: true,
    assign: true,
    view: true,
    comment: true,
  },
  {
    userName: 'Jane Smith',
    email: 'jane.smith@example.com',
    role: 'Hiring Manager',
    allJobs: false,
    create: false,
    assign: false,
    view: true,
    comment: true,
  },
  {
    userName: 'Michael Brown',
    email: 'michael.brown@example.com',
    role: 'Admin',
    allJobs: true,
    create: true,
    assign: true,
    view: true,
    comment: true,
  },
  {
    userName: 'Sarah Johnson',
    email: 'sarah.johnson@example.com',
    role: 'Recruiter',
    allJobs: false,
    create: true,
    assign: true,
    view: true,
    comment: true,
  },
  {
    userName: 'Adam Sandler',
    email: 'sandler.adam@example.com',
    role: 'Observer',
    allJobs: false,
    create: false,
    assign: false,
    view: true,
    comment: true,
  },
];


