import Typography from '@mui/material/Typography';
import { ThemedBtn, ThemedBtnRed } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails.styled';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ApiStates } from 'shared/constants';
import { enableOrDisableEmployer, fetchEmployerDetails, resetEmpToggle } from 'store/slices/admin/employer/emp-details-slice';

export const DisableEmployer = () => {
    const { employerId } = useParams();
    const dispatch = useDispatch();
    const enablingOrDisablingEmp = useSelector((state) => state.employer.employer.enablingOrDisablingEmp);
    const enblngOrDsblngEmpRspnse = useSelector((state) => state.employer.employer.enblngOrDsblngEmpRspnse);
    const isEmpDisabled = useSelector((state) => state.employer.employer.isEmpDisabled);

    const disableOrEnableEmp = () => {
        dispatch(enableOrDisableEmployer({ employerId, action: isEmpDisabled ? 'enable' : 'disable' }));
    };

    useEffect(() => {
        if (enablingOrDisablingEmp === ApiStates.success) {
            dispatch(fetchEmployerDetails({ employerId }));
        }
    }, [dispatch, employerId, enablingOrDisablingEmp]);

    const Alerts = () => {
        switch (enablingOrDisablingEmp) {
            case ApiStates.pending:
                return (<ShAlert severity="info">{isEmpDisabled ? 'Enabling...' : 'Disabling...'}</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetEmpToggle()) }} severity="success">{enblngOrDsblngEmpRspnse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetEmpToggle()) }} severity="error">`Error occurred while ${isEmpDisabled ? 'Enabling...' : 'Disabling...'}!`</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <Typography variant="subtitle2" gutterBottom>
            Disable employer (in case of spammers or scammers)
        </Typography>
        {isEmpDisabled ?
            <ThemedBtn variant="contained" disabled={enablingOrDisablingEmp === ApiStates.pending} onClick={disableOrEnableEmp}>Enable this Employer</ThemedBtn> :
            <ThemedBtnRed variant="contained" disabled={enablingOrDisablingEmp === ApiStates.pending} onClick={disableOrEnableEmp}>Disable this Employer</ThemedBtnRed>}
        {Alerts()}
    </>);
}