import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IJobSummary, IJobSummaryApiParams, IJobsApiParams, IJobsListResponse, IJobsListState, TJobsCount } from 'Modules/Core/JobsTs/JobsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';
import { IAllJobDetails } from './jobs-slice-model';

const initialJobsListState: IJobsListState = {
    getJobsListStatus: 'idle',
    getJobsListResponse: '',
    getJobsCountsStatus: 'idle',
    getJobsCountsResponse: '',
    jobsList: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    },
    jobsSummary: {},
    activeCount: 0,
    draftCount: 0,
    templateCount: 0,
    expiredCount: 0
};

export const fetchJobsCounts = createAsyncThunk<TJobsCount, void, { rejectValue: IBaseResponse }>(
    "fetchJobsCounts",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_SUMMARY}`)
            .then((response: AxiosResponse<TJobsCount>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const fetchJobsList = createAsyncThunk<IJobsListResponse, IJobsApiParams, { rejectValue: IBaseResponse }>(
    'fetchJobsList',
    async ({ sortDirection, pageNumber, pageSize, sortColumn, jobType, search }: IJobsApiParams, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_LIST}/${jobType}?pageNo=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}${search && '&search=' + search}`)
            .then((response: AxiosResponse<IJobsListResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const fetchJobSummary = createAsyncThunk<IBaseResponse<IJobSummary>, IJobSummaryApiParams, { rejectValue: IBaseResponse }>(
    'fetchJobSummary',
    async ({ jobId }: IJobSummaryApiParams, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOB_SUMMARY}?jobId=${jobId}`)
            .then((response: AxiosResponse<IBaseResponse<IJobSummary>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobDescription = createAsyncThunk<IBaseResponse<IAllJobDetails>, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getJobDescription",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}`)
            .then((response: AxiosResponse<IBaseResponse<IAllJobDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const jobsListSlice = createSlice({
    name: 'jobsList',
    initialState: initialJobsListState,
    reducers: {
        toggleJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsList[action.payload].isExpanded = !state.jobsList[action.payload].isExpanded
        },
        resetJobsState: () => initialJobsListState,
        resetFetchJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsSummary[action.payload].getSummaryStatus = 'idle';
            state.jobsSummary[action.payload].getSummaryResponse = '';
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobsListState;
        });
        // Fetch jobs counts
        builder.addCase(fetchJobsCounts.pending, (state) => {
            state.getJobsCountsStatus = 'pending'
        });
        builder.addCase(fetchJobsCounts.fulfilled, (state, action) => {
            state.getJobsCountsStatus = 'success';
            // Filter data and set counts of each job types
            if (action?.payload) {
                state.activeCount = action?.payload.active || 0;
                state.draftCount = action?.payload.draft || 0;
                state.expiredCount = action?.payload.expired || 0;
                state.templateCount = action?.payload.template || 0;
            }
        });
        builder.addCase(fetchJobsCounts.rejected, (state, action) => {
            state.getJobsCountsStatus = 'failed';
            state.getJobsCountsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // Fetch jobs list
        builder.addCase(fetchJobsList.pending, (state) => {
            state.getJobsListStatus = 'pending'
        });
        builder.addCase(fetchJobsList.fulfilled, (state, action) => {
            state.getJobsListStatus = 'success';
            state.jobsList = action?.payload.data;
            state.paginationParams = action?.payload.stats;
            // Add total records counts in the response.
            state.paginationParams.count = action.payload.data?.length;
        });
        builder.addCase(fetchJobsList.rejected, (state, action) => {
            state.getJobsListStatus = 'failed';
            state.getJobsListResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.jobsList = [];
            state.paginationParams = { count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0 }
        });
        // Fetch job summary
        builder.addCase(fetchJobSummary.pending, (state, action) => {
            if (!(action.meta.arg.jobId in state.jobsSummary)) {
                state.jobsSummary[action.meta.arg.jobId] = { getSummaryStatus: 'pending' };
            }
            state.jobsSummary[action.meta.arg.jobId].getSummaryStatus = 'pending';
            const jobIndx = state.jobsList.findIndex(al => al.id === action.meta.arg.jobId)
            if (jobIndx >= 0) {
                state.jobsList[jobIndx].isExpanded = true;
            }
        });
        builder.addCase(fetchJobSummary.fulfilled, (state, action) => {
            state.jobsSummary[action.meta.arg.jobId] = { ...action?.payload.data, getSummaryStatus: 'success' };
        });
        builder.addCase(fetchJobSummary.rejected, (state, action) => {
            state.jobsSummary[action.meta.arg.jobId].getSummaryStatus = 'failed';
            state.jobsSummary[action.meta.arg.jobId].getSummaryResponse = action?.payload?.message;
        });
        /*
            Get job details and append to its job object in jobsList.
            This is to avoid multiple API calls if the details are already loaded.
        */
        builder.addCase(getJobDescription.pending, (state, action) => {
            state.jobsList[state.jobsList.findIndex(j => j.id === action.meta.arg.jobId)].getJobDescStatus = 'pending';
        });
        builder.addCase(getJobDescription.fulfilled, (state, action) => {
            const indx = state.jobsList.findIndex(j => j.id === action.meta.arg.jobId);
            state.jobsList[indx].getJobDescStatus = 'success';
            state.jobsList[indx].getJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.jobsList[indx].jobDetails = action.payload.data;
        });
        builder.addCase(getJobDescription.rejected, (state, action) => {
            const indx = state.jobsList.findIndex(j => j.id === action.meta.arg.jobId);
            state.jobsList[indx].getJobDescStatus = 'failed';
            state.jobsList[indx].getJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { toggleJobSummary, resetJobsState, resetFetchJobSummary } = jobsListSlice.actions;
export default jobsListSlice;
