import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateJobPreviewState } from 'Modules/Core/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialJobPreviewState: ICreateJobPreviewState = {
    aiJobDescription: undefined,
    getAiJobDescResponse: '',
    getAiJobDescStatus: 'idle'
};

export const getAiJobDescription = createAsyncThunk<{ description: string }, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getAiJobDescription",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/ai_job_description`)
            .then((response: AxiosResponse<{ description: string }>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const createJobPreviewSlice = createSlice({
    name: 'createJobPreviewSlice',
    initialState: initialJobPreviewState,
    reducers: {
        resetCreateJobPreviewState: () => initialJobPreviewState,
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobPreviewState;
        });
        // get ai job description
        builder.addCase(getAiJobDescription.pending, (state) => {
            state.getAiJobDescStatus = 'pending'
        });
        builder.addCase(getAiJobDescription.fulfilled, (state, action) => {
            state.getAiJobDescStatus = 'success';
            state.aiJobDescription = action.payload.description;
        });
        builder.addCase(getAiJobDescription.rejected, (state, action) => {
            state.getAiJobDescStatus = 'failed';
            state.getAiJobDescResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetCreateJobPreviewState } = createJobPreviewSlice.actions;
export default createJobPreviewSlice;
