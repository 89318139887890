export const MarketingManagerJobDescription = {
    title: "Marketing Manager Job Description",
    jobRole: "A Marketing Manager is responsible for developing and implementing marketing strategies to promote products or services, attract customers, and drive revenue growth. They oversee the marketing team and collaborate with other departments to achieve business objectives.",
    preRead: "Use this Marketing Manager job description template to advertise open positions for a Marketing Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Manager, you will lead the marketing efforts of the company, developing strategic plans to reach target audiences, generate leads, and increase brand awareness. You will work closely with the sales team, product managers, and other stakeholders to ensure that marketing initiatives align with business goals and objectives. This role requires creativity, analytical thinking, and strong leadership skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute comprehensive marketing plans and strategies to meet business objectives.",
                "Identify target audiences and segments, and develop targeted marketing campaigns.",
                "Manage and oversee the marketing team, including hiring, training, and performance management.",
                "Coordinate marketing efforts across multiple channels, including digital, social media, email, and traditional advertising.",
                "Analyze market trends, consumer behavior, and competitor activities to identify opportunities for growth.",
                "Monitor and report on the effectiveness of marketing campaigns and initiatives.",
                "Collaborate with sales, product, and other departments to ensure alignment of marketing activities with overall business goals.",
                "Manage marketing budgets and allocate resources effectively.",
                "Stay updated on industry trends, best practices, and emerging technologies in marketing.",
                "Lead and participate in cross-functional teams and projects as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Marketing, Business Administration, or related field (Master’s Degree preferred).",
                "Proven experience in marketing management or related roles.",
                "Strong leadership and team management skills.",
                "Excellent communication and interpersonal abilities.",
                "Strategic thinking and analytical skills.",
                "Creativity and innovation in developing marketing campaigns and initiatives.",
                "Proficiency in digital marketing tools and platforms.",
                "Ability to work effectively under pressure and meet tight deadlines.",
                "Results-oriented mindset with a focus on driving business growth.",
                "Ability to adapt to changing priorities and requirements."
            ]
        }
    ]
};

export const ContentStrategistJobDescription = {
    title: "Content Strategist Job Description",
    jobRole: "A Content Strategist is responsible for developing and executing content strategies to drive engagement, increase brand awareness, and support marketing objectives. They create and manage content across various channels to attract and retain customers.",
    preRead: "Use this Content Strategist job description template to advertise open positions for a Content Strategist in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Content Strategist, you will be responsible for planning, creating, and managing content that aligns with the company’s brand and marketing goals. You will work closely with the marketing team to develop content strategies that resonate with target audiences and drive business results. This role requires creativity, strategic thinking, and strong writing skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute content strategies to support marketing objectives and drive engagement.",
                "Create high-quality, engaging content for various channels, including websites, blogs, social media, and email.",
                "Collaborate with internal stakeholders to understand business goals, target audiences, and messaging.",
                "Conduct content audits and analysis to identify gaps and opportunities for improvement.",
                "Manage content calendars and schedules to ensure timely delivery of content.",
                "Optimize content for SEO and search engine visibility.",
                "Monitor and analyze content performance using analytics tools and metrics.",
                "Stay updated on industry trends, best practices, and emerging technologies in content marketing.",
                "Provide guidance and support to other team members on content creation and optimization.",
                "Lead and participate in cross-functional teams and projects as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Marketing, Communications, Journalism, or related field (Master’s Degree preferred).",
                "Proven experience in content marketing, content strategy, or related roles.",
                "Excellent writing and editing skills, with attention to detail and grammar.",
                "Creative thinking and ability to generate innovative content ideas.",
                "Familiarity with SEO principles and best practices.",
                "Proficiency in content management systems and digital publishing platforms.",
                "Strong analytical and problem-solving skills.",
                "Ability to work effectively in a fast-paced, deadline-driven environment.",
                "Collaborative mindset with the ability to work cross-functionally.",
                "Adaptability and willingness to learn new tools and technologies."
            ]
        }
    ]
};

export const SeoSpecialistJobDescription = {
    title: "SEO Specialist Job Description",
    jobRole: "An SEO Specialist is responsible for optimizing websites and content to improve search engine visibility, drive organic traffic, and increase rankings on search engine results pages. They use various strategies and tactics to improve website performance and enhance online presence.",
    preRead: "Use this SEO Specialist job description template to advertise open positions for an SEO Specialist in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an SEO Specialist, you will play a key role in improving the online visibility and performance of the company’s website. You will analyze website traffic and user behavior, identify opportunities for improvement, and implement strategies to increase organic search traffic. This role requires a deep understanding of SEO principles, analytical skills, and the ability to stay updated on industry trends."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct keyword research to identify high-value keywords and phrases.",
                "Optimize website content, including meta tags, headings, and on-page elements, for targeted keywords.",
                "Develop and implement link building strategies to improve website authority and credibility.",
                "Analyze website performance using SEO tools and analytics platforms.",
                "Monitor search engine algorithms and updates to ensure compliance and adapt strategies accordingly.",
                "Provide recommendations for website improvements and optimization.",
                "Collaborate with content creators, developers, and designers to implement SEO best practices.",
                "Stay updated on industry trends, best practices, and emerging technologies in SEO.",
                "Track and report on key SEO metrics and performance indicators.",
                "Identify and troubleshoot technical SEO issues."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Marketing, Computer Science, or related field.",
                "Proven experience in SEO, digital marketing, or related roles.",
                "In-depth knowledge of SEO principles, algorithms, and best practices.",
                "Familiarity with SEO tools and analytics platforms (e.g., Google Analytics, SEMrush, Moz).",
                "Strong analytical skills and attention to detail.",
                "Excellent communication and interpersonal abilities.",
                "Ability to work effectively in a fast-paced, collaborative environment.",
                "Certifications in SEO or related areas are a plus.",
                "Creativity and innovation in developing SEO strategies and tactics.",
                "Ability to adapt to changing priorities and requirements."
            ]
        }
    ]
};

export const SocialMediaManagerJobDescription = {
    title: "Social Media Manager Job Description",
    jobRole: "A Social Media Manager is responsible for developing and implementing social media strategies to build brand awareness, engage audiences, and drive business results. They manage social media accounts, create content, and monitor performance to optimize social media presence.",
    preRead: "Use this Social Media Manager job description template to advertise open positions for a Social Media Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Social Media Manager, you will be responsible for managing the company’s presence on social media platforms, including Facebook, Twitter, LinkedIn, and Instagram. You will develop and execute social media strategies to engage audiences, increase brand awareness, and drive traffic and conversions. This role requires creativity, strategic thinking, and strong communication skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute social media strategies to achieve business goals and objectives.",
                "Create and curate engaging content for social media platforms, including text, images, and videos.",
                "Manage social media accounts and profiles, including posting content, responding to comments, and engaging with followers.",
                "Monitor social media trends, conversations, and competitors to identify opportunities and risks.",
                "Analyze social media performance using analytics tools and metrics.",
                "Optimize social media content and campaigns for maximum engagement and reach.",
                "Collaborate with cross-functional teams, including marketing, sales, and product, to align social media activities with overall business goals.",
                "Stay updated on industry trends, best practices, and emerging technologies in social media marketing.",
                "Track and report on key social media metrics and performance indicators.",
                "Provide recommendations for improving social media presence and performance."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Marketing, Communications, or related field.",
                "Proven experience in social media management, digital marketing, or related roles.",
                "In-depth knowledge of social media platforms, algorithms, and best practices.",
                "Excellent writing and editing skills, with the ability to create engaging content.",
                "Strong analytical skills and attention to detail.",
                "Creativity and innovation in developing social media strategies and content.",
                "Ability to work effectively in a fast-paced, deadline-driven environment.",
                "Excellent communication and interpersonal abilities.",
                "Experience with social media management tools and analytics platforms.",
                "Ability to adapt to changing priorities and requirements."
            ]
        }
    ]
};

export const BrandManagerJobDescription = {
    title: "Brand Manager Job Description",
    jobRole: "A Brand Manager is responsible for developing and implementing brand strategies to build brand equity, increase brand awareness, and drive customer loyalty. They oversee brand identity, messaging, and positioning across various channels to ensure consistency and alignment with business goals.",
    preRead: "Use this Brand Manager job description template to advertise open positions for a Brand Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Brand Manager, you will be responsible for managing and enhancing the company’s brand identity and reputation. You will develop brand strategies, messaging, and positioning to differentiate the brand and drive customer engagement and loyalty. This role requires creativity, strategic thinking, and strong leadership skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute brand strategies to build brand equity and awareness.",
                "Define brand identity, messaging, and positioning across various channels.",
                "Ensure brand consistency and alignment with business objectives.",
                "Develop and maintain brand guidelines and standards.",
                "Collaborate with cross-functional teams, including marketing, product, and sales, to ensure brand alignment and integration.",
                "Monitor and analyze market trends, consumer insights, and competitor activities to identify opportunities and threats.",
                "Lead brand campaigns and initiatives, including advertising, promotions, and events.",
                "Manage brand budgets and resources effectively.",
                "Track and report on key brand metrics and performance indicators.",
                "Provide recommendations for improving brand performance and market presence."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Marketing, Communications, or related field.",
                "Proven experience in brand management, marketing, or related roles.",
                "In-depth knowledge of branding principles, strategies, and best practices.",
                "Strong creative and conceptual thinking skills.",
                "Excellent communication and presentation abilities.",
                "Strong project management and organizational skills.",
                "Ability to work effectively in a cross-functional team environment.",
                "Analytical mindset with the ability to interpret data and insights.",
                "Ability to multitask and manage competing priorities.",
                "Experience with brand management tools and platforms."
            ]
        }
    ]
};


export const DigitalMarketingSpecialistJobDescription = {
    title: "Digital Marketing Specialist Job Description",
    jobRole: "A Digital Marketing Specialist is responsible for developing, implementing, and managing marketing campaigns that promote a company's products or services. They play a major role in enhancing brand awareness within the digital space as well as driving website traffic and acquiring leads/customers.",
    preRead: "Use this Digital Marketing Specialist job description template to attract qualified candidates for your digital marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Digital Marketing Specialist, you will be responsible for developing, implementing, and managing marketing campaigns that promote our company and its products or services. You will play a major role in enhancing brand awareness within the digital space as well as driving website traffic and acquiring leads/customers. This role requires a combination of creativity, analytical thinking, and digital marketing expertise."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement digital marketing strategies across various channels including SEO, SEM, social media, email marketing, and content marketing.",
                "Manage and optimize online advertising campaigns on platforms such as Google Ads and social media.",
                "Monitor and analyze website traffic and user behavior using tools like Google Analytics.",
                "Create engaging and informative content for digital marketing campaigns.",
                "Collaborate with the design team to create visually appealing graphics and videos for online promotion.",
                "Stay up-to-date with the latest digital marketing trends and technologies.",
                "Measure and report performance of all digital marketing campaigns and assess against goals (ROI and KPIs).",
                "Identify trends and insights, and optimize spend and performance based on the insights."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in digital marketing or a similar role.",
                "Strong understanding of current online marketing concepts, strategy, and best practices.",
                "Experience with SEO, SEM, Google Analytics, and CRM software.",
                "Proficiency in marketing automation tools and content management systems.",
                "Excellent communication and interpersonal skills.",
                "Strong analytical and problem-solving abilities.",
                "Creative and innovative thinker."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Digital Marketing Specialists varies depending on experience and location. On average, Digital Marketing Specialists can expect to earn between $50,000 and $80,000 per year."
        }
    ]
};

export const ProductMarketingManagerJobDescription = {
    title: "Product Marketing Manager Job Description",
    jobRole: "A Product Marketing Manager is responsible for developing and executing marketing strategies for a company's products. They conduct market research, create product messaging, and work closely with sales and development teams to ensure product success.",
    preRead: "Use this Product Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Product Marketing Manager, you will be responsible for developing and executing marketing strategies for our products. You will conduct market research, create product messaging, and work closely with sales and development teams to ensure product success. This role requires strong strategic thinking, excellent communication skills, and a deep understanding of the market and customer needs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement product marketing strategies that drive product adoption and growth.",
                "Conduct market research to identify customer needs, market trends, and competitive landscape.",
                "Create compelling product messaging and positioning that differentiates our products in the market.",
                "Collaborate with product development teams to ensure products meet market needs and are positioned for success.",
                "Work closely with sales teams to develop sales enablement materials and training.",
                "Plan and execute product launches, including coordinating with cross-functional teams.",
                "Measure and report on the effectiveness of product marketing initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, or a related field.",
                "Proven experience in product marketing or a similar role.",
                "Strong understanding of market research techniques and data analysis.",
                "Excellent communication and presentation skills.",
                "Ability to work effectively with cross-functional teams.",
                "Creative thinker with a strategic mindset.",
                "Experience with product launch processes and go-to-market strategies."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Product Marketing Managers varies depending on experience and location. On average, Product Marketing Managers can expect to earn between $80,000 and $120,000 per year."
        }
    ]
};

export const MarketingCoordinatorJobDescription = {
    title: "Marketing Coordinator Job Description",
    jobRole: "A Marketing Coordinator supports the marketing department by coordinating and implementing marketing initiatives. They assist with campaign development, manage marketing materials, and analyze marketing data to measure effectiveness.",
    preRead: "Use this Marketing Coordinator job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Coordinator, you will support the marketing department by coordinating and implementing marketing initiatives. You will assist with campaign development, manage marketing materials, and analyze marketing data to measure effectiveness. This role requires strong organizational skills, attention to detail, and the ability to work collaboratively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate and implement marketing campaigns across various channels.",
                "Assist in the creation and distribution of marketing materials such as brochures, flyers, and newsletters.",
                "Manage social media accounts and create engaging content.",
                "Monitor and report on the effectiveness of marketing campaigns.",
                "Conduct market research to support marketing strategies.",
                "Collaborate with design and content teams to produce high-quality marketing assets.",
                "Maintain marketing databases and contact lists.",
                "Support event planning and execution for marketing events and trade shows."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in a marketing coordinator or similar role.",
                "Strong organizational and project management skills.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in marketing software and social media platforms.",
                "Ability to analyze marketing data and generate insights.",
                "Creative thinker with attention to detail."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Marketing Coordinators varies depending on experience and location. On average, Marketing Coordinators can expect to earn between $40,000 and $60,000 per year."
        }
    ]
};

export const CopywriterJobDescription = {
    title: "Copywriter Job Description",
    jobRole: "A Copywriter is responsible for creating engaging, persuasive, and informative written content for various marketing and communication channels. They develop copy for advertisements, websites, social media, emails, and other marketing materials.",
    preRead: "Use this Copywriter job description template to attract qualified candidates for your content team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Copywriter, you will be responsible for creating engaging, persuasive, and informative written content for various marketing and communication channels. You will develop copy for advertisements, websites, social media, emails, and other marketing materials. This role requires excellent writing skills, creativity, and a deep understanding of the target audience."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Write clear, compelling, and persuasive copy for various marketing channels.",
                "Develop content for advertisements, websites, social media, emails, and other marketing materials.",
                "Collaborate with designers and marketing teams to produce cohesive and effective marketing campaigns.",
                "Conduct research to understand the target audience and tailor content accordingly.",
                "Edit and proofread copy to ensure accuracy and consistency.",
                "Maintain brand voice and messaging across all written content.",
                "Stay updated on industry trends and incorporate best practices into content creation.",
                "Assist with content strategy development and execution."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, English, or a related field.",
                "Proven experience as a copywriter or in a similar role.",
                "Excellent writing, editing, and proofreading skills.",
                "Creative thinker with the ability to craft engaging and persuasive content.",
                "Strong understanding of marketing principles and strategies.",
                "Ability to work collaboratively with cross-functional teams.",
                "Attention to detail and ability to meet deadlines.",
                "Proficiency in using content management systems and marketing software."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Copywriters varies depending on experience and location. On average, Copywriters can expect to earn between $50,000 and $75,000 per year."
        }
    ]
};

export const MarketResearchAnalystJobDescription = {
    title: "Market Research Analyst Job Description",
    jobRole: "A Market Research Analyst is responsible for gathering and analyzing data on consumers, competitors, and market conditions to help companies understand what products people want, who will buy them, and at what price.",
    preRead: "Use this Market Research Analyst job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Market Research Analyst, you will gather and analyze data on consumers, competitors, and market conditions. Your insights will help the company understand what products people want, who will buy them, and at what price. This role requires strong analytical skills, attention to detail, and the ability to communicate findings effectively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect and analyze data on market conditions, consumer behavior, and competitors.",
                "Design and conduct surveys, focus groups, and other research methods.",
                "Interpret data to identify patterns, trends, and insights.",
                "Prepare reports and presentations to communicate findings to stakeholders.",
                "Collaborate with marketing, sales, and product teams to inform business decisions.",
                "Monitor and forecast marketing and sales trends.",
                "Stay up-to-date with industry trends and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, Statistics, or a related field.",
                "Proven experience in market research or a similar role.",
                "Strong analytical and critical thinking skills.",
                "Proficiency in statistical software and data analysis tools.",
                "Excellent communication and presentation skills.",
                "Ability to work independently and collaboratively.",
                "Attention to detail and accuracy."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Market Research Analysts varies depending on experience and location. On average, Market Research Analysts can expect to earn between $50,000 and $75,000 per year."
        }
    ]
};

export const EmailMarketingSpecialistJobDescription = {
    title: "Email Marketing Specialist Job Description",
    jobRole: "An Email Marketing Specialist is responsible for creating and executing email marketing campaigns to promote products or services, increase brand awareness, and drive customer engagement and sales.",
    preRead: "Use this Email Marketing Specialist job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Email Marketing Specialist, you will create and execute email marketing campaigns to promote products or services, increase brand awareness, and drive customer engagement and sales. This role requires strong writing skills, attention to detail, and proficiency with email marketing platforms."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement email marketing strategies and campaigns.",
                "Create engaging and persuasive email content and designs.",
                "Segment email lists based on demographics, behaviors, and preferences.",
                "Monitor and analyze email campaign performance metrics.",
                "Optimize email campaigns to improve open rates, click-through rates, and conversions.",
                "Ensure email campaigns comply with industry best practices and regulations.",
                "Collaborate with marketing, design, and content teams to align email marketing with overall marketing goals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in email marketing or a similar role.",
                "Strong writing and editing skills.",
                "Proficiency with email marketing platforms such as Mailchimp, HubSpot, or Marketo.",
                "Knowledge of email marketing best practices and regulations.",
                "Analytical skills to interpret campaign performance data.",
                "Creative and detail-oriented mindset.",
                "Ability to work collaboratively with cross-functional teams."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Email Marketing Specialists varies depending on experience and location. On average, Email Marketing Specialists can expect to earn between $45,000 and $70,000 per year."
        }
    ]
};

export const PPCSpecialistJobDescription = {
    title: "PPC Specialist Job Description",
    jobRole: "A PPC Specialist is responsible for managing pay-per-click advertising campaigns, analyzing performance data, and optimizing campaigns to achieve the best possible ROI.",
    preRead: "Use this PPC Specialist job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a PPC Specialist, you will manage pay-per-click advertising campaigns, analyze performance data, and optimize campaigns to achieve the best possible ROI. This role requires strong analytical skills, attention to detail, and expertise in PPC platforms such as Google Ads and Bing Ads."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and manage PPC campaigns across various platforms.",
                "Conduct keyword research and selection for PPC campaigns.",
                "Write compelling ad copy and create ad visuals.",
                "Monitor and analyze campaign performance metrics.",
                "Optimize campaigns to improve performance and achieve goals.",
                "Conduct A/B testing to identify the most effective strategies.",
                "Collaborate with marketing and design teams to align PPC efforts with overall marketing goals.",
                "Stay up-to-date with PPC industry trends and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, or a related field.",
                "Proven experience in PPC advertising or a similar role.",
                "Strong understanding of PPC platforms such as Google Ads and Bing Ads.",
                "Analytical skills to interpret campaign performance data.",
                "Excellent written and verbal communication skills.",
                "Ability to work independently and collaboratively.",
                "Detail-oriented and highly organized.",
                "Proficiency in using analytics tools like Google Analytics."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for PPC Specialists varies depending on experience and location. On average, PPC Specialists can expect to earn between $50,000 and $80,000 per year."
        }
    ]
};

export const MarketingAnalystJobDescription = {
    title: "Marketing Analyst Job Description",
    jobRole: "A Marketing Analyst is responsible for analyzing market data to help businesses understand market trends, customer behavior, and the effectiveness of marketing strategies.",
    preRead: "Use this Marketing Analyst job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Analyst, you will analyze market data to help businesses understand market trends, customer behavior, and the effectiveness of marketing strategies. This role requires strong analytical skills, attention to detail, and the ability to communicate findings effectively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect and analyze data on market conditions, customer behavior, and competitors.",
                "Develop reports and presentations to communicate insights and recommendations.",
                "Collaborate with marketing, sales, and product teams to inform business decisions.",
                "Monitor and forecast marketing and sales trends.",
                "Evaluate the effectiveness of marketing campaigns and strategies.",
                "Conduct market research using surveys, focus groups, and other methods.",
                "Stay up-to-date with industry trends and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, Statistics, or a related field.",
                "Proven experience in marketing analysis or a similar role.",
                "Strong analytical and critical thinking skills.",
                "Proficiency in statistical software and data analysis tools.",
                "Excellent communication and presentation skills.",
                "Ability to work independently and collaboratively.",
                "Attention to detail and accuracy."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Comprehensive health, dental, and vision insurance.",
                "401(k) plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        },
        {
            heading: "Salary Range",
            paragraph: "The salary range for Marketing Analysts varies depending on experience and location. On average, Marketing Analysts can expect to earn between $55,000 and $85,000 per year."
        }
    ]
};


export const MarketingDirectorJobDescription = {
    title: "Marketing Director Job Description",
    jobRole: "A Marketing Director is responsible for overseeing the marketing department, developing and implementing marketing strategies, and ensuring that marketing efforts align with the organization's overall goals and objectives.",
    preRead: "Use this Marketing Director job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Director, you will be responsible for overseeing the marketing department, developing and implementing marketing strategies, and ensuring that marketing efforts align with the organization's overall goals and objectives. This role requires strong leadership skills, strategic thinking, and a deep understanding of marketing principles and practices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement comprehensive marketing strategies to drive business growth.",
                "Oversee the planning, execution, and evaluation of marketing campaigns and initiatives.",
                "Manage and mentor the marketing team, providing guidance and support to achieve departmental goals.",
                "Collaborate with cross-functional teams to ensure marketing efforts align with overall business objectives.",
                "Analyze market trends and competitor activity to identify opportunities for innovation and differentiation.",
                "Track and measure the performance of marketing campaigns, making data-driven decisions to optimize results.",
                "Stay up-to-date with industry trends and best practices to maintain a competitive edge.",
                "Manage marketing budgets and allocate resources effectively to maximize ROI."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field; Master’s degree preferred.",
                "Proven experience in marketing management or a similar leadership role.",
                "Strong leadership and team management skills.",
                "Excellent strategic thinking and problem-solving abilities.",
                "Ability to analyze complex data and draw actionable insights.",
                "Exceptional communication and interpersonal skills.",
                "Ability to thrive in a fast-paced, dynamic environment.",
                "Proficiency in marketing analytics tools and software."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const AffiliateMarketingManagerJobDescription = {
    title: "Affiliate Marketing Manager Job Description",
    jobRole: "An Affiliate Marketing Manager is responsible for developing and managing affiliate marketing programs to drive traffic, acquire customers, and increase sales through partnerships with affiliate partners.",
    preRead: "Use this Affiliate Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Affiliate Marketing Manager, you will develop and manage affiliate marketing programs to drive traffic, acquire customers, and increase sales through partnerships with affiliate partners. This role requires strong relationship-building skills, analytical abilities, and a deep understanding of digital marketing strategies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute affiliate marketing strategies to drive traffic and sales.",
                "Identify and recruit new affiliate partners to expand the affiliate network.",
                "Negotiate and manage contracts and agreements with affiliate partners.",
                "Monitor and analyze affiliate performance metrics to optimize campaigns and maximize ROI.",
                "Provide regular reporting and analysis on affiliate program performance.",
                "Stay up-to-date with industry trends and best practices in affiliate marketing.",
                "Collaborate with cross-functional teams to align affiliate marketing efforts with overall business goals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field.",
                "Proven experience in affiliate marketing or a similar role.",
                "Strong understanding of affiliate marketing concepts and best practices.",
                "Excellent communication and negotiation skills.",
                "Analytical mindset with the ability to interpret data and draw insights.",
                "Ability to build and maintain strong relationships with affiliate partners.",
                "Proficiency in affiliate marketing tools and platforms.",
                "Creative thinking and problem-solving abilities."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const EventMarketingManagerJobDescription = {
    title: "Event Marketing Manager Job Description",
    jobRole: "An Event Marketing Manager is responsible for planning, organizing, and executing events to promote products, services, or the overall brand of the company. They coordinate all aspects of event marketing, from conceptualization to post-event analysis.",
    preRead: "Use this Event Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Event Marketing Manager, you will be responsible for planning, organizing, and executing events to promote products, services, or the overall brand of the company. You will coordinate all aspects of event marketing, from conceptualization to post-event analysis. This role requires strong organizational skills, creativity, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute event marketing strategies to promote products, services, or the brand.",
                "Plan, organize, and coordinate all aspects of events, including venue selection, logistics, and catering.",
                "Collaborate with internal teams and external vendors to ensure the successful execution of events.",
                "Create promotional materials and collateral for events, including invitations, flyers, and signage.",
                "Manage event budgets and expenses to ensure cost-effectiveness and ROI.",
                "Measure and analyze the success of events, providing insights and recommendations for future improvements.",
                "Stay up-to-date with industry trends and best practices in event marketing.",
                "Build and maintain relationships with event partners, sponsors, and vendors."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in event marketing or a similar role.",
                "Strong organizational and project management skills.",
                "Excellent communication and interpersonal abilities.",
                "Creative thinking and problem-solving abilities.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Proficiency in event management software and tools.",
                "Flexibility to work evenings and weekends as needed for events."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const PublicRelationsSpecialistJobDescription = {
    title: "Public Relations Specialist Job Description",
    jobRole: "A Public Relations Specialist is responsible for managing the public image and reputation of a company or organization. They develop and execute PR campaigns to promote positive media coverage and enhance brand awareness.",
    preRead: "Use this Public Relations Specialist job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Public Relations Specialist, you will be responsible for managing the public image and reputation of a company or organization. You will develop and execute PR campaigns to promote positive media coverage and enhance brand awareness. This role requires strong communication skills, creativity, and strategic thinking."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement PR strategies to promote positive media coverage and enhance brand reputation.",
                "Write press releases, articles, and other PR materials to distribute to media outlets.",
                "Pitch stories and news angles to journalists and media contacts to secure media coverage.",
                "Monitor media coverage and respond to inquiries from journalists and media outlets.",
                "Organize and manage press events, interviews, and media appearances.",
                "Build and maintain relationships with media contacts, influencers, and industry stakeholders.",
                "Manage crisis communication and reputation management strategies as needed.",
                "Stay up-to-date with industry trends and best practices in PR and media relations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Public Relations, Communications, Journalism, or a related field.",
                "Proven experience in PR, media relations, or a similar role.",
                "Strong writing and editing skills, with the ability to craft compelling PR materials.",
                "Excellent communication and interpersonal abilities.",
                "Strategic thinking and problem-solving skills.",
                "Ability to work effectively under pressure and meet tight deadlines.",
                "Creativity and the ability to think outside the box.",
                "Proficiency in PR and media monitoring tools."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const CreativeDirectorJobDescription = {
    title: "Creative Director Job Description",
    jobRole: "A Creative Director is responsible for overseeing the creative vision and direction of marketing campaigns and projects. They lead a team of designers, writers, and other creative professionals to develop innovative and impactful marketing materials.",
    preRead: "Use this Creative Director job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Creative Director, you will be responsible for overseeing the creative vision and direction of marketing campaigns and projects. You will lead a team of designers, writers, and other creative professionals to develop innovative and impactful marketing materials. This role requires strong leadership skills, creativity, and strategic thinking."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and oversee the creative vision and direction of marketing campaigns and projects.",
                "Lead a team of designers, writers, and other creative professionals to develop innovative and impactful marketing materials.",
                "Collaborate with cross-functional teams to understand business objectives and develop creative solutions.",
                "Provide creative direction and feedback to ensure the quality and effectiveness of marketing materials.",
                "Manage multiple projects simultaneously, ensuring deadlines and budgets are met.",
                "Stay up-to-date with industry trends and best practices in design and marketing.",
                "Build and maintain relationships with clients, stakeholders, and external partners.",
                "Contribute to the development of creative strategies and initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Graphic Design, Marketing, Communications, or a related field.",
                "Proven experience in a creative leadership role, preferably in a marketing agency or creative studio.",
                "Strong leadership and team management skills.",
                "Excellent communication and interpersonal abilities.",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Proficiency in design software and tools.",
                "Portfolio showcasing creative work and achievements."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const MarketingCommunicationsManagerJobDescription = {
    title: "Marketing Communications Manager Job Description",
    jobRole: "A Marketing Communications Manager is responsible for developing and implementing marketing communication strategies to promote products, services, or the overall brand of the company. They oversee all aspects of marketing communications, including advertising, public relations, and internal communications.",
    preRead: "Use this Marketing Communications Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Communications Manager, you will be responsible for developing and implementing marketing communication strategies to promote products, services, or the overall brand of the company. You will oversee all aspects of marketing communications, including advertising, public relations, and internal communications. This role requires strong communication skills, creativity, and strategic thinking."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement marketing communication strategies to promote products, services, or the brand.",
                "Oversee all aspects of marketing communications, including advertising, public relations, and internal communications.",
                "Manage relationships with external agencies and vendors to execute marketing communication campaigns.",
                "Create and manage marketing collateral, including print materials, digital content, and multimedia assets.",
                "Develop and execute digital marketing campaigns, including email marketing, social media, and online advertising.",
                "Monitor and analyze the effectiveness of marketing communication efforts, providing insights and recommendations for optimization.",
                "Collaborate with cross-functional teams to align marketing communication efforts with business objectives.",
                "Stay up-to-date with industry trends and best practices in marketing communications."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, Public Relations, or a related field.",
                "Proven experience in marketing communications or a similar role.",
                "Strong written and verbal communication skills.",
                "Excellent project management and organizational abilities.",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Proficiency in digital marketing tools and platforms.",
                "Familiarity with marketing analytics and reporting."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};


export const WebContentManagerJobDescription = {
    title: "Web Content Manager Job Description",
    jobRole: "A Web Content Manager is responsible for developing, managing, and maintaining the content of a company's website. They work closely with marketing, design, and development teams to ensure that the website content is engaging, accurate, and up-to-date.",
    preRead: "Use this Web Content Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Web Content Manager, you will be responsible for developing, managing, and maintaining the content of a company's website. You will work closely with marketing, design, and development teams to ensure that the website content is engaging, accurate, and up-to-date. This role requires strong communication skills, attention to detail, and proficiency in web content management systems."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement a content strategy for the company's website.",
                "Create and publish high-quality content, including articles, blog posts, videos, and images.",
                "Manage and update website content using a content management system (CMS).",
                "Ensure that all website content is accurate, up-to-date, and aligned with brand guidelines.",
                "Optimize website content for search engines (SEO) to improve visibility and rankings.",
                "Monitor website performance and user engagement metrics, making adjustments as needed.",
                "Collaborate with marketing, design, and development teams to coordinate website updates and improvements.",
                "Stay up-to-date with industry trends and best practices in web content management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, Journalism, or a related field.",
                "Proven experience in web content management or a similar role.",
                "Strong writing and editing skills, with attention to grammar and style.",
                "Proficiency in content management systems (e.g., WordPress, Drupal, Joomla).",
                "Familiarity with web analytics tools (e.g., Google Analytics) and SEO best practices.",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Excellent communication and collaboration abilities."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const AdvertisingManagerJobDescription = {
    title: "Advertising Manager Job Description",
    jobRole: "An Advertising Manager is responsible for planning, implementing, and managing advertising campaigns to promote products, services, or the overall brand of the company. They work closely with marketing, creative, and media teams to develop effective advertising strategies.",
    preRead: "Use this Advertising Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Advertising Manager, you will be responsible for planning, implementing, and managing advertising campaigns to promote products, services, or the overall brand of the company. You will work closely with marketing, creative, and media teams to develop effective advertising strategies. This role requires strong project management skills, creativity, and strategic thinking."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement advertising strategies to promote products, services, or the brand.",
                "Plan and manage advertising campaigns across various channels, including print, digital, social media, and broadcast.",
                "Collaborate with creative teams to develop compelling ad creative and messaging.",
                "Manage relationships with advertising agencies and media partners.",
                "Monitor and analyze the performance of advertising campaigns, making adjustments as needed to optimize results.",
                "Stay up-to-date with industry trends and best practices in advertising.",
                "Manage advertising budgets and ensure campaigns are delivered on time and within budget.",
                "Present campaign proposals, strategies, and performance reports to stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Advertising, Communications, or a related field.",
                "Proven experience in advertising or a similar role.",
                "Strong project management and organizational skills.",
                "Excellent communication and negotiation abilities.",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Familiarity with advertising platforms and tools (e.g., Google Ads, Facebook Ads Manager).",
                "Knowledge of media planning and buying processes."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const MediaPlannerJobDescription = {
    title: "Media Planner Job Description",
    jobRole: "A Media Planner is responsible for developing, implementing, and managing media plans to achieve advertising objectives. They analyze target audiences, select appropriate media channels, negotiate media buys, and monitor campaign performance to optimize results.",
    preRead: "Use this Media Planner job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Media Planner, you will be responsible for developing, implementing, and managing media plans to achieve advertising objectives. You will analyze target audiences, select appropriate media channels, negotiate media buys, and monitor campaign performance to optimize results. This role requires strong analytical skills, negotiation abilities, and knowledge of media planning tools and techniques."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute media plans to meet advertising objectives and target audience reach goals.",
                "Conduct research and analysis to identify target audiences and media consumption habits.",
                "Select appropriate media channels, including digital, print, broadcast, outdoor, and social media.",
                "Negotiate media buys, including rates, placements, and added value opportunities.",
                "Monitor and analyze media campaign performance, providing insights and recommendations for optimization.",
                "Collaborate with internal teams and external partners to ensure seamless execution of media plans.",
                "Stay up-to-date with industry trends and best practices in media planning and buying.",
                "Manage media budgets and reconcile invoices with media vendors."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Advertising, Communications, or a related field.",
                "Proven experience in media planning or a similar role.",
                "Strong analytical and quantitative skills.",
                "Excellent communication and negotiation abilities.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Familiarity with media planning and buying tools (e.g., Nielsen, comScore, Kantar).",
                "Knowledge of media research methodologies and audience measurement metrics.",
                "Proficiency in Microsoft Excel and other data analysis tools."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const ContentMarketingManagerJobDescription = {
    title: "Content Marketing Manager Job Description",
    jobRole: "A Content Marketing Manager is responsible for developing and executing content strategies to drive brand awareness, engagement, and lead generation. They create compelling content across various channels, including blog posts, social media, email, and website.",
    preRead: "Use this Content Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Content Marketing Manager, you will be responsible for developing and executing content strategies to drive brand awareness, engagement, and lead generation. You will create compelling content across various channels, including blog posts, social media, email, and website. This role requires creativity, strategic thinking, and strong writing skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute content marketing strategies to achieve brand awareness and lead generation goals.",
                "Create and publish high-quality content across various channels, including blog posts, social media, email, and website.",
                "Collaborate with internal teams and external partners to develop content ideas and campaigns.",
                "Manage content calendars and editorial schedules to ensure consistent content production and distribution.",
                "Optimize content for search engines (SEO) and user engagement.",
                "Monitor and analyze content performance, providing insights and recommendations for optimization.",
                "Stay up-to-date with industry trends and best practices in content marketing.",
                "Manage content budgets and vendor relationships."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, Journalism, or a related field.",
                "Proven experience in content marketing or a similar role.",
                "Strong writing and editing skills, with attention to grammar and style.",
                "Experience creating content for digital channels, including blog posts, social media, and email.",
                "Knowledge of content management systems (e.g., WordPress) and web analytics tools (e.g., Google Analytics).",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Excellent communication and collaboration abilities."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const CommunityManagerJobDescription = {
    title: "Community Manager Job Description",
    jobRole: "A Community Manager is responsible for building, engaging, and managing online communities around a brand or product. They interact with community members, respond to inquiries, facilitate discussions, and drive community growth and engagement through various channels.",
    preRead: "Use this Community Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Community Manager, you will be responsible for building, engaging, and managing online communities around a brand or product. You will interact with community members, respond to inquiries, facilitate discussions, and drive community growth and engagement through various channels. This role requires strong communication skills, relationship-building abilities, and a passion for fostering online communities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Build and grow online communities around the brand or product.",
                "Engage with community members through various channels, including social media, forums, and chat platforms.",
                "Respond to inquiries, comments, and messages from community members in a timely and professional manner.",
                "Facilitate discussions, organize events, and create engaging content to foster community interaction.",
                "Monitor community activity, identify trends, and provide insights to inform community strategies.",
                "Collaborate with internal teams to align community initiatives with business goals and objectives.",
                "Develop and implement community engagement strategies to drive growth and retention.",
                "Stay up-to-date with industry trends and best practices in community management.",
                "Measure and analyze community metrics to track performance and effectiveness."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in community management or a similar role.",
                "Strong written and verbal communication skills.",
                "Experience with social media management tools and community platforms.",
                "Ability to build and maintain relationships with community members.",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Excellent organizational and time management abilities."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const GrowthHackerJobDescription = {
    title: "Growth Hacker Job Description",
    jobRole: "A Growth Hacker is responsible for driving rapid and sustainable user and revenue growth through innovative and data-driven marketing techniques. They experiment with various strategies, channels, and tactics to identify scalable growth opportunities and optimize conversion rates.",
    preRead: "Use this Growth Hacker job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Growth Hacker, you will be responsible for driving rapid and sustainable user and revenue growth through innovative and data-driven marketing techniques. You will experiment with various strategies, channels, and tactics to identify scalable growth opportunities and optimize conversion rates. This role requires a combination of creativity, analytical skills, and a deep understanding of digital marketing and user acquisition."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute growth strategies to acquire and retain users, customers, and revenue.",
                "Conduct experiments to test hypotheses and identify effective growth tactics.",
                "Optimize conversion funnels, landing pages, and user journeys to maximize conversion rates.",
                "Analyze data and metrics to track performance, identify trends, and uncover insights.",
                "Collaborate with cross-functional teams to align growth initiatives with business goals.",
                "Stay up-to-date with emerging trends and technologies in digital marketing and growth hacking.",
                "Identify and pursue partnerships, collaborations, and opportunities for expansion.",
                "Measure and report on key performance indicators (KPIs) to track progress and success."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, or a related field.",
                "Proven experience in growth hacking, digital marketing, or a similar role.",
                "Strong analytical and data-driven mindset.",
                "Experience with digital marketing tools and platforms (e.g., Google Analytics, Facebook Ads, A/B testing tools).",
                "Creative thinking and problem-solving skills.",
                "Ability to work effectively in a fast-paced and dynamic environment.",
                "Excellent communication and collaboration abilities.",
                "Entrepreneurial mindset and willingness to take risks."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const InfluencerMarketingManagerJobDescription = {
    title: "Influencer Marketing Manager Job Description",
    jobRole: "An Influencer Marketing Manager is responsible for developing and executing influencer marketing campaigns to promote products or services. They identify and engage with relevant influencers, negotiate partnerships, and track campaign performance to achieve marketing objectives.",
    preRead: "Use this Influencer Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Influencer Marketing Manager, you will be responsible for developing and executing influencer marketing campaigns to promote products or services. You will identify and engage with relevant influencers, negotiate partnerships, and track campaign performance to achieve marketing objectives. This role requires strong communication skills, relationship-building abilities, and a deep understanding of social media and influencer marketing trends."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute influencer marketing strategies and campaigns to promote products or services.",
                "Identify and engage with relevant influencers across various social media platforms.",
                "Negotiate partnership terms and agreements with influencers, including compensation and deliverables.",
                "Collaborate with internal teams to align influencer marketing initiatives with overall marketing goals.",
                "Track and analyze campaign performance metrics to measure effectiveness and ROI.",
                "Manage influencer relationships, including communication, feedback, and support.",
                "Stay up-to-date with industry trends and best practices in influencer marketing.",
                "Identify and pursue partnership opportunities with new influencers and content creators."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or a related field.",
                "Proven experience in influencer marketing, social media marketing, or a similar role.",
                "Strong understanding of social media platforms and influencer marketing trends.",
                "Excellent communication and negotiation skills.",
                "Ability to build and maintain relationships with influencers and content creators.",
                "Analytical mindset with the ability to interpret data and metrics.",
                "Creative thinking and problem-solving abilities.",
                "Ability to work effectively in a fast-paced and dynamic environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const GraphicDesignerJobDescription = {
    title: "Graphic Designer Job Description",
    jobRole: "A Graphic Designer is responsible for creating visual concepts, layouts, and designs for various marketing materials, websites, and other digital and print assets. They use creativity, design software, and artistic skills to develop visually appealing and effective designs that communicate the intended message.",
    preRead: "Use this Graphic Designer job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Graphic Designer, you will be responsible for creating visual concepts, layouts, and designs for various marketing materials, websites, and other digital and print assets. You will use creativity, design software, and artistic skills to develop visually appealing and effective designs that communicate the intended message. This role requires a strong portfolio of design work, proficiency in design software, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Create visual concepts, layouts, and designs for marketing materials, websites, and other digital and print assets.",
                "Collaborate with internal teams to understand design requirements and project objectives.",
                "Translate design briefs and requirements into high-quality visual designs that meet brand guidelines and standards.",
                "Use design software and tools to develop and refine designs, including Adobe Creative Suite (Photoshop, Illustrator, InDesign).",
                "Ensure accuracy, consistency, and quality in all design deliverables.",
                "Stay up-to-date with design trends, techniques, and software to continuously improve skills and knowledge.",
                "Communicate and present design concepts and ideas effectively to internal stakeholders.",
                "Collaborate with external vendors and agencies as needed for design projects."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Graphic Design, Visual Arts, or a related field.",
                "Proven experience as a graphic designer or a similar role.",
                "Strong portfolio of design work showcasing creativity and proficiency in design software.",
                "Proficiency in Adobe Creative Suite (Photoshop, Illustrator, InDesign) and other design tools.",
                "Understanding of design principles, typography, and color theory.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively under pressure and manage multiple projects simultaneously.",
                "Attention to detail and commitment to quality."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const VideoContentCreatorJobDescription = {
    title: "Video Content Creator Job Description",
    jobRole: "A Video Content Creator is responsible for producing engaging and compelling video content to promote products, services, or brands. They conceptualize, script, film, and edit videos for various platforms such as social media, websites, and advertising campaigns. Video Content Creators use their creativity, storytelling skills, and technical expertise to create videos that resonate with the target audience.",
    preRead: "Use this Video Content Creator job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Video Content Creator, you will be responsible for producing engaging and compelling video content to promote products, services, or brands. You will conceptualize, script, film, and edit videos for various platforms such as social media, websites, and advertising campaigns. This role requires creativity, storytelling skills, and proficiency in video production techniques and tools."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conceptualize, script, and storyboard video content ideas that align with marketing objectives and target audience preferences.",
                "Film and produce high-quality video content, including product demos, tutorials, testimonials, and promotional videos.",
                "Edit and post-process video footage to enhance visual appeal, storytelling, and overall quality.",
                "Collaborate with internal teams to ensure video content meets brand guidelines, messaging, and objectives.",
                "Optimize video content for various platforms and formats, including social media, websites, and email campaigns.",
                "Stay up-to-date with video production trends, techniques, and technologies to continuously improve skills and knowledge.",
                "Monitor and analyze video performance metrics to assess effectiveness and identify opportunities for improvement.",
                "Manage video production schedules, budgets, and resources to ensure timely and cost-effective delivery of projects."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Film Production, Digital Media, Marketing, or a related field.",
                "Proven experience as a video content creator, videographer, or a similar role.",
                "Proficiency in video production and editing software, such as Adobe Premiere Pro, Final Cut Pro, or DaVinci Resolve.",
                "Strong storytelling and creative skills with the ability to translate ideas into compelling video content.",
                "Excellent videography and cinematography skills, including camera operation, lighting, and composition.",
                "Ability to work independently and collaboratively in a fast-paced and dynamic environment.",
                "Strong communication and project management skills.",
                "Attention to detail and commitment to quality."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const EcommerceMarketingManagerJobDescription = {
    title: "E-commerce Marketing Manager Job Description",
    jobRole: "An E-commerce Marketing Manager is responsible for developing and implementing marketing strategies to drive online sales and revenue for an e-commerce business. They oversee digital marketing campaigns, optimize website performance, and analyze customer data to identify opportunities for growth and improvement. E-commerce Marketing Managers use their expertise in online marketing channels, analytics, and e-commerce platforms to increase brand visibility, attract customers, and maximize sales.",
    preRead: "Use this E-commerce Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an E-commerce Marketing Manager, you will be responsible for developing and implementing marketing strategies to drive online sales and revenue for an e-commerce business. You will oversee digital marketing campaigns, optimize website performance, and analyze customer data to identify opportunities for growth and improvement. This role requires strong analytical skills, strategic thinking, and proficiency in online marketing channels and e-commerce platforms."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute marketing strategies to drive online sales and revenue growth.",
                "Plan and manage digital marketing campaigns across various channels, including email, social media, search engine marketing (SEM), and display advertising.",
                "Optimize website performance and user experience to increase conversion rates and sales.",
                "Analyze customer data and behavior to identify trends, preferences, and opportunities for improvement.",
                "Collaborate with internal teams to develop and launch new products, promotions, and initiatives.",
                "Monitor and report on key performance indicators (KPIs) to track the effectiveness of marketing campaigns and initiatives.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in e-commerce and digital marketing.",
                "Manage relationships with external partners, agencies, and vendors to support marketing efforts."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field.",
                "Proven experience in e-commerce marketing, digital marketing, or a similar role.",
                "Strong understanding of online marketing channels, including email, social media, SEM, and display advertising.",
                "Experience with e-commerce platforms such as Shopify, Magento, or WooCommerce.",
                "Proficiency in web analytics tools such as Google Analytics or Adobe Analytics.",
                "Excellent analytical, strategic, and problem-solving skills.",
                "Strong communication and collaboration skills.",
                "Ability to work effectively in a fast-paced and dynamic environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const MobileMarketingManagerJobDescription = {
    title: "Mobile Marketing Manager Job Description",
    jobRole: "A Mobile Marketing Manager is responsible for developing and implementing marketing strategies to promote mobile applications and drive user acquisition, engagement, and retention. They oversee mobile marketing campaigns, analyze user data, and optimize app performance to increase downloads, usage, and revenue. Mobile Marketing Managers use their expertise in mobile marketing channels, analytics, and user experience to achieve business goals and maximize app success.",
    preRead: "Use this Mobile Marketing Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mobile Marketing Manager, you will be responsible for developing and implementing marketing strategies to promote mobile applications and drive user acquisition, engagement, and retention. You will oversee mobile marketing campaigns, analyze user data, and optimize app performance to increase downloads, usage, and revenue. This role requires strong analytical skills, strategic thinking, and proficiency in mobile marketing channels and analytics."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute marketing strategies to promote mobile applications and drive user acquisition, engagement, and retention.",
                "Plan and manage mobile marketing campaigns across various channels, including app stores, mobile advertising, push notifications, and in-app messaging.",
                "Analyze user data and behavior to identify trends, preferences, and opportunities for improvement.",
                "Optimize app performance and user experience to increase downloads, usage, and revenue.",
                "Monitor and report on key performance indicators (KPIs) to track the effectiveness of mobile marketing campaigns and initiatives.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in mobile marketing and app development.",
                "Collaborate with internal teams, including product, design, and development, to drive app success and achieve business goals.",
                "Manage relationships with external partners, agencies, and vendors to support mobile marketing efforts."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field.",
                "Proven experience in mobile marketing, digital marketing, or a similar role.",
                "Strong understanding of mobile marketing channels, including app stores, mobile advertising, push notifications, and in-app messaging.",
                "Experience with mobile analytics tools such as App Annie, Mixpanel, or Firebase.",
                "Excellent analytical, strategic, and problem-solving skills.",
                "Strong communication and collaboration skills.",
                "Ability to work effectively in a fast-paced and dynamic environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const UXDesignerJobDescription = {
    title: "UX Designer Job Description",
    jobRole: "A UX Designer is responsible for creating intuitive and engaging user experiences for digital products and platforms. They collaborate with cross-functional teams to understand user needs, define user journeys, and design user interfaces that are visually appealing, functional, and easy to use. UX Designers use their expertise in user research, wireframing, prototyping, and usability testing to ensure that digital products meet user requirements and business goals.",
    preRead: "Use this UX Designer job description template to attract qualified candidates for your design team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a UX Designer, you will be responsible for creating intuitive and engaging user experiences for digital products and platforms. You will collaborate with cross-functional teams to understand user needs, define user journeys, and design user interfaces that are visually appealing, functional, and easy to use. This role requires strong problem-solving skills, creativity, and proficiency in UX design principles and tools."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct user research to understand user needs, preferences, and behaviors.",
                "Define user personas, user journeys, and use cases to inform design decisions.",
                "Create wireframes, mockups, and prototypes to visualize and communicate design concepts.",
                "Design user interfaces that are visually appealing, functional, and intuitive to use.",
                "Collaborate with product managers, developers, and other stakeholders to translate user requirements into design solutions.",
                "Conduct usability testing and gather feedback to iterate and improve design solutions.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in UX design.",
                "Advocate for user-centered design principles and processes within the organization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Design, Human-Computer Interaction, or a related field.",
                "Proven experience as a UX Designer, UI Designer, or a similar role.",
                "Strong portfolio showcasing UX design projects and proficiency in design tools such as Sketch, Adobe XD, or Figma.",
                "Excellent understanding of user-centered design principles and methodologies.",
                "Experience with user research methods, wireframing, prototyping, and usability testing.",
                "Strong problem-solving, analytical, and creative thinking skills.",
                "Excellent communication and collaboration skills.",
                "Ability to work effectively in a fast-paced and collaborative environment."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const MarketingAutomationSpecialistJobDescription = {
    title: "Marketing Automation Specialist Job Description",
    jobRole: "A Marketing Automation Specialist is responsible for managing and optimizing marketing automation platforms to streamline marketing processes, generate leads, and drive customer engagement. They design, execute, and monitor automated marketing campaigns, analyze performance metrics, and implement improvements to enhance campaign effectiveness. Marketing Automation Specialists collaborate with marketing teams to develop targeted messaging and personalized experiences that resonate with audiences.",
    preRead: "Use this Marketing Automation Specialist job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Automation Specialist, you will be responsible for managing and optimizing marketing automation platforms to streamline marketing processes, generate leads, and drive customer engagement. You will design, execute, and monitor automated marketing campaigns, analyze performance metrics, and implement improvements to enhance campaign effectiveness. This role requires strong analytical skills, technical proficiency, and a deep understanding of marketing automation best practices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage marketing automation platforms (e.g., HubSpot, Marketo, Pardot) to create, execute, and monitor automated marketing campaigns.",
                "Design and implement automated workflows, lead nurturing programs, and personalized email campaigns to drive lead generation and customer engagement.",
                "Segment and target audiences based on demographic, behavioral, and firmographic data to deliver relevant and timely marketing messages.",
                "Analyze campaign performance metrics, including open rates, click-through rates, and conversion rates, to measure effectiveness and identify optimization opportunities.",
                "Optimize marketing automation workflows, email templates, and landing pages to improve conversion rates and campaign performance.",
                "Collaborate with marketing teams to develop targeted messaging, content, and offers that resonate with audience segments.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in marketing automation.",
                "Provide training and support to marketing teams on marketing automation tools and processes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field.",
                "Proven experience in marketing automation, email marketing, or a similar role.",
                "Strong technical proficiency with marketing automation platforms (e.g., HubSpot, Marketo, Pardot) and CRM systems (e.g., Salesforce).",
                "Excellent analytical and problem-solving skills, with the ability to interpret data and optimize campaigns for performance.",
                "Creative thinking and attention to detail, with the ability to develop compelling messaging and personalized experiences.",
                "Strong communication and collaboration skills, with the ability to work cross-functionally and provide training and support to marketing teams."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const DigitalAdvertisingSpecialistJobDescription = {
    title: "Digital Advertising Specialist Job Description",
    jobRole: "A Digital Advertising Specialist is responsible for planning, executing, and optimizing digital advertising campaigns to drive brand awareness, website traffic, and customer acquisition. They leverage digital advertising platforms such as Google Ads, Facebook Ads, and LinkedIn Ads to target audiences, manage campaign budgets, and analyze performance metrics. Digital Advertising Specialists collaborate with marketing teams to develop ad creatives, messaging, and landing pages that align with campaign objectives and drive results.",
    preRead: "Use this Digital Advertising Specialist job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Digital Advertising Specialist, you will be responsible for planning, executing, and optimizing digital advertising campaigns to drive brand awareness, website traffic, and customer acquisition. You will leverage digital advertising platforms such as Google Ads, Facebook Ads, and LinkedIn Ads to target audiences, manage campaign budgets, and analyze performance metrics. This role requires strong analytical skills, creative thinking, and proficiency in digital advertising platforms and tactics."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Plan, execute, and optimize digital advertising campaigns across various platforms, including Google Ads, Facebook Ads, LinkedIn Ads, and display networks.",
                "Identify target audiences, set campaign objectives, and develop targeting strategies to reach desired audience segments.",
                "Create and manage ad campaigns, ad groups, and ad creatives, including text ads, image ads, and video ads.",
                "Monitor campaign performance metrics, such as click-through rates, conversion rates, and return on ad spend (ROAS), and make data-driven optimizations to improve campaign effectiveness.",
                "Conduct keyword research, competitive analysis, and audience segmentation to inform campaign strategy and targeting.",
                "Collaborate with marketing teams to develop ad creatives, messaging, and landing pages that align with campaign objectives and brand guidelines.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in digital advertising.",
                "Provide regular reporting and analysis of campaign performance to stakeholders, including insights and recommendations for optimization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Advertising, Business Administration, or a related field.",
                "Proven experience in digital advertising, PPC advertising, or a similar role.",
                "Strong proficiency in digital advertising platforms such as Google Ads, Facebook Ads, and LinkedIn Ads.",
                "Excellent analytical and problem-solving skills, with the ability to interpret data and optimize campaigns for performance.",
                "Creative thinking and attention to detail, with the ability to develop compelling ad creatives and messaging.",
                "Strong communication and collaboration skills, with the ability to work cross-functionally and provide regular reporting and analysis."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const CustomerRelationshipManagerJobDescription = {
    title: "Customer Relationship Manager Job Description",
    jobRole: "A Customer Relationship Manager (CRM) is responsible for managing relationships with existing customers to maximize customer satisfaction, retention, and loyalty. They oversee customer interactions, address customer inquiries and concerns, and ensure a positive customer experience at every touchpoint. CRM professionals analyze customer data, identify trends and opportunities, and develop strategies to enhance customer engagement and drive business growth.",
    preRead: "Use this Customer Relationship Manager job description template to attract qualified candidates for your customer service team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Customer Relationship Manager (CRM), you will be responsible for managing relationships with existing customers to maximize customer satisfaction, retention, and loyalty. You will oversee customer interactions, address customer inquiries and concerns, and ensure a positive customer experience at every touchpoint. This role requires strong interpersonal skills, problem-solving abilities, and a deep understanding of customer relationship management principles."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Build and maintain relationships with existing customers through regular communication and engagement.",
                "Serve as the primary point of contact for customer inquiries, concerns, and escalations.",
                "Ensure timely resolution of customer issues and complaints to maintain customer satisfaction.",
                "Proactively reach out to customers to gather feedback, assess satisfaction levels, and identify opportunities for improvement.",
                "Collaborate with cross-functional teams to address customer needs and deliver solutions that meet or exceed customer expectations.",
                "Analyze customer data and feedback to identify trends, patterns, and opportunities for enhancing the customer experience.",
                "Develop and implement strategies to improve customer retention, loyalty, and lifetime value.",
                "Stay up-to-date with industry trends and best practices in customer relationship management.",
                "Provide regular reporting and analysis of customer engagement metrics and KPIs."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or a related field.",
                "Proven experience in customer relationship management, customer service, or a similar role.",
                "Strong interpersonal and communication skills, with the ability to build rapport and trust with customers.",
                "Excellent problem-solving and conflict resolution abilities.",
                "Analytical mindset, with the ability to interpret data and extract insights.",
                "Ability to work collaboratively with cross-functional teams.",
                "Proficiency in CRM software and customer support tools.",
                "Strong organizational and time management skills.",
                "Customer-centric mindset, with a focus on delivering exceptional customer experiences."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development and training opportunities.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};


export const CampaignManagerJobDescription = {
    title: "Campaign Manager Job Description",
    jobRole: "A Campaign Manager is responsible for planning, executing, and managing marketing campaigns to promote products, services, or initiatives. They develop campaign strategies, coordinate with cross-functional teams, and analyze campaign performance to optimize results. Campaign Managers oversee the implementation of various marketing tactics, such as digital advertising, email marketing, and social media, to achieve campaign objectives and drive business growth.",
    preRead: "Use this Campaign Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Campaign Manager, you will be responsible for planning, executing, and managing marketing campaigns to promote products, services, or initiatives. You will develop campaign strategies, coordinate with cross-functional teams, and analyze campaign performance to optimize results. This role requires strong project management skills, strategic thinking, and the ability to collaborate with stakeholders to achieve campaign objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute marketing campaigns to promote products, services, or initiatives.",
                "Define campaign objectives, target audiences, and key messages.",
                "Create campaign strategies and plans, including timelines, budgets, and resources.",
                "Coordinate with cross-functional teams, including creative, digital, and sales teams, to execute campaign tactics.",
                "Manage campaign budgets, timelines, and resources to ensure successful execution.",
                "Monitor and analyze campaign performance, including key performance indicators (KPIs), to measure effectiveness and optimize results.",
                "Identify opportunities for campaign optimization and recommend adjustments to improve performance.",
                "Collaborate with internal and external stakeholders, including agencies and vendors, to support campaign objectives.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in marketing and advertising."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, Business Administration, or a related field; Master’s degree is a plus.",
                "Proven experience in campaign management, marketing, or a related role.",
                "Strong project management skills, with the ability to manage multiple projects and priorities simultaneously.",
                "Excellent communication and collaboration skills, with the ability to work cross-functionally and lead projects.",
                "Analytical mindset with the ability to analyze data, derive insights, and make data-driven decisions.",
                "Creative thinking and problem-solving skills, with the ability to develop innovative campaign strategies and solutions.",
                "Experience with marketing analytics tools and platforms, such as Google Analytics, Adobe Analytics, or similar.",
                "Detail-oriented with a focus on quality and accuracy.",
                "Ability to thrive in a fast-paced and dynamic environment.",
                "Continuous learner with a growth mindset, eager to learn and adapt to new technologies and methodologies."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        }
    ]
}

export const MarketingOperationsManagerJobDescription = {
    title: "Marketing Operations Manager Job Description",
    jobRole: "A Marketing Operations Manager is responsible for overseeing the operational aspects of marketing campaigns and initiatives. They manage marketing processes, systems, and technologies to optimize efficiency and effectiveness. Marketing Operations Managers collaborate with cross-functional teams to implement marketing strategies, track performance metrics, and drive continuous improvement in marketing operations.",
    preRead: "Use this Marketing Operations Manager job description template to attract qualified candidates for your marketing team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Operations Manager, you will oversee the operational aspects of marketing campaigns and initiatives. You will manage marketing processes, systems, and technologies to optimize efficiency and effectiveness. This role requires strong project management skills, analytical abilities, and expertise in marketing automation and technology platforms."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage marketing operations processes, including campaign planning, execution, and measurement.",
                "Implement and optimize marketing automation systems and technologies to support campaign execution and performance tracking.",
                "Collaborate with cross-functional teams, including marketing, sales, and IT, to align marketing operations with business objectives.",
                "Develop and implement marketing workflows, templates, and best practices to streamline processes and improve efficiency.",
                "Monitor and analyze marketing performance metrics, such as campaign ROI, lead conversion rates, and customer acquisition costs.",
                "Identify opportunities for process improvement and automation to drive efficiencies and scalability.",
                "Oversee the maintenance and management of marketing databases, lists, and contact records.",
                "Provide training and support to marketing teams on marketing operations processes, systems, and tools.",
                "Stay up-to-date with industry trends, best practices, and emerging technologies in marketing operations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or a related field; Master’s degree is a plus.",
                "Proven experience in marketing operations, marketing automation, or a related role.",
                "Strong project management skills, with the ability to manage multiple projects and priorities simultaneously.",
                "Expertise in marketing automation platforms, such as HubSpot, Marketo, or Pardot.",
                "Solid understanding of marketing processes, workflows, and best practices.",
                "Analytical mindset with the ability to analyze data, derive insights, and make data-driven decisions.",
                "Excellent communication and collaboration skills, with the ability to work cross-functionally and lead projects.",
                "Detail-oriented with a focus on quality and accuracy.",
                "Ability to thrive in a fast-paced and dynamic environment.",
                "Continuous learner with a growth mindset, eager to learn and adapt to new technologies and methodologies."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const ProductManagerJobDescription = {
    title: "Product Manager Job Description",
    jobRole: "A Product Manager is responsible for the development, planning, and execution of a product or product line throughout its lifecycle. They work closely with cross-functional teams to define product requirements, prioritize features, and drive product development. Product Managers analyze market trends, gather customer feedback, and make data-driven decisions to ensure the success of their products.",
    preRead: "Use this Product Manager job description template to attract qualified candidates for your product management team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Product Manager, you will lead the development and management of a product or product line from concept to launch. You will work closely with cross-functional teams, including engineering, design, marketing, and sales, to define product requirements, prioritize features, and drive product development. This role requires strong analytical skills, strategic thinking, and the ability to balance competing priorities to deliver successful products."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Define and prioritize product requirements based on market analysis, customer feedback, and business objectives.",
                "Work closely with engineering, design, and other cross-functional teams to develop and launch new products and features.",
                "Create and maintain product roadmaps, timelines, and release plans.",
                "Analyze market trends, competitive landscape, and customer needs to identify opportunities for product innovation and improvement.",
                "Gather and prioritize customer feedback and incorporate it into product planning and development processes.",
                "Collaborate with marketing and sales teams to develop go-to-market strategies, messaging, and sales enablement materials.",
                "Track and analyze key performance metrics, such as user engagement, retention, and satisfaction, to measure product success and inform future iterations.",
                "Manage product launches and coordinate cross-functional activities to ensure successful product releases.",
                "Stay informed about industry trends, emerging technologies, and best practices in product management.",
                "Provide leadership and guidance to cross-functional teams and stakeholders throughout the product lifecycle."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business, Computer Science, Engineering, or related field; MBA is a plus.",
                "Proven experience in product management or related roles, with a track record of successfully launching and managing products.",
                "Strong analytical and problem-solving skills, with the ability to make data-driven decisions.",
                "Excellent communication and collaboration skills, with the ability to work effectively with cross-functional teams and stakeholders.",
                "Strategic thinker with the ability to define product vision and roadmap.",
                "Detail-oriented with strong organizational and project management skills.",
                "Ability to thrive in a fast-paced and dynamic environment, with a sense of urgency and drive for results.",
                "Knowledge of Agile development methodologies and product management tools is preferred.",
                "Passion for technology and innovation, with a customer-centric mindset.",
                "Strong leadership and influencing skills, with the ability to inspire and motivate teams towards a common goal."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const ConversionRateOptimizationCROSpecialistJobDescription = {
    title: "Conversion Rate Optimization (CRO) Specialist Job Description",
    jobRole: "A Conversion Rate Optimization (CRO) Specialist is responsible for improving the performance of digital properties, such as websites and mobile apps, to increase conversion rates and achieve business objectives. They use data-driven insights, A/B testing, and optimization techniques to identify and implement improvements that enhance user experience and drive conversions.",
    preRead: "Use this Conversion Rate Optimization (CRO) Specialist job description template to attract qualified candidates for your digital marketing or optimization team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Conversion Rate Optimization (CRO) Specialist, you will focus on improving the performance of digital properties to increase conversion rates and achieve business objectives. You will use data-driven insights, A/B testing, and optimization techniques to identify and implement improvements that enhance user experience and drive conversions. This role requires strong analytical skills, technical expertise, and a deep understanding of user behavior."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct data analysis and user research to identify areas for improvement and optimization.",
                "Plan, execute, and analyze A/B tests and multivariate tests to optimize website and app performance.",
                "Develop hypotheses and recommendations for improving conversion rates based on data insights and best practices.",
                "Implement and optimize website and app elements, such as landing pages, forms, and checkout processes, to improve user experience and drive conversions.",
                "Collaborate with cross-functional teams, including marketing, design, and development, to implement optimization initiatives.",
                "Monitor key performance metrics, such as conversion rates, bounce rates, and average session duration, to track performance and identify opportunities for improvement.",
                "Stay informed about industry trends, best practices, and emerging technologies in conversion rate optimization.",
                "Provide regular reporting and analysis on conversion rate optimization efforts and performance results.",
                "Contribute to the development of conversion rate optimization strategies and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, Statistics, Computer Science, or related field.",
                "Proven experience in conversion rate optimization, digital marketing, or related roles.",
                "Strong analytical and quantitative skills, with the ability to analyze data and draw actionable insights.",
                "Experience with A/B testing and multivariate testing tools, such as Google Optimize, Optimizely, or VWO.",
                "Knowledge of web analytics tools, such as Google Analytics or Adobe Analytics.",
                "Understanding of user experience principles and best practices.",
                "Proficiency in HTML, CSS, and JavaScript is a plus.",
                "Excellent communication and collaboration skills, with the ability to work effectively with cross-functional teams.",
                "Detail-oriented with strong organizational and project management skills.",
                "Ability to thrive in a fast-paced and dynamic environment, with a focus on continuous improvement and optimization."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const MarketingConsultantJobDescription = {
    title: "Marketing Consultant Job Description",
    jobRole: "A Marketing Consultant provides expert advice and guidance to businesses on marketing strategies, tactics, and campaigns to help them achieve their marketing objectives. They analyze market trends, conduct research, and develop customized marketing plans tailored to the client's needs. Marketing Consultants may specialize in areas such as digital marketing, content marketing, or social media marketing.",
    preRead: "Use this Marketing Consultant job description template to attract experienced marketing professionals to provide consulting services to your clients. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Consultant, you will work closely with clients to understand their business objectives, target audience, and competitive landscape. You will provide strategic guidance and recommendations on marketing strategies, tactics, and campaigns to help them achieve their goals. This role requires strong analytical skills, creativity, and the ability to develop innovative marketing solutions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct market research and competitive analysis to identify opportunities and challenges.",
                "Develop customized marketing plans and strategies tailored to the client's business objectives and target audience.",
                "Provide expert advice and guidance on marketing tactics, channels, and campaigns, including digital marketing, content marketing, social media marketing, and more.",
                "Collaborate with clients to develop marketing budgets, timelines, and KPIs to measure success.",
                "Create and deliver presentations, reports, and recommendations to clients.",
                "Monitor and analyze marketing performance metrics to track progress and identify areas for optimization.",
                "Stay informed about industry trends, best practices, and emerging technologies in marketing.",
                "Build and maintain strong client relationships to drive customer satisfaction and retention.",
                "Provide training and support to clients on marketing tools, platforms, and techniques as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business, Communications, or related field; MBA is a plus.",
                "Proven experience in marketing consulting, agency, or related roles.",
                "Strong understanding of marketing principles, tactics, and strategies.",
                "Excellent analytical and problem-solving skills, with the ability to think strategically and creatively.",
                "Strong communication and presentation skills, with the ability to articulate complex concepts to clients.",
                "Ability to work effectively with cross-functional teams and stakeholders.",
                "Detail-oriented with strong organizational and project management skills.",
                "Ability to thrive in a fast-paced and dynamic environment, with a focus on delivering results.",
                "Proficiency in marketing tools, platforms, and software.",
                "Certifications in marketing or related fields are a plus."
            ]
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive salary commensurate with experience.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with company match.",
                "Paid time off and holidays.",
                "Professional development and training opportunities.",
                "Flexible work schedule and remote work options.",
                "Employee wellness programs and activities."
            ]
        }
    ]
};

export const AnalyticsManagerJobDescription = {
    title: "Analytics Manager Job Description",
    jobRole: "An Analytics Manager is responsible for leading a team of analysts to collect, analyze, and interpret data to provide insights and recommendations to support business decision-making. They develop and implement analytics strategies, tools, and processes to optimize data collection and analysis, and they communicate findings to stakeholders to inform strategic initiatives and drive business growth.",
    preRead: "Use this Analytics Manager job description template to attract experienced analytics professionals to lead your data analytics team. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Analytics Manager, you will lead a team of analysts to collect, analyze, and interpret data to provide actionable insights and recommendations to support business decision-making. You will develop and implement analytics strategies, tools, and processes to optimize data collection and analysis, and you will communicate findings to stakeholders to inform strategic initiatives and drive business growth. This role requires strong leadership skills, analytical expertise, and the ability to translate data into actionable insights."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Lead and manage a team of analysts to collect, analyze, and interpret data to provide insights and recommendations to stakeholders.",
                "Develop and implement analytics strategies, tools, and processes to optimize data collection, analysis, and reporting.",
                "Define and track key performance metrics and KPIs to measure and monitor business performance.",
                "Collaborate with cross-functional teams and stakeholders to understand business objectives, priorities, and requirements.",
                "Translate business questions and objectives into analytics projects and initiatives.",
                "Design and execute data analysis and modeling projects to uncover insights and drive business decision-making.",
                "Communicate findings, insights, and recommendations to stakeholders through reports, presentations, and data visualization.",
                "Stay informed about industry trends, best practices, and emerging technologies in analytics."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business, Statistics, Mathematics, Economics, Computer Science, or related field; Master’s degree is a plus.",
                "Proven experience in data analytics, business intelligence, or related roles.",
                "Strong analytical and problem-solving skills, with the ability to work with large datasets and complex data structures.",
                "Experience with data analytics tools and software such as SQL, Python, R, Tableau, or Power BI.",
                "Experience in statistical analysis, data modeling, and predictive analytics.",
                "Excellent communication and presentation skills, with the ability to convey complex technical concepts to non-technical stakeholders.",
                "Strong leadership and team management skills, with the ability to motivate and develop team members.",
                "Ability to manage multiple projects and priorities in a fast-paced and dynamic environment.",
                "Attention to detail and accuracy in data analysis and reporting.",
                "Continuous learning mindset to stay updated on industry trends and technologies."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};


export const CreativeStrategistJobDescription = {
    title: "Creative Strategist Job Description",
    jobRole: "A Creative Strategist combines creative thinking with strategic planning to develop innovative marketing campaigns and initiatives. They collaborate with cross-functional teams to understand business objectives and target audience insights, and they develop creative concepts, messaging, and content to engage and resonate with audiences.",
    preRead: "Use this Creative Strategist job description template to attract creative professionals who can develop impactful marketing campaigns for your organization. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Creative Strategist, you will work closely with cross-functional teams to develop innovative marketing campaigns and initiatives that drive business objectives and resonate with target audiences. You will use your creative thinking and strategic planning skills to develop compelling concepts, messaging, and content that engage and inspire audiences. This role requires a combination of creative expertise, strategic thinking, and collaboration."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with cross-functional teams to understand business objectives, target audience insights, and marketing goals.",
                "Develop creative concepts, messaging, and content for marketing campaigns, initiatives, and assets across various channels.",
                "Translate strategic insights and brand guidelines into creative executions that resonate with target audiences and drive engagement.",
                "Lead brainstorming sessions and creative workshops to generate ideas and concepts for marketing campaigns and projects.",
                "Work with internal and external stakeholders, including creative teams, designers, writers, and agencies, to bring creative concepts to life.",
                "Conduct market research and competitive analysis to identify trends, opportunities, and best practices in creative marketing.",
                "Stay informed about industry trends, emerging technologies, and best practices in creative marketing and advertising.",
                "Collaborate with analytics teams to measure and evaluate the effectiveness of marketing campaigns and initiatives.",
                "Provide strategic recommendations and insights to optimize creative executions and drive business results."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Advertising, Communications, or related field.",
                "Proven experience in creative marketing, advertising, or related roles.",
                "Strong creative thinking and ideation skills, with the ability to develop innovative and impactful marketing concepts.",
                "Excellent storytelling and communication skills, with the ability to convey complex ideas and concepts in a clear and compelling manner.",
                "Ability to work effectively in a collaborative, cross-functional environment, with the flexibility to adapt to changing priorities and requirements.",
                "Strong project management and organizational skills, with the ability to manage multiple projects and deadlines simultaneously.",
                "Proficiency in creative software and tools such as Adobe Creative Suite (Photoshop, Illustrator, InDesign), Sketch, or Figma.",
                "Knowledge of digital marketing channels, platforms, and best practices.",
                "Attention to detail and a commitment to quality in creative executions.",
                "Passion for creativity, innovation, and continuous learning."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $100,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const SearchEngineMarketingSEMSpecialistJobDescription = {
    title: "Search Engine Marketing (SEM) Specialist Job Description",
    jobRole: "A Search Engine Marketing (SEM) Specialist is responsible for managing and optimizing pay-per-click (PPC) advertising campaigns to drive traffic, leads, and conversions through search engine platforms such as Google Ads and Bing Ads. They conduct keyword research, create ad copy, optimize landing pages, and analyze campaign performance to maximize ROI and achieve marketing objectives.",
    preRead: "Use this Search Engine Marketing (SEM) Specialist job description template to attract qualified candidates who can effectively manage PPC advertising campaigns to drive results for your organization. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Search Engine Marketing (SEM) Specialist, you will be responsible for managing and optimizing pay-per-click (PPC) advertising campaigns across search engine platforms such as Google Ads and Bing Ads. You will conduct keyword research, create ad copy, optimize landing pages, and analyze campaign performance to drive traffic, leads, and conversions. This role requires strong analytical skills, attention to detail, and expertise in PPC advertising platforms."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement PPC advertising campaigns across search engine platforms such as Google Ads and Bing Ads.",
                "Conduct keyword research to identify relevant and high-performing keywords for PPC campaigns.",
                "Create compelling ad copy and design effective ad creatives to maximize click-through rates (CTR) and conversions.",
                "Optimize landing pages to improve ad relevance, user experience, and conversion rates.",
                "Monitor and analyze campaign performance, including click-through rates, conversion rates, and return on ad spend (ROAS).",
                "Identify opportunities for campaign optimization and implement strategies to improve performance and achieve marketing objectives.",
                "Perform A/B testing and multivariate testing to optimize ad copy, ad creatives, and landing pages.",
                "Generate regular reports on campaign performance and provide insights and recommendations for improvement.",
                "Stay informed about industry trends, best practices, and updates in PPC advertising platforms.",
                "Collaborate with cross-functional teams, including marketing, design, and analytics teams, to align PPC campaigns with overall marketing strategies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Advertising, Communications, or related field.",
                "Proven experience in managing PPC advertising campaigns, preferably in Google Ads and Bing Ads.",
                "Strong understanding of PPC advertising principles, strategies, and best practices.",
                "Proficiency in PPC advertising platforms such as Google Ads (formerly Google AdWords) and Bing Ads.",
                "Experience with keyword research tools and analytics platforms such as Google Analytics.",
                "Analytical mindset with the ability to interpret data, analyze campaign performance, and identify optimization opportunities.",
                "Excellent written and verbal communication skills, with the ability to create compelling ad copy and communicate campaign performance.",
                "Attention to detail and a commitment to quality in campaign setup, optimization, and reporting.",
                "Ability to manage multiple projects and deadlines in a fast-paced environment.",
                "Google Ads certification or Bing Ads certification is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const TradeMarketingManagerJobDescription = {
    title: "Trade Marketing Manager Job Description",
    jobRole: "A Trade Marketing Manager is responsible for developing and implementing marketing strategies to drive sales and visibility of products within retail channels. They collaborate with sales teams to create promotional campaigns, manage trade shows and events, and analyze market trends to identify opportunities for growth. This role requires a combination of marketing expertise, analytical skills, and understanding of retail environments.",
    preRead: "Use this Trade Marketing Manager job description template to attract qualified candidates who can develop and execute marketing strategies to drive product sales and visibility in retail channels. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Trade Marketing Manager, you will be responsible for developing and executing marketing strategies to promote products within retail channels. You will collaborate with sales teams to create promotional campaigns, manage trade shows and events, and analyze market trends to identify opportunities for growth. This role requires strong marketing skills, strategic thinking, and the ability to work effectively in a fast-paced environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute trade marketing strategies to drive product sales and visibility in retail channels.",
                "Collaborate with sales teams to create promotional campaigns, incentives, and marketing materials for retail partners.",
                "Manage trade shows, exhibitions, and events to showcase products and engage with retail partners.",
                "Analyze market trends, consumer behavior, and competitive landscape to identify opportunities for growth.",
                "Conduct market research and gather insights to inform marketing strategies and product positioning.",
                "Work closely with product development teams to understand product features, benefits, and target audience.",
                "Monitor and evaluate the performance of trade marketing activities, including ROI and sales metrics.",
                "Develop relationships with key retail partners and negotiate marketing agreements and promotional opportunities.",
                "Provide regular reports and updates to management on trade marketing initiatives and results.",
                "Stay informed about industry trends, best practices, and innovations in trade marketing."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or related field; MBA preferred.",
                "Proven experience in trade marketing, retail marketing, or related roles.",
                "Strong understanding of marketing principles, strategies, and tactics.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and negotiate with retail partners.",
                "Analytical mindset with the ability to interpret data, analyze market trends, and make data-driven decisions.",
                "Creative thinking and problem-solving abilities.",
                "Ability to work effectively in a cross-functional team environment.",
                "Strong project management skills with the ability to manage multiple projects and deadlines.",
                "Proficiency in Microsoft Office suite and marketing software tools.",
                "Willingness to travel for trade shows, events, and meetings with retail partners."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and conference attendance.",
                "Travel reimbursement for trade shows and events.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const RetailMarketingManagerJobDescription = {
    title: "Retail Marketing Manager Job Description",
    jobRole: "A Retail Marketing Manager oversees all marketing activities aimed at promoting products or services within a retail environment. They develop and execute marketing strategies to drive foot traffic, increase sales, and enhance brand visibility in retail stores. This role involves collaborating with various departments, managing marketing campaigns, analyzing market trends, and evaluating campaign performance.",
    preRead: "Use this Retail Marketing Manager job description template to attract qualified candidates who can develop and implement marketing strategies to drive sales and brand awareness in retail settings. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Retail Marketing Manager, you will be responsible for developing and implementing marketing strategies to promote products or services within retail stores. You will collaborate with sales teams, product managers, and external partners to create marketing campaigns, promotions, and events. This role requires strong marketing skills, retail industry knowledge, and the ability to analyze market data to drive business objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute marketing strategies to drive foot traffic, increase sales, and enhance brand visibility in retail stores.",
                "Collaborate with product managers to understand product features, benefits, and target audience.",
                "Create and manage marketing campaigns, promotions, and events to support product launches and drive sales.",
                "Analyze market trends, consumer behavior, and competitive landscape to identify opportunities for growth.",
                "Monitor and evaluate the performance of marketing campaigns, including ROI, sales metrics, and customer feedback.",
                "Manage relationships with retail partners, vendors, and agencies to execute marketing initiatives.",
                "Develop marketing collateral, signage, and promotional materials for retail stores.",
                "Coordinate with internal teams to ensure marketing activities align with brand guidelines and business objectives.",
                "Stay informed about industry trends, best practices, and innovations in retail marketing.",
                "Provide regular reports and updates to management on marketing initiatives and results."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or related field; MBA preferred.",
                "Proven experience in retail marketing, brand management, or related roles.",
                "Strong understanding of retail industry trends, consumer behavior, and customer journey.",
                "Excellent communication and interpersonal skills, with the ability to collaborate with cross-functional teams.",
                "Analytical mindset with the ability to interpret data, analyze market trends, and make data-driven decisions.",
                "Creative thinking and problem-solving abilities.",
                "Ability to manage multiple projects and deadlines in a fast-paced environment.",
                "Proficiency in marketing software tools and platforms.",
                "Willingness to travel for retail store visits, meetings, and events."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and conference attendance.",
                "Travel reimbursement for retail store visits and events.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const DirectMarketingSpecialistJobDescription = {
    title: "Direct Marketing Specialist Job Description",
    jobRole: "A Direct Marketing Specialist is responsible for designing and executing targeted marketing campaigns aimed at reaching specific audiences through direct channels such as email, direct mail, telemarketing, or SMS. They analyze customer data, segment audiences, create personalized messaging, and measure campaign performance to drive customer engagement and conversion.",
    preRead: "Use this Direct Marketing Specialist job description template to attract candidates who can develop and implement effective direct marketing strategies to reach target audiences and drive business objectives. Customize the responsibilities and qualifications to match your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Direct Marketing Specialist, you will develop and implement targeted marketing campaigns to engage and convert customers through direct channels. You will leverage customer data and segmentation techniques to deliver personalized messaging and drive measurable results. This role requires a strategic mindset, analytical skills, and a strong understanding of direct marketing principles."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute targeted marketing campaigns through direct channels such as email, direct mail, telemarketing, or SMS.",
                "Segment audiences based on demographic, behavioral, or psychographic factors to deliver personalized messaging.",
                "Create compelling content and creative assets for direct marketing campaigns, including email copy, landing pages, and promotional materials.",
                "Manage the end-to-end campaign process, including campaign planning, execution, optimization, and performance analysis.",
                "Monitor campaign performance metrics such as open rates, click-through rates, conversion rates, and ROI.",
                "Analyze customer data and campaign results to identify trends, insights, and areas for optimization.",
                "Collaborate with cross-functional teams including marketing, sales, and analytics to align direct marketing initiatives with business objectives.",
                "Stay informed about industry trends, best practices, and regulations related to direct marketing.",
                "Test and iterate on campaign elements to optimize performance and drive continuous improvement.",
                "Provide regular reports and updates on campaign performance to stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or related field.",
                "Proven experience in direct marketing, email marketing, or related roles.",
                "Strong understanding of direct marketing principles, strategies, and best practices.",
                "Experience with email marketing platforms, marketing automation tools, and CRM systems.",
                "Analytical mindset with the ability to interpret data, analyze results, and draw actionable insights.",
                "Excellent written and verbal communication skills, with the ability to create compelling content and messaging.",
                "Creative thinking and problem-solving abilities.",
                "Detail-oriented with strong organizational and project management skills.",
                "Ability to work effectively in a fast-paced, deadline-driven environment.",
                "Proficiency in marketing analytics and reporting tools."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const FieldMarketingManagerJobDescription = {
    title: "Field Marketing Manager Job Description",
    jobRole: "A Field Marketing Manager is responsible for developing and executing marketing strategies and campaigns at the local level to drive brand awareness, lead generation, and customer engagement. They collaborate with sales teams, partners, and local stakeholders to implement marketing initiatives that support business objectives and drive revenue growth in specific geographic regions.",
    preRead: "Use this Field Marketing Manager job description template to attract candidates who can develop and implement effective marketing strategies at the local level to drive business results. Customize the responsibilities and qualifications to align with your company's needs and objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Field Marketing Manager, you will be responsible for planning, executing, and measuring marketing activities at the local level to support sales efforts and drive business growth. You will work closely with sales teams, channel partners, and other local stakeholders to understand market dynamics, identify opportunities, and develop marketing programs that resonate with target audiences. This role requires strategic thinking, strong communication skills, and the ability to collaborate effectively across teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute local marketing plans and campaigns to drive brand awareness, lead generation, and customer engagement in specific geographic regions.",
                "Collaborate with sales teams to understand market dynamics, customer needs, and sales objectives for assigned territories.",
                "Work closely with channel partners and other local stakeholders to develop joint marketing programs and initiatives.",
                "Coordinate local events, trade shows, and sponsorships to promote brand visibility and generate leads.",
                "Manage local marketing budgets and allocate resources effectively to maximize ROI.",
                "Track and measure the performance of local marketing activities, analyze results, and optimize campaigns for success.",
                "Provide regular updates and reports to management on the effectiveness of field marketing initiatives and their impact on business outcomes.",
                "Stay informed about industry trends, competitor activities, and market developments in assigned territories.",
                "Collaborate with cross-functional teams including product marketing, digital marketing, and corporate marketing to ensure alignment and consistency of messaging and branding.",
                "Build relationships with key stakeholders, including customers, partners, and community organizations, to support marketing objectives and enhance brand reputation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Business Administration, or related field.",
                "Proven experience in field marketing, channel marketing, or related roles.",
                "Strong understanding of marketing principles, strategies, and tactics.",
                "Experience developing and executing marketing plans and campaigns at the local level.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and collaborate effectively with internal and external stakeholders.",
                "Analytical mindset with the ability to interpret data, analyze results, and draw actionable insights.",
                "Strong project management skills, with the ability to manage multiple projects and deadlines simultaneously.",
                "Flexibility and adaptability to work in a dynamic, fast-paced environment.",
                "Proficiency in marketing analytics and reporting tools.",
                "Willingness to travel as needed for events, meetings, and customer engagements."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};
