import ArticleIcon from '@mui/icons-material/Article';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import { IDashboardMenuItem } from 'Modules/Core/Layouts/AdminLayout/AdminLayoutModel';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const Menus: Array<IDashboardMenuItem> = [
  {
    icon: <DashboardIcon />,
    title: "Dashboard",
    to: "/admin",
  },
  {
    icon: <WorkIcon />,
    title: "Action Required",
    items: [
      {
        icon: <WorkIcon />,
        title: "Pending Jobs",
        to: "/admin/pending-jobs",
      },
      {
        icon: <HomeIcon />,
        title: "Approved Jobs",
        to: "/admin/approved-jobs",
      },
      {
        icon: <HomeIcon />,
        title: "Rejected Jobs",
        to: "/admin/rejected-jobs",
      },
      {
        icon: <HomeIcon />,
        title: "Removed Jobs",
        to: "/admin/removed-jobs",
      },
      {
        icon: <HomeIcon />,
        title: "Pending Update Jobs",
        to: "/admin/pending-update-jobs",
      },
    ],
  },
  {
    icon: <ArticleIcon />,
    title: "Application",
    items: [
      {
        title: "Application items",
        to: "/admin-dashboard/demo",
      },
    ],
  },
  {
    icon: <PersonIcon />,
    title: "Employees and jobs",
    items: [
      {
        title: "Demo",
        to: "/admin-dashboard/demo1",
      },
    ],
  },
  {
    icon: <ManageAccountsIcon />,
    title: "Manage Accounts",
    items: [
      {
        title: "Active Administrators",
        to: "/admin/accounts",
      },
      {
        title: "Manage Customer accounts",
        to: "/admin/accounts/reset_password",
      },
      {
        title: "Delete Candidate accounts",
        to: "/admin/accounts/delete_account",
      },
      {
        title: "Generate Security Code",
        to: "/admin/accounts/security_code",
      },
    ],
  },
];
