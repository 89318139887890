import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IEmployerDetails, IEmployerDetailsState } from './employer-details-model';

const initialEmployerCustomState: IEmployerDetailsState = {};

export const getEmployerDetails = createAsyncThunk<IBaseResponse<IEmployerDetails>, { empId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerDetails",
    async ({ empId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${empId}`)
            .then((response: AxiosResponse<IBaseResponse<IEmployerDetails>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const employerDetailsSlice = createSlice({
    name: 'employerDetails',
    initialState: initialEmployerCustomState,
    reducers: {
        resetGetEmployerDetails: (state) => {
            state.getEmployerDetailsStatus = 'idle';
            state.getEmployerDetailsResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialEmployerCustomState;
        });
        // get employer details
        builder.addCase(getEmployerDetails.pending, (state) => {
            state.getEmployerDetailsStatus = 'pending'
        });
        builder.addCase(getEmployerDetails.fulfilled, (state, action) => {
            state.getEmployerDetailsStatus = 'success';
            state.employerDetails = action.payload.data;
        });
        builder.addCase(getEmployerDetails.rejected, (state, action) => {
            state.getEmployerDetailsStatus = 'failed';
            state.getEmployerDetailsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetEmployerDetails } = employerDetailsSlice.actions;
export default employerDetailsSlice;
