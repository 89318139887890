import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICreateJobSharedState, IJobTitle, IPreviewJobDetails, IRequirementsMetaData, TJobCreationFLow } from 'Modules/Core/CreateJob/CreateJobModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { IAllJobDetails, IJobDetails, IRequirement } from '../jobs/jobs-slice-model';

const initialCjbSharedState: ICreateJobSharedState = {
    createOrUpdateJobResponse: '',
    createOrUpdateJobStatus: 'idle',
    getRequirementsResponse: '',
    getRequirementsStatus: 'idle',
    jobRequirements: [],
    getRequirementsMetaDataResponse: '',
    getRequirementsMetaDataStatus: 'idle',
    requirementsMetaData: undefined,
    defaultRequirementLevel: 0,
    isDetailsEnabled: false,
    isPreviewEnabled: false,
    isBundlesEnabled: false,
    currentJobCreationFlow: 'default'
};

export const updateJob = createAsyncThunk<IBaseResponse, { payload: (IJobTitle | IPreviewJobDetails), jobId: number }, { rejectValue: IBaseResponse }>(
    "updateJob",
    async ({ payload, jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.JOBS}/${jobId}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateTemplateJob = createAsyncThunk<IBaseResponse, { payload: (IJobTitle | IPreviewJobDetails), jobId: number }, { rejectValue: IBaseResponse }>(
    "updateTemplateJob",
    async ({ payload, jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.UPDATE_TEMPLATE_JOB}/${jobId}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getRequirementsMetaData = createAsyncThunk<IRequirementsMetaData, { jobId: number }, { rejectValue: IBaseResponse }>(
    "getRequirementsMetaData",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/meta`)
            .then((response: AxiosResponse<IRequirementsMetaData>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getRequirements = createAsyncThunk<IBaseResponse<IRequirement[]>, { jobId: string }, { rejectValue: IBaseResponse }>(
    "getRequirements",
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS}/${jobId}/requirements`)
            .then((response: AxiosResponse<IBaseResponse<IRequirement[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const createJobSharedSlice = createSlice({
    name: 'createJobSharedSlice',
    initialState: initialCjbSharedState,
    reducers: {
        resetCreateJobSlice: () => initialCjbSharedState,
        setCurrentJobCreationFlow: (state, action: PayloadAction<TJobCreationFLow>) => { state.currentJobCreationFlow = action.payload; },
        setIsJobIdWithTemplateCreated: (state, action: PayloadAction<boolean>) => { state.isJobIdWithTemplateCreated = action.payload; },
        resetCreateOrUpdateJob: (state) => { state.createOrUpdateJobStatus = 'idle'; state.createOrUpdateJobResponse = '' },
        resetGetRequirementsMetaData: (state) => { state.getRequirementsMetaDataStatus = 'idle'; state.getRequirementsMetaDataResponse = '' },
        resetGetRequirements: (state) => { state.getRequirementsStatus = 'idle'; state.getRequirementsResponse = '' },
        setIsDetailsEnabled: (state, action: PayloadAction<boolean>) => { state.isDetailsEnabled = action.payload },
        setIsPreviewEnabled: (state, action: PayloadAction<boolean | undefined>) => { state.isPreviewEnabled = action.payload },
        setAllJobDetailsInState: (state, action: PayloadAction<{ allJobDetails: IAllJobDetails }>) => {
            state.allJobDetails = action.payload.allJobDetails;
            // 'jobDetails' contains the information of entire job. Segregate it according the pages.
            const { job_title_id, name, hiring_manager_id, optional_hiring_manager_id,
                remote_job_type, city, postalcode, province_id, salary_details,
                neighbourhood, experience, positiontype_id, description, hours_per_week,
                salary_type_id, benefits, keywords } = state.allJobDetails;

            // Job Title page.
            state.jobTitleInfo = {
                job_title_id, name, hiring_manager_id: hiring_manager_id ?? 0,
                optional_hiring_manager_id, remote_job_type, city: city ?? '',
                postalcode: postalcode ?? '', province_id: province_id ?? '', neighbourhood,
                experience, positiontype_id, description, hours_per_week
            };

            // Job Details page.
            state.jobDetails = {
                benefits,
                keywords: keywords?.join(','),
                requirements: [],
                salary_details: salary_details,
                salary_type_id
            };
        },
        setIsBundlesEnabled: (state, action: PayloadAction<boolean>) => {
            if (state.allJobDetails?.workflow_state !== 'active' || state.currentJobCreationFlow === 'useTemplateJob') {
                state.isBundlesEnabled = action.payload;
            }
        },
        setIsPaymentEnabled: (state, action: PayloadAction<boolean>) => {
            if (state.allJobDetails?.workflow_state !== 'active' || state.currentJobCreationFlow === 'useTemplateJob') {
                state.isPaymentEnabled = action.payload;
            }
        },
        resetStoredInfo: (state) => {
            state.jobDetails = undefined;
            state.jobTitleInfo = undefined;
            state.allJobDetails = undefined;
            state.isDetailsEnabled = false;
            state.isPreviewEnabled = false;
            state.isBundlesEnabled = false;
            state.isPaymentEnabled = false;
            state.jobRequirements = [];
        },
        // Update 'allJobDetails' on saving the details from user.
        updateAllJobDetails: (state, action: PayloadAction<IJobDetails>) => {
            if (state.allJobDetails) {
                state.allJobDetails.benefits = action.payload.benefits ?? '';
                state.allJobDetails.keywords = action.payload?.keywords?.split(',');
                state.allJobDetails.salary_details = action.payload.salary_details ?? '';
                state.allJobDetails.salary_type_id = action.payload.salary_type_id ?? undefined;
                state.allJobDetails.areRequirementsUpdated = action.payload.areRequirementsUpdated;
            }
        },
        // Update description in 'allJobDetails' on saving the description in preview.
        updateDescriptionInAllJobDetails: (state, action: PayloadAction<{
            description: string, ai_job_description?: boolean, custom_description?: boolean
        }>) => {
            if (state.allJobDetails) {
                state.allJobDetails.description = action.payload.description ?? '';
                state.allJobDetails.ai_job_description = action.payload.ai_job_description;
                state.allJobDetails.custom_description = action.payload.custom_description;
            }
        },
        // Update 'jobPage' on saving the details from user.
        updateJobDetails: (state, action: PayloadAction<IJobDetails>) => {
            if (state.jobDetails) {
                state.jobDetails.benefits = action.payload.benefits;
                state.jobDetails.companydescription = action.payload.companydescription ?? '';
                state.jobDetails.keywords = action.payload?.keywords;
                state.jobDetails.salary_details = action.payload.salary_details;
                state.jobDetails.salary_type_id = action.payload.salary_type_id;
            }
        },
        updateJobRequirementsInSlice: (state, action: PayloadAction<IRequirement[]>) => {
            state.jobRequirements = action.payload;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialCjbSharedState;
        });
        // update job
        builder.addCase(updateJob.pending, (state) => {
            state.createOrUpdateJobStatus = 'pending'
        });
        builder.addCase(updateJob.fulfilled, (state, action) => {
            state.createOrUpdateJobStatus = 'success';
            state.createOrUpdateJobResponse = action.payload.message ?? DefaultAPIErrorMsg;
            // Update 'allJobDetails' once Title info is saved.
            if (!action.meta.arg.payload.is_complete) {
                const { job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id,
                    hours_per_week } = action.meta.arg.payload as IJobTitle;
                state.allJobDetails = {
                    ...state.allJobDetails,
                    job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id,
                    keywords: [], salary_details: '', salary_type_id: 0, hours_per_week
                };
                state.jobTitleInfo = {
                    job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id, description: ''
                };
            }
        });
        builder.addCase(updateJob.rejected, (state, action) => {
            state.createOrUpdateJobStatus = 'failed';
            state.createOrUpdateJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // update template job
        builder.addCase(updateTemplateJob.pending, (state) => {
            state.createOrUpdateJobStatus = 'pending'
        });
        builder.addCase(updateTemplateJob.fulfilled, (state, action) => {
            state.createOrUpdateJobStatus = 'success';
            state.createOrUpdateJobResponse = action.payload.message ?? DefaultAPIErrorMsg;
            // Update 'allJobDetails' once Title info is saved.
            if (!action.meta.arg.payload.is_complete) {
                const { job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id,
                    hours_per_week } = action.meta.arg.payload as IJobTitle;
                state.allJobDetails = {
                    ...state.allJobDetails,
                    job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id,
                    keywords: [], salary_details: '', salary_type_id: 0, hours_per_week
                };
                state.jobTitleInfo = {
                    job_title_id, name, hiring_manager_id, optional_hiring_manager_id, remote_job_type,
                    city, postalcode, province_id, neighbourhood, experience, positiontype_id, description: ''
                };
            }
        });
        builder.addCase(updateTemplateJob.rejected, (state, action) => {
            state.createOrUpdateJobStatus = 'failed';
            state.createOrUpdateJobResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

        // get requirements metadata
        builder.addCase(getRequirementsMetaData.pending, (state) => {
            state.getRequirementsMetaDataStatus = 'pending'
        });
        builder.addCase(getRequirementsMetaData.fulfilled, (state, action) => {
            state.getRequirementsMetaDataStatus = 'success';
            const metaData = action.payload;
            // Add order to requirements and sort.
            metaData.categories = metaData.categories.map(c => {
                return {
                    ...c,
                    order: c.name === 'responsibility' ? 1 : c.name === 'education' ? 2 : c.name === 'qualification' ? 3 : 4
                }
            }).sort((a, b) => a.order - b.order);
            state.requirementsMetaData = metaData;
            // Set default requirement level.
            state.defaultRequirementLevel = state.requirementsMetaData?.levels.find(lvl => lvl.the_default === true)?.id ?? 0;
        });
        builder.addCase(getRequirementsMetaData.rejected, (state, action) => {
            state.getRequirementsMetaDataStatus = 'failed';
            state.getRequirementsMetaDataResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get requirements
        builder.addCase(getRequirements.pending, (state) => {
            state.getRequirementsStatus = 'pending'
        });
        builder.addCase(getRequirements.fulfilled, (state, action) => {
            state.getRequirementsStatus = 'success';
            /*
                Generate random number as id for job requirement to handle new job requirement additions and deletions properly in UI.
                Add index as ui_order for each requirement if ui_order is present.
            */
            state.jobRequirements = action.payload.data.map((jR, indx) => {
                jR.reqId = Math.floor((Math.random() * 100000) + 1);
                // jR.ui_order = jR.ui_order ?? indx;
                return jR;
            });
        });
        builder.addCase(getRequirements.rejected, (state, action) => {
            state.getRequirementsStatus = 'failed';
            state.getRequirementsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});
export const { resetCreateOrUpdateJob, setIsDetailsEnabled, setIsPreviewEnabled, resetStoredInfo,
    setIsBundlesEnabled, resetGetRequirements, updateJobDetails, setAllJobDetailsInState, setIsJobIdWithTemplateCreated,
    resetGetRequirementsMetaData, updateJobRequirementsInSlice, setIsPaymentEnabled, updateAllJobDetails,
    resetCreateJobSlice, updateDescriptionInAllJobDetails, setCurrentJobCreationFlow } = createJobSharedSlice.actions;
export default createJobSharedSlice;
