import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { CircularProgress, Divider, Grow, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Notification, useNotification } from 'Modules/Core/Notification';
import { ResourceElementsBorderRadius, ResourceHomeStack } from 'Modules/Marketing/Resources/Resources.styled';
import { IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { ShButton, ShTextField } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ShBorderRadius } from 'shared/SharedStyles/styleConstants';
import { getAiInterviewQuestions } from 'store/slices/app/resources-slice';
import { EMPLOYER_AI_TOOLS_DETAILS_JOB_DESCRIPTION, EMPLOYER_AI_TOOLS_TITLE_INTERVIEW_KIT } from '../EmployerResourcesConstants';
import EmployerSampleInterviewKitPlaceholder from './SamplePlaceHolderPages/EmployerSampleInterviewKitPlaceholder';

export const EmployerAiInterviewQuestionsPage = () => {
    const dispatch = useAppDispatch();
    const isXsScreen = IsXsScreen();
    const { aiInterviewQuestions, getAiInterviewQuestionsStatus } = useAppSelector(state => state.app.resources);
    const notification = useNotification();
    const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);
    const [industry, setIndustry] = useState<string>('');
    const [role, setRole] = useState<string>('');

    useEffect(() => {
        if (copiedToClipboard) {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: 'Successfully Copied!'
            });
            setCopiedToClipboard(false);
        }
    }, [copiedToClipboard, notification]);

    const handleCopyAllClick = () => {
        const questionsAndAnswers = aiInterviewQuestions?.description?.questions?.map(q => `${q.question}\n${q.answer}`).join('\n\n') ?? '';
        navigator.clipboard.writeText(questionsAndAnswers)
            .then(() => {
                setCopiedToClipboard(true);
            })
    };


    const handleSubmit = () => {
        if (role) {
            dispatch(getAiInterviewQuestions({ role: role, industry: industry }));
        }
    };

    return (
        <ShContainer maxWidth='lg'>
            <Notification />
            <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Box marginTop={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                    <Typography gutterBottom fontSize={25} fontWeight={700}>
                        {EMPLOYER_AI_TOOLS_TITLE_INTERVIEW_KIT}
                    </Typography>
                    <Typography variant='subtitle1' marginBottom={{ xs: 4, sm: 4, md: 5, lg: 6 }}>
                        {EMPLOYER_AI_TOOLS_DETAILS_JOB_DESCRIPTION}
                    </Typography>
                </Box>
            </Grow>
            <Grow in={true} timeout={2000} mountOnEnter>
                <ResourceHomeStack spacing={2} direction={isXsScreen ? 'column' : 'row'} alignItems="center" borderRadius={ShBorderRadius}>
                    <ShTextField size='medium' label='Industry' variant='outlined' fullWidth
                        onChange={(e) => {
                            setIndustry(e.target.value);
                        }} />
                    <ShTextField size='medium' label='Job Role' variant='outlined' fullWidth
                        onChange={(e) => {
                            setRole(e.target.value);
                        }} />
                    <ShButton size='large' disableElevation color="primary"
                        disabled={getAiInterviewQuestionsStatus === 'pending'}
                        borderRadius={3} startIcon={<AutoAwesomeIcon />} variant='contained'
                        onClick={() => handleSubmit()}><Typography>Generate&nbsp;</Typography>
                        {getAiInterviewQuestionsStatus === 'pending' ?
                            <CircularProgress size='1.5rem' /> : ''}</ShButton>
                </ResourceHomeStack>
            </Grow>
            {aiInterviewQuestions ?
                <Grow in={true} timeout={2000} mountOnEnter>
                    <Box marginTop={2}>
                        <ShPaper variant='outlined' borderRadius={ResourceElementsBorderRadius}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='right' paddingLeft={1} paddingBottom={1} paddingTop={2}>
                                <ShButton color='primary' onClick={handleCopyAllClick}>
                                    <CopyAllIcon />
                                    <Typography variant="body2">Copy</Typography>
                                </ShButton>
                            </Stack>
                            <Stack paddingLeft={1}>
                                {aiInterviewQuestions?.description?.questions?.map((q, index) =>
                                    <Box padding={1} key={index + '_q_a_pair'}>
                                        <Stack direction="row" alignItems="center" spacing={1}>
                                            <Typography variant="subtitle1" fontWeight={'bold'}>{q.question}</Typography>
                                        </Stack>
                                        <Stack direction="row" alignItems="center">
                                            <Typography marginBottom={2} variant="body2">{q.answer}</Typography>
                                        </Stack>
                                        {index !== aiInterviewQuestions?.description?.questions?.length - 1 && <Divider />}
                                    </Box>)}
                            </Stack>
                        </ShPaper>
                    </Box>
                </Grow>
                :
                <Stack paddingTop={3} paddingBottom={3}>
                    <ShPaper borderRadius={15} elevation={3}>
                        <EmployerSampleInterviewKitPlaceholder />
                    </ShPaper>
                </Stack>
            }
        </ShContainer>
    );
}
