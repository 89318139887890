import { combineReducers } from "@reduxjs/toolkit";
import jobReviewsSlice from "store/slices/admin/jobs-reviews-slice";
import adminNotificationSlice from "store/slices/admin/notifications-slice";
import assessmentsAndReportsReducer from "./assessments-and-reports";
import adminCandidateSlice from "./candidate/admin-candidate-slice";
import adminEmployerSlice from "./employer/admin-employer-slice";
import manageAccountsSlice from "./manage-accounts/manage-accounts-slice";

const adminReducer = combineReducers({
    jobReviews: jobReviewsSlice.reducer,
    adminNotifications: adminNotificationSlice.reducer,
    adminEmployer: adminEmployerSlice.reducer,
    adminCandidate: adminCandidateSlice.reducer,
    manageAccounts: manageAccountsSlice.reducer,
    assessmentsAndReports: assessmentsAndReportsReducer
});

export default adminReducer;