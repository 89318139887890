import { SvgIcon } from '@mui/material';

export const CanadaFlagIcon = () => {
    return (<>
        <SvgIcon viewBox='0 0 20 20'>
            <svg width="50px" height="35px" viewBox="0 -4 28 28" fill="none">
                <g clipPath="url(#clip0_503_2789)">
                    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" strokeWidth="0.5" />
                    <mask id="mask0_503_2789" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
                        <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" strokeWidth="0.5" />
                    </mask>
                    <g mask="url(#mask0_503_2789)">
                        <rect x="20" width="8" height="20" fill="#FF3131" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 20H8V0H0V20Z" fill="#FF3131" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.1118 9.22154C15.8786 9.45474 15.4845 9.24386 15.5492 8.92046L16 6.66668L14.6666 7.33334L14 5.33334L13.3333 7.33334L12 6.66668L12.4507 8.92046C12.5154 9.24386 12.1214 9.45474 11.8882 9.22154L11.569 8.90238C11.4388 8.7722 11.2278 8.7722 11.0976 8.90238L10.6666 9.33334L9.33331 8.66668L9.99998 10L9.56901 10.431C9.43884 10.5611 9.43884 10.7722 9.56902 10.9024L11.3333 12.6667H13.3333L13.6666 14.6667H14.3333L14.6666 12.6667H16.6666L18.4309 10.9024C18.5611 10.7722 18.5611 10.5611 18.4309 10.431L18 10L18.6666 8.66668L17.3333 9.33334L16.9023 8.90238C16.7722 8.7722 16.5611 8.7722 16.4309 8.90238L16.1118 9.22154Z" fill="#FF3131" />
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_503_2789">
                        <rect width="28" height="20" rx="2" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    </>)
};