import { useAppSelector } from "helpers/hooks";
import { useCallback, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { RoleAccessControlMap, TemplateJobFeatureMap, TemplateJobPaths, UnauthorizedRedirect } from "./AuthConstants";
import { TRoleAccessMap } from "./AuthModel";

export const AccessController = () => {
    const location = useLocation();
    const path = location.pathname;
    const { accountAccess } = useAppSelector(state => state.auth.auth);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const { jobId, applicantId } = useParams();
    const [isAuthorized, setIsAuthorized] = useState<boolean>(true);
    const [roleAccessControlMap, setRoleAccessControlMap] = useState<TRoleAccessMap>({ ...RoleAccessControlMap });

    // Add template job features and routes as blocked if has_template_jobs is false.
    useEffect(() => {
        if (employerDetails?.account_id && !employerDetails?.has_template_jobs) {
            setRoleAccessControlMap(prev => {
                let newMap = prev;
                Object.keys(newMap).forEach(role => {
                    const roleKey = role as keyof typeof RoleAccessControlMap;
                    newMap[roleKey].paths.push(...TemplateJobPaths);
                    newMap[roleKey].featureMap = { ...newMap[roleKey].featureMap, ...TemplateJobFeatureMap }
                })
                return newMap;
            })
        }
    }, [employerDetails?.account_id, employerDetails?.has_template_jobs])

    const updateRoutesWithParams = useCallback((): string[] => {
        let updatedRoutes: string[] = [];
        if (accountAccess.role) {
            roleAccessControlMap[accountAccess.role].paths.forEach(route => {
                let updatedRoute = route;
                if (route.includes(':jobId')) {
                    updatedRoute = updatedRoute.replace(':jobId', jobId ?? ':jobId');
                }
                if (route.includes(':applicantId')) {
                    updatedRoute = updatedRoute.replace(':applicantId', applicantId ?? ':applicantId');
                }
                updatedRoutes.push(updatedRoute);
            });
        }
        return updatedRoutes;
    }, [accountAccess.role, applicantId, jobId, roleAccessControlMap]);

    useEffect(() => {
        if (path && accountAccess.role) {
            setIsAuthorized(!updateRoutesWithParams().includes(path));
        }
    }, [accountAccess.role, path, updateRoutesWithParams]);

    return (<>
        {isAuthorized ? <Outlet /> : <Navigate to={UnauthorizedRedirect} />}
    </>)
}