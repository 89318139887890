import { SvgIcon } from '@mui/material';
import React from 'react';

export const InterviewIcon = () => {
    return (<>
        <SvgIcon>
            <path d="M 14.796875 7.007812 L 14.132812 8.667969 L 14.613281 8.609375 C 16.269531 8.402344 17.855469 7.78125 18.609375 7.457031 C 19.324219 7.203125 19.941406 6.765625 20.386719 6.199219 C 20.835938 5.625 21.105469 4.914062 21.105469 4.148438 C 21.105469 3.648438 20.988281 3.167969 20.785156 2.738281 C 20.472656 2.09375 19.964844 1.554688 19.335938 1.179688 C 18.707031 0.804688 17.957031 0.589844 17.148438 0.589844 C 16.074219 0.589844 15.09375 0.972656 14.375 1.609375 C 14.015625 1.925781 13.71875 2.308594 13.515625 2.738281 C 13.308594 3.167969 13.191406 3.648438 13.191406 4.148438 C 13.191406 4.789062 13.382812 5.390625 13.703125 5.902344 C 13.980469 6.34375 14.355469 6.714844 14.796875 7.007812 Z M 14 3.027344 C 14.25 2.507812 14.675781 2.050781 15.222656 1.726562 C 15.765625 1.398438 16.429688 1.207031 17.148438 1.207031 C 18.105469 1.207031 18.96875 1.550781 19.582031 2.09375 C 19.886719 2.363281 20.128906 2.679688 20.296875 3.027344 C 20.460938 3.375 20.554688 3.753906 20.554688 4.148438 C 20.554688 4.75 20.34375 5.3125 19.96875 5.789062 C 19.597656 6.265625 19.0625 6.652344 18.425781 6.875 L 18.417969 6.878906 L 18.40625 6.882812 C 17.757812 7.164062 16.4375 7.679688 15.035156 7.921875 L 15.511719 6.738281 L 15.277344 6.605469 C 14.808594 6.335938 14.421875 5.96875 14.15625 5.546875 C 13.890625 5.125 13.746094 4.652344 13.746094 4.148438 C 13.746094 3.753906 13.835938 3.375 14 3.027344 Z M 14 3.027344 " />
            <path d="M 15.589844 4.714844 C 15.824219 4.714844 16.019531 4.5 16.019531 4.234375 C 16.019531 3.972656 15.824219 3.757812 15.589844 3.757812 C 15.351562 3.757812 15.160156 3.972656 15.160156 4.234375 C 15.160156 4.5 15.351562 4.714844 15.589844 4.714844 Z M 15.589844 4.714844 " />
            <path d="M 17.246094 4.714844 C 17.484375 4.714844 17.675781 4.5 17.675781 4.234375 C 17.675781 3.972656 17.484375 3.757812 17.246094 3.757812 C 17.007812 3.757812 16.816406 3.972656 16.816406 4.234375 C 16.816406 4.5 17.007812 4.714844 17.246094 4.714844 Z M 17.246094 4.714844 " />
            <path d="M 18.902344 4.714844 C 19.140625 4.714844 19.332031 4.5 19.332031 4.234375 C 19.332031 3.972656 19.140625 3.757812 18.902344 3.757812 C 18.664062 3.757812 18.472656 3.972656 18.472656 4.234375 C 18.472656 4.5 18.664062 4.714844 18.902344 4.714844 Z M 18.902344 4.714844 " />
            <path d="M 23.957031 22.691406 L 20.140625 13.824219 C 20.058594 13.632812 19.871094 13.511719 19.667969 13.511719 L 4.335938 13.511719 C 4.128906 13.511719 3.941406 13.632812 3.859375 13.824219 L 0.0429688 22.691406 C -0.0273438 22.851562 -0.0117188 23.035156 0.0859375 23.179688 C 0.179688 23.324219 0.34375 23.410156 0.515625 23.410156 L 7.710938 23.410156 C 8.324219 22.371094 9.382812 21.621094 10.867188 21.375 C 11.210938 21.488281 11.574219 21.554688 11.957031 21.554688 C 12.335938 21.554688 12.699219 21.488281 13.042969 21.375 C 14.527344 21.621094 15.585938 22.371094 16.199219 23.410156 L 23.484375 23.410156 C 23.65625 23.410156 23.820312 23.324219 23.914062 23.179688 C 24.011719 23.035156 24.027344 22.851562 23.957031 22.691406 Z M 14.621094 18.097656 C 14.621094 19.570312 13.429688 20.761719 11.957031 20.761719 C 10.484375 20.761719 9.289062 19.570312 9.289062 18.097656 L 9.289062 17.339844 C 9.289062 15.867188 10.484375 14.671875 11.957031 14.671875 C 13.429688 14.671875 14.621094 15.867188 14.621094 17.339844 Z M 14.621094 18.097656 " />
            <path d="M 14.847656 12.390625 C 14.847656 11.097656 14.085938 10.027344 12.632812 9.789062 C 12.433594 9.855469 12.222656 9.894531 12 9.894531 C 11.777344 9.894531 11.566406 9.855469 11.363281 9.789062 C 9.914062 10.027344 9.152344 11.097656 9.152344 12.390625 Z M 14.847656 12.390625 " />
            <path d="M 12 9.433594 C 12.859375 9.433594 13.558594 8.734375 13.558594 7.875 L 13.558594 7.433594 C 13.558594 6.570312 12.859375 5.875 12 5.875 C 11.140625 5.875 10.441406 6.570312 10.441406 7.433594 L 10.441406 7.875 C 10.441406 8.734375 11.140625 9.433594 12 9.433594 Z M 12 9.433594 " />
        </SvgIcon>
    </>)
};