import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { TBoolean } from "shared/SharedModels";
import { IAppInfoState } from "./app-model";

const initialAppInfoState: IAppInfoState = {
    isPricingOpen: false,
    isFaqOpen: false
};

const appInfoSlice = createSlice({
    name: "pricing",
    initialState: initialAppInfoState,
    reducers: {
        togglePricingDialog: (state) => {
            state.isPricingOpen = !state.isPricingOpen;
        },
        toggleFaqDialog: (state) => {
            state.isFaqOpen = !state.isFaqOpen;
        },
        setIsWelcomeAcknowledged: (state, action: PayloadAction<{ _isWelcomeAcknowledged: TBoolean }>) => {
            state.isWelcomeAcknowledged = action.payload._isWelcomeAcknowledged;
        },
        setIsWelcomeCreateJobAcknowledged: (state, action: PayloadAction<{ _isWelcomeCreateJobAcknowledged: TBoolean }>) => {
            state.isWelcomeCreateJobAcknowledged = action.payload._isWelcomeCreateJobAcknowledged;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialAppInfoState;
        });
    }
});

export const { togglePricingDialog, toggleFaqDialog, setIsWelcomeAcknowledged, setIsWelcomeCreateJobAcknowledged } = appInfoSlice.actions;
export default appInfoSlice;
