import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Box, DialogContent, DialogTitle, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { SettingsDialog } from 'Modules/Core/SettingsTs/Settings.styled';
import { IForgotAuthDialog, IResetAuthAns } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { BorderColorDark, BorderColorLight } from 'shared/SharedStyles/styleConstants';
import { resetAuthAnsChange, resetAuthAnswer } from 'store/slices/employer/settingsTs/security-slice';
export const ForgotAuthDialog = ({ authQuestionId, isForgotAuthOpen, setIsForgotAuthOpen, showNotification }: IForgotAuthDialog) => {
    const dispatch = useAppDispatch();
    const [resetAuthAns, setResetAuthAns] = useState<IResetAuthAns>({ password: '', question_id: authQuestionId, auth_answer: '' });
    const { resetAuthAnsApiResponse, resetAuthAnsApiStatus } = useAppSelector((state) => state.employer.employerSettings.security);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    // Update state if question id is updated.
    useEffect(() => {
        setResetAuthAns({ password: '', question_id: authQuestionId, auth_answer: '' });
    }, [authQuestionId]);

    // On closing dialog reset the state of forgot Auth answer object.
    const closeForgotAuthDlg = useCallback(() => {
        dispatch(resetAuthAnsChange());
        setIsForgotAuthOpen(false);
        setResetAuthAns({ password: '', question_id: authQuestionId, auth_answer: '' });
    }, [authQuestionId, dispatch, setIsForgotAuthOpen]);

    //Show notification on Auth answer change and close dialog.
    useEffect(() => {
        if (resetAuthAnsApiStatus === 'success') {
            closeForgotAuthDlg();
            showNotification(resetAuthAnsApiStatus, resetAuthAnsChange, resetAuthAnsApiResponse);
        }
    }, [closeForgotAuthDlg, resetAuthAnsApiResponse, resetAuthAnsApiStatus, showNotification]);

    return (<>
        <SettingsDialog open={isForgotAuthOpen} onClose={closeForgotAuthDlg}
            aria-label='Forgot answer dialog'>
            <DialogTitle fontWeight={600} padding={`5px 16px !important`}
                borderBottom={(theme) => `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`}>
                Forgot Answer
                <IconButton className='close-btn' onClick={() => setIsForgotAuthOpen(false)}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }}
                    padding={{ xs: 0.5, sm: 2, md: 2, lg: 2 }} alignItems='center' justifyContent='center'>
                    <Typography variant='body2'>
                        Enter the password used to login to change the Answer
                    </Typography>
                    {resetAuthAnsApiStatus === 'failed' && <Box width='100%'><Alert severity="error">
                        {resetAuthAnsApiResponse ?? 'Error occurred while changing Auth answerF'}
                    </Alert></Box>}
                    <TextField id='password' size='small' fullWidth variant='outlined' label='Password'
                        type={showPassword ? 'text' : 'password'}
                        value={resetAuthAns.password}
                        onChange={e => setResetAuthAns({ ...resetAuthAns, password: e.target.value })}
                        InputProps={{
                            endAdornment: <InputAdornment position='end'>
                                <IconButton
                                    edge='end' onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </InputAdornment>
                        }}
                    />
                    <TextField id='updated_auth_ans' size='small' fullWidth variant='outlined' label='Answer'
                        value={resetAuthAns.auth_answer}
                        onChange={e => setResetAuthAns({ ...resetAuthAns, auth_answer: e.target.value })} />
                    <ShGreenBtn className='self-center' variant='contained' disableElevation onClick={() => dispatch(resetAuthAnswer(resetAuthAns))}
                        disabled={resetAuthAnsApiStatus === 'pending' || resetAuthAns.auth_answer?.trim().length === 0 ||
                            resetAuthAns.question_id === undefined || resetAuthAns.auth_answer?.trim().length === 0}>
                        {resetAuthAnsApiStatus === 'pending' ? 'Updating Answer ...' : 'Update Answer'}</ShGreenBtn>
                </Stack>
            </DialogContent>
        </SettingsDialog >
    </>);
};