import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";
import { IAdminEmployerByEmail, IAdminEmployerState } from "./admin-employer-model";

const adminCandidateActionsInitialState: IAdminEmployerState = {}

export const getEmployerByEmail = createAsyncThunk<IAdminEmployerByEmail, { email: string }, { rejectValue: IBaseResponse }>(
    'getEmployerByEmail',
    async ({ email }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.ADMIN_EMP_SEARCH}?email=${email}`)
            .then((response: AxiosResponse<IAdminEmployerByEmail>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const adminEmployerSlice = createSlice({
    name: "adminEmployer",
    initialState: adminCandidateActionsInitialState,
    reducers: {
        resetGetEmployerByEmail: (state) => {
            state.getEmployerByEmailResponse = '';
            state.getEmployerByEmailStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployerByEmail.pending, (state) => {
            state.getEmployerByEmailStatus = 'pending';
        });
        builder.addCase(getEmployerByEmail.fulfilled, (state, action) => {
            state.getEmployerByEmailStatus = 'success';
            state.adminEmployerByEmail = action.payload;
            state.getEmployerByEmailResponse = action.payload.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(getEmployerByEmail.rejected, (state, action) => {
            state.getEmployerByEmailStatus = 'failed';
            state.getEmployerByEmailResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
})

export const { resetGetEmployerByEmail } = adminEmployerSlice.actions;
export default adminEmployerSlice;