import { setLocalAccessToken } from "helpers/TokenHelper";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { setAccountLoginToTrue } from "store/slices/auth-v2/auth-v2-slice";
import { getSocialLoginRedirectUrl } from "store/slices/auth-v2/social-login-slice";
import { signUpCandidate } from "store/slices/candidate/candidate-profile-slice";

export const SurveySocialSignUp = () => {
    const dispatch = useAppDispatch();
    const { socialSignUpProfile } = useAppSelector((state) => state.auth.socialLogin);
    const { candidateSignUpErrorCode, candidateSignUpStatus,
        socialLoginProvider, candidateAuthToken } = useAppSelector((state) => state.candidate.candidateProfile);
    const navigate = useNavigate();

    // auto fill form on social sign up
    useEffect(() => {
        if (socialSignUpProfile) {
            const { email, family_name, given_name, signup_source } = socialSignUpProfile;
            const signUpPayload = {
                email: email, first_name: given_name, signup_source: signup_source,
                last_name: family_name, password: '', ps: undefined, job_id: undefined
            };
            dispatch(signUpCandidate({ payload: signUpPayload }));
        }
    }, [dispatch, socialSignUpProfile]);

    useEffect(() => {
        if (candidateSignUpStatus === 'success' && candidateAuthToken) {
            //Set token & refresh token in local storage
            setLocalAccessToken(candidateAuthToken);
            dispatch(setAccountLoginToTrue({ authToken: candidateAuthToken, loginPage: 'candidate_survey' }));
        }
    }, [candidateAuthToken, candidateSignUpStatus, dispatch]);

    useEffect(() => {
        if (candidateSignUpErrorCode === 'USER_EXIST') {
            if (socialLoginProvider) {
                dispatch(getSocialLoginRedirectUrl({ provider: socialLoginProvider, isSignUp: false }));
            } else {
                navigate('/candidate/login');
            }
        }
    }, [candidateSignUpErrorCode, dispatch, navigate, socialLoginProvider]);

    return (<>
        {candidateSignUpStatus === 'pending' &&
            <ShAlert severity="info">Signing up...</ShAlert>
        }
    </>);
}