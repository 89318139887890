import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, Paper, Stack, Tooltip } from "@mui/material";
import { ShInputBase } from "shared/SharedStyles/ShInputs";
import { ShToolbarPaper } from "shared/SharedStyles/ShSurfaces";
import { PaperVariant } from "shared/SharedStyles/styleConstants";
import { KeyCodes } from "shared/constants";
import { ISHTableSearchBar } from './ShAdminTableModel';

const SHTableSearchBar = ({ searchKey, setSearchKey, onSearch }: ISHTableSearchBar) => {
  return (
    <ShToolbarPaper elevation={0}>
      <Stack direction="row" justifyContent="space-between">
        <Box />
        <Paper className="custom-paper" variant={PaperVariant}>
          <ShInputBase id='search-jobs' placeholder='Title/Location' inputProps={{ 'aria-label': 'Search Jobs' }}
            value={searchKey} onChange={e => setSearchKey(e.target.value)}
            onKeyUp={e => { e.code === KeyCodes.Enter && onSearch(searchKey) }}
          />
          {searchKey &&
            <Tooltip arrow title="Clear search">
              <IconButton aria-label="clear" color='error' onClick={() => {
                setSearchKey("");
                onSearch("");
              }}>
                <ClearIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip arrow title="Search">
            <IconButton aria-label="search"
              onClick={() => { }}><SearchIcon /></IconButton>
          </Tooltip>
        </Paper>
      </Stack>
    </ShToolbarPaper>
  );
};

export default SHTableSearchBar;