import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
    Badge,
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
    darken,
    lighten,
} from "@mui/material";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { IApplicant, IApplicantsApiParams } from "Modules/Core/Applicants/ApplicantsModel";
import { IJobsApiParams } from "Modules/Core/JobsTs/JobsModel";
import { useCallback, useEffect, useState } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { ShGreen } from 'shared/SharedStyles/styleConstants';
import { resetApplicantsState } from "store/slices/employer/applicants/applicants-list-slice";
import { fetchJobSummaryWidgetApplicantList } from "store/slices/employer/employer/dashboard/dashboard-summaray-widget-applicant-list";
import { fetchJobSummaryWidgetActiveJobsList } from "store/slices/employer/employer/dashboard/dashboard-summary-widget-active-jobs.slice";
import { setSelectedJobId } from "store/slices/employer/employer/dashboard/dashboard-summary-widget-selected-job-slice";
import { DashboardMenuProps } from "../Dashboard.styled";
import { DashboardListSize, MaxDashboardApplicantSize } from "../DashboardConstants";
import { truncateText } from 'shared/utils';


export const DashboardApplicantSummaryWidget = () => {

    const dispatch = useAppDispatch();
    const isSmScreen = IsSmScreen();
    const [candidateCount, setCandidateCount] = useState<number>(0);
    const [newStageCount, setNewStageCount] = useState<number>(0);
    const [interviewStageCount, setInterviewStageCount] = useState<number>(0);
    const [rejectedStageCount, setRejectedStageCount] = useState<number>(0);
    const [offerStageCount, setOfferStageCount] = useState<number>(0);
    const [hiredStageCount, setHiredStageCount] = useState<number>(0);
    const [selectedJob, setSelectedJob] = useState<number | 0>(0);
    const [applicantList, setApplicantList] = useState<IApplicant[]>([]);


    const { jobsList: activeJobsList } = useAppSelector(state => state.employer.dashboard.dashboardSummaryWidgetJobList);
    const { applicantsList: applicantsData } = useAppSelector(state => state.employer.dashboard.dashboardSummaryWidgetApplicantList);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForJobSummaryWidget);

    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        dispatch(fetchJobSummaryWidgetActiveJobsList(params));
    }, [dispatch]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: MaxDashboardApplicantSize,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };
        dispatch(fetchJobSummaryWidgetApplicantList(params));
    }, [dispatch]);

    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);


    useEffect(() => {
        if (applicantsData) {
            setApplicantList(applicantsData);
        }
    }, [applicantsData]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);

            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);

    const handleDashboardApplicantSummaryChange = (event: SelectChangeEvent<string>) => {
        const jobId = parseInt(event.target.value);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }
    };

    useEffect(() => {
        const fetchData = () => {
            const stageCounts = applicantList.reduce<Record<string, number>>((acc, { jobStage }) => {
                acc[jobStage] = (acc[jobStage] || 0) + 1;
                return acc;
            }, {});

            setCandidateCount(applicantList.length);
            setNewStageCount(stageCounts.new || 0);
            setInterviewStageCount(stageCounts.interview || 0);
            setRejectedStageCount(stageCounts.rejected || 0);
            setOfferStageCount(stageCounts.offer || 0);
            setHiredStageCount(stageCounts.hired || 0);
        };
        fetchData();

        return () => {
            dispatch(resetApplicantsState())
        };
    }, [activeJobsList, selectedJob, applicantList, dispatch]);


    return (<>
        <ShPaper flex={.5} variant="outlined" height='100%' >
            <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2} alignItems={isSmScreen ? 'left' : 'center'} marginBottom={1} justifyContent='space-between'>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='body1' padding={.5}> Total Applications</Typography>
                    <ShChip textColor={darken(ShGreen, .6)} bgColor={lighten(ShGreen, .7)} variant='filled'
                        color='success' label={candidateCount} size='small' />
                </Stack>
                <Box width={250} >
                    <FormControl fullWidth size='small' disabled={activeJobsList.length === 0}>
                        <InputLabel style={{fontSize:'small'}}>{activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}</InputLabel>
                        <Select 
                        style={{ fontSize: 'small' }}
                        MenuProps={DashboardMenuProps} 
                        onChange={handleDashboardApplicantSummaryChange} 
                        value={activeJobsList.length > 0 ? selectedJob.toString() : ''}
                        input={<OutlinedInput label="Select a JobsX" />}>
                            {activeJobsList?.map((job) => (
                                <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                     {truncateText(job.name, 30)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Stack>
            <Divider />
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent='space-between' paddingTop={2}>
                <Stack flexDirection='row' alignItems='center' columnGap={7} padding={1}>
                    <Box>
                        <Badge badgeContent={newStageCount === 0 ? '0' : newStageCount} color="primary">
                            <PersonOutlineIcon color='action' fontSize="large" />
                        </Badge>
                        <Typography variant='subtitle2'> New </Typography>
                    </Box>
                    <Box>
                        <Badge badgeContent={interviewStageCount === 0 ? '0' : interviewStageCount} color="primary">

                            <PersonOutlineIcon color='action' fontSize="large" />
                        </Badge>
                        <Typography variant='subtitle2'> Interview </Typography>
                    </Box>
                    <Box >
                        <Badge badgeContent={rejectedStageCount === 0 ? '0' : rejectedStageCount} color="primary">

                            <PersonOutlineIcon color='action' fontSize="large" />
                        </Badge>
                        <Typography variant='subtitle2'> Rejected </Typography>
                    </Box>
                    <Box>
                        <Badge badgeContent={offerStageCount === 0 ? '0' : offerStageCount} color="primary">
                            <PersonOutlineIcon color='action' fontSize="large" />
                        </Badge>
                        <Typography variant='subtitle2'> Offer </Typography>
                    </Box>
                    <Box>
                        <Badge badgeContent={hiredStageCount === 0 ? '0' : hiredStageCount} color="primary">
                            <PersonOutlineIcon color='action' fontSize="large" />
                        </Badge>
                        <Typography variant='subtitle2'> Hired </Typography>
                    </Box>
                </Stack>
            </Stack>
        </ShPaper>
    </>);
};
