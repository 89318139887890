import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import adminReducer from 'store/slices/admin/index';
import appReducer from "store/slices/app";
import authReducer from "store/slices/auth-v2";
import candidateReducer from "store/slices/candidate";
import employerReducer from "store/slices/employer";
import jobsSlice from "store/slices/jobs-slice";
import metadataReducer from "store/slices/meta-data";
import { TLogoutType } from './slices/auth-v2/auth-v2-model';
import commonReducer from './slices/common';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  // blacklist: ["jobs"],
};

const rootReducer = combineReducers({
  admin: adminReducer,
  employer: employerReducer,
  candidate: candidateReducer,
  auth: authReducer,
  app: appReducer,
  metadata: metadataReducer,
  jobs: jobsSlice.reducer,
  common: commonReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Custom middleware to purge the state on logout
 * */
const clearStateMiddleware = () =>
  (next: (arg0: any) => any) =>
    (action: {
      type: string; payload: { logoutType: TLogoutType }
    }) => {
      if (action.type === 'authV2/logoutAccount') {
        persister.purge();
      }
      return next(action);
    };

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(clearStateMiddleware),
});

const persister = persistStore(store);

export default store;
export { persister };

