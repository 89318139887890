import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Box, DialogContent, DialogTitle, IconButton, Stack, Tab, TableBody, TableCell,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import { PricingDialog, PricingTable, XSOnlyBlock } from 'Modules/Core/Pricing/Pricing.styled';
import { PricingFeaturesMap, PricingTypes } from 'Modules/Core/Pricing/PricingConstants';
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useState } from 'react';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { togglePricingDialog } from 'store/slices/app/app-info-slice';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const PricingInfo = () => {
    const dispatch = useAppDispatch();
    const { isPricingOpen } = useAppSelector((state) => state.app.appInfo);
    const [tabValue, setTabValue] = useState<number>(0);
    const isXsScreen = IsXsScreen();

    return (<>
        {/* Pricing Dialog */}
        <PricingDialog open={isPricingOpen} onClose={() => dispatch(togglePricingDialog())} aria-labelledby="title"
            maxWidth='xl' scroll='paper' fullScreen={isXsScreen}>
            <DialogTitle>
                <Stack alignItems='center' className='title-block'>
                    <ShLogo />
                    <Typography id='title' variant="subtitle1" textAlign='center' gutterBottom>
                        Packages and Pricing
                    </Typography>
                </Stack>
                <IconButton className='close-btn' onClick={() => dispatch(togglePricingDialog())}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                <PricingTable>
                    <TableHead>
                        <TableRow>
                            <TableCell className='title-head'>
                                <Stack>
                                    <ShLogo />
                                    <Typography id='title' variant="subtitle1" textAlign='center'>
                                        Packages and Pricing
                                    </Typography>
                                </Stack>
                            </TableCell>
                            {PricingTypes.map(pt => (
                                <TableCell key={pt.pricingLabel}>
                                    <Stack className={pt.pricingType === 'MAX' ? 'best-value' : ''} padding={1} paddingTop={1.6} borderRadius={2}>
                                        <Typography variant="h5" textAlign='center' fontWeight={600}>{pt.pricingLabel}</Typography>
                                        <Typography variant="caption" whiteSpace='nowrap'>{pt.description}</Typography>
                                        <Typography variant="h6" fontWeight={500} textAlign='center'>{pt.price}</Typography>
                                        <Typography variant="caption" textAlign='center' color={'InactiveCaptionText'}>({pt.unit})</Typography>
                                    </Stack>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* Empty spacing row acts as spacing between header and cells */}
                        <TableRow>
                            <TableCell component="td" scope="row" height='30px'></TableCell>
                            {PricingTypes.map(pt => (
                                <TableCell key={pt.pricingLabel} align="center"></TableCell>
                            ))}
                        </TableRow>
                        {PricingFeaturesMap.map((row, indx) => (
                            <TableRow key={row.label} className={indx % 2 === 0 ? 'odd-row' : ''}>
                                <TableCell component="td" scope="row">
                                    {row.label}
                                </TableCell>
                                {PricingTypes.map(pt => (
                                    <TableCell key={pt.pricingLabel} align="center"> {row.includesIn?.includes(pt.pricingType) ?
                                        <CheckIcon color='success' /> : <ClearIcon color='error' />}  </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </PricingTable>
                {/* Hide tabs block which will be displayed only in mobile view to eliminate MUI tabs null value error. */}
                {isXsScreen &&
                    <XSOnlyBlock>
                        <ShTabs value={tabValue} onChange={(evt, newValue) => setTabValue(newValue)} aria-label="Pricing tabs"
                            highlightVariant='underline' variant='fullWidth' scrollButtons allowScrollButtonsMobile>
                            {PricingTypes.map(pt => (
                                <Tab key={pt.pricingLabel} label={
                                    <Stack className={pt.pricingType === 'MAX' ? 'best-value' : ''} padding={0.5} borderRadius={2} marginTop={0.5}>
                                        <Typography textTransform='none' variant="body1" textAlign='center' fontWeight={600}>{pt.pricingLabel}</Typography>
                                        <Typography textTransform='none' variant="caption" whiteSpace='nowrap'>{pt.description}</Typography>
                                        <Typography textTransform='none' variant="body2" fontWeight={500} textAlign='center'>{pt.price}</Typography>
                                        <Typography textTransform='none' variant="caption" textAlign='center' color={'InactiveCaptionText'}>({pt.unit})</Typography>
                                    </Stack>
                                } />

                            ))}
                        </ShTabs>
                        <TabPanel value={tabValue} index={0}>
                            {PricingFeaturesMap.filter(pm => pm.includesIn?.includes('BASIC')).map((pm, indx) => (
                                <Stack key={indx} className={indx % 2 === 0 ? 'odd-row' : ''}
                                    paddingX={1} paddingY={0.5} borderRadius={10}
                                    alignItems='center' flexDirection='row' columnGap={1} marginTop={1}>
                                    <CheckIcon color='success' />
                                    <Typography>{pm.label}</Typography>
                                </Stack>
                            ))}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {PricingFeaturesMap.filter(pm => pm.includesIn?.includes('PRO')).map((pm, indx) => (
                                <Stack key={indx} className={indx % 2 === 0 ? 'odd-row' : ''}
                                    paddingX={1} paddingY={0.5} borderRadius={10}
                                    alignItems='center' flexDirection='row' columnGap={1} marginTop={1}>
                                    <CheckIcon color='success' />
                                    <Typography>{pm.label}</Typography>
                                </Stack>
                            ))}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            {PricingFeaturesMap.filter(pm => pm.includesIn?.includes('MAX')).map((pm, indx) => (
                                <Stack key={indx} className={indx % 2 === 0 ? 'odd-row' : ''}
                                    paddingX={1} paddingY={0.5} borderRadius={10}
                                    alignItems='center' flexDirection='row' columnGap={1} marginTop={1}>
                                    <CheckIcon color='success' />
                                    <Typography>{pm.label}</Typography>
                                </Stack>
                            ))}
                        </TabPanel>
                    </XSOnlyBlock>}
            </DialogContent>
        </PricingDialog>
    </>);
};