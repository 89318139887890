import { Dialog, Stack, Table } from "@mui/material";
import { styled } from '@mui/material/styles';
import { BorderColorDark, BorderColorLight, ShGreen, WhiteColor } from "shared/SharedStyles/styleConstants";

export const XSOnlyBlock = styled(Stack)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        '& .odd-row': {
            backgroundColor: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark,
        }
    })
);

export const PricingDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        '& .MuiDialogTitle-root': {
            '& .title-block': {
                display: 'flex',
                paddingBottom: 0,
                '& img': {
                    maxWidth: '200px'
                }
            }
        },
        '& .MuiDialogContent-root': {
            borderTop: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`
        }
    },
    [theme.breakpoints.up('sm')]: {
        '& .MuiDialogTitle-root': {
            '& .title-block': {
                display: 'none'
            }
        },
        '& .MuiDialogContent-root': {
            borderTop: 'none'
        }
    },
    '& .MuiDialogTitle-root': {
        minHeight: '50px',
        position: 'relative',
        '& .close-btn': {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1)
        }
    },
    '& .MuiDialogTitle-root, .MuiDialogActions-root': {
        backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDialogContent-root': {
        backgroundColor: theme.palette.background.paper,
        paddingTop: '10px !important',
    },
    '& .MuiDialog-paper': {
        minHeight: '464px',
        minWidth: '350px'
    },
    '& .best-value': {
        position: 'relative',
        backgroundColor: ShGreen,
        color: WhiteColor,
        '& .MuiTypography-caption': {
            color: WhiteColor
        },
        '&::before': {
            content: "'Best Value'",
            position: 'absolute',
            whiteSpace: 'nowrap',
            padding: '1px 8px',
            borderRadius: '20px',
            top: '-13px',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.getContrastText(theme.palette.primary.main),
        }
    }
}));

export const PricingTable = styled(Table)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'table'
        },
        [theme.breakpoints.up('md')]: {
            display: 'table'
        },
        borderCollapse: 'separate',
        marginTop: theme.spacing(1),
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: `${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
            backgroundColor: theme.palette.background.paper,
            '&:first-of-type': {
                position: 'sticky',
                left: 0,
                zIndex: 1
            },
            '& img': {
                maxWidth: '220px'
            }
        },
        '& .title-head': {
            borderRight: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`
        },
        '& .odd-row': {
            '& .MuiTableCell-root': {
                backgroundColor: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark,
                overflow: 'hidden',
                '&:first-of-type': {
                    borderTopLeftRadius: '20px',
                    borderBottomLeftRadius: '20px',
                },
                '&:last-of-type': {
                    borderTopRightRadius: '20px',
                    borderBottomRightRadius: '20px',
                }
            }
        }
    })
);

export const PricingSelectionDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        maxWidth: '800px',
        minWidth: '350px'
    },
    '& .MuiDialogTitle-root, .MuiDialogContent-root': {
        backgroundColor: theme.palette.background.paper
    },
    '& .MuiFormControlLabel-label': {
        flex: 1
    },
    '& label': {
        border: `2px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
        borderRadius: '5px',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(1.5),
        marginLeft: 0,
        marginRight: 0,
        marginBottom: theme.spacing(1),
        '&.selected-package': {
            borderColor: ShGreen,
            '& .MuiRadio-root.Mui-checked': {
                color: ShGreen
            }
        }
    }
}));
