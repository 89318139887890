import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IEmployer, IEmployerCustomState, IEmployerPostedJob } from 'Modules/Subdomain/EmployerSubDomainLayout/EmployerSubDomainModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialEmployerCustomState: IEmployerCustomState = {
    getEmployerByMaskingNameStatus: 'idle'
};

export const getEmployerDetailsByMaskingName = createAsyncThunk<IBaseResponse<IEmployer>, { maskingName: string }, { rejectValue: IBaseResponse }>(
    "getEmployerDetailsByMaskingName",
    async ({ maskingName }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${maskingName}/details`)
            .then((response: AxiosResponse<IBaseResponse<IEmployer>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getEmployerPostedJobs = createAsyncThunk<IBaseResponse<IEmployerPostedJob[]>, { employerId: number }, { rejectValue: IBaseResponse }>(
    "getEmployerPostedJobs",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_POSTED_JOBS}?employerId=${employerId}`)
            .then((response: AxiosResponse<IBaseResponse<IEmployerPostedJob[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const employerSubDomainSlice = createSlice({
    name: 'employerSubDomain',
    initialState: initialEmployerCustomState,
    reducers: {
        resetEmployerPostedJobs: (state) => {
            state.getEmployerPostedJobsStatus = 'idle';
            state.getEmployerPostedJobsResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialEmployerCustomState;
        });
        // get employer details
        builder.addCase(getEmployerDetailsByMaskingName.pending, (state) => {
            state.getEmployerByMaskingNameStatus = 'pending';
            state.isEmployerNotFound = false;
        });
        builder.addCase(getEmployerDetailsByMaskingName.fulfilled, (state, action) => {
            state.getEmployerByMaskingNameStatus = 'success';
            state.employerByMask = action.payload.data;
        });
        builder.addCase(getEmployerDetailsByMaskingName.rejected, (state, action) => {
            state.getEmployerByMaskingNameStatus = 'failed';
            state.getEmployerByMaskingNameResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.isEmployerNotFound = action.payload?.statusCode === 404;
        });
        // employer posted jobs
        builder.addCase(getEmployerPostedJobs.pending, (state) => {
            state.getEmployerPostedJobsStatus = 'pending'
        });
        builder.addCase(getEmployerPostedJobs.fulfilled, (state, action) => {
            state.getEmployerPostedJobsStatus = 'success';
            state.employerPostedJobs = action.payload.data;
        });
        builder.addCase(getEmployerPostedJobs.rejected, (state, action) => {
            state.getEmployerPostedJobsStatus = 'failed';
            state.getEmployerPostedJobsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetEmployerPostedJobs } = employerSubDomainSlice.actions;
export default employerSubDomainSlice;
