import ConstructionIcon from '@mui/icons-material/Construction';
import { Stack, Typography } from '@mui/material';

export const ToolsHome = () => {

    return (
        <>
            <Stack justifyContent='center' alignItems='center' height='100%'>
                <Stack justifyContent='center' alignItems='center'>
                    <ConstructionIcon fontSize='large' color='info' />
                    <Typography variant='h6'>Page under construction</Typography>
                </Stack>
            </Stack>
        </>
    );
};
