import { Credits } from 'Modules/Core/Admin/EmployerProfile/Credits';
import { DisableEmployer } from 'Modules/Core/Admin/EmployerProfile/DisableEmployer';
import { EmployerAccounts } from 'Modules/Core/Admin/EmployerProfile/EmployerAccounts';
import { EmployerDetails } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails';
import { MainContainer } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails.styled';
import { Products } from 'Modules/Core/Admin/EmployerProfile/Products';
import { Subscriptions } from "Modules/Core/Admin/EmployerProfile/Subscriptions";
import { Notification } from "Modules/Core/Notification";
import { useParams } from 'react-router-dom';

export const EmployerDetailsWrapper = () => {
    const { employerId } = useParams();

    return (<>
        <MainContainer>
            <EmployerDetails employerId={employerId} />
            <Subscriptions employerId={employerId} />
            <EmployerAccounts employerId={employerId} />
            <Products employerId={employerId} />
            <Credits employerId={employerId} />
            <DisableEmployer />
        </MainContainer>
        <Notification />
    </>);
}