import { combineReducers } from '@reduxjs/toolkit';
import timezonesSlice from './timezone-slice';

const commonReducer = combineReducers({
    timezones: timezonesSlice.reducer,
});

export { timezonesSlice };
export default commonReducer;

