import { SvgIcon } from '@mui/material';
import { IShIcon } from 'shared/SharedModels';

export const ShCreateJobIcon = ({ primaryColor, secondaryColor }: IShIcon) => {
    return (<>
        <SvgIcon viewBox='0 0 20 20'>
            <path d="M4 0.7H16C17.8225 0.7 19.3 2.17746 19.3 4V16C19.3 17.8225 17.8225 19.3 16 19.3H4C2.17746 19.3 0.7 17.8225 0.7 16V4C0.7 2.17746 2.17746 0.7 4 0.7Z" stroke={secondaryColor} strokeWidth="1.4" fillOpacity='0' />
            <path d="M14.8584 9.03991H11.2218C11.1345 9.03991 11.0182 8.95263 11.0182 8.83626V5.19965C11.0182 4.64689 10.5818 4.21049 10.029 4.21049C9.47625 4.21049 9.03986 4.64689 9.03986 5.19965V8.80716C9.03986 8.89444 8.95258 9.01081 8.83621 9.01081H5.19961C4.64684 9.01081 4.21045 9.44721 4.21045 9.99997C4.21045 10.5527 4.64684 10.9891 5.19961 10.9891H8.80712C8.8944 10.9891 9.01077 11.0764 9.01077 11.1928V14.8003C9.01077 15.3531 9.44716 15.7894 9.99992 15.7894C10.5527 15.7894 10.9891 15.3531 10.9891 14.8003V11.2219C10.9891 11.1346 11.0764 11.0182 11.1927 11.0182H14.8002C15.353 11.0182 15.7894 10.5818 15.7894 10.0291C15.7894 9.4763 15.4112 9.03991 14.8584 9.03991Z" fill={primaryColor} />
        </SvgIcon>
    </>)
};