import { Box, Checkbox, FormControlLabel, LinearProgress, Stack, TextField, Typography } from '@mui/material';
import { IAccountInfo } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { DefaultAPIErrorMsg, EmailRegEx } from 'shared/constants';
import { updateAccountDetails } from 'store/slices/auth-v2/auth-v2-slice';
import { getAccInfo, resetUpdateAccInfo, updateAccountInfo } from 'store/slices/employer/settingsTs/acc-info-slice';

export const AccountInfo = () => {

    const dispatch = useAppDispatch();
    const { getAccInfoApiStatus, getAccInfoApiResponse, accountInfo, updateAccInfoApiStatus,
        updateAccInfoApiResponse
    } = useAppSelector((state) => state.employer.employerSettings.accInfo);
    const [accInfo, setAccInfo] = useState<IAccountInfo>({
        id: 0,
        first_name: '',
        last_name: '',
        email: '',
        calendly_token: '',
        applicant_notifications: false
    });
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    // get account information
    useEffect(() => {
        if (!accountInfo.id) {
            dispatch(getAccInfo());
        }

        return () => {
            // Reset update account infoAPIvariables on component unmount.
            dispatch(resetUpdateAccInfo());
        }
    }, [accountInfo.id, dispatch]);

    // update account information form after the data is retrieved
    useEffect(() => {
        if (getAccInfoApiStatus === 'success' && accountInfo !== undefined) {
            const tempAccInfo = {
                first_name: accountInfo.first_name,
                last_name: accountInfo.last_name,
                email: accountInfo.email,
                calendly_token: '',
                id: accountInfo.id,
                applicant_notifications: accountInfo.applicant_notifications
            };
            setAccInfo(tempAccInfo);
        }
    }, [accountInfo, getAccInfoApiStatus]);

    // This is just a good measure to ensure the acc info view is updated after its been changed
    // otherwise the old name will still show until refresh
    useEffect(() => {
        if (updateAccInfoApiStatus === 'success') {
            dispatch(getAccInfo());
        }
    }, [updateAccInfoApiStatus, dispatch]);

    // Email validation function with Regular Expression.
    const validateEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInvalid(!reg.test(e.target.value));
    };

    // Disable save if any required field is empty or email is invalid
    useEffect(() => {
        setIsSaveDisabled(() => {
            return accInfo.first_name.trim()?.length === 0 || accInfo.last_name.trim()?.length === 0 ||
                accInfo.email.trim()?.length === 0 || isEmailInvalid
        });
    }, [accInfo, isEmailInvalid]);

    const updateAccInfo = () => {
        const { first_name, last_name, applicant_notifications } = accInfo;
        dispatch(updateAccountInfo({ userId: accInfo.id, payload: { first_name, last_name, applicant_notifications } }));
    };

    const InfoUpdateAlerts = () => {
        switch (updateAccInfoApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Updating Information...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={() => dispatch(resetUpdateAccInfo())}>
                    <Typography fontWeight={600}>{updateAccInfoApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{updateAccInfoApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    // update first and last name in auth state once account details are updated.
    useEffect(() => {
        if (updateAccInfoApiStatus === 'success') {
            const { first_name, last_name } = accInfo;
            dispatch(updateAccountDetails({ firstName: first_name, lastName: last_name }));
        }
    }, [accInfo, dispatch, updateAccInfoApiStatus]);

    return (<>
        <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} minHeight='350px' maxWidth='400px'>
            {/* Alerts placeholder block with min height to maintain height consistency while showing alerts. */}
            {getAccInfoApiStatus === 'failed' ?
                <Box marginBottom={2} width='100%'>
                    <ShAlert severity="error">{getAccInfoApiResponse ?? DefaultAPIErrorMsg}</ShAlert></Box>
                : updateAccInfoApiStatus !== 'idle' && <Box marginBottom={2}>{InfoUpdateAlerts()}</Box>}
            {/*Show loading spinner whileAPIis fetching data*/}
            {getAccInfoApiStatus === 'pending' ?
                <LinearProgress /> :
                <>
                    <TextField label="First Name" variant="outlined" fullWidth size='small' required
                        value={accInfo.first_name} onChange={(e) => setAccInfo({ ...accInfo, first_name: e.target.value })}></TextField>
                    <TextField label="Last Name" variant="outlined" fullWidth size='small' required
                        value={accInfo.last_name} onChange={(e) => setAccInfo({ ...accInfo, last_name: e.target.value })}></TextField>
                    <TextField label="Email Address" variant="outlined" fullWidth size='small' required disabled
                        value={accInfo.email} onChange={(e) => { setAccInfo({ ...accInfo, email: e.target.value }); validateEmail(e) }}
                        error={isEmailInvalid}></TextField>
                    {/* <TextField label="Calendly Token" variant="outlined" fullWidth size='small'
                        value={accInfo.calendly_token} onChange={(e) => setAccInfo({ ...accInfo, calendly_token: e.target.value })}></TextField> */}
                    <FormControlLabel label={<Typography variant='body2'>New Applicant Notifications</Typography>}
                        control={<Checkbox size='small' checked={accInfo.applicant_notifications}
                            onChange={e => setAccInfo({ ...accInfo, applicant_notifications: e.target.checked })} />} />
                    <ShGreenBtn variant='contained' disableElevation disabled={isSaveDisabled} onClick={updateAccInfo}>
                        {updateAccInfoApiStatus === 'pending' ? 'Saving Changes...' : 'Save Changes'}
                    </ShGreenBtn>
                </>}
        </Stack>
    </>);
}
