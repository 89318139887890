import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";
import { IIndustry, IJobRole, IJobsMetaState, IJobsState } from "./meta-data-model";

const initialJobsMetaState: IJobsMetaState = {
    industries: [],
    jobTitles: [],
    getJobTitlesStatus: 'idle',
    getJobTitlesResponse: '',
    getJobStatesStatus: 'idle',
    getJobStatesResponse: '',
    jobStates: [],
    getJobStatesByCountryStatus: 'idle',
    getJobStatesByCountryResponse: '',
    jobStatesByCountry: []
};

export const getIndustries = createAsyncThunk<IBaseResponse<IIndustry[]>,
    void, { rejectValue: IBaseResponse }>(
        "getIndustries",
        async (_, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.EMPLOYER_INDUSTRIES}`)
                .then((response: AxiosResponse<IBaseResponse<IIndustry[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const getJobTitles = createAsyncThunk<{ industryTitle: string, roles: IJobRole[] }, void, { rejectValue: IBaseResponse }>(
    "getJobTitles",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_TITLES}`)
            .then((response: AxiosResponse<{ industryTitle: string, roles: IJobRole[] }>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobsStates = createAsyncThunk<IBaseResponse<IJobsState[]>, void, { rejectValue: IBaseResponse }>(
    "getJobsStates",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOB_STATES}`)
            .then((response: AxiosResponse<IBaseResponse<IJobsState[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobsStatesByCountry = createAsyncThunk<IBaseResponse<IJobsState[]>, { countryId: number }, { rejectValue: IBaseResponse }>(
    "getJobsStatesByCountry",
    async ({ countryId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.STATES_BY_COUNTRY}/${countryId}`)
            .then((response: AxiosResponse<IBaseResponse<IJobsState[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const jobsMetaSlice = createSlice({
    name: "jobsMeta",
    initialState: initialJobsMetaState,
    reducers: {
        resetGetIndustries: (state) => {
            state.getIndustriesStatus = 'idle';
            state.getIndustriesResponse = ''
        },
        resetGetJobRoles: (state) => { state.getJobTitlesStatus = 'idle'; state.getJobTitlesResponse = '' },
        resetGetJobsStates: (state) => { state.getJobStatesStatus = 'idle'; state.getJobStatesResponse = '' },
        resetGetJobsStatesByCountry: (state) => { state.getJobStatesByCountryStatus = 'idle'; state.getJobStatesByCountryResponse = '' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialJobsMetaState
        });

        // get industries
        builder.addCase(getIndustries.pending, (state) => {
            state.getIndustriesStatus = 'pending'
        });
        builder.addCase(getIndustries.fulfilled, (state, action) => {
            state.getIndustriesStatus = 'success';
            state.industries = action.payload?.data;
        });
        builder.addCase(getIndustries.rejected, (state, action) => {
            state.getIndustriesStatus = 'failed';
            state.getIndustriesResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get roles
        builder.addCase(getJobTitles.pending, (state) => {
            state.getJobTitlesStatus = 'pending'
        });
        builder.addCase(getJobTitles.fulfilled, (state, action) => {
            state.getJobTitlesStatus = 'success';
            /*
                Sort the array while assigning to 'jobTitles'.
                MUI Autocomplete has grouping feature which works best with sorted array.
                Unsorted array might result in duplicate grouping labels. 
            */
            state.jobTitles = action.payload.roles.sort((a, b) => {
                if (a.industry < b.industry) {
                    return -1;
                }
                if (a.industry > b.industry) {
                    return 1;
                }
                return 0;
            });
        });
        builder.addCase(getJobTitles.rejected, (state, action) => {
            state.getJobTitlesStatus = 'failed';
            state.getJobTitlesResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get states
        builder.addCase(getJobsStates.pending, (state) => {
            state.getJobStatesStatus = 'pending';
        });
        builder.addCase(getJobsStates.fulfilled, (state, action) => {
            state.getJobStatesStatus = 'success';
            state.jobStates = action.payload.data;
        });
        builder.addCase(getJobsStates.rejected, (state, action) => {
            state.getJobStatesStatus = 'failed';
            state.getJobStatesResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get states by country
        builder.addCase(getJobsStatesByCountry.pending, (state) => {
            state.getJobStatesByCountryStatus = 'pending';
        });
        builder.addCase(getJobsStatesByCountry.fulfilled, (state, action) => {
            state.getJobStatesByCountryStatus = 'success';
            state.jobStatesByCountry = action.payload.data;
        });
        builder.addCase(getJobsStatesByCountry.rejected, (state, action) => {
            state.getJobStatesByCountryStatus = 'failed';
            state.getJobStatesByCountryResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetIndustries, resetGetJobRoles, resetGetJobsStates, resetGetJobsStatesByCountry } = jobsMetaSlice.actions;
export default jobsMetaSlice;
