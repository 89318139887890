import { SvgIcon } from '@mui/material';
import React from 'react';

export const GoogleMcIcon = () => {
    return (<>
        <SvgIcon>
            <path d="M 23.714844 12.222656 C 23.714844 11.242188 23.632812 10.523438 23.460938 9.78125 L 12.234375 9.78125 L 12.234375 14.21875 L 18.824219 14.21875 C 18.691406 15.320312 17.972656 16.980469 16.378906 18.097656 L 16.355469 18.246094 L 19.90625 20.996094 L 20.152344 21.019531 C 22.410156 18.933594 23.714844 15.867188 23.714844 12.222656 " fill='#4285f4' />
            <path d="M 12.234375 23.917969 C 15.460938 23.917969 18.171875 22.855469 20.152344 21.019531 L 16.378906 18.097656 C 15.371094 18.800781 14.015625 19.292969 12.234375 19.292969 C 9.070312 19.292969 6.386719 17.207031 5.429688 14.324219 L 5.289062 14.335938 L 1.597656 17.191406 L 1.550781 17.328125 C 3.515625 21.234375 7.554688 23.917969 12.234375 23.917969 " fill='#34a853' />
            <path d="M 5.429688 14.324219 C 5.179688 13.578125 5.03125 12.78125 5.03125 11.957031 C 5.03125 11.136719 5.179688 10.335938 5.417969 9.59375 L 5.410156 9.433594 L 1.671875 6.53125 L 1.550781 6.589844 C 0.738281 8.210938 0.273438 10.03125 0.273438 11.957031 C 0.273438 13.886719 0.738281 15.707031 1.550781 17.328125 L 5.429688 14.324219 " fill='#fbbc05' />
            <path d="M 12.234375 4.625 C 14.480469 4.625 15.992188 5.59375 16.859375 6.40625 L 20.234375 3.109375 C 18.160156 1.183594 15.460938 0 12.234375 0 C 7.554688 0 3.515625 2.683594 1.550781 6.589844 L 5.417969 9.59375 C 6.386719 6.710938 9.070312 4.625 12.234375 4.625 " fill='#eb4335' />
        </SvgIcon>
    </>)
};