import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { IconButton, Menu, Paper, Stack, Tab, Tooltip, Typography } from "@mui/material";
import { InterviewHeaders } from 'Modules/Core/Interviews/InterviewsConstants';
import { IInterviewsToolbar, InterviewTypes } from "Modules/Core/Interviews/InterviewsModel";
import { IsSmScreen } from 'helpers/hooks';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LabelWithBadge } from 'shared/SharedStyles/ShDataDisplay';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShTabs, StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { ShToolbarPaper } from 'shared/SharedStyles/ShSurfaces';
import { PaperVariant } from 'shared/SharedStyles/styleConstants';

export const InterviewsToolbar = ({ interviewsType, interviewsCount, setInterviewsType,
    getInterviews, sortList, sortParams }: IInterviewsToolbar) => {

    const isSmScreen = IsSmScreen();
    const [sortByAnchorEl, setSortByAnchorEl] = useState<HTMLElement | null>(null);
    const isSortByOpen = Boolean(sortByAnchorEl);

    const onTabChange = (newValue: InterviewTypes) => {
        if (newValue !== interviewsType) {
            getInterviews(newValue);
            setInterviewsType(newValue);
        }
    };

    return (<>
        <ShToolbarPaper elevation={0}>
            <Stack className='tools-stack' columnGap={1} flexWrap={isSmScreen ? 'wrap-reverse' : 'nowrap'}
                rowGap={1} justifyContent="space-between">
                <Stack direction='row' maxWidth='100%'>
                    <ShTabs className="settings-tabs" aria-label="Interviews Tabs" variant="scrollable" scrollButtons='auto'
                        allowScrollButtonsMobile value={interviewsType} onChange={(e, nV) => { onTabChange(nV); }}>
                        <Tab label={
                            <LabelWithBadge variant='body2'>Scheduled Interviews&nbsp;
                                <Typography variant='caption'>{interviewsCount.scheduled}</Typography>
                            </LabelWithBadge>} value={InterviewTypes.Scheduled} component={Link}
                            to='/employer/interviews/scheduled' />
                        <Tab label={
                            <LabelWithBadge variant='body2'>Completed Interviews&nbsp;
                                <Typography variant='caption'>{interviewsCount.completed}</Typography>
                            </LabelWithBadge>} value={InterviewTypes.Completed} component={Link}
                            to='/employer/interviews/completed' />
                        <Tab label={
                            <LabelWithBadge variant='body2'>Cancelled Interviews&nbsp;
                                <Typography variant='caption'>{interviewsCount.cancelled}</Typography>
                            </LabelWithBadge>} value={InterviewTypes.Cancelled} component={Link}
                            to='/employer/interviews/cancelled' />
                    </ShTabs>
                </Stack>
                <Stack flexDirection='row' columnGap={1}>
                    <Paper className="custom-paper" variant={PaperVariant}>
                        {/* Sort by column name dropdown and sort direction button*/}
                        <Stack flexDirection='row' columnGap={{ xs: 0, sm: 1, md: 1, lg: 1 }}>
                            <ShButton size="small"
                                aria-label={`Sort by ${InterviewHeaders.filter(interviewHeader => interviewHeader.isSortable)
                                    .find(ah => sortParams && ah.columName === sortParams.orderBy)?.label}
                                in ${sortParams.order === 'asc' ? 'ascending' : 'descending'} order`}
                                onClick={e => setSortByAnchorEl(e.currentTarget)}>
                                {InterviewHeaders.filter(interviewHeader => interviewHeader.isSortable)
                                    .find(ah => ah.columName === sortParams.orderBy)?.label}
                            </ShButton>
                            <Menu id="sort-by-menu" aria-label='Sort by menu' anchorEl={sortByAnchorEl}
                                open={isSortByOpen} onClose={() => setSortByAnchorEl(null)}>
                                {InterviewHeaders.filter(interviewHeader => interviewHeader.isSortable).map(hd => (
                                    <StyledMenuItem key={hd.columName} className={hd.columName === sortParams.orderBy ? "selected-item" : ""}
                                        onClick={() => { sortList(hd.columName, sortParams.order); setSortByAnchorEl(null); }}>
                                        {hd.label}</StyledMenuItem>
                                ))}
                            </Menu>
                            <Tooltip arrow title="Reverse sort direction">
                                <IconButton size='small'
                                    aria-label={`Sort in ${sortParams.order === 'asc' ? 'ascending' : 'descending'} order`}
                                    onClick={() => {
                                        sortList(sortParams.orderBy, sortParams.order === 'asc' ? 'desc' : 'asc');
                                        setSortByAnchorEl(null);
                                    }}>
                                    {sortParams.order === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack >
        </ShToolbarPaper>
    </>);
};