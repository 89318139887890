import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Stack, ToggleButton, Typography } from "@mui/material";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShLoadingDots } from 'shared/SharedComponents/ShLoadingDots/ShLoadingDots';
import { ShBullets } from 'shared/SharedStyles/ShDataDisplay';
import { ShGreenBtn, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { completeAtsJobApplication, resetCompleteAtsJobApplication, updateJobRequirementAnswer } from 'store/slices/candidate/apply-job/apply-job-questionnaire.slice';
import { completeJobApplication } from 'store/slices/candidate/survey/survey-slice';
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';
import { useNotification } from '../Notification';

export const ApplyJobQuestionnaire = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const notification = useNotification();
    const currentPath = location.pathname + location.search;
    const { jobApplicationRequirements, isQuestionnaireComplete,
        completeAtsJobApplicationStatus, completeAtsJobApplicationResponse } = useAppSelector((state) => state.candidate.applyJobQuestionnaire);
    const { candidateAssessmentMetadata } = useAppSelector((state) => state.metadata.assessmentMeta);
    const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const { completeJobApplicationStatus, completeJobApplicationResponse } = useAppSelector((state) => state.candidate.survey);
    const isSmScreen = IsSmScreen();

    const updateAnswer = (reqId: number, answerId: number) => {
        if (candidateDetails?.jobApplicationId) {
            dispatch(updateJobRequirementAnswer({
                jobId: candidateDetails?.jobApplicationId,
                payload: { requirementId: reqId, requirementLevelId: answerId }
            }))
        }
    }

    useEffect(() => {
        if (completeAtsJobApplicationStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: completeAtsJobApplicationResponse ?? ''
            });
            dispatch(resetCompleteAtsJobApplication());
            navigate(CandidateLoginRedirect);
        } else if (completeAtsJobApplicationStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: completeAtsJobApplicationResponse ?? ''
            });
        }
    }, [completeAtsJobApplicationResponse, completeAtsJobApplicationStatus, dispatch, navigate, notification]);

    const onSubmitInQuestionnaire = () => {
        if (candidateDetails?.jobApplicationId) {
            if (jobDetailsByCode?.ats_purchased) {
                dispatch(completeAtsJobApplication(candidateDetails?.jobApplicationId));
            } else {
                dispatch(completeJobApplication(candidateDetails?.jobApplicationId));
            }
        }
    }

    useEffect(() => {
        if (completeJobApplicationStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: completeJobApplicationResponse ?? ''
            });
            navigate(CandidateLoginRedirect);
        } else if (completeJobApplicationStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: completeJobApplicationResponse ?? ''
            });
        }
    }, [completeJobApplicationResponse, completeJobApplicationStatus, dispatch, navigate, notification]);

    return (<>
        <Stack rowGap={2}>
            <Typography variant="body2">
                Please rate your experience level for each of the following questions.
                Your answers will be included in your application.
            </Typography>
            <Stack overflow='auto' rowGap={1} maxHeight={450} minHeight={200} paddingRight={1}>
                {jobApplicationRequirements?.map((requirement) => (
                    <Paper variant="outlined" key={requirement.id}>
                        <Grid container padding={1} spacing={1} alignItems='center'>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                                <Typography variant="body2">
                                    {requirement.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} textAlign='end'>
                                <ShToggleButtonGroup color="primary" size="small" exclusive
                                    minWidth='100px' fullWidth={isSmScreen}
                                    orientation={isSmScreen ? 'vertical' : 'horizontal'}
                                    disabled={requirement?.updateJobRequirementAnswerStatus === 'pending'}
                                    value={requirement.applicant_requirement_level_id}>
                                    {candidateAssessmentMetadata?.skills[requirement.requirement_category_id].map((option) => (
                                        <ToggleButton value={option.id} key={option.id}
                                            onClick={() => option.id !== requirement.applicant_requirement_level_id &&
                                                updateAnswer(requirement.id, option.id)}>
                                            {requirement?.updateJobRequirementAnswerStatus === 'pending' &&
                                                requirement.updateJobRequirementAnswerId === option.id &&
                                                <ShLoadingDots variant='positive' />
                                            }
                                            <Typography variant='body2'>
                                                {option.name}
                                            </Typography>
                                        </ToggleButton>
                                    ))}
                                </ShToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Stack>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="preview-content"
                    id="preview-header"
                >
                    Preview
                </AccordionSummary>
                <AccordionDetails>
                    <ShBullets>
                        {jobApplicationRequirements?.map((requirement) => (
                            <li key={requirement.id}>
                                <Typography variant='caption'>{requirement.name} :&nbsp;
                                    <strong>
                                        {candidateAssessmentMetadata?.skills[requirement.requirement_category_id]
                                            .find(option => option.id === requirement.applicant_requirement_level_id)?.name}
                                    </strong></Typography>
                            </li>
                        ))}
                    </ShBullets>
                </AccordionDetails>
            </Accordion>
            <Stack direction='row' justifyContent='flex-end'>
                {jobDetailsByCode?.ats_purchased ?
                    <ShGreenBtn size='small' disabled={!isQuestionnaireComplete} variant="outlined"
                        onClick={onSubmitInQuestionnaire}>
                        {completeAtsJobApplicationStatus === 'pending' ? 'Submitting Application...' : 'Submit'}</ShGreenBtn> :
                    <ShGreenBtn size='small' disabled={!isQuestionnaireComplete || completeAtsJobApplicationStatus === 'pending'}
                        variant="outlined" onClick={() => navigate(`${currentPath.replace('/questionnaire', `/assessment`)}`)}>
                        Next</ShGreenBtn>}
            </Stack>
        </Stack>
    </>)
}