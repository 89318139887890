import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { IconButton, Pagination, Stack, Typography } from "@mui/material";
import Menu from '@mui/material/Menu';
import { IInterviewsFooter } from 'Modules/Core/Interviews/InterviewsModel';
import { useState } from "react";
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { FooterWrapper } from 'shared/SharedStyles/ShLayouts';
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { PageSizes } from 'shared/constants';

export const InterviewsFooter = ({ paginationParams, getInterviews, isSmScreen, interviewsType }: IInterviewsFooter) => {
    const [paginationAnchorEl, setPaginationAnchorEl] = useState<HTMLElement | null>(null);
    const isPageSizeOpen = Boolean(paginationAnchorEl);

    return (<>
        <FooterWrapper>
            <Stack flexDirection='row' justifyContent='flex-end' alignItems='center' width='100%'
                alignSelf='flex-end' columnGap={{ xs: 0, sm: 1, md: 1 }} flexWrap='wrap'>
                {/* Number of records in a given page and total records information. */}
                <Typography variant='caption' fontWeight='bold'>
                    {(paginationParams.requestedPageNumber - 1) * paginationParams.requestedPageSize + 1}-
                    {((paginationParams.requestedPageNumber - 1) * paginationParams.requestedPageSize) +
                        paginationParams.count}&nbsp;of&nbsp;{paginationParams.totalElements}
                </Typography>
                {/* MUI pagination. Changes sibling and boundary counts based on screen size to best fit the page numbers in smaller screens. */}
                <Pagination className='desktop-pagination' color="primary" showFirstButton showLastButton
                    siblingCount={isSmScreen ? 0 : 0} boundaryCount={isSmScreen ? 0 : 2}
                    // requestedPageNumber is being parsed to int because the api is sending in string as response.
                    count={paginationParams.totalPages} page={parseInt(paginationParams.requestedPageNumber.toString())}
                    onChange={(e, pgNo) => getInterviews(interviewsType, pgNo, paginationParams.requestedPageSize)}
                />
                {/* MUI pagination is replaced with simple prev and next stack of buttons for mobile screens. */}
                <Stack className='mobile-pagination' flexDirection='row'>
                    <IconButton disabled={paginationParams.firstPage}
                        onClick={() => getInterviews(interviewsType, paginationParams.requestedPageNumber - 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <IconButton disabled={paginationParams.lastPage}
                        onClick={() => getInterviews(interviewsType, paginationParams.requestedPageNumber + 1, paginationParams.requestedPageSize)}>
                        <KeyboardArrowRightIcon />
                    </IconButton>
                </Stack>
                {/* Page size select menu. */}
                <ShButton size='small' variant='contained' disableElevation onClick={e => setPaginationAnchorEl(e.currentTarget)}
                    color='primary' endIcon={<ExpandMoreIcon />}>{paginationParams.requestedPageSize}</ShButton>
                <Menu id="page-size-menu" aria-label='Page size' anchorEl={paginationAnchorEl}
                    open={isPageSizeOpen} onClose={() => setPaginationAnchorEl(null)}>
                    {PageSizes.map(size => (
                        <StyledMenuItem key={size}
                            className={parseInt(paginationParams.requestedPageSize.toString()) === size ? "selected-item" : ""}
                            onClick={() => {
                                getInterviews(interviewsType, 1, size);
                                setPaginationAnchorEl(null);
                            }}>{size}</StyledMenuItem>
                    ))}
                </Menu>
            </Stack>
        </FooterWrapper>
    </>);
};