import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IJobsApiParams, IJobsListState, TJobsCount } from 'Modules/Core/JobsTs/JobsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';


const initialDashboardActiveJobsListState: IJobsListState = {
    getJobsListStatus: 'idle',
    getJobsListResponse: '',
    getJobsCountsStatus: 'idle',
    getJobsCountsResponse: '',
    jobsList: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    },
    jobsSummary: {},
    activeCount: 0,
    draftCount: 0,
    expiredCount: 0,
    templateCount: 0
};

export const fetchDashboardJobsCounts = createAsyncThunk<TJobsCount, void, { rejectValue: IBaseResponse }>(
    "fetchJobsCounts",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_SUMMARY}`)
            .then((response: AxiosResponse<TJobsCount>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);


export const fetchDashboardActiveJobsList = createAsyncThunk<IBaseResponse, IJobsApiParams, { rejectValue: IBaseResponse }>(
    'fetchDashboardActiveJobsList',
    async ({ sortDirection, pageNumber, pageSize, sortColumn, jobType, search }: IJobsApiParams, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_LIST}/active?pageNo=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}${search && '&search=' + search}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const dashboardActiveJobsSlice = createSlice({
    name: 'dashboardActiveJobsList',
    initialState: initialDashboardActiveJobsListState,
    reducers: {
        toggleJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsList[action.payload].isExpanded = !state.jobsList[action.payload].isExpanded
        },
        resetJobsState: () => initialDashboardActiveJobsListState,
        resetFetchJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsSummary[action.payload].getSummaryStatus = 'idle';
            state.jobsSummary[action.payload].getSummaryResponse = '';
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialDashboardActiveJobsListState;
        });

        // Fetch jobs list
        builder.addCase(fetchDashboardActiveJobsList.pending, (state) => {
            state.getJobsListStatus = 'pending'
        });
        builder.addCase(fetchDashboardActiveJobsList.fulfilled, (state, action) => {
            state.getJobsListStatus = 'success';
            state.jobsList = action?.payload.data;
            state.paginationParams = action?.payload.stats;
            // Add total records counts in the response.
            state.paginationParams.count = action.payload.data?.length;
        });
        builder.addCase(fetchDashboardActiveJobsList.rejected, (state, action) => {
            state.getJobsListStatus = 'failed';
            state.getJobsListResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.jobsList = [];
            state.paginationParams = { count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0 }
        });

        // Fetch jobs counts
        builder.addCase(fetchDashboardJobsCounts.pending, (state) => {
            state.getJobsCountsStatus = 'pending'
        });
        builder.addCase(fetchDashboardJobsCounts.fulfilled, (state, action) => {
            state.getJobsCountsStatus = 'success';
            // Filter data and set counts of each job types
            if (action?.payload) {
                state.activeCount = action?.payload.active || 0;
                state.draftCount = action?.payload.draft || 0;
                state.expiredCount = action?.payload.expired || 0;
                state.templateCount = action?.payload.template || 0;
            }
        });
        builder.addCase(fetchDashboardJobsCounts.rejected, (state, action) => {
            state.getJobsCountsStatus = 'failed';
            state.getJobsCountsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });

    }
});

export const { toggleJobSummary, resetJobsState, resetFetchJobSummary } = dashboardActiveJobsSlice.actions;
export default dashboardActiveJobsSlice;
