import { createSlice } from "@reduxjs/toolkit";
import { IAppTheme } from "./app-model";

const initialTheme: IAppTheme = {
    curTheme: 'light',
    mode: 'light'
};

const themeSlice = createSlice({
    name: "theme",
    initialState: initialTheme,
    reducers: {
        setThemeMode: (state, action) => {
            // Set curTheme based on the mode.
            state.curTheme = action.payload.curTheme;
            if (action.payload?.mode) {
                state.mode = action.payload.mode;
            }
        }
    }
});

export const { setThemeMode } = themeSlice.actions;
export default themeSlice;
