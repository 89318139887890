import { Button, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LogoutRedColor, TextPrimary } from "shared/SharedStyles/styleConstants";

export const NavLinks = styled(Stack)(
    ({ theme }) => ({
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiLink-root': {
            fontWeight: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            textTransform: 'none',
            padding: '8px 16px',
            color: TextPrimary,
            '& .MuiSvgIcon-root': {
                width: '20px',
                height: '22px',
                marginRight: '5px',
                color: TextPrimary
            },
            '& svg': {
                width: '20px',
                height: '22px',
                marginRight: '5px',
                color: TextPrimary
            }
        },
        '& .MuiToggleButtonGroup-grouped:not(:first-of-type), .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
            border: 'none',
        },
        '& .Mui-selected': {
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.primary.main} !important`,
            },
            backgroundColor: 'white !important',
            color: `${theme.palette.primary.main} !important`,
            '&::after': {
                content: '""',
                height: '100%',
                width: '7px',
                backgroundColor: theme.palette.primary.main,
                position: 'absolute',
                right: '0',
                top: '0'
            }
        }
    })
);

export const MobileOnlyStack = styled(Stack)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    })
);

export const LeftNavButtonStyled = styled(Button)(
    ({ theme }) => ({
        fontWeight: 400,
        justifyContent: 'flex-start',
        textTransform: 'none',
        padding: '8px 0px 8px 20px',
        color: TextPrimary,
        '& .MuiSvgIcon-root': {
            width: '20px',
            height: '22px',
            marginRight: '5px',
            color: '#c2cfe0'
        },
        '& .MuiSvgIcon-root.logout-icon': {
            color: LogoutRedColor
        }
    })
);