import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance, { feedServer } from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { ApiState, IApprovedJobResponse, IBaseResponse, IJobInfoResponse, JobReviewsStates } from "shared/SharedModels";

interface InitialJobsState {
  pendingJobs: Array<IJobInfoResponse>;
  approvedJobs: Array<IJobInfoResponse>;
  rejectedJobs: Array<IJobInfoResponse>;
  removedJobs: Array<IJobInfoResponse>;
  employerJob: IJobInfoResponse | object;
  isFetchingJobs: ApiState;
  approveJobApiStatus: ApiState;
  feedGenerationApiStatus: ApiState;
}

const initialJobsState: InitialJobsState = {
  isFetchingJobs: "idle",
  pendingJobs: [],
  approvedJobs: [],
  rejectedJobs: [],
  removedJobs: [],
  employerJob: {},
  approveJobApiStatus: "idle",
  feedGenerationApiStatus: "idle",
};

export const getAdminJobs = createAsyncThunk<IBaseResponse<IJobInfoResponse[]>, { state: string, pageNo: number, pageSize: number }>(
  "getAdminJobs",
  async ({ state = "pending", pageNo = 1, pageSize = 20 }, { rejectWithValue }) => {
    const response = await httpAdapterInstance
      .get(`${AdminApiEndpoints.ADMIN_JOB_REVIEWS}?state=${state}&pageNo=${pageNo}&pageSize=${pageSize}`)
      .then((response) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

export const getEmployerJob = createAsyncThunk<IBaseResponse<IJobInfoResponse>, { jobId: string }>(
  "getEmployerJob",
  async ({ jobId }, { rejectWithValue }) => {
    const response = await httpAdapterInstance
      .get(`${AdminApiEndpoints.ADMIN_EMPLOYER_JOBS}/${jobId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

export const approveJob = createAsyncThunk<IBaseResponse<IApprovedJobResponse>, { jobId: number }>(
  "approveJob",
  async ({ jobId }, { rejectWithValue }) => {
    const response = await httpAdapterInstance
      .put(`${AdminApiEndpoints.ADMIN_JOB_REVIEWS}/${jobId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

export const generateFeeds = createAsyncThunk<void>(
  "generateFeeds",
  async (_, { rejectWithValue }) => {
    const response = await feedServer.get(AdminApiEndpoints.ADMIN_GENERATE_FEEDS)
      .then((response) => {
        return response.data;
      }).catch((error) => {
        throw rejectWithValue(error.response.data);
      });
    return response;
  }
);

const jobReviewsSlice = createSlice({
  name: "jobReviewsSlice",
  initialState: initialJobsState,
  reducers: {
    resetAdminJobs: (state) => {
      state.isFetchingJobs = "idle";
      state.pendingJobs = [];
      state.approvedJobs = [];
      state.rejectedJobs = [];
      state.removedJobs = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminJobs.pending, (state, action) => {
      state.isFetchingJobs = "pending";
    });
    builder.addCase(getAdminJobs.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { state: jobStatus } = action.meta.arg;

      switch (jobStatus) {
        case JobReviewsStates.approved:
          state.approvedJobs = data;
          break;
        case JobReviewsStates.pending:
          state.pendingJobs = data;
          break;
        case JobReviewsStates.rejected:
          state.rejectedJobs = data;
          break;
        case JobReviewsStates.removed:
          state.removedJobs = data;
          break;
      }
      state.isFetchingJobs = "success";
    });
    builder.addCase(getAdminJobs.rejected, (state, action) => {
      state.pendingJobs = [];
      state.isFetchingJobs = "failed";
    });
    builder.addCase(getEmployerJob.pending, (state, action) => {
      state.isFetchingJobs = "pending";
    });
    builder.addCase(getEmployerJob.fulfilled, (state, action) => {
      state.employerJob = action.payload;
      state.isFetchingJobs = "success";
    });
    builder.addCase(getEmployerJob.rejected, (state, action) => {
      state.employerJob = {};
      state.isFetchingJobs = "failed";
    });
    builder.addCase(approveJob.pending, (state, action) => {
      state.approveJobApiStatus = "pending";
    });
    builder.addCase(approveJob.fulfilled, (state, action) => {
      state.approveJobApiStatus = "success";
    });
    builder.addCase(approveJob.rejected, (state, action) => {
      state.approveJobApiStatus = "failed";
    });
    builder.addCase(generateFeeds.pending, (state, action) => {
      state.approveJobApiStatus = "pending";
    });
    builder.addCase(generateFeeds.fulfilled, (state, action) => {
      state.approveJobApiStatus = "success";
    });
    builder.addCase(generateFeeds.rejected, (state, action) => {
      state.approveJobApiStatus = "failed";
    });
  },
});

export const { resetAdminJobs } = jobReviewsSlice.actions;
export default jobReviewsSlice;
