import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { ScoreCategoryInitial } from "Modules/Core/Admin/AssessmentsAndReports/AssessmentsAndReportsConstants";
import { PURGE } from "redux-persist";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";
import { IBaseResponse } from "shared/SharedModels";
import { IScoreCategoriesState, IScoreCategory } from "./assessments-and-reports-model";

const initialScoreCategoriesState: IScoreCategoriesState = {
    scoreCategories: [],
    getScoreCategoriesStatus: 'idle',
    createScoreCategoryStatus: 'idle',
    updateScoreCategoryStatus: 'idle',
    createScoreCategoryResponse: '',
    updateScoreCategoryResponse: ''
};

export const getScoreCategories = createAsyncThunk<IBaseResponse<IScoreCategory[]>, void>(
    "getScoreCategories",
    async (_, { rejectWithValue }) => {
        const response = await httpAdapterInstance
            .get(`${AdminApiEndpoints.SCORE_CATEGORIES}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
        return response;
    }
);

export const getScoreCategory = createAsyncThunk<IScoreCategory, { id: number }>(
    "getScoreCategory",
    async ({ id }, { rejectWithValue }) => {
        const response = await httpAdapterInstance
            .get(`${AdminApiEndpoints.SCORE_CATEGORIES}${id}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
        return response;
    }
);

export const createScoreCategory = createAsyncThunk<{ message: string }, { payload: IScoreCategory }, { rejectValue: { message: string } }>(
    "createScoreCategory",
    async ({ payload }, { rejectWithValue }) => {
        const response = await httpAdapterInstance
            .post(`${AdminApiEndpoints.SCORE_CATEGORIES}`, payload)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
        return response;
    }
);

export const updateScoreCategory = createAsyncThunk<{ message: string }, { id: number, payload: IScoreCategory }, { rejectValue: { message: string } }>(
    "updateScoreCategory",
    async ({ id, payload }, { rejectWithValue }) => {
        const response = await httpAdapterInstance
            .put(`${AdminApiEndpoints.SCORE_CATEGORIES}${id}`, payload)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
        return response;
    }
);

const scoreCategories = createSlice({
    name: "scoreCategories",
    initialState: initialScoreCategoriesState,
    reducers: {
        resetGetScoreCategories: (state) => {
            state.getScoreCategoriesStatus = 'idle';
            state.getScoreCategoriesResponse = ''
        },
        resetGetScoreCategory: (state) => {
            state.getScoreCategoryStatus = 'idle';
            state.getScoreCategoryResponse = '';
            state.currentScoreCategory = { ...ScoreCategoryInitial };
        },
        resetCreteScoreCategory: (state) => {
            state.createScoreCategoryStatus = 'idle';
            state.createScoreCategoryResponse = ''
        },
        resetUpdateScoreCategory: (state) => {
            state.updateScoreCategoryStatus = 'idle';
            state.updateScoreCategoryResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialScoreCategoriesState;
        });
        // get list
        builder.addCase(getScoreCategories.pending, (state) => {
            state.getScoreCategoriesStatus = "pending";
        });
        builder.addCase(getScoreCategories.fulfilled, (state, action) => {
            state.scoreCategories = action.payload.data;
            state.getScoreCategoriesStatus = "success";
        });
        builder.addCase(getScoreCategories.rejected, (state) => {
            state.scoreCategories = [];
            state.getScoreCategoriesStatus = "failed";
        });
        // get one
        builder.addCase(getScoreCategory.pending, (state) => {
            state.getScoreCategoryStatus = "pending";
        });
        builder.addCase(getScoreCategory.fulfilled, (state, action) => {
            state.currentScoreCategory = action.payload;
            state.getScoreCategoryStatus = "success";
        });
        builder.addCase(getScoreCategory.rejected, (state) => {
            state.getScoreCategoryStatus = "failed";
        });
        // create one
        builder.addCase(createScoreCategory.pending, (state) => {
            state.createScoreCategoryStatus = "pending";
        });
        builder.addCase(createScoreCategory.fulfilled, (state, action) => {
            state.createScoreCategoryResponse = action.payload.message ?? DefaultAPISuccessMsg;
            state.createScoreCategoryStatus = "success";
        });
        builder.addCase(createScoreCategory.rejected, (state, action) => {
            state.createScoreCategoryStatus = "failed";
            state.createScoreCategoryResponse = action.payload?.message ?? DefaultAPIErrorMsg;

        });
        // update one
        builder.addCase(updateScoreCategory.pending, (state) => {
            state.updateScoreCategoryStatus = "pending";
        });
        builder.addCase(updateScoreCategory.fulfilled, (state, action) => {
            state.updateScoreCategoryResponse = action.payload.message ?? DefaultAPISuccessMsg;
            state.updateScoreCategoryStatus = "success";
        });
        builder.addCase(updateScoreCategory.rejected, (state, action) => {
            state.updateScoreCategoryStatus = "failed";
            state.createScoreCategoryResponse = action.payload?.message ?? DefaultAPIErrorMsg;
        });
    },
});

export const { resetGetScoreCategories, resetGetScoreCategory, resetCreteScoreCategory, resetUpdateScoreCategory } = scoreCategories.actions;
export default scoreCategories;
