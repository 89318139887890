import { Box, Grid, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BorderColorDark, BorderColorLight, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const FilterPopover = styled(Popover)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            '& .desktop-only': {
                display: 'none'
            },
            '& .mobile-only': {
                display: 'block'
            }
        },
        [theme.breakpoints.up('sm')]: {
            '& .desktop-only': {
                display: 'block'
            },
            '& .mobile-only': {
                display: 'none'
            }
        },
        [theme.breakpoints.up('md')]: {
            '& .desktop-only': {
                display: 'block'
            }
        },
        [theme.breakpoints.up('lg')]: {
            '& .desktop-only': {
                display: 'block'
            }
        },
        [theme.breakpoints.up('xl')]: {
            '& .desktop-only': {
                display: 'block'
            }
        },
        '& .MuiPopover-paper': {
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            marginTop: theme.spacing(1.5),
            maxWidth: '500px',
            maxHeight: '75%',
            width: '90%',
            '& .filters-wrapper': {
                padding: theme.spacing(3),
                '& label': {
                    marginLeft: 0,
                    marginRight: 0,
                    justifyContent: 'space-between',
                    whiteSpace: 'nowrap',
                    '& .icon-label': {
                        display: 'flex',
                        alignItems: 'center',
                        margin: `${theme.spacing(0.5)} 0`,
                        '& svg': {
                            marginRight: theme.spacing(1)
                        }
                    },
                    '& .MuiCheckbox-root': {
                        padding: '2px'
                    }
                },
                '& hr': {
                    height: '95%',
                    width: '100%',
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                    borderColor: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark
                },
                '& .MuiGrid-item': {
                    '&:hover,&:focus': {
                        '& h6': {
                            textDecoration: 'underline'
                        }
                    }
                }
            },
            '& .actions': {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: theme.spacing(1),
                padding: theme.spacing(1),
                borderTop: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
                position: 'sticky',
                bottom: 0,
                background: 'inherit'
            }
        }
    })
);

export const FingerprintImgContainer = styled(Grid)(({ theme }) => ({
    textAlign: 'center',
    '& img': {
        width: '100%',
        maxWidth: '500px',
        backgroundColor: WhiteColor
    }
}));

export const FingerprintImageContainer = styled(Box)(
    ({ theme }) => ({
        '& svg': {
            // height: '100%',
            // width: '100%'
        }
    })
);