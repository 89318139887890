import { Box, Paper, Stack, Typography } from "@mui/material";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { PaperVariant } from "shared/SharedStyles/styleConstants";
import { EmployerLoginRedirect } from "../AuthV2/AuthConstants";

export const Unauthorized = () => {

    return (<>
        <Stack height='100vh' justifyContent='center' alignItems='center' padding={2}>
            <Paper variant={PaperVariant}>
                <Stack alignItems='center' justifyContent='center' rowGap={6} padding={5}>
                    <Box maxWidth={250}>
                        <ShLogo />
                    </Box>
                    <Box textAlign='center'>
                        <Typography variant="h3">Unauthorized!</Typography>
                        <Typography variant="subtitle1">Access denied for this page</Typography>
                    </Box>
                    <ShButton variant="contained" color="primary" component={"a"} href={EmployerLoginRedirect}>Home</ShButton>
                </Stack>
            </Paper>
        </Stack>
    </>);
}