import { Rating, Typography } from "@mui/material";
import { IApplicantRatingComponent } from "Modules/Core/Applicants/ApplicantsModel";
import { useNotification } from "Modules/Core/Notification";
import { useAppDispatch } from "helpers/hooks";
import { useEffect } from "react";
import { resetUpdateApplicantRatingInProfile, updateApplicantRatingInProfile } from "store/slices/employer/applicants/applicant-profile-slice";
import { resetUpdateApplicantRatingInList, updateApplicantRatingInList } from "store/slices/employer/applicants/applicants-list-slice";

export const ApplicantRating = ({ applicant, className, usedIn }: IApplicantRatingComponent) => {
    const notification = useNotification();
    const dispatch = useAppDispatch();

    // Show snackbar notifications when the rating change is either successful or failed.
    useEffect(() => {
        if (applicant?.updateRatingStatus === 'failed' || applicant?.updateRatingStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: applicant?.updateRatingStatus === 'failed' ? 'error' : 'success',
                message: applicant?.updateRatingResponse ?? ''
            });
            dispatch(resetUpdateApplicantRatingInList(applicant?.candidateEmployerJobId));
            dispatch(resetUpdateApplicantRatingInProfile());
        }
    }, [applicant?.candidateEmployerJobId, applicant?.updateRatingResponse, applicant?.updateRatingStatus, dispatch, notification]);

    return (
        <>
            {applicant?.updateRatingStatus === 'pending' ? <Typography variant="caption">Saving...</Typography> :
                <Rating key={applicant?.candidateEmployerJobId} id={applicant?.candidateEmployerJobId + ''} className={className} size="small"
                    name={applicant?.candidateEmployerJobId + ''} value={applicant?.rating ?? 0}
                    onChange={(event, newValue) => {
                        // Both api calls hit the same api but replicated in different slices to handle list and profile scenarios.
                        if (usedIn === 'list') {
                            dispatch(updateApplicantRatingInList({
                                applicantId: applicant?.candidateEmployerJobId,
                                payload: { rating: newValue ?? 0 }
                            }));
                        } else {
                            dispatch(updateApplicantRatingInProfile({
                                applicantId: applicant?.candidateEmployerJobId,
                                payload: { rating: newValue ?? 0 }
                            }));
                        }
                        event.stopPropagation();
                    }} onClick={e => e.stopPropagation()} />}
        </>
    );
};