import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { FacebookMcIcon, GoogleMcIcon, LinkedInMcIcon, OutlookMcIcon } from "assets/Icons";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { MUIGrey } from "shared/SharedStyles/styleConstants";
import { TSocialLoginProvider } from "store/slices/auth-v2/auth-v2-model";
import { SocialProviders } from "./AuthConstants";
import { ISocialActionItems } from "./AuthModel";

export const SocialButtons = ({ handleGetRedirect, isSignUp, buttonType, loginProvider }: ISocialActionItems) => {

    const getSocialIcon = (provider: TSocialLoginProvider): React.JSX.Element => {
        switch (provider) {
            case 'google':
                return <GoogleMcIcon />;
            case 'facebook':
                return <FacebookMcIcon />;
            case 'linkedin':
                return <LinkedInMcIcon />;
            case 'outlook':
                return <OutlookMcIcon />;
        }
    }

    const isSocialButtonNotHidden = (provider: TSocialLoginProvider): boolean => {
        switch (provider) {
            case 'google':
                return loginProvider !== 'facebook' && loginProvider !== 'linkedin' && loginProvider !== 'outlook';
            case 'facebook':
                return loginProvider !== 'google' && loginProvider !== 'linkedin' && loginProvider !== 'outlook';
            case 'linkedin':
                return loginProvider !== 'google' && loginProvider !== 'facebook' && loginProvider !== 'outlook';
            case 'outlook':
                return loginProvider !== 'google' && loginProvider !== 'linkedin' && loginProvider !== 'facebook';
        }
    }

    return (<>
        {buttonType === 'button' ? <>
            <Stack gap={2}>
                {SocialProviders.map(sp => (
                    isSocialButtonNotHidden(sp.socialProviderKey) &&
                    <ShButton key={sp.socialProviderKey} fullWidth variant="outlined"
                        aria-label={`Sign ${isSignUp ? 'up' : 'in'} with ${sp.displayName}`}
                        onClick={() => handleGetRedirect(sp.socialProviderKey)}>
                        <Stack direction="row" p={1} gap={1} justifyContent="start" width="100%">
                            {getSocialIcon(sp.socialProviderKey)}
                            <Typography color={MUIGrey} variant='body1'>
                                {`Sign ${isSignUp ? 'up' : 'in'} with ${sp.displayName}`}
                            </Typography>
                        </Stack>
                    </ShButton>
                ))}
            </Stack></> : <>
            <Stack direction='row' columnGap={2}>
                {SocialProviders.map(sp => (
                    isSocialButtonNotHidden(sp.socialProviderKey) &&
                    <Tooltip key={sp.socialProviderKey} title={`Sign ${isSignUp ? 'up' : 'in'} with ${sp.displayName}`}>
                        <IconButton aria-label={`Sign ${isSignUp ? 'up' : 'in'} with ${sp.displayName}`}
                            onClick={() => handleGetRedirect(sp.socialProviderKey)}>
                            {getSocialIcon(sp.socialProviderKey)}
                        </IconButton>
                    </Tooltip>
                ))}
            </Stack>
        </>}
    </>)
}