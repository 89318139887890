import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { InterviewDialogStyled } from 'Modules/Core/Interviews/Interviews.styled';
import { IUpdateInterviewStateDialog } from 'Modules/Core/Interviews/InterviewsModel';
import { useNotification } from 'Modules/Core/Notification';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import {
  resetUpdateInterviewStatus,
  updateInterviewCancelStatus,
} from 'store/slices/employer/interviews/interviews-actions-slice';

const UpdateInterviewCancelStatusDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  status,
  interviewId,
  candidateName,
}: IUpdateInterviewStateDialog) => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { updateInterviewStateStatus, updateInterviewStateResponse } =
    useAppSelector((state) => state.employer.interviews.interviewsActions);
  const [updateComment, setUpdateComment] = useState<string>('');

  const updateStatus = () => {
    dispatch(
      updateInterviewCancelStatus({
        id: interviewId,
        body: { status: status, comments: updateComment },
      })
    );
  };

  // Show snackbar notifications when interview schedule is successfully created.
  useEffect(() => {
    if (updateInterviewStateStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: updateInterviewStateResponse,
      });
      dispatch(resetUpdateInterviewStatus());
    }
  }, [
    dispatch,
    notification,
    updateInterviewStateResponse,
    updateInterviewStateStatus,
  ]);

  const AlertForUpdateInterviewSchedule = () => {
    switch (updateInterviewStateStatus) {
      case 'pending':
        return (
          <ShAlert marginBottom={2} severity="info">
            Updating Interview status...
          </ShAlert>
        );
      case 'failed':
        return (
          <ShAlert marginBottom={2} severity="error">
            {updateInterviewStateResponse}
          </ShAlert>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <InterviewDialogStyled
        open={isDialogOpen}
        onClick={(e) => e.stopPropagation()}
        aria-labelledby="title"
      >
        <DialogTitle id="title" textAlign="center" fontWeight={600}>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={1}
            justifyContent="space-between"
          >
            <Box>
              {`Cancel ${ candidateName + `'s` } ${'interview'}`}
            </Box>
            <IconButton
              onClick={() => {
                setIsDialogOpen(false);
                setUpdateComment('');
              }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          {AlertForUpdateInterviewSchedule()}
          <TextField
            id="comment"
            label="Comment (Optional)"
            variant="outlined"
            multiline
            rows={3}
            maxRows={10}
            fullWidth
            value={updateComment}
            onChange={(e) => {
              setUpdateComment(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <ShGreenBtn
            variant="contained"
            disableElevation
            size="small"
            onClick={updateStatus}
            className="self-right"
          >
            Cancel Interview
          </ShGreenBtn>
        </DialogActions>
      </InterviewDialogStyled>
    </>
  );
};

export default UpdateInterviewCancelStatusDialog;
