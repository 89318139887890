import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Box, Divider, Grid, List, ListItemText, Stack, Typography } from '@mui/material';
import { ResourceTypography, TemplateHomesStack } from 'Modules/Marketing/Resources/Resources.styled';
import { JobDescription, JobDescriptions, requireDescriptionTemplateContext } from 'Modules/Marketing/Resources/Templates/TemplateModel';
import { IsXsScreen, useAppDispatch } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { setCurrentBreadCrumb } from 'store/slices/app/breadcrumb-slice';

const jobDescriptions: JobDescriptions = requireDescriptionTemplateContext.keys().reduce((acc: JobDescriptions, key: string) => {
    const moduleExports = requireDescriptionTemplateContext(key);
    Object.keys(moduleExports).forEach((exportKey) => {
        const moduleName = exportKey.toLowerCase();
        acc[moduleName] = moduleExports[exportKey];
    });
    return acc;
}, {});

export const EmployerJobDescriptionPage = () => {

    const isXsScreen = IsXsScreen();
    const { templateName } = useParams<{ templateName: string | undefined }>();
    const [similarTemplates, setSimilarTemplates] = useState<string[]>([]);
    const [jobDescription, setJobDescription] = useState<JobDescription | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const setBreadcrumbInJobDescriptionTemplate = useCallback((templateName: string) => {
        const crumbName = templateName.split('/').pop()?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '';
        const bc = {
            pathname: location.pathname,
            breadcrumbs: [
                { displayName: 'Home', href: '/employer/dashboard', isActive: true },
                { displayName: 'Resources', href: '/employer/resources', isActive: true },
                { displayName: 'Job Description Templates', href: '/employer/resources/job-description-templates', isActive: true },
                { displayName: crumbName, href: '', isActive: false }
            ]
        };
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [dispatch, location]);

    useEffect(() => {
        if (templateName) {
            const formattedTemplateName = templateName.replace(/-/g, '').replace(/[()]/g, '').toLowerCase();
            const matchedJobDescription = jobDescriptions[formattedTemplateName];
            setJobDescription(matchedJobDescription ?? null);
            if (!matchedJobDescription) {
                // Navigate to the error page if the job description is empty
                navigate('/error');
                return;
            }
            setBreadcrumbInJobDescriptionTemplate(templateName);
        }
    }, [navigate, templateName, setBreadcrumbInJobDescriptionTemplate]);

    const { title = "", jobRole = "", preRead = "", sections = [] } = jobDescription || {};

    const findSimilarTemplates = useCallback((templateName: string): string[] => {
        try {
            const templateWords = templateName.toLowerCase().split('-');
            Object.keys(jobDescriptions).forEach((key) => {
                const template = jobDescriptions[key];
                const templateWordsToMatch = template.title.toLowerCase().split(" ");
                const matches = templateWords.filter(word => templateWordsToMatch.includes(word)).length;
                if (matches >= 3) {
                    similarTemplates.push(template.title);
                }
            });

            return similarTemplates;
        } catch (error) {
            console.error('Error finding similar templates:', error);
            return ["Nothing similar currently, Check back later!"];
        }
    }, [similarTemplates]);

    useEffect(() => {
        if (templateName) {
            const createSimilarTemplates = async () => {
                const templates = await findSimilarTemplates(templateName);
                setSimilarTemplates(templates)
            };
            createSimilarTemplates();
        }
    }, [templateName, similarTemplates, findSimilarTemplates]);

    return (
        <Stack padding={3}>
            <TemplateHomesStack justifyContent="center" alignItems="center" backgroundColor="#F0F8FF">
                <ResourceTypography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryThemeColor}>
                    HR Templates | Job descriptions
                </ResourceTypography>
                <Typography textAlign="center" padding={2} maxWidth={500} variant="h4" gutterBottom fontWeight={800}>
                    {title}
                </Typography>
                <ResourceTypography textAlign="center" maxWidth={1000} variant="body1" paragraph>
                    {jobRole}
                </ResourceTypography>

                <Stack margin={1} spacing={2} direction={isXsScreen ? 'column' : 'row'}
                    alignContent="center" justifyContent="center">
                    <ShButton component={RouterLink} to="/employer/resources/ai-interview-kit"
                        size="large" color="primary"
                        variant="contained" endIcon={<NearMeIcon />}>
                        <ResourceTypography>Create Interview Kit</ResourceTypography>
                    </ShButton>
                    <ShButton component={RouterLink} to="/employer/resources/ai-job-description-generator"
                        size="large"
                        color="info" variant="outlined" endIcon={<AutoAwesomeIcon />}>
                        <ResourceTypography>Create AI Job Description</ResourceTypography>
                    </ShButton>
                </Stack>
            </TemplateHomesStack>
            <ShContainer maxWidth="lg" height="100%" margin="auto">
                <Stack padding={3}>
                    <ResourceTypography gutterBottom variant="body1" paragraph>
                        {preRead}
                    </ResourceTypography>
                    {sections.map((section, index) => (
                        <Box key={index} marginBottom={3}>
                            <ResourceTypography fontWeight="bold" variant="h5" gutterBottom>
                                {section.heading}
                            </ResourceTypography>
                            {Array.isArray(section.paragraphs) ? (
                                <List>
                                    {section.paragraphs.map((bullet, bulletIndex) => (
                                        <ListItemText key={bulletIndex}>
                                            <ResourceTypography noWrap variant="body1">
                                                - {bullet}
                                            </ResourceTypography>
                                        </ListItemText>
                                    ))}
                                </List>
                            ) : (
                                <ResourceTypography variant="body1" paragraph>
                                    {section.paragraph}
                                </ResourceTypography>
                            )}
                        </Box>
                    ))}
                </Stack>
                <ResourceTypography variant="h6" fontWeight="bold" paddingBottom={2}> Similar Job Description Templates</ResourceTypography>
                <Divider />
                <Grid container spacing={1} paddingTop={2} >
                    {similarTemplates.slice(0, 20).map((template, index) => (
                        <Grid item sm={12} md={6} key={index}>
                            <ShMuiLink noWrap component={RouterLink} to={`/employer/resources/job-description-templates/${template.split(" ").join("-").toLocaleLowerCase()}`} variant="subtitle2" paddingTop={1} fontWeight='bold' noUnderline>{template}</ShMuiLink>
                        </Grid>
                    ))}
                </Grid>
            </ShContainer>
        </Stack>
    );
};

export default EmployerJobDescriptionPage;