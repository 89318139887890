import ClearIcon from '@mui/icons-material/Clear';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { IChangePassPayload } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { PasswordValidator } from 'shared/SharedComponents/PasswordValidator';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { changeUserPassword, resetChangePassword } from 'store/slices/employer/settingsTs/security-slice';

export const ChangePassword = () => {
    const dispatch = useAppDispatch();
    const [chngPass, setChngPass] = useState<IChangePassPayload>({ oldPassword: '', newPassword: '', confirmPassword: '' });
    const { changeUserPassApiStatus, changeUserPassApiResponse } = useAppSelector((state) => state.employer.employerSettings.security);
    const [showCurPass, setShowCurPass] = useState<boolean>(false);
    const [showNewPass, setShowNewPass] = useState<boolean>(false);
    const [showConfPass, setShowConfPass] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

    const AlertPasswordChng = () => {
        switch (changeUserPassApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Changing password</ShAlert>);
            case 'success':
                return (<ShAlert severity='success' onClose={() => dispatch(resetChangePassword())}>
                    <Typography fontWeight={600}>{changeUserPassApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity='error'>{changeUserPassApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    useEffect(() => {
        setIsSaveDisabled(changeUserPassApiStatus === 'pending' || chngPass.oldPassword?.trim().length === 0 ||
            !isPasswordValid)
    }, [changeUserPassApiStatus, chngPass.oldPassword, isPasswordValid]);

    // Reset on component unmount.
    useEffect(() => { return () => { dispatch(resetChangePassword()) } }, [dispatch]);

    return (
        <>
            <Stack rowGap={2} paddingY={{ xs: 2, sm: 3, md: 4, lg: 4 }} maxWidth='400px'>
                {/* Display alerts while updating password */}
                {AlertPasswordChng()}
                <TextField id='current_password' label='Enter Current Password' size='small' fullWidth variant='outlined' type={showCurPass ? 'text' : 'password'}
                    value={chngPass.oldPassword} onChange={e => setChngPass({ ...chngPass, oldPassword: e.target.value })}
                    InputProps={{
                        endAdornment: (<InputAdornment position='end'> <IconButton
                            edge='end' onClick={() => setShowCurPass(!showCurPass)}>
                            {showCurPass ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                        </InputAdornment>)
                    }} />
                <Stack width='100%'>
                    <TextField margin='dense' id='new_password' label='Enter New Password' size='small' fullWidth variant='outlined' type={showNewPass ? 'text' : 'password'}
                        value={chngPass.newPassword} onChange={e => setChngPass({ ...chngPass, newPassword: e.target.value })}
                        InputProps={{
                            endAdornment: (<InputAdornment position='end'>
                                <IconButton
                                    edge='end' onClick={() => setShowNewPass(!showNewPass)}>
                                    {showNewPass ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                            </InputAdornment>)
                        }} />
                    {chngPass.newPassword?.trim().length > 0 && <>
                        <PasswordValidator password={chngPass.newPassword} confirmPassword={chngPass.confirmPassword}
                            onValidityChange={e => setIsPasswordValid(e)} />
                    </>}
                </Stack>
                <Stack width='100%'>
                    <TextField margin='dense' id='confirm_password' label='Confirm New Password' size='small' fullWidth variant='outlined' type={showConfPass ? 'text' : 'password'}
                        value={chngPass.confirmPassword} onChange={e => setChngPass({ ...chngPass, confirmPassword: e.target.value })}
                        InputProps={{
                            endAdornment: (<InputAdornment position='end'>
                                <IconButton
                                    edge='end' onClick={() => setShowConfPass(!showConfPass)}>
                                    {showConfPass ? <VisibilityOffIcon /> : <VisibilityIcon />}</IconButton>
                            </InputAdornment>)
                        }} />
                    {chngPass.confirmPassword?.trim().length > 0 && chngPass.newPassword !== chngPass.confirmPassword &&
                        <Typography variant='caption' display='flex' alignItems='center'>
                            {<ClearIcon color='error' fontSize='small' />}&nbsp;Passwords don't match!</Typography>}
                </Stack>
                <ShGreenBtn disableElevation variant='contained' disabled={isSaveDisabled}
                    onClick={() => dispatch(changeUserPassword(chngPass))}>Change Password</ShGreenBtn>
            </Stack>
        </>
    );
}