export const offerTemplates = [
    { 
        title: "General Job Offer Template",
        description: "Versatile structure for various job positions, including job title, salary, benefits, and employment terms.",
        path: "/general-job-offer-template"
    },
    { 
        title: "Formal Job Offer Template",
        description: "Corporate template with formal language, detailed job responsibilities, compensation, benefits, and policies.",
        path: "/formal-job-offer-template"
    },
    { 
        title: "Informal Job Offer Template",
        description: "Friendly template for startups, covering job role, salary, and benefits in a relaxed style.",
        path: "/informal-job-offer-template"
    },
    { 
        title: "Internal Job Offer Template",
        description: "Tailored for promoting current employees, focusing on transition, new responsibilities, and any changes in compensation.",
        path: "/internal-job-offer-template"
    },
    { 
        title: "Sales Job Offer Template",
        description: "Customized for sales roles, detailing performance incentives, commission structures, and sales targets.",
        path: "/sales-job-offer-template"
    },
];

export const jobOfferTemplateData = {
    companyName: "Sample Company Inc.",
    recruiterName: "John Doe",
    jobTitle: "Software Engineer",
    jobDescription: "We are seeking a talented and motivated Software Engineer to join our team.",
    salary: "70,000", // This will be dynamically filled by the user
    startDate: "05/15/2024", // This will be dynamically filled by the user
    benefits: [
        "Health insurance",
        "401(k) matching",
        "Flexible work hours",
        "Paid time off",
        "Life insurance",
        "Employee assistance programs",
        "Professional development programs",
        "Remote work options",
        "Performance bonuses",
        "Commuter benefits"
    ],
    vacationDays: "20 days", // Number of paid vacation days
    employmentType: "Full-time", // Employment type (e.g., Full-time, Part-time)
    employmentHours: "40 hours per week", // Weekly working hours
    department: "Engineering", // Department name
    employmentAgreement: "Confidentiality, Nondisclosure, Noncompete agreements", // Employment agreements
    atWillMessage: true, // Whether to include the at-will employment message
    bonusPrograms: "annual bonus program", // Mention bonus programs, if applicable
    compensationDetails: true, // Whether to attach a letter with more details about compensation plan
    contractDuration: "1 year", // Contract duration, if applicable
    responseDate: "two weeks from the offer date", // Response deadline date
    managerName: "Jane Smith", // Manager's name for contact
    contactDetails: "manager@samplecompany.com, +1-234-567-8901", // Contact details for queries
    candidateName: "Candidate Name", // Placeholder for candidate's name
    yourName: "Your Name", // Placeholder for your name
    signature: "Your Signature", // Placeholder for your signature
};
