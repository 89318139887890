import { Box, LinkProps, Link, ListItem, ListItemText, Stack, TextField, Typography, styled } from "@mui/material";
import { Link as href } from "react-router-dom";
import { ShBorderRadius } from "shared/SharedStyles/styleConstants";

interface TemplateHomesStackProps {
    backgroundColor?: string;
}
interface ShListItemProps {
    to: string;
    onClick: () => void;
    primary: string;
}

interface ResourceHomeStackProps {
    borderRadius?: string;
  }

export const ResourceElementsBorderRadius = 20;


export const ShListItem: React.FC<ShListItemProps> = ({ to, onClick, primary }) => (
    <ListItem
        component={href}
        to={to}
        onClick={onClick}
        sx={{
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
                textDecoration: 'none',
            },
        }}
    >
        <ListItemText primary={primary} />
    </ListItem>
);

export const ResourcesWrapperBox = styled(Box)(
    ({ theme }) => ({
        flex: 1,
        height: '100vh', overflow: 'auto',
    })
);

export const ResourceTitleStack = styled(Stack)(
    ({ theme }) => ({
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        position: 'relative',
        '& .back-button': {
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(0%, -50%)'
        }
    })
);

export const ResourceHomeStack = styled(Stack, {
    shouldForwardProp: (prop) => prop !== 'borderRadius',
  })<ResourceHomeStackProps>(
    ({ theme, borderRadius }) => ({
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      padding: theme.spacing(5),
      borderRadius: borderRadius || ResourceElementsBorderRadius,
    })
  );


export const TemplateHomesStack = styled(Stack)<TemplateHomesStackProps>(({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor || '#E5F0FA', 
    borderRadius: ResourceElementsBorderRadius, 
    padding: theme.spacing(5),
    width: '100%',
}));

export const ResourceTypography = styled(Typography)({
    lineHeight: 1.75,
    textDecoration: 'none'
});


export const ResourceHomeTextField = styled(TextField)(
    ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            // Styles for small screens (xs and sm)
            minWidth: '200px',
        },
        [theme.breakpoints.up('md')]: {
            // Styles for medium screens and above (md and lg)
            maxWidth: '500px',
        },
        [theme.breakpoints.up('lg')]: {
            // Styles for large screens (lg)
            minWidth: '700px',
        },
    })
);

interface IShMuiLink extends LinkProps {
    noUnderline?: boolean
}
export const ResourceMuiLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== "noUnderline"
  })<IShMuiLink>(({ theme, noUnderline }) => ({
    height: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }));

  export const ResourceWrapperStyle = styled(Stack)<TemplateHomesStackProps>(({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor || '#E5F0FA',
    padding: theme.spacing(1.75),
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
  }));
  
export const OfferTemplateHomeStack = styled(Stack)<TemplateHomesStackProps>(({ theme, backgroundColor }) => ({
    backgroundColor: backgroundColor || '#E5F0FA', 
    borderRadius: ResourceElementsBorderRadius, 
    padding: theme.spacing(3),
    width: '100%',
    cursor:'pointer',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    },
}));

export const ResourceCTAStack = styled(Stack)<TemplateHomesStackProps>(({ theme, backgroundColor }) => ({
    padding: theme.spacing(3),
    borderRadius: ShBorderRadius,
    cursor: 'pointer',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease-in-out',
}));