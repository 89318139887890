import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlaceIcon from "@mui/icons-material/Place";
import { Box, Chip, DialogContentText, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import parse from "html-react-parser";
import jobStatesByCountryFallBack from "Modules/Core/CreateJob/FallbackStatesUsAndCanada";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from "react-share";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { toTitleCase } from "shared/utils";
import { getJobDetails } from "store/slices/employer/jobs/job-info-slice";
import { IsSmScreen, useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { HtmlRegex } from "../../../shared/constants";
import { DefaultState } from "../../Core/CreateJob/CreateJobConstants";
import { JobDetailsBox } from "./CareerPages.styled";

export const JobDetailsPage = () => {

    const navigate = useNavigate();
    const { jobId } = useParams();
    const dispatch = useAppDispatch();
    const { getJobDetailsStatus, jobDetailsInInfo } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const isSmScreen = IsSmScreen();
    const location = useLocation();
    const currentUrl = window.location.origin + location.pathname;


    useEffect(() => {
        if (jobId !== undefined && !isNaN(parseInt(jobId))) {
            dispatch(getJobDetails({ jobId: parseInt(jobId) }));
        }
    }, [dispatch, jobId]);

    return (
        <Box overflow='auto' height='100%' padding={!isSmScreen ? 6 : 1}>
            <Stack direction='row' alignItems='flex-start' marginY={1} columnGap={1} rowGap={1} flexWrap='wrap'>

                <Stack rowGap={1} flex={1} minWidth={300}>
                    {getJobDetailsStatus === 'pending' ? <Skeleton variant='rounded' animation='wave' width='100%' height={300} /> :
                        <ShPaper variant="outlined">
                            {isSmScreen &&
                                <IconButton component={RouterLink} to="/careers">
                                    <ArrowBackIcon />
                                </IconButton>
                            }
                            <JobDetailsBox>
                                <Stack direction='row' paddingTop={1} spacing={2} justifyContent='space-between'>
                                    <Typography variant="h6">
                                        {jobDetailsInInfo?.name}
                                    </Typography>
                                    <ShButton variant="text" color="info" size="large" endIcon={<OpenInNewIcon />}
                                        href={`https://app.smoothhiring.com/candidate/apply-job/${jobDetailsInInfo?.code}`}>
                                        Apply
                                    </ShButton>
                                </Stack>
                                <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                    <PlaceIcon fontSize='small' />
                                    <Typography variant="body2" color="text.secondary">
                                        {`${jobDetailsInInfo?.city}, ${jobStatesByCountryFallBack.find(s => s.id === jobDetailsInInfo?.province_id)?.name_en ??
                                            DefaultState}`}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        &bull;
                                    </Typography>
                                    {jobDetailsInInfo?.remote_job_type &&
                                        <>
                                            <ShChip color='primary' size='small' label={jobDetailsInInfo?.remote_job_type} />
                                            <Typography variant="body2" color="text.secondary">
                                                &bull;
                                            </Typography>
                                        </>
                                    }
                                    <ShChip size='small' color='primary' label={jobDetailsInInfo?.experience} />
                                </Stack>
                                <Stack direction='row' alignItems='center' columnGap={1} marginTop={3}>
                                    <InfoOutlinedIcon fontSize='small' />
                                    <Typography variant="body2" color="text.secondary">
                                        Job ID: {jobDetailsInInfo?.id}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        &bull;
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Postalcode: {jobDetailsInInfo?.postalcode}
                                    </Typography>
                                </Stack>
                                <Stack >
                                    <Stack direction='row' columnGap={3} rowGap={1} flexWrap='wrap' justifyContent='space-between'>
                                        <Stack direction='row' spacing={1}>
                                            {isSmScreen &&
                                                <ShButton size="small" color='primary' variant='contained' onClick={() => navigate('/careers')}>
                                                    View All Jobs
                                                </ShButton>
                                            }
                                        </Stack>
                                    </Stack>
                                    <Typography marginTop={2} variant="body1" component="div"> Share Job </Typography>
                                    <Stack marginTop={1} direction='row' columnGap={1} justifyContent='space-between'>
                                        <Stack direction='row' spacing={1}>
                                            <LinkedinShareButton url={currentUrl} title="Check out this job!" >
                                                <LinkedinIcon size={28} />
                                            </LinkedinShareButton>
                                            <FacebookShareButton url={currentUrl} title="Check out this job!">
                                                <FacebookIcon size={28} />
                                            </FacebookShareButton>
                                            <TwitterShareButton url={currentUrl} title="Check out this job!">
                                                <XIcon size={28} />
                                            </TwitterShareButton>
                                        </Stack>
                                        {/* Doesnt Work Currently */}
                                        <ShButton size='small' color='primary' variant='contained' endIcon={<OpenInNewIcon />}>View Company Website</ShButton>
                                    </Stack>
                                </Stack>
                            </JobDetailsBox>
                        </ShPaper>
                    }
                    {jobDetailsInInfo?.keywords?.some(keyword => keyword.length) &&
                        <Box>
                            {getJobDetailsStatus === 'success' &&
                                <ShPaper variant='outlined'>
                                    <JobDetailsBox>
                                        <Typography variant="h6" component="div">
                                            Job Requirements
                                        </Typography>
                                        <Stack marginTop={1} direction='row' columnGap={1} rowGap={1} flexWrap='wrap'>
                                            {jobDetailsInInfo?.keywords?.filter(keyword => keyword.length).map(keyword =>
                                                <Chip size='small' key={keyword} color='primary' variant='outlined' label={toTitleCase(keyword)} />)}
                                        </Stack>
                                    </JobDetailsBox>
                                </ShPaper>
                            }
                        </Box>
                    }
                    <Box>
                        {getJobDetailsStatus === 'pending' ? <Skeleton variant='rounded' animation='wave' width='100%' height={500} /> :
                            <ShPaper variant='outlined'>
                                <JobDetailsBox>
                                    <Typography variant="h6" gutterBottom fontWeight={700}>
                                        Job Description
                                    </Typography>
                                    {HtmlRegex.test(jobDetailsInInfo?.description ?? '')
                                        ? (
                                            <Typography variant="body2" component="div">
                                                {parse(jobDetailsInInfo?.description ?? '')}
                                            </Typography>
                                        )
                                        : (
                                            <DialogContentText whiteSpace="break-spaces" variant="body2">
                                                {jobDetailsInInfo?.description}
                                            </DialogContentText>
                                        )
                                    }
                                    <Box display='flex' justifyContent='end'>
                                        <ShGreenBtn
                                            endIcon={<OpenInNewIcon />}
                                            href={`https://app.smoothhiring.com/candidate/apply-job/${jobDetailsInInfo?.code}`}>
                                            Apply</ShGreenBtn>
                                    </Box>
                                </JobDetailsBox>
                            </ShPaper>
                        }
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
};