import { Stack } from "@mui/material";
import { IInterviewsListParams, InterviewSortCols, InterviewsType } from "Modules/Core/Interviews/InterviewsModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ISortParamsBase } from "shared/SharedModels";
import { DefaultPageSize } from "shared/constants";
import { getInterviewsSummary } from "store/slices/employer/interviews/interviews-details-slice";
import { getInterviewsList } from "store/slices/employer/interviews/interviews-list-slice";
import ErrorBoundary from "./calendarErrorBoundary";
import { Scheduler } from "./lib";


export const Calendar = () => {

    const pathname = useLocation()?.pathname?.split('/').pop();
    const dispatch = useAppDispatch();
    const { interviews } = useAppSelector(state => state.employer.interviews.interviewsList);

    const getInterviews = useCallback((interviewsState: InterviewsType, pageNo?: number, pageSize?: number,
        sortParams?: ISortParamsBase<InterviewSortCols>) => {
        const params: IInterviewsListParams = {
            status: 'Scheduled',
            pageNo: pageNo ?? 1,
            pageSize: pageSize ?? DefaultPageSize,
            sort: sortParams?.order ?? 'asc',
            sortBy: sortParams?.orderBy ?? 'alphabetical'
        };

        dispatch(getInterviewsList(params))
    }, [dispatch,]);

    // Set Interviews Type
    useEffect(() => {
        if (pathname) {
            getInterviews(pathname as InterviewsType);
        }
    }, [getInterviews, pathname]);

    // Get interviews list on page load.
    useEffect(() => {
        dispatch(getInterviewsSummary());
        getInterviews('scheduled');
    }, [dispatch, getInterviews]);

    // convert from 24hr to 12hr
    function convertToFormattedTime(militaryTime: string): string {
        const timeArray = militaryTime.split(':');
        const hours = parseInt(timeArray[0], 10);
        const minutes = parseInt(timeArray[1], 10);

        // Ensure hours and minutes are within valid ranges
        const formattedHours = hours >= 0 && hours <= 23 ? (hours % 12 || 12) : 12;
        const formattedMinutes = minutes >= 0 && minutes <= 59 ? (minutes < 10 ? `0${minutes}` : `${minutes}`) : '00';

        // Determine AM/PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Construct the final time string
        const finalTime: string = `${formattedHours}:${formattedMinutes} ${period}`;

        return finalTime;
    }


    const createCalendarEvents = (interviews: any[]) => {
        // Get the current date
        const currentDate = new Date();
        return interviews
            .filter((interview) => {
                // Parsing schedule date
                const scheduleDate = new Date(interview.schedule_date);

                // Check if the interview is within the last 180 days or the next 365 days
                const isWithinRange =
                    scheduleDate >= new Date(currentDate.getTime() - 365 * 24 * 60 * 60 * 1000) &&
                    scheduleDate <= new Date(currentDate.getTime() + 365 * 24 * 60 * 60 * 1000);
                return isWithinRange;
            })

            .map((interview) => {
                // Extracting relevant data
                const eventId = interview.candidate.account.first_name + ' ' + interview.candidate.account.last_name;
                const title = `Interview: ${eventId}`;

                // Parsing date and time values
                const scheduleDate = interview.schedule_date.replace(/-/g, '/');

                const fromTime = convertToFormattedTime(interview.from_time);
                const toTime = convertToFormattedTime(interview.to_time);

                // Creating the calendar event object
                const calendarEvent = {
                    event_id: eventId,
                    title: title,
                    start: new Date(`${scheduleDate} ${fromTime}`),
                    end: new Date(`${scheduleDate} ${toTime}`),
                    admin_id: interview.admin_id,
                    draggable: false,
                    editable: false,
                    deletable: false,
                    color: "#50b500",
                };

                return calendarEvent;
            });
    };

    const calendarEvents = createCalendarEvents(interviews);

    return (
        <ErrorBoundary>
            <Stack>
                {/* Render the Scheduler component wrapped with ErrorBoundary */}
                <Scheduler
                    editable={false}
                    events={calendarEvents}
                    week={{ weekDays: [0, 1, 2, 3, 4, 5, 6], weekStartOn: 6, startHour: 7, endHour: 24, step: 30 }}
                    day={{ startHour: 6, endHour: 24, step: 30 }}
                />
            </Stack>
        </ErrorBoundary>
    );
}