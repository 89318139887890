import React, { useEffect, useReducer } from 'react';

// Define props type for ErrorBoundary component
type ErrorBoundaryProps = {
    children: React.ReactNode;
}

// Define state type for ErrorBoundary component
type ErrorBoundaryState = {
    hasError: boolean;
}

// Action type for reducer
type ErrorBoundaryAction = {
    type: 'SET_ERROR';
}

// Reducer function for ErrorBoundary component
const errorBoundaryReducer = (state: ErrorBoundaryState, action: ErrorBoundaryAction): ErrorBoundaryState => {
    switch (action.type) {
        case 'SET_ERROR':
            return { ...state, hasError: true };
        default:
            return state;
    }
}

// ErrorBoundary functional component to catch errors during rendering
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
    const [state, dispatch] = useReducer(errorBoundaryReducer, { hasError: false });

    // useEffect to handle errors
    useEffect(() => {
        const handleOnError = () => {
            dispatch({ type: 'SET_ERROR' });
        };

        window.addEventListener('error', handleOnError);

        return () => {
            window.removeEventListener('error', handleOnError);
        };
    }, []);

    // If an error occurred, render a fallback UI
    if (state.hasError) {
        return (
            <p style={{ textAlign: 'center', marginTop: '50vh', transform: 'translateY(-50%)' }}>
                Sorry! Something went wrong.
            </p>
        );
    }

    // Render children if no error occurred
    return <>{children}</>;
};

export default ErrorBoundary;
