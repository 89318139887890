export const BillingManagerJobDescription = {
    title: "Billing Manager Job Description",
    jobRole: "A Billing Manager oversees the billing operations of a company, ensuring accuracy and efficiency in invoicing processes. They manage billing staff, handle customer inquiries, and collaborate with other departments to resolve billing issues.",
    preRead: "Use this Billing Manager job description template to advertise open positions for a Billing Manager in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Billing Manager, you will be responsible for overseeing the billing operations, managing billing staff, and ensuring timely and accurate invoicing. Your role will involve coordinating with various departments to address billing discrepancies, resolve customer inquiries, and optimize billing processes."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the billing process and ensure accuracy and timeliness of invoices.",
                "Manage billing staff, including hiring, training, and performance management.",
                "Develop and implement billing policies and procedures to improve efficiency.",
                "Handle customer inquiries and resolve billing discrepancies.",
                "Collaborate with sales, finance, and other departments to address billing issues.",
                "Monitor accounts receivable and follow up on overdue payments.",
                "Prepare billing reports and analyze billing trends.",
                "Ensure compliance with billing regulations and standards.",
                "Stay updated on industry trends and best practices in billing management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Accounting, Finance, Business Administration, or related field.",
                "Proven experience in billing management or related role.",
                "Strong leadership and managerial skills.",
                "Excellent communication and interpersonal skills.",
                "Detail-oriented with strong analytical abilities.",
                "Proficiency in billing software and Microsoft Office suite.",
                "Ability to multitask and prioritize tasks effectively.",
                "Problem-solving and decision-making skills.",
                "Knowledge of billing regulations and compliance requirements."
            ]
        }
    ]
};

export const AccountsPayableSpecialistJobDescription = {
    title: "Accounts Payable Specialist Job Description",
    jobRole: "An Accounts Payable Specialist is responsible for processing invoices, managing vendor accounts, and ensuring timely payments to suppliers. They play a critical role in maintaining good relationships with vendors and ensuring the accuracy of financial records.",
    preRead: "Use this Accounts Payable Specialist job description template to advertise open positions for an Accounts Payable Specialist in your company. Be sure to outline the responsibilities and qualifications required for the role.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Payable Specialist, you will be responsible for processing invoices, reconciling vendor accounts, and ensuring timely payments. Your role will involve verifying the accuracy of invoices, resolving discrepancies, and communicating with vendors to address payment issues."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process vendor invoices and expense reports accurately and efficiently.",
                "Verify invoice accuracy, including pricing, quantities, and terms.",
                "Reconcile vendor statements and resolve discrepancies.",
                "Ensure timely payment of invoices and adherence to payment terms.",
                "Communicate with vendors to resolve payment issues and address inquiries.",
                "Maintain accurate records of accounts payable transactions.",
                "Assist in month-end closing and financial reporting activities.",
                "Adhere to company policies and procedures related to accounts payable.",
                "Collaborate with other departments to streamline accounts payable processes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate or Bachelor’s Degree in Accounting, Finance, or related field.",
                "Previous experience in accounts payable or accounting role.",
                "Knowledge of accounts payable principles and practices.",
                "Proficiency in accounting software and Microsoft Excel.",
                "Strong attention to detail and accuracy.",
                "Excellent communication and interpersonal skills.",
                "Ability to prioritize tasks and meet deadlines.",
                "Problem-solving and analytical skills.",
                "Familiarity with relevant accounting regulations and standards."
            ]
        }
    ]
};


export const SeniorTaxAndAccountingSpecialistJobDescription = {
    title: "Senior Tax and Accounting Specialist Job Description",
    jobRole: "A Senior Tax and Accounting Specialist is a professional expert in managing tax compliance, overseeing accounting processes, and ensuring financial regulations are met. They play a critical role in maintaining the financial health and legal standing of an organization.",
    preRead: "Use this Senior Tax and Accounting Specialist job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Senior Tax and Accounting Specialist, you will be responsible for managing tax compliance, overseeing financial reporting, and developing tax strategies to optimize financial performance. Your expertise in accounting and taxation will be crucial for ensuring the financial integrity and compliance of the organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage tax compliance with local and international tax laws.",
                "Prepare accurate financial documents and reports.",
                "Develop and implement tax strategies to optimize financial performance.",
                "Liaise with external tax consultants and regulatory authorities.",
                "Ensure compliance with financial regulations and standards.",
                "Conduct financial analysis and reporting.",
                "Review and reconcile financial data.",
                "Assist in audit preparation and support.",
                "Provide financial guidance and support to management.",
                "Stay updated on changes in tax laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Finance, Accounting, or related field.",
                "2-5 years of experience in a tax-related position, with Big 4 experience preferred.",
                "Knowledge of Greek corporate tax requirements and GAAP; familiarity with IFRS is a plus.",
                "Excellent English communication skills, both verbal and written.",
                "Proficiency in accounting systems and software.",
                "High accuracy and attention to detail.",
                "Strong analytical and problem-solving skills.",
                "Ability to work independently and collaboratively as part of a team."
            ]
        }
    ]
};

export const AccountingInternJobDescription = {
    title: "Accounting Intern Job Description",
    jobRole: "Accounting Interns assist with various accounting tasks and gain practical experience in tax compliance, financial reporting, and accounting processes. They support the finance team in maintaining accurate financial records and preparing financial documents.",
    preRead: "Use this Accounting Intern job description template to advertise open intern positions for your company. Be sure to outline the responsibilities and qualifications required for the role.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounting Intern, you will support the finance department in various accounting tasks. You will assist in preparing financial documents, reconciling accounts, and conducting financial analysis. This internship will provide you with valuable hands-on experience in accounting and finance."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in preparing financial documents and reports.",
                "Support in reconciling accounts and conducting financial analysis.",
                "Assist in tax compliance activities.",
                "Help maintain accurate financial records and documentation.",
                "Support the finance team in day-to-day accounting tasks.",
                "Participate in team meetings and projects as assigned.",
                "Adhere to company policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Currently enrolled in a Bachelor’s Degree program in Finance, Accounting, or related field.",
                "Strong academic performance with a minimum GPA of 3.0.",
                "Basic understanding of accounting principles and practices.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Excel and other accounting software is a plus.",
                "Ability to multitask and prioritize tasks effectively.",
                "Willingness to learn and contribute as part of a team."
            ]
        }
    ]
};

export const FinancialAccountantJobDescription = {
    title: "Financial Accountant Job Description",
    jobRole: "A Financial Accountant plays a vital role in ensuring the financial health and integrity of an organization by maintaining accurate financial records, preparing financial statements, and analyzing financial data. They provide valuable insights to management to support decision-making processes and ensure compliance with financial regulations.",
    preRead: "Use this Financial Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Financial Accountant, you will be responsible for overseeing the financial accounting processes of the organization, including recording transactions, reconciling accounts, and preparing financial reports. You will collaborate with other departments to ensure accurate financial data and compliance with accounting standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare financial statements, including balance sheets, income statements, and cash flow statements.",
                "Analyze financial data and provide insights to management for decision-making purposes.",
                "Maintain accurate financial records and ensure compliance with accounting principles and regulations.",
                "Reconcile accounts and resolve discrepancies in a timely manner.",
                "Assist in the preparation of budgets and financial forecasts.",
                "Conduct internal audits to ensure accuracy and compliance with internal controls.",
                "Collaborate with external auditors during the audit process.",
                "Stay updated on changes in accounting standards and regulations.",
                "Provide support for financial reporting and analysis as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA certification preferred.",
                "2-5 years of experience in financial accounting or related field.",
                "Strong understanding of accounting principles and regulations.",
                "Proficiency in accounting software and Microsoft Excel.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Ability to work effectively both independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Financial Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Financial Accountants earn between $50,000 to $80,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const TaxManagerJobDescription = {
    title: "Tax Manager Job Description",
    jobRole: "A Tax Manager oversees all aspects of tax compliance and planning for an organization. They ensure that the company complies with tax laws and regulations, minimize tax liabilities, and develop tax strategies to optimize financial performance. Tax Managers play a critical role in maintaining the financial health and legal standing of the organization.",
    preRead: "Use this Tax Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tax Manager, you will be responsible for managing the organization’s tax compliance and planning activities. You will work closely with internal stakeholders and external advisors to ensure accurate and timely tax filings, minimize tax liabilities, and develop tax strategies to support the organization's goals."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage all aspects of tax compliance, including preparing and filing tax returns, and ensuring compliance with tax laws and regulations.",
                "Analyze the organization's tax positions and develop strategies to minimize tax liabilities.",
                "Stay updated on changes in tax laws and regulations and ensure the organization's compliance.",
                "Coordinate with external tax advisors and authorities during tax audits and inquiries.",
                "Provide guidance and support to internal stakeholders on tax-related matters.",
                "Conduct research on tax issues and provide recommendations to management.",
                "Participate in strategic tax planning initiatives to support the organization's goals.",
                "Prepare and review tax provisions and deferred tax calculations.",
                "Assist in the implementation of tax-related systems and processes to improve efficiency and accuracy."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or similar professional certification required.",
                "5-7 years of experience in tax compliance and planning, preferably in a corporate environment or public accounting firm.",
                "Strong knowledge of tax laws and regulations.",
                "Excellent analytical and problem-solving skills.",
                "Ability to work effectively under pressure and meet tight deadlines.",
                "Strong communication and interpersonal skills.",
                "Proficiency in tax preparation software and Microsoft Excel.",
                "Attention to detail and accuracy.",
                "Ability to lead and manage a team effectively."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Tax Managers varies depending on factors such as experience, location, and the size of the organization. On average, Tax Managers earn between $90,000 to $150,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CostAccountantJobDescription = {
    title: "Cost Accountant Job Description",
    jobRole: "A Cost Accountant is responsible for analyzing and monitoring the cost of production and operations within an organization. They provide valuable insights into cost-saving opportunities, budgeting, and pricing strategies to optimize financial performance.",
    preRead: "Use this Cost Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cost Accountant, you will be responsible for analyzing and monitoring the cost of production and operations within the organization. You will work closely with the finance and operations teams to ensure accurate cost data and provide insights to support decision-making processes."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze and monitor the cost of production and operations.",
                "Prepare cost estimates and budgets for new products or projects.",
                "Evaluate cost-saving opportunities and recommend cost reduction strategies.",
                "Analyze variances between actual and budgeted costs.",
                "Maintain cost accounting records and ensure accuracy.",
                "Provide support for inventory valuation and control.",
                "Prepare and analyze financial reports related to cost and profitability.",
                "Participate in cross-functional teams to improve processes and reduce costs.",
                "Assist in the development of pricing strategies based on cost analysis."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "2-5 years of experience in cost accounting or related field.",
                "Strong understanding of cost accounting principles and techniques.",
                "Proficiency in Microsoft Excel and accounting software.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Ability to work effectively both independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Cost Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Cost Accountants earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const SeniorAuditorJobDescription = {
    title: "Senior Auditor Job Description",
    jobRole: "A Senior Auditor is responsible for assessing the accuracy, completeness, and reliability of financial information and internal controls within an organization. They conduct audits to ensure compliance with laws, regulations, and company policies, and provide recommendations to improve processes and mitigate risks.",
    preRead: "Use this Senior Auditor job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Senior Auditor, you will be responsible for planning and executing audit procedures, identifying areas of risk and control weaknesses, and providing recommendations to management for improvement. You will work closely with internal stakeholders and external auditors to ensure compliance with auditing standards and regulatory requirements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Plan and execute audit engagements in accordance with audit standards and procedures.",
                "Assess the adequacy and effectiveness of internal controls and identify areas of risk.",
                "Conduct tests of controls and substantive audit procedures to verify the accuracy of financial information.",
                "Document audit findings and communicate results to management.",
                "Provide recommendations for improving internal controls and business processes.",
                "Prepare audit reports and presentations for management and stakeholders.",
                "Coordinate with external auditors during the audit process.",
                "Stay updated on changes in auditing standards and regulations.",
                "Provide training and guidance to junior auditors as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA, CIA, or similar professional certification required.",
                "5-7 years of experience in auditing, preferably in a public accounting firm or internal audit department.",
                "Strong understanding of auditing standards and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Proficiency in audit software and Microsoft Office.",
                "Ability to work effectively both independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Senior Auditors varies depending on factors such as experience, location, and the size of the organization. On average, Senior Auditors earn between $70,000 to $110,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};


export const AccountsReceivableClerkJobDescription = {
    title: "Accounts Receivable Clerk Job Description",
    jobRole: "An Accounts Receivable Clerk is responsible for managing incoming payments, processing invoices, and ensuring that financial records are accurate and up-to-date. They play a key role in maintaining the financial health of an organization by managing its receivables.",
    preRead: "Use this Accounts Receivable Clerk job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Receivable Clerk, you will be responsible for processing and monitoring incoming payments, ensuring that invoices are accurate and sent out in a timely manner, and maintaining records of payments received. You will work closely with the finance team to ensure the accuracy and efficiency of the accounts receivable process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process accounts and incoming payments in compliance with financial policies and procedures.",
                "Perform day-to-day financial transactions, including verifying, classifying, computing, posting, and recording accounts receivables’ data.",
                "Prepare bills, invoices, and bank deposits.",
                "Reconcile the accounts receivable ledger to ensure that all payments are accounted for and properly posted.",
                "Verify discrepancies and resolve clients' billing issues.",
                "Facilitate payment of invoices due by sending bill reminders and contacting clients.",
                "Generate financial statements and reports detailing accounts receivable status.",
                "Ensure compliance with company policies and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as an Accounts Receivable Clerk, Accounts Receivable Manager, or similar role.",
                "Familiarity with bookkeeping and basic accounting procedures.",
                "Proficiency in MS Office and accounting software.",
                "High degree of accuracy and attention to detail.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Good organizational and time-management abilities."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounts Receivable Clerks varies depending on factors such as experience, location, and the size of the organization. On average, Accounts Receivable Clerks earn between $35,000 to $50,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CreditAnalystJobDescription = {
    title: "Credit Analyst Job Description",
    jobRole: "A Credit Analyst is responsible for assessing the creditworthiness of potential customers and existing clients. They analyze financial data, prepare reports, and provide recommendations on whether to extend credit and under what terms.",
    preRead: "Use this Credit Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Credit Analyst, you will evaluate the financial status of individuals and businesses applying for credit, prepare detailed reports based on your findings, and make recommendations regarding the approval or rejection of credit applications. You will play a critical role in managing risk and ensuring the financial stability of the organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze credit data and financial statements to determine the degree of risk involved in extending credit.",
                "Prepare reports that include the degree of risk involved in extending credit.",
                "Evaluate customer records and recommend payment plans based on earnings, savings data, payment history, and purchase activity.",
                "Consult with customers to resolve complaints and verify financial and credit transactions.",
                "Review and update credit and loan files.",
                "Maintain records of loan applications, credit approvals, and other related activities.",
                "Stay updated on legal, compliance, and market risk-related issues."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Credit Analyst or similar role.",
                "Strong understanding of lending procedures and credit risk management.",
                "Proficiency in financial analysis software and MS Office.",
                "Excellent analytical and decision-making skills.",
                "Strong communication and interpersonal skills.",
                "Detail-oriented with the ability to manage multiple tasks.",
                "Knowledge of relevant legal and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Credit Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Credit Analysts earn between $50,000 to $75,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const BookkeeperJobDescription = {
    title: "Bookkeeper Job Description",
    jobRole: "A Bookkeeper is responsible for recording financial transactions, maintaining financial records, and ensuring the accuracy of financial data. They play a crucial role in managing the financial health of an organization by keeping track of income and expenditures.",
    preRead: "Use this Bookkeeper job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Bookkeeper, you will be responsible for maintaining accurate financial records, including recording transactions, reconciling accounts, and preparing financial reports. You will work closely with the finance team to ensure the accuracy and completeness of the company’s financial data."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Record financial transactions and complete the posting process.",
                "Reconcile sales taxes, payroll taxes, and bank accounts at the end of each month.",
                "Monitor financial transactions and reports.",
                "Process accounts receivable and payable.",
                "Create invoices and manage accounts receivable.",
                "Maintain and balance the general ledger.",
                "Prepare financial statements for review by management.",
                "Assist with budgeting and forecasting.",
                "Ensure compliance with applicable standards, rules, and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as a Bookkeeper or in a similar role.",
                "Proficiency in accounting software and MS Office.",
                "Strong attention to detail and accuracy.",
                "Excellent organizational and time-management skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Knowledge of bookkeeping principles and practices."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Bookkeepers varies depending on factors such as experience, location, and the size of the organization. On average, Bookkeepers earn between $40,000 to $60,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const BudgetAnalystJobDescription = {
    title: "Budget Analyst Job Description",
    jobRole: "A Budget Analyst is responsible for helping organizations manage their finances by evaluating budget proposals, analyzing financial data, and making recommendations for budget allocations.",
    preRead: "Use this Budget Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Budget Analyst, you will review budget proposals, conduct cost-benefit analyses, and evaluate financial data to ensure efficient allocation of resources. You will collaborate with various departments to provide financial insights and recommendations for budget planning and management."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Review and analyze budget proposals and financial data.",
                "Conduct cost-benefit analyses to support budget recommendations.",
                "Prepare budget reports and financial statements.",
                "Monitor budget performance and make adjustments as needed.",
                "Collaborate with department heads to develop and manage budgets.",
                "Ensure compliance with financial regulations and policies.",
                "Identify areas for cost savings and efficiency improvements.",
                "Provide training and support to staff on budgetary processes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, Economics, or related field.",
                "Proven experience as a Budget Analyst or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in financial analysis software and MS Office.",
                "Excellent communication and presentation skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of financial regulations and policies."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Budget Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Budget Analysts earn between $55,000 to $80,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const ManagementAccountantJobDescription = {
    title: "Management Accountant Job Description",
    jobRole: "A Management Accountant is responsible for preparing and analyzing financial information to help managers make informed business decisions. They play a key role in budgeting, forecasting, and financial planning.",
    preRead: "Use this Management Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Management Accountant, you will be responsible for preparing financial statements, conducting financial analysis, and providing strategic recommendations to management. You will work closely with various departments to support financial planning and decision-making processes."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and analyze financial statements and reports.",
                "Conduct financial analysis to support business decisions.",
                "Assist in budgeting and forecasting processes.",
                "Monitor and report on financial performance.",
                "Provide strategic recommendations to management.",
                "Ensure compliance with financial regulations and standards.",
                "Coordinate with external auditors and regulatory bodies.",
                "Provide training and support to staff on financial matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or CMA certification preferred.",
                "Proven experience as a Management Accountant or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and presentation skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Management Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Management Accountants earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CertifiedPublicAccountantCPAJobDescription = {
    title: "Certified Public Accountant (CPA) Job Description",
    jobRole: "A Certified Public Accountant (CPA) is responsible for providing financial advice, preparing financial statements, and ensuring compliance with financial regulations. They play a crucial role in helping organizations manage their finances effectively.",
    preRead: "Use this Certified Public Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Certified Public Accountant (CPA), you will provide financial services to clients, including preparing tax returns, conducting audits, and offering financial advice. You will ensure compliance with financial regulations and help clients achieve their financial goals."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and review financial statements and reports.",
                "Conduct audits and ensure compliance with financial regulations.",
                "Prepare tax returns and provide tax planning advice.",
                "Provide financial advice and recommendations to clients.",
                "Assist in budgeting and financial planning processes.",
                "Stay updated on changes in financial regulations and standards.",
                "Maintain accurate financial records and documentation.",
                "Collaborate with other financial professionals and regulatory bodies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA certification required.",
                "Proven experience as a CPA or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Certified Public Accountants varies depending on factors such as experience, location, and the size of the organization. On average, CPAs earn between $60,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountsReceivableManagerJobDescription = {
    title: "Accounts Receivable Manager Job Description",
    jobRole: "An Accounts Receivable Manager is responsible for overseeing the accounts receivable department and ensuring that the collection of payments is carried out in a timely and efficient manner.",
    preRead: "Use this Accounts Receivable Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Receivable Manager, you will manage the accounts receivable team, ensure timely collection of payments, and maintain accurate records of accounts receivable transactions. You will work closely with other departments to optimize the accounts receivable process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and oversee the accounts receivable department.",
                "Ensure timely collection of payments and resolve any issues related to unpaid invoices.",
                "Maintain accurate records of accounts receivable transactions.",
                "Prepare reports on accounts receivable status and performance.",
                "Develop and implement strategies to improve the accounts receivable process.",
                "Collaborate with other departments to ensure smooth operations.",
                "Train and mentor accounts receivable staff.",
                "Ensure compliance with financial regulations and company policies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as an Accounts Receivable Manager or in a similar role.",
                "Strong understanding of accounts receivable processes and procedures.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and leadership skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Strong problem-solving and analytical skills."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounts Receivable Managers varies depending on factors such as experience, location, and the size of the organization. On average, Accounts Receivable Managers earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const ForensicAccountantJobDescription = {
    title: "Forensic Accountant Job Description",
    jobRole: "A Forensic Accountant is responsible for investigating financial discrepancies and fraud, analyzing financial data for legal cases, and providing expert testimony in court.",
    preRead: "Use this Forensic Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Forensic Accountant, you will investigate financial discrepancies and fraud, analyze financial data for legal cases, and provide expert testimony in court. You will work closely with legal and law enforcement professionals to uncover financial crimes."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Investigate financial discrepancies and fraud.",
                "Analyze financial data for legal cases.",
                "Prepare detailed reports and documentation for legal proceedings.",
                "Provide expert testimony in court.",
                "Collaborate with legal and law enforcement professionals.",
                "Stay updated on changes in financial regulations and standards.",
                "Ensure compliance with financial regulations and company policies.",
                "Provide training and support to staff on forensic accounting matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or CFE certification required.",
                "Proven experience as a Forensic Accountant or in a similar role.",
                "Strong analytical and investigative skills.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Forensic Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Forensic Accountants earn between $70,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const PayrollAdministratorJobDescription = {
    title: "Payroll Administrator Job Description",
    jobRole: "A Payroll Administrator is responsible for managing the payroll process, ensuring that employees are paid accurately and on time, and maintaining payroll records.",
    preRead: "Use this Payroll Administrator job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Payroll Administrator, you will manage the payroll process, ensure that employees are paid accurately and on time, and maintain payroll records. You will work closely with the finance and HR departments to ensure compliance with payroll regulations and company policies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the payroll process and ensure timely payment of salaries.",
                "Maintain accurate payroll records and documentation.",
                "Process payroll deductions, benefits, and taxes.",
                "Prepare payroll reports and financial statements.",
                "Ensure compliance with payroll regulations and company policies.",
                "Resolve payroll discrepancies and answer employee queries.",
                "Collaborate with HR and finance departments.",
                "Stay updated on changes in payroll regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as a Payroll Administrator or in a similar role.",
                "Strong understanding of payroll processes and procedures.",
                "Proficiency in payroll software and MS Office.",
                "Excellent communication and organizational skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of payroll regulations and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Payroll Administrators varies depending on factors such as experience, location, and the size of the organization. On average, Payroll Administrators earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const InternalAuditorJobDescription = {
    title: "Internal Auditor Job Description",
    jobRole: "An Internal Auditor is responsible for evaluating and improving the effectiveness of internal controls, risk management, and governance processes within an organization.",
    preRead: "Use this Internal Auditor job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Internal Auditor, you will evaluate and improve the effectiveness of internal controls, risk management, and governance processes within the organization. You will conduct audits, assess risks, and provide recommendations to management for improvements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct internal audits to assess the effectiveness of internal controls, risk management, and governance processes.",
                "Identify areas of risk and control weaknesses.",
                "Provide recommendations for improving internal controls and business processes.",
                "Prepare audit reports and presentations for management.",
                "Monitor and follow up on the implementation of audit recommendations.",
                "Ensure compliance with auditing standards and regulatory requirements.",
                "Collaborate with external auditors and regulatory bodies.",
                "Provide training and guidance to junior auditors as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA, CIA, or similar professional certification preferred.",
                "Proven experience as an Internal Auditor or in a similar role.",
                "Strong understanding of auditing standards and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Proficiency in audit software and MS Office.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Internal Auditors varies depending on factors such as experience, location, and the size of the organization. On average, Internal Auditors earn between $60,000 to $85,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const RevenueAccountantJobDescription = {
    title: "Revenue Accountant Job Description",
    jobRole: "A Revenue Accountant is responsible for managing and recording revenue transactions, ensuring compliance with accounting standards, and providing financial insights related to revenue performance.",
    preRead: "Use this Revenue Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Revenue Accountant, you will manage and record revenue transactions, ensure compliance with accounting standards, and provide financial insights related to revenue performance. You will work closely with various departments to ensure accurate and timely revenue recognition."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and record revenue transactions in compliance with accounting standards.",
                "Prepare and review revenue reports and financial statements.",
                "Ensure timely and accurate revenue recognition.",
                "Analyze revenue performance and provide financial insights.",
                "Collaborate with sales and finance teams to resolve revenue-related issues.",
                "Assist in budgeting and forecasting processes.",
                "Ensure compliance with financial regulations and company policies.",
                "Stay updated on changes in accounting standards and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as a Revenue Accountant or in a similar role.",
                "Strong understanding of revenue recognition principles and accounting standards.",
                "Proficiency in accounting software and MS Office.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Revenue Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Revenue Accountants earn between $65,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const FinancialControllerJobDescription = {
    title: "Financial Controller Job Description",
    jobRole: "A Financial Controller is responsible for overseeing the financial operations of an organization, including financial reporting, budgeting, and compliance with financial regulations.",
    preRead: "Use this Financial Controller job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Financial Controller, you will oversee the financial operations of the organization, including financial reporting, budgeting, and compliance with financial regulations. You will manage the finance team and provide strategic financial insights to senior management."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the preparation of financial statements and reports.",
                "Manage the budgeting and forecasting processes.",
                "Ensure compliance with financial regulations and standards.",
                "Monitor and analyze financial performance.",
                "Provide strategic financial insights to senior management.",
                "Manage and mentor the finance team.",
                "Coordinate with external auditors and regulatory bodies.",
                "Develop and implement financial policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or CMA certification preferred.",
                "Proven experience as a Financial Controller or in a similar role.",
                "Strong understanding of financial regulations and standards.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and leadership skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Financial Controllers varies depending on factors such as experience, location, and the size of the organization. On average, Financial Controllers earn between $85,000 to $130,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const FinancialReportingManagerJobDescription = {
    title: "Financial Reporting Manager Job Description",
    jobRole: "A Financial Reporting Manager is responsible for overseeing the preparation and submission of financial reports, ensuring compliance with accounting standards and regulatory requirements.",
    preRead: "Use this Financial Reporting Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Financial Reporting Manager, you will oversee the preparation and submission of financial reports, ensuring compliance with accounting standards and regulatory requirements. You will work closely with various departments to gather and analyze financial data."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the preparation and submission of financial reports.",
                "Ensure compliance with accounting standards and regulatory requirements.",
                "Review and analyze financial statements for accuracy and completeness.",
                "Provide financial insights and recommendations to management.",
                "Coordinate with external auditors and regulatory bodies.",
                "Develop and implement financial reporting policies and procedures.",
                "Stay updated on changes in accounting standards and regulations.",
                "Provide training and support to staff on financial reporting matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA certification preferred.",
                "Proven experience as a Financial Reporting Manager or in a similar role.",
                "Strong understanding of financial reporting standards and regulations.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Financial Reporting Managers varies depending on factors such as experience, location, and the size of the organization. On average, Financial Reporting Managers earn between $80,000 to $120,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const TaxAccountantJobDescription = {
    title: "Tax Accountant Job Description",
    jobRole: "A Tax Accountant is responsible for preparing tax returns, ensuring compliance with tax laws and regulations, and providing tax planning and advisory services to clients.",
    preRead: "Use this Tax Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tax Accountant, you will prepare tax returns, ensure compliance with tax laws and regulations, and provide tax planning and advisory services to clients. You will work closely with clients to minimize tax liabilities and optimize tax strategies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and review tax returns for individuals and businesses.",
                "Ensure compliance with tax laws and regulations.",
                "Provide tax planning and advisory services to clients.",
                "Stay updated on changes in tax laws and regulations.",
                "Assist clients with tax audits and disputes.",
                "Maintain accurate tax records and documentation.",
                "Provide training and support to staff on tax matters.",
                "Collaborate with other financial professionals and regulatory bodies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or EA certification preferred.",
                "Proven experience as a Tax Accountant or in a similar role.",
                "Strong understanding of tax laws and regulations.",
                "Proficiency in tax software and MS Office.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Tax Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Tax Accountants earn between $55,000 to $85,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CostAnalystJobDescription = {
    title: "Cost Analyst Job Description",
    jobRole: "A Cost Analyst is responsible for analyzing and controlling the cost of an organization's operations, providing insights to improve cost efficiency, and supporting budgeting and forecasting processes.",
    preRead: "Use this Cost Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cost Analyst, you will analyze and control the costs of the organization's operations, provide insights to improve cost efficiency, and support the budgeting and forecasting processes. You will work closely with various departments to identify cost-saving opportunities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze and control the costs of the organization's operations.",
                "Identify cost-saving opportunities and provide recommendations.",
                "Support budgeting and forecasting processes.",
                "Prepare and analyze cost reports and financial statements.",
                "Collaborate with various departments to gather cost data.",
                "Ensure compliance with financial regulations and company policies.",
                "Monitor and report on cost performance.",
                "Provide training and support to staff on cost management matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as a Cost Analyst or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of cost accounting principles and practices."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Cost Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Cost Analysts earn between $55,000 to $75,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const FinanceDirectorJobDescription = {
    title: "Finance Director Job Description",
    jobRole: "A Finance Director is responsible for overseeing the financial operations of an organization, including financial planning, management, and reporting, to ensure financial health and compliance with regulations.",
    preRead: "Use this Finance Director job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Finance Director, you will oversee the financial operations of the organization, including financial planning, management, and reporting, to ensure financial health and compliance with regulations. You will work closely with senior management to provide strategic financial insights."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee financial planning, management, and reporting.",
                "Ensure compliance with financial regulations and standards.",
                "Develop and implement financial strategies and policies.",
                "Manage the budgeting and forecasting processes.",
                "Provide strategic financial insights to senior management.",
                "Monitor and analyze financial performance.",
                "Coordinate with external auditors and regulatory bodies.",
                "Manage and mentor the finance team."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA or CMA certification preferred.",
                "Proven experience as a Finance Director or in a similar role.",
                "Strong understanding of financial regulations and standards.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and leadership skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Finance Directors varies depending on factors such as experience, location, and the size of the organization. On average, Finance Directors earn between $100,000 to $150,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const BillingCoordinatorJobDescription = {
    title: "Billing Coordinator Job Description",
    jobRole: "A Billing Coordinator is responsible for managing the billing process, ensuring accurate and timely invoicing, and maintaining billing records.",
    preRead: "Use this Billing Coordinator job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Billing Coordinator, you will manage the billing process, ensure accurate and timely invoicing, and maintain billing records. You will work closely with the finance and customer service departments to resolve billing issues and provide excellent service to clients."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the billing process and ensure accurate and timely invoicing.",
                "Maintain accurate billing records and documentation.",
                "Resolve billing issues and answer client queries.",
                "Prepare billing reports and financial statements.",
                "Collaborate with finance and customer service departments.",
                "Ensure compliance with billing regulations and company policies.",
                "Monitor and report on billing performance.",
                "Provide training and support to staff on billing matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as a Billing Coordinator or in a similar role.",
                "Strong understanding of billing processes and procedures.",
                "Proficiency in billing software and MS Office.",
                "Excellent communication and organizational skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of billing regulations and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Billing Coordinators varies depending on factors such as experience, location, and the size of the organization. On average, Billing Coordinators earn between $40,000 to $60,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AuditManagerJobDescription = {
    title: "Audit Manager Job Description",
    jobRole: "An Audit Manager is responsible for overseeing the audit process, ensuring compliance with auditing standards, and providing recommendations to improve internal controls and business processes.",
    preRead: "Use this Audit Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Audit Manager, you will oversee the audit process, ensure compliance with auditing standards, and provide recommendations to improve internal controls and business processes. You will manage a team of auditors and work closely with senior management to address audit findings."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the audit process and ensure compliance with auditing standards.",
                "Identify areas of risk and control weaknesses.",
                "Provide recommendations for improving internal controls and business processes.",
                "Prepare audit reports and presentations for management.",
                "Monitor and follow up on the implementation of audit recommendations.",
                "Manage and mentor a team of auditors.",
                "Coordinate with external auditors and regulatory bodies.",
                "Stay updated on changes in auditing standards and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA, CIA, or similar professional certification preferred.",
                "Proven experience as an Audit Manager or in a similar role.",
                "Strong understanding of auditing standards and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and leadership skills.",
                "Proficiency in audit software and MS Office.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Audit Managers varies depending on factors such as experience, location, and the size of the organization. On average, Audit Managers earn between $80,000 to $120,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const TreasuryAnalystJobDescription = {
    title: "Treasury Analyst Job Description",
    jobRole: "A Treasury Analyst is responsible for managing the organization's liquidity, funding, and financial risk, ensuring efficient cash flow and compliance with financial regulations.",
    preRead: "Use this Treasury Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Treasury Analyst, you will manage the organization's liquidity, funding, and financial risk, ensuring efficient cash flow and compliance with financial regulations. You will analyze financial data, prepare reports, and provide recommendations to improve financial performance."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the organization's liquidity, funding, and financial risk.",
                "Ensure efficient cash flow and compliance with financial regulations.",
                "Analyze financial data and prepare reports.",
                "Provide recommendations to improve financial performance.",
                "Monitor and forecast cash flow and liquidity levels.",
                "Manage relationships with financial institutions and investors.",
                "Develop and implement treasury policies and procedures.",
                "Stay updated on changes in financial regulations and market conditions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Treasury Analyst or in a similar role.",
                "Strong understanding of financial regulations and markets.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in financial software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of treasury management systems and practices."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Treasury Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Treasury Analysts earn between $65,000 to $95,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountsPayableClerkJobDescription = {
    title: "Accounts Payable Clerk Job Description",
    jobRole: "An Accounts Payable Clerk is responsible for managing and processing invoices and payments, ensuring accuracy and timeliness, and maintaining financial records.",
    preRead: "Use this Accounts Payable Clerk job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Payable Clerk, you will manage and process invoices and payments, ensuring accuracy and timeliness, and maintain financial records. You will work closely with vendors and internal departments to resolve payment issues and ensure efficient financial operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and process invoices and payments.",
                "Ensure accuracy and timeliness of payments.",
                "Maintain accurate financial records and documentation.",
                "Resolve payment issues and answer vendor queries.",
                "Prepare accounts payable reports and financial statements.",
                "Collaborate with finance and other departments.",
                "Ensure compliance with financial regulations and company policies.",
                "Monitor and report on accounts payable performance.",
                "Provide training and support to staff on accounts payable matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as an Accounts Payable Clerk or in a similar role.",
                "Strong understanding of accounts payable processes and procedures.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and organizational skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of accounts payable regulations and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounts Payable Clerks varies depending on factors such as experience, location, and the size of the organization. On average, Accounts Payable Clerks earn between $35,000 to $50,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const FinancialAnalystJobDescription = {
    title: "Financial Analyst Job Description",
    jobRole: "A Financial Analyst is responsible for analyzing financial data, creating financial models, and providing insights to support decision-making and strategic planning.",
    preRead: "Use this Financial Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Financial Analyst, you will analyze financial data, create financial models, and provide insights to support decision-making and strategic planning. You will work closely with various departments to gather financial information and provide recommendations to improve financial performance."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze financial data and create financial models.",
                "Provide insights to support decision-making and strategic planning.",
                "Prepare financial reports and presentations for management.",
                "Monitor and analyze financial performance.",
                "Collaborate with various departments to gather financial information.",
                "Develop and implement financial forecasting and budgeting processes.",
                "Stay updated on changes in financial regulations and market conditions.",
                "Provide training and support to staff on financial analysis matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Financial Analyst or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in financial software and MS Office.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of financial analysis principles and practices."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Financial Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Financial Analysts earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CorporateAccountantJobDescription = {
    title: "Corporate Accountant Job Description",
    jobRole: "A Corporate Accountant is responsible for managing the financial records and transactions of an organization, ensuring accuracy and compliance with financial regulations.",
    preRead: "Use this Corporate Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Corporate Accountant, you will manage the financial records and transactions of the organization, ensuring accuracy and compliance with financial regulations. You will prepare financial statements, reports, and analyses to support decision-making and financial planning."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the financial records and transactions of the organization.",
                "Ensure accuracy and compliance with financial regulations.",
                "Prepare financial statements, reports, and analyses.",
                "Monitor and analyze financial performance.",
                "Collaborate with various departments to gather financial information.",
                "Support budgeting and forecasting processes.",
                "Coordinate with external auditors and regulatory bodies.",
                "Stay updated on changes in financial regulations and accounting standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA certification preferred.",
                "Proven experience as a Corporate Accountant or in a similar role.",
                "Strong understanding of accounting principles and practices.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Corporate Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Corporate Accountants earn between $65,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountsPayableManagerJobDescription = {
    title: "Accounts Payable Manager Job Description",
    jobRole: "An Accounts Payable Manager is responsible for overseeing the accounts payable process, ensuring timely and accurate payments, and managing a team of accounts payable clerks.",
    preRead: "Use this Accounts Payable Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Payable Manager, you will oversee the accounts payable process, ensure timely and accurate payments, and manage a team of accounts payable clerks. You will work closely with vendors and internal departments to resolve payment issues and ensure efficient financial operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the accounts payable process and ensure timely and accurate payments.",
                "Manage and mentor a team of accounts payable clerks.",
                "Resolve payment issues and answer vendor queries.",
                "Prepare accounts payable reports and financial statements.",
                "Collaborate with finance and other departments.",
                "Ensure compliance with financial regulations and company policies.",
                "Monitor and report on accounts payable performance.",
                "Develop and implement accounts payable policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as an Accounts Payable Manager or in a similar role.",
                "Strong understanding of accounts payable processes and procedures.",
                "Proficiency in accounting software and MS Office.",
                "Excellent communication and organizational skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of accounts payable regulations and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounts Payable Managers varies depending on factors such as experience, location, and the size of the organization. On average, Accounts Payable Managers earn between $60,000 to $85,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CreditManagerJobDescription = {
    title: "Credit Manager Job Description",
    jobRole: "A Credit Manager is responsible for managing the credit granting process, assessing the creditworthiness of potential customers, and ensuring the timely collection of payments.",
    preRead: "Use this Credit Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Credit Manager, you will manage the credit granting process, assess the creditworthiness of potential customers, and ensure the timely collection of payments. You will work closely with the sales and finance departments to support credit decisions and manage credit risk."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the credit granting process and assess creditworthiness of potential customers.",
                "Ensure timely collection of payments and manage credit risk.",
                "Prepare credit reports and analyses for management.",
                "Develop and implement credit policies and procedures.",
                "Monitor and analyze customer credit profiles.",
                "Collaborate with sales and finance departments.",
                "Resolve credit issues and disputes with customers.",
                "Stay updated on changes in credit regulations and market conditions.",
                "Provide training and support to staff on credit management matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Credit Manager or in a similar role.",
                "Strong understanding of credit management principles and practices.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in financial software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of credit regulations and compliance issues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Credit Managers varies depending on factors such as experience, location, and the size of the organization. On average, Credit Managers earn between $70,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const SeniorAccountantJobDescription = {
    title: "Senior Accountant Job Description",
    jobRole: "A Senior Accountant is responsible for managing complex accounting tasks, preparing financial statements, and providing insights to support decision-making and strategic planning.",
    preRead: "Use this Senior Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Senior Accountant, you will manage complex accounting tasks, prepare financial statements, and provide insights to support decision-making and strategic planning. You will work closely with various departments to gather financial information and ensure compliance with accounting standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage complex accounting tasks and prepare financial statements.",
                "Provide insights to support decision-making and strategic planning.",
                "Monitor and analyze financial performance.",
                "Collaborate with various departments to gather financial information.",
                "Ensure compliance with accounting standards and regulations.",
                "Support budgeting and forecasting processes.",
                "Coordinate with external auditors and regulatory bodies.",
                "Stay updated on changes in accounting standards and regulations.",
                "Provide training and support to junior accountants."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA certification preferred.",
                "Proven experience as a Senior Accountant or in a similar role.",
                "Strong understanding of accounting principles and practices.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Senior Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Senior Accountants earn between $75,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const TaxPreparerJobDescription = {
    title: "Tax Preparer Job Description",
    jobRole: "A Tax Preparer is responsible for preparing and filing tax returns, ensuring compliance with tax laws and regulations, and providing tax planning and advisory services to clients.",
    preRead: "Use this Tax Preparer job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tax Preparer, you will prepare and file tax returns, ensure compliance with tax laws and regulations, and provide tax planning and advisory services to clients. You will work closely with clients to gather financial information and provide guidance on tax-related matters."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and file tax returns for individuals and businesses.",
                "Ensure compliance with tax laws and regulations.",
                "Provide tax planning and advisory services to clients.",
                "Gather financial information from clients and review financial documents.",
                "Identify potential tax deductions and credits.",
                "Stay updated on changes in tax laws and regulations.",
                "Communicate with tax authorities on behalf of clients.",
                "Provide training and support to junior tax preparers."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as a Tax Preparer or in a similar role.",
                "Strong understanding of tax laws and regulations.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in tax software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Knowledge of tax preparation procedures and practices."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Tax Preparers varies depending on factors such as experience, location, and the size of the organization. On average, Tax Preparers earn between $40,000 to $60,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const MortgageLoanOfficerJobDescription = {
    title: "Mortgage Loan Officer Job Description",
    jobRole: "A Mortgage Loan Officer assists clients in acquiring mortgage loans, evaluates financial information, and works with underwriters to ensure timely loan approvals.",
    preRead: "Use this Mortgage Loan Officer job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mortgage Loan Officer, you will assist clients in acquiring mortgage loans, evaluate financial information, and work with underwriters to ensure timely loan approvals. You will provide guidance on mortgage options and ensure compliance with banking regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist clients in acquiring mortgage loans and provide guidance on mortgage options.",
                "Evaluate financial information and assess loan eligibility.",
                "Work with underwriters to ensure timely loan approvals.",
                "Prepare and review mortgage applications and documentation.",
                "Communicate with clients throughout the loan process.",
                "Stay updated on changes in mortgage regulations and market conditions.",
                "Maintain client confidentiality and handle sensitive information."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Business, or related field.",
                "Proven experience as a Mortgage Loan Officer or in a similar role.",
                "Strong understanding of mortgage loan processes and regulations.",
                "Excellent analytical and decision-making skills.",
                "Proficiency in mortgage software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Mortgage Loan Officers varies depending on factors such as experience, location, and the size of the organization. On average, Mortgage Loan Officers earn between $45,000 to $75,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const BudgetManagerJobDescription = {
    title: "Budget Manager Job Description",
    jobRole: "A Budget Manager oversees the financial planning and budget management processes within an organization, ensuring efficient allocation of resources.",
    preRead: "Use this Budget Manager job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Budget Manager, you will oversee the financial planning and budget management processes within an organization, ensuring efficient allocation of resources. You will develop budgets, monitor expenditures, and prepare financial reports."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and manage the annual budgeting process.",
                "Monitor and analyze budget performance and variances.",
                "Prepare and present financial reports to senior management.",
                "Collaborate with department heads to develop budgetary forecasts.",
                "Ensure compliance with financial regulations and standards.",
                "Identify cost-saving opportunities and recommend improvements.",
                "Provide training and support to staff on budget management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Budget Manager or in a similar role.",
                "Strong understanding of budgeting and financial analysis.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in budget management software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Budget Managers varies depending on factors such as experience, location, and the size of the organization. On average, Budget Managers earn between $80,000 to $120,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountingSupervisorJobDescription = {
    title: "Accounting Supervisor Job Description",
    jobRole: "An Accounting Supervisor oversees the accounting department's daily operations, ensuring accuracy and compliance with financial regulations.",
    preRead: "Use this Accounting Supervisor job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounting Supervisor, you will oversee the accounting department's daily operations, ensuring accuracy and compliance with financial regulations. You will supervise accounting staff, manage financial reporting, and assist with audits."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Supervise and mentor accounting staff.",
                "Manage financial reporting and ensure accuracy.",
                "Assist with internal and external audits.",
                "Prepare financial statements and reports.",
                "Ensure compliance with financial regulations and standards.",
                "Develop and implement accounting policies and procedures.",
                "Review and approve journal entries and reconciliations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "Proven experience as an Accounting Supervisor or in a similar role.",
                "Strong understanding of accounting principles and practices.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in accounting software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounting Supervisors varies depending on factors such as experience, location, and the size of the organization. On average, Accounting Supervisors earn between $65,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const BillingAnalystJobDescription = {
    title: "Billing Analyst Job Description",
    jobRole: "A Billing Analyst is responsible for managing and analyzing billing processes, ensuring accurate invoicing, and resolving billing issues.",
    preRead: "Use this Billing Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Billing Analyst, you will manage and analyze billing processes, ensuring accurate invoicing, and resolving billing issues. You will work with clients and internal teams to ensure timely and accurate billing."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and analyze billing processes.",
                "Ensure accurate and timely invoicing.",
                "Resolve billing issues and discrepancies.",
                "Prepare billing reports and statements.",
                "Collaborate with clients and internal teams on billing matters.",
                "Maintain billing records and documentation.",
                "Identify and implement process improvements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, or related field.",
                "Proven experience as a Billing Analyst or in a similar role.",
                "Strong understanding of billing processes and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Proficiency in billing software and MS Office.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Billing Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Billing Analysts earn between $50,000 to $70,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const LoanProcessorJobDescription = {
    title: "Loan Processor Job Description",
    jobRole: "A Loan Processor is responsible for reviewing loan applications, verifying financial information, and ensuring compliance with lending regulations.",
    preRead: "Use this Loan Processor job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Loan Processor, you will review loan applications, verify financial information, and ensure compliance with lending regulations. You will work with loan officers and underwriters to facilitate the loan approval process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Review loan applications and supporting documentation.",
                "Verify financial information and conduct credit checks.",
                "Communicate with loan officers, underwriters, and clients throughout the loan process.",
                "Ensure compliance with lending regulations and company policies.",
                "Prepare loan files and documentation for underwriting review.",
                "Coordinate loan closings and ensure all requirements are met.",
                "Maintain accurate records and documentation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Bachelor’s degree preferred.",
                "Proven experience as a Loan Processor or in a similar role.",
                "Strong understanding of lending processes and regulations.",
                "Excellent attention to detail and accuracy.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in loan processing software and MS Office.",
                "Ability to work efficiently in a fast-paced environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Loan Processors varies depending on factors such as experience, location, and the size of the organization. On average, Loan Processors earn between $35,000 to $55,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const InvestmentAccountantJobDescription = {
    title: "Investment Accountant Job Description",
    jobRole: "An Investment Accountant is responsible for managing investment portfolios, analyzing financial data, and providing investment-related advice and recommendations.",
    preRead: "Use this Investment Accountant job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Investment Accountant, you will be responsible for managing investment portfolios, analyzing financial data, and providing investment-related advice and recommendations to clients or internal stakeholders. You will monitor market trends and investment performance to make informed decisions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage investment portfolios and assets.",
                "Analyze financial data and market trends.",
                "Prepare investment reports and presentations.",
                "Monitor investment performance and risks.",
                "Provide investment-related advice and recommendations.",
                "Collaborate with investment managers and financial advisors.",
                "Ensure compliance with investment regulations and company policies.",
                "Conduct research on investment opportunities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Finance, Accounting, Economics, or related field.",
                "Certified Public Accountant (CPA) or Chartered Financial Analyst (CFA) designation preferred.",
                "Proven experience as an Investment Accountant or in a similar role.",
                "Strong understanding of investment principles and financial markets.",
                "Excellent analytical and problem-solving skills.",
                "Strong communication and interpersonal skills.",
                "Proficiency in financial analysis software and MS Office.",
                "Ability to work effectively in a team environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Investment Accountants varies depending on factors such as experience, location, and the size of the organization. On average, Investment Accountants earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountingClerkJobDescription = {
    title: "Accounting Clerk Job Description",
    jobRole: "An Accounting Clerk is responsible for performing various accounting tasks, such as processing transactions, maintaining financial records, and assisting with payroll and billing functions.",
    preRead: "Use this Accounting Clerk job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounting Clerk, you will perform various accounting tasks to support the finance department. This may include processing transactions, maintaining financial records, and assisting with payroll and billing functions. You will ensure accuracy and compliance with accounting principles and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process accounts payable and accounts receivable transactions.",
                "Maintain financial records and databases.",
                "Assist with payroll processing and reconciliations.",
                "Prepare and distribute invoices and statements.",
                "Reconcile bank statements and other financial documents.",
                "Assist with budgeting and forecasting activities.",
                "Provide support for audits and tax filings.",
                "Generate financial reports and summaries."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Associate’s degree in Accounting or related field preferred.",
                "Proven experience as an Accounting Clerk or in a similar role.",
                "Knowledge of basic accounting principles and practices.",
                "Proficiency in accounting software and MS Office.",
                "Attention to detail and accuracy.",
                "Strong organizational and multitasking skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work effectively in a team environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounting Clerks varies depending on factors such as experience, location, and the size of the organization. On average, Accounting Clerks earn between $35,000 to $50,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const PayrollSpecialistJobDescription = {
    title: "Payroll Specialist Job Description",
    jobRole: "A Payroll Specialist is responsible for processing payroll, ensuring accurate and timely payments to employees, and maintaining payroll records in compliance with company policies and regulations.",
    preRead: "Use this Payroll Specialist job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Payroll Specialist, you will be responsible for processing payroll, ensuring accurate and timely payments to employees, and maintaining payroll records in compliance with company policies and regulations. You will work closely with the HR department and finance team to ensure payroll accuracy and compliance."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process payroll for employees accurately and timely.",
                "Calculate wages, deductions, and taxes.",
                "Ensure compliance with federal, state, and local payroll regulations.",
                "Maintain accurate payroll records and documentation.",
                "Respond to employee inquiries regarding payroll matters.",
                "Prepare payroll reports and summaries for management.",
                "Assist with payroll tax filings and audits.",
                "Stay updated on changes in payroll laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field preferred.",
                "Proven experience as a Payroll Specialist or in a similar role.",
                "Strong understanding of payroll processes and regulations.",
                "Proficiency in payroll software and MS Office.",
                "Excellent attention to detail and accuracy.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to maintain confidentiality and handle sensitive information."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Payroll Specialists varies depending on factors such as experience, location, and the size of the organization. On average, Payroll Specialists earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AuditAssociateJobDescription = {
    title: "Audit Associate Job Description",
    jobRole: "An Audit Associate is responsible for assisting in the planning and execution of audit engagements, reviewing financial statements, and identifying areas of risk and control weaknesses.",
    preRead: "Use this Audit Associate job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Audit Associate, you will assist in the planning and execution of audit engagements, review financial statements, and identify areas of risk and control weaknesses. You will work closely with audit teams and clients to ensure compliance with auditing standards and regulatory requirements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in planning and executing audit engagements.",
                "Review financial statements and supporting documents.",
                "Perform tests of controls and substantive audit procedures.",
                "Identify areas of risk and control weaknesses.",
                "Document audit findings and communicate results to management.",
                "Provide recommendations for improving internal controls and processes.",
                "Assist with the preparation of audit reports and presentations.",
                "Stay updated on changes in auditing standards and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, or related field.",
                "CPA, CIA, or similar professional certification preferred.",
                "Previous experience in auditing or accounting.",
                "Strong understanding of auditing standards and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Proficiency in audit software and MS Office.",
                "Ability to work effectively in a team environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Audit Associates varies depending on factors such as experience, location, and the size of the organization. On average, Audit Associates earn between $50,000 to $75,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CollectionsSpecialistJobDescription = {
    title: "Collections Specialist Job Description",
    jobRole: "A Collections Specialist is responsible for managing accounts receivable and collecting outstanding debts from customers or clients. They work to ensure timely payments and maintain positive relationships with customers while minimizing bad debt losses.",
    preRead: "Use this Collections Specialist job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Collections Specialist, you will be responsible for managing accounts receivable and collecting outstanding debts from customers or clients. You will work closely with customers to resolve payment issues, negotiate payment plans, and maintain accurate records of collections activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Contact customers to collect outstanding debts.",
                "Negotiate payment arrangements and settlements.",
                "Resolve payment disputes and discrepancies.",
                "Maintain accurate records of collections activities.",
                "Monitor accounts for overdue payments and delinquencies.",
                "Provide customer service and support to clients.",
                "Prepare reports on collections activities and account statuses.",
                "Collaborate with sales and customer service teams to address customer issues."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; degree in Finance or related field preferred.",
                "Proven experience as a Collections Specialist or similar role.",
                "Knowledge of collection laws and regulations.",
                "Strong negotiation and communication skills.",
                "Excellent customer service skills.",
                "Attention to detail and accuracy.",
                "Ability to work independently and as part of a team.",
                "Proficiency in MS Office and collection software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Collections Specialists varies depending on factors such as experience, location, and the size of the organization. On average, Collections Specialists earn between $35,000 to $55,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const FinancialPlannerJobDescription = {
    title: "Financial Planner Job Description",
    jobRole: "A Financial Planner is responsible for providing financial advice and creating personalized financial plans for individuals or businesses. They assess clients' financial situations, develop strategies to achieve financial goals, and provide ongoing support and guidance.",
    preRead: "Use this Financial Planner job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Financial Planner, you will work with clients to assess their financial situations, develop personalized financial plans, and provide ongoing support and guidance to help them achieve their financial goals. You will analyze clients' financial data, recommend strategies for saving and investing, and monitor progress towards financial objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess clients' financial situations, goals, and risk tolerance.",
                "Develop personalized financial plans based on clients' needs and objectives.",
                "Recommend investment strategies and financial products.",
                "Provide advice on retirement planning, estate planning, and tax planning.",
                "Monitor clients' financial progress and adjust plans as needed.",
                "Educate clients on financial matters and investment options.",
                "Help clients navigate life events and financial challenges.",
                "Stay updated on changes in financial markets and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Finance, Accounting, Economics, or related field; CFP certification preferred.",
                "Proven experience as a Financial Planner or similar role.",
                "Strong understanding of financial planning principles and practices.",
                "Excellent analytical and problem-solving skills.",
                "Strong communication and interpersonal skills.",
                "Ability to build and maintain client relationships.",
                "Proficiency in financial planning software and MS Office.",
                "Ethical and trustworthy behavior."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Financial Planners varies depending on factors such as experience, location, and the size of the organization. On average, Financial Planners earn between $60,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};


export const CostControllerJobDescription = {
    title: "Cost Controller Job Description",
    jobRole: "A Cost Controller is responsible for managing and controlling costs within an organization to optimize profitability and financial performance. They analyze financial data, monitor expenses, and develop strategies to reduce costs and improve efficiency.",
    preRead: "Use this Cost Controller job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cost Controller, you will be responsible for analyzing financial data, monitoring expenses, and developing strategies to reduce costs and improve efficiency. You will work closely with finance teams, department heads, and senior management to identify areas for cost savings and implement cost control measures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze financial data and identify cost-saving opportunities.",
                "Monitor expenses and variances from budgeted costs.",
                "Develop and implement cost control strategies and initiatives.",
                "Collaborate with department heads to set and monitor departmental budgets.",
                "Prepare regular cost reports and financial forecasts.",
                "Review and approve expenditures and purchase orders.",
                "Identify and resolve cost discrepancies and discrepancies.",
                "Ensure compliance with financial policies and procedures.",
                "Provide financial analysis and recommendations to senior management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Finance, Accounting, Economics, or related field.",
                "Proven experience as a Cost Controller or similar role.",
                "Strong understanding of cost accounting principles and practices.",
                "Proficiency in financial analysis and reporting.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Cost Controllers varies depending on factors such as experience, location, and the size of the organization. On average, Cost Controllers earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const RevenueAnalystJobDescription = {
    title: "Revenue Analyst Job Description",
    jobRole: "A Revenue Analyst is responsible for analyzing revenue data, identifying trends, and providing insights to improve revenue generation and profitability. They work closely with finance teams to develop revenue forecasts, monitor performance against targets, and recommend strategies to optimize revenue.",
    preRead: "Use this Revenue Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Revenue Analyst, you will be responsible for analyzing revenue data, identifying trends, and providing insights to improve revenue generation and profitability. You will work closely with finance teams to develop revenue forecasts, monitor performance against targets, and recommend strategies to optimize revenue."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze revenue data and identify trends and opportunities.",
                "Develop revenue forecasts and budgets.",
                "Monitor performance against revenue targets and goals.",
                "Identify factors impacting revenue performance and recommend corrective actions.",
                "Prepare and present revenue reports and analysis to management.",
                "Collaborate with sales, marketing, and product teams to optimize revenue generation strategies.",
                "Assist with revenue recognition and compliance with accounting standards.",
                "Participate in financial planning and budgeting processes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Finance, Accounting, Economics, or related field.",
                "Proven experience as a Revenue Analyst or similar role.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in financial analysis and reporting.",
                "Knowledge of revenue recognition principles and accounting standards.",
                "Excellent communication and presentation skills.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and accuracy."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Revenue Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Revenue Analysts earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const CreditControllerJobDescription = {
    title: "Credit Controller Job Description",
    jobRole: "A Credit Controller is responsible for managing credit and collections activities within an organization to ensure timely payment from customers and minimize credit risk. They monitor customer accounts, negotiate payment terms, and implement credit control procedures.",
    preRead: "Use this Credit Controller job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Credit Controller, you will be responsible for managing credit and collections activities within an organization to ensure timely payment from customers and minimize credit risk. You will monitor customer accounts, negotiate payment terms, and implement credit control procedures to optimize cash flow and reduce bad debt."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Monitor customer accounts and aging reports.",
                "Contact customers to collect outstanding payments.",
                "Negotiate payment terms and repayment plans with customers.",
                "Review credit applications and assess creditworthiness of customers.",
                "Perform credit checks and recommend credit limits.",
                "Investigate and resolve billing discrepancies or disputes.",
                "Maintain accurate records of collections activities.",
                "Prepare and present reports on collections performance to management.",
                "Collaborate with sales and customer service teams to address customer issues and concerns."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Finance, Accounting, Business Administration, or related field.",
                "Proven experience as a Credit Controller or similar role.",
                "Strong understanding of credit control principles and practices.",
                "Excellent negotiation and communication skills.",
                "Ability to prioritize and manage multiple tasks effectively.",
                "Attention to detail and accuracy.",
                "Proficiency in accounting software and MS Office.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Credit Controllers varies depending on factors such as experience, location, and the size of the organization. On average, Credit Controllers earn between $45,000 to $70,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const TaxAnalystJobDescription = {
    title: "Tax Analyst Job Description",
    jobRole: "A Tax Analyst is responsible for preparing and analyzing tax data and assisting with tax compliance and planning activities. They research tax laws and regulations, prepare tax returns, and provide support to tax audits and inquiries.",
    preRead: "Use this Tax Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tax Analyst, you will be responsible for preparing and analyzing tax data, assisting with tax compliance and planning activities, and providing support to tax audits and inquiries. You will research tax laws and regulations, prepare tax returns, and ensure compliance with tax requirements."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and analyze tax data for various tax compliance and planning activities.",
                "Research tax laws, regulations, and rulings to ensure compliance and identify tax-saving opportunities.",
                "Prepare and file tax returns for individuals, businesses, and other entities.",
                "Assist with tax audits and inquiries by gathering and analyzing relevant information.",
                "Review and reconcile tax accounts and resolve discrepancies.",
                "Provide support to finance and accounting teams on tax-related matters.",
                "Stay updated on changes in tax laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Accounting, Finance, Taxation, or related field.",
                "CPA, Enrolled Agent (EA), or similar professional certification preferred.",
                "2-5 years of experience in tax accounting or related field.",
                "Strong knowledge of tax laws, regulations, and procedures.",
                "Experience with tax preparation software and accounting systems.",
                "Excellent analytical and problem-solving skills.",
                "Strong attention to detail and accuracy.",
                "Effective communication and interpersonal skills.",
                "Ability to work independently and as part of a team."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Tax Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Tax Analysts earn between $55,000 to $80,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const AccountsPayableAnalystJobDescription = {
    title: "Accounts Payable Analyst Job Description",
    jobRole: "An Accounts Payable Analyst is responsible for processing invoices, verifying expenses, and reconciling accounts payable transactions. They ensure accurate and timely payments to vendors, maintain vendor records, and resolve payment discrepancies.",
    preRead: "Use this Accounts Payable Analyst job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Accounts Payable Analyst, you will be responsible for processing invoices, verifying expenses, and reconciling accounts payable transactions. You will ensure accurate and timely payments to vendors, maintain vendor records, and resolve payment discrepancies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process accounts payable invoices and expense reports.",
                "Verify and reconcile invoices, statements, and expenses.",
                "Ensure accuracy and completeness of accounts payable transactions.",
                "Prepare and review accounts payable reports and reconciliations.",
                "Maintain vendor records and communication.",
                "Resolve payment discrepancies and issues with vendors.",
                "Respond to inquiries from vendors and internal stakeholders.",
                "Assist with month-end and year-end closing processes.",
                "Stay updated on accounts payable policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Accounting, Finance, or related field.",
                "1-3 years of experience in accounts payable or related field.",
                "Knowledge of accounts payable principles, practices, and procedures.",
                "Experience with accounting software and ERP systems.",
                "Strong analytical and problem-solving skills.",
                "Attention to detail and accuracy.",
                "Excellent communication and interpersonal skills.",
                "Ability to prioritize tasks and meet deadlines.",
                "Proficiency in Microsoft Office, especially Excel."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Accounts Payable Analysts varies depending on factors such as experience, location, and the size of the organization. On average, Accounts Payable Analysts earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

export const PayrollCoordinatorJobDescription = {
    title: "Payroll Coordinator Job Description",
    jobRole: "A Payroll Coordinator is responsible for processing payroll, maintaining payroll records, and ensuring compliance with payroll laws and regulations. They calculate wages, deductions, and taxes, and address payroll-related inquiries and issues.",
    preRead: "Use this Payroll Coordinator job description template to advertise open roles for your company. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Payroll Coordinator, you will be responsible for processing payroll, maintaining payroll records, and ensuring compliance with payroll laws and regulations. You will calculate wages, deductions, and taxes, address payroll-related inquiries and issues, and provide support to the finance and HR teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process payroll for employees on a regular basis.",
                "Calculate wages, deductions, and taxes accurately.",
                "Maintain accurate payroll records and documentation.",
                "Ensure compliance with payroll laws, regulations, and company policies.",
                "Address payroll-related inquiries and issues from employees.",
                "Process employee changes such as new hires, terminations, and salary adjustments.",
                "Prepare and distribute payroll reports to management.",
                "Stay updated on changes in payroll laws and regulations.",
                "Provide support to the finance and HR teams on payroll-related matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Accounting, Finance, Business Administration, or related field.",
                "2-4 years of experience in payroll processing or related field.",
                "Knowledge of payroll laws, regulations, and best practices.",
                "Experience with payroll software and systems.",
                "Strong analytical and problem-solving skills.",
                "Excellent attention to detail and accuracy.",
                "Effective communication and interpersonal skills.",
                "Ability to prioritize tasks and meet deadlines.",
                "Proficiency in Microsoft Office, especially Excel."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Payroll Coordinators varies depending on factors such as experience, location, and the size of the organization. On average, Payroll Coordinators earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Flexible work schedule",
                "Employee wellness programs",
                "Employee assistance program (EAP)",
                "Tuition reimbursement",
                "Performance bonuses"
            ]
        }
    ]
};

