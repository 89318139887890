import { SvgIcon } from '@mui/material';
import React from 'react';

export const FacebookMcIcon = () => {
    return (<>
        <SvgIcon>
            <path d="M 24 12 C 24 18.628906 18.628906 24 12 24 C 5.371094 24 0 18.628906 0 12 C 0 5.371094 5.371094 0 12 0 C 18.628906 0 24 5.371094 24 12 Z M 24 12 " fill="#3c5a9a" />
            <path d="M 15.902344 3.683594 L 13.242188 3.683594 C 11.667969 3.683594 9.910156 4.347656 9.910156 6.632812 C 9.917969 7.429688 9.910156 8.195312 9.910156 9.050781 L 8.085938 9.050781 L 8.085938 11.957031 L 9.96875 11.957031 L 9.96875 20.316406 L 13.425781 20.316406 L 13.425781 11.902344 L 15.707031 11.902344 L 15.914062 9.042969 L 13.367188 9.042969 C 13.367188 9.042969 13.371094 7.773438 13.367188 7.402344 C 13.367188 6.5 14.304688 6.550781 14.363281 6.550781 C 14.808594 6.550781 15.679688 6.554688 15.902344 6.550781 Z M 15.902344 3.683594 " fill="#ffffff" />
        </SvgIcon>
    </>)
};