import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BorderColorDark, BorderColorLight, DarkModeBackground, MUIGrey, WhiteColor } from 'shared/SharedStyles/styleConstants';

export const SettingsDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        minWidth: '320px'
    },
    '& .MuiDialogTitle-root': {
        position: 'relative',
        textAlign: 'center',
        '& .close-btn': {
            position: 'absolute',
            right: theme.spacing(0.1),
            top: theme.spacing(0.1)
        }
    }
}));

export const SettingsWrapper = styled(Box)(
    ({ theme }) => ({
        flex: 1,
        display: 'flex',
        padding: 0,
        overflow: 'auto',
        height: '100%',
        flexDirection: 'column',
        maxWidth: '100%',
        backgroundColor: theme.palette.mode === 'light' ? WhiteColor : DarkModeBackground,
        '& .settings-tabs': {
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
            maxWidth: '100%',
            width: '100%',
            minHeight: '60px'
        },
        '& .settings-outlet': {
            overflow: 'auto',
            // Company Info
            '& .phone-num-field': {
                '& .MuiOutlinedInput-root': {
                    minHeight: '40px',
                    '& .phone-number': {
                        border: 'none',
                        outline: 'none !important',
                        padding: '8px 14px',
                        background: 'inherit',
                        color: 'inherit'
                    }
                }
            },
            '& .file-select-btn': {
                position: 'relative',
                whiteSpace: 'nowrap',
                '& input': {
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '100%',
                    opacity: 0
                }
            },
            // /Access levels
            '& table': {
                '& tr': {
                    '& .MuiInput-underline': {
                        border: 'none',
                        '&:before': {
                            border: 'none'
                        }
                    }
                }
            },
            '& .bullet-points': {
                paddingInlineStart: '20px',
                color: MUIGrey
            },
            '& .add-btn': {
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
            },
            // Login info
            '& .switch-n-label': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: theme.spacing(2),
                '& .label, .login-info-label': {
                    fontWeight: '500'
                },
                '& .login-info-label': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    minWidth: '125px',
                    marginRight: theme.spacing(1.5)
                },
                '& .value': {
                    flex: 1,
                    textAlign: 'left'
                }
            }
        },
        '& .elements-stack': {
            maxWidth: '1000px'
        }
    })
);