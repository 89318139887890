export const EMPLOYER_AI_TOOLS_TITLE_JOB_TITLE= "Job Description Generator - Powered by AI";

// Employer Interview Kit
export const EMPLOYER_AI_TOOLS_TITLE_INTERVIEW_KIT = "AI-Powered Interview Kit with Questions & Answers";
export const EMPLOYER_AI_TOOLS_DETAILS_JOB_DESCRIPTION = "Prepare for your upcoming interviews by generating a set of interview questions";


export const tabData = [
    { value: '1', label: 'General Offer Template' },
    { value: '2', label: 'Formal Offer Template' },
    { value: '3', label: 'Internal Offer Template' },
    { value: '4', label: 'Informal Offer Template' },
    { value: '5', label: 'Sales Offer Template' },
];

export const tabDataRejection = [
    { value: '1', label: 'Formal Rejection Template' },
    { value: '2', label: 'Informal Rejection Template' },
    { value: '3', label: 'Auto Rejection Template' },
]
