interface State {
    id: number;
    country_id: number;
    name_en: string;
    abbreviation: string;
}

export const jobStatesByCountryFallBack: State[] = [
    // US Fallback
    { id: 97, country_id: 233, name_en: 'Alabama', abbreviation: 'AL' },
    { id: 11, country_id: 233, name_en: 'California', abbreviation: 'CA' },
    { id: 70, country_id: 233, name_en: 'Colorado', abbreviation: 'CO' },
    { id: 81, country_id: 233, name_en: 'Connecticut', abbreviation: 'CT' },
    { id: 98, country_id: 233, name_en: 'District of Columbia', abbreviation: 'DC' },
    { id: 131, country_id: 233, name_en: 'Florida', abbreviation: 'FL' },
    { id: 142, country_id: 233, name_en: 'Georgia', abbreviation: 'GA' },
    { id: 3, country_id: 233, name_en: 'Washington', abbreviation: 'WA' },
    { id: 4, country_id: 233, name_en: 'West Virginia', abbreviation: 'WV' },
    { id: 6, country_id: 233, name_en: 'Wisconsin', abbreviation: 'WI' },
    { id: 178, country_id: 233, name_en: 'Alaska', abbreviation: 'AK' },
    { id: 175, country_id: 233, name_en: 'Idaho', abbreviation: 'ID' },
    { id: 179, country_id: 233, name_en: 'Illinois', abbreviation: 'IL' },
    { id: 190, country_id: 233, name_en: 'Indiana', abbreviation: 'IN' },
    { id: 195, country_id: 233, name_en: 'Iowa', abbreviation: 'IA' },
    { id: 216, country_id: 233, name_en: 'Kansas', abbreviation: 'KS' },
    { id: 227, country_id: 233, name_en: 'Kentucky', abbreviation: 'KY' },
    { id: 249, country_id: 233, name_en: 'Maine', abbreviation: 'ME' },
    { id: 271, country_id: 233, name_en: 'Maryland', abbreviation: 'MD' },
    { id: 283, country_id: 233, name_en: 'Massachusetts', abbreviation: 'MA' },
    { id: 305, country_id: 233, name_en: 'Michigan', abbreviation: 'MI' },
    { id: 327, country_id: 233, name_en: 'Minnesota', abbreviation: 'MN' },
    { id: 383, country_id: 233, name_en: 'Arizona', abbreviation: 'AZ' },
    { id: 452, country_id: 233, name_en: 'Arkansas', abbreviation: 'AR' },
    { id: 92, country_id: 233, name_en: 'Delaware', abbreviation: 'DE' },
    { id: 449, country_id: 233, name_en: 'Federated States of Micronesia', abbreviation: 'FM' },
    { id: 173, country_id: 233, name_en: 'Hawaii', abbreviation: 'HI' },
    { id: 238, country_id: 233, name_en: 'Louisiana', abbreviation: 'LA' },
    { id: 338, country_id: 233, name_en: 'Mississippi', abbreviation: 'MS' },
    { id: 346, country_id: 233, name_en: 'Missouri', abbreviation: 'MO' },
    { id: 352, country_id: 233, name_en: 'Montana', abbreviation: 'MT' },
    { id: 384, country_id: 233, name_en: 'Nebraska', abbreviation: 'NE' },
    { id: 395, country_id: 233, name_en: 'Nevada', abbreviation: 'NV' },
    { id: 414, country_id: 233, name_en: 'New Hampshire', abbreviation: 'NH' },
    { id: 415, country_id: 233, name_en: 'New Jersey', abbreviation: 'NJ' },
    { id: 419, country_id: 233, name_en: 'New Mexico', abbreviation: 'NM' },
    { id: 447, country_id: 233, name_en: 'Armed Forces EU. ME. and CAN', abbreviation: 'AE' },
    { id: 448, country_id: 233, name_en: 'Armed Forces Pacific', abbreviation: 'AP' },
    { id: 441, country_id: 233, name_en: 'New York', abbreviation: 'NY' },
    { id: 451, country_id: 233, name_en: 'North Carolina', abbreviation: 'NC' },
    { id: 453, country_id: 233, name_en: 'North Dakota', abbreviation: 'ND' },
    { id: 459, country_id: 233, name_en: 'Ohio', abbreviation: 'OH' },
    { id: 460, country_id: 233, name_en: 'Oklahoma', abbreviation: 'OK' },
    { id: 463, country_id: 233, name_en: 'Oregon', abbreviation: 'OR' },
    { id: 464, country_id: 233, name_en: 'Pennsylvania', abbreviation: 'PA' },
    { id: 467, country_id: 233, name_en: 'Puerto Rico', abbreviation: 'PR' },
    { id: 473, country_id: 233, name_en: 'Rhode Island', abbreviation: 'RI' },
    { id: 479, country_id: 233, name_en: 'South Carolina', abbreviation: 'SC' },
    { id: 480, country_id: 233, name_en: 'South Dakota', abbreviation: 'SD' },
    { id: 485, country_id: 233, name_en: 'Tennessee', abbreviation: 'TN' },
    { id: 486, country_id: 233, name_en: 'Texas', abbreviation: 'TX' },
    { id: 488, country_id: 233, name_en: 'Utah', abbreviation: 'UT' },
    { id: 492, country_id: 233, name_en: 'Virgin Islands', abbreviation: 'VI' },
    { id: 493, country_id: 233, name_en: 'Virginia', abbreviation: 'VA' },
    { id: 490, country_id: 233, name_en: 'Vermont', abbreviation: 'VT' },
    { id: 7, country_id: 233, name_en: 'Wyoming', abbreviation: 'WY' },
    { id: 446, country_id: 233, name_en: 'Armed Forces Americas', abbreviation: 'AA' },
    // CA Fallback
    { id: 9, country_id: 230, name_en: 'Yukon Territory', abbreviation: 'YT' },
    { id: 406, country_id: 230, name_en: 'New Brunswick', abbreviation: 'NB' },
    { id: 450, country_id: 230, name_en: 'Newfoundland', abbreviation: 'NL' },
    { id: 455, country_id: 230, name_en: 'Northwest Territories', abbreviation: 'NT' },
    { id: 359, country_id: 230, name_en: 'Nunavut', abbreviation: 'NU' },
    { id: 462, country_id: 230, name_en: 'Ontario', abbreviation: 'ON' },
    { id: 468, country_id: 230, name_en: 'Quebec', abbreviation: 'QC' },
    { id: 475, country_id: 230, name_en: 'Saskatchewan', abbreviation: 'SK' },
    { id: 282, country_id: 230, name_en: 'Alberta', abbreviation: 'AB' },
    { id: 456, country_id: 230, name_en: 'Nova Scotia', abbreviation: 'NS' },
    { id: 2, country_id: 230, name_en: 'British Columbia', abbreviation: 'BC' },
    { id: 260, country_id: 230, name_en: 'Manitoba', abbreviation: 'MB' },
    { id: 465, country_id: 230, name_en: 'Prince Edward Island', abbreviation: 'PE' },
];

export default jobStatesByCountryFallBack;
