import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { FAQAccordion } from 'Modules/Core/FAQ/FAQ.styled';
import parse from 'html-react-parser';
import { useState } from 'react';
import { FrequentlyAskedQuestions, HtmlRegex } from 'shared/constants';

export const FAQ = () => {
    const [qsExpanded, setQsExpanded] = useState<string | false>(false);

    return (<>
        {FrequentlyAskedQuestions?.map((faq, indx) => (
            <FAQAccordion key={indx} elevation={0} expanded={qsExpanded === `panel${indx}`}
                onChange={(e, isExpanded) => setQsExpanded(isExpanded ? `panel${indx}` : false)}>
                <AccordionSummary expandIcon={qsExpanded === `panel${indx}` ? <RemoveIcon /> : <AddIcon />} aria-controls={`panel${indx}a-content`}>
                    <Typography variant='subtitle2' fontWeight={600}>{faq.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {HtmlRegex.test(faq.value) ? parse(faq.value) :
                        <Typography variant='body2' color={'gray'} whiteSpace='break-spaces'>
                            {faq.value}
                        </Typography>}
                </AccordionDetails>
            </FAQAccordion>
        ))}
    </>);
};