import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack, Tab } from "@mui/material";
import { ChangePassword } from 'Modules/Core/SettingsTs/Security/ChangePassword';
import { LoginInfo } from "Modules/Core/SettingsTs/Security/LoginInfo";
import TwoStepAuth from "Modules/Core/SettingsTs/Security/TwoStepAuth";
import { useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { TabPanelProps } from 'shared/SharedModels';
import { ShTabs } from 'shared/SharedStyles/ShNavigation';
import { TSecurityUrlFragments } from '../SettingsModel';

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index } = props;
    return (
        <Box role='tabpanel' minHeight={300} hidden={value !== index}>{value === index && (<>{children}</>)}</Box>
    );
}

export const Security = () => {

    const hashInSecurityUrl = useLocation().hash.substring(1) as TSecurityUrlFragments;
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState<number>(0);
    const { twoStepAuth } = useAppSelector((state) => state.auth.auth);

    // Set tab value according to the url fragment if any.
    useEffect(() => {
        if (hashInSecurityUrl) {
            switch (hashInSecurityUrl) {
                case 'two-step':
                    setTabValue(1);
                    break;
                case 'login-info':
                    setTabValue(2);
                    break;
                case 'password-change':
                    setTabValue(0);
                    break;

                default:
                    break;
            }
        } else {
            navigate('/employer/settings/security#password-change');
        }
    }, [hashInSecurityUrl, navigate]);

    const setSecurityTabValue = (newValue: number) => {
        setTabValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/employer/settings/security#password-change');
                break;
            case 1:
                navigate('/employer/settings/security#two-step');
                break;
            case 2:
                navigate('/employer/settings/security#login-info');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Box maxWidth='600px'>
                <ShTabs aria-label="Security tabs" variant="scrollable" scrollButtons='auto'
                    allowScrollButtonsMobile value={tabValue} onChange={(evt, newValue) => setSecurityTabValue(newValue)}>
                    <Tab label='Change Password' />
                    <Tab label={<Stack flexDirection='row' alignItems='center'>
                        Two Step Verification {(twoStepAuth?.emailVerification || twoStepAuth?.securityVerification) &&
                            <CheckIcon className='check-icon' color='success' fontSize='small' />}
                    </Stack>} />
                    <Tab label='Last Login Info' />
                </ShTabs>
                <TabPanel value={tabValue} index={0}>
                    <ChangePassword />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <TwoStepAuth />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <LoginInfo />
                </TabPanel>
            </Box>
        </>
    );
}
