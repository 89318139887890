import { Alert, Box, Paper, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setAppContext } from "store/slices/auth-v2/auth-v2-slice";
import { resetGetSurveyQuestions } from 'store/slices/candidate/survey/survey-slice';

export const SurveyWrapper = () => {
    const dispatch = useAppDispatch();
    const { isAccountLoggedIn, isRememberMe, email } = useAppSelector((state) => state.auth.auth);
    const navigate = useNavigate();
    const { getSurveyQuestionsStatus, getSurveyQuestionsResponse } = useAppSelector((state) => state.candidate.survey);
    const currentPath = useLocation().pathname.split('/').pop() as 'login' | 'new' | 'sign-up';

    // set app context
    useEffect(() => {
        dispatch(setAppContext({ currentAppContext: 'candidate_survey' }));
    }, [dispatch]);

    // Redirect to login if candidate selected Remember me while signing up
    useEffect(() => {
        if (!isAccountLoggedIn && isRememberMe && currentPath !== 'sign-up') {
            navigate("/candidate/login", { state: { email: email } })
        }
    }, [currentPath, email, isAccountLoggedIn, isRememberMe, navigate]);

    return (<>
        <Stack height='100%' width='100%' justifyContent='center' alignItems='center' padding={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
            <Paper variant='elevation' elevation={24}>
                <Box paddingX={{ xs: 2, sm: 2, md: 3, lg: 3 }} paddingY={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                    {getSurveyQuestionsStatus === 'failed' &&
                        <Alert severity='error' onClose={() => dispatch(resetGetSurveyQuestions())}>
                            {getSurveyQuestionsResponse}</Alert>}
                    <Box marginTop={1} minWidth={300} minHeight={300}>
                        <Outlet />
                    </Box>
                </Box>
            </Paper>
        </Stack>
    </ >);
};
