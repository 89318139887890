import { Stack, Typography } from "@mui/material";
import { useAppSelector } from 'helpers/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { RoleAccessControlMap } from "../AuthV2/AuthConstants";
import { TemplateJobFeatureTiles } from './CreateJobConstants';

export const TemplateJobSelect = () => {

    const { accountAccess } = useAppSelector((state) => state.auth.auth);

    return (<>
        <Stack rowGap={{ xs: 2, sm: 2, md: 3, lg: 4 }} alignItems='center'>
            <Typography variant="h5" textAlign='center'>What type of Job you want to create?</Typography>
            <Stack direction='row' flexWrap='wrap' columnGap={{ xs: 2, sm: 2, md: 3, lg: 4 }}
                rowGap={{ xs: 2, sm: 2, md: 3, lg: 4 }} justifyContent='center'>
                {TemplateJobFeatureTiles?.map((tile) => (
                    RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                        ?.[tile.featureName]?.visibility !== 'hidden' &&
                    <ShPaper key={tile?.featureRedirectUrl} noPadding transElevateOnHover>
                        <ShMuiLink noUnderline component={RouterLink} to={tile.featureRedirectUrl}
                            minWidth={275} maxWidth={300} display='flex' alignItems='center'
                            columnGap={1} noBlueHighlight padding={2} width='100%'>
                            {tile.tileIcon}
                            <Stack>
                                <Typography variant="body1" fontWeight="bold">
                                    {tile.tileLabel}
                                </Typography>
                                {tile.caption &&
                                    <Typography variant="caption" color='gray'>
                                        {tile.caption}
                                    </Typography>}
                            </Stack>
                        </ShMuiLink>
                    </ShPaper>
                ))}
            </Stack>
        </Stack>
    </>);
}