import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Paper, Skeleton, Stack, ToggleButton, Typography } from "@mui/material";
import { DefaultState, JobDescriptionTypes, JobPositionsTypes, RequirementCategoryMap } from 'Modules/Core/CreateJob/CreateJobConstants';
import { IPreviewJobDetails, JobDescriptionType } from "Modules/Core/CreateJob/CreateJobModel";
import { useNotification } from 'Modules/Core/Notification';
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import parse from 'html-react-parser';
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ShReactQuill } from 'shared/SharedComponents/ShReactQuill/ShReactQuill';
import { ApiState } from "shared/SharedModels";
import { UnderlinedText } from 'shared/SharedStyles/ShDataDisplay';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PaperVariant } from 'shared/SharedStyles/styleConstants';
import { DefaultAPIErrorMsg } from "shared/constants";
import { getAiJobDescription } from "store/slices/employer/create-job/create-job-preview-slice";
import {
    getRequirements, resetCreateOrUpdateJob, resetGetRequirements, setIsBundlesEnabled,
    updateDescriptionInAllJobDetails, updateJob, updateTemplateJob
} from "store/slices/employer/create-job/create-job-shared-slices";
import { getCompanyInfo, resetUpdateCompany } from 'store/slices/employer/settingsTs/company-info-slice';

export const JobPreview = () => {

    const { allJobDetails, createOrUpdateJobResponse, createOrUpdateJobStatus, jobRequirements,
        requirementsMetaData, currentJobCreationFlow } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { employerDetails } = useAppSelector((state) => state.employer.employerDetails);
    const { aiJobDescription, getAiJobDescResponse,
        getAiJobDescStatus } = useAppSelector((state) => state.employer.createJob.createJobPreview);
    const { jobStatesByCountry } = useAppSelector((state) => state.metadata.jobsMeta);
    const { companyInfo, updateCompanyInfoApiStatus } = useAppSelector((state) => state.employer.employerSettings.companyInfo);
    const isXsScreen = IsXsScreen();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { jobId } = useParams();
    const notification = useNotification();
    const isRouteEnabled = useOutletContext();
    const [previewDetails, setPreviewDetails] = useState<IPreviewJobDetails>({
        description: '',
        custom_description: false,
        is_complete: true
    });
    const [jobDescType, setJobDescType] = useState<JobDescriptionType | undefined>(undefined);
    const [jobDescriptionEditorValue, setJobDescriptionEditorValue] = useState<string>('');
    const [isBackClicked, setIsBackClicked] = useState<boolean>(false);

    // Set description from 'allJobDetails'.
    useEffect(() => {
        if (allJobDetails) {
            setPreviewDetails((prev) => {
                const returnObj = { ...prev };
                // Set boolean flags based on 'allJobDetails'.
                if (allJobDetails.description) {
                    returnObj.description = allJobDetails.description;
                }
                if (allJobDetails.ai_job_description) {
                    returnObj.ai_job_description = allJobDetails.ai_job_description;
                    returnObj.ai_generated_description = allJobDetails.description;
                }
                if (allJobDetails.custom_description) {
                    returnObj.custom_description = allJobDetails.custom_description;
                    returnObj.description = allJobDetails.description;
                }
                return returnObj;
            });
            if (allJobDetails.custom_description) {
                setJobDescType('custom');
            } else if (allJobDetails.ai_job_description) {
                setJobDescType('ai');
            } else {
                setJobDescType('sh');
            }
        }
    }, [allJobDetails]);

    // update job description editor value on preview description value updates.
    useEffect(() => {
        setJobDescriptionEditorValue(previewDetails?.description ?? '');
    }, [previewDetails?.description]);

    // Get job requirements to build SmoothHiring generated job description if new Job.
    useEffect(() => {
        if (isRouteEnabled && jobId && jobRequirements?.length === 0) {
            dispatch(getRequirements({ jobId: jobId }));
        }
    }, [dispatch, isRouteEnabled, jobId, jobRequirements?.length]);

    const generateSmoothHiringDescription = useCallback(() => {
        if (allJobDetails) {
            const { benefits, salary_details, positiontype_id } = allJobDetails;
            let shGenJD = '';

            // About Us section for company
            if (companyInfo.jobs?.companydescription) {
                shGenJD += '<p style="margin: 0;"><strong>About Us</strong></p>';
                shGenJD += `<p style="margin: 0;">${(companyInfo.jobs?.companydescription ?? 'None')}</p>`;
                shGenJD += '<p style="margin: 0;"><br></p>';
            }

            // Get categories information from metadata to fill in the headings
            requirementsMetaData?.categories.forEach(ct => {
                shGenJD += `<p style="margin: 0;"><strong>${RequirementCategoryMap[ct.name]}</strong></p>`;
                shGenJD += `<ul style="margin: 0; padding-left: 20px;">`;

                // Filter category from saved requirement and append to string with bullet characters
                const jobReqByCat = jobRequirements.filter(jr => jr.requirement_category_id === ct.id);
                jobReqByCat.forEach((jr) => {
                    shGenJD += `<li style="margin: 0; padding: 0;"><span style="font-size: 14px;">${jr.name}</span></li>`;
                });
                shGenJD += '</ul>';
                shGenJD += '<p style="margin: 0;"><br></p>';
            });

            if (salary_details) {
                shGenJD += '<p style="margin: 0;"><strong>Compensation</strong></p>';
                shGenJD += `<p style="margin: 0;">${salary_details}</p>`;
                shGenJD += '<p style="margin: 0;"><br></p>';
            }

            if (positiontype_id) {
                shGenJD += '<p style="margin: 0;"><strong>Position Type</strong></p>';
                shGenJD += `<p style="margin: 0;">${JobPositionsTypes.find(position=>position.id ===positiontype_id)?.label }</p>`;
                shGenJD += '<p style="margin: 0;"><br></p>';
            }

            if (benefits) {
                shGenJD += '<p style="margin: 0;"><strong>Benefits</strong></p>';
                shGenJD += `<p style="margin: 0;">${(benefits ?? 'None')}</p>`;
            }

            setPreviewDetails((prev) => { return { ...prev, description: shGenJD } });
            setJobDescriptionEditorValue(shGenJD ?? '');
        }
    }, [allJobDetails, companyInfo.jobs?.companydescription, jobRequirements, requirementsMetaData?.categories]);


    // Generate SmoothHiring generated job description once requirements are fetched.
    useEffect(() => {
        if (jobRequirements?.length > 0 && allJobDetails && jobDescType === 'sh') {
            generateSmoothHiringDescription();
        }
    }, [allJobDetails, generateSmoothHiringDescription, jobDescType, jobRequirements?.length]);

    // Change Job description type function gets called when Job description type is changed from drop down.
    const changeJobDescType = (jobDescType: JobDescriptionType) => {
        setJobDescType(jobDescType);
        // Update booleans in api payload based on selection.
        switch (jobDescType) {
            case 'sh':
                setPreviewDetails((prev) => { return { ...prev, custom_description: false, ai_job_description: false } });
                generateSmoothHiringDescription();
                break;
            case 'custom':
                setPreviewDetails((prev) => { return { ...prev, custom_description: true, ai_job_description: false } });
                if (allJobDetails?.description) {
                    setPreviewDetails((prev) => { return { ...prev, description: allJobDetails?.description } });
                    setJobDescriptionEditorValue(allJobDetails?.description ?? '');
                }
                break;
            case 'ai':
                setPreviewDetails((prev) => { return { ...prev, custom_description: false, ai_job_description: true } });
                if (!aiJobDescription && !previewDetails.ai_job_description) {
                    dispatch(getAiJobDescription({ jobId: parseInt(jobId ?? '') }));
                }
                break;
            default:
                break;
        }
    };

    const updateJobPreviewInAllJobDetails = useCallback(() => {
        // temporarily sending title id from ui, shall be removed once discussed about api side.
        const preview = { job_title_id: allJobDetails?.job_title_id, ...previewDetails };
        if (preview.ai_job_description) {
            if (preview.ai_generated_description) {
                preview.description = preview.ai_generated_description
            }
        } else if (preview.custom_description) {
            preview.description = jobDescriptionEditorValue;
        }
        if (preview.description) {
            //Update generated job description in 'allJobDetails'
            dispatch(updateDescriptionInAllJobDetails({
                description: preview.description,
                ai_job_description: preview.ai_job_description,
                custom_description: preview.custom_description
            }));
        }
        return preview;
    }, [allJobDetails?.job_title_id, dispatch, jobDescriptionEditorValue, previewDetails]);

    useEffect(() => {
        if (createOrUpdateJobStatus === 'success') {
            if (currentJobCreationFlow === 'templateJob') {
                notification.displayNotification({
                    open: true,
                    type: 'success',
                    message: createOrUpdateJobResponse
                });
                navigate(`/employer/jobs/template`);
            } else {
                dispatch(setIsBundlesEnabled(allJobDetails?.workflow_state === 'active' ? false : true));

                // navigate to active jobs once edited, workflow_state === 'active' means job is already active
                if (allJobDetails?.workflow_state === 'active') {
                    notification.displayNotification({
                        open: true,
                        type: 'success',
                        message: createOrUpdateJobResponse
                    });
                    if (isBackClicked) {
                        navigate(`/employer/job/${jobId}/details`);
                    } else {
                        navigate(`/employer/jobs/active`);
                    }
                } else {
                    updateJobPreviewInAllJobDetails();
                    if (isBackClicked) {
                        navigate(`/employer/job/${jobId}/details`);
                    } else {
                        navigate(`/employer/job/${jobId}/bundles`, { preventScrollReset: false });
                    }
                }
            }
        }
    }, [allJobDetails?.workflow_state, createOrUpdateJobResponse, createOrUpdateJobStatus, currentJobCreationFlow,
        dispatch, isBackClicked, jobId, navigate, notification, updateJobPreviewInAllJobDetails]);

    const goToBundles = () => {
        const preview = updateJobPreviewInAllJobDetails();
        if (currentJobCreationFlow === 'templateJob') {
            dispatch(updateTemplateJob({ jobId: parseInt(jobId ?? ''), payload: preview }));
        } else {
            dispatch(updateJob({ jobId: parseInt(jobId ?? ''), payload: preview }));
        }
    };

    const showAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            case 'success':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="success" onClose={() => dispatch(resetCreateOrUpdateJob())}>
                            <Typography fontWeight={600}>{msg}</Typography>
                        </ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    useEffect(() => {
        if (getAiJobDescStatus === 'success') {
            setPreviewDetails((prev) => { return { ...prev, ai_generated_description: aiJobDescription } });
        }
    }, [aiJobDescription, getAiJobDescStatus]);

    const AiJobDescriptionBlock = () => {
        return (<>
            <Stack justifyContent='center' rowGap={1}>
                {getAiJobDescStatus !== 'pending' && (previewDetails?.ai_generated_description &&
                    previewDetails.ai_generated_description.length > 0) && <ShGreenBtn aria-label='Generate again' endIcon={<RefreshIcon />}
                        onClick={() => dispatch(getAiJobDescription({ jobId: parseInt(jobId ?? '') }))}>Generate again</ShGreenBtn>}
                {getAiJobDescStatus === 'failed' && <ShAlert severity='error'>{getAiJobDescResponse}</ShAlert>}
                {getAiJobDescStatus === 'pending' ? <>
                    <Typography variant="subtitle2">Generating AI Job description...</Typography>
                    <Typography variant='h4'><Skeleton animation='wave' /></Typography>
                    <Typography variant='body2'><Skeleton animation='wave' /></Typography>
                    <Typography variant='h5'><Skeleton animation='wave' /></Typography>
                    <Typography variant='body2'><Skeleton animation='wave' /></Typography>
                    <Typography variant='body2'><Skeleton animation='wave' /></Typography>
                    <Typography variant='body2'><Skeleton animation='wave' /></Typography>
                </> :
                    <Box component='textarea' className="job-desc-txt-area" value={previewDetails.ai_generated_description}
                        onChange={(e: { target: { value: any; }; }) => setPreviewDetails({ ...previewDetails, ai_generated_description: e.target.value })}>
                    </Box>}
            </Stack>
        </>);
    };

    // Reset all states on unmount.
    useEffect(() => {
        return () => {
            dispatch(resetGetRequirements());
            dispatch(resetCreateOrUpdateJob());
        }
    }, [dispatch]);

    const onJobPreviewBack = () => {
        setIsBackClicked(true);
        goToBundles();
    }

    useEffect(() => {
        if (!companyInfo.id || updateCompanyInfoApiStatus === 'success') {
            dispatch(getCompanyInfo());
            dispatch(resetUpdateCompany());
        }
    }, [dispatch, companyInfo.id, updateCompanyInfoApiStatus]);

    return (<>
        <Stack className='elements-stack' rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} paddingX={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            paddingTop={'10px !important'} minHeight='350px' justifyContent='center'>
            {showAlerts(createOrUpdateJobStatus, createOrUpdateJobResponse)}
            <Typography variant="body2">
                Congratulations, you're steps away from better hiring.
                You've created the following job description based on industry best practices and thousands of other employers
                hiring for similar positions. Please review it before we post it to the world's largest job boards and feel free
                to make any edits if you'd like to change any of the wording.
            </Typography>
            <Paper variant={PaperVariant} className="preview-paper-main">
                <Typography variant="subtitle2">Job Title</Typography>
                <Typography variant="body2" fontWeight={600} mb={2}>{allJobDetails?.name}</Typography>
                <Typography variant="subtitle2">Company</Typography>
                <Typography variant="body2" fontWeight={600} mb={2}>{employerDetails?.company_name}</Typography>
                {currentJobCreationFlow !== 'templateJob' &&
                    <>
                        <Typography variant="subtitle2">Location</Typography>
                        <Typography variant="body2" fontWeight={600} mb={2}>
                            {`${allJobDetails?.remote_job_type ?? ''}${allJobDetails?.remote_job_type !== 'Fully Remote'
                                ? ` (${allJobDetails?.city}, ${jobStatesByCountry.find(s => s.id === allJobDetails?.province_id)?.name_en ?? DefaultState})`
                                : employerDetails?.address.address1
                                    ? ` (${employerDetails.address.address1}, ${jobStatesByCountry.find(s => s.id === allJobDetails?.province_id)?.name_en ?? DefaultState})`
                                    : ''
                                }`}
                        </Typography>
                    </>}
                <Stack direction='row' columnGap={2} alignItems='center' marginBottom={1} flexWrap='wrap'>
                    <UnderlinedText variant="body1" gutterBottom>Job Description</UnderlinedText>
                    <ShToggleButtonGroup color="primary" size="small" exclusive variant='pill' buttonsGap={isXsScreen ? 1 : 1.5}
                        minWidth='100px' value={jobDescType} orientation={isXsScreen ? 'vertical' : 'horizontal'}>
                        {JobDescriptionTypes.map(jdt => (
                            <ToggleButton key={jdt.value} value={jdt.value} size='small'
                                onClick={(e) => changeJobDescType(jdt.value)}>
                                <Stack direction='row' alignItems='center' columnGap={1}>
                                    {jdt.icon}
                                    <Typography variant='body2'>{jdt.label}</Typography>
                                </Stack>
                            </ToggleButton>
                        ))}
                    </ShToggleButtonGroup>
                </Stack>
                {
                    previewDetails.custom_description ?
                        <>
                            <ShReactQuill quillEditorValue={jobDescriptionEditorValue}
                                setQuillEditorValue={setJobDescriptionEditorValue} isDisabled={!previewDetails.custom_description} />
                        </> :
                        previewDetails.ai_job_description ?
                            AiJobDescriptionBlock()
                            : <ShPaper variant='outlined'>{parse(previewDetails.description ?? '',)}</ShPaper>}
            </Paper>
            <Stack flexDirection='row' justifyContent='space-between' paddingY={1} width='100%'>
                <ShButton variant='contained' size='small' disableElevation onClick={onJobPreviewBack}>Back</ShButton>
                <ShGreenBtn variant='contained' disableElevation onClick={goToBundles}
                    disabled={createOrUpdateJobStatus === 'pending' || getAiJobDescStatus === 'pending'}>
                    {createOrUpdateJobStatus === 'pending' ? 'Saving...' : 'Done'}
                </ShGreenBtn>
            </Stack>
        </Stack>
    </>)
};