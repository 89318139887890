import ContactMailIcon from '@mui/icons-material/ContactMail';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PeopleIcon from '@mui/icons-material/People';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ShareIcon from '@mui/icons-material/Share';
import StartIcon from '@mui/icons-material/Start';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tooltip } from '@mui/material';
import { InviteApplicants } from 'Modules/Core/Applicants/InviteApplicants';
import { DefaultRoleForRAC, RoleAccessControlMap } from 'Modules/Core/AuthV2/AuthConstants';
import { JobDescriptionDialog } from 'Modules/Core/JobsTs/JobDescriptionDialog';
import { DeleteJobConfirmationLabels, EditJobConfirmationLabels, StopHiringConfirmationLabels } from 'Modules/Core/JobsTs/JobsConstants';
import { IJobActionsMenu } from 'Modules/Core/JobsTs/JobsModel';
import { UseTemplateJobIcon } from 'assets/Icons';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { ConfirmationDialogFor, IConfirmationDialogLabels } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialogModel';
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from 'store/slices/app/breadcrumb-slice';
import { setCurrentJobCreationFlow } from 'store/slices/employer/create-job/create-job-shared-slices';
import { setRePostJobId } from 'store/slices/employer/create-job/job-title-slice';
import { deleteJobById, rePostJob, stopHireById } from 'store/slices/employer/jobs/jobs-list-actions-slice';
import { getJobDescription } from 'store/slices/employer/jobs/jobs-list-slice';
import JobShareDialog from '../JobShareDialog';

export const JobActions = ({ job, anchorEl, openMoreMenu, setMoreOptionsAnchorEl, jobsType, actionMenuType }: IJobActionsMenu) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { rePostJobStatus, copiedJobId, prevJobId } = useAppSelector(state => state.employer.employerJobs.jobsListActions);
    const { accountAccess } = useAppSelector(state => state.auth.auth);
    const { paginationParams } = useAppSelector(state => state.employer.employerJobs.jobsList);
    const isMoreOptionsOpen = Boolean(anchorEl);
    const [isJobDescOpen, setIsJobDescOpen] = useState<boolean>(false);
    const [isInviteApplicantsOpen, setIsInviteApplicantsOpen] = useState<boolean>(false);
    /*
        Job delete confirmation dialog.
    */
    const [confirmDialogLabels, setConfirmDialogLabels] = useState<IConfirmationDialogLabels | undefined>(undefined);
    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
    const [dialogType, setDialogType] = useState<ConfirmationDialogFor>(undefined);
    const [showShareDialogue, setShareDialogue] = useState(false);

    const openJobDesc = () => {
        setIsJobDescOpen(true);
        if (!job.jobDetails) {
            dispatch(getJobDescription({ jobId: job.id }));
        }
    };

    const deleteJob = () => {
        // Set confirmation dialog's labels appropriately based on the current action.
        setIsConfirmDialogOpen(true);
        setDialogType('deleteJob');
        setConfirmDialogLabels(DeleteJobConfirmationLabels)
    };

    const closeDialog = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        setIsConfirmDialogOpen(false);
    }

    const stopHiring = () => {
        setIsConfirmDialogOpen(true);
        setDialogType('stopHiring');
        setConfirmDialogLabels(StopHiringConfirmationLabels);
    }

    const editJob = () => {
        if (jobsType === 'expired') {
            setIsConfirmDialogOpen(true);
            setDialogType('editJob');
            setConfirmDialogLabels(EditJobConfirmationLabels);
        } else {
            gotoEditJob();
        }
    }

    const gotoEditJob = () => {
        if (jobsType === 'template') {
            dispatch(setCurrentJobCreationFlow('templateJob'));
        } else {
            dispatch(setCurrentJobCreationFlow('default'));
        }
        navigate(`/employer/job/${job.id}/title`);
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}` } }));
    };

    const gotoApplicants = () => {
        navigate(`/employer/applicants/${job.id}`)
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}`, [`jobs_${job.id}_page`]: `${paginationParams.requestedPageNumber}` } }));
    };

    // const gotoIncompleteApplicants = () => {
    //     navigate(`/employer/incomplete-applicants/${job.id}`)
    //     dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
    //     dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}`, [`${job.id}_page`]: `${paginationParams.requestedPageNumber}` } }));
    // };

    const gotoUseTemplateJob = () => {
        navigate(`/employer/job/${job.id}/title#useTemplateJob`);
        dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
        dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}` } }));
    };

    const onDialogConfirm = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        switch (dialogType) {
            case "deleteJob":
                dispatch(deleteJobById({ jobId: job.id }));
                break;
            case "stopHiring":
                dispatch(stopHireById({ jobId: job.id }));
                break;
            case "editJob":
                gotoEditJob();
                break;
        }
    }

    useEffect(() => {
        if (rePostJobStatus === 'success' && copiedJobId) {
            dispatch(setRePostJobId(copiedJobId));
            /*
                Saving previous job id and reusing it instead of using "job" available in input prop because,
                its giving the last record instance. This needs to be enhanced further.
            */
            navigate(`/employer/job/${prevJobId}`);
            dispatch(setBreadcrumbNavFrom({ breadcrumbNav: `${jobsType}_jobs` }));
            dispatch(setBreadcrumbLabelRegistry({ labelReg: { [job.id]: `${job.name}` } }));
        }
    }, [copiedJobId, dispatch, job.id, job.name, jobsType, navigate, prevJobId, rePostJobStatus]);

    const JobActionItems = () => {
        return (<Stack>
            {jobsType === 'template' &&
                <MenuItem onClick={gotoUseTemplateJob}>
                    <ListItemIcon><UseTemplateJobIcon color='primary' /></ListItemIcon>
                    <ListItemText>Use this Template</ListItemText>
                </MenuItem>}
            {jobsType !== 'draft' && jobsType !== 'template' &&
                <MenuItem onClick={gotoApplicants}>
                    <ListItemIcon><PeopleIcon color='secondary' /></ListItemIcon>
                    <ListItemText>View Applicants</ListItemText>
                </MenuItem>}
            {/* {jobsType !== 'draft' && jobsType !== 'template' &&
                <MenuItem onClick={gotoIncompleteApplicants}>
                    <ListItemIcon><IncompleteIcon /></ListItemIcon>
                    <ListItemText>Incomplete Applicants</ListItemText>
                </MenuItem>} */}
            {jobsType !== 'active' && jobsType !== 'template' && (RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                ?.['Start Hiring']?.visibility !== 'hidden') &&
                <MenuItem onClick={() => dispatch(rePostJob({ jobId: job.id + '' }))}
                    disabled={RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                        ?.['Start Hiring']?.visibility === 'disabled'}>
                    <ListItemIcon><StartIcon color='success' /></ListItemIcon>
                    <ListItemText>Start Hiring</ListItemText>
                </MenuItem>}
            {jobsType === 'active' &&
                <MenuItem onClick={() => setIsInviteApplicantsOpen(true)}>
                    <ListItemIcon><ContactMailIcon color='success' /></ListItemIcon>
                    <ListItemText> Invite Applicants</ListItemText>
                </MenuItem>}
            {jobsType === 'active' && (RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                ?.['Stop Hiring']?.visibility !== 'hidden') &&
                <MenuItem onClick={(e) => stopHiring()} disabled={
                    RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                        ?.['Stop Hiring']?.visibility === 'disabled'}>
                    <ListItemIcon><StopCircleIcon color='error' /></ListItemIcon>
                    <ListItemText>Stop Hiring</ListItemText>
                </MenuItem>}
            {RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                ?.['Edit Job']?.visibility !== 'hidden' &&
                <MenuItem onClick={editJob} disabled={
                    RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                        ?.['Edit Job']?.visibility === 'disabled'}>
                    <ListItemIcon><EditIcon color='primary' /></ListItemIcon>
                    <ListItemText>Edit Job</ListItemText>
                </MenuItem>}
            {(jobsType === 'draft' || jobsType === 'template') && (RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                ?.['Delete Job']?.visibility !== 'hidden') &&
                <MenuItem onClick={() => deleteJob()}
                    disabled={RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                        ?.['Delete Job']?.visibility === 'disabled'}>
                    <ListItemIcon><DeleteIcon color='error' /></ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>}
            {jobsType === 'expired' && (RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                ?.['Re-post Job']?.visibility !== 'hidden') &&
                <MenuItem onClick={() => dispatch(rePostJob({ jobId: job.id + '' }))}
                    disabled={RoleAccessControlMap[accountAccess.role ?? DefaultRoleForRAC].featureMap
                        ?.['Re-post Job']?.visibility === 'disabled'}>
                    <ListItemIcon><PostAddIcon color='info' /></ListItemIcon>
                    <ListItemText>Re-post this Job</ListItemText>
                </MenuItem>}
            {jobsType !== 'draft' &&
                <MenuItem onClick={openJobDesc}>
                    <ListItemIcon><DescriptionIcon color='success' /></ListItemIcon>
                    <ListItemText>Job Description</ListItemText>
                </MenuItem>}
            {jobsType === 'active' &&
                <MenuItem onClick={() => setShareDialogue(value => !value)}>
                    <ListItemIcon><ShareIcon color="primary" /></ListItemIcon>
                    <ListItemText>Share Job</ListItemText>
                </MenuItem>}
        </Stack>);
    }

    return (<>
        {actionMenuType === 'popup' ? <>
            {openMoreMenu && setMoreOptionsAnchorEl && <>
                <Tooltip title="More Actions" onClick={e => e.stopPropagation()} placement='right' >
                    <IconButton className='more-btn' aria-label='More options' onClick={e => { openMoreMenu(e, job.id) }}>
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                <Menu id="more-options" aria-label='More options menu' anchorEl={anchorEl}
                    open={isMoreOptionsOpen} onClose={() => setMoreOptionsAnchorEl({ ...anchorEl, [job.id]: null })}
                    onClick={e => { setMoreOptionsAnchorEl({ ...anchorEl, [job.id]: null }); e.stopPropagation() }}>
                    {JobActionItems()}
                </Menu>
            </>}
        </> :
            <>
                {JobActionItems()}
            </>}
        {/* Invite applicants dialog */}
        <InviteApplicants isInviteApplicantsOpen={isInviteApplicantsOpen} job={job}
            setIsInviteApplicantsOpen={setIsInviteApplicantsOpen} />
        {/* Job description dialog. */}
        <JobDescriptionDialog isJobDescOpen={isJobDescOpen} setIsJobDescOpen={setIsJobDescOpen} jobDescApiResponse={job.getJobDescResponse}
            jobDescApiStatus={job.getJobDescStatus} jobDescription={job.jobDetails?.description} jobName={job.jobDetails?.name} />
        {/* ConfirmationDialog will be rendered only is 'job.id' and 'dialogType' is present. */}
        {job.id && dialogType &&
            <ConfirmationDialog contentText={confirmDialogLabels?.contentText} title={confirmDialogLabels?.title}
                isDialogOpen={isConfirmDialogOpen} onCancel={(e) => closeDialog(e)}
                onConfirm={(e) => onDialogConfirm(e)} confirmButtonLabel={confirmDialogLabels?.confirmButtonLabel}
                cancelButtonLabel={confirmDialogLabels?.cancelButtonLabel} />}
        {showShareDialogue && <JobShareDialog onClose={setShareDialogue} jobId={job.id} />}
    </>);
};
