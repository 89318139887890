import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { AdminApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from 'shared/constants';
import {
  CustomerHardResetBody,
  ManageAccountsState,
} from './manage-accounts-model';

const initialState: ManageAccountsState = {
  hardResetPassword: {
    errors: [],
    response: '',
    status: 'idle',
  },
};

export const hardResetPassword = createAsyncThunk<
  IBaseResponse,
  CustomerHardResetBody,
  { rejectValue: IBaseResponse & { errors: [] } }
>('hardReset', async ({ email, password }, { rejectWithValue }) => {
  const response = await httpAdapterInstance
    .put(AdminApiEndpoints.ADMIN_HARD_RESET, { email, password })
    .then((response) => response?.data)
    .catch((error) => {
      throw rejectWithValue(error.response.data);
    });
  return response;
});

const manageAccountsSlice = createSlice({
  name: 'manageAccounts',
  initialState: initialState,
  reducers: {
    resetValuesOfHardReset: (state) => {
      state.hardResetPassword.errors = [];
      state.hardResetPassword.response = '';
      state.hardResetPassword.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hardResetPassword.pending, (state) => {
      state.hardResetPassword.status = 'pending';
    });
    builder.addCase(hardResetPassword.fulfilled, (state, action) => {
      state.hardResetPassword.status = 'success';
      state.hardResetPassword.response =
        action.payload.message ?? DefaultAPISuccessMsg;
    });
    builder.addCase(hardResetPassword.rejected, (state, action) => {
      state.hardResetPassword.status = 'failed';
      state.hardResetPassword.response =
        action?.payload?.message ?? DefaultAPIErrorMsg;
      state.hardResetPassword.errors = action?.payload?.errors || [];
    });
  },
});

export const { resetValuesOfHardReset } = manageAccountsSlice.actions;
export default manageAccountsSlice;
