export const SoftwareDeveloperJobDescription = {
    title: "Software Developer Job Description",
    jobRole: "A Software Developer is responsible for designing, developing, and maintaining software applications to meet business needs. They collaborate with cross-functional teams to analyze requirements, design solutions, and implement software solutions that enhance organizational efficiency and productivity.",
    preRead: "Use this Software Developer job description template to advertise open positions for a Software Developer in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Software Developer, you will play a key role in the development and maintenance of software applications. You will work closely with other developers, engineers, and stakeholders to understand requirements, design solutions, and deliver high-quality software products. This role requires strong technical skills, problem-solving abilities, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop software solutions according to requirements and design specifications.",
                "Write clean, efficient, and maintainable code.",
                "Test and debug software applications to ensure quality and reliability.",
                "Collaborate with cross-functional teams to define and implement software solutions.",
                "Participate in code reviews and provide constructive feedback to peers.",
                "Troubleshoot and resolve technical issues reported by users or stakeholders.",
                "Stay updated on emerging technologies and best practices in software development.",
                "Contribute to the continuous improvement of development processes and practices.",
                "Document software designs, code, and technical specifications."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Computer Science, Engineering, or related field.",
                "Proven experience in software development, preferably in a relevant technology stack.",
                "Proficiency in programming languages such as Java, Python, C++, etc.",
                "Experience with software development tools and frameworks.",
                "Solid understanding of software engineering principles and best practices.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and collaboration abilities.",
                "Ability to work effectively in a team environment.",
                "Attention to detail and commitment to quality."
            ]
        }
    ]
};

export const ItSupportSpecialistJobDescription = {
    title: "IT Support Specialist Job Description",
    jobRole: "An IT Support Specialist is responsible for providing technical assistance and support to users experiencing computer-related issues. They diagnose and resolve hardware and software problems, install and configure computer systems and software, and maintain IT infrastructure to ensure smooth operations.",
    preRead: "Use this IT Support Specialist job description template to advertise open positions for an IT Support Specialist in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Support Specialist, you will be the first point of contact for users seeking technical assistance. You will diagnose and resolve IT issues, provide guidance and support to users, and ensure the efficient operation of IT systems and equipment. This role requires strong technical skills, problem-solving abilities, and customer service skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide technical support and assistance to users via phone, email, or in person.",
                "Diagnose and resolve hardware and software problems.",
                "Install, configure, and maintain computer systems, software, and peripherals.",
                "Troubleshoot network connectivity issues.",
                "Assist with user account management and access control.",
                "Ensure data security and privacy by implementing appropriate security measures.",
                "Document and maintain IT assets and inventory.",
                "Provide training and guidance to users on IT-related topics.",
                "Collaborate with IT team members to resolve complex technical issues."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Information Technology, Computer Science, or related field, or equivalent experience.",
                "Proven experience in IT support or technical assistance roles.",
                "Strong knowledge of hardware, software, and network troubleshooting.",
                "Familiarity with operating systems, such as Windows, macOS, and Linux.",
                "Experience with IT support tools and ticketing systems.",
                "Excellent communication and customer service skills.",
                "Ability to prioritize tasks and work under pressure.",
                "Certifications such as CompTIA A+, Microsoft Certified IT Professional (MCITP), or equivalent are a plus."
            ]
        }
    ]
};

export const NetworkAdministratorJobDescription = {
    title: "Network Administrator Job Description",
    jobRole: "A Network Administrator is responsible for designing, implementing, and maintaining computer networks within an organization. They ensure network availability, security, and performance by monitoring network activity, troubleshooting issues, and implementing network infrastructure upgrades.",
    preRead: "Use this Network Administrator job description template to advertise open positions for a Network Administrator in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Network Administrator, you will be responsible for managing and maintaining the organization's computer networks. You will design, install, and configure network infrastructure, monitor network performance, and troubleshoot network issues. This role requires strong technical skills, attention to detail, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, implement, and maintain computer networks, including LANs, WANs, and wireless networks.",
                "Install, configure, and manage network hardware and software, such as routers, switches, firewalls, and network monitoring tools.",
                "Monitor network performance and security, analyze network traffic, and optimize network resources.",
                "Troubleshoot network issues, diagnose problems, and implement solutions in a timely manner.",
                "Implement and enforce network security policies and procedures to protect against unauthorized access, data breaches, and other security threats.",
                "Perform network upgrades and expansions as needed to support business growth and technology advancements.",
                "Document network configurations, procedures, and troubleshooting steps.",
                "Provide technical support and guidance to users and IT staff as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Information Technology, Computer Science, or related field, or equivalent experience.",
                "Proven experience in network administration or related IT roles.",
                "Strong knowledge of networking protocols, technologies, and best practices.",
                "Experience with network hardware, software, and security tools.",
                "Certifications such as Cisco Certified Network Associate (CCNA), CompTIA Network+, or equivalent are a plus.",
                "Excellent troubleshooting and problem-solving skills.",
                "Ability to work independently and collaboratively in a team environment.",
                "Good communication and interpersonal skills."
            ]
        }
    ]
};

export const cybersecurityAnalystJobDescription = {
    title: "Cybersecurity Analyst Job Description",
    jobRole: "A Cybersecurity Analyst is responsible for protecting an organization's computer systems and networks from security breaches and cyber threats. They monitor network traffic, analyze security logs, and implement security measures to safeguard against unauthorized access, malware, and other cyber attacks.",
    preRead: "Use this Cybersecurity Analyst job description template to advertise open positions for a Cybersecurity Analyst in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cybersecurity Analyst, you will play a critical role in protecting the organization's information assets from cyber threats and security breaches. You will monitor security logs, analyze security incidents, and implement security measures to mitigate risks and vulnerabilities. This role requires strong technical skills, analytical abilities, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Monitor security logs and alerts to identify potential security incidents and breaches.",
                "Analyze network traffic and security logs to detect and respond to security threats and vulnerabilities.",
                "Conduct security assessments and audits to identify and address security risks and weaknesses.",
                "Implement and maintain security measures, including firewalls, intrusion detection systems, and endpoint security solutions.",
                "Respond to security incidents and breaches in a timely and effective manner, following incident response procedures.",
                "Investigate security incidents, perform forensic analysis, and document findings and remediation steps.",
                "Provide technical expertise and guidance to IT staff and end users on security best practices and procedures.",
                "Stay updated on emerging threats, vulnerabilities, and security technologies to enhance the organization's security posture."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Information Security, Computer Science, or related field, or equivalent experience.",
                "Proven experience in cybersecurity or information security roles.",
                "Strong knowledge of cybersecurity principles, technologies, and best practices.",
                "Experience with security tools and technologies, such as firewalls, intrusion detection systems, and antivirus software.",
                "Certifications such as Certified Information Systems Security Professional (CISSP), Certified Ethical Hacker (CEH), or equivalent are a plus.",
                "Excellent analytical and problem-solving skills.",
                "Ability to work effectively under pressure and prioritize tasks.",
                "Good communication and teamwork skills."
            ]
        }
    ]
};

export const DataScientistJobDescription = {
    title: "Data Scientist Job Description",
    jobRole: "A Data Scientist is responsible for collecting, analyzing, and interpreting large datasets to uncover insights and inform business decisions. They use statistical techniques, machine learning algorithms, and data visualization tools to extract meaningful patterns and trends from data.",
    preRead: "Use this Data Scientist job description template to advertise open positions for a Data Scientist in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Data Scientist, you will work with large datasets to extract insights and drive business decisions. You will use statistical analysis, machine learning algorithms, and data visualization techniques to identify trends, patterns, and correlations in data. This role requires strong analytical skills, programming expertise, and business acumen."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect, clean, and preprocess large datasets for analysis.",
                "Perform exploratory data analysis to identify patterns, trends, and relationships in data.",
                "Develop predictive models and machine learning algorithms to solve business problems.",
                "Evaluate model performance and refine algorithms to improve accuracy and reliability.",
                "Communicate findings and insights to stakeholders through data visualization and storytelling.",
                "Collaborate with cross-functional teams to understand business requirements and develop data-driven solutions.",
                "Stay updated on emerging trends and technologies in data science and machine learning.",
                "Contribute to the development of data science best practices and methodologies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s or Ph.D. degree in Computer Science, Statistics, Mathematics, or related field.",
                "Proven experience in data science, machine learning, or related roles.",
                "Strong programming skills in languages such as Python, R, or SQL.",
                "Experience with data visualization tools such as Tableau, Power BI, or matplotlib.",
                "Solid understanding of statistical techniques and machine learning algorithms.",
                "Familiarity with big data technologies such as Hadoop, Spark, or TensorFlow is a plus.",
                "Excellent analytical and problem-solving skills.",
                "Strong communication and collaboration skills."
            ]
        }
    ]
};


export const SystemsAdministratorJobDescription = {
    title: "Systems Administrator Job Description",
    jobRole: "A Systems Administrator is responsible for managing, maintaining, and supporting an organization's IT infrastructure, including servers, networks, and systems. They ensure the smooth operation, security, and reliability of IT systems and provide technical support to users.",
    preRead: "Use this Systems Administrator job description template to attract candidates who can maintain and support your organization's IT infrastructure. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Systems Administrator, you will manage and maintain the organization's IT infrastructure, ensuring its smooth operation, security, and reliability. You will be responsible for installing, configuring, and troubleshooting servers, networks, and systems. This role requires strong technical skills, problem-solving abilities, and the ability to provide excellent technical support to users."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Install, configure, and maintain servers, networks, and systems.",
                "Monitor system performance and ensure the availability and reliability of IT infrastructure.",
                "Implement and maintain security measures to protect IT systems and data.",
                "Perform regular system backups and recovery procedures.",
                "Troubleshoot and resolve hardware, software, and network issues.",
                "Manage user accounts, permissions, and access controls.",
                "Maintain and update system documentation and IT policies.",
                "Collaborate with other IT staff to plan and implement IT projects.",
                "Provide technical support and training to users.",
                "Stay updated on emerging technologies and industry best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Systems Administrator or in a similar role.",
                "Strong knowledge of IT infrastructure, including servers, networks, and systems.",
                "Experience with operating systems such as Windows, Linux, and macOS.",
                "Familiarity with virtualization and cloud computing technologies.",
                "Understanding of network protocols, routing, and switching.",
                "Excellent troubleshooting and problem-solving skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Certifications such as CompTIA Network+, Microsoft Certified Systems Administrator (MCSA), or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const DatabaseAdministratorJobDescription = {
    title: "Database Administrator Job Description",
    jobRole: "A Database Administrator (DBA) is responsible for the performance, integrity, and security of an organization's databases. They manage and maintain database systems, ensure data availability, and perform troubleshooting and optimization tasks.",
    preRead: "Use this Database Administrator job description template to attract candidates who can manage and maintain your organization's databases. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Database Administrator, you will manage and maintain the performance, integrity, and security of the organization's databases. You will be responsible for database design, configuration, maintenance, and troubleshooting. This role requires strong technical skills, attention to detail, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Install, configure, and maintain database systems.",
                "Monitor database performance and optimize queries and indexing.",
                "Ensure data integrity, security, and availability.",
                "Perform database backups and recovery procedures.",
                "Develop and implement database policies and standards.",
                "Troubleshoot and resolve database issues.",
                "Collaborate with developers and IT staff to support database-related activities.",
                "Perform database upgrades and patch management.",
                "Maintain database documentation and technical specifications.",
                "Stay updated on emerging database technologies and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Database Administrator or in a similar role.",
                "Strong knowledge of database management systems (DBMS) such as MySQL, Oracle, SQL Server, or PostgreSQL.",
                "Experience with database design, configuration, and maintenance.",
                "Understanding of SQL and database scripting.",
                "Familiarity with data backup, recovery, and security procedures.",
                "Excellent troubleshooting and problem-solving skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Certifications such as Oracle Certified Professional (OCP), Microsoft Certified: Azure Database Administrator Associate, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $75,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const CloudEngineerJobDescription = {
    title: "Cloud Engineer Job Description",
    jobRole: "A Cloud Engineer is responsible for designing, implementing, and managing an organization's cloud infrastructure. They work with cloud service providers to deploy and maintain cloud-based applications and services, ensuring scalability, security, and reliability.",
    preRead: "Use this Cloud Engineer job description template to attract candidates who can design and manage your organization's cloud infrastructure. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cloud Engineer, you will design, implement, and manage cloud-based infrastructure and applications. You will work with cloud service providers to deploy, maintain, and optimize cloud solutions. This role requires strong technical skills, experience with cloud platforms, and the ability to work collaboratively with development and IT teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and implement cloud-based solutions and infrastructure.",
                "Deploy and manage cloud services, applications, and resources.",
                "Monitor and optimize the performance, scalability, and security of cloud infrastructure.",
                "Implement and maintain cloud security best practices and policies.",
                "Troubleshoot and resolve issues related to cloud infrastructure and services.",
                "Collaborate with development and IT teams to support cloud-based projects and initiatives.",
                "Stay updated on emerging cloud technologies and industry trends.",
                "Develop and maintain cloud infrastructure documentation.",
                "Ensure compliance with regulatory and security standards.",
                "Provide technical guidance and support to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Cloud Engineer or in a similar role.",
                "Strong knowledge of cloud platforms such as AWS, Azure, or Google Cloud.",
                "Experience with cloud infrastructure design, deployment, and management.",
                "Familiarity with cloud security best practices and tools.",
                "Understanding of networking, virtualization, and containerization technologies.",
                "Excellent troubleshooting and problem-solving skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Certifications such as AWS Certified Solutions Architect, Microsoft Certified: Azure Solutions Architect Expert, or Google Professional Cloud Architect are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $140,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const DevOpsEngineerJobDescription = {
    title: "DevOps Engineer Job Description",
    jobRole: "A DevOps Engineer is responsible for automating and streamlining the software development and deployment processes. They work closely with development and IT operations teams to build, test, and maintain the infrastructure and tools needed to support the continuous integration and delivery (CI/CD) pipelines.",
    preRead: "Use this DevOps Engineer job description template to attract candidates who can automate and streamline your software development and deployment processes. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a DevOps Engineer, you will automate and streamline the software development and deployment processes. You will work closely with development and IT operations teams to build, test, and maintain the infrastructure and tools needed to support CI/CD pipelines. This role requires strong technical skills, experience with automation tools, and a deep understanding of software development and IT operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and maintain CI/CD pipelines for automated software builds, tests, and deployments.",
                "Collaborate with development and IT operations teams to ensure seamless integration and delivery of software.",
                "Automate infrastructure provisioning and configuration using tools such as Terraform, Ansible, or Puppet.",
                "Monitor and optimize the performance, reliability, and security of development and production environments.",
                "Implement and maintain monitoring, logging, and alerting systems.",
                "Troubleshoot and resolve issues related to software builds, deployments, and infrastructure.",
                "Stay updated on emerging DevOps tools and practices.",
                "Develop and maintain documentation for DevOps processes and infrastructure.",
                "Ensure compliance with security and regulatory standards.",
                "Provide technical guidance and support to development and IT operations teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a DevOps Engineer or in a similar role.",
                "Strong knowledge of CI/CD tools such as Jenkins, GitLab CI, or CircleCI.",
                "Experience with infrastructure as code (IaC) tools such as Terraform, Ansible, or Puppet.",
                "Familiarity with containerization and orchestration tools such as Docker and Kubernetes.",
                "Understanding of cloud platforms such as AWS, Azure, or Google Cloud.",
                "Excellent troubleshooting and problem-solving skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Certifications such as AWS Certified DevOps Engineer, Google Professional DevOps Engineer, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $95,000 - $150,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const FrontEndDeveloperJobDescription = {
    title: "Front-End Developer Job Description",
    jobRole: "A Front-End Developer is responsible for designing and implementing the user interface and user experience of web applications. They work with HTML, CSS, JavaScript, and other front-end technologies to create responsive, interactive, and visually appealing web pages.",
    preRead: "Use this Front-End Developer job description template to attract candidates who can design and implement user interfaces for web applications. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Front-End Developer, you will design and implement the user interface and user experience of web applications. You will work with HTML, CSS, JavaScript, and other front-end technologies to create responsive, interactive, and visually appealing web pages. This role requires strong technical skills, creativity, and attention to detail."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and implement user interfaces and user experiences for web applications.",
                "Develop and maintain responsive, interactive, and visually appealing web pages.",
                "Write clean, maintainable, and efficient code using HTML, CSS, and JavaScript.",
                "Collaborate with designers, back-end developers, and other stakeholders to deliver high-quality web applications.",
                "Ensure cross-browser compatibility and optimize web pages for performance.",
                "Implement and maintain front-end build processes and tools.",
                "Stay updated on emerging front-end technologies and best practices.",
                "Perform code reviews and provide constructive feedback to team members.",
                "Troubleshoot and resolve front-end issues and bugs.",
                "Maintain documentation for front-end development processes and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Web Development, or related field.",
                "Proven experience as a Front-End Developer or in a similar role.",
                "Strong knowledge of HTML, CSS, and JavaScript.",
                "Experience with front-end frameworks and libraries such as React, Angular, or Vue.js.",
                "Understanding of responsive design principles and mobile-first development.",
                "Familiarity with version control systems such as Git.",
                "Excellent problem-solving and debugging skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Experience with front-end build tools and processes is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};


export const BackEndDeveloperJobDescription = {
    title: "Back-End Developer Job Description",
    jobRole: "A Back-End Developer is responsible for server-side web application logic and integration of the work front-end developers do. They write web services and APIs used by front-end developers and mobile application developers.",
    preRead: "Use this Back-End Developer job description template to attract candidates who can build and maintain the server-side of web applications. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Back-End Developer, you will build and maintain the server-side logic of web applications. You will be responsible for database integration, server logic, and ensuring high performance and responsiveness to requests from the front-end. This role requires strong programming skills, a deep understanding of web technologies, and the ability to work collaboratively with front-end developers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and maintain server-side logic for web applications.",
                "Integrate user-facing elements developed by front-end developers.",
                "Create and manage databases and ensure data consistency and security.",
                "Optimize applications for maximum speed and scalability.",
                "Design and implement data storage solutions.",
                "Collaborate with front-end developers to integrate APIs and web services.",
                "Perform code reviews and ensure code quality and best practices.",
                "Troubleshoot and debug applications to optimize performance.",
                "Stay updated on emerging technologies and industry trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Back-End Developer or in a similar role.",
                "Strong programming skills in languages such as Java, Python, or Node.js.",
                "Experience with database management systems like MySQL, PostgreSQL, or MongoDB.",
                "Familiarity with front-end technologies (e.g., HTML, CSS, JavaScript) for seamless integration.",
                "Understanding of RESTful APIs and web services.",
                "Excellent problem-solving and troubleshooting skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with version control systems such as Git."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const FullStackDeveloperJobDescription = {
    title: "Full-Stack Developer Job Description",
    jobRole: "A Full-Stack Developer is responsible for both front-end and back-end development tasks. They design, develop, and maintain fully-fledged and functioning platforms with databases or servers. They work on the front-end, back-end, database, and debugging of web applications or websites.",
    preRead: "Use this Full-Stack Developer job description template to attract candidates who can handle both front-end and back-end development tasks. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Full-Stack Developer, you will handle both the front-end and back-end development tasks. You will design, develop, and maintain fully functional web applications and websites, ensuring their responsiveness and efficiency. This role requires expertise in various programming languages and technologies, strong problem-solving skills, and the ability to work collaboratively with other developers and stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop front-end website architecture and back-end website applications.",
                "Create servers and databases for functionality.",
                "Ensure cross-platform optimization for mobile phones.",
                "Ensure responsiveness of applications.",
                "Collaborate with graphic designers for web design features.",
                "Design and develop APIs.",
                "Maintain quality and ensure responsiveness of applications.",
                "Troubleshoot, debug, and upgrade software.",
                "Write technical documentation and provide training as needed.",
                "Stay updated on emerging technologies and industry trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Full-Stack Developer or in a similar role.",
                "Strong knowledge of front-end languages such as HTML, CSS, JavaScript, and back-end languages like Java, Python, or Ruby.",
                "Experience with database management systems like MySQL, PostgreSQL, or MongoDB.",
                "Familiarity with JavaScript frameworks such as Angular JS, React, or Amber.",
                "Proficiency with server-side languages such as Python, Ruby, Java, PHP, and .Net.",
                "Excellent problem-solving and debugging skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with version control systems such as Git."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITProjectManagerJobDescription = {
    title: "IT Project Manager Job Description",
    jobRole: "An IT Project Manager is responsible for planning, executing, and closing IT projects. They oversee all aspects of projects, set deadlines, assign responsibilities, and monitor progress. They ensure that projects are completed on time, within scope, and within budget.",
    preRead: "Use this IT Project Manager job description template to attract candidates who can manage and deliver IT projects successfully. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Project Manager, you will plan, execute, and finalize IT projects according to strict deadlines and within budget. You will be responsible for coordinating project team members, managing resources, and ensuring all project objectives are met. This role requires strong leadership, organizational, and problem-solving skills, as well as the ability to manage multiple projects simultaneously."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Define project scope, goals, and deliverables.",
                "Develop detailed project plans and schedules.",
                "Coordinate project team members and assign tasks.",
                "Monitor and track project progress and performance.",
                "Manage project budgets and resources.",
                "Identify and mitigate project risks and issues.",
                "Ensure project deliverables meet quality standards.",
                "Communicate project status to stakeholders.",
                "Manage changes to project scope, schedule, and costs.",
                "Conduct project post-mortems and create recommendations for future projects."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as an IT Project Manager or in a similar role.",
                "Strong knowledge of project management methodologies such as Agile or Waterfall.",
                "Experience with project management software tools.",
                "Excellent leadership, communication, and interpersonal skills.",
                "Strong organizational and multitasking abilities.",
                "Ability to manage multiple projects simultaneously.",
                "Strong problem-solving and decision-making skills.",
                "PMP, PRINCE2, or similar project management certification is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $95,000 - $140,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITSecuritySpecialistJobDescription = {
    title: "IT Security Specialist Job Description",
    jobRole: "An IT Security Specialist is responsible for protecting an organization's IT systems and data from cyber threats. They implement security measures, monitor network activity, and respond to security incidents to ensure the confidentiality, integrity, and availability of information.",
    preRead: "Use this IT Security Specialist job description template to attract candidates who can secure your organization's IT systems and data. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Security Specialist, you will protect the organization's IT systems and data from cyber threats. You will implement security measures, monitor network activity, and respond to security incidents. This role requires strong technical skills, attention to detail, and the ability to stay updated on the latest cybersecurity trends and threats."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Implement and maintain security measures to protect IT systems and data.",
                "Monitor network activity and respond to security incidents.",
                "Conduct vulnerability assessments and penetration testing.",
                "Investigate security breaches and recommend corrective actions.",
                "Ensure compliance with security policies, standards, and regulations.",
                "Develop and maintain security documentation and procedures.",
                "Provide security training and awareness programs for employees.",
                "Collaborate with IT and development teams to ensure secure system design.",
                "Stay updated on emerging cybersecurity threats and best practices.",
                "Conduct regular security audits and assessments."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as an IT Security Specialist or in a similar role.",
                "Strong knowledge of IT security principles, protocols, and technologies.",
                "Experience with security tools and technologies such as firewalls, IDS/IPS, and SIEM.",
                "Familiarity with network protocols and operating systems.",
                "Excellent problem-solving and analytical skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Relevant certifications such as CISSP, CISM, or CEH are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $85,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const BusinessAnalystJobDescription = {
    title: "Business Analyst Job Description",
    jobRole: "A Business Analyst is responsible for analyzing an organization’s business processes and identifying opportunities for improvement. They work with stakeholders to gather requirements, develop solutions, and ensure that business needs are met through technology and process improvements.",
    preRead: "Use this Business Analyst job description template to attract candidates who can analyze and improve your organization's business processes. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Business Analyst, you will analyze the organization's business processes and identify opportunities for improvement. You will work with stakeholders to gather requirements, develop solutions, and ensure that business needs are met. This role requires strong analytical skills, business acumen, and the ability to communicate effectively with both technical and non-technical stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze business processes and identify areas for improvement.",
                "Gather and document business requirements from stakeholders.",
                "Develop and propose solutions to meet business needs.",
                "Collaborate with IT and development teams to implement solutions.",
                "Create and maintain documentation such as process maps, requirements documents, and user guides.",
                "Conduct feasibility studies and cost-benefit analyses.",
                "Perform gap analysis and recommend process changes.",
                "Facilitate workshops and meetings with stakeholders.",
                "Ensure that solutions align with business goals and objectives.",
                "Monitor and evaluate the effectiveness of implemented solutions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Information Technology, or related field.",
                "Proven experience as a Business Analyst or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work effectively with both technical and non-technical stakeholders.",
                "Experience with business process modeling and analysis tools.",
                "Understanding of project management methodologies such as Agile or Waterfall.",
                "Strong organizational and multitasking abilities.",
                "Ability to work independently and as part of a team.",
                "Relevant certifications such as CBAP or PMI-PBA are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const DataAnalystJobDescription = {
    title: "Data Analyst Job Description",
    jobRole: "A Data Analyst is responsible for collecting, processing, and analyzing data to help organizations make informed business decisions. They use statistical techniques and software tools to identify trends, patterns, and insights from data sets.",
    preRead: "Use this Data Analyst job description template to attract candidates who can analyze and interpret data to support your organization's decision-making processes. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Data Analyst, you will collect, process, and analyze data to help the organization make informed business decisions. You will use statistical techniques and software tools to identify trends, patterns, and insights from data sets. This role requires strong analytical skills, attention to detail, and the ability to communicate findings effectively to stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect, process, and analyze data from various sources.",
                "Use statistical techniques and software tools to identify trends and patterns.",
                "Develop and maintain data dashboards and reports.",
                "Collaborate with stakeholders to understand data needs and requirements.",
                "Provide insights and recommendations based on data analysis.",
                "Ensure data accuracy and integrity.",
                "Create and maintain documentation for data processes and analyses.",
                "Monitor and evaluate data quality and performance.",
                "Stay updated on emerging data analysis techniques and tools.",
                "Support data-driven decision-making across the organization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Data Science, Statistics, Computer Science, or related field.",
                "Proven experience as a Data Analyst or in a similar role.",
                "Strong knowledge of statistical techniques and data analysis tools.",
                "Experience with data visualization tools such as Tableau or Power BI.",
                "Proficiency in programming languages such as SQL, Python, or R.",
                "Excellent analytical and problem-solving skills.",
                "Strong communication and presentation skills.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and commitment to data accuracy.",
                "Familiarity with database management and data warehousing concepts."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $65,000 - $105,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SoftwareEngineerJobDescription = {
    title: "Software Engineer Job Description",
    jobRole: "A Software Engineer is responsible for designing, developing, testing, and maintaining software applications. They work with other engineers and stakeholders to understand requirements and create software solutions that meet business needs.",
    preRead: "Use this Software Engineer job description template to attract candidates who can design and develop software applications for your organization. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Software Engineer, you will design, develop, test, and maintain software applications. You will work with other engineers and stakeholders to understand requirements and create software solutions that meet business needs. This role requires strong programming skills, problem-solving abilities, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, develop, test, and maintain software applications.",
                "Collaborate with other engineers and stakeholders to understand requirements.",
                "Write clean, efficient, and well-documented code.",
                "Perform code reviews and ensure code quality and best practices.",
                "Debug and troubleshoot software issues.",
                "Develop and maintain technical documentation.",
                "Participate in the software development lifecycle, including planning, design, development, testing, and deployment.",
                "Ensure software applications meet performance, security, and reliability standards.",
                "Stay updated on emerging technologies and industry trends.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Software Engineering, or related field.",
                "Proven experience as a Software Engineer or in a similar role.",
                "Strong programming skills in languages such as Java, Python, C++, or JavaScript.",
                "Experience with software development frameworks and tools.",
                "Excellent problem-solving and debugging skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with version control systems such as Git.",
                "Understanding of software development methodologies such as Agile or Scrum.",
                "Relevant certifications such as Microsoft Certified: Azure Developer Associate, Google Associate Android Developer, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $85,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const MachineLearningEngineerJobDescription = {
    title: "Machine Learning Engineer Job Description",
    jobRole: "A Machine Learning Engineer is responsible for designing, developing, and deploying machine learning models and algorithms. They work with large data sets, develop predictive models, and implement machine learning solutions to solve complex business problems.",
    preRead: "Use this Machine Learning Engineer job description template to attract candidates who can develop and implement machine learning models and solutions. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Machine Learning Engineer, you will design, develop, and deploy machine learning models and algorithms. You will work with large data sets, develop predictive models, and implement machine learning solutions to solve complex business problems. This role requires strong programming skills, knowledge of machine learning techniques, and the ability to work with data scientists and other engineers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, develop, and deploy machine learning models and algorithms.",
                "Work with large data sets to build and train predictive models.",
                "Collaborate with data scientists and engineers to develop machine learning solutions.",
                "Implement machine learning algorithms and validate their performance.",
                "Optimize and scale machine learning models for production.",
                "Perform data preprocessing and feature engineering.",
                "Monitor and maintain the performance of deployed models.",
                "Stay updated on emerging machine learning techniques and tools.",
                "Participate in the software development lifecycle, including planning, design, development, testing, and deployment.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Data Science, or related field.",
                "Proven experience as a Machine Learning Engineer or in a similar role.",
                "Strong programming skills in languages such as Python, R, or Java.",
                "Experience with machine learning frameworks and tools such as TensorFlow, PyTorch, or scikit-learn.",
                "Excellent problem-solving and analytical skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with data preprocessing and feature engineering techniques.",
                "Understanding of software development methodologies such as Agile or Scrum.",
                "Relevant certifications such as Google Professional Machine Learning Engineer, AWS Certified Machine Learning - Specialty, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $100,000 - $150,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ArtificialIntelligenceEngineerJobDescription = {
    title: "Artificial Intelligence Engineer Job Description",
    jobRole: "An Artificial Intelligence (AI) Engineer is responsible for developing and implementing AI models and algorithms. They work on various AI technologies, including machine learning, natural language processing, and computer vision, to build intelligent systems and applications.",
    preRead: "Use this Artificial Intelligence Engineer job description template to attract candidates who can develop and implement AI models and solutions. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Artificial Intelligence Engineer, you will develop and implement AI models and algorithms. You will work on various AI technologies, including machine learning, natural language processing, and computer vision, to build intelligent systems and applications. This role requires strong programming skills, knowledge of AI techniques, and the ability to work with data scientists and other engineers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement AI models and algorithms.",
                "Work on various AI technologies, including machine learning, natural language processing, and computer vision.",
                "Collaborate with data scientists and engineers to develop AI solutions.",
                "Train and validate AI models using large data sets.",
                "Optimize and scale AI models for production.",
                "Perform data preprocessing and feature engineering.",
                "Monitor and maintain the performance of deployed models.",
                "Stay updated on emerging AI techniques and tools.",
                "Participate in the software development lifecycle, including planning, design, development, testing, and deployment.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Data Science, or related field.",
                "Proven experience as an AI Engineer or in a similar role.",
                "Strong programming skills in languages such as Python, R, or Java.",
                "Experience with AI frameworks and tools such as TensorFlow, PyTorch, or Keras.",
                "Excellent problem-solving and analytical skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with data preprocessing and feature engineering techniques.",
                "Understanding of software development methodologies such as Agile or Scrum.",
                "Relevant certifications such as Google Professional Machine Learning Engineer, AWS Certified Machine Learning - Specialty, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $110,000 - $160,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const MobileAppDeveloperJobDescription = {
    title: "Mobile App Developer Job Description",
    jobRole: "A Mobile App Developer is responsible for designing, developing, and maintaining mobile applications for various platforms, including iOS and Android. They work with other developers and stakeholders to create user-friendly and efficient mobile apps.",
    preRead: "Use this Mobile App Developer job description template to attract candidates who can design and develop mobile applications for your organization. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mobile App Developer, you will design, develop, and maintain mobile applications for various platforms, including iOS and Android. You will work with other developers and stakeholders to create user-friendly and efficient mobile apps. This role requires strong programming skills, knowledge of mobile development frameworks, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, develop, and maintain mobile applications for iOS and Android platforms.",
                "Collaborate with other developers and stakeholders to understand requirements.",
                "Write clean, efficient, and well-documented code.",
                "Perform code reviews and ensure code quality and best practices.",
                "Debug and troubleshoot mobile app issues.",
                "Develop and maintain technical documentation.",
                "Participate in the mobile app development lifecycle, including planning, design, development, testing, and deployment.",
                "Ensure mobile apps meet performance, security, and reliability standards.",
                "Stay updated on emerging mobile development technologies and industry trends.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Software Engineering, or related field.",
                "Proven experience as a Mobile App Developer or in a similar role.",
                "Strong programming skills in languages such as Swift, Java, or Kotlin.",
                "Experience with mobile development frameworks such as React Native or Flutter.",
                "Excellent problem-solving and debugging skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with version control systems such as Git.",
                "Understanding of mobile app development methodologies such as Agile or Scrum.",
                "Relevant certifications such as Apple Certified iOS Developer, Google Associate Android Developer, or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const TechnicalSupportEngineerJobDescription = {
    title: "Technical Support Engineer Job Description",
    jobRole: "A Technical Support Engineer is responsible for providing technical support and troubleshooting assistance to customers and internal staff. They diagnose and resolve technical issues, provide guidance on product usage, and ensure a high level of customer satisfaction.",
    preRead: "Use this Technical Support Engineer job description template to attract candidates who can provide technical support and troubleshooting assistance. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Technical Support Engineer, you will provide technical support and troubleshooting assistance to customers and internal staff. You will diagnose and resolve technical issues, provide guidance on product usage, and ensure a high level of customer satisfaction. This role requires strong technical skills, excellent communication abilities, and a customer-focused mindset."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide technical support and troubleshooting assistance to customers and internal staff.",
                "Diagnose and resolve technical issues related to hardware, software, and network systems.",
                "Respond to support requests via phone, email, or chat.",
                "Guide customers on product usage and best practices.",
                "Document and track support requests and resolutions.",
                "Collaborate with other support team members and escalate issues when necessary.",
                "Create and maintain technical documentation and knowledge base articles.",
                "Stay updated on product features, updates, and industry trends.",
                "Participate in training sessions and workshops to enhance technical skills.",
                "Ensure a high level of customer satisfaction through timely and effective support."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field.",
                "Proven experience as a Technical Support Engineer or in a similar role.",
                "Strong knowledge of hardware, software, and network systems.",
                "Excellent problem-solving and troubleshooting skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work effectively with customers and internal staff.",
                "Experience with support ticketing systems and remote support tools.",
                "Ability to work independently and as part of a team.",
                "Customer-focused mindset with a commitment to providing high-quality support.",
                "Relevant certifications such as CompTIA A+, Microsoft Certified: Windows Server, or Cisco Certified Network Associate (CCNA) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $55,000 - $90,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITManagerJobDescription = {
    title: "IT Manager Job Description",
    jobRole: "An IT Manager is responsible for overseeing the information technology department and ensuring the company's technology infrastructure runs smoothly. They manage IT staff, plan and implement IT projects, and ensure the security of company data.",
    preRead: "Use this IT Manager job description template to attract candidates who can effectively manage your IT department. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Manager, you will oversee the information technology department and ensure the company's technology infrastructure runs smoothly. You will manage IT staff, plan and implement IT projects, and ensure the security of company data. This role requires strong leadership, technical expertise, and project management skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the daily operations of the IT department.",
                "Manage IT staff, including hiring, training, and evaluating employees.",
                "Plan, implement, and monitor IT projects.",
                "Ensure the security of company data and IT systems.",
                "Develop and enforce IT policies and procedures.",
                "Manage the IT budget and ensure cost-effectiveness.",
                "Collaborate with other departments to understand their IT needs.",
                "Stay updated on the latest technology trends and advancements.",
                "Provide technical support and guidance to staff.",
                "Ensure compliance with relevant regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field.",
                "Proven experience as an IT Manager or in a similar role.",
                "Strong leadership and project management skills.",
                "Excellent technical skills and knowledge of IT systems.",
                "Strong problem-solving and decision-making abilities.",
                "Excellent communication and interpersonal skills.",
                "Ability to manage multiple projects simultaneously.",
                "Experience with IT budget management.",
                "Understanding of IT security and data protection principles.",
                "Relevant certifications such as CompTIA ITF+, ITIL, or PMP are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const WebDeveloperJobDescription = {
    title: "Web Developer Job Description",
    jobRole: "A Web Developer is responsible for designing, coding, and modifying websites, from layout to function according to a client's specifications. They strive to create visually appealing sites that feature user-friendly design and clear navigation.",
    preRead: "Use this Web Developer job description template to attract candidates who can design and develop websites for your organization. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Web Developer, you will design, code, and modify websites, from layout to function according to a client's specifications. You will strive to create visually appealing sites that feature user-friendly design and clear navigation. This role requires strong technical skills, creativity, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, code, and modify websites from layout to function.",
                "Create visually appealing sites that feature user-friendly design and clear navigation.",
                "Write well-designed, testable, and efficient code using best software development practices.",
                "Integrate data from various back-end services and databases.",
                "Gather and refine specifications and requirements based on technical needs.",
                "Create and maintain software documentation.",
                "Be responsible for maintaining, expanding, and scaling the site.",
                "Stay plugged into emerging technologies and industry trends.",
                "Collaborate with web designers and other stakeholders.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience as a Web Developer or in a similar role.",
                "Strong knowledge of web programming languages such as HTML, CSS, JavaScript, and PHP.",
                "Experience with web development frameworks such as React, Angular, or Vue.js.",
                "Excellent problem-solving and debugging skills.",
                "Strong communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Familiarity with version control systems such as Git.",
                "Understanding of web development methodologies such as Agile or Scrum.",
                "Relevant certifications such as Certified Web Developer (CWD) or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SystemsAnalystJobDescription = {
    title: "Systems Analyst Job Description",
    jobRole: "A Systems Analyst is responsible for analyzing and designing information systems to meet a company's business needs. They evaluate current systems, identify improvements, and implement solutions to optimize performance and efficiency.",
    preRead: "Use this Systems Analyst job description template to attract candidates who can analyze and design information systems for your organization. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Systems Analyst, you will analyze and design information systems to meet a company's business needs. You will evaluate current systems, identify improvements, and implement solutions to optimize performance and efficiency. This role requires strong analytical skills, technical expertise, and the ability to work effectively with stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze and design information systems to meet business needs.",
                "Evaluate current systems and identify improvements.",
                "Implement solutions to optimize performance and efficiency.",
                "Collaborate with stakeholders to understand requirements and develop specifications.",
                "Conduct system testing and validation procedures.",
                "Develop and maintain technical documentation.",
                "Provide technical support and training to users.",
                "Ensure systems comply with industry standards and regulations.",
                "Monitor system performance and troubleshoot issues.",
                "Stay updated on emerging technologies and industry trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field.",
                "Proven experience as a Systems Analyst or in a similar role.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work effectively with stakeholders and team members.",
                "Experience with systems analysis and design methodologies.",
                "Knowledge of programming languages and software development practices.",
                "Familiarity with database management and data analysis tools.",
                "Understanding of IT infrastructure and network systems.",
                "Relevant certifications such as Certified Systems Analyst (CSA) or similar are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const NetworkEngineerJobDescription = {
    title: "Network Engineer Job Description",
    jobRole: "A Network Engineer is responsible for designing, implementing, and maintaining network infrastructure. They ensure the reliability and performance of networks, troubleshoot issues, and optimize network systems to support business operations.",
    preRead: "Use this Network Engineer job description template to attract candidates who can design and manage network infrastructure for your organization. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Network Engineer, you will design, implement, and maintain network infrastructure. You will ensure the reliability and performance of networks, troubleshoot issues, and optimize network systems to support business operations. This role requires strong technical skills, problem-solving abilities, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, implement, and maintain network infrastructure.",
                "Ensure the reliability and performance of networks.",
                "Troubleshoot network issues and perform regular maintenance.",
                "Optimize network systems to support business operations.",
                "Collaborate with IT staff to understand network requirements.",
                "Monitor network performance and implement improvements.",
                "Maintain network security and data protection measures.",
                "Develop and maintain network documentation.",
                "Stay updated on emerging network technologies and industry trends.",
                "Provide technical support and guidance to other team members."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field.",
                "Proven experience as a Network Engineer or in a similar role.",
                "Strong knowledge of network protocols and technologies.",
                "Excellent problem-solving and troubleshooting skills.",
                "Ability to work effectively in a team environment.",
                "Excellent communication and interpersonal skills.",
                "Certifications such as CCNA, CCNP, or CompTIA Network+ are a plus.",
                "Experience with network monitoring and management tools."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const InformationSecurityManagerJobDescription = {
    title: "Information Security Manager Job Description",
    jobRole: "An Information Security Manager is responsible for protecting an organization's computer systems and networks from security breaches and cyber attacks. They develop and implement security policies and procedures, conduct security audits, and oversee security measures to safeguard company data.",
    preRead: "Use this Information Security Manager job description template to attract candidates who can manage and enhance the security of your organization's IT infrastructure. Customize the responsibilities and qualifications to align with your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Information Security Manager, you will be responsible for protecting an organization's computer systems and networks from security breaches and cyber attacks. You will develop and implement security policies and procedures, conduct security audits, and oversee security measures to safeguard company data. This role requires strong technical skills, knowledge of security best practices, and the ability to stay updated on emerging threats and technologies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement information security policies and procedures.",
                "Conduct security audits and risk assessments to identify vulnerabilities.",
                "Monitor network activity for security breaches and investigate violations.",
                "Install and configure security software and tools to protect company systems.",
                "Manage access controls and permissions for users.",
                "Educate staff about information security best practices.",
                "Respond to security incidents and provide incident response support.",
                "Stay updated on emerging threats and security technologies.",
                "Ensure compliance with relevant regulations and standards.",
                "Collaborate with IT staff and other stakeholders to implement security measures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field.",
                "Proven experience in information security or related roles.",
                "Strong knowledge of security frameworks and standards such as ISO 27001, NIST, or HIPAA.",
                "Certifications such as CISSP, CISM, or CompTIA Security+ are a plus.",
                "Experience with security tools and technologies such as firewalls, antivirus software, and intrusion detection systems.",
                "Excellent problem-solving and analytical skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work effectively in a team environment.",
                "Understanding of cloud security principles is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $130,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const CloudArchitectJobDescription = {
    title: "Cloud Architect Job Description",
    jobRole: "A Cloud Architect is responsible for designing and implementing cloud-based solutions to meet an organization's business needs. They assess existing infrastructure, recommend cloud services and platforms, and oversee the migration of applications and data to the cloud. Cloud Architects also ensure security, scalability, and reliability of cloud environments.",
    preRead: "Use this Cloud Architect job description template to attract qualified candidates who can design and implement scalable and secure cloud solutions for your organization. Customize the responsibilities and qualifications to match your specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cloud Architect, you will be responsible for designing and implementing cloud-based solutions to meet an organization's business needs. You will assess existing infrastructure, recommend cloud services and platforms, and oversee the migration of applications and data to the cloud. Additionally, you will ensure the security, scalability, and reliability of cloud environments. This role requires strong technical expertise, knowledge of cloud technologies, and the ability to design solutions that align with business objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess current infrastructure and develop cloud migration strategies.",
                "Design cloud architecture solutions to meet business and technical requirements.",
                "Select appropriate cloud services and platforms based on performance, cost, and security considerations.",
                "Define cloud deployment models and design scalable and reliable cloud environments.",
                "Implement cloud security best practices and ensure compliance with security standards.",
                "Collaborate with cross-functional teams to integrate cloud solutions with existing systems.",
                "Optimize cloud resources for performance and cost efficiency.",
                "Monitor cloud environments for performance, availability, and security.",
                "Provide technical guidance and support to teams involved in cloud projects."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience in cloud architecture or related roles.",
                "Strong understanding of cloud computing concepts and architectures.",
                "Experience with cloud platforms such as AWS, Azure, or Google Cloud Platform.",
                "Knowledge of cloud security best practices and compliance requirements.",
                "Familiarity with infrastructure as code tools such as Terraform or CloudFormation.",
                "Excellent problem-solving and analytical skills.",
                "Strong communication and collaboration skills.",
                "Certifications such as AWS Certified Solutions Architect or Microsoft Certified: Azure Solutions Architect Expert are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $110,000 - $160,000 per year, depending on experience and qualifications.",
                "Comprehensive healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certification programs.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const TechnicalWriterJobDescription = {
    title: "Technical Writer Job Description",
    jobRole: "A Technical Writer is responsible for creating and maintaining technical documentation, such as user manuals, guides, and online help systems, for software applications, products, and services. They collaborate with subject matter experts to gather information and ensure accuracy and clarity of technical content.",
    preRead: "Use this Technical Writer job description template to attract skilled individuals who can effectively communicate complex technical information to users. Customize the responsibilities and qualifications to fit your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Technical Writer, you will play a crucial role in creating and maintaining technical documentation for software applications, products, and services. You will work closely with subject matter experts to gather information and translate complex technical concepts into clear and concise documentation that is easy to understand for users. This role requires strong writing skills, attention to detail, and the ability to work collaboratively with cross-functional teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Create and update technical documentation, including user manuals, guides, and online help systems.",
                "Gather and analyze technical information from subject matter experts and other sources.",
                "Organize and structure content to ensure clarity, consistency, and accuracy.",
                "Translate complex technical concepts into clear and concise written content for a diverse audience.",
                "Collaborate with product managers, developers, and other stakeholders to understand product features and functionality.",
                "Ensure documentation adheres to style guidelines and follows best practices for technical writing.",
                "Review and edit documentation for grammar, punctuation, and spelling errors.",
                "Manage documentation projects and timelines to meet deadlines.",
                "Stay updated on industry trends and best practices in technical writing and documentation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in English, Technical Writing, Computer Science, or related field.",
                "Proven experience as a technical writer, preferably in the software industry.",
                "Strong writing and editing skills with the ability to explain complex technical concepts clearly and concisely.",
                "Familiarity with technical writing tools and software, such as Adobe FrameMaker, MadCap Flare, or Microsoft Word.",
                "Ability to work independently and collaborate effectively with cross-functional teams.",
                "Attention to detail and commitment to producing high-quality documentation.",
                "Excellent communication and interpersonal skills.",
                "Experience with Agile development methodologies is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience and qualifications.",
                "Comprehensive healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and workshops.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const UXUIDesignerJobDescription = {
    title: "UX/UI Designer Job Description",
    jobRole: "A UX/UI Designer is responsible for creating intuitive and visually appealing user interfaces for digital products and applications. They collaborate with cross-functional teams to understand user needs, design wireframes and prototypes, and ensure a seamless user experience across all platforms.",
    preRead: "Use this UX/UI Designer job description template to attract talented individuals who can create engaging and user-friendly interfaces for your digital products. Customize the responsibilities and qualifications to match your organization's requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a UX/UI Designer, you will be tasked with designing user interfaces that enhance user satisfaction and drive engagement with our digital products. You will work closely with product managers, developers, and other stakeholders to understand user needs and translate them into intuitive and visually appealing designs. This role requires a blend of creative design skills, user-centric thinking, and technical proficiency."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Gather and evaluate user requirements in collaboration with product managers and developers.",
                "Design intuitive and visually appealing user interfaces for web and mobile applications.",
                "Create wireframes, mockups, and prototypes to demonstrate design concepts and interactions.",
                "Conduct user research, usability testing, and feedback sessions to iteratively improve designs.",
                "Collaborate with developers to ensure design feasibility and implementation.",
                "Optimize designs for responsiveness, performance, and cross-browser compatibility.",
                "Stay updated on industry trends, best practices, and emerging technologies in UX/UI design.",
                "Communicate design ideas and rationale effectively to stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Design, HCI, Computer Science, or related field.",
                "Proven experience as a UX/UI Designer or similar role.",
                "Proficiency in design and prototyping tools such as Sketch, Adobe XD, Figma, or InVision.",
                "Strong understanding of user-centered design principles and methodologies.",
                "Experience with user research techniques and usability testing.",
                "Solid grasp of web development technologies such as HTML, CSS, and JavaScript.",
                "Excellent visual design skills with an eye for detail and aesthetics.",
                "Ability to work collaboratively in a fast-paced, iterative environment.",
                "Strong communication and presentation skills."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Comprehensive healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and conferences.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SolutionsArchitectJobDescription = {
    title: "Solutions Architect Job Description",
    jobRole: "A Solutions Architect is responsible for designing and implementing scalable, reliable, and cost-effective solutions that meet business requirements. They collaborate with stakeholders to understand technical needs, design architecture solutions, and oversee implementation to ensure successful delivery.",
    preRead: "Use this Solutions Architect job description template to attract skilled individuals who can design and implement effective solutions for your organization. Customize the responsibilities and qualifications to match your company's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Solutions Architect, you will play a key role in designing and implementing technical solutions that align with our business goals and requirements. You will work closely with stakeholders to understand their needs, assess existing systems and infrastructure, and design scalable and reliable solutions. This role requires a blend of technical expertise, strategic thinking, and effective communication skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with stakeholders to understand business objectives, technical requirements, and constraints.",
                "Design scalable, reliable, and cost-effective solutions that meet business needs and align with architectural best practices.",
                "Create architectural diagrams, documentation, and technical specifications to communicate solution designs to stakeholders.",
                "Lead the implementation and deployment of solutions, working closely with development teams and other stakeholders.",
                "Ensure solutions adhere to security, performance, and compliance standards.",
                "Provide guidance and support to development teams during the implementation phase, ensuring alignment with solution architecture.",
                "Evaluate emerging technologies and trends to inform architectural decisions and identify opportunities for innovation.",
                "Contribute to the development and maintenance of architectural standards, best practices, and guidelines."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Engineering, or related field; Master’s degree preferred.",
                "Proven experience as a Solutions Architect, Technical Architect, or similar role.",
                "Strong understanding of cloud computing principles and technologies (e.g., AWS, Azure, Google Cloud).",
                "Experience designing and implementing scalable and reliable distributed systems.",
                "Proficiency in architectural design patterns, frameworks, and methodologies.",
                "Excellent analytical and problem-solving skills.",
                "Effective communication and interpersonal skills, with the ability to collaborate across teams and stakeholders.",
                "Certifications such as AWS Certified Solutions Architect or Microsoft Certified: Azure Solutions Architect are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $110,000 - $160,000 per year, depending on experience and qualifications.",
                "Comprehensive healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITConsultantJobDescription = {
    title: "IT Consultant Job Description",
    jobRole: "An IT Consultant provides expert advice and assistance to organizations in planning, implementing, and managing their information technology systems. They assess the organization's IT needs, recommend solutions, and provide guidance on technology strategy, infrastructure, security, and optimization.",
    preRead: "Use this IT Consultant job description template to attract experienced professionals who can provide valuable IT consulting services to your clients. Customize the responsibilities and qualifications to match your company's specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Consultant, you will work closely with clients to understand their business objectives and IT requirements. You will assess existing systems and infrastructure, identify areas for improvement, and recommend solutions to enhance efficiency, productivity, and security. This role requires strong technical expertise, problem-solving skills, and the ability to communicate complex concepts effectively."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Consult with clients to assess their IT needs, objectives, and challenges.",
                "Analyze existing IT systems, infrastructure, and processes to identify areas for improvement.",
                "Develop and present recommendations for technology solutions that align with client objectives and budget.",
                "Provide guidance and assistance in planning, designing, and implementing IT projects and initiatives.",
                "Assist with vendor selection, contract negotiation, and procurement of hardware, software, and services.",
                "Advise on IT security best practices, policies, and procedures to mitigate risks and protect data.",
                "Deliver technical expertise and support to clients during project implementation and post-implementation phases.",
                "Stay updated on emerging technologies, trends, and best practices in IT consulting and related fields."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field; Master’s degree preferred.",
                "Proven experience as an IT Consultant, Technology Advisor, or similar role.",
                "Strong understanding of IT systems, infrastructure, and architecture.",
                "Knowledge of a wide range of technologies including networking, cloud computing, cybersecurity, and enterprise software.",
                "Excellent analytical and problem-solving skills.",
                "Effective communication and interpersonal skills, with the ability to build rapport with clients and stakeholders.",
                "Project management skills and the ability to manage multiple projects simultaneously.",
                "Certifications such as CompTIA IT Fundamentals, ITIL Foundation, or Project Management Professional (PMP) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Opportunities for professional development and training.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const BigDataEngineerJobDescription = {
    title: "Big Data Engineer Job Description",
    jobRole: "A Big Data Engineer is responsible for designing, building, and maintaining large-scale data processing systems and infrastructure. They work with big data technologies to collect, store, process, and analyze massive volumes of data efficiently and effectively.",
    preRead: "Use this Big Data Engineer job description template to attract skilled professionals who can design and implement robust data solutions to meet your organization's needs. Customize the responsibilities and qualifications to match your company's specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Big Data Engineer, you will be involved in the development and implementation of data pipelines, data warehouses, and other data infrastructure solutions. You will work with cutting-edge technologies to ingest, process, and analyze large volumes of structured and unstructured data. This role requires strong technical skills, problem-solving abilities, and a deep understanding of big data tools and frameworks."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and develop scalable data processing systems and infrastructure using big data technologies such as Hadoop, Spark, Kafka, and NoSQL databases.",
                "Build and maintain data pipelines to ingest, transform, and load large volumes of structured and unstructured data from various sources.",
                "Optimize data processing and storage solutions for performance, reliability, and cost-effectiveness.",
                "Implement data security, privacy, and governance best practices to ensure compliance with regulatory requirements.",
                "Collaborate with data scientists, analysts, and other stakeholders to understand data requirements and deliver data-driven solutions.",
                "Troubleshoot and debug data processing issues, performance bottlenecks, and system failures.",
                "Stay updated on emerging technologies, tools, and best practices in big data engineering and related fields.",
                "Contribute to the development of data engineering standards, practices, and methodologies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s or Master’s degree in Computer Science, Engineering, or related field.",
                "Proven experience as a Big Data Engineer, Data Engineer, or similar role.",
                "Strong programming skills in languages such as Java, Python, Scala, or SQL.",
                "Experience with big data technologies and frameworks such as Hadoop, Spark, Kafka, Hive, and HBase.",
                "Familiarity with cloud platforms such as AWS, Azure, or Google Cloud Platform.",
                "Knowledge of data modeling, ETL (Extract, Transform, Load) processes, and data warehousing concepts.",
                "Excellent problem-solving and analytical skills.",
                "Effective communication and collaboration skills."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $150,000 per year, depending on experience and qualifications.",
                "Comprehensive healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const PenetrationTesterJobDescription = {
    title: "Penetration Tester Job Description",
    jobRole: "A Penetration Tester, also known as an Ethical Hacker, is responsible for evaluating the security of computer systems, networks, and applications by simulating cyberattacks. They identify vulnerabilities, assess security risks, and recommend remediation measures to improve the overall security posture of an organization.",
    preRead: "Use this Penetration Tester job description template to attract qualified candidates who can assess and enhance the security of your organization's digital assets. Customize the responsibilities and qualifications to align with your specific security requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Penetration Tester, you will conduct simulated cyberattacks to identify weaknesses in systems, networks, and applications. You will use a variety of tools and techniques to exploit vulnerabilities and assess the effectiveness of existing security controls. This role requires strong technical skills, ethical hacking expertise, and the ability to communicate findings effectively to stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct vulnerability assessments and penetration tests on systems, networks, and applications.",
                "Identify and exploit security vulnerabilities to gain unauthorized access to digital assets.",
                "Perform manual and automated security testing to assess the effectiveness of security controls.",
                "Document and report security findings, including detailed exploitation techniques and recommended remediation measures.",
                "Collaborate with security teams and other stakeholders to prioritize and address security risks.",
                "Stay updated on emerging threats, vulnerabilities, and security best practices.",
                "Provide guidance and recommendations for improving the security posture of systems and applications.",
                "Participate in incident response activities to investigate and mitigate security incidents."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Security, or related field.",
                "Certifications such as Certified Ethical Hacker (CEH), Offensive Security Certified Professional (OSCP), or similar.",
                "Proven experience in penetration testing, vulnerability assessment, or ethical hacking.",
                "Strong knowledge of networking protocols, operating systems, and web application technologies.",
                "Experience with penetration testing tools such as Metasploit, Nmap, Burp Suite, and Wireshark.",
                "Familiarity with common security frameworks and standards such as OWASP Top 10, PCI DSS, and NIST Cybersecurity Framework.",
                "Excellent analytical and problem-solving skills.",
                "Effective communication and report writing skills."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SoftwareTesterJobDescription = {
    title: "Software Tester Job Description",
    jobRole: "A Software Tester, also known as a QA Tester or Quality Assurance Analyst, is responsible for ensuring the quality and functionality of software products through rigorous testing. They design test cases, execute tests, identify defects, and work closely with development teams to resolve issues and improve product quality.",
    preRead: "Use this Software Tester job description template to attract qualified candidates who can contribute to the quality assurance process of your software development projects. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Software Tester, you will play a critical role in ensuring the quality and reliability of software products before they are released to customers. You will design test cases, execute test plans, and report defects to development teams for resolution. This role requires attention to detail, analytical skills, and the ability to work collaboratively with cross-functional teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and maintain test plans, test cases, and test scenarios based on project requirements and specifications.",
                "Execute functional, regression, and performance tests to identify defects and ensure software quality.",
                "Collaborate with software developers and business analysts to understand software requirements and acceptance criteria.",
                "Report and track defects using bug tracking systems, and work with development teams to prioritize and resolve issues.",
                "Perform exploratory testing to uncover hidden defects and usability issues.",
                "Conduct compatibility testing across different platforms, browsers, and devices.",
                "Participate in release planning and post-release validation activities.",
                "Contribute to the continuous improvement of testing processes and methodologies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience in software testing, quality assurance, or related roles.",
                "Strong understanding of software testing methodologies, techniques, and best practices.",
                "Experience with test automation tools such as Selenium, Appium, or JUnit.",
                "Familiarity with bug tracking and test management tools such as Jira, Bugzilla, or TestRail.",
                "Excellent analytical and problem-solving skills.",
                "Strong attention to detail and ability to work independently as well as part of a team.",
                "Effective communication skills and ability to collaborate with cross-functional teams."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const HelpDeskTechnicianJobDescription = {
    title: "Help Desk Technician Job Description",
    jobRole: "A Help Desk Technician, also known as a Support Technician or IT Support Specialist, is responsible for providing technical assistance and support to users experiencing issues with computer hardware, software, or network systems. They diagnose and troubleshoot problems, escalate unresolved issues, and maintain documentation of support activities.",
    preRead: "Use this Help Desk Technician job description template to attract qualified candidates who can provide effective technical support to end users. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Help Desk Technician, you will be the first point of contact for users seeking technical assistance. You will diagnose and resolve technical issues, provide guidance and support on software and hardware problems, and escalate complex issues to higher-level support teams when necessary. This role requires excellent communication skills, technical expertise, and a customer-oriented approach to problem-solving."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Respond to user inquiries and requests for technical assistance via phone, email, or ticketing system.",
                "Diagnose and troubleshoot hardware, software, and network problems.",
                "Provide guidance and support on the use of computer systems, software applications, and peripheral devices.",
                "Install, configure, and maintain computer hardware and software components.",
                "Escalate unresolved issues to higher-level support teams or vendors for resolution.",
                "Document all support activities, including issue resolution and troubleshooting steps, in the ticketing system.",
                "Follow standard procedures for proper escalation of complex issues to ensure timely resolution.",
                "Contribute to the development and maintenance of knowledge base articles and support documentation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate or bachelor’s degree in Information Technology, Computer Science, or related field (preferred).",
                "Proven experience as a Help Desk Technician or similar role.",
                "Strong knowledge of computer hardware, software, and operating systems.",
                "Experience with remote desktop support tools and ticketing systems.",
                "Excellent troubleshooting and problem-solving skills.",
                "Effective communication and interpersonal skills.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Customer-oriented mindset and dedication to providing high-quality technical support."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITOperationsManagerJobDescription = {
    title: "IT Operations Manager Job Description",
    jobRole: "An IT Operations Manager oversees the daily operations of an organization's IT infrastructure, ensuring that systems, networks, and services are running smoothly and efficiently. They manage a team of IT professionals, set priorities, allocate resources, and implement policies and procedures to optimize IT operations.",
    preRead: "Use this IT Operations Manager job description template to attract qualified candidates who can effectively manage and optimize IT infrastructure and operations. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Operations Manager, you will be responsible for managing and maintaining the organization's IT infrastructure and ensuring that all systems and services are operating effectively. You will lead a team of IT professionals, set strategic priorities, and implement best practices to optimize IT operations and support the organization's goals and objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and oversee the day-to-day operations of the IT department, including systems administration, network operations, and help desk support.",
                "Develop and implement IT policies, procedures, and best practices to ensure the security, reliability, and efficiency of IT infrastructure and operations.",
                "Lead and mentor a team of IT professionals, providing guidance, support, and professional development opportunities.",
                "Evaluate and recommend IT solutions and technologies to improve efficiency, reduce costs, and support business objectives.",
                "Monitor and analyze system performance and resource utilization, identifying and addressing issues proactively.",
                "Ensure compliance with regulatory requirements and industry standards for IT operations and security.",
                "Manage relationships with external vendors, service providers, and contractors to ensure effective delivery of IT services and support.",
                "Collaborate with other departments and stakeholders to understand IT needs and priorities, and align IT strategies with business goals.",
                "Develop and maintain disaster recovery and business continuity plans to ensure IT systems and services are resilient and recoverable in the event of a disruption or outage."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field (Master’s degree preferred).",
                "Proven experience in IT management, including systems administration, network operations, and help desk support.",
                "Strong leadership and management skills, with the ability to effectively lead and develop a team.",
                "Solid understanding of IT infrastructure and operations, including systems, networks, servers, and storage.",
                "Knowledge of ITIL (Information Technology Infrastructure Library) principles and best practices.",
                "Experience with cloud computing platforms, virtualization technologies, and IT security practices.",
                "Excellent communication, interpersonal, and problem-solving skills.",
                "Ability to prioritize and manage multiple projects and initiatives in a fast-paced environment.",
                "Certifications such as ITIL, PMP (Project Management Professional), or CISSP (Certified Information Systems Security Professional) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITCoordinatorJobDescription = {
    title: "IT Coordinator Job Description",
    jobRole: "An IT Coordinator assists in the coordination and implementation of IT projects, services, and operations within an organization. They provide technical support, manage IT assets, and facilitate communication between IT teams and other departments.",
    preRead: "Use this IT Coordinator job description template to attract candidates who can support and coordinate IT activities effectively. Customize the responsibilities and qualifications to match your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Coordinator, you will play a key role in supporting and coordinating IT initiatives and operations within the organization. You will assist in the implementation of IT projects, provide technical support to end-users, and ensure that IT assets and resources are managed effectively. This role requires strong organizational skills, attention to detail, and a proactive approach to problem-solving."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in the coordination and implementation of IT projects, including software deployments, hardware upgrades, and system migrations.",
                "Provide technical support to end-users, troubleshooting hardware and software issues, and resolving technical problems.",
                "Manage IT assets and inventory, including procurement, tracking, and disposal of hardware and software.",
                "Facilitate communication between IT teams and other departments, ensuring that IT initiatives align with business needs and priorities.",
                "Maintain documentation and records related to IT systems, processes, and procedures.",
                "Assist in the configuration and administration of IT systems and services, including network infrastructure, email systems, and cloud platforms.",
                "Collaborate with vendors and service providers to coordinate IT services and support, and ensure that service level agreements are met.",
                "Assist in the development and implementation of IT policies, procedures, and best practices to improve efficiency and security.",
                "Stay updated on emerging technologies and industry trends, and provide recommendations for IT improvements and enhancements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field (Associate’s degree or relevant certifications may be considered).",
                "Previous experience in a similar role, with knowledge of IT systems, hardware, and software.",
                "Strong technical skills, with proficiency in troubleshooting and resolving IT issues.",
                "Excellent organizational and multitasking abilities, with the ability to prioritize and manage multiple tasks and projects.",
                "Good communication and interpersonal skills, with the ability to work effectively with team members and end-users.",
                "Attention to detail and accuracy in documentation and record-keeping.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Certifications such as CompTIA A+, Network+, or Microsoft Certified Desktop Support Technician (MCDST) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $45,000 - $65,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ERPConsultantJobDescription = {
    title: "ERP Consultant Job Description",
    jobRole: "An ERP (Enterprise Resource Planning) Consultant is responsible for implementing and configuring ERP systems to meet the specific needs of clients. They work closely with clients to understand their business processes, gather requirements, and design solutions that optimize efficiency and productivity.",
    preRead: "Use this ERP Consultant job description template to attract candidates who can effectively implement and customize ERP systems for clients. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an ERP Consultant, you will play a critical role in helping clients implement and customize ERP systems to streamline their business processes. You will work closely with clients to understand their requirements, configure the ERP system accordingly, and provide training and support to ensure successful adoption. This role requires a combination of technical expertise, business acumen, and strong communication skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with clients to understand their business processes, requirements, and objectives for implementing an ERP system.",
                "Configure and customize the ERP system to meet the specific needs of clients, including module setup, workflow design, and data migration.",
                "Provide training and support to end-users to ensure successful adoption of the ERP system.",
                "Work closely with technical teams to resolve issues, troubleshoot problems, and optimize system performance.",
                "Document client requirements, system configurations, and processes to support ongoing maintenance and future upgrades.",
                "Stay updated on new features and functionalities of ERP systems, and provide recommendations for improvements and enhancements.",
                "Communicate effectively with clients and internal teams to ensure project milestones are met and expectations are managed.",
                "Contribute to the development of best practices, methodologies, and tools for ERP implementation and customization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Business Administration, or related field.",
                "Previous experience in ERP implementation or consulting, with knowledge of ERP systems such as SAP, Oracle, or Microsoft Dynamics.",
                "Strong technical skills, with proficiency in ERP configuration, customization, and data migration.",
                "Excellent problem-solving and analytical skills, with the ability to understand complex business processes and requirements.",
                "Good communication and interpersonal skills, with the ability to build rapport with clients and collaborate effectively with internal teams.",
                "Project management skills, with the ability to manage multiple projects simultaneously and meet deadlines.",
                "Certifications in ERP systems such as SAP Certified Application Associate or Oracle Certified Implementation Specialist are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ScrumMasterJobDescription = {
    title: "Scrum Master Job Description",
    jobRole: "A Scrum Master is responsible for facilitating Agile development processes and ensuring the Scrum team adheres to Scrum principles and practices. They act as a servant-leader, helping the team self-organize, collaborate effectively, and deliver high-quality products iteratively.",
    preRead: "Use this Scrum Master job description template to attract candidates who can effectively lead Agile development teams and drive continuous improvement. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Scrum Master, you will play a key role in guiding Agile development teams to deliver value to customers efficiently and effectively. You will facilitate Scrum ceremonies, remove impediments, and foster a culture of collaboration and continuous improvement within the team. This role requires strong leadership, communication, and facilitation skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Facilitate Agile ceremonies including sprint planning, daily stand-ups, sprint reviews, and retrospectives.",
                "Guide the Scrum team in adhering to Scrum principles, practices, and values.",
                "Remove impediments and obstacles that prevent the team from achieving their sprint goals.",
                "Coach and mentor team members on Agile principles, practices, and roles.",
                "Promote a culture of transparency, collaboration, and continuous improvement within the team.",
                "Collaborate with Product Owners and stakeholders to prioritize and refine the product backlog.",
                "Track and report progress towards sprint goals, sprint velocity, and team performance metrics.",
                "Facilitate effective communication and collaboration between team members, Product Owners, and stakeholders.",
                "Identify opportunities for process improvements and lead initiatives to implement changes.",
                "Ensure that the team follows Agile best practices and adapts to changes in the Agile landscape."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Certification as a Scrum Master (CSM) or equivalent Agile certification.",
                "Proven experience as a Scrum Master or Agile Coach in software development projects.",
                "Deep understanding of Agile principles, practices, and frameworks (Scrum, Kanban, etc.).",
                "Strong facilitation, coaching, and mentoring skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to build rapport and establish trust with team members and stakeholders.",
                "Problem-solving and conflict resolution skills.",
                "Knowledge of Agile tools such as Jira, Trello, or Azure DevOps is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITAuditorJobDescription = {
    title: "IT Auditor Job Description",
    jobRole: "An IT Auditor is responsible for assessing the effectiveness of an organization's IT systems, controls, and processes to ensure compliance with regulatory requirements and industry best practices. They conduct audits, analyze risks, and provide recommendations to enhance the security, integrity, and efficiency of IT operations.",
    preRead: "Use this IT Auditor job description template to attract candidates who can effectively evaluate and improve IT systems and controls within your organization. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Auditor, you will play a crucial role in evaluating the design and effectiveness of IT controls and processes. You will conduct audits, identify risks, and provide recommendations to enhance the security, reliability, and compliance of IT systems. This role requires strong analytical skills, attention to detail, and knowledge of IT governance and security standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Plan and execute IT audits in accordance with established audit plans and methodologies.",
                "Assess the design and effectiveness of IT controls, policies, and procedures.",
                "Evaluate IT systems, applications, and infrastructure to identify risks and vulnerabilities.",
                "Perform testing of IT controls to ensure compliance with regulatory requirements and industry standards.",
                "Document audit findings, including deficiencies, risks, and recommendations for improvement.",
                "Communicate audit results and recommendations to key stakeholders, including senior management and IT leadership.",
                "Collaborate with internal and external stakeholders to address audit findings and implement corrective actions.",
                "Stay informed about emerging trends, technologies, and regulations in IT audit and cybersecurity."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, Accounting, or related field.",
                "Certification as a Certified Information Systems Auditor (CISA) or equivalent IT audit certification.",
                "Proven experience in IT auditing, risk assessment, or IT security.",
                "Strong understanding of IT governance frameworks, such as COBIT, ITIL, or NIST.",
                "Knowledge of regulatory requirements and standards, such as GDPR, HIPAA, SOX, or PCI DSS.",
                "Experience with audit tools and techniques, including data analysis and sampling.",
                "Excellent analytical, problem-solving, and critical-thinking skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and collaborate effectively with cross-functional teams."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $110,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ApplicationSupportAnalystJobDescription = {
    title: "Application Support Analyst Job Description",
    jobRole: "An Application Support Analyst is responsible for ensuring the smooth operation and performance of software applications used within an organization. They troubleshoot technical issues, provide user support, and collaborate with developers and other IT teams to resolve problems and improve application functionality.",
    preRead: "Use this Application Support Analyst job description template to attract candidates who can effectively maintain and support software applications within your organization. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Application Support Analyst, you will be responsible for providing technical support and assistance to users of software applications. You will troubleshoot issues, diagnose problems, and work with internal teams to resolve technical issues and improve application performance. This role requires strong analytical skills, technical expertise, and the ability to communicate effectively with both technical and non-technical stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide first and second-level technical support to users of software applications.",
                "Troubleshoot and diagnose issues related to application functionality, performance, and integration.",
                "Respond to user inquiries, incidents, and service requests in a timely and professional manner.",
                "Document support tickets, including issue description, troubleshooting steps, and resolution details.",
                "Collaborate with developers, QA analysts, and other IT teams to investigate and resolve technical issues.",
                "Perform application configuration, updates, and patches as needed to maintain system stability and security.",
                "Monitor application performance and system health to identify and address potential issues proactively.",
                "Contribute to the development of knowledge base articles, user guides, and training materials.",
                "Participate in testing and validation of software releases, patches, and updates.",
                "Provide training and guidance to end-users on application functionality and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field.",
                "Proven experience in application support, technical support, or help desk roles.",
                "Strong troubleshooting and problem-solving skills.",
                "Experience with ticketing systems and service desk tools.",
                "Knowledge of software development lifecycle (SDLC) and ITIL framework is a plus.",
                "Familiarity with databases, SQL queries, and data analysis techniques.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and collaboratively in a fast-paced environment.",
                "Flexibility to work outside regular business hours as needed for support coverage."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SecurityConsultantJobDescription = {
    title: "Security Consultant Job Description",
    jobRole: "A Security Consultant is responsible for assessing, designing, and implementing security solutions to protect an organization's information systems and data from cyber threats and attacks. They conduct security assessments, develop security policies and procedures, and provide guidance on security best practices.",
    preRead: "Use this Security Consultant job description template to attract candidates who can effectively assess and enhance the security posture of your organization. Customize the responsibilities and qualifications to align with your organization's security needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Security Consultant, you will work with clients to assess their security risks, identify vulnerabilities, and develop strategies to mitigate security threats. You will conduct security assessments, penetration testing, and vulnerability scans to evaluate the effectiveness of security controls. This role requires strong technical skills, knowledge of cybersecurity principles, and the ability to communicate effectively with clients and internal teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct security assessments and audits to identify vulnerabilities and assess the overall security posture of client environments.",
                "Perform penetration testing, vulnerability scanning, and ethical hacking to identify and exploit security weaknesses.",
                "Develop security policies, standards, and procedures to enhance the security posture of client organizations.",
                "Provide recommendations and guidance on security best practices, security architecture, and risk mitigation strategies.",
                "Assist with the implementation of security controls, technologies, and solutions to protect against cyber threats.",
                "Monitor and analyze security logs and alerts to detect and respond to security incidents.",
                "Collaborate with clients, IT teams, and other stakeholders to address security issues and implement security improvements.",
                "Stay informed about emerging threats, vulnerabilities, and security technologies to provide proactive security guidance and recommendations.",
                "Conduct security awareness training and education sessions for employees to promote a security-conscious culture."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Security, or related field; Master’s degree is a plus.",
                "Certifications such as CISSP, CISM, CEH, or other relevant certifications.",
                "Proven experience in cybersecurity, information security, or related roles.",
                "Strong knowledge of cybersecurity principles, practices, and technologies.",
                "Experience with security assessment tools, penetration testing tools, and vulnerability scanners.",
                "Familiarity with regulatory compliance requirements (e.g., GDPR, HIPAA, PCI DSS).",
                "Excellent analytical and problem-solving skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ITTrainerJobDescription = {
    title: "IT Trainer Job Description",
    jobRole: "An IT Trainer is responsible for designing, developing, and delivering training programs to educate employees or clients on various IT systems, applications, and technologies. They assess training needs, create training materials, and conduct hands-on training sessions to ensure effective knowledge transfer.",
    preRead: "Use this IT Trainer job description template to attract candidates who can deliver high-quality IT training programs to enhance the skills and knowledge of employees or clients. Customize the responsibilities and qualifications to align with your organization's training needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an IT Trainer, you will play a crucial role in educating employees or clients on IT systems, software applications, and technology tools. You will assess training needs, develop training materials, and deliver engaging and interactive training sessions. This role requires strong technical expertise, instructional design skills, and the ability to communicate complex technical concepts in a clear and understandable manner."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess training needs and develop training plans and objectives based on organizational goals and learner requirements.",
                "Design and develop training materials, including presentations, manuals, videos, and online tutorials.",
                "Deliver training sessions in a classroom setting, virtual environment, or through online platforms.",
                "Conduct hands-on training exercises and demonstrations to reinforce learning and skills development.",
                "Evaluate training effectiveness through assessments, quizzes, and feedback surveys.",
                "Update training materials and content to reflect changes in technology or software updates.",
                "Provide one-on-one or group coaching and support to learners as needed.",
                "Collaborate with subject matter experts and IT teams to stay updated on new technologies and tools.",
                "Stay informed about industry trends and best practices in IT training and professional development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Information Technology, Computer Science, or related field; relevant certifications are a plus.",
                "Proven experience as an IT Trainer, Technical Trainer, or similar role.",
                "Strong knowledge of IT systems, software applications, and technology tools.",
                "Experience with instructional design principles and e-learning platforms.",
                "Excellent presentation, communication, and interpersonal skills.",
                "Ability to adapt training methods and techniques to meet diverse learning styles and preferences.",
                "Organizational skills and attention to detail to manage training schedules, materials, and resources.",
                "Patience, empathy, and a passion for teaching and empowering others to learn.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience and qualifications.",
                "Healthcare benefits including medical, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Flexible work schedule and remote work options.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const DataEngineerJobDescription = {
    title: "Data Engineer Job Description",
    jobRole: "A Data Engineer is responsible for designing, constructing, and maintaining scalable data pipelines and infrastructure for collecting, storing, and processing large volumes of data. They collaborate with data scientists, analysts, and other stakeholders to ensure data availability, quality, and reliability for analytics and business insights.",
    preRead: "Use this Data Engineer job description template to attract candidates who can design and implement robust data pipelines and infrastructure to support data-driven decision-making in your organization. Customize the responsibilities and qualifications to align with your specific data engineering needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Data Engineer, you will play a key role in building and maintaining the data infrastructure that powers our organization's analytics and data-driven initiatives. You will design, develop, and optimize data pipelines and ETL processes to ingest, transform, and load data from various sources into our data warehouse or lake. This role requires strong technical skills, including proficiency in programming, data modeling, and database technologies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, develop, and maintain scalable and reliable data pipelines for ingesting, processing, and transforming large volumes of data.",
                "Work closely with data scientists, analysts, and other stakeholders to understand data requirements and ensure data availability, quality, and integrity.",
                "Optimize data pipelines and ETL processes for performance, scalability, and efficiency.",
                "Implement data governance and security best practices to ensure compliance with data privacy regulations and company policies.",
                "Collaborate with infrastructure and operations teams to deploy and manage data infrastructure components, including databases, storage systems, and processing engines.",
                "Monitor and troubleshoot data pipeline issues, performance bottlenecks, and data quality issues.",
                "Document data pipelines, data models, and technical specifications for reference and knowledge sharing.",
                "Stay updated on emerging trends and technologies in data engineering, big data, and cloud computing.",
                "Contribute to the development and implementation of data engineering best practices, standards, and guidelines."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Engineering, or related field; Master’s degree preferred.",
                "Proven experience as a Data Engineer, Database Engineer, or similar role.",
                "Strong programming skills in languages such as Python, Java, Scala, or SQL.",
                "Experience with big data technologies and frameworks such as Hadoop, Spark, Kafka, or Flink.",
                "Proficiency in data modeling, schema design, and database technologies such as SQL, NoSQL, or NewSQL.",
                "Familiarity with cloud platforms such as AWS, Azure, or Google Cloud Platform.",
                "Knowledge of data warehousing concepts and technologies, including ETL, ELT, and data integration tools.",
                "Excellent problem-solving, analytical, and communication skills.",
                "Ability to work independently and collaboratively in a fast-paced environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $130,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const NetworkSecurityEngineerJobDescription = {
    title: "Network Security Engineer Job Description",
    jobRole: "A Network Security Engineer is responsible for designing, implementing, and maintaining network security solutions to protect the organization's computer systems, networks, and data from cyber threats. They assess security risks, develop security policies and procedures, and monitor network activity to detect and respond to security incidents.",
    preRead: "Use this Network Security Engineer job description template to attract candidates who can design and maintain robust network security architectures and technologies to safeguard the organization's digital assets. Customize the responsibilities and qualifications to align with your specific security needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Network Security Engineer, you will play a critical role in protecting our organization's network infrastructure and information assets from cyber threats and attacks. You will design, deploy, and manage security measures such as firewalls, intrusion detection systems, VPNs, and endpoint security solutions. This role requires strong technical skills, knowledge of networking and security principles, and the ability to stay updated on emerging threats and technologies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, implement, and maintain network security solutions, including firewalls, intrusion detection/prevention systems (IDS/IPS), VPNs, and endpoint security solutions.",
                "Conduct security assessments and risk analysis to identify vulnerabilities and develop remediation plans.",
                "Monitor network traffic and security events to detect and respond to security incidents in a timely manner.",
                "Manage security policies, access controls, and user authentication mechanisms to ensure compliance with security standards and regulations.",
                "Conduct security awareness training for employees and educate them about security best practices.",
                "Stay updated on the latest security threats, vulnerabilities, and technologies, and recommend security enhancements and solutions.",
                "Collaborate with IT teams and stakeholders to integrate security measures into network architecture and systems design.",
                "Perform security audits, penetration testing, and vulnerability assessments to evaluate the effectiveness of security controls.",
                "Document security policies, procedures, and incident response plans for reference and compliance purposes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field; Master’s degree preferred.",
                "Proven experience as a Network Security Engineer, Information Security Analyst, or similar role.",
                "Strong understanding of networking protocols, TCP/IP stack, and network security principles.",
                "Experience with security technologies and tools such as firewalls, IDS/IPS, SIEM, antivirus, and endpoint security solutions.",
                "Knowledge of security standards and regulations such as ISO 27001, NIST, HIPAA, and GDPR.",
                "Certifications such as CISSP, CEH, CompTIA Security+, or CCNA Security are preferred.",
                "Excellent analytical, problem-solving, and troubleshooting skills.",
                "Strong communication and interpersonal skills, with the ability to work effectively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SiteReliabilityEngineerJobDescription = {
    title: "Site Reliability Engineer Job Description",
    jobRole: "A Site Reliability Engineer (SRE) is responsible for ensuring the reliability, availability, and performance of large-scale, distributed software systems. SREs collaborate with development teams to design, build, and maintain resilient systems, automate deployment processes, and monitor system health to proactively identify and mitigate issues.",
    preRead: "Use this Site Reliability Engineer job description template to attract candidates who can optimize and maintain the reliability and performance of our infrastructure and applications. Customize the responsibilities and qualifications to align with your organization's needs and infrastructure.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Site Reliability Engineer, you will play a key role in ensuring the reliability and scalability of our systems and services. You will work closely with development teams to design and implement scalable architectures, automate deployment processes, and monitor system performance. This role requires a strong understanding of distributed systems, cloud computing, and software development principles."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design, build, and maintain scalable, reliable, and high-performance infrastructure and applications.",
                "Automate deployment processes and develop tools for configuration management, monitoring, and incident response.",
                "Monitor system health and performance metrics to proactively identify and resolve issues before they impact users.",
                "Implement and maintain robust disaster recovery and failover strategies to ensure system availability.",
                "Collaborate with development teams to optimize code, improve system performance, and reduce latency.",
                "Participate in on-call rotations and respond to system outages or performance incidents as needed.",
                "Document system configurations, processes, and procedures for reference and training purposes.",
                "Stay updated on emerging technologies and best practices in site reliability engineering and cloud computing.",
                "Provide technical guidance and mentorship to junior engineers and development teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or related field; Master’s degree preferred.",
                "Proven experience as a Site Reliability Engineer, DevOps Engineer, or similar role.",
                "Strong programming skills in languages such as Python, Go, or Java.",
                "Experience with cloud computing platforms such as AWS, Azure, or Google Cloud Platform.",
                "Proficiency in containerization technologies such as Docker and container orchestration tools like Kubernetes.",
                "Knowledge of infrastructure as code tools such as Terraform or Ansible.",
                "Familiarity with monitoring and logging tools such as Prometheus, Grafana, ELK stack, or Splunk.",
                "Excellent troubleshooting, problem-solving, and communication skills.",
                "Ability to work effectively in a fast-paced, collaborative environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $140,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Flexible work schedule and remote work options.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};
