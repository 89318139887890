export const RegisteredNurseJobDescription = {
    title: "Registered Nurse Job Description",
    jobRole: "A Registered Nurse (RN) provides and coordinates patient care, educates patients and the public about various health conditions, and offers advice and emotional support to patients and their families. RNs work in a variety of healthcare settings, including hospitals, clinics, and long-term care facilities.",
    preRead: "Use this Registered Nurse job description template to attract qualified candidates for nursing positions in your healthcare facility. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Registered Nurse, you will be responsible for assessing patient health problems and needs, developing and implementing nursing care plans, and maintaining medical records. You will also administer nursing care to ill, injured, convalescent, or disabled patients, and may advise patients on health maintenance and disease prevention."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patient health problems and needs.",
                "Develop and implement nursing care plans.",
                "Administer nursing care to ill, injured, or disabled patients.",
                "Monitor and report changes in patient symptoms or behavior.",
                "Educate patients and their families about various health conditions and treatment plans.",
                "Maintain accurate medical records.",
                "Administer medications and treatments as prescribed by physicians or other healthcare professionals.",
                "Operate medical equipment and assist with diagnostic tests and procedures.",
                "Collaborate with healthcare professionals to plan, implement, or evaluate patient care plans.",
                "Provide emotional support and guidance to patients and their families."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor of Science in Nursing (BSN) or Associate Degree in Nursing (ADN).",
                "Valid nursing license (RN) in the state of practice.",
                "Basic life support (BLS) and advanced cardiac life support (ACLS) certifications.",
                "Strong clinical skills and knowledge of medical terminology.",
                "Excellent communication and interpersonal skills.",
                "Ability to work well in a fast-paced environment and make quick decisions.",
                "Compassionate and empathetic approach to patient care.",
                "Attention to detail and accuracy in patient assessment and documentation.",
                "Ability to work effectively as part of a multidisciplinary healthcare team.",
                "Willingness to maintain continuing education and professional development."
            ]
        }
    ]
};

export const MedicalAssistantJobDescription = {
    title: "Medical Assistant Job Description",
    jobRole: "A Medical Assistant (MA) performs administrative and clinical tasks to support healthcare professionals, such as physicians and nurses, in providing patient care. MAs work in various healthcare settings, including hospitals, clinics, and physician offices.",
    preRead: "Use this Medical Assistant job description template to attract qualified candidates for medical assistant positions in your healthcare facility. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Assistant, you will assist healthcare professionals with patient examinations, take patient vital signs, and perform various administrative tasks, such as scheduling appointments and maintaining medical records. You will also provide direct patient care and support the delivery of healthcare services in a clinical setting."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist healthcare professionals with patient examinations and procedures.",
                "Take and record patient vital signs, such as blood pressure and temperature.",
                "Prepare patients for examinations and treatments.",
                "Administer medications and injections as directed by healthcare providers.",
                "Perform basic laboratory tests, such as collecting specimens and conducting routine tests.",
                "Clean and sterilize medical instruments and equipment.",
                "Provide patient education and instruction on healthcare topics and procedures.",
                "Schedule appointments and maintain medical records.",
                "Handle administrative tasks, such as answering phones, filing paperwork, and billing.",
                "Ensure compliance with infection control and safety protocols."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Completion of a medical assistant training program or certification.",
                "Certified Medical Assistant (CMA) or Registered Medical Assistant (RMA) preferred.",
                "Basic life support (BLS) certification.",
                "Strong organizational and multitasking skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work well in a team environment.",
                "Attention to detail and accuracy in performing clinical and administrative tasks.",
                "Knowledge of medical terminology and procedures.",
                "Willingness to maintain confidentiality and professionalism in patient interactions."
            ]
        }
    ]
};

export const HealthcareAdministratorJobDescription = {
    title: "Healthcare Administrator Job Description",
    jobRole: "A Healthcare Administrator oversees the day-to-day operations of healthcare facilities, ensuring efficient delivery of services and compliance with regulatory standards. They play a crucial role in managing staff, budgets, and resources to maintain high-quality patient care and organizational effectiveness.",
    preRead: "Use this Healthcare Administrator job description template to advertise open positions for a Healthcare Administrator in your healthcare facility. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Healthcare Administrator, you will be responsible for overseeing the administrative and operational functions of healthcare facilities, such as hospitals, clinics, or medical practices. You will manage staff, budgets, and resources to ensure smooth day-to-day operations and high-quality patient care. This role requires strong leadership, organizational, and problem-solving skills."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and coordinate daily operations of healthcare facilities.",
                "Develop and implement policies and procedures to ensure compliance with regulatory standards.",
                "Supervise administrative staff and healthcare professionals.",
                "Manage budgets, financial planning, and revenue cycle management.",
                "Oversee patient admissions, scheduling, and discharge processes.",
                "Ensure the availability of medical and non-medical supplies and equipment.",
                "Maintain electronic health records (EHR) and ensure data accuracy and security.",
                "Coordinate with medical staff, including physicians, nurses, and technicians, to optimize patient care.",
                "Participate in strategic planning and decision-making to improve organizational performance and patient outcomes.",
                "Stay updated on industry trends and developments in healthcare administration."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Healthcare Administration, Business Administration, or related field; Master’s degree preferred.",
                "Proven experience in healthcare administration or management roles.",
                "Strong leadership, communication, and interpersonal skills.",
                "Knowledge of healthcare regulations, compliance requirements, and accreditation standards.",
                "Experience with healthcare management software and electronic health records (EHR) systems.",
                "Analytical and problem-solving skills with attention to detail.",
                "Ability to work well under pressure and in a fast-paced environment.",
                "Commitment to quality patient care and organizational excellence.",
                "Ability to collaborate effectively with multidisciplinary teams.",
                "Professional certification in healthcare administration is a plus."
            ]
        }
    ]
};

export const PharmacyTechnicianJobDescription = {
    title: "Pharmacy Technician Job Description",
    jobRole: "A Pharmacy Technician assists pharmacists in dispensing medications to customers or healthcare professionals and performs various administrative tasks in a pharmacy setting. They work under the supervision of licensed pharmacists to ensure the accurate and safe distribution of medications.",
    preRead: "Use this Pharmacy Technician job description template to attract qualified candidates for pharmacy technician positions in your pharmacy or healthcare facility. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Pharmacy Technician, you will work closely with pharmacists to fill prescriptions, maintain inventory, and provide excellent customer service to patients. Your role is critical in ensuring the safe and efficient operation of the pharmacy and delivering quality healthcare services to customers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Receive and process prescription orders from customers or healthcare professionals.",
                "Prepare prescription medications by counting, pouring, and labeling medications accurately.",
                "Verify prescription information and patient details for accuracy and completeness.",
                "Assist in inventory management, including ordering, stocking, and organizing medications and supplies.",
                "Perform administrative tasks, such as answering phones, processing payments, and maintaining patient records.",
                "Provide excellent customer service by addressing inquiries, resolving issues, and offering assistance as needed.",
                "Handle and store medications safely and in accordance with pharmacy regulations and guidelines.",
                "Maintain cleanliness and organization of the pharmacy workspace.",
                "Collaborate with pharmacists and other healthcare professionals to ensure efficient pharmacy operations.",
                "Stay updated on pharmaceutical products, regulations, and industry trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; completion of a pharmacy technician training program is preferred.",
                "Certified Pharmacy Technician (CPhT) certification is a plus.",
                "Experience working in a pharmacy or healthcare setting.",
                "Strong attention to detail and accuracy in medication preparation and dispensing.",
                "Excellent communication and customer service skills.",
                "Ability to work well in a team environment and follow instructions.",
                "Basic computer skills and familiarity with pharmacy software.",
                "Knowledge of pharmaceutical terminology, drug names, and dosages.",
                "Commitment to patient safety and confidentiality.",
                "Willingness to adhere to pharmacy regulations and guidelines."
            ]
        }
    ]
};

export const PhysicalTherapistJobDescription = {
    title: "Physical Therapist Job Description",
    jobRole: "A Physical Therapist (PT) evaluates and treats patients with physical injuries or conditions to improve their mobility, relieve pain, and restore function. PTs develop personalized treatment plans, provide hands-on therapy, and educate patients on exercises and techniques to improve their physical well-being.",
    preRead: "Use this Physical Therapist job description template to attract qualified candidates for physical therapist positions in your healthcare facility. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Physical Therapist, you will assess patients' physical abilities and limitations, develop individualized treatment plans, and provide hands-on therapy to improve mobility, strength, and function. Your goal is to help patients recover from injuries or conditions and regain independence in their daily activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Evaluate patients' medical history, physical condition, and functional abilities.",
                "Develop personalized treatment plans based on patients' needs, goals, and progress.",
                "Provide hands-on therapy, such as manual techniques, exercises, and therapeutic modalities.",
                "Educate patients and their families on exercises, techniques, and home care strategies.",
                "Monitor patients' progress and adjust treatment plans as needed.",
                "Collaborate with other healthcare professionals, such as physicians, nurses, and occupational therapists, to coordinate patient care.",
                "Document patient evaluations, progress, and treatment outcomes accurately and timely.",
                "Adhere to ethical and legal standards in patient care and practice.",
                "Participate in professional development activities to stay updated on advances in physical therapy techniques and research.",
                "Ensure a safe and clean therapy environment and equipment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Doctor of Physical Therapy (DPT) degree from an accredited program.",
                "State licensure or eligibility for licensure as a Physical Therapist.",
                "Clinical experience in physical therapy settings, preferably with diverse patient populations.",
                "Strong clinical reasoning and problem-solving skills.",
                "Excellent interpersonal and communication skills.",
                "Ability to work independently and collaboratively as part of a multidisciplinary team.",
                "Empathy and compassion for patients with physical challenges.",
                "Physical stamina and dexterity to perform therapy techniques.",
                "Commitment to evidence-based practice and continuous learning.",
                "Professionalism and adherence to ethical standards."
            ]
        }
    ]
};


export const RadiologicTechnologistJobDescription = {
    title: "Radiologic Technologist Job Description",
    jobRole: "A Radiologic Technologist performs diagnostic imaging examinations, such as X-rays, MRI, and CT scans, to assist in diagnosing patients' medical conditions. They ensure the quality of images, maintain imaging equipment, and provide care to patients during procedures.",
    preRead: "Use this Radiologic Technologist job description template to advertise open positions for a Radiologic Technologist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Radiologic Technologist, you will perform diagnostic imaging examinations, such as X-rays, MRI, and CT scans, to assist in diagnosing patients' medical conditions. Your role involves ensuring the quality of images, maintaining imaging equipment, and providing care to patients during procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Perform diagnostic imaging examinations, including X-rays, MRI, and CT scans.",
                "Ensure the quality of images and proper positioning of patients.",
                "Operate and maintain imaging equipment.",
                "Provide care and comfort to patients during imaging procedures.",
                "Follow safety protocols to protect patients and staff from radiation exposure.",
                "Review and interpret physician's orders for imaging procedures.",
                "Maintain accurate patient records and documentation.",
                "Collaborate with radiologists and other healthcare professionals.",
                "Stay updated on advancements in radiologic technology and techniques.",
                "Ensure compliance with healthcare regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree in Radiologic Technology or related field.",
                "Certification by the American Registry of Radiologic Technologists (ARRT) or equivalent.",
                "Proven experience as a Radiologic Technologist.",
                "Strong knowledge of radiologic imaging techniques and equipment.",
                "Excellent communication and patient care skills.",
                "Attention to detail and accuracy in imaging procedures.",
                "Ability to work in a fast-paced healthcare environment.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to assist and position patients."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const OccupationalTherapistJobDescription = {
    title: "Occupational Therapist Job Description",
    jobRole: "An Occupational Therapist helps patients develop, recover, and improve the skills needed for daily living and working. They assess patients' conditions, create treatment plans, and provide therapeutic interventions to enhance patients' ability to perform tasks independently.",
    preRead: "Use this Occupational Therapist job description template to advertise open positions for an Occupational Therapist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Occupational Therapist, you will help patients develop, recover, and improve the skills needed for daily living and working. Your role involves assessing patients' conditions, creating treatment plans, and providing therapeutic interventions to enhance patients' ability to perform tasks independently."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patients' physical, emotional, and social conditions.",
                "Develop individualized treatment plans to address patients' needs.",
                "Provide therapeutic interventions to improve patients' abilities.",
                "Teach patients how to use adaptive equipment and techniques.",
                "Monitor and document patients' progress.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Educate patients and families on coping strategies and techniques.",
                "Maintain accurate patient records and documentation.",
                "Stay updated on advancements in occupational therapy practices.",
                "Ensure compliance with healthcare regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s or Master’s Degree in Occupational Therapy.",
                "State licensure and certification as an Occupational Therapist.",
                "Proven experience as an Occupational Therapist.",
                "Strong knowledge of occupational therapy techniques and practices.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to develop and implement effective treatment plans.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to assist patients with exercises and activities."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const SpeechLanguagePathologistJobDescription = {
    title: "Speech-Language Pathologist Job Description",
    jobRole: "A Speech-Language Pathologist assesses, diagnoses, and treats speech, language, and swallowing disorders in patients. They develop individualized treatment plans, provide therapy sessions, and work with patients to improve their communication and swallowing abilities.",
    preRead: "Use this Speech-Language Pathologist job description template to advertise open positions for a Speech-Language Pathologist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Speech-Language Pathologist, you will assess, diagnose, and treat speech, language, and swallowing disorders in patients. Your role involves developing individualized treatment plans, providing therapy sessions, and working with patients to improve their communication and swallowing abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess and diagnose speech, language, and swallowing disorders.",
                "Develop individualized treatment plans based on patients' needs.",
                "Provide therapy sessions to improve patients' communication skills.",
                "Teach patients techniques to manage and overcome speech disorders.",
                "Monitor and document patients' progress.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Educate patients and families on communication strategies.",
                "Maintain accurate patient records and documentation.",
                "Stay updated on advancements in speech-language pathology practices.",
                "Ensure compliance with healthcare regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s Degree in Speech-Language Pathology.",
                "State licensure and certification as a Speech-Language Pathologist.",
                "Proven experience as a Speech-Language Pathologist.",
                "Strong knowledge of speech-language pathology techniques and practices.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to develop and implement effective treatment plans.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to assist patients during therapy sessions."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const DentalHygienistJobDescription = {
    title: "Dental Hygienist Job Description",
    jobRole: "A Dental Hygienist provides preventive oral care under a dentist's supervision, including cleaning teeth, examining patients for oral diseases, and educating patients on oral hygiene practices.",
    preRead: "Use this Dental Hygienist job description template to advertise open positions for a Dental Hygienist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Dental Hygienist, you will provide preventive oral care under a dentist's supervision, including cleaning teeth, examining patients for oral diseases, and educating patients on oral hygiene practices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Perform dental cleanings, including scaling and polishing teeth.",
                "Examine patients for signs of oral diseases and abnormalities.",
                "Take and develop dental X-rays.",
                "Educate patients on oral hygiene practices and preventive care.",
                "Apply fluoride treatments and sealants to protect teeth.",
                "Maintain accurate patient records and documentation.",
                "Assist the dentist during dental procedures as needed.",
                "Stay updated on advancements in dental hygiene practices.",
                "Ensure compliance with health and safety regulations.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree in Dental Hygiene or related field.",
                "State licensure and certification as a Dental Hygienist.",
                "Proven experience as a Dental Hygienist.",
                "Strong knowledge of dental hygiene practices and procedures.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and precision in dental cleanings.",
                "Empathy and patience in dealing with patients.",
                "Ability to educate patients on oral health.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to perform dental procedures."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const RespiratoryTherapistJobDescription = {
    title: "Respiratory Therapist Job Description",
    jobRole: "A Respiratory Therapist assesses, treats, and cares for patients with breathing or other cardiopulmonary disorders. They provide respiratory therapy treatments, manage ventilators, and educate patients on managing respiratory conditions.",
    preRead: "Use this Respiratory Therapist job description template to advertise open positions for a Respiratory Therapist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Respiratory Therapist, you will assess, treat, and care for patients with breathing or other cardiopulmonary disorders. Your role involves providing respiratory therapy treatments, managing ventilators, and educating patients on managing respiratory conditions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patients' respiratory conditions and develop treatment plans.",
                "Administer respiratory therapy treatments, such as oxygen therapy and aerosol medications.",
                "Manage and monitor ventilators and other respiratory equipment.",
                "Perform diagnostic tests, such as pulmonary function tests and blood gas analyses.",
                "Educate patients and families on managing respiratory conditions and using equipment.",
                "Monitor and document patients' progress and response to treatment.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Stay updated on advancements in respiratory therapy practices.",
                "Ensure compliance with healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s or Bachelor’s Degree in Respiratory Therapy.",
                "State licensure and certification as a Respiratory Therapist.",
                "Proven experience as a Respiratory Therapist.",
                "Strong knowledge of respiratory therapy techniques and equipment.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy in treatments.",
                "Empathy and patience in dealing with patients.",
                "Ability to work in a fast-paced healthcare environment.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to perform respiratory therapy treatments."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const ClinicalLaboratoryTechnicianJobDescription = {
    title: "Clinical Laboratory Technician Job Description",
    jobRole: "A Clinical Laboratory Technician performs laboratory tests and procedures to help diagnose, treat, and prevent diseases. They operate and maintain laboratory equipment, analyze samples, and ensure accuracy in test results.",
    preRead: "Use this Clinical Laboratory Technician job description template to advertise open positions for a Clinical Laboratory Technician in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Clinical Laboratory Technician, you will perform laboratory tests and procedures to help diagnose, treat, and prevent diseases. Your role involves operating and maintaining laboratory equipment, analyzing samples, and ensuring accuracy in test results."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Perform routine laboratory tests and procedures.",
                "Analyze blood, urine, and other body fluids for medical conditions.",
                "Operate and maintain laboratory equipment.",
                "Ensure accuracy and reliability of test results.",
                "Prepare and maintain accurate laboratory records and documentation.",
                "Follow safety protocols and procedures in the laboratory.",
                "Collaborate with healthcare professionals to interpret test results.",
                "Stay updated on advancements in laboratory technology and techniques.",
                "Ensure compliance with healthcare regulations and standards.",
                "Provide accurate and timely results to support patient care."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree in Clinical Laboratory Science or related field.",
                "Certification as a Clinical Laboratory Technician preferred.",
                "Proven experience as a Clinical Laboratory Technician.",
                "Strong knowledge of laboratory testing techniques and procedures.",
                "Excellent analytical and problem-solving skills.",
                "Attention to detail and accuracy in laboratory work.",
                "Good communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to perform laboratory tasks."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const EmergencyMedicalTechnicianEMTJobDescription = {
    title: "Emergency Medical Technician (EMT) Job Description",
    jobRole: "An Emergency Medical Technician (EMT) provides prehospital emergency medical care and transportation for patients. They respond to emergency calls, perform medical procedures, and ensure patients receive timely and appropriate care.",
    preRead: "Use this Emergency Medical Technician (EMT) job description template to advertise open positions for an EMT in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Emergency Medical Technician (EMT), you will provide prehospital emergency medical care and transportation for patients. Your role involves responding to emergency calls, performing medical procedures, and ensuring patients receive timely and appropriate care."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Respond to emergency calls and provide prehospital medical care.",
                "Assess patients' conditions and perform necessary medical procedures.",
                "Transport patients to medical facilities for further treatment.",
                "Operate and maintain emergency medical equipment.",
                "Document patient information and treatment provided.",
                "Communicate with medical staff and emergency personnel.",
                "Ensure patients' safety and comfort during transport.",
                "Stay updated on advancements in emergency medical care.",
                "Ensure compliance with healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Certification as an Emergency Medical Technician (EMT).",
                "Proven experience as an EMT.",
                "Strong knowledge of emergency medical procedures and protocols.",
                "Excellent communication and interpersonal skills.",
                "Ability to remain calm under pressure.",
                "Physical stamina and strength to perform emergency medical tasks.",
                "Good problem-solving and decision-making skills.",
                "Knowledge of safety protocols and regulations.",
                "Compassion and empathy in dealing with patients."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const DietitianJobDescription = {
    title: "Dietitian Job Description",
    jobRole: "A Dietitian provides dietary and nutritional guidance to promote healthy eating habits and manage medical conditions. They assess patients' nutritional needs, develop meal plans, and educate patients on nutrition and healthy lifestyles.",
    preRead: "Use this Dietitian job description template to advertise open positions for a Dietitian in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Dietitian, you will provide dietary and nutritional guidance to promote healthy eating habits and manage medical conditions. Your role involves assessing patients' nutritional needs, developing meal plans, and educating patients on nutrition and healthy lifestyles."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patients' dietary and nutritional needs.",
                "Develop and implement individualized meal plans.",
                "Provide nutrition education and counseling to patients.",
                "Monitor and document patients' progress and outcomes.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Stay updated on advancements in nutrition and dietetics.",
                "Ensure compliance with healthcare regulations and standards.",
                "Promote healthy eating habits and lifestyle choices.",
                "Conduct nutritional research and participate in community outreach.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s or Master’s Degree in Dietetics, Nutrition, or related field.",
                "State licensure and certification as a Dietitian.",
                "Proven experience as a Dietitian.",
                "Strong knowledge of nutrition and dietetics principles.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to develop and implement effective meal plans.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Commitment to promoting health and wellness."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const LicensedPracticalNurseLPNJobDescription = {
    title: "Licensed Practical Nurse (LPN) Job Description",
    jobRole: "A Licensed Practical Nurse (LPN) provides basic nursing care under the supervision of registered nurses and doctors. They assist with patient care, administer medications, and monitor patients' health.",
    preRead: "Use this Licensed Practical Nurse (LPN) job description template to advertise open positions for an LPN in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Licensed Practical Nurse (LPN), you will provide basic nursing care under the supervision of registered nurses and doctors. Your role involves assisting with patient care, administering medications, and monitoring patients' health."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist with basic patient care activities.",
                "Administer medications and treatments as prescribed.",
                "Monitor and document patients' health status.",
                "Assist with medical procedures and examinations.",
                "Provide wound care and change dressings.",
                "Educate patients and families on health and wellness.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Maintain accurate patient records and documentation.",
                "Ensure compliance with healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Diploma in Practical Nursing from an accredited program.",
                "State licensure and certification as a Licensed Practical Nurse (LPN).",
                "Proven experience as an LPN.",
                "Strong knowledge of basic nursing care principles.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to work under the supervision of registered nurses and doctors.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Physical stamina to perform nursing tasks."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const SurgicalTechnologistJobDescription = {
    title: "Surgical Technologist Job Description",
    jobRole: "A Surgical Technologist assists in surgical operations by preparing operating rooms, arranging equipment, and providing assistance to surgeons during procedures. They ensure a sterile environment and maintain surgical instruments.",
    preRead: "Use this Surgical Technologist job description template to advertise open positions for a Surgical Technologist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Surgical Technologist, you will assist in surgical operations by preparing operating rooms, arranging equipment, and providing assistance to surgeons during procedures. Your role involves ensuring a sterile environment and maintaining surgical instruments."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare operating rooms and surgical instruments.",
                "Assist surgeons during surgical procedures.",
                "Ensure a sterile and safe environment in the operating room.",
                "Arrange surgical equipment and supplies.",
                "Monitor and maintain aseptic techniques.",
                "Handle and pass instruments and supplies to surgeons.",
                "Assist with patient positioning and draping.",
                "Document and track surgical procedures and outcomes.",
                "Stay updated on advancements in surgical technology and techniques.",
                "Ensure compliance with healthcare regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree or certificate in Surgical Technology.",
                "Certification as a Surgical Technologist preferred.",
                "Proven experience as a Surgical Technologist.",
                "Strong knowledge of surgical procedures and instruments.",
                "Excellent attention to detail and accuracy.",
                "Good communication and interpersonal skills.",
                "Ability to work effectively under pressure.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Physical stamina to assist in surgical procedures."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const PhlebotomistJobDescription = {
    title: "Phlebotomist Job Description",
    jobRole: "A Phlebotomist collects blood samples from patients for laboratory testing, transfusions, or donations. They ensure proper handling and labeling of specimens, maintain equipment, and provide a positive patient experience.",
    preRead: "Use this Phlebotomist job description template to advertise open positions for a Phlebotomist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Phlebotomist, you will collect blood samples from patients for laboratory testing, transfusions, or donations. Your role involves ensuring proper handling and labeling of specimens, maintaining equipment, and providing a positive patient experience."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect blood samples from patients using venipuncture or capillary puncture techniques.",
                "Ensure proper handling and labeling of blood specimens.",
                "Maintain phlebotomy equipment and supplies.",
                "Prepare patients for blood collection procedures.",
                "Provide a positive and compassionate patient experience.",
                "Document and track collected samples.",
                "Follow safety protocols and procedures in blood collection.",
                "Collaborate with healthcare professionals to ensure accurate testing.",
                "Stay updated on advancements in phlebotomy techniques.",
                "Ensure compliance with healthcare regulations and standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Certification as a Phlebotomist preferred.",
                "Proven experience as a Phlebotomist.",
                "Strong knowledge of blood collection techniques and procedures.",
                "Excellent communication and interpersonal skills.",
                "Attention to detail and accuracy in specimen handling.",
                "Empathy and patience in dealing with patients.",
                "Ability to work in a fast-paced healthcare environment.",
                "Knowledge of safety protocols and regulations.",
                "Physical stamina to perform phlebotomy tasks."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const MedicalRecordsTechnicianJobDescription = {
    title: "Medical Records Technician Job Description",
    jobRole: "A Medical Records Technician manages and organizes patients' medical records. They ensure the accuracy, security, and confidentiality of health information, and use electronic health record (EHR) systems to maintain and update records.",
    preRead: "Use this Medical Records Technician job description template to advertise open positions for a Medical Records Technician in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Records Technician, you will manage and organize patients' medical records. Your role involves ensuring the accuracy, security, and confidentiality of health information, and using electronic health record (EHR) systems to maintain and update records."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and organize medical records and health information.",
                "Ensure the accuracy and completeness of medical records.",
                "Use electronic health record (EHR) systems to maintain and update records.",
                "Protect the confidentiality and security of health information.",
                "Retrieve and provide medical records for healthcare professionals.",
                "Follow healthcare regulations and standards for record-keeping.",
                "Stay updated on advancements in health information technology.",
                "Collaborate with healthcare professionals to ensure accurate documentation.",
                "Assist in audits and reviews of medical records.",
                "Provide excellent customer service to patients and staff."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree in Health Information Technology or related field.",
                "Certification as a Registered Health Information Technician (RHIT) preferred.",
                "Proven experience as a Medical Records Technician.",
                "Strong knowledge of medical record-keeping principles and procedures.",
                "Excellent attention to detail and accuracy.",
                "Good communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Knowledge of healthcare regulations and standards.",
                "Proficiency in using electronic health record (EHR) systems.",
                "Commitment to maintaining confidentiality and security of health information."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const CertifiedNursingAssistantCNAJobDescription = {
    title: "Certified Nursing Assistant (CNA) Job Description",
    jobRole: "A Certified Nursing Assistant (CNA) provides basic patient care under the direction of nursing staff. They assist with daily living activities, monitor patients' health, and ensure patients' comfort and safety.",
    preRead: "Use this Certified Nursing Assistant (CNA) job description template to advertise open positions for a CNA in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Certified Nursing Assistant (CNA), you will provide basic patient care under the direction of nursing staff. Your role involves assisting with daily living activities, monitoring patients' health, and ensuring patients' comfort and safety."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist patients with daily living activities such as bathing, dressing, and eating.",
                "Monitor and document patients' health status.",
                "Provide basic patient care and comfort.",
                "Assist with medical procedures and treatments.",
                "Help patients with mobility and physical exercises.",
                "Ensure a clean and safe environment for patients.",
                "Communicate with nursing staff and healthcare professionals.",
                "Provide emotional support to patients and families.",
                "Follow healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Certification as a Nursing Assistant (CNA).",
                "Proven experience as a CNA.",
                "Strong knowledge of basic patient care principles.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to work under the direction of nursing staff.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Physical stamina to perform patient care tasks."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const HomeHealthAideJobDescription = {
    title: "Home Health Aide Job Description",
    jobRole: "A Home Health Aide provides in-home care and assistance to patients with chronic illnesses, disabilities, or cognitive impairments. They assist with daily living activities, monitor patients' health, and provide companionship.",
    preRead: "Use this Home Health Aide job description template to advertise open positions for a Home Health Aide in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Home Health Aide, you will provide in-home care and assistance to patients with chronic illnesses, disabilities, or cognitive impairments. Your role involves assisting with daily living activities, monitoring patients' health, and providing companionship."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist patients with daily living activities such as bathing, dressing, and eating.",
                "Monitor and document patients' health status.",
                "Provide basic patient care and comfort.",
                "Assist with medication administration and reminders.",
                "Help patients with mobility and physical exercises.",
                "Ensure a clean and safe environment for patients.",
                "Provide companionship and emotional support.",
                "Communicate with healthcare professionals and family members.",
                "Follow healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Certification as a Home Health Aide preferred.",
                "Proven experience as a Home Health Aide.",
                "Strong knowledge of basic patient care principles.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to work independently and as part of a team.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Physical stamina to perform patient care tasks."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const GeneticCounselorJobDescription = {
    title: "Genetic Counselor Job Description",
    jobRole: "A Genetic Counselor provides information and support to patients and families regarding genetic conditions and risks. They assess family history, educate patients on genetic testing, and help interpret test results.",
    preRead: "Use this Genetic Counselor job description template to advertise open positions for a Genetic Counselor in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Genetic Counselor, you will provide information and support to patients and families regarding genetic conditions and risks. Your role involves assessing family history, educating patients on genetic testing, and helping interpret test results."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patients' family history and genetic risks.",
                "Provide information and counseling on genetic conditions.",
                "Educate patients on genetic testing and its implications.",
                "Interpret and explain genetic test results to patients.",
                "Develop and implement individualized care plans.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Stay updated on advancements in genetics and genomics.",
                "Ensure compliance with healthcare regulations and standards.",
                "Participate in research and community outreach activities.",
                "Provide compassionate and professional support to patients and families."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s Degree in Genetic Counseling or related field.",
                "Certification as a Genetic Counselor (CGC).",
                "Proven experience as a Genetic Counselor.",
                "Strong knowledge of genetics and genetic counseling principles.",
                "Excellent communication and interpersonal skills.",
                "Empathy and patience in dealing with patients.",
                "Ability to interpret and explain complex genetic information.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Commitment to ongoing education and professional development."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const CardiovascularTechnologistJobDescription = {
    title: "Cardiovascular Technologist Job Description",
    jobRole: "A Cardiovascular Technologist assists in diagnosing and treating heart and blood vessel conditions. They perform diagnostic imaging procedures, monitor patients' cardiovascular health, and assist in cardiac interventions.",
    preRead: "Use this Cardiovascular Technologist job description template to advertise open positions for a Cardiovascular Technologist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Cardiovascular Technologist, you will assist in diagnosing and treating heart and blood vessel conditions. Your role involves performing diagnostic imaging procedures, monitoring patients' cardiovascular health, and assisting in cardiac interventions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Perform diagnostic imaging procedures such as echocardiograms and ultrasounds.",
                "Monitor patients' cardiovascular health during procedures.",
                "Assist in cardiac catheterizations and other interventions.",
                "Prepare patients for diagnostic and treatment procedures.",
                "Maintain and operate cardiovascular equipment.",
                "Document and track patients' cardiovascular health data.",
                "Collaborate with healthcare professionals to coordinate care.",
                "Stay updated on advancements in cardiovascular technology.",
                "Ensure compliance with healthcare regulations and standards.",
                "Provide compassionate and professional care to patients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s or Bachelor’s Degree in Cardiovascular Technology or related field.",
                "Certification as a Cardiovascular Technologist (CVT).",
                "Proven experience as a Cardiovascular Technologist.",
                "Strong knowledge of cardiovascular technology and procedures.",
                "Excellent attention to detail and accuracy.",
                "Good communication and interpersonal skills.",
                "Ability to work effectively under pressure.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Physical stamina to assist in cardiovascular procedures."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const BiomedicalEngineerJobDescription = {
    title: "Biomedical Engineer Job Description",
    jobRole: "A Biomedical Engineer designs and develops medical devices and equipment. They work on improving healthcare technology, conduct research, and ensure the safety and effectiveness of biomedical devices.",
    preRead: "Use this Biomedical Engineer job description template to advertise open positions for a Biomedical Engineer in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Biomedical Engineer, you will design and develop medical devices and equipment. Your role involves improving healthcare technology, conducting research, and ensuring the safety and effectiveness of biomedical devices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and develop medical devices and equipment.",
                "Conduct research to improve healthcare technology.",
                "Test and evaluate the safety and effectiveness of biomedical devices.",
                "Collaborate with healthcare professionals to understand needs and requirements.",
                "Ensure compliance with healthcare regulations and standards.",
                "Document and track the development and testing of devices.",
                "Stay updated on advancements in biomedical engineering.",
                "Provide technical support and training to healthcare staff.",
                "Participate in clinical trials and research studies.",
                "Ensure the quality and reliability of biomedical devices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s or Master’s Degree in Biomedical Engineering or related field.",
                "Proven experience as a Biomedical Engineer.",
                "Strong knowledge of biomedical engineering principles and techniques.",
                "Excellent problem-solving and analytical skills.",
                "Attention to detail and accuracy in design and testing.",
                "Good communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Knowledge of safety protocols and regulations.",
                "Good organizational and time-management skills.",
                "Commitment to ongoing education and professional development."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};


export const HealthInformationTechnicianJobDescription = {
    title: "Health Information Technician Job Description",
    jobRole: "A Health Information Technician manages and organizes patients' health information. They ensure the accuracy, security, and confidentiality of health records, and use electronic health record (EHR) systems to maintain and update records.",
    preRead: "Use this Health Information Technician job description template to advertise open positions for a Health Information Technician in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Health Information Technician, you will manage and organize patients' health information. Your role involves ensuring the accuracy, security, and confidentiality of health records, and using electronic health record (EHR) systems to maintain and update records."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and organize health information and records.",
                "Ensure the accuracy and completeness of health records.",
                "Use electronic health record (EHR) systems to maintain and update records.",
                "Protect the confidentiality and security of health information.",
                "Retrieve and provide health information for healthcare professionals.",
                "Follow healthcare regulations and standards for record-keeping.",
                "Stay updated on advancements in health information technology."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s Degree in Health Information Technology or related field.",
                "Certification as a Registered Health Information Technician (RHIT) preferred.",
                "Proven experience as a Health Information Technician.",
                "Strong knowledge of health information management principles and practices.",
                "Excellent attention to detail and accuracy.",
                "Good communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Knowledge of electronic health record (EHR) systems.",
                "Commitment to maintaining confidentiality and security of health information."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Competitive salary range based on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance.",
                "Retirement savings plan with company match.",
                "Paid time off and holidays.",
                "Professional development opportunities.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const NursePractitionerJobDescription = {
    title: "Nurse Practitioner Job Description",
    jobRole: "A Nurse Practitioner (NP) is an advanced practice registered nurse who provides primary and specialty healthcare services to patients of all ages. NPs assess patients, diagnose conditions, prescribe medications, and develop treatment plans in collaboration with physicians and other healthcare professionals.",
    preRead: "Use this Nurse Practitioner job description template to advertise open positions for an NP in your healthcare organization. Customize the responsibilities and qualifications according to your specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Nurse Practitioner, you will play a crucial role in delivering comprehensive healthcare services to patients. You will assess patients' health status, order and interpret diagnostic tests, diagnose illnesses, develop treatment plans, and provide patient education and counseling. Your scope of practice may vary depending on state regulations and specialty area."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct comprehensive health assessments, including physical examinations and medical histories.",
                "Order and interpret diagnostic tests, such as laboratory tests and imaging studies.",
                "Diagnose and treat acute and chronic illnesses, injuries, and conditions.",
                "Prescribe medications, treatments, and therapies based on assessment findings and clinical guidelines.",
                "Develop and implement individualized treatment plans in collaboration with patients and other healthcare providers.",
                "Provide patient education and counseling on preventive healthcare, disease management, and lifestyle modifications.",
                "Monitor patients' progress and response to treatment, adjusting plans as necessary.",
                "Coordinate patient care with other members of the healthcare team, including physicians, specialists, nurses, and allied health professionals.",
                "Document patient care activities, treatment plans, and outcomes in electronic health records (EHRs) or other medical records systems.",
                "Participate in quality improvement initiatives, research projects, and continuing education activities to enhance clinical practice and patient outcomes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's or Doctoral degree in Nursing from an accredited Nurse Practitioner program.",
                "Current, unrestricted Nurse Practitioner license in the state of practice.",
                "Certification as a Nurse Practitioner by a recognized national certifying body (e.g., AANP, ANCC).",
                "Clinical experience as a Nurse Practitioner in primary care, family practice, or specialty area preferred.",
                "Strong clinical assessment and diagnostic skills.",
                "Proficiency in performing procedures relevant to the specialty area (e.g., suturing, casting, minor surgical procedures).",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and collaboratively within a multidisciplinary team.",
                "Strong critical thinking and problem-solving abilities.",
                "Commitment to evidence-based practice, patient-centered care, and professional growth.",
                "Adherence to ethical and legal standards of nursing practice.",
                "Willingness to maintain certification and licensure through continuing education and professional development activities."
            ]
        }
    ]
};

export const OrthopedicTechnicianJobDescription = {
    title: "Orthopedic Technician Job Description",
    jobRole: "An Orthopedic Technician specializes in assisting orthopedic surgeons with patient care, treatment procedures, and medical equipment related to orthopedic conditions and injuries. They perform tasks such as casting, splinting, and applying traction under the supervision of healthcare professionals.",
    preRead: "Use this Orthopedic Technician job description template to advertise open positions for an Orthopedic Technician in your healthcare facility. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Orthopedic Technician, you will play a crucial role in providing support to orthopedic surgeons and other healthcare providers in the diagnosis, treatment, and management of orthopedic conditions. Your responsibilities may include preparing patients for procedures, assisting with casting and splinting, and maintaining orthopedic equipment and supplies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist orthopedic surgeons and physicians in the evaluation and treatment of patients with orthopedic conditions and injuries.",
                "Prepare patients for orthopedic procedures, including positioning and prepping surgical sites.",
                "Apply and remove casts, splints, and braces according to physician orders and established protocols.",
                "Assist with wound care, suture removal, and dressing changes.",
                "Take and record patients' vital signs and other clinical measurements as directed.",
                "Provide patient education on orthopedic conditions, treatment plans, and postoperative care.",
                "Ensure proper functioning and maintenance of orthopedic equipment and supplies.",
                "Sterilize and maintain surgical instruments and equipment.",
                "Assist with patient transport, transfers, and ambulation as needed.",
                "Document patient care activities and maintain accurate medical records.",
                "Adhere to infection control protocols and maintain a clean and safe patient care environment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; completion of an accredited Orthopedic Technician program preferred.",
                "Certification as an Orthopedic Technician (COT) or Orthopedic Technologist (OT) preferred.",
                "Previous experience in orthopedic or medical assisting role preferred.",
                "Knowledge of orthopedic casting and splinting techniques.",
                "Familiarity with orthopedic equipment and surgical instruments.",
                "Strong interpersonal and communication skills.",
                "Ability to work effectively in a fast-paced healthcare environment.",
                "Attention to detail and accuracy in patient care.",
                "Physical stamina and ability to lift and move patients as needed.",
                "Basic computer skills for medical record documentation.",
                "Commitment to patient safety and quality care."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $50,000 per year, depending on experience and location.",
                "Health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and sick leave.",
                "Continuing education and professional development opportunities.",
                "Employee assistance program for counseling and support services.",
                "Discounts on healthcare services and products.",
                "Opportunities for career advancement within the organization."
            ]
        }
    ]
};

export const PatientCareCoordinatorJobDescription = {
    title: "Patient Care Coordinator Job Description",
    jobRole: "A Patient Care Coordinator plays a vital role in facilitating communication and coordination between patients, healthcare providers, and support staff to ensure high-quality patient care. They assist patients with scheduling appointments, coordinating referrals, and accessing healthcare services.",
    preRead: "Use this Patient Care Coordinator job description template to advertise open positions for a Patient Care Coordinator in your healthcare facility. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Patient Care Coordinator, you will be responsible for coordinating and managing the delivery of healthcare services to patients. You will serve as a liaison between patients and healthcare providers, helping to ensure that patients receive timely and appropriate care. Your role may also involve administrative tasks such as scheduling appointments, maintaining patient records, and verifying insurance coverage."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Schedule patient appointments, consultations, and follow-up visits.",
                "Coordinate referrals to specialists, diagnostic tests, and other healthcare services as needed.",
                "Communicate with patients to provide information, instructions, and support regarding their care.",
                "Verify patient insurance coverage and obtain authorizations for procedures and services.",
                "Maintain accurate and up-to-date patient records, including demographic information, medical history, and treatment plans.",
                "Assist patients with completing paperwork, forms, and consent documents.",
                "Collaborate with healthcare providers and support staff to ensure efficient and effective patient care.",
                "Address patient inquiries, concerns, and complaints in a timely and professional manner.",
                "Follow established protocols and procedures for patient care coordination and documentation.",
                "Participate in training and professional development activities to enhance job knowledge and skills.",
                "Adhere to privacy and confidentiality regulations in handling patient information."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate or bachelor's degree in healthcare administration or related field preferred.",
                "Previous experience in a healthcare setting, preferably in a patient care coordination or administrative role.",
                "Knowledge of medical terminology, procedures, and insurance billing processes.",
                "Strong organizational and multitasking skills.",
                "Excellent communication and interpersonal abilities.",
                "Proficiency in computer applications such as electronic health records (EHR) systems and Microsoft Office.",
                "Ability to work effectively in a team environment.",
                "Customer service-oriented mindset with empathy and compassion for patients.",
                "Attention to detail and accuracy in managing patient information and documentation.",
                "Ability to handle sensitive and confidential information with discretion.",
                "Flexibility to adapt to changing priorities and workflow demands in a fast-paced healthcare setting."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $45,000 per year, depending on experience and location.",
                "Health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and sick leave.",
                "Continuing education and professional development opportunities.",
                "Employee assistance program for counseling and support services.",
                "Discounts on healthcare services and products.",
                "Opportunities for career advancement within the organization."
            ]
        }
    ]
};

export const MedicalBillingCodingSpecialistJobDescription = {
    title: "Medical Billing and Coding Specialist Job Description",
    jobRole: "A Medical Billing and Coding Specialist plays a crucial role in the healthcare industry by accurately translating medical diagnoses and procedures into numerical codes for billing and insurance purposes. They ensure proper reimbursement for healthcare services provided by accurately assigning codes and submitting claims to insurance companies and government healthcare programs.",
    preRead: "Use this Medical Billing and Coding Specialist job description template to advertise open positions for a Medical Billing and Coding Specialist in your healthcare facility. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Billing and Coding Specialist, you will be responsible for accurately translating medical diagnoses, procedures, and services into numeric codes for billing and insurance purposes. Your role will involve reviewing medical records, assigning appropriate codes, and submitting claims to insurance companies and government healthcare programs to ensure timely reimbursement for healthcare services provided."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Review medical records and documentation to accurately assign diagnostic and procedural codes according to coding guidelines and conventions.",
                "Translate medical terminology and abbreviations into standardized codes using ICD (International Classification of Diseases) and CPT (Current Procedural Terminology) code sets.",
                "Ensure accuracy and completeness of coded data for billing and reimbursement purposes.",
                "Verify patient insurance coverage and eligibility for services, and obtain necessary authorizations and referrals.",
                "Submit claims and encounter forms to insurance carriers and government healthcare programs in a timely manner.",
                "Follow up on unpaid claims and denied claims, and resolve billing discrepancies or coding errors.",
                "Adhere to privacy and confidentiality regulations in handling patient information and medical records.",
                "Maintain up-to-date knowledge of coding guidelines, regulations, and industry trends.",
                "Collaborate with healthcare providers, billing staff, and insurance companies to resolve coding-related issues and optimize reimbursement.",
                "Participate in coding audits and compliance reviews to ensure accuracy and integrity of coded data.",
                "Provide coding training and support to healthcare providers and staff as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate degree or certification in medical coding or related field preferred.",
                "Certified Professional Coder (CPC) or Certified Coding Specialist (CCS) certification preferred.",
                "Previous experience in medical billing and coding or healthcare administration.",
                "Knowledge of medical terminology, anatomy, physiology, and disease processes.",
                "Proficiency in ICD and CPT coding systems and coding software applications.",
                "Strong attention to detail and accuracy in coding and data entry.",
                "Excellent analytical and problem-solving skills.",
                "Ability to work effectively in a team environment and independently with minimal supervision.",
                "Strong communication and interpersonal abilities.",
                "Familiarity with healthcare billing and reimbursement processes.",
                "Ability to prioritize tasks and meet deadlines in a fast-paced healthcare environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $35,000 - $50,000 per year, depending on experience and certification.",
                "Health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and sick leave.",
                "Continuing education and professional development opportunities.",
                "Employee assistance program for counseling and support services.",
                "Opportunities for career advancement within the organization.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const HealthServicesManagerJobDescription = {
    title: "Health Services Manager Job Description",
    jobRole: "A Health Services Manager oversees the administrative and operational aspects of healthcare facilities, ensuring efficient delivery of healthcare services and compliance with regulatory standards. They manage staff, finances, and facility operations to maintain high-quality patient care and organizational effectiveness.",
    preRead: "Use this Health Services Manager job description template to advertise open positions for a Health Services Manager in your healthcare organization. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Health Services Manager, you will be responsible for overseeing the administrative and operational functions of healthcare facilities, including hospitals, clinics, and long-term care facilities. Your role will involve managing staff, budgets, and resources to ensure the delivery of high-quality patient care and compliance with regulatory standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement organizational policies and procedures to ensure efficient and effective healthcare service delivery.",
                "Manage healthcare facility operations, including staffing, budgeting, scheduling, and resource allocation.",
                "Supervise administrative staff, healthcare professionals, and support personnel.",
                "Oversee patient care services to ensure compliance with quality and safety standards.",
                "Monitor and evaluate healthcare facility performance, identify areas for improvement, and implement corrective actions.",
                "Coordinate with medical staff, department heads, and external stakeholders to optimize patient care processes and outcomes.",
                "Ensure compliance with healthcare regulations, accreditation standards, and legal requirements.",
                "Manage financial operations, including budget development, revenue cycle management, and cost control.",
                "Develop and maintain relationships with vendors, suppliers, and community partners.",
                "Participate in strategic planning and decision-making to support organizational goals and objectives.",
                "Provide leadership and guidance to healthcare staff, fostering a culture of teamwork, accountability, and professional development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in healthcare administration, business administration, or related field; master's degree preferred.",
                "Previous experience in healthcare management or administration.",
                "Strong leadership and organizational skills.",
                "Excellent communication and interpersonal abilities.",
                "Knowledge of healthcare regulations, policies, and procedures.",
                "Financial management skills, including budgeting and financial analysis.",
                "Ability to make sound decisions under pressure and prioritize competing demands.",
                "Problem-solving and conflict resolution skills.",
                "Attention to detail and accuracy in administrative tasks.",
                "Ability to adapt to changing healthcare environments and technologies."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and holidays.",
                "Professional development opportunities, including tuition reimbursement.",
                "Employee wellness programs and resources.",
                "Opportunities for career advancement and leadership development.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const MedicalTranscriptionistJobDescription = {
    title: "Medical Transcriptionist Job Description",
    jobRole: "A Medical Transcriptionist listens to dictated recordings from healthcare professionals and transcribes them into written reports, such as medical histories, discharge summaries, and consultation notes. They ensure accuracy, clarity, and adherence to medical terminology and standards.",
    preRead: "Use this Medical Transcriptionist job description template to advertise open positions for a Medical Transcriptionist in your healthcare organization. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Transcriptionist, you will be responsible for listening to recorded dictations from healthcare professionals and accurately transcribing them into written reports. Your role will involve ensuring the clarity, accuracy, and compliance with medical terminology and standards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Listen to recorded dictations from healthcare professionals, including physicians, nurses, and other medical staff.",
                "Transcribe dictated recordings into written reports, such as medical histories, progress notes, discharge summaries, and consultation notes.",
                "Ensure accuracy and completeness of transcribed reports, including correct spelling, grammar, and medical terminology usage.",
                "Verify patient information, medical procedures, diagnoses, and treatment plans.",
                "Format and organize transcribed reports according to established guidelines and standards.",
                "Edit and proofread transcribed reports to ensure clarity and coherence.",
                "Maintain confidentiality and security of patient information and medical records.",
                "Communicate with healthcare providers to clarify dictations or obtain additional information as needed.",
                "Adhere to deadlines and productivity targets for transcribing reports.",
                "Stay updated on medical terminology, procedures, and documentation requirements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; completion of a medical transcriptionist training program preferred.",
                "Proven experience as a medical transcriptionist or in a similar role.",
                "Excellent listening and typing skills with a high level of accuracy.",
                "Proficiency in medical terminology, anatomy, and physiology.",
                "Knowledge of medical transcription guidelines and standards.",
                "Familiarity with transcription software and equipment.",
                "Attention to detail and ability to maintain focus for extended periods.",
                "Ability to work independently and meet deadlines.",
                "Excellent communication and interpersonal skills.",
                "Adherence to confidentiality and privacy regulations."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $45,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and holidays.",
                "Professional development opportunities, including continuing education programs.",
                "Flexible work schedule and remote work options."
            ]
        }
    ]
};

export const AnesthesiologistAssistantJobDescription = {
    title: "Anesthesiologist Assistant Job Description",
    jobRole: "An Anesthesiologist Assistant works under the supervision of an anesthesiologist to provide anesthesia care to patients before, during, and after medical procedures. They assist with anesthesia administration, monitor patients' vital signs, and ensure their safety and comfort throughout the procedure.",
    preRead: "Use this Anesthesiologist Assistant job description template to advertise open positions for an Anesthesiologist Assistant in your healthcare organization. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Anesthesiologist Assistant, you will work closely with anesthesiologists to provide anesthesia care to patients undergoing medical procedures. Your role will involve assisting with anesthesia administration, monitoring patients' vital signs, and ensuring their safety and comfort throughout the procedure."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist anesthesiologists in administering anesthesia to patients before, during, and after medical procedures.",
                "Prepare anesthesia equipment and medications for administration.",
                "Monitor patients' vital signs, such as heart rate, blood pressure, and oxygen saturation, during anesthesia.",
                "Assist with airway management and ventilation as needed.",
                "Administer medications and fluids intravenously as directed by the anesthesiologist.",
                "Monitor patients' responses to anesthesia and adjust dosages as necessary.",
                "Ensure patients' safety and comfort throughout the procedure.",
                "Document anesthesia care provided and patients' responses to treatment.",
                "Assist with patient positioning and preparation for surgery.",
                "Provide post-anesthesia care and monitor patients' recovery from anesthesia."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in a health-related field; completion of an accredited Anesthesiologist Assistant program.",
                "Certification as an Anesthesiologist Assistant (CAA) through the National Commission for Certification of Anesthesiologist Assistants (NCCAA).",
                "Clinical experience in anesthesia or critical care settings.",
                "Knowledge of anesthesia equipment, medications, and procedures.",
                "Strong critical thinking and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work effectively in a fast-paced medical environment.",
                "Attention to detail and ability to follow protocols and guidelines.",
                "Ability to work collaboratively as part of a healthcare team.",
                "Adherence to patient safety protocols and standards."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $120,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and holidays.",
                "Professional development opportunities, including continuing education programs.",
                "Flexible work schedule and potential for overtime pay."
            ]
        }
    ]
};

export const ClinicalResearchCoordinatorJobDescription = {
    title: "Clinical Research Coordinator Job Description",
    jobRole: "A Clinical Research Coordinator manages clinical research studies, including participant recruitment, data collection, and compliance with regulatory requirements. They collaborate with investigators, sponsors, and regulatory agencies to ensure the successful execution of research protocols.",
    preRead: "Use this Clinical Research Coordinator job description template to advertise open positions for a Clinical Research Coordinator in your research institution or healthcare organization. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Clinical Research Coordinator, you will be responsible for managing clinical research studies from initiation to closeout. Your role will involve coordinating study activities, ensuring compliance with regulatory requirements, and collaborating with investigators and sponsors to achieve research objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate all aspects of clinical research studies, including participant recruitment, informed consent, and study visits.",
                "Develop study protocols, case report forms, and study documentation.",
                "Maintain regulatory compliance with Institutional Review Board (IRB) regulations and Good Clinical Practice (GCP) guidelines.",
                "Collect and manage study data, ensuring accuracy and integrity.",
                "Communicate study progress, issues, and results to investigators, sponsors, and regulatory agencies.",
                "Coordinate study-related meetings, trainings, and site visits.",
                "Ensure adherence to study timelines, milestones, and budget.",
                "Manage study supplies, equipment, and resources.",
                "Conduct study-related procedures, such as blood draws, specimen collection, and assessments, as needed.",
                "Provide support and guidance to study team members and collaborators."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in a health-related field; Master's degree preferred.",
                "Certification as a Clinical Research Coordinator (CCRC) or equivalent preferred.",
                "Experience in clinical research coordination or related field.",
                "Knowledge of clinical research regulations, protocols, and procedures.",
                "Familiarity with electronic data capture systems and clinical trial management software.",
                "Strong organizational and project management skills.",
                "Excellent communication and interpersonal abilities.",
                "Attention to detail and ability to maintain accurate study records.",
                "Ability to work independently and collaboratively in a multidisciplinary team environment.",
                "Problem-solving skills and ability to adapt to changing priorities and deadlines."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and holidays.",
                "Professional development opportunities, including continuing education programs.",
                "Flexible work schedule and potential for remote work options."
            ]
        }
    ]
};

export const PublicHealthAdministratorJobDescription = {
    title: "Public Health Administrator Job Description",
    jobRole: "A Public Health Administrator oversees public health programs and initiatives, ensuring effective planning, implementation, and evaluation. They collaborate with stakeholders, manage resources, and address public health challenges to improve community health outcomes.",
    preRead: "Use this Public Health Administrator job description template to advertise open positions for a Public Health Administrator in your organization. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Public Health Administrator, you will be responsible for leading and managing public health programs and initiatives to address community health needs. Your role will involve strategic planning, program development, resource management, and stakeholder engagement to promote and protect public health."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop, implement, and evaluate public health programs and initiatives to address community health needs.",
                "Conduct needs assessments, data analysis, and evaluation to inform program planning and decision-making.",
                "Collaborate with government agencies, community organizations, healthcare providers, and other stakeholders to coordinate public health efforts.",
                "Manage budgets, grants, and funding sources to support public health programs.",
                "Supervise and mentor public health staff, interns, and volunteers.",
                "Provide leadership and direction in response to public health emergencies and outbreaks.",
                "Advocate for policies and interventions to promote health equity and address social determinants of health.",
                "Monitor and report on key public health indicators and outcomes.",
                "Ensure compliance with relevant laws, regulations, and accreditation standards.",
                "Participate in public health research, advocacy, and professional development activities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in public health, health administration, or related field; Doctoral degree preferred.",
                "At least 5 years of experience in public health administration or related role.",
                "Knowledge of public health principles, theories, and practices.",
                "Experience in program planning, implementation, and evaluation.",
                "Strong leadership, management, and communication skills.",
                "Ability to work effectively with diverse stakeholders and communities.",
                "Analytical skills and ability to interpret and communicate data.",
                "Understanding of health policy, advocacy, and public health law.",
                "Ability to adapt to changing priorities and work under pressure.",
                "Commitment to promoting health equity and addressing health disparities."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience and qualifications.",
                "Comprehensive health, dental, and vision insurance coverage.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and holidays.",
                "Professional development opportunities, including conferences and training programs.",
                "Flexible work schedule and potential for remote work options."
            ]
        }
    ]
};

export const VeterinaryTechnicianJobDescription = {
    title: "Veterinary Technician Job Description",
    jobRole: "A Veterinary Technician assists veterinarians in providing medical care to animals. They perform various tasks, including conducting laboratory tests, administering medications, and assisting with surgeries, to ensure the health and well-being of animals under veterinary care.",
    preRead: "Use this Veterinary Technician job description template to advertise open positions for a Veterinary Technician in your veterinary practice. Customize the responsibilities and qualifications according to your practice's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Veterinary Technician, you will play a vital role in providing medical care to animals under the supervision of veterinarians. Your responsibilities will include assisting with medical procedures, performing diagnostic tests, and providing compassionate care to animals and their owners."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist veterinarians in examining and treating animals.",
                "Prepare animals for surgery and assist during surgical procedures.",
                "Administer medications, vaccines, and treatments as prescribed by veterinarians.",
                "Perform diagnostic tests, such as blood tests, urinalysis, and x-rays.",
                "Clean and sterilize equipment and maintain a clean and organized work environment.",
                "Provide compassionate care and support to animals and their owners.",
                "Educate pet owners on topics such as nutrition, preventive care, and medication administration.",
                "Maintain accurate records of patient care and treatment.",
                "Monitor the health and behavior of animals before and after procedures.",
                "Assist with euthanasia procedures and provide emotional support to pet owners."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in veterinary technology or equivalent certification.",
                "Certification or licensure as a veterinary technician (CVT, RVT, or LVT) preferred.",
                "Previous experience working in a veterinary clinic or animal hospital.",
                "Knowledge of veterinary medical procedures and terminology.",
                "Ability to handle and restrain animals safely and effectively.",
                "Strong communication and interpersonal skills.",
                "Attention to detail and ability to follow instructions accurately.",
                "Compassion and empathy for animals and their owners.",
                "Ability to work efficiently in a fast-paced environment.",
                "Willingness to continuously learn and stay updated on veterinary practices and protocols."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $45,000 per year, depending on experience and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation and sick leave.",
                "Continuing education opportunities and support for professional development.",
                "Discounts on veterinary services and pet care products for personal pets."
            ]
        }
    ]
};

export const MassageTherapistJobDescription = {
    title: "Massage Therapist Job Description",
    jobRole: "A Massage Therapist provides therapeutic massage and bodywork treatments to clients to promote relaxation, relieve muscle tension, and alleviate pain. They assess clients' needs, develop treatment plans, and perform various massage techniques to improve clients' physical and mental well-being.",
    preRead: "Use this Massage Therapist job description template to advertise open positions for a Massage Therapist in your wellness center or spa. Customize the responsibilities and qualifications according to your center's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Massage Therapist, you will play a crucial role in promoting the health and wellness of clients through therapeutic massage and bodywork techniques. Your responsibilities will include assessing clients' conditions, developing personalized treatment plans, and providing compassionate care to enhance clients' overall well-being."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess clients' health history and specific needs to determine the most appropriate massage techniques and treatment plans.",
                "Provide therapeutic massage and bodywork treatments to clients, including Swedish massage, deep tissue massage, hot stone massage, and aromatherapy.",
                "Tailor massage treatments to address clients' individual concerns, such as muscle tension, stress, injuries, or chronic pain.",
                "Educate clients on the benefits of massage therapy and recommend additional self-care strategies to support their overall wellness.",
                "Maintain accurate records of client sessions, including treatment notes, progress reports, and payment information.",
                "Adhere to ethical and professional standards in client interactions, including maintaining confidentiality and respecting clients' boundaries.",
                "Collaborate with other healthcare professionals, such as chiropractors, physical therapists, or physicians, as needed to provide integrated care for clients.",
                "Stay updated on industry trends, new massage techniques, and best practices in massage therapy.",
                "Maintain a clean and organized workspace and adhere to sanitation and hygiene protocols.",
                "Promote massage therapy services and participate in marketing and promotional activities to attract new clients and retain existing clients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Completion of a massage therapy program from an accredited school or institution.",
                "State licensure or certification as a massage therapist.",
                "Previous experience working as a massage therapist in a spa, wellness center, or healthcare setting.",
                "Proficiency in various massage techniques and modalities.",
                "Strong interpersonal and communication skills.",
                "Empathy and compassion for clients' well-being.",
                "Ability to assess clients' needs and tailor treatment plans accordingly.",
                "Physical stamina and strength to perform massage therapy techniques for extended periods.",
                "Attention to detail and professionalism in client interactions.",
                "Commitment to continuous learning and professional development in massage therapy."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $60,000 per year, depending on experience, location, and client base.",
                "Flexible work schedule, including evenings and weekends.",
                "Opportunities for tips and commission on massage services.",
                "Discounts on wellness services and products for personal use.",
                "Professional liability insurance coverage.",
                "Continuing education opportunities and reimbursement for training or workshops.",
                "Paid time off and vacation benefits.",
                "Healthcare benefits, including medical, dental, and vision insurance."
            ]
        }
    ]
};

export const ChiropractorJobDescription = {
    title: "Chiropractor Job Description",
    jobRole: "A Chiropractor diagnoses and treats musculoskeletal conditions, primarily focusing on the spine and nervous system. They use manual adjustments and other therapeutic techniques to alleviate pain, improve mobility, and promote overall wellness for their patients.",
    preRead: "Use this Chiropractor job description template to advertise open positions for a Chiropractor in your healthcare facility. Customize the responsibilities and qualifications according to your clinic's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Chiropractor, you will be responsible for diagnosing and treating patients with musculoskeletal disorders, particularly those related to the spine and nervous system. Your role will involve performing manual adjustments, providing patient education, and developing personalized treatment plans to improve patients' physical health and well-being."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct patient consultations and examinations to assess musculoskeletal conditions and identify underlying health issues.",
                "Perform spinal adjustments and manipulations to correct misalignments and improve spinal function.",
                "Use additional therapeutic techniques, such as massage, traction, and physical therapy modalities, to alleviate pain and improve mobility.",
                "Develop personalized treatment plans for patients based on their specific needs, health goals, and preferences.",
                "Educate patients on chiropractic care, wellness principles, and self-care strategies to support their overall health and recovery.",
                "Monitor patients' progress during treatment, adjust treatment plans as needed, and provide ongoing support and guidance.",
                "Collaborate with other healthcare professionals, such as physicians, physical therapists, and massage therapists, to provide integrated care for patients.",
                "Maintain accurate patient records, including treatment notes, progress reports, and billing information, in compliance with healthcare regulations.",
                "Adhere to ethical and professional standards in patient care, including maintaining patient confidentiality and respecting patients' rights.",
                "Participate in professional development activities, such as continuing education courses and conferences, to stay updated on chiropractic techniques and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Doctor of Chiropractic (DC) degree from an accredited chiropractic college or university.",
                "State licensure or certification as a chiropractor.",
                "Previous experience working as a chiropractor in a clinical setting.",
                "Strong diagnostic skills and ability to assess musculoskeletal conditions.",
                "Proficiency in manual adjustment techniques and therapeutic modalities.",
                "Excellent communication and interpersonal skills to build rapport with patients and collaborate with healthcare team members.",
                "Empathy and compassion for patients' well-being and comfort.",
                "Physical stamina and dexterity to perform chiropractic adjustments and manipulations safely and effectively.",
                "Attention to detail and accuracy in patient assessments and treatment documentation.",
                "Commitment to lifelong learning and professional development in chiropractic care."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $150,000 per year, depending on experience, location, and patient volume.",
                "Flexible work schedule, including evenings and weekends.",
                "Opportunities for bonuses or profit sharing based on patient satisfaction and clinic performance.",
                "Malpractice insurance coverage.",
                "Paid time off and vacation benefits.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Professional development allowances for continuing education and training."
            ]
        }
    ]
};

export const OccupationalHealthSafetySpecialistJobDescription = {
    title: "Occupational Health and Safety Specialist Job Description",
    jobRole: "An Occupational Health and Safety Specialist is responsible for ensuring workplace safety and health by identifying hazards, implementing safety measures, and providing training to employees. They conduct inspections, investigate accidents, and develop safety policies and programs to prevent injuries and illnesses.",
    preRead: "Use this Occupational Health and Safety Specialist job description template to advertise open positions for an Occupational Health and Safety Specialist in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Occupational Health and Safety Specialist, you will play a crucial role in promoting and maintaining a safe and healthy work environment for employees. Your responsibilities will include conducting risk assessments, implementing safety protocols, and providing training to ensure compliance with occupational health and safety regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct workplace inspections and risk assessments to identify potential hazards and safety risks.",
                "Develop and implement safety policies, procedures, and programs to mitigate workplace hazards and ensure compliance with occupational health and safety regulations.",
                "Provide guidance and support to management and employees on occupational health and safety matters.",
                "Coordinate and conduct safety training programs for employees to increase awareness of workplace hazards and safe work practices.",
                "Investigate accidents, incidents, and near misses to determine root causes and develop preventive measures.",
                "Maintain records of safety inspections, incidents, and corrective actions to track safety performance and compliance.",
                "Monitor and evaluate workplace conditions and practices to identify areas for improvement and implement corrective actions.",
                "Collaborate with cross-functional teams, including human resources, facilities management, and legal, to address occupational health and safety issues.",
                "Stay updated on occupational health and safety regulations, industry standards, and best practices to ensure compliance and continuous improvement.",
                "Promote a culture of safety and wellness among employees through communication, education, and engagement initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in occupational health and safety, industrial hygiene, environmental science, or related field.",
                "Certification in occupational health and safety (e.g., CSP, OHST, CIH) preferred.",
                "Proven experience in occupational health and safety, risk management, or related field.",
                "Strong knowledge of occupational health and safety regulations, standards, and best practices.",
                "Excellent analytical and problem-solving skills.",
                "Effective communication and interpersonal skills.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in conducting safety assessments and investigations.",
                "Proficiency in using safety management systems and software.",
                "Commitment to promoting a culture of safety and wellness in the workplace."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $100,000 per year, depending on experience, location, and industry.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off and vacation benefits.",
                "Professional development opportunities, including training and certification programs.",
                "Opportunities for advancement and career growth within the organization.",
                "Flexible work schedule and work-life balance initiatives.",
                "Employee assistance programs for wellness and mental health support."
            ]
        }
    ]
};

export const MedicalEquipmentPreparerJobDescription = {
    title: "Medical Equipment Preparer Job Description",
    jobRole: "A Medical Equipment Preparer is responsible for cleaning, sterilizing, and preparing medical equipment and instruments for patient use. They follow strict protocols and procedures to ensure that medical equipment is safe, functional, and ready for use in medical procedures and treatments.",
    preRead: "Use this Medical Equipment Preparer job description template to advertise open positions for a Medical Equipment Preparer in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Equipment Preparer, you will play a critical role in ensuring the safety and effectiveness of medical equipment used in patient care. Your responsibilities will include cleaning, disinfecting, and sterilizing medical instruments, as well as inspecting equipment for damage or defects."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Clean, disinfect, and sterilize medical equipment and instruments according to established protocols and procedures.",
                "Inspect medical equipment for damage, defects, or malfunctions, and report any issues to appropriate personnel.",
                "Assemble and prepare medical equipment and instruments for use in medical procedures and treatments.",
                "Package and label medical supplies and instruments for storage, transport, or disposal.",
                "Maintain inventory of medical equipment and supplies, and replenish stock as needed.",
                "Follow safety guidelines and infection control protocols to prevent cross-contamination and ensure patient safety.",
                "Adhere to regulatory requirements and industry standards for medical equipment preparation and sterilization.",
                "Collaborate with healthcare professionals, including nurses, surgeons, and technicians, to ensure timely and efficient preparation of medical equipment.",
                "Document and maintain records of equipment preparation activities, including cleaning, sterilization, and inspection procedures.",
                "Participate in training and professional development activities to stay updated on best practices and advancements in medical equipment preparation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; certification in sterile processing or medical equipment preparation preferred.",
                "Proven experience in medical equipment preparation, sterile processing, or related field.",
                "Knowledge of medical terminology, infection control practices, and sterilization techniques.",
                "Attention to detail and accuracy in following protocols and procedures.",
                "Ability to work efficiently and effectively in a fast-paced healthcare environment.",
                "Strong communication and interpersonal skills.",
                "Ability to lift and move heavy objects, and stand for extended periods.",
                "Commitment to maintaining a safe and sterile work environment.",
                "Flexibility to work evenings, weekends, and holidays as needed."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $50,000 per year, depending on experience, location, and employer.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off and vacation benefits.",
                "Tuition reimbursement and continuing education opportunities.",
                "Employee assistance programs for wellness and mental health support.",
                "Opportunities for career advancement and professional growth within the organization.",
                "Flexible work schedule and work-life balance initiatives."
            ]
        }
    ]
};

export const PsychiatricTechnicianJobDescription = {
    title: "Psychiatric Technician Job Description",
    jobRole: "A Psychiatric Technician provides care and support to individuals with mental illness or emotional disturbances under the supervision of psychiatrists, psychologists, or other mental health professionals. They assist in implementing treatment plans, monitoring patient behavior, and facilitating therapeutic activities to promote mental wellness and stability.",
    preRead: "Use this Psychiatric Technician job description template to advertise open positions for a Psychiatric Technician in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Psychiatric Technician, you will work closely with mental health professionals to provide compassionate care and support to individuals with psychiatric disorders. Your role will involve assisting patients with daily activities, monitoring their behavior and vital signs, and implementing therapeutic interventions to promote recovery and well-being."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in implementing treatment plans developed by mental health professionals.",
                "Monitor and observe patients' behavior, mood changes, and vital signs.",
                "Assist patients with activities of daily living, such as eating, bathing, and dressing.",
                "Facilitate group therapy sessions and recreational activities to promote socialization and emotional expression.",
                "Administer medications under the direction and supervision of licensed nurses or healthcare providers.",
                "Document patient interactions, progress, and observations in medical records or electronic health records (EHR).",
                "Respond to crisis situations and provide emotional support and reassurance to patients.",
                "Maintain a safe and therapeutic environment for patients and staff.",
                "Collaborate with interdisciplinary healthcare team members to coordinate patient care and treatment.",
                "Participate in ongoing training and professional development activities to enhance skills and knowledge in psychiatric care."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; certification or associate degree in psychiatric technology or related field preferred.",
                "Previous experience in psychiatric or mental health care preferred.",
                "Knowledge of psychiatric disorders, treatment modalities, and crisis intervention techniques.",
                "Strong interpersonal and communication skills.",
                "Empathy, compassion, and patience when working with individuals experiencing mental health challenges.",
                "Ability to remain calm and composed in stressful situations.",
                "Flexibility to adapt to changing patient needs and treatment plans.",
                "Physical stamina and resilience to work in a demanding healthcare environment.",
                "Basic computer skills for documentation and recordkeeping.",
                "Commitment to maintaining patient confidentiality and privacy."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $45,000 per year, depending on experience, location, and employer.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off and vacation benefits.",
                "Tuition reimbursement and continuing education opportunities.",
                "Employee assistance programs for wellness and mental health support.",
                "Opportunities for career advancement and specialization in psychiatric care.",
                "Flexible work schedule and work-life balance initiatives."
            ]
        }
    ]
};

export const RehabilitationCounselorJobDescription = {
    title: "Rehabilitation Counselor Job Description",
    jobRole: "A Rehabilitation Counselor assists individuals with physical, mental, developmental, or emotional disabilities to overcome barriers and achieve independence. They assess clients' needs, develop rehabilitation plans, provide counseling and support, and coordinate services to help clients integrate into their communities and lead fulfilling lives.",
    preRead: "Use this Rehabilitation Counselor job description template to advertise open positions for a Rehabilitation Counselor in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Rehabilitation Counselor, you will work with clients with disabilities to help them overcome obstacles and achieve their personal, educational, and vocational goals. Your role will involve assessing clients' needs, developing individualized rehabilitation plans, providing counseling and guidance, and connecting clients with resources and services to support their rehabilitation journey."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct assessments and evaluations to determine clients' strengths, limitations, and rehabilitation needs.",
                "Develop individualized rehabilitation plans in collaboration with clients, family members, and interdisciplinary teams.",
                "Provide counseling, guidance, and support to clients to address emotional, social, and vocational challenges.",
                "Assist clients in developing and implementing strategies to improve daily living skills, mobility, and independence.",
                "Coordinate services and resources, such as vocational training, education, job placement, and assistive technology, to support clients' rehabilitation goals.",
                "Advocate for clients' rights and access to community resources and services.",
                "Monitor clients' progress and adjust rehabilitation plans as needed.",
                "Maintain accurate and confidential client records and documentation.",
                "Collaborate with healthcare professionals, social workers, educators, employers, and community organizations to provide comprehensive support to clients.",
                "Stay updated on best practices, regulations, and trends in rehabilitation counseling and disability services."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Rehabilitation Counseling, Counseling Psychology, Social Work, or related field.",
                "State licensure or certification as a Rehabilitation Counselor (CRC) preferred.",
                "Previous experience working with individuals with disabilities in a rehabilitation or counseling setting.",
                "Knowledge of disability rights, laws, and regulations, such as the Americans with Disabilities Act (ADA).",
                "Strong counseling, assessment, and case management skills.",
                "Empathy, patience, and cultural sensitivity when working with clients from diverse backgrounds.",
                "Excellent communication and interpersonal skills.",
                "Ability to collaborate effectively with interdisciplinary teams and community stakeholders.",
                "Organizational skills and attention to detail in documentation and recordkeeping.",
                "Commitment to ethical standards and confidentiality in client interactions."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $35,000 - $55,000 per year, depending on experience, location, and employer.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off and vacation benefits.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and work-from-home options.",
                "Employee assistance programs for wellness and mental health support.",
                "Opportunities for career advancement and specialization in rehabilitation counseling."
            ]
        }
    ]
};

export const SubstanceAbuseCounselorJobDescription = {
    title: "Substance Abuse Counselor Job Description",
    jobRole: "A Substance Abuse Counselor provides support and treatment to individuals struggling with substance abuse and addiction. They assess clients' needs, develop treatment plans, facilitate therapy sessions, and provide counseling to help clients overcome addiction and achieve recovery.",
    preRead: "Use this Substance Abuse Counselor job description template to advertise open positions for a Substance Abuse Counselor in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Substance Abuse Counselor, you will work with individuals dealing with substance abuse and addiction to help them overcome their challenges and achieve sobriety. Your role will involve assessing clients' needs, developing personalized treatment plans, facilitating individual and group therapy sessions, and providing ongoing support and counseling to promote recovery and wellness."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct comprehensive assessments to evaluate clients' substance abuse history, mental health status, and treatment needs.",
                "Develop individualized treatment plans in collaboration with clients, family members, and healthcare professionals.",
                "Facilitate individual counseling sessions to address underlying issues contributing to substance abuse and addiction.",
                "Lead group therapy sessions to provide support, education, and skills training to clients.",
                "Monitor clients' progress in treatment and adjust treatment plans as needed.",
                "Provide crisis intervention and support to clients experiencing relapse or other challenges.",
                "Educate clients and their families about the nature of addiction, recovery strategies, and available resources.",
                "Collaborate with other healthcare professionals, such as physicians, psychiatrists, and social workers, to coordinate comprehensive care for clients.",
                "Maintain accurate and confidential client records and documentation in compliance with regulations.",
                "Participate in ongoing training and professional development to stay updated on best practices and treatment modalities in substance abuse counseling."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Counseling, Psychology, Social Work, or related field.",
                "State licensure or certification as a Substance Abuse Counselor (LADC, LCADC, CADC, etc.) preferred.",
                "Previous experience working in substance abuse treatment or mental health counseling.",
                "Knowledge of evidence-based treatment modalities for substance abuse and addiction.",
                "Strong counseling, assessment, and communication skills.",
                "Empathy, patience, and non-judgmental attitude when working with clients.",
                "Ability to establish rapport and build therapeutic relationships with clients.",
                "Understanding of ethical standards and confidentiality in counseling practice.",
                "Cultural competence and sensitivity to diverse backgrounds and experiences.",
                "Ability to work effectively as part of a multidisciplinary team and collaborate with other professionals."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience, location, and employer.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off and vacation benefits.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and work-from-home options.",
                "Employee assistance programs for wellness and mental health support.",
                "Opportunities for career advancement and specialization in substance abuse counseling."
            ]
        }
    ]
};

export const ClinicalNurseSpecialistJobDescription = {
    title: "Clinical Nurse Specialist Job Description",
    jobRole: "A Clinical Nurse Specialist (CNS) is an advanced practice registered nurse (APRN) who specializes in a specific area of healthcare, such as pediatrics, geriatrics, oncology, or mental health. They provide expert clinical care, conduct assessments, develop treatment plans, and offer education and support to patients, families, and healthcare teams.",
    preRead: "Use this Clinical Nurse Specialist job description template to advertise open positions for a Clinical Nurse Specialist in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Clinical Nurse Specialist, you will play a crucial role in delivering specialized patient care and improving outcomes within your chosen specialty area. You will assess patients, develop comprehensive treatment plans, provide direct patient care, and collaborate with interdisciplinary healthcare teams to optimize patient care delivery."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess patients' health status, medical history, and healthcare needs.",
                "Develop individualized care plans based on assessment findings and evidence-based practice guidelines.",
                "Provide direct patient care, including performing physical exams, administering medications, and monitoring patient progress.",
                "Coordinate patient care activities and serve as a liaison between patients, families, and healthcare providers.",
                "Educate patients and families about their health conditions, treatment options, and self-care strategies.",
                "Collaborate with physicians, nurses, therapists, and other healthcare professionals to develop and implement coordinated care plans.",
                "Participate in quality improvement initiatives and research projects to enhance patient care outcomes.",
                "Serve as a mentor and resource for nursing staff, providing guidance and support in clinical practice and professional development.",
                "Stay updated on advances in nursing practice, healthcare technology, and evidence-based interventions within your specialty area."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Nursing with a specialization in Clinical Nurse Specialist (CNS) program.",
                "Current licensure as a Registered Nurse (RN) and certification as a Clinical Nurse Specialist (CNS) in the respective specialty area.",
                "Minimum of 2-3 years of clinical nursing experience in the chosen specialty.",
                "Strong clinical assessment and critical thinking skills.",
                "Excellent communication and interpersonal abilities.",
                "Ability to work collaboratively in a multidisciplinary healthcare team.",
                "Proficiency in electronic health record (EHR) systems and healthcare technology.",
                "Commitment to lifelong learning and professional development.",
                "Leadership qualities and the ability to influence change in clinical practice.",
                "Empathy, compassion, and a patient-centered approach to care delivery."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience, location, and specialty.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Continuing education opportunities and tuition reimbursement.",
                "Professional liability insurance coverage.",
                "Employee assistance programs for wellness and mental health support.",
                "Opportunities for career advancement and specialization within the clinical nurse specialist role."
            ]
        }
    ]
};

export const HealthEducatorJobDescription = {
    title: "Health Educator Job Description",
    jobRole: "A Health Educator is responsible for developing and implementing health education programs to promote wellness and prevent illness or injury within communities or organizations. They assess community health needs, design educational materials and curricula, facilitate workshops and seminars, and advocate for health policy changes to improve public health outcomes.",
    preRead: "Use this Health Educator job description template to advertise open positions for a Health Educator in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Health Educator, you will play a vital role in promoting health and wellness by developing and delivering educational programs and initiatives. You will collaborate with community organizations, schools, healthcare providers, and government agencies to address public health issues and empower individuals to make informed decisions about their health."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess community health needs and develop targeted health education programs and interventions.",
                "Create educational materials, resources, and curricula tailored to diverse populations and cultural backgrounds.",
                "Facilitate workshops, seminars, and training sessions on topics such as nutrition, physical activity, chronic disease prevention, sexual health, and substance abuse.",
                "Provide individual and group counseling on health-related topics and behavior change strategies.",
                "Collaborate with healthcare providers, community leaders, and stakeholders to promote health initiatives and advocacy efforts.",
                "Evaluate the effectiveness of health education programs through data collection, surveys, and assessments.",
                "Advocate for policy changes and environmental modifications to support healthy behaviors and improve community health outcomes.",
                "Participate in community outreach events, health fairs, and promotional campaigns to raise awareness of health issues and resources.",
                "Stay informed about current health trends, research findings, and evidence-based practices in health education and promotion.",
                "Maintain accurate records, documentation, and reports related to program activities and outcomes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's or Master's degree in Public Health, Health Education, Community Health, or related field.",
                "Certification as a Certified Health Education Specialist (CHES) or Master Certified Health Education Specialist (MCHES) preferred.",
                "Previous experience in health education, community outreach, or public health promotion.",
                "Knowledge of health behavior theories, behavior change strategies, and evidence-based interventions.",
                "Excellent communication, presentation, and interpersonal skills.",
                "Cultural competence and the ability to work effectively with diverse populations.",
                "Strong organizational and project management skills.",
                "Proficiency in Microsoft Office and other relevant software applications.",
                "Ability to work independently and collaboratively in a team environment.",
                "Passion for promoting health and wellness and a commitment to social justice and health equity."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $70,000 per year, depending on experience, education, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including conference attendance and continuing education courses.",
                "Flexible work schedule and remote work options.",
                "Opportunities for career advancement and specialization within the field of health education."
            ]
        }
    ]
};

export const MedicalSecretaryJobDescription = {
    title: "Medical Secretary Job Description",
    jobRole: "A Medical Secretary provides administrative support to healthcare professionals and medical facilities. They are responsible for managing patient records, scheduling appointments, handling correspondence, and assisting with billing and coding tasks. Medical Secretaries play a crucial role in ensuring the smooth operation of medical offices and clinics.",
    preRead: "Use this Medical Secretary job description template to advertise open positions for a Medical Secretary in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Secretary, you will be responsible for providing administrative support to healthcare professionals, including physicians, nurses, and medical staff. Your duties will include managing patient records, scheduling appointments, coordinating communications, and assisting with billing and coding tasks. You will play a key role in maintaining efficient and organized operations within the medical office or clinic."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage patient records and ensure accuracy, confidentiality, and compliance with HIPAA regulations.",
                "Schedule patient appointments, surgeries, and procedures, and coordinate with healthcare providers and facilities.",
                "Greet patients, answer phone calls, and respond to inquiries in a professional and courteous manner.",
                "Assist patients with completing forms, obtaining referrals, and understanding insurance coverage and billing procedures.",
                "Enter patient information, medical histories, and treatment plans into electronic health records (EHR) systems.",
                "Process insurance claims, verify insurance coverage, and assist with billing and coding tasks as needed.",
                "Maintain office supplies, equipment, and inventory, and coordinate repairs and maintenance as necessary.",
                "Handle incoming and outgoing mail, faxes, and emails, and distribute correspondence to appropriate personnel.",
                "Coordinate meetings, conferences, and events, and prepare agendas, materials, and presentations as needed.",
                "Assist with administrative tasks, such as data entry, filing, scanning, and photocopying documents."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate degree or certification in medical office administration or related field preferred.",
                "Previous experience as a medical secretary, medical assistant, or administrative assistant in a healthcare setting.",
                "Familiarity with medical terminology, procedures, and insurance billing practices.",
                "Proficiency in computer skills, including Microsoft Office (Word, Excel, Outlook) and electronic health records (EHR) systems.",
                "Strong organizational and multitasking abilities, with attention to detail and accuracy.",
                "Excellent communication and interpersonal skills, with the ability to interact professionally with patients, staff, and visitors.",
                "Ability to maintain confidentiality of patient information and adhere to HIPAA regulations.",
                "Customer service-oriented mindset and the ability to handle inquiries and concerns with empathy and professionalism.",
                "Flexibility to adapt to changing priorities and work in a fast-paced healthcare environment.",
                "Reliability, punctuality, and a positive attitude towards teamwork and collaboration."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $30,000 - $45,000 per year, depending on experience, education, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and continuing education courses.",
                "Employee assistance program (EAP) for counseling and wellness services.",
                "Opportunities for career advancement and growth within the healthcare industry."
            ]
        }
    ]
};

export const OrthotistAndProsthetistJobDescription = {
    title: "Orthotist and Prosthetist Job Description",
    jobRole: "An Orthotist and Prosthetist is a healthcare professional who specializes in designing, fabricating, and fitting orthotic and prosthetic devices to assist patients with physical disabilities or limb loss. They work closely with patients, physicians, and other healthcare professionals to assess patients' needs, create custom devices, and provide ongoing care and support.",
    preRead: "Use this Orthotist and Prosthetist job description template to advertise open positions for an Orthotist and Prosthetist in your organization. Customize the responsibilities and qualifications according to your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Orthotist and Prosthetist, you will be responsible for assessing patients' needs for orthotic and prosthetic devices, designing and fabricating custom devices, and providing ongoing care and support. Your role will involve working closely with patients, physicians, and other healthcare professionals to improve patients' mobility, function, and quality of life."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct assessments and evaluations to determine patients' needs for orthotic and prosthetic devices.",
                "Design and fabricate custom orthotic and prosthetic devices, including braces, supports, limbs, and other assistive devices.",
                "Fit and adjust orthotic and prosthetic devices to ensure proper alignment, function, and comfort for patients.",
                "Educate patients and caregivers on the use and care of orthotic and prosthetic devices, including proper cleaning, maintenance, and adjustment techniques.",
                "Collaborate with physicians, physical therapists, and other healthcare professionals to develop comprehensive treatment plans for patients.",
                "Document patient assessments, treatment plans, and device specifications in medical records and electronic health records (EHR) systems.",
                "Stay updated on advances in orthotics and prosthetics technology, materials, and techniques through continuing education and professional development.",
                "Adhere to ethical and professional standards in patient care and device fabrication, including patient confidentiality and informed consent.",
                "Provide ongoing follow-up care and support to patients, including device adjustments, repairs, and replacements as needed.",
                "Participate in interdisciplinary team meetings and case conferences to coordinate patient care and treatment goals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in orthotics and prosthetics or a related field; certification or licensure as an Orthotist and Prosthetist required.",
                "Proven experience in orthotics and prosthetics practice, including patient assessment, device fabrication, and fitting.",
                "Strong knowledge of anatomy, physiology, biomechanics, and pathology related to orthotics and prosthetics.",
                "Proficiency in using orthotic and prosthetic materials, tools, and equipment to fabricate custom devices.",
                "Excellent communication and interpersonal skills, with the ability to work collaboratively in a multidisciplinary healthcare team.",
                "Empathy, patience, and compassion in working with patients with physical disabilities or limb loss.",
                "Attention to detail and accuracy in device fabrication, fitting, and adjustment.",
                "Problem-solving skills and the ability to adapt to changing patient needs and preferences.",
                "Strong organizational and time management abilities to manage multiple patient cases and tasks effectively.",
                "Commitment to professional development and staying updated on advances in orthotics and prosthetics practice."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, certification, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and continuing education courses.",
                "Opportunities for career advancement and specialization within orthotics and prosthetics practice.",
                "Rewarding work environment with the opportunity to make a positive impact on patients' lives."
            ]
        }
    ]
};

export const RadiationTherapistJobDescription = {
    title: "Radiation Therapist Job Description",
    jobRole: "A Radiation Therapist is a healthcare professional responsible for administering radiation treatments to patients with cancer and other diseases. They work closely with oncologists, medical physicists, and other healthcare professionals to deliver precise radiation therapy according to treatment plans, ensure patient safety and comfort, and monitor patients' progress throughout the course of treatment.",
    preRead: "Use this Radiation Therapist job description template to advertise open positions for a Radiation Therapist in your healthcare facility. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Radiation Therapist, you will be responsible for administering radiation treatments to patients with cancer and other diseases, following treatment plans prescribed by oncologists and medical physicists. Your role will involve operating specialized radiation therapy equipment, monitoring patients' responses to treatment, and providing compassionate care and support to patients and their families throughout the treatment process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Review and verify treatment plans prescribed by oncologists and medical physicists to ensure accurate delivery of radiation therapy.",
                "Prepare patients for radiation therapy sessions, including positioning patients on treatment tables, immobilizing body parts, and explaining procedures and potential side effects.",
                "Operate radiation therapy equipment, such as linear accelerators, to deliver precise doses of radiation to targeted areas of the body.",
                "Monitor patients' responses to treatment, including assessing side effects, monitoring vital signs, and documenting observations in medical records.",
                "Collaborate with oncologists, medical physicists, and other healthcare professionals to develop and modify treatment plans based on patients' progress and feedback.",
                "Provide emotional support and reassurance to patients and their families, addressing concerns and answering questions about radiation therapy.",
                "Ensure patient safety during radiation therapy sessions by adhering to safety protocols and radiation protection measures.",
                "Maintain accurate records of radiation treatments, patient progress, and equipment calibration in electronic medical records (EMR) systems.",
                "Participate in quality assurance and quality control activities to ensure the safe and effective delivery of radiation therapy.",
                "Stay updated on advances in radiation therapy techniques, equipment, and protocols through continuing education and professional development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in radiation therapy or a related field; certification or licensure as a Radiation Therapist required.",
                "Proven experience in radiation therapy practice, including patient assessment, treatment delivery, and documentation.",
                "Knowledge of radiation therapy principles, techniques, and safety procedures.",
                "Proficiency in operating radiation therapy equipment, such as linear accelerators and treatment planning systems.",
                "Excellent communication and interpersonal skills, with the ability to provide compassionate care and support to patients and their families.",
                "Attention to detail and accuracy in patient positioning, treatment delivery, and documentation.",
                "Ability to work effectively in a fast-paced healthcare environment, collaborating with multidisciplinary teams to deliver high-quality patient care.",
                "Empathy, patience, and sensitivity to patients' physical and emotional needs during radiation therapy.",
                "Strong problem-solving skills and the ability to adapt to changes in patient conditions or treatment plans.",
                "Commitment to patient safety, quality assurance, and continuous improvement in radiation therapy practice."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, certification, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and continuing education courses.",
                "Opportunities for career advancement and specialization within radiation therapy practice.",
                "Rewarding work environment with the opportunity to make a positive impact on patients' lives."
            ]
        }
    ]
};

export const NuclearMedicineTechnologistJobDescription = {
    title: "Nuclear Medicine Technologist Job Description",
    jobRole: "A Nuclear Medicine Technologist is a healthcare professional who specializes in the use of radioactive materials to diagnose and treat various medical conditions. They prepare and administer radioactive drugs to patients, operate imaging equipment to capture images of organs and tissues, and analyze the images to assist physicians in making accurate diagnoses and treatment plans.",
    preRead: "Use this Nuclear Medicine Technologist job description template to advertise open positions for a Nuclear Medicine Technologist in your healthcare facility. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Nuclear Medicine Technologist, you will be responsible for preparing and administering radioactive drugs to patients, operating imaging equipment to capture images of organs and tissues, and analyzing the images to assist physicians in making accurate diagnoses and treatment plans. Your role will involve collaborating with physicians, radiologists, and other healthcare professionals to provide high-quality patient care and ensure patient safety throughout the imaging process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and administer radioactive drugs to patients according to physicians' orders and established protocols.",
                "Operate nuclear medicine imaging equipment, such as gamma cameras and PET scanners, to capture images of organs and tissues.",
                "Position patients properly for imaging procedures and ensure their safety and comfort throughout the process.",
                "Monitor patients' vital signs and responses to radioactive drugs during imaging procedures, and intervene as needed to ensure patient safety.",
                "Analyze nuclear medicine images to identify abnormalities, artifacts, and other factors that may affect image quality or interpretation.",
                "Collaborate with physicians, radiologists, and other healthcare professionals to interpret imaging results and develop treatment plans.",
                "Maintain accurate records of imaging procedures, patient information, and radioactive materials in compliance with regulatory requirements.",
                "Adhere to radiation safety protocols and procedures to minimize radiation exposure to patients, staff, and the public.",
                "Participate in quality assurance and quality control activities to ensure the accuracy and reliability of imaging equipment and procedures.",
                "Stay updated on advances in nuclear medicine technology, imaging techniques, and safety standards through continuing education and professional development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in nuclear medicine technology or a related field; certification or licensure as a Nuclear Medicine Technologist required.",
                "Proven experience in nuclear medicine imaging procedures, including patient preparation, drug administration, and imaging equipment operation.",
                "Knowledge of radioactive drugs, radiation safety principles, and imaging techniques used in nuclear medicine.",
                "Proficiency in operating nuclear medicine imaging equipment, such as gamma cameras, PET scanners, and computer systems.",
                "Excellent communication and interpersonal skills, with the ability to work effectively in a multidisciplinary healthcare team.",
                "Attention to detail and accuracy in patient positioning, drug administration, and imaging data analysis.",
                "Ability to prioritize tasks, manage multiple responsibilities, and adapt to changes in workflow or patient needs.",
                "Compassion, empathy, and sensitivity to patients' physical and emotional needs during imaging procedures.",
                "Commitment to patient safety, quality assurance, and compliance with regulatory requirements in nuclear medicine practice.",
                "Strong problem-solving skills and the ability to troubleshoot technical issues related to imaging equipment or procedures."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $65,000 - $95,000 per year, depending on experience, certification, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and continuing education courses.",
                "Opportunities for career advancement and specialization within nuclear medicine technology.",
                "Rewarding work environment with the opportunity to make a positive impact on patients' lives."
            ]
        }
    ]
};
