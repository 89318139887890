import { combineReducers } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import appInfoSlice from "store/slices/app/app-info-slice";
import breadcrumbSlice from "store/slices/app/breadcrumb-slice";
import notificationSlice from "store/slices/app/notification-slice";
import resourcesSlice from "store/slices/app/resources-slice";
import themeSlice from "store/slices/app/theme-slice";
import { IAppTheme, IBreadcrumbState } from "./app-model";

const themePersistConfig: PersistConfig<IAppTheme> = {
    key: 'theme',
    storage
};

const breadcrumbPersistConfig: PersistConfig<IBreadcrumbState> = {
    key: 'breadcrumb',
    storage: storageSession,
    whitelist: ['breadcrumbNavFrom', 'currentBreadcrumb', 'breadcrumbLabelRegistry']
};

const appReducer = combineReducers({
    theme: persistReducer(themePersistConfig, themeSlice.reducer),
    notification: notificationSlice.reducer,
    appInfo: appInfoSlice.reducer,
    resources: resourcesSlice.reducer,
    breadcrumb: persistReducer(breadcrumbPersistConfig, breadcrumbSlice.reducer)
});

export { themeSlice };

export default appReducer;