import CheckIcon from "@mui/icons-material/Check";
import { Alert, Box, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { SocialLogin } from "Modules/Core/AuthV2/SocialLogin";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { checkIfAccountExists } from "store/slices/auth-v2/auth-v2-slice";
import { IsSmScreen, useAppDispatch, useAppSelector } from "../../../helpers/hooks";
import { MUIGrey, PrimaryThemeColor, TextPrimary } from "../../../shared/SharedStyles/styleConstants";
import { EmailRegEx } from "../../../shared/constants";

export const SurveyHome = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSmScreen = IsSmScreen();
    const { curTheme } = useAppSelector((state) => state.app.theme);
    const { email, isRememberMe, checkIfAccountExistsStatus, checkIfAccountExistsResponse,
        checkIfAccountExistsResponseMsg } = useAppSelector(state => state.auth.auth);
    const [isTOSChecked, setIsTOSChecked] = useState<boolean>(false)
    // Prefill email if candidate selected Remember me while signing up
    const [candidateEmail, setCandidateEmail] = useState<string>(() => (isRememberMe && email) ? email : '');
    const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false);

    const handleSubmit = () => {
        const reg = new RegExp(EmailRegEx);
        setIsInvalidEmail(candidateEmail.length === 0 || !reg.test(candidateEmail));
        if (reg.test(candidateEmail)) {
            dispatch(checkIfAccountExists({ email: candidateEmail }))

        }
    };

    useEffect(() => {
        if (checkIfAccountExistsStatus === 'success') {
            if (checkIfAccountExistsResponse?.exists) {
                navigate("/candidate/login", { state: { email: candidateEmail, firstname: checkIfAccountExistsResponse.firstname } });
            } else {
                navigate("/candidate/sign-up", { state: { email: candidateEmail } });
            }
        }
    }, [candidateEmail, checkIfAccountExistsResponse?.exists,
        checkIfAccountExistsResponse?.firstname, checkIfAccountExistsStatus, dispatch, navigate]);

    return (<>
        <Typography variant='h6' gutterBottom fontWeight="bold" color={PrimaryThemeColor}>
            Take the SmoothHiring Survey
        </Typography>
        <Divider />
        <Box maxWidth={1000}>
            <Box my={3}>
                <Typography variant='subtitle1' gutterBottom
                    color={curTheme === 'light' ? TextPrimary : MUIGrey}>
                    Take our FREE 15 minute career survey and you will be able to:
                </Typography>
                <Stack direction="row" gap={1} ml={2}>
                    <CheckIcon color="primary" />
                    <Typography variant='body2' color={MUIGrey}>
                        Compare yourself with jobs
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1} ml={2}>
                    <CheckIcon color="primary" />
                    <Typography variant='body2' color={MUIGrey}>
                        See your top career personality traits
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1} ml={2}>
                    <CheckIcon color="primary" />
                    <Typography variant='body2' color={MUIGrey}>
                        Get a career feedback report
                    </Typography>
                </Stack>
                <Stack direction="row" gap={1} ml={2}>
                    <CheckIcon color="primary" />
                    <Typography variant='body2' color={MUIGrey}>Apply to jobs</Typography>
                </Stack>
            </Box>
            <Divider />
            {checkIfAccountExistsStatus === 'failed' &&
                <Alert severity='error' action={<ShButton
                    onClick={() => dispatch(checkIfAccountExists({ email: candidateEmail }))}>Retry</ShButton>}>
                    {checkIfAccountExistsResponseMsg}</Alert>
            }
            <Stack gap={3} direction={isSmScreen ? 'column' : 'row'} justifyContent="space-between" padding={2}>
                <Box>
                    <Typography variant="body2" marginBottom={2}>
                        Enter your email address to begin your application</Typography>
                    <TextField type='email' size='small' id='mail_or_phone' label='Enter Your Email'
                        variant='outlined' value={candidateEmail} fullWidth
                        onChange={(e) => {
                            setCandidateEmail(e.target.value);
                            const reg = new RegExp(EmailRegEx);
                            setIsInvalidEmail(!reg.test(e.target.value));
                        }}
                        error={isInvalidEmail}
                    />
                    <Typography mt={3} variant="body2">Data Processing Agreement</Typography>
                    <FormControlLabel control={<Checkbox size='small' checked={isTOSChecked}
                        onChange={() => setIsTOSChecked(!isTOSChecked)} />}
                        label={<Typography fontSize="small">
                            I consent to have my personal information stored securely in your database.
                        </Typography>} />
                    <ShGreenBtn variant='outlined' disabled={!isTOSChecked}
                        onClick={handleSubmit}> {checkIfAccountExistsStatus === 'pending' ? 'Checking...' : 'Go'}</ShGreenBtn>
                </Box>
                {/* To be enabled once social login is sorted out */}
                <Divider orientation={isSmScreen ? 'horizontal' : 'vertical'} variant='middle' flexItem>
                    <Typography variant='body2' color={MUIGrey}>or</Typography>
                </Divider>
                <SocialLogin buttonType="button" usedIn="candidate_survey" isSignUp></SocialLogin>
            </Stack>
        </Box >
    </>);
}