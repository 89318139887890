import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import jobTitleSlice from 'store/slices/employer/create-job/job-title-slice';
import creditSlice from "../admin/employer/credits-slice";
import empAccountsSlice from "../admin/employer/emp-accounts-slice";
import empDetailsSlice from "../admin/employer/emp-details-slice";
import productsSlice from "../admin/employer/products-slice";
import subsSlice from "../admin/employer/subs-slice";
import employerSignUpSlice from '../auth-v2/employer-sign-up-slice';
import { applicantsReducer } from './applicants';
import { createJobReducer } from './create-job';
import employerSubDomainSlice from './employer-sub-domain/employer-sub-domain-slice';
import dashboardReducer from './employer/dashboard';
import { IEmployerDetailsState } from './employer/employer-details-model';
import employerDetailsSlice from './employer/employer-details-slice';
import interviewsReducer from './interviews';
import employerJobsReducer from './jobs';
import employerSettingsReducer from './settingsTs';

const employerDetailsPersistConfig: PersistConfig<IEmployerDetailsState> = {
    key: 'employerDetails',
    storage,
    whitelist: ['employerDetails']
}

const employerReducer = combineReducers({
    employer: empDetailsSlice.reducer,
    employerAccounts: empAccountsSlice.reducer,
    credits: creditSlice.reducer,
    subs: subsSlice.reducer,
    products: productsSlice.reducer,
    jobTitle: jobTitleSlice.reducer,
    employerSubDomain: employerSubDomainSlice.reducer,
    employerSignUp: employerSignUpSlice.reducer,
    employerDetails: persistReducer(employerDetailsPersistConfig, employerDetailsSlice.reducer),
    dashboard: dashboardReducer,
    createJob: createJobReducer,
    applicants: applicantsReducer,
    interviews: interviewsReducer,
    employerSettings: employerSettingsReducer,
    employerJobs: employerJobsReducer
});
export default employerReducer;
