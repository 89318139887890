import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Badge, Stack, Typography, lighten } from "@mui/material";
import { useAppSelector } from "helpers/hooks";
import moment from "moment";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShAvatar, ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import { getInitialsFromName } from "shared/utils";
import { stringToColor } from "../Dashboard.styled";

export const DashboardEmployerWelcomeWidget = () => {

    const { firstName, middleName, lastName, accountAccess, accountLoginInfo, twoStepAuth } = useAppSelector((state) => state.auth.auth);
    const userInitials = getInitialsFromName(`${firstName} ${lastName}`, true, true)
    const getAvatarBackgroundColor = stringToColor(userInitials);

    return (
        <ShPaper variant="outlined" height='100%'>
            <Stack padding={1.5} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                justifyContent='space-between' alignContent='center'>
                <Stack flexDirection='row' alignItems='center' columnGap={2} >
                    <Badge
                        overlap="rectangular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent={!twoStepAuth?.emailVerification && !twoStepAuth?.securityVerification &&
                            <ShTooltip title="Two-Step Authentication is not enabled. Your account/data may be vulnerable!"
                                placement='bottom-start'>
                                <ErrorOutlineIcon color='warning' />
                            </ShTooltip>
                        }>
                        <ShAvatar className="dashboard-user-avatar" backgroundColor={getAvatarBackgroundColor}>
                            <Typography variant='subtitle1' fontWeight='bold'>
                                {userInitials.toUpperCase()}
                            </Typography>
                        </ShAvatar>
                    </Badge>
                    <Typography variant="h5">
                        Welcome {[firstName, middleName, lastName].filter(Boolean).join(' ')}
                    </Typography>
                    <ShChip textColor='white' bgColor={lighten(PrimaryThemeColor, .3)} label={accountAccess.role?.toUpperCase()} />
                </Stack>
            </Stack>
            {accountLoginInfo?.last_sign_in_at &&
                <Typography padding={1} textAlign='left' variant="subtitle2">Last login :&nbsp;
                    {moment(accountLoginInfo?.last_sign_in_at)?.fromNow()}</Typography>
            }
        </ShPaper>
    )
}
