import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Login } from 'Modules/Core/AuthV2/Login';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ShTooltip } from 'shared/SharedComponents/ShTooltip';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { getWelcomeText } from 'shared/utils';
import { resetCheckIfAccountExists, setAccountRememberMe } from 'store/slices/auth-v2/auth-v2-slice';

export const SurveyLogin = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { checkIfAccountExistsResponse } = useAppSelector((state) => state.auth.auth);
    const [isShowForgotPassword, setIsShowForgotPassword] = useState<boolean>(false);

    const handleBackInLogin = () => {
        dispatch(resetCheckIfAccountExists());
        navigate('/candidate/new');
        dispatch(setAccountRememberMe({ isRememberMe: false, email: '' }));
    }

    return (<>
        <Typography variant='h6' gutterBottom fontWeight="bold" color={PrimaryThemeColor}>
            {isShowForgotPassword ? 'Forgot password' : 'Login'}
        </Typography>
        <Divider />
        <Box>
            <Stack direction='row' columnGap={1} alignItems='center'>
                <ShTooltip title='Create new a account' placement='bottom'>
                    <IconButton size='small' onClick={handleBackInLogin}><ArrowBackIosIcon fontSize='small' /></IconButton>
                </ShTooltip>
                {!isShowForgotPassword && <Typography my={3} variant="body1" gutterBottom>
                    {getWelcomeText(checkIfAccountExistsResponse?.firstname)}</Typography>}
            </Stack>
            {!isShowForgotPassword && <Divider />}
            <Stack my={3} rowGap={3} alignItems='center'>
                {!isShowForgotPassword &&
                    <Typography variant="body2" gutterBottom>
                        Please Enter your details to begin your application
                    </Typography>}
                <Login emailAutoFill={checkIfAccountExistsResponse?.email} usedIn='candidate_survey' isShowSocialLogin={false}
                    getIsForgotPassword={(e) => setIsShowForgotPassword(e)} />
            </Stack>
        </Box>
    </>);
}