import { combineReducers } from '@reduxjs/toolkit';
import assessmentMetaSlice from 'store/slices/meta-data/assessment-meta-slice';
import jobsMetaSlice from 'store/slices/meta-data/jobs-meta-slice';

const metaDataReducer = combineReducers({
    assessmentMeta: assessmentMetaSlice.reducer,
    jobsMeta: jobsMetaSlice.reducer,
});

export { assessmentMetaSlice, jobsMetaSlice };
export default metaDataReducer;