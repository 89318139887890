import { Stack } from '@mui/material';
import ShDarkModeLogo from 'assets/logos/sh_dark_mode.svg';
import ShLightModeLogo from 'assets/logos/sh_light_mode.svg';
import { useAppSelector } from 'helpers/hooks';
import { Link } from 'react-router-dom';

export const ShLogo = ({ link }: { link?: string }) => {
    const { curTheme } = useAppSelector((state) => state.app.theme);
    return (<>
        {link ?
            <Stack component={Link} to={link}>
                <img src={(curTheme === 'light' ? ShLightModeLogo : ShDarkModeLogo)} alt="Logo" />
            </Stack> :
            <img src={(curTheme === 'light' ? ShLightModeLogo : ShDarkModeLogo)} alt="Logo" width='100%' height='100%' />
        }
    </>);
}