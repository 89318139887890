import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApplicantsExcelHeaders } from 'Modules/Core/Applicants/ApplicantsConstants';
import { IApplicantsListActionsState, IInviteApplicantsApiParams, IShareToEmailParams } from 'Modules/Core/Applicants/ApplicantsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { exportToCSV, exportToExcel } from 'helpers/fileHandlers';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialApplicantsListActionsState: IApplicantsListActionsState = {
    shareApplicantsStatus: 'idle',
    downloadAsCSVStatus: 'idle',
    downloadAsExcelStatus: 'idle'
};

//share applicants to email
export const shareApplicantsToEmail = createAsyncThunk<IBaseResponse, IShareToEmailParams, { rejectValue: IBaseResponse }>(
    'shareApplicantsToEmail',
    async ({ employerJobId, toEmail, applicantIds }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS_JOBS}/${employerJobId}/share_email?toEmail=${toEmail}${applicantIds ? `&applicantIds=${applicantIds}` : ''}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

//invite applicants 
export const inviteApplicants = createAsyncThunk<IBaseResponse, IInviteApplicantsApiParams, { rejectValue: IBaseResponse }>(
    'inviteApplicants',
    async ({ body, jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.INVITE_APPLICANTS}/${jobId}`, body)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

//download applicants as csv
export const downloadApplicantsAsCSV = createAsyncThunk<IBaseResponse | unknown, { employerJobId: number }, { rejectValue: IBaseResponse | unknown }>(
    'downloadApplicantsAsCSV',
    async ({ employerJobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS_JOBS}/${employerJobId}/download`)
            .then((response: AxiosResponse) => response.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

//download applicants as excel
export const downloadApplicantsAsExcel = createAsyncThunk<IBaseResponse, { jobId: number }, { rejectValue: IBaseResponse }>(
    'downloadApplicantsAsExcel',
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/export?jobId=${jobId}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getJobFilterKeywords = createAsyncThunk<IBaseResponse<string[]>, { jobId: string }, { rejectValue: IBaseResponse }>(
    'getJobFilterKeywords',
    async ({ jobId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYER_JOBS}/${jobId}/keywords`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const applicantsListActionsSlice = createSlice({
    name: 'applicantsListActions',
    initialState: initialApplicantsListActionsState,
    reducers: {
        resetShareToEmail: (state) => { state.shareApplicantsStatus = 'idle'; state.shareApplicantsResponse = '' },
        resetDownloadCSV: (state) => { state.downloadAsCSVStatus = 'idle'; state.downloadAsCSVResponse = '' },
        resetDownloadExcel: (state) => { state.downloadAsExcelStatus = 'idle'; state.downloadAsExcelResponse = '' },
        resetInviteApplicants: (state) => { state.inviteApplicantsStatus = 'idle'; state.inviteApplicantsResponse = '' },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplicantsListActionsState;
        });
        // share applicants to email
        builder.addCase(shareApplicantsToEmail.pending, (state) => {
            state.shareApplicantsStatus = 'pending';
        });
        builder.addCase(shareApplicantsToEmail.fulfilled, (state, action) => {
            state.shareApplicantsStatus = 'success';
            state.shareApplicantsResponse = action?.payload.message;
        });
        builder.addCase(shareApplicantsToEmail.rejected, (state, action) => {
            state.shareApplicantsStatus = 'failed';
            state.shareApplicantsResponse = action?.payload?.message ?? 'Error occurred while sharing to Email';
        });
        // download as csv
        builder.addCase(downloadApplicantsAsCSV.pending, (state) => {
            state.downloadAsCSVStatus = 'pending';
        });
        builder.addCase(downloadApplicantsAsCSV.fulfilled, (state, action) => {
            state.downloadAsCSVStatus = 'success';
            // Download file handler.
            exportToCSV(action?.payload, 'Applicants');
        });
        builder.addCase(downloadApplicantsAsCSV.rejected, (state, action) => {
            state.downloadAsCSVStatus = 'failed';
            state.downloadAsCSVResponse = 'Error occurred while downloading';
        });
        // download as excel
        builder.addCase(downloadApplicantsAsExcel.pending, (state) => {
            state.downloadAsExcelStatus = 'pending';
        });
        builder.addCase(downloadApplicantsAsExcel.fulfilled, (state, action) => {
            state.downloadAsExcelStatus = 'success';
            state.downloadAsExcelResponse = action?.payload.message;
            /*
                Sort the headers while passing to exportToExcel function to maintain column order.
            */
            exportToExcel(action?.payload.data.results, 'Applicants', ApplicantsExcelHeaders.sort((a, b) => a.order - b.order));

        });
        builder.addCase(downloadApplicantsAsExcel.rejected, (state, action) => {
            state.downloadAsExcelStatus = 'failed';
            state.downloadAsExcelResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // invite applicants
        builder.addCase(inviteApplicants.pending, (state) => {
            state.inviteApplicantsStatus = 'pending';
        });
        builder.addCase(inviteApplicants.fulfilled, (state, action) => {
            state.inviteApplicantsStatus = 'success';
            state.inviteApplicantsResponse = action?.payload.message;
        });
        builder.addCase(inviteApplicants.rejected, (state, action) => {
            state.inviteApplicantsStatus = 'failed';
            state.inviteApplicantsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // get job filters
        builder.addCase(getJobFilterKeywords.pending, (state) => {
            state.getJobFilterKeywordsStatus = 'pending';
        });
        builder.addCase(getJobFilterKeywords.fulfilled, (state, action) => {
            state.getJobFilterKeywordsStatus = 'success';
            state.jobFilterKeywords = action.payload.data;
            state.getJobFilterKeywordsResponse = action?.payload.message;
        });
        builder.addCase(getJobFilterKeywords.rejected, (state, action) => {
            state.getJobFilterKeywordsStatus = 'failed';
            state.getJobFilterKeywordsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetShareToEmail, resetDownloadCSV, resetDownloadExcel, resetInviteApplicants } = applicantsListActionsSlice.actions;
export default applicantsListActionsSlice;
