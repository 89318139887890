import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { IconButton, ListItemIcon, ListItemText, Menu, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { StyledMenuItem } from 'shared/SharedStyles/ShNavigation';
import { TThemeMode } from 'store/slices/app/app-model';
import { setThemeMode } from "store/slices/app/theme-slice";

const ThemeToggler = () => {
  const [themeMenuAnchorEl, setThemeMenuAnchorEl] = useState<HTMLElement | null>(null);
  const isThemeMenuOpen = Boolean(themeMenuAnchorEl);
  const { mode } = useAppSelector((state) => state.app.theme);
  const dispatch = useAppDispatch();
  const isSysThemeDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

  useEffect(() => {
    if (mode === 'system_default') {
      let curTheme: TThemeMode = isSysThemeDark ? 'dark' : 'light';
      dispatch(setThemeMode({ curTheme: curTheme }));
    }
  }, [dispatch, isSysThemeDark, mode]);

  const toggleTheme = (mode: string) => {
    // Toggle theme with 'setThemeMode' reducer in theme-slice.
    let curTheme = mode;
    if (mode === 'system_default') {
      curTheme = isSysThemeDark ? 'dark' : 'light';
    }
    dispatch(setThemeMode({ curTheme, mode }));
  };

  return (
    <>
      <Tooltip title='Toggle Theme'>
        <IconButton size="large" onClick={e => setThemeMenuAnchorEl(e.currentTarget)}>
          {mode === 'dark' ? <DarkModeOutlinedIcon /> : mode === 'light' ?
            <LightModeOutlinedIcon /> : <SettingsBrightnessIcon />}
        </IconButton>
      </Tooltip>
      <Menu id="theme-menu" aria-label='Toggle theme' anchorEl={themeMenuAnchorEl}
        open={isThemeMenuOpen} onClose={() => setThemeMenuAnchorEl(null)}>
        <StyledMenuItem className={mode === 'dark' ? "selected-item" : ""}
          onClick={() => { toggleTheme('dark'); setThemeMenuAnchorEl(null); }}>
          <ListItemIcon><DarkModeOutlinedIcon /></ListItemIcon>
          <ListItemText>Dark</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem className={mode === 'light' ? "selected-item" : ""}
          onClick={() => { toggleTheme('light'); setThemeMenuAnchorEl(null); }}>
          <ListItemIcon><LightModeOutlinedIcon /></ListItemIcon>
          <ListItemText>Light</ListItemText>
        </StyledMenuItem>
        <StyledMenuItem className={mode === 'system_default' ? "selected-item" : ""}
          onClick={() => { toggleTheme('system_default'); setThemeMenuAnchorEl(null); }}>
          <ListItemIcon><SettingsBrightnessIcon /></ListItemIcon>
          <ListItemText>System</ListItemText>
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default ThemeToggler;
