import { Box, Typography, Stack } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import CommonQuestionSidebar from "../CommonQuestionSidebar";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { ShButton } from "shared/SharedStyles/ShInputs";

export const ContactSupport = () => {

  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Contact Support</ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            If you need assistance or have any questions regarding our ATS platform,
             our support team is here to help you. Please fill out the form below or use one of the alternative contact methods listed.
          </ResourceTypography>

          <ShButton variant="contained" href="https://smoothhiring.com/contact-us/" >View Form</ShButton>

          <ResourceTypography paddingTop={2} variant="h6" fontWeight={'bold'} gutterBottom>Other Contact Methods</ResourceTypography>
          <Box paddingBottom={3}>
            <Stack spacing={2}>
              <Typography variant="body1" paragraph>
                <strong>Email:</strong> help@smoothhiring.com
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Phone:</strong> +1 877 789 8767
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Support Hours:</strong> Monday to Friday, 9 AM to 6 PM (EST)
              </Typography>
            </Stack>
          </Box>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Frequently Asked Questions</ResourceTypography>
          <Box paddingBottom={3}>
            <Typography variant="body1" paragraph>
              Before reaching out, you might want to check our <a href="https://smoothhiring.com/frequently-asked-questions/">FAQ page</a> where we have answered common questions about our platform.
            </Typography>
          </Box>

        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};

export default ContactSupport;
