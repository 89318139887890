import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedJobState {
    selectedJobId: number | null;
}

const initialState: SelectedJobState = {
    selectedJobId: null,
};

const selectedJobForJobSummaryWidgetSlice = createSlice({
    name: 'selectedJobForJobSummaryWidget',
    initialState,
    reducers: {
        setSelectedJobId(state, action: PayloadAction<number>) {
            state.selectedJobId = action.payload;
        },
    },
});

export const { setSelectedJobId } = selectedJobForJobSummaryWidgetSlice.actions;
export default selectedJobForJobSummaryWidgetSlice;
