import { Stack, Toolbar } from "@mui/material";
import { Box } from "@mui/system";
import { IsLgScreen } from 'helpers/hooks';
import { Outlet } from "react-router-dom";
import { ContactInfo } from "shared/SharedComponents/ContactInfo";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";

export const ToolsWrapper = () => {
    const isLgScreen = IsLgScreen()

    return (
        <Stack height='100vh'>
            <ShAppBar position="sticky">
                <Toolbar>
                    <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                        <a href="https://smoothhiring.com" target="_self" rel="noopener noreferrer">
                            <ShLogo />
                        </a>
                        <Stack direction='row' alignItems='center'>
                            {isLgScreen && (
                                <Stack direction="row" columnGap={2}>
                                    <ContactInfo showDivider dividerOrientation="vertical" />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Toolbar>
            </ShAppBar>
            <Box flex={1}>
                <Outlet />
            </Box>
        </Stack>
    )
}
