import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import PeopleIcon from '@mui/icons-material/People';
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import JobDetailsFields from 'assets/Images/JobDetailsFields.svg';
import { IsLgScreen, IsMdScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { CommonQuestionSidebar } from "../CommonQuestionSidebar";
import { TextFieldsInfo } from "../HelpConstants";

export const OptimizeJobPosting = () => {
  const isMdScreen = IsMdScreen();
  const isLgScreen = IsLgScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>
            Welcome to SmoothHiring Hiring Suite
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            SmoothHiring is your one-stop solution for all your recruitment needs. Create job listings, post job openings, and manage candidate applications with ease.
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Candidates applying through SmoothHiring can take advantage of predictive analytics assessments to showcase their skills and suitability for the job.
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Once candidates have applied, you can seamlessly schedule interviews, evaluate their performance, and extend job offers.
          </ResourceTypography>

          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>
            How to Optimize Your Job Posting to Boost Resume Match Scores
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Optimizing your job posting is essential to attracting qualified candidates and ensuring that their resumes match well with your job requirements. An optimized posting will increase the likelihood of receiving higher resume scores from our predictive analytics tool. 
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Here's how our resume scoring algorithm works:
          </ResourceTypography>

          <ResourceTypography variant="body2" color="textSecondary" paragraph>
            1. **Tokenization and Keyword Extraction**: We tokenize the resume and job description text to identify the most relevant keywords and phrases. These include technical skills, soft skills, qualifications, and job-specific terms.
          </ResourceTypography>
          <ResourceTypography variant="body2" color="textSecondary" paragraph>
            2. **Keyword Matching**: The system checks for common keywords between the job description and the candidate's resume. Matching keywords indicate a stronger fit.
          </ResourceTypography>
          <ResourceTypography variant="body2" color="textSecondary" paragraph>
            3. **Weighted Keywords**: Keywords that are marked as high-priority in the job description carry more weight in scoring. For example, technical terms like "Python" or "Data Science" might be weighted more heavily for a technical job posting.
          </ResourceTypography>
          <ResourceTypography variant="body2" color="textSecondary" paragraph>
            4. **Score Boosting**: The resume match score is boosted if the matching keywords exceed a certain threshold. This helps prioritize resumes that are highly aligned with the job's core requirements.
          </ResourceTypography>

          <ResourceTypography variant="body1" paragraph>
            To get a higher resume match score, you should craft job descriptions that contain clear and specific keywords related to the role. Here are some tips to optimize your job postings:
          </ResourceTypography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={4} lg={4}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3}>
                <FormatListNumberedRtlIcon color="primary" />
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>Clear and Concise Job Titles</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Job titles should be standard and commonly used in your industry. For example, use “Software Engineer” instead of internal terms like “Tech Guru.” This makes your job post easier for both job seekers and the algorithm to match.
                  </Typography>
                  <ShButton variant="outlined" color="primary" component={RouterLink} to="/employer/job/new">
                    Create a Job
                  </ShButton>
                </Stack>
              </ShPaper>
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={4}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3}>
                <PeopleIcon color="primary" />
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>Be Specific with Job Responsibilities</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Define the tasks and key responsibilities in a clear and structured way. Avoid vague phrases like "help with daily operations" and be specific about the actions the candidate will perform.
                  </Typography>
                  <ShButton variant="contained" color="primary" component={RouterLink} to="/employer/job/new">
                    See my Jobs
                  </ShButton>
                </Stack>
              </ShPaper>
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={4}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3}>
                <AddIcon color="primary" />
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>Use Relevant Keywords</Typography>
                  <Typography variant="body2" color="textSecondary">
                    Include specific industry terms and skills that align with the job. These keywords help the system identify and match candidates who have the right qualifications.
                  </Typography>
                  <ShButton variant="outlined" color="secondary" component={RouterLink} to="/employer/job/new">
                    View Applicants
                  </ShButton>
                </Stack>
              </ShPaper>
            </Grid>
          </Grid>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>
            Highlight Key Skills and Requirements
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            List the essential skills required for the position. Be sure to include both hard skills (e.g., programming languages) and soft skills (e.g., communication, teamwork). This will help candidates self-assess whether they meet your requirements.
          </ResourceTypography>
          <Grid container spacing={2}>
            {TextFieldsInfo.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Chip size="medium" label={item} color="primary" />
              </Grid>
            ))}
          </Grid>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>
            Salary, Benefits, and Work Environment
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Being transparent about salary, benefits, and work environment increases your job posting’s attractiveness. Moreover, it enables the system to better match candidates looking for specific compensation or benefits.
          </ResourceTypography>

          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>
                Define the Job Duties Clearly
              </ResourceTypography>
              <ResourceTypography variant="body1" paragraph>
                Be as specific as possible about the key duties of the role. This allows candidates to assess if they are a good match for the position and ensures the resume scoring algorithm finds relevant matches.
              </ResourceTypography>

              <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>
                Use AI to Improve Job Descriptions
              </ResourceTypography>
              <ResourceTypography variant="body1">
                Use our AI-powered job description generator to help you craft the perfect listing. Simply click the <EditIcon fontSize="small" /> icon to generate a custom description.
              </ResourceTypography>
            </Grid>
            <Grid item xs={12} md={7}>
              <img
                src={JobDetailsFields}
                alt="Job Posting Example"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              />
            </Grid>
          </Grid>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>
            Modify and Preview Job Description
          </ResourceTypography>
          <ResourceTypography variant="body1" paragraph>
            Use the <ShButton size="small" variant="contained">Preview</ShButton> button in the top menu bar to see how your job description looks to candidates. You can edit it as necessary to optimize your post.
          </ResourceTypography>
        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};
