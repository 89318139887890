import { combineReducers } from '@reduxjs/toolkit';
import { IApplyJobHomeState, IResumeSliceState } from 'Modules/Core/ApplyJob/ApplyJobModel';
import { ICandidateProfileState } from 'Modules/Core/Candidate/CandidateModel';
import { PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/lib/storage";
import adminNotificationSlice from 'store/slices/admin/notifications-slice';
import applyJobHomeSlice from "store/slices/candidate/apply-job/apply-job-home-slice";
import applyJobQuestionnaireSlice from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";
import resumeSlice from 'store/slices/candidate/apply-job/resume-slice';
import candidateNotificationsSlice from "store/slices/candidate/candidate-notifications-slice";
import candidateProfileSlice from "store/slices/candidate/candidate-profile-slice";
import surveySlice from "store/slices/candidate/survey/survey-slice";

const candidateProfilePersistConfig: PersistConfig<ICandidateProfileState> = {
    key: 'candidateProfile',
    storage,
    whitelist: ['candidateDetails']
};

const candidateResumePersistConfig: PersistConfig<IResumeSliceState> = {
    key: 'resumeSlice',
    storage,
    whitelist: ['isResumeUpdated']
};

const applyJobHomePersistConfig: PersistConfig<IApplyJobHomeState> = {
    key: 'applyJobHomeSlice',
    storage,
    whitelist: ['jobApplicationId']
};

const candidateReducer = combineReducers({
    applyJobHome: persistReducer(applyJobHomePersistConfig, applyJobHomeSlice.reducer),
    candidateProfile: persistReducer(candidateProfilePersistConfig, candidateProfileSlice.reducer),
    survey: surveySlice.reducer,
    candidateNotifications: candidateNotificationsSlice.reducer,
    applyJobQuestionnaire: applyJobQuestionnaireSlice.reducer,
    resume: persistReducer(candidateResumePersistConfig, resumeSlice.reducer),
});

export { adminNotificationSlice, applyJobHomeSlice, candidateNotificationsSlice, candidateProfileSlice, surveySlice };
export default candidateReducer;

