import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ShBorderRadius, ShOnHover } from 'shared/SharedStyles/styleConstants';

export const DashboardFeatureTile = styled(Paper)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: ShBorderRadius,
    textDecoration: 'none',
    cursor: 'pointer',
    minWidth: 260,
    maxWidth: 360,
    flex: 1,
    ...(ShOnHover(true, theme)),
}));

export const jobBoxStyles = {
    padding: 2,
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    textDecoration: 'none',
    transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
    '&:hover': {
        background: '#DFE8F6',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
};

export const stringToColor = (name: string) => {

    const colors = [
        '#fabbaf', '#eb758f', '#d94c8e', '#fa9891', '#ff7070', '#fb969f', '#f58122', '#FFBEA0',
        '#9EA5F5', '#73DA6E', '#ffb938', '#BD5B96', '#6FCE92', '#F3BC9E', '#CEE47F', '#bba3ea', '#eaa3bf',
        '#94bf30', '#55b33b', '#179c43', '#1F835D', '#116061', '#a0eba8', '#7ccf9a', '#5cb888', '#3da17e', '#20806c',
        '#49c2f2', '#25acf5', '#1793e6', '#1c75bd', '#195ba6', '#ae88e3', '#7e7ef2', '#586ac4', '#e29bfa', '#ca7ef2',
        '#a35dd9', '#773bbf', '#b58c7f', '#6e4250','#495169', '#d9a798', '#c4bbb3'
    ];

    let hash = 0;
    for (let i = 0; i < name?.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
};

export  const ShTimeline = styled(Timeline)(({ theme }) => ({
    padding: 0,
    '& .MuiTimelineItem-root': {
      '&:before': {
        flex: 0,
        padding: 0,
      },
    },
  }));

export const ShTimelineItem = styled(TimelineItem)(({ theme }) => ({
  paddingLeft: 0,
  marginLeft: 0,
  '& .MuiTimelineContent-root': {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    flexGrow: 1,
    margin: 0,
    marginRight:theme.spacing(2),
  },
  '& .MuiTimelineOppositeContent-root': {
    margin: 0,
    textAlign: 'left',
  },
}));

export const DASHBOARD_MENU_ITEM_HEIGHT = 48;
export const DASHBOARD_MENU_ITEM_PADDING_TOP = 8;
export const DashboardMenuProps = {
  PaperProps: {
    style: {
      maxHeight: DASHBOARD_MENU_ITEM_HEIGHT * 4.5 + DASHBOARD_MENU_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
