import { GenerateFormalJobOfferDocument, generateFormalJobOfferPreviewContent, GenerateGeneralJobOfferDocument, generateGeneralJobOfferPreviewContent, GenerateInformalJobOfferDocument, generateInformalJobOfferPreviewContent, GenerateInternalJobOfferDocument, generateInternalJobOfferPreviewContent, GenerateSalesJobOfferDocument, generateSalesJobOfferPreviewContent, JobOfferProps } from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferLetterWordDocConstants";
import { Document } from 'docx';


type TemplateTypes = 'formal' | 'general' | 'internal' | 'informal' | 'sales';


const templateFunctions: Record<TemplateTypes, {
    generateDocument: (props: JobOfferProps) => Document;
    generatePreviewContent: (props: JobOfferProps) => JSX.Element;
}> = {
    formal: {
        generateDocument: GenerateFormalJobOfferDocument,
        generatePreviewContent: generateFormalJobOfferPreviewContent,
    },
    general: {
        generateDocument: GenerateGeneralJobOfferDocument,
        generatePreviewContent: generateGeneralJobOfferPreviewContent,
    },
    internal: {
        generateDocument: GenerateInternalJobOfferDocument,
        generatePreviewContent: generateInternalJobOfferPreviewContent,
    },
    informal: {
        generateDocument: GenerateInformalJobOfferDocument,
        generatePreviewContent: generateInformalJobOfferPreviewContent,
    },
    sales: {
        generateDocument: GenerateSalesJobOfferDocument,
        generatePreviewContent: generateSalesJobOfferPreviewContent,
    },
};

export default templateFunctions;
