import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Box, CircularProgress, Grow, List, ListItem, ListItemText, Stack, Typography, } from '@mui/material';
import { Notification, useNotification } from 'Modules/Core/Notification';
import { ResourceElementsBorderRadius, ResourceHomeStack } from 'Modules/Marketing/Resources/Resources.styled';
import { IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { ShButton, ShTextField } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { HtmlRegex } from 'shared/constants';
import { IAiJobDescriptionAndInterviewKitPayload } from 'store/slices/app/app-model';
import { getAiJobDescriptionByTitle } from 'store/slices/app/resources-slice';
import { EMPLOYER_AI_TOOLS_TITLE_JOB_TITLE } from '../EmployerResourcesConstants';
import JobDescriptionTemplate from './SamplePlaceHolderPages/EmployerSampleJobDescriptionPlaceholder';

export const EmployerAiJobDescriptionPage = () => {
    const dispatch = useAppDispatch();
    const { aiJobDescription } = useAppSelector(state => state.app.resources);
    const notification = useNotification();
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const isXsScreen = IsXsScreen();
    const [industry, setIndustry] = useState<string>('');
    const [jobCompany, setJobCompany] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const { getAiJobDescStatus } = useAppSelector(state => state.app.resources);

    const formatJobDescription = (jobDescription: string) => {
        const lines = jobDescription.split('\n');

        return lines.map((line, index) => {
            const isHeading = line.includes(':');
            const isListElement = /^\d+\./.test(line.trim()) || line.trim().includes('-');

            return isHeading ? (
                <Typography key={index} variant="body1" fontWeight={'bold'}>
                    {line}
                </Typography>
            ) : isListElement ? (
                <ListItem key={index} dense sx={{ listStyleType: 'disc' }}>
                    <ListItemText primary={line.replace(/^-|\d+\./, '•')} />
                </ListItem>
            ) : (
                <List key={index} dense>
                    <ListItemText primary={line} />
                </List>
            );
        });
    };

    useEffect(() => {
        if (copiedToClipboard) {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: 'Successfully Copied!'
            });
            setCopiedToClipboard(false);
        }
    }, [copiedToClipboard, notification]);


    const handleCopyAllClick = () => {
        navigator.clipboard.writeText(aiJobDescription ?? '')
            .then(() => {
                setCopiedToClipboard(true);
            })
    };

    const handleSubmit = () => {
        if (role) {
            let payload: IAiJobDescriptionAndInterviewKitPayload = { role };
            if (industry) {
                // Temporary fix until backend can handle jobCompany 
                payload.industry = industry + "in" + jobCompany;
            }
            if (jobCompany) {
                payload.jobCompany = jobCompany;
            }
            dispatch(getAiJobDescriptionByTitle(payload));
        }
    };

    return (
        <ShContainer maxWidth='lg'>
            <Notification />
            <Grow in={true} timeout={1000} mountOnEnter unmountOnExit>
                <Box marginTop={{ xs: 1, sm: 2, md: 3, lg: 4 }} padding={1}>
                    <Typography gutterBottom fontSize={20} fontWeight='bold'>
                        {EMPLOYER_AI_TOOLS_TITLE_JOB_TITLE}
                    </Typography>
                </Box>
            </Grow>
            <Grow in={true} timeout={2000} mountOnEnter>
                <ResourceHomeStack marginBottom={3} spacing={2} direction={isXsScreen ? 'column' : 'row'} alignItems="center" justifyContent="center">
                    <ShTextField size='medium' label='Company (Optional)' variant='outlined' fullWidth
                        onChange={(e) => {
                            setJobCompany(e.target.value);
                        }} />

                    <ShTextField size='medium' label='Industry' variant='outlined' fullWidth
                        onChange={(e) => {
                            setIndustry(e.target.value);
                        }} />
                    <ShTextField size='medium' label='Job Role' variant='outlined' fullWidth
                        onChange={(e) => {
                            setRole(e.target.value);
                        }} />
                    <ShButton size='large' disableElevation color="primary"
                        disabled={getAiJobDescStatus === 'pending'}
                        borderRadius={ResourceElementsBorderRadius} startIcon={<AutoAwesomeIcon />} variant='contained'
                        onClick={() => handleSubmit()}><Typography>Generate&nbsp;</Typography>
                        {getAiJobDescStatus === 'pending' ? <CircularProgress size='1.5rem' /> : ''}</ShButton>
                </ResourceHomeStack>
            </Grow>
            {aiJobDescription ?
                <Grow in={true} timeout={2000} mountOnEnter>
                    <Stack paddingBottom={3}>
                        <ShPaper variant='outlined' borderRadius={ResourceElementsBorderRadius}>
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='right' paddingLeft={1} paddingBottom={1} paddingTop={2}>
                                <ShButton color='primary' onClick={handleCopyAllClick}>
                                    <CopyAllIcon />
                                    <Typography variant="body2">Copy</Typography>
                                </ShButton>
                            </Stack>
                            <ShContainer>
                                {HtmlRegex.test(aiJobDescription ?? '') ? (
                                    parse(aiJobDescription ?? '')
                                ) : (
                                    formatJobDescription(aiJobDescription)
                                )}
                            </ShContainer>
                        </ShPaper>
                    </Stack>
                </Grow> :
                <ShPaper>
                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent='center' paddingLeft={1} paddingBottom={1} paddingTop={2}>
                        <JobDescriptionTemplate />
                    </Stack>
                </ShPaper>
            }
        </ShContainer>
    );
}

export default EmployerAiJobDescriptionPage;
