import ClearIcon from '@mui/icons-material/Clear';
import { DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { SettingsDialog } from "Modules/Core/SettingsTs/Settings.styled";
import { IAccount, IAddUserDialog, INewUser, IUpdateRoleUser } from "Modules/Core/SettingsTs/SettingsModel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { EmailRegEx } from "shared/constants";
import { addUser, resetAddUser, resetDeleteUser, updateRoles } from "store/slices/employer/settingsTs/access-levels-slice";

export const AddUserDialog = ({ accountList, closeAddUserDialog, isAddUserDialogOpen, _accountList, employerId, user }: IAddUserDialog) => {
    const [isEmailInValid, setIsEmailInValid] = useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const { addUserApiStatus, addUserApiResponse, rolesList, updateRolesApiStatus } = useAppSelector((state) => state.employer.employerSettings.accessLevels);
    const [newOrExistingUser, setNewOrExistingUser] = useState<INewUser>({ first_name: '', last_name: '', email: '', role_id: '' });

    // If dialog is toggled to edit existing user.
    useEffect(() => {
        if (user) {
            setNewOrExistingUser({
                id: user.id, first_name: user.account?.first_name, last_name: user.account?.last_name,
                email: user.account?.email, role_id: user.role_id
            });
        }
    }, [user]);


    const validateEmail = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInValid(!reg.test(e?.target.value ?? ''));
        setNewOrExistingUser({ ...newOrExistingUser, email: e?.target.value ?? '' });
    };

    const saveNewUser = () => {
        const newUserForPost = {
            ...newOrExistingUser, first_name: newOrExistingUser.first_name.trim(),
            last_name: newOrExistingUser.last_name.trim(),
            email: newOrExistingUser.email.trim()
        };
        dispatch(addUser(newUserForPost));
    };

    // Resetting add user api's variables after a user is successfully added.
    const resetAddUserForm = () => {
        setNewOrExistingUser({ first_name: '', last_name: '', email: '', role_id: '' });
        dispatch(resetAddUser());
    };

    // Disable save button if form is invalid.
    useEffect(() => {
        setIsSaveDisabled(newOrExistingUser.email?.trim().length === 0 || isEmailInValid || newOrExistingUser.first_name?.trim().length === 0 ||
            newOrExistingUser.last_name?.trim().length === 0 || newOrExistingUser.role_id === undefined || newOrExistingUser.role_id === '' || newOrExistingUser.role_id <= 0);
    }, [isEmailInValid, newOrExistingUser]);

    const updateUser = () => {
        const newList: IAccount[] = [];
        _accountList.forEach(ac => {
            let newAc: IAccount = { ...ac, account: { ...ac.account } };
            /*
                Update the role id and name when the selected user ID to update is matched.
                This still won't save in the API level but just for visual representation in the data table.
            */
            if (newAc.id === newOrExistingUser.id) {
                newAc.role_id = parseInt(newOrExistingUser.role_id.toString());
                newAc.role = { name: rolesList.find(role => role.id === parseInt(newOrExistingUser.role_id.toString()))?.name ?? '' }
            };
            newList.push(newAc);
        });
        closeDialog();
        /* 
            With the 'accountList' as reference data array, filter out the records for which the role has changed and prepare payload for API.
         */
        const updtdUsers = newList.filter(acc => acc.role_id !== accountList?.find(ac => ac.account_id === acc.account_id)?.role_id);
        if (updtdUsers) {
            const payload: IUpdateRoleUser[] = [];
            updtdUsers.forEach(acc => {
                payload.push({ user_id: acc.account_id, role_id: acc.role_id });
            });
            dispatch(resetDeleteUser());
            dispatch(updateRoles({ employerId: employerId, updatedRoles: { users: payload } }));
        }
    };

    const closeDialog = () => {
        resetAddUserForm();
        closeAddUserDialog();
    };

    const Alerts = () => {
        switch (addUserApiStatus) {
            case 'pending':
                return (<ShAlert severity="info">Adding User ...</ShAlert>);
            case 'success':
                return (<ShAlert severity="success" onClose={resetAddUserForm} >
                    <Typography fontWeight={600}>{addUserApiResponse}</Typography>
                </ShAlert>);
            case 'failed':
                return (<ShAlert severity="error">{addUserApiResponse}</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <SettingsDialog open={isAddUserDialogOpen} onClose={closeDialog} aria-labelledby="dlg_ttl">
            <DialogTitle id='dlg_ttl' fontWeight={600} padding={`5px 16px !important`}>
                {newOrExistingUser?.id === undefined ? 'Add New User' : 'Edit User'}
                <IconButton className='close-btn' onClick={closeDialog}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Stack padding={1} rowGap={{ xs: 1, sm: 2, md: 3, lg: 3 }} minWidth='320px'>
                    {Alerts()}
                    <TextField id="first_name" size="small" variant="outlined" label='First Name' disabled={newOrExistingUser?.id !== undefined}
                        value={newOrExistingUser.first_name} onChange={(e) => { setNewOrExistingUser({ ...newOrExistingUser, first_name: e.target.value }) }} />
                    <TextField id="last_name" size="small" variant="outlined" label='Last Name' disabled={newOrExistingUser?.id !== undefined}
                        value={newOrExistingUser.last_name} onChange={(e) => { setNewOrExistingUser({ ...newOrExistingUser, last_name: e.target.value }) }} />
                    <TextField id="email" size="small" variant="outlined" label='Email Address' disabled={newOrExistingUser?.id !== undefined}
                        value={newOrExistingUser.email} onChange={validateEmail} />
                    <FormControl size="small" fullWidth>
                        <InputLabel>Select Role</InputLabel>
                        <Select size='small' id='role' fullWidth value={newOrExistingUser.role_id} label='Select Role'
                            onChange={(e) => { setNewOrExistingUser({ ...newOrExistingUser, role_id: e.target.value as number }) }}>
                            {/*
                                Filter out the owner for the drop down data array
                                since there can only be one owner and thats the user who created the account.
                            */}
                            {rolesList.filter(r => r.name !== 'owner').map((r) => (
                                <MenuItem key={r.id} value={r.id}>{r.display_name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                {(newOrExistingUser?.id === undefined && updateRolesApiStatus !== 'pending') ?
                    <ShGreenBtn disableElevation variant='contained' disabled={isSaveDisabled}
                        // Call save or close dialog apis based on the new user addition status
                        onClick={addUserApiStatus === 'success' ? closeDialog : saveNewUser}>
                        {addUserApiStatus === 'success' ? 'Close' : '+ Add'}
                    </ShGreenBtn> :
                    <ShGreenBtn disableElevation variant='contained' onClick={updateUser} disabled={isSaveDisabled ||
                        newOrExistingUser.role_id === _accountList.find(acc => acc.id === newOrExistingUser.id)?.role_id} >
                        {updateRolesApiStatus === 'pending' ? 'Saving...' : 'Save'}
                    </ShGreenBtn>}
            </DialogActions>
        </SettingsDialog>
    </>);
};