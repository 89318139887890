import LogoutIcon from '@mui/icons-material/Logout';
import { IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { ContactInfo } from 'shared/SharedComponents/ContactInfo';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { DesktopOnlyStack } from 'shared/SharedStyles/ShLayouts';
import { ShAppBar } from 'shared/SharedStyles/ShSurfaces';
import { logoutAccount } from 'store/slices/auth-v2/auth-v2-slice';
import { resetApplyForJob } from 'store/slices/candidate/apply-job/apply-job-home-slice';
import { resetGetJobRequirements } from 'store/slices/candidate/apply-job/apply-job-questionnaire.slice';
import { resetUploadApplicantResume } from 'store/slices/candidate/apply-job/resume-slice';
import { resetGetCandidateNotificationSettings, resetUpdateCandidateNotificationSettings } from 'store/slices/candidate/candidate-notifications-slice';
import { resetGetCandidateApplicationHistory, resetGetCandidateDetails, resetSignUpCandidate } from 'store/slices/candidate/candidate-profile-slice';
import { resetSurvey } from 'store/slices/candidate/survey/survey-slice';

export const CandidateTopNav = () => {

    const dispatch = useAppDispatch();
    const { isAccountLoggedIn } = useAppSelector((state) => state.auth.auth);

    const logoutCandidate = () => {
        dispatch(logoutAccount({ logoutType: 'user' }));
        dispatch(resetSurvey());
        dispatch(resetApplyForJob());
        dispatch(resetGetCandidateDetails());
        dispatch(resetGetJobRequirements());
        dispatch(resetSignUpCandidate());
        dispatch(resetGetCandidateApplicationHistory());
        dispatch(resetGetCandidateNotificationSettings());
        dispatch(resetUpdateCandidateNotificationSettings());
        dispatch(resetSurvey());
        dispatch(resetUploadApplicantResume());
    }

    return (
        <ShAppBar position="sticky">
            <Toolbar>
                <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                    <ShLogo link='/candidate' />
                    <Stack direction='row' alignItems='center'>
                        <DesktopOnlyStack direction='row' columnGap={2}>
                            <ContactInfo showDivider dividerOrientation='vertical' />
                        </DesktopOnlyStack>
                        <ThemeToggler />
                        {isAccountLoggedIn && <Tooltip title="Log out">
                            <IconButton size="large" onClick={logoutCandidate}>
                                <LogoutIcon color='warning' />
                            </IconButton>
                        </Tooltip>}
                    </Stack>
                </Stack>
            </Toolbar>
        </ShAppBar>
    );
};