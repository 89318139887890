import { JobsList } from "Modules/Core/JobsTs/JobsListTs/JobsList";
import { useAppDispatch } from "helpers/hooks";
import { useEffect } from "react";
import { fetchJobsCounts, resetJobsState } from "store/slices/employer/jobs/jobs-list-slice";


export const JobsWrapper = () => {
    const dispatch = useAppDispatch();

    // Get Jobs counts on load.
    useEffect(() => {
        dispatch(fetchJobsCounts());
        return () => {
            // Reset jobs state on component unmount.
            dispatch(resetJobsState());
        }
    }, [dispatch]);

    return (<>
        <JobsList />
    </>);
};