import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AccessLevelsTableHeads, RoleAccessLevelsTableHeads, RoleAccessMap } from 'Modules/Core/SettingsTs/SettingsConstants';
import { AccessLevelTableHead, IAccessLevels, IAccessLevelsRow, RoleAccessLevelTableHead } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppSelector } from 'helpers/hooks';
import { ShTable, ShTableContainer } from 'shared/SharedStyles/ShDataDisplay';
import { AccountActions } from './AccountActions';

// Row component to wrap table row.
const Row = ({ row, editUser, rolesList }: IAccessLevelsRow) => {

    const renderCell = (headerId: AccessLevelTableHead | RoleAccessLevelTableHead) => {
        switch (headerId) {
            case 'NAME':
                return <TableCell key={headerId}>{row.fullName}</TableCell>;
            case 'EMAIL':
                return <TableCell key={headerId}>{row.account?.email}</TableCell>
            case 'ROLE':
                return <TableCell key={headerId}>
                    {rolesList.find(rL => rL.name === row.role?.name)?.display_name ?? row.role?.name}&nbsp;
                    {/* <Stack className='role-cell'>
                    {rolesList.find(rL => rL.name === row.role?.name)?.display_name ?? row.role?.name}&nbsp;
                    <HelpOutlineIcon color='disabled' /></Stack> */}
                </TableCell>;
            case 'ASSIGN':
            case 'COMMENT':
            case 'CREATE':
            case 'VIEW':
            case 'ALL_JOBS':
                return <TableCell key={headerId} align='center'>{RoleAccessMap[row.role_id]?.includes(headerId) ?
                    <CheckIcon fontSize='small' color='success' /> : <ClearIcon fontSize='small' color='error' />}</TableCell>;
            default:
                break;
        }
        return (<></>);
    };

    return (
        <>
            <TableRow key={row.account?.email}>
                {AccessLevelsTableHeads.map(th => (renderCell(th.columName)))}
                {RoleAccessLevelsTableHeads.map(th => (renderCell(th.columName)))}
                <TableCell align='center'>
                    <AccountActions account={row} editUser={editUser} />
                </TableCell>
            </TableRow>
        </>
    );
}

export const AccessLevelsTableView = ({ editUser, paginatedAccountList }: IAccessLevels) => {
    const { rolesList } = useAppSelector((state) => state.employer.employerSettings.accessLevels);
    return (<>
        <ShTableContainer>
            <ShTable>
                <TableHead>
                    <TableRow>
                        {AccessLevelsTableHeads.map(th => (
                            <TableCell key={th.columName}>
                                <Typography variant='body2' fontWeight={600}>{th.label}</Typography>
                            </TableCell>
                        ))}
                        {RoleAccessLevelsTableHeads.map(th => (
                            <TableCell key={th.columName} align='center'>
                                <Typography variant='body2' fontWeight={600}>{th.label}</Typography>
                            </TableCell>
                        ))}
                        <TableCell align='center'>
                            <Typography variant='body2' fontWeight={600}>Actions</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedAccountList?.map((acc) => (
                        <Row key={acc.id} row={acc} editUser={editUser} rolesList={rolesList} />
                    ))}
                </TableBody>
            </ShTable>
        </ShTableContainer>
    </>);
}