import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { Link as RouterLink } from "react-router-dom";
import { ShDialog } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { setIsWelcomeAcknowledged } from "store/slices/app/app-info-slice";

/**
 * Welcome dialog shows up when the Employer is signed up for the first time.
 * This dialog will only close if the Employer Acknowledges the Welcome prompt */
export const WelcomeDialog = () => {

    const dispatch = useAppDispatch();
    const isWelcomeAcknowledged = useAppSelector(state => state.app.appInfo.isWelcomeAcknowledged);

    const dispatchWelcomeAcknowledgement = () => {
        dispatch(setIsWelcomeAcknowledged({ _isWelcomeAcknowledged: 'yes' }));
    }

    return (<>
        <ShDialog open={isWelcomeAcknowledged === 'no'}>
            <DialogTitle id='title' textAlign='center' fontWeight={600}>
                Congratulations!
                <IconButton className='close-btn' onClick={dispatchWelcomeAcknowledgement}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    You're about to change the way you hire forever.
                    It will be Easy, Fast and we'll get the best candidates to the top of your pile.
                </Typography>
            </DialogContent>
            <DialogActions>
                <ShButton component={RouterLink} to="/employer/help"
                    onClick={dispatchWelcomeAcknowledgement}>Learn More</ShButton>
                <ShGreenBtn component={RouterLink} to="/employer/job/welcome"
                    onClick={dispatchWelcomeAcknowledgement}>Start Hiring</ShGreenBtn>
            </DialogActions>
        </ShDialog>
    </>);
}

