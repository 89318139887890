import { Box, Paper, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "helpers/hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { PaperVariant } from "shared/SharedStyles/styleConstants";
import { TAppContext } from "store/slices/auth-v2/auth-v2-model";
import { logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { AdminLogoutRedirect, CandidateSurveyLogoutRedirect, EmployerLogoutRedirect, SessionExpiredTimer } from "./AuthConstants";

export const SessionExpired = () => {

    const dispatch = useAppDispatch();
    const { state } = useLocation() as { state: { expiredIn: TAppContext, applyJobUrl: string } };
    const [seconds, setSeconds] = useState<number>(SessionExpiredTimer);
    const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

    const startTimer = useCallback(() => {
        setSeconds(SessionExpiredTimer);
        const interval = setInterval(() => {
            setSeconds(prevSeconds => (prevSeconds === 0 ? 0 : prevSeconds - 1));
        }, 1000);
        setTimer(interval);
    }, []);

    const resetTimer = useCallback(() => {
        if (timer) {
            clearInterval(timer);
            setTimer(null);
        }
    }, [timer]);

    // Start timer on component load
    useEffect(() => startTimer(), [startTimer]);

    useEffect(() => {
        if (seconds === 0) {
            resetTimer();
            dispatch(logoutAccount({ logoutType: 'auto' }));
            window.location.href = EmployerLogoutRedirect;
        }
    }, [dispatch, resetTimer, seconds]);

    const getLogoutRedirect = (): string => {
        switch (state.expiredIn) {
            case 'admin':
                return AdminLogoutRedirect;
            case 'candidate_apply_job':
                return state.applyJobUrl;
            case 'candidate_survey':
                return CandidateSurveyLogoutRedirect;
            case 'employer':
                return EmployerLogoutRedirect;
        }
    }

    return (<>
        <Stack height='100vh' justifyContent='center' alignItems='center' padding={2}>
            <Paper variant={PaperVariant}>
                <Stack alignItems='center' justifyContent='center' rowGap={6} padding={5}>
                    <Box maxWidth={250}>
                        <ShLogo />
                    </Box>
                    <Box textAlign='center'>
                        <Typography variant="subtitle1">Your session has expired.</Typography>
                        <Typography variant="subtitle1">You will be redirected to login page in {seconds} seconds</Typography>
                    </Box>
                    <ShButton variant="contained" color="primary" href={getLogoutRedirect()}
                        onClick={() => dispatch(logoutAccount({ logoutType: 'auto' }))}>Login</ShButton>
                </Stack>
            </Paper>
        </Stack>
    </>);
}

