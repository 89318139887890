import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IApplicantComment, IApplicantCommentPost, IApplicantCommentUpdate, IApplicantCommentsState } from 'Modules/Core/Applicants/ApplicantsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

const initialApplicantCommentState: IApplicantCommentsState = {
    comments: [],
    getCommentsResponse: '',
    getCommentsStatus: 'idle',
    postCommentsResponse: '',
    postCommentsStatus: 'idle',
    updateCommentsResponse: '',
    updateCommentsStatus: 'idle'
};

// get applicants comments if any
export const getApplicantComments = createAsyncThunk<IBaseResponse<IApplicantComment[]>, { applicantId: number }, { rejectValue: IBaseResponse }>(
    'getApplicantComments',
    async ({ applicantId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/comments`)
            .then((response: AxiosResponse<IBaseResponse<IApplicantComment[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// post applicants comments 
export const postApplicantComments = createAsyncThunk<IBaseResponse<IApplicantComment[]>,
    IApplicantCommentPost, { rejectValue: IBaseResponse }>(
        'postApplicantComments',
        async ({ applicantId, payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/comments`, payload)
                .then((response: AxiosResponse<IBaseResponse<IApplicantComment[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

// update applicants comments 
export const updateApplicantComments = createAsyncThunk<IBaseResponse<IApplicantComment[]>,
    IApplicantCommentUpdate, { rejectValue: IBaseResponse }>(
        'updateApplicantComments',
        async ({ applicantId, commentId, payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .put(`${EmployerApiEndpoints.APPLICANTS}/${applicantId}/comments/${commentId}`, payload)
                .then((response: AxiosResponse<IBaseResponse<IApplicantComment[]>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const applicantCommentsSlice = createSlice({
    name: 'applicantsListActions',
    initialState: initialApplicantCommentState,
    reducers: {
        resetCommentsState: (state) => {
            state.getCommentsStatus = 'idle';
            state.postCommentsStatus = 'idle';
            state.updateCommentsStatus = 'idle';
            state.getCommentsResponse = '';
            state.postCommentsResponse = '';
            state.updateCommentsResponse = '';
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialApplicantCommentState;
        });
        // get applicant comments
        builder.addCase(getApplicantComments.pending, (state) => {
            state.getCommentsStatus = 'pending';
        });
        builder.addCase(getApplicantComments.fulfilled, (state, action) => {
            state.getCommentsStatus = 'success';
            state.comments = action?.payload.data;
            state.getCommentsResponse = action?.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(getApplicantComments.rejected, (state, action) => {
            state.getCommentsStatus = 'failed';
            state.getCommentsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // post applicant comments
        builder.addCase(postApplicantComments.pending, (state) => {
            state.postCommentsStatus = 'pending';
        });
        builder.addCase(postApplicantComments.fulfilled, (state, action) => {
            state.postCommentsStatus = 'success';
            state.postCommentsResponse = action?.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(postApplicantComments.rejected, (state, action) => {
            state.postCommentsStatus = 'failed';
            state.postCommentsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update applicant comments
        builder.addCase(updateApplicantComments.pending, (state) => {
            state.updateCommentsStatus = 'pending';
        });
        builder.addCase(updateApplicantComments.fulfilled, (state, action) => {
            state.updateCommentsStatus = 'success';
            state.updateCommentsResponse = action?.payload.message ?? DefaultAPIErrorMsg;
        });
        builder.addCase(updateApplicantComments.rejected, (state, action) => {
            state.updateCommentsStatus = 'failed';
            state.updateCommentsResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetCommentsState } = applicantCommentsSlice.actions;
export default applicantCommentsSlice;
