import { Box, Stack, Typography } from "@mui/material";
import { AdminJobsTableCellOrder, AdminJobsTableHeaders } from "Modules/Core/Admin/AdminDashboard/adminDashboardConstants";
import { JobsFooter } from "Modules/Core/JobsTs/JobsListTs/JobsFooter";
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShAdminTable from "shared/SharedComponents/ShAdminTable/ShAdminTable";
import SHTableSearchBar from "shared/SharedComponents/ShAdminTable/ShAdminTableSearchbar";
import { IJobInfoResponse, IPaginationParams, JobReviewsStates, RootState } from "shared/SharedModels";
import { DefaultPageSize } from "shared/constants";
import { getAdminJobs } from "store/slices/admin/jobs-reviews-slice";

export const AdminJobs = () => {
  const [pageTitle, setPageTitle] = useState<string>("");
  const [tableTitle, setTableTitle] = useState<string>("");
  const [tableRows, setTableRows] = useState<Array<IJobInfoResponse>>([]);
  const [searchKey, setSearchKey] = useState<string>("");
  const [paginationParams, setPaginationParams] = useState<IPaginationParams>(
    { count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0, }
  );
  const { pendingJobs, approvedJobs, rejectedJobs, removedJobs, isFetchingJobs } = useAppSelector((state: RootState) => state.admin.jobReviews);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const activePathname = location.pathname.split("/").pop();
  const isSmScreen = IsSmScreen();

  useEffect(() => {
    const concatSdfcLink = ((jobs: Array<IJobInfoResponse>) => jobs.map((job) => {
      return { ...job, company_name: `${job.company_name} | Link` };
    }));

    switch (activePathname) {
      case "pending-jobs":
        setTableRows(concatSdfcLink(pendingJobs));
        break;
      case "approved-jobs":
        setTableRows(concatSdfcLink(approvedJobs));
        break;
      case "rejected-jobs":
        setTableRows(concatSdfcLink(rejectedJobs));
        break;
      case "removed-jobs":
        setTableRows(concatSdfcLink(removedJobs));
        break;
      case "pending-update-jobs":
        setTableRows(concatSdfcLink([]));
        break;
    }
  }, [pendingJobs, approvedJobs, rejectedJobs, removedJobs, activePathname]);

  useEffect(() => {
    switch (activePathname) {
      case "pending-jobs":
        setPageTitle("Pending Queue");
        setTableTitle("New Jobs Pending Approval");
        dispatch(getAdminJobs({ state: JobReviewsStates.pending, pageNo: 1, pageSize: 20 }));
        break;
      case "approved-jobs":
        setPageTitle("Approval Queue");
        setTableTitle("New Approved Jobs");
        dispatch(getAdminJobs({ state: JobReviewsStates.approved, pageNo: 1, pageSize: 20 }));
        break;
      case "rejected-jobs":
        setPageTitle("Rejected Queue");
        setTableTitle("Rejected Jobs");
        dispatch(getAdminJobs({ state: JobReviewsStates.rejected, pageNo: 1, pageSize: 20 }));
        break;
      case "removed-jobs":
        setPageTitle("Removed Queue");
        setTableTitle("Removed Jobs");
        dispatch(getAdminJobs({ state: JobReviewsStates.removed, pageNo: 1, pageSize: 20 }));
        break;
      case "pending-update-jobs":
        setPageTitle("Pending Updates Queue");
        setTableTitle("New Jobs Pending Updates");
        dispatch(getAdminJobs({ state: JobReviewsStates.updatesPending, pageNo: 1, pageSize: 20 }));
        break;
    }
  }, [activePathname, dispatch]);

  const searchJob = (searchKey: string) => {
    // search the records and update the rows of table
  }

  const handleGetJobs = (pageNo: number, pageSize: number) => {
    dispatch(getAdminJobs({ state: JobReviewsStates.pending, pageNo, pageSize }));
    setPaginationParams((params) => ({ ...params, requestedPageNumber: pageNo, requestedPageSize: pageSize }));
  };

  return (
    <Box p={4}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6" fontWeight="700">{pageTitle}</Typography>
        <SHTableSearchBar searchKey={searchKey} setSearchKey={setSearchKey} onSearch={(searchKey) => searchJob(searchKey)} />
      </Stack>
      <ShAdminTable
        title={tableTitle}
        headers={AdminJobsTableHeaders}
        rows={isFetchingJobs === "pending" ? [] : tableRows}
        cellOrder={AdminJobsTableCellOrder}
        redirectionUrls={["company_name", "name"]}
        generateUrl={(value: string) => `job-reviews/${value}/edit`}
        generateUrlById
        isLoading={isFetchingJobs === "pending" ? true : false}
      />
      <JobsFooter paginationParams={paginationParams} isSmScreen={isSmScreen}
        getJobs={(pageNo, pageSize) => {
          if (pageNo && pageSize) handleGetJobs(pageNo, pageSize)
        }} />
    </Box>
  );
};