export const AcceptableUsePolicyTemplate = {
    title: "Acceptable Use Policy Template",
    policyBlurb: "This Acceptable Use Policy (AUP) template is designed to help you create a comprehensive and clear document that guides the appropriate use of IT resources within your organization, ensuring security, compliance, and productivity.",
    preRead: "Utilize this template to craft an Acceptable Use Policy tailored to your organization's specific needs, ensuring the proper and responsible use of IT resources by all employees, contractors, and partners.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "General Use and Ownership",
                "Security and Proprietary Information",
                "Unacceptable Use",
                "System and Network Activities",
                "Internet and Email Use",
                "Social Media Use",
                "Monitoring and Privacy",
                "Enforcement",
                "Review and Updates",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "This Acceptable Use Policy (AUP) outlines the guidelines and standards for the use of IT resources at [Your Company]. It aims to protect the organization’s assets, ensure compliance with legal and regulatory requirements, and promote a secure and productive work environment. By setting clear expectations, the AUP helps prevent misuse of IT resources and fosters a culture of responsibility and accountability."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this AUP is to establish clear and comprehensive guidelines for the appropriate use of [Your Company]'s IT resources. These resources include, but are not limited to, computers, networks, software, and data. The AUP aims to protect these resources from misuse, ensuring their security, integrity, and efficient use, while also complying with relevant legal and regulatory standards."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, consultants, temporary staff, and other workers at [Your Company], including personnel affiliated with third parties. It covers all IT resources owned, leased, or operated by [Your Company], ensuring that all users understand their responsibilities when using these resources.",
                "The policy encompasses all devices and systems that access the company's IT infrastructure, including personal devices used under a Bring Your Own Device (BYOD) policy, ensuring comprehensive coverage and risk mitigation."
            ]
        },
        {
            heading: "General Use and Ownership",
            paragraphs: [
                "[Your Company]'s IT resources are provided primarily for business purposes to support the organization's operations and objectives. Limited personal use of these resources is permitted as long as it does not interfere with work responsibilities, productivity, or violate any terms of this policy.",
                "Users should have no expectation of privacy when using company IT resources. All activities performed on these resources are subject to monitoring to ensure compliance with this policy and to safeguard company assets and data."
            ]
        },
        {
            heading: "Security and Proprietary Information",
            paragraphs: [
                "Users must take all necessary precautions to ensure the confidentiality, integrity, and availability of sensitive information. This includes adhering to password policies, encrypting sensitive data, and reporting any security incidents promptly.",
                "Access to proprietary data and systems should be strictly controlled and limited to authorized individuals only. Users must not share their access credentials or allow unauthorized access to IT resources, ensuring that all access and data handling practices comply with the company's security protocols."
            ]
        },
        {
            heading: "Unacceptable Use",
            paragraphs: [
                "Engaging in illegal activities or using IT resources for any unlawful purpose is strictly prohibited. This includes, but is not limited to, activities such as hacking, distributing malware, and infringing on intellectual property rights.",
                "Accessing, downloading, or distributing inappropriate or offensive content, including materials that are sexually explicit, discriminatory, or otherwise harmful, is not permitted.",
                "Using IT resources for personal gain, political activities, or any non-business-related purposes that could harm the company or its reputation is forbidden."
            ]
        },
        {
            heading: "System and Network Activities",
            paragraphs: [
                "Users must not attempt to bypass security controls, gain unauthorized access to systems and networks, or engage in any activities that could compromise the security of IT resources. This includes activities such as network scanning, password cracking, and exploiting security vulnerabilities.",
                "Installing unauthorized software or hardware on company IT resources is strictly prohibited. All software and hardware must be approved and managed by the IT department to ensure compliance with security and operational standards."
            ]
        },
        {
            heading: "Internet and Email Use",
            paragraphs: [
                "Internet access is provided primarily for business use. While limited personal use is allowed, it should not interfere with work responsibilities or consume excessive bandwidth. Users must avoid visiting inappropriate or non-work-related websites that could expose the company to security risks.",
                "Users must not send unsolicited emails (spam) or engage in phishing activities. Email communication should be professional and comply with the company's email usage guidelines, ensuring that all messages are secure and respectful."
            ]
        },
        {
            heading: "Social Media Use",
            paragraphs: [
                "Employees must adhere to the company's social media policy when using social platforms. This includes representing the company professionally, avoiding conflicts of interest, and refraining from sharing confidential or proprietary information.",
                "Confidential information should never be shared on social media without explicit authorization from the appropriate authorities. Employees must be cautious about their online presence and ensure that their activities do not harm the company’s reputation or violate any policy terms."
            ]
        },
        {
            heading: "Monitoring and Privacy",
            paragraphs: [
                "[Your Company] reserves the right to monitor all IT resource usage to ensure compliance with this policy. Monitoring activities may include, but are not limited to, tracking internet usage, email communications, and access to files and systems.",
                "Users should be aware that their activities are subject to monitoring and that there is no expectation of privacy when using company IT resources. Monitoring is conducted to protect the company’s assets and ensure a secure and compliant operational environment."
            ]
        },
        {
            heading: "Enforcement",
            paragraphs: [
                "Violations of this policy may result in disciplinary action, up to and including termination of employment. The severity of the disciplinary action will depend on the nature and impact of the violation.",
                "Legal action may be taken against individuals who engage in illegal activities using company IT resources. This could include criminal charges and civil litigation to recover damages or protect the company’s interests."
            ]
        },
        {
            heading: "Review and Updates",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in technology, business practices, and legal requirements. Regular reviews ensure that the policy remains relevant and effective in protecting the company’s IT resources.",
                "Employees will be notified of any significant changes to the policy. It is the responsibility of all users to stay informed about the current policy and comply with its terms."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All users must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Acceptable Use Policy. Acknowledgment is a mandatory requirement to ensure that all users are aware of their responsibilities and the consequences of non-compliance."
        }
    ]
};

export const CodeOfConductForEmployeesTemplate = {
    title: "Code of Conduct for Employees Template",
    policyBlurb: "This Code of Conduct outlines the expectations for employee behavior and performance at [Company Name], fostering a professional, ethical, and respectful work environment.",
    preRead: "Use this template to develop a policy that ensures a professional, ethical, and respectful work environment, aligning employee behavior with the company’s values and standards.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Employee Responsibilities",
                "Professional Behavior",
                "Conflict of Interest",
                "Confidentiality",
                "Use of Company Assets",
                "Reporting Violations",
                "Enforcement",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Company Name], we are dedicated to upholding a high standard of professional conduct. This Code of Conduct outlines the expectations for employee behavior and performance, ensuring a consistent and positive work environment for everyone. By adhering to these guidelines, employees contribute to the overall success and integrity of the organization."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this Code of Conduct is to establish clear guidelines for professional behavior, ensuring that all employees act ethically and respectfully in all interactions. This policy is designed to promote a work environment that is free from discrimination, harassment, and conflicts of interest, thereby supporting the company's mission and values."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, consultants, temporary staff, and affiliates of [Company Name], regardless of their role or location. It is essential that everyone associated with the company adheres to the standards set forth in this document.",
                "The policy encompasses all aspects of employment, including but not limited to recruitment, hiring, training, performance evaluations, promotions, and terminations. It also applies to all business-related activities and interactions, both within and outside the workplace."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are expected to act in the best interest of the company at all times, adhering to all established policies, procedures, and ethical guidelines. This includes demonstrating integrity, accountability, and respect in all professional activities.",
                "Employees must promptly report any unethical behavior, violations of this policy, or any conduct that could harm the company’s reputation or operations. Reporting mechanisms are in place to ensure that concerns can be raised safely and confidentially."
            ]
        },
        {
            heading: "Professional Behavior",
            paragraphs: [
                "Employees are required to maintain a high standard of professional conduct. This includes engaging in respectful communication, collaboration, and behavior that fosters a positive and productive work environment. Professional behavior extends to all forms of interaction, whether in-person, via email, or on digital platforms.",
                "Any form of harassment, discrimination, or bullying is strictly prohibited. This includes behavior that is offensive, intimidating, or hostile towards colleagues, clients, or any other individuals. The company is committed to ensuring a workplace that is inclusive and free from any form of discrimination based on race, gender, age, religion, sexual orientation, disability, or any other protected characteristic."
            ]
        },
        {
            heading: "Conflict of Interest",
            paragraphs: [
                "Employees must avoid situations where their personal interests conflict with the interests of the company. This includes avoiding any activities, relationships, or financial interests that could compromise or appear to compromise their impartiality and loyalty to the company.",
                "Any potential conflicts of interest must be disclosed to the HR department or relevant supervisor as soon as they arise. Transparency and proactive disclosure are essential to maintaining trust and integrity within the organization."
            ]
        },
        {
            heading: "Confidentiality",
            paragraphs: [
                "Employees must protect sensitive company information and refrain from disclosing it without proper authorization. Confidential information includes, but is not limited to, trade secrets, financial data, client information, strategic plans, and employee records.",
                "Unauthorized disclosure of confidential information can have serious consequences for the company and may result in disciplinary action, including termination. Employees are also required to continue protecting confidential information even after their employment with the company has ended."
            ]
        },
        {
            heading: "Use of Company Assets",
            paragraphs: [
                "Employees must use company assets responsibly and for legitimate business purposes only. Company assets include physical property, such as equipment and office supplies, as well as digital assets, such as software, email accounts, and data.",
                "Misuse of company assets, including theft, damage, or unauthorized use, will not be tolerated and may result in disciplinary action. Employees are expected to take reasonable steps to protect company assets from loss, damage, or misuse."
            ]
        },
        {
            heading: "Reporting Violations",
            paragraphs: [
                "Employees are encouraged and expected to report any violations of this policy to their supervisor, the HR department, or through any established reporting channels. The company provides multiple avenues for reporting concerns to ensure that employees can choose the method they are most comfortable with.",
                "All reports of violations will be investigated promptly and thoroughly. The company is committed to handling all reports with confidentiality and sensitivity, protecting the rights and privacy of those involved to the fullest extent possible."
            ]
        },
        {
            heading: "Enforcement",
            paragraphs: [
                "Violations of this policy may result in disciplinary action, up to and including termination of employment. The specific disciplinary measures will depend on the nature and severity of the violation, as well as any previous conduct issues.",
                "In cases where violations involve illegal activities, the company reserves the right to take appropriate legal action. This may include reporting the matter to law enforcement authorities and pursuing legal remedies to protect the company’s interests."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and revised as necessary to ensure it remains current and effective. The review process will consider changes in laws, regulations, and best practices, as well as feedback from employees and stakeholders.",
                "Employees will be informed of any significant changes to the policy. It is the responsibility of all employees to stay informed about the current policy and to comply with its terms. The company will provide training and resources as needed to support compliance."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Code of Conduct. Acknowledgment is a mandatory requirement and a condition of continued employment. It ensures that all employees are aware of the expectations and responsibilities outlined in this policy."
        }
    ]
};

export const CorporateAffirmativeActionCompliancePolicyTemplate = {
    title: "Corporate Affirmative Action Compliance Policy",
    policyBlurb: "This policy outlines our commitment to affirmative action and equal employment opportunities, ensuring a diverse and inclusive workplace.",
    preRead: "Use this template to develop a policy that ensures compliance with affirmative action requirements and promotes diversity in the workplace, fostering an environment where all employees can thrive.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Affirmative Action Goals",
                "Responsibilities",
                "Recruitment and Hiring",
                "Training and Development",
                "Monitoring and Reporting",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we are firmly committed to affirmative action and equal employment opportunities. This Corporate Affirmative Action Compliance Policy outlines our strategic approach to achieving affirmative action goals, ensuring that our workforce is diverse and inclusive, and reflects the communities we serve."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure full compliance with affirmative action requirements and to actively promote diversity in the workplace. This policy is designed to support a work environment where all employees, regardless of their background, have equal opportunities to succeed and advance their careers."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and job applicants at [Your Company], encompassing all locations and divisions. It covers all aspects of employment, including but not limited to recruitment, hiring, training, promotion, and termination.",
                "By adhering to this policy, [Your Company] aims to uphold the principles of affirmative action and equal employment opportunity in every facet of our operations."
            ]
        },
        {
            heading: "Affirmative Action Goals",
            paragraphs: [
                "Our company aims to foster a diverse and inclusive workforce through the implementation of comprehensive affirmative action programs. These programs are designed to address underrepresentation and ensure that all individuals have access to opportunities based on merit and ability.",
                "We strive to create a workforce that reflects the diversity of our community and the customers we serve. Our goals include increasing the representation of underrepresented groups in all levels of the organization, from entry-level positions to senior management."
            ]
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "All managers and supervisors are responsible for implementing affirmative action programs within their respective departments. This includes ensuring that hiring practices, promotions, and other employment decisions are made in accordance with this policy.",
                "The HR department is responsible for overseeing the implementation and monitoring of affirmative action programs. This includes tracking progress, addressing any barriers to success, and ensuring compliance with all relevant laws and regulations."
            ]
        },
        {
            heading: "Recruitment and Hiring",
            paragraphs: [
                "Our recruitment and hiring practices are designed to promote equal opportunities for all candidates. We are committed to actively seeking out and recruiting individuals from underrepresented groups, ensuring that our hiring processes are fair, unbiased, and inclusive.",
                "Specific strategies include engaging with diverse professional organizations, attending career fairs that target underrepresented groups, and implementing bias training for hiring managers to ensure equitable treatment of all candidates."
            ]
        },
        {
            heading: "Training and Development",
            paragraphs: [
                "We will provide training and development opportunities that support the advancement of underrepresented groups within the company. This includes offering mentorship programs, leadership development courses, and continuous learning opportunities to help all employees reach their full potential.",
                "Employees are encouraged to participate in training programs to enhance their skills and career development. Our goal is to create a culture of continuous improvement where every employee has the opportunity to grow and advance."
            ]
        },
        {
            heading: "Monitoring and Reporting",
            paragraphs: [
                "The HR department will monitor the effectiveness of affirmative action programs and report on progress to senior management. This includes conducting regular audits of hiring, promotion, and termination practices to identify areas for improvement.",
                "Regular reports will be provided to senior management and relevant regulatory agencies. These reports will detail our progress toward affirmative action goals and any steps taken to address identified challenges or barriers."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and best practices. The review process will involve input from various stakeholders, including employees, managers, and external experts.",
                "Employees will be informed of any significant changes to the policy. It is the responsibility of all employees to stay informed about the current policy and to comply with its terms."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Corporate Affirmative Action Compliance Policy. Acknowledgment is a mandatory requirement and a condition of employment, ensuring that all employees are aware of their responsibilities and the company’s commitment to affirmative action."
        }
    ]
};

export const DataProtectionAndPrivacyPolicyTemplate = {
    title: "Data Protection and Privacy Policy Template",
    policyBlurb: "This policy outlines our commitment to protecting the privacy and data of our employees, customers, and stakeholders.",
    preRead: "Use this template to develop a policy that ensures compliance with data protection regulations and promotes best practices in data privacy.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Data Collection",
                "Data Use",
                "Data Storage and Security",
                "Data Access and Sharing",
                "Data Breach Response",
                "Employee Responsibilities",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is committed to protecting the privacy and data of our employees, customers, and stakeholders. This Data Protection and Privacy Policy outlines our approach to handling personal data in compliance with relevant regulations and industry best practices. We recognize the importance of maintaining the privacy and security of personal data and are dedicated to ensuring that all data is handled responsibly and securely."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure compliance with data protection regulations and to promote best practices in data privacy within our organization. We aim to establish clear guidelines for the collection, use, storage, and sharing of personal data, thereby safeguarding the privacy rights of individuals and maintaining their trust in our company."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and third-party service providers at [Your Company]. It encompasses all personal data collected, processed, and stored by the company, regardless of the format or medium in which the data is held.",
                "It covers all aspects of personal data management, including but not limited to the collection, use, storage, transfer, and disposal of personal data. This policy also extends to all business units and departments within the company, ensuring a unified approach to data protection across the organization."
            ]
        },
        {
            heading: "Data Collection",
            paragraphs: [
                "We will only collect personal data that is necessary for legitimate business purposes. The data collected will be relevant, adequate, and limited to what is necessary in relation to the purposes for which it is processed.",
                "Data will be collected in a fair and transparent manner, with the knowledge and consent of the data subject where required. We will provide clear information about the purposes of data collection and the rights of data subjects concerning their data."
            ]
        },
        {
            heading: "Data Use",
            paragraphs: [
                "Personal data will be used solely for the purposes for which it was collected, unless further consent is obtained from the data subject. We will ensure that data use is lawful, fair, and transparent.",
                "We will not use personal data for any purposes that are incompatible with the original purpose of collection. Any secondary uses of personal data will be clearly communicated to the data subject, and their consent will be obtained where required."
            ]
        },
        {
            heading: "Data Storage and Security",
            paragraphs: [
                "Personal data will be stored securely, using appropriate technical and organizational measures to protect against unauthorized access, alteration, or destruction. These measures include, but are not limited to, encryption, access controls, and regular security audits.",
                "We will retain personal data only for as long as necessary to fulfill the purposes for which it was collected, or as required by law. Upon the expiry of the retention period, personal data will be securely deleted or anonymized to prevent unauthorized access."
            ]
        },
        {
            heading: "Data Access and Sharing",
            paragraphs: [
                "Access to personal data will be restricted to authorized personnel who require it for legitimate business purposes. Access controls will be implemented to ensure that only individuals with a need to know can access personal data.",
                "We will not share personal data with third parties without the explicit consent of the data subject, except as required by law. Any third-party data processors engaged by the company will be required to comply with our data protection standards and undergo regular audits to ensure compliance."
            ]
        },
        {
            heading: "Data Breach Response",
            paragraphs: [
                "In the event of a data breach, we will take immediate steps to mitigate the impact and notify affected individuals as required by law. Our data breach response plan outlines the procedures for identifying, containing, and rectifying data breaches.",
                "We will investigate all data breaches to determine the cause and implement measures to prevent future occurrences. Affected individuals and regulatory authorities will be informed promptly, and we will provide support to those impacted by the breach."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for protecting the personal data they handle and for reporting any data breaches or security incidents promptly. They must adhere to this policy and related procedures to ensure the security and confidentiality of personal data.",
                "Employees must complete mandatory data protection training and remain informed about data protection best practices and regulatory requirements. They should also be vigilant in identifying and reporting any potential data protection risks."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in regulations and best practices. We will ensure that the policy remains relevant and effective in addressing current data protection challenges.",
                "Employees will be informed of any significant changes to the policy. We will also seek feedback from employees and stakeholders to continuously improve our data protection practices."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Data Protection and Privacy Policy. This acknowledgment underscores our collective commitment to safeguarding personal data and maintaining the trust of our stakeholders."
        }
    ]
};

export const EmployeeAccessibilityAndInclusionPolicyTemplate = {
    title: "Employee Accessibility and Inclusion Policy Template",
    policyBlurb: "This policy outlines our commitment to creating an accessible and inclusive workplace for all employees.",
    preRead: "Use this template to develop a policy that ensures accessibility and promotes inclusion for employees with disabilities.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Accessibility Standards",
                "Reasonable Accommodations",
                "Employee Responsibilities",
                "Training and Awareness",
                "Monitoring and Reporting",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is dedicated to creating an accessible and inclusive workplace for all employees, including those with disabilities. This Employee Accessibility and Inclusion Policy outlines our approach to ensuring that all employees have equal opportunities to succeed, regardless of any disabilities they may have."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure accessibility and promote inclusion for employees with disabilities, fostering a work environment where all employees can thrive and contribute to the company's success."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and job applicants at [Your Company]. It covers all aspects of employment, including recruitment, hiring, training, promotion, and termination.",
                "The policy also applies to all company facilities, technologies, and communications to ensure they are accessible to employees with disabilities."
            ]
        },
        {
            heading: "Accessibility Standards",
            paragraphs: [
                "We will adhere to all relevant accessibility standards and regulations to ensure that our facilities, services, and communications are accessible to employees with disabilities. This includes compliance with the Americans with Disabilities Act (ADA) and other applicable laws.",
                "Regular audits will be conducted to identify and address any accessibility barriers. We will work continuously to improve accessibility in our workplace and to remove any obstacles that may hinder employees' ability to perform their job duties."
            ]
        },
        {
            heading: "Reasonable Accommodations",
            paragraphs: [
                "We are committed to providing reasonable accommodations to employees with disabilities to enable them to perform their job duties effectively. Reasonable accommodations may include modifications to the work environment, job restructuring, flexible work schedules, and the provision of assistive devices or technologies.",
                "Employees who require accommodations should contact the HR department to initiate the accommodation process. The HR department will work with the employee and their supervisor to identify appropriate accommodations and ensure they are implemented promptly."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for supporting an inclusive workplace by treating all colleagues with respect and consideration. This includes recognizing and valuing the contributions of employees with disabilities and helping to create an environment where everyone can succeed.",
                "Employees should report any accessibility barriers or discriminatory behavior to their supervisor or the HR department. All reports will be taken seriously and addressed promptly to ensure a respectful and inclusive workplace."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "All employees will receive training on accessibility and inclusion to ensure they understand their responsibilities and the importance of an inclusive workplace. Training will cover topics such as disability awareness, inclusive communication, and best practices for supporting colleagues with disabilities.",
                "Training programs will be updated regularly to reflect best practices and regulatory changes. We will also provide ongoing education and resources to promote a culture of inclusion and accessibility within the organization."
            ]
        },
        {
            heading: "Monitoring and Reporting",
            paragraphs: [
                "The HR department will monitor the effectiveness of accessibility and inclusion initiatives and report on progress to senior management. Regular assessments will be conducted to evaluate our performance and identify areas for improvement.",
                "We will seek feedback from employees, including those with disabilities, to understand their experiences and to ensure that our policies and practices meet their needs. This feedback will be used to inform and enhance our accessibility and inclusion efforts."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws and best practices. Regular reviews will ensure that our accessibility and inclusion practices remain effective and compliant with legal requirements.",
                "Employees will be informed of any significant changes to the policy through formal communications, and additional training will be provided as needed to ensure understanding and compliance."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Accessibility and Inclusion Policy. Acknowledgment records will be maintained by the HR department to ensure compliance."
        }
    ]
};
export const EmployeeAntiDiscriminationAndEqualOpportunityPolicyTemplate = {
    title: "Employee Anti-Discrimination and Equal Opportunity Policy Template",
    policyBlurb: "This policy outlines our commitment to providing a workplace free from discrimination and promoting equal opportunity for all employees.",
    preRead: "Use this template to develop a policy that ensures compliance with anti-discrimination laws and promotes diversity and inclusion in the workplace.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Non-Discrimination Policy",
                "Equal Opportunity Policy",
                "Harassment and Bullying",
                "Employee Responsibilities",
                "Training and Awareness",
                "Monitoring and Reporting",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is committed to providing a workplace free from discrimination and promoting equal opportunity for all employees. This Employee Anti-Discrimination and Equal Opportunity Policy outlines our approach to ensuring a fair and inclusive work environment."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure compliance with anti-discrimination laws and to promote diversity and inclusion within our organization. We strive to create a workplace where all employees are treated with respect and have equal opportunities to succeed."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and job applicants at [Your Company]. It covers all aspects of employment, including recruitment, hiring, training, promotion, and termination.",
                "The policy applies to all work-related interactions and environments, both on-site and off-site, including company events, meetings, and online communications."
            ]
        },
        {
            heading: "Non-Discrimination Policy",
            paragraphs: [
                "We are committed to providing a work environment free from discrimination. Discrimination based on race, color, religion, sex, sexual orientation, gender identity, national origin, disability, age, or any other protected characteristic is strictly prohibited.",
                "Any form of discriminatory behavior, including biased decision-making, exclusion, or unequal treatment, will not be tolerated. Employees found to be engaging in discriminatory practices will be subject to disciplinary action."
            ]
        },
        {
            heading: "Equal Opportunity Policy",
            paragraphs: [
                "We are an equal opportunity employer and are committed to providing equal employment opportunities to all individuals. Employment decisions will be based on merit, qualifications, and business needs.",
                "We will take proactive measures to ensure that our recruitment, hiring, and promotion practices are free from bias and that we actively seek to include individuals from diverse backgrounds."
            ]
        },
        {
            heading: "Harassment and Bullying",
            paragraphs: [
                "Harassment and bullying in any form are strictly prohibited. This includes verbal, physical, and psychological abuse, as well as any behavior that creates a hostile or intimidating work environment.",
                "Employees are encouraged to report any incidents of harassment or bullying to their supervisor or the HR department. All reports will be investigated promptly and confidentially, and appropriate action will be taken to address the situation."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for contributing to a respectful and inclusive workplace. This includes treating all colleagues with respect, refraining from discriminatory or harassing behavior, and supporting diversity and inclusion initiatives.",
                "Employees should report any violations of this policy to their supervisor or the HR department. All reports will be taken seriously and investigated promptly to ensure compliance with this policy."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "All employees will receive training on anti-discrimination and equal opportunity to ensure they understand their rights and responsibilities. Training programs will cover topics such as recognizing and addressing discrimination, promoting inclusion, and best practices for creating an equitable workplace.",
                "Training programs will be updated regularly to reflect best practices and changes in laws. We will also provide ongoing education and resources to promote a culture of respect and inclusion within the organization."
            ]
        },
        {
            heading: "Monitoring and Reporting",
            paragraphs: [
                "The HR department will monitor compliance with this policy and report on progress to senior management. Regular assessments will be conducted to evaluate our performance and identify areas for improvement.",
                "We will seek feedback from employees to understand their experiences and to ensure that our policies and practices meet their needs. This feedback will be used to inform and enhance our anti-discrimination and equal opportunity efforts."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws and best practices. Regular reviews will ensure that our anti-discrimination and equal opportunity practices remain effective and compliant with legal requirements.",
                "Employees will be informed of any significant changes to the policy through formal communications, and additional training will be provided as needed to ensure understanding and compliance."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Anti-Discrimination and Equal Opportunity Policy. Acknowledgment records will be maintained by the HR department to ensure compliance."
        }
    ]
};

export const EmployeeAssistanceAndSupportProgramTemplate = {
    title: "Employee Assistance and Support Program Template",
    policyBlurb: "This policy outlines our commitment to providing employees with assistance and support for personal and work-related issues.",
    preRead: "Use this template to develop a policy that promotes employee well-being and provides resources for addressing personal and professional challenges.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Employee Assistance Program (EAP)",
                "Confidentiality and Privacy",
                "Accessing EAP Services",
                "Work-Life Balance",
                "Promotion and Awareness",
                "Monitoring and Evaluation",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is committed to supporting the well-being of our employees. This Employee Assistance and Support Program (EAP) outlines our commitment to providing resources and support for personal and work-related challenges that may impact employees' health, well-being, and job performance."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to promote employee well-being, enhance job satisfaction, and improve productivity by providing access to confidential assistance and support services."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company]. It covers all aspects of the Employee Assistance Program (EAP), including eligibility, services offered, and confidentiality.",
                "The policy aims to support employees facing personal issues, work-related stress, mental health challenges, substance abuse, family or relationship problems, financial concerns, and other issues affecting their well-being."
            ]
        },
        {
            heading: "Employee Assistance Program (EAP)",
            paragraphs: [
                "Our EAP provides confidential counseling, assessment, referral, and support services to employees and their immediate family members.",
                "Services may include short-term counseling, crisis intervention, legal and financial consultations, wellness programs, and resources for managing work-life balance."
            ]
        },
        {
            heading: "Confidentiality and Privacy",
            paragraphs: [
                "Confidentiality is a cornerstone of our EAP. Information shared by employees during counseling sessions or while accessing EAP services will be kept strictly confidential, in accordance with applicable laws and ethical standards.",
                "EAP providers will only disclose information with the employee's consent or as required by law, such as when there is a risk of harm to self or others."
            ]
        },
        {
            heading: "Accessing EAP Services",
            paragraphs: [
                "Employees can access EAP services voluntarily and without fear of reprisal. We encourage employees to seek assistance early to address issues before they escalate.",
                "Information about accessing EAP services, including contact details and available resources, will be communicated to employees through multiple channels, ensuring accessibility and awareness."
            ]
        },
        {
            heading: "Work-Life Balance",
            paragraphs: [
                "We recognize the importance of work-life balance in promoting employee well-being. Our EAP includes resources and programs aimed at supporting employees in managing their professional and personal responsibilities.",
                "Flexible work arrangements, time-off policies, and wellness initiatives are among the strategies we employ to help employees achieve a healthy work-life balance."
            ]
        },
        {
            heading: "Promotion and Awareness",
            paragraphs: [
                "We will actively promote awareness of the EAP through training sessions, orientation programs for new employees, and ongoing communications.",
                "Managers and supervisors will be trained to recognize signs of employee distress and to refer employees to EAP services when appropriate."
            ]
        },
        {
            heading: "Monitoring and Evaluation",
            paragraphs: [
                "The effectiveness of the EAP will be monitored through feedback from employees, utilization reports, and assessments of outcomes.",
                "Regular evaluations will be conducted to measure the impact of the EAP on employee well-being, job satisfaction, and organizational performance."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, employee feedback, and best practices in employee assistance programs.",
                "Employees will be informed of any significant changes to the policy through formal communications, ensuring transparency and understanding."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Assistance and Support Program Policy. Acknowledgment records will be maintained by the HR department to ensure compliance."
        }
    ]
};

export const EmployeeAttendanceAndPunctualityPolicyTemplate = {
    title: "Employee Attendance and Punctuality Policy Template",
    policyBlurb: "This policy outlines our expectations regarding employee attendance, punctuality, and leave management.",
    preRead: "Use this template to develop a policy that promotes accountability, fairness, and productivity in managing employee attendance and punctuality.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Attendance Expectations",
                "Punctuality Expectations",
                "Leave Management",
                "Reporting Absences",
                "Exceptions and Accommodations",
                "Monitoring and Enforcement",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company values employee attendance and punctuality as critical to maintaining a productive and efficient workplace. This policy establishes clear expectations and guidelines for managing attendance, punctuality, and leave."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure fairness, consistency, and accountability in managing employee attendance, punctuality, and leave, while accommodating legitimate absences and promoting a positive work environment."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], including full-time, part-time, and temporary employees. It covers all aspects of attendance, punctuality, and leave management.",
                "The policy outlines procedures for reporting absences, requesting leave, addressing tardiness, and managing exceptions and accommodations."
            ]
        },
        {
            heading: "Attendance Expectations",
            paragraphs: [
                "Employees are expected to report to work as scheduled and to maintain regular attendance. Excessive absenteeism or unexcused absences may result in disciplinary action.",
                "Attendance records will be maintained to track employee attendance patterns and to identify any recurring issues that may require intervention or support."
            ]
        },
        {
            heading: "Punctuality Expectations",
            paragraphs: [
                "Employees are expected to arrive on time for their scheduled shifts, meetings, and appointments. Tardiness disrupts workflow and may impact team productivity.",
                "Managers and supervisors will address instances of tardiness promptly and provide coaching or corrective action as necessary to improve punctuality."
            ]
        },
        {
            heading: "Leave Management",
            paragraphs: [
                "Employees are entitled to various types of leave, including sick leave, vacation leave, and other statutory leaves as mandated by law.",
                "Leave requests must be submitted in advance, following the company's leave request procedures, except in cases of unforeseen circumstances or emergencies."
            ]
        },
        {
            heading: "Reporting Absences",
            paragraphs: [
                "Employees are responsible for notifying their supervisor or designated contact as soon as possible when they are unable to report to work due to illness or other reasons.",
                "Absences should be reported within the specified timeframe, and employees may be required to provide documentation for extended absences or sick leave."
            ]
        },
        {
            heading: "Exceptions and Accommodations",
            paragraphs: [
                "Reasonable accommodations will be considered for employees who require flexibility due to medical conditions, disabilities, or other legitimate reasons.",
                "Requests for accommodations should be submitted to the HR department, which will evaluate each request based on individual circumstances and company policies."
            ]
        },
        {
            heading: "Monitoring and Enforcement",
            paragraphs: [
                "Managers and supervisors will monitor attendance and punctuality to ensure compliance with this policy. They will address any concerns promptly and fairly, providing support and guidance to employees as needed.",
                "Disciplinary action may be taken for repeated violations of this policy, up to and including termination of employment, depending on the severity and frequency of the infractions."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, employment laws, and best practices in attendance management.",
                "Employees will be informed of any significant changes to the policy through formal communications, ensuring transparency and understanding."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Attendance and Punctuality Policy. Acknowledgment records will be maintained by the HR department to ensure compliance."
        }
    ]
};

export const EmployeeBackgroundVerificationPolicyTemplate = {
    title: "Employee Background Verification Policy Template",
    policyBlurb: "This policy outlines our procedures for conducting background checks on prospective employees.",
    preRead: "Use this template to develop a policy that ensures thorough and consistent background verification processes, enhancing workplace safety and security.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Background Verification Process",
                "Types of Checks",
                "Confidentiality and Privacy",
                "Employee Consent",
                "Verification Criteria",
                "Handling Adverse Findings",
                "Compliance",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is committed to ensuring a safe and secure workplace for all employees. This Employee Background Verification Policy outlines our procedures for conducting thorough background checks on prospective employees."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish guidelines for conducting background verifications that help mitigate risks, ensure workplace safety, protect company assets, and maintain trust with stakeholders."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all prospective employees of [Your Company] who have received a conditional offer of employment. It covers the background verification process from initiation to completion.",
                "The policy ensures consistency and fairness in the application of background checks, while adhering to applicable laws and regulations."
            ]
        },
        {
            heading: "Background Verification Process",
            paragraphs: [
                "Background verifications will be conducted by the HR department or designated third-party providers.",
                "The process includes verifying educational credentials, employment history, professional licenses, criminal records, and other relevant information."
            ]
        },
        {
            heading: "Types of Checks",
            paragraphs: [
                "The types of checks conducted may include criminal background checks, credit history checks (where permitted by law and relevant to the position), and verification of professional references.",
                "Additional checks may be required based on the nature of the position or regulatory requirements."
            ]
        },
        {
            heading: "Confidentiality and Privacy",
            paragraphs: [
                "All information obtained during the background verification process will be treated with strict confidentiality and in accordance with applicable privacy laws.",
                "Access to background check results will be limited to authorized personnel involved in the hiring decision."
            ]
        },
        {
            heading: "Employee Consent",
            paragraphs: [
                "Prospective employees must provide written consent before a background check is initiated. The consent form will outline the types of checks to be conducted and how the information will be used.",
                "Candidates have the right to review the results of their background check and to dispute any inaccurate or incomplete information."
            ]
        },
        {
            heading: "Verification Criteria",
            paragraphs: [
                "Background checks will be conducted based on predefined criteria relevant to the position and consistent with industry standards and legal requirements.",
                "Criteria may include assessing the candidate's suitability for the role, evaluating potential risks, and ensuring compliance with regulatory standards."
            ]
        },
        {
            heading: "Handling Adverse Findings",
            paragraphs: [
                "If adverse findings are identified during the background check, the HR department will follow established procedures for review and evaluation.",
                "Candidates will be given an opportunity to provide additional information or context regarding adverse findings before any employment decision is made."
            ]
        },
        {
            heading: "Compliance",
            paragraphs: [
                "This policy complies with all applicable laws and regulations governing background checks, including but not limited to the Fair Credit Reporting Act (FCRA) and state-specific regulations.",
                "HR personnel responsible for conducting background checks will receive training on legal requirements, privacy considerations, and best practices."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in legal requirements, industry standards, and business needs.",
                "Employees will be informed of any significant changes to the policy through formal communications, ensuring transparency and compliance."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All prospective employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Background Verification Policy. Acknowledgment records will be maintained by the HR department to ensure compliance."
        }
    ]
};

export const EmployeeBereavementLeavePolicyTemplate = {
    title: "Employee Bereavement Leave Policy Template",
    policyBlurb: "This policy outlines our approach to providing compassionate bereavement leave to employees who experience the loss of a loved one.",
    preRead: "Use this template to develop a policy that supports employees during times of loss, promotes well-being, and ensures consistency in bereavement leave practices.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Eligibility",
                "Duration of Bereavement Leave",
                "Notification and Documentation",
                "Flexibility and Support",
                "Interaction with Other Leave Types",
                "Returning to Work",
                "Training and Awareness",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company understands the importance of supporting employees during difficult times. This Employee Bereavement Leave Policy outlines our commitment to providing compassionate leave to employees who have experienced the death of a family member or loved one."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide eligible employees with time off to grieve and attend to matters related to the death of a family member or loved one. It aims to support employees emotionally, ensure fairness in leave practices, and maintain productivity."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company] who are regular full-time employees. It covers the procedures and guidelines for requesting and granting bereavement leave.",
                "The policy ensures that employees are treated with empathy and compassion during times of bereavement, while also balancing the operational needs of the company."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "Employees who experience the death of an immediate family member or close relative are eligible for bereavement leave.",
                "Immediate family members typically include spouse or partner, children, parents, siblings, grandparents, and grandchildren. The definition may be extended to cover other relatives depending on cultural or familial norms."
            ]
        },
        {
            heading: "Duration of Bereavement Leave",
            paragraphs: [
                "The duration of bereavement leave will vary based on the employee's relationship with the deceased and cultural practices. Generally, employees may be granted up to [number] days of paid bereavement leave.",
                "Additional unpaid leave may be available upon request and approval, considering individual circumstances and the needs of the employee."
            ]
        },
        {
            heading: "Notification and Documentation",
            paragraphs: [
                "Employees are required to notify their supervisor or HR department as soon as possible upon the death of a family member or loved one.",
                "Documentation, such as a death certificate or obituary notice, may be requested to verify the need for bereavement leave. Confidentiality will be maintained regarding the nature of the leave."
            ]
        },
        {
            heading: "Flexibility and Support",
            paragraphs: [
                "We recognize that grieving is a personal process and that employees may need flexibility in how they use bereavement leave.",
                "Managers and supervisors are encouraged to provide additional support and flexibility, such as remote work options or adjusted schedules, to accommodate grieving employees."
            ]
        },
        {
            heading: "Interaction with Other Leave Types",
            paragraphs: [
                "Bereavement leave may run concurrently with other types of leave, such as sick leave or vacation leave, depending on company policy and applicable laws.",
                "Employees should consult with HR to understand how bereavement leave interacts with other leave entitlements."
            ]
        },
        {
            heading: "Returning to Work",
            paragraphs: [
                "Employees are encouraged to take the time they need to grieve and heal before returning to work.",
                "Managers and HR will support employees in their return to work process, ensuring a smooth transition and providing any necessary accommodations."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "Managers and HR personnel will receive training on how to handle bereavement situations sensitively and effectively.",
                "We will promote awareness of the bereavement leave policy through employee handbooks, orientation programs, and regular communications."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to ensure it meets the needs of employees and aligns with best practices in bereavement support.",
                "Employees will be informed of any changes to the policy through company-wide communications, ensuring transparency and understanding."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Bereavement Leave Policy. Acknowledgment records will be maintained by the HR department."
        }
    ]
};

export const EmployeeBreastfeedingSupportPolicyTemplate = {
    title: "Employee Breastfeeding Support Policy Template",
    policyBlurb: "This policy outlines our commitment to supporting employees who are breastfeeding or expressing milk for their infants.",
    preRead: "Use this template to develop a policy that promotes a supportive and inclusive workplace for breastfeeding employees, ensuring compliance with legal requirements.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Employee Rights",
                "Facilities and Equipment",
                "Flexible Work Arrangements",
                "Privacy and Respect",
                "Health and Safety",
                "Training and Awareness",
                "Monitoring and Compliance",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company values diversity and inclusion and is committed to supporting employees who are breastfeeding or expressing milk for their infants. This Employee Breastfeeding Support Policy outlines our commitment to providing a supportive environment for breastfeeding employees."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure that breastfeeding employees are provided with the necessary support, facilities, and accommodations to continue breastfeeding or expressing milk upon returning to work."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company] who are breastfeeding or expressing milk for their infants. It covers the rights, facilities, and accommodations available to breastfeeding employees.",
                "The policy aims to comply with applicable laws and regulations related to breastfeeding support in the workplace, while promoting a culture of respect and inclusion."
            ]
        },
        {
            heading: "Employee Rights",
            paragraphs: [
                "Breastfeeding employees have the right to reasonable break times for expressing milk during the workday, as well as access to a private space, other than a restroom, for this purpose.",
                "Employees will not face discrimination, retaliation, or adverse employment actions for exercising their rights under this policy."
            ]
        },
        {
            heading: "Facilities and Equipment",
            paragraphs: [
                "We will provide clean and private lactation rooms equipped with comfortable seating, electrical outlets, and access to refrigeration for storing expressed milk.",
                "Employees will have access to lactation supplies, such as breast pumps and storage containers, upon request and as needed."
            ]
        },
        {
            heading: "Flexible Work Arrangements",
            paragraphs: [
                "Flexible work schedules or telecommuting options may be considered to accommodate breastfeeding employees' needs, subject to operational requirements.",
                "Employees are encouraged to discuss flexible work arrangements with their supervisor or HR department in advance."
            ]
        },
        {
            heading: "Privacy and Respect",
            paragraphs: [
                "Managers and coworkers are expected to respect the privacy and dignity of breastfeeding employees.",
                "Discussions about breastfeeding accommodations will be handled discreetly and confidentially."
            ]
        },
        {
            heading: "Health and Safety",
            paragraphs: [
                "We are committed to providing a safe and healthy environment for breastfeeding employees and their infants.",
                "Employees will receive information on maintaining hygiene and preventing contamination when handling expressed milk."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "Managers and HR personnel will receive training on the rights of breastfeeding employees and the implementation of this policy.",
                "We will promote awareness of breastfeeding support resources and accommodations through employee handbooks, training sessions, and company communications."
            ]
        },
        {
            heading: "Monitoring and Compliance",
            paragraphs: [
                "HR will monitor compliance with this policy and address any concerns or complaints promptly and confidentially.",
                "Employees are encouraged to report any issues related to breastfeeding support to the HR department for resolution."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to ensure it remains effective and compliant with changing laws and best practices.",
                "Employees will be informed of any updates or changes to the policy through company-wide communications."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Breastfeeding Support Policy. Acknowledgment records will be maintained by the HR department."
        }
    ]
};

export const EmployeeCellPhoneUsagePolicyTemplate = {
    title: "Employee Cell Phone Usage Policy Template",
    policyBlurb: "This policy outlines our guidelines and expectations regarding the use of cell phones and mobile devices by employees during working hours.",
    preRead: "Use this template to establish clear rules for cell phone usage to minimize distractions, ensure productivity, and protect company information.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Acceptable Use",
                "Unacceptable Use",
                "Security and Confidentiality",
                "Use of Personal Devices",
                "Emergency Calls",
                "Monitoring and Enforcement",
                "Policy Violations",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "In today's digital age, mobile devices play a significant role in our daily lives. This Employee Cell Phone Usage Policy outlines our guidelines for the appropriate use of cell phones and mobile devices by employees during working hours."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to ensure that cell phones and mobile devices are used responsibly and in a manner that does not interfere with work responsibilities, productivity, or the security of company information."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company] who are issued company-owned cell phones or who use personal mobile devices for work purposes.",
                "It covers the rules and expectations regarding cell phone usage, both on company premises and during work-related activities."
            ]
        },
        {
            heading: "Acceptable Use",
            paragraphs: [
                "Employees may use company-owned cell phones or personal mobile devices for work-related communications and tasks.",
                "Personal use of cell phones should be limited to breaks and lunch hours, and should not interfere with work responsibilities."
            ]
        },
        {
            heading: "Unacceptable Use",
            paragraphs: [
                "Using cell phones for personal calls or messaging during work hours, except during authorized breaks.",
                "Accessing or downloading inappropriate, offensive, or unauthorized content on company devices."
            ]
        },
        {
            heading: "Security and Confidentiality",
            paragraphs: [
                "Employees are responsible for safeguarding company-owned devices and preventing unauthorized access.",
                "Confidential company information should not be shared or stored on personal mobile devices without proper authorization."
            ]
        },
        {
            heading: "Use of Personal Devices",
            paragraphs: [
                "Employees using personal mobile devices for work purposes must comply with all aspects of this policy.",
                "Company policies regarding security and acceptable use also apply to personal devices used for work."
            ]
        },
        {
            heading: "Emergency Calls",
            paragraphs: [
                "Employees may use company-owned or personal cell phones to make emergency calls as needed.",
                "Emergency calls should be brief and pertinent to the situation requiring immediate attention."
            ]
        },
        {
            heading: "Monitoring and Enforcement",
            paragraphs: [
                "The company reserves the right to monitor cell phone usage to ensure compliance with this policy.",
                "Violations of this policy may result in disciplinary action, up to and including termination of employment."
            ]
        },
        {
            heading: "Policy Violations",
            paragraphs: [
                "Employees who violate this policy may be subject to consequences outlined in the company's disciplinary procedures.",
                "Repeated violations or misuse of company-owned devices may lead to revocation of cell phone privileges."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in technology and company needs.",
                "Employees will be notified of any updates or changes to the policy through company communications."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Cell Phone Usage Policy. Acknowledgment records will be maintained by the HR department."
        }
    ]
};

export const EmployeeCodeOfEthicsTemplate = {
    title: "Employee Code of Ethics Template",
    policyBlurb: "This policy outlines our commitment to ethical conduct and integrity in all aspects of our operations. It serves as a guide for employees to ensure that their actions reflect the company's values and ethical standards.",
    preRead: "Use this template to develop a comprehensive Employee Code of Ethics that promotes ethical behavior, integrity, and accountability within your organization.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Core Values",
                "Professional Conduct",
                "Conflict of Interest",
                "Confidentiality",
                "Use of Company Resources",
                "Compliance with Laws and Regulations",
                "Reporting Ethical Violations",
                "Non-Retaliation",
                "Training and Awareness",
                "Monitoring and Enforcement",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Our company is dedicated to maintaining the highest standards of ethical conduct and integrity in all our operations. This Employee Code of Ethics outlines the principles and guidelines that govern the behavior of our employees, ensuring that their actions reflect our commitment to ethical practices and the company's core values."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this Employee Code of Ethics is to provide a clear framework for ethical behavior within our organization. It aims to promote integrity, accountability, and respect among all employees, fostering a positive and ethical work environment. This policy serves as a guide to help employees navigate ethical dilemmas and make decisions that align with our company's values and ethical standards."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and affiliates of [Your Company]. It covers all aspects of professional conduct, including interactions with colleagues, clients, vendors, and other stakeholders.",
                "The policy is designed to be comprehensive and applicable to a wide range of situations, ensuring that all employees understand their ethical responsibilities and the importance of maintaining integrity in their actions."
            ]
        },
        {
            heading: "Core Values",
            paragraphs: [
                "Integrity: We are committed to honesty and transparency in all our dealings. We act with integrity, ensuring that our actions are consistent with our values and ethical standards.",
                "Respect: We treat all individuals with dignity and respect, fostering an inclusive and supportive work environment. We value diversity and promote a culture of mutual respect.",
                "Accountability: We take responsibility for our actions and decisions. We are accountable for our behavior and strive to uphold the highest standards of ethical conduct.",
                "Excellence: We are dedicated to achieving excellence in our work. We continuously strive to improve and deliver high-quality results, maintaining professionalism in all our endeavors."
            ]
        },
        {
            heading: "Professional Conduct",
            paragraphs: [
                "Employees are expected to conduct themselves in a professional manner at all times. This includes demonstrating respect, courtesy, and consideration in interactions with colleagues, clients, and other stakeholders.",
                "Employees must adhere to company policies and procedures, ensuring that their actions align with the company's ethical standards and core values."
            ]
        },
        {
            heading: "Conflict of Interest",
            paragraphs: [
                "Employees must avoid situations where their personal interests could conflict with the interests of the company. Any potential conflicts of interest must be disclosed to the HR department or a designated ethics officer.",
                "Employees should not engage in activities or relationships that could impair their ability to make objective decisions on behalf of the company. This includes refraining from accepting gifts, favors, or other benefits that could influence their judgment."
            ]
        },
        {
            heading: "Confidentiality",
            paragraphs: [
                "Employees must protect the confidentiality of sensitive company information. This includes trade secrets, financial data, client information, and any other proprietary information.",
                "Confidential information should only be shared with authorized individuals who have a legitimate need to know. Employees must not disclose confidential information without proper authorization."
            ]
        },
        {
            heading: "Use of Company Resources",
            paragraphs: [
                "Employees are expected to use company resources responsibly and for legitimate business purposes only. This includes company equipment, facilities, and intellectual property.",
                "Misuse of company resources, including theft, fraud, or unauthorized use, will not be tolerated and may result in disciplinary action."
            ]
        },
        {
            heading: "Compliance with Laws and Regulations",
            paragraphs: [
                "Employees must comply with all applicable laws and regulations in the jurisdictions where the company operates. This includes adhering to industry-specific regulations and standards.",
                "Employees are responsible for understanding the legal and regulatory requirements relevant to their roles and ensuring compliance in all their actions and decisions."
            ]
        },
        {
            heading: "Reporting Ethical Violations",
            paragraphs: [
                "Employees are encouraged to report any suspected ethical violations or misconduct to their supervisor, HR department, or a designated ethics officer. Reports will be handled confidentially and investigated promptly.",
                "The company is committed to maintaining an open and supportive environment where employees can raise ethical concerns without fear of retaliation."
            ]
        },
        {
            heading: "Non-Retaliation",
            paragraphs: [
                "The company strictly prohibits retaliation against employees who report ethical violations or participate in investigations. Employees who experience or witness retaliation should report it immediately to the HR department or a designated ethics officer.",
                "Retaliation is a serious violation of this policy and may result in disciplinary action, up to and including termination of employment."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "The company will provide regular training and resources to employees to ensure they understand their ethical responsibilities and the principles outlined in this policy.",
                "Employees are encouraged to seek guidance from their supervisors, HR department, or a designated ethics officer if they have questions or need clarification on ethical matters."
            ]
        },
        {
            heading: "Monitoring and Enforcement",
            paragraphs: [
                "The company will monitor compliance with this policy and take appropriate action to address any violations. This includes conducting regular audits and investigations as needed.",
                "Employees found to be in violation of this policy may be subject to disciplinary action, up to and including termination of employment."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and best practices.",
                "Employees will be informed of any significant changes to the policy through company-wide communications, ensuring transparency and understanding."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with this Employee Code of Ethics. Acknowledgment records will be maintained by the HR department."
        }
    ]
};
export const EmployeeConfidentialityAndDataSecurityTemplate = {
    title: "Employee Confidentiality and Data Security Template",
    policyBlurb: "This policy outlines the importance of maintaining confidentiality and securing data within the organization. It provides guidelines for employees to ensure that sensitive information is protected and handled appropriately.",
    preRead: "Use this template to develop a comprehensive confidentiality and data security policy that promotes the protection of sensitive information and ensures compliance with relevant laws and regulations.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Employee Responsibilities",
                "Confidential Information",
                "Data Security Practices",
                "Use of Technology",
                "Access Control",
                "Data Breach Response",
                "Training and Awareness",
                "Monitoring and Enforcement",
                "Compliance with Laws and Regulations",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we are committed to maintaining the confidentiality and security of our data. This policy outlines the guidelines and procedures that employees must follow to protect sensitive information and ensure data security. Our commitment to data security is fundamental to maintaining trust with our clients, partners, and employees, and to complying with all applicable data protection laws and regulations."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for maintaining confidentiality and securing data within our organization. It aims to protect sensitive information, ensure compliance with legal requirements, and promote a culture of data security. By adhering to this policy, employees will help safeguard the integrity, availability, and confidentiality of our data assets, thereby supporting the company's overall mission and operational effectiveness."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and affiliates of [Your Company]. It covers all types of data, including electronic, paper, and verbal information, as well as all systems and devices used to store, process, or transmit data. This includes but is not limited to computers, mobile devices, email systems, cloud services, and physical records.",
                "The scope of this policy encompasses all data handling activities, whether conducted on company premises, remotely, or at any third-party locations. It is essential that every individual within the organization understands their role and responsibilities in protecting data, as any breach could have significant repercussions for the company and its stakeholders."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Confidential Information: Any non-public information that could cause harm to the company, its clients, or its employees if disclosed. This includes trade secrets, business plans, financial records, client and employee personal data, and proprietary information.",
                "Data Security: The practice of protecting data from unauthorized access, use, disclosure, disruption, modification, or destruction. This involves implementing measures such as encryption, access controls, and secure disposal methods to safeguard data throughout its lifecycle.",
                "Data Breach: An incident where confidential information is accessed or disclosed without authorization. Data breaches can result from various causes, including cyber-attacks, human error, or physical theft, and they necessitate a swift and effective response to mitigate potential damage."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for maintaining the confidentiality and security of all data they handle. This includes adhering to all company policies and procedures related to data security and immediately reporting any suspected breaches or security incidents.",
                "Employees must only access information necessary for their job functions and should not share confidential data with unauthorized individuals. Additionally, employees are expected to participate in regular training sessions on data security practices and stay informed about the latest threats and mitigation strategies."
            ]
        },
        {
            heading: "Confidential Information",
            paragraphs: [
                "Employees must protect all confidential information, including trade secrets, financial data, client information, and personal data. Unauthorized access, use, or disclosure of such information is strictly prohibited and may result in disciplinary action.",
                "Confidential information should only be shared with authorized individuals who have a legitimate need to know. When discussing sensitive information, employees should take care to ensure that conversations are not overheard by unauthorized parties. Physical documents containing confidential information should be stored securely and disposed of properly when no longer needed."
            ]
        },
        {
            heading: "Data Security Practices",
            paragraphs: [
                "Employees must use strong passwords and change them regularly. Passwords should be unique, complex, and not shared with anyone. Multi-factor authentication should be used wherever possible to add an additional layer of security.",
                "Sensitive data should be encrypted, both in transit and at rest. Encryption helps to protect data from unauthorized access and ensures that even if data is intercepted, it cannot be read without the correct decryption key.",
                "Physical security measures, such as locking doors and filing cabinets, should be used to protect paper records. Additionally, employees should ensure that screens and devices are locked when not in use and that sensitive information is not left unattended."
            ]
        },
        {
            heading: "Use of Technology",
            paragraphs: [
                "Employees must use company-provided technology responsibly and for business purposes only. Personal use of company technology should be minimal and should not interfere with work responsibilities or productivity.",
                "Personal devices used for work purposes must comply with the company's security standards. This includes installing approved security software, keeping systems up to date, and following guidelines for secure configuration. Employees should avoid using public Wi-Fi for accessing sensitive information and should use a virtual private network (VPN) when necessary."
            ]
        },
        {
            heading: "Access Control",
            paragraphs: [
                "Access to confidential information should be restricted based on the principle of least privilege, meaning that employees should only have access to the information necessary to perform their job functions. Access permissions should be reviewed regularly and adjusted as needed.",
                "Employees must not share their login credentials or allow others to use their accounts. Shared accounts should be avoided, and individual accountability should be maintained through unique user credentials. Any suspected compromise of login credentials should be reported immediately and passwords should be changed promptly."
            ]
        },
        {
            heading: "Data Breach Response",
            paragraphs: [
                "Employees must report any suspected data breaches immediately to the IT department or designated data security officer. Prompt reporting allows the company to take swift action to contain the breach, assess its impact, and mitigate any potential damage.",
                "The company will investigate all reports of data breaches and take appropriate action to mitigate any harm. This may include notifying affected individuals, working with law enforcement, and implementing additional security measures to prevent future incidents."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "The company will provide regular training on data security practices and policies. Training sessions will cover topics such as recognizing phishing attempts, using secure passwords, and reporting security incidents.",
                "Employees are encouraged to stay informed about the latest data security threats and best practices. This can include reading industry publications, attending webinars, and participating in ongoing education opportunities."
            ]
        },
        {
            heading: "Monitoring and Enforcement",
            paragraphs: [
                "The company will monitor compliance with this policy and take appropriate action to address any violations. Monitoring may include reviewing access logs, conducting security audits, and investigating reported incidents.",
                "Employees found to be in violation of this policy may be subject to disciplinary action, up to and including termination. The severity of the disciplinary action will depend on the nature and impact of the violation."
            ]
        },
        {
            heading: "Compliance with Laws and Regulations",
            paragraphs: [
                "Employees must comply with all applicable data protection laws and regulations. This includes but is not limited to the General Data Protection Regulation (GDPR), the Health Insurance Portability and Accountability Act (HIPAA), and the California Consumer Privacy Act (CCPA).",
                "The company will regularly review and update its data security practices to ensure compliance with legal requirements. Employees will be informed of any significant changes to the policy and may be required to participate in additional training sessions."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in technology, business practices, and legal requirements. The review process will include input from various stakeholders, including IT, HR, and legal departments.",
                "Employees will be informed of any significant changes to the policy. Updated policies will be distributed to all employees, and acknowledgment of receipt and understanding will be required."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Confidentiality and Data Security Policy. Acknowledgment of this policy is a condition of employment, and failure to adhere to the policy may result in disciplinary action."
        }
    ]
};

export const EmployeeConstructiveDismissalPreventionTemplate = {
    title: "Employee Constructive Dismissal Prevention Template",
    policyBlurb: "This policy provides guidelines to prevent constructive dismissal by ensuring fair treatment, clear communication, and supportive work conditions for all employees.",
    preRead: "Use this template to develop a policy that safeguards against constructive dismissal by promoting a positive work environment and addressing employee concerns effectively.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Employee Rights and Responsibilities",
                "Management Responsibilities",
                "Communication and Feedback",
                "Workplace Conditions",
                "Handling Complaints and Grievances",
                "Performance Management",
                "Training and Development",
                "Monitoring and Enforcement",
                "Compliance with Laws and Regulations",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we are dedicated to providing a fair and supportive work environment. This policy outlines the guidelines and procedures that aim to prevent constructive dismissal by ensuring that all employees are treated with respect and their concerns are addressed promptly and effectively. Constructive dismissal occurs when an employee feels forced to resign due to the employer's behavior, creating a hostile or untenable work environment. Preventing such situations is crucial for maintaining a positive workplace culture and retaining valuable talent."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for preventing constructive dismissal within our organization. By fostering a positive work environment and ensuring that employees' concerns are heard and addressed, we aim to prevent situations that may lead to constructive dismissal. This policy promotes fair treatment, effective communication, and supportive workplace conditions, thereby enhancing employee satisfaction and retention."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, managers, and supervisors at [Your Company]. It covers all aspects of employment, including recruitment, hiring, training, performance management, and termination. The principles and guidelines outlined in this policy are applicable to interactions and relationships within the workplace, whether between employees or between employees and management.",
                "The policy extends to all work-related activities and settings, including remote work environments and off-site work-related events. It is essential that all employees understand their roles and responsibilities in preventing constructive dismissal and promoting a respectful and supportive workplace."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Constructive Dismissal: A situation where an employee feels compelled to resign due to the employer's actions or behavior, which create a hostile, intolerable, or significantly altered work environment.",
                "Hostile Work Environment: An environment where the conduct of supervisors or colleagues creates an atmosphere of intimidation, offense, or humiliation, affecting an employee's ability to perform their job effectively.",
                "Fair Treatment: Ensuring that all employees are treated with respect, dignity, and equality, without discrimination or favoritism."
            ]
        },
        {
            heading: "Employee Rights and Responsibilities",
            paragraphs: [
                "Employees have the right to a respectful, supportive, and safe work environment. They should be treated fairly and have their concerns heard and addressed promptly.",
                "Employees are responsible for communicating their concerns and grievances in a constructive manner. They should follow the company's procedures for reporting issues and cooperate in resolving any disputes."
            ]
        },
        {
            heading: "Management Responsibilities",
            paragraphs: [
                "Managers and supervisors are responsible for fostering a positive and supportive work environment. They should ensure fair treatment of all employees and address any concerns or grievances promptly and effectively.",
                "Management must be proactive in identifying and addressing potential issues that could lead to constructive dismissal. This includes providing clear communication, support, and resources to employees."
            ]
        },
        {
            heading: "Communication and Feedback",
            paragraphs: [
                "Effective communication is essential for preventing misunderstandings and resolving issues. Employees should be encouraged to provide feedback and express their concerns openly.",
                "Regular check-ins and performance reviews should be conducted to ensure that employees feel heard and supported. Management should provide constructive feedback and address any performance issues or concerns."
            ]
        },
        {
            heading: "Workplace Conditions",
            paragraphs: [
                "The company is committed to maintaining a safe and healthy work environment. This includes providing appropriate resources, equipment, and support to employees.",
                "Any changes to workplace conditions, such as job roles or responsibilities, should be communicated clearly and implemented fairly. Employees should be given adequate notice and support to adapt to any changes."
            ]
        },
        {
            heading: "Handling Complaints and Grievances",
            paragraphs: [
                "The company has a clear procedure for handling complaints and grievances. Employees should be aware of this procedure and feel comfortable using it without fear of retaliation.",
                "All complaints and grievances will be investigated promptly and thoroughly. The company is committed to resolving issues fairly and transparently."
            ]
        },
        {
            heading: "Performance Management",
            paragraphs: [
                "Performance management should be conducted in a fair and constructive manner. Employees should receive regular feedback and support to improve their performance.",
                "Any disciplinary actions should be based on clear, documented policies and communicated to the employee. Employees should have the opportunity to respond to any performance concerns."
            ]
        },
        {
            heading: "Training and Development",
            paragraphs: [
                "The company is committed to providing ongoing training and development opportunities for employees. This includes training on company policies, job-specific skills, and professional development.",
                "Employees are encouraged to participate in training programs and seek opportunities for growth and development within the company."
            ]
        },
        {
            heading: "Monitoring and Enforcement",
            paragraphs: [
                "The company will monitor compliance with this policy and take appropriate action to address any violations. This includes regular reviews of workplace conditions and employee feedback.",
                "Employees found to be in violation of this policy may be subject to disciplinary action, up to and including termination. The severity of the disciplinary action will depend on the nature and impact of the violation."
            ]
        },
        {
            heading: "Compliance with Laws and Regulations",
            paragraphs: [
                "Employees must comply with all applicable employment laws and regulations. This includes but is not limited to anti-discrimination laws, health and safety regulations, and labor standards.",
                "The company will regularly review and update its policies to ensure compliance with legal requirements. Employees will be informed of any significant changes to the policy."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in employment laws, best practices, and business needs. The review process will include input from various stakeholders, including HR, legal, and employee representatives.",
                "Employees will be informed of any significant changes to the policy. Updated policies will be distributed to all employees, and acknowledgment of receipt and understanding will be required."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Constructive Dismissal Prevention Policy. Acknowledgment of this policy is a condition of employment, and failure to adhere to the policy may result in disciplinary action."
        }
    ]
};

export const EmployeeCOVID19ResponseAndSafetyTemplate = {
    title: "Employee COVID-19 Response and Safety Template",
    policyBlurb: "This policy outlines the measures and guidelines [Your Company] has implemented to ensure the safety and well-being of employees during the COVID-19 pandemic.",
    preRead: "Use this template to develop a comprehensive COVID-19 response and safety policy that addresses health protocols, remote work arrangements, and support for employees during the pandemic.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Health and Safety Protocols",
                "Remote Work Arrangements",
                "On-Site Work Guidelines",
                "Employee Health Screening",
                "Response to COVID-19 Cases",
                "Mental Health and Well-being",
                "Communication and Updates",
                "Employee Responsibilities",
                "Management Responsibilities",
                "Training and Education",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "The COVID-19 pandemic has presented unprecedented challenges for businesses and their employees. At [Your Company], the health and safety of our employees are our top priority. This policy outlines the measures and guidelines we have implemented to protect our workforce and ensure a safe working environment during the COVID-19 pandemic. Our response strategy is based on recommendations from health authorities and aims to minimize the risk of transmission while maintaining business continuity."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide clear guidelines on the measures [Your Company] is taking to respond to the COVID-19 pandemic. This includes health and safety protocols, remote work arrangements, and support systems for employees. By adhering to this policy, we aim to protect the health and well-being of our employees, customers, and the wider community."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and visitors to [Your Company]. It covers all work locations, including offices, remote work sites, and any other locations where company business is conducted.",
                "The policy is applicable to all aspects of employment, including but not limited to, daily work routines, interactions with colleagues and clients, and participation in work-related events and activities."
            ]
        },
        {
            heading: "Health and Safety Protocols",
            paragraphs: [
                "Employees must adhere to all health and safety protocols, including wearing masks, maintaining physical distancing, and practicing good hand hygiene. Hand sanitizers and personal protective equipment (PPE) will be provided at all work sites.",
                "Regular cleaning and disinfection of workspaces will be conducted to ensure a safe and sanitary environment. Employees are encouraged to clean their workstations and shared equipment frequently."
            ]
        },
        {
            heading: "Remote Work Arrangements",
            paragraphs: [
                "To minimize the risk of COVID-19 transmission, [Your Company] supports remote work arrangements where feasible. Employees who can perform their duties remotely are encouraged to work from home.",
                "Remote work guidelines include maintaining regular communication with supervisors, adhering to work schedules, and ensuring data security. Employees should have the necessary tools and resources to work effectively from home."
            ]
        },
        {
            heading: "On-Site Work Guidelines",
            paragraphs: [
                "For employees who need to work on-site, staggered work schedules and reduced occupancy limits will be implemented to maintain physical distancing. Meeting rooms and common areas will have occupancy limits to prevent crowding.",
                "Employees must follow entry and exit protocols, including health screenings and temperature checks. Any employee showing symptoms of COVID-19 should stay home and seek medical advice."
            ]
        },
        {
            heading: "Employee Health Screening",
            paragraphs: [
                "Daily health screenings will be conducted for employees working on-site. This includes temperature checks and symptom questionnaires. Employees who exhibit symptoms or have been in contact with a confirmed case of COVID-19 must notify their supervisor and follow self-isolation protocols.",
                "Confidentiality will be maintained for all health-related information, and data will be used solely for the purpose of ensuring workplace safety."
            ]
        },
        {
            heading: "Response to COVID-19 Cases",
            paragraphs: [
                "In the event of a confirmed COVID-19 case within the workplace, [Your Company] will follow public health guidelines for contact tracing, communication, and cleaning. Affected areas will be thoroughly disinfected, and individuals who had close contact with the infected person will be notified and advised to self-isolate.",
                "Employees who test positive for COVID-19 must follow medical advice and not return to work until they have fully recovered and received clearance from a healthcare provider."
            ]
        },
        {
            heading: "Mental Health and Well-being",
            paragraphs: [
                "Recognizing the impact of the pandemic on mental health, [Your Company] provides resources and support for employees. This includes access to counseling services, mental health webinars, and stress management programs.",
                "Employees are encouraged to take regular breaks, maintain a healthy work-life balance, and seek support if they experience anxiety, stress, or other mental health challenges."
            ]
        },
        {
            heading: "Communication and Updates",
            paragraphs: [
                "Regular communication from management will keep employees informed about COVID-19 developments, policy updates, and safety measures. Updates will be shared through emails, company intranet, and virtual meetings.",
                "Employees are encouraged to stay informed about COVID-19 through reputable sources such as health authorities and follow company communications for the latest information."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for adhering to all aspects of this policy. This includes following health and safety protocols, reporting any symptoms or exposure to COVID-19, and participating in health screenings.",
                "Employees should also respect the concerns and safety of colleagues by maintaining physical distancing and following all preventive measures."
            ]
        },
        {
            heading: "Management Responsibilities",
            paragraphs: [
                "Management is responsible for implementing and enforcing this policy. This includes providing necessary resources, conducting regular reviews of safety measures, and addressing any concerns raised by employees.",
                "Managers should lead by example, adhere to all protocols, and support employees in adapting to new working conditions."
            ]
        },
        {
            heading: "Training and Education",
            paragraphs: [
                "Employees will receive training on COVID-19 safety measures, including proper use of PPE, hygiene practices, and protocols for remote work. Training sessions will be conducted virtually to accommodate remote workers.",
                "Ongoing education will be provided to keep employees informed about any changes in guidelines or procedures."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed regularly and updated as necessary to reflect changes in public health guidelines and company practices. Feedback from employees will be considered in the review process.",
                "Any significant changes to the policy will be communicated to all employees, and updated documents will be distributed for acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee COVID-19 Response and Safety Policy. Compliance with this policy is mandatory to ensure the safety and well-being of all employees and the continued operation of [Your Company]."
        }
    ]
};

export const EmployeeCybersecurityAndITUsageTemplate = {
    title: "Employee Cybersecurity and IT Usage Template",
    policyBlurb: "This policy outlines the guidelines for cybersecurity practices and IT resource usage to protect [Your Company]'s data and systems.",
    preRead: "Use this template to develop a comprehensive policy that ensures the secure and responsible use of IT resources by employees.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "General IT Usage",
                "Password Management",
                "Data Protection",
                "Internet and Email Use",
                "Social Media Guidelines",
                "Access Control",
                "Software and Hardware Management",
                "Incident Reporting",
                "Training and Awareness",
                "Monitoring and Compliance",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "In today's digital age, cybersecurity is paramount for protecting company data and ensuring business continuity. This policy outlines the guidelines and expectations for employees regarding cybersecurity practices and IT resource usage at [Your Company]. Adherence to these guidelines is critical to safeguarding our information assets and maintaining the trust of our clients and partners."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for the secure and responsible use of [Your Company]'s IT resources. By following these guidelines, we aim to prevent data breaches, protect sensitive information, and ensure the smooth operation of our IT systems."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and third-party partners who use [Your Company]'s IT resources. It covers all devices, networks, software, and data owned or managed by the company.",
                "The policy encompasses all aspects of IT usage, including email communication, internet access, data storage, and social media interactions."
            ]
        },
        {
            heading: "General IT Usage",
            paragraphs: [
                "Employees must use IT resources for business purposes only. Limited personal use is permitted, provided it does not interfere with work responsibilities or violate company policies.",
                "All users are responsible for the security and proper use of the IT resources assigned to them. This includes safeguarding devices, securing login credentials, and reporting any suspicious activities."
            ]
        },
        {
            heading: "Password Management",
            paragraphs: [
                "Employees must create strong passwords that meet the company's complexity requirements. Passwords should be unique, not reused across multiple accounts, and changed regularly.",
                "Sharing passwords or login credentials is strictly prohibited. Employees must keep their passwords confidential and report any suspected compromise immediately."
            ]
        },
        {
            heading: "Data Protection",
            paragraphs: [
                "Sensitive company data must be protected at all times. This includes using encryption for data transmission and storage, as well as following access control measures.",
                "Employees should only access data necessary for their job functions and must not share sensitive information without proper authorization."
            ]
        },
        {
            heading: "Internet and Email Use",
            paragraphs: [
                "Internet access is provided for business purposes. Employees must avoid visiting malicious or inappropriate websites and should be cautious when downloading files or clicking on links.",
                "Email communication should be professional and secure. Employees must not send unsolicited emails (spam) or engage in phishing activities."
            ]
        },
        {
            heading: "Social Media Guidelines",
            paragraphs: [
                "Employees must adhere to the company's social media policy when using social platforms. Personal use of social media during work hours should be minimal and not interfere with job duties.",
                "Confidential company information must never be shared on social media without proper authorization. Employees should exercise caution and discretion when posting online."
            ]
        },
        {
            heading: "Access Control",
            paragraphs: [
                "Access to company systems and data is granted based on job responsibilities. Employees must not attempt to bypass security controls or gain unauthorized access to systems or data.",
                "Any changes in job roles or responsibilities must be reported to the IT department to adjust access permissions accordingly."
            ]
        },
        {
            heading: "Software and Hardware Management",
            paragraphs: [
                "Employees must use only authorized software and hardware for work purposes. Installing unauthorized applications or connecting personal devices to the company network is prohibited.",
                "All software and hardware must be regularly updated to ensure they are protected against security vulnerabilities."
            ]
        },
        {
            heading: "Incident Reporting",
            paragraphs: [
                "Any cybersecurity incidents, such as data breaches, malware infections, or unauthorized access, must be reported immediately to the IT department. Timely reporting helps mitigate potential damage and facilitates a prompt response.",
                "Employees should be familiar with the company's incident response plan and know the appropriate steps to take in the event of a security incident."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "Regular cybersecurity training will be provided to employees to keep them informed about the latest threats and best practices. Participation in training sessions is mandatory.",
                "Employees are encouraged to stay updated on cybersecurity trends and to apply their knowledge to protect company data and systems."
            ]
        },
        {
            heading: "Monitoring and Compliance",
            paragraphs: [
                "[Your Company] reserves the right to monitor IT resource usage to ensure compliance with this policy. Monitoring activities include reviewing email communications, internet usage, and access logs.",
                "Violations of this policy may result in disciplinary action, up to and including termination of employment. Legal action may also be taken in cases of serious breaches or illegal activities."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in technology and cybersecurity best practices.",
                "Employees will be notified of any significant changes to the policy, and updated documents will be made available for review and acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Cybersecurity and IT Usage Policy. Compliance with this policy is crucial to ensuring the security and integrity of [Your Company]'s IT resources."
        }
    ]
};

export const EmployeeDrugAndAlcoholTestingTemplate = {
    title: "Employee Drug and Alcohol Testing Template",
    policyBlurb: "This policy outlines the procedures and guidelines for drug and alcohol testing to ensure a safe and productive workplace.",
    preRead: "Use this template to develop a policy that addresses the testing, prevention, and management of drug and alcohol use in the workplace.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Prohibited Conduct",
                "Testing Procedures",
                "Types of Testing",
                "Confidentiality",
                "Employee Rights and Responsibilities",
                "Consequences of Violations",
                "Support and Rehabilitation",
                "Training and Awareness",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we are committed to maintaining a safe and productive workplace. This policy outlines our approach to drug and alcohol testing, including the procedures for testing and the actions to be taken in the event of violations. Ensuring that our employees are not impaired by drugs or alcohol while at work is essential to the safety and efficiency of our operations."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for the prevention, testing, and management of drug and alcohol use in the workplace. By adhering to these guidelines, we aim to protect the health and safety of our employees and maintain a work environment free from the adverse effects of substance abuse."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, and temporary staff at [Your Company]. It covers all work locations and company-sponsored events.",
                "The policy includes guidelines for pre-employment testing, random testing, reasonable suspicion testing, and post-incident testing."
            ]
        },
        {
            heading: "Prohibited Conduct",
            paragraphs: [
                "Employees are prohibited from using, possessing, distributing, or being under the influence of drugs or alcohol while on company premises, during work hours, or at company-sponsored events.",
                "The use of prescription medications that may impair an employee's ability to perform their duties safely must be reported to the HR department. Employees should provide documentation from their healthcare provider."
            ]
        },
        {
            heading: "Testing Procedures",
            paragraphs: [
                "Drug and alcohol testing will be conducted in a manner that ensures the accuracy and reliability of the results. All testing will be carried out by certified professionals and accredited laboratories.",
                "Employees selected for testing will be notified and required to provide samples (e.g., urine, breath, or blood) in accordance with the company's testing procedures."
            ]
        },
        {
            heading: "Types of Testing",
            paragraphs: [
                "Pre-Employment Testing: All prospective employees must undergo drug and alcohol testing as part of the hiring process. Employment offers are contingent upon passing the test.",
                "Random Testing: Employees may be selected for random drug and alcohol testing. Selection will be made using a neutral, computer-generated process.",
                "Reasonable Suspicion Testing: Testing may be conducted if there is a reasonable suspicion that an employee is under the influence of drugs or alcohol. Reasonable suspicion must be based on observable signs or behaviors.",
                "Post-Incident Testing: Employees involved in workplace incidents or accidents may be required to undergo testing to determine if drugs or alcohol were a contributing factor."
            ]
        },
        {
            heading: "Confidentiality",
            paragraphs: [
                "All testing results and related information will be treated as confidential. Access to this information will be limited to authorized personnel only.",
                "Employees will be informed of the results of their tests and provided with an opportunity to discuss any positive results with the HR department."
            ]
        },
        {
            heading: "Employee Rights and Responsibilities",
            paragraphs: [
                "Employees have the right to a safe and supportive work environment. They also have the right to confidentiality and fair treatment throughout the testing process.",
                "Employees are responsible for complying with this policy, including submitting to testing when required and providing accurate information regarding their use of prescription medications."
            ]
        },
        {
            heading: "Consequences of Violations",
            paragraphs: [
                "Violations of this policy, including positive test results or refusal to submit to testing, may result in disciplinary action up to and including termination of employment.",
                "Employees found to be under the influence of drugs or alcohol while at work will be removed from their duties immediately and escorted off the premises."
            ]
        },
        {
            heading: "Support and Rehabilitation",
            paragraphs: [
                "Employees struggling with substance abuse are encouraged to seek help. [Your Company] offers support through our Employee Assistance Program (EAP) and provides referrals to rehabilitation programs.",
                "Participation in a rehabilitation program may be required as a condition of continued employment for employees who test positive for drugs or alcohol."
            ]
        },
        {
            heading: "Training and Awareness",
            paragraphs: [
                "Regular training will be provided to employees on the dangers of drug and alcohol use, the company's testing procedures, and the support available for those struggling with substance abuse.",
                "Supervisors will receive additional training on identifying signs of impairment and handling reasonable suspicion situations."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and best practices.",
                "Employees will be informed of any significant changes to the policy, and updated documents will be made available for review and acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Drug and Alcohol Testing Policy. Compliance with this policy is essential to maintaining a safe and productive work environment at [Your Company]."
        }
    ]
};

export const EmployeeFlexibleWorkingHoursTemplate = {
    title: "Employee Flexible Working Hours Template",
    policyBlurb: "This policy outlines the guidelines for flexible working hours to accommodate the diverse needs of employees while ensuring business continuity and productivity.",
    preRead: "Use this template to develop a comprehensive policy that promotes work-life balance and flexibility for employees, aligning with the operational needs of [Your Company].",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Eligibility",
                "Flexible Working Hours Options",
                "Application Process",
                "Approval Process",
                "Employee Responsibilities",
                "Manager Responsibilities",
                "Monitoring and Evaluation",
                "Impact on Compensation and Benefits",
                "Technology and Equipment",
                "Confidentiality and Data Security",
                "Compliance with Labor Laws",
                "Termination of Flexible Working Arrangement",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize the importance of providing flexible working hours to meet the diverse needs of our employees. This policy aims to offer various flexible working arrangements to promote a healthy work-life balance while ensuring that business operations remain efficient and productive. By implementing this policy, we seek to create a supportive work environment that enhances employee satisfaction and retention."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for flexible working hours at [Your Company]. This policy is designed to provide employees with options to adjust their work schedules to better accommodate their personal and family needs, while still fulfilling their job responsibilities. It outlines the different types of flexible working arrangements available, the application and approval processes, and the responsibilities of both employees and managers in ensuring the success of these arrangements."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], regardless of their position or level within the organization. Contractors, temporary staff, and interns may also be eligible for flexible working arrangements, subject to approval by their respective managers and the HR department.",
                "The policy covers various types of flexible working hours, including but not limited to, flexible start and end times, compressed workweeks, job sharing, and telecommuting."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Flexible Working Hours: Work arrangements that allow employees to vary their start and end times, work from different locations, or alter the number of hours worked each day or week.",
                "Compressed Workweek: A work schedule that allows employees to complete their required weekly hours in fewer than the standard five days, typically by working longer hours on the days they work.",
                "Job Sharing: An arrangement where two employees share the responsibilities of a single full-time position, each working part-time hours.",
                "Telecommuting: Working remotely from a location other than the company’s primary office, often from home, using digital communication tools to stay connected with colleagues and managers."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "All full-time and part-time employees who have completed their probationary period are eligible to apply for flexible working hours. Eligibility for contractors, temporary staff, and interns will be considered on a case-by-case basis.",
                "Eligibility for specific types of flexible working arrangements may vary depending on the nature of the employee's role, job responsibilities, and the operational needs of the department."
            ]
        },
        {
            heading: "Flexible Working Hours Options",
            paragraphs: [
                "Flexible Start and End Times: Employees may choose to begin and end their workday at different times, provided they work the required number of hours and are available during core business hours as defined by their department.",
                "Compressed Workweek: Employees may work longer hours over fewer days to complete their weekly hours in less than the standard five days. For example, an employee could work four 10-hour days instead of five 8-hour days.",
                "Job Sharing: Two employees can share the duties and responsibilities of a single full-time position, each working part-time hours. This arrangement requires close coordination between the job-sharing partners and their manager.",
                "Telecommuting: Employees can work remotely from a location other than the company office, such as their home. Telecommuting arrangements can be full-time, part-time, or on an as-needed basis."
            ]
        },
        {
            heading: "Application Process",
            paragraphs: [
                "Employees interested in flexible working hours must submit a formal request to their immediate supervisor or manager. The request should outline the desired flexible working arrangement, the proposed schedule, and the reasons for the request.",
                "The request must also include a plan for how the employee will manage their job responsibilities, communicate with colleagues and supervisors, and ensure that their work does not negatively impact the team or department."
            ]
        },
        {
            heading: "Approval Process",
            paragraphs: [
                "Managers will review flexible working hour requests on a case-by-case basis, considering factors such as the employee's job performance, the nature of their role, the needs of the team, and the overall impact on business operations.",
                "Managers may consult with the HR department to evaluate the feasibility of the proposed arrangement and to ensure consistency with company policies and labor laws.",
                "Employees will be notified of the decision in writing. If the request is approved, the manager and employee will work together to finalize the details of the flexible working arrangement, including any necessary adjustments to job duties or communication protocols."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees with flexible working hours are expected to maintain the same level of productivity, quality, and professionalism as those working standard hours.",
                "Employees must adhere to the agreed-upon schedule and communicate any changes or issues promptly to their manager.",
                "Employees working remotely must ensure they have a suitable work environment and reliable internet access to perform their job duties effectively."
            ]
        },
        {
            heading: "Manager Responsibilities",
            paragraphs: [
                "Managers are responsible for evaluating and approving flexible working hour requests based on the criteria outlined in this policy.",
                "Managers must monitor the performance and productivity of employees with flexible working hours to ensure that business operations are not adversely affected.",
                "Managers should provide support and guidance to employees with flexible working arrangements, helping them address any challenges and maintain effective communication with the team."
            ]
        },
        {
            heading: "Monitoring and Evaluation",
            paragraphs: [
                "The HR department will periodically review the implementation of flexible working arrangements to ensure they are meeting the intended goals and not negatively impacting business operations.",
                "Feedback from employees and managers will be collected to assess the effectiveness of the policy and identify any areas for improvement."
            ]
        },
        {
            heading: "Impact on Compensation and Benefits",
            paragraphs: [
                "Flexible working arrangements should not negatively impact an employee's compensation or benefits, provided they continue to fulfill their job responsibilities and meet performance expectations.",
                "Employees with approved flexible working arrangements will retain their eligibility for company benefits, including health insurance, retirement plans, and paid time off."
            ]
        },
        {
            heading: "Technology and Equipment",
            paragraphs: [
                "Employees working remotely or with flexible schedules may be provided with the necessary technology and equipment to perform their job duties, such as laptops, software, and secure access to company networks.",
                "Employees are responsible for the proper use and maintenance of company-provided equipment and must report any issues or malfunctions to the IT department promptly."
            ]
        },
        {
            heading: "Confidentiality and Data Security",
            paragraphs: [
                "Employees must adhere to all company policies and procedures regarding confidentiality and data security, regardless of their work location or schedule.",
                "Employees working remotely must ensure that sensitive company information is protected from unauthorized access and must use secure methods for data transmission and storage."
            ]
        },
        {
            heading: "Compliance with Labor Laws",
            paragraphs: [
                "All flexible working arrangements must comply with applicable labor laws and regulations, including those related to working hours, overtime, and employee rights.",
                "The HR department will ensure that the implementation of flexible working arrangements adheres to legal requirements and best practices."
            ]
        },
        {
            heading: "Termination of Flexible Working Arrangement",
            paragraphs: [
                "Flexible working arrangements may be terminated or adjusted by the company if they are no longer feasible or if the employee's performance or business needs change.",
                "Employees will be given reasonable notice of any changes to their flexible working arrangements and will have the opportunity to discuss alternative options with their manager and the HR department."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in business needs, employee feedback, and best practices.",
                "Employees will be informed of any significant changes to the policy, and updated documents will be made available for review and acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Flexible Working Hours Policy. Adherence to this policy is essential for maintaining a balanced and productive work environment at [Your Company]."
        }
    ]
};

export const EmployeeInternetUsageAndMonitoringTemplate = {
    title: "Employee Internet Usage and Monitoring Template",
    policyBlurb: "This policy outlines the guidelines for internet usage and monitoring within the workplace to ensure productivity, security, and compliance with company standards.",
    preRead: "Use this template to develop a comprehensive policy that manages internet usage, monitors online activities, and protects the integrity of [Your Company]'s IT resources.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "General Use",
                "Acceptable Internet Usage",
                "Unacceptable Internet Usage",
                "Monitoring of Internet Usage",
                "Employee Responsibilities",
                "Manager Responsibilities",
                "Privacy and Confidentiality",
                "Consequences of Policy Violations",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "The use of the internet is an essential part of business operations at [Your Company]. This policy outlines the acceptable use of internet resources, the monitoring of online activities, and the responsibilities of employees to ensure a productive and secure work environment. By implementing this policy, we aim to protect our IT infrastructure, comply with legal requirements, and promote efficient use of internet resources."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide clear guidelines on the acceptable and unacceptable use of internet resources at [Your Company]. This policy aims to prevent misuse of the internet, protect sensitive company information, and ensure that all internet usage aligns with the company's business objectives and legal obligations."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, contractors, temporary staff, and any other individuals who have access to [Your Company]'s internet resources.",
                "It covers all internet usage, including browsing, email, social media, and other online activities conducted using company devices or networks."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Internet Usage: The use of the internet for accessing websites, sending and receiving emails, using social media, and other online activities.",
                "Monitoring: The process of observing and recording internet activities to ensure compliance with company policies and protect IT resources."
            ]
        },
        {
            heading: "General Use",
            paragraphs: [
                "Employees are expected to use the internet primarily for business purposes. Limited personal use is permitted, provided it does not interfere with work responsibilities or productivity.",
                "Employees should be aware that their internet activities may be monitored to ensure compliance with this policy."
            ]
        },
        {
            heading: "Acceptable Internet Usage",
            paragraphs: [
                "Using the internet for work-related research and communication.",
                "Accessing online tools and resources necessary for job performance.",
                "Communicating with colleagues, clients, and stakeholders via email and other approved communication platforms.",
                "Participating in online training and professional development activities."
            ]
        },
        {
            heading: "Unacceptable Internet Usage",
            paragraphs: [
                "Accessing, downloading, or distributing inappropriate or offensive content.",
                "Engaging in illegal activities or using the internet for any unlawful purposes.",
                "Using the internet for personal gain or conducting non-business-related activities during work hours.",
                "Bypassing security controls or attempting to gain unauthorized access to systems and networks.",
                "Engaging in excessive personal use of the internet that interferes with work responsibilities."
            ]
        },
        {
            heading: "Monitoring of Internet Usage",
            paragraphs: [
                "To ensure compliance with this policy, [Your Company] reserves the right to monitor internet usage. This includes, but is not limited to, tracking websites visited, monitoring email communications, and logging online activities.",
                "Monitoring will be conducted in a manner that respects employee privacy while ensuring the protection of company resources and compliance with legal requirements."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for using the internet in accordance with this policy and for reporting any misuse or suspicious activities to their manager or the IT department.",
                "Employees must ensure that their use of the internet does not compromise the security of company systems or the confidentiality of sensitive information."
            ]
        },
        {
            heading: "Manager Responsibilities",
            paragraphs: [
                "Managers are responsible for ensuring that their team members are aware of and adhere to this policy.",
                "Managers should address any internet usage issues promptly and report policy violations to the HR and IT departments for further investigation."
            ]
        },
        {
            heading: "Privacy and Confidentiality",
            paragraphs: [
                "While [Your Company] respects the privacy of its employees, it is necessary to monitor internet usage to protect the company's interests and ensure compliance with this policy.",
                "Employees should have no expectation of privacy when using company internet resources, as all activities may be subject to monitoring and review."
            ]
        },
        {
            heading: "Consequences of Policy Violations",
            paragraphs: [
                "Violations of this policy may result in disciplinary action, up to and including termination of employment. Legal action may also be taken against individuals who engage in illegal activities or cause significant harm to the company's IT resources.",
                "Disciplinary actions will be determined based on the severity of the violation and the impact on the company."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in technology, legal requirements, and business practices.",
                "Employees will be informed of any significant changes to the policy, and updated documents will be made available for review and acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Internet Usage and Monitoring Policy. Adherence to this policy is essential for maintaining a secure and productive work environment at [Your Company]."
        }
    ]
};

export const EmployeeJuryDutyLeaveTemplate = {
    title: "Employee Jury Duty Leave Template",
    policyBlurb: "This policy outlines the provisions for employees who are summoned for jury duty, ensuring their civic responsibilities do not negatively impact their employment status.",
    preRead: "Use this template to develop a comprehensive policy that supports employees in fulfilling their jury duty obligations while maintaining their rights and responsibilities within the organization.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Employee Rights and Responsibilities",
                "Company Responsibilities",
                "Notification Procedure",
                "Leave Entitlement and Pay",
                "Return to Work",
                "Employee Protection",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Jury duty is a civic obligation that our company fully supports. This policy outlines the procedures and provisions for employees who are summoned for jury duty, ensuring they can fulfill their responsibilities without fear of losing their employment or income."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide clear guidelines on how jury duty leave is managed within [Your Company], ensuring employees can perform their civic duties while maintaining their job security and financial stability."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all full-time and part-time employees of [Your Company] who are summoned to serve on a jury.",
                "It covers all aspects of jury duty leave, including notification, pay, job protection, and return to work."
            ]
        },
        {
            heading: "Employee Rights and Responsibilities",
            paragraphs: [
                "Employees are entitled to take leave for jury duty without fear of losing their job or facing retaliation.",
                "Employees must notify their supervisor or HR department as soon as they receive a jury summons, providing a copy of the summons for verification."
            ]
        },
        {
            heading: "Company Responsibilities",
            paragraphs: [
                "[Your Company] is committed to supporting employees in fulfilling their jury duty obligations. The company will provide necessary time off and ensure job protection for employees serving on a jury.",
                "Supervisors and managers are responsible for accommodating the employee’s absence and ensuring that their duties are covered during their leave."
            ]
        },
        {
            heading: "Notification Procedure",
            paragraphs: [
                "Employees must inform their supervisor or HR department immediately upon receiving a jury summons, ideally within one business day.",
                "A copy of the jury summons must be provided to the HR department to process the leave request and make necessary arrangements."
            ]
        },
        {
            heading: "Leave Entitlement and Pay",
            paragraphs: [
                "[Your Company] will provide paid leave for employees serving on a jury, up to a specified number of days per year. The exact number of paid days will be determined by company policy and any applicable state or federal laws.",
                "If the jury duty extends beyond the paid leave period, employees may use their accrued vacation or personal leave, or opt for unpaid leave for the remaining duration."
            ]
        },
        {
            heading: "Return to Work",
            paragraphs: [
                "Employees are expected to return to work on the next scheduled workday following the conclusion of their jury duty.",
                "If the employee is released from jury duty early on any given day, they are required to inform their supervisor and return to work if feasible."
            ]
        },
        {
            heading: "Employee Protection",
            paragraphs: [
                "[Your Company] prohibits any form of retaliation against employees for fulfilling their jury duty obligations. Employees will not be penalized, demoted, or terminated for taking jury duty leave.",
                "The company will make reasonable accommodations to ensure that the employee’s job responsibilities are managed during their absence and that they can resume their duties seamlessly upon their return."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and business practices.",
                "Employees will be informed of any significant changes to the policy, and the updated document will be made available for review and acknowledgment."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Jury Duty Leave Policy. Adherence to this policy is essential for maintaining a supportive and legally compliant work environment at [Your Company]."
        }
    ]
};

export const EmployeeLeaveOfAbsenceRequestTemplate = {
    title: "Employee Leave of Absence Request Template",
    policyBlurb: "This policy outlines the procedure for employees to request a leave of absence from work, ensuring transparency, fairness, and compliance with company guidelines.",
    preRead: "Use this template to establish a structured process for handling leave of absence requests, balancing the needs of employees with the operational requirements of the company.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Types of Leave",
                "Eligibility",
                "Request Process",
                "Approval Process",
                "Denial of Request",
                "Duration and Extensions",
                "Returning to Work",
                "Compensation and Benefits",
                "Communication",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize that employees may occasionally need to take a leave of absence from work due to various personal or medical reasons. This policy provides guidelines on how employees can request a leave of absence, ensuring a fair and consistent approach across the organization."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to outline the procedure for requesting and managing leaves of absence to ensure that employees understand their rights and responsibilities when seeking time away from work."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all regular full-time and part-time employees of [Your Company].",
                "It covers all types of leave, including but not limited to medical leave, family leave, personal leave, and other authorized absences."
            ]
        },
        {
            heading: "Types of Leave",
            paragraphs: [
                "Employees may request various types of leave, including:",
                "- Medical leave for personal illness or injury.",
                "- Family and parental leave for the birth, adoption, or foster placement of a child.",
                "- Bereavement leave for the death of a family member.",
                "- Personal leave for personal reasons not covered by other leave types.",
                "Each type of leave may have specific eligibility criteria and documentation requirements."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "To be eligible for a leave of absence, employees must:",
                "- Be employed by [Your Company] for a specified period (if applicable).",
                "- Meet any specific eligibility requirements outlined for each type of leave.",
                "- Provide appropriate documentation to support their leave request, such as medical certificates or legal documents."
            ]
        },
        {
            heading: "Request Process",
            paragraphs: [
                "Employees must submit a leave of absence request to their immediate supervisor or the HR department.",
                "The request should include:",
                "- The type of leave requested.",
                "- The reason for the leave.",
                "- The expected start and end dates of the leave.",
                "Employees should submit their request as far in advance as possible, except in cases of emergency."
            ]
        },
        {
            heading: "Approval Process",
            paragraphs: [
                "The supervisor or HR department will review the leave request based on company policy and operational needs.",
                "Approval may depend on factors such as the employee's eligibility, the reason for the leave, and the impact on the team and company.",
                "Employees will be notified of the approval or denial of their request within a reasonable timeframe."
            ]
        },
        {
            heading: "Denial of Request",
            paragraphs: [
                "If a leave request is denied, the employee will receive a clear explanation for the denial.",
                "Employees have the right to appeal the decision through the established grievance procedure or by providing additional documentation."
            ]
        },
        {
            heading: "Duration and Extensions",
            paragraphs: [
                "The duration of leave granted will be determined based on the type of leave requested and the employee's specific circumstances.",
                "Employees may request extensions of their leave if necessary, subject to approval by their supervisor or HR department."
            ]
        },
        {
            heading: "Returning to Work",
            paragraphs: [
                "Employees are expected to return to work promptly upon the expiration of their approved leave period.",
                "Employees must notify their supervisor or HR department of their intention to return to work and any necessary accommodations."
            ]
        },
        {
            heading: "Compensation and Benefits",
            paragraphs: [
                "Employees may be entitled to certain benefits and compensation during their leave, depending on company policy and applicable laws.",
                "Details regarding pay continuation, use of accrued leave balances, and benefit coverage during leave will be communicated to employees upon approval of their leave request."
            ]
        },
        {
            heading: "Communication",
            paragraphs: [
                "Effective communication between employees and management is essential throughout the leave of absence process.",
                "Supervisors and HR personnel should maintain confidentiality regarding the employee's leave request and related information."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed periodically to ensure it remains compliant with current laws and meets the needs of [Your Company].",
                "Any updates or revisions to the policy will be communicated to employees and documented accordingly."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to review and acknowledge receipt of this Employee Leave of Absence Request Policy. By signing or electronically acknowledging this policy, employees affirm their understanding and agreement to comply with its provisions."
        }
    ]
};


export const EmployeeLongTermDisabilityCoverageTemplate = {
    title: "Employee Long-Term Disability Coverage Template",
    policyBlurb: "This policy outlines the long-term disability coverage available to employees of [Your Company], ensuring financial protection in the event of extended illness or injury.",
    preRead: "Use this template to inform employees about the long-term disability benefits provided by [Your Company], eligibility requirements, claims process, and rights under this policy.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definition of Disability",
                "Eligibility",
                "Coverage Details",
                "Exclusions",
                "Benefit Amount",
                "Waiting Period",
                "Claims Process",
                "Coordination with Other Benefits",
                "Continuation of Benefits",
                "Rehabilitation and Return to Work",
                "Employee Responsibilities",
                "Privacy and Confidentiality",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we understand the importance of protecting our employees from the financial impact of long-term disabilities. This policy outlines the long-term disability coverage available to eligible employees, ensuring peace of mind and financial security during times of extended illness or injury."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide employees with information about the long-term disability benefits offered by [Your Company]. It aims to outline eligibility criteria, coverage details, claims procedures, and employee rights under this policy."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all regular full-time and part-time employees of [Your Company] who meet the eligibility criteria for long-term disability coverage.",
                "It covers disabilities that prevent employees from performing the essential duties of their occupation for an extended period."
            ]
        },
        {
            heading: "Definition of Disability",
            paragraphs: [
                "Disability under this policy is defined as the inability of an employee to perform the essential duties of their occupation due to illness, injury, or other medical conditions.",
                "The disability must be supported by medical evidence and must continue beyond the policy's waiting period."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "To be eligible for long-term disability coverage, employees must meet the following criteria:",
                "Be actively at work and covered under [Your Company]'s group benefits plan.",
                "Have completed the waiting period specified in the policy.",
                "Provide medical evidence supporting the disability, as required by the insurer."
            ]
        },
        {
            heading: "Coverage Details",
            paragraphs: [
                "Long-term disability coverage provides income replacement benefits to eligible employees who are unable to work due to a covered disability.",
                "Benefits typically start after the expiry of the short-term disability coverage or a specified waiting period, and continue until the employee can return to work or reaches the maximum benefit period."
            ]
        },
        {
            heading: "Exclusions",
            paragraphs: [
                "The following conditions or situations may be excluded from long-term disability coverage:",
                "Pre-existing conditions not disclosed at the time of enrollment.",
                "Disabilities resulting from intentional self-inflicted injuries or acts of misconduct.",
                "Disabilities covered by workers' compensation or similar workplace injury benefits."
            ]
        },
        {
            heading: "Benefit Amount",
            paragraphs: [
                "The benefit amount is typically a percentage of the employee's pre-disability earnings, up to a maximum specified in the policy.",
                "Benefits are subject to any applicable offsets, such as Social Security Disability Insurance (SSDI) or other disability benefits."
            ]
        },
        {
            heading: "Waiting Period",
            paragraphs: [
                "A waiting period may apply before long-term disability benefits commence. This waiting period is designed to ensure that short-term disability benefits or other forms of income replacement are exhausted before long-term benefits begin."
            ]
        },
        {
            heading: "Claims Process",
            paragraphs: [
                "Employees must initiate a long-term disability claim by notifying the HR department or the designated benefits administrator.",
                "The claims process typically involves submitting medical documentation and other required forms to support the disability claim.",
                "Claims will be reviewed and processed by the insurer or the designated claims administrator, who will determine the eligibility for benefits based on the policy terms and medical evidence."
            ]
        },
        {
            heading: "Coordination with Other Benefits",
            paragraphs: [
                "Long-term disability benefits may be coordinated with other benefits, such as Social Security Disability Insurance (SSDI), workers' compensation, or retirement benefits.",
                "Employees must report any changes in their eligibility or receipt of other benefits that may affect their long-term disability coverage."
            ]
        },
        {
            heading: "Continuation of Benefits",
            paragraphs: [
                "Benefits will continue as long as the employee remains eligible under the terms of the policy, including compliance with medical reviews and rehabilitation efforts.",
                "Employees may be required to undergo periodic medical evaluations to assess their ongoing eligibility for benefits."
            ]
        },
        {
            heading: "Rehabilitation and Return to Work",
            paragraphs: [
                "The goal of long-term disability benefits is to support employees in their recovery and eventual return to work, if medically feasible.",
                "Employees may be encouraged to participate in rehabilitation programs or vocational training to facilitate their return to the workforce."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for providing accurate information and supporting documentation for their long-term disability claim.",
                "Employees must cooperate with the claims process and any requests for medical information or evaluations.",
                "Employees should notify the HR department of any changes in their medical condition or ability to return to work."
            ]
        },
        {
            heading: "Privacy and Confidentiality",
            paragraphs: [
                "All medical information and personal data collected during the long-term disability claims process will be handled confidentially and in accordance with privacy laws.",
                "Access to employee medical records will be restricted to authorized personnel involved in the administration of long-term disability benefits."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed periodically to ensure it remains compliant with legal requirements and meets the needs of [Your Company] and its employees.",
                "Updates or revisions to the policy will be communicated to employees and documented accordingly."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees who are eligible for long-term disability coverage must review and acknowledge receipt of this Employee Long-Term Disability Coverage Policy. By signing or electronically acknowledging this policy, employees affirm their understanding and agreement to comply with its provisions."
        }
    ]
};

export const EmployeeMaternityLeaveTemplate = {
    title: "Employee Maternity Leave Template",
    policyBlurb: "This policy outlines maternity leave benefits and procedures for employees of [Your Company], ensuring support and accommodation during pregnancy and childbirth.",
    preRead: "Use this template to inform employees about maternity leave entitlements, eligibility criteria, application procedures, and their rights under this policy.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Eligibility",
                "Maternity Leave Entitlement",
                "Pregnancy and Maternity Benefits",
                "Application Process",
                "Medical Documentation",
                "Alternative Work Arrangements",
                "Workplace Accommodations",
                "Benefits During Maternity Leave",
                "Return to Work",
                "Breastfeeding Support",
                "Employee Responsibilities",
                "Privacy and Confidentiality",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize the importance of supporting our employees during the maternity period. This policy outlines the maternity leave benefits and procedures to ensure that expecting mothers receive necessary accommodations and support."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide guidelines and support for employees who are expecting a child, outlining their entitlements, responsibilities, and the process for taking maternity leave."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all female employees of [Your Company] who are expecting a child, including full-time, part-time, and temporary employees.",
                "It covers maternity leave for childbirth, adoption, and surrogate parenting."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "To be eligible for maternity leave, employees must meet the following criteria:",
                "- The employee must have completed a specified period of employment as outlined in the company's employment policies.",
                "- The employee must provide sufficient notice and medical documentation as required by the company."
            ]
        },
        {
            heading: "Maternity Leave Entitlement",
            paragraphs: [
                "Maternity leave entitlement includes both statutory and additional leave provided by [Your Company].",
                "Statutory maternity leave typically includes a specified number of weeks before and after childbirth, as per local labor laws.",
                "[Your Company] may provide additional maternity leave benefits beyond statutory requirements based on company policy and practice."
            ]
        },
        {
            heading: "Pregnancy and Maternity Benefits",
            paragraphs: [
                "During maternity leave, employees may be eligible for various benefits, including:",
                "Paid maternity leave benefits as per company policy.",
                "Continuation of health insurance benefits.",
                "Accrued vacation or sick leave benefits, if applicable."
            ]
        },
        {
            heading: "Application Process",
            paragraphs: [
                "Employees must notify their supervisor and HR department in writing as soon as they become aware of their pregnancy and intention to take maternity leave.",
                "The notification should include expected dates of leave commencement and return to work."
            ]
        },
        {
            heading: "Medical Documentation",
            paragraphs: [
                "Employees may be required to provide medical documentation confirming pregnancy and anticipated due date.",
                "Medical certification may also be required to determine the employee's fitness to work before and after maternity leave."
            ]
        },
        {
            heading: "Alternative Work Arrangements",
            paragraphs: [
                "Employees may request alternative work arrangements during pregnancy or upon returning from maternity leave, such as flexible hours or telecommuting.",
                "Such requests will be considered based on operational feasibility and business needs."
            ]
        },
        {
            heading: "Workplace Accommodations",
            paragraphs: [
                "Reasonable accommodations will be provided to support pregnant employees in the workplace, as required by law.",
                "Accommodations may include ergonomic adjustments, modified work duties, or temporary reassignments."
            ]
        },
        {
            heading: "Benefits During Maternity Leave",
            paragraphs: [
                "Employees may continue to receive certain benefits during maternity leave, such as health insurance coverage and retirement contributions.",
                "Accrued vacation or sick leave benefits may also continue to accrue during the leave period."
            ]
        },
        {
            heading: "Return to Work",
            paragraphs: [
                "Employees are expected to notify their supervisor and HR department of their intention to return to work at least [number] weeks before the end of maternity leave.",
                "Upon returning, employees will resume their previous position or an equivalent role, as applicable."
            ]
        },
        {
            heading: "Breastfeeding Support",
            paragraphs: [
                "Employees returning from maternity leave will be provided with breastfeeding support, including access to lactation rooms or facilities.",
                "Flexible break times and support for expressing milk at work will be accommodated as per legal requirements."
            ]
        },
        {
            heading: "Employee Responsibilities",
            paragraphs: [
                "Employees are responsible for providing timely notice and required documentation related to their maternity leave.",
                "Employees should communicate openly with their supervisor and HR department regarding any changes in their pregnancy status or return-to-work plans."
            ]
        },
        {
            heading: "Privacy and Confidentiality",
            paragraphs: [
                "All medical and personal information related to maternity leave will be handled confidentially, in compliance with privacy laws.",
                "Access to employee maternity records will be restricted to authorized personnel involved in administering leave benefits."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed periodically to ensure compliance with legal requirements and alignment with best practices.",
                "Updates or revisions to the policy will be communicated to employees and documented accordingly."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees who are eligible for maternity leave must review and acknowledge receipt of this Employee Maternity Leave Policy. By signing or electronically acknowledging this policy, employees affirm their understanding and agreement to comply with its provisions."
        }
    ]
};

export const EmployeeMentalHealthAndWellnessTemplate = {
    title: "Employee Mental Health and Wellness Template",
    policyBlurb: "This policy outlines [Your Company]'s commitment to promoting mental health and supporting employees' well-being in the workplace.",
    preRead: "Use this template to develop a comprehensive mental health and wellness policy that fosters a supportive and inclusive work environment, enhances employee productivity, and ensures the well-being of all employees.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Importance of Mental Health and Wellness",
                "Promoting Mental Health Awareness",
                "Supporting Employees' Mental Health",
                "Mental Health Resources and Support Programs",
                "Confidentiality and Privacy",
                "Training and Education",
                "Roles and Responsibilities",
                "Workplace Accommodations",
                "Preventing Mental Health Discrimination",
                "Crisis Management and Intervention",
                "Return to Work",
                "Monitoring and Evaluation",
                "Policy Review and Updates",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "[Your Company] recognizes the importance of mental health and wellness in the workplace. This policy aims to promote a supportive environment where employees feel valued and empowered to prioritize their mental well-being."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish guidelines and resources to support employees' mental health, reduce stigma, and create a culture of well-being at [Your Company]."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees, including full-time, part-time, temporary, and contract workers of [Your Company].",
                "It covers all aspects of mental health and wellness initiatives, programs, and resources provided by the company."
            ]
        },
        {
            heading: "Importance of Mental Health and Wellness",
            paragraphs: [
                "Promoting mental health and wellness contributes to employee satisfaction, productivity, and retention.",
                "It fosters a positive work environment and reduces absenteeism and presenteeism."
            ]
        },
        {
            heading: "Promoting Mental Health Awareness",
            paragraphs: [
                "Educational campaigns and workshops will be conducted to raise awareness about mental health issues, symptoms, and available resources.",
                "Employees will be encouraged to participate in mental health awareness activities."
            ]
        },
        {
            heading: "Supporting Employees' Mental Health",
            paragraphs: [
                "Employee assistance programs (EAPs) will be available to provide confidential counseling, crisis intervention, and referrals to professional services.",
                "Managers and supervisors will receive training on recognizing signs of mental health issues and supporting employees effectively."
            ]
        },
        {
            heading: "Mental Health Resources and Support Programs",
            paragraphs: [
                "Employees will have access to resources such as mental health hotlines, online support groups, and wellness apps.",
                "Wellness programs will include mindfulness sessions, stress management workshops, and fitness activities."
            ]
        },
        {
            heading: "Confidentiality and Privacy",
            paragraphs: [
                "All information related to employees' mental health will be treated confidentially and in compliance with privacy laws.",
                "Access to mental health records will be restricted to authorized personnel involved in providing support and accommodations."
            ]
        },
        {
            heading: "Training and Education",
            paragraphs: [
                "Training programs will be offered to employees to enhance mental health literacy and resilience.",
                "Managers and HR personnel will receive training on implementing mental health policies and supporting employees in crisis."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Managers are responsible for creating a supportive work environment and addressing mental health concerns promptly and sensitively.",
                "Employees are encouraged to seek help when needed and support colleagues in maintaining their mental well-being."
            ]
        },
        {
            heading: "Workplace Accommodations",
            paragraphs: [
                "Reasonable accommodations will be provided to employees with mental health conditions, as required by law.",
                "Flexible work arrangements and modified job duties may be considered to support employees' mental health."
            ]
        },
        {
            heading: "Preventing Mental Health Discrimination",
            paragraphs: [
                "Discrimination or harassment based on mental health status will not be tolerated and will be addressed through appropriate disciplinary measures.",
                "Employees are encouraged to report any incidents of discrimination or harassment related to mental health."
            ]
        },
        {
            heading: "Crisis Management and Intervention",
            paragraphs: [
                "Procedures will be in place for managing mental health crises, including suicide prevention protocols.",
                "Immediate support and intervention will be provided to employees in crisis, and referrals to external resources will be made as needed."
            ]
        },
        {
            heading: "Return to Work",
            paragraphs: [
                "Employees returning to work after a mental health-related absence will be supported through a phased return-to-work plan.",
                "Accommodations and support services will be provided to facilitate a smooth transition back to full productivity."
            ]
        },
        {
            heading: "Monitoring and Evaluation",
            paragraphs: [
                "The effectiveness of mental health initiatives will be monitored through employee feedback, utilization rates of support programs, and absenteeism records.",
                "Regular evaluations will be conducted to assess the impact of policies and make necessary improvements."
            ]
        },
        {
            heading: "Policy Review and Updates",
            paragraphs: [
                "This policy will be reviewed annually to ensure alignment with current best practices and legal requirements.",
                "Updates will be communicated to employees, and training on revised policies will be provided as necessary."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to review and acknowledge receipt of this Employee Mental Health and Wellness Policy. By signing or electronically acknowledging this policy, employees affirm their understanding and commitment to promoting mental health and well-being in the workplace."
        }
    ]
};

export const EmployeeMilitaryLeaveTemplate = {
    title: "Employee Military Leave Template",
    policyBlurb: "This policy outlines [Your Company]'s commitment to supporting employees who serve in the military by providing leave and ensuring their rights and benefits are protected.",
    preRead: "Use this template to develop a policy that complies with legal requirements, supports employees called to military service, and maintains a supportive work environment.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Eligibility",
                "Types of Military Leave",
                "Notification and Documentation",
                "Benefits During Military Leave",
                "Job Protection and Reinstatement",
                "Compensation During Military Leave",
                "Health Insurance Benefits",
                "Training and Drills",
                "Return to Work",
                "Support for Military Families",
                "Prohibited Actions",
                "Policy Review and Updates",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "[Your Company] recognizes the sacrifices and contributions of employees who serve in the military. This policy ensures that these employees receive the necessary support and benefits while fulfilling their military duties."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to outline the rights and responsibilities of employees called to military duty and to ensure compliance with federal and state laws regarding military leave."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company] who are members of the uniformed services, including the National Guard and Reserves.",
                "It covers all types of military leave, including training, active duty, and deployment."
            ]
        },
        {
            heading: "Eligibility",
            paragraphs: [
                "Employees are eligible for military leave if they are members of the uniformed services, including the Army, Navy, Air Force, Marine Corps, Coast Guard, National Guard, and Reserves.",
                "Eligibility criteria may vary depending on federal and state laws."
            ]
        },
        {
            heading: "Types of Military Leave",
            paragraphs: [
                "Active Duty: Employees called to active duty are entitled to military leave for the duration of their service.",
                "Training and Drills: Employees are entitled to leave for required military training, drills, and meetings."
            ]
        },
        {
            heading: "Notification and Documentation",
            paragraphs: [
                "Employees must provide advance notice of military duty to their supervisor and HR department, unless military necessity prevents advance notice.",
                "Documentation of military orders or other official notices may be required to verify the need for military leave."
            ]
        },
        {
            heading: "Benefits During Military Leave",
            paragraphs: [
                "Employees will continue to accrue vacation, sick leave, and other benefits as if actively employed during military leave.",
                "Health insurance benefits will continue during military leave, with the option for employees to continue coverage under COBRA if applicable."
            ]
        },
        {
            heading: "Job Protection and Reinstatement",
            paragraphs: [
                "Employees are entitled to return to their previous position or a comparable position upon completion of military leave, with the same pay, benefits, and seniority.",
                "The company will make reasonable efforts to accommodate any changes in job duties or responsibilities resulting from the employee's military service."
            ]
        },
        {
            heading: "Compensation During Military Leave",
            paragraphs: [
                "Employees may use accrued vacation or other paid leave for compensation during military leave, in addition to any military pay received.",
                "The company may provide differential pay to make up the difference between military pay and regular salary."
            ]
        },
        {
            heading: "Health Insurance Benefits",
            paragraphs: [
                "Health insurance coverage will continue during military leave, with premiums paid by the company or by the employee under COBRA continuation.",
                "Employees should contact the HR department to make arrangements for health insurance coverage during military leave."
            ]
        },
        {
            heading: "Training and Drills",
            paragraphs: [
                "Employees are encouraged to schedule military training and drills in advance to minimize disruption to work schedules.",
                "Reasonable accommodations will be made to allow employees to attend required military training and drills."
            ]
        },
        {
            heading: "Return to Work",
            paragraphs: [
                "Employees returning from military leave must notify their supervisor and HR department of their return date and provide any necessary documentation.",
                "The company will facilitate a smooth transition back to work and ensure that the employee receives any necessary training or updates."
            ]
        },
        {
            heading: "Support for Military Families",
            paragraphs: [
                "The company may provide resources and support to military families during an employee's absence, including access to counseling services or community support groups.",
                "Employees are encouraged to communicate their needs to the HR department to receive appropriate assistance."
            ]
        },
        {
            heading: "Prohibited Actions",
            paragraphs: [
                "Discrimination or retaliation against employees based on their military service status is strictly prohibited.",
                "Managers and supervisors are prohibited from taking adverse employment actions against employees due to their military obligations."
            ]
        },
        {
            heading: "Policy Review and Updates",
            paragraphs: [
                "This policy will be reviewed annually to ensure compliance with legal requirements and to incorporate any necessary updates.",
                "Employees will be informed of any changes to the policy and provided with updated information as needed."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to acknowledge receipt of this Employee Military Leave Policy. By signing or electronically acknowledging this policy, employees affirm their understanding of their rights and responsibilities related to military leave."
        }
    ]
};

export const EmployeeMoonlightingAndOutsideEmploymentTemplate = {
    title: "Employee Moonlighting and Outside Employment Policy",
    policyBlurb: "This policy outlines the guidelines and procedures for employees seeking outside employment or engaging in moonlighting activities while employed at [Your Company].",
    preRead: "Use this template to establish clear expectations, protect company interests, and ensure employees maintain commitment and performance in their primary roles.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definition of Moonlighting and Outside Employment",
                "Approval Process",
                "Conflicts of Interest",
                "Use of Company Resources",
                "Performance and Commitment",
                "Non-Disclosure and Confidentiality",
                "Non-Compete and Intellectual Property",
                "Termination of Outside Employment",
                "Policy Violations",
                "Review and Updates",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize that employees may have opportunities or obligations outside of their primary employment. This policy establishes guidelines to manage moonlighting and outside employment activities to ensure they do not interfere with job performance or create conflicts of interest."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to maintain the integrity of [Your Company]'s operations, protect confidential information, and ensure employees fulfill their obligations and commitments effectively."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], including full-time, part-time, and temporary employees.",
                "It covers all forms of outside employment, including self-employment, freelance work, consulting, and any other paid or unpaid engagements."
            ]
        },
        {
            heading: "Definition of Moonlighting and Outside Employment",
            paragraphs: [
                "Moonlighting refers to an employee holding a second job or engaging in additional employment outside their primary job at [Your Company].",
                "Outside employment includes any work or business activities conducted outside of [Your Company], whether for profit or not."
            ]
        },
        {
            heading: "Approval Process",
            paragraphs: [
                "Employees must seek approval from their supervisor and HR department before engaging in moonlighting or outside employment.",
                "Approval is contingent upon ensuring that the outside activities do not conflict with the employee's job responsibilities, affect performance, or create conflicts of interest."
            ]
        },
        {
            heading: "Conflicts of Interest",
            paragraphs: [
                "Employees must avoid conflicts of interest between their primary job at [Your Company] and their outside employment.",
                "Conflicts of interest may arise if the outside employment involves a competitor, supplier, or customer of [Your Company], or if it interferes with the employee’s ability to perform their job effectively."
            ]
        },
        {
            heading: "Use of Company Resources",
            paragraphs: [
                "Employees may not use [Your Company]'s resources, including time, equipment, supplies, or proprietary information, for moonlighting or outside employment activities.",
                "This includes using company email, facilities, or intellectual property without proper authorization."
            ]
        },
        {
            heading: "Performance and Commitment",
            paragraphs: [
                "Engaging in moonlighting or outside employment must not adversely impact an employee's performance, attendance, or commitment to their primary job at [Your Company].",
                "Employees are expected to prioritize their responsibilities at [Your Company] and maintain the same standards of performance and conduct."
            ]
        },
        {
            heading: "Non-Disclosure and Confidentiality",
            paragraphs: [
                "Employees must maintain confidentiality and not disclose [Your Company]'s proprietary or confidential information to any outside parties.",
                "This obligation extends to information learned or accessed during the course of outside employment."
            ]
        },
        {
            heading: "Non-Compete and Intellectual Property",
            paragraphs: [
                "Employees may not engage in outside employment that involves activities competing directly with [Your Company]'s business interests.",
                "Employees must respect [Your Company]'s intellectual property rights and not use or disclose proprietary information gained through their employment."
            ]
        },
        {
            heading: "Termination of Outside Employment",
            paragraphs: [
                "The company reserves the right to request termination of outside employment if it interferes with the employee's performance or creates conflicts of interest.",
                "Failure to disclose outside employment or obtain required approvals may result in disciplinary action, up to and including termination."
            ]
        },
        {
            heading: "Policy Violations",
            paragraphs: [
                "Violations of this policy will be subject to disciplinary action, which may include verbal or written warnings, probation, or termination of employment.",
                "Employees are encouraged to report potential violations of this policy to the HR department."
            ]
        },
        {
            heading: "Review and Updates",
            paragraphs: [
                "This policy will be reviewed annually to ensure relevance and compliance with legal requirements and company policies.",
                "Updates may be made as necessary, and employees will be informed of any changes."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to acknowledge receipt and understanding of this Employee Moonlighting and Outside Employment Policy. By signing or electronically acknowledging this policy, employees affirm their commitment to comply with its provisions."
        }
    ]
};

export const EmployeeNonDisclosureAgreementNDATemplate = {
    title: "Employee Non-Disclosure Agreement NDA Template",
    policyBlurb: "This Employee Non-Disclosure Agreement (NDA) outlines the obligations and responsibilities of employees regarding the protection and confidentiality of [Your Company]'s proprietary information.",
    preRead: "Use this template to establish clear guidelines to safeguard sensitive information, maintain confidentiality, and protect [Your Company]'s intellectual property.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Definition of Confidential Information",
                "Obligations of the Employee",
                "Permitted Disclosures",
                "Return of Confidential Information",
                "Use of Confidential Information",
                "Duration of Obligations",
                "Non-Compete and Non-Solicitation",
                "Governing Law and Jurisdiction",
                "Dispute Resolution",
                "Enforcement",
                "Amendments and Waivers",
                "Severability",
                "Entire Agreement",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "This Employee Non-Disclosure Agreement (NDA) is entered into between [Your Company] and the employee to ensure the protection of confidential and proprietary information. It establishes the framework for maintaining confidentiality and preventing unauthorized disclosure of sensitive information."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this agreement is to maintain the confidentiality of [Your Company]'s proprietary information. This includes trade secrets, business strategies, customer lists, financial information, and any other information deemed confidential. By safeguarding this information, [Your Company] aims to protect its competitive advantage and intellectual property."
        },
        {
            heading: "Definition of Confidential Information",
            paragraph: "Confidential Information encompasses all information disclosed by [Your Company], whether in oral, written, electronic, or other form, that is not generally known to the public and is intended to be confidential. This includes data, processes, methodologies, designs, formulas, prototypes, and any information that, if disclosed, could harm [Your Company]'s business interests."
        },
        {
            heading: "Obligations of the Employee",
            paragraphs: [
                "The Employee agrees to maintain the confidentiality of all Confidential Information disclosed during the course of their employment.",
                "The Employee must not disclose, publish, reproduce, transmit, or make use of Confidential Information except as necessary to fulfill their duties at [Your Company]. This obligation extends beyond the termination of employment.",
                "The Employee acknowledges that Confidential Information remains the exclusive property of [Your Company] and agrees to return or destroy such information upon request or upon termination of employment."
            ]
        },
        {
            heading: "Permitted Disclosures",
            paragraph: "The Employee may disclose Confidential Information when required by law or court order. However, the Employee agrees to notify [Your Company] promptly to enable it to seek a protective order or other appropriate remedy. This provision ensures compliance with legal obligations while safeguarding [Your Company]'s interests."
        },
        {
            heading: "Return of Confidential Information",
            paragraph: "Upon termination of employment or at the request of [Your Company], the Employee agrees to promptly return or destroy all Confidential Information, including any copies or derivatives thereof, in their possession or control. This ensures that [Your Company]'s proprietary information is not retained beyond the scope of authorized use."
        },
        {
            heading: "Use of Confidential Information",
            paragraph: "The Employee agrees to use Confidential Information solely for the purpose of performing their duties at [Your Company]. They must take reasonable precautions to prevent unauthorized use, access, or disclosure of such information. This includes implementing security measures and following [Your Company]'s policies and procedures regarding data protection."
        },
        {
            heading: "Duration of Obligations",
            paragraph: "The obligations under this agreement remain in effect during the term of employment and continue indefinitely thereafter. Even after the termination of employment, the Employee must continue to abide by the confidentiality obligations to protect [Your Company]'s trade secrets and proprietary information."
        },
        {
            heading: "Non-Compete and Non-Solicitation",
            paragraph: "During the term of employment and for a specified period after termination, the Employee agrees not to engage in any activity that competes with [Your Company]'s business or solicit its clients, customers, or employees for competitive purposes. This provision safeguards [Your Company]'s relationships and prevents unfair competition."
        },
        {
            heading: "Governing Law and Jurisdiction",
            paragraph: "This agreement shall be governed by and construed in accordance with the laws of [Your State/Country]. Any disputes arising out of or in connection with this agreement shall be resolved exclusively by the courts of [City, State/Country]. This ensures consistency and clarity in legal interpretation and enforcement."
        },
        {
            heading: "Dispute Resolution",
            paragraph: "Any disputes arising under this agreement shall be resolved through arbitration in [City, State/Country], conducted in accordance with the rules of [Arbitration Service/Institution]. This alternative dispute resolution mechanism promotes efficiency and confidentiality in resolving conflicts."
        },
        {
            heading: "Enforcement",
            paragraph: "Violation of any provision of this agreement may result in legal remedies, including injunctive relief and monetary damages, in addition to any other remedies available under applicable law. This ensures that [Your Company] can protect its interests and seek appropriate relief for breaches of confidentiality."
        },
        {
            heading: "Amendments and Waivers",
            paragraph: "No amendment or waiver of any provision of this agreement shall be effective unless it is in writing and signed by both parties. This provision ensures that any changes to the terms of the agreement are documented and mutually agreed upon, maintaining clarity and enforceability."
        },
        {
            heading: "Severability",
            paragraph: "If any provision of this agreement is held invalid or unenforceable, the remaining provisions shall continue in full force and effect. The parties shall replace the invalid or unenforceable provision with a valid and enforceable provision that most closely achieves the intended economic effect."
        },
        {
            heading: "Entire Agreement",
            paragraph: "This agreement constitutes the entire understanding between the parties regarding the subject matter herein and supersedes all prior agreements and understandings, whether written or oral. It reflects the complete agreement reached after careful negotiation and discussion."
        },
        {
            heading: "Acknowledgment",
            paragraph: "By signing this agreement, the Employee acknowledges that they have read, understood, and agree to comply with the terms of this Employee Non-Disclosure Agreement (NDA). They recognize the importance of protecting [Your Company]'s confidential information and agree to abide by the obligations outlined herein."
        }
    ]
};

export const EmployeeOnboardingAndOrientationTemplate = {
    title: "Employee Onboarding and Orientation Template",
    policyBlurb: "This Employee Onboarding and Orientation template outlines the process and components of welcoming new employees to [Your Company], ensuring a smooth transition and integration into the organization.",
    preRead: "Use this template to establish a structured onboarding program that introduces new hires to [Your Company]'s culture, policies, procedures, and expectations, setting the foundation for long-term success.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose of Onboarding",
                "Benefits of Effective Onboarding",
                "Pre-Onboarding Preparation",
                "Onboarding Process Overview",
                "Day 1: Welcome and Orientation",
                "First Week: Role Introduction and Training",
                "First Month: Integration and Development",
                "First Three Months: Performance Check-in",
                "Continuous Feedback and Support",
                "Conclusion and Feedback",
                "Evaluation and Improvement"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "Employee onboarding is a crucial process that begins from the moment a new hire accepts an offer and continues through their initial days, weeks, and months at [Your Company]. It goes beyond paperwork and orientation sessions; it's about integrating new employees into the organizational culture and ensuring they feel welcomed and prepared to contribute effectively."
        },
        {
            heading: "Purpose of Onboarding",
            paragraph: "The primary purpose of onboarding at [Your Company] is to facilitate a smooth transition for new employees, helping them acclimate to their roles, understand organizational values, and build positive relationships with colleagues. Effective onboarding sets clear expectations, reduces turnover, and boosts employee engagement and productivity."
        },
        {
            heading: "Benefits of Effective Onboarding",
            paragraphs: [
                "Effective onboarding enhances employee retention by up to 25% and accelerates time to productivity.",
                "It fosters a positive employee experience, leading to higher job satisfaction and commitment to the organization.",
                "Well-structured onboarding reduces anxiety and uncertainty for new hires, promoting faster integration into team dynamics and organizational culture.",
                "It ensures compliance with company policies, procedures, and legal requirements, minimizing risks associated with misunderstandings or missteps."
            ]
        },
        {
            heading: "Pre-Onboarding Preparation",
            paragraphs: [
                "Before the new employee's first day, HR and hiring managers should prepare a comprehensive onboarding plan tailored to the role and individual needs.",
                "Ensure all necessary paperwork, including employment contracts, tax forms, and company policies, is ready for completion.",
                "Coordinate with IT to set up necessary equipment, access to systems, and email accounts prior to the start date.",
                "Communicate the onboarding schedule, including key contacts and orientation details, to the new hire in advance."
            ]
        },
        {
            heading: "Onboarding Process Overview",
            paragraph: "The onboarding process at [Your Company] is structured into several phases, each designed to achieve specific objectives while providing continuous support and feedback to the new employee."
        },
        {
            heading: "Day 1: Welcome and Orientation",
            paragraphs: [
                "On the first day, the new employee is welcomed by their manager and team members. They receive a tour of the office facilities and an introduction to key personnel.",
                "Orientation sessions cover organizational basics, including company history, mission, values, and an overview of departments and team structures.",
                "HR conducts a session on company policies, benefits packages, and administrative procedures. This includes completing required paperwork and understanding employee rights and responsibilities."
            ]
        },
        {
            heading: "First Week: Role Introduction and Training",
            paragraphs: [
                "During the first week, the new employee starts to delve into their role specifics. They meet with their manager to clarify job expectations, performance goals, and project assignments.",
                "Training sessions focus on job-specific skills and competencies necessary for success. This may include technical training, software systems familiarization, and shadowing experienced colleagues.",
                "The new hire is encouraged to ask questions, seek clarification, and provide input as they begin to contribute to team projects and tasks."
            ]
        },
        {
            heading: "First Month: Integration and Development",
            paragraphs: [
                "In the first month, the focus shifts to deeper integration within the team and understanding broader organizational dynamics.",
                "Regular check-ins with the manager provide opportunities for feedback and adjustment. Performance expectations are clarified, and any early challenges are addressed proactively.",
                "Additional training and development opportunities are identified based on the new employee's progress and career aspirations."
            ]
        },
        {
            heading: "First Three Months: Performance Check-in",
            paragraphs: [
                "At the three-month mark, a formal performance review is conducted to assess the new employee's progress, achievements, and areas for improvement.",
                "The review includes feedback from peers and stakeholders, discussing strengths, developmental needs, and alignment with company values and culture.",
                "Adjustments to goals or training plans are made as necessary to support ongoing development and career growth."
            ]
        },
        {
            heading: "Continuous Feedback and Support",
            paragraphs: [
                "Beyond the initial onboarding period, [Your Company] fosters a culture of continuous feedback and support.",
                "Regular check-ins between the employee and their manager provide opportunities to discuss performance, career goals, and any challenges.",
                "Mentoring programs, coaching sessions, and peer support networks further enhance professional development and integration within the organization."
            ]
        },
        {
            heading: "Conclusion and Feedback",
            paragraph: "At the conclusion of the formal onboarding process, the new employee is invited to provide feedback on their onboarding experience. This input helps [Your Company] refine and improve future onboarding practices to better meet the needs of incoming employees."
        },
        {
            heading: "Evaluation and Improvement",
            paragraphs: [
                "HR conducts periodic evaluations of the onboarding program's effectiveness, gathering feedback from both new hires and managers.",
                "Based on evaluation results, adjustments are made to enhance program delivery, content relevance, and alignment with evolving organizational goals and employee needs."
            ]
        }
    ]
};

export const EmployeeOpenDoorCommunicationPolicyTemplate = {
    title: "Employee Open Door Communication Policy Template",
    policyBlurb: "This policy outlines our commitment to fostering an open and transparent communication environment within [Your Company].",
    preRead: "Use this template to develop a policy that encourages open communication between employees and management, promoting a culture of trust and collaboration.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Policy Statement",
                "Guidelines for Open Door Communication",
                "Roles and Responsibilities",
                "Confidentiality and Anonymity",
                "Handling Concerns and Feedback",
                "Training and Support",
                "Monitoring and Evaluation",
                "Review and Revision",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we believe that open and transparent communication is essential to fostering a positive work environment. Our Open Door Communication Policy is designed to encourage employees to freely express their ideas, concerns, and feedback to management at all levels. This policy is a cornerstone of our commitment to creating a culture of trust, respect, and collaboration."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to provide a structured framework that facilitates open communication between employees and management. By promoting an open door policy, we aim to ensure that all employees feel heard, valued, and empowered to contribute to the continuous improvement of our organization. This policy outlines the principles and procedures that guide open communication within [Your Company]."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees at [Your Company], regardless of their position or tenure. It encompasses all forms of communication, including face-to-face meetings, phone calls, emails, and virtual communications.",
                "The Open Door Communication Policy is applicable to all departments and locations within [Your Company]. It is designed to address both work-related and non-work-related issues that may impact employee well-being and job performance."
            ]
        },
        {
            heading: "Policy Statement",
            paragraph: "At [Your Company], we are committed to maintaining an open door policy that encourages employees to communicate openly and honestly with management. We believe that open communication leads to better decision-making, increased employee engagement, and a more positive workplace culture. Managers at all levels are expected to be approachable, listen actively, and respond constructively to employee concerns and suggestions."
        },
        {
            heading: "Guidelines for Open Door Communication",
            paragraphs: [
                "Employees are encouraged to approach their immediate supervisor with any concerns, suggestions, or feedback. If the issue cannot be resolved at this level, employees should feel free to escalate the matter to higher levels of management.",
                "Managers are expected to maintain an open and approachable demeanor, actively listen to employees, and provide timely and constructive feedback. They should create an environment where employees feel safe and respected when voicing their opinions.",
                "All communications should be conducted in a respectful and professional manner. Employees should clearly articulate their concerns or suggestions, and managers should provide thoughtful and constructive responses."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Employees: It is the responsibility of all employees to actively participate in open communication by voicing their concerns, providing feedback, and contributing ideas for improvement. Employees should utilize the open door policy in a constructive and respectful manner.",
                "Managers: Managers at all levels are responsible for fostering an open and approachable environment. They should actively listen to employees, provide timely and constructive feedback, and take appropriate action to address concerns or implement suggestions.",
                "Human Resources: The HR department is responsible for supporting the open door policy by providing training, resources, and guidance to both employees and managers. HR should also monitor the effectiveness of the policy and ensure that it is being implemented consistently across the organization."
            ]
        },
        {
            heading: "Confidentiality and Anonymity",
            paragraphs: [
                "Confidentiality: [Your Company] is committed to maintaining the confidentiality of all communications made under this policy. Managers and HR personnel should handle all concerns and feedback with the utmost discretion and ensure that sensitive information is not disclosed without the employee's consent.",
                "Anonymity: While we encourage employees to communicate openly and directly, we understand that some may prefer to remain anonymous. Employees can choose to submit anonymous feedback or concerns through designated channels, such as suggestion boxes or anonymous online forms."
            ]
        },
        {
            heading: "Handling Concerns and Feedback",
            paragraphs: [
                "All concerns and feedback received through the open door policy should be addressed promptly and constructively. Managers should take the time to understand the issue, seek additional information if necessary, and work collaboratively with the employee to find a resolution.",
                "If a concern requires further investigation or escalation, managers should follow the appropriate procedures and involve relevant parties, such as HR or senior management. The employee should be kept informed of the progress and outcome of their concern."
            ]
        },
        {
            heading: "Training and Support",
            paragraphs: [
                "Training: [Your Company] will provide regular training sessions for both employees and managers to ensure that they understand the principles and procedures of the open door policy. Training will cover topics such as effective communication, active listening, conflict resolution, and confidentiality.",
                "Support: Employees and managers can seek support from the HR department if they need guidance or assistance in utilizing the open door policy. HR will provide resources, such as communication templates and best practices, to help facilitate effective open communication."
            ]
        },
        {
            heading: "Monitoring and Evaluation",
            paragraphs: [
                "The HR department will regularly monitor the implementation and effectiveness of the open door policy. This will include collecting feedback from employees and managers, reviewing communication logs, and assessing the resolution of concerns and feedback.",
                "Based on the findings of the monitoring process, [Your Company] will make necessary adjustments to the policy and procedures to ensure continuous improvement. The HR department will also report the findings to senior management and recommend any required actions."
            ]
        },
        {
            heading: "Review and Revision",
            paragraphs: [
                "This policy will be reviewed annually by the HR department to ensure its continued relevance and effectiveness. Any changes to the policy will be communicated to all employees and managers in a timely manner.",
                "Employees and managers are encouraged to provide feedback on the policy and suggest improvements. This feedback will be considered during the review process to enhance the open door communication framework at [Your Company]."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Open Door Communication Policy. This acknowledgment will be kept on record by the HR department."
        }
    ]
};


export const EmployeeOvertimeCompensationPolicyTemplate = {
    title: "Employee Overtime Compensation Policy Template",
    policyBlurb: "This policy outlines the guidelines and procedures for compensating employees for overtime work at [Your Company].",
    preRead: "Use this template to develop a policy that ensures fair and consistent compensation for employees who work overtime, in compliance with applicable labor laws and regulations.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Eligibility for Overtime Compensation",
                "Overtime Rates",
                "Procedures for Requesting and Approving Overtime",
                "Timekeeping and Recordkeeping",
                "Payment of Overtime",
                "Compliance with Labor Laws",
                "Roles and Responsibilities",
                "Handling Disputes and Grievances",
                "Training and Communication",
                "Monitoring and Review",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize that from time to time, employees may be required to work beyond their regular working hours to meet business needs. This policy sets forth our commitment to ensuring that employees are fairly compensated for overtime work in accordance with applicable labor laws and regulations. The Employee Overtime Compensation Policy provides clear guidelines and procedures for the management, approval, and payment of overtime."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish fair and consistent practices for compensating employees who work overtime. This policy aims to ensure compliance with legal requirements, promote transparency in overtime compensation, and provide employees with a clear understanding of their rights and responsibilities regarding overtime work. By doing so, we aim to foster a positive and productive work environment where employees feel valued and fairly compensated for their efforts."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all non-exempt employees at [Your Company], including full-time, part-time, and temporary staff. Exempt employees, as defined by applicable labor laws, are generally not eligible for overtime compensation unless specified otherwise in their employment contract or company policy.",
                "The policy covers all forms of overtime work, including mandatory and voluntary overtime, and applies to all departments and locations within [Your Company]. It outlines the procedures for requesting, approving, and compensating overtime work, as well as the roles and responsibilities of employees and managers in managing overtime."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Overtime: Work performed by an employee that exceeds the standard working hours established by [Your Company] and applicable labor laws. Overtime may include hours worked beyond the daily or weekly limits specified by law or company policy.",
                "Non-exempt Employee: An employee who is entitled to receive overtime compensation under applicable labor laws. Non-exempt employees are typically paid on an hourly basis and must be compensated for all hours worked beyond the standard workweek.",
                "Exempt Employee: An employee who is exempt from overtime compensation requirements under applicable labor laws. Exempt employees are typically paid on a salary basis and are not eligible for overtime pay unless specified otherwise."
            ]
        },
        {
            heading: "Eligibility for Overtime Compensation",
            paragraphs: [
                "Non-exempt employees are eligible for overtime compensation for hours worked beyond the standard workweek, as defined by applicable labor laws and [Your Company] policy. The standard workweek is typically 40 hours, but may vary depending on local regulations and company practices.",
                "Exempt employees are generally not eligible for overtime compensation. However, [Your Company] may, at its discretion, provide overtime pay or compensatory time off for exempt employees under specific circumstances, as outlined in their employment contracts or company policy.",
                "Employees must obtain prior approval from their supervisor or manager before working overtime. Unauthorized overtime work may not be compensated unless it is determined that the overtime was necessary and unavoidable."
            ]
        },
        {
            heading: "Overtime Rates",
            paragraphs: [
                "Overtime work is compensated at a rate of one and one-half times the employee's regular hourly rate for hours worked beyond the standard workweek, as required by applicable labor laws. For example, if an employee's regular hourly rate is $20, their overtime rate would be $30 per hour.",
                "In certain circumstances, such as work performed on weekends, public holidays, or during emergency situations, overtime may be compensated at a higher rate, such as double the regular hourly rate. Specific rates and conditions for such overtime will be outlined in [Your Company]'s supplementary policies or employee contracts.",
                "Overtime rates are calculated based on the employee's regular hourly rate, excluding bonuses, commissions, or other forms of additional compensation unless required by law. The regular hourly rate is determined by dividing the employee's total earnings for the workweek by the total hours worked."
            ]
        },
        {
            heading: "Procedures for Requesting and Approving Overtime",
            paragraphs: [
                "Employees who anticipate the need to work overtime must submit a request to their supervisor or manager as soon as possible. The request should include the reason for the overtime, the estimated number of hours required, and the expected completion date.",
                "Supervisors and managers are responsible for reviewing and approving or denying overtime requests based on the needs of the department and the availability of budgeted funds. Approval should be granted only when it is determined that the overtime work is necessary and cannot be avoided.",
                "In cases of unexpected or emergency overtime, employees must notify their supervisor or manager as soon as practicable and obtain retroactive approval for the overtime work. Failure to obtain prior approval may result in disciplinary action unless it is determined that the overtime was unavoidable."
            ]
        },
        {
            heading: "Timekeeping and Recordkeeping",
            paragraphs: [
                "Accurate timekeeping is essential for ensuring that employees are properly compensated for overtime work. Employees are responsible for recording their hours worked, including overtime, using [Your Company]'s designated timekeeping system. This may include electronic time clocks, timesheets, or other approved methods.",
                "Supervisors and managers are responsible for reviewing and verifying the accuracy of employee time records. Any discrepancies or errors must be addressed promptly to ensure that employees receive accurate and timely compensation for their work.",
                "All timekeeping records must be retained in accordance with applicable labor laws and [Your Company]'s record retention policies. These records will be used to verify compliance with overtime compensation requirements and may be subject to audit or inspection by regulatory authorities."
            ]
        },
        {
            heading: "Payment of Overtime",
            paragraphs: [
                "Overtime compensation will be included in the employee's regular paycheck for the pay period in which the overtime was worked. Employees will receive a detailed breakdown of their regular and overtime hours, along with the corresponding compensation, on their pay stubs.",
                "In cases where compensatory time off is provided in lieu of overtime pay, the employee must receive the time off within a reasonable period, as defined by applicable labor laws and [Your Company] policy. Compensatory time off must be scheduled and approved in the same manner as regular vacation or leave time.",
                "Employees who believe that they have not been properly compensated for overtime work should promptly report the issue to their supervisor or the HR department. [Your Company] will investigate and resolve any payment discrepancies in a timely and fair manner."
            ]
        },
        {
            heading: "Compliance with Labor Laws",
            paragraphs: [
                "[Your Company] is committed to complying with all applicable labor laws and regulations regarding overtime compensation. This includes federal, state, and local laws that govern overtime pay, recordkeeping, and employee rights.",
                "The HR department is responsible for staying informed about changes in labor laws and ensuring that [Your Company]'s overtime compensation practices are updated accordingly. Any changes to the policy or procedures will be communicated to employees in a timely manner.",
                "Non-compliance with labor laws related to overtime compensation can result in significant legal and financial penalties. [Your Company] takes these obligations seriously and will take appropriate action to address any violations of this policy or applicable laws."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Employees: Employees are responsible for accurately recording their hours worked, including overtime, and obtaining prior approval for overtime work. They should report any concerns or discrepancies related to overtime compensation to their supervisor or the HR department.",
                "Supervisors and Managers: Supervisors and managers are responsible for reviewing and approving overtime requests, verifying the accuracy of timekeeping records, and ensuring that employees are properly compensated for overtime work. They should also monitor and manage overtime within their departments to prevent excessive or unnecessary overtime.",
                "Human Resources: The HR department is responsible for developing and maintaining the overtime compensation policy, providing training and support to employees and managers, and ensuring compliance with applicable labor laws. HR will also handle disputes or grievances related to overtime compensation and ensure that any necessary corrective actions are taken."
            ]
        },
        {
            heading: "Handling Disputes and Grievances",
            paragraphs: [
                "[Your Company] is committed to resolving disputes and grievances related to overtime compensation in a fair and timely manner. Employees who believe that they have not been properly compensated for overtime work should first discuss the issue with their immediate supervisor.",
                "If the issue is not resolved at the supervisory level, employees may escalate their concern to the HR department. HR will investigate the matter, review relevant records, and work with the employee and management to reach a resolution.",
                "Employees will not face retaliation or discrimination for raising concerns or filing grievances related to overtime compensation. [Your Company] encourages open communication and will take all necessary steps to address and resolve any issues in accordance with this policy and applicable labor laws."
            ]
        },
        {
            heading: "Training and Communication",
            paragraphs: [
                "[Your Company] will provide regular training sessions for employees and managers to ensure that they understand the overtime compensation policy and their respective roles and responsibilities. Training will cover topics such as timekeeping procedures, approval processes, and compliance with labor laws.",
                "The HR department will communicate any changes to the overtime compensation policy or procedures to all employees in a timely manner. This may include updates to the employee handbook, email notifications, or announcements during team meetings.",
                "Employees are encouraged to ask questions and seek clarification on any aspects of the overtime compensation policy. The HR department is available to provide support and guidance to ensure that employees and managers fully understand and comply with this policy."
            ]
        },
        {
            heading: "Monitoring and Review",
            paragraphs: [
                "[Your Company] will regularly monitor the implementation and effectiveness of the overtime compensation policy. This includes reviewing timekeeping records, conducting audits, and collecting feedback from employees and managers.",
                "The HR department will conduct an annual review of the policy to ensure that it remains relevant and compliant with current labor laws. Any necessary revisions will be made and communicated to employees in a timely manner.",
                "Employees and managers are encouraged to provide feedback on the overtime compensation policy and suggest improvements. This feedback will be considered during the review process to enhance the effectiveness and fairness of the policy."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Overtime Compensation Policy. This acknowledgment will be kept on record by the HR department and will serve as confirmation of the employee's understanding of their rights and responsibilities regarding overtime work."
        }
    ]
};

export const EmployeeParentalLeavePolicyTemplate = {
    title: "Employee Parental Leave Policy",
    policyBlurb: "This policy outlines the guidelines and procedures for providing parental leave to employees at [Your Company].",
    preRead: "Use this template to develop a policy that ensures fair and consistent parental leave for employees, in compliance with applicable labor laws and regulations.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Eligibility for Parental Leave",
                "Duration of Parental Leave",
                "Procedures for Requesting Parental Leave",
                "Compensation During Parental Leave",
                "Job Protection and Return to Work",
                "Compliance with Labor Laws",
                "Roles and Responsibilities",
                "Handling Disputes and Grievances",
                "Training and Communication",
                "Monitoring and Review",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize the importance of supporting employees during significant life events, such as the birth or adoption of a child. Our Employee Parental Leave Policy provides guidelines for granting parental leave to eligible employees, ensuring they can take time off to care for their new child without fear of job loss or financial instability. This policy demonstrates our commitment to fostering a supportive and family-friendly work environment."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish fair and consistent practices for granting parental leave to employees, in accordance with applicable labor laws and regulations. This policy aims to support employees in balancing their work and family responsibilities, promote gender equality in caregiving, and enhance employee satisfaction and retention. By providing clear guidelines and procedures, we aim to ensure that all employees are aware of their rights and responsibilities regarding parental leave."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees at [Your Company], including full-time, part-time, and temporary staff, who meet the eligibility criteria for parental leave as defined by applicable labor laws and company policy. The policy covers parental leave for both the primary and secondary caregivers, as well as for birth and adoptive parents.",
                "The policy outlines the procedures for requesting, approving, and taking parental leave, as well as the compensation and job protection benefits available to employees during their leave. It applies to all departments and locations within [Your Company], ensuring a consistent and equitable approach to parental leave across the organization."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Parental Leave: Leave granted to employees for the purpose of caring for a new child, either through birth or adoption. Parental leave may include maternity leave, paternity leave, and adoption leave.",
                "Primary Caregiver: The parent who has the primary responsibility for the care and upbringing of the child during the period of parental leave. The primary caregiver may be either the birth parent or the adoptive parent.",
                "Secondary Caregiver: The parent who shares caregiving responsibilities but is not the primary caregiver. The secondary caregiver may also be the birth parent or the adoptive parent.",
                "Eligible Employee: An employee who meets the eligibility criteria for parental leave as defined by applicable labor laws and [Your Company] policy."
            ]
        },
        {
            heading: "Eligibility for Parental Leave",
            paragraphs: [
                "To be eligible for parental leave, employees must have completed at least [X] months of continuous service with [Your Company] prior to the expected start date of the leave. The exact duration of required service may vary based on applicable labor laws and company policy.",
                "Parental leave is available to both primary and secondary caregivers. Employees must provide appropriate documentation, such as a birth certificate or adoption papers, to confirm their eligibility for parental leave.",
                "Employees must submit a written request for parental leave to their supervisor or the HR department at least [X] weeks in advance of the expected start date of the leave, unless there are extenuating circumstances that prevent such notice."
            ]
        },
        {
            heading: "Duration of Parental Leave",
            paragraphs: [
                "The duration of parental leave available to employees will be determined based on applicable labor laws and company policy. Typically, primary caregivers are entitled to a longer period of leave compared to secondary caregivers.",
                "Primary caregivers may be eligible for up to [X] weeks of parental leave, while secondary caregivers may be eligible for up to [Y] weeks of leave. The exact duration of leave may vary based on local regulations and individual circumstances.",
                "Employees may choose to take their parental leave in a continuous block or in separate periods, as long as the total leave taken does not exceed the maximum duration allowed. Employees must discuss their preferred leave arrangements with their supervisor or the HR department and obtain approval."
            ]
        },
        {
            heading: "Procedures for Requesting Parental Leave",
            paragraphs: [
                "Employees who wish to take parental leave must submit a written request to their supervisor or the HR department. The request should include the expected start date, the duration of the leave, and whether the leave will be taken in a continuous block or in separate periods.",
                "Supervisors and managers are responsible for reviewing and approving parental leave requests based on the needs of the department and the eligibility of the employee. Approval should be granted in a timely manner to allow employees to make necessary arrangements for their leave.",
                "In cases of unexpected or emergency parental leave, such as preterm birth or sudden adoption, employees must notify their supervisor or the HR department as soon as practicable and provide the necessary documentation to support their request."
            ]
        },
        {
            heading: "Compensation During Parental Leave",
            paragraphs: [
                "The compensation provided to employees during parental leave will be determined based on applicable labor laws and company policy. In some cases, parental leave may be unpaid, while in other cases, employees may be eligible for partial or full pay during their leave.",
                "Employees may be eligible for government-provided parental leave benefits, such as paid family leave or maternity/paternity benefits. [Your Company] will assist employees in understanding and applying for any available government benefits.",
                "In addition to government benefits, [Your Company] may offer supplementary parental leave benefits, such as paid leave for a certain number of weeks. The details of these benefits will be outlined in the employee's contract or in supplementary company policies."
            ]
        },
        {
            heading: "Job Protection and Return to Work",
            paragraphs: [
                "[Your Company] is committed to ensuring job protection for employees who take parental leave. Employees will be entitled to return to their same position or an equivalent position with the same pay, benefits, and working conditions at the end of their leave.",
                "Employees must notify their supervisor or the HR department of their intended return to work date at least [X] weeks in advance. This notice period allows the company to make necessary arrangements for the employee's return and to ensure a smooth transition.",
                "If an employee wishes to extend their parental leave beyond the initially approved period, they must submit a written request to their supervisor or the HR department. Extensions may be granted based on the employee's circumstances and the needs of the department."
            ]
        },
        {
            heading: "Compliance with Labor Laws",
            paragraphs: [
                "[Your Company] is committed to complying with all applicable labor laws and regulations regarding parental leave. This includes federal, state, and local laws that govern the duration, compensation, and job protection benefits associated with parental leave.",
                "The HR department is responsible for staying informed about changes in labor laws and ensuring that [Your Company]'s parental leave practices are updated accordingly. Any changes to the policy or procedures will be communicated to employees in a timely manner.",
                "Non-compliance with labor laws related to parental leave can result in significant legal and financial penalties. [Your Company] takes these obligations seriously and will take appropriate action to address any violations of this policy or applicable laws."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Employees: Employees are responsible for submitting timely and accurate requests for parental leave, providing the necessary documentation, and communicating with their supervisor or the HR department regarding their leave and return to work plans.",
                "Supervisors and Managers: Supervisors and managers are responsible for reviewing and approving parental leave requests, ensuring that the needs of the department are met, and supporting employees in transitioning to and from parental leave.",
                "Human Resources: The HR department is responsible for developing and maintaining the parental leave policy, providing training and support to employees and managers, and ensuring compliance with applicable labor laws. HR will also handle disputes or grievances related to parental leave and ensure that any necessary corrective actions are taken."
            ]
        },
        {
            heading: "Handling Disputes and Grievances",
            paragraphs: [
                "[Your Company] is committed to resolving disputes and grievances related to parental leave in a fair and timely manner. Employees who have concerns or issues regarding their parental leave should first discuss the matter with their immediate supervisor.",
                "If the issue is not resolved at the supervisory level, employees may escalate their concern to the HR department. HR will investigate the matter, review relevant records, and work with the employee and management to reach a resolution.",
                "Employees will not face retaliation or discrimination for raising concerns or filing grievances related to parental leave. [Your Company] encourages open communication and will take all necessary steps to address and resolve any issues in accordance with this policy and applicable labor laws."
            ]
        },
        {
            heading: "Training and Communication",
            paragraphs: [
                "[Your Company] will provide regular training sessions for employees and managers to ensure that they understand the parental leave policy and their respective roles and responsibilities. Training will cover topics such as eligibility criteria, request procedures, and compliance with labor laws.",
                "The HR department will communicate any changes to the parental leave policy or procedures to all employees in a timely manner. This may include updates to the employee handbook, email notifications, or announcements during team meetings.",
                "Employees are encouraged to ask questions and seek clarification on any aspects of the parental leave policy. The HR department is available to provide support and guidance to ensure that employees and managers fully understand and comply with this policy."
            ]
        },
        {
            heading: "Monitoring and Review",
            paragraphs: [
                "[Your Company] will regularly monitor the implementation and effectiveness of the parental leave policy. This includes reviewing leave records, conducting audits, and collecting feedback from employees and managers.",
                "The HR department will conduct an annual review of the policy to ensure that it remains relevant and compliant with current labor laws. Any necessary revisions will be made and communicated to employees in a timely manner.",
                "Employees and managers are encouraged to provide feedback on the parental leave policy and suggest improvements. This feedback will be considered during the review process to enhance the effectiveness and fairness of the policy."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Parental Leave Policy. This acknowledgment will be kept on record by the HR department and will serve as confirmation of the employee's understanding of their rights and responsibilities regarding parental leave."
        }
    ]
};

export const EmployeePayrollAdvancePolicyTemplate = {
    title: "Employee Payroll Advance Policy Template",
    policyBlurb: "This policy outlines the guidelines and procedures for providing payroll advances to employees at [Your Company].",
    preRead: "Use this template to develop a policy that ensures fair and consistent practices for granting payroll advances to employees, in compliance with applicable labor laws and regulations.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "Eligibility for Payroll Advance",
                "Conditions for Payroll Advance",
                "Request Procedures",
                "Approval Process",
                "Repayment Terms",
                "Impact on Taxes and Benefits",
                "Confidentiality",
                "Roles and Responsibilities",
                "Handling Disputes and Grievances",
                "Training and Communication",
                "Monitoring and Review",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we understand that employees may occasionally face unexpected financial emergencies that require immediate funds. To support our employees during such times, we offer a payroll advance option. This policy provides guidelines for requesting, approving, and repaying payroll advances, ensuring that the process is transparent, fair, and in compliance with applicable labor laws."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear and consistent practices for providing payroll advances to employees in need. By doing so, we aim to support our employees' financial well-being, enhance job satisfaction, and foster a supportive work environment. This policy outlines the eligibility criteria, conditions, procedures, and repayment terms associated with payroll advances, ensuring that all employees are aware of their rights and responsibilities."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], including full-time, part-time, and temporary staff, who meet the eligibility criteria for a payroll advance as defined in this policy. It covers the procedures for requesting, approving, and repaying payroll advances, as well as the roles and responsibilities of employees and management in the process.",
                "The policy aims to ensure that payroll advances are granted in a fair and consistent manner across the organization, while also maintaining the financial stability of the company. It applies to all departments and locations within [Your Company]."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Payroll Advance: A payroll advance is a financial arrangement in which an employee receives a portion of their upcoming paycheck before the scheduled payday to address urgent financial needs.",
                "Eligible Employee: An employee who meets the criteria outlined in this policy and is eligible to request a payroll advance.",
                "Repayment Terms: The agreed-upon conditions under which the payroll advance will be repaid by the employee, typically through deductions from future paychecks."
            ]
        },
        {
            heading: "Eligibility for Payroll Advance",
            paragraphs: [
                "To be eligible for a payroll advance, employees must have completed at least [X] months of continuous service with [Your Company]. The exact duration of required service may vary based on company policy and applicable labor laws.",
                "Payroll advances are intended for employees facing unforeseen financial emergencies, such as medical expenses, urgent home repairs, or other critical situations. Employees must provide a valid reason for requesting a payroll advance, which will be reviewed as part of the approval process.",
                "Employees who have outstanding payroll advances or have defaulted on previous advances may not be eligible for additional advances until the outstanding amounts are fully repaid and their eligibility is reinstated."
            ]
        },
        {
            heading: "Conditions for Payroll Advance",
            paragraphs: [
                "Payroll advances are granted at the discretion of [Your Company] and are subject to the availability of company funds. The company reserves the right to limit the number and amount of payroll advances granted to an employee within a specified period.",
                "The maximum amount of a payroll advance will be determined based on a percentage of the employee's net pay for the upcoming pay period, typically not exceeding [X]% of their net pay. This limit ensures that employees have sufficient funds remaining in their paycheck to cover their regular expenses.",
                "Employees may be required to sign an agreement outlining the terms and conditions of the payroll advance, including the repayment schedule and any applicable fees or interest charges."
            ]
        },
        {
            heading: "Request Procedures",
            paragraphs: [
                "Employees who wish to request a payroll advance must submit a written application to their supervisor or the HR department. The application should include the amount requested, the reason for the advance, and any supporting documentation, such as invoices or receipts for emergency expenses.",
                "The request must be submitted at least [X] business days before the expected disbursement date to allow sufficient time for review and processing. Employees are encouraged to provide as much detail as possible to facilitate the approval process.",
                "In cases of urgent financial emergencies, employees may contact the HR department directly to expedite the review and approval process. However, they must still provide the necessary documentation to support their request."
            ]
        },
        {
            heading: "Approval Process",
            paragraphs: [
                "The approval of payroll advance requests will be based on a review of the employee's eligibility, the validity of the reason for the advance, and the availability of company funds. Supervisors and HR personnel are responsible for reviewing and approving requests in a fair and timely manner.",
                "Once a request is approved, the HR department will notify the employee and arrange for the disbursement of the advance. The disbursement will typically be made through direct deposit to the employee's bank account or by issuing a check.",
                "If a request is denied, the HR department will provide the employee with an explanation for the denial and, if applicable, offer alternative solutions or resources to address their financial needs."
            ]
        },
        {
            heading: "Repayment Terms",
            paragraphs: [
                "Employees who receive a payroll advance are required to repay the advance through deductions from their future paychecks. The repayment schedule and amount will be agreed upon at the time of the advance and outlined in the payroll advance agreement.",
                "Repayment terms will typically involve equal deductions from the employee's paychecks over a specified number of pay periods, ensuring that the total advance is repaid within a reasonable timeframe. The repayment period will not exceed [X] months, unless otherwise agreed upon.",
                "In the event of an employee's termination or resignation before the payroll advance is fully repaid, the outstanding balance will be deducted from their final paycheck. If the final paycheck is insufficient to cover the outstanding amount, the employee will be required to make alternative arrangements to repay the remaining balance."
            ]
        },
        {
            heading: "Impact on Taxes and Benefits",
            paragraphs: [
                "Payroll advances may have implications for an employee's taxes and benefits. The amount advanced will be considered part of the employee's taxable income for the year in which it is received. Employees should consult with a tax advisor to understand the potential tax implications of receiving a payroll advance.",
                "Repayment of payroll advances through paycheck deductions will reduce the employee's net pay for the pay periods in which deductions are made. Employees should consider this reduction when planning their personal finances and budgeting for regular expenses.",
                "Payroll advances do not affect an employee's eligibility for company benefits, such as health insurance, retirement contributions, or paid time off. However, employees should be aware of the impact of reduced net pay on their overall financial situation and plan accordingly."
            ]
        },
        {
            heading: "Confidentiality",
            paragraphs: [
                "[Your Company] is committed to maintaining the confidentiality of all payroll advance requests and related financial information. Employees' personal and financial information will be handled with the utmost care and will not be disclosed to unauthorized individuals.",
                "HR personnel and supervisors involved in the review and approval process are required to keep all information related to payroll advance requests confidential and to use this information solely for the purpose of evaluating and processing the requests.",
                "Employees who have concerns about the confidentiality of their payroll advance request or any related information should contact the HR department for assistance and clarification."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Employees: Employees are responsible for submitting timely and accurate payroll advance requests, providing the necessary documentation, and adhering to the agreed-upon repayment terms. Employees should communicate openly with their supervisors and the HR department regarding any issues or concerns related to their payroll advance.",
                "Supervisors and Managers: Supervisors and managers are responsible for reviewing payroll advance requests, assessing the validity of the reasons provided, and approving or denying requests based on company policy and the availability of funds. They should also support employees in understanding the payroll advance process and addressing any concerns.",
                "Human Resources: The HR department is responsible for developing and maintaining the payroll advance policy, providing training and support to employees and supervisors, and ensuring compliance with applicable labor laws. HR will also handle disputes or grievances related to payroll advances and ensure that any necessary corrective actions are taken."
            ]
        },
        {
            heading: "Handling Disputes and Grievances",
            paragraphs: [
                "[Your Company] is committed to resolving disputes and grievances related to payroll advances in a fair and timely manner. Employees who have concerns or issues regarding their payroll advance should first discuss the matter with their immediate supervisor.",
                "If the issue is not resolved at the supervisory level, employees may escalate their concern to the HR department. HR will investigate the matter, review relevant records, and work with the employee and management to reach a resolution.",
                "Employees will not face retaliation or discrimination for raising concerns or filing grievances related to payroll advances. [Your Company] encourages open communication and will take all necessary steps to address and resolve any issues in accordance with this policy."
            ]
        },
        {
            heading: "Training and Communication",
            paragraphs: [
                "[Your Company] will provide training to all employees, supervisors, and HR personnel on the payroll advance policy and procedures. This training will ensure that everyone understands their roles and responsibilities and is aware of the guidelines for requesting and approving payroll advances.",
                "The payroll advance policy will be communicated to all employees through the employee handbook, company intranet, and other appropriate channels. Regular updates and reminders will be provided to ensure that employees remain informed about the policy and any changes that may occur.",
                "Employees are encouraged to seek clarification and ask questions about the payroll advance policy and procedures. The HR department is available to provide support and guidance to ensure that employees fully understand their rights and responsibilities."
            ]
        },
        {
            heading: "Monitoring and Review",
            paragraphs: [
                "[Your Company] will regularly monitor the implementation and effectiveness of the payroll advance policy. This includes reviewing payroll advance records, conducting audits, and collecting feedback from employees and supervisors.",
                "The HR department will conduct an annual review of the policy to ensure that it remains relevant and compliant with current labor laws. Any necessary revisions will be made and communicated to employees in a timely manner.",
                "Employees and supervisors are encouraged to provide feedback on the payroll advance policy and suggest improvements. This feedback will be considered during the review process to enhance the effectiveness and fairness of the policy."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Payroll Advance Policy. This acknowledgment will be kept on record by the HR department and will serve as confirmation of the employee's understanding of their rights and responsibilities regarding payroll advances."
        }
    ]
};

export const EmployeePersonalRelationshipsInTheWorkplacePolicyTemplate = {
    title: "Employee Personal Relationships in the Workplace Policy Template",
    policyBlurb: "This policy outlines the guidelines and expectations for managing personal relationships in the workplace at [Your Company].",
    preRead: "Use this template to develop a policy that ensures personal relationships among employees do not interfere with professional responsibilities, maintain a respectful work environment, and comply with applicable laws and regulations.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "General Guidelines",
                "Disclosure Requirements",
                "Prohibited Relationships",
                "Impact on Work Environment",
                "Conflict of Interest",
                "Supervisory Relationships",
                "Confidentiality",
                "Roles and Responsibilities",
                "Handling Complaints and Violations",
                "Training and Communication",
                "Monitoring and Review",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize that personal relationships among employees are natural and can contribute positively to the workplace. However, it is essential to manage these relationships professionally to prevent conflicts of interest, favoritism, and disruptions to the work environment. This policy provides guidelines for maintaining appropriate boundaries between personal and professional relationships, ensuring that all employees are treated fairly and with respect."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines for managing personal relationships in the workplace. By doing so, we aim to prevent conflicts of interest, ensure fairness, and maintain a professional and respectful work environment. This policy outlines the expectations for employees who are involved in personal relationships, the disclosure requirements, and the procedures for handling potential issues related to such relationships."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], including full-time, part-time, temporary staff, contractors, and interns. It covers all types of personal relationships, including but not limited to romantic, familial, and close friendships.",
                "The policy aims to ensure that personal relationships do not interfere with professional responsibilities, compromise the integrity of the workplace, or create an uncomfortable or discriminatory environment for other employees. It applies to all departments and locations within [Your Company]."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Personal Relationships: Any relationship between employees that extends beyond professional interactions, including romantic relationships, familial connections, and close friendships.",
                "Conflict of Interest: A situation in which an employee's personal interests or relationships could potentially interfere with their professional responsibilities or judgment.",
                "Supervisory Relationship: A working relationship in which one employee has direct or indirect authority over another employee's performance evaluations, promotions, salary decisions, or other terms and conditions of employment."
            ]
        },
        {
            heading: "General Guidelines",
            paragraphs: [
                "Employees are expected to maintain professional behavior and uphold the company's standards of conduct, regardless of their personal relationships. Personal relationships should not influence work-related decisions, performance evaluations, or the allocation of resources.",
                "Employees involved in personal relationships must exercise discretion and professionalism to avoid creating an uncomfortable or disruptive work environment for other colleagues. Public displays of affection and personal disputes should be kept outside the workplace.",
                "It is essential to separate personal and professional roles to ensure that work responsibilities are fulfilled objectively and fairly. Employees should avoid situations where their personal relationships could create the perception of bias or favoritism."
            ]
        },
        {
            heading: "Disclosure Requirements",
            paragraphs: [
                "Employees who are involved in personal relationships that may create a conflict of interest or impact their work environment are required to disclose the relationship to the HR department. This disclosure is necessary to assess and manage any potential conflicts and ensure transparency.",
                "Disclosure should be made promptly after the relationship begins or if an existing relationship changes in a way that could affect the workplace. The HR department will handle disclosures confidentially and will work with the employees involved to determine appropriate measures to prevent conflicts of interest.",
                "Failure to disclose a personal relationship that creates a conflict of interest or affects the workplace may result in disciplinary action, up to and including termination of employment."
            ]
        },
        {
            heading: "Prohibited Relationships",
            paragraphs: [
                "Certain personal relationships are strictly prohibited due to the inherent conflicts of interest they present. These include relationships between employees and their direct supervisors, managers, or anyone in their direct reporting line.",
                "Romantic relationships between employees and clients, vendors, or other business partners are also prohibited to avoid conflicts of interest and maintain the integrity of the company's business relationships.",
                "Employees found to be engaging in prohibited relationships may face disciplinary action, up to and including termination of employment. The HR department will work with the employees involved to address any potential conflicts and determine appropriate measures."
            ]
        },
        {
            heading: "Impact on Work Environment",
            paragraphs: [
                "Personal relationships should not negatively impact the work environment or create discomfort for other employees. It is important to maintain a professional demeanor and ensure that personal relationships do not interfere with work duties or the functioning of the team.",
                "Employees involved in personal relationships must be mindful of how their interactions are perceived by others. Public displays of affection, favoritism, or personal disputes should be avoided to maintain a respectful and professional workplace.",
                "If a personal relationship causes disruptions or affects the morale of other employees, the HR department will intervene to address the issue. This may include reassigning work responsibilities or making other adjustments to ensure a positive work environment."
            ]
        },
        {
            heading: "Conflict of Interest",
            paragraphs: [
                "Personal relationships that create a conflict of interest must be managed to prevent any undue influence on work-related decisions. Employees should avoid situations where their personal relationships could affect their professional judgment or create a perception of bias.",
                "Employees involved in personal relationships should not participate in decisions related to hiring, promotion, performance evaluation, or disciplinary actions affecting the other party in the relationship. Such decisions should be made by an impartial third party to ensure fairness.",
                "The HR department will work with employees to identify and mitigate potential conflicts of interest. This may include reassigning work responsibilities or implementing other measures to maintain objectivity and fairness."
            ]
        },
        {
            heading: "Supervisory Relationships",
            paragraphs: [
                "Supervisory relationships between employees who are involved in personal relationships are strictly prohibited. This includes direct and indirect reporting relationships where one employee has authority over the other's performance evaluations, promotions, salary decisions, or other terms and conditions of employment.",
                "If a personal relationship develops between a supervisor and a subordinate, the employees must disclose the relationship to the HR department immediately. The HR department will assess the situation and determine appropriate measures to prevent conflicts of interest, which may include reassigning one or both employees.",
                "Supervisors are expected to maintain professional boundaries and avoid situations where their personal relationships could compromise their authority or create a perception of favoritism. Failure to comply with this policy may result in disciplinary action, up to and including termination of employment."
            ]
        },
        {
            heading: "Confidentiality",
            paragraphs: [
                "[Your Company] is committed to maintaining the confidentiality of all disclosures related to personal relationships in the workplace. Information provided by employees will be handled with the utmost care and will not be disclosed to unauthorized individuals.",
                "HR personnel and managers involved in handling disclosures or addressing issues related to personal relationships must maintain confidentiality and use the information solely for the purpose of evaluating and managing potential conflicts of interest.",
                "Employees who have concerns about the confidentiality of their disclosure or any related information should contact the HR department for assistance and clarification."
            ]
        },
        {
            heading: "Roles and Responsibilities",
            paragraphs: [
                "Employees: Employees are responsible for disclosing personal relationships that may create conflicts of interest or impact the workplace. They must maintain professionalism, separate personal and professional roles, and comply with this policy.",
                "Supervisors and Managers: Supervisors and managers are responsible for fostering a professional work environment, reviewing disclosures of personal relationships, and working with HR to address any potential conflicts of interest or issues arising from such relationships.",
                "Human Resources: The HR department is responsible for developing and maintaining this policy, providing training and support to employees and supervisors, and ensuring compliance with applicable laws. HR will also handle disputes or grievances related to personal relationships and ensure appropriate measures are taken to prevent conflicts of interest."
            ]
        },
        {
            heading: "Handling Complaints and Violations",
            paragraphs: [
                "[Your Company] is committed to addressing complaints and violations related to personal relationships in the workplace promptly and fairly. Employees who have concerns or issues regarding personal relationships should first discuss the matter with their immediate supervisor.",
                "If the issue is not resolved at the supervisory level, employees may escalate their concern to the HR department. HR will investigate the matter, review relevant records, and work with the employee and management to reach a resolution.",
                "Employees will not face retaliation or discrimination for raising concerns or filing complaints related to personal relationships. [Your Company] encourages open communication and will take all necessary steps to address and resolve any issues in accordance with this policy."
            ]
        },
        {
            heading: "Training and Communication",
            paragraphs: [
                "[Your Company] will provide training to all employees, supervisors, and HR personnel on the personal relationships policy and procedures. This training will ensure that everyone understands their roles and responsibilities and is aware of the guidelines for managing personal relationships in the workplace.",
                "The personal relationships policy will be communicated to all employees through the employee handbook, company intranet, and other appropriate channels. Regular updates and reminders will be provided to ensure that employees remain informed about the policy and any changes that may occur.",
                "Employees are encouraged to seek clarification and ask questions about the personal relationships policy and procedures. The HR department is available to provide support and guidance to ensure that employees fully understand their rights and responsibilities."
            ]
        },
        {
            heading: "Monitoring and Review",
            paragraphs: [
                "[Your Company] will regularly monitor the implementation and effectiveness of the personal relationships policy. This includes reviewing disclosures, conducting audits, and collecting feedback from employees and supervisors.",
                "The HR department will conduct an annual review of the policy to ensure that it remains relevant and compliant with current labor laws. Any necessary revisions will be made and communicated to employees in a timely manner.",
                "Employees and supervisors are encouraged to provide feedback on the personal relationships policy and suggest improvements. This feedback will be considered during the review process to enhance the effectiveness and fairness of the policy."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees are required to sign or electronically acknowledge that they have read, understood, and agreed to comply with this Employee Personal Relationships in the Workplace Policy. This acknowledgment will be kept on record by the HR department and will serve as confirmation of the employee's understanding of their rights and responsibilities regarding personal relationships in the workplace."
        }
    ]
};


export const EmployeePTOAndVacationRequestPolicyTemplate = {
    title: "Employee PTO and Vacation Request Policy",
    policyBlurb: "This policy outlines the guidelines and procedures for requesting Paid Time Off (PTO) and vacation at [Your Company].",
    preRead: "Use this template to develop a policy that ensures a fair and transparent process for managing PTO and vacation requests, balancing employee needs with business requirements.",
    sections: [
        {
            heading: "Table of Contents",
            paragraphs: [
                "Introduction",
                "Purpose",
                "Scope",
                "Definitions",
                "PTO Accrual and Eligibility",
                "Request Procedures",
                "Approval Process",
                "Blackout Dates and Peak Times",
                "Unused PTO and Carryover",
                "Coordination with Other Leave Policies",
                "Responsibilities",
                "Impact on Payroll",
                "Communication and Training",
                "Monitoring and Review",
                "Acknowledgment"
            ]
        },
        {
            heading: "Introduction",
            paragraph: "At [Your Company], we recognize the importance of providing our employees with opportunities to rest, recharge, and attend to personal matters. This Employee PTO and Vacation Request Policy outlines the guidelines for requesting and using Paid Time Off (PTO) and vacation. Our goal is to create a fair and transparent process that meets the needs of both our employees and the organization, ensuring that business operations continue smoothly while employees take the time they need."
        },
        {
            heading: "Purpose",
            paragraph: "The purpose of this policy is to establish clear guidelines and procedures for requesting and managing PTO and vacation. By providing a structured process, we aim to ensure fairness, transparency, and consistency in handling time-off requests. This policy helps employees understand how to request PTO and vacation, the criteria for approval, and the impact on their pay and benefits. Additionally, it assists managers in planning and maintaining adequate staffing levels to support business operations."
        },
        {
            heading: "Scope",
            paragraphs: [
                "This policy applies to all employees of [Your Company], including full-time, part-time, temporary staff, and interns. It covers all aspects of PTO and vacation, including accrual, eligibility, request procedures, approval process, and usage.",
                "The policy ensures that employees have access to time off for rest, personal matters, and vacations while maintaining the necessary staffing levels to support business needs. It applies to all departments and locations within [Your Company]."
            ]
        },
        {
            heading: "Definitions",
            paragraphs: [
                "Paid Time Off (PTO): A flexible time-off policy that provides employees with a pool of days that can be used for various types of leave, including vacation, personal days, and sick leave.",
                "Vacation: A specific type of PTO that is used for personal leisure and travel.",
                "Blackout Dates: Specific dates or periods during which PTO and vacation requests may be restricted or denied due to peak business operations or critical project deadlines.",
                "Carryover: The process of transferring unused PTO from one calendar year to the next, subject to certain limits and conditions."
            ]
        },
        {
            heading: "PTO Accrual and Eligibility",
            paragraphs: [
                "PTO accrual begins on the employee's first day of work and is calculated based on the employee's length of service and employment status (full-time or part-time). Full-time employees accrue PTO at a higher rate than part-time employees.",
                "Employees become eligible to use their accrued PTO after completing a specified probationary period, typically 90 days from their start date. The exact accrual rates and eligibility criteria are outlined in the employee handbook and may vary based on role, tenure, and other factors.",
                "The maximum amount of PTO that can be accrued is capped at a certain number of hours or days, as specified in the employee handbook. Once the maximum accrual limit is reached, employees will stop accruing additional PTO until they use some of their existing balance."
            ]
        },
        {
            heading: "Request Procedures",
            paragraphs: [
                "Employees are required to submit their PTO and vacation requests through the designated HR system or application. Requests should be submitted at least two weeks in advance for planned vacations and as soon as possible for unplanned personal or sick leave.",
                "When submitting a request, employees must specify the dates and duration of the requested time off. For vacation requests, employees are encouraged to provide additional information, such as travel plans or special considerations, to assist in the approval process.",
                "In cases where multiple employees request time off for the same period, approval will be based on a first-come, first-served basis, and the business needs of the department. Employees are encouraged to plan and coordinate their time off with their team to minimize disruptions to operations."
            ]
        },
        {
            heading: "Approval Process",
            paragraphs: [
                "Once a PTO or vacation request is submitted, it will be reviewed by the employee's immediate supervisor or manager. The supervisor will consider factors such as departmental staffing levels, workload, and the timing of the request before making a decision.",
                "Supervisors are encouraged to communicate their decision within five business days of receiving the request. Approved requests will be entered into the HR system, and the employee will receive a confirmation email or notification.",
                "In cases where a request is denied, the supervisor will provide a clear explanation for the denial and, if possible, suggest alternative dates or options. Employees have the right to appeal the decision to the HR department if they believe their request was unfairly denied."
            ]
        },
        {
            heading: "Blackout Dates and Peak Times",
            paragraphs: [
                "[Your Company] may designate certain periods as blackout dates during which PTO and vacation requests will be limited or restricted due to peak business operations, critical project deadlines, or other essential business needs.",
                "Employees will be informed of blackout dates well in advance, typically at the beginning of the calendar year or as soon as they are determined. Blackout dates will also be communicated through the employee handbook, company intranet, or other appropriate channels.",
                "Exceptions to blackout date restrictions may be granted on a case-by-case basis for extraordinary circumstances, such as medical emergencies or family events. Such exceptions must be approved by the HR department in consultation with the employee's supervisor."
            ]
        },
        {
            heading: "Unused PTO and Carryover",
            paragraphs: [
                "At the end of each calendar year, employees may carry over a certain amount of unused PTO to the next year, subject to the limits specified in the employee handbook. Any PTO beyond the carryover limit will be forfeited or, in some cases, converted to cash at the end of the year.",
                "Employees are encouraged to use their PTO throughout the year to avoid reaching the maximum accrual limit and to ensure they benefit from regular time off for rest and personal matters.",
                "In the event of termination of employment, employees will be compensated for any unused accrued PTO, provided they have met the notice requirements specified in the employee handbook. PTO will be paid out at the employee's current rate of pay."
            ]
        },
        {
            heading: "Coordination with Other Leave Policies",
            paragraphs: [
                "PTO and vacation requests will be coordinated with other leave policies, such as medical leave, family leave, and bereavement leave, to ensure that employees receive the appropriate time off for their needs while maintaining compliance with applicable laws and regulations.",
                "Employees on other types of leave, such as short-term disability or family medical leave, may still accrue PTO according to company policy. However, they may be required to use their accrued PTO concurrently with other leave types, depending on the circumstances and legal requirements.",
                "The HR department will provide guidance and support to employees and supervisors in coordinating PTO with other leave policies to ensure a smooth and compliant process."
            ]
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Employees: Employees are responsible for planning and submitting their PTO and vacation requests in a timely manner, providing necessary information to support their requests, and ensuring they understand the policy and procedures.",
                "Supervisors and Managers: Supervisors and managers are responsible for reviewing and approving PTO and vacation requests, considering business needs, and communicating decisions to employees. They must also ensure that their teams are adequately staffed during peak times and blackout dates.",
                "Human Resources: The HR department is responsible for maintaining and updating the PTO and vacation request policy, providing training and support to employees and supervisors, and ensuring compliance with applicable laws and regulations."
            ]
        },
        {
            heading: "Impact on Payroll",
            paragraphs: [
                "PTO and vacation time will be tracked and recorded in the HR system, ensuring accurate payroll processing and adherence to company policy. Employees will receive their regular pay for approved PTO and vacation days, provided they have sufficient accrued PTO.",
                "In cases where an employee has used all their accrued PTO, any additional time off may be unpaid or result in negative PTO balances, subject to company policy. Employees should plan their time off carefully to avoid unpaid leave situations.",
                "The payroll department will work closely with HR to ensure that PTO balances are accurately tracked, reported, and reflected in employee paychecks. Any discrepancies should be reported and addressed promptly."
            ]
        },
        {
            heading: "Communication and Training",
            paragraphs: [
                "[Your Company] will provide regular training to employees and supervisors on the PTO and vacation request policy and procedures. Training sessions will cover key aspects of the policy, such as accrual rates, request procedures, approval processes, and coordination with other leave types.",
                "The PTO and vacation request policy will be communicated to all employees through the employee handbook, company intranet, and other appropriate channels. Updates and reminders will be provided to ensure employees remain informed about the policy and any changes that may occur.",
                "Supervisors and managers will receive additional training on their roles and responsibilities in managing PTO and vacation requests, including handling conflicts, ensuring equitable treatment, and maintaining departmental productivity.",
                "Employees are encouraged to participate actively in training sessions to familiarize themselves with the policy, ask questions, and seek clarification on any aspects they may not fully understand."
            ]
        },
        {
            heading: "Monitoring and Review",
            paragraphs: [
                "The HR department will monitor the implementation of the PTO and vacation request policy to ensure compliance with established procedures and fairness in decision-making.",
                "Regular reviews will be conducted to assess the effectiveness of the policy in meeting employee needs and business objectives. Feedback from employees and supervisors will be solicited to identify areas for improvement and refinement.",
                "Data on PTO usage, approval rates, and employee satisfaction will be analyzed to evaluate trends and make data-driven decisions for policy adjustments.",
                "Supervisors and managers will be responsible for providing feedback on their experiences with the policy, highlighting challenges, successes, and suggestions for improvement during performance evaluations and team meetings."
            ]
        },
        {
            heading: "Acknowledgment",
            paragraph: "All employees must acknowledge receipt and understanding of the PTO and vacation request policy. By signing or electronically acknowledging the policy, employees confirm their commitment to comply with its provisions, follow established procedures, and contribute to a positive work environment."
        }
        ]
    }


   export const EmployeeRecordRetentionAndManagementPolicy = {
        title: "Employee Record Retention and Management Policy",
        policyBlurb: "This policy outlines the guidelines and procedures for the retention and management of employee records at [Your Company], ensuring compliance with legal requirements and efficient record-keeping practices.",
        preRead: "Use this template to establish a systematic approach to managing employee records, from creation to disposal, while ensuring confidentiality, accessibility, and compliance with relevant laws and regulations.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Record Creation and Maintenance",
                    "Types of Employee Records",
                    "Record Storage and Security",
                    "Access and Confidentiality",
                    "Record Retention Periods",
                    "Record Disposal and Destruction",
                    "Legal and Compliance Considerations",
                    "Monitoring and Review",
                    "Training and Awareness",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The management of employee records is crucial for [Your Company] to ensure compliance with legal requirements, support business operations, and protect employee rights. This policy establishes guidelines for the creation, maintenance, retention, and disposal of employee records."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to define the procedures for managing employee records effectively throughout their lifecycle, from initial creation to final disposition. It aims to ensure that records are accurate, accessible, secure, and retained for the appropriate period while adhering to legal and regulatory requirements."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employee records created and maintained by [Your Company], including those in electronic and physical formats.",
                    "It covers records related to recruitment, employment history, compensation, benefits, performance evaluations, disciplinary actions, and any other records defined under applicable laws."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Employee Records: Any documentation or information related to an individual employee's employment with [Your Company], including personal data, employment history, performance evaluations, disciplinary records, and other relevant information.",
                    "Record Retention: The practice of keeping and maintaining employee records for a specific period to meet operational needs, statutory requirements, and potential legal obligations.",
                    "Record Disposal: The secure destruction or deletion of employee records that are no longer required to be retained, ensuring compliance with privacy and data protection laws."
                ]
            },
            {
                heading: "Record Creation and Maintenance",
                paragraphs: [
                    "Employee records will be created accurately, promptly, and in compliance with applicable laws and regulations.",
                    "Records will be updated regularly to reflect changes in employment status, job responsibilities, compensation, and other relevant information.",
                    "Supervisors and HR personnel are responsible for ensuring the accuracy and completeness of employee records under their supervision."
                ]
            },
            {
                heading: "Types of Employee Records",
                paragraphs: [
                    "Employment Contracts and Agreements",
                    "Personal Information Forms",
                    "Performance Evaluations and Appraisals",
                    "Attendance and Leave Records",
                    "Payroll and Compensation Records",
                    "Benefits Enrollment and Participation",
                    "Training and Development History",
                    "Disciplinary and Grievance Records",
                    "Termination and Exit Interviews"
                ]
            },
            {
                heading: "Record Storage and Security",
                paragraphs: [
                    "Employee records will be stored securely in both physical and electronic formats.",
                    "Access to records will be restricted to authorized personnel only, based on their job responsibilities and the principle of least privilege.",
                    "Physical records will be stored in locked cabinets or secure areas to prevent unauthorized access, theft, or damage.",
                    "Electronic records will be protected through encryption, access controls, and regular cybersecurity measures to safeguard against data breaches."
                ]
            },
            {
                heading: "Access and Confidentiality",
                paragraphs: [
                    "Access to employee records will be granted only to authorized individuals who have a legitimate need to know for business purposes.",
                    "Confidential information, such as Social Security numbers, medical records, and financial data, will be handled with the utmost confidentiality and in compliance with privacy laws.",
                    "Employees have the right to request access to their own records and to challenge the accuracy of information contained therein, in accordance with applicable laws."
                ]
            },
            {
                heading: "Record Retention Periods",
                paragraphs: [
                    "Retention periods for employee records will be established based on legal requirements, operational needs, and potential litigation or audit considerations.",
                    "A retention schedule will be maintained to specify the period for which each type of record should be retained before disposal.",
                    "Records will be retained for the minimum period required by law or regulation, after which they will be securely disposed of in accordance with the record disposal procedures."
                ]
            },
            {
                heading: "Record Disposal and Destruction",
                paragraphs: [
                    "Records that have met their retention period or are no longer needed for operational purposes will be securely disposed of or destroyed.",
                    "Physical records will be shredded or incinerated to prevent unauthorized access or retrieval.",
                    "Electronic records will be permanently deleted or rendered unrecoverable using secure data destruction methods."
                ]
            },
            {
                heading: "Legal and Compliance Considerations",
                paragraphs: [
                    "Employee record management practices will comply with all applicable federal, state, and local laws regarding privacy, data protection, and record retention.",
                    "HR personnel will stay informed of changes in relevant laws and regulations to ensure ongoing compliance and update policies and procedures as necessary.",
                    "Records may be subject to legal holds or preservation orders in the event of litigation, regulatory investigation, or other legal proceedings."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The HR department will periodically audit employee record management practices to verify compliance with this policy and applicable laws.",
                    "Internal audits will assess the accuracy, completeness, security, and accessibility of employee records.",
                    "Findings from audits will be used to implement improvements to record management procedures and training programs."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Employees involved in the creation, maintenance, or handling of employee records will receive training on record management principles, confidentiality requirements, and compliance obligations.",
                    "Training sessions will be conducted regularly and will cover updates to policies, procedures, and legal requirements related to record management.",
                    "Employees will be encouraged to raise concerns or seek clarification on record management practices during training sessions or through designated HR channels."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees and relevant stakeholders must acknowledge receipt and understanding of this Employee Record Retention and Management Policy. By signing or electronically acknowledging this policy, individuals confirm their commitment to comply with its provisions and support [Your Company]'s efforts to maintain accurate, secure, and compliant employee records."
            }
        ]
    }


    export const EmployeeRemoteWorkPolicyTemplate = {
        title: "Employee Remote Work Policy Template",
        policyBlurb: "This policy outlines the guidelines and procedures for remote work arrangements at [Your Company], ensuring productivity, communication, and security while working remotely.",
        preRead: "Use this template to establish a structured approach to remote work that promotes flexibility, maintains operational efficiency, and addresses legal and security considerations.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility and Approval",
                    "Remote Work Arrangements",
                    "Work Hours and Availability",
                    "Work Environment and Equipment",
                    "Communication and Collaboration",
                    "Data Security and Confidentiality",
                    "Performance and Productivity",
                    "Health and Safety",
                    "Training and Support",
                    "Monitoring and Compliance",
                    "Termination of Remote Work Arrangements",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The landscape of work is evolving, and remote work has become integral to [Your Company]'s operational strategy. This policy establishes guidelines to facilitate remote work arrangements while maintaining productivity, communication, and security."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to provide a framework for employees and managers to establish and manage remote work arrangements effectively. It aims to support work-life balance, enhance employee satisfaction, and ensure business continuity."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Your Company] who may be eligible and approved to work remotely, either on a full-time or part-time basis.",
                    "It covers remote work conducted from any location outside the company’s physical premises, including home offices, co-working spaces, or other remote locations."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Remote Work: Work performed by an employee at a location other than the company’s office or designated workplace, typically from home or another remote location.",
                    "Telecommuting: A specific form of remote work that involves regular work from home or another remote location using information technology and telecommunications."
                ]
            },
            {
                heading: "Eligibility and Approval",
                paragraphs: [
                    "Remote work eligibility will be determined based on job roles, operational requirements, and employee performance.",
                    "Employees interested in remote work arrangements must submit a formal request to their supervisor or HR department.",
                    "Approval of remote work requests will consider business needs, job responsibilities, performance history, and the employee's ability to meet remote work requirements."
                ]
            },
            {
                heading: "Remote Work Arrangements",
                paragraphs: [
                    "Remote work may be approved on a temporary or permanent basis, depending on business needs and the nature of the employee's role.",
                    "Employees approved for remote work must adhere to the terms and conditions outlined in their remote work agreement.",
                    "Changes to remote work arrangements, such as location or schedule adjustments, require prior approval from the supervisor or HR department."
                ]
            },
            {
                heading: "Work Hours and Availability",
                paragraphs: [
                    "Employees are expected to adhere to their regular work hours unless otherwise agreed upon with their supervisor.",
                    "Remote workers must be accessible during agreed-upon work hours and responsive to communications from colleagues, supervisors, or clients.",
                    "Flexible work schedules may be permitted based on business needs and approval from supervisors."
                ]
            },
            {
                heading: "Work Environment and Equipment",
                paragraphs: [
                    "Employees are responsible for maintaining a safe, ergonomic, and productive home office or remote work environment.",
                    "The company may provide necessary equipment, such as laptops, monitors, and ergonomic furniture, based on business needs and budgetary considerations.",
                    "Remote workers must ensure the security and confidentiality of company-provided equipment and data."
                ]
            },
            {
                heading: "Communication and Collaboration",
                paragraphs: [
                    "Remote workers must use company-approved communication tools and platforms for business-related communications.",
                    "Regular check-ins with supervisors and team members are encouraged to maintain collaboration, alignment, and team spirit.",
                    "Virtual meetings and conference calls should be scheduled as needed to facilitate communication and teamwork."
                ]
            },
            {
                heading: "Data Security and Confidentiality",
                paragraphs: [
                    "Remote workers must adhere to the company’s data security policies and procedures to protect confidential and sensitive information.",
                    "Employees must use secure networks and encryption protocols when accessing company systems or handling sensitive data.",
                    "Physical and electronic documents containing confidential information must be stored securely and accessed only by authorized personnel."
                ]
            },
            {
                heading: "Performance and Productivity",
                paragraphs: [
                    "Performance expectations and deliverables for remote workers will be aligned with those of in-office employees.",
                    "Regular performance reviews and goal-setting sessions will be conducted to assess remote workers’ productivity and job performance.",
                    "Managers will provide ongoing feedback and support to remote workers to ensure continuous improvement and achievement of performance objectives."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "Remote workers are responsible for maintaining a safe and healthy work environment in compliance with health and safety regulations.",
                    "Employees should take regular breaks, stretch, and maintain good posture to prevent ergonomic-related injuries.",
                    "The company may provide resources or guidelines to promote mental and physical well-being for remote workers."
                ]
            },
            {
                heading: "Training and Support",
                paragraphs: [
                    "Training sessions on remote work best practices, cybersecurity awareness, and communication tools will be provided to remote workers.",
                    "Support resources, such as IT helpdesk services and remote technical assistance, will be available to address any issues or challenges encountered during remote work.",
                    "Managers and supervisors will receive training on effectively managing remote teams and supporting their professional development."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "The company reserves the right to monitor remote work activities to ensure compliance with company policies and performance expectations.",
                    "Monitoring may include time-tracking systems, productivity tools, and periodic reviews of work outputs and communications.",
                    "Any concerns regarding remote work performance or compliance should be addressed promptly through HR or management channels."
                ]
            },
            {
                heading: "Termination of Remote Work Arrangements",
                paragraphs: [
                    "Remote work arrangements may be terminated or modified at any time based on business needs, operational changes, or performance considerations.",
                    "Employees will be notified in advance of any changes to their remote work status or arrangement.",
                    "Reassignment to in-office work may be considered if remote work is no longer feasible or aligned with business objectives."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as needed to reflect changes in business practices, technology, or regulatory requirements.",
                    "Employees will be notified of any revisions to the remote work policy and provided with updated guidelines or training materials as necessary."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees approved for remote work must acknowledge receipt and understanding of this Employee Remote Work Policy. By signing or electronically acknowledging this policy, employees confirm their commitment to comply with its provisions and maintain the integrity of remote work practices at [Your Company]."
            }
        ]
    };
    
    export const EmployeeRetaliationPreventionPolicyTemplate = {
        title: "Employee Retaliation Prevention Policy Template",
        policyBlurb: "This policy establishes guidelines to prevent retaliation against employees who report complaints, concerns, or violations of company policies, fostering a safe and respectful workplace environment.",
        preRead: "Use this template to develop a policy that ensures employees are protected from retaliation for engaging in protected activities, such as reporting harassment, discrimination, unethical behavior, or other workplace concerns.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Prohibited Retaliation",
                    "Reporting Retaliation",
                    "Investigation and Resolution",
                    "Confidentiality",
                    "Non-Retaliation Assurance",
                    "Consequences of Retaliation",
                    "Training and Education",
                    "Monitoring and Compliance",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Retaliation Prevention Policy underscores [Your Company]'s commitment to maintaining a workplace free from retaliation. This policy aims to protect employees who report concerns, violations, or illegal activities from retaliation, ensuring a fair and supportive work environment."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to prohibit and prevent retaliation against employees who engage in protected activities, such as reporting harassment, discrimination, safety concerns, ethical violations, or other workplace issues."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, consultants, vendors, and visitors of [Your Company].",
                    "It covers all forms of retaliation, including but not limited to adverse actions, harassment, discrimination, or intimidation in response to protected activities."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Retaliation: Any adverse action or treatment taken against an employee in response to their engagement in protected activities, such as reporting misconduct, participating in investigations, or exercising legal rights.",
                    "Protected Activities: Actions by an employee that are legally safeguarded, including reporting harassment, discrimination, safety violations, illegal activities, or other concerns."
                ]
            },
            {
                heading: "Prohibited Retaliation",
                paragraphs: [
                    "Retaliation against employees who engage in protected activities is strictly prohibited.",
                    "Examples of prohibited retaliation include termination, demotion, salary reduction, denial of benefits, unfavorable assignments, harassment, or threats."
                ]
            },
            {
                heading: "Reporting Retaliation",
                paragraphs: [
                    "Employees who believe they have experienced or witnessed retaliation are encouraged to report incidents promptly.",
                    "Reports should be made to the HR department, a supervisor, or through designated reporting channels specified in the company's whistleblower policy."
                ]
            },
            {
                heading: "Investigation and Resolution",
                paragraphs: [
                    "All reports of retaliation will be promptly and thoroughly investigated by the HR department or designated investigators.",
                    "Investigations will be conducted impartially, respecting confidentiality and due process rights of all parties involved."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "Information related to retaliation reports and investigations will be handled confidentially to the extent possible and permitted by law.",
                    "Confidentiality will be maintained to protect the privacy and reputation of individuals involved, while ensuring transparency and accountability in the investigation process."
                ]
            },
            {
                heading: "Non-Retaliation Assurance",
                paragraphs: [
                    "The company assures employees that they will not suffer retaliation for reporting concerns, participating in investigations, or engaging in protected activities.",
                    "Managers and supervisors are prohibited from engaging in or condoning any form of retaliation and are expected to uphold this policy."
                ]
            },
            {
                heading: "Consequences of Retaliation",
                paragraphs: [
                    "Employees found responsible for retaliation may be subject to disciplinary action, up to and including termination of employment.",
                    "Legal remedies and sanctions may also apply depending on the severity and nature of the retaliation."
                ]
            },
            {
                heading: "Training and Education",
                paragraphs: [
                    "Training programs on retaliation prevention and employee rights will be provided to all employees and supervisors.",
                    "Education initiatives will focus on recognizing, preventing, and addressing retaliation in the workplace."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "The HR department will monitor compliance with this policy and periodically review retaliation reports and investigations.",
                    "Regular assessments and audits will be conducted to ensure the effectiveness and adherence to retaliation prevention measures."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, or best practices.",
                    "Employees will be notified of any revisions to the policy and provided with updated guidelines or training materials."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agree to comply with the Employee Retaliation Prevention Policy. By doing so, employees reaffirm their commitment to maintaining a workplace culture free from retaliation."
            }
        ]
    };
    
    export const EmployeeReturnToWorkPolicyTemplate = {
        title: "Employee Return-to-Work Policy Template",
        policyBlurb: "This policy outlines the procedures and guidelines for employees returning to work after a leave of absence, ensuring a smooth transition and supportive environment.",
        preRead: "Use this template to develop a comprehensive policy that facilitates employees' successful return to work following a leave of absence due to illness, injury, family reasons, or other circumstances.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility and Notification",
                    "Return-to-Work Process",
                    "Accommodations and Modifications",
                    "Health and Safety",
                    "Support and Resources",
                    "Communication and Coordination",
                    "Monitoring and Follow-Up",
                    "Training and Awareness",
                    "Confidentiality",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Return-to-Work Policy is designed to support employees in resuming their duties effectively after a period of absence. It outlines the procedures and resources available to facilitate a smooth transition back to the workplace."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to ensure a structured and supportive process for employees returning to work following a leave of absence. It aims to promote employee well-being, productivity, and integration back into the workforce."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Your Company] who are returning to work after a leave of absence, including medical leave, parental leave, disability leave, or other approved leaves.",
                    "It covers all aspects of the return-to-work process, including eligibility criteria, notification procedures, accommodations, health and safety considerations, and ongoing support."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Return-to-Work (RTW): The process of an employee returning to their job duties after a period of absence.",
                    "Accommodations: Adjustments made to the work environment or job duties to support the successful return of an employee."
                ]
            },
            {
                heading: "Eligibility and Notification",
                paragraphs: [
                    "Employees eligible for return-to-work include those who have been on approved leave due to medical reasons, family obligations, or other authorized reasons.",
                    "Employees must notify their supervisor or HR department of their intent to return to work within a specified timeframe as outlined in company policies."
                ]
            },
            {
                heading: "Return-to-Work Process",
                paragraphs: [
                    "The return-to-work process begins with an initial assessment to determine the employee's readiness to resume duties.",
                    "A structured plan will be developed in collaboration with the employee, supervisor, and HR to outline expectations, accommodations, and timelines."
                ]
            },
            {
                heading: "Accommodations and Modifications",
                paragraphs: [
                    "Reasonable accommodations will be provided to support employees in performing essential job functions.",
                    "This may include adjustments to work schedules, job duties, physical workspace, or provision of assistive devices."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "The health and safety of returning employees will be prioritized through risk assessments and adherence to relevant health protocols.",
                    "Employees are encouraged to report any health concerns or limitations that may affect their ability to perform job duties safely."
                ]
            },
            {
                heading: "Support and Resources",
                paragraphs: [
                    "Employees will have access to resources such as employee assistance programs (EAPs), counseling services, and occupational health services.",
                    "Managers and HR will provide ongoing support and guidance to facilitate a successful transition back to work."
                ]
            },
            {
                heading: "Communication and Coordination",
                paragraphs: [
                    "Effective communication between the returning employee, their supervisor, HR, and any relevant stakeholders is essential throughout the return-to-work process.",
                    "Regular meetings and check-ins will be scheduled to review progress, address concerns, and adjust accommodations as needed."
                ]
            },
            {
                heading: "Monitoring and Follow-Up",
                paragraphs: [
                    "Progress and adjustments made during the return-to-work process will be monitored and documented.",
                    "Follow-up meetings will be conducted to evaluate the effectiveness of accommodations and address any ongoing challenges."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Managers and supervisors will receive training on supporting employees returning to work after a leave of absence.",
                    "Employees will be provided with information on their rights, responsibilities, and available resources related to return-to-work."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All medical and personal information disclosed during the return-to-work process will be treated confidentially and in compliance with privacy laws.",
                    "Access to employee health records and related documentation will be restricted to authorized personnel only."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in legislation, organizational needs, or best practices.",
                    "Feedback from employees and stakeholders will be considered to improve the effectiveness and relevance of the return-to-work process."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees returning to work after a leave of absence must acknowledge their understanding and compliance with this policy. By doing so, employees affirm their commitment to a successful return-to-work experience."
            }
        ]
    };
    
    export const EmployeeSabbaticalLeavePolicyTemplate = {
        title: "Employee Sabbatical Leave Policy Template",
        policyBlurb: "This policy outlines the guidelines and procedures for employees taking a sabbatical leave, providing them with an opportunity for personal and professional growth.",
        preRead: "Use this template to develop a comprehensive sabbatical leave policy that supports the long-term career development and well-being of employees.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility Criteria",
                    "Application Process",
                    "Approval Process",
                    "Duration and Compensation",
                    "Return-to-Work Process",
                    "Continuity of Benefits",
                    "Use of Sabbatical Leave",
                    "Monitoring and Reporting",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Sabbatical Leave Policy is designed to provide eligible employees with the opportunity to take an extended leave from work for personal or professional development. This policy aims to enhance employee satisfaction, retention, and growth by allowing them time to pursue educational opportunities, engage in research, volunteer, or address personal matters."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear guidelines for the administration of sabbatical leave within the organization. It seeks to balance the needs of the organization with the personal and professional development goals of employees, ensuring that the leave is beneficial for both parties."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all eligible employees of [Your Company] who meet the criteria outlined in the eligibility section. It covers the application, approval, and return-to-work processes associated with sabbatical leave.",
                    "The policy does not apply to other types of leave such as vacation, medical leave, or parental leave, which are governed by separate policies."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Sabbatical Leave: A period of extended leave from work granted to an employee for personal or professional development purposes. This leave is typically unpaid and is intended to provide employees with the opportunity to pursue activities that contribute to their long-term career and personal growth.",
                    "Eligible Employee: An employee who meets the criteria for sabbatical leave as defined in the eligibility section of this policy."
                ]
            },
            {
                heading: "Eligibility Criteria",
                paragraphs: [
                    "Employees must have completed a minimum of [X] years of continuous service with [Your Company] to be eligible for sabbatical leave.",
                    "Employees must have a satisfactory performance record, with no significant disciplinary actions in the [X] years preceding the application for sabbatical leave.",
                    "Employees must demonstrate a clear plan for the sabbatical leave that aligns with their personal or professional development goals and the interests of [Your Company]."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees wishing to apply for sabbatical leave must submit a formal application to their immediate supervisor at least [X] months in advance of the desired start date.",
                    "The application should include a detailed plan outlining the purpose of the sabbatical leave, the activities to be undertaken, and the anticipated benefits for both the employee and [Your Company].",
                    "Supervisors will review the application and provide their recommendations to the HR department for further consideration."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "The HR department will evaluate the application based on the eligibility criteria, the employee’s performance record, and the potential impact on the organization.",
                    "A decision will be communicated to the employee within [X] weeks of receiving the application.",
                    "Approval of sabbatical leave is subject to the discretion of [Your Company] and may depend on operational requirements and the availability of resources."
                ]
            },
            {
                heading: "Duration and Compensation",
                paragraphs: [
                    "The duration of sabbatical leave will typically range from [X] months to [X] months, depending on the nature of the activities and the employee’s goals.",
                    "Sabbatical leave is generally unpaid; however, [Your Company] may offer financial support or stipends in certain cases where the leave aligns with organizational goals.",
                    "Employees may continue to accrue certain benefits, such as seniority and retirement contributions, during the sabbatical leave period, subject to applicable laws and company policies."
                ]
            },
            {
                heading: "Return-to-Work Process",
                paragraphs: [
                    "Employees are required to provide a written summary of their sabbatical leave activities and outcomes upon their return to work.",
                    "A meeting will be scheduled with the employee’s supervisor and HR to discuss the reentry process and any support needed to reintegrate the employee into their role.",
                    "Any adjustments to the employee’s duties or responsibilities will be addressed during this meeting."
                ]
            },
            {
                heading: "Continuity of Benefits",
                paragraphs: [
                    "During the sabbatical leave, employees may continue to receive certain benefits such as health insurance, subject to the terms of the company’s benefits plan and any applicable laws.",
                    "Employees should contact the HR department to understand the specific benefits that will continue during the leave period and any required contributions."
                ]
            },
            {
                heading: "Use of Sabbatical Leave",
                paragraphs: [
                    "Sabbatical leave should be used for the purposes outlined in the approved application, such as pursuing educational opportunities, conducting research, engaging in community service, or addressing personal development needs.",
                    "Employees are expected to adhere to the plan submitted in their application and maintain communication with their supervisor and HR as needed."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "The HR department will monitor the implementation of this policy and ensure compliance with its provisions.",
                    "Employees on sabbatical leave may be required to provide periodic updates on their activities and progress, as outlined in their sabbatical plan.",
                    "Regular reports will be submitted to senior management to evaluate the effectiveness and benefits of the sabbatical leave program."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, employee feedback, and best practices.",
                    "Employees will be notified of any significant changes to the policy and provided with an updated copy of the policy document."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees granted sabbatical leave must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this policy. This acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EmployeeShortTermDisabilityPolicyTemplate = {
        title: "Employee Short-Term Disability Policy Template",
        policyBlurb: "This policy outlines the provisions and procedures for short-term disability benefits for employees who are temporarily unable to work due to a medical condition.",
        preRead: "Use this template to develop a comprehensive short-term disability policy that supports employees during periods of temporary disability and ensures they receive necessary benefits.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility Criteria",
                    "Application Process",
                    "Approval Process",
                    "Duration and Benefits",
                    "Return-to-Work Process",
                    "Coordination with Other Benefits",
                    "Use of Short-Term Disability Leave",
                    "Monitoring and Reporting",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Short-Term Disability Policy is designed to provide financial and job security to employees who are temporarily unable to perform their work duties due to a medical condition. This policy outlines the eligibility criteria, application process, benefits, and return-to-work procedures associated with short-term disability leave."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear guidelines for the administration of short-term disability benefits within the organization. It aims to support employees during periods of temporary disability, ensuring they receive the necessary benefits while maintaining their employment status."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all eligible employees of [Your Company] who meet the criteria outlined in the eligibility section. It covers the application, approval, and return-to-work processes associated with short-term disability leave.",
                    "The policy does not apply to long-term disability, workers' compensation, or other types of leave, which are governed by separate policies."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Short-Term Disability: A temporary period during which an employee is unable to perform their job duties due to a medical condition, as certified by a healthcare provider.",
                    "Eligible Employee: An employee who meets the criteria for short-term disability benefits as defined in the eligibility section of this policy."
                ]
            },
            {
                heading: "Eligibility Criteria",
                paragraphs: [
                    "Employees must have completed a minimum of [X] months of continuous service with [Your Company] to be eligible for short-term disability benefits.",
                    "Employees must provide a medical certification from a licensed healthcare provider indicating the nature of the disability and the expected duration of the inability to work.",
                    "Employees must have exhausted any available paid sick leave or other similar benefits before applying for short-term disability benefits."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees wishing to apply for short-term disability benefits must submit a formal application to the HR department as soon as they become aware of the need for leave.",
                    "The application should include a medical certification from a healthcare provider detailing the nature of the disability, the expected duration of the leave, and any work restrictions or accommodations required.",
                    "The HR department will provide the necessary forms and guidance to assist employees in completing the application process."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "The HR department will review the application and medical certification to determine eligibility for short-term disability benefits.",
                    "A decision will be communicated to the employee within [X] business days of receiving the completed application and supporting documentation.",
                    "Approval of short-term disability benefits is subject to the discretion of [Your Company] and may depend on the completeness and accuracy of the submitted information."
                ]
            },
            {
                heading: "Duration and Benefits",
                paragraphs: [
                    "Short-term disability benefits are typically provided for a period ranging from [X] weeks to [X] months, depending on the nature of the disability and the employee's recovery progress.",
                    "During the short-term disability leave, employees may receive a percentage of their regular salary, as specified in the company's benefits plan.",
                    "Employees may continue to accrue certain benefits, such as seniority and retirement contributions, during the short-term disability period, subject to applicable laws and company policies."
                ]
            },
            {
                heading: "Return-to-Work Process",
                paragraphs: [
                    "Employees are required to provide a medical clearance from a healthcare provider indicating their ability to return to work, either with or without accommodations.",
                    "A meeting will be scheduled with the employee's supervisor and HR to discuss the return-to-work process, any necessary accommodations, and any adjustments to the employee's duties or responsibilities.",
                    "Employees will be reintegrated into their roles as smoothly as possible, with support provided to ensure a successful transition back to work."
                ]
            },
            {
                heading: "Coordination with Other Benefits",
                paragraphs: [
                    "Short-term disability benefits may be coordinated with other benefits, such as workers' compensation or long-term disability, to ensure employees receive the maximum support available.",
                    "Employees should inform the HR department of any other benefits they are receiving to avoid duplication of benefits and ensure compliance with applicable laws and policies."
                ]
            },
            {
                heading: "Use of Short-Term Disability Leave",
                paragraphs: [
                    "Short-term disability leave should be used for the purposes outlined in the approved application, such as recovery from surgery, treatment for a medical condition, or any other health-related reasons certified by a healthcare provider.",
                    "Employees are expected to adhere to the treatment plan prescribed by their healthcare provider and maintain communication with their supervisor and HR as needed."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "The HR department will monitor the implementation of this policy and ensure compliance with its provisions.",
                    "Employees on short-term disability leave may be required to provide periodic updates on their recovery progress and expected return-to-work date.",
                    "Regular reports will be submitted to senior management to evaluate the effectiveness and benefits of the short-term disability program."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, employee feedback, and best practices.",
                    "Employees will be notified of any significant changes to the policy and provided with an updated copy of the policy document."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees granted short-term disability benefits must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this policy. This acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EmployeeSocialMediaConductPolicyTemplate = {
        title: "Employee Social Media Conduct Policy Template",
        policyBlurb: "This policy outlines the expectations and guidelines for employees' use of social media, both during and outside of work hours.",
        preRead: "Use this template to develop a comprehensive social media conduct policy that ensures responsible use of social media by employees, protects the company's reputation, and maintains a respectful and professional online presence.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "General Guidelines",
                    "Professional Use of Social Media",
                    "Personal Use of Social Media",
                    "Confidentiality and Privacy",
                    "Respectful Conduct",
                    "Use of Company Resources",
                    "Monitoring and Compliance",
                    "Consequences of Policy Violations",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Social Media Conduct Policy is designed to provide clear guidelines for employees on the appropriate use of social media platforms. This policy aims to protect the company’s reputation, maintain a professional online presence, and ensure that employees use social media responsibly."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish expectations for employees' behavior on social media, whether they are using it for professional purposes or personal reasons. It aims to ensure that employees' use of social media does not negatively impact the company, its employees, or its stakeholders."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, and affiliates of [Your Company] who use social media, regardless of whether they are using it during work hours or on their own time.",
                    "It covers all social media platforms, including but not limited to Facebook, Twitter, LinkedIn, Instagram, YouTube, and any other online public forums."
                ]
            },
            {
                heading: "General Guidelines",
                paragraphs: [
                    "Employees should always represent the company in a professional and respectful manner on social media.",
                    "Employees must adhere to the company's code of conduct, confidentiality agreements, and other relevant policies when using social media."
                ]
            },
            {
                heading: "Professional Use of Social Media",
                paragraphs: [
                    "Employees authorized to use social media on behalf of the company must ensure that all content is accurate, professional, and aligned with the company's values and branding.",
                    "Official company social media accounts should be managed by designated employees who have received appropriate training and authorization."
                ]
            },
            {
                heading: "Personal Use of Social Media",
                paragraphs: [
                    "Employees must be mindful that their personal use of social media can impact the company's reputation. They should avoid posting content that could be considered offensive, inappropriate, or harmful to the company.",
                    "When discussing work-related matters on personal social media accounts, employees must clearly state that their views are their own and do not represent the company's position."
                ]
            },
            {
                heading: "Confidentiality and Privacy",
                paragraphs: [
                    "Employees must not share confidential or proprietary information about the company, its clients, or its employees on social media.",
                    "Personal data of colleagues, clients, or stakeholders should not be disclosed without their consent."
                ]
            },
            {
                heading: "Respectful Conduct",
                paragraphs: [
                    "Employees must not engage in bullying, harassment, or discriminatory behavior on social media.",
                    "Respectful and constructive communication should be maintained at all times, both in professional and personal interactions."
                ]
            },
            {
                heading: "Use of Company Resources",
                paragraphs: [
                    "Employees should not use company resources, including computers and internet access, for excessive personal social media use during work hours.",
                    "Social media activities should not interfere with work responsibilities or productivity."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "The company reserves the right to monitor employees' use of social media to ensure compliance with this policy.",
                    "Employees should be aware that their social media activities may be subject to review, especially if they are using company resources or discussing work-related matters."
                ]
            },
            {
                heading: "Consequences of Policy Violations",
                paragraphs: [
                    "Violations of this policy may result in disciplinary action, up to and including termination of employment.",
                    "The company reserves the right to take legal action if an employee's social media conduct results in harm to the company's reputation or business interests."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in technology, social media trends, and company practices.",
                    "Employees will be notified of any significant changes to the policy and provided with an updated copy of the policy document."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this Employee Social Media Conduct Policy. This acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EmployeeSubstanceAbusePreventionPolicyTemplate = {
        title: "Employee Substance Abuse Prevention Policy Template",
        policyBlurb: "This policy outlines the guidelines and procedures for preventing substance abuse within the workplace, ensuring a safe and productive environment for all employees.",
        preRead: "Use this template to develop a comprehensive substance abuse prevention policy that supports the health, safety, and well-being of all employees.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Prohibited Substances and Activities",
                    "Employee Responsibilities",
                    "Employer Responsibilities",
                    "Prevention and Education",
                    "Testing Procedures",
                    "Confidentiality",
                    "Support and Rehabilitation Programs",
                    "Disciplinary Actions",
                    "Monitoring and Reporting",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Substance Abuse Prevention Policy is designed to provide clear guidelines and procedures for preventing substance abuse within the workplace. Substance abuse can severely impact the safety, health, and productivity of employees and the organization. This policy aims to create a safe, healthy, and productive work environment by addressing and mitigating the risks associated with substance abuse."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline the organization's commitment to preventing substance abuse among employees. It aims to establish a zero-tolerance approach towards substance abuse, provide support for employees struggling with substance-related issues, and ensure compliance with applicable laws and regulations."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, and temporary staff at [Your Company]. It covers all work-related activities, including on-site and off-site assignments, business travel, and company-sponsored events.",
                    "The policy also applies to job applicants undergoing the hiring process, ensuring that the organization maintains a drug-free workplace from the outset of employment."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Substance Abuse: The misuse or dependency on illegal drugs, prescription medications, or alcohol that impairs an individual's ability to perform their job duties safely and effectively.",
                    "Prohibited Substances: Includes illegal drugs, controlled substances, prescription medications used without a valid prescription, and alcohol when its use impairs job performance or safety.",
                    "Reasonable Suspicion: A belief based on specific, observable facts and rational inferences that an employee may be under the influence of a prohibited substance."
                ]
            },
            {
                heading: "Prohibited Substances and Activities",
                paragraphs: [
                    "The possession, use, distribution, or sale of illegal drugs and controlled substances on company premises or during work hours is strictly prohibited.",
                    "Employees are prohibited from being under the influence of alcohol or illegal drugs while performing their job duties or while on company property.",
                    "The misuse of prescription medications, including using them without a valid prescription or in a manner inconsistent with prescribed dosages, is also prohibited."
                ]
            },
            {
                heading: "Employee Responsibilities",
                paragraphs: [
                    "Employees are responsible for understanding and complying with this policy. They must report to work in a condition that allows them to perform their duties safely and efficiently.",
                    "Employees are encouraged to seek help if they are struggling with substance abuse issues and to utilize the support resources provided by the organization.",
                    "Employees must report any violations of this policy or suspicions of substance abuse to their supervisor or HR department promptly."
                ]
            },
            {
                heading: "Employer Responsibilities",
                paragraphs: [
                    "The organization is responsible for maintaining a safe and drug-free workplace. This includes providing education and training on substance abuse prevention and the resources available to employees.",
                    "The HR department is responsible for enforcing this policy, including conducting substance abuse testing, managing support programs, and taking disciplinary actions when necessary.",
                    "The organization must ensure that all actions taken under this policy comply with applicable laws and regulations."
                ]
            },
            {
                heading: "Prevention and Education",
                paragraphs: [
                    "The organization will provide regular training and education programs to employees about the dangers of substance abuse and the importance of maintaining a drug-free workplace.",
                    "Information about available support and rehabilitation programs will be communicated to employees, encouraging those in need to seek help.",
                    "Supervisors and managers will receive additional training to help them recognize the signs of substance abuse and understand their role in enforcing this policy."
                ]
            },
            {
                heading: "Testing Procedures",
                paragraphs: [
                    "Substance abuse testing may be conducted under the following circumstances: pre-employment, random testing, reasonable suspicion, post-accident, and return-to-duty testing.",
                    "Testing will be conducted in a manner that respects the privacy and dignity of employees while ensuring the integrity of the process.",
                    "Employees who test positive for prohibited substances will be subject to disciplinary action, which may include mandatory participation in a rehabilitation program, suspension, or termination."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All information related to substance abuse testing, treatment, and results will be kept confidential and shared only with individuals who have a legitimate need to know.",
                    "The organization will comply with all applicable privacy laws and regulations when handling substance abuse-related information.",
                    "Employees' participation in substance abuse programs will not be disclosed without their consent, except as required by law."
                ]
            },
            {
                heading: "Support and Rehabilitation Programs",
                paragraphs: [
                    "The organization is committed to supporting employees who seek help for substance abuse issues. Employees may access counseling, treatment, and rehabilitation programs through the company's Employee Assistance Program (EAP) or other designated resources.",
                    "Participation in a rehabilitation program may be required as a condition of continued employment for employees who test positive for prohibited substances.",
                    "Employees returning from rehabilitation must comply with a return-to-duty agreement, which may include follow-up testing and monitoring."
                ]
            },
            {
                heading: "Disciplinary Actions",
                paragraphs: [
                    "Violations of this policy will result in disciplinary action, up to and including termination of employment. The severity of the disciplinary action will depend on the nature of the violation and the employee's history.",
                    "Disciplinary actions may include mandatory counseling, suspension, probation, or termination, depending on the circumstances.",
                    "The organization reserves the right to take legal action against employees who engage in illegal activities related to substance abuse on company premises."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "The HR department will monitor the implementation of this policy and ensure compliance with its provisions. Regular audits and reviews will be conducted to assess the effectiveness of the policy.",
                    "Employees are encouraged to report any concerns or violations of this policy without fear of retaliation. All reports will be investigated promptly and confidentially.",
                    "The organization will maintain records of substance abuse incidents and testing results to identify trends and areas for improvement."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and best practices.",
                    "Employees will be informed of any significant changes to the policy and provided with updated copies of the policy document.",
                    "Feedback from employees and management will be considered in the review process to ensure the policy remains effective and relevant."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this Employee Substance Abuse Prevention Policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };
    

    export const EmployeeTrainingAndDevelopmentPolicyTemplate = {
        title: "Employee Training and Development Policy",
        policyBlurb: "This policy outlines the guidelines and procedures for providing training and development opportunities to employees, ensuring their growth and the continuous improvement of the organization.",
        preRead: "Use this template to develop a comprehensive training and development policy that supports employee growth, enhances skills, and promotes career advancement.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Training and Development Goals",
                    "Employee Responsibilities",
                    "Employer Responsibilities",
                    "Types of Training and Development Programs",
                    "Training Needs Assessment",
                    "Training Delivery Methods",
                    "Evaluation and Feedback",
                    "Career Development and Succession Planning",
                    "Budget and Resources",
                    "Monitoring and Reporting",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Training and Development Policy is designed to provide clear guidelines and procedures for enhancing the skills, knowledge, and competencies of employees. Training and development are essential for the growth of both the individual employees and the organization as a whole. This policy aims to create a supportive learning environment that encourages continuous professional development and career advancement."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish the organization's commitment to providing training and development opportunities for all employees. It aims to ensure that employees have the necessary skills and knowledge to perform their job duties effectively, support career growth, and contribute to the overall success of the organization. This policy also seeks to promote a culture of continuous learning and improvement."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary, and contract staff at [Your Company].",
                    "It covers all aspects of employee training and development, including initial onboarding, ongoing professional development, leadership training, and succession planning."
                ]
            },
            {
                heading: "Training and Development Goals",
                paragraphs: [
                    "Our company aims to enhance employee skills, knowledge, and competencies through targeted training and development programs.",
                    "We strive to create a culture of continuous learning where employees are encouraged to pursue personal and professional growth opportunities."
                ]
            },
            {
                heading: "Employee Responsibilities",
                paragraphs: [
                    "Employees are responsible for actively participating in training and development programs offered by the organization.",
                    "Employees should identify their own training needs and communicate them to their supervisors or HR department.",
                    "Employees are expected to apply the knowledge and skills gained from training to their job roles and share insights with their colleagues."
                ]
            },
            {
                heading: "Employer Responsibilities",
                paragraphs: [
                    "The organization is responsible for providing access to relevant and high-quality training and development programs.",
                    "Managers and supervisors should support and encourage their team members' participation in training activities.",
                    "The HR department is responsible for coordinating training programs, assessing training needs, and evaluating the effectiveness of training initiatives."
                ]
            },
            {
                heading: "Types of Training and Development Programs",
                paragraphs: [
                    "The organization offers a variety of training and development programs, including but not limited to:",
                    "Onboarding and orientation programs for new hires to familiarize them with company policies, procedures, and culture.",
                    "Job-specific training to enhance employees' skills and knowledge required for their current roles.",
                    "Leadership development programs to prepare employees for future leadership positions.",
                    "Soft skills training, such as communication, teamwork, and problem-solving skills.",
                    "Technical training to keep employees updated with the latest industry trends and technologies."
                ]
            },
            {
                heading: "Training Needs Assessment",
                paragraphs: [
                    "A training needs assessment will be conducted regularly to identify the skills and knowledge gaps within the organization.",
                    "The assessment will involve input from employees, supervisors, and managers to ensure that training programs are aligned with organizational goals and employee development needs.",
                    "The results of the training needs assessment will be used to design and implement targeted training programs."
                ]
            },
            {
                heading: "Training Delivery Methods",
                paragraphs: [
                    "Training and development programs will be delivered using a variety of methods to cater to different learning styles and preferences.",
                    "Delivery methods may include classroom-based training, online courses, workshops, seminars, on-the-job training, mentoring, and coaching.",
                    "The organization will leverage technology to provide flexible and accessible training options, such as e-learning platforms and virtual training sessions."
                ]
            },
            {
                heading: "Evaluation and Feedback",
                paragraphs: [
                    "The effectiveness of training and development programs will be evaluated through feedback from participants and their supervisors.",
                    "Evaluation methods may include surveys, assessments, performance reviews, and follow-up interviews.",
                    "The feedback collected will be used to improve future training programs and ensure they meet the needs of employees and the organization."
                ]
            },
            {
                heading: "Career Development and Succession Planning",
                paragraphs: [
                    "The organization is committed to supporting employees' career development and progression.",
                    "Career development plans will be created for employees, outlining the skills and experiences needed to achieve their career goals.",
                    "Succession planning will be conducted to identify and develop potential future leaders within the organization."
                ]
            },
            {
                heading: "Budget and Resources",
                paragraphs: [
                    "The organization will allocate a dedicated budget for training and development activities.",
                    "Resources, such as training materials, facilities, and external trainers, will be provided to support the effective delivery of training programs.",
                    "Employees may be eligible for financial assistance or reimbursement for external training programs and certifications relevant to their job roles."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "The HR department will monitor the implementation of this policy and the effectiveness of training and development programs.",
                    "Regular reports on training activities, participation rates, and outcomes will be provided to senior management.",
                    "The organization will use this data to make informed decisions about future training and development initiatives."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in organizational goals, industry trends, and best practices.",
                    "Employees will be informed of any significant changes to the policy and provided with updated copies of the policy document.",
                    "Feedback from employees and management will be considered in the review process to ensure the policy remains effective and relevant."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this Employee Training and Development Policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EmployeeTravelAndExpenseReimbursementPolicyTemplate = {
        title: "Employee Travel and Expense Reimbursement Policy",
        policyBlurb: "This policy outlines the guidelines and procedures for reimbursing employees for travel and business-related expenses incurred during the course of their work.",
        preRead: "Use this template to develop a comprehensive travel and expense reimbursement policy that ensures fair and consistent handling of expenses, compliance with tax regulations, and proper documentation.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Travel Authorization",
                    "Expense Categories",
                    "Travel Booking Procedures",
                    "Transportation",
                    "Lodging",
                    "Meals and Entertainment",
                    "Other Business Expenses",
                    "Expense Reporting and Documentation",
                    "Approval Process",
                    "Reimbursement Process",
                    "Compliance and Audit",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Travel and Expense Reimbursement Policy provides clear guidelines for reimbursing employees for travel and other business-related expenses incurred while performing their job duties. The goal of this policy is to ensure that employees are fairly compensated for legitimate expenses, that company resources are used efficiently, and that all expenses comply with applicable tax regulations and company policies."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline the procedures for approving, reporting, and reimbursing travel and business-related expenses. It aims to ensure transparency, accountability, and consistency in handling expenses, as well as to provide employees with clear instructions on what expenses are reimbursable and the documentation required for reimbursement."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, and consultants of [Your Company] who incur travel and business-related expenses in the course of their work.",
                    "It covers all types of business travel, including local, domestic, and international travel, as well as other business-related expenses such as meals, lodging, transportation, and entertainment."
                ]
            },
            {
                heading: "Travel Authorization",
                paragraphs: [
                    "All business travel must be pre-approved by the employee's supervisor or manager. Employees should submit a travel authorization request outlining the purpose of the trip, the destination, the expected duration, and an estimate of the costs.",
                    "Travel authorization is required to ensure that the travel is necessary, that the costs are justified, and that the expenses are within the company's budget. Unauthorized travel expenses may not be reimbursed."
                ]
            },
            {
                heading: "Expense Categories",
                paragraphs: [
                    "Reimbursable expenses include, but are not limited to, transportation (airfare, train, rental car, taxi), lodging, meals, business entertainment, and incidental expenses such as parking and tolls.",
                    "Non-reimbursable expenses include personal expenses, such as personal entertainment, alcohol (unless part of a business meal), fines, and expenses that are not related to business purposes."
                ]
            },
            {
                heading: "Travel Booking Procedures",
                paragraphs: [
                    "Employees are expected to book travel through the company's designated travel agency or booking platform to ensure that the best rates and negotiated discounts are obtained. Employees should book travel as far in advance as possible to take advantage of lower fares.",
                    "In cases where employees must book travel independently, they should seek approval from their supervisor and ensure that they obtain competitive rates. Employees should use economy class for air travel unless otherwise approved for business class in exceptional circumstances."
                ]
            },
            {
                heading: "Transportation",
                paragraphs: [
                    "Air Travel: Employees should book the most cost-effective and practical flights. Business class may only be used for international flights or flights exceeding a specified duration, with prior approval.",
                    "Ground Transportation: Employees should use public transportation, ridesharing services, or rental cars for ground travel. Rental cars should be booked at the most economical rate, and employees should opt for standard or compact vehicles unless there is a justified need for a larger vehicle.",
                    "Personal Vehicles: Employees using personal vehicles for business travel will be reimbursed at the standard mileage rate established by the company. Parking and toll expenses will also be reimbursed with proper receipts."
                ]
            },
            {
                heading: "Lodging",
                paragraphs: [
                    "Employees should book lodging at moderately priced, business-class hotels. The company's travel agency or booking platform should be used to secure the best rates and negotiated discounts.",
                    "Lodging expenses will be reimbursed based on the actual cost, including taxes and reasonable tips. Employees should avoid luxury hotels and seek accommodations that provide good value and necessary amenities such as internet access and breakfast."
                ]
            },
            {
                heading: "Meals and Entertainment",
                paragraphs: [
                    "Employees will be reimbursed for reasonable meal expenses incurred during business travel. The company may establish per diem rates or meal allowances based on location and duration of travel. Employees should refer to the company's guidelines for specific meal reimbursement rates.",
                    "Business entertainment expenses, such as client meals or events, must be pre-approved and documented with receipts and a description of the business purpose and attendees. Alcohol expenses may be reimbursed only if part of a business meal and within reasonable limits."
                ]
            },
            {
                heading: "Other Business Expenses",
                paragraphs: [
                    "Incidental expenses, such as parking, tolls, internet access, and business-related phone calls, are reimbursable with proper receipts and documentation.",
                    "Employees may also be reimbursed for conference fees, training materials, and other expenses directly related to their work, with prior approval from their supervisor."
                ]
            },
            {
                heading: "Expense Reporting and Documentation",
                paragraphs: [
                    "Employees must submit an expense report within a specified timeframe after completing their travel or incurring business expenses. The expense report should include itemized receipts, a description of the expenses, and the business purpose.",
                    "All receipts must be original and legible. Credit card statements alone are not sufficient for reimbursement. In cases where receipts are not available, employees must provide a detailed explanation and obtain approval from their supervisor."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "Expense reports must be reviewed and approved by the employee's supervisor or manager. The approver is responsible for ensuring that expenses are reasonable, necessary, and compliant with company policies.",
                    "The HR or finance department may conduct additional reviews and audits to ensure compliance with the policy and prevent fraud or misuse of company funds."
                ]
            },
            {
                heading: "Reimbursement Process",
                paragraphs: [
                    "Approved expenses will be reimbursed within a specified timeframe, typically within 30 days of submitting the expense report. Reimbursements will be made through direct deposit to the employee's bank account or added to their next payroll cycle.",
                    "Employees are encouraged to use company credit cards for business expenses whenever possible to streamline the reimbursement process and reduce the need for personal out-of-pocket expenditures."
                ]
            },
            {
                heading: "Compliance and Audit",
                paragraphs: [
                    "The company reserves the right to audit expense reports and supporting documentation to ensure compliance with this policy. Any discrepancies or violations may result in disciplinary action, including the requirement to repay unauthorized expenses.",
                    "Employees are expected to act with integrity and honesty when reporting expenses. Fraudulent claims or misrepresentation of expenses will not be tolerated and may lead to termination of employment."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in company procedures, travel practices, and tax regulations. Employees will be notified of any significant changes to the policy.",
                    "Feedback from employees and management will be considered during the review process to ensure the policy remains effective and practical."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with this Employee Travel and Expense Reimbursement Policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };
    
    export const EmployeeUniformAndDressCodePolicyTemplate = {
        title: "Employee Uniform and Dress Code Policy Template",
        policyBlurb: "This policy outlines the standards for employee uniforms and dress codes to ensure a professional and consistent appearance within the workplace.",
        preRead: "Use this template to develop a comprehensive uniform and dress code policy that promotes a professional image, ensures safety, and maintains consistency across the organization.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "General Dress Code Guidelines",
                    "Uniform Requirements",
                    "Personal Appearance Standards",
                    "Casual Dress Days",
                    "Protective Clothing and Safety Gear",
                    "Dress Code Violations",
                    "Compliance and Monitoring",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Uniform and Dress Code Policy is designed to provide clear guidelines for employee attire and personal appearance in the workplace. A professional appearance helps to create a positive impression with clients, customers, and colleagues, and promotes a sense of pride and discipline among employees. This policy applies to all employees and is intended to ensure that everyone presents a consistent and professional image."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish standards for appropriate dress and appearance in the workplace. This includes guidelines for uniforms, business attire, casual dress days, and personal grooming. The policy aims to ensure that all employees maintain a neat, professional, and safe appearance while representing the company."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary, and contract workers at [Your Company]. It covers all aspects of employee attire and personal appearance during working hours and when representing the company at off-site events.",
                    "Specific departments or roles may have additional requirements or exceptions based on job function, safety considerations, or client interactions. These specific guidelines will be outlined in departmental procedures and communicated to the relevant employees."
                ]
            },
            {
                heading: "General Dress Code Guidelines",
                paragraphs: [
                    "Employees are expected to dress in a manner that is appropriate for their role and work environment. Clothing should be clean, well-maintained, and fit properly.",
                    "Business attire is generally required for employees who interact with clients, customers, or the public. This includes suits, dress shirts, blouses, skirts, dresses, and appropriate footwear.",
                    "Casual attire may be permitted in certain work environments or on designated casual dress days. Casual attire should still be neat, modest, and appropriate for a professional setting. Examples include khakis, polo shirts, and casual dresses."
                ]
            },
            {
                heading: "Uniform Requirements",
                paragraphs: [
                    "Certain roles may require employees to wear uniforms provided by the company. Uniforms are intended to create a cohesive and professional appearance, enhance brand identity, and ensure safety.",
                    "Employees are responsible for maintaining their uniforms in good condition. This includes regular cleaning, repairing any damage, and replacing worn or faded items. The company may provide guidelines for the care and maintenance of uniforms."
                ]
            },
            {
                heading: "Personal Appearance Standards",
                paragraphs: [
                    "Personal grooming and hygiene are important aspects of maintaining a professional appearance. Employees should ensure that they are well-groomed and practice good hygiene.",
                    "Hair should be clean, neat, and styled in a manner that is appropriate for the work environment. Long hair should be tied back if it poses a safety hazard.",
                    "Jewelry, makeup, and accessories should be worn in moderation and should not be distracting or pose a safety risk. Visible tattoos and body piercings should be covered or minimized as per company guidelines."
                ]
            },
            {
                heading: "Casual Dress Days",
                paragraphs: [
                    "The company may designate specific days as casual dress days, where employees are permitted to wear more relaxed attire. Casual dress should still be professional and appropriate for the workplace.",
                    "Clothing that is excessively casual, such as ripped jeans, t-shirts with offensive graphics, or beachwear, is not permitted. Employees should use good judgment in selecting attire for casual dress days."
                ]
            },
            {
                heading: "Protective Clothing and Safety Gear",
                paragraphs: [
                    "Employees in certain roles may be required to wear protective clothing or safety gear to comply with health and safety regulations. This includes items such as hard hats, safety glasses, gloves, and high-visibility vests.",
                    "The company will provide the necessary protective clothing and safety gear, and employees are responsible for wearing it correctly and maintaining it in good condition. Failure to comply with safety gear requirements may result in disciplinary action."
                ]
            },
            {
                heading: "Dress Code Violations",
                paragraphs: [
                    "Employees who do not adhere to the dress code policy may be subject to disciplinary action. This includes verbal or written warnings, and in severe or repeated cases, suspension or termination of employment.",
                    "Supervisors and managers are responsible for enforcing the dress code policy and addressing violations promptly and fairly. Employees who are unsure about the appropriateness of their attire should seek guidance from their supervisor."
                ]
            },
            {
                heading: "Compliance and Monitoring",
                paragraphs: [
                    "The HR department will regularly review the dress code policy to ensure it meets the company's needs and aligns with industry standards. Feedback from employees and management will be considered during the review process.",
                    "Managers and supervisors are responsible for monitoring compliance with the dress code policy within their teams. Any issues or concerns should be reported to HR for further review and action."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in company standards, industry practices, or legal requirements. Employees will be notified of any significant changes to the policy.",
                    "Suggestions for improving the dress code policy are welcome and should be directed to the HR department. The goal is to maintain a policy that supports a professional and safe work environment while accommodating the diverse needs of employees."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with the Employee Uniform and Dress Code Policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EmployeeVolunteeringAndCommunityServicePolicyTemplate = {
        title: "Employee Volunteering and Community Service Policy Template",
        policyBlurb: "This policy outlines the guidelines and opportunities for employee volunteering and community service initiatives.",
        preRead: "Use this template to develop a comprehensive policy that encourages and supports employee involvement in community service and volunteering activities.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Volunteering Opportunities",
                    "Community Service Guidelines",
                    "Time Off for Volunteering",
                    "Volunteer Safety and Conduct",
                    "Recognition and Rewards",
                    "Compliance and Monitoring",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Volunteering and Community Service Policy is designed to encourage and support employees in their efforts to give back to the community. At [Your Company], we believe that engaging in community service not only benefits society but also enhances employee satisfaction and fosters a sense of teamwork and corporate social responsibility. This policy outlines the guidelines for participation in volunteering activities and the support provided by the company."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a framework for employee participation in community service and volunteering activities. By providing clear guidelines and support, we aim to facilitate meaningful community engagement, enhance the company's reputation as a socially responsible organization, and contribute to the personal and professional growth of our employees."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary, and contract workers at [Your Company]. It covers all company-sponsored volunteering events as well as individual volunteer activities undertaken by employees.",
                    "Employees are encouraged to participate in a variety of volunteering activities, including those organized by the company and those initiated by employees themselves. The company will provide support and resources to facilitate employee involvement in these activities."
                ]
            },
            {
                heading: "Volunteering Opportunities",
                paragraphs: [
                    "The company will identify and organize various volunteering opportunities throughout the year. These may include partnerships with local non-profit organizations, community events, environmental clean-up activities, and other initiatives that align with the company's values and mission.",
                    "Employees are also encouraged to suggest and initiate their own volunteering projects. Proposals for new volunteering activities should be submitted to the HR department for review and approval. The company will provide resources and support for approved employee-initiated projects."
                ]
            },
            {
                heading: "Community Service Guidelines",
                paragraphs: [
                    "Employees participating in volunteering activities are expected to conduct themselves professionally and uphold the company's values. This includes respecting the community members and organizations they are working with, adhering to the guidelines provided by the host organization, and ensuring that their actions reflect positively on the company.",
                    "Employees should dress appropriately for the volunteering activity, considering the nature of the work and the expectations of the host organization. Safety guidelines and instructions must be followed at all times to ensure the well-being of all participants."
                ]
            },
            {
                heading: "Time Off for Volunteering",
                paragraphs: [
                    "The company recognizes the importance of providing employees with the flexibility to participate in volunteering activities. As such, employees are entitled to [specific number] hours of paid time off per year for volunteering. This time off can be used for company-sponsored events or individual volunteering activities that have been approved by the HR department.",
                    "Employees should request time off for volunteering through the usual leave request process, specifying the nature of the volunteering activity and the dates of participation. Approval for time off will be subject to operational requirements and the relevance of the volunteering activity to the company's community engagement goals."
                ]
            },
            {
                heading: "Volunteer Safety and Conduct",
                paragraphs: [
                    "The safety of our employees is a top priority. Employees must adhere to all safety guidelines and instructions provided by the host organization during volunteering activities. Any safety concerns or incidents should be reported immediately to the supervising staff and the HR department.",
                    "Employees are expected to maintain a high standard of conduct while volunteering. This includes being punctual, respectful, and cooperative with other volunteers and community members. Any behavior that violates company policies or the guidelines of the host organization may result in disciplinary action."
                ]
            },
            {
                heading: "Recognition and Rewards",
                paragraphs: [
                    "The company values and recognizes the contributions of employees who participate in volunteering activities. To acknowledge their efforts, the company will implement a recognition program that may include awards, certificates, and public acknowledgment of volunteer achievements.",
                    "Employees who demonstrate outstanding commitment to volunteering and community service may be eligible for additional rewards, such as extra paid time off, gift vouchers, or other incentives. The HR department will oversee the recognition program and ensure that deserving employees are appropriately honored."
                ]
            },
            {
                heading: "Compliance and Monitoring",
                paragraphs: [
                    "The HR department will monitor participation in volunteering activities and ensure compliance with this policy. Employees are required to submit reports or feedback forms detailing their volunteering experiences, which will be used to evaluate the impact of the company's community service initiatives.",
                    "Any issues or concerns regarding volunteering activities should be reported to the HR department. The company will address any problems promptly and take necessary actions to ensure the continued success and safety of the volunteering program."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in company priorities, community needs, or legal requirements. Employees will be informed of any significant changes to the policy through internal communication channels.",
                    "Feedback from employees regarding the effectiveness and implementation of this policy is encouraged. Suggestions for improvement should be directed to the HR department, which will consider all input during the review process."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with the Employee Volunteering and Community Service Policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    
    export const EmploymentAgreementAndContractTermsTemplate = {
        title: "Employment Agreement and Contract Terms Template",
        policyBlurb: "This policy outlines the guidelines for drafting, negotiating, and managing employment agreements and contract terms at [Your Company].",
        preRead: "Use this template to develop a comprehensive and legally compliant employment agreement that clearly defines the terms and conditions of employment.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Employment Terms",
                    "Compensation and Benefits",
                    "Work Schedule and Hours",
                    "Duties and Responsibilities",
                    "Confidentiality and Non-Disclosure",
                    "Intellectual Property",
                    "Non-Compete and Non-Solicitation",
                    "Termination and Severance",
                    "Dispute Resolution",
                    "Compliance with Laws",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employment Agreement and Contract Terms policy provides a comprehensive framework for the establishment and management of employment agreements at [Your Company]. This policy is designed to ensure that all employment agreements are clear, consistent, and legally compliant, thereby fostering a fair and transparent work environment. It is crucial for both the employer and the employee to have a mutual understanding of the terms and conditions of employment to prevent disputes and misunderstandings."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline the key components of employment agreements, ensuring they reflect the expectations and obligations of both [Your Company] and its employees. This policy aims to protect the interests of both parties by clearly defining roles, responsibilities, compensation, and other critical aspects of the employment relationship. By adhering to this policy, [Your Company] seeks to maintain high standards of employment practice and legal compliance."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary, and contract workers at [Your Company]. It covers all stages of the employment process, from recruitment and hiring to termination and severance.",
                    "All employment agreements must be reviewed and approved by the HR department and, where necessary, by legal counsel to ensure compliance with relevant laws and regulations. This policy also applies to amendments and renewals of existing employment agreements."
                ]
            },
            {
                heading: "Employment Terms",
                paragraphs: [
                    "The employment agreement must clearly specify the terms of employment, including the job title, start date, and duration of the contract (if applicable). Any probationary period should also be outlined, along with the conditions for successful completion.",
                    "The agreement should include a detailed description of the position and the expectations regarding performance and conduct. This section serves as a foundation for evaluating the employee's suitability for the role and their progression within the company."
                ]
            },
            {
                heading: "Compensation and Benefits",
                paragraphs: [
                    "The agreement must detail the compensation package, including base salary, bonuses, and any other forms of remuneration. Payment frequency and methods should also be specified.",
                    "Benefits such as health insurance, retirement plans, paid time off, and other perks must be clearly outlined. Any conditions or eligibility criteria for these benefits should be included to avoid misunderstandings."
                ]
            },
            {
                heading: "Work Schedule and Hours",
                paragraphs: [
                    "The standard work schedule, including daily and weekly hours, should be clearly stated in the agreement. Any expectations regarding overtime, shift work, or flexible hours must also be included.",
                    "The policy should address procedures for requesting time off, handling absences, and managing work schedules during peak periods or emergencies. This ensures that both the employer and employee have a clear understanding of work hour expectations."
                ]
            },
            {
                heading: "Duties and Responsibilities",
                paragraphs: [
                    "A comprehensive description of the employee's duties and responsibilities should be included. This helps to set clear expectations and provides a basis for performance evaluations.",
                    "The agreement should also outline the reporting structure, including the employee's immediate supervisor and any other key contacts within the organization. This ensures clarity in communication and accountability."
                ]
            },
            {
                heading: "Confidentiality and Non-Disclosure",
                paragraphs: [
                    "Employees must agree to maintain the confidentiality of company information, both during and after their employment. This includes trade secrets, proprietary information, and any other confidential data.",
                    "The agreement should specify the types of information considered confidential and the consequences of unauthorized disclosure. This protects the company's intellectual property and sensitive information."
                ]
            },
            {
                heading: "Intellectual Property",
                paragraphs: [
                    "Any intellectual property created by the employee during their employment, including inventions, designs, and written works, must be assigned to the company. The agreement should detail the employee's obligations regarding the protection and transfer of intellectual property rights.",
                    "The policy should also address the procedures for reporting and managing intellectual property created during employment. This ensures that the company retains ownership of valuable innovations and creations."
                ]
            },
            {
                heading: "Non-Compete and Non-Solicitation",
                paragraphs: [
                    "The agreement may include non-compete and non-solicitation clauses to protect the company's interests. These clauses prevent the employee from engaging in activities that compete with the company or from soliciting clients or employees after leaving the company.",
                    "The terms of these clauses, including the duration and geographic scope, must be reasonable and legally enforceable. This helps to protect the company's business interests while ensuring fairness to the employee."
                ]
            },
            {
                heading: "Termination and Severance",
                paragraphs: [
                    "The conditions under which the employment may be terminated, including voluntary resignation, termination for cause, and termination without cause, should be clearly outlined. The notice period required for termination by either party must also be specified.",
                    "Severance pay and other post-employment benefits, if applicable, should be detailed. This ensures that both parties understand their rights and obligations upon termination of the employment relationship."
                ]
            },
            {
                heading: "Dispute Resolution",
                paragraphs: [
                    "The agreement should include a dispute resolution mechanism to handle any conflicts that arise during the employment. This may involve mediation, arbitration, or other methods of resolving disputes without resorting to litigation.",
                    "Clear procedures for initiating and conducting dispute resolution processes should be outlined. This helps to ensure that disputes are handled fairly and efficiently."
                ]
            },
            {
                heading: "Compliance with Laws",
                paragraphs: [
                    "All employment agreements must comply with applicable federal, state, and local laws. This includes labor laws, anti-discrimination laws, and any other regulations relevant to the employment relationship.",
                    "The HR department and legal counsel should regularly review and update employment agreements to ensure ongoing compliance with legal requirements. This helps to protect the company from legal risks and liabilities."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy and all employment agreements will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and company practices. Employees will be informed of any significant changes to their employment agreements.",
                    "Feedback from employees and managers regarding the effectiveness and clarity of employment agreements is encouraged. This helps to ensure that the agreements remain relevant and supportive of the company's goals and values."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with the Employment Agreement and Contract Terms policy. The acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const EqualEmploymentOpportunityEEOPolicyTemplate = {
        title: "Equal Employment Opportunity EEO Policy Template",
        policyBlurb: "This Equal Employment Opportunity (EEO) policy template outlines our commitment to providing equal opportunities for all employees and applicants.",
        preRead: "Use this template to develop a policy that ensures fair treatment, prohibits discrimination, and promotes diversity and inclusion within the workplace.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Policy Statement",
                    "Responsibilities",
                    "Recruitment and Hiring Practices",
                    "Training and Development",
                    "Promotion and Advancement",
                    "Equal Pay and Compensation",
                    "Work Environment",
                    "Complaints and Grievances",
                    "Monitoring and Compliance",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our company is committed to fostering an inclusive workplace where every employee and job applicant is treated with dignity and respect. This policy reaffirms our dedication to providing equal employment opportunities (EEO) without regard to race, color, religion, sex, national origin, age, disability, genetic information, or any other protected status under applicable law."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish guidelines and procedures to ensure that all employment-related decisions are based on merit and job-related criteria. We aim to create a work environment that values diversity, promotes fairness, and prohibits discrimination in all aspects of employment."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary, and contract workers.",
                    "It covers all aspects of employment, including recruitment, hiring, training, promotion, compensation, benefits, transfers, layoffs, recalls, and terminations."
                ]
            },
            {
                heading: "Policy Statement",
                paragraph: "We are committed to providing equal employment opportunities based on individual qualifications and job performance. Discrimination, harassment, or retaliation of any kind against employees or applicants is strictly prohibited. We will make reasonable accommodations for qualified individuals with disabilities and ensure that employment-related decisions are made without bias or prejudice."
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Senior management is responsible for promoting and enforcing this policy throughout the organization.",
                    "Managers and supervisors are responsible for implementing EEO principles in all employment practices and decisions.",
                    "Employees are expected to treat their colleagues with respect and contribute to a positive and inclusive work environment."
                ]
            },
            {
                heading: "Recruitment and Hiring Practices",
                paragraphs: [
                    "We will recruit, hire, and promote individuals based on qualifications, skills, and abilities relevant to the job.",
                    "Job postings will include a statement of our commitment to EEO and diversity.",
                    "Recruiters and hiring managers will receive training on EEO principles and unbiased hiring practices."
                ]
            },
            {
                heading: "Training and Development",
                paragraphs: [
                    "We will provide training and development opportunities to enhance awareness of EEO policies and practices.",
                    "Training programs will address unconscious bias, diversity awareness, and respectful workplace behaviors."
                ]
            },
            {
                heading: "Promotion and Advancement",
                paragraphs: [
                    "Promotions and career development opportunities will be based on merit, qualifications, and performance.",
                    "We will monitor promotion decisions to ensure fairness and equal opportunities for advancement."
                ]
            },
            {
                heading: "Equal Pay and Compensation",
                paragraphs: [
                    "We will ensure that our compensation and benefits policies are applied equitably and without regard to protected characteristics.",
                    "Regular reviews of pay equity will be conducted to identify and address any disparities."
                ]
            },
            {
                heading: "Work Environment",
                paragraphs: [
                    "We will maintain a work environment free from discrimination, harassment, and retaliation.",
                    "Employees are encouraged to report any concerns or incidents of discrimination to HR or management."
                ]
            },
            {
                heading: "Complaints and Grievances",
                paragraphs: [
                    "We have established procedures for employees to report complaints of discrimination or harassment.",
                    "All complaints will be promptly investigated, and appropriate action will be taken to address violations of this policy."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "HR will monitor compliance with this policy and conduct regular audits of employment practices.",
                    "Reports on EEO metrics and initiatives will be provided to senior management and stakeholders."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, or organizational needs.",
                    "Employees will be informed of any revisions to the policy through company communications."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge receipt of this Equal Employment Opportunity (EEO) policy and confirm their commitment to abide by its principles."
            }
        ]
    };

    export const FraudAndCorruptionPreventionPolicyTemplate = {
        title: "Fraud and Corruption Prevention Policy Template",
        policyBlurb: "This Fraud and Corruption Prevention Policy outlines our commitment to maintaining integrity, transparency, and ethical conduct in all business operations.",
        preRead: "Use this template to develop a policy that establishes guidelines and procedures to prevent, detect, and respond to fraud, corruption, and unethical behavior within the organization.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Policy Statement",
                    "Definitions",
                    "Responsibilities",
                    "Risk Assessment and Prevention Measures",
                    "Detection and Reporting",
                    "Investigation and Response",
                    "Training and Awareness",
                    "Monitoring and Compliance",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our organization is committed to upholding the highest standards of integrity and ethical behavior. This policy aims to prevent and combat fraud, corruption, and unethical conduct in all aspects of our operations."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear guidelines and procedures to prevent, detect, and respond to instances of fraud, corruption, or unethical behavior. By promoting a culture of transparency and accountability, we aim to safeguard our assets, reputation, and stakeholder trust."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, consultants, agents, and representatives of the organization.",
                    "It encompasses all activities, transactions, and interactions related to our business operations."
                ]
            },
            {
                heading: "Policy Statement",
                paragraph: "We prohibit all forms of fraud, corruption, bribery, kickbacks, embezzlement, falsification of records, and any other unethical practices. Employees are expected to uphold honesty, fairness, and ethical conduct in their roles."
            },
            {
                heading: "Definitions",
                paragraph: "Define key terms such as fraud, corruption, conflicts of interest, and other relevant terms to ensure clarity and understanding throughout the policy."
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Senior management is responsible for promoting a culture of integrity and ensuring the implementation of effective fraud prevention measures.",
                    "Managers and supervisors are accountable for communicating and enforcing the policy within their respective teams.",
                    "All employees are responsible for understanding and adhering to the principles outlined in this policy."
                ]
            },
            {
                heading: "Risk Assessment and Prevention Measures",
                paragraphs: [
                    "Conduct regular risk assessments to identify potential vulnerabilities to fraud and corruption.",
                    "Implement controls and safeguards to mitigate identified risks, such as segregation of duties, authorization procedures, and whistleblower mechanisms."
                ]
            },
            {
                heading: "Detection and Reporting",
                paragraphs: [
                    "Establish mechanisms for employees to report suspected fraud, corruption, or unethical behavior confidentially and without fear of retaliation.",
                    "Encourage a culture of vigilance and awareness where employees are proactive in identifying and reporting suspicious activities."
                ]
            },
            {
                heading: "Investigation and Response",
                paragraphs: [
                    "Outline procedures for investigating allegations of fraud or corruption, ensuring fairness, confidentiality, and compliance with legal requirements.",
                    "Define disciplinary actions and sanctions for individuals found guilty of fraud or unethical conduct."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Provide regular training programs to educate employees on recognizing, preventing, and reporting fraud and corruption.",
                    "Promote awareness of the policy through communication channels, training sessions, and employee engagement initiatives."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Establish monitoring mechanisms to assess compliance with the policy and effectiveness of fraud prevention measures.",
                    "Conduct periodic audits and reviews to evaluate the organization's adherence to ethical standards and identify areas for improvement."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "Regularly review and update the policy to reflect changes in regulatory requirements, industry best practices, and organizational needs.",
                    "Communicate revisions to employees and stakeholders and ensure understanding and compliance with updated policies."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to acknowledge receipt of this Fraud and Corruption Prevention Policy and commit to upholding its principles in their daily activities."
            }
        ]
    };

    export const GDPRComplianceAndDataPrivacyPolicyTemplate = {
        title: "GDPR Compliance and Data Privacy Policy Template",
        policyBlurb: "This GDPR Compliance and Data Privacy Policy outlines our commitment to protecting personal data and ensuring compliance with the General Data Protection Regulation (GDPR).",
        preRead: "Use this template to develop a policy that establishes procedures and safeguards to protect personal data, ensure privacy rights, and comply with GDPR requirements.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Key Definitions",
                    "Principles of GDPR Compliance",
                    "Lawfulness, Fairness, and Transparency",
                    "Purpose Limitation",
                    "Data Minimization",
                    "Accuracy",
                    "Storage Limitation",
                    "Integrity and Confidentiality (Security)",
                    "Accountability",
                    "Roles and Responsibilities",
                    "Data Protection Officer (DPO)",
                    "Data Subject Rights",
                    "Lawful Basis for Processing",
                    "Consent Mechanisms",
                    "Data Breach Notification",
                    "International Data Transfers",
                    "Data Protection Impact Assessments (DPIA)",
                    "Records of Processing Activities",
                    "Privacy by Design and by Default",
                    "Data Retention and Disposal",
                    "Third-Party Data Processors",
                    "Training and Awareness",
                    "Monitoring and Compliance",
                    "Incident Response and Management",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our organization acknowledges the critical importance of protecting personal data and is committed to complying with the GDPR, which sets stringent standards for the collection, processing, and storage of personal information of individuals within the European Union (EU). This policy establishes our dedication to data privacy and outlines our responsibilities and procedures to achieve GDPR compliance."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish comprehensive guidelines and procedures to ensure the lawful, fair, and transparent processing of personal data while safeguarding the rights of individuals. It aims to mitigate risks associated with data breaches, enhance trust among stakeholders, and demonstrate our commitment to data protection principles as outlined in the GDPR."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all personal data collected, processed, and stored by our organization, regardless of the format or medium.",
                    "It covers all employees, contractors, third-party vendors, and any other entities that handle personal data on behalf of the organization."
                ]
            },
            {
                heading: "Key Definitions",
                paragraph: "Define essential terms related to GDPR compliance and data privacy to ensure clarity and understanding throughout the policy. Key definitions include personal data, data subject, processing, controller, processor, consent, and more."
            },
            {
                heading: "Principles of GDPR Compliance",
                paragraphs: [
                    "Explain the core principles of GDPR that govern the processing of personal data:",
                    "**Lawfulness, Fairness, and Transparency:** Personal data must be processed lawfully, fairly, and transparently in relation to the data subject.",
                    "**Purpose Limitation:** Personal data must be collected for specified, explicit, and legitimate purposes and not further processed in a manner incompatible with those purposes.",
                    "**Data Minimization:** Personal data must be adequate, relevant, and limited to what is necessary in relation to the purposes for which it is processed.",
                    "**Accuracy:** Personal data must be accurate and, where necessary, kept up to date; every reasonable step must be taken to ensure that inaccurate personal data, having regard to the purposes for which it is processed, is erased or rectified without delay.",
                    "**Storage Limitation:** Personal data must be kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data are processed.",
                    "**Integrity and Confidentiality (Security):** Personal data must be processed in a manner that ensures appropriate security, including protection against unauthorized or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical or organizational measures.",
                    "**Accountability:** The controller shall be responsible for, and be able to demonstrate compliance with, these principles."
                ]
            },
            {
                heading: "Roles and Responsibilities",
                paragraphs: [
                    "Outline the roles and responsibilities of individuals and departments within the organization in achieving GDPR compliance:",
                    "**Data Protection Officer (DPO):** Designate responsibilities of the DPO in overseeing data protection strategy, implementation, and ensuring compliance with GDPR.",
                    "**Data Controller:** Responsibilities of those determining the purposes and means of processing personal data within the organization.",
                    "**Data Processor:** Responsibilities when processing personal data on behalf of a data controller.",
                    "**Employees:** Responsibilities for handling personal data in accordance with GDPR principles and organizational policies."
                ]
            },
            {
                heading: "Data Protection Officer (DPO)",
                paragraph: "Designate a Data Protection Officer (DPO) responsible for overseeing GDPR compliance efforts within the organization. The DPO ensures that data protection policies and procedures are implemented effectively, provides advice on data protection impact assessments (DPIAs), and serves as a point of contact for data subjects and supervisory authorities."
            },
            {
                heading: "Data Subject Rights",
                paragraphs: [
                    "Detail the rights of individuals (data subjects) under GDPR and how the organization will facilitate the exercise of these rights:",
                    "**Right to Access:** Data subjects have the right to obtain confirmation as to whether or not personal data concerning them is being processed, and where that is the case, access to the personal data and related information.",
                    "**Right to Rectification:** Data subjects have the right to obtain the rectification of inaccurate personal data concerning them.",
                    "**Right to Erasure (Right to be Forgotten):** Data subjects have the right to obtain the erasure of personal data concerning them without undue delay.",
                    "**Right to Restriction of Processing:** Data subjects have the right to obtain restriction of processing where certain conditions apply.",
                    "**Right to Data Portability:** Data subjects have the right to receive the personal data concerning them, which they have provided to a controller, in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller without hindrance.",
                    "**Right to Object:** Data subjects have the right to object to processing of personal data concerning them, unless the controller demonstrates compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defense of legal claims.",
                    "**Rights in Relation to Automated Decision Making and Profiling:** Data subjects have the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her."
                ]
            },
            {
                heading: "Lawful Basis for Processing",
                paragraphs: [
                    "Describe the lawful bases for processing personal data under GDPR, including consent, contract necessity, legal obligation, vital interests, public task, and legitimate interests. Clarify how the organization determines and documents the lawful basis for each processing activity."
                ]
            },
            {
                heading: "Consent Mechanisms",
                paragraphs: [
                    "Outline procedures for obtaining, managing, and documenting consent when processing personal data. Explain when and how consent should be obtained, the conditions for valid consent, withdrawal of consent, and alternatives to consent."
                ]
            },
            {
                heading: "Data Breach Notification",
                paragraphs: [
                    "Detail the procedures for detecting, reporting, and managing data breaches in compliance with GDPR requirements. Include obligations to notify supervisory authorities and affected data subjects without undue delay, along with provisions for assessing the severity and impact of breaches."
                ]
            },
            {
                heading: "International Data Transfers",
                paragraphs: [
                    "Explain how the organization ensures lawful transfer of personal data outside the EU/EEA in accordance with GDPR requirements. Discuss mechanisms such as adequacy decisions, standard contractual clauses (SCCs), binding corporate rules (BCRs), and derogations for specific situations."
                ]
            },
            {
                heading: "Data Protection Impact Assessments (DPIA)",
                paragraphs: [
                    "Describe the process for conducting Data Protection Impact Assessments (DPIAs) to assess the impact of data processing activities on data subjects' privacy and identify measures to mitigate risks. Explain when DPIAs are mandatory under GDPR and the involvement of the DPO in conducting assessments."
                ]
            },
            {
                heading: "Records of Processing Activities",
                paragraphs: [
                    "Explain the requirements for maintaining records of processing activities as mandated by GDPR. Discuss the content of records, including purposes of processing, categories of data subjects and personal data, recipients of personal data, and details of international transfers."
                ]
            },
            {
                heading: "Privacy by Design and by Default",
                paragraphs: [
                    "Highlight the organization's commitment to integrating data protection principles into the design of systems, products, and processes (Privacy by Design). Discuss measures for implementing data protection features by default, such as data minimization, pseudonymization, and transparency."
                ]
            },
            {
                heading: "Data Retention and Disposal",
                paragraphs: [
                    "Specify the organization's policies and procedures for retaining and disposing of personal data in compliance with GDPR principles. Define retention periods for different categories of personal data based on legal, regulatory, operational, and business requirements."
                ]
            },
            {
                heading: "Third-Party Data Processors",
                paragraphs: [
                    "Address the organization's obligations when engaging third-party data processors and subprocessors. Discuss requirements for data processing agreements (DPAs), ensuring that third parties provide sufficient guarantees to implement appropriate technical and organizational measures for data protection."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Emphasize the importance of ongoing training and awareness programs for employees to ensure understanding of GDPR principles, responsibilities, and compliance requirements. Describe the content, frequency, and methods of delivering training sessions across different departments."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Outline procedures for monitoring compliance with this policy and GDPR regulations. Discuss regular audits, assessments, and reviews conducted to evaluate the effectiveness of data protection measures and identify areas for improvement."
                ]
            },
            {
                heading: "Incident Response and Management",
                paragraphs: [
                    "Detail the organization's incident response plan for addressing data breaches and security incidents. Include procedures for containment, investigation, mitigation, and notification, ensuring timely response and adherence to legal obligations under GDPR."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "Establish a framework for regularly reviewing and updating this policy to reflect changes in GDPR regulations, organizational practices, and technological advancements. Define roles responsible for policy updates and communication of changes to relevant stakeholders."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "Require all employees, contractors, and third parties handling personal data to acknowledge their understanding of this GDPR Compliance and Data Privacy Policy. Encourage commitment to comply with policy requirements and contribute to a culture of data protection within the organization."
            }
        ]
    };
    
    

    export const HotDeskingAndFlexibleWorkspacePolicyTemplate = {
        title: "Hot Desking and Flexible Workspace Policy Template",
        policyBlurb: "This Hot Desking and Flexible Workspace Policy outlines guidelines and procedures for the efficient and safe use of shared workspaces within [Your Company].",
        preRead: "Use this template to establish protocols that promote productivity, collaboration, and hygiene in hot desking and flexible workspace environments.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definition of Hot Desking and Flexible Workspace",
                    "Benefits of Hot Desking",
                    "Considerations Before Implementing Hot Desking",
                    "Guidelines for Using Hot Desks",
                    "Workspace Hygiene and Cleanliness",
                    "Health and Safety Measures",
                    "Personalization and Comfort",
                    "Equipment and Connectivity",
                    "Security and Confidentiality",
                    "Communication and Collaboration",
                    "Scheduling and Reservations",
                    "Behavior and Etiquette",
                    "Monitoring and Compliance",
                    "Feedback and Continuous Improvement",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our organization recognizes the evolving nature of workspaces and is committed to implementing hot desking and flexible workspace arrangements that support employee productivity, collaboration, and well-being."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to provide clear guidelines and procedures for the effective and safe utilization of shared workspaces, ensuring consistency in workspace management and promoting a positive work environment."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, and visitors who utilize hot desking and flexible workspace facilities provided by [Your Company].",
                    "It covers all aspects of workspace usage, including hygiene, health and safety, equipment provision, security, and behavioral expectations."
                ]
            },
            {
                heading: "Definition of Hot Desking and Flexible Workspace",
                paragraph: "Hot desking refers to the practice of non-assigned seating, where employees choose from available desks or workstations each day, promoting flexibility and efficiency in workspace utilization. Flexible workspace extends this concept to include various settings like collaboration zones, quiet areas, and breakout spaces."
            },
            {
                heading: "Benefits of Hot Desking",
                paragraphs: [
                    "Discuss the benefits of hot desking and flexible workspace arrangements:",
                    "**Optimized Space Utilization:** Reduces the need for dedicated desks, maximizing office space efficiency.",
                    "**Promotes Flexibility:** Allows employees to choose work environments that suit their tasks and preferences.",
                    "**Encourages Collaboration:** Facilitates spontaneous interactions and teamwork among employees.",
                    "**Cost Efficiency:** Potential savings on real estate and facilities management costs."
                ]
            },
            {
                heading: "Considerations Before Implementing Hot Desking",
                paragraph: "Before adopting hot desking, consider factors such as organizational culture, employee preferences, technology readiness, and the physical layout of the workspace. Conduct surveys or pilot programs to assess readiness and gather employee feedback."
            },
            {
                heading: "Guidelines for Using Hot Desks",
                paragraphs: [
                    "Provide detailed guidelines for employees on how to use hot desks effectively:",
                    "**Reservation System:** If applicable, outline procedures for reserving desks through an online platform or scheduling tool.",
                    "**Clear Desk Policy:** Require employees to clear personal belongings and documents at the end of each day to maintain workspace cleanliness and availability for others.",
                    "**Etiquette:** Encourage respectful behavior and consideration for fellow coworkers when using shared spaces."
                ]
            },
            {
                heading: "Workspace Hygiene and Cleanliness",
                paragraphs: [
                    "Detail protocols for maintaining hygiene and cleanliness in hot desking areas:",
                    "**Cleaning Schedule:** Regular cleaning and disinfection of desks, chairs, and common touchpoints.",
                    "**Personal Hygiene:** Encourage employees to practice good hygiene habits and provide sanitization stations.",
                    "**Reporting Procedures:** Establish a process for reporting cleanliness issues or maintenance needs."
                ]
            },
            {
                heading: "Health and Safety Measures",
                paragraphs: [
                    "Outline health and safety measures to promote employee well-being:",
                    "**Ergonomic Considerations:** Provide ergonomic furniture and encourage proper posture and workstation setup.",
                    "**Workspace Design:** Ensure adequate lighting, ventilation, and noise control to create a comfortable working environment.",
                    "**Emergency Procedures:** Communicate emergency evacuation plans and procedures applicable to hot desking areas."
                ]
            },
            {
                heading: "Personalization and Comfort",
                paragraphs: [
                    "Address opportunities for personalization and employee comfort in shared workspaces:",
                    "**Personal Belongings:** Allow employees to personalize their workspace within guidelines.",
                    "**Comfortable Environment:** Ensure temperature control and access to amenities like refreshments and break areas."
                ]
            },
            {
                heading: "Equipment and Connectivity",
                paragraphs: [
                    "Discuss provisions for essential equipment and reliable connectivity in hot desking areas:",
                    "**Access to Technology:** Provide access to computers, monitors, and peripherals needed for work tasks.",
                    "**Internet Connectivity:** Ensure stable internet access and IT support for troubleshooting."
                ]
            },
            {
                heading: "Security and Confidentiality",
                paragraphs: [
                    "Address security measures to protect confidential information and personal belongings:",
                    "**Access Control:** Implement access control measures and ensure only authorized personnel can enter designated areas.",
                    "**Data Security:** Remind employees to secure laptops and sensitive documents when not in use and comply with data protection policies."
                ]
            },
            {
                heading: "Communication and Collaboration",
                paragraphs: [
                    "Encourage effective communication and collaboration among employees in hot desking environments:",
                    "**Communication Tools:** Provide tools and platforms for virtual meetings, instant messaging, and project collaboration.",
                    "**Team Engagement:** Facilitate team-building activities and social interactions to foster a sense of community."
                ]
            },
            {
                heading: "Scheduling and Reservations",
                paragraphs: [
                    "Explain the process for scheduling and reserving hot desks or flexible workspaces:",
                    "**Booking System:** Describe how employees can reserve desks in advance or on the day of use.",
                    "**Fair Usage:** Establish guidelines to ensure fair and equitable access to hot desks, especially during peak times."
                ]
            },
            {
                heading: "Behavior and Etiquette",
                paragraphs: [
                    "Promote professional behavior and workplace etiquette in shared workspaces:",
                    "**Noise Levels:** Encourage employees to maintain appropriate noise levels and respect quiet zones.",
                    "**Courtesy:** Remind employees to be mindful of others and maintain a clean and organized workspace."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Describe procedures for monitoring compliance with this policy and addressing non-compliance:",
                    "**Regular Audits:** Conduct periodic audits of hot desking areas to ensure adherence to guidelines and policies.",
                    "**Feedback Mechanisms:** Encourage employees to provide feedback on their hot desking experience and suggest improvements."
                ]
            },
            {
                heading: "Feedback and Continuous Improvement",
                paragraphs: [
                    "Emphasize the importance of continuous improvement in hot desking practices:",
                    "**Feedback Loops:** Implement mechanisms for gathering feedback from employees and stakeholders to refine policies and procedures.",
                    "**Adaptation:** Stay responsive to evolving needs and challenges related to hot desking through regular policy reviews and updates."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "Establish a schedule for reviewing and revising this policy to ensure its relevance and effectiveness over time:",
                    "**Policy Updates:** Assign responsibility for updating the policy in response to legislative changes or organizational requirements.",
                    "**Communication:** Inform employees of any updates to the hot desking policy and provide training or guidance as needed."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "Require all employees to acknowledge their understanding and acceptance of this Hot Desking and Flexible Workspace Policy. Encourage commitment to comply with the policy's guidelines to promote a productive and harmonious working environment."
            }
        ]
    };
    
    export const InternalPromotionAndTransferTemplate = {
        title: "Internal Promotion and Transfer Template",
        policyBlurb: "This policy outlines the guidelines and procedures for internal promotions and transfers within [Your Company]. It aims to provide fair and transparent opportunities for employee advancement and mobility within the organization.",
        preRead: "Use this template to establish a clear and structured approach for promoting and transferring employees internally. This policy supports employee growth, development, and organizational efficiency.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility Criteria",
                    "Promotion Procedures",
                    "Transfer Procedures",
                    "Evaluation and Selection Process",
                    "Communication and Notification",
                    "Training and Development",
                    "Compensation and Benefits",
                    "Record Keeping",
                    "Appeals and Grievances",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we recognize the importance of internal promotions and transfers as a means to motivate employees, enhance job satisfaction, and retain talent. This policy sets forth the principles and processes for promoting and transferring employees within the organization. By providing clear guidelines, we aim to ensure that these processes are conducted fairly, consistently, and transparently, thereby fostering a culture of growth and development."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a structured and equitable framework for internal promotions and transfers. It seeks to: \n- Provide employees with opportunities for career advancement and skill development. \n- Ensure that promotions and transfers are based on merit, qualifications, and performance. \n- Enhance organizational efficiency by placing employees in roles where they can contribute most effectively. \n- Support workforce planning and succession management. \n- Promote a positive work environment by recognizing and rewarding employee contributions."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Your Company], including full-time, part-time, and temporary employees. It covers all aspects of the internal promotion and transfer process, from eligibility criteria to the final decision-making and communication steps.",
                    "It is applicable across all departments and locations of [Your Company] and is intended to support the organization's goals of employee development and operational excellence."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Promotion: A change in an employee's position that involves greater responsibility, higher rank, and usually an increase in salary or benefits.",
                    "Transfer: The movement of an employee from one position or department to another within the organization, which may or may not involve a change in rank or salary.",
                    "Eligible Employee: An employee who meets the criteria set forth in this policy for consideration for a promotion or transfer.",
                    "Hiring Manager: The manager responsible for the department or team to which the employee is being promoted or transferred."
                ]
            },
            {
                heading: "Eligibility Criteria",
                paragraphs: [
                    "To be eligible for a promotion or transfer, an employee must: \n- Have completed a minimum of [X] months in their current position. \n- Meet the qualifications and experience requirements for the new position. \n- Have a satisfactory performance record with no active disciplinary actions.",
                    "Additional criteria for promotion may include: \n- Demonstrated ability to perform the responsibilities of the new position. \n- Completion of relevant training or certification programs. \n- Positive feedback from supervisors and peers.",
                    "Additional criteria for transfer may include: \n- Alignment of the transfer with the employee's career goals and development plan. \n- Availability of a suitable vacancy in the desired department or location."
                ]
            },
            {
                heading: "Promotion Procedures",
                paragraphs: [
                    "The promotion process begins with the identification of a vacancy or the need for a new role. This can be initiated by the department head, HR, or the employee themselves.",
                    "Once a potential candidate is identified, the following steps will be taken: \n- The employee’s performance, qualifications, and suitability for the new role will be reviewed by the current and prospective supervisors. \n- An interview or assessment may be conducted to evaluate the employee’s readiness for the new position. \n- A recommendation for promotion will be made to the HR department.",
                    "HR will then: \n- Review the recommendation to ensure it aligns with company policies and the employee’s career development plan. \n- Determine the appropriate salary and benefits adjustments. \n- Prepare an offer letter or promotion agreement for the employee’s review and signature."
                ]
            },
            {
                heading: "Transfer Procedures",
                paragraphs: [
                    "The transfer process can be initiated by the employee, the current supervisor, or HR. Employees interested in transferring should submit a transfer request form to their current supervisor and HR.",
                    "The following steps will then be taken: \n- The employee’s request will be reviewed to ensure they meet the eligibility criteria. \n- The hiring manager of the receiving department will review the employee’s qualifications and conduct an interview if necessary. \n- If the transfer is approved, HR will coordinate the logistics of the transfer, including the effective date and any necessary training.",
                    "HR will ensure that: \n- The employee’s compensation and benefits are adjusted if applicable. \n- The employee’s records are updated to reflect the transfer. \n- Any necessary onboarding or orientation for the new role is scheduled."
                ]
            },
            {
                heading: "Evaluation and Selection Process",
                paragraphs: [
                    "The evaluation process for promotions and transfers involves a thorough review of the employee’s performance, qualifications, and potential. This includes: \n- Performance appraisals and feedback from current and past supervisors. \n- Assessment of skills and competencies relevant to the new position. \n- Consideration of the employee’s career aspirations and development plan.",
                    "The selection process is designed to be transparent and objective. It includes: \n- An initial screening by HR to verify eligibility and qualifications. \n- Interviews or assessments conducted by the hiring manager and other relevant stakeholders. \n- A final decision made in consultation with HR and senior management.",
                    "The goal is to ensure that the most qualified and suitable candidate is selected for the promotion or transfer, and that the process is fair and consistent across the organization."
                ]
            },
            {
                heading: "Communication and Notification",
                paragraphs: [
                    "Effective communication is critical to the promotion and transfer process. All stakeholders, including the employee, current supervisor, and hiring manager, should be kept informed throughout the process.",
                    "Once a decision is made: \n- The employee will be notified in writing of the promotion or transfer offer, including details of the new position, salary, and start date. \n- The employee’s current supervisor will be informed to facilitate a smooth transition. \n- The hiring manager will be notified to prepare for the employee’s onboarding and integration into the new role.",
                    "HR will ensure that all necessary documentation is completed and filed, and that any required system updates (e.g., payroll, benefits) are made in a timely manner."
                ]
            },
            {
                heading: "Training and Development",
                paragraphs: [
                    "To support employees in their new roles, [Your Company] will provide relevant training and development opportunities. This may include: \n- On-the-job training and shadowing with experienced colleagues. \n- Formal training programs or courses relevant to the new position. \n- Ongoing professional development to build skills and competencies.",
                    "The goal is to ensure that employees have the knowledge and resources they need to succeed in their new roles and to continue their professional growth.",
                    "Employees are encouraged to take advantage of these opportunities and to proactively seek out additional learning and development resources as needed."
                ]
            },
            {
                heading: "Compensation and Benefits",
                paragraphs: [
                    "Promotions and transfers may involve changes to an employee’s compensation and benefits. These changes will be determined based on: \n- The salary range and compensation structure for the new position. \n- The employee’s experience, qualifications, and performance.",
                    "HR will ensure that any adjustments to salary, benefits, or other compensation elements are communicated clearly to the employee and implemented promptly.",
                    "The goal is to provide competitive and fair compensation that reflects the employee’s new responsibilities and contributions to the organization."
                ]
            },
            {
                heading: "Record Keeping",
                paragraphs: [
                    "HR is responsible for maintaining accurate and up-to-date records of all promotions and transfers. This includes: \n- Documentation of the promotion or transfer request and approval process. \n- Records of performance evaluations, interviews, and assessments. \n- Copies of the promotion or transfer offer letter and any related agreements.",
                    "These records will be maintained in accordance with [Your Company]’s data retention policies and applicable legal requirements.",
                    "Accurate record keeping helps ensure transparency and accountability in the promotion and transfer process and supports workforce planning and reporting."
                ]
            },
            {
                heading: "Appeals and Grievances",
                paragraphs: [
                    "Employees who believe that they have been unfairly denied a promotion or transfer may file an appeal or grievance. The process for doing so includes: \n- Submitting a written appeal to HR outlining the reasons for the appeal and any supporting evidence. \n- HR will review the appeal and conduct an investigation if necessary.",
                    "HR will then: \n- Provide a written response to the employee within [Number] days, outlining the findings and any corrective actions to be taken. \n- If the employee is not satisfied with the response, they may escalate the appeal to senior management or use the formal grievance process outlined in the Employee Handbook.",
                    "The goal is to ensure that all employees have access to a fair and transparent process for addressing concerns related to promotions and transfers."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "This policy will be reviewed annually by HR and senior management to ensure its continued relevance and effectiveness.",
                    "Any necessary revisions will be made to reflect changes in organizational goals, industry best practices, or legal requirements.",
                    "Employees will be informed of any significant changes to the policy and will have the opportunity to provide feedback."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, the employee acknowledges that they have read and understood this Internal Promotion and Transfer Policy and agree to abide by its terms and conditions. The employee further acknowledges that they have had the opportunity to ask questions and seek clarification about the policy."
            }
        ]
    };
    

    export const InternationalRecruitmentAndHiringTemplate = {
        title: "International Recruitment and Hiring Template",
        policyBlurb: "This policy outlines the procedures and guidelines for recruiting and hiring international employees at [Your Company]. It ensures compliance with legal requirements and promotes a fair and effective hiring process.",
        preRead: "Use this template to establish a clear and structured approach for international recruitment and hiring. This policy supports the acquisition of global talent, fostering diversity and inclusion within the organization.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Legal Compliance",
                    "Recruitment Process",
                    "Selection Criteria",
                    "Visa and Work Permit Procedures",
                    "Onboarding and Orientation",
                    "Cultural Integration",
                    "Compensation and Benefits",
                    "Relocation Assistance",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we recognize the value of a diverse and inclusive workforce. This policy outlines our approach to international recruitment and hiring, ensuring that we attract and retain top talent from around the globe. By providing clear guidelines, we aim to conduct the recruitment and hiring process fairly, consistently, and transparently, while complying with all relevant legal requirements."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a structured and equitable framework for the recruitment and hiring of international employees. It seeks to: \n- Ensure compliance with immigration laws and regulations. \n- Attract a diverse pool of qualified candidates from around the world. \n- Promote a fair and transparent recruitment and hiring process. \n- Facilitate the smooth integration of international employees into the organization. \n- Support the organization's goals of diversity, inclusion, and global competitiveness."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all departments and locations of [Your Company] involved in the recruitment and hiring of international employees. It covers all stages of the recruitment and hiring process, from identifying a vacancy to the onboarding and integration of the new employee.",
                    "It is intended to support the recruitment and hiring of individuals from outside the country in which [Your Company] operates, ensuring that the process is conducted in accordance with applicable laws and organizational standards."
                ]
            },
            {
                heading: "Legal Compliance",
                paragraphs: [
                    "Compliance with immigration and employment laws is paramount in the recruitment and hiring of international employees. This includes, but is not limited to: \n- Ensuring that all candidates have the legal right to work in the country of employment. \n- Adhering to visa and work permit requirements and procedures. \n- Complying with anti-discrimination laws and promoting equal employment opportunities.",
                    "HR is responsible for staying informed about relevant laws and regulations and ensuring that all recruitment and hiring activities comply with these requirements.",
                    "Failure to comply with legal requirements can result in significant penalties for the organization and the individual employees involved."
                ]
            },
            {
                heading: "Recruitment Process",
                paragraphs: [
                    "The recruitment process for international candidates follows the same principles as domestic recruitment but includes additional steps to address legal and logistical considerations. The process includes: \n- Identifying a vacancy and obtaining approval to recruit internationally. \n- Developing a job description and selection criteria that are inclusive and non-discriminatory. \n- Advertising the vacancy through appropriate channels, including international job boards and recruitment agencies.",
                    "HR will work with hiring managers to ensure that all recruitment activities are conducted in accordance with this policy and applicable legal requirements.",
                    "Recruitment efforts should aim to attract a diverse pool of qualified candidates and provide them with clear and accurate information about the position and the organization."
                ]
            },
            {
                heading: "Selection Criteria",
                paragraphs: [
                    "Selection criteria for international candidates should be based on the qualifications, experience, and skills required for the position. It should include: \n- Educational qualifications and professional certifications. \n- Relevant work experience and achievements. \n- Language proficiency and communication skills. \n- Cultural adaptability and ability to work in a diverse environment.",
                    "The selection process may include: \n- Initial screening of applications to ensure that candidates meet the minimum requirements. \n- Conducting interviews, which may be held remotely or in person, depending on the candidate’s location. \n- Assessing candidates through tests or assignments relevant to the position.",
                    "The goal is to select the most qualified and suitable candidate for the position while ensuring a fair and transparent process."
                ]
            },
            {
                heading: "Visa and Work Permit Procedures",
                paragraphs: [
                    "Obtaining the necessary visas and work permits is a critical step in the hiring process for international employees. The steps involved include: \n- Identifying the appropriate visa or work permit category based on the employee’s qualifications and the nature of the position. \n- Preparing and submitting the required documentation to the relevant immigration authorities. \n- Monitoring the application process and providing support to the employee as needed.",
                    "HR is responsible for coordinating the visa and work permit application process and ensuring that all requirements are met in a timely manner.",
                    "Employees are expected to cooperate with the process by providing accurate and complete information and adhering to all legal requirements."
                ]
            },
            {
                heading: "Onboarding and Orientation",
                paragraphs: [
                    "A comprehensive onboarding and orientation program is essential to help international employees integrate smoothly into the organization. The program includes: \n- Providing information about the company’s policies, procedures, and culture. \n- Introducing the employee to their team and key contacts within the organization. \n- Providing training and resources to help the employee understand their role and responsibilities.",
                    "HR and the hiring manager will work together to ensure that the onboarding process is thorough and supportive, addressing any specific needs or concerns of the international employee.",
                    "Effective onboarding helps international employees feel welcomed and valued, and sets them up for success in their new role."
                ]
            },
            {
                heading: "Cultural Integration",
                paragraphs: [
                    "Cultural integration is a crucial aspect of the onboarding process for international employees. It involves: \n- Providing cultural awareness training to help employees understand and appreciate cultural differences. \n- Encouraging open communication and creating opportunities for employees to share their cultural backgrounds. \n- Promoting an inclusive workplace culture that values diversity and fosters mutual respect.",
                    "HR and the hiring manager will work together to support the cultural integration of international employees, addressing any challenges that may arise and promoting a positive and inclusive work environment.",
                    "Successful cultural integration enhances employee engagement, satisfaction, and retention, and contributes to a more dynamic and innovative workplace."
                ]
            },
            {
                heading: "Compensation and Benefits",
                paragraphs: [
                    "Compensation and benefits for international employees should be competitive and aligned with the organization’s policies and industry standards. This includes: \n- Offering a salary that reflects the employee’s qualifications, experience, and the cost of living in the country of employment. \n- Providing benefits such as health insurance, retirement plans, and other perks that are comparable to those offered to domestic employees. \n- Addressing any additional needs or considerations specific to international employees, such as housing allowances or relocation assistance.",
                    "HR is responsible for ensuring that compensation and benefits packages are fair, transparent, and compliant with legal requirements.",
                    "Providing competitive compensation and benefits helps attract and retain top international talent and supports the organization’s goals of diversity and inclusion."
                ]
            },
            {
                heading: "Relocation Assistance",
                paragraphs: [
                    "Relocation assistance is provided to international employees to help them transition smoothly to their new location. This may include: \n- Providing information and resources about the new location, including housing, schools, and local services. \n- Offering financial assistance for relocation expenses, such as moving costs and temporary housing. \n- Providing support for obtaining necessary documents and settling into the new community.",
                    "HR and the hiring manager will work together to ensure that relocation assistance is tailored to the needs of the individual employee and their family, as applicable.",
                    "Effective relocation assistance helps international employees feel supported and reduces the stress and challenges associated with moving to a new country."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The effectiveness of the international recruitment and hiring process is regularly monitored and reviewed to ensure continuous improvement. This involves: \n- Collecting feedback from international employees and hiring managers about their experiences with the recruitment and onboarding process. \n- Analyzing key metrics, such as time-to-hire, employee retention rates, and diversity statistics. \n- Identifying and addressing any areas for improvement in the recruitment, hiring, and onboarding processes.",
                    "HR is responsible for leading the monitoring and review process and implementing any necessary changes to ensure that the policy remains effective and relevant.",
                    "Regular monitoring and review help ensure that the organization continues to attract and retain top international talent and supports its goals of diversity and inclusion."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, the employee acknowledges that they have read and understood this International Recruitment and Hiring Policy and agree to abide by its terms and conditions. The employee further acknowledges that they have had the opportunity to ask questions and seek clarification about the policy."
            }
        ]
    };
    

    export const InterviewProcessAndCandidateSelectionTemplate = {
        title: "Interview Process and Candidate Selection Template",
        policyBlurb: "This policy outlines the procedures and guidelines for conducting interviews and selecting candidates at [Your Company]. It ensures a fair, consistent, and effective hiring process.",
        preRead: "Use this template to establish a clear and structured approach for the interview process and candidate selection. This policy supports the hiring of the best talent while promoting diversity and inclusion within the organization.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Roles and Responsibilities",
                    "Job Analysis and Description",
                    "Candidate Sourcing",
                    "Initial Screening",
                    "Interview Preparation",
                    "Interview Process",
                    "Candidate Evaluation",
                    "Selection Decision",
                    "Communication with Candidates",
                    "Onboarding and Integration",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we recognize the importance of a structured and transparent interview process in hiring the best talent. This policy outlines our approach to conducting interviews and selecting candidates, ensuring that we adhere to best practices and legal requirements. By providing clear guidelines, we aim to conduct interviews and candidate selection processes fairly, consistently, and transparently."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a structured framework for the interview process and candidate selection at [Your Company]. It aims to: \n- Ensure a fair and transparent interview process. \n- Promote equal employment opportunities and prevent discrimination. \n- Attract and select the best candidates based on merit. \n- Support hiring managers and interviewers in conducting effective interviews. \n- Facilitate the integration of new employees into the organization."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all departments and locations of [Your Company] involved in the interview process and candidate selection. It covers all stages of the interview process, from job analysis and candidate sourcing to the final selection decision and onboarding.",
                    "It is intended to support the recruitment and selection of individuals for all types of employment, including full-time, part-time, temporary, and contract positions."
                ]
            },
            {
                heading: "Roles and Responsibilities",
                paragraphs: [
                    "Hiring Manager: Responsible for defining the job requirements, participating in interviews, and making the final selection decision.",
                    "Human Resources (HR): Responsible for coordinating the recruitment process, providing training and support to interviewers, and ensuring compliance with legal requirements.",
                    "Interviewers: Responsible for conducting interviews, evaluating candidates, and providing feedback to the hiring manager.",
                    "Candidates: Responsible for providing accurate and complete information and participating in the interview process in good faith."
                ]
            },
            {
                heading: "Job Analysis and Description",
                paragraphs: [
                    "The interview process begins with a thorough job analysis to identify the skills, qualifications, and experience required for the position. This involves: \n- Reviewing the current job description and updating it as necessary. \n- Consulting with the hiring manager and relevant stakeholders to define the job requirements. \n- Identifying the key competencies and behaviors needed for success in the role.",
                    "Based on the job analysis, a clear and detailed job description is created. This includes: \n- A summary of the position and its purpose. \n- A list of key responsibilities and duties. \n- The required qualifications, skills, and experience. \n- Any specific competencies or behaviors that are essential for the role.",
                    "The job description serves as the basis for the candidate sourcing and selection process, ensuring that all candidates are assessed against the same criteria."
                ]
            },
            {
                heading: "Candidate Sourcing",
                paragraphs: [
                    "Effective candidate sourcing is essential to attract a diverse pool of qualified candidates. This involves: \n- Advertising the vacancy through appropriate channels, such as job boards, social media, and recruitment agencies. \n- Engaging in proactive sourcing methods, such as employee referrals, networking, and talent scouting. \n- Ensuring that the job advertisement is inclusive and free from biased language.",
                    "HR is responsible for coordinating the candidate sourcing process, working closely with the hiring manager to identify the most effective sourcing strategies.",
                    "The goal is to attract a diverse pool of candidates who meet the qualifications and requirements outlined in the job description."
                ]
            },
            {
                heading: "Initial Screening",
                paragraphs: [
                    "The initial screening process involves reviewing applications and resumes to identify candidates who meet the minimum qualifications for the position. This includes: \n- Reviewing each application to ensure that the candidate meets the basic requirements outlined in the job description. \n- Conducting preliminary phone or video interviews to assess the candidate's suitability for the role. \n- Using standardized screening criteria to ensure a fair and consistent evaluation of all candidates.",
                    "HR and the hiring manager work together to conduct the initial screening process, ensuring that all candidates are assessed fairly and consistently.",
                    "Candidates who pass the initial screening are shortlisted for the interview process."
                ]
            },
            {
                heading: "Interview Preparation",
                paragraphs: [
                    "Preparing for the interview process involves: \n- Developing a structured interview format that includes a mix of behavioral, situational, and technical questions. \n- Providing interviewers with training and resources to conduct effective interviews. \n- Creating an interview schedule that accommodates the availability of both candidates and interviewers.",
                    "HR is responsible for coordinating the interview preparation process, ensuring that all interviewers are well-prepared and that the interview process is organized and efficient.",
                    "Effective preparation helps ensure that interviews are conducted fairly and consistently, and that candidates have a positive experience."
                ]
            },
            {
                heading: "Interview Process",
                paragraphs: [
                    "The interview process involves conducting structured interviews to assess the qualifications and suitability of candidates. This includes: \n- Using a mix of behavioral, situational, and technical questions to evaluate the candidate's skills, experience, and fit for the role. \n- Ensuring that all interviewers are trained and prepared to conduct interviews effectively. \n- Providing candidates with a clear understanding of the interview process and what to expect.",
                    "HR and the hiring manager work together to coordinate the interview process, ensuring that all interviews are conducted fairly and consistently.",
                    "The goal is to assess the qualifications and suitability of candidates based on merit, and to provide a positive experience for all candidates."
                ]
            },
            {
                heading: "Candidate Evaluation",
                paragraphs: [
                    "The candidate evaluation process involves assessing the performance of candidates during the interview process. This includes: \n- Using standardized evaluation criteria to assess the candidate's qualifications, skills, and experience. \n- Providing feedback and ratings for each candidate based on their performance in the interview. \n- Conducting reference checks to verify the candidate's qualifications and experience.",
                    "HR and the hiring manager work together to evaluate candidates, ensuring that all evaluations are fair and consistent.",
                    "The goal is to select the most qualified and suitable candidate for the position based on merit."
                ]
            },
            {
                heading: "Selection Decision",
                paragraphs: [
                    "The selection decision involves choosing the best candidate for the position based on the evaluation process. This includes: \n- Reviewing the feedback and ratings for each candidate. \n- Consulting with the hiring manager and relevant stakeholders to make the final selection decision. \n- Ensuring that the selection decision is based on merit and free from bias.",
                    "HR and the hiring manager work together to make the final selection decision, ensuring that all candidates are evaluated fairly and consistently.",
                    "The goal is to select the most qualified and suitable candidate for the position, and to provide a positive experience for all candidates."
                ]
            },
            {
                heading: "Communication with Candidates",
                paragraphs: [
                    "Communication with candidates is an important part of the interview process. This includes: \n- Providing candidates with clear and timely information about the status of their application. \n- Offering feedback to candidates who are not selected for the position. \n- Ensuring that all communication with candidates is professional and respectful.",
                    "HR is responsible for coordinating communication with candidates, ensuring that all candidates are kept informed and receive timely feedback.",
                    "Effective communication helps ensure a positive experience for all candidates, regardless of the outcome of the selection process."
                ]
            },
            {
                heading: "Onboarding and Integration",
                paragraphs: [
                    "The onboarding and integration process involves helping new employees integrate into the organization. This includes: \n- Providing new employees with information about the company's policies, procedures, and culture. \n- Introducing new employees to their team and key contacts within the organization. \n- Providing training and resources to help new employees understand their role and responsibilities.",
                    "HR and the hiring manager work together to ensure that the onboarding process is thorough and supportive, addressing any specific needs or concerns of the new employee.",
                    "Effective onboarding helps new employees feel welcomed and valued, and sets them up for success in their new role."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The effectiveness of the interview process and candidate selection is regularly monitored and reviewed to ensure continuous improvement. This involves: \n- Collecting feedback from candidates and interviewers about their experiences with the interview process. \n- Analyzing key metrics, such as time-to-hire, candidate satisfaction, and diversity statistics. \n- Identifying and addressing any areas for improvement in the interview and selection process.",
                    "HR is responsible for leading the monitoring and review process and implementing any necessary changes to ensure that the policy remains effective and relevant.",
                    "Regular monitoring and review help ensure that the organization continues to attract and select the best talent, and supports its goals of diversity and inclusion."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, the employee acknowledges that they have read and understood this Interview Process and Candidate Selection Policy and agree to abide by its terms and conditions. The employee further acknowledges that they have had the opportunity to ask questions and seek clarification about the policy."
            }
        ]
    };

    export const InterviewTravelReimbursementTemplate = {
        title: "Interview Travel Reimbursement Template",
        policyBlurb: "This policy outlines the procedures and guidelines for reimbursing travel expenses incurred by candidates attending interviews at [Your Company]. It ensures a fair and consistent approach to managing travel reimbursements.",
        preRead: "Use this template to establish a clear process for reimbursing candidates for travel expenses incurred during the interview process. This policy aims to attract a diverse range of candidates by reducing financial barriers to attending interviews.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Reimbursable Expenses",
                    "Non-Reimbursable Expenses",
                    "Travel Booking Procedures",
                    "Expense Submission Process",
                    "Approval and Reimbursement",
                    "Communication with Candidates",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we believe in providing equal opportunities to all candidates, regardless of their geographical location. To support this, we offer travel reimbursement for candidates invited to attend interviews at our company. This policy outlines the procedures and guidelines for reimbursing travel expenses, ensuring a fair and consistent approach."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to: \n- Provide a clear and structured process for reimbursing candidates for travel expenses incurred during the interview process. \n- Ensure fairness and consistency in the handling of travel reimbursements. \n- Reduce financial barriers for candidates attending interviews from distant locations. \n- Support the company's efforts to attract a diverse range of candidates."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all candidates invited to attend in-person interviews at [Your Company], regardless of the position they are applying for or their geographical location.",
                    "It covers travel expenses incurred for transportation, accommodation, meals, and other related expenses as outlined in this policy."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "Candidates invited to attend in-person interviews at [Your Company] are eligible for travel reimbursement.",
                    "Reimbursement is subject to prior approval from the Human Resources (HR) department and is limited to reasonable and necessary expenses incurred directly for the purpose of attending the interview."
                ]
            },
            {
                heading: "Reimbursable Expenses",
                paragraphs: [
                    "The following expenses are eligible for reimbursement: \n- Transportation: Airfare, train fare, bus fare, or mileage for personal vehicle use. \n- Accommodation: Hotel or other lodging expenses for the duration of the interview stay. \n- Meals: Reasonable meal expenses incurred during travel and stay. \n- Miscellaneous: Other necessary expenses such as parking fees and tolls.",
                    "All reimbursable expenses must be supported by original receipts and must be deemed reasonable and necessary by the HR department."
                ]
            },
            {
                heading: "Non-Reimbursable Expenses",
                paragraphs: [
                    "The following expenses are not eligible for reimbursement: \n- Personal entertainment (e.g., movies, sightseeing, etc.). \n- Alcoholic beverages. \n- Personal items (e.g., toiletries, clothing, etc.). \n- Any expenses incurred by accompanying persons (e.g., family members, friends).",
                    "Candidates are advised to review this list carefully and seek clarification from the HR department if they have any questions regarding reimbursable expenses."
                ]
            },
            {
                heading: "Travel Booking Procedures",
                paragraphs: [
                    "Candidates are responsible for making their own travel arrangements in consultation with the HR department. This includes booking transportation and accommodation as necessary.",
                    "Candidates are encouraged to book travel arrangements that are cost-effective and convenient. The HR department may provide guidance or assistance with travel bookings if needed.",
                    "Any changes to travel plans must be communicated to the HR department as soon as possible."
                ]
            },
            {
                heading: "Expense Submission Process",
                paragraphs: [
                    "Candidates must submit a detailed expense report along with original receipts for all reimbursable expenses. The expense report should include: \n- A list of expenses with corresponding receipts. \n- Dates and purpose of each expense. \n- Total amount of expenses incurred.",
                    "Expense reports should be submitted to the HR department within [specified time frame, e.g., 30 days] of the interview date.",
                    "Incomplete or late submissions may result in delays or denial of reimbursement."
                ]
            },
            {
                heading: "Approval and Reimbursement",
                paragraphs: [
                    "The HR department will review all submitted expense reports and receipts for accuracy and compliance with this policy.",
                    "Approved expenses will be reimbursed to candidates within [specified time frame, e.g., 30 days] of receiving the expense report.",
                    "Reimbursements will be made via [specified method, e.g., bank transfer, check, etc.]. Candidates will be notified once the reimbursement has been processed."
                ]
            },
            {
                heading: "Communication with Candidates",
                paragraphs: [
                    "Clear and timely communication with candidates is essential for a positive interview experience. This includes: \n- Providing candidates with a copy of this policy and guidelines for travel reimbursement. \n- Answering any questions candidates may have regarding travel arrangements and reimbursements. \n- Keeping candidates informed of the status of their expense submissions and reimbursements.",
                    "The HR department is responsible for ensuring that candidates receive all necessary information and support related to travel reimbursement."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The effectiveness of this policy will be regularly monitored and reviewed to ensure it meets the needs of candidates and the company. This involves: \n- Collecting feedback from candidates and HR staff regarding the travel reimbursement process. \n- Analyzing data on travel expenses and reimbursement trends. \n- Identifying any areas for improvement and making necessary adjustments to the policy.",
                    "The HR department is responsible for leading the monitoring and review process and implementing any necessary changes to ensure the policy remains effective and relevant."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, the candidate acknowledges that they have read and understood this Interview Travel Reimbursement Policy and agree to abide by its terms and conditions. The candidate further acknowledges that they have had the opportunity to ask questions and seek clarification about the policy."
            }
        ]
    };

    
    export const ITAndComputerUsageTemplate = {
        title: "IT and Computer Usage Template",
        policyBlurb: "This policy outlines the guidelines and expectations for the use of IT resources and computer systems within [Your Company].",
        preRead: "Use this template to develop a comprehensive policy that ensures the appropriate and secure use of IT and computer resources by employees, contractors, and partners.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "General Use and Ownership",
                    "Security and Proprietary Information",
                    "Unacceptable Use",
                    "System and Network Activities",
                    "Internet and Email Use",
                    "Software and Hardware Use",
                    "Data Protection and Privacy",
                    "Monitoring and Compliance",
                    "Enforcement",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "This IT and Computer Usage Policy outlines the standards and guidelines for the use of IT resources and computer systems at [Your Company]. The policy aims to protect the company’s IT assets, ensure compliance with legal and regulatory requirements, and promote a secure and efficient working environment."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear guidelines for the appropriate use of [Your Company]'s IT resources, including computers, networks, software, and data. By adhering to these guidelines, employees can help maintain the security and integrity of company systems and data, and ensure that IT resources are used effectively and responsibly."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, consultants, temporary staff, and other workers at [Your Company], including all personnel affiliated with third parties.",
                    "It covers all IT resources owned, leased, or operated by [Your Company], including but not limited to computers, servers, networks, software, and data."
                ]
            },
            {
                heading: "General Use and Ownership",
                paragraphs: [
                    "[Your Company]'s IT resources are provided for business purposes to support the company's operations. Limited personal use is permitted, provided it does not interfere with job responsibilities or productivity.",
                    "Users should have no expectation of privacy when using company IT resources. [Your Company] reserves the right to monitor and audit usage to ensure compliance with this policy."
                ]
            },
            {
                heading: "Security and Proprietary Information",
                paragraphs: [
                    "Users must ensure the confidentiality, integrity, and availability of sensitive and proprietary information. This includes taking appropriate measures to protect data from unauthorized access, disclosure, alteration, and destruction.",
                    "Access to proprietary and sensitive information should be strictly controlled and limited to authorized individuals only. Users must follow all security protocols and procedures to safeguard company data."
                ]
            },
            {
                heading: "Unacceptable Use",
                paragraphs: [
                    "Engaging in illegal activities or using IT resources for any unlawful purpose is strictly prohibited.",
                    "Accessing, downloading, or distributing inappropriate, offensive, or malicious content is not allowed.",
                    "Using IT resources for personal gain, non-business-related activities, or activities that could harm the company's reputation is prohibited."
                ]
            },
            {
                heading: "System and Network Activities",
                paragraphs: [
                    "Users must not attempt to bypass security controls, gain unauthorized access to systems or networks, or engage in activities that could compromise the security or performance of IT resources.",
                    "Installing unauthorized software or hardware on company systems is strictly prohibited. All installations must be approved and performed by the IT department."
                ]
            },
            {
                heading: "Internet and Email Use",
                paragraphs: [
                    "Internet access is provided primarily for business purposes. Excessive personal use is discouraged and should not interfere with job responsibilities.",
                    "Users must not send unsolicited emails (spam), engage in phishing activities, or use email for personal gain or non-business-related purposes."
                ]
            },
            {
                heading: "Software and Hardware Use",
                paragraphs: [
                    "Only authorized software and hardware should be used on company systems. Users must not install, download, or use software or hardware that has not been approved by the IT department.",
                    "All software and hardware must be used in accordance with licensing agreements and company policies. Unauthorized copying or distribution of software is strictly prohibited."
                ]
            },
            {
                heading: "Data Protection and Privacy",
                paragraphs: [
                    "Users must comply with all data protection and privacy laws and regulations when handling personal and sensitive data. This includes following company policies and procedures for data protection and privacy.",
                    "Personal and sensitive data must be stored securely and accessed only by authorized individuals. Users must report any data breaches or suspected breaches immediately to the IT department."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "[Your Company] reserves the right to monitor and audit the use of IT resources to ensure compliance with this policy. This includes monitoring network traffic, email communications, and access to systems and data.",
                    "Users are expected to cooperate with monitoring and auditing activities. Failure to comply with this policy or any related monitoring and auditing activities may result in disciplinary action."
                ]
            },
            {
                heading: "Enforcement",
                paragraphs: [
                    "Violations of this policy may result in disciplinary action, up to and including termination of employment. [Your Company] may also take legal action against individuals who engage in illegal activities or other actions that harm the company.",
                    "Employees are encouraged to report any violations of this policy to their supervisor or the IT department. Reports will be investigated promptly and appropriate action will be taken."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in technology, business practices, and legal requirements.",
                    "Employees will be notified of any significant changes to this policy. It is the responsibility of all users to stay informed about and comply with the most current version of the policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All users must sign or electronically acknowledge that they have read, understood, and agreed to comply with this IT and Computer Usage Policy. Acknowledgment forms must be submitted to the HR department and will be kept on file."
            }
        ]
    };

    export const LeaveOfAbsenceForMedicalReasonsTemplate = {
        title: "Leave of Absence for Medical Reasons Template",
        policyBlurb: "This policy outlines the guidelines and procedures for employees requesting a leave of absence for medical reasons at [Your Company].",
        preRead: "Use this template to develop a comprehensive policy that ensures employees can take necessary time off for medical reasons while maintaining their job security and benefits.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Application Process",
                    "Medical Certification",
                    "Duration of Leave",
                    "Benefits During Leave",
                    "Return to Work",
                    "Confidentiality",
                    "Non-Retaliation",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "This Leave of Absence for Medical Reasons policy outlines the procedures and guidelines for employees at [Your Company] who need to take time off for medical reasons. The policy aims to provide employees with the necessary support and resources to manage their health while ensuring the continuity of business operations."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a clear process for requesting, approving, and managing leaves of absence for medical reasons. By adhering to this policy, employees can take the necessary time off to recover from medical conditions, and the company can maintain a structured approach to managing such absences."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Your Company], including full-time, part-time, and temporary staff.",
                    "It covers all types of medical leaves, including short-term and long-term absences due to illness, injury, surgery, or other medical conditions that require time off from work."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "To be eligible for a medical leave of absence, employees must provide appropriate medical documentation from a licensed healthcare provider.",
                    "Employees must have completed a minimum of [X] months of continuous service with [Your Company] to qualify for a medical leave of absence."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees must submit a formal written request for a medical leave of absence to their immediate supervisor or the HR department at least [X] days in advance, unless the medical condition requires immediate leave.",
                    "The request must include the expected start date, duration of the leave, and medical documentation supporting the need for the leave."
                ]
            },
            {
                heading: "Medical Certification",
                paragraphs: [
                    "Employees must provide a medical certification from a licensed healthcare provider that outlines the nature of the medical condition and the need for leave.",
                    "The company reserves the right to request additional medical documentation or an independent medical examination to verify the need for leave."
                ]
            },
            {
                heading: "Duration of Leave",
                paragraphs: [
                    "The duration of the medical leave will be determined based on the medical certification provided by the healthcare provider and the specific needs of the employee.",
                    "Employees are entitled to a maximum of [X] weeks/months of medical leave per year. Extensions may be granted on a case-by-case basis with appropriate medical documentation."
                ]
            },
            {
                heading: "Benefits During Leave",
                paragraphs: [
                    "During the medical leave of absence, employees will continue to receive health insurance benefits as per company policy.",
                    "Other benefits, such as paid time off (PTO) and retirement contributions, may be affected based on the duration of the leave and company policies."
                ]
            },
            {
                heading: "Return to Work",
                paragraphs: [
                    "Employees must provide a medical release from their healthcare provider indicating their ability to return to work and any necessary accommodations.",
                    "The company will work with the employee to facilitate a smooth transition back to work, including any required adjustments or accommodations."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All medical information and documentation provided by employees will be kept confidential and stored securely in accordance with company policy and applicable laws.",
                    "Only authorized personnel will have access to medical records, and information will be disclosed only on a need-to-know basis."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraphs: [
                    "Employees will not be subject to retaliation or discrimination for requesting or taking a medical leave of absence.",
                    "Any concerns about retaliation or discrimination should be reported to the HR department for investigation and resolution."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in laws, regulations, and company practices.",
                    "Employees will be notified of any significant changes to this policy. It is the responsibility of all employees to stay informed about and comply with the most current version of the policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Leave of Absence for Medical Reasons policy. Acknowledgment forms must be submitted to the HR department and will be kept on file."
            }
        ]
    };

    export const NewHireOnboardingTemplate = {
        title: "New Hire Onboarding Template",
        policyBlurb: "This policy outlines the procedures and guidelines for onboarding new hires at [Your Company], ensuring they are welcomed, informed, and equipped to succeed in their new roles.",
        preRead: "Use this template to develop a comprehensive onboarding program that facilitates a smooth transition for new employees into the company, promotes a positive work environment, and ensures alignment with organizational goals and culture.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Pre-Boarding Activities",
                    "First Day Orientation",
                    "First Week Integration",
                    "First Month Follow-Up",
                    "Role-Specific Training",
                    "Cultural Assimilation",
                    "Performance Expectations",
                    "Mentorship and Support",
                    "Feedback and Evaluation",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we believe that effective onboarding is crucial to the success and satisfaction of our new hires. This New Hire Onboarding policy provides a structured and comprehensive framework to ensure that all new employees are properly integrated into the company, understand their roles, and are set up for long-term success. Our goal is to create a welcoming and supportive environment where new hires can quickly become productive and feel valued as part of the team."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline the steps and procedures involved in onboarding new hires at [Your Company]. By implementing a consistent and thorough onboarding process, we aim to enhance employee engagement, reduce turnover, and ensure that new hires have the knowledge and resources they need to perform their duties effectively. This policy covers the entire onboarding journey, from pre-boarding activities to long-term integration and support."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all new hires at [Your Company], including full-time, part-time, and temporary employees across all departments and locations.",
                    "It encompasses all stages of the onboarding process, from the acceptance of the job offer through the first six months of employment."
                ]
            },
            {
                heading: "Pre-Boarding Activities",
                paragraphs: [
                    "Before the new hire's first day, the HR department will coordinate with the hiring manager to prepare all necessary materials and documentation. This includes sending a welcome email, providing information about the first day, and ensuring all employment forms are completed.",
                    "The new hire will be given access to the company portal where they can review the employee handbook, company policies, and other relevant documents."
                ]
            },
            {
                heading: "First Day Orientation",
                paragraphs: [
                    "On the first day, new hires will participate in an orientation session that includes a tour of the facility, an introduction to key personnel, and an overview of company policies and procedures.",
                    "The new hire will receive their work equipment, including a computer, phone, and any other necessary tools, and will be set up with access to company systems and email."
                ]
            },
            {
                heading: "First Week Integration",
                paragraphs: [
                    "During the first week, the new hire will meet with their supervisor to discuss their role, responsibilities, and performance expectations. This meeting will also cover the company's mission, values, and goals.",
                    "The new hire will be introduced to their team and will participate in team meetings and activities to foster relationships and integration."
                ]
            },
            {
                heading: "First Month Follow-Up",
                paragraphs: [
                    "Within the first month, the new hire will have regular check-ins with their supervisor to discuss progress, address any questions or concerns, and provide feedback on the onboarding experience.",
                    "The new hire will also complete any required compliance training and begin role-specific training to develop the skills needed for their position."
                ]
            },
            {
                heading: "Role-Specific Training",
                paragraphs: [
                    "Role-specific training will be provided to ensure the new hire has a thorough understanding of their job duties and responsibilities. This training will be tailored to the new hire's position and may include on-the-job training, e-learning modules, and shadowing experienced colleagues.",
                    "Training progress will be monitored, and additional support will be provided as needed to ensure the new hire's success."
                ]
            },
            {
                heading: "Cultural Assimilation",
                paragraphs: [
                    "To promote cultural assimilation, new hires will be encouraged to participate in company events, social activities, and team-building exercises. This helps new employees feel connected to the company culture and build strong working relationships.",
                    "New hires will also be educated about the company's diversity and inclusion initiatives and will be encouraged to contribute to creating an inclusive work environment."
                ]
            },
            {
                heading: "Performance Expectations",
                paragraphs: [
                    "Clear performance expectations will be set for new hires, including specific goals and objectives that align with the company's strategic priorities. Regular performance reviews will be conducted to assess progress and provide constructive feedback.",
                    "Supervisors will work with new hires to create development plans that outline the steps needed to achieve their goals and advance in their careers."
                ]
            },
            {
                heading: "Mentorship and Support",
                paragraphs: [
                    "Each new hire will be paired with a mentor who can provide guidance, support, and advice during the onboarding process and beyond. Mentors will help new hires navigate the company culture, understand expectations, and develop their skills.",
                    "Regular mentorship meetings will be scheduled to ensure ongoing support and to address any challenges or concerns the new hire may have."
                ]
            },
            {
                heading: "Feedback and Evaluation",
                paragraphs: [
                    "The onboarding process will include regular opportunities for new hires to provide feedback on their experience. This feedback will be used to continuously improve the onboarding program and address any issues.",
                    "At the end of the onboarding period, a comprehensive evaluation will be conducted to assess the new hire's integration, performance, and overall satisfaction with the onboarding process."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in company practices, industry standards, and regulatory requirements.",
                    "Employees will be informed of any significant changes to this policy. It is the responsibility of all employees to stay informed about and comply with the most current version of the policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All new hires must sign or electronically acknowledge that they have read, understood, and agreed to comply with this New Hire Onboarding policy. Acknowledgment forms must be submitted to the HR department and will be kept on file."
            }
        ]
    };

    export const NoticeOfTerminationTemplate = {
        title: "Notice of Termination Template",
        policyBlurb: "This policy outlines the procedures and guidelines for issuing a Notice of Termination at [Your Company], ensuring a fair, legal, and respectful termination process.",
        preRead: "Use this template to develop a comprehensive Notice of Termination policy that facilitates a clear, respectful, and legally compliant termination process for employees.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Grounds for Termination",
                    "Notice Period",
                    "Procedure for Issuing Notice",
                    "Communication Guidelines",
                    "Severance and Final Pay",
                    "Return of Company Property",
                    "Exit Interview",
                    "Employee Support and Counseling",
                    "Legal Considerations",
                    "Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Your Company], we understand that terminating an employee is a sensitive and serious matter. This Notice of Termination policy provides a structured and respectful approach to handling terminations, ensuring that all procedures are followed in compliance with legal standards and company values. Our goal is to conduct terminations in a manner that is transparent, fair, and supportive for all parties involved."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline the steps and procedures for issuing a Notice of Termination at [Your Company]. By implementing a clear and consistent termination process, we aim to mitigate risks, protect the company’s interests, and treat employees with respect and dignity during the termination process. This policy covers all aspects of the termination procedure, from identifying grounds for termination to conducting exit interviews and providing support to departing employees."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees at [Your Company], including full-time, part-time, and temporary staff across all departments and locations.",
                    "It encompasses all types of terminations, including voluntary resignations, involuntary dismissals, and layoffs."
                ]
            },
            {
                heading: "Grounds for Termination",
                paragraphs: [
                    "Terminations at [Your Company] may be based on various grounds, including but not limited to: poor performance, misconduct, redundancy, violation of company policies, and mutual agreement.",
                    "Specific examples and scenarios for each ground for termination should be clearly documented and communicated to employees to ensure understanding and transparency."
                ]
            },
            {
                heading: "Notice Period",
                paragraphs: [
                    "The notice period required for termination will vary based on the employee's position, tenure, and the nature of the termination. [Your Company] adheres to legal requirements and industry best practices when determining the appropriate notice period.",
                    "Employees are expected to fulfill their duties and responsibilities during the notice period unless otherwise specified. In some cases, payment in lieu of notice may be provided."
                ]
            },
            {
                heading: "Procedure for Issuing Notice",
                paragraphs: [
                    "The procedure for issuing a Notice of Termination involves several steps to ensure a fair and transparent process. This includes documentation of the reasons for termination, review and approval by HR and legal departments, and scheduling a meeting with the employee to deliver the notice in person.",
                    "During the termination meeting, the reasons for termination, effective date, notice period, and next steps will be clearly communicated to the employee. The meeting should be conducted in a private setting with respect and professionalism."
                ]
            },
            {
                heading: "Communication Guidelines",
                paragraphs: [
                    "Clear and compassionate communication is crucial during the termination process. Managers and HR representatives should be trained to handle termination discussions with sensitivity and respect.",
                    "Employees should be informed of their rights and options, including severance pay, benefits, and support services. Follow-up communication should be provided in writing to ensure clarity and record-keeping."
                ]
            },
            {
                heading: "Severance and Final Pay",
                paragraphs: [
                    "Severance pay and final compensation will be determined based on company policy, employment contracts, and applicable laws. [Your Company] aims to provide fair and competitive severance packages to support transitioning employees.",
                    "Final pay, including any outstanding wages, accrued vacation, and bonuses, will be processed and provided to the employee in a timely manner. Employees will receive a detailed breakdown of their final pay."
                ]
            },
            {
                heading: "Return of Company Property",
                paragraphs: [
                    "Upon termination, employees are required to return all company property, including but not limited to: keys, access cards, laptops, mobile phones, and confidential documents.",
                    "HR and IT departments will coordinate the collection of company property and deactivation of access to company systems. Employees will be provided with a checklist to ensure all items are returned."
                ]
            },
            {
                heading: "Exit Interview",
                paragraphs: [
                    "An exit interview will be conducted with the departing employee to gather feedback on their experience at [Your Company] and identify any areas for improvement. The exit interview is an opportunity to address any concerns and gain insights into the employee’s perspective.",
                    "The information gathered during the exit interview will be confidential and used to enhance the company’s policies, work environment, and employee satisfaction."
                ]
            },
            {
                heading: "Employee Support and Counseling",
                paragraphs: [
                    "Terminated employees will be offered support and counseling services to assist them during the transition. This may include career counseling, job placement assistance, and access to an Employee Assistance Program (EAP).",
                    "Providing support helps to maintain a positive relationship with departing employees and demonstrates [Your Company]'s commitment to their well-being."
                ]
            },
            {
                heading: "Legal Considerations",
                paragraphs: [
                    "The termination process will be conducted in compliance with all applicable laws and regulations. Legal counsel will be consulted to ensure that all actions taken are legally sound and that the company is protected from potential legal challenges.",
                    "Documentation of the termination process, including the reasons for termination and all communications with the employee, will be maintained to provide a clear record and support any future legal proceedings."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in legal requirements, industry standards, and company practices.",
                    "Employees will be informed of any significant changes to this policy. It is the responsibility of all employees to stay informed about and comply with the most current version of the policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must sign or electronically acknowledge that they have read, understood, and agreed to comply with this Notice of Termination policy. Acknowledgment forms must be submitted to the HR department and will be kept on file."
            }
        ]
    };
    

    export const OccupationalHealthAndSafetyPolicyTemplate = {
        title: "Occupational Health and Safety Policy Template",
        policyBlurb: "This policy outlines our commitment to ensuring a safe and healthy work environment for all employees.",
        preRead: "Use this template to develop a policy that prioritizes occupational health and safety, promoting a culture of prevention and compliance with relevant regulations.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Responsibilities",
                    "Risk Assessment and Management",
                    "Training and Communication",
                    "Incident Reporting and Investigation",
                    "Emergency Preparedness and Response",
                    "Health and Wellness Programs",
                    "Monitoring and Review",
                    "Compliance and Legal Requirements",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our company is committed to providing a safe and healthy work environment for all employees, contractors, and visitors. This policy outlines our approach to occupational health and safety management."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to prevent workplace injuries and illnesses, comply with occupational health and safety legislation, and promote a culture of safety throughout the organization."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, visitors, and anyone else involved in company activities.",
                    "It covers all workplaces under our control, including offices, job sites, and remote work locations."
                ]
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Management is responsible for providing and maintaining a safe work environment, ensuring compliance with health and safety standards, and providing necessary resources.",
                    "Employees are responsible for following safe work practices, reporting hazards, and participating in health and safety programs."
                ]
            },
            {
                heading: "Risk Assessment and Management",
                paragraphs: [
                    "Regular risk assessments will be conducted to identify hazards and assess risks associated with work activities.",
                    "Control measures will be implemented to eliminate or minimize risks to an acceptable level."
                ]
            },
            {
                heading: "Training and Communication",
                paragraphs: [
                    "Employees will receive training on occupational health and safety policies, procedures, and safe work practices relevant to their roles.",
                    "Effective communication channels will be maintained to ensure employees are informed about health and safety matters."
                ]
            },
            {
                heading: "Incident Reporting and Investigation",
                paragraphs: [
                    "All workplace incidents, injuries, and near misses must be reported promptly.",
                    "Incidents will be investigated to determine root causes and implement corrective actions to prevent recurrence."
                ]
            },
            {
                heading: "Emergency Preparedness and Response",
                paragraphs: [
                    "Emergency procedures will be established, communicated, and practiced regularly.",
                    "Emergency response teams will be designated and trained to handle potential emergencies."
                ]
            },
            {
                heading: "Health and Wellness Programs",
                paragraphs: [
                    "Health promotion and wellness programs will be implemented to support employees' physical and mental well-being.",
                    "Resources for stress management, ergonomic assessments, and health screenings will be provided."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "Performance metrics related to health and safety will be monitored regularly to evaluate the effectiveness of the program.",
                    "Periodic audits and inspections will be conducted to ensure compliance with policies and regulations."
                ]
            },
            {
                heading: "Compliance and Legal Requirements",
                paragraphs: [
                    "We are committed to complying with all applicable occupational health and safety laws, regulations, and standards.",
                    "Regular updates will be made to policies and procedures to reflect changes in legislation."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually or as necessary to ensure it remains relevant and effective.",
                    "Employees will be notified of any updates or changes to the policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge that they have read, understood, and agree to comply with this Occupational Health and Safety Policy."
            }
        ]
    };

    export const OrganizationalSuccessionPlanningPolicyTemplate = {
        title: "Organizational Succession Planning Policy Template",
        policyBlurb: "This policy outlines our commitment to identifying and developing talent to ensure continuity of leadership and organizational success.",
        preRead: "Use this template to develop a policy that establishes a systematic approach to succession planning, preparing the organization for future leadership transitions.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Key Definitions",
                    "Succession Planning Process",
                    "Identification of Critical Roles",
                    "Talent Assessment and Development",
                    "Leadership Development Programs",
                    "Succession Planning Metrics",
                    "Monitoring and Review",
                    "Training and Communication",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Succession planning is essential for ensuring continuity in leadership roles and maintaining organizational stability during leadership transitions. This policy outlines our commitment to identifying and developing talent to support our long-term success."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish a systematic approach to succession planning that ensures key leadership positions are filled with qualified internal candidates who are prepared to assume greater responsibilities."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all leadership and critical roles within the organization.",
                    "It encompasses all departments and levels of the organization where succession planning is deemed necessary."
                ]
            },
            {
                heading: "Key Definitions",
                paragraphs: [
                    "**Succession Planning:** The process of identifying and developing potential successors for key leadership positions.",
                    "**Critical Roles:** Positions within the organization that are essential for achieving strategic objectives and maintaining operational continuity."
                ]
            },
            {
                heading: "Succession Planning Process",
                paragraphs: [
                    "Succession planning will be conducted in a structured and ongoing manner.",
                    "The process includes identifying critical roles, assessing talent, developing potential successors, and monitoring progress."
                ]
            },
            {
                heading: "Identification of Critical Roles",
                paragraphs: [
                    "Critical roles will be identified based on their impact on organizational goals and potential risk of disruption if left vacant.",
                    "These roles will be periodically reviewed and updated to align with organizational strategy."
                ]
            },
            {
                heading: "Talent Assessment and Development",
                paragraphs: [
                    "Potential successors for critical roles will be identified through a combination of performance evaluations, skills assessments, and leadership potential.",
                    "Individual development plans will be created to address skill gaps and prepare identified successors for future roles."
                ]
            },
            {
                heading: "Leadership Development Programs",
                paragraphs: [
                    "Development programs will be established to groom high-potential employees for future leadership roles.",
                    "These programs may include mentoring, coaching, job rotations, and specialized training."
                ]
            },
            {
                heading: "Succession Planning Metrics",
                paragraphs: [
                    "Key performance indicators (KPIs) will be established to measure the effectiveness of succession planning efforts.",
                    "Metrics may include succession fill rate, time-to-fill critical roles, and employee satisfaction with career development opportunities."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The effectiveness of succession planning strategies will be regularly monitored and evaluated.",
                    "Reviews will ensure alignment with organizational goals and responsiveness to changing business needs."
                ]
            },
            {
                heading: "Training and Communication",
                paragraphs: [
                    "Training on succession planning processes and expectations will be provided to managers and employees involved in talent development.",
                    "Clear communication channels will be maintained to inform employees about succession planning initiatives and opportunities."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually or as needed to ensure it remains relevant and effective.",
                    "Updates will be made to reflect changes in organizational structure, leadership needs, or best practices in succession planning."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees involved in succession planning processes must acknowledge that they have read, understood, and agree to comply with this Organizational Succession Planning Policy."
            }
        ]
    };

    export const ParentalLeavePolicyTemplate = {
        title: "Parental Leave Policy Template",
        policyBlurb: "This policy outlines our commitment to supporting employees who are new parents or expecting a child, ensuring they have adequate leave and support during this important time.",
        preRead: "Use this template to establish a parental leave policy that provides clear guidelines and benefits for employees who are new parents or expectant parents.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Types of Parental Leave",
                    "Eligibility and Entitlement",
                    "Application Process",
                    "Leave Benefits",
                    "Returning to Work",
                    "Flexibility and Accommodation",
                    "Support and Resources",
                    "Communication and Confidentiality",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our company recognizes the importance of supporting employees during significant life events, such as becoming a parent. This policy aims to provide clear guidelines and benefits for employees who are new parents or expecting a child."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to ensure that our employees have access to adequate parental leave to bond with their newborn or adopted child, manage parental responsibilities, and maintain work-life balance."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, regardless of gender or marital status, who become parents through childbirth, adoption, or surrogacy.",
                    "It covers all types of parental leave provided by law and additional leave benefits offered by the company."
                ]
            },
            {
                heading: "Types of Parental Leave",
                paragraphs: [
                    "**Maternity Leave:** Leave for birth mothers to recover from childbirth and bond with their newborn.",
                    "**Paternity Leave:** Leave for fathers or non-birth parents to bond with their newborn or newly adopted child.",
                    "**Adoption Leave:** Leave for employees who adopt a child, providing time to bond and settle into the new family arrangement.",
                    "**Parental Leave:** Additional leave beyond maternity, paternity, or adoption leave to care for and bond with a newborn or adopted child."
                ]
            },
            {
                heading: "Eligibility and Entitlement",
                paragraphs: [
                    "Employees are eligible for parental leave if they have completed a specified length of service with the company, as outlined in our leave policy.",
                    "Entitlement to parental leave includes both statutory entitlements and additional leave benefits provided by the company."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees must notify their manager or HR department of their intention to take parental leave as soon as possible, providing the expected start date and duration of leave.",
                    "Written confirmation of leave details and any supporting documentation may be required."
                ]
            },
            {
                heading: "Leave Benefits",
                paragraphs: [
                    "During parental leave, employees may receive full or partial pay, as per company policy and statutory requirements.",
                    "Benefits such as healthcare coverage and accrual of vacation and sick leave will continue during the leave period."
                ]
            },
            {
                heading: "Returning to Work",
                paragraphs: [
                    "Employees will be supported in transitioning back to work after parental leave, with flexibility in return-to-work arrangements if needed.",
                    "Reasonable accommodations will be provided to facilitate a smooth return to full-time duties."
                ]
            },
            {
                heading: "Flexibility and Accommodation",
                paragraphs: [
                    "Flexible work arrangements may be considered during and after parental leave, including options for remote work or adjusted work hours.",
                    "Accommodations will be made for breastfeeding mothers, ensuring a supportive environment."
                ]
            },
            {
                heading: "Support and Resources",
                paragraphs: [
                    "Employees will have access to resources such as parenting workshops, counseling services, and lactation rooms.",
                    "Managers and HR will provide guidance on parental leave benefits and available support."
                ]
            },
            {
                heading: "Communication and Confidentiality",
                paragraphs: [
                    "All communication regarding parental leave will be handled confidentially, respecting the employee's privacy and personal circumstances.",
                    "Managers and colleagues will be informed of the employee's leave duration and expected return date to facilitate planning."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually or as needed to ensure compliance with legal requirements and alignment with best practices.",
                    "Updates will be communicated to employees, and their feedback on the policy will be considered."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By taking parental leave, employees acknowledge that they have read, understood, and agreed to comply with this Parental Leave Policy."
            }
        ]
    };

    export const PetsInTheWorkplacePolicyTemplate = {
        title: "Pets in the Workplace Policy",
        policyBlurb: "This policy outlines the guidelines and expectations for bringing pets into the workplace to create a positive and productive environment for all employees.",
        preRead: "Use this template to establish rules and procedures for allowing pets in the workplace, promoting a pet-friendly environment while ensuring the safety, health, and well-being of employees and pets alike.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Types of Pets Allowed",
                    "Guidelines for Bringing Pets to Work",
                    "Pet Behavior and Safety",
                    "Health and Hygiene",
                    "Liability and Responsibility",
                    "Allergies and Sensitivities",
                    "Policy Enforcement",
                    "Feedback and Adaptation",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Our company recognizes the positive impact pets can have on workplace morale and employee well-being. This policy is designed to create a harmonious environment where pets are welcomed responsibly."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish guidelines for bringing pets into the workplace, ensuring a safe, healthy, and productive environment for all employees while promoting a pet-friendly culture."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees who wish to bring their pets to the workplace.",
                    "It covers all types of pets, including dogs, cats, and other domestic animals, brought into company premises."
                ]
            },
            {
                heading: "Types of Pets Allowed",
                paragraphs: [
                    "Employees are permitted to bring well-behaved and vaccinated pets that pose no threat or disruption to others.",
                    "Approval from management may be required before bringing certain types of pets or exotic animals into the workplace."
                ]
            },
            {
                heading: "Guidelines for Bringing Pets to Work",
                paragraphs: [
                    "Employees must obtain prior approval from their manager or HR department before bringing a pet to the workplace.",
                    "Pets should be clean, groomed, and well-behaved, with no history of aggressive behavior towards people or other animals."
                ]
            },
            {
                heading: "Pet Behavior and Safety",
                paragraphs: [
                    "Pets must be supervised and kept under control at all times.",
                    "Owners are responsible for ensuring their pet does not disturb or cause harm to coworkers."
                ]
            },
            {
                heading: "Health and Hygiene",
                paragraphs: [
                    "Pets should be up-to-date on vaccinations and free of parasites.",
                    "Employees must clean up after their pets and maintain a hygienic workspace."
                ]
            },
            {
                heading: "Liability and Responsibility",
                paragraphs: [
                    "Owners are liable for any damage caused by their pets to company property or to other employees.",
                    "Employees bringing pets to work assume full responsibility for their pet's behavior and well-being."
                ]
            },
            {
                heading: "Allergies and Sensitivities",
                paragraphs: [
                    "Employees with allergies or sensitivities to animals should notify HR in advance.",
                    "Efforts will be made to accommodate employees with allergies, such as designated pet-free areas."
                ]
            },
            {
                heading: "Policy Enforcement",
                paragraphs: [
                    "Non-compliance with this policy may result in the pet owner being asked to remove their pet from the premises.",
                    "Repeated violations may lead to disciplinary action in accordance with company policies."
                ]
            },
            {
                heading: "Feedback and Adaptation",
                paragraphs: [
                    "Employees are encouraged to provide feedback on the policy to HR or management.",
                    "This policy will be reviewed periodically to address any concerns and ensure its effectiveness."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By bringing a pet to the workplace, employees acknowledge that they have read, understood, and agree to comply with this Pets in the Workplace Policy."
            }
        ]
    };

    export const PolicyRevisionAndEmployeeAcknowledgmentPolicyTemplate = {
        title: "Policy Revision and Employee Acknowledgment Policy Template",
        policyBlurb: "This policy outlines the procedures for policy revision and employee acknowledgment to ensure that all employees are informed about and comply with organizational policies.",
        preRead: "Use this template to establish guidelines for reviewing, updating, and communicating policy changes effectively. It includes procedures for obtaining employee acknowledgment of policy updates to promote compliance and accountability.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Policy Review and Revision Process",
                    "Communication of Policy Changes",
                    "Employee Acknowledgment",
                    "Monitoring Compliance",
                    "Policy Exceptions",
                    "Policy Enforcement",
                    "Feedback and Improvement",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Policy revision is essential for maintaining relevance and compliance with evolving legal requirements, industry standards, and organizational needs. This policy ensures that employees are aware of and adhere to updated policies."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish procedures for reviewing, revising, and communicating changes to organizational policies. It aims to ensure that all employees understand their responsibilities and obligations under current policies."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, and stakeholders who are subject to organizational policies.",
                    "It encompasses all policies, procedures, guidelines, and standards set forth by the organization."
                ]
            },
            {
                heading: "Policy Review and Revision Process",
                paragraphs: [
                    "Organizational policies will be reviewed periodically to assess their effectiveness and relevance.",
                    "Policy revisions may be initiated due to changes in laws, regulations, business practices, or internal needs."
                ]
            },
            {
                heading: "Communication of Policy Changes",
                paragraphs: [
                    "Policy changes will be communicated to employees through official channels, such as email, intranet, or staff meetings.",
                    "Employees will be notified of significant policy updates and provided with access to the revised policies."
                ]
            },
            {
                heading: "Employee Acknowledgment",
                paragraphs: [
                    "Employees are required to acknowledge receipt and understanding of updated policies.",
                    "Acknowledgment may be obtained electronically or through signed acknowledgment forms."
                ]
            },
            {
                heading: "Monitoring Compliance",
                paragraphs: [
                    "Managers and HR personnel will monitor employee compliance with updated policies.",
                    "Regular audits or assessments may be conducted to ensure adherence to policy requirements."
                ]
            },
            {
                heading: "Policy Exceptions",
                paragraphs: [
                    "Exceptions to policies may be considered on a case-by-case basis with approval from appropriate management or HR personnel.",
                    "Any exceptions must be documented and justified based on valid reasons."
                ]
            },
            {
                heading: "Policy Enforcement",
                paragraphs: [
                    "Non-compliance with organizational policies may result in disciplinary action, up to and including termination of employment.",
                    "Consistent enforcement of policies ensures fairness and maintains organizational standards."
                ]
            },
            {
                heading: "Feedback and Improvement",
                paragraphs: [
                    "Employees are encouraged to provide feedback on policy effectiveness and clarity.",
                    "Feedback will be used to improve policies and enhance employee understanding and compliance."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below or electronically acknowledging, employees confirm that they have received, read, understood, and agree to comply with the updated policies of the organization."
            }
        ]
    };

    
    export const PositionEliminationAndWorkforceReductionPolicyTemplate = {
        title: "Position Elimination and Workforce Reduction Policy",
        policyBlurb: "This policy outlines the procedures and principles for managing position elimination and workforce reduction in accordance with organizational goals and legal requirements.",
        preRead: "Use this template to develop a policy that ensures fair and transparent processes during times of restructuring or downsizing. It aims to mitigate the impact on affected employees and maintain organizational stability.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Principles",
                    "Process for Position Elimination",
                    "Workforce Reduction Criteria",
                    "Notification and Consultation",
                    "Support for Affected Employees",
                    "Alternative Employment Opportunities",
                    "Severance Package and Benefits",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Position elimination and workforce reduction are strategic measures taken by organizations to streamline operations, reduce costs, or adapt to changing market conditions. This policy provides guidelines for implementing these measures while prioritizing fairness and respect for employees."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear procedures and principles for managing position elimination and workforce reduction processes. It aims to minimize disruption, support affected employees, and uphold the organization's ethical and legal obligations."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, and temporary staff, who may be affected by position elimination or workforce reduction.",
                    "It covers all departments and levels within the organization."
                ]
            },
            {
                heading: "Principles",
                paragraphs: [
                    "Fairness: Decisions regarding position elimination and workforce reduction will be made objectively and without discrimination.",
                    "Transparency: The process will be transparent, and affected employees will be informed promptly and clearly.",
                    "Communication: Open communication will be maintained throughout the process, with opportunities for employees to ask questions and seek clarification.",
                    "Support: The organization is committed to supporting affected employees through counseling, career transition assistance, and access to resources."
                ]
            },
            {
                heading: "Process for Position Elimination",
                paragraphs: [
                    "Identification: Positions eligible for elimination will be identified based on operational needs, financial considerations, and strategic goals.",
                    "Evaluation: The impact of position elimination on affected employees, teams, and organizational functions will be evaluated.",
                    "Decision: Final decisions regarding position elimination will be made by [designated authority], considering input from relevant stakeholders."
                ]
            },
            {
                heading: "Workforce Reduction Criteria",
                paragraphs: [
                    "Selection Criteria: Criteria for selecting employees for workforce reduction may include performance evaluations, skills assessment, seniority, and business needs.",
                    "Approval: Workforce reduction plans will require approval from [designated authority] to ensure consistency and fairness."
                ]
            },
            {
                heading: "Notification and Consultation",
                paragraphs: [
                    "Affected employees will be notified of position elimination or workforce reduction in person by their immediate supervisor or HR representative.",
                    "Consultation: Employees will be given the opportunity to discuss the impact of these changes, seek clarification, and explore options."
                ]
            },
            {
                heading: "Support for Affected Employees",
                paragraphs: [
                    "Career Counseling: Affected employees will have access to career counseling services to explore alternative employment opportunities.",
                    "Training and Development: Training programs and resources will be provided to enhance skills and competencies for future employment."
                ]
            },
            {
                heading: "Alternative Employment Opportunities",
                paragraphs: [
                    "Internal Opportunities: Efforts will be made to identify internal job vacancies or transfer opportunities for affected employees.",
                    "External Resources: Information and support will be provided to help employees find job opportunities outside the organization."
                ]
            },
            {
                heading: "Severance Package and Benefits",
                paragraphs: [
                    "Severance Pay: Eligible employees will receive severance pay based on length of service and other relevant factors.",
                    "Benefits Continuation: The organization will outline benefits continuation options to ensure minimal disruption for affected employees."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, legal requirements, or best practices.",
                    "Employees will be notified of any significant changes to this policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge that they have received, read, and understood the Position Elimination and Workforce Reduction Policy."
            }
        ]
    };

    export const ProgressiveDisciplineAndConductPolicyTemplate = {
        title: "Progressive Discipline and Conduct Policy Template",
        policyBlurb: "This policy outlines the procedures and principles for addressing employee conduct and performance issues in a fair and constructive manner.",
        preRead: "Use this template to develop a policy that promotes a positive work environment while addressing behavior or performance concerns through progressive disciplinary measures.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Principles of Progressive Discipline",
                    "Types of Misconduct",
                    "Progressive Discipline Process",
                    "Conducting Investigations",
                    "Employee Rights and Due Process",
                    "Documentation",
                    "Appeals Process",
                    "Support and Training",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Progressive discipline is a methodical approach to managing employee conduct and performance issues. This policy aims to maintain a positive work environment while addressing behavior or performance concerns in a fair and consistent manner."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear procedures and principles for addressing employee conduct and performance issues. It aims to promote professionalism, accountability, and continuous improvement within the organization."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, and temporary staff, across all departments and levels within the organization.",
                    "It covers conduct-related issues and performance deficiencies that impact workplace behavior, productivity, or organizational values."
                ]
            },
            {
                heading: "Principles of Progressive Discipline",
                paragraphs: [
                    "Fairness: Discipline will be administered fairly and without discrimination.",
                    "Constructiveness: The primary goal of discipline is corrective rather than punitive, aimed at improving behavior and performance.",
                    "Consistency: Discipline will be applied consistently across all employees and situations.",
                    "Documentation: All disciplinary actions will be documented accurately and objectively."
                ]
            },
            {
                heading: "Types of Misconduct",
                paragraphs: [
                    "Examples of misconduct include but are not limited to: insubordination, attendance issues, workplace harassment, unethical behavior, and violation of company policies."
                ]
            },
            {
                heading: "Progressive Discipline Process",
                paragraphs: [
                    "Verbal Warning: A private discussion between the employee and supervisor to address the issue and clarify expectations.",
                    "Written Warning: Formal documentation of the issue, consequences of continued misconduct, and improvement expectations.",
                    "Suspension: Temporary removal from work with or without pay, depending on the severity of the misconduct.",
                    "Termination: Last resort action if misconduct persists despite prior warnings or for serious offenses."
                ]
            },
            {
                heading: "Conducting Investigations",
                paragraphs: [
                    "Objective: Investigations will be conducted objectively and without bias.",
                    "Confidentiality: Information related to investigations will be handled confidentially to protect the privacy of all parties involved."
                ]
            },
            {
                heading: "Employee Rights and Due Process",
                paragraphs: [
                    "Employees have the right to know the allegations against them and to provide their side of the story.",
                    "Due process will be followed to ensure fair treatment and the opportunity to appeal disciplinary actions."
                ]
            },
            {
                heading: "Documentation",
                paragraphs: [
                    "All disciplinary actions, including warnings, suspensions, and terminations, will be documented in writing.",
                    "Documentation will include details of the misconduct, actions taken, and employee responses."
                ]
            },
            {
                heading: "Appeals Process",
                paragraphs: [
                    "Employees may appeal disciplinary decisions through a formal process outlined in the organization's policies.",
                    "Appeals will be reviewed impartially, and decisions will be communicated in a timely manner."
                ]
            },
            {
                heading: "Support and Training",
                paragraphs: [
                    "Managers and supervisors will receive training on effective disciplinary practices, conflict resolution, and communication.",
                    "Employees will be provided with resources and support to address behavior or performance issues and improve."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually and updated as necessary to reflect changes in organizational needs, legal requirements, or best practices.",
                    "Employees will be informed of any significant changes to this policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge that they have received, read, and understood the Progressive Discipline and Conduct Policy."
            }
        ]
    };

    
    export const RecordRetentionAndDocumentManagementPolicyTemplate = {
        title: "Record Retention and Document Management Policy Template",
        policyBlurb: "This policy outlines the guidelines and procedures for the retention and management of records and documents at [Your Company], ensuring compliance, security, and efficiency.",
        preRead: "Use this template to establish a comprehensive policy that governs the creation, retention, storage, retrieval, and disposal of records and documents in your organization.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Responsibilities",
                    "Records Retention Schedule",
                    "Document Management",
                    "Storage and Security",
                    "Backup and Recovery",
                    "Retention Periods",
                    "Disposal and Destruction",
                    "Training and Awareness",
                    "Monitoring and Compliance",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "This Record Retention and Document Management Policy establishes guidelines for the systematic retention, storage, and disposal of records and documents at [Your Company]."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to ensure that [Your Company] complies with legal, regulatory, and business requirements for record retention and document management."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all records and documents created, received, maintained, and disposed of by employees, contractors, and agents of [Your Company].",
                    "It covers both physical and electronic records and documents."
                ]
            },
            {
                heading: "Definitions",
                paragraph: "Definitions of key terms used in this policy are provided in the company's Glossary of Terms."
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Management is responsible for overseeing the implementation and enforcement of this policy.",
                    "Employees are responsible for complying with the guidelines outlined in this policy."
                ]
            },
            {
                heading: "Records Retention Schedule",
                paragraph: "A Records Retention Schedule will be established to specify the retention periods for different categories of records and documents."
            },
            {
                heading: "Document Management",
                paragraphs: [
                    "Documents should be properly categorized, organized, and indexed for easy retrieval and reference.",
                    "Version control procedures should be implemented to manage document revisions."
                ]
            },
            {
                heading: "Storage and Security",
                paragraphs: [
                    "Physical records should be stored in secure, climate-controlled environments.",
                    "Electronic records should be stored on secure servers with access controls and encryption."
                ]
            },
            {
                heading: "Backup and Recovery",
                paragraphs: [
                    "Regular backups of electronic records should be performed to prevent data loss.",
                    "A disaster recovery plan should be in place to ensure timely recovery of records in the event of data loss or system failure."
                ]
            },
            {
                heading: "Retention Periods",
                paragraph: "Retention periods for different categories of records and documents should be based on legal, regulatory, operational, and historical requirements."
            },
            {
                heading: "Disposal and Destruction",
                paragraphs: [
                    "Records and documents should be disposed of securely and in accordance with the Records Retention Schedule.",
                    "Sensitive or confidential information should be shredded or permanently deleted to prevent unauthorized access."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraph: "Employees should receive training on record retention and document management policies and procedures."
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Regular audits and reviews should be conducted to ensure compliance with this policy.",
                    "Non-compliance may result in disciplinary action."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraph: "This policy will be reviewed annually and updated as necessary to reflect changes in legal or regulatory requirements."
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge receipt and understanding of this Record Retention and Document Management Policy."
            }
        ]
    };

    export const SampleAccidentReportingProcedureTemplate = {
        title: "Sample Accident Reporting Procedure Template",
        policyBlurb: "This Sample Accident Reporting Procedure outlines the steps to be followed in the event of an accident to ensure timely reporting, investigation, and corrective action.",
        preRead: "Use this template to establish a clear process for reporting accidents promptly and effectively, ensuring the safety and well-being of all individuals involved.",
        sections: [
            {
                heading: "Introduction",
                paragraph: "Accidents are unforeseen events that can occur in any workplace, posing risks to employee health and safety. Establishing a well-defined accident reporting procedure is essential to mitigate these risks and ensure the prompt and effective management of incidents when they occur."
            },
            {
                heading: "Purpose",
                paragraph: "The primary purpose of this procedure is to ensure the safety and well-being of employees by promptly addressing accidents, identifying root causes to prevent future occurrences, and complying with legal requirements for reporting workplace accidents. By implementing a structured accident reporting process, [Company Name] aims to foster a safe working environment where incidents are managed proactively and transparently."
            },
            {
                heading: "Scope",
                paragraph: "This procedure applies to all employees, contractors, visitors, and any other individuals present on [Company Name]'s premises or engaged in company-related activities. It covers all types of accidents, including injuries, near misses, and incidents resulting in property damage."
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Accident: An unexpected event that results in injury, damage, or loss.",
                    "Near Miss: A situation that could have resulted in an accident but did not cause harm.",
                    "Reporting Person: The individual responsible for reporting the accident."
                ]
            },
            {
                heading: "Procedure",
                subSections: [
                    {
                        heading: "Immediate Response",
                        paragraphs: [
                            "Upon discovering an accident or incident, immediate actions must be taken to ensure the safety of all individuals involved.",
                            "Provide immediate first aid or medical assistance as necessary.",
                            "Secure the accident scene to prevent further injuries or damage."
                        ]
                    },
                    {
                        heading: "Reporting the Accident",
                        paragraphs: [
                            "The Reporting Person must notify their supervisor or manager immediately after ensuring safety.",
                            "Use the designated Accident Report Form to document details of the accident, including date, time, location, individuals involved, witnesses, and a brief description of what happened.",
                            "Submit the completed Accident Report Form to the HR department within the specified timeframe."
                        ]
                    },
                    {
                        heading: "Investigation",
                        paragraphs: [
                            "Upon receiving the Accident Report Form, the HR department or designated safety officer will initiate an investigation promptly to determine the cause(s) of the accident.",
                            "Interview witnesses, review evidence, and gather relevant information to establish a clear understanding of the incident.",
                            "Identify corrective actions and preventive measures to mitigate risks and prevent similar accidents in the future."
                        ]
                    },
                    {
                        heading: "Corrective Actions",
                        paragraphs: [
                            "Based on the investigation findings, the HR department or safety officer will implement corrective actions to eliminate identified hazards.",
                            "Communicate preventive measures effectively to relevant stakeholders to ensure awareness and compliance."
                        ]
                    }
                ]
            },
            {
                heading: "Communication and Reporting",
                paragraphs: [
                    "Timely and accurate communication is crucial throughout the accident reporting process.",
                    "Ensure that all stakeholders, including affected employees and management, are informed of the incident and its resolution.",
                    "Maintain confidentiality and respect the privacy of individuals involved in the accident."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "Regularly monitor the effectiveness of the accident reporting procedure and associated corrective actions.",
                    "Conduct periodic reviews and updates to the procedure to incorporate lessons learned, changes in legislation, or improvements in industry best practices."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees must acknowledge their understanding and commitment to comply with this Sample Accident Reporting Procedure. Training and awareness programs will be conducted to ensure all staff members are familiar with their roles and responsibilities."
            }
        ]
    };

    export const SampleBusinessExpenseReimbursementProcedureTemplate = {
        title: "Sample Business Expense Reimbursement Procedure Template",
        policyBlurb: "This Sample Business Expense Reimbursement Procedure outlines the process for employees to request reimbursement of approved business expenses in accordance with company policy.",
        preRead: "Use this template to establish a clear and standardized process for submitting, approving, and reimbursing business expenses incurred by employees on behalf of [Company Name].",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Expense Eligibility and Guidelines",
                    "Expense Submission Process",
                    "Expense Review and Approval",
                    "Reimbursement Process",
                    "Receipts and Documentation",
                    "Expense Disputes and Exceptions",
                    "Monitoring and Reporting",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Managing business expenses effectively is crucial for maintaining financial transparency and ensuring that employees are reimbursed promptly for expenses incurred during the course of their duties. This procedure establishes guidelines and processes to streamline the reimbursement of business-related costs while maintaining compliance with company policies and regulatory requirements."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this procedure is to provide clear guidance on the submission, review, and reimbursement of business expenses to ensure consistency, fairness, and accuracy in financial transactions. By outlining these processes, [Company Name] aims to minimize delays in reimbursement and enhance accountability in expense reporting."
            },
            {
                heading: "Scope",
                paragraph: "This procedure applies to all employees, contractors, and authorized individuals who incur business-related expenses on behalf of [Company Name]. It covers expenses such as travel, meals, accommodations, transportation, supplies, and other costs necessary for conducting business activities."
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Business Expenses: Costs incurred by employees while performing duties or tasks that benefit [Company Name].",
                    "Reimbursement: Compensation provided to employees for eligible business expenses incurred in accordance with company policies.",
                    "Authorized Individual: Any person designated by [Company Name] with the authority to incur and approve business expenses."
                ]
            },
            {
                heading: "Expense Eligibility and Guidelines",
                paragraphs: [
                    "Employees must adhere to the company's expense reimbursement policy when incurring and submitting expenses.",
                    "Expenses must be necessary, reasonable, and directly related to business activities.",
                    "Non-reimbursable expenses include personal expenses, fines, penalties, and entertainment costs not directly related to business purposes."
                ]
            },
            {
                heading: "Expense Submission Process",
                paragraphs: [
                    "Employees are responsible for accurately recording expenses using the designated expense report form or software.",
                    "Include detailed information such as date, purpose, amount, and supporting documentation (e.g., receipts, invoices).",
                    "Submit the completed expense report to the designated approver within the specified timeframe."
                ]
            },
            {
                heading: "Expense Review and Approval",
                paragraphs: [
                    "Approvers review submitted expense reports to ensure compliance with company policies and accuracy of information.",
                    "Approvers may request additional information or clarification from employees regarding submitted expenses.",
                    "Approved expenses are processed for reimbursement through the designated financial system or department."
                ]
            },
            {
                heading: "Reimbursement Process",
                paragraphs: [
                    "Upon approval, reimbursements are processed within a reasonable timeframe to ensure timely compensation to employees.",
                    "Reimbursements may be issued via direct deposit, payroll deduction, or other approved methods.",
                    "Communicate reimbursement timelines and methods clearly to employees to manage expectations."
                ]
            },
            {
                heading: "Receipts and Documentation",
                paragraphs: [
                    "Employees must retain original receipts and documentation as proof of incurred expenses.",
                    "Ensure that receipts are legible, itemized, and include relevant details such as vendor name, date of purchase, and amount.",
                    "Failure to provide adequate documentation may delay or result in denial of reimbursement."
                ]
            },
            {
                heading: "Expense Disputes and Exceptions",
                paragraphs: [
                    "Employees who dispute expenses or reimbursement decisions should follow the company's escalation process outlined in the expense policy.",
                    "Documented exceptions to policy guidelines must be approved by designated authorities before reimbursement."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "Regularly monitor expense reimbursement processes to identify inefficiencies or areas for improvement.",
                    "Generate reports to analyze spending patterns, budget adherence, and compliance with expense policies.",
                    "Address any discrepancies or concerns promptly to maintain financial integrity and transparency."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This procedure will be reviewed annually or as needed to reflect changes in company policies, regulatory requirements, or industry best practices.",
                    "Notify employees of any updates or revisions to the expense reimbursement procedure and provide necessary training."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to acknowledge their understanding and compliance with this Sample Business Expense Reimbursement Procedure. By adhering to these guidelines, employees contribute to maintaining financial accountability and operational efficiency within [Company Name]."
            }
        ]
    };

    export const SampleCompanySickLeavePolicyTemplate = {
        title: "Sample Company Sick Leave Policy Template",
        policyBlurb: "This Sample Company Sick Leave Policy outlines the guidelines and procedures for employees to request and utilize sick leave benefits effectively and responsibly.",
        preRead: "Use this comprehensive template to establish a structured sick leave policy that promotes employee well-being, ensures consistency in leave management, and complies with legal requirements.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility and Entitlement",
                    "Accrual and Utilization",
                    "Requesting Sick Leave",
                    "Sick Leave Documentation",
                    "Paid vs. Unpaid Sick Leave",
                    "Leave Coordination with Other Benefits",
                    "Return to Work",
                    "Monitoring and Compliance",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we prioritize the health and well-being of our employees. This policy establishes guidelines and expectations regarding sick leave to ensure equitable treatment and support for all employees during periods of illness or medical necessity."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to provide a clear framework for managing sick leave, including accrual, utilization, and documentation procedures. It aims to maintain productivity, promote employee health, and comply with relevant employment laws and regulations."
            },
            {
                heading: "Scope",
                paragraph: "This policy applies to all regular full-time and part-time employees, temporary employees, contractors, and interns of [Company Name] who meet the eligibility criteria for sick leave benefits."
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Sick Leave: Authorized absence from work due to an employee's illness, injury, medical appointment, or health condition that prevents them from performing their job duties.",
                    "Accrual: The process of accumulating sick leave hours based on an employee's length of service, employment status (full-time or part-time), and company policy.",
                    "Certified Medical Professional: A licensed healthcare provider qualified to diagnose and treat medical conditions, including physicians, nurse practitioners, and licensed therapists."
                ]
            },
            {
                heading: "Eligibility and Entitlement",
                paragraphs: [
                    "All eligible employees are entitled to sick leave benefits as mandated by federal, state, and local laws or as per company policy, whichever is more favorable to the employee.",
                    "Employees must fulfill specific tenure and employment status requirements to qualify for sick leave benefits, which are outlined in the company's employee handbook or policy documents."
                ]
            },
            {
                heading: "Accrual and Utilization",
                paragraphs: [
                    "Sick leave accrual rates vary based on an employee's length of service and employment status (e.g., full-time or part-time). Accrual details are specified in the company's policy.",
                    "Employees may use accrued sick leave for their own illness, injury, medical appointments, or to care for a sick family member as defined by applicable family and medical leave laws."
                ]
            },
            {
                heading: "Requesting Sick Leave",
                paragraphs: [
                    "Employees must notify their supervisor or designated HR representative as soon as possible when requesting sick leave, preferably before the start of their shift or workday.",
                    "Provide advance notice when foreseeable, and follow company procedures for reporting absences and requesting leave to ensure smooth operational continuity."
                ]
            },
            {
                heading: "Sick Leave Documentation",
                paragraphs: [
                    "Employees may be required to provide medical certification or documentation for sick leave that exceeds a specified duration (e.g., more than three consecutive workdays).",
                    "Documentation should be submitted promptly and kept confidential in accordance with privacy laws and company policies regarding medical information."
                ]
            },
            {
                heading: "Paid vs. Unpaid Sick Leave",
                paragraphs: [
                    "Sick leave may be paid or unpaid depending on the employee's employment status (e.g., exempt or non-exempt), company policy, and applicable federal, state, or local laws.",
                    "Clearly communicate the conditions under which sick leave is paid or unpaid to employees to ensure transparency and consistent application of the policy."
                ]
            },
            {
                heading: "Leave Coordination with Other Benefits",
                paragraphs: [
                    "Sick leave may run concurrently with other leave benefits such as Family and Medical Leave Act (FMLA), short-term disability, or personal leave as permitted by applicable laws and company policy.",
                    "Ensure compliance with federal, state, and local regulations when coordinating sick leave with other benefits to avoid overlap or misuse of leave entitlements."
                ]
            },
            {
                heading: "Return to Work",
                paragraphs: [
                    "Employees returning from sick leave must provide clearance from a certified medical professional if required by company policy or as specified in the return-to-work guidelines.",
                    "Discuss return-to-work expectations, accommodations, or phased return options with the employee to facilitate a smooth transition back to their job duties."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "HR or designated personnel will monitor sick leave usage to ensure compliance with company policy, legal requirements, and fairness in application across all employees.",
                    "Address any potential misuse or abuse of sick leave promptly and in accordance with established disciplinary procedures outlined in the employee handbook or policy manual."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed annually or as needed to reflect changes in employment laws, regulations, or organizational needs.",
                    "Employees will be informed of any updates or revisions to the sick leave policy through company-wide announcements, email communications, or updated policy documents."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to acknowledge receipt and understanding of this Sample Company Sick Leave Policy. By adhering to these guidelines, employees contribute to maintaining a healthy work environment and promoting their well-being at [Company Name]."
            }
        ]
    };

    
    export const SampleEmployeeBonusAndIncentiveProgramTemplate = {
        title: "Sample Employee Bonus and Incentive Program Template",
        policyBlurb: "This Sample Employee Bonus and Incentive Program outlines the framework for rewarding employees based on their performance, achievements, and contributions to [Company Name].",
        preRead: "Use this template to establish a structured bonus and incentive program that motivates employees, aligns with company goals, and drives exceptional performance.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Eligibility",
                    "Types of Bonuses and Incentives",
                    "Performance Metrics",
                    "Bonus Administration",
                    "Discretionary Bonuses",
                    "Communication and Transparency",
                    "Tax Implications",
                    "Review and Adjustment",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we recognize the importance of rewarding our employees for their dedication, hard work, and achievements. This Employee Bonus and Incentive Program is designed to acknowledge outstanding performance and contributions that drive our company's success."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this program is to establish a fair and transparent framework for providing bonuses and incentives to employees based on their individual performance, team accomplishments, and overall company results. By aligning rewards with company objectives, we aim to motivate employees, foster a culture of excellence, and retain top talent."
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "All regular full-time and part-time employees of [Company Name] are eligible to participate in the Employee Bonus and Incentive Program, subject to specific eligibility criteria defined in the program guidelines.",
                    "Contractors, temporary employees, and interns may be eligible for certain incentives based on their contributions and tenure, as determined by management."
                ]
            },
            {
                heading: "Types of Bonuses and Incentives",
                paragraphs: [
                    "Performance-Based Bonuses: Rewards based on individual, team, or departmental performance against predefined goals and metrics.",
                    "Profit-Sharing Plans: Incentives tied to the company's profitability and financial performance, distributed periodically or annually.",
                    "Recognition Awards: Non-monetary rewards such as certificates, plaques, or public acknowledgment for outstanding contributions.",
                    "Sales Commission: Commission-based incentives for sales and business development roles based on achieved sales targets or revenue generation."
                ]
            },
            {
                heading: "Performance Metrics",
                paragraphs: [
                    "Key Performance Indicators (KPIs): Metrics used to evaluate employee performance, including sales targets, customer satisfaction scores, productivity levels, and project milestones.",
                    "Objective Setting: Clear and measurable objectives aligned with departmental or organizational goals to guide performance assessment and bonus allocation.",
                    "Peer and Manager Feedback: Input from colleagues and supervisors on teamwork, leadership qualities, and contributions to collaborative projects."
                ]
            },
            {
                heading: "Bonus Administration",
                paragraphs: [
                    "Criteria Determination: Transparent guidelines for determining bonus eligibility, calculation methods, and payout schedules.",
                    "Disbursement Process: Procedures for announcing bonus payouts, including timing, method of distribution, and communication to eligible employees.",
                    "Performance Review: Annual or periodic performance reviews to assess eligibility for bonuses and incentives based on documented achievements and performance outcomes."
                ]
            },
            {
                heading: "Discretionary Bonuses",
                paragraphs: [
                    "Management Discretion: Occasional bonuses awarded at the discretion of management for exceptional contributions, extraordinary efforts, or special achievements.",
                    "Special Recognition: Bonuses outside the regular incentive structure to acknowledge outstanding performance during critical projects, crisis management, or significant milestones."
                ]
            },
            {
                heading: "Communication and Transparency",
                paragraphs: [
                    "Program Communication: Clear and consistent communication of the bonus program's objectives, eligibility criteria, and performance metrics to all employees.",
                    "Transparency: Open communication about the rationale behind bonus decisions, ensuring fairness and trust in the incentive program.",
                    "Feedback Mechanism: Channels for employees to provide feedback on the bonus program, including suggestions for improvement and concerns about equity or transparency."
                ]
            },
            {
                heading: "Tax Implications",
                paragraphs: [
                    "Taxation Guidelines: Explanation of tax implications for bonuses and incentives based on local tax laws and regulations.",
                    "Employee Education: Resources and guidance on understanding tax implications related to bonus payments and incentives received."
                ]
            },
            {
                heading: "Review and Adjustment",
                paragraphs: [
                    "Periodic Review: Regular evaluation of the bonus program's effectiveness in motivating employees and achieving desired performance outcomes.",
                    "Adjustment Criteria: Procedures for updating bonus structures, eligibility criteria, and performance metrics based on business needs, market conditions, and employee feedback."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By participating in the Employee Bonus and Incentive Program, employees acknowledge their understanding of the program guidelines and commit to upholding the company's values of excellence, collaboration, and achievement."
            }
        ]
    };

    export const SampleEmployeeBusinessDressCodeTemplate = {
        title: "Sample Employee Business Dress Code Template",
        policyBlurb: "This Sample Employee Business Dress Code outlines the dress standards and expectations for employees at [Company Name], promoting a professional and respectful workplace environment.",
        preRead: "Use this template to establish guidelines that reflect the company's culture, industry standards, and client expectations while ensuring employees present themselves in a manner that enhances the company's image.",
        sections: [
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we value professionalism and understand the importance of presenting a positive image to clients, partners, and the public. This dress code policy sets forth guidelines to help employees understand the appropriate attire for the workplace."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to define acceptable business attire and grooming standards to ensure consistency and professionalism across all departments. This policy aims to promote a comfortable and respectful work environment while aligning with industry norms and client expectations."
            },
            {
                heading: "General Guidelines",
                paragraphs: [
                    "Employees are expected to dress in a manner that reflects professionalism and respect for the workplace.",
                    "Appropriate attire contributes to a positive work atmosphere and helps maintain the company's reputation.",
                    "Employees should always consider their role, job duties, and scheduled activities when choosing their attire."
                ]
            },
            {
                heading: "Business Attire Standards",
                paragraphs: [
                    "Men's Business Attire: Examples include tailored suits, dress slacks, collared shirts, ties, and appropriate footwear.",
                    "Women's Business Attire: Examples include business suits, tailored dresses or skirts of appropriate length, blouses, and closed-toe shoes.",
                    "Casual Fridays: Guidelines for casual dress days should still maintain a professional appearance, avoiding items such as jeans, shorts, or flip-flops unless specified otherwise.",
                    "Seasonal Considerations: Guidance on appropriate attire during different seasons, taking into account weather conditions and indoor climate control."
                ]
            },
            {
                heading: "Grooming and Personal Hygiene",
                paragraphs: [
                    "Employees are expected to maintain good personal hygiene and grooming standards.",
                    "Hair should be clean and neatly groomed, and facial hair should be well-maintained.",
                    "Perfume, cologne, or other scented products should be used sparingly to avoid discomfort to others."
                ]
            },
            {
                heading: "Exceptions and Special Circumstances",
                paragraph: "Exceptions to the dress code may be granted for specific events or job functions with prior approval from management. Employees should seek clarification from their supervisor if unsure about appropriate attire for a particular occasion."
            },
            {
                heading: "Enforcement",
                paragraphs: [
                    "Managers and supervisors are responsible for enforcing the dress code policy consistently and fairly.",
                    "Employees found in violation of the dress code may be asked to change into appropriate attire or sent home to comply with the policy."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This dress code policy will be reviewed periodically to ensure it remains relevant and effective.",
                    "Updates to the policy may be made to reflect changes in company culture, industry standards, or client expectations."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to read and acknowledge receipt of this dress code policy. By signing below, employees confirm their understanding and agreement to comply with the guidelines outlined."
            }
        ]
    };

    export const SampleEmployeeBusinessTravelTemplate = {
        title: "Sample Employee Business Travel",
        policyBlurb: "This Sample Employee Business Travel policy outlines guidelines and expectations for employees traveling on behalf of [Company Name], ensuring safe, efficient, and compliant travel experiences.",
        preRead: "Use this template to establish procedures that promote employee safety, cost-effective travel arrangements, and compliance with company policies during business-related trips.",
        sections: [
            {
                heading: "Introduction",
                paragraph: "At [Company Name], business travel is sometimes necessary to achieve our goals, meet clients, attend conferences, or conduct other essential business activities. This policy provides guidelines to ensure that all business travel is conducted in a safe, efficient, and responsible manner."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to define the expectations and responsibilities of employees when traveling for business purposes. It aims to ensure the safety and well-being of employees, protect company assets, and maintain professionalism throughout the travel experience."
            },
            {
                heading: "General Guidelines",
                paragraphs: [
                    "Employees must obtain prior approval from their supervisor or department head before making any travel arrangements.",
                    "Travel arrangements should be booked through the company's designated travel booking system or approved vendors.",
                    "Employees should always prioritize safety, cost-effectiveness, and adherence to company policies during business travel."
                ]
            },
            {
                heading: "Travel Authorization and Booking",
                paragraphs: [
                    "Employees must submit a travel request detailing the purpose, dates, destination, estimated costs, and any other relevant information for approval.",
                    "Travel bookings should be made well in advance to secure the best rates and ensure availability."
                ]
            },
            {
                heading: "Transportation and Accommodation",
                paragraphs: [
                    "Employees are encouraged to use cost-effective and efficient modes of transportation.",
                    "Accommodation should be booked at reputable hotels or accommodations approved by the company."
                ]
            },
            {
                heading: "Travel Expenses",
                paragraphs: [
                    "Employees must adhere to the company's expense policy when claiming reimbursements for travel-related expenses.",
                    "Original receipts must be submitted for all expenses, and expenses must be reasonable and directly related to business activities."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "Employees should prioritize their health and safety during travel, including following local health guidelines and recommendations.",
                    "Travelers should be aware of emergency procedures and contact information while abroad."
                ]
            },
            {
                heading: "Communication During Travel",
                paragraphs: [
                    "Employees should maintain regular communication with their supervisor or designated contact person during travel.",
                    "Check-ins and updates regarding travel status and activities should be provided as requested."
                ]
            },
            {
                heading: "Cultural Sensitivity and Respect",
                paragraphs: [
                    "Employees should respect local customs, cultures, and traditions while traveling on behalf of the company.",
                    "Dress and behavior should be appropriate and respectful at all times."
                ]
            },
            {
                heading: "Security and Data Protection",
                paragraphs: [
                    "Employees must take precautions to protect company data and equipment while traveling.",
                    "Use of secure networks and encrypted devices is recommended for accessing company resources."
                ]
            },
            {
                heading: "Emergency Situations",
                paragraphs: [
                    "Employees should be aware of emergency procedures and contacts both within the company and locally.",
                    "In case of emergency, employees should follow company protocols and seek assistance as needed."
                ]
            },
            {
                heading: "Expense Reimbursement",
                paragraphs: [
                    "Expense reports must be submitted promptly upon return from the trip, including all necessary receipts and documentation.",
                    "Reimbursement will be processed according to company policy and may require approval from appropriate departments."
                ]
            },
            {
                heading: "Review and Updates",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure it remains current and aligned with company goals and regulatory requirements.",
                    "Updates to the policy will be communicated to employees to ensure awareness and compliance."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to review and acknowledge receipt of this business travel policy. By signing below, employees confirm their understanding and agreement to comply with the guidelines outlined."
            }
        ]
    };

    export const SampleEmployeeHandbookTemplate = {
        title: "Sample Employee Handbook Template",
        policyBlurb: "This Employee Handbook provides essential information about [Company Name]'s policies, procedures, and expectations. It serves as a guide for employees regarding their rights, responsibilities, and conduct within the organization.",
        preRead: "Use this template to establish clear guidelines and expectations for employees, ensuring consistency, fairness, and compliance with company policies and legal requirements.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Welcome Message",
                    "Employment Relationship",
                    "Equal Employment Opportunity",
                    "Code of Conduct",
                    "Workplace Policies",
                    "Employee Benefits",
                    "Employee Responsibilities",
                    "Communication",
                    "Health and Safety",
                    "Employee Development",
                    "Technology and Information Security",
                    "Conflict Resolution",
                    "Termination of Employment",
                    "Policy Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name]'s Employee Handbook is designed to acquaint you with the company's history, mission, values, and culture. It outlines the policies, procedures, benefits, and expectations that apply to all employees. By familiarizing yourself with this handbook, you will gain valuable insight into our organization's operations and the standards of behavior and performance we uphold."
            },
            {
                heading: "Welcome Message",
                paragraph: "Welcome to [Company Name]! We are delighted to have you join our team. This handbook serves as your guide to understanding our company's policies, procedures, and expectations. It is essential reading for all employees to ensure a harmonious work environment and mutual understanding of our shared goals."
            },
            {
                heading: "Employment Relationship",
                paragraphs: [
                    "Employment at [Company Name] is based on mutual consent and is at-will unless otherwise stated in a written agreement signed by both the employee and an authorized representative of the company. This means that both the employee and the employer have the right to terminate the employment relationship at any time, with or without cause or notice.",
                    "This handbook is not a contract of employment, nor does it guarantee employment for any specific duration. The policies and procedures outlined herein are subject to change at the discretion of the company. Employees will be notified of any significant changes and provided with updated copies of the handbook as necessary.",
                    "It is important for employees to understand that adherence to the policies and guidelines in this handbook is a condition of employment. Failure to comply with these policies may result in disciplinary action, up to and including termination of employment."
                ]
            },
            {
                heading: "Equal Employment Opportunity",
                paragraphs: [
                    "[Company Name] is committed to providing equal employment opportunities to all employees and applicants for employment without regard to race, color, religion, sex, national origin, age, disability, sexual orientation, gender identity, genetic information, or any other protected status. We believe that diversity and inclusion are essential to our success and strive to create a work environment where all employees feel valued and respected.",
                    "Discrimination or harassment based on any of these characteristics is strictly prohibited and will not be tolerated. Employees who believe they have been subjected to discrimination or harassment are encouraged to report their concerns to the HR department or any member of management. All complaints will be investigated promptly and thoroughly, and appropriate corrective action will be taken as necessary.",
                    "We are committed to making reasonable accommodations for qualified individuals with disabilities. If you require an accommodation to perform your job duties, please contact the HR department to discuss your needs and explore possible solutions."
                ]
            },
            {
                heading: "Code of Conduct",
                paragraphs: [
                    "Employees are expected to adhere to high standards of ethical conduct, integrity, and professionalism in all business dealings and interactions. This includes being honest and transparent in communications, respecting the rights and dignity of others, and avoiding conflicts of interest.",
                    "The code of conduct includes guidelines on honesty, respect, confidentiality, and compliance with laws and company policies. Employees must protect the company's confidential information and intellectual property and refrain from using it for personal gain or disclosing it to unauthorized parties. Additionally, employees are expected to comply with all applicable laws and regulations and to report any suspected violations to the appropriate authorities within the company.",
                    "Employees are also responsible for maintaining a professional appearance and demeanor while representing the company. This includes adhering to the company's dress code, being punctual and reliable, and treating colleagues, clients, and customers with courtesy and respect. Any form of harassment, discrimination, or bullying will not be tolerated and may result in disciplinary action."
                ]
            },
            {
                heading: "Workplace Policies",
                paragraphs: [
                    "This section outlines specific policies related to workplace behavior, including attendance, punctuality, dress code, and use of company property and equipment. Employees are expected to familiarize themselves with these policies and comply with them in their daily work activities.",
                    "Attendance and punctuality are critical to the smooth operation of the company. Employees are expected to report to work on time and be ready to perform their duties. If you are unable to report to work or will be late, you must notify your supervisor as soon as possible.",
                    "The dress code policy ensures that employees maintain a professional appearance while at work. Employees are expected to dress in a manner that is appropriate for their job duties and work environment. Specific guidelines regarding acceptable attire will be provided by your supervisor or the HR department.",
                    "Employees are expected to use company property and equipment responsibly and for business purposes only. This includes computers, phones, vehicles, and any other tools or resources provided by the company. Misuse of company property may result in disciplinary action, up to and including termination of employment."
                ]
            },
            {
                heading: "Employee Benefits",
                paragraphs: [
                    "We offer a comprehensive benefits package to eligible employees, including health insurance, retirement plans, paid time off, and other benefits designed to support your well-being and work-life balance. Detailed information about the benefits available to you will be provided during your onboarding process and can also be obtained from the HR department.",
                    "Health insurance coverage includes medical, dental, and vision plans. Employees are encouraged to review the plan options and select the coverage that best meets their needs. The company also offers a variety of wellness programs and resources to promote a healthy lifestyle.",
                    "Retirement plans, such as 401(k) or pension plans, are available to help employees save for their future. Employees are encouraged to participate in these plans and take advantage of any employer matching contributions. Additionally, the company offers financial planning resources to assist employees with managing their finances and planning for retirement."
                ]
            },
            {
                heading: "Employee Responsibilities",
                paragraphs: [
                    "Employees are responsible for understanding and complying with all company policies and procedures. This includes reading and familiarizing yourself with the contents of this handbook, as well as any additional policies that may be specific to your department or job role.",
                    "Employees are expected to perform their job duties diligently, contribute positively to the team, and maintain a professional demeanor. This includes being proactive in identifying and addressing issues, collaborating with colleagues, and striving to achieve the highest standards of performance and quality in your work.",
                    "Employees are also responsible for maintaining the confidentiality and security of company information. This includes protecting sensitive data, following information security protocols, and reporting any suspected breaches or vulnerabilities to the appropriate authorities within the company."
                ]
            },
            {
                heading: "Communication",
                paragraphs: [
                    "Open and effective communication is essential at [Company Name]. Employees are encouraged to express ideas, concerns, and feedback constructively. This includes participating in team meetings, providing input on projects and initiatives, and seeking clarification when needed.",
                    "Managers and supervisors are responsible for fostering a communicative and supportive work environment. This includes being accessible and approachable, providing regular feedback and guidance, and addressing any issues or concerns promptly and fairly.",
                    "Employees are also encouraged to take advantage of the company's various communication channels, such as email, intranet, and team collaboration tools, to stay informed about company news, updates, and events. Regular communication helps to ensure that everyone is aligned with the company's goals and objectives and fosters a sense of community and engagement within the organization."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "[Company Name] is committed to providing a safe and healthy work environment for all employees. This includes complying with all applicable health and safety regulations, providing necessary training and resources, and maintaining a culture of safety and wellness.",
                    "Employees are expected to comply with safety guidelines, report hazards, and participate in safety training programs. This includes following proper procedures for operating equipment, using personal protective equipment as required, and promptly reporting any accidents, injuries, or unsafe conditions to your supervisor or the HR department.",
                    "The company also offers various wellness programs and resources to support employees' physical and mental well-being. This includes access to fitness facilities, health screenings, counseling services, and educational workshops on topics such as stress management, nutrition, and work-life balance."
                ]
            },
            {
                heading: "Employee Development",
                paragraphs: [
                    "We invest in our employees' growth and development through training programs, workshops, and educational opportunities. This includes on-the-job training, mentoring programs, and external courses and certifications relevant to your job role.",
                    "Employees are encouraged to take advantage of these resources to enhance their skills and advance their careers. This includes setting personal and professional development goals, seeking feedback and guidance from supervisors, and actively participating in training and development activities.",
                    "The company also provides opportunities for career advancement and internal mobility. Employees are encouraged to explore different career paths within the organization and apply for open positions that align with their skills, interests, and career goals. Support and resources, such as career counseling and job shadowing, are available to help employees navigate their career development journey."
                ]
            },
            {
                heading: "Technology and Information Security",
                paragraphs: [
                    "Employees must use company technology and information systems responsibly and in accordance with established guidelines. This includes using company email, internet, and other digital tools for business purposes only and avoiding any activities that could compromise the security or integrity of the company's systems.",
                    "Protecting company data and respecting intellectual property rights are critical responsibilities. Employees are expected to follow information security protocols, such as using strong passwords, encrypting sensitive data, and reporting any suspected breaches or vulnerabilities to the IT department.",
                    "Employees are also responsible for respecting the privacy and confidentiality of colleagues, clients, and customers. This includes handling personal and sensitive information with care, following data protection regulations, and ensuring that information is shared only with authorized parties."
                ]
            },
            {
                heading: "Conflict Resolution",
                paragraphs: [
                    "In the event of conflicts or disputes, employees are encouraged to seek resolution through open dialogue and mediation. This includes addressing issues directly with the parties involved, seeking assistance from supervisors or HR, and utilizing the company's formal dispute resolution processes if necessary.",
                    "The company is committed to addressing concerns promptly and fairly. This includes conducting thorough and impartial investigations, maintaining confidentiality to the extent possible, and taking appropriate corrective action based on the findings of the investigation.",
                    "Employees who raise concerns or report misconduct in good faith will be protected from retaliation. The company encourages a culture of openness and accountability and values employees' contributions to maintaining a positive and ethical work environment."
                ]
            },
            {
                heading: "Termination of Employment",
                paragraphs: [
                    "This section outlines the circumstances under which employment may be terminated and the procedures for termination. Employment may be terminated voluntarily by the employee, involuntarily by the employer, or due to other reasons such as retirement, disability, or death.",
                    "Employees who resign or are terminated are expected to provide written notice, return company property, and respect confidentiality obligations. The notice period and other requirements will be specified in your employment agreement or as outlined in this handbook.",
                    "The company will conduct exit interviews with departing employees to gather feedback and address any concerns. The final paycheck, including any accrued benefits, will be provided in accordance with applicable laws and company policies."
                ]
            },
            {
                heading: "Policy Acknowledgment",
                paragraphs: [
                    "All employees are required to review this handbook and sign an acknowledgment form indicating their understanding and acceptance of the policies and guidelines contained herein. The acknowledgment form will be kept on file in the HR department.",
                    "By signing the acknowledgment form, employees agree to comply with all company policies and procedures and understand that failure to do so may result in disciplinary action, up to and including termination of employment."
                ]
            }
        ]
    };
    
    
    export const SampleEmployeeReferralProgramTemplate = {
        title: "Sample Employee Referral Program Template",
        policyBlurb: "This policy outlines the employee referral program, which encourages employees to refer qualified candidates for open positions within the company.",
        preRead: "Use this template to develop a comprehensive employee referral program that incentivizes employees to refer top talent, helping to attract and retain high-quality candidates.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Referral Process",
                    "Referral Rewards",
                    "Criteria for Referrals",
                    "Tracking and Monitoring",
                    "Confidentiality",
                    "Dispute Resolution",
                    "Program Changes and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Employee Referral Program is designed to encourage current employees to refer qualified candidates for open positions within the company. By leveraging employees' networks, we aim to attract top talent and enhance our recruitment efforts. This policy provides detailed guidelines on the referral process, eligibility, rewards, and other essential aspects of the program."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this Employee Referral Program is to incentivize employees to contribute to the recruitment process by referring talented individuals who would be a good fit for our company. By doing so, we hope to improve the quality of our hires, reduce recruitment costs, and foster a culture of teamwork and collaboration."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all full-time and part-time employees of [Your Company], excluding temporary staff, contractors, and members of the HR department involved in the hiring process.",
                    "The program covers all open positions within the company, unless specified otherwise in the job posting or communicated by HR."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "All current employees of [Your Company] are eligible to participate in the referral program, with the exception of members of the HR department, senior management involved in the hiring decision, and employees who would be the direct supervisors of the referred candidates.",
                    "Referrals of former employees who left the company less than one year ago are not eligible for referral rewards."
                ]
            },
            {
                heading: "Referral Process",
                paragraphs: [
                    "To refer a candidate, employees must submit the candidate's resume along with a completed referral form to the HR department or through the designated online portal.",
                    "HR will review the referral submission and determine if the candidate meets the basic qualifications for the position. If the candidate is deemed suitable, they will proceed through the standard recruitment process."
                ]
            },
            {
                heading: "Referral Rewards",
                paragraphs: [
                    "Employees who refer a candidate that is successfully hired and completes the probationary period will be eligible for a referral reward.",
                    "The reward structure is as follows: a monetary bonus, gift cards, additional vacation days, or other incentives as determined by the company. Specific reward details will be communicated by HR."
                ]
            },
            {
                heading: "Criteria for Referrals",
                paragraphs: [
                    "Referred candidates must meet the minimum qualifications and requirements for the position to be considered for referral rewards.",
                    "The referred candidate should not have applied to the company within the last six months or been referred by another employee within the same timeframe."
                ]
            },
            {
                heading: "Tracking and Monitoring",
                paragraphs: [
                    "The HR department will maintain records of all referrals and track the progress of referred candidates through the recruitment process.",
                    "Regular updates will be provided to the referring employee regarding the status of their referral."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "Employees must respect the confidentiality of referred candidates' information and not disclose details about the referral process or the candidate's application status.",
                    "HR will ensure that referral information is handled in compliance with data protection and privacy regulations."
                ]
            },
            {
                heading: "Dispute Resolution",
                paragraphs: [
                    "Any disputes or disagreements regarding the referral program should be addressed to the HR department.",
                    "HR will review and resolve disputes in accordance with company policies and procedures."
                ]
            },
            {
                heading: "Program Changes and Updates",
                paragraphs: [
                    "This Employee Referral Program will be reviewed annually and updated as necessary to reflect changes in recruitment strategies and company policies.",
                    "Employees will be notified of any significant changes to the program and provided with an updated copy of the policy document."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees participating in the referral program must sign an acknowledgment form confirming that they have read, understood, and agreed to comply with the Employee Referral Program policy. This acknowledgment will be kept on file in the HR department."
            }
        ]
    };

    export const SampleEmployeeVolunteerProgramTemplate = {
        title: "Sample Employee Volunteer Program Template",
        policyBlurb: "This Employee Volunteer Program policy outlines [Company Name]'s commitment to supporting and encouraging employee participation in volunteer activities. It provides guidelines for employees who wish to engage in volunteer work and describes the benefits and responsibilities associated with the program.",
        preRead: "Use this template to develop a comprehensive volunteer program that promotes community involvement, employee engagement, and corporate social responsibility.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Program Objectives",
                    "Eligibility and Participation",
                    "Volunteer Opportunities",
                    "Volunteer Time Off",
                    "Roles and Responsibilities",
                    "Health and Safety",
                    "Recognition and Rewards",
                    "Communication and Reporting",
                    "Monitoring and Evaluation",
                    "Policy Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name]'s Employee Volunteer Program is designed to encourage and support our employees in contributing to the community through volunteer work. This program aligns with our corporate values and commitment to social responsibility. By participating in volunteer activities, employees can make a positive impact, develop new skills, and build stronger connections with their colleagues and the community."
            },
            {
                heading: "Program Objectives",
                paragraphs: [
                    "The primary objectives of the Employee Volunteer Program are to promote community involvement, enhance employee engagement, and demonstrate our commitment to corporate social responsibility. We aim to support charitable organizations, address social issues, and contribute to the well-being of the communities in which we operate.",
                    "Through this program, we seek to provide opportunities for employees to use their skills and talents to benefit others, foster a sense of purpose and fulfillment, and strengthen the company's reputation as a responsible and caring organization. We also aim to create a positive and supportive work environment that values and recognizes the contributions of our employees both inside and outside the workplace."
                ]
            },
            {
                heading: "Eligibility and Participation",
                paragraphs: [
                    "All full-time and part-time employees of [Company Name] are eligible to participate in the Employee Volunteer Program. Participation in volunteer activities is voluntary and should not interfere with employees' job responsibilities or performance.",
                    "Employees interested in participating in volunteer activities must obtain approval from their supervisor or manager. This ensures that volunteer work is coordinated with work schedules and does not negatively impact business operations. Employees are encouraged to discuss their interests and potential volunteer opportunities with their supervisors to find mutually beneficial arrangements."
                ]
            },
            {
                heading: "Volunteer Opportunities",
                paragraphs: [
                    "Employees are encouraged to identify and participate in volunteer opportunities that align with their interests, skills, and the needs of the community. [Company Name] may also organize company-sponsored volunteer events and partnerships with local charitable organizations. Information about these opportunities will be communicated to employees through company channels such as emails, newsletters, and the intranet.",
                    "Employees can choose to volunteer individually or as part of a team. Team volunteering can help build camaraderie and strengthen relationships among colleagues while making a positive impact on the community. Examples of volunteer activities include tutoring and mentoring, environmental conservation, food drives, fundraising events, and community service projects."
                ]
            },
            {
                heading: "Volunteer Time Off",
                paragraphs: [
                    "[Company Name] recognizes the importance of supporting employees' volunteer efforts and offers Volunteer Time Off (VTO) to eligible employees. Full-time employees are entitled to up to [X] hours of paid VTO per year, while part-time employees are entitled to a prorated amount based on their regular work hours.",
                    "To request VTO, employees must submit a Volunteer Time Off Request Form to their supervisor at least [X] days in advance. The request should include details about the volunteer activity, the organization, and the dates and times of participation. Supervisors will review the request and approve or deny it based on business needs and the relevance of the volunteer activity to the program's objectives."
                ]
            },
            {
                heading: "Roles and Responsibilities",
                paragraphs: [
                    "Employees participating in the volunteer program are expected to represent [Company Name] in a positive and professional manner. This includes adhering to the organization's policies and guidelines, demonstrating respect and cooperation, and fulfilling any commitments made to the volunteer organization.",
                    "Supervisors and managers play a key role in supporting and encouraging employee participation in volunteer activities. They are responsible for reviewing and approving VTO requests, coordinating volunteer activities with work schedules, and providing feedback and recognition to employees who participate in the program."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "[Company Name] is committed to ensuring the health and safety of employees while participating in volunteer activities. Employees should follow all safety guidelines and procedures provided by the volunteer organization and take necessary precautions to avoid injury or illness.",
                    "Employees must report any accidents, injuries, or health concerns that occur during volunteer activities to their supervisor and the HR department as soon as possible. The company will provide support and assistance to employees in addressing any health and safety issues that arise from their volunteer work."
                ]
            },
            {
                heading: "Recognition and Rewards",
                paragraphs: [
                    "[Company Name] values and appreciates the contributions of employees who participate in volunteer activities. To recognize and reward their efforts, the company may offer various forms of acknowledgment, such as certificates of appreciation, awards, and public recognition through company communications.",
                    "Employees are encouraged to share their volunteer experiences and accomplishments with their colleagues and supervisors. The company may also feature stories and highlights of employee volunteer activities in newsletters, on the intranet, and at company events to inspire others and promote a culture of giving and community involvement."
                ]
            },
            {
                heading: "Communication and Reporting",
                paragraphs: [
                    "Effective communication is essential for the success of the Employee Volunteer Program. Employees are encouraged to communicate their volunteer interests, experiences, and feedback to their supervisors and the HR department. This helps ensure that the program meets the needs of employees and the community.",
                    "Employees who participate in volunteer activities should also report their volunteer hours and accomplishments to the HR department. This information is important for tracking the program's impact, recognizing employee contributions, and identifying opportunities for improvement."
                ]
            },
            {
                heading: "Monitoring and Evaluation",
                paragraphs: [
                    "[Company Name] is committed to continuously improving the Employee Volunteer Program. The HR department will monitor and evaluate the program's effectiveness by collecting feedback from employees, supervisors, and volunteer organizations. This includes assessing the impact of volunteer activities on the community, employee engagement, and the company's reputation.",
                    "Based on the evaluation results, the company may make adjustments to the program to better align with employee interests and community needs. This may include introducing new volunteer opportunities, enhancing support and resources for employees, and improving communication and reporting processes."
                ]
            },
            {
                heading: "Policy Acknowledgment",
                paragraphs: [
                    "All employees are required to review this Employee Volunteer Program policy and sign an acknowledgment form indicating their understanding and acceptance of the guidelines and responsibilities outlined herein. The acknowledgment form will be kept on file in the HR department.",
                    "By signing the acknowledgment form, employees agree to comply with all program guidelines and understand that participation in volunteer activities is a privilege that comes with certain responsibilities. Employees are encouraged to make the most of this opportunity to give back to the community and enhance their personal and professional development."
                ]
            }
        ]
    };
    
    export const SampleEmploymentContractTemplate = {
        title: "Sample Employment Contract Template",
        policyBlurb: "This Employment Contract sets out the terms and conditions of employment between [Company Name] and the employee. It outlines the rights, responsibilities, and obligations of both parties to ensure a clear understanding and agreement on employment terms.",
        preRead: "Use this template to create a formal agreement that details the terms of employment, including job responsibilities, compensation, benefits, and other relevant provisions.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Position and Duties",
                    "Compensation and Benefits",
                    "Employment Term",
                    "Confidentiality",
                    "Non-Compete and Non-Solicitation",
                    "Intellectual Property",
                    "Termination",
                    "Dispute Resolution",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "This Employment Contract ('Contract') is made and entered into as of [Start Date] by and between [Company Name], a [State] corporation with its principal place of business at [Company Address] ('Company'), and [Employee Name], residing at [Employee Address] ('Employee'). The Company and Employee are collectively referred to as 'the Parties' and individually as a 'Party.' This Contract outlines the terms and conditions of Employee's employment with the Company."
            },
            {
                heading: "Position and Duties",
                paragraphs: [
                    "The Company agrees to employ the Employee in the position of [Job Title]. The Employee agrees to diligently and faithfully perform the duties and responsibilities associated with this position as outlined in the job description attached as Exhibit A and as may be reasonably assigned by the Company from time to time.",
                    "The Employee shall devote their full business time, attention, and efforts to the performance of their duties and shall act in the best interests of the Company at all times. The Employee agrees to comply with all Company policies, procedures, and directives."
                ]
            },
            {
                heading: "Compensation and Benefits",
                paragraphs: [
                    "The Employee shall be compensated at an annual base salary of [Salary Amount], payable in accordance with the Company's standard payroll practices and subject to applicable tax withholdings. The Employee may also be eligible for additional compensation, including bonuses, commissions, or other incentives, as determined by the Company in its sole discretion.",
                    "In addition to the base salary, the Employee shall be entitled to participate in the Company's employee benefit plans, including health insurance, retirement plans, and paid time off, subject to the terms and conditions of each plan. The specific benefits and eligibility requirements are detailed in the Company's Employee Handbook and benefit plan documents."
                ]
            },
            {
                heading: "Employment Term",
                paragraphs: [
                    "The employment relationship between the Company and the Employee is 'at-will,' meaning that either Party may terminate the employment relationship at any time, with or without cause, and with or without notice. This at-will employment relationship cannot be changed except in a written agreement signed by both the Employee and an authorized representative of the Company.",
                    "The Employee's employment shall commence on [Start Date] and shall continue until terminated by either Party in accordance with the terms of this Contract. The Company may terminate the Employee's employment immediately for cause, including but not limited to gross misconduct, breach of this Contract, or violation of Company policies."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "The Employee acknowledges that during the course of their employment, they will have access to and become acquainted with confidential information, including but not limited to trade secrets, customer lists, financial data, and business strategies ('Confidential Information'). The Employee agrees to maintain the confidentiality of all such information and not to disclose it to any third party or use it for any purpose other than the performance of their duties for the Company.",
                    "Upon termination of employment, the Employee agrees to return all Company property and Confidential Information in their possession and to refrain from retaining any copies or reproductions thereof. The Employee's obligation to maintain the confidentiality of Confidential Information shall survive the termination of this Contract."
                ]
            },
            {
                heading: "Non-Compete and Non-Solicitation",
                paragraphs: [
                    "During the term of employment and for a period of [Non-Compete Duration] following the termination of employment, the Employee agrees not to engage in any business or activity that competes with the Company within [Geographic Area]. This includes, but is not limited to, owning, managing, operating, or consulting for a competing business.",
                    "The Employee also agrees that for a period of [Non-Solicitation Duration] following the termination of employment, they will not solicit or attempt to solicit any employees, customers, or clients of the Company for the purpose of engaging in a competing business or causing them to terminate or alter their relationship with the Company."
                ]
            },
            {
                heading: "Intellectual Property",
                paragraphs: [
                    "The Employee agrees that any inventions, discoveries, designs, works of authorship, or other intellectual property created or developed by the Employee during the course of their employment ('Work Product') shall be the sole property of the Company. The Employee hereby assigns to the Company all rights, title, and interest in and to any such Work Product.",
                    "The Employee agrees to execute any documents or take any actions necessary to perfect the Company's ownership of the Work Product and to assist the Company in obtaining and enforcing intellectual property rights related thereto."
                ]
            },
            {
                heading: "Termination",
                paragraphs: [
                    "Either Party may terminate this Contract at any time by providing written notice to the other Party. In the event of termination, the Employee shall be entitled to receive their base salary and any earned but unpaid compensation through the date of termination, as well as any accrued but unused paid time off.",
                    "Upon termination of employment, the Employee agrees to return all Company property, including but not limited to keys, equipment, documents, and Confidential Information. The Employee also agrees to cooperate with the Company in any transition of duties and responsibilities and to comply with any post-termination obligations set forth in this Contract."
                ]
            },
            {
                heading: "Dispute Resolution",
                paragraphs: [
                    "In the event of any dispute arising out of or relating to this Contract, the Parties agree to first attempt to resolve the dispute through good faith negotiations. If the dispute cannot be resolved through negotiations, the Parties agree to submit the dispute to mediation before a mutually agreed-upon mediator.",
                    "If the dispute is not resolved through mediation, either Party may initiate binding arbitration in accordance with the rules of the [Arbitration Association]. The decision of the arbitrator shall be final and binding on the Parties, and judgment on the award may be entered in any court of competent jurisdiction."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraphs: [
                    "The Employee acknowledges that they have read and understand the terms of this Contract and agree to be bound by its provisions. The Employee further acknowledges that they have had the opportunity to seek independent legal counsel prior to signing this Contract.",
                    "By signing below, the Parties acknowledge their acceptance and agreement to the terms and conditions set forth in this Contract."
                ]
            }
        ]
    };

    export const SampleExitInterviewTemplate = {
        title: "Sample Exit Interview Template",
        policyBlurb: "This Exit Interview form is designed to gather feedback from departing employees about their experiences at [Company Name]. It aims to identify areas for improvement and ensure a smooth transition for both the employee and the company.",
        preRead: "Use this template to conduct structured exit interviews that provide valuable insights into employee satisfaction, workplace culture, and areas for organizational development.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose of Exit Interview",
                    "Employee Information",
                    "Reason for Leaving",
                    "Job Satisfaction",
                    "Work Environment",
                    "Management Feedback",
                    "Suggestions for Improvement",
                    "Final Thoughts",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Exit Interview is an important process in understanding the reasons behind an employee's departure and gathering constructive feedback. It provides an opportunity for departing employees to share their experiences and offer suggestions for improving the work environment. This information is valuable in helping [Company Name] identify trends, enhance employee retention strategies, and create a better workplace for current and future employees."
            },
            {
                heading: "Purpose of Exit Interview",
                paragraphs: [
                    "The primary purpose of the Exit Interview is to collect honest feedback from departing employees regarding their experiences, job satisfaction, and reasons for leaving. This information helps the company understand the factors influencing employee turnover and identify areas where improvements can be made.",
                    "The Exit Interview also serves as a platform for employees to express any concerns or issues they may have faced during their tenure, providing the company with an opportunity to address and rectify these concerns for the benefit of current and future employees."
                ]
            },
            {
                heading: "Employee Information",
                paragraphs: [
                    "Please provide the following information to help us understand your role and tenure at [Company Name]:",
                    "- Name: [Employee Name]",
                    "- Job Title: [Employee Job Title]",
                    "- Department: [Employee Department]",
                    "- Date of Hire: [Date of Hire]",
                    "- Last Working Day: [Last Working Day]"
                ]
            },
            {
                heading: "Reason for Leaving",
                paragraphs: [
                    "Understanding the reasons behind an employee's departure is crucial for identifying patterns and addressing any systemic issues within the organization. Please indicate your primary reason(s) for leaving:",
                    "- New job opportunity",
                    "- Career advancement",
                    "- Relocation",
                    "- Work-life balance",
                    "- Compensation and benefits",
                    "- Job dissatisfaction",
                    "- Management or leadership",
                    "- Personal reasons",
                    "- Other (please specify): [Reason]"
                ]
            },
            {
                heading: "Job Satisfaction",
                paragraphs: [
                    "We value your insights into your job satisfaction during your time with us. Please rate your satisfaction in the following areas:",
                    "- Overall job satisfaction",
                    "- Alignment of job responsibilities with job description",
                    "- Workload and work-life balance",
                    "- Opportunities for career development and advancement",
                    "- Recognition and rewards for your contributions",
                    "- Adequacy of training and resources provided",
                    "Please provide additional comments or explanations for your ratings: [Comments]"
                ]
            },
            {
                heading: "Work Environment",
                paragraphs: [
                    "The work environment plays a significant role in employee satisfaction and productivity. Please rate your satisfaction with the following aspects of the work environment:",
                    "- Physical workspace and facilities",
                    "- Team dynamics and collaboration",
                    "- Communication and information sharing",
                    "- Inclusivity and diversity",
                    "- Company culture and values",
                    "Please provide additional comments or explanations for your ratings: [Comments]"
                ]
            },
            {
                heading: "Management Feedback",
                paragraphs: [
                    "Effective management is key to a positive work experience. Please rate your satisfaction with the following aspects of management:",
                    "- Support and guidance from your direct supervisor",
                    "- Fairness and consistency in management practices",
                    "- Communication and feedback from management",
                    "- Opportunities for input and participation in decision-making",
                    "- Leadership and vision of the organization",
                    "Please provide additional comments or explanations for your ratings: [Comments]"
                ]
            },
            {
                heading: "Suggestions for Improvement",
                paragraphs: [
                    "We value your suggestions for improving our workplace. Please provide any recommendations you have for enhancing the employee experience at [Company Name]:",
                    "- What did you enjoy most about working here?",
                    "- What did you enjoy least about working here?",
                    "- How can we improve our onboarding and training processes?",
                    "- What changes would you suggest for improving job satisfaction and engagement?",
                    "- How can we enhance our work environment and company culture?",
                    "Please provide any additional comments or suggestions: [Comments]"
                ]
            },
            {
                heading: "Final Thoughts",
                paragraphs: [
                    "As we conclude this Exit Interview, we would like to hear any final thoughts or comments you may have:",
                    "- Do you have any unresolved issues or concerns that you would like to address?",
                    "- Is there anything else you would like to share about your experience at [Company Name]?",
                    "Thank you for taking the time to provide your feedback. Your insights are invaluable in helping us improve our workplace and better support our employees."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that the information provided in this Exit Interview is accurate and truthful to the best of my knowledge. I understand that my feedback will be used to help [Company Name] improve its policies, procedures, and work environment for current and future employees."
            }
        ]
    };

    export const SampleGrievanceHandlingTemplate = {
        title: "Sample Grievance Handling Template",
        policyBlurb: "This Grievance Handling policy outlines the procedures for addressing and resolving employee grievances at [Company Name]. It ensures that employees can raise concerns and complaints in a structured and supportive environment, and that these issues are handled promptly and fairly.",
        preRead: "Use this template to establish a clear, fair, and consistent process for managing employee grievances. This policy aims to promote a positive work environment by addressing employee concerns effectively and preventing conflicts from escalating.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Grievance Procedure",
                    "Informal Resolution",
                    "Formal Grievance",
                    "Investigation",
                    "Decision and Resolution",
                    "Appeal Process",
                    "Confidentiality",
                    "Non-Retaliation",
                    "Training and Communication",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we are committed to fostering a positive and productive work environment where employees feel valued and heard. The Grievance Handling policy provides a structured process for employees to raise concerns and ensures that these concerns are addressed promptly and fairly. By encouraging open communication and resolving issues at an early stage, we aim to maintain a harmonious workplace."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures for reporting, investigating, and resolving employee grievances. It aims to provide a fair and transparent process for handling complaints, ensuring that all employees are treated with respect and dignity.",
                    "This policy is designed to address a wide range of issues, including but not limited to, workplace conflicts, harassment, discrimination, unfair treatment, and breaches of company policy. By addressing grievances effectively, we aim to improve employee satisfaction, productivity, and overall organizational health."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name], regardless of their position or tenure. It covers grievances related to working conditions, interpersonal conflicts, management practices, and any other issues that may affect an employee's well-being or job performance.",
                    "Contractors, interns, and other non-permanent staff are also encouraged to report grievances through this process. The company is committed to addressing all grievances impartially and taking appropriate action to resolve them."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Grievance: A formal complaint raised by an employee regarding a workplace issue that affects their employment or working conditions.",
                    "Complainant: The employee who raises a grievance.",
                    "Respondent: The person or persons against whom the grievance is raised.",
                    "Grievance Officer: A designated company representative responsible for managing the grievance process and ensuring compliance with this policy."
                ]
            },
            {
                heading: "Grievance Procedure",
                paragraphs: [
                    "The grievance procedure is designed to provide a clear and consistent approach for resolving employee complaints. It consists of the following stages: Informal Resolution, Formal Grievance, Investigation, Decision and Resolution, and Appeal Process.",
                    "Employees are encouraged to seek informal resolution of their concerns before initiating a formal grievance. If informal efforts are unsuccessful or inappropriate given the nature of the complaint, the employee may proceed with the formal grievance process."
                ]
            },
            {
                heading: "Informal Resolution",
                paragraphs: [
                    "Employees are encouraged to address grievances informally by discussing their concerns directly with the person involved or their immediate supervisor. This approach often leads to quick and mutually acceptable resolutions.",
                    "If the employee is uncomfortable with direct discussion or if the issue remains unresolved, they may seek assistance from HR or another neutral party to facilitate an informal resolution."
                ]
            },
            {
                heading: "Formal Grievance",
                paragraphs: [
                    "If informal resolution is not possible or fails to resolve the issue, the employee may file a formal grievance. The formal grievance should be submitted in writing to the Grievance Officer, detailing the nature of the complaint, the parties involved, and any relevant evidence.",
                    "Upon receiving a formal grievance, the Grievance Officer will acknowledge receipt and initiate an investigation. The employee will be informed of the process and expected timelines."
                ]
            },
            {
                heading: "Investigation",
                paragraphs: [
                    "The Grievance Officer will conduct a thorough and impartial investigation into the grievance. This may involve interviewing the complainant, respondent, and any witnesses, as well as reviewing relevant documents and evidence.",
                    "The investigation should be completed within a reasonable timeframe, typically within 30 days of receiving the formal grievance. If additional time is needed, the Grievance Officer will inform the parties involved and provide an updated timeline."
                ]
            },
            {
                heading: "Decision and Resolution",
                paragraphs: [
                    "Upon completing the investigation, the Grievance Officer will review the findings and determine the appropriate course of action. This may include mediation, corrective action, or other measures to resolve the grievance.",
                    "The complainant and respondent will be informed of the decision and any actions to be taken. The resolution should aim to address the concerns raised, restore a positive work environment, and prevent recurrence of the issue."
                ]
            },
            {
                heading: "Appeal Process",
                paragraphs: [
                    "If the complainant or respondent is dissatisfied with the decision, they may appeal the outcome. The appeal must be submitted in writing within 10 days of receiving the decision, outlining the reasons for the appeal and any new evidence.",
                    "The appeal will be reviewed by a senior manager or an independent committee, who will reassess the investigation and decision. A final determination will be made, and the parties will be informed of the outcome."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All grievances will be handled with the utmost confidentiality. Information related to the grievance will only be shared with those directly involved in the investigation and resolution process.",
                    "Employees are assured that their privacy will be respected, and any information provided will be used solely for the purpose of resolving the grievance."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraphs: [
                    "Retaliation against an employee for raising a grievance or participating in the investigation process is strictly prohibited. Any form of retaliation will be considered a serious violation of company policy and will be subject to disciplinary action.",
                    "Employees are encouraged to report any instances of retaliation to the Grievance Officer or HR immediately."
                ]
            },
            {
                heading: "Training and Communication",
                paragraphs: [
                    "All employees, managers, and supervisors will receive training on the Grievance Handling policy and procedures. This training will include recognizing potential grievances, understanding the grievance process, and promoting a supportive work environment.",
                    "The Grievance Handling policy will be communicated to all employees through the employee handbook, company intranet, and regular training sessions. Employees are encouraged to familiarize themselves with the policy and understand their rights and responsibilities."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The Grievance Handling policy will be regularly monitored and reviewed to ensure its effectiveness and compliance with legal requirements. Feedback from employees and management will be considered in making any necessary updates.",
                    "Periodic reviews will help identify trends, assess the policy's impact, and ensure continuous improvement in handling employee grievances."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that I have read and understood the Grievance Handling policy. I agree to comply with the procedures outlined and understand my rights and responsibilities in raising and addressing grievances."
            }
        ]
    };
    
    export const SampleInternalJobPostingTemplate = {
        title: "Sample Internal Job Posting Template",
        policyBlurb: "This Internal Job Posting policy outlines the procedures and guidelines for posting and applying for job openings within [Company Name]. It aims to provide current employees with opportunities for career growth and advancement by ensuring a fair and transparent internal recruitment process.",
        preRead: "Use this template to develop a policy that encourages internal mobility, recognizes and retains talent, and supports employees' career development goals. This policy ensures that all job openings are communicated effectively and that qualified internal candidates are considered fairly.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Job Posting Procedures",
                    "Eligibility Criteria",
                    "Application Process",
                    "Selection Process",
                    "Notification of Results",
                    "Confidentiality",
                    "Non-Discrimination",
                    "Training and Development",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraphs: [
                    "[Company Name] is committed to providing employees with opportunities for career growth and advancement within the organization. The Internal Job Posting policy is designed to encourage internal mobility by offering current employees the chance to apply for open positions before considering external candidates. This policy ensures that job openings are communicated effectively, and that the internal recruitment process is fair and transparent.",
                    "By promoting internal job postings, we aim to foster a culture of continuous learning and development, allowing our employees to explore new roles and responsibilities within the company. This approach helps in building a more engaged and motivated workforce, as employees see clear pathways for career advancement and feel valued for their contributions."
                ]
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures and guidelines for posting and applying for job openings within [Company Name]. It aims to promote career development, recognize and retain talent, and support employees' career goals by providing them with opportunities for growth and advancement.",
                    "By encouraging internal mobility, the company seeks to build a strong and engaged workforce, leverage existing talent, and foster a culture of continuous learning and development. Internal job postings also help in retaining top talent by providing career progression opportunities within the organization, thereby reducing turnover and maintaining organizational knowledge and expertise."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all current employees of [Company Name], including full-time, part-time, and temporary staff. It covers all job openings, except those specifically designated for external recruitment due to business needs or strategic considerations.",
                    "The policy ensures that all eligible employees have the opportunity to apply for open positions and be considered fairly based on their qualifications, experience, and performance. It is designed to promote fairness and transparency in the recruitment process, ensuring that all employees are aware of available opportunities and have equal access to apply for them."
                ]
            },
            {
                heading: "Job Posting Procedures",
                paragraphs: [
                    "Job openings will be posted internally for a minimum of [X] days before considering external candidates. Internal job postings will be communicated through the company's intranet, email, bulletin boards, and other appropriate channels.",
                    "The job posting will include the job title, department, location, key responsibilities, required qualifications, application deadline, and instructions for applying. The hiring manager and HR department are responsible for ensuring that the job posting is accurate and comprehensive.",
                    "In addition to the basic details, job postings will also highlight the opportunities for growth and development that the position offers. This includes potential career paths, training opportunities, and any special projects or responsibilities that come with the role. By providing detailed information, we aim to attract qualified candidates who are genuinely interested in the position and have a clear understanding of what it entails."
                ]
            },
            {
                heading: "Eligibility Criteria",
                paragraphs: [
                    "To be eligible to apply for an internal job posting, employees must meet the following criteria: (1) have been employed with the company for a minimum of [X] months, (2) meet the required qualifications and experience for the position, (3) have a satisfactory performance record and no active disciplinary actions.",
                    "Exceptions to these criteria may be considered on a case-by-case basis, subject to approval by the HR department and the hiring manager. Employees are encouraged to discuss their eligibility with their current supervisor before applying.",
                    "The eligibility criteria are designed to ensure that only qualified and experienced employees apply for internal job postings. This helps in maintaining a high standard of performance and ensures that the selected candidate can effectively contribute to the role from day one. Additionally, by considering exceptions on a case-by-case basis, we acknowledge that there may be exceptional circumstances where a highly capable employee may not meet all the criteria but still be a good fit for the role."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees interested in applying for an internal job posting must submit a completed application form, along with an updated resume and cover letter, to the HR department by the specified deadline. The application should highlight their relevant skills, experience, and reasons for seeking the position.",
                    "Employees are encouraged to inform their current supervisor of their intent to apply for the position. The HR department will acknowledge receipt of the application and provide updates on the status of the recruitment process.",
                    "The application process is designed to be straightforward and accessible, ensuring that all interested employees have the opportunity to apply. By submitting a detailed application, employees can demonstrate their qualifications and enthusiasm for the role, providing the hiring team with a clear understanding of their suitability for the position."
                ]
            },
            {
                heading: "Selection Process",
                paragraphs: [
                    "The selection process for internal job postings will follow the same principles as external recruitment, including initial screening, interviews, assessments, and reference checks. The HR department and hiring manager will review all applications and shortlist candidates based on their qualifications, experience, and suitability for the role.",
                    "Shortlisted candidates will be invited for interviews and assessments, as appropriate. The selection process will be conducted fairly and objectively, with a focus on identifying the best candidate for the position.",
                    "Throughout the selection process, the HR department and hiring manager will maintain open communication with all candidates, providing feedback and updates on their application status. This ensures transparency and helps in building trust and confidence in the internal recruitment process. Additionally, by following a structured and objective selection process, we aim to minimize bias and ensure that the best candidate is selected based on merit."
                ]
            },
            {
                heading: "Notification of Results",
                paragraphs: [
                    "All candidates who applied for an internal job posting will be notified of the outcome of their application. Successful candidates will receive an offer letter outlining the terms and conditions of the new position. Unsuccessful candidates will be provided with feedback on their application and encouraged to apply for future opportunities.",
                    "The HR department and hiring manager will coordinate the transition process for the successful candidate, including setting a start date and ensuring a smooth handover of responsibilities.",
                    "Providing timely and constructive feedback to all candidates is an important aspect of the notification process. This helps in maintaining a positive relationship with employees and encourages them to continue seeking growth opportunities within the company. Additionally, by offering guidance and support to unsuccessful candidates, we can help them identify areas for improvement and better prepare for future job postings."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "The company is committed to maintaining the confidentiality of all internal job applications. Information related to an employee's application will only be shared with those directly involved in the recruitment process.",
                    "Employees are assured that their application will be handled with discretion and that their current position will not be adversely affected by their decision to apply for another role within the company.",
                    "Maintaining confidentiality is crucial in fostering a trusting and supportive work environment. Employees should feel confident that their applications are being handled professionally and that their privacy is being respected. This encourages more employees to apply for internal job postings, knowing that their career aspirations will be supported and respected."
                ]
            },
            {
                heading: "Non-Discrimination",
                paragraphs: [
                    "[Company Name] is committed to providing equal opportunities to all employees. The internal job posting process will be conducted without discrimination based on race, color, religion, sex, national origin, age, disability, sexual orientation, gender identity, genetic information, or any other protected status.",
                    "All decisions regarding internal job postings will be based on merit, qualifications, and the ability to perform the job effectively.",
                    "Ensuring a non-discriminatory process is essential to upholding the company's values of fairness and equality. By strictly adhering to these principles, we aim to create an inclusive workplace where all employees have the opportunity to succeed and advance based on their skills and performance."
                ]
            },
            {
                heading: "Training and Development",
                paragraphs: [
                    "To support employees in their career development and preparation for internal job opportunities, the company offers various training and development programs. Employees are encouraged to participate in these programs to enhance their skills and qualifications.",
                    "The HR department will work with employees and their supervisors to identify appropriate training and development opportunities, including workshops, courses, mentoring, and on-the-job training.",
                    "Investing in employee development is a key aspect of our commitment to internal mobility. By providing access to training and development programs, we help employees build the skills and knowledge needed to take on new roles and responsibilities. This not only benefits the individual employee but also strengthens the overall capabilities of the organization."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The internal job posting policy will be regularly monitored and reviewed to ensure its effectiveness and alignment with the company's goals. Feedback from employees and managers will be used to make necessary improvements.",
                    "Periodic reviews will help identify trends, assess the policy's impact on employee mobility and satisfaction, and ensure that the internal recruitment process remains fair and transparent.",
                    "By continuously monitoring and reviewing the policy, we aim to keep it relevant and effective in meeting the needs of both the company and its employees. This proactive approach allows us to address any issues promptly and make adjustments that enhance the overall internal job posting process."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that I have read and understood the Internal Job Posting policy. I agree to comply with the procedures outlined and understand my rights and responsibilities in applying for internal job opportunities."
            }
        ]
    };

    export const SampleMoonlightingTemplate = {
        title: "Sample Moonlighting Policy Template",
        policyBlurb: "This Moonlighting Policy outlines the guidelines and procedures for employees seeking to engage in additional employment outside their primary role at [Company Name]. It aims to ensure that moonlighting activities do not interfere with employees' responsibilities, commitments, or the interests of the company.",
        preRead: "Use this template to establish clear guidelines regarding moonlighting to maintain productivity, protect confidential information, and avoid conflicts of interest. This policy applies to all employees, including full-time, part-time, and contract workers.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definition of Moonlighting",
                    "Guidelines for Moonlighting",
                    "Approval Process",
                    "Conflicts of Interest",
                    "Confidentiality and Intellectual Property",
                    "Performance and Productivity",
                    "Non-Disclosure",
                    "Termination of Employment",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we recognize that employees may seek additional employment opportunities outside their primary role. This Moonlighting Policy provides guidelines to ensure that moonlighting activities do not negatively impact employees' performance, our business operations, or our commitment to maintaining a fair and productive work environment."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish clear guidelines and procedures for employees who wish to engage in moonlighting activities. It aims to prevent conflicts of interest, protect company resources and confidential information, and uphold our standards of professionalism and productivity."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name], including full-time, part-time, and contract workers.",
                    "Moonlighting activities covered under this policy include, but are not limited to, secondary employment, freelance work, consulting engagements, and self-employment ventures."
                ]
            },
            {
                heading: "Definition of Moonlighting",
                paragraph: "Moonlighting refers to the practice of engaging in additional employment outside of an employee's primary job with [Company Name]. This can involve working for another employer, self-employment, freelance work, or any other form of compensated work outside regular working hours."
            },
            {
                heading: "Guidelines for Moonlighting",
                paragraphs: [
                    "Employees must ensure that their moonlighting activities do not interfere with their primary responsibilities and obligations to [Company Name].",
                    "Moonlighting activities should not result in a conflict of interest, compromise the company's reputation, or impact the employee's ability to perform their job effectively.",
                    "Employees must comply with all applicable laws, contractual obligations, and ethical standards in their moonlighting activities."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "Employees must obtain written approval from their supervisor or department head before engaging in moonlighting activities.",
                    "The approval process will consider factors such as potential conflicts of interest, impact on job performance, and adherence to company policies.",
                    "Supervisors may consult with the HR department or legal counsel as needed to evaluate the request."
                ]
            },
            {
                heading: "Conflicts of Interest",
                paragraphs: [
                    "Employees must disclose any potential conflicts of interest that may arise from their moonlighting activities.",
                    "A conflict of interest occurs when an employee's outside activities or interests interfere or appear to interfere with their obligations to [Company Name].",
                    "Employees are required to avoid situations where their personal interests conflict with the interests of the company or its clients."
                ]
            },
            {
                heading: "Confidentiality and Intellectual Property",
                paragraphs: [
                    "Employees must not disclose confidential or proprietary information belonging to [Company Name] to third parties in the course of their moonlighting activities.",
                    "Employees must respect the intellectual property rights of [Company Name] and refrain from using company resources, including equipment and facilities, for moonlighting purposes.",
                    "Any inventions, creations, or innovations developed during moonlighting activities that relate to the company's business must be disclosed to [Company Name] and may be subject to the company's intellectual property policies."
                ]
            },
            {
                heading: "Performance and Productivity",
                paragraphs: [
                    "Engaging in moonlighting activities should not adversely affect an employee's performance, productivity, or attendance at [Company Name].",
                    "Employees are responsible for managing their time effectively to ensure that moonlighting does not interfere with their primary job responsibilities.",
                    "Failure to maintain satisfactory job performance or attendance due to moonlighting activities may result in disciplinary action."
                ]
            },
            {
                heading: "Non-Disclosure",
                paragraph: "Employees are required to maintain confidentiality regarding their moonlighting activities unless disclosure is authorized by [Company Name] or required by law."
            },
            {
                heading: "Termination of Employment",
                paragraphs: [
                    "Violation of this Moonlighting Policy may result in disciplinary action, up to and including termination of employment.",
                    "Employees who engage in unauthorized moonlighting activities that result in a conflict of interest or breach of confidentiality may face immediate termination."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure its effectiveness and compliance with legal and regulatory requirements.",
                    "Updates to the policy may be made as necessary to address changes in business practices, industry standards, or legal guidelines.",
                    "Employees will be notified of any updates to the Moonlighting Policy and may be required to acknowledge receipt and understanding of the revised policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, I acknowledge that I have read and understood the Moonlighting Policy of [Company Name]. I agree to comply with the guidelines and procedures outlined herein regarding moonlighting activities."
            }
        ]
    };

    export const SamplePerformanceImprovementPlanTemplate = {
        title: "Sample Performance Improvement Plan Template",
        policyBlurb: "The Performance Improvement Plan (PIP) is designed to assist employees in improving their job performance when it has been identified as below acceptable levels. This plan outlines clear goals, expectations, and support mechanisms to help employees succeed and meet the organization's standards.",
        preRead: "Managers and HR professionals can use this template to guide the process of developing and implementing a Performance Improvement Plan for employees. The goal is to provide constructive feedback, set achievable targets, and support the employee in enhancing their performance.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Performance Assessment",
                    "Development of the Improvement Plan",
                    "Objectives and Goals",
                    "Support and Resources",
                    "Monitoring Progress",
                    "Evaluation and Feedback",
                    "Completion and Follow-Up",
                    "Confidentiality",
                    "Non-Retaliation",
                    "Policy Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name]'s Performance Improvement Plan (PIP) provides a structured approach to support employees in enhancing their job performance when it has been identified as below acceptable levels. By outlining clear goals, expectations, and support mechanisms, this plan aims to assist employees in meeting the organization's standards effectively and efficiently."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of the Performance Improvement Plan (PIP) is to establish a framework for addressing performance deficiencies and supporting employees in achieving their full potential within [Company Name]. This plan aims to foster professional growth, enhance job satisfaction, and contribute to a positive work environment by providing employees with the necessary tools and guidance to succeed."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees whose performance has been identified as below acceptable levels during regular performance assessments or through other evaluation methods. The Performance Improvement Plan (PIP) ensures that employees receive fair and constructive feedback, along with the resources and support needed to improve their performance effectively."
                ]
            },
            {
                heading: "Performance Assessment",
                paragraphs: [
                    "Performance assessments are conducted regularly to evaluate employees' job performance against established criteria and expectations. When an employee's performance is found to be below acceptable levels, the manager or supervisor initiates the PIP process to address identified deficiencies proactively."
                ]
            },
            {
                heading: "Development of the Improvement Plan",
                paragraphs: [
                    "The Performance Improvement Plan (PIP) is developed collaboratively between the employee, their manager or supervisor, and HR personnel. It includes specific performance improvement objectives, measurable goals, timelines for achievement, and support mechanisms tailored to the employee's needs and circumstances."
                ]
            },
            {
                heading: "Objectives and Goals",
                paragraphs: [
                    "The PIP outlines clear objectives and goals that the employee is expected to achieve within a specified timeframe. These objectives are designed to address identified performance deficiencies effectively and help the employee reach the desired level of performance aligned with organizational standards."
                ]
            },
            {
                heading: "Support and Resources",
                paragraphs: [
                    "Employees participating in the PIP receive necessary support, resources, and training to enhance their skills and capabilities. Managers and HR ensure that employees have access to the tools, guidance, and development opportunities required to succeed in meeting the PIP objectives."
                ]
            },
            {
                heading: "Monitoring Progress",
                paragraphs: [
                    "Progress towards achieving PIP goals is monitored regularly through scheduled check-ins, evaluations, and feedback sessions. Adjustments to the PIP may be made based on the employee's progress, challenges encountered, or changes in job responsibilities to ensure continued alignment with performance improvement objectives."
                ]
            },
            {
                heading: "Evaluation and Feedback",
                paragraphs: [
                    "At the end of the PIP period, the employee's performance is evaluated against the established goals and objectives. Comprehensive feedback is provided to the employee regarding their progress, areas of improvement, and next steps based on the evaluation outcomes."
                ]
            },
            {
                heading: "Completion and Follow-Up",
                paragraphs: [
                    "Upon successful completion of the PIP, the employee's performance is reviewed to ensure sustained improvement and alignment with organizational standards. If the employee meets the performance criteria, they continue their regular duties. If not, further actions or extensions to the PIP may be considered to support ongoing development."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All information related to the PIP, including performance assessments, discussions, and outcomes, is treated with strict confidentiality. Access to PIP documentation is limited to individuals directly involved in the performance management process, ensuring privacy and respect for the employee's confidentiality."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraph: "Employees participating in the PIP process are protected against retaliation for their involvement. Retaliation against employees for engaging in good faith performance improvement efforts is strictly prohibited and will not be tolerated."
            },
            {
                heading: "Policy Acknowledgment",
                paragraph: "By participating in the Performance Improvement Plan (PIP), employees acknowledge that they have read, understood, and agree to comply with the objectives, goals, and procedures outlined in this policy."
            }
        ]
    };
    
    export const SampleTelecommutingPolicyTemplate = {
        title: "Sample Telecommuting Policy Template",
        policyBlurb: "This Telecommuting Policy outlines the guidelines and procedures for employees who work remotely or telecommute on a regular or occasional basis. It aims to establish clear expectations, ensure productivity, and maintain communication and security standards.",
        preRead: "Use this template to develop a policy that supports flexible work arrangements while maintaining operational efficiency and employee well-being. This policy encourages responsible telecommuting practices and outlines the rights and responsibilities of both employees and the company.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Telecommuting Arrangements",
                    "Equipment and Workspace",
                    "Work Hours and Availability",
                    "Communication and Collaboration",
                    "Performance Expectations",
                    "Data Security and Confidentiality",
                    "Health and Safety",
                    "Training and Support",
                    "Monitoring and Compliance",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Telecommuting Policy at [Company Name] establishes guidelines and expectations for employees who work remotely or telecommute. This policy aims to promote flexibility in work arrangements while maintaining productivity, collaboration, and adherence to company policies."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures and requirements for telecommuting at [Company Name]. It aims to support work-life balance, enhance employee satisfaction, and contribute to operational continuity.",
                    "By formalizing telecommuting arrangements, the company seeks to accommodate employee preferences for remote work while ensuring business objectives are met."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name] who may be eligible to telecommute, either on a regular or occasional basis. It covers remote work arrangements that occur within or outside of the employee's primary work location.",
                    "Telecommuting arrangements are subject to managerial approval and must align with business needs and job responsibilities."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "Eligibility for telecommuting will be determined based on the nature of the employee's role, job responsibilities, and performance history. Not all positions may be suitable for remote work, and managerial discretion applies.",
                    "Employees must demonstrate the ability to work independently, maintain productivity, and adhere to company policies and procedures while telecommuting."
                ]
            },
            {
                heading: "Telecommuting Arrangements",
                paragraphs: [
                    "Telecommuting may be established as a regular or occasional arrangement, subject to approval by the employee's supervisor and HR department.",
                    "Employees and supervisors must agree on the frequency of telecommuting, work schedule, and communication expectations. Written telecommuting agreements should be documented and signed by both parties."
                ]
            },
            {
                heading: "Equipment and Workspace",
                paragraphs: [
                    "Employees are responsible for ensuring they have suitable equipment and a dedicated workspace to perform their job duties effectively while telecommuting.",
                    "The company may provide necessary equipment and resources, such as laptops, software licenses, and access to secure networks, to support remote work."
                ]
            },
            {
                heading: "Work Hours and Availability",
                paragraphs: [
                    "Employees must adhere to their agreed-upon work schedule while telecommuting and remain available during core business hours as required by their position.",
                    "Supervisors may establish guidelines for availability, responsiveness, and attendance for remote workers to ensure effective communication and collaboration."
                ]
            },
            {
                heading: "Communication and Collaboration",
                paragraphs: [
                    "Effective communication is essential for remote teams. Employees must maintain regular communication with their supervisors, team members, and stakeholders using approved communication channels.",
                    "Collaboration tools, such as video conferencing, instant messaging, and project management software, should be utilized to facilitate teamwork and maintain connectivity."
                ]
            },
            {
                heading: "Performance Expectations",
                paragraphs: [
                    "Telecommuting employees are expected to meet the same performance standards and goals as their in-office counterparts.",
                    "Supervisors will assess performance based on agreed-upon metrics, deliverables, and outcomes, regardless of the work location."
                ]
            },
            {
                heading: "Data Security and Confidentiality",
                paragraphs: [
                    "Employees must adhere to company policies and procedures regarding data security and confidentiality while telecommuting.",
                    "Personal devices should not be used to access sensitive company information unless approved and secured according to IT guidelines."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "Employees are responsible for maintaining a safe and ergonomic workspace at their remote location.",
                    "The company will provide guidelines for ergonomic best practices and may conduct periodic health and safety assessments for telecommuting employees."
                ]
            },
            {
                heading: "Training and Support",
                paragraphs: [
                    "Telecommuting employees may receive training on remote work best practices, cybersecurity awareness, and effective communication strategies.",
                    "The HR department will provide support and resources to assist employees in adapting to telecommuting arrangements and addressing any challenges."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Managers may monitor telecommuting employees' performance, attendance, and adherence to policies to ensure productivity and compliance.",
                    "Employees should maintain regular contact with their supervisors and provide updates on work progress and achievements as required."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed periodically to assess its effectiveness and relevance to business needs and employee preferences.",
                    "Updates to the policy will be communicated to all employees, and changes may be implemented based on feedback and evolving telecommuting practices."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that I have read and understood the Telecommuting Policy. I agree to comply with the guidelines and expectations outlined herein and understand my responsibilities as a telecommuting employee."
            }
        ]
    };

    export const SampleTimeAndAttendancePolicyTemplate = {
        title: "Sample Time and Attendance Policy Template",
        policyBlurb: "This Time and Attendance Policy outlines the guidelines and expectations for recording and managing employee work hours at [Company Name]. It aims to ensure accuracy, fairness, and compliance with company policies and labor laws.",
        preRead: "Use this template to establish clear guidelines for timekeeping, attendance, and leave management. This policy helps maintain productivity, efficiency, and transparency in tracking employees' work hours and attendance.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Work Hours",
                    "Attendance",
                    "Overtime",
                    "Leave Management",
                    "Recording Time",
                    "Breaks and Meal Periods",
                    "Reporting Absences",
                    "Compliance",
                    "Monitoring and Review",
                    "Policy Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Time and Attendance Policy of [Company Name] establishes guidelines for employees regarding their work hours and attendance. This policy ensures consistency and fairness in timekeeping practices across the organization."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures and expectations for recording and managing employee work hours and attendance.",
                    "By implementing this policy, [Company Name] aims to ensure accurate timekeeping, monitor attendance patterns, manage leave effectively, and comply with legal requirements."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name], including full-time, part-time, and temporary employees.",
                    "It covers all aspects related to work hours, attendance, overtime, leave management, and time recording."
                ]
            },
            {
                heading: "Work Hours",
                paragraphs: [
                    "Employees are expected to adhere to their scheduled work hours as communicated by their department or supervisor.",
                    "Work hours may vary by department or position and will be specified in the employee's contract or job description."
                ]
            },
            {
                heading: "Attendance",
                paragraphs: [
                    "Regular and punctual attendance is essential for the efficient operation of [Company Name].",
                    "Employees are required to report to work on time and notify their supervisor promptly of any anticipated absences or delays."
                ]
            },
            {
                heading: "Overtime",
                paragraphs: [
                    "Overtime work may be required based on business needs and must be approved in advance by the employee's supervisor.",
                    "Employees will be compensated for overtime work in accordance with company policy and applicable labor laws."
                ]
            },
            {
                heading: "Leave Management",
                paragraphs: [
                    "Employees are eligible for various types of leave, including vacation, sick leave, personal leave, and other statutory leaves as per company policy and applicable laws.",
                    "Leave requests must be submitted and approved through the company's designated leave management system or process."
                ]
            },
            {
                heading: "Recording Time",
                paragraphs: [
                    "Employees are responsible for accurately recording their work hours, including regular hours, overtime, and any leave taken.",
                    "Time records must be submitted by the specified deadlines and verified by supervisors for accuracy."
                ]
            },
            {
                heading: "Breaks and Meal Periods",
                paragraphs: [
                    "Employees are entitled to breaks and meal periods as required by law and company policy.",
                    "Break times and duration will be communicated by the department or supervisor based on work schedules."
                ]
            },
            {
                heading: "Reporting Absences",
                paragraphs: [
                    "Employees must notify their supervisor as soon as possible in case of unplanned absences due to illness, emergencies, or other reasons.",
                    "Absence reporting procedures may include calling a designated phone number, emailing, or using an online absence reporting system."
                ]
            },
            {
                heading: "Compliance",
                paragraphs: [
                    "All employees must comply with this Time and Attendance Policy and related company procedures.",
                    "Failure to adhere to policy guidelines may result in disciplinary action, up to and including termination."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure its effectiveness and compliance with evolving business needs and legal requirements.",
                    "Feedback from employees and supervisors will be considered to make necessary improvements to timekeeping and attendance practices."
                ]
            },
            {
                heading: "Policy Acknowledgment",
                paragraph: "I acknowledge that I have received a copy of the Time and Attendance Policy and agree to abide by its provisions. I understand my responsibilities regarding work hours, attendance, and leave management."
            }
        ]
    };
    
    export const SampleWhistleblowerProtectionPolicyTemplate = {
        title: "Sample Whistleblower Protection Policy Template",
        policyBlurb: "This Whistleblower Protection Policy outlines [Company Name]'s commitment to creating an environment where employees feel safe and encouraged to report unethical or illegal activities without fear of retaliation. It aims to protect whistleblowers from adverse consequences and ensure that all reports are handled confidentially, promptly, and impartially.",
        preRead: "Use this template to establish clear guidelines for reporting wrongdoing within the organization. By implementing this policy, [Company Name] demonstrates its commitment to integrity, transparency, and ethical behavior.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Reporting Procedures",
                    "Confidentiality",
                    "Protection from Retaliation",
                    "Investigation Process",
                    "Disciplinary Action",
                    "Communication and Training",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "At [Company Name], we value integrity, transparency, and ethical behavior. This Whistleblower Protection Policy is designed to encourage employees to report concerns about potential wrongdoing without fear of retaliation. It underscores our commitment to maintaining a work environment where ethical conduct is valued and protected."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to establish procedures for reporting and investigating allegations of misconduct or illegal activities within [Company Name].",
                    "This policy aims to protect whistleblowers from retaliation and ensure that all reports are handled confidentially, impartially, and in compliance with applicable laws and regulations."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, consultants, and third parties associated with [Company Name]. It covers any concerns related to unethical conduct, fraud, corruption, violations of company policies, or breaches of legal obligations.",
                    "The policy encourages employees to report concerns internally first. However, it also provides guidance on reporting to external authorities when necessary."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Whistleblower: An employee who reports suspected misconduct or illegal activities in good faith.",
                    "Retaliation: Any adverse action taken against a whistleblower in response to their report, including but not limited to dismissal, demotion, harassment, or discrimination."
                ]
            },
            {
                heading: "Reporting Procedures",
                paragraphs: [
                    "Employees who have concerns about potential wrongdoing should report them to their supervisor, manager, HR department, or designated whistleblower hotline.",
                    "Reports should include specific details of the incident or behavior observed, along with any supporting evidence or documentation.",
                    "Anonymous reports will be accepted and investigated to the extent possible, provided they contain sufficient information for investigation."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All reports of suspected misconduct will be handled confidentially to the extent possible, considering the need to conduct a thorough investigation.",
                    "Information related to whistleblower reports will only be disclosed on a need-to-know basis or as required by law."
                ]
            },
            {
                heading: "Protection from Retaliation",
                paragraphs: [
                    "Retaliation against whistleblowers is strictly prohibited and will result in disciplinary action, up to and including termination of employment.",
                    "Employees who believe they have experienced retaliation for whistleblowing should report it immediately to HR or the designated authority for investigation."
                ]
            },
            {
                heading: "Investigation Process",
                paragraphs: [
                    "All reports of suspected misconduct will be promptly investigated by the appropriate authorities, such as HR, legal department, or an external investigator.",
                    "Investigations will be conducted impartially and will include gathering evidence, interviewing relevant parties, and documenting findings.",
                    "The results of investigations will be communicated to the whistleblower to the extent possible, while maintaining confidentiality and protecting the privacy of individuals involved."
                ]
            },
            {
                heading: "Disciplinary Action",
                paragraphs: [
                    "If an investigation confirms misconduct, appropriate disciplinary action will be taken against the individuals responsible.",
                    "Disciplinary measures may include warnings, counseling, suspension, termination of employment, or legal action, depending on the severity and nature of the misconduct."
                ]
            },
            {
                heading: "Communication and Training",
                paragraphs: [
                    "This policy will be communicated to all employees upon hiring and regularly thereafter.",
                    "Training programs on ethical behavior, reporting procedures, and whistleblower protection will be provided to ensure awareness and compliance."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "The effectiveness of this policy will be monitored periodically to ensure its relevance and compliance with legal requirements.",
                    "Feedback from employees and stakeholders will be considered to make necessary improvements."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, I acknowledge that I have read, understood, and agree to comply with [Company Name]'s Whistleblower Protection Policy. I understand that I can report concerns about potential wrongdoing without fear of retaliation."
            }
        ]
    };

    export const SharedParentalLeavePolicyTemplate = {
        title: "Shared Parental Leave Policy Template",
        policyBlurb: "This policy outlines the provisions and procedures for Shared Parental Leave (SPL) at [Company Name]. It aims to support parents in balancing work and family responsibilities by providing flexible leave options.",
        preRead: "Use this template to establish a policy that complies with legal requirements and promotes a supportive work environment for employees taking shared parental leave. This policy ensures fairness and transparency in managing SPL requests.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Entitlement",
                    "Notification and Application Process",
                    "Management of Shared Parental Leave",
                    "Payment and Benefits",
                    "Return to Work",
                    "Flexible Working Arrangements",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] recognizes the importance of supporting employees in their parental responsibilities. This policy outlines the provisions for Shared Parental Leave (SPL), allowing eligible employees to share leave entitlement following the birth or adoption of a child."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to provide eligible employees with flexibility in taking parental leave to care for their child. It aims to promote work-life balance, support gender equality in parenting roles, and comply with legal obligations regarding SPL.",
                    "By offering SPL, [Company Name] aims to foster a supportive and inclusive work environment that values employees' family commitments."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all eligible employees of [Company Name] who meet the criteria for SPL. It covers situations involving childbirth or adoption, ensuring that employees have the opportunity to take leave to care for their child and maintain a work-life balance.",
                    "The policy ensures that SPL requests are managed fairly and consistently across the organization."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "To be eligible for SPL, employees must meet the statutory requirements set out in applicable employment laws. This includes having a partner (spouse, civil partner, or joint adopter) who meets employment and earnings criteria.",
                    "Employees must also provide the required notice and declarations as per legal and company requirements."
                ]
            },
            {
                heading: "Entitlement",
                paragraphs: [
                    "Eligible employees are entitled to a specified amount of SPL and Shared Parental Pay (ShPP) as per statutory guidelines. The entitlement may vary based on the employee's and their partner's eligibility and the duration of leave taken by the primary caregiver.",
                    "Detailed entitlement calculations and conditions are provided in the accompanying SPL policy documentation."
                ]
            },
            {
                heading: "Notification and Application Process",
                paragraphs: [
                    "Employees must notify their line manager or HR department of their intention to take SPL at least [X] weeks before the intended start date. They are required to submit an SPL application form along with supporting documentation, such as a birth certificate or adoption papers.",
                    "The HR department will acknowledge receipt of the application and provide guidance on the SPL process."
                ]
            },
            {
                heading: "Management of Shared Parental Leave",
                paragraphs: [
                    "The HR department will assess SPL applications in accordance with statutory requirements and company policies. They will review eligibility criteria, entitlements, and availability of SPL within the team or department.",
                    "Decisions regarding SPL requests will be communicated to the employee in writing, outlining the approved leave period and any associated conditions."
                ]
            },
            {
                heading: "Payment and Benefits",
                paragraphs: [
                    "During SPL, eligible employees may receive Shared Parental Pay (ShPP) in accordance with statutory rates and company policies. The HR department will provide information on payment schedules and entitlements.",
                    "Employees on SPL will continue to receive benefits such as pension contributions and accrual of annual leave entitlement, as per company policies."
                ]
            },
            {
                heading: "Return to Work",
                paragraphs: [
                    "Upon completion of SPL, employees have the right to return to the same job or a suitable alternative role with terms and conditions no less favorable than their previous position. The HR department will facilitate a smooth transition back to work.",
                    "Employees are encouraged to discuss their return-to-work plans and any flexible working arrangements with their line manager in advance."
                ]
            },
            {
                heading: "Flexible Working Arrangements",
                paragraphs: [
                    "To support employees balancing work and childcare responsibilities, [Company Name] offers flexible working arrangements where feasible. This may include options such as part-time work, job sharing, or telecommuting, subject to operational needs and agreement with the employer.",
                    "Employees are encouraged to discuss flexible working arrangements with their line manager or HR department to explore suitable options."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "This policy will be monitored and reviewed regularly to ensure its effectiveness and compliance with legal requirements. Feedback from employees and managers will be considered to make necessary improvements and updates.",
                    "Periodic reviews will help assess the policy's impact on employees' work-life balance, satisfaction, and organizational culture."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, I acknowledge that I have read and understood the Shared Parental Leave policy. I agree to comply with its provisions and understand my rights and responsibilities regarding SPL at [Company Name]."
            }
        ]
    };

    export const ShortTermDisabilityCoveragePolicyTemplate = {
        title: "Short-Term Disability Coverage Policy Template",
        policyBlurb: "This policy outlines the provisions and procedures for Short-Term Disability (STD) coverage at [Company Name]. It aims to provide financial protection and support to employees who are temporarily unable to work due to a non-work-related illness, injury, or medical condition.",
        preRead: "Use this template to establish a policy that ensures consistency in managing short-term disability claims, complies with legal requirements, and supports employees during periods of medical incapacity. This policy aims to facilitate a smooth transition for employees returning to work after a temporary disability.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Coverage Details",
                    "Qualifying Conditions",
                    "Notification and Application Process",
                    "Medical Certification",
                    "Duration and Payment",
                    "Return to Work",
                    "Benefits Continuation",
                    "Monitoring and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] understands the importance of supporting employees during periods of temporary disability. This Short-Term Disability Coverage policy is designed to provide eligible employees with financial protection and assistance when they are unable to work due to a covered medical condition."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures and guidelines for Short-Term Disability (STD) coverage at [Company Name]. It aims to ensure that employees receive timely and appropriate financial support during temporary periods of incapacity.",
                    "By offering STD coverage, [Company Name] aims to alleviate financial stress for employees facing medical conditions that prevent them from performing their job duties."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all eligible employees of [Company Name], including full-time, part-time, and temporary staff. It covers disabilities resulting from non-work-related illnesses, injuries, or medical conditions that temporarily prevent employees from performing their regular job duties.",
                    "The policy ensures that STD claims are processed and managed fairly and consistently across the organization."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "To be eligible for Short-Term Disability (STD) coverage, employees must meet the criteria specified in the policy. This includes having completed the probationary period or meeting other specified eligibility requirements.",
                    "Employees are encouraged to review the eligibility criteria and consult with the HR department to determine their eligibility status before applying for STD coverage."
                ]
            },
            {
                heading: "Coverage Details",
                paragraphs: [
                    "STD coverage provides eligible employees with a percentage of their base salary during periods of temporary disability. The specific coverage details, including the waiting period and benefit duration, are outlined in the accompanying policy documentation.",
                    "Employees are advised to familiarize themselves with the coverage details to understand their entitlements and obligations under the policy."
                ]
            },
            {
                heading: "Qualifying Conditions",
                paragraphs: [
                    "Employees may qualify for STD coverage if they are unable to work due to a covered medical condition, such as illness, injury, or pregnancy-related complications.",
                    "Qualifying conditions and medical documentation requirements are specified in the policy and must be verified by a healthcare provider."
                ]
            },
            {
                heading: "Notification and Application Process",
                paragraphs: [
                    "Employees are required to notify their supervisor or HR department as soon as possible after becoming aware of their medical condition that may qualify for STD coverage. The HR department will provide guidance on the application process and required documentation.",
                    "Employees must submit a completed STD claim form along with medical certification from their healthcare provider to support their claim."
                ]
            },
            {
                heading: "Medical Certification",
                paragraphs: [
                    "Medical certification is required to verify the employee's medical condition and the expected duration of disability. The healthcare provider must complete and submit the medical certification form directly to the HR department.",
                    "The HR department will review the medical certification to assess the employee's eligibility for STD coverage."
                ]
            },
            {
                heading: "Duration and Payment",
                paragraphs: [
                    "The duration of STD coverage and payment amounts are determined based on the employee's medical condition, recovery prognosis, and coverage details outlined in the policy.",
                    "Employees will receive regular payments for the duration of their approved STD leave, subject to any waiting periods or benefit limits specified in the policy."
                ]
            },
            {
                heading: "Return to Work",
                paragraphs: [
                    "Employees on STD leave are expected to keep the HR department informed of their recovery progress and anticipated return-to-work date. They must provide medical clearance from their healthcare provider before resuming their job duties.",
                    "The HR department will coordinate the employee's return to work, including any necessary accommodations or adjustments to facilitate a smooth transition."
                ]
            },
            {
                heading: "Benefits Continuation",
                paragraphs: [
                    "During STD leave, employees may continue to receive certain employment benefits, such as health insurance coverage and retirement plan contributions, as per company policies and applicable laws.",
                    "Employees are advised to review their benefit entitlements during STD leave and consult with the HR department for clarification."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "This policy will be monitored and reviewed regularly to ensure its effectiveness and compliance with legal requirements. Feedback from employees, healthcare providers, and HR professionals will be considered to make necessary improvements and updates.",
                    "Periodic reviews will help assess the policy's impact on employee well-being, satisfaction, and organizational effectiveness."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, I acknowledge that I have read and understood the Short-Term Disability Coverage policy. I agree to comply with its provisions and understand my rights and responsibilities regarding STD coverage at [Company Name]."
            }
        ]
    };

    export const SolicitationAndDistributionPolicyTemplate = {
        title: "Solicitation and Distribution Policy Template",
        policyBlurb: "This policy governs the solicitation and distribution activities within [Company Name]. It aims to ensure a productive and respectful workplace environment while balancing employees' rights to express themselves and engage in permissible activities.",
        preRead: "Use this template to establish clear guidelines regarding solicitation and distribution activities. This policy helps maintain workplace order and harmony by defining acceptable behaviors and outlining the consequences of non-compliance.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Guidelines for Solicitation",
                    "Guidelines for Distribution",
                    "Prohibited Conduct",
                    "Enforcement",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Solicitation and Distribution Policy at [Company Name] governs the activities related to solicitation and distribution of materials within the workplace. This policy aims to strike a balance between employees' rights to engage in certain activities and the need to maintain a productive work environment."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to provide guidelines for solicitation and distribution activities to ensure that they do not interfere with work responsibilities or disrupt the workplace environment. It aims to clarify what constitutes acceptable conduct in these areas and outline the consequences of violations."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, visitors, and any other individuals present on company premises.",
                    "It covers all forms of solicitation and distribution, including but not limited to petitions, sales, charitable donations, literature, and promotional materials."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Solicitation: Any verbal or written request for money, donations, sales, subscriptions, signatures, or support of any kind.",
                    "Distribution: The circulation or dissemination of literature, pamphlets, promotional materials, or any other written or printed materials."
                ]
            },
            {
                heading: "Guidelines for Solicitation",
                paragraphs: [
                    "Solicitation during work hours should be minimal and should not interfere with work duties.",
                    "Employees must obtain permission from their supervisor before engaging in any form of solicitation on company premises.",
                    "Solicitation that is coercive, intimidating, or disruptive is strictly prohibited."
                ]
            },
            {
                heading: "Guidelines for Distribution",
                paragraphs: [
                    "Distribution of literature or materials should not disrupt work activities or create clutter in common areas.",
                    "Materials must be non-commercial in nature unless approved by management.",
                    "Employees should not use company communication channels for unauthorized distribution."
                ]
            },
            {
                heading: "Prohibited Conduct",
                paragraphs: [
                    "Engaging in solicitation or distribution activities that violate this policy may result in disciplinary action.",
                    "Employees are prohibited from soliciting or distributing materials that are discriminatory, harassing, defamatory, or illegal."
                ]
            },
            {
                heading: "Enforcement",
                paragraphs: [
                    "Supervisors and managers are responsible for enforcing this policy and addressing any violations promptly.",
                    "Disciplinary actions for violations of this policy may range from a verbal warning to termination, depending on the severity and recurrence of the offense."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure it remains up-to-date and effective.",
                    "Updates or revisions to the policy will be communicated to all employees."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to acknowledge receipt and understanding of this policy. Failure to comply may result in disciplinary action."
            }
        ]
    };
    
    export const StressManagementAndSupportPolicyTemplate = {
        title: "Stress Management and Support Policy Template",
        policyBlurb: "This policy outlines [Company Name]'s commitment to promoting stress management and providing support for employees to maintain their mental and emotional well-being. It aims to create a supportive work environment where employees can thrive and perform at their best.",
        preRead: "Use this template to establish guidelines and resources that support stress management among employees. This policy emphasizes proactive measures to prevent stress-related issues and provides support mechanisms for employees experiencing stress.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Responsibilities",
                    "Stress Prevention",
                    "Support Resources",
                    "Confidentiality",
                    "Training and Awareness",
                    "Monitoring and Evaluation",
                    "Review and Revision",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] recognizes that work-related stress can impact employees' health, well-being, and productivity. This policy aims to foster a positive work environment that promotes stress management and supports employees in maintaining their mental and emotional health."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to outline [Company Name]'s commitment to preventing and managing work-related stress. It aims to provide guidelines and resources that support employees in coping with stress and seeking assistance when needed."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name], including full-time, part-time, and temporary staff.",
                    "It covers all aspects of stress management within the workplace, including prevention, support, and resources."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Stress: A state of mental or emotional strain resulting from demanding circumstances or situations.",
                    "Work-related Stress: Stress caused or exacerbated by factors related to the workplace environment or job responsibilities."
                ]
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Management: Managers and supervisors are responsible for promoting a supportive work environment and addressing stress-related issues.",
                    "Employees: Employees are responsible for managing their own stress levels and seeking support when needed.",
                    "HR Department: The HR department is responsible for implementing and monitoring the effectiveness of this policy."
                ]
            },
            {
                heading: "Stress Prevention",
                paragraphs: [
                    "Promoting Work-Life Balance: Encouraging flexible work arrangements, promoting reasonable workload distribution, and encouraging employees to take breaks.",
                    "Clear Communication: Ensuring clear communication of job expectations, providing feedback, and fostering open communication channels.",
                    "Conflict Resolution: Providing mechanisms for resolving conflicts and addressing workplace issues promptly."
                ]
            },
            {
                heading: "Support Resources",
                paragraphs: [
                    "Employee Assistance Program (EAP): Offering confidential counseling and support services for employees experiencing personal or work-related stress.",
                    "Health and Wellness Programs: Providing access to wellness activities, mindfulness programs, and health education workshops.",
                    "Training and Workshops: Offering stress management training, resilience-building workshops, and mental health first aid courses."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "Maintaining Privacy: Ensuring that information related to an employee's stress management or seeking support is kept confidential, except where disclosure is required by law.",
                    "Non-Retaliation: Ensuring that employees feel safe to seek support without fear of retaliation or adverse consequences."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Educational Programs: Conducting training sessions and workshops on stress management techniques, recognizing signs of stress, and promoting mental health awareness.",
                    "Promoting Resources: Ensuring that employees are aware of available resources and how to access them."
                ]
            },
            {
                heading: "Monitoring and Evaluation",
                paragraphs: [
                    "Periodic Review: Monitoring the implementation of this policy and evaluating its effectiveness in reducing stress-related issues.",
                    "Feedback Mechanisms: Soliciting feedback from employees to identify areas for improvement and additional support needs."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "Regular Review: This policy will be reviewed periodically to ensure it remains current and effective in addressing workplace stress.",
                    "Updates: Making updates as necessary to align with best practices and legal requirements."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to acknowledge receipt and understanding of this policy. By acknowledging, employees indicate their commitment to supporting a stress-resilient workplace."
            }
        ]
    };

    export const StudyLeavePolicyTemplate = {
        title: "Study Leave Policy Template",
        policyBlurb: "The Study Leave policy outlines [Company Name]'s guidelines and procedures regarding employees' requests for study leave to pursue educational opportunities. It aims to support continuous learning, career development, and skill enhancement among employees.",
        preRead: "Use this template to establish a framework that encourages employees to upgrade their knowledge and skills through formal education or professional development courses. This policy ensures that study leave requests are evaluated fairly and that the organization benefits from employees' enhanced capabilities.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Types of Study Leave",
                    "Application Process",
                    "Approval Process",
                    "Study Leave Conditions",
                    "Monitoring and Reporting",
                    "Policy Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] recognizes the importance of continuous learning and professional development in enhancing employee skills and knowledge. The Study Leave policy is designed to facilitate employees' pursuit of formal education or professional development opportunities while maintaining a balance with work responsibilities."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to outline the procedures and guidelines for granting study leave to employees. It aims to encourage lifelong learning, support career advancement, and contribute to the overall growth and success of [Company Name] by fostering a skilled and knowledgeable workforce.",
                    "By providing study leave opportunities, the company aims to enhance employee satisfaction, retention, and productivity."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all regular full-time employees of [Company Name]. Part-time employees may be eligible for study leave on a pro-rata basis, subject to approval.",
                    "Study leave may be granted for pursuing formal educational qualifications (such as degree programs, diplomas, or certifications) relevant to the employee's role or for professional development courses that enhance job-related skills."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "To be eligible for study leave, employees must have completed a minimum of [X] months of continuous service with the company. Employees should also demonstrate a commitment to their current role and show how the study leave will benefit both their professional development and their contributions to the organization.",
                    "Eligibility criteria may vary depending on the type of study leave requested (educational or professional development). Employees should consult with HR to determine their eligibility before applying."
                ]
            },
            {
                heading: "Types of Study Leave",
                paragraphs: [
                    "Educational Study Leave: Employees may request study leave to pursue formal educational qualifications relevant to their job role.",
                    "Professional Development Study Leave: Employees may request study leave to attend workshops, seminars, conferences, or courses that enhance their job-related skills and knowledge."
                ]
            },
            {
                heading: "Application Process",
                paragraphs: [
                    "Employees must submit a formal application for study leave to their supervisor or HR department well in advance of the proposed leave period. The application should include details such as the purpose of the study leave, the program or course to be undertaken, the duration of the leave, and how it aligns with the employee's career goals.",
                    "Employees are encouraged to discuss their study leave plans with their supervisor to ensure alignment with departmental goals and work schedules."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "All study leave requests will be reviewed and approved by [HR Manager/Department] in consultation with the employee's supervisor and relevant department heads.",
                    "Approval will be based on factors such as the employee's eligibility, the relevance of the study leave to their role, the impact on departmental operations, and the availability of resources."
                ]
            },
            {
                heading: "Study Leave Conditions",
                paragraphs: [
                    "During study leave, employees are expected to comply with the terms and conditions agreed upon during the approval process.",
                    "Employees must provide periodic progress reports to HR or their supervisor, detailing their academic or professional development achievements during the study leave period.",
                    "Employees granted study leave will be required to return to work for a minimum period following the completion of their leave, as agreed upon during the approval process."
                ]
            },
            {
                heading: "Monitoring and Reporting",
                paragraphs: [
                    "HR will monitor the implementation of the study leave policy to ensure compliance with the agreed terms and conditions.",
                    "Regular reports may be prepared to assess the impact of study leave on employee development, job performance, and overall organizational effectiveness."
                ]
            },
            {
                heading: "Policy Review",
                paragraphs: [
                    "This policy will be reviewed periodically to assess its effectiveness and relevance to [Company Name]'s goals and objectives.",
                    "Feedback from employees who have taken study leave, supervisors, and department heads will be considered in the policy review process."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By signing below, I acknowledge that I have read and understood the Study Leave policy of [Company Name]. I agree to comply with the guidelines and conditions outlined herein, including the requirement to provide progress reports during my study leave."
            }
        ]
    };

    export const TemporaryDisabilityCoveragePolicyTemplate = {
        title: "Temporary Disability Coverage Policy Tempalte",
        policyBlurb: "This policy outlines the provisions and procedures for temporary disability coverage at [Company Name]. It aims to provide financial support and assistance to employees who are temporarily unable to work due to non-work-related injuries or illnesses.",
        preRead: "Use this template to establish clear guidelines and procedures for managing temporary disability cases in a fair and consistent manner. This policy ensures that employees receive necessary support during their recovery period while maintaining compliance with legal requirements and company standards.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Eligibility",
                    "Coverage Details",
                    "Claims Process",
                    "Duration of Coverage",
                    "Return to Work",
                    "Benefits During Disability",
                    "Employee Responsibilities",
                    "Confidentiality",
                    "Policy Review and Updates",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "The Temporary Disability Coverage policy at [Company Name] aims to support employees who experience temporary disabilities that prevent them from performing their job duties. This policy outlines the procedures and benefits available to eligible employees during their recovery period."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to provide financial and logistical support to employees who are temporarily unable to work due to non-work-related injuries or illnesses. By offering temporary disability coverage, [Company Name] aims to assist employees in maintaining financial stability and facilitate their return to work."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all full-time and part-time employees of [Company Name]. It covers temporary disabilities resulting from non-work-related injuries or illnesses.",
                    "The policy ensures that eligible employees receive temporary disability benefits in accordance with legal requirements and company guidelines."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Temporary Disability: A condition that temporarily prevents an employee from performing their job duties.",
                    "Coverage Period: The duration during which an employee is eligible to receive temporary disability benefits.",
                    "Healthcare Provider: A licensed medical professional responsible for diagnosing and treating the employee's temporary disability."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "To be eligible for temporary disability coverage, an employee must meet the following criteria:",
                    "Be a full-time or part-time employee of [Company Name].",
                    "Have completed the probationary period or qualifying period as defined by company policy.",
                    "Provide medical documentation from a healthcare provider certifying the temporary disability.",
                    "Follow the claims process outlined in this policy."
                ]
            },
            {
                heading: "Coverage Details",
                paragraphs: [
                    "Temporary disability coverage includes:",
                    "Partial replacement of lost income during the disability period.",
                    "Continuation of certain employment benefits as specified in the employee benefits plan.",
                    "Access to resources and support services to facilitate recovery and return to work."
                ]
            },
            {
                heading: "Claims Process",
                paragraphs: [
                    "Employees seeking temporary disability coverage must:",
                    "Notify their supervisor or HR department as soon as possible after the disability occurs.",
                    "Submit medical documentation from a healthcare provider certifying the temporary disability.",
                    "Complete and submit the necessary forms to initiate the claims process.",
                    "Cooperate with any additional requirements or requests from the HR department or insurance provider."
                ]
            },
            {
                heading: "Duration of Coverage",
                paragraphs: [
                    "The duration of temporary disability coverage is determined based on medical assessment and certification from a healthcare provider. Coverage typically extends until the employee is medically cleared to return to work or until the maximum coverage period specified in the policy."
                ]
            },
            {
                heading: "Return to Work",
                paragraphs: [
                    "Employees are expected to notify their supervisor and HR department of their intention to return to work as soon as they are medically cleared. [Company Name] will make reasonable accommodations, if necessary, to support the employee's return to work."
                ]
            },
            {
                heading: "Benefits During Disability",
                paragraphs: [
                    "During the temporary disability period, eligible employees may continue to receive certain employment benefits, such as health insurance coverage and accrual of vacation or sick leave, in accordance with company policy."
                ]
            },
            {
                heading: "Employee Responsibilities",
                paragraphs: [
                    "Employees receiving temporary disability coverage are responsible for:",
                    "Providing accurate and timely medical documentation to support their claim.",
                    "Cooperating with the HR department and insurance provider throughout the claims process.",
                    "Keeping the company informed of their medical status and anticipated return-to-work date."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "All medical information related to an employee's temporary disability will be kept confidential and shared only with individuals involved in the claims process or as required by law."
                ]
            },
            {
                heading: "Policy Review and Updates",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure its effectiveness and compliance with legal requirements. Updates may be made as necessary to reflect changes in company operations, healthcare regulations, or industry standards."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By accepting temporary disability coverage, employees acknowledge that they have read and understood the policy. They agree to comply with its provisions and understand their rights and responsibilities."
            }
        ]
    };
    
    export const ThirdPartyHarassmentPreventionPolicyTemplate = {
        title: "Third-Party Harassment Prevention Policy Template",
        policyBlurb: "This policy outlines [Company Name]'s commitment to preventing and addressing harassment from third parties, such as clients, vendors, suppliers, and contractors. It aims to provide a safe and respectful workplace environment for all employees, free from harassment and inappropriate conduct.",
        preRead: "Use this template to establish clear guidelines and procedures for preventing and addressing third-party harassment. This policy ensures that employees feel empowered to report incidents of harassment without fear of retaliation and that appropriate actions are taken to investigate and resolve complaints in a timely manner.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Policy Statement",
                    "Scope",
                    "Definitions",
                    "Prohibited Conduct",
                    "Responsibilities",
                    "Reporting Procedure",
                    "Investigation Process",
                    "Confidentiality",
                    "Corrective Action",
                    "Training and Awareness",
                    "Monitoring and Review",
                    "Non-Retaliation",
                    "Policy Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "Third-party harassment refers to any unwelcome or offensive behavior directed at employees by individuals or entities outside of [Company Name], such as clients, customers, vendors, suppliers, or contractors. This policy aims to prevent such harassment and provide a framework for addressing complaints effectively."
            },
            {
                heading: "Policy Statement",
                paragraph: "[Company Name] is committed to maintaining a workplace environment free from harassment, including harassment by third parties. Harassment based on race, color, religion, sex, national origin, age, disability, sexual orientation, gender identity, or any other protected characteristic is strictly prohibited and will not be tolerated."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees of [Company Name], including full-time, part-time, temporary, and contract workers.",
                    "It also applies to harassment that occurs in the workplace or in connection with work-related activities outside the workplace, such as business trips, conferences, or company events."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Third Party: Any individual or entity that is not an employee of [Company Name], including but not limited to clients, customers, vendors, suppliers, contractors, and service providers.",
                    "Harassment: Unwelcome conduct based on a protected characteristic that creates an intimidating, hostile, or offensive work environment."
                ]
            },
            {
                heading: "Prohibited Conduct",
                paragraphs: [
                    "Examples of prohibited conduct by third parties include:",
                    "Verbal or written abuse, including derogatory remarks, slurs, or insults.",
                    "Unwelcome advances, requests for sexual favors, or other verbal or physical conduct of a sexual nature.",
                    "Offensive gestures, displays, or materials based on protected characteristics.",
                    "Threats or intimidation based on race, religion, disability, or other protected characteristics."
                ]
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Management: Supervisors and managers are responsible for enforcing this policy, responding promptly to reports of third-party harassment, and taking appropriate action to address and prevent further incidents.",
                    "Employees: All employees are responsible for reporting incidents of third-party harassment promptly and cooperating with investigations.",
                    "HR Department: The HR department is responsible for receiving and documenting harassment complaints, conducting investigations, and recommending corrective action as necessary."
                ]
            },
            {
                heading: "Reporting Procedure",
                paragraphs: [
                    "Employees who experience or witness third-party harassment should report the incident to their supervisor, HR department, or any designated individual identified in the company's reporting procedure.",
                    "Reports should include details of the incident, such as the date, time, location, individuals involved, and any witnesses."
                ]
            },
            {
                heading: "Investigation Process",
                paragraphs: [
                    "Upon receiving a harassment complaint, the HR department will conduct a thorough and impartial investigation.",
                    "The investigation may include interviewing the complainant, alleged harasser, and witnesses, as well as reviewing relevant documents or evidence.",
                    "Confidentiality will be maintained to the extent possible during the investigation process."
                ]
            },
            {
                heading: "Confidentiality",
                paragraph: "All information related to a harassment complaint, including the identity of the complainant and witnesses, will be kept confidential to the extent possible. Disclosure will be limited to individuals involved in the investigation or as required by law."
            },
            {
                heading: "Corrective Action",
                paragraphs: [
                    "If the investigation confirms that harassment occurred, appropriate corrective action will be taken promptly.",
                    "Corrective actions may include counseling, warning, suspension, termination of business relationship, or other measures deemed necessary to prevent further harassment."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "To prevent third-party harassment, [Company Name] will provide training sessions for employees, supervisors, and managers on recognizing, preventing, and reporting harassment.",
                    "Training will also emphasize the importance of maintaining a respectful workplace environment and complying with company policies."
                ]
            },
            {
                heading: "Monitoring and Review",
                paragraphs: [
                    "This policy will be reviewed periodically to assess its effectiveness and ensure compliance with legal requirements.",
                    "Feedback from employees and managers will be used to make improvements to the policy and procedures as needed."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraph: "Retaliation against individuals who report third-party harassment or participate in an investigation is strictly prohibited and will result in disciplinary action, up to and including termination of employment or business relationship."
            },
            {
                heading: "Policy Acknowledgment",
                paragraph: "All employees are required to read, understand, and comply with this Third-Party Harassment Prevention policy. By signing below, employees acknowledge their commitment to maintaining a harassment-free workplace and agree to adhere to the principles outlined in this policy."
            }
        ]
    };

    export const TransgenderInclusionAndSupportPolicyTemplate = {
        title: "Transgender Inclusion and Support Policy Template",
        policyBlurb: "This policy outlines [Company Name]'s commitment to creating an inclusive and supportive workplace environment for transgender employees. It aims to promote equality, respect, and dignity for all employees regardless of gender identity or expression.",
        preRead: "Use this template to develop a policy that ensures transgender employees are treated fairly, have equal access to opportunities, and are supported in their workplace needs. This policy aligns with legal requirements and promotes a culture of diversity and inclusion within the organization.",
        sections: [
            {
                heading: "Introduction",
                paragraph: "[Company Name] is committed to fostering a diverse and inclusive workplace where every employee feels valued, respected, and supported. This policy reflects our commitment to promoting transgender inclusion and supporting employees of all gender identities."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to establish guidelines and procedures to ensure that transgender employees are treated fairly, have equal opportunities for advancement and development, and can work in an environment free from discrimination and harassment."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including transgender individuals, gender non-conforming individuals, and those undergoing gender transition, at [Company Name]. It covers all aspects of employment, including hiring, promotion, training, benefits, and workplace conduct.",
                    "The policy also applies to interactions with clients, customers, vendors, and any other individuals associated with [Company Name]."
                ]
            },
            {
                heading: "Definitions",
                paragraph: "Transgender: An umbrella term that describes people whose gender identity or expression differs from the sex they were assigned at birth."
            },
            {
                heading: "Equal Employment Opportunity",
                paragraphs: [
                    "[Company Name] provides equal employment opportunities to all employees, including transgender individuals, without regard to gender identity or expression, sexual orientation, or any other protected characteristic.",
                    "Discrimination or harassment based on gender identity or expression is strictly prohibited and will not be tolerated."
                ]
            },
            {
                heading: "Respect and Dignity",
                paragraphs: [
                    "All employees, regardless of gender identity or expression, are entitled to respect and dignity in the workplace.",
                    "Colleagues are expected to use the chosen name, pronouns, and gender identity of transgender employees."
                ]
            },
            {
                heading: "Gender Transition Guidelines",
                paragraphs: [
                    "Employees who are transitioning or have transitioned genders will be supported in their workplace needs, including but not limited to restroom access, dress code, and name and gender marker changes in company records.",
                    "Confidentiality regarding an employee's gender transition will be maintained to the extent possible and as requested by the employee."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Training on transgender inclusion and awareness will be provided to all employees, managers, and human resources staff to foster understanding and support for transgender colleagues.",
                    "Managers will receive additional training on how to support transgender employees during the transition process and beyond."
                ]
            },
            {
                heading: "Reporting and Non-Retaliation",
                paragraphs: [
                    "Employees who experience discrimination or harassment based on gender identity or expression are encouraged to report such incidents to HR or management.",
                    "Retaliation against employees who report violations of this policy is prohibited and will result in disciplinary action."
                ]
            },
            {
                heading: "Review and Revision",
                paragraphs: [
                    "This policy will be reviewed periodically to ensure it remains current with legal requirements and best practices in transgender inclusion.",
                    "Feedback from employees and updates in applicable laws or regulations will inform any revisions to this policy."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to review and acknowledge their understanding and commitment to comply with this Transgender Inclusion and Support Policy."
            }
        ]
    };

    export const UnlimitedVacationPolicyTemplate = {
        title: "Unlimited Vacation Policy Template",
        policyBlurb: "This Unlimited Vacation Policy outlines [Company Name]'s approach to providing flexible and unlimited vacation time for its employees. It aims to promote work-life balance, trust, and responsibility while ensuring that business needs are met.",
        preRead: "Use this template to establish guidelines and expectations for unlimited vacation time, fostering a culture of accountability, productivity, and employee well-being. This policy encourages employees to take time off as needed, recognizing their individual needs and contributions to the organization.",
        sections: [
            {
                heading: "Introduction",
                paragraph: "[Company Name] values its employees and recognizes the importance of work-life balance. The Unlimited Vacation Policy reflects our commitment to providing flexibility and autonomy to our employees, allowing them to manage their time effectively while contributing to our collective success."
            },
            {
                heading: "Purpose",
                paragraph: "The purpose of this policy is to offer employees the freedom to take as much time off as needed without being limited by traditional accrued vacation days. It aims to empower employees to make responsible decisions about their time off while ensuring that business operations continue smoothly."
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all full-time employees of [Company Name]. It covers all aspects of unlimited vacation time, including eligibility, requesting time off, approval process, and expectations for managing workload and team responsibilities.",
                    "Employees are encouraged to take time off for vacation, personal reasons, or illness as needed, with the understanding that performance expectations and business needs should be met."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "All full-time employees of [Company Name] are eligible for unlimited vacation time upon completion of their probationary period.",
                    "Part-time employees and contractors are not eligible for unlimited vacation time but may be subject to different leave policies as outlined in their respective agreements."
                ]
            },
            {
                heading: "Requesting Time Off",
                paragraphs: [
                    "Employees are responsible for managing their time off by communicating with their manager or team regarding their absence.",
                    "They should submit a time-off request through the company's designated system or process, ensuring that their absence does not disrupt team operations."
                ]
            },
            {
                heading: "Approval Process",
                paragraphs: [
                    "Managers are responsible for approving or denying time-off requests based on business needs, team workload, and coverage.",
                    "They should consider the impact of the absence on team productivity and ensure that adequate coverage is maintained during the employee's absence."
                ]
            },
            {
                heading: "Managing Workload and Responsibilities",
                paragraphs: [
                    "Employees are expected to plan their time off in advance and coordinate with their team to manage workload and deadlines.",
                    "They should ensure that critical tasks are completed or delegated before taking time off to minimize disruption to team operations."
                ]
            },
            {
                heading: "Communication",
                paragraphs: [
                    "Open communication between employees and their managers is essential for effective time-off management.",
                    "Managers should encourage employees to take time off when needed and support their work-life balance initiatives."
                ]
            },
            {
                heading: "Performance Expectations",
                paragraphs: [
                    "Employees are accountable for meeting performance expectations and delivering results, regardless of their vacation time.",
                    "Managers should evaluate performance based on results achieved and contributions to team and organizational goals."
                ]
            },
            {
                heading: "Review and Evaluation",
                paragraphs: [
                    "This policy will be periodically reviewed to ensure its effectiveness in promoting work-life balance and meeting business needs.",
                    "Feedback from employees and managers will be used to make improvements or adjustments to the policy as necessary."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "By continuing employment with [Company Name], employees acknowledge their understanding and acceptance of the Unlimited Vacation Policy and agree to comply with its guidelines and expectations."
            }
        ]
    };
    
    export const ViolencePreventionAndResponsePolicyTemplate = {
        title: "Violence Prevention and Response Policy Template",
        policyBlurb: "The Violence Prevention and Response Policy outlines [Company Name]'s commitment to providing a safe and secure workplace for all employees. It aims to prevent incidents of violence, harassment, and threatening behavior, and to respond promptly and effectively should such incidents occur.",
        preRead: "Use this template to establish guidelines and procedures for preventing workplace violence, promoting a respectful work environment, and ensuring the safety and well-being of employees. This policy sets expectations for appropriate conduct and outlines steps for reporting and addressing incidents of violence or threats in the workplace.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Definitions",
                    "Preventive Measures",
                    "Prohibited Conduct",
                    "Reporting Procedures",
                    "Investigation and Resolution",
                    "Support and Resources",
                    "Confidentiality",
                    "Non-Retaliation",
                    "Training and Awareness",
                    "Policy Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] is committed to maintaining a safe and respectful workplace environment. This policy aims to prevent incidents of violence, harassment, and threatening behavior among employees, contractors, clients, and visitors."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to establish guidelines and procedures for preventing workplace violence and responding effectively to incidents when they occur. It aims to promote a culture of safety, respect, and professionalism.",
                    "By implementing this policy, [Company Name] seeks to protect the well-being of its employees, minimize risks associated with workplace violence, and ensure compliance with legal requirements."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, contractors, clients, and visitors at [Company Name]. It covers all locations where work is performed, including company premises, off-site locations, and business-related events.",
                    "The policy addresses conduct both during working hours and outside of regular business hours if it affects the workplace environment or involves individuals connected to [Company Name]."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Violence: Any physical assault, threat of assault, or aggressive behavior that poses a risk to the safety or well-being of individuals in the workplace.",
                    "Harassment: Unwelcome conduct, including verbal, physical, or visual behavior that creates an intimidating, hostile, or offensive work environment.",
                    "Threatening Behavior: Actions, gestures, or verbal expressions that convey a threat to harm or intimidate others."
                ]
            },
            {
                heading: "Preventive Measures",
                paragraphs: [
                    "Promote a culture of respect, tolerance, and professionalism among all employees.",
                    "Conduct risk assessments to identify potential hazards and implement measures to mitigate risks of workplace violence.",
                    "Provide training and awareness programs on violence prevention, conflict resolution, and respectful workplace behavior."
                ]
            },
            {
                heading: "Prohibited Conduct",
                paragraphs: [
                    "Engaging in violent acts, threats, intimidation, or aggressive behavior towards others.",
                    "Harassing, bullying, or discriminating against individuals based on protected characteristics.",
                    "Possessing or using weapons or other dangerous objects in the workplace without authorization."
                ]
            },
            {
                heading: "Reporting Procedures",
                paragraphs: [
                    "Employees who witness or experience workplace violence, harassment, or threatening behavior should report incidents immediately to their supervisor, HR department, or designated contact person.",
                    "Reports should include details of the incident, names of individuals involved, and any supporting evidence or witnesses."
                ]
            },
            {
                heading: "Investigation and Resolution",
                paragraphs: [
                    "All reports of workplace violence will be promptly and thoroughly investigated by the HR department or designated investigators.",
                    "Investigations will be conducted confidentially, respecting the privacy of all parties involved, and appropriate actions will be taken based on the findings."
                ]
            },
            {
                heading: "Support and Resources",
                paragraphs: [
                    "Provide support services to employees who have experienced or witnessed workplace violence, including access to counseling, medical assistance, or legal advice.",
                    "Maintain a list of internal and external resources available for employees seeking assistance or guidance."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "Maintain confidentiality throughout the reporting, investigation, and resolution process, to the extent possible and permitted by law.",
                    "Protect the privacy of individuals involved in incidents of workplace violence or harassment."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraphs: [
                    "Prohibit retaliation against individuals who report workplace violence or participate in investigations.",
                    "Ensure that individuals are not subjected to adverse actions or treatment as a result of making a report in good faith."
                ]
            },
            {
                heading: "Training and Awareness",
                paragraphs: [
                    "Provide regular training sessions on violence prevention, conflict resolution, and respectful workplace behavior for all employees.",
                    "Ensure that employees are aware of their rights and responsibilities under this policy and understand the importance of maintaining a safe work environment."
                ]
            },
            {
                heading: "Policy Review",
                paragraphs: [
                    "Regularly review and update the Violence Prevention and Response Policy to ensure its effectiveness and compliance with legal requirements.",
                    "Gather feedback from employees, managers, and stakeholders to identify areas for improvement and implement necessary changes."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to review and acknowledge their understanding of the Violence Prevention and Response Policy. By signing this acknowledgment, employees agree to comply with the policy and contribute to maintaining a safe and respectful workplace environment."
            }
        ]
    };
    
    export const WorkFromHomePolicyTemplate = {
        title: "Work from Home Policy Template",
        policyBlurb: "The Work from Home Policy establishes guidelines and expectations for employees who perform their job duties remotely. It aims to promote productivity, maintain communication, and ensure compliance with company policies and procedures while working from home.",
        preRead: "Use this template to develop a policy that supports flexible work arrangements, enhances work-life balance, and defines responsibilities for both employees and managers. This policy ensures that remote work arrangements align with business needs and contribute to overall organizational goals.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Eligibility",
                    "Work Arrangements",
                    "Performance Expectations",
                    "Communication and Collaboration",
                    "Equipment and Workspace",
                    "Security and Confidentiality",
                    "Health and Safety",
                    "Expenses and Reimbursement",
                    "Monitoring and Compliance",
                    "Policy Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] recognizes the importance of offering flexible work options to accommodate diverse employee needs and support business continuity. The Work from Home Policy outlines the framework for remote work arrangements, ensuring that employees can effectively perform their job responsibilities outside of the traditional office setting."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to provide guidelines and procedures for employees who work remotely. It aims to promote flexibility, work-life balance, and productivity while maintaining communication and collaboration with colleagues and supervisors.",
                    "By establishing clear expectations and responsibilities, this policy ensures that remote work arrangements contribute to employee satisfaction, operational efficiency, and the achievement of company goals."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to employees who are eligible to work remotely based on their job functions, performance, and managerial approval. It covers temporary remote work arrangements and regular work-from-home agreements, as determined by business needs and operational requirements.",
                    "Employees are expected to adhere to company policies, procedures, and performance standards while working remotely."
                ]
            },
            {
                heading: "Eligibility",
                paragraphs: [
                    "Employees eligible for remote work arrangements must meet specific criteria established by their department or supervisor. Eligibility considerations may include job role suitability, performance history, and compliance with previous remote work policies.",
                    "Managers have the discretion to approve or deny remote work requests based on operational needs, team collaboration requirements, and business continuity considerations."
                ]
            },
            {
                heading: "Work Arrangements",
                paragraphs: [
                    "Remote work arrangements may be approved on a full-time, part-time, or ad-hoc basis, depending on job responsibilities, team dynamics, and business requirements.",
                    "Employees and managers should discuss and document the agreed-upon work schedule, availability during core business hours, and expectations for availability and responsiveness."
                ]
            },
            {
                heading: "Performance Expectations",
                paragraphs: [
                    "Employees working remotely are accountable for maintaining productivity levels and meeting performance objectives as outlined in their job descriptions.",
                    "Managers will establish clear performance expectations, monitor progress, and provide ongoing feedback to ensure work quality and effectiveness."
                ]
            },
            {
                heading: "Communication and Collaboration",
                paragraphs: [
                    "Remote employees are expected to maintain regular communication with their supervisor, team members, and other relevant stakeholders.",
                    "Utilize company-approved communication tools and platforms for team meetings, project updates, and collaboration to ensure transparency and alignment."
                ]
            },
            {
                heading: "Equipment and Workspace",
                paragraphs: [
                    "Employees are responsible for setting up a safe, ergonomic, and productive workspace in their remote location.",
                    "The company may provide necessary equipment, such as laptops, monitors, or peripherals, and establish guidelines for their proper use, maintenance, and return."
                ]
            },
            {
                heading: "Security and Confidentiality",
                paragraphs: [
                    "Maintain the security and confidentiality of company information, data, and resources while working remotely.",
                    "Follow established protocols for information security, data protection, and access to proprietary systems and applications."
                ]
            },
            {
                heading: "Health and Safety",
                paragraphs: [
                    "Employees are responsible for maintaining their health and safety while working remotely.",
                    "Follow ergonomic best practices, take regular breaks, and report any health or safety concerns to their supervisor or HR department."
                ]
            },
            {
                heading: "Expenses and Reimbursement",
                paragraphs: [
                    "Reimbursement for authorized business expenses incurred while working remotely will be handled according to company expense reimbursement policies.",
                    "Submit expense reports with appropriate documentation for approval and processing in a timely manner."
                ]
            },
            {
                heading: "Monitoring and Compliance",
                paragraphs: [
                    "Managers may monitor remote work performance and compliance with company policies and procedures to ensure accountability and productivity.",
                    "Employees should adhere to timekeeping procedures, maintain accurate records of work hours, and comply with company guidelines."
                ]
            },
            {
                heading: "Policy Review",
                paragraphs: [
                    "This policy will be reviewed periodically to evaluate its effectiveness, address emerging challenges, and incorporate feedback from employees and stakeholders.",
                    "Updates or revisions to the Work from Home Policy will be communicated to employees in a timely manner."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that I have read and understood the Work from Home Policy. I agree to comply with the guidelines and expectations outlined herein, and I understand my responsibilities for maintaining productivity, communication, and security while working remotely."
            }
        ]
    };

    export const WorkplaceHarassmentPreventionPolicyTemplate = {
        title: "Workplace Harassment Prevention Policy Template",
        policyBlurb: "The Workplace Harassment Prevention Policy aims to create a respectful and safe work environment for all employees by prohibiting harassment of any kind. It outlines the procedures for reporting, investigating, and addressing allegations of harassment, ensuring prompt and fair resolution.",
        preRead: "Use this template to develop a policy that promotes a culture of respect, inclusion, and zero tolerance for harassment. This policy establishes clear expectations for behavior, defines harassment in various forms, and outlines the consequences for violations.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Purpose",
                    "Scope",
                    "Policy Statement",
                    "Definitions",
                    "Prohibited Conduct",
                    "Reporting Procedures",
                    "Investigation Process",
                    "Confidentiality",
                    "Non-Retaliation",
                    "Corrective Actions",
                    "Training and Education",
                    "Policy Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] is committed to providing a work environment that is free from harassment, discrimination, and disrespectful behavior. The Workplace Harassment Prevention Policy sets forth our commitment to maintaining a workplace where all employees are treated with dignity and respect."
            },
            {
                heading: "Purpose",
                paragraphs: [
                    "The purpose of this policy is to prevent harassment in the workplace and ensure a professional and productive work environment. It aims to protect employees from harassment based on protected characteristics, such as race, color, religion, sex, national origin, age, disability, sexual orientation, gender identity, or any other status protected by applicable law.",
                    "By establishing clear guidelines and procedures for addressing harassment allegations, this policy aims to promote transparency, accountability, and a supportive workplace culture."
                ]
            },
            {
                heading: "Scope",
                paragraphs: [
                    "This policy applies to all employees, including full-time, part-time, temporary employees, contractors, and vendors, while they are engaged in company business or on company premises.",
                    "It covers all forms of harassment, including but not limited to verbal, physical, visual, and electronic harassment, whether perpetrated by supervisors, managers, coworkers, clients, or third parties."
                ]
            },
            {
                heading: "Policy Statement",
                paragraphs: [
                    "[Company Name] prohibits harassment of any kind, including harassment based on race, color, religion, sex, national origin, age, disability, sexual orientation, gender identity, or any other protected characteristic under applicable law.",
                    "Harassment includes unwelcome conduct that is severe or pervasive and creates a hostile, intimidating, or offensive work environment, or interferes with an individual's work performance."
                ]
            },
            {
                heading: "Definitions",
                paragraphs: [
                    "Harassment: Unwelcome conduct based on protected characteristics that interferes with an individual's work performance or creates an intimidating, hostile, or offensive work environment.",
                    "Protected Characteristics: Categories protected by federal, state, and local employment laws, including but not limited to race, color, religion, sex, national origin, age, disability, sexual orientation, and gender identity."
                ]
            },
            {
                heading: "Prohibited Conduct",
                paragraphs: [
                    "Examples of prohibited conduct include, but are not limited to:",
                    "- Verbal harassment, such as derogatory remarks, slurs, or epithets.",
                    "- Physical harassment, including unwanted touching, assault, or blocking movement.",
                    "- Visual harassment, such as offensive gestures, displays, or images.",
                    "- Electronic harassment, including offensive emails, messages, or social media posts.",
                    "Any conduct that creates a hostile, intimidating, or offensive work environment based on a protected characteristic is strictly prohibited."
                ]
            },
            {
                heading: "Reporting Procedures",
                paragraphs: [
                    "Employees who experience or witness harassment are encouraged to report the incident promptly to their supervisor, HR department, or any designated company contact.",
                    "Reports can be made verbally or in writing and should include details of the incident(s), names of witnesses, and any supporting documentation."
                ]
            },
            {
                heading: "Investigation Process",
                paragraphs: [
                    "All reports of harassment will be promptly and thoroughly investigated by the HR department or an appointed investigator.",
                    "The investigation will be conducted impartially, respecting confidentiality to the extent possible, and following a fair and objective process."
                ]
            },
            {
                heading: "Confidentiality",
                paragraphs: [
                    "Confidentiality will be maintained throughout the investigation to the extent possible and appropriate under the circumstances.",
                    "Information related to harassment complaints will only be shared on a need-to-know basis for the purpose of investigation and resolution."
                ]
            },
            {
                heading: "Non-Retaliation",
                paragraphs: [
                    "Retaliation against individuals who report harassment or participate in an investigation is strictly prohibited.",
                    "Employees who engage in retaliatory behavior will be subject to disciplinary action, up to and including termination."
                ]
            },
            {
                heading: "Corrective Actions",
                paragraphs: [
                    "If harassment is substantiated following an investigation, appropriate corrective action will be taken based on the severity and circumstances of the conduct.",
                    "Corrective actions may include counseling, training, transfer, suspension, demotion, or termination, depending on the nature and severity of the offense."
                ]
            },
            {
                heading: "Training and Education",
                paragraphs: [
                    "Regular training on harassment prevention and workplace conduct will be provided to all employees.",
                    "Training sessions will cover the policy, definitions of harassment, reporting procedures, and the importance of maintaining a respectful work environment."
                ]
            },
            {
                heading: "Policy Review",
                paragraphs: [
                    "This policy will be reviewed periodically to assess its effectiveness, address any emerging issues, and ensure compliance with legal requirements.",
                    "Updates or revisions to the Workplace Harassment Prevention Policy will be communicated to employees and stakeholders as needed."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "I acknowledge that I have read and understood the Workplace Harassment Prevention Policy. I agree to comply with the policy's guidelines and expectations, and I understand my role in promoting a respectful and harassment-free work environment."
            }
        ]
    };

    export const WorkplaceSafetyAndHealthPolicyTemplate = {
        title: "Workplace Safety and Health Policy Template",
        policyBlurb: "This policy outlines [Company Name]'s commitment to ensuring a safe and healthy work environment for all employees. It defines the responsibilities of both the company and its employees in maintaining workplace safety and health standards.",
        preRead: "Use this template to establish clear guidelines and procedures that promote safety, prevent accidents and injuries, and comply with legal requirements related to workplace safety and health.",
        sections: [
            {
                heading: "Table of Contents",
                paragraphs: [
                    "Introduction",
                    "Policy Statement",
                    "Responsibilities",
                    "Safety Training and Education",
                    "Workplace Hazard Assessment",
                    "Incident Reporting and Investigation",
                    "Emergency Preparedness",
                    "Safety Inspections",
                    "Health and Wellness Programs",
                    "Workplace Ergonomics",
                    "Personal Protective Equipment (PPE)",
                    "Safety Communication",
                    "Compliance and Review",
                    "Acknowledgment"
                ]
            },
            {
                heading: "Introduction",
                paragraph: "[Company Name] is dedicated to providing a safe and healthy workplace environment for all employees, contractors, visitors, and anyone else affected by our operations. This policy serves to outline our commitment to maintaining high standards of safety and health across all facets of our business operations."
            },
            {
                heading: "Policy Statement",
                paragraph: "We prioritize the safety and health of our workforce as a fundamental aspect of our business operations. [Company Name] is committed to complying with all applicable health and safety laws, regulations, and industry standards. We strive to continuously improve our safety and health practices to prevent workplace accidents, injuries, and illnesses."
            },
            {
                heading: "Responsibilities",
                paragraphs: [
                    "Management, supervisors, and employees at all levels are responsible for ensuring compliance with safety and health policies and procedures.",
                    "Management will provide the necessary resources, training, and support to maintain a safe and healthy work environment.",
                    "Employees are responsible for following safety guidelines, reporting hazards, participating in training programs, and contributing to a culture of safety."
                ]
            },
            {
                heading: "Safety Training and Education",
                paragraphs: [
                    "We provide comprehensive safety training and education programs to all employees to equip them with the knowledge and skills necessary to perform their jobs safely.",
                    "Training includes but is not limited to hazard recognition, emergency procedures, safe work practices, and the proper use of personal protective equipment (PPE)."
                ]
            },
            {
                heading: "Workplace Hazard Assessment",
                paragraphs: [
                    "Regular assessments of workplace hazards are conducted to identify and mitigate risks to health and safety.",
                    "Hazards identified are promptly addressed through engineering controls, administrative controls, or the provision of appropriate PPE."
                ]
            },
            {
                heading: "Incident Reporting and Investigation",
                paragraphs: [
                    "All workplace incidents, injuries, near misses, and hazardous conditions are promptly reported to management.",
                    "Incidents are thoroughly investigated to determine root causes and implement corrective actions to prevent recurrence."
                ]
            },
            {
                heading: "Emergency Preparedness",
                paragraphs: [
                    "Emergency response procedures are established and communicated to all employees.",
                    "Emergency drills and simulations are conducted periodically to ensure preparedness and evaluate the effectiveness of emergency plans."
                ]
            },
            {
                heading: "Safety Inspections",
                paragraphs: [
                    "Regular safety inspections are conducted to assess workplace conditions and identify potential hazards.",
                    "Inspections may include physical inspections, audits, and assessments of safety practices and procedures."
                ]
            },
            {
                heading: "Health and Wellness Programs",
                paragraphs: [
                    "We promote employee health and wellness through programs aimed at preventing illness, promoting healthy lifestyles, and supporting mental well-being.",
                    "Programs may include access to health screenings, wellness workshops, fitness incentives, and employee assistance programs (EAPs)."
                ]
            },
            {
                heading: "Workplace Ergonomics",
                paragraphs: [
                    "We strive to provide ergonomic workspaces that minimize the risk of musculoskeletal disorders and promote comfortable and safe working conditions.",
                    "Employees are encouraged to report ergonomic concerns to management for evaluation and remediation."
                ]
            },
            {
                heading: "Personal Protective Equipment (PPE)",
                paragraphs: [
                    "Appropriate PPE is provided to employees at no cost where required to mitigate workplace hazards.",
                    "Employees are trained on the proper use, maintenance, and limitations of PPE and are expected to use it as directed."
                ]
            },
            {
                heading: "Safety Communication",
                paragraphs: [
                    "Effective communication channels are established to disseminate safety information, updates, and alerts to all employees.",
                    "Employees are encouraged to participate in safety discussions, provide feedback, and report safety concerns or suggestions for improvement."
                ]
            },
            {
                heading: "Compliance and Review",
                paragraphs: [
                    "This policy is reviewed periodically to ensure its effectiveness and relevance to current safety and health practices.",
                    "Updates are made as necessary to comply with changing laws, regulations, and industry standards."
                ]
            },
            {
                heading: "Acknowledgment",
                paragraph: "All employees are required to review this Workplace Safety and Health Policy and acknowledge their understanding and commitment to complying with its provisions."
            }
        ]
    };
    
    