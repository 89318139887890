import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { Box, Divider, Stack, ToggleButton, Typography } from "@mui/material";
import { TCandidateFlow } from "Modules/Core/Candidate/CandidateModel";
import { useNotification } from "Modules/Core/Notification";
import { IsMdScreen, IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import lodash from 'lodash';
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConfirmationDialog } from "shared/SharedComponents/ConfirmationDialog/ConfirmationDialog";
import { ShLoadingDots } from 'shared/SharedComponents/ShLoadingDots/ShLoadingDots';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShToggleButtonGroup } from "shared/SharedStyles/ShInputs";
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { resetAuth } from "store/slices/auth-v2/auth-v2-slice";
import { completeJobApplication, resetCompleteJobApplication, updateSurveyAnswer } from "store/slices/candidate/survey/survey-slice";
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';
import { QuestionBox } from "./Survey.styled";
import { ISurveyQuestion } from './SurveyModel';

export const Survey = ({ navigatedFrom }: { navigatedFrom: TCandidateFlow }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const currentPath = useLocation().pathname;
    const notification = useNotification();
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const isMdScreen = IsMdScreen();
    const { surveyQuestions, getSurveyQuestionsStatus, isSurveyCompleted,
        completeJobApplicationStatus, completeJobApplicationResponse, isSurveyQuestionBeingAnswered
    } = useAppSelector((state) => state.candidate.survey);
    const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { candidateAssessmentMetadata } = useAppSelector((state) => state.metadata.assessmentMeta);
    const [isSurveyConfirmationOpen, setIsSurveyConfirmationOpen] = useState<boolean>(false);
    const [isSurveyNotCompleteOpen, setIsSurveyNotCompleteOpen] = useState<boolean>(false);
    const [areNotAllQuestionsAnswered, setAreNotAllQuestionsAnswered] = useState<boolean>(false);
    const [isShowAutoFill, setIsShowAutoFill] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<ISurveyQuestion>();

    // All question UI element references which will be added to the intersection observer.
    const [questionCards, setQuestionCards] = useState<NodeListOf<Element>>();

    /*
    * Function to scroll the highlighted question into highlight view port
    * NOTE: Altering any parameters in this function could result in unexpected behavior while scrolling through the questions.
    */
    const scrollIntoView = useCallback(
        lodash.debounce((element) => {
            const container = document.getElementById('questionsContainer');
            const containerRect = container?.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();
            if (container && containerRect && elementRect) {
                // Calculate the scroll position to bring the element into the center of the container
                const scrollPosition = element.offsetTop - container.offsetTop - (container.offsetHeight - element.offsetHeight) / 2;

                // Scroll the container to the calculated position
                container.scrollTo({
                    top: scrollPosition,
                    behavior: 'smooth'
                });
            }
        }, 300), // Debounce time in milliseconds
        []
    );

    // Intersection observer to observe if the question is scrolled into the view port to highlight.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const intersectionObserver = new IntersectionObserver(entries => {
        // get allIntersections
        const allIntersections = entries.filter(e => e.isIntersecting)
            ?.sort((a, b) => a.intersectionRatio - b.intersectionRatio);
        const mostlyInView = allIntersections[allIntersections?.length - 1];
        if (mostlyInView && questionCards) {
            questionCards.forEach(qC => qC.classList.toggle('in-view', false));
            mostlyInView.target.classList.toggle('in-view', true);
            scrollIntoView(mostlyInView.target);
        }
    },
        {
            threshold: isSmScreen ? 0.4 : 0.5,
            root: document.getElementById('questionsContainer'),
            rootMargin: isXsScreen ? '-160px 0px -150px 0px' : isMdScreen ? '-135px 0px -175px 0px' : '-175px 0px -175px 0px'
        }
    );

    // Scroll the selected question into view on user selection. 
    const scrollQuestionIntoView = useCallback((questionId: number) => {
        if (questionId) {
            const questionElement = document.getElementById(questionId?.toString());
            setTimeout(() => {
                scrollIntoView(questionElement);

            }, 150);
        }
    }, [scrollIntoView]);

    const handleScrollIntoView = useCallback((scrollId: number) => {
        if (surveyQuestions) {
            const timerId = setTimeout(() => {
                if (areNotAllQuestionsAnswered) {
                    const unAnsweredQuestion = surveyQuestions.find(q => typeof q.answer !== 'number');
                    if (unAnsweredQuestion) {
                        setAreNotAllQuestionsAnswered(true);
                        scrollQuestionIntoView(unAnsweredQuestion.id);
                    }
                } else {
                    scrollQuestionIntoView(surveyQuestions[scrollId]?.id);

                }
            }, 100);
            return () => {
                // Clean up the timeout to avoid memory leaks
                clearTimeout(timerId);
            };
        }
    }, [areNotAllQuestionsAnswered, scrollQuestionIntoView, surveyQuestions]);

    /**
     * Scroll to the topmost un answered question.
    */
    const scrollToUnAnsweredQuestion = useCallback(() => {
        if (surveyQuestions) {
            const unAnsweredQuestion = surveyQuestions.find(q => typeof q.answer !== 'number');
            if (unAnsweredQuestion) {
                setAreNotAllQuestionsAnswered(true);
                handleScrollIntoView(unAnsweredQuestion.scrollId);
            } else {
                setAreNotAllQuestionsAnswered(false);
            }
        }
    }, [handleScrollIntoView, surveyQuestions]);

    const getQuestionCards = useCallback(() => {
        const qcCards = document.querySelectorAll('.question-card');
        setQuestionCards(qcCards);
    }, []);

    questionCards?.forEach(qC => {
        intersectionObserver.observe(qC);
    });

    // scroll to 1st question on component init
    useEffect(() => {
        if (surveyQuestions && surveyQuestions?.length > 0) {
            getQuestionCards();
        }
    }, [getQuestionCards, surveyQuestions]);

    /**
     * Update answer function is called synchronously when called inside a loop in auto-fill scenario in development environments */
    const updateAnswer = async (question: ISurveyQuestion, answerId: number) => {
        setCurrentQuestion(question);
        await dispatch(updateSurveyAnswer({
            payload: { question_id: question.id, answer_value: answerId }
        }));
    }

    // To be removed before moving to prod
    const autoFill = async () => {
        if (surveyQuestions && candidateAssessmentMetadata) {
            for (let sqIndx = 0; sqIndx < surveyQuestions.length; sqIndx++) {
                const sq = surveyQuestions[sqIndx];
                if (sqIndx % 2 === 0) {
                    await updateAnswer(sq, candidateAssessmentMetadata.survey?.personality[Math.floor(Math.random() * 4)].id);
                } else {
                    await updateAnswer(sq, candidateAssessmentMetadata.survey?.interest[Math.floor(Math.random() * 4)].id);
                }
            }
        }
    };

    useEffect(() => {
        if (completeJobApplicationStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: completeJobApplicationResponse ?? ''
            });
            navigate(CandidateLoginRedirect);
        } else if (completeJobApplicationStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: completeJobApplicationResponse ?? ''
            });
        }
    }, [completeJobApplicationResponse, completeJobApplicationStatus, dispatch, navigate, notification]);

    const closeConfirmationDialog = () => {
        setIsSurveyConfirmationOpen(false);
        dispatch(resetCompleteJobApplication());
    }

    const renderResponseIcons = (id: number, coloredIcons: boolean, primaryColor?: boolean) => {
        switch (id) {
            case -3:
                return (<>
                    <ThumbDownAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'warning' : 'action'} />
                    <ThumbDownAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'warning' : 'action'} />
                </>)
            case -1:
                return (<>
                    <ThumbDownAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'warning' : 'action'} />
                </>)
            case 1:
                return (<>
                    <ThumbUpAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'success' : 'action'} />
                </>)
            case 3:
                return (<>
                    <ThumbUpAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'success' : 'action'} />
                    <ThumbUpAltIcon fontSize='small' color={primaryColor ? 'primary' : coloredIcons ? 'success' : 'action'} />
                </>)
        }
    }

    // Scroll to next question after answer is saved
    useEffect(() => {
        const question = surveyQuestions?.find(sQ => sQ.id === currentQuestion?.id);
        if (surveyQuestions && question && question.updateSurveyAnswerStatus === 'success') {
            if (question.scrollId < surveyQuestions.length && !areNotAllQuestionsAnswered) {
                handleScrollIntoView(question.scrollId);
            } else {
                scrollToUnAnsweredQuestion();
            }
        }
    }, [areNotAllQuestionsAnswered, currentQuestion?.id, handleScrollIntoView, scrollToUnAnsweredQuestion, surveyQuestions]);

    const handleBack = () => {
        if (navigatedFrom === 'apply_job') {
            navigate(`${currentPath.replace('assessment', 'questionnaire')}`)
        } else {
            dispatch(resetAuth());
            navigate('/candidate/login');
        }
    }

    // Show survey completed acknowledgement automatically if every question is answered.
    useEffect(() => {
        if (isSurveyCompleted) {
            if (navigatedFrom === 'apply_job') {
                setIsSurveyConfirmationOpen(true)
            } else {
                navigate(CandidateLoginRedirect);
            }
        }
    }, [isSurveyCompleted, navigate, navigatedFrom]);

    const handleNext = () => {
        // Stop execution if any answer is being updated.
        if (surveyQuestions && surveyQuestions.filter(q => q.updateSurveyAnswerStatus === 'pending').length === 0) {
            if (isSurveyCompleted) {
                if (navigatedFrom === 'apply_job') {
                    setIsSurveyConfirmationOpen(true)
                } else {
                    navigate(CandidateLoginRedirect);
                }
            } else {
                setIsSurveyNotCompleteOpen(true);
            }
        }
    }

    useEffect(() => {
        // Function to get cookie value by name
        const getCookie = (name: string): string | undefined => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop()?.split(';').shift();
            return undefined;
        };

        // Get the cookie value (assuming the cookie name is 'isAutoFillEnabled')
        const cookieValue = getCookie('isAutoFillEnabled');

        // Set isAutoFillEnabled state based on cookie value (e.g., show if cookie is 'true')
        if (cookieValue === 'true') {
            setIsShowAutoFill(true);
        } else {
            setIsShowAutoFill(false);
        }
    }, []);


    return (<>
        <Stack rowGap={2}>
            {candidateDetails?.surveyComplete ? <>
                <Typography variant='body2'>Wow! Looks like you've already filled out our survey in the past. Hit the Submit Application below to finish.</Typography>
            </> :
                <>
                    {/* Editor fold for labels */}
                    <>
                        <Typography variant="body2">
                            There are no wrong answers and your specific answers aren't shown to anyone.
                            <br />
                            You'll receive an in-depth report of your unique traits at the end. It only takes 10 minutes to complete!
                        </Typography>
                        <Typography variant="caption">
                            Tip: The first answer that comes to mind is usually the most accurate one.
                        </Typography>
                        <Divider />
                        <Typography variant="body2">
                            Please rate your experience level for each of the following questions.
                            Your answers will be included in your application.
                        </Typography>
                        <Typography variant="caption">
                            <strong>{surveyQuestions?.filter(q => q.answer !== null).length}</strong>&nbsp;
                            questions answered out of <strong>{surveyQuestions?.length}</strong>.
                        </Typography>
                        {
                            isShowAutoFill && <ShButton variant="outlined" onClick={autoFill}>Auto fill (staging-only!)</ShButton>
                        }
                        {/* {areNotAllQuestionsAnswered &&
                            <ShAlert variant='outlined' severity='info'>
                                Please answer all the questions. It will take about 5 to 7 minutes to complete.
                            </ShAlert>} */}
                    </>
                    {getSurveyQuestionsStatus === 'pending' ?
                        <ShAlert severity='info'>Loading Survey questions...</ShAlert>
                        : <>
                            {/* Wrapper box with relative positioning acts as a reference container for "QuestionHighlightOverlay" */}
                            <Box position='relative'>
                                {/* <QuestionHighlightOverlay elevation={4}></QuestionHighlightOverlay> */}
                                {/* 
                                     * NOTE: Changing props of the below Stack will hinder the question highlight functionality.
                                */}
                                <Stack overflow='auto' rowGap={1} maxHeight={450} minHeight={200} paddingRight={1}
                                    paddingY={'175px'} id="questionsContainer">
                                    {
                                        surveyQuestions?.map((question) => (
                                            <QuestionBox padding={{ xs: 2, sm: 2, md: 2, lg: 3 }} className='question-card'
                                                maxWidth='100%' paddingRight={0} id={question.id?.toString()} key={question.id}>
                                                <Stack rowGap={{ xs: 0, sm: 0, md: 3, lg: 3 }}>
                                                    <Typography variant="body1" marginBottom={1}
                                                        color={question.answer ? PrimaryThemeColor : 'inherit'}
                                                        onClick={() => scrollQuestionIntoView(question.id)} gutterBottom>
                                                        {question.text}
                                                        {renderResponseIcons(question.answer ?? 0, true)}
                                                    </Typography>
                                                    <ShToggleButtonGroup className="answer-toggle"
                                                        color="primary" size="small" exclusive fullWidth={isSmScreen}
                                                        orientation={isSmScreen ? 'vertical' : 'horizontal'}
                                                        disabled={question?.updateSurveyAnswerStatus === 'pending' ||
                                                            isSurveyQuestionBeingAnswered}
                                                        value={question.answer}>
                                                        {candidateAssessmentMetadata?.survey[question.kind].map((option) => (
                                                            <ToggleButton value={option.id} key={option.id}
                                                                onClick={() => option.id !== question.answer && updateAnswer(question, option.id)}>
                                                                {renderResponseIcons(option.id, option.id === question.answer, option.id !== question.answer)}
                                                                {/* Loading dots */}
                                                                {question?.updateSurveyAnswerStatus === 'pending' &&
                                                                    question.updateSurveyAnswerId === option.id &&
                                                                    <ShLoadingDots variant={option.id > 0 ? 'positive' : 'negative'} />
                                                                }
                                                                <Typography variant='body2' className='button-label'>
                                                                    {option.name}
                                                                </Typography>
                                                            </ToggleButton>
                                                        ))}
                                                    </ShToggleButtonGroup>
                                                </Stack>
                                            </QuestionBox>
                                        ))
                                    }
                                </Stack>
                            </Box>
                        </>}
                </>}
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
            <ShButton variant="outlined" onClick={handleBack}>Back</ShButton>
            <ShGreenBtn variant="outlined" size="small" onClick={handleNext}>Submit Application</ShGreenBtn>
        </Stack>
        {/* complete survey dialog */}
        <ConfirmationDialog isDialogOpen={isSurveyNotCompleteOpen}
            onConfirm={() => {
                scrollToUnAnsweredQuestion();
                setIsSurveyNotCompleteOpen(false);
            }} isOnlyOk
            title="Survey Incomplete!" confirmButtonLabel="Complete Survey"
            contentText="Please answer all the questions. It will take about 5 to 7 minutes to complete." />
        {/* complete application dialog */}
        <ConfirmationDialog isDialogOpen={isSurveyConfirmationOpen} onCancel={() => closeConfirmationDialog()}
            onConfirm={() => candidateDetails?.jobApplicationId &&
                dispatch(completeJobApplication(candidateDetails?.jobApplicationId))}
            cancelButtonLabel="Review Answers" title="Well done!"
            contentText="Complete your job application by hitting submit or review your answers below."
            confirmButtonLabel="Submit Application"
            isCancelDisabled={completeJobApplicationStatus === "pending"}
            isConfirmDisabled={completeJobApplicationStatus === "pending"} />
    </>)
}
