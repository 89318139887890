import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { CommonApiEndpoints } from 'shared/ApiEndpoints';
import { ApiState, IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg } from 'shared/constants';

interface timezones {
  name: string;
  abbr: string;
  tz: string;
}
interface TimezonesState {
  timezones: timezones[];
  status: ApiState;
  response: string;
}

export interface TimezonesResponse {
  data: timezones[];
  message: string;
}

const initialState: TimezonesState = {
  timezones: [],
  response: '',
  status: 'idle',
};

export const getTimezones = createAsyncThunk<
  timezones[],
  void,
  { rejectValue: IBaseResponse }
>('getTimezones', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<timezones[]> =
      await httpAdapterInstance.get(CommonApiEndpoints.TIMEZONES);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

const timezonesSlice = createSlice({
  name: 'timezones',
  initialState,
  reducers: {
    clearTimezones: (state) => {
      state.response = '';
      state.status = 'idle';
      state.timezones = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimezones.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getTimezones.fulfilled, (state, action) => {
      state.status = 'success';
      state.timezones = action.payload;
    });
    builder.addCase(getTimezones.rejected, (state, action) => {
      state.status = 'failed';
      state.response = action.payload?.message ?? DefaultAPIErrorMsg;
    });
  },
});

export const { clearTimezones } = timezonesSlice.actions;
export default timezonesSlice;
