import { CssBaseline, GlobalStyles, PaletteMode, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { ShRouter } from "Router";
import { useAppSelector } from "helpers/hooks";
import { BorderColorDark, BorderColorLight, PaperBorderRadius, PrimaryThemeColor, ShGreen, WhiteColor } from "shared/SharedStyles/styleConstants";

const App = () => {

  // This comment is just for triggering deployment. Count 1

  // App theme is maintained in theme-slice.
  const { curTheme } = useAppSelector((state) => state.app.theme);
  // Theme creation
  const theme = responsiveFontSizes(createTheme({
    palette: {
      mode: curTheme as PaletteMode,
      primary: { main: curTheme === 'light' ? PrimaryThemeColor : WhiteColor },
      success: { main: ShGreen }
    },
    typography: {
      fontFamily: "Poppins, Roboto, sans-serif",
    },
    components: {
      MuiAppBar: { defaultProps: { color: 'inherit' } },
      MuiPaper: {
        styleOverrides: {
          outlined: {
            borderColor: curTheme === 'light' ? BorderColorLight : BorderColorDark,
            borderRadius: PaperBorderRadius
          },
          elevation: {
            borderRadius: PaperBorderRadius
          },
        }
      }
    }
  }));

  return (
    <>
      <ThemeProvider theme={theme}>

        {/* Necessary due to bold looking different in safari & other browsers */}
        <GlobalStyles
          styles={{
            '*': {
              fontSynthesis: 'none',
            },

          }}
        />
        <CssBaseline />
        <ShRouter />
      </ThemeProvider>
    </>
  );
};

export default App;
