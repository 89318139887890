import { Autocomplete, Box, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "helpers/hooks"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ApiState } from "shared/SharedModels"
import { ShAlert } from "shared/SharedStyles/ShFeedback"
import { AutocompleteLoaders, AutocompletePopoverProps, ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs"
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants"
import { LovNoMatchesText } from "shared/constants"
import {
    getCandidateNotificationSettings, resetGetCandidateNotificationSettings, resetUpdateCandidateNotificationSettings,
    updateCandidateNotificationSettings
} from "store/slices/candidate/candidate-notifications-slice"
import { getIndustries, getJobTitles, getJobsStates } from "store/slices/meta-data/jobs-meta-slice"
import { CandidateLoginRedirect } from "../AuthV2/AuthConstants"
import { ICandidateJobNotificationsPayload } from "./CandidateModel"

export const CandidateJobNotifications = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { industries, getIndustriesStatus, getJobTitlesStatus, jobTitles,
        jobStates, getJobStatesStatus } = useAppSelector(state => state.metadata.jobsMeta);
    const { candidateNotifications, getCandidateNotificationStatus, getCandidateNotificationResponse,
        updateCandidateNotificationStatus, updateCandidateNotificationResponse
    } = useAppSelector(state => state.candidate.candidateNotifications);

    const [jobNotificationsPayload, setJobNotificationsPayload] = useState<ICandidateJobNotificationsPayload>({
        industry_id: 0,
        job_title_id: 0,
        job_city: '',
        state_id: 0
    });
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);

    // Call get on load
    useEffect(() => {
        dispatch(getCandidateNotificationSettings());
        // reset api call on unmount
        return () => {
            dispatch(resetGetCandidateNotificationSettings());
            dispatch(resetUpdateCandidateNotificationSettings());
        }
    }, [dispatch]);

    // Update payload if "candidateNotifications" are available.
    useEffect(() => {
        if (getCandidateNotificationStatus === 'success' && candidateNotifications) {
            setJobNotificationsPayload({ ...candidateNotifications });
        }
    }, [candidateNotifications, getCandidateNotificationStatus]);

    // Load industries on load.
    useEffect(() => {
        if ((!industries || industries?.length === 0) && getIndustriesStatus !== 'pending') {
            dispatch(getIndustries());
        }
    }, [dispatch, getIndustriesStatus, industries]);

    // Load jobTitles on load.
    useEffect(() => {
        if ((!jobTitles || jobTitles?.length === 0) && getJobTitlesStatus !== 'pending') {
            dispatch(getJobTitles());
        }
    }, [dispatch, getJobTitlesStatus, jobTitles]);

    // Load states on load.
    useEffect(() => {
        if ((!jobStates || jobStates?.length === 0) && getJobStatesStatus !== 'pending') {
            dispatch(getJobsStates());
        }
    }, [dispatch, getJobStatesStatus, jobStates]);

    //Set save button disability.
    useEffect(() => {
        const { industry_id, job_title_id, job_city, state_id } = jobNotificationsPayload;
        setIsSaveDisabled(!industry_id || !job_title_id || !job_city || !state_id ||
            getCandidateNotificationStatus === 'pending' || updateCandidateNotificationStatus === 'pending');
    }, [getCandidateNotificationStatus, jobNotificationsPayload, updateCandidateNotificationStatus]);

    const showAlerts = (apiState: ApiState, pendingText: string, responseText: string,
        callBack?: ActionCreatorWithoutPayload<"candidateNotifications/resetGetCandidateNotificationSettings"> |
            ActionCreatorWithoutPayload<"candidateNotifications/resetUpdateCandidateNotificationSettings">) => {
        switch (apiState) {
            case 'pending':
                return (<ShAlert marginBottom={2} severity="info">{pendingText}</ShAlert>);
            case 'success':
                return (<ShAlert marginBottom={2} severity="success"
                    onClose={() => callBack ? dispatch(callBack()) : ''}>{responseText}</ShAlert>);
            case 'failed':
                return (<ShAlert marginBottom={2} severity="error">{responseText}</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <Container maxWidth='md'>
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Job Notification Settings</Typography>
                <Paper variant="outlined">
                    <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                        <Typography variant='body1' marginBottom={2}>
                            Select jobs you would be interested in
                        </Typography>
                        {getCandidateNotificationStatus !== 'success' &&
                            showAlerts(getCandidateNotificationStatus, 'Loading notification settings...',
                                getCandidateNotificationResponse, resetGetCandidateNotificationSettings)}
                        {showAlerts(updateCandidateNotificationStatus, 'Updating notification settings...',
                            updateCandidateNotificationResponse, resetUpdateCandidateNotificationSettings)}
                        <Grid container spacing={{ xs: 2, sm: 2, md: 3, lg: 4 }} marginBottom={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Autocomplete disabled={getCandidateNotificationStatus === 'pending'}
                                    noOptionsText={LovNoMatchesText} size='small' disablePortal id='industry'
                                    ListboxProps={AutocompletePopoverProps} options={industries}
                                    getOptionLabel={(option) => option.industry_translation?.name || ''}
                                    value={industries?.find(industry => industry.id === jobNotificationsPayload.industry_id) ?? null}
                                    renderInput={(params) => <TextField required {...params} label='Industry'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{getIndustriesStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) =>
                                        setJobNotificationsPayload({ ...jobNotificationsPayload, industry_id: newValue?.id ?? 0 })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Autocomplete disabled={getCandidateNotificationStatus === 'pending'}
                                    noOptionsText={LovNoMatchesText} size='small' disablePortal id='jobTitle'
                                    ListboxProps={AutocompletePopoverProps} options={jobTitles}
                                    getOptionLabel={(option) => option?.title || ''}
                                    groupBy={(option) => option.industry}
                                    value={jobTitles?.find(jobTitle => jobTitle.id === jobNotificationsPayload.job_title_id) ?? null}
                                    renderInput={(params) => <TextField required {...params} label='Job title'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{getJobTitlesStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) =>
                                        setJobNotificationsPayload({ ...jobNotificationsPayload, job_title_id: newValue?.id ?? 0 })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <TextField disabled={getCandidateNotificationStatus === 'pending'}
                                    label="Job location" variant="outlined" fullWidth size='small'
                                    onChange={(e) => setJobNotificationsPayload({ ...jobNotificationsPayload, job_city: e.target.value })}
                                    value={jobNotificationsPayload.job_city ?? ''} required />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Autocomplete disabled={getCandidateNotificationStatus === 'pending'}
                                    noOptionsText={LovNoMatchesText} size='small' disablePortal id='state'
                                    ListboxProps={AutocompletePopoverProps} options={jobStates}
                                    getOptionLabel={(option) => option?.name_en || ''}
                                    value={jobStates?.find(jobTitle => jobTitle.id === jobNotificationsPayload.state_id) ?? null}
                                    renderInput={(params) => <TextField required {...params} label='State'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{getJobStatesStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) =>
                                        setJobNotificationsPayload({ ...jobNotificationsPayload, state_id: newValue?.id ?? 0 })} />
                            </Grid>
                        </Grid>
                        <Stack direction='row' justifyContent='space-between'>
                            <ShButton variant="outlined" onClick={() => navigate(CandidateLoginRedirect)}>Back</ShButton>
                            <ShGreenBtn variant="outlined" disabled={isSaveDisabled}
                                onClick={() => dispatch(updateCandidateNotificationSettings({ payload: jobNotificationsPayload }))}>
                                Save</ShGreenBtn>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </Container>
    </>)
}