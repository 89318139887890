import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthQuestion, IAuthQuestionAndAnswer, IChangePassPayload, IResetAuthAns, ISecurityState, IUpdateAuthQuestion } from "Modules/Core/SettingsTs/SettingsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";

const initialSecurityState: ISecurityState = {
    changeUserPassApiStatus: 'idle',
    changeUserPassApiResponse: '',
    updateSecurityAuthApiStatus: 'idle',
    updateSecurityAuthApiResponse: '',
    updatedAuthPreferences: { emailVerification: false, securityVerification: false, authQuestionId: null },
    getSecurityQuestionsStatus: 'idle',
    getSecurityQuestionsResponse: '',
    securityQuestions: [],
    validateAuthAnsApiStatus: 'idle',
    validateAuthAnsApiResponse: '',
    isValidAnswer: undefined,
    setAuthQsApiStatus: 'idle',
    setAuthQsApiResponse: '',
    resetAuthAnsApiStatus: 'idle',
    resetAuthAnsApiResponse: '',
    changeAuthQuestionStatus: 'idle',
    changeAuthQuestionResponse: '',
};

export const changeUserPassword = createAsyncThunk<IBaseResponse, IChangePassPayload, { rejectValue: IBaseResponse }>(
    "changeUserPassword",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.CHANGE_USER_PASSWORD}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateSecurityAuth = createAsyncThunk<IBaseResponse, { security_varification: boolean, email_varification: boolean }, { rejectValue: IBaseResponse }>(
    "updateSecurityAuth",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.SECURITY_AUTH}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const getSecurityQuestions = createAsyncThunk<IBaseResponse<IAuthQuestion[]>, void, { rejectValue: IBaseResponse }>(
    "getSecurityQuestions",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.GET_AUTH_QS}`)
            .then((response: AxiosResponse<IBaseResponse<IAuthQuestion[]>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const setAuthQuestion = createAsyncThunk<IBaseResponse, IAuthQuestionAndAnswer, { rejectValue: IBaseResponse }>(
    "setAuthQuestion",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.SECURITY_AUTH}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const validateAuthAnswer = createAsyncThunk<IBaseResponse, IAuthQuestionAndAnswer, { rejectValue: IBaseResponse }>(
    "validateAuthAnswer",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.DEVICE_AUTH}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const resetAuthAnswer = createAsyncThunk<IBaseResponse, IResetAuthAns, { rejectValue: IBaseResponse }>(
    "resetAuthAnswer",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.RESET_AUTH_ANSWER}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const changeAuthQuestion = createAsyncThunk<IBaseResponse, IUpdateAuthQuestion, { rejectValue: IBaseResponse }>(
    "changeAuthQuestion",
    async (payload, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.CHANGE_AUTH_QS}`, payload)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

// response data from the api response is not configured properly thus using any temporarily.
const setAuthPreferences = (state: ISecurityState, data: any) => {
    // Get latest user security auth preferences to update user info in auth slice.
    const { email_varification, security_varification, auth_question_id } = data;
    state.updatedAuthPreferences = {
        emailVerification: email_varification,
        securityVerification: security_varification,
        authQuestionId: auth_question_id
    };
    return state;
};

const securitySlice = createSlice({
    name: "securitySlice",
    initialState: initialSecurityState,
    reducers: {
        resetChangePassword: (state) => { state.changeUserPassApiStatus = 'idle'; state.changeUserPassApiResponse = '' },
        resetSecurityAuth: (state) => { state.updateSecurityAuthApiStatus = 'idle'; state.updateSecurityAuthApiResponse = '' },
        resetAuthAnsChange: (state) => { state.resetAuthAnsApiStatus = 'idle'; state.resetAuthAnsApiResponse = '' },
        resetAuthQuestionChange: (state) => { state.changeAuthQuestionStatus = 'idle'; state.changeAuthQuestionResponse = '' },
        resetSetAuthQs: (state) => { state.setAuthQsApiStatus = 'idle'; state.setAuthQsApiResponse = '' },
        resetAnsValidation: (state) => { state.isValidAnswer = undefined; state.validateAuthAnsApiResponse = '' }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialSecurityState;
        });
        //handle change user password
        builder.addCase(changeUserPassword.pending, (state) => {
            state.changeUserPassApiStatus = 'pending';
        });
        builder.addCase(changeUserPassword.fulfilled, (state, action) => {
            state.changeUserPassApiStatus = 'success';
            state.changeUserPassApiResponse = action?.payload?.message;
        });
        builder.addCase(changeUserPassword.rejected, (state, action) => {
            state.changeUserPassApiStatus = 'failed';
            state.changeUserPassApiResponse = action?.payload?.message;
        });
        //handle update security authentication
        builder.addCase(updateSecurityAuth.pending, (state) => {
            state.updateSecurityAuthApiStatus = 'pending';
        });
        builder.addCase(updateSecurityAuth.fulfilled, (state, action) => {
            state.updateSecurityAuthApiStatus = 'success';
            state.updateSecurityAuthApiResponse = action?.payload?.message;
            // Get latest user security auth preferences to update user info in auth slice.
            state = setAuthPreferences(state, action?.payload?.data);
        });
        builder.addCase(updateSecurityAuth.rejected, (state, action) => {
            state.updateSecurityAuthApiStatus = 'failed';
            state.updateSecurityAuthApiResponse = action?.payload?.message;
        });
        //handle get auth questions
        builder.addCase(getSecurityQuestions.pending, (state) => {
            state.getSecurityQuestionsStatus = 'pending';
        });
        builder.addCase(getSecurityQuestions.fulfilled, (state, action) => {
            state.getSecurityQuestionsStatus = 'success';
            state.getSecurityQuestionsResponse = action?.payload?.message;
            state.securityQuestions = action?.payload?.data;
        });
        builder.addCase(getSecurityQuestions.rejected, (state, action) => {
            state.getSecurityQuestionsStatus = 'failed';
            state.getSecurityQuestionsResponse = action?.payload?.message;
        });
        //handle validate auth answer
        builder.addCase(validateAuthAnswer.pending, (state) => {
            state.validateAuthAnsApiStatus = 'pending';
            state.isValidAnswer = undefined;
        });
        builder.addCase(validateAuthAnswer.fulfilled, (state, action) => {
            state.validateAuthAnsApiStatus = 'success';
            state.validateAuthAnsApiResponse = action?.payload?.message;
            state.isValidAnswer = action?.payload.data?.success;
        });
        builder.addCase(validateAuthAnswer.rejected, (state, action) => {
            state.validateAuthAnsApiStatus = 'failed';
            state.validateAuthAnsApiResponse = action?.payload?.message;
        });
        //handle update auth answer
        builder.addCase(resetAuthAnswer.pending, (state) => {
            state.resetAuthAnsApiStatus = 'pending';
        });
        builder.addCase(resetAuthAnswer.fulfilled, (state, action) => {
            state.resetAuthAnsApiStatus = 'success';
            state.resetAuthAnsApiResponse = action?.payload?.message;
        });
        builder.addCase(resetAuthAnswer.rejected, (state, action) => {
            state.resetAuthAnsApiStatus = 'failed';
            state.resetAuthAnsApiResponse = action?.payload?.message;
        });
        //handle change auth question
        builder.addCase(changeAuthQuestion.pending, (state) => {
            state.changeAuthQuestionStatus = 'pending';
        });
        builder.addCase(changeAuthQuestion.fulfilled, (state, action) => {
            state.changeAuthQuestionStatus = 'success';
            state.changeAuthQuestionResponse = action?.payload?.message;
            // Get latest user security auth preferences to update user info in auth slice.
            state = setAuthPreferences(state, action?.payload?.data);
        });
        builder.addCase(changeAuthQuestion.rejected, (state, action) => {
            state.changeAuthQuestionStatus = 'failed';
            state.changeAuthQuestionResponse = action?.payload?.message;
        });
        //set auth question
        builder.addCase(setAuthQuestion.pending, (state) => {
            state.setAuthQsApiStatus = 'pending';
        });
        builder.addCase(setAuthQuestion.fulfilled, (state, action) => {
            state.setAuthQsApiStatus = 'success';
            state.setAuthQsApiResponse = action?.payload?.message;
            // Get latest user security auth preferences to update user info in auth slice.
            state = setAuthPreferences(state, action?.payload?.data)
        });
        builder.addCase(setAuthQuestion.rejected, (state, action) => {
            state.setAuthQsApiStatus = 'failed';
            state.setAuthQsApiResponse = action?.payload?.message;
        });
    },
});

export const { resetChangePassword, resetSecurityAuth, resetAuthAnsChange, resetAuthQuestionChange, resetSetAuthQs, resetAnsValidation } = securitySlice.actions;
export default securitySlice;
