import ErrorIcon from '@mui/icons-material/Error';
import { Stack, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { EmailRegEx } from "shared/constants";
import { getPasswordResetEmail, resetGetPassReset } from "store/slices/auth-v2/password-slice";
import { IResetPasswordForm } from "./AuthModel";

export const ForgotPassword = ({ setIsShowForgotPassword }: { setIsShowForgotPassword: Dispatch<SetStateAction<boolean>>, }) => {

    const dispatch = useAppDispatch();
    const { getPasswordResetStatus, getPasswordResetResponse } = useAppSelector((state) => state.auth.password);
    const [forgotPasswordForm, setForgotPasswordForm] = useState<IResetPasswordForm>(
        { email: '', emailError: null, emailTouched: false });

    const updateForgotPasswordFormValues = (value: string) => {
        setForgotPasswordForm({
            ...forgotPasswordForm, email: value,
            emailError: value?.length === 0 ? 'Please enter email' :
                new RegExp(EmailRegEx).test(value) ? null : 'Please enter a valid email',
            emailTouched: true
        });
    }

    const sendPasswordResetMail = (evt: FormEvent<HTMLFormElement>) => {
        // preventDefault to stop browser reload on submit
        evt.preventDefault();
        // set form errors.
        const forgotPassForm = {
            ...forgotPasswordForm,
            emailError: forgotPasswordForm.email?.length === 0 ? 'Please enter email' :
                new RegExp(EmailRegEx).test(forgotPasswordForm.email) ? null : 'Please enter a valid email',
            emailTouched: true
        }
        setForgotPasswordForm(forgotPassForm);
        // proceed to send reset link only if there are no form errors.
        if (forgotPassForm.emailError === null) {
            dispatch(getPasswordResetEmail({ email: forgotPassForm.email }));
        }
    }

    return (<>
        {/* Displaying alerts based on get password resetAPIresponse */}
        {getPasswordResetStatus === 'success' && <ShAlert
            severity='success' onClose={() => { dispatch(resetGetPassReset()) }}>{getPasswordResetResponse}</ShAlert>}
        {getPasswordResetStatus === 'failed' && <ShAlert
            severity='error' icon={<ErrorIcon />} onClose={() => { dispatch(resetGetPassReset()) }}>
            {getPasswordResetResponse}</ShAlert>}
        <form id="forgot_password" onSubmit={(e) => sendPasswordResetMail(e)}>
            <Stack rowGap={2} padding={{ xs: 1, sm: 2, md: 2, lg: 3 }}>
                <Typography variant='body2'>
                    Provide your email address so that we can send you a password reset link
                </Typography>
                <TextField size="small" id="email" label="Email" fullWidth
                    variant="outlined" value={forgotPasswordForm.email}
                    onChange={e => updateForgotPasswordFormValues(e.target.value)}
                    error={forgotPasswordForm.emailTouched && forgotPasswordForm.emailError !== null}
                    helperText={forgotPasswordForm.emailError} />

                <Stack alignItems='center' rowGap={3} marginTop={1}
                    direction='row' justifyContent='space-between'>
                    <ShButton variant='outlined' onClick={() => setIsShowForgotPassword(false)}>Login</ShButton>
                    <ShGreenBtn size="small" type="submit" variant="outlined"
                        disabled={getPasswordResetStatus === 'pending'}>
                        {getPasswordResetStatus === 'pending' ? 'Sending link...' : 'Get Reset Link'}
                    </ShGreenBtn>
                </Stack>
            </Stack>
        </form>
    </>)
}