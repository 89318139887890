import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { ApiStates } from "shared/constants";

const initialEmployerState = {
    fetchingPricingGrps: ApiStates.idle,
    fetchingExtrnlIds: ApiStates.idle,
    fetchingEmpDetails: ApiStates.idle,
    updatingEmpDetails: ApiStates.idle,
    updtEmpDetlsResposne: '',
    enablingOrDisablingEmp: ApiStates.idle,
    enblngOrDsblngEmpRspnse: '',
    empEmail: undefined,
    empAccId: undefined,
    empPricingGrpId: undefined,
    isEmpDisabled: undefined,
    employerDetails: {},
    pricingGroups: [],
    externalIds: []
};

export const fetchEmployerDetails = createAsyncThunk(
    "employerDetails",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const fetchPricingGroups = createAsyncThunk(
    "pricingGroups",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.PRICING_GROUPS}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const fetchExternalIds = createAsyncThunk(
    "externalIds",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/externalIds`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateEmployerDetails = createAsyncThunk(
    "updateEmployerDetails",
    async ({ employerId, empDetls }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}`, empDetls)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const enableOrDisableEmployer = createAsyncThunk(
    "enableOrDisableEmployer",
    async ({ employerId, action }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${AdminApiEndpoints.ADMIN_EMPLOYERS}/${employerId}/actions?action=${action}`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const empDetailsSlice = createSlice({
    name: "employer",
    initialState: initialEmployerState,
    reducers: {
        resetEmpDetUpdation: (state) => { state.updatingEmpDetails = ApiStates.idle; state.updtEmpDetlsResposne = '' },
        resetEmpToggle: (state) => { state.enablingOrDisablingEmp = ApiStates.idle; state.enblngOrDsblngEmpRspnse = '' }
    },
    extraReducers: (builder) => {
        //employer details
        builder.addCase(fetchEmployerDetails.pending, (state, action) => {
            state.fetchingEmpDetails = ApiStates.pending;
        });
        builder.addCase(fetchEmployerDetails.fulfilled, (state, action) => {
            state.employerDetails = action?.payload?.data;
            // Updating Account ID, Mail and Pricing group ID only once initially or if their value changes.
            if (state.empAccId === undefined) {
                state.empAccId = action?.payload?.data?.account_id;
            }
            if (state.empEmail === undefined) {
                state.empEmail = action?.payload?.data.email;
            }
            if (state.empPricingGrpId === undefined ||
                (state.empPricingGrpId !== action?.payload?.data?.employer_pricing_group_id)) {
                state.empPricingGrpId = action?.payload?.data?.employer_pricing_group_id;
            }
            if (state.isEmpDisabled === undefined || (state.isEmpDisabled !== action?.payload?.data?.is_disabled)) {
                state.isEmpDisabled = action?.payload?.data?.is_disabled;
            }
            state.fetchingEmpDetails = ApiStates.success;
        });
        builder.addCase(fetchEmployerDetails.rejected, (state, action) => {
            state.employerDetails = {};
            state.fetchingEmpDetails = ApiStates.failed;
        });
        //pricing groups
        builder.addCase(fetchPricingGroups.pending, (state, action) => {
            state.fetchingPricingGrps = ApiStates.pending;
        });
        builder.addCase(fetchPricingGroups.fulfilled, (state, action) => {
            state.pricingGroups = action?.payload?.data;
            state.fetchingPricingGrps = ApiStates.success;
        });
        builder.addCase(fetchPricingGroups.rejected, (state, action) => {
            state.pricingGroups = [];
            state.fetchingPricingGrps = ApiStates.failed;
        });
        //updating employer details
        builder.addCase(updateEmployerDetails.pending, (state, action) => {
            state.updatingEmpDetails = ApiStates.pending;
        });
        builder.addCase(updateEmployerDetails.fulfilled, (state, action) => {
            state.updatingEmpDetails = ApiStates.success;
            state.updtEmpDetlsResposne = action?.payload?.message;
        });
        builder.addCase(updateEmployerDetails.rejected, (state, action) => {
            state.updatingEmpDetails = ApiStates.failed;
        });
        //external ids
        builder.addCase(fetchExternalIds.pending, (state, action) => {
            state.fetchingExtrnlIds = ApiStates.pending;
        });
        builder.addCase(fetchExternalIds.fulfilled, (state, action) => {
            state.externalIds = action?.payload?.data;
            state.fetchingExtrnlIds = ApiStates.success;
        });
        builder.addCase(fetchExternalIds.rejected, (state, action) => {
            state.externalIds = [];
            state.fetchingExtrnlIds = ApiStates.failed;
        });
        //enable or disable employer
        builder.addCase(enableOrDisableEmployer.pending, (state, action) => {
            state.enablingOrDisablingEmp = ApiStates.pending;
        });
        builder.addCase(enableOrDisableEmployer.fulfilled, (state, action) => {
            state.enblngOrDsblngEmpRspnse = action?.payload?.message;
            state.enablingOrDisablingEmp = ApiStates.success;
        });
        builder.addCase(enableOrDisableEmployer.rejected, (state, action) => {
            state.enablingOrDisablingEmp = ApiStates.failed;
        });
    },
});

export const { resetEmpDetUpdation, resetEmpToggle } = empDetailsSlice.actions;
export default empDetailsSlice;
