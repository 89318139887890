export const CompanyPolicies = {
  Compensation_Benefits_Policies: [
    "Employee Payroll Advance Policy Template",
    "Employee Travel And Expense Reimbursement Policy Template",
    "Sample Business Expense Reimbursement Procedure Template",
    "Sample Employee Bonus And Incentive Program Template",
    "Sample Employee Business Travel Template",
    "Sample Employment Contract Template",
  ],
  Recruitment_Onboarding_Policies: [
    "Employee Background Verification Policy Template",
    "Employee Onboarding And Orientation Template",
    "Internal Promotion And Transfer Template",
    "International Recruitment And Hiring Template",
    "Interview Process And Candidate Selection Template",
    "Interview Travel Reimbursement Template",
    "New Hire Onboarding Template",
    "Sample Internal Job Posting Template",
    "Sample Telecommuting Policy Template",
  ],
  Leave_Time_Off_Policies: [
    "Employee Attendance And Punctuality Policy Template",
    "Employee Bereavement Leave Policy Template",
    "Employee Jury Duty Leave Template",
    "Employee Leave Of Absence Request Template",
    "Employee Long Term Disability Coverage Template",
    "Employee Maternity Leave Template",
    "Employee Military Leave Template",
    "Employee Parental Leave Policy Template",
    "Employee PTO And Vacation Request Policy Template",
    "Employee Sabbatical Leave Policy Template",
    "Employee Short Term Disability Policy Template",
    "Leave Of Absence For Medical Reasons Template",
    "Shared Parental Leave Policy Template",
    "Short Term Disability Coverage Policy Template",
    "Study Leave Policy Template",
    "Temporary Disability Coverage Policy Template",
    "Unlimited Vacation Policy Template",
  ],
  Conduct_Ethics_Policies: [
    "Code Of Conduct For Employees Template",
    "Employee Anti Discrimination And Equal Opportunity Policy Template",
    "Employee Code Of Ethics Template",
    "Employee Confidentiality And Data Security Template",
    "Employee Internet Usage And Monitoring Template",
    "Employee Non Disclosure Agreement NDA Template",
    "Employee Personal Relationships In The Workplace Policy Template",
    "Employee Social Media Conduct Policy Template",
    "Progressive Discipline And Conduct Policy Template",
    "Sample Grievance Handling Template",
    "Sample Performance Improvement Plan Template",
    "Sample Whistleblower Protection Policy Template",
    "Solicitation And Distribution Policy Template",
    "Transgender Inclusion And Support Policy Template",
    "Violence Prevention And Response Policy Template",
    "Workplace Harassment Prevention Policy Template",
  ],
  Health_Safety_Policies: [
    "Employee COVID19 Response And Safety Template",
    "Employee Drug And Alcohol Testing Template",
    "Employee Mental Health And Wellness Template",
    "Employee Return To Work Policy Template",
    "Employee Substance Abuse Prevention Policy Template",
    "Occupational Health And Safety Policy Template",
    "Sample Accident Reporting Procedure Template",
    "Stress Management And Support Policy Template",
    "Third Party Harassment Prevention Policy Template",
    "Workplace Safety And Health Policy Template",
  ],
  Technology_IT_Policies: [
    "Acceptable Use Policy Template",
    "Data Protection And Privacy Policy Template",
    "Employee Cybersecurity And IT Usage Template",
    "Employee Internet Usage And Monitoring Template",
    "GDPR Compliance And Data Privacy Policy Template",
    "IT And Computer Usage Template",
    "Sample Telecommuting Policy Template",
  ],
  Miscellaneous_Policies: [
    "Employee Accessibility And Inclusion Policy Template",
    "Employee Assistance And Support Program Template",
    "Employee Breastfeeding Support Policy Template",
    "Employee Cell Phone Usage Policy Template",
    "Employee Constructive Dismissal Prevention Template",
    "Employee Flexible Working Hours Template",
    "Employee Moonlighting And Outside Employment Template",
    "Employee Remote Work Policy Template",
    "Employee Retaliation Prevention Policy Template",
    "Employee Training And Development Policy Template",
    "Employee Uniform And Dress Code Policy Template",
    "Employee Volunteering And Community Service Policy Template",
    "Employment Agreement And Contract Terms Template",
    "Fraud And Corruption Prevention Policy Template",
    "Hot Desking And Flexible Workspace Policy Template",
    "Organizational Succession Planning Policy Template",
    "Pets In The Workplace Policy Template",
    "Record Retention And Document Management Policy Template",
    "Sample Company Sick Leave Policy Template",
    "Sample Employee Business Dress Code Template",
    "Sample Employee Handbook Template",
    "Sample Employee Referral Program Template",
    "Sample Employee Volunteer Program Template",
    "Sample Moonlighting Template",
    "Sample Time And Attendance Policy Template",
    "Work From Home Policy Template",
  ],
};
