import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { UnderlinedText } from 'shared/SharedStyles/ShDataDisplay';
import { ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { setIsWelcomeCreateJobAcknowledged } from 'store/slices/app/app-info-slice';

export const WelcomeCreateJob = () => {

    const dispatch = useDispatch();

    return (<>
        <UnderlinedText variant="h6" fontSize='large' marginBottom={{ xs: 2, sm: 3, md: 4, lg: 5 }} underlineType='border'
            borderBottomWidth={2} width='fit-content'>
            Welcome to Job Description Wizard!
        </UnderlinedText>
        <Typography variant='body1' marginBottom={2}>
            The first step to getting great candidates is to create a Job Description.
        </Typography>
        <Typography variant='body1' marginBottom={2}>
            We've done all the work for you, just follow the steps and in 5 minutes you're done.
        </Typography>
        <Typography variant='body1' marginBottom={2}>
            Create as many job descriptions as you want with your free account.
        </Typography>
        <Typography variant='body1' marginBottom={2}>
            Then choose a bundle and get your job posted on multiple job boards.
        </Typography>
        <Typography variant='body1' marginBottom={2}>
            Already have a great job description? No problem, you can always upload your own.
        </Typography>
        <ShGreenBtn component={RouterLink} to='/employer/job/new' endIcon={<NavigateNextIcon />}
            onClick={() => dispatch(setIsWelcomeCreateJobAcknowledged({ _isWelcomeCreateJobAcknowledged: 'yes' }))}>
            Continue
        </ShGreenBtn>
    </>);
}