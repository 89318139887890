import FormalJobOfferTemplatePage from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateSinglePages/FormalJobOfferTemplatePage";
import GeneralJobOfferTemplatePage from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateSinglePages/GeneralJobOfferTemplatePage";
import InformalJobOfferTemplatePage from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateSinglePages/InformalJobOfferTemplatePage";
import InternalJobOfferTemplatePage from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateSinglePages/InternalJobOfferTemplatePage";
import SalesJobOfferTemplatePage from "Modules/Marketing/Resources/Templates/OfferTemplates/OfferTemplateSinglePages/SalesJobOfferTemplatePage";
import { Route, Routes } from "react-router-dom";

export const OfferLetterTemplatesRouter = () => {
    return (
        <Routes>
            {/* Offer Letter Templates*/}
            <Route path="/general-job-offer-template" element={<GeneralJobOfferTemplatePage />} />
            <Route path="/formal-job-offer-template" element={<FormalJobOfferTemplatePage />} />
            <Route path="/informal-job-offer-template" element={<InformalJobOfferTemplatePage />} />
            <Route path="/internal-job-offer-template" element={<InternalJobOfferTemplatePage />} />
            <Route path="/sales-job-offer-template" element={<SalesJobOfferTemplatePage />} />
        </Routes>
    );
};
