import { Breadcrumbs, styled } from "@mui/material";

export const EmpBreadcrumbs = styled(Breadcrumbs)(
    ({ theme }) => ({
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        '& .MuiBreadcrumbs-li': {
            '& a': {
                cursor: 'pointer',
                '&.disabled': {
                    pointerEvents: 'none'
                }
            }
        },
        '& .MuiBreadcrumbs-separator': {
            marginLeft: '4px',
            marginRight: '4px'
        }
    })
);
