import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, LinearProgress, Link, Paper, Stack, Typography } from "@mui/material";
import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { useNotification } from "Modules/Core/Notification";
import { downloadPdfFromUrl } from "helpers/fileHandlers";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { ApiState } from "shared/SharedModels";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ListWrapper, ShContainer } from "shared/SharedStyles/ShLayouts";
import { DefaultAPIErrorMsg } from "shared/constants";
import { getApplicantResume, resetGetApplicantResume } from "store/slices/employer/applicants/applicant-profile-slice";
import {
    exportIncompleteApplicantsListAsCSV, getIncompleteApplicantsList,
    resetExportApplicantsAsCSV, resetIncompleteApplicants
} from "store/slices/employer/applicants/incomplete-applicants-slice";

export const IncompleteApplicants = () => {
    const dispatch = useAppDispatch();
    const { jobId } = useParams();
    const notification = useNotification();
    const { incompleteApplicantsList, getIncompleteApplicantsListStatus, getIncompleteApplicantsListResponse,
        downloadAsCSVStatus
    } = useAppSelector((state) => state.employer.applicants.incompleteApplicants);
    const { getApplicantResumeStatus, getApplicantResumeResponse,
        resumeS3Url } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const [resumeName, setResumeName] = useState<string | undefined>(undefined);
    const { currentBreadcrumb } = useAppSelector((state) => state.app.breadcrumb);
    const backPageBreadcrumb =
        currentBreadcrumb &&
        currentBreadcrumb.breadcrumbs[currentBreadcrumb.breadcrumbs.length - 2];

    // get list on page load.
    useEffect(() => {
        dispatch(getIncompleteApplicantsList({ jobId: parseInt(jobId ?? '') }));
    }, [dispatch, jobId]);

    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadAsCSVStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: 'Download successful'
            });
            dispatch(resetExportApplicantsAsCSV());
        }
    }, [dispatch, downloadAsCSVStatus, notification]);

    // download resume pdf once retrieved.
    useEffect(() => {
        if (getApplicantResumeStatus === 'success' && resumeS3Url && resumeName) {
            downloadPdfFromUrl(resumeS3Url, resumeName);
            dispatch(resetGetApplicantResume());
            setResumeName(() => { return undefined });
        }
    }, [dispatch, getApplicantResumeStatus, resumeName, resumeS3Url]);

    const showFailureAlerts = (apiState: ApiState, msg: string,
        resetFunction: ActionCreatorWithoutPayload<"applicantProfile/resetGetApplicantResume"> |
            ActionCreatorWithoutPayload<"incompleteApplicants/resetIncompleteApplicants">) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error" onClose={() => dispatch(resetFunction())}>
                            {msg ?? DefaultAPIErrorMsg}
                        </ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    return (<>
        <ShContainer maxWidth='sm' disableGutters>
            {backPageBreadcrumb?.isActive && (
                <Stack marginLeft="16px" marginY="16px">
                    <ShButton size="small"
                        startIcon={<ArrowBackIcon fontSize="medium" />}
                        variant="outlined" component={RouterLink}
                        to={backPageBreadcrumb.href}>
                        Back
                    </ShButton>
                </Stack>
            )}
            <ListWrapper paddingX={2}>
                <Stack direction='row' justifyContent='space-between' alignItems='center' marginBottom={2}>
                    <Typography variant='body1' fontWeight={600}>Incomplete Applicants</Typography>
                    <ShButton size="small" variant="contained" disableElevation disabled={downloadAsCSVStatus === 'pending' ? true : false}
                        onClick={() => dispatch(exportIncompleteApplicantsListAsCSV({ jobId: parseInt(jobId ?? '') }))}>
                        {downloadAsCSVStatus === 'pending' ? 'Downloading...' : 'Download list as CSV'}
                    </ShButton>
                </Stack>
                {showFailureAlerts(getIncompleteApplicantsListStatus, getIncompleteApplicantsListResponse ?? '', resetIncompleteApplicants)}
                {getApplicantResumeStatus !== undefined &&
                    showFailureAlerts(getApplicantResumeStatus, getApplicantResumeResponse ?? '', resetGetApplicantResume)
                }
                {getIncompleteApplicantsListStatus === 'pending' && <LinearProgress />}
                <Stack rowGap={1}>
                    {incompleteApplicantsList?.map(applicant => (
                        <Paper variant="outlined" key={applicant.id}>
                            <Stack direction='row' justifyContent='space-between' paddingY={1} rowGap={1}
                                paddingX={{ xs: 1, sm: 2, md: 2, lg: 2 }} alignItems='center' flexWrap='wrap'>
                                <Stack>
                                    <Typography variant="body2">
                                        {`${applicant.candidate?.account?.first_name ?? ''} 
                                    ${applicant.candidate?.account?.middle_name ?? ''}
                                    ${applicant.candidate?.account?.last_name ?? ''}`}
                                    </Typography>
                                    <Typography variant="body2">
                                        <Link href={`mailto:${applicant.candidate?.account?.email}`} fontSize='small' underline='hover'>
                                            {`${applicant.candidate?.account?.email}`}
                                        </Link>
                                    </Typography>
                                </Stack>
                                <Stack>
                                    <Typography variant="body2">Date applied:&nbsp;{moment(applicant.updatedAt).format("MMM DD, YYYY")}</Typography>

                                    <Typography variant="body2">Resume:&nbsp;
                                        {applicant.resume_id ?
                                            <ShButton size="small"
                                                onClick={() => {
                                                    dispatch(getApplicantResume({ applicantId: applicant.id }));
                                                    setResumeName(`${applicant.candidate?.account?.first_name ?? ''}${applicant.candidate?.account?.middle_name ?? ''}${applicant.candidate?.account?.last_name ?? ''}`)
                                                }}>
                                                Download
                                            </ShButton> : <>
                                                Not Provided
                                            </>
                                        }
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Paper>
                    ))}
                </Stack>
            </ListWrapper>
        </ShContainer>
    </>);
};