import { Stack, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { IScoreCategory } from "store/slices/admin/assessments-and-reports/assessments-and-reports-model";
import { createScoreCategory, getScoreCategory, resetCreteScoreCategory, resetGetScoreCategory, resetUpdateScoreCategory, updateScoreCategory } from "store/slices/admin/assessments-and-reports/score-categories-slice";
import { ScoreCategoryInitial } from "../AssessmentsAndReportsConstants";

export const ScoreCategory = () => {

    const { scoreCategoryId } = useParams();
    const dispatch = useAppDispatch();
    const notification = useNotification();
    const navigate = useNavigate();

    const { getScoreCategoryStatus, getScoreCategoryResponse, createScoreCategoryStatus, updateScoreCategoryStatus,
        currentScoreCategory, createScoreCategoryResponse, updateScoreCategoryResponse
    } = useAppSelector(state => state.admin.assessmentsAndReports.scoreCategories);
    const [scoreCategoryForm, setScoreCategoryForm] = useState<IScoreCategory>({ ...ScoreCategoryInitial });
    const [isScoreCategoryFormDisabled, setIsScoreCategoryFormDisabled] = useState<boolean>(true);

    useEffect(() => {
        if (scoreCategoryId) {
            dispatch(getScoreCategory({ id: parseInt(scoreCategoryId) }));
        }
    }, [dispatch, scoreCategoryId]);

    useEffect(() => {
        if (getScoreCategoryStatus === 'success' && currentScoreCategory) {
            dispatch(resetGetScoreCategory());
            setScoreCategoryForm({ ...currentScoreCategory });
        }
    }, [currentScoreCategory, dispatch, getScoreCategoryStatus]);

    useEffect(() => {
        const { code, name, position, scoring_type } = scoreCategoryForm;
        setIsScoreCategoryFormDisabled(!(code || name || position || scoring_type));
    }, [scoreCategoryForm]);

    const createOrUpdateScoreCategory = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (scoreCategoryId) {
            dispatch(updateScoreCategory({ id: parseInt(scoreCategoryId), payload: scoreCategoryForm }));
        } else {
            dispatch(createScoreCategory({ payload: scoreCategoryForm }));
        }
    }

    useEffect(() => {
        if (['failed', 'success'].includes(createScoreCategoryStatus)) {
            notification.displayNotification({
                open: true,
                type: createScoreCategoryStatus === 'success' ? 'success' : 'error',
                message: createScoreCategoryResponse
            });
            dispatch(resetCreteScoreCategory());
        }
    }, [createScoreCategoryResponse, createScoreCategoryStatus, dispatch, notification]);

    useEffect(() => {
        if (['failed', 'success'].includes(updateScoreCategoryStatus)) {
            notification.displayNotification({
                open: true,
                type: updateScoreCategoryStatus === 'success' ? 'success' : 'error',
                message: updateScoreCategoryResponse
            });
            dispatch(resetUpdateScoreCategory());
        }
    }, [dispatch, notification, updateScoreCategoryResponse, updateScoreCategoryStatus]);

    useEffect(() => {
        if (createScoreCategoryStatus === 'success' || updateScoreCategoryStatus === 'success') {
            navigate(`/admin/score-categories`);
        }
    }, [createScoreCategoryStatus, navigate, updateScoreCategoryStatus]);

    return (<>
        <Stack rowGap={3} padding={4} flex={1} overflow='auto'>
            <Typography variant="h6">Score Category</Typography>
            {getScoreCategoryStatus === 'failed' && <ShAlert severity="error">{getScoreCategoryResponse}</ShAlert>}
            {getScoreCategoryStatus === 'pending' ? <ShAlert severity="info">Loading Score Category details...</ShAlert> :
                <ShContainer maxWidth='sm' disableGutters>
                    <Stack rowGap={2} component='form' onSubmit={e => createOrUpdateScoreCategory(e)}>
                        <TextField label="Name" variant="outlined" fullWidth size='small' value={scoreCategoryForm?.name ?? ''}
                            onChange={(e) => setScoreCategoryForm({ ...scoreCategoryForm, name: e.target.value })} required />
                        <TextField label="Code" variant="outlined" fullWidth size='small' value={scoreCategoryForm?.code ?? ''}
                            onChange={(e) => setScoreCategoryForm({ ...scoreCategoryForm, code: e.target.value })} required />
                        <TextField label="Position" type="number" variant="outlined" fullWidth size='small' value={scoreCategoryForm?.position ?? ''}
                            onChange={(e) => setScoreCategoryForm({ ...scoreCategoryForm, position: e.target.value ? parseInt(e.target.value) : undefined })} required />
                        <TextField label="Scoring Type" type="number" variant="outlined" fullWidth size='small' value={scoreCategoryForm?.scoring_type ?? ''}
                            onChange={(e) => setScoreCategoryForm({ ...scoreCategoryForm, scoring_type: e.target.value ? parseInt(e.target.value) : undefined })} required />
                        <ShButton variant="contained" disableElevation disabled={isScoreCategoryFormDisabled || createScoreCategoryStatus === 'pending' ||
                            updateScoreCategoryStatus === 'pending'} type="submit">
                            {(createScoreCategoryStatus === 'pending' || updateScoreCategoryStatus === 'pending') ? 'Saving Changes...' : scoreCategoryId ? 'Save Changes' : 'Save'}</ShButton>
                    </Stack>
                </ShContainer>
            }
        </Stack>
    </>)
}