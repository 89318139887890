export const TeacherJobDescription = {
    title: "Teacher Job Description",
    jobRole: "A Teacher is responsible for educating students at various levels, developing lesson plans, assessing student progress, and creating a positive learning environment. Teachers play a crucial role in shaping students' academic, social, and emotional development by delivering engaging instruction and providing support and guidance.",
    preRead: "Use this Teacher job description template to attract qualified candidates for teaching positions in your school or educational institution. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Teacher, you will design and deliver instructional lessons, assess student learning, and create a supportive classroom environment conducive to learning. Your role is to inspire and empower students to achieve academic success and personal growth while fostering a love of learning."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans that align with curriculum standards and educational objectives.",
                "Deliver engaging and interactive instruction that meets the diverse learning needs of students.",
                "Assess student learning through various methods, such as quizzes, tests, projects, and discussions.",
                "Provide constructive feedback to students to support their academic progress and growth.",
                "Create a positive and inclusive classroom environment where students feel valued, respected, and supported.",
                "Establish and maintain effective communication with students, parents, and colleagues.",
                "Collaborate with other teachers and school staff to enhance student learning and success.",
                "Participate in professional development activities to stay updated on best practices in teaching and learning.",
                "Maintain accurate records of student attendance, grades, and academic performance.",
                "Adhere to school policies, procedures, and guidelines.",
                "Promote a culture of lifelong learning and academic excellence among students."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Education or related field; teaching certification or licensure is required.",
                "Experience working with students in a classroom setting, preferably in a K-12 educational environment.",
                "Strong knowledge of subject matter content and instructional strategies.",
                "Excellent communication and interpersonal skills.",
                "Ability to differentiate instruction to meet the diverse needs of students.",
                "Effective classroom management and behavior management skills.",
                "Passion for teaching and dedication to student success.",
                "Flexibility, adaptability, and a growth mindset.",
                "Commitment to ongoing professional development and continuous improvement.",
                "Cultural competency and ability to work with diverse student populations."
            ]
        }
    ]
};

export const SchoolCounselorJobDescription = {
    title: "School Counselor Job Description",
    jobRole: "A School Counselor provides comprehensive counseling services to students to support their academic, social, and emotional well-being. School Counselors assist students in developing academic and career goals, navigating personal challenges, and accessing resources and support services.",
    preRead: "Use this School Counselor job description template to attract qualified candidates for counseling positions in your school or educational institution. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a School Counselor, you will provide individual and group counseling to students, conduct assessments, and collaborate with parents, teachers, and other school personnel to promote student success. Your role is to support students' academic achievement, personal development, and social-emotional well-being to help them reach their full potential."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide individual and group counseling to students on academic, personal, social, and career-related issues.",
                "Conduct assessments to identify students' strengths, challenges, and needs.",
                "Develop and implement comprehensive counseling programs and interventions to address students' needs and promote positive outcomes.",
                "Collaborate with teachers, administrators, and parents to support student success and address academic and behavioral concerns.",
                "Assist students in developing academic and career goals and create plans to achieve them.",
                "Provide crisis intervention and support to students experiencing emotional or behavioral challenges.",
                "Refer students and families to appropriate support services and community resources as needed.",
                "Advocate for students' rights and well-being within the school and community.",
                "Maintain accurate and confidential records of counseling sessions and student progress.",
                "Participate in professional development activities to enhance counseling skills and stay updated on best practices.",
                "Adhere to ethical and legal standards in counseling practice and student confidentiality."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in School Counseling, Counseling Psychology, or related field; state licensure or certification is required.",
                "Experience working as a school counselor or in a counseling role with children and adolescents.",
                "Strong knowledge of counseling theories, techniques, and best practices.",
                "Excellent communication, interpersonal, and listening skills.",
                "Ability to establish rapport and build trusting relationships with students.",
                "Empathy, compassion, and sensitivity to students' diverse backgrounds and experiences.",
                "Crisis intervention and conflict resolution skills.",
                "Ability to collaborate effectively with diverse stakeholders, including students, parents, teachers, and community members.",
                "Strong organizational and time-management skills.",
                "Commitment to ongoing professional development and self-reflection."
            ]
        }
    ]
};

export const PharmacyTechnicianJobDescription = {
    title: "Pharmacy Technician Job Description",
    jobRole: "A Pharmacy Technician assists pharmacists in preparing and dispensing medications, managing inventory, and providing customer service in a pharmacy setting. Pharmacy Technicians play a crucial role in ensuring the safe and efficient delivery of pharmaceutical services.",
    preRead: "Use this Pharmacy Technician job description template to attract qualified candidates for pharmacy technician positions in your pharmacy or healthcare facility. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Pharmacy Technician, you will support pharmacists in dispensing prescriptions, managing inventory, and providing customer service to patients. Your role is to ensure accurate medication preparation, maintain pharmacy records, and assist patients with medication-related inquiries."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Receive and process prescription orders from patients and prescribers.",
                "Prepare and package medications according to prescription orders, including measuring, compounding, and labeling.",
                "Maintain accurate medication and inventory records, including tracking expiration dates and restocking medications.",
                "Assist pharmacists in verifying prescription information, resolving medication discrepancies, and ensuring compliance with regulatory requirements.",
                "Provide customer service to patients, including answering inquiries, processing payments, and offering medication counseling.",
                "Assist in the management of pharmacy supplies and equipment, including ordering, stocking, and organizing inventory.",
                "Perform administrative tasks, such as data entry, filing, and maintaining patient records.",
                "Adhere to pharmacy policies, procedures, and safety standards to ensure quality and accuracy in medication dispensing.",
                "Collaborate with other healthcare professionals, such as physicians and nurses, to coordinate patient care and medication therapy.",
                "Participate in continuing education and training to stay updated on pharmacy laws, regulations, and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; completion of a pharmacy technician training program is preferred.",
                "Certification or licensure as a Pharmacy Technician may be required, depending on the jurisdiction.",
                "Experience working in a pharmacy or healthcare setting, preferably as a pharmacy technician.",
                "Knowledge of pharmacy laws, regulations, and standards governing medication dispensing and handling.",
                "Familiarity with pharmaceutical calculations, measurements, and compounding techniques.",
                "Excellent attention to detail and accuracy in medication preparation and record-keeping.",
                "Strong communication and interpersonal skills for interacting with patients, healthcare providers, and pharmacy staff.",
                "Ability to work efficiently in a fast-paced environment and multitask effectively.",
                "Proficiency in pharmacy computer systems and software applications.",
                "Commitment to patient safety, confidentiality, and quality care."
            ]
        }
    ]
};

export const LibrarianJobDescription = {
    title: "Librarian Job Description",
    jobRole: "A Librarian manages library resources, provides research assistance to patrons, and promotes literacy and lifelong learning. Librarians organize library collections, develop programs and services, and assist users in accessing information and resources.",
    preRead: "Use this Librarian job description template to attract qualified candidates for librarian positions in your library or educational institution. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Librarian, you will manage library operations, curate collections, and assist patrons in finding and using library resources. Your role is to support learning, research, and literacy by providing access to information, resources, and services."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage library collections, including selecting, acquiring, cataloging, and organizing materials.",
                "Assist patrons in locating and accessing library resources, including books, journals, electronic databases, and multimedia materials.",
                "Provide reference and research assistance to library users, including helping with information inquiries, research projects, and citation requests.",
                "Develop and deliver library programs and services, such as storytime sessions, book clubs, and information literacy workshops.",
                "Promote library resources and services through outreach activities, social media, and community partnerships.",
                "Maintain library facilities, equipment, and technology to ensure a safe and welcoming environment for patrons.",
                "Collaborate with colleagues to develop library policies, procedures, and strategic initiatives.",
                "Stay updated on trends and developments in library science, information technology, and educational technology.",
                "Participate in professional development activities and engage in continuing education to enhance job skills and knowledge.",
                "Provide excellent customer service and support to library users of all ages and backgrounds."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Library Science, Information Science, or related field.",
                "Library certification or licensure may be required, depending on the jurisdiction.",
                "Experience working in a library or information center, preferably in a public or academic setting.",
                "Knowledge of library cataloging and classification systems, such as Dewey Decimal Classification (DDC) and Library of Congress Classification (LCC).",
                "Familiarity with library automation systems, integrated library systems (ILS), and electronic resource management (ERM) systems.",
                "Excellent communication, interpersonal, and customer service skills.",
                "Strong organizational and multitasking abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Flexibility, adaptability, and a passion for lifelong learning."
            ]
        }
    ]
};


export const SpecialEducationTeacherJobDescription = {
    title: "Special Education Teacher Job Description",
    jobRole: "A Special Education Teacher works with students who have a wide range of learning, mental, emotional, and physical disabilities. Special Education Teachers adapt curriculum and teaching methods to meet the individual needs of students with disabilities, providing them with academic instruction, social skills development, and emotional support.",
    preRead: "Use this Special Education Teacher job description template to attract qualified candidates for special education teaching positions in your school or educational institution. Customize the responsibilities and qualifications to fit the specific needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Special Education Teacher, you will create and implement Individualized Education Programs (IEPs) for students with disabilities, collaborate with other educators and support staff, and communicate with parents and caregivers to support students' learning and development. Your role is to provide a supportive and inclusive learning environment where all students can thrive academically, socially, and emotionally."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess students' academic, social, and emotional needs through formal and informal assessments.",
                "Develop and implement Individualized Education Programs (IEPs) tailored to students' unique learning needs and goals.",
                "Adapt curriculum materials, instructional strategies, and classroom activities to accommodate diverse learning styles and abilities.",
                "Provide direct instruction to students in academic subjects, social skills, and behavioral management.",
                "Use specialized teaching techniques and assistive technologies to support students' learning and accessibility.",
                "Monitor and track student progress, adjust instructional strategies as needed, and regularly update IEP goals and objectives.",
                "Collaborate with general education teachers, therapists, and support staff to coordinate services and ensure students' needs are met.",
                "Communicate regularly with parents and caregivers to discuss students' progress, address concerns, and develop strategies for support at home.",
                "Participate in Individualized Education Program (IEP) meetings, team meetings, and professional development activities.",
                "Maintain accurate records and documentation of student progress, behavior, and attendance.",
                "Ensure compliance with federal and state laws and regulations related to special education services and student rights."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Special Education or related field; master's degree and state certification or licensure in Special Education may be required.",
                "Experience working with students with disabilities in an educational setting, preferably as a special education teacher.",
                "Knowledge of special education laws, regulations, and best practices.",
                "Strong understanding of various disabilities and their impact on learning and development.",
                "Effective classroom management and behavior intervention skills.",
                "Ability to differentiate instruction and modify curriculum to meet individual student needs.",
                "Excellent communication and interpersonal skills for working collaboratively with students, parents, and colleagues.",
                "Empathy, patience, and flexibility in addressing students' diverse needs and challenges.",
                "Organizational skills and attention to detail in managing IEP documentation and student records.",
                "Commitment to fostering an inclusive and supportive learning environment for all students."
            ]
        }
    ]
};

export const PrincipalJobDescription = {
    title: "Principal Job Description",
    jobRole: "A Principal is responsible for overseeing the daily operations and administration of a school. They ensure a safe and productive learning environment, manage staff, and develop and implement educational programs.",
    preRead: "Use this Principal job description template to advertise open roles for your school. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Principal, you will be responsible for setting educational goals, managing school staff, and creating a positive learning environment. You will work with teachers, parents, and the community to ensure the academic and social success of students."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement school policies and programs.",
                "Supervise and evaluate staff performance.",
                "Manage school budgets and resources.",
                "Ensure compliance with educational standards and regulations.",
                "Communicate with parents and the community.",
                "Oversee student discipline and welfare.",
                "Promote a safe and inclusive school environment.",
                "Coordinate professional development for staff.",
                "Lead school improvement initiatives.",
                "Prepare reports and presentations for stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Education or related field.",
                "State certification as a school principal.",
                "5+ years of experience in educational leadership.",
                "Strong leadership and organizational skills.",
                "Excellent communication and interpersonal skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to manage budgets and resources effectively.",
                "Proficiency in educational technology."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Principals varies depending on factors such as experience, location, and the size of the school. On average, Principals earn between $90,000 to $130,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const AssistantPrincipalJobDescription = {
    title: "Assistant Principal Job Description",
    jobRole: "An Assistant Principal supports the Principal in the administration and management of a school. They assist with discipline, staff supervision, and the implementation of educational programs.",
    preRead: "Use this Assistant Principal job description template to advertise open roles for your school. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Assistant Principal, you will assist the Principal in managing the daily operations of the school. You will handle student discipline, support teachers, and help implement school policies and programs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist the Principal in developing and implementing school policies.",
                "Supervise and evaluate staff performance.",
                "Manage student discipline and welfare.",
                "Coordinate and support educational programs.",
                "Communicate with parents and the community.",
                "Assist with budgeting and resource management.",
                "Support staff professional development.",
                "Oversee extracurricular activities.",
                "Assist with school improvement initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Education or related field.",
                "State certification as a school administrator.",
                "3+ years of experience in educational leadership.",
                "Strong leadership and organizational skills.",
                "Excellent communication and interpersonal skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to manage budgets and resources effectively.",
                "Proficiency in educational technology."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Assistant Principals varies depending on factors such as experience, location, and the size of the school. On average, Assistant Principals earn between $70,000 to $100,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const SchoolAdministratorJobDescription = {
    title: "School Administrator Job Description",
    jobRole: "A School Administrator oversees the administrative functions of a school, including managing staff, budgets, and student services. They ensure the smooth operation of the school and support the educational mission.",
    preRead: "Use this School Administrator job description template to advertise open roles for your school. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a School Administrator, you will be responsible for managing the administrative tasks of the school. You will work closely with the Principal and other staff to ensure a productive and efficient learning environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage school administrative tasks and operations.",
                "Supervise and support administrative staff.",
                "Oversee budget and resource allocation.",
                "Ensure compliance with school policies and regulations.",
                "Coordinate student services and support programs.",
                "Maintain school records and documentation.",
                "Communicate with parents and community members.",
                "Support school events and activities.",
                "Assist with staff recruitment and training."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Business Administration, or related field.",
                "Experience in school administration or management.",
                "Strong organizational and leadership skills.",
                "Excellent communication and interpersonal skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to manage budgets and resources effectively.",
                "Proficiency in office software and administrative tools."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for School Administrators varies depending on factors such as experience, location, and the size of the school. On average, School Administrators earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const CurriculumDeveloperJobDescription = {
    title: "Curriculum Developer Job Description",
    jobRole: "A Curriculum Developer is responsible for designing, developing, and evaluating educational programs and materials. They work with educators and subject matter experts to create effective learning experiences.",
    preRead: "Use this Curriculum Developer job description template to advertise open roles for your educational institution. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Curriculum Developer, you will design and develop educational programs and materials. You will collaborate with educators to create curricula that meet educational standards and enhance student learning."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and develop educational curricula and materials.",
                "Collaborate with educators and subject matter experts.",
                "Evaluate and revise curricula based on feedback and outcomes.",
                "Ensure curricula align with educational standards and regulations.",
                "Develop assessment tools and resources.",
                "Provide training and support to educators.",
                "Conduct research on educational best practices.",
                "Prepare reports and presentations on curriculum development.",
                "Stay updated on trends in education and curriculum development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Curriculum Development, or related field.",
                "Experience in curriculum development or instructional design.",
                "Strong research and analytical skills.",
                "Excellent communication and collaboration skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to create engaging and effective educational materials.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Curriculum Developers varies depending on factors such as experience, location, and the educational institution. On average, Curriculum Developers earn between $55,000 to $85,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const InstructionalDesignerJobDescription = {
    title: "Instructional Designer Job Description",
    jobRole: "An Instructional Designer is responsible for creating engaging and effective instructional materials for various learning environments. They use instructional design principles and technology to enhance learning outcomes.",
    preRead: "Use this Instructional Designer job description template to advertise open roles for your educational institution. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Instructional Designer, you will create instructional materials and courses that enhance learning experiences. You will work with educators and subject matter experts to design effective and engaging content."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and develop instructional materials and courses.",
                "Collaborate with educators and subject matter experts.",
                "Apply instructional design principles to enhance learning.",
                "Use technology to create interactive and engaging content.",
                "Evaluate and revise instructional materials based on feedback.",
                "Provide training and support to educators.",
                "Conduct research on instructional design best practices.",
                "Prepare reports and presentations on instructional design projects.",
                "Stay updated on trends in instructional design and technology."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Instructional Design, Education, or related field.",
                "Experience in instructional design or educational technology.",
                "Strong design and creativity skills.",
                "Excellent communication and collaboration skills.",
                "Knowledge of instructional design principles and best practices.",
                "Ability to create engaging and effective educational materials.",
                "Proficiency in instructional design software and technology."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Instructional Designers varies depending on factors such as experience, location, and the educational institution. On average, Instructional Designers earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const EducationalConsultantJobDescription = {
    title: "Educational Consultant Job Description",
    jobRole: "An Educational Consultant provides expertise and guidance to educational institutions, organizations, and individuals on educational programs, strategies, and practices. They help improve educational outcomes and solve educational challenges.",
    preRead: "Use this Educational Consultant job description template to advertise open roles for your educational institution or consulting firm. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Educational Consultant, you will provide advice and solutions to educational institutions and organizations. You will analyze educational programs, develop strategies for improvement, and support the implementation of best practices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze educational programs and practices.",
                "Provide recommendations for improvement.",
                "Develop and implement educational strategies.",
                "Conduct research on educational trends and best practices.",
                "Collaborate with educators and administrators.",
                "Provide training and support to staff.",
                "Evaluate the effectiveness of educational programs.",
                "Prepare reports and presentations for clients.",
                "Stay updated on trends in education and consulting."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Education or related field.",
                "Experience in educational consulting or administration.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and presentation skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to develop and implement effective strategies.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Educational Consultants varies depending on factors such as experience, location, and the client base. On average, Educational Consultants earn between $70,000 to $110,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const SchoolPsychologistJobDescription = {
    title: "School Psychologist Job Description",
    jobRole: "A School Psychologist supports students' academic and emotional well-being by providing psychological services, counseling, and assessments. They work with educators and parents to develop strategies to improve student outcomes.",
    preRead: "Use this School Psychologist job description template to advertise open roles for your school. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a School Psychologist, you will provide psychological services to support students' academic and emotional development. You will conduct assessments, offer counseling, and collaborate with educators and parents to create supportive learning environments."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct psychological assessments and evaluations.",
                "Provide individual and group counseling to students.",
                "Develop and implement intervention strategies.",
                "Collaborate with educators and parents on student support plans.",
                "Address behavioral and emotional issues.",
                "Support students with special needs.",
                "Conduct research on psychological and educational issues.",
                "Prepare reports and documentation.",
                "Stay updated on trends in school psychology and education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Psychology or related field.",
                "State certification or licensure as a School Psychologist.",
                "Experience in school psychology or counseling.",
                "Strong assessment and diagnostic skills.",
                "Excellent communication and collaboration skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to develop and implement effective interventions.",
                "Proficiency in psychological assessment tools and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for School Psychologists varies depending on factors such as experience, location, and the size of the school. On average, School Psychologists earn between $65,000 to $95,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const EducationSpecialistJobDescription = {
    title: "Education Specialist Job Description",
    jobRole: "An Education Specialist provides expertise in specific educational areas, supporting curriculum development, teacher training, and student assessment. They work to improve educational programs and outcomes.",
    preRead: "Use this Education Specialist job description template to advertise open roles for your educational institution. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Education Specialist, you will provide expertise in your specific educational area. You will support curriculum development, teacher training, and student assessment to enhance educational programs and outcomes."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement specialized curricula.",
                "Conduct teacher training and professional development.",
                "Support student assessment and evaluation.",
                "Collaborate with educators on instructional strategies.",
                "Conduct research on educational best practices.",
                "Provide expertise in your specific educational area.",
                "Prepare reports and presentations.",
                "Stay updated on trends in education and your specialty."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Education or related field.",
                "Experience in your specific educational area.",
                "Strong knowledge of educational standards and best practices.",
                "Excellent communication and collaboration skills.",
                "Ability to develop and implement effective educational programs.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Education Specialists varies depending on factors such as experience, location, and the educational institution. On average, Education Specialists earn between $60,000 to $90,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const GuidanceCounselorJobDescription = {
    title: "Guidance Counselor Job Description",
    jobRole: "A Guidance Counselor provides academic, career, and personal counseling to students. They support students in their educational and career planning, as well as their personal development.",
    preRead: "Use this Guidance Counselor job description template to advertise open roles for your school. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Guidance Counselor, you will provide counseling services to support students' academic, career, and personal development. You will assist students with educational planning, career choices, and personal issues."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide individual and group counseling to students.",
                "Assist students with academic and career planning.",
                "Support students' personal development.",
                "Conduct assessments and evaluations.",
                "Collaborate with educators and parents on student support plans.",
                "Develop and implement intervention strategies.",
                "Provide crisis counseling and support.",
                "Prepare reports and documentation.",
                "Stay updated on trends in counseling and education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Counseling or related field.",
                "State certification or licensure as a Guidance Counselor.",
                "Experience in school counseling or related field.",
                "Strong counseling and assessment skills.",
                "Excellent communication and collaboration skills.",
                "Knowledge of educational standards and regulations.",
                "Ability to develop and implement effective interventions.",
                "Proficiency in counseling tools and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Guidance Counselors varies depending on factors such as experience, location, and the size of the school. On average, Guidance Counselors earn between $50,000 to $80,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const CareerCounselorJobDescription = {
    title: "Career Counselor Job Description",
    jobRole: "A Career Counselor assists students and clients in their career development and planning. They provide career assessments, advice, and resources to help individuals make informed career choices.",
    preRead: "Use this Career Counselor job description template to advertise open roles for your educational institution or career center. Be sure to modify requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Career Counselor, you will provide career counseling services to students and clients. You will help individuals assess their career interests, skills, and goals, and provide resources and advice for career planning."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide individual and group career counseling.",
                "Conduct career assessments and evaluations.",
                "Assist clients with career planning and decision-making.",
                "Provide information on career options and job market trends.",
                "Develop and deliver career development workshops.",
                "Collaborate with educators and employers.",
                "Maintain client records and documentation.",
                "Prepare reports and presentations.",
                "Stay updated on trends in career counseling and employment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Counseling, Career Development, or related field.",
                "State certification or licensure as a Career Counselor.",
                "Experience in career counseling or related field.",
                "Strong assessment and counseling skills.",
                "Excellent communication and interpersonal skills.",
                "Knowledge of career development theories and practices.",
                "Ability to develop and implement effective career interventions.",
                "Proficiency in career counseling tools and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary range for Career Counselors varies depending on factors such as experience, location, and the client base. On average, Career Counselors earn between $50,000 to $75,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Employee wellness programs",
                "Performance bonuses"
            ]
        }
    ]
};

export const AdmissionsCounselorJobDescription = {
    title: "Admissions Counselor Job Description",
    jobRole: "An Admissions Counselor is responsible for assisting prospective students through the admissions process, including providing information about programs, helping with application completion, and evaluating applications.",
    preRead: "Use this Admissions Counselor job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Admissions Counselor, you will engage with potential students, guiding them through the admissions process and helping them understand the benefits of the institution’s programs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide information about academic programs and admissions requirements.",
                "Assist prospective students in completing their applications.",
                "Evaluate student applications and make admissions decisions.",
                "Attend recruitment events and represent the institution.",
                "Maintain accurate records of student interactions.",
                "Collaborate with other departments to ensure a smooth admissions process.",
                "Stay updated on educational trends and admissions standards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Marketing, or a related field.",
                "Previous experience in admissions or student recruitment.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational skills and attention to detail.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and admissions software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Admissions Counselors ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const AcademicAdvisorJobDescription = {
    title: "Academic Advisor Job Description",
    jobRole: "An Academic Advisor assists students in developing educational plans that align with their academic and career goals. They provide guidance on course selection, academic policies, and career planning.",
    preRead: "Use this Academic Advisor job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Academic Advisor, you will support students in achieving their academic objectives by providing personalized advice and resources."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist students in course selection and registration.",
                "Provide information on academic policies and procedures.",
                "Monitor student progress and provide academic support.",
                "Develop educational plans that align with student goals.",
                "Offer career advice and help students plan for their future.",
                "Maintain accurate records of student interactions.",
                "Stay updated on academic requirements and career trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Counseling, or a related field.",
                "Experience in academic advising or a related role.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and collaboratively.",
                "Proficiency in Microsoft Office and student information systems."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Academic Advisors ranges from $45,000 to $65,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const StudentAffairsCoordinatorJobDescription = {
    title: "Student Affairs Coordinator Job Description",
    jobRole: "A Student Affairs Coordinator supports the development and implementation of student programs and services. They focus on enhancing student life, promoting student engagement, and ensuring a positive campus experience.",
    preRead: "Use this Student Affairs Coordinator job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Student Affairs Coordinator, you will facilitate programs and services that support student engagement, development, and success."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement student programs and events.",
                "Provide support and resources for student organizations.",
                "Promote student engagement and leadership development.",
                "Collaborate with campus departments to enhance student life.",
                "Address student concerns and provide guidance.",
                "Maintain accurate records and reports on student activities.",
                "Stay updated on trends in student affairs and campus life."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Student Affairs, or a related field.",
                "Experience in student affairs or campus activities.",
                "Strong organizational and project management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and student information systems."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Student Affairs Coordinators ranges from $45,000 to $70,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const RegistrarJobDescription = {
    title: "Registrar Job Description",
    jobRole: "A Registrar manages student records and registration processes. They ensure the accuracy and confidentiality of academic records and oversee the enrollment and graduation processes.",
    preRead: "Use this Registrar job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Registrar, you will oversee student registration, maintain academic records, and ensure compliance with institutional policies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage student registration and enrollment processes.",
                "Maintain accurate and confidential academic records.",
                "Oversee the graduation application process.",
                "Ensure compliance with institutional policies and regulations.",
                "Provide information and support to students, faculty, and staff.",
                "Prepare reports and analysis of student data.",
                "Stay updated on trends in student records management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Business Administration, or a related field.",
                "Experience in student records management or a related role.",
                "Strong organizational and data management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and student information systems."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Registrars ranges from $50,000 to $75,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const EnrollmentSpecialistJobDescription = {
    title: "Enrollment Specialist Job Description",
    jobRole: "An Enrollment Specialist assists students through the enrollment process, including application submission, document collection, and enrollment verification. They provide information and support to ensure a smooth transition for new students.",
    preRead: "Use this Enrollment Specialist job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Enrollment Specialist, you will guide prospective students through the enrollment process, ensuring all required documents are submitted and verified."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist students with the application and enrollment process.",
                "Collect and verify required enrollment documents.",
                "Provide information on programs, policies, and procedures.",
                "Maintain accurate records of student enrollment.",
                "Coordinate with other departments to ensure a smooth enrollment process.",
                "Answer inquiries from prospective students and parents.",
                "Stay updated on enrollment policies and trends."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Business Administration, or a related field.",
                "Experience in admissions or enrollment management.",
                "Strong organizational and data management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and enrollment management systems."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Enrollment Specialists ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const EducationProgramManagerJobDescription = {
    title: "Education Program Manager Job Description",
    jobRole: "An Education Program Manager oversees the development and implementation of educational programs. They work with educators and stakeholders to ensure programs meet academic standards and student needs.",
    preRead: "Use this Education Program Manager job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Education Program Manager, you will lead the development and execution of educational programs, ensuring they align with institutional goals and student needs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement educational programs.",
                "Collaborate with educators and stakeholders to design program curriculum.",
                "Monitor program effectiveness and make improvements as needed.",
                "Manage program budgets and resources.",
                "Coordinate program activities and events.",
                "Provide support and guidance to program staff and participants.",
                "Stay updated on educational trends and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master’s degree in Education, Program Management, or a related field.",
                "Experience in program management or educational administration.",
                "Strong leadership and organizational skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and project management software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Education Program Managers ranges from $60,000 to $90,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const EducationalTechnologistJobDescription = {
    title: "Educational Technologist Job Description",
    jobRole: "An Educational Technologist integrates technology into educational settings to enhance teaching and learning. They provide training and support to educators on the use of educational technologies.",
    preRead: "Use this Educational Technologist job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Educational Technologist, you will support the integration of technology in educational settings, providing training and resources to educators."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Integrate technology into educational programs.",
                "Provide training and support to educators on educational technologies.",
                "Develop and implement technology-enhanced instructional materials.",
                "Evaluate the effectiveness of educational technologies.",
                "Stay updated on trends in educational technology.",
                "Collaborate with educators to identify technology needs.",
                "Maintain and troubleshoot educational technology systems."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Educational Technology, Instructional Design, or a related field.",
                "Experience in educational technology or instructional design.",
                "Strong technical and troubleshooting skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology tools and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Educational Technologists ranges from $50,000 to $75,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const LearningSupportSpecialistJobDescription = {
    title: "Learning Support Specialist Job Description",
    jobRole: "A Learning Support Specialist provides academic support to students with learning difficulties. They work with students to develop learning strategies and provide resources to enhance academic success.",
    preRead: "Use this Learning Support Specialist job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Learning Support Specialist, you will assist students with learning difficulties by providing academic support and resources to help them succeed."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide academic support to students with learning difficulties.",
                "Develop and implement individualized learning plans.",
                "Collaborate with educators to identify student needs.",
                "Provide training and resources to support student learning.",
                "Monitor student progress and adjust support as needed.",
                "Maintain accurate records of student interactions.",
                "Stay updated on best practices in learning support."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Special Education, or a related field.",
                "Experience in learning support or special education.",
                "Strong organizational and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in Microsoft Office and educational support software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Learning Support Specialists ranges from $45,000 to $65,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const ESLTeacherJobDescription = {
    title: "ESL Teacher Job Description",
    jobRole: "An ESL (English as a Second Language) Teacher helps non-native speakers learn English. They design lesson plans, teach English language skills, and assess student progress.",
    preRead: "Use this ESL Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an ESL Teacher, you will help non-native speakers improve their English language skills through structured lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and implement lesson plans for English language learners.",
                "Teach English language skills, including speaking, reading, writing, and listening.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in ESL education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, English, or a related field.",
                "TESOL/TEFL certification or equivalent.",
                "Experience in teaching English to non-native speakers.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for ESL Teachers ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const LanguageArtsTeacherJobDescription = {
    title: "Language Arts Teacher Job Description",
    jobRole: "A Language Arts Teacher educates students in reading, writing, and communication skills. They develop lesson plans, teach various aspects of language arts, and assess student progress.",
    preRead: "Use this Language Arts Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Language Arts Teacher, you will help students develop reading, writing, and communication skills through engaging lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for language arts.",
                "Teach reading, writing, and communication skills.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in language arts education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, English, or a related field.",
                "Teaching certification in Language Arts.",
                "Experience in teaching language arts at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Language Arts Teachers ranges from $45,000 to $65,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const MathematicsTeacherJobDescription = {
    title: "Mathematics Teacher Job Description",
    jobRole: "A Mathematics Teacher educates students in mathematical concepts and problem-solving skills. They develop lesson plans, teach various levels of math, and assess student progress.",
    preRead: "Use this Mathematics Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mathematics Teacher, you will help students understand mathematical concepts and develop problem-solving skills through engaging lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for mathematics.",
                "Teach mathematical concepts and problem-solving skills.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in mathematics education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Mathematics, or a related field.",
                "Teaching certification in Mathematics.",
                "Experience in teaching mathematics at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Mathematics Teachers ranges from $50,000 to $70,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const ScienceTeacherJobDescription = {
    title: "Science Teacher Job Description",
    jobRole: "A Science Teacher educates students in various scientific disciplines, such as biology, chemistry, and physics. They develop lesson plans, conduct experiments, and assess student progress.",
    preRead: "Use this Science Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Science Teacher, you will help students understand scientific concepts and conduct experiments to enhance their learning experience."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for science.",
                "Teach scientific concepts and conduct experiments.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in science education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Science, or a related field.",
                "Teaching certification in Science.",
                "Experience in teaching science at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Science Teachers ranges from $50,000 to $70,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const SocialStudiesTeacherJobDescription = {
    title: "Social Studies Teacher Job Description",
    jobRole: "A Social Studies Teacher educates students in history, geography, civics, and other social sciences. They develop lesson plans, engage students in discussions, and assess their understanding of social studies concepts.",
    preRead: "Use this Social Studies Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Social Studies Teacher, you will help students understand social studies concepts and develop critical thinking skills through engaging lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for social studies.",
                "Teach history, geography, civics, and other social sciences.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in social studies education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, History, or a related field.",
                "Teaching certification in Social Studies.",
                "Experience in teaching social studies at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Social Studies Teachers ranges from $45,000 to $65,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const PhysicalEducationTeacherJobDescription = {
    title: "Physical Education Teacher Job Description",
    jobRole: "A Physical Education (PE) Teacher promotes physical activity and health among students. They develop lesson plans, teach various sports and fitness activities, and assess student performance.",
    preRead: "Use this Physical Education Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Physical Education Teacher, you will help students develop physical fitness and promote a healthy lifestyle through engaging physical activities and sports."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for physical education.",
                "Teach various sports and fitness activities.",
                "Assess student performance and provide feedback.",
                "Promote physical activity and healthy habits.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in physical education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Physical Education, or a related field.",
                "Teaching certification in Physical Education.",
                "Experience in teaching physical education at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Physical Education Teachers ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const ArtTeacherJobDescription = {
    title: "Art Teacher Job Description",
    jobRole: "An Art Teacher educates students in various forms of visual arts, including drawing, painting, and sculpture. They develop lesson plans, teach artistic techniques, and assess student progress.",
    preRead: "Use this Art Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Art Teacher, you will help students explore and develop their artistic skills through creative projects and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for art classes.",
                "Teach various artistic techniques and mediums.",
                "Assess student progress and provide feedback.",
                "Encourage creativity and self-expression among students.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in art education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Fine Arts, or a related field.",
                "Teaching certification in Art.",
                "Experience in teaching art at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in artistic techniques and materials."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Art Teachers ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const MusicTeacherJobDescription = {
    title: "Music Teacher Job Description",
    jobRole: "A Music Teacher educates students in various aspects of music, including theory, performance, and history. They develop lesson plans, teach musical techniques, and assess student progress.",
    preRead: "Use this Music Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Music Teacher, you will help students develop their musical skills and appreciation through engaging lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for music classes.",
                "Teach music theory, performance, and history.",
                "Assess student progress and provide feedback.",
                "Encourage musical creativity and expression among students.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in music education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Music, or a related field.",
                "Teaching certification in Music.",
                "Experience in teaching music at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in musical techniques and instruments."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Music Teachers ranges from $40,000 to $60,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const ForeignLanguageTeacherJobDescription = {
    title: "Foreign Language Teacher Job Description",
    jobRole: "A Foreign Language Teacher educates students in a specific foreign language, focusing on speaking, reading, writing, and listening skills. They develop lesson plans, teach language skills, and assess student progress.",
    preRead: "Use this Foreign Language Teacher job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Foreign Language Teacher, you will help students develop proficiency in a foreign language through engaging lessons and activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for foreign language classes.",
                "Teach language skills, including speaking, reading, writing, and listening.",
                "Assess student progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with other educators to support student learning.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in foreign language education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Foreign Language, or a related field.",
                "Teaching certification in the specific foreign language.",
                "Experience in teaching the foreign language at the appropriate grade level.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in the foreign language and cultural understanding."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Foreign Language Teachers ranges from $45,000 to $65,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const EarlyChildhoodEducatorJobDescription = {
    title: "Early Childhood Educator Job Description",
    jobRole: "An Early Childhood Educator works with children from birth to age 8, fostering their social, emotional, cognitive, and physical development. They create lesson plans, engage children in activities, and assess developmental progress.",
    preRead: "Use this Early Childhood Educator job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Early Childhood Educator, you will nurture young children’s development through engaging activities and structured learning experiences."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Create and implement lesson plans for young children.",
                "Engage children in activities to promote social, emotional, cognitive, and physical development.",
                "Assess children’s developmental progress and provide feedback.",
                "Create a positive and inclusive learning environment.",
                "Collaborate with parents and other educators to support child development.",
                "Maintain accurate records of children’s progress.",
                "Stay updated on best practices in early childhood education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s or Bachelor’s degree in Early Childhood Education or a related field.",
                "Experience in early childhood education.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Early Childhood Educators ranges from $30,000 to $50,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};


export const TeachingAssistantJobDescription = {
    title: "Teaching Assistant Job Description",
    jobRole: "A Teaching Assistant supports classroom teachers in delivering lessons, managing student behavior, and providing individualized instruction. They assist with grading, prepare materials, and help maintain a positive learning environment.",
    preRead: "Use this Teaching Assistant job description template to advertise open roles for your company. Modify the requirements and duties based on the unique needs of the role you’re hiring for.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Teaching Assistant, you will support classroom teachers in delivering lessons and managing the classroom to create a positive learning environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist teachers in delivering lessons and instructional activities.",
                "Help manage student behavior and maintain classroom discipline.",
                "Provide individualized instruction and support to students.",
                "Assist with grading and assessment of student work.",
                "Prepare and organize classroom materials and resources.",
                "Support students with special needs as required.",
                "Maintain accurate records of student progress.",
                "Stay updated on best practices in education and classroom management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate’s or Bachelor’s degree in Education or a related field.",
                "Experience working with children in an educational setting.",
                "Strong communication and interpersonal skills.",
                "Ability to work independently and as part of a team.",
                "Proficiency in educational technology and classroom management software."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Teaching Assistants ranges from $25,000 to $35,000 per year, depending on experience and location."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "401(k) retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Flexible work schedule"
            ]
        }
    ]
};

export const DramaTeacherJobDescription = {
    title: "Drama Teacher Job Description",
    jobRole: "A Drama Teacher is responsible for teaching students about various aspects of drama and theater, including acting techniques, script analysis, and stage production. They develop lesson plans, organize performances, and provide guidance to students interested in pursuing careers in theater.",
    preRead: "Use this Drama Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Drama Teacher, you will instruct students in the principles of drama and theater, help them develop performance skills, and organize productions and performances."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans for drama classes.",
                "Teach acting techniques, stagecraft, and script analysis.",
                "Organize and supervise rehearsals and performances.",
                "Provide constructive feedback to students on their performances.",
                "Encourage students to explore their creativity and express themselves through drama.",
                "Collaborate with other teachers and staff on school productions and events.",
                "Participate in faculty meetings and professional development activities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Theater Arts, Drama, or a related field (Master’s degree preferred).",
                "Teaching certification or licensure.",
                "Experience in theater production or performance.",
                "Strong communication and interpersonal skills.",
                "Creativity and enthusiasm for teaching drama.",
                "Ability to work collaboratively with students and colleagues."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Drama Teachers varies depending on factors such as experience, location, and the educational institution. On average, Drama Teachers earn between $40,000 to $60,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Discounted or free tickets to performances"
            ]
        }
    ]
};

export const ComputerScienceTeacherJobDescription = {
    title: "Computer Science Teacher Job Description",
    jobRole: "A Computer Science Teacher educates students on computer science concepts, programming languages, and software development. They design curriculum, teach classes, and provide guidance to students interested in pursuing careers in technology.",
    preRead: "Use this Computer Science Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Computer Science Teacher, you will instruct students in computer science principles, programming languages, and software development, preparing them for careers in technology."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement curriculum for computer science courses.",
                "Teach programming languages, algorithms, and computer systems.",
                "Provide hands-on instruction and assignments to reinforce learning.",
                "Guide students in developing software projects and applications.",
                "Assess student progress and provide feedback on assignments and projects.",
                "Stay updated on advancements in computer science and technology.",
                "Participate in department meetings and professional development activities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Computer Science, Information Technology, or a related field (Master’s degree preferred).",
                "Teaching certification or licensure.",
                "Experience in software development or computer programming.",
                "Strong knowledge of programming languages such as Java, Python, or C++.",
                "Excellent communication and presentation skills.",
                "Ability to engage and motivate students in learning."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Computer Science Teachers varies depending on factors such as experience, location, and the educational institution. On average, Computer Science Teachers earn between $50,000 to $80,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Access to technology resources and training"
            ]
        }
    ]
};


export const HealthTeacherJobDescription = {
    title: "Health Teacher Job Description",
    jobRole: "A Health Teacher educates students on topics related to health and wellness, including nutrition, fitness, and personal hygiene. They develop curriculum, teach classes, and promote healthy behaviors among students.",
    preRead: "Use this Health Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Health Teacher, you will instruct students in health-related topics, promote healthy behaviors, and empower students to make informed decisions about their well-being."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement curriculum for health education courses.",
                "Teach topics such as nutrition, fitness, mental health, and substance abuse prevention.",
                "Facilitate discussions and activities to engage students in learning.",
                "Promote healthy behaviors and positive decision-making among students.",
                "Provide resources and support to students with health-related concerns.",
                "Collaborate with school staff and community organizations on health initiatives.",
                "Stay updated on current health trends and research."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Health Education, Public Health, or a related field (Master’s degree preferred).",
                "Teaching certification or licensure.",
                "Experience in health education or related field.",
                "Knowledge of health-related topics and issues.",
                "Effective communication and interpersonal skills.",
                "Passion for promoting health and wellness.",
                "Ability to create engaging and informative lesson plans."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Health Teachers varies depending on factors such as experience, location, and the educational institution. On average, Health Teachers earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Access to wellness resources"
            ]
        }
    ]
};

export const SpecialtySubjectTeacherJobDescription = {
    title: "Specialty Subject Teacher Job Description",
    jobRole: "A Specialty Subject Teacher specializes in teaching a specific subject area, such as art, music, physical education, or computer science. They develop curriculum, instruct classes, and foster students' skills and creativity in their chosen field.",
    preRead: "Use this Specialty Subject Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Specialty Subject Teacher, you will provide specialized instruction in your chosen subject area, engaging students in creative and hands-on learning experiences."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and deliver curriculum tailored to the specific subject area.",
                "Instruct students in fundamental concepts, techniques, and skills.",
                "Foster students' creativity and critical thinking through hands-on projects and activities.",
                "Organize and oversee performances, exhibitions, or competitions related to the subject area.",
                "Provide constructive feedback and guidance to students on their work.",
                "Collaborate with other teachers and staff to integrate the subject area into broader educational initiatives.",
                "Stay updated on developments and best practices in the subject area."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in the relevant subject area (Master’s degree preferred).",
                "Teaching certification or licensure.",
                "Experience in teaching or working in the subject area.",
                "Passion for the subject and enthusiasm for sharing knowledge with students.",
                "Strong communication and interpersonal skills.",
                "Ability to inspire and motivate students in their learning.",
                "Creativity and flexibility in lesson planning and instruction."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Specialty Subject Teachers varies depending on factors such as experience, location, and the educational institution. On average, Specialty Subject Teachers earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Access to resources and materials for the subject area"
            ]
        }
    ]
};


export const ElementarySchoolTeacherJobDescription = {
    title: "Elementary School Teacher Job Description",
    jobRole: "An Elementary School Teacher is responsible for teaching students in grades kindergarten through fifth or sixth grade. They create engaging lesson plans, assess student progress, and provide a supportive learning environment to help students achieve academic success and personal growth.",
    preRead: "Use this Elementary School Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Elementary School Teacher, you will have the opportunity to make a significant impact on students' lives by fostering their academic, social, and emotional development. You will create a positive and inclusive classroom environment where all students feel valued and supported."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement lesson plans that align with curriculum standards and meet the diverse needs of students.",
                "Differentiate instruction to accommodate students with varying learning styles, abilities, and backgrounds.",
                "Assess student progress and provide feedback to students, parents, and administrators.",
                "Establish and maintain a positive and supportive classroom climate conducive to learning.",
                "Promote a culture of respect, kindness, and inclusivity among students.",
                "Collaborate with colleagues, parents, and other stakeholders to support student success.",
                "Participate in professional development activities to enhance teaching skills and knowledge.",
                "Ensure the health, safety, and well-being of all students in your care."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Elementary Education or a related field (Master’s degree may be preferred for some positions).",
                "State teaching certification or licensure.",
                "Experience working with elementary-aged students in a school setting.",
                "Knowledge of elementary education curriculum standards and instructional strategies.",
                "Strong classroom management and behavior management skills.",
                "Effective communication and interpersonal skills.",
                "Patience, flexibility, and a passion for working with children.",
                "Commitment to ongoing professional growth and development."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Elementary School Teachers varies depending on factors such as experience, location, and the school district. On average, Elementary School Teachers earn between $40,000 to $60,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Childcare discounts or subsidies",
                "Access to resources and materials for teaching",
                "Summer vacation"
            ]
        }
    ]
};

export const MiddleSchoolTeacherJobDescription = {
    title: "Middle School Teacher Job Description",
    jobRole: "A Middle School Teacher is responsible for teaching students in grades six through eight, typically covering a range of subjects within a specific content area. They create engaging lesson plans, assess student progress, and provide guidance and support to help students succeed academically and personally.",
    preRead: "Use this Middle School Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Middle School Teacher, you will play a critical role in guiding students through a crucial stage of their academic and personal development. You will create a challenging and supportive learning environment where students can explore new ideas, develop critical thinking skills, and build confidence in themselves."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement engaging lesson plans that align with curriculum standards and meet the diverse needs of middle school students.",
                "Utilize a variety of instructional strategies and resources to actively engage students in learning.",
                "Assess student progress through ongoing formative and summative assessments, and provide timely feedback to students and parents.",
                "Establish and maintain a positive classroom culture that promotes respect, collaboration, and inclusivity.",
                "Provide guidance and support to students as they navigate academic challenges, social interactions, and personal growth.",
                "Collaborate with colleagues, parents, and other stakeholders to support student success and well-being.",
                "Participate in professional development activities to enhance teaching skills and knowledge.",
                "Ensure a safe and supportive learning environment for all students."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education or a related field (Master’s degree may be preferred for some positions).",
                "State teaching certification or licensure for the appropriate grade level and subject area.",
                "Experience working with middle school-aged students in an educational setting.",
                "Knowledge of middle school curriculum standards, instructional strategies, and assessment practices.",
                "Strong classroom management and behavior management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to build positive relationships with students, parents, and colleagues.",
                "Commitment to fostering a supportive and inclusive learning environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Middle School Teachers varies depending on factors such as experience, location, and the school district. On average, Middle School Teachers earn between $45,000 to $65,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Childcare discounts or subsidies",
                "Access to resources and materials for teaching",
                "Summer vacation"
            ]
        }
    ]
};

export const HighSchoolTeacherJobDescription = {
    title: "High School Teacher Job Description",
    jobRole: "A High School Teacher is responsible for teaching students in grades nine through twelve, typically specializing in one or more subject areas. They create engaging lesson plans, assess student progress, and provide academic and emotional support to help students succeed in high school and beyond.",
    preRead: "Use this High School Teacher job description template to advertise open roles for your school. Modify the requirements and duties based on the unique needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a High School Teacher, you will have the opportunity to inspire and empower students as they prepare for college, careers, and adulthood. You will create a rigorous and supportive learning environment where students can develop critical thinking skills, explore their interests, and achieve academic excellence."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement engaging lesson plans that align with curriculum standards and meet the diverse needs of high school students.",
                "Differentiate instruction to accommodate students with varying learning styles, abilities, and interests.",
                "Assess student progress through a variety of formative and summative assessments, and provide constructive feedback to students and parents.",
                "Provide academic and emotional support to students as they navigate the challenges of high school and prepare for post-secondary education or careers.",
                "Establish and maintain a positive and inclusive classroom culture that promotes respect, collaboration, and lifelong learning.",
                "Collaborate with colleagues, parents, and other stakeholders to support student success and well-being.",
                "Participate in professional development activities to enhance teaching skills and stay current with best practices in education.",
                "Ensure a safe and supportive learning environment for all students."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education or a related field (Master’s degree may be preferred for some positions).",
                "State teaching certification or licensure for the appropriate grade level and subject area.",
                "Experience working with high school-aged students in an educational setting.",
                "Knowledge of high school curriculum standards, instructional strategies, and assessment practices.",
                "Strong classroom management and behavior management skills.",
                "Effective communication and interpersonal skills.",
                "Ability to build positive relationships with students, parents, and colleagues.",
                "Commitment to fostering a supportive and inclusive learning environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for High School Teachers varies depending on factors such as experience, location, and the school district. On average, High School Teachers earn between $50,000 to $70,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Professional development opportunities",
                "Tuition reimbursement",
                "Childcare discounts or subsidies",
                "Access to resources and materials for teaching",
                "Summer vacation"
            ]
        }
    ]
};

export const OnlineInstructorJobDescription = {
    title: "Online Instructor Job Description",
    jobRole: "An Online Instructor is responsible for facilitating online courses and virtual learning experiences for students of various ages and backgrounds. They design and deliver engaging online lessons, provide feedback and support to students, and foster a collaborative and interactive online learning community.",
    preRead: "Use this Online Instructor job description template to advertise open roles for your institution. Modify the requirements and duties based on the unique needs of your educational program.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Online Instructor, you will have the opportunity to leverage technology and innovation to deliver high-quality education to students regardless of geographical location or physical limitations. You will create dynamic and interactive online learning experiences that inspire curiosity, promote critical thinking, and facilitate meaningful student engagement."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and develop online course materials, including lectures, assignments, assessments, and multimedia resources.",
                "Facilitate live and asynchronous online class sessions, engaging students in active learning activities and discussions.",
                "Provide timely and constructive feedback to students on their coursework, progress, and performance.",
                "Monitor student participation and progress, and intervene as needed to support student success.",
                "Facilitate online discussions and collaborative activities to promote peer interaction and knowledge sharing.",
                "Support students in navigating the online learning platform and accessing resources and support services.",
                "Maintain regular communication with students, parents, and colleagues to ensure a positive and supportive online learning experience.",
                "Participate in professional development activities to enhance online teaching skills and stay current with best practices in online education."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Instructional Design, or a related field (Master’s degree may be preferred for some positions).",
                "Experience teaching or facilitating online courses or virtual learning environments.",
                "Proficiency in online teaching platforms and educational technology tools.",
                "Strong communication and interpersonal skills, with the ability to build rapport and connect with students online.",
                "Knowledge of online pedagogy, instructional design principles, and best practices in online teaching and learning.",
                "Ability to adapt instructional strategies and materials to meet the diverse needs of online learners.",
                "Commitment to fostering a supportive and inclusive online learning environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Online Instructors varies depending on factors such as experience, qualifications, and the institution. On average, Online Instructors earn between $40,000 to $70,000 per year."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Flexible work schedule",
                "Professional development opportunities",
                "Remote work options",
                "Health insurance",
                "Retirement plan",
                "Paid time off",
                "Access to educational resources and tools"
            ]
        }
    ]
};

export const TutorJobDescription = {
    title: "Tutor Job Description",
    jobRole: "A Tutor provides one-on-one or small group academic support and instruction to students in various subjects. They help students develop study skills, clarify concepts, review materials, and prepare for exams or assessments.",
    preRead: "Use this Tutor job description template to advertise open positions for tutoring services. Customize the requirements and responsibilities based on the specific needs of your tutoring program.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Tutor, you will play a crucial role in helping students achieve their academic goals and build confidence in their abilities. Whether working with struggling students who need remedial support or high-achieving students seeking enrichment, you will provide personalized instruction tailored to each student's needs and learning style."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide individualized academic support and instruction to students in specific subjects or areas of need.",
                "Assess students' strengths and weaknesses and develop customized learning plans to address their learning goals and objectives.",
                "Review class materials, textbooks, and assignments with students to reinforce understanding and mastery of concepts.",
                "Help students develop effective study habits, organizational skills, and time management strategies.",
                "Create and administer practice quizzes, tests, or exercises to assess student progress and identify areas for improvement.",
                "Offer constructive feedback and guidance to students to enhance their academic performance and self-confidence.",
                "Communicate regularly with parents, teachers, or other stakeholders to provide progress updates and coordinate support efforts.",
                "Stay informed about curriculum standards, educational trends, and best practices in tutoring and academic support."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, related field, or equivalent experience (Master’s degree may be preferred for specialized subjects).",
                "Experience working as a tutor, teacher, or academic coach preferred.",
                "Strong knowledge and expertise in the subject area(s) being tutored.",
                "Excellent communication and interpersonal skills, with the ability to establish rapport and build trust with students.",
                "Patience, empathy, and the ability to motivate and inspire students to succeed.",
                "Flexibility and adaptability to work with students of diverse backgrounds, learning styles, and abilities.",
                "Ability to assess student needs, set goals, and develop individualized learning plans.",
                "Passion for education and a commitment to helping students achieve their full potential."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Tutors can vary depending on factors such as experience, qualifications, location, and the demand for tutoring services. On average, Tutors can earn between $20 to $50 per hour."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Flexible work schedule",
                "Opportunity to make a positive impact on students' academic success",
                "Professional development and training opportunities",
                "Ability to work remotely or in-person",
                "Potential for growth and advancement in the tutoring field"
            ]
        }
    ]
};


export const EducationResearcherJobDescription = {
    title: "Education Researcher Job Description",
    jobRole: "An Education Researcher conducts research studies and analyses related to various aspects of education, including teaching and learning practices, curriculum development, educational policies, and student outcomes. They collect and analyze data, generate insights and recommendations, and contribute to the advancement of educational theory and practice.",
    preRead: "Use this Education Researcher job description template to recruit qualified candidates for research positions in the field of education. Customize the requirements and responsibilities based on the specific focus areas and research needs of your organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Education Researcher, you will have the opportunity to contribute to the improvement of education systems and practices through rigorous research and analysis. Whether investigating the effectiveness of instructional strategies, evaluating educational interventions, or studying the impact of policy reforms, you will play a vital role in shaping the future of education."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and conduct research studies and evaluations related to education policies, programs, and practices.",
                "Develop research proposals, study designs, and data collection instruments (e.g., surveys, interviews, observation protocols).",
                "Collect, compile, and analyze quantitative and qualitative data using appropriate research methods and statistical techniques.",
                "Interpret research findings, draw conclusions, and generate actionable insights and recommendations.",
                "Prepare research reports, scholarly articles, and presentations for dissemination to academic audiences, policymakers, and practitioners.",
                "Collaborate with interdisciplinary teams of researchers, educators, policymakers, and stakeholders to address complex educational challenges.",
                "Stay current with developments in educational research, theory, and practice through literature reviews, conferences, and professional development activities.",
                "Contribute to the development of research agendas, grant proposals, and funding applications to support ongoing research initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's or doctoral degree in Education, Educational Research, Psychology, Sociology, or related field.",
                "Experience conducting educational research studies, including study design, data collection, and analysis.",
                "Proficiency in research methods, statistical analysis software (e.g., SPSS, R), and qualitative data analysis techniques.",
                "Strong analytical and critical thinking skills, with the ability to interpret complex data and draw valid conclusions.",
                "Excellent written and verbal communication skills, with the ability to communicate research findings to diverse audiences.",
                "Ability to work independently and collaboratively in a fast-paced research environment, managing multiple projects simultaneously.",
                "Commitment to ethical research practices, including participant confidentiality, informed consent, and data integrity.",
                "Demonstrated passion for education and a desire to contribute to positive social change through research."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Education Researchers can vary depending on factors such as experience, education level, location, and the type of organization. On average, Education Researchers can earn between $60,000 to $100,000 per year, with potential for higher earnings in senior-level positions or with extensive experience."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Flexible work schedule",
                "Opportunities for advancement and career growth",
                "Work-life balance initiatives",
                "Employee assistance programs (EAPs)",
                "Access to academic resources and libraries",
                "Collaborative and supportive work environment"
            ]
        }
    ]
};

export const SchoolLibrarianMediaSpecialistJobDescription = {
    title: "School Librarian Media Specialist Job Description",
    jobRole: "A School Librarian Media Specialist manages and promotes the school library media center, providing students and staff with access to resources that support teaching, learning, and research. They curate collections, assist with information literacy instruction, and foster a culture of reading and inquiry within the school community.",
    preRead: "Use this School Librarian Media Specialist job description template to attract qualified candidates who are passionate about promoting literacy and facilitating access to information resources in an educational setting. Customize the responsibilities and qualifications based on the specific needs of your school.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a School Librarian Media Specialist, you will play a pivotal role in supporting the academic success and intellectual growth of students by providing access to a diverse range of print and digital resources. From managing the library collection to collaborating with teachers on instructional activities, you will be instrumental in creating a dynamic and enriching learning environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage the school library media center, including the selection, acquisition, organization, and maintenance of library materials.",
                "Develop and maintain a comprehensive collection of print and digital resources that align with the curriculum and support the diverse needs and interests of students and faculty.",
                "Provide instruction and guidance to students and staff on information literacy skills, research strategies, and the ethical use of information resources.",
                "Collaborate with classroom teachers to integrate library resources and instructional technology into lesson plans and curriculum units.",
                "Promote a culture of reading and lifelong learning through library programs, book clubs, author visits, and other literacy initiatives.",
                "Assist students in locating and evaluating information sources, conducting research, and citing sources appropriately.",
                "Manage library circulation services, including cataloging, circulation, and inventory management.",
                "Stay informed about emerging trends and technologies in library and information science, and incorporate innovative practices into library services and programs.",
                "Advocate for the role of the school library media center in supporting student achievement, digital literacy, and information access for all students.",
                "Collaborate with school administrators, teachers, and community stakeholders to develop and implement library policies, procedures, and strategic initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Library and Information Science (MLIS) or School Library Media (MSLM) from an ALA-accredited program.",
                "State certification or licensure as a School Library Media Specialist, or eligibility to obtain certification.",
                "Strong knowledge of library and information science principles, practices, and standards, with an emphasis on school library media services.",
                "Experience working in a school library setting, with a demonstrated ability to manage library collections, provide reference services, and deliver information literacy instruction.",
                "Proficiency in library automation systems, integrated library systems (ILS), and educational technology tools commonly used in school libraries.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with students, teachers, administrators, and community members.",
                "Passion for promoting literacy, fostering a love of reading, and supporting diverse learners in their academic and personal growth.",
                "Commitment to equity, diversity, and inclusion in library services and collections, with an understanding of culturally responsive pedagogy and anti-bias education.",
                "Ability to adapt to changing educational trends and technological advancements, and to innovate library programs and services to meet the evolving needs of the school community.",
                "Strong organizational and time management skills, with the ability to prioritize tasks, manage multiple projects, and meet deadlines in a fast-paced environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for School Librarian Media Specialists can vary depending on factors such as experience, education level, geographic location, and school district size. On average, School Librarian Media Specialists can earn between $45,000 to $70,000 per year, with potential for higher earnings in districts with higher cost of living or in leadership positions."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Discounted or free access to library resources and educational materials",
                "Opportunities for leadership and professional growth within the school district",
                "Collaborative and supportive work environment",
                "Access to school facilities and resources for personal and professional use",
                "Community engagement and networking opportunities"
            ]
        }
    ]
};

export const InstructionalCoachJobDescription = {
    title: "Instructional Coach Job Description",
    jobRole: "An Instructional Coach supports teachers in improving their instructional practices and enhancing student learning outcomes. They provide targeted professional development, facilitate collaborative planning sessions, and offer feedback and coaching to help teachers implement effective teaching strategies.",
    preRead: "Use this Instructional Coach job description template to attract experienced educators who are passionate about supporting teacher growth and promoting instructional excellence in schools. Customize the responsibilities and qualifications based on the specific needs of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Instructional Coach, you will serve as a mentor, collaborator, and resource for teachers, helping them refine their teaching techniques, differentiate instruction, and meet the diverse needs of students. By fostering a culture of continuous improvement and reflective practice, you will contribute to the professional growth and success of educators across the school community."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide one-on-one coaching and mentoring to teachers, focusing on instructional strategies, classroom management, and student engagement.",
                "Facilitate professional learning communities (PLCs) and collaborative planning sessions to promote the sharing of best practices and the development of instructional expertise.",
                "Conduct classroom observations and provide constructive feedback to teachers based on evidence of student learning, teacher practice, and instructional goals.",
                "Assist teachers in analyzing student data, identifying instructional needs, and designing targeted interventions and differentiated instruction to support student success.",
                "Model effective teaching strategies and techniques through demonstration lessons, co-teaching, and team teaching with classroom teachers.",
                "Coordinate and deliver professional development workshops, seminars, and training sessions on topics related to curriculum, instruction, assessment, and educational technology.",
                "Support the implementation of district-wide initiatives, curriculum adoptions, and instructional programs by providing guidance, resources, and professional learning opportunities for teachers.",
                "Collaborate with administrators, instructional leaders, and other stakeholders to align coaching efforts with school improvement goals, academic standards, and assessment practices.",
                "Stay current on research-based instructional practices, educational trends, and pedagogical approaches, and share relevant information and resources with teachers and staff.",
                "Maintain documentation of coaching activities, teacher progress, and outcomes, and contribute to the ongoing evaluation and refinement of the coaching program."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Master's degree in Education or a related field, with a focus on curriculum and instruction, educational leadership, or teacher development.",
                "Valid teaching license or certification, with a minimum of five years of successful classroom teaching experience in K-12 education.",
                "Demonstrated expertise in effective teaching practices, instructional strategies, and curriculum development, with a track record of improving student achievement and teacher effectiveness.",
                "Experience providing professional development, coaching, or mentoring to educators, with a deep understanding of adult learning principles and teacher growth models.",
                "Strong interpersonal and communication skills, with the ability to build trusting relationships, facilitate meaningful dialogue, and provide constructive feedback in a supportive manner.",
                "Collaborative mindset and team-oriented approach, with the ability to work effectively with teachers, administrators, and other stakeholders to achieve shared goals and objectives.",
                "Analytical and problem-solving skills, with the ability to analyze data, identify trends, and make data-informed decisions to support teacher and student success.",
                "Adaptability and flexibility, with the ability to adjust coaching strategies and approaches based on the needs of individual teachers, grade levels, and subject areas.",
                "Commitment to equity, diversity, and inclusion in education, with a focus on culturally responsive teaching practices, social justice, and educational equity for all students.",
                "High level of organization and time management skills, with the ability to prioritize tasks, manage multiple projects, and meet deadlines in a fast-paced educational environment."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Instructional Coaches varies depending on factors such as experience, education level, geographic location, and school district size. On average, Instructional Coaches can earn between $55,000 to $80,000 per year, with potential for higher earnings in districts with higher cost of living or in leadership positions."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Discounted or free access to educational resources and professional learning materials",
                "Opportunities for leadership and career advancement within the school or district",
                "Collaborative and supportive work environment",
                "Access to school facilities and resources for personal and professional use",
                "Community engagement and networking opportunities"
            ]
        }
    ]
};

export const EducationalProgramCoordinatorJobDescription = {
    title: "Educational Program Coordinator Job Description",
    jobRole: "An Educational Program Coordinator is responsible for planning, implementing, and evaluating educational programs and initiatives within an educational institution or organization. They collaborate with stakeholders, develop curriculum materials, coordinate instructional activities, and assess program effectiveness to support student learning and achievement.",
    preRead: "Use this Educational Program Coordinator job description template to attract qualified candidates who have experience in program development, curriculum design, and educational leadership. Customize the responsibilities and qualifications based on the specific needs of your educational program or department.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Educational Program Coordinator, you will play a key role in developing and managing educational programs to meet the needs of students, teachers, and the broader school community. You will work closely with administrators, faculty members, and external partners to design and implement innovative programs, coordinate resources, and ensure alignment with academic standards and instructional goals."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop, implement, and evaluate educational programs, initiatives, and projects to support student learning, academic achievement, and school improvement goals.",
                "Collaborate with school administrators, faculty members, and community partners to identify program needs, goals, and objectives, and develop strategic plans and timelines for program implementation.",
                "Design curriculum materials, instructional resources, and learning activities aligned with academic standards, best practices in teaching and learning, and the needs of diverse learners.",
                "Coordinate program logistics, including scheduling, staffing, budgeting, and procurement of materials and resources, to ensure the successful implementation of educational initiatives.",
                "Provide professional development, training, and support to teachers, staff members, and volunteers involved in program delivery, including workshops, seminars, and ongoing coaching and mentoring.",
                "Monitor program implementation and progress, collect and analyze data on program outcomes and effectiveness, and prepare reports and presentations to communicate findings and recommendations to stakeholders.",
                "Facilitate communication and collaboration among program participants, stakeholders, and community partners to foster a supportive and inclusive learning environment and promote family and community engagement.",
                "Stay current on research, trends, and developments in education, instructional technology, and curriculum design, and integrate innovative practices and approaches into program development and delivery.",
                "Evaluate program outcomes and impact on student learning, teacher practice, and school culture, and use assessment data to inform program improvement efforts and decision-making.",
                "Manage program budgets, resources, and grant funding, including preparing funding proposals, monitoring expenditures, and ensuring compliance with grant requirements and reporting guidelines."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's or master's degree in Education, Curriculum and Instruction, Educational Leadership, or a related field.",
                "Teaching certification or licensure preferred, with a minimum of three years of teaching experience in K-12 education.",
                "Experience in program development, curriculum design, instructional coaching, or educational leadership, with a track record of success in planning and implementing effective educational initiatives.",
                "Strong knowledge of curriculum standards, instructional strategies, assessment practices, and educational trends and research, with the ability to translate theory into practice.",
                "Excellent communication and interpersonal skills, with the ability to build relationships, facilitate collaboration, and communicate effectively with diverse stakeholders, including students, teachers, parents, and community members.",
                "Organizational and project management skills, with the ability to manage multiple priorities, meet deadlines, and coordinate resources to achieve program goals and objectives.",
                "Analytical and problem-solving skills, with the ability to collect, analyze, and interpret data, identify trends, and make data-driven decisions to support program improvement and innovation.",
                "Adaptability and flexibility, with the ability to thrive in a dynamic and changing environment, respond to challenges and opportunities, and adjust strategies and approaches as needed.",
                "Commitment to equity, diversity, and inclusion in education, with a focus on addressing inequities and disparities in access to educational opportunities and resources.",
                "Proficiency in educational technology tools and software applications, with the ability to leverage technology to enhance teaching and learning, facilitate communication, and support program delivery."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Educational Program Coordinators varies depending on factors such as experience, education level, geographic location, and the size of the educational institution or organization. On average, Educational Program Coordinators can earn between $50,000 to $70,000 per year, with potential for higher earnings in leadership positions or with additional responsibilities."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Discounted or free access to educational resources and professional learning materials",
                "Opportunities for leadership and career advancement within the educational institution or organization",
                "Collaborative and supportive work environment",
                "Access to school facilities and resources for personal and professional use",
                "Community engagement and networking opportunities"
            ]
        }
    ]
};


export const CommunityEducationCoordinatorJobDescription = {
    title: "Community Education Coordinator Job Description",
    jobRole: "A Community Education Coordinator is responsible for planning, coordinating, and implementing educational programs and events for the local community. They collaborate with schools, community organizations, and stakeholders to develop initiatives that address the educational needs and interests of diverse audiences, promote lifelong learning, and foster community engagement.",
    preRead: "Use this Community Education Coordinator job description template to attract qualified candidates who have experience in community outreach, program development, and event planning. Customize the responsibilities and qualifications based on the specific needs of your community education program or organization.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Community Education Coordinator, you will play a key role in connecting individuals and families with educational opportunities and resources that support personal and professional growth, enhance quality of life, and strengthen community bonds. You will work collaboratively with community partners, volunteers, and educators to identify needs, develop programs, and promote access to educational services and activities that meet the diverse interests and aspirations of community members."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement educational programs, workshops, and events for community members of all ages and backgrounds, including children, youth, adults, and seniors.",
                "Collaborate with schools, libraries, parks and recreation departments, nonprofits, businesses, and other community organizations to identify educational needs, resources, and opportunities, and leverage partnerships to maximize impact and reach.",
                "Plan and coordinate logistics for educational events and activities, including scheduling, venue selection, registration, marketing and promotion, volunteer recruitment, and coordination of presenters and facilitators.",
                "Design and create educational materials, resources, and curricula to support program objectives and participant learning outcomes, and ensure alignment with educational standards and best practices.",
                "Provide outreach and engagement to diverse communities and populations to raise awareness of available educational programs and services, address barriers to participation, and promote inclusivity and accessibility.",
                "Evaluate program effectiveness and participant satisfaction through surveys, assessments, and feedback mechanisms, and use data to inform program improvement and decision-making.",
                "Maintain records and documentation of program activities, participant demographics, and outcomes, and prepare reports and presentations to communicate program impact and accomplishments to stakeholders and funders.",
                "Recruit, train, and supervise volunteers, interns, and staff members involved in program delivery, and provide ongoing support, coaching, and professional development to build capacity and ensure program quality.",
                "Manage program budgets, grants, and funding sources, including preparing funding proposals, tracking expenditures, and ensuring compliance with grant requirements and reporting guidelines.",
                "Stay informed about emerging trends, research, and best practices in community education, lifelong learning, and adult education, and incorporate innovative approaches and strategies into program design and delivery."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's or master's degree in Education, Community Development, Nonprofit Management, or a related field.",
                "Experience in community outreach, program development, event planning, or adult education, with a focus on engaging diverse communities and promoting lifelong learning.",
                "Knowledge of educational principles, theories, and practices, with the ability to design and implement effective educational programs and activities that meet the needs of diverse learners.",
                "Excellent communication and interpersonal skills, with the ability to build relationships, collaborate with stakeholders, and engage community members from diverse backgrounds and cultures.",
                "Organizational and project management skills, with the ability to plan, coordinate, and execute multiple programs and events simultaneously, meet deadlines, and manage resources effectively.",
                "Creativity and innovation, with the ability to develop engaging and interactive educational experiences, adapt programs to meet changing needs and interests, and inspire community participation and involvement.",
                "Strong problem-solving and decision-making skills, with the ability to identify challenges, analyze complex issues, and develop practical solutions to address barriers and enhance program effectiveness.",
                "Flexibility and adaptability, with the ability to work independently and as part of a team, respond to changing priorities and circumstances, and adjust strategies and approaches as needed.",
                "Commitment to equity, diversity, and inclusion, with a focus on promoting access and opportunity for all community members, including those from marginalized or underserved populations.",
                "Proficiency in technology tools and platforms, including Microsoft Office, social media, email marketing software, and online learning management systems, with the ability to leverage technology to support program delivery and engagement."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Community Education Coordinators varies depending on factors such as experience, education level, geographic location, and the size and funding of the organization. On average, Community Education Coordinators can earn between $40,000 to $60,000 per year, with potential for higher earnings in leadership roles or with additional responsibilities."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Discounted or free access to educational resources and professional learning materials",
                "Opportunities for career advancement and leadership development within"
            ]
        }
    ]
};

export const AfterSchoolProgramCoordinatorJobDescription = {
    title: "After School Program Coordinator Job Description",
    jobRole: "An After School Program Coordinator is responsible for planning, organizing, and overseeing after-school programs and activities for students. They collaborate with school administrators, teachers, parents, and community partners to develop engaging and enriching programs that promote academic success, personal development, and positive social interactions.",
    preRead: "Use this After School Program Coordinator job description template to attract qualified candidates who have experience in education, youth development, or community programming. Customize the responsibilities and qualifications based on the specific needs and goals of your after-school program.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an After School Program Coordinator, you will design and implement a variety of activities, including homework help, tutoring, arts and crafts, sports, and cultural enrichment programs, to create a safe and supportive environment where students can learn, grow, and thrive outside of regular school hours. You will supervise program staff and volunteers, manage program logistics, and communicate regularly with parents and stakeholders to ensure program success and student engagement."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement after-school program curriculum, activities, and schedules that align with educational standards, student interests, and program goals.",
                "Supervise program staff, volunteers, and students to ensure a safe, inclusive, and supportive learning environment.",
                "Coordinate program logistics, including scheduling, transportation, facilities management, and equipment/materials procurement.",
                "Recruit, train, and mentor program staff and volunteers, providing guidance and support to enhance program quality and effectiveness.",
                "Collaborate with school administrators, teachers, parents, and community partners to assess student needs, interests, and strengths, and develop programs that address academic, social, and emotional goals.",
                "Monitor program activities and evaluate program effectiveness through regular assessments, surveys, and feedback mechanisms.",
                "Communicate regularly with parents and stakeholders to provide program updates, solicit feedback, and address concerns or questions.",
                "Maintain accurate records and documentation of program activities, participant attendance, and program outcomes.",
                "Manage program budget and resources, including tracking expenditures, fundraising, and seeking grants or sponsorships to support program sustainability and growth.",
                "Stay informed about best practices and trends in after-school programming, youth development, and education, and incorporate new ideas and strategies into program design and delivery."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Youth Development, Social Work, or a related field (Master’s degree preferred).",
                "Experience working with children and youth in an educational or recreational setting, with a focus on after-school programming or youth development.",
                "Knowledge of child development principles, positive behavior management strategies, and effective teaching and learning practices.",
                "Strong leadership and interpersonal skills, with the ability to build relationships, motivate others, and work collaboratively with diverse stakeholders.",
                "Excellent communication and organizational skills, with the ability to plan, prioritize, and manage multiple tasks and responsibilities.",
                "Creativity and flexibility, with the ability to adapt program activities and approaches to meet the needs and interests of diverse learners.",
                "Cultural competency and sensitivity to the needs of diverse populations, with a commitment to promoting equity, inclusion, and social justice.",
                "Ability to work independently and as part of a team, take initiative, and solve problems creatively.",
                "Experience with program management, budgeting, and grant writing/fundraising preferred.",
                "CPR/First Aid certification and background clearance required (varies by location)."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for After School Program Coordinators varies depending on factors such as experience, education level, geographic location, and the size and funding of the organization. On average, After School Program Coordinators can earn between $30,000 to $50,000 per year, with potential for higher earnings in leadership roles or with additional responsibilities."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Discounted or free access to educational resources and professional learning materials",
                "Opportunities for career advancement and leadership development within the organization."
            ]
        }
    ]
};

export const SummerSchoolTeacherJobDescription = {
    title: "Summer School Teacher Job Description",
    jobRole: "A Summer School Teacher is responsible for planning and implementing educational activities and lessons during the summer break for students who may need additional academic support or enrichment opportunities. They create engaging and interactive learning experiences to help students maintain or improve their academic skills and prepare for the upcoming school year.",
    preRead: "Use this Summer School Teacher job description template to attract qualified candidates who have experience in teaching and working with students in a summer school or educational setting. Customize the responsibilities and qualifications based on the specific needs and goals of your summer school program.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Summer School Teacher, you will work with students from diverse backgrounds and grade levels to deliver instruction in core academic subjects or specialized areas of interest. You will assess student learning needs, develop lesson plans and materials, and provide differentiated instruction to meet the needs of individual learners. Your goal is to create a positive and supportive learning environment where students can succeed academically and build confidence in their abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Plan and prepare daily lessons and activities that align with curriculum standards and learning objectives.",
                "Assess student learning needs and adapt instruction to meet the diverse needs and abilities of students.",
                "Create a positive and inclusive classroom environment where all students feel supported and valued.",
                "Utilize a variety of instructional strategies and resources to engage students in learning, including hands-on activities, group discussions, and technology-based tools.",
                "Monitor student progress and provide timely feedback on assignments and assessments.",
                "Collaborate with colleagues and administrators to align curriculum, share best practices, and support student success.",
                "Communicate regularly with parents and guardians to provide updates on student progress and address any concerns or questions.",
                "Participate in professional development opportunities to enhance teaching skills and stay informed about best practices in education.",
                "Maintain accurate records of student attendance, grades, and performance data.",
                "Ensure a safe and orderly learning environment by enforcing school rules and policies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Education or related field (Master's degree preferred).",
                "Valid teaching certification/license in the appropriate subject area and grade level.",
                "Experience teaching in a summer school or similar educational setting preferred.",
                "Knowledge of curriculum standards and instructional best practices.",
                "Ability to differentiate instruction to meet the needs of diverse learners.",
                "Strong classroom management skills and the ability to establish a positive and supportive learning environment.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with students, colleagues, and parents.",
                "Flexibility and adaptability, with the ability to adjust teaching strategies and approaches based on student needs and feedback.",
                "Passion for working with students and helping them achieve their academic goals.",
                "Commitment to ongoing professional development and growth as an educator."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Summer School Teachers varies depending on factors such as experience, education level, geographic location, and the size of the school or district. On average, Summer School Teachers can earn between $25,000 to $45,000 for the duration of the summer program, with potential for higher earnings based on additional responsibilities or extended hours."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Competitive compensation for the duration of the summer program.",
                "Professional development opportunities to enhance teaching skills and advance career growth.",
                "Opportunity to make a positive impact on students' academic achievement and success.",
                "Collaborative and supportive work environment with dedicated colleagues and administrators.",
                "Potential for future employment opportunities within the school or district."
            ]
        }
    ]
};

export const AlternativeEducationTeacherJobDescription = {
    title: "Alternative Education Teacher Job Description",
    jobRole: "An Alternative Education Teacher works with students who require a non-traditional approach to learning due to behavioral, academic, or other challenges. They create individualized educational plans, implement innovative teaching strategies, and provide support to help students overcome obstacles and achieve academic success.",
    preRead: "Use this Alternative Education Teacher job description template to attract qualified candidates who have experience in alternative education settings and a passion for working with diverse student populations. Customize the responsibilities and qualifications based on the specific needs and goals of your alternative education program.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Alternative Education Teacher, you will work with students who may have struggled in traditional classroom settings and require personalized support and interventions to succeed academically and behaviorally. You will collaborate with school staff, parents, and community partners to develop and implement strategies that address students' individual needs, interests, and goals, and create a positive and supportive learning environment where all students can thrive."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement individualized education plans (IEPs) or personalized learning plans (PLPs) for students with diverse learning needs and challenges.",
                "Design and deliver instructional materials and activities that are engaging, relevant, and accessible to students with varying abilities and learning styles.",
                "Use a variety of instructional strategies and interventions to support student learning, including project-based learning, hands-on activities, and differentiated instruction.",
                "Provide academic support and tutoring to help students improve their skills in core subject areas such as math, reading, writing, and science.",
                "Address behavioral issues and social-emotional needs through positive behavior supports, counseling, and conflict resolution techniques.",
                "Monitor student progress and assess learning outcomes using a variety of assessment tools and methods, and adjust instruction and support services as needed.",
                "Collaborate with school staff, counselors, social workers, and other professionals to coordinate services and resources for students and families.",
                "Communicate regularly with parents and guardians to provide updates on student progress, discuss concerns, and solicit input on student goals and strategies.",
                "Participate in professional development activities and workshops to enhance teaching skills, learn new strategies, and stay informed about best practices in alternative education.",
                "Maintain accurate records of student attendance, behavior, and academic performance, and prepare reports and documentation as required by school and district policies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Education or related field (Master's degree preferred).",
                "Valid teaching certification/license in the appropriate subject area and grade level.",
                "Experience working with students in alternative education settings, such as alternative schools, special education programs, or juvenile justice facilities.",
                "Knowledge of educational principles, theories, and practices related to alternative education, differentiated instruction, and special education.",
                "Strong understanding of behavior management strategies, social-emotional learning, and trauma-informed practices.",
                "Ability to develop positive relationships with students, build trust, and create a safe and supportive learning environment.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with colleagues, parents, and community partners.",
                "Flexibility and adaptability, with the ability to respond to changing needs and circumstances and adjust instructional strategies accordingly.",
                "Passion for working with at-risk youth and helping them overcome obstacles to achieve academic and personal success.",
                "Commitment to equity, diversity, and inclusion, with a focus on promoting access and opportunity for all students, regardless of background or ability."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Alternative Education Teachers varies depending on factors such as experience, education level, geographic location, and the size of the school or organization. On average, Alternative Education Teachers can earn between $40,000 to $60,000 per year, with potential for higher earnings based on additional certifications, qualifications, or leadership roles."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Opportunities for career advancement and leadership development within the school or organization."
            ]
        }
    ]
};

export const VocationalEducationInstructorJobDescription = {
    title: "Vocational Education Instructor Job Description",
    jobRole: "A Vocational Education Instructor is responsible for teaching vocational or career-focused courses to students, preparing them for employment in specific industries or occupations. They develop curriculum, deliver instruction, and provide hands-on training to help students acquire the skills and knowledge needed to succeed in their chosen field.",
    preRead: "Use this Vocational Education Instructor job description template to attract qualified candidates who have expertise in a particular vocational field and a passion for teaching and mentoring students. Customize the responsibilities and qualifications based on the specific vocational program or industry focus of your educational institution.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Vocational Education Instructor, you will play a critical role in preparing students for careers in a wide range of industries, trades, and occupations. You will develop and deliver instructional materials and hands-on training activities that align with industry standards and prepare students for entry-level employment or further education in their chosen field. You will also provide guidance and support to help students develop essential job skills, professional competencies, and workplace readiness."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and update curriculum, lesson plans, and instructional materials for vocational courses based on industry standards, emerging trends, and employer needs.",
                "Deliver instruction and hands-on training in vocational subjects such as automotive technology, construction trades, culinary arts, healthcare professions, cosmetology, information technology, or other specialized fields.",
                "Provide demonstrations, simulations, and practical exercises to help students develop technical skills, problem-solving abilities, and critical thinking competencies relevant to their chosen career path.",
                "Create a supportive and inclusive learning environment that promotes student engagement, motivation, and success, and encourages active participation and collaboration among students.",
                "Assess student learning outcomes using a variety of assessment methods, including written exams, practical demonstrations, performance evaluations, and industry certifications.",
                "Monitor student progress, provide feedback and guidance, and offer additional support or interventions as needed to help students achieve mastery of course objectives and competencies.",
                "Collaborate with industry partners, employers, and professional organizations to identify workforce needs, internship and job placement opportunities, and trends in vocational education.",
                "Participate in professional development activities, workshops, and training sessions to stay current with industry trends, instructional best practices, and emerging technologies relevant to vocational education.",
                "Maintain accurate records of student attendance, grades, and performance, and prepare reports and documentation as required by school policies and accreditation standards.",
                "Engage in ongoing reflection and evaluation of instructional practices, curriculum materials, and program outcomes to identify areas for improvement and ensure alignment with program goals and objectives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Education, Vocational Education, or a related field (Master's degree preferred).",
                "Professional certification, licensure, or industry credentials in the relevant vocational field or area of specialization.",
                "Previous teaching or training experience in vocational education, workforce development, or adult education, with a focus on delivering hands-on instruction and skill-based training.",
                "Expertise in the specific vocational field or industry area being taught, with up-to-date knowledge of industry standards, regulations, and best practices.",
                "Strong communication and interpersonal skills, with the ability to effectively convey complex concepts and technical information to diverse audiences.",
                "Excellent organizational and time management skills, with the ability to plan, coordinate, and manage instructional activities, resources, and assessments.",
                "Adaptability and flexibility, with the ability to adjust teaching methods, instructional materials, and assessments to meet the needs of diverse learners and changing industry demands.",
                "Passion for teaching and mentoring students, with a commitment to helping them develop the skills, knowledge, and confidence needed to succeed in their chosen career path.",
                "Ability to work collaboratively with colleagues, industry partners, and community stakeholders to support student success and program growth.",
                "Commitment to professional growth and lifelong learning, with a willingness to pursue additional training, certifications, or credentials as needed to enhance teaching effectiveness and stay current with industry trends."
            ]
        },
        {
            heading: "Salary",
            paragraph: "The salary for Vocational Education Instructors varies depending on factors such as experience, education level, geographic location, and the specific vocational field or industry focus. On average, Vocational Education Instructors can earn between $45,000 to $70,000 per year, with potential for higher earnings based on additional certifications, qualifications, or teaching responsibilities."
        },
        {
            heading: "Benefits",
            paragraphs: [
                "Health insurance",
                "Retirement plans (e.g., 401(k))",
                "Paid time off (vacation, sick leave)",
                "Professional development opportunities (conferences, workshops)",
                "Tuition reimbursement for continuing education",
                "Opportunities for career advancement and leadership development within the educational institution or vocational program."
            ]
        }
    ]
};
