import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export const StrongFitIcon = (props: SvgIconProps) => {
    return (<>
        <SvgIcon {...props} viewBox="0 0 25 25">
            <path d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z" fill="#75C059" />
            <path d="M4 13.267L7.22906 10.0969L10.0572 12.937L17.8695 5.25552C18.031 5.09602 18.2499 5.00441 18.4795 5.00015C18.7091 4.9959 18.9313 5.07935 19.0989 5.23276L21.0869 7.05355C21.1728 7.13219 21.2417 7.22683 21.2896 7.33191C21.3375 7.43699 21.3634 7.55037 21.3657 7.66537C21.368 7.78037 21.3467 7.89466 21.3032 8.0015C21.2596 8.10835 21.1945 8.20558 21.1119 8.28747L10.9239 18.3831C10.7068 18.5967 10.4119 18.7174 10.1038 18.7189C9.79577 18.7205 9.49964 18.6026 9.28029 18.3913L4 13.267Z" fill="white" />
            <path d="M19.0401 13.7598H17.0537V19.2677H19.0401V13.7598Z" fill="white" />
            <path d="M20.8672 17.4841V15.543H15.2309V17.4841H20.8672Z" fill="white" />
        </SvgIcon>
    </>)
};