import { CanadaFlagIcon, UsaFlagIcon } from "assets/Icons";

export const ShFlag = ({ countryCode }: { countryCode: 'ca' | 'us' }) => {
    switch (countryCode) {
        case 'us':
            return <UsaFlagIcon />;
        case 'ca':
            return <CanadaFlagIcon />
        default:
            return (<>NA</>)
    }
}