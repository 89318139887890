import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  PrimaryThemeColor,
  WhiteColor,
} from 'shared/SharedStyles/styleConstants';

export const ThemedBtn = styled(Button)(({ theme }) => ({
  backgroundColor: PrimaryThemeColor,
  color: WhiteColor,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  width: '100%',
}));

export const BoxColumn = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
