import { Box, Button, Divider, Grid, Link, Stack, Table, TableCell } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LightModeBackground } from "shared/SharedStyles/styleConstants";

export const MainContainer = styled(Box)(
    ({ theme }) => ({
        padding: '1% 5%',
        maxHeight: '100%',
        overflow: 'auto',
        backgroundColor: LightModeBackground
    })
);

export const EmpInfoLabelBlock = styled(Grid)(
    ({ theme }) => ({
        backgroundColor: '#E2ECF6',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 20px',
        borderRight: 'none',
        '&:not(last-of-type)': {
            borderBottom: 'none',
        },
        '.MuiTypography-root': {
            fontWeight: '600',
            wordBreak: 'break-word'
        },
    })
);

export const EmpInfoInputBlock = styled(Grid)(
    ({ theme }) => ({
        backgroundColor: '#CEDFF1',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 20px',
        '&:not(last-of-type)': {
            borderBottom: 'none',
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
            padding: '0 5px',
            border: '1px solid #CFCFCF',
            touchAction: 'none',
            '&:before': {
                borderBottom: 'none'
            },
            '& .MuiInputBase-input': {
                '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0
                }
            }
        },
        '& .MuiCheckbox-root': {
            padding: 0,
            '& .MuiSvgIcon-root': {
                backgroundColor: 'white',
                border: '1px solid #CFCFCF',
                '& path': {
                    color: 'transparent'
                }
            }
        },
        '& .Mui-checked': {
            '& .MuiSvgIcon-root': {
                backgroundColor: 'white',
                '& path': {
                    color: '#319AEB'
                }
            }
        }
    })
);

export const ExternalIdsBlock = styled(Grid)(
    ({ theme }) => ({
        backgroundColor: '#E2ECF6',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 20px',
        borderBottom: 'none',
        overflow: 'auto'
    })
);

export const ExternalIdBlock = styled(Grid)(
    ({ theme }) => ({
        minWidth: '200px',
        '.MuiTypography-root': {
            wordBreak: 'break-word'
        },
        '&:not(:first-of-type), &:not(:last-of-type)': {
            '.label': {
                borderRight: 'none',
                borderBottom: 'none',
                '.MuiTypography-root': {
                    wordBreak: 'break-word'
                }
            },
            '.value': {
                borderBottom: 'none',
                '.MuiTypography-root': {
                    wordBreak: 'break-word'
                }
            }
        },
        '&:last-of-type': {
            '.label': {
                borderRight: 'none',
                borderBottom: '1px solid white',
            },
            '.value': {
                borderBottom: '1px solid white',
            }
        }
    })
);

export const ExternalIdLabel = styled(Grid)(
    ({ theme }) => ({
        backgroundColor: '#E2ECF6',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 20px'
    })
);

export const ExternalIdValue = styled(Grid)(
    ({ theme }) => ({
        backgroundColor: '#CEDFF1',
        border: '1px solid white',
        display: 'flex',
        alignItems: 'center',
        padding: '7px 20px'
    })
);

export const StyledLink = styled(Link)(
    ({ theme }) => ({
        textDecoration: 'none',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    })
);

export const StyledTableCell = styled(TableCell)(
    ({ theme }) => ({
        backgroundColor: '#E2ECF6',
        border: '1px solid white',
        '&:not([colspan="1"])': {
            textAlign: 'center'
        },
        '& .MuiInputBase-root': {
            backgroundColor: 'white',
            padding: '0 5px',
            border: '1px solid #CFCFCF',
            touchAction: 'none',
            '&:before': {
                borderBottom: 'none'
            },
            '& .MuiInputBase-input': {
                '&::-webkit-outer-spin-button,&::-webkit-inner-spin-button': {
                    WebkitAppearance: 'none',
                    margin: 0
                }
            }
        },
        '& .credits': {
            '& .MuiInputBase-input': {
                textAlign: 'center'
            }
        }
    })
);

export const StyledStack = styled(Stack)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    position: 'relative',
    maxWidth: '100%',
    overflow: 'auto'
}));

export const BlackDivider = styled(Divider)(({ theme }) => ({
    borderColor: 'black',
    marginBottom: theme.spacing(2),
}));


export const ActionsCell = styled(TableCell)(
    ({ theme }) => ({
        backgroundColor: '#E2ECF6',
        border: '1px solid white',
        padding: '6px'
    })
);

export const TableActionsContainer = styled(Stack)(
    ({ theme }) => ({
        flexDirection: 'row',
        backgroundColor: 'white',
        padding: '6px 12px',
        '& .MuiButton-root': {
            marginBottom: 0
        }
    })
);

export const ThemedBtn = styled(Button)(
    ({ theme }) => ({
        backgroundColor: '#319AEB',
        color: 'white',
        marginBottom: theme.spacing(2),
        textTransform: 'none'
    })
);

export const ThemedBtnRed = styled(Button)(
    ({ theme }) => ({
        backgroundColor: '#319AEB',
        color: 'white',
        background: 'linear-gradient(180deg, #CF0D0E 0%, rgba(207, 13, 14, 0.6) 100%)',
        marginBottom: theme.spacing(2),
        textTransform: 'none'
    })
);

export const BlockDisable = styled(Box)(
    ({ theme }) => ({
        position: 'absolute',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        backgroundColor: '#f5f6f87a',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    })
);

export const FixedTable = styled(Table)(
    ({ theme }) => ({
        tableLayout: 'fixed'
    })
);