import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, Box, IconButton, Stack, Toolbar, Tooltip } from "@mui/material";
import { InterviewDialog } from "Modules/Core/Interviews/InterviewDialog";
import { LeftDrawerStyled, MainContainer, ParentContainerStyled, SideNavContainer, TopNavItemsContainerStyled, Wrapper } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { SideNav } from 'Modules/Core/Layouts/AppLayout/SideNav';
import { Notification } from 'Modules/Core/Notification';
import { PricingInfo } from 'Modules/Core/Pricing/PricingInfo';
import { EmployerBreadcrumbs } from "Modules/Core/ShBreadcrumbs/EmployerBreadcrumbs";
import { WelcomeDialog } from "Modules/Core/WelcomeDialog";
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { Outlet, Link as RouterLink } from 'react-router-dom';
import { ShLogo } from "shared/SharedComponents/ShLogo";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { LeftPanelContainer, MobileOnlyStack } from "shared/SharedStyles/ShLayouts";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { togglePricingDialog } from 'store/slices/app/app-info-slice';
import { logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMiniDrawer, setIsMiniDrawer] = useState(true);
  const { employerId } = useAppSelector((state) => state.auth.auth);
  const { employerDetails } = useAppSelector(state => state.employer.employerDetails);

  const toggleDrawerHandler = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // Load employer info once logged in.
  useEffect(() => {
    if (employerId && !employerDetails) {
      dispatch(getEmployerDetails({ empId: employerId }));
    }
  }, [employerId, dispatch, employerDetails]);

  return (<>
    <WelcomeDialog />
    <ParentContainerStyled>
      {/* Snackbar type Notifications component */}
      <Notification />
      <ShAppBar position="sticky">
        <Toolbar>
          <Stack direction="row" alignContent="center" justifyContent="flex-start">
            <MobileOnlyStack>
              <IconButton size="large" edge="start" onClick={toggleDrawerHandler}>
                <MenuIcon />
              </IconButton>
            </MobileOnlyStack>
            <ShLogo link="/employer/dashboard" />
          </Stack>
          <TopNavItemsContainerStyled>
            <Tooltip title="Pricing">
              <IconButton size="large" onClick={() => dispatch(togglePricingDialog())}>
                <MonetizationOnOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Help">
              <RouterLink to="/employer/help">
                <IconButton size="large">
                  <HelpOutlineOutlinedIcon />
                </IconButton>
              </RouterLink>
            </Tooltip>
            {/* To be enabled once this functionality is implemented */}
            {/* <Tooltip title="Notifications">
              <IconButton size="large">
                <Badge className='notif-badge' color='primary'>
                  <NotificationsOutlinedIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}
            {/* Button to change the theme */}
            <ThemeToggler />
            <Tooltip title="Log out">
              <IconButton size="large" onClick={() => dispatch(logoutAccount({ logoutType: 'user' }))}>
                <LogoutIcon className="logout-icon" />
              </IconButton>
            </Tooltip>
          </TopNavItemsContainerStyled>
          <MobileOnlyStack direction='row'>
            {/* Button to change the theme */}
            <ThemeToggler />
            <IconButton size="large" edge="end">
              <Badge className='notif-badge' color='primary'>
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </MobileOnlyStack>
        </Toolbar>
        <PricingInfo />
      </ShAppBar>
      {/* Wrapper holds side nav and main outlet for components */}
      <Wrapper>
        <LeftDrawerStyled anchor="left" open={isDrawerOpen} onClose={toggleDrawerHandler}>
          {/* Side nav wrapper switches the different side nav components based on the route */}
          <SideNav toggleDrawer={toggleDrawerHandler} />
          {/* Toggle drawer prop is only sent in mobile view to close the drawer overlay while navigating to components. */}
        </LeftDrawerStyled>
        <SideNavContainer className={isMiniDrawer ? 'is-mini-drawer' : ''}>
          <LeftPanelContainer>
            <SideNav isMiniDrawer={isMiniDrawer} />
          </LeftPanelContainer>
        </SideNavContainer>
        <MainContainer>
          {/* Material Breadcrumbs component wraps around links with 'Home' as constant and dynamic path names.
          */}
          <Box className='breadcrumb-control'>
            <IconButton className='side-bar-tgl' size="medium" edge="start" onClick={() => setIsMiniDrawer(!isMiniDrawer)}>
              <MenuIcon />
            </IconButton>
            <EmployerBreadcrumbs />
          </Box>
          <Box component='div' className="main-outlet-container">
            <Outlet />
            {/* Interviews dialog */}
            <InterviewDialog />
          </Box>
        </MainContainer>
      </Wrapper >
    </ParentContainerStyled>
  </>
  );
};

export default AppLayout;