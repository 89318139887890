import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import helpPageCards from "./HelpContent";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { SidebarContainer, SidebarButton, CollapsedSidebarContainer } from "./Help.styled";


export const CommonQuestionSidebar = () => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      {open ? (
        <SidebarContainer>
          <SidebarButton onClick={handleClick}>
            <KeyboardArrowRightIcon />
          </SidebarButton>
          <List component="nav" aria-labelledby="nested-list-subheader">
            {helpPageCards.map((card, index) => (
              <Tooltip title={card.title} key={index} placement="right">
                <ListItem button component={Link} to={card.path}>
                  <ListItemText primary={card.title} />
                </ListItem>
              </Tooltip>
            ))}
          </List>
        </SidebarContainer>
      ) : (
        <CollapsedSidebarContainer>
          <SidebarButton onClick={handleClick}>
            <KeyboardArrowLeftIcon />
          </SidebarButton>
        </CollapsedSidebarContainer>
      )}
    </>
  );
};

export default CommonQuestionSidebar;
