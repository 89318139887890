import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";
import { IAdminCandidateActionsInitialState } from "./admin-candidate-model";

const adminCandidateActionsInitialState: IAdminCandidateActionsInitialState = {}

export const deleteCandidateByEmail = createAsyncThunk<IBaseResponse, { email: string }, { rejectValue: { error: string } }>(
    'deleteCandidateByEmail',
    async ({ email }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .delete(`${AdminApiEndpoints.ADMIN_ACCOUNTS_CANDIDATE}?email=${email}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const adminCandidateSlice = createSlice({
    name: "adminCandidate",
    initialState: adminCandidateActionsInitialState,
    reducers: {
        resetDeleteCandidateByEmail: (state) => {
            state.deleteCandidateByEmailResponse = '';
            state.deleteCandidateByEmailStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCandidateByEmail.pending, (state) => {
            state.deleteCandidateByEmailStatus = 'pending';
        });
        builder.addCase(deleteCandidateByEmail.fulfilled, (state, action) => {
            state.deleteCandidateByEmailStatus = 'success';
            state.deleteCandidateByEmailResponse = action.payload.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(deleteCandidateByEmail.rejected, (state, action) => {
            state.deleteCandidateByEmailStatus = 'failed';
            state.deleteCandidateByEmailResponse = action.payload?.error ?? DefaultAPIErrorMsg;
        });

    }
})

export const { resetDeleteCandidateByEmail } = adminCandidateSlice.actions;
export default adminCandidateSlice;