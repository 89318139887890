export const SalesManagerJobDescription = {
    title: "Sales Manager Job Description",
    jobRole: "A Sales Manager oversees a company's sales team, sets sales goals, develops strategies to achieve them, and ensures the team meets targets. They play a key role in driving revenue growth, building customer relationships, and implementing sales plans.",
    preRead: "Use this Sales Manager job description template to advertise open positions for a Sales Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Manager, you will lead and motivate a team of sales professionals to achieve sales targets and drive revenue growth. You will develop sales strategies, identify new business opportunities, and build strong relationships with customers. This role requires strong leadership skills, sales expertise, and a customer-focused mindset."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Set sales goals and develop strategies to achieve them.",
                "Lead and motivate a team of sales professionals.",
                "Identify new business opportunities and markets.",
                "Build and maintain relationships with key customers and stakeholders.",
                "Monitor sales performance and provide feedback and coaching to team members.",
                "Develop and implement sales plans and initiatives.",
                "Forecast sales projections and track progress against targets.",
                "Collaborate with marketing and product teams to develop sales collateral and promotional materials.",
                "Stay updated on industry trends, market dynamics, and competitor activities.",
                "Ensure compliance with company policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field.",
                "Proven experience in sales management or related roles.",
                "Strong leadership and team management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to build and maintain relationships with customers and stakeholders.",
                "Results-oriented with a focus on driving revenue growth.",
                "Analytical mindset with the ability to interpret sales data and trends.",
                "Proficiency in sales management software and CRM systems.",
                "Ability to work under pressure and meet tight deadlines."
            ]
        }
    ]
};

export const AccountExecutiveJobDescription = {
    title: "Account Executive Job Description",
    jobRole: "An Account Executive is responsible for generating sales revenue by acquiring new clients, managing relationships with existing clients, and achieving sales targets. They play a crucial role in driving business growth, identifying opportunities for upselling and cross-selling, and delivering exceptional customer service.",
    preRead: "Use this Account Executive job description template to advertise open positions for an Account Executive in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Account Executive, you will be responsible for identifying and pursuing sales opportunities, building relationships with clients, and achieving sales targets. You will develop sales strategies, conduct market research, and deliver presentations to prospective clients. This role requires strong communication skills, sales expertise, and a customer-focused approach."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and pursue sales opportunities to generate revenue.",
                "Build and maintain relationships with clients and key stakeholders.",
                "Understand client needs and requirements and propose solutions.",
                "Develop and implement sales strategies to achieve targets.",
                "Deliver presentations and demonstrations to prospective clients.",
                "Negotiate contracts and agreements with clients.",
                "Collaborate with internal teams to ensure customer satisfaction and success.",
                "Track sales performance and report on progress against targets.",
                "Stay updated on industry trends, market dynamics, and competitor activities.",
                "Provide feedback and insights to improve sales processes and strategies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field.",
                "Proven experience in sales or account management roles.",
                "Strong negotiation and persuasion skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to understand client needs and propose appropriate solutions.",
                "Results-oriented with a focus on achieving sales targets.",
                "Self-motivated with the ability to work independently and as part of a team.",
                "Proficiency in sales management software and CRM systems.",
                "Ability to travel as required."
            ]
        }
    ]
};

export const SalesRepresentativeJobDescription = {
    title: "Sales Representative Job Description",
    jobRole: "A Sales Representative is responsible for selling products or services to customers, identifying new business opportunities, and achieving sales targets. They play a key role in building relationships with clients, understanding their needs, and providing solutions to meet those needs.",
    preRead: "Use this Sales Representative job description template to advertise open positions for a Sales Representative in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Representative, you will be responsible for prospecting and qualifying leads, conducting sales presentations, and closing deals. You will build and maintain relationships with clients, understand their requirements, and provide solutions to meet their needs. This role requires strong communication skills, sales expertise, and a customer-focused approach."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prospect and qualify leads to generate new business opportunities.",
                "Conduct sales presentations and demonstrations to prospective clients.",
                "Build and maintain relationships with clients and key stakeholders.",
                "Understand client needs and requirements and propose appropriate solutions.",
                "Negotiate terms and close sales deals with clients.",
                "Achieve and exceed sales targets and quotas.",
                "Provide excellent customer service and support to clients.",
                "Collaborate with internal teams to ensure customer satisfaction and success.",
                "Stay updated on industry trends, market dynamics, and competitor activities.",
                "Provide feedback and insights to improve sales processes and strategies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Bachelor’s degree in Business Administration, Marketing, or related field preferred.",
                "Proven experience in sales or customer service roles.",
                "Strong communication and interpersonal skills.",
                "Ability to understand client needs and propose appropriate solutions.",
                "Results-oriented with a focus on achieving sales targets.",
                "Self-motivated with the ability to work independently and as part of a team.",
                "Proficiency in sales techniques and methodologies.",
                "Ability to travel as required."
            ]
        }
    ]
};

export const BusinessDevelopmentManagerJobDescription = {
    title: "Business Development Manager Job Description",
    jobRole: "A Business Development Manager is responsible for identifying new business opportunities, developing and implementing growth strategies, and building relationships with clients and partners. They play a crucial role in driving business growth, expanding market presence, and achieving revenue targets.",
    preRead: "Use this Business Development Manager job description template to advertise open positions for a Business Development Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Business Development Manager, you will be responsible for identifying and pursuing new business opportunities, developing strategic partnerships, and expanding market presence. You will research market trends, analyze competitor activities, and develop growth strategies to achieve revenue targets. This role requires strong leadership skills, business acumen, and a proactive approach to driving business growth."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and pursue new business opportunities to drive revenue growth.",
                "Develop and implement strategic plans and initiatives to achieve business objectives.",
                "Build and maintain relationships with clients, partners, and key stakeholders.",
                "Conduct market research and analysis to identify market trends and opportunities.",
                "Analyze competitor activities and develop strategies to maintain competitive advantage.",
                "Negotiate contracts and agreements with clients and partners.",
                "Track and report on sales performance, market trends, and competitor activities.",
                "Collaborate with internal teams to ensure alignment with business objectives.",
                "Stay updated on industry trends and developments.",
                "Provide leadership and guidance to the business development team."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field; Master’s degree preferred.",
                "Proven experience in business development, sales, or related roles.",
                "Strong leadership and strategic planning skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to build and maintain relationships with clients and partners.",
                "Analytical mindset with the ability to interpret market data and trends.",
                "Results-oriented with a focus on achieving business objectives.",
                "Proficiency in business development tools and techniques.",
                "Ability to travel as required."
            ]
        }
    ]
};

export const SalesAnalystJobDescription = {
    title: "Sales Analyst Job Description",
    jobRole: "A Sales Analyst is responsible for analyzing sales data, evaluating sales performance, and providing insights and recommendations to improve sales effectiveness. They play a critical role in driving strategic decision-making, identifying opportunities for growth, and optimizing sales processes.",
    preRead: "Use this Sales Analyst job description template to advertise open positions for a Sales Analyst in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Analyst, you will be responsible for collecting, analyzing, and interpreting sales data to identify trends, patterns, and opportunities. You will prepare reports, dashboards, and presentations to communicate insights and recommendations to key stakeholders. This role requires strong analytical skills, attention to detail, and the ability to translate data into actionable insights."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect and analyze sales data from various sources.",
                "Evaluate sales performance against targets and KPIs.",
                "Identify trends, patterns, and opportunities in sales data.",
                "Prepare reports, dashboards, and presentations to communicate insights.",
                "Provide recommendations to improve sales effectiveness and efficiency.",
                "Collaborate with sales teams to understand business needs and requirements.",
                "Conduct market research and competitor analysis.",
                "Assist in forecasting and budgeting processes.",
                "Support strategic decision-making by providing data-driven insights.",
                "Stay updated on industry trends and developments in sales analytics."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Economics, Statistics, or related field.",
                "Proven experience in sales analysis, business analytics, or related roles.",
                "Strong analytical and problem-solving skills.",
                "Proficiency in data analysis tools and techniques.",
                "Advanced Excel skills; experience with data visualization tools is a plus.",
                "Excellent communication and presentation skills.",
                "Ability to work independently and as part of a team.",
                "Detail-oriented with a focus on accuracy and quality of work.",
                "Ability to manage multiple tasks and prioritize effectively."
            ]
        }
    ]
};

export const SalesDirectorJobDescription = {
    title: "Sales Director Job Description",
    jobRole: "A Sales Director is responsible for leading and managing the sales team to achieve revenue targets and drive business growth. They develop and implement sales strategies, establish sales objectives, and oversee the execution of sales plans. Sales Directors also build and maintain relationships with key clients, identify new business opportunities, and provide guidance and mentorship to the sales team.",
    preRead: "Use this Sales Director job description template to attract qualified candidates who can drive sales performance and lead the sales team effectively. Customize the responsibilities and qualifications to align with your organization's needs and objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Director, you will play a critical role in driving revenue growth and achieving sales targets. You will lead and mentor the sales team, develop strategic sales plans, and build strong relationships with clients and partners. This role requires strong leadership, communication, and negotiation skills, as well as a deep understanding of sales methodologies and market dynamics."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement strategic sales plans to achieve revenue targets and business objectives.",
                "Lead, motivate, and mentor the sales team to maximize performance and productivity.",
                "Set sales targets and objectives for individual team members and monitor their progress towards goals.",
                "Build and maintain relationships with key clients, partners, and stakeholders.",
                "Identify new business opportunities and market trends to expand the company's customer base.",
                "Collaborate with marketing, product, and other cross-functional teams to drive sales initiatives and campaigns.",
                "Analyze sales data and market insights to identify areas for improvement and optimize sales performance.",
                "Provide regular reporting and updates on sales activities, performance metrics, and pipeline management.",
                "Stay updated on industry trends, competitor activities, and market developments to inform sales strategies and decisions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field; Master’s degree preferred.",
                "Proven experience in sales leadership roles, with a track record of achieving sales targets and driving business growth.",
                "Strong leadership, communication, and interpersonal skills.",
                "Excellent negotiation and persuasion abilities.",
                "Strategic thinker with a deep understanding of sales methodologies and market dynamics.",
                "Experience in B2B sales, preferably in the [industry/sector] sector.",
                "Proficiency in sales CRM software and other sales productivity tools.",
                "Ability to travel as needed to meet with clients, attend industry events, and visit sales team locations."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $120,000 - $200,000 per year, depending on experience and qualifications.",
                "Attractive commission or bonus structure based on sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and conferences.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const InsideSalesRepresentativeJobDescription = {
    title: "Inside Sales Representative Job Description",
    jobRole: "An Inside Sales Representative is responsible for generating leads, qualifying prospects, and closing sales over the phone or through online channels. They engage with potential customers to understand their needs, promote products or services, and persuade them to make a purchase. Inside Sales Representatives also maintain relationships with existing clients, follow up on leads, and meet sales targets.",
    preRead: "Use this Inside Sales Representative job description template to attract motivated individuals who can drive sales and build relationships with customers over the phone or online. Customize the responsibilities and qualifications to align with your organization's needs and sales strategies.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Inside Sales Representative, you will play a key role in driving revenue growth and expanding the customer base. You will engage with potential customers over the phone or through online channels, qualify leads, and close sales. This role requires strong communication, persuasion, and negotiation skills, as well as the ability to build rapport and trust with customers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Generate leads and identify new sales opportunities through outbound calling, email campaigns, and other lead generation tactics.",
                "Qualify leads and prospects to determine their needs, budget, and purchase intent.",
                "Educate potential customers about products or services, features, and benefits to persuade them to make a purchase.",
                "Close sales and achieve monthly or quarterly sales targets.",
                "Maintain accurate records of all sales activities, customer interactions, and follow-up tasks in the CRM system.",
                "Build and maintain relationships with existing clients to drive repeat business and upsell additional products or services.",
                "Follow up on inbound leads, inquiries, and requests for information to ensure timely response and resolution.",
                "Collaborate with the sales team and other departments to optimize sales processes, share best practices, and achieve business objectives.",
                "Stay updated on product knowledge, industry trends, and competitor activities to effectively position products and services in the market."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Bachelor’s degree preferred.",
                "Proven experience in sales or customer service roles, preferably in an inside sales environment.",
                "Strong communication, negotiation, and persuasion skills.",
                "Ability to work effectively in a fast-paced, target-driven environment.",
                "Excellent phone etiquette and customer service skills.",
                "Familiarity with CRM software and sales automation tools.",
                "Ability to multitask, prioritize tasks, and manage time efficiently.",
                "Positive attitude, resilience, and a desire to learn and grow in a sales career."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $70,000 per year, depending on experience and qualifications.",
                "Attractive commission or bonus structure based on sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesConsultantJobDescription = {
    title: "Sales Consultant Job Description",
    jobRole: "A Sales Consultant is responsible for providing expert advice and guidance to clients on purchasing products or services. They identify client needs, recommend appropriate solutions, and negotiate terms to close sales. Sales Consultants also build and maintain relationships with clients, conduct market research, and stay updated on industry trends to effectively position products or services in the market.",
    preRead: "Use this Sales Consultant job description template to attract talented individuals who can provide exceptional sales support and expertise to clients. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Consultant, you will play a crucial role in driving revenue growth and building strong relationships with clients. You will leverage your expertise and industry knowledge to understand client needs, recommend solutions, and close sales. This role requires excellent communication, negotiation, and interpersonal skills, as well as a deep understanding of products or services and market dynamics."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify client needs and requirements through consultations, assessments, and discovery meetings.",
                "Recommend and demonstrate products or services that meet client needs and objectives.",
                "Provide expert advice, guidance, and support to clients throughout the sales process.",
                "Negotiate terms, pricing, and contracts to close sales and achieve revenue targets.",
                "Build and maintain relationships with clients to drive repeat business and referrals.",
                "Conduct market research and stay updated on industry trends, competitor activities, and market developments.",
                "Collaborate with the sales team, marketing, and other departments to optimize sales strategies and achieve business objectives.",
                "Maintain accurate records of sales activities, client interactions, and pipeline management in the CRM system."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field; Master’s degree preferred.",
                "Proven experience in sales or consulting roles, with a track record of achieving sales targets and driving business growth.",
                "Strong communication, negotiation, and interpersonal skills.",
                "Expertise in the products or services being sold, as well as market dynamics and industry trends.",
                "Ability to build rapport, trust, and long-term relationships with clients.",
                "Excellent problem-solving, decision-making, and analytical skills.",
                "Familiarity with CRM software and sales automation tools.",
                "Positive attitude, resilience, and a desire to succeed in a competitive sales environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $100,000 per year, depending on experience and qualifications.",
                "Attractive commission or bonus structure based on sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const RegionalSalesManagerJobDescription = {
    title: "Regional Sales Manager Job Description",
    jobRole: "A Regional Sales Manager is responsible for leading and managing a team of sales representatives to achieve sales targets within a specific geographic region. They develop and implement sales strategies, set performance goals, and provide guidance and support to their team members. Regional Sales Managers also establish and maintain relationships with key clients, monitor market trends, and collaborate with other departments to optimize sales efforts and drive revenue growth.",
    preRead: "Use this Regional Sales Manager job description template to attract qualified candidates who have experience in sales leadership and a proven track record of driving business results. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Regional Sales Manager, you will play a pivotal role in driving revenue growth and expanding market share within your assigned region. You will lead and motivate a team of sales representatives to achieve sales targets, deliver exceptional customer service, and build strong relationships with clients. This role requires strong leadership, strategic thinking, and excellent communication skills to effectively manage sales activities and drive business results."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement sales strategies and objectives to achieve revenue targets within the assigned region.",
                "Lead, coach, and mentor a team of sales representatives to drive performance, achieve sales goals, and deliver exceptional customer service.",
                "Set performance goals, monitor progress, and provide ongoing feedback and support to team members to ensure their success.",
                "Establish and maintain relationships with key clients, partners, and stakeholders to identify opportunities, address customer needs, and drive business growth.",
                "Monitor market trends, competitor activities, and customer feedback to identify opportunities for growth and optimization.",
                "Collaborate with other departments such as marketing, product management, and customer service to optimize sales efforts, address customer issues, and improve the overall customer experience.",
                "Prepare and present sales forecasts, reports, and analyses to senior management to inform strategic decision-making and resource allocation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Sales, Marketing, or related field; Master’s degree preferred.",
                "Proven experience in sales management or leadership roles, with a track record of achieving sales targets and driving business growth.",
                "Strong leadership, coaching, and team-building skills.",
                "Excellent communication, negotiation, and interpersonal skills.",
                "Strategic thinking and problem-solving abilities.",
                "Ability to analyze data, identify trends, and make data-driven decisions.",
                "Knowledge of sales techniques, processes, and best practices.",
                "Familiarity with CRM software and sales automation tools.",
                "Willingness to travel within the assigned region as needed."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $150,000 per year, depending on experience and qualifications.",
                "Attractive commission or bonus structure based on sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and leadership development programs.",
                "Car allowance or reimbursement for travel expenses.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const KeyAccountManagerJobDescription = {
    title: "Key Account Manager Job Description",
    jobRole: "A Key Account Manager is responsible for maintaining and growing relationships with key clients or accounts to drive sales and revenue growth. They serve as the primary point of contact for key clients, understand their business needs, and develop strategies to meet and exceed their expectations. Key Account Managers collaborate with cross-functional teams to ensure the successful delivery of products or services and identify opportunities for upselling or cross-selling.",
    preRead: "Use this Key Account Manager job description template to attract experienced sales professionals who have a strong track record of managing key client relationships and driving business results. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Key Account Manager, you will play a critical role in driving revenue growth and maximizing customer satisfaction within your assigned key accounts. You will build and maintain strong relationships with key clients, understand their business objectives, and proactively identify opportunities to deliver value and exceed expectations. This role requires strong communication skills, strategic thinking, and a customer-centric mindset to effectively manage key client relationships and drive business success."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Serve as the primary point of contact for key clients or accounts, building and maintaining strong relationships to drive customer satisfaction and loyalty.",
                "Understand key clients' business objectives, challenges, and needs, and develop strategies to address them effectively.",
                "Collaborate with cross-functional teams such as sales, marketing, and customer service to ensure the successful delivery of products or services and meet client expectations.",
                "Proactively identify opportunities for upselling or cross-selling additional products or services to key clients to maximize revenue and business growth.",
                "Monitor key account performance, track sales metrics, and prepare regular reports and updates for internal stakeholders.",
                "Address key clients' concerns or issues in a timely and effective manner, working closely with internal teams to resolve any issues and maintain customer satisfaction.",
                "Stay updated on industry trends, market developments, and competitor activities to identify opportunities and risks within key accounts.",
                "Develop and execute strategic account plans to achieve sales targets and business objectives for key accounts."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Sales, Marketing, or related field; Master’s degree preferred.",
                "Proven experience in key account management or sales roles, with a track record of managing key client relationships and driving business results.",
                "Strong communication, negotiation, and interpersonal skills.",
                "Customer-focused mindset with a passion for delivering exceptional service and value to clients.",
                "Ability to understand clients' business needs and develop customized solutions to address them effectively.",
                "Strategic thinking and problem-solving abilities.",
                "Excellent organizational and time management skills, with the ability to prioritize tasks and manage multiple projects simultaneously.",
                "Proficiency in CRM software and sales automation tools.",
                "Willingness to travel as needed to meet with key clients and attend industry events."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience and qualifications.",
                "Attractive commission or bonus structure based on sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and leadership development programs.",
                "Car allowance or reimbursement for travel expenses.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesCoordinatorJobDescription = {
    title: "Sales Coordinator Job Description",
    jobRole: "A Sales Coordinator plays a crucial role in supporting the sales team by coordinating sales activities, managing administrative tasks, and providing excellent customer service to clients. They assist in the execution of sales strategies, maintain sales records, and communicate with internal and external stakeholders to ensure smooth operations. Sales Coordinators also handle inquiries, resolve customer issues, and contribute to the overall success of the sales department.",
    preRead: "Use this Sales Coordinator job description template to attract detail-oriented individuals who excel in multitasking and have strong organizational skills. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Coordinator, you will play a key role in supporting the sales team and facilitating the sales process. You will assist in coordinating sales activities, managing customer inquiries, and ensuring the timely completion of sales-related tasks. This role requires strong communication skills, attention to detail, and the ability to work collaboratively with internal and external stakeholders to achieve sales objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist the sales team in executing sales strategies and achieving sales targets.",
                "Coordinate sales activities, including scheduling appointments, preparing sales materials, and arranging product demonstrations or presentations.",
                "Handle customer inquiries and provide information about products or services.",
                "Manage sales-related administrative tasks such as processing orders, preparing sales reports, and maintaining sales records.",
                "Follow up with customers to ensure satisfaction and address any concerns or issues.",
                "Collaborate with internal teams such as marketing, customer service, and finance to ensure seamless operations and customer satisfaction.",
                "Prepare sales contracts and agreements and ensure compliance with company policies and regulations.",
                "Monitor inventory levels and coordinate with the logistics team to ensure timely product delivery.",
                "Assist in organizing sales events, trade shows, or promotional activities as needed.",
                "Stay updated on industry trends, market developments, and competitor activities to identify opportunities and risks."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field; or equivalent work experience.",
                "Proven experience in sales coordination, customer service, or administrative support roles.",
                "Excellent communication and interpersonal skills, with the ability to interact professionally with clients and colleagues.",
                "Strong organizational skills and attention to detail.",
                "Ability to multitask and prioritize tasks in a fast-paced environment.",
                "Proficiency in Microsoft Office Suite and CRM software.",
                "Customer-focused mindset with a passion for delivering excellent service.",
                "Ability to work independently as well as part of a team.",
                "Flexibility to adapt to changing priorities and deadlines.",
                "Willingness to learn and take on new responsibilities as needed."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience and qualifications.",
                "Performance-based incentives or bonuses based on sales achievements.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and skill enhancement programs.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const ChannelSalesManagerJobDescription = {
    title: "Channel Sales Manager Job Description",
    jobRole: "A Channel Sales Manager is responsible for developing and managing relationships with channel partners to drive sales and revenue growth. They collaborate with partners to develop sales strategies, provide training and support, and ensure alignment with company objectives. Channel Sales Managers analyze market trends, identify new business opportunities, and work closely with internal sales, marketing, and product teams to achieve sales targets.",
    preRead: "Use this Channel Sales Manager job description template to attract experienced professionals who have a strong background in sales management and channel partner relationships. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Channel Sales Manager, you will be responsible for managing and growing relationships with channel partners to drive sales and revenue. You will develop sales strategies, provide training and support to partners, and ensure effective collaboration to achieve sales targets. This role requires strong leadership skills, strategic thinking, and the ability to build and maintain relationships with partners."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and execute channel sales strategies to drive revenue growth and achieve sales targets.",
                "Identify and recruit new channel partners to expand market reach and increase sales opportunities.",
                "Build and maintain strong relationships with existing channel partners to maximize sales performance and loyalty.",
                "Provide training, support, and resources to channel partners to help them effectively sell company products or services.",
                "Collaborate with internal sales, marketing, and product teams to develop sales enablement materials, programs, and promotions.",
                "Analyze market trends, competitor activities, and sales data to identify opportunities for growth and improvement.",
                "Monitor and evaluate channel partner performance, provide feedback and guidance, and implement corrective actions as needed.",
                "Negotiate contracts, pricing, and terms with channel partners to ensure alignment with company objectives and profitability.",
                "Track and report on key performance metrics, including sales pipeline, revenue targets, and partner satisfaction.",
                "Stay updated on industry developments, emerging trends, and best practices in channel sales management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Sales, Marketing, or related field; or equivalent work experience.",
                "Proven experience in channel sales management, partner relationship management, or similar roles.",
                "Strong leadership and interpersonal skills, with the ability to build and maintain relationships with channel partners.",
                "Excellent communication and negotiation skills, with the ability to present ideas and influence others.",
                "Strategic thinking and analytical skills, with the ability to analyze data and market trends to drive decision-making.",
                "Results-oriented mindset with a focus on achieving sales targets and driving business growth.",
                "Ability to work independently and collaboratively in a fast-paced, dynamic environment.",
                "Proficiency in Microsoft Office Suite and CRM software.",
                "Willingness to travel to meet with channel partners, attend industry events, and participate in sales meetings."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $100,000 per year, depending on experience and qualifications.",
                "Performance-based incentives or bonuses based on sales achievements.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including sales training and skill enhancement programs.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesEngineerJobDescription = {
    title: "Sales Engineer Job Description",
    jobRole: "A Sales Engineer plays a critical role in the sales process by combining technical expertise with sales acumen to promote and sell complex technical products or solutions. They collaborate with sales teams to understand customer requirements, demonstrate product capabilities, and provide technical support throughout the sales cycle. Sales Engineers also build relationships with customers, identify opportunities for upselling or cross-selling, and contribute to the development of sales strategies.",
    preRead: "Use this Sales Engineer job description template to attract skilled professionals who have a strong technical background and excellent communication skills. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Engineer, you will be responsible for supporting the sales team in promoting and selling technical products or solutions to customers. You will leverage your technical expertise to understand customer needs, demonstrate product capabilities, and address technical questions or concerns. This role requires a blend of technical knowledge, sales skills, and customer relationship management."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with sales teams to understand customer requirements and propose technical solutions to meet their needs.",
                "Demonstrate product features, functionality, and benefits to customers through presentations, product demos, and technical discussions.",
                "Provide pre-sales technical support and guidance to customers, including solution design, configuration, and troubleshooting.",
                "Conduct product evaluations, proof-of-concepts, and pilot projects to showcase product value and validate technical feasibility.",
                "Build and maintain strong relationships with customers, serving as a trusted advisor and technical point of contact throughout the sales cycle.",
                "Work closely with product management and engineering teams to gather customer feedback, communicate product requirements, and drive product improvements.",
                "Identify opportunities for upselling or cross-selling additional products or services based on customer needs and usage patterns.",
                "Prepare and deliver technical proposals, RFP responses, and other sales collateral to effectively communicate product value and differentiation.",
                "Stay updated on industry trends, competitive offerings, and emerging technologies to maintain a deep understanding of the market landscape.",
                "Collaborate with sales operations to track and report on sales activities, opportunities, and pipeline progress."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Engineering, Computer Science, or related field; or equivalent work experience.",
                "Proven experience in technical sales, pre-sales engineering, or similar roles.",
                "Strong technical knowledge in relevant technologies, products, or solutions, with the ability to quickly learn and adapt to new technologies.",
                "Excellent presentation and communication skills, with the ability to explain complex technical concepts in a clear and concise manner.",
                "Customer-focused mindset with the ability to understand and address customer needs and concerns.",
                "Analytical and problem-solving skills, with the ability to assess customer requirements and propose effective solutions.",
                "Team player with strong collaboration and interpersonal skills, able to work effectively in cross-functional teams.",
                "Results-oriented mindset with a focus on achieving sales targets and driving business growth.",
                "Proficiency in sales tools and CRM software.",
                "Willingness to travel to customer sites for meetings, presentations, and technical discussions."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience and qualifications.",
                "Performance-based incentives or bonuses based on sales achievements.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including technical training and certification programs.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesOperationsManagerJobDescription = {
    title: "Sales Operations Manager Job Description",
    jobRole: "A Sales Operations Manager is responsible for optimizing the efficiency and effectiveness of a sales organization by overseeing various sales processes, systems, and initiatives. They collaborate with sales leadership to develop and implement strategies for improving sales performance, managing sales metrics and analytics, and streamlining sales operations. Sales Operations Managers also play a key role in sales planning, forecasting, territory management, and sales enablement.",
    preRead: "Use this Sales Operations Manager job description template to attract experienced professionals who have a strong background in sales operations and business analytics. Customize the responsibilities and qualifications to align with your organization's needs and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Operations Manager, you will lead efforts to enhance the efficiency and effectiveness of the sales organization. You will work closely with sales leadership to develop and implement strategies, processes, and tools that support sales growth and productivity. This role requires a combination of analytical skills, strategic thinking, and leadership abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement sales strategies, processes, and initiatives to improve sales performance and efficiency.",
                "Analyze sales data and metrics to identify trends, patterns, and opportunities for improvement.",
                "Provide sales forecasting, pipeline analysis, and territory management support to sales teams.",
                "Lead sales planning and budgeting processes, including setting sales targets and quotas.",
                "Manage sales systems and tools, including CRM software, sales automation platforms, and reporting dashboards.",
                "Collaborate with cross-functional teams, including marketing, finance, and operations, to align sales efforts with business objectives.",
                "Drive sales enablement initiatives, including sales training, onboarding programs, and sales collateral development.",
                "Monitor and evaluate sales performance against key performance indicators (KPIs) and sales goals.",
                "Identify process bottlenecks and inefficiencies and recommend solutions for improvement.",
                "Stay updated on industry trends, best practices, and emerging technologies in sales operations and management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Sales, Marketing, or related field; MBA or equivalent preferred.",
                "Proven experience in sales operations, sales management, or related roles.",
                "Strong analytical and problem-solving skills, with the ability to interpret sales data and metrics.",
                "Proficiency in CRM software and sales automation tools, such as Salesforce, HubSpot, or Microsoft Dynamics.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with sales teams and cross-functional stakeholders.",
                "Strategic thinking and business acumen, with the ability to translate business objectives into actionable sales strategies.",
                "Leadership abilities with a track record of driving change and achieving results.",
                "Detail-oriented with strong organizational and project management skills.",
                "Ability to thrive in a fast-paced, dynamic environment and manage multiple priorities effectively.",
                "Willingness to travel occasionally for meetings, conferences, or training."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $140,000 per year, depending on experience and qualifications.",
                "Performance-based bonuses or incentives tied to sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and conferences.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const TechnicalSalesRepresentativeJobDescription = {
    title: "Technical Sales Representative Job Description",
    jobRole: "A Technical Sales Representative is responsible for promoting and selling technical products or services to customers. They combine technical knowledge with sales expertise to understand customer needs, demonstrate product capabilities, and provide solutions that meet customer requirements. Technical Sales Representatives also build and maintain relationships with customers, identify new sales opportunities, and achieve sales targets.",
    preRead: "Use this Technical Sales Representative job description template to attract qualified candidates who have a strong technical background and proven sales experience. Customize the responsibilities and qualifications to align with your company's products, industry, and sales objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Technical Sales Representative, you will be the primary point of contact for customers interested in technical products or services. You will use your technical expertise to understand customer needs, address their concerns, and provide customized solutions. This role requires a blend of technical knowledge, sales skills, and customer relationship management abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and qualify sales opportunities by understanding customer needs and requirements.",
                "Promote and sell technical products or services to customers through presentations, demonstrations, and product trials.",
                "Provide technical expertise and support to customers during the sales process, including answering questions and addressing technical issues.",
                "Build and maintain strong relationships with customers to understand their business goals and challenges.",
                "Collaborate with internal teams, including sales, marketing, and product development, to develop customized solutions that meet customer needs.",
                "Track and manage sales activities, leads, and opportunities using CRM software.",
                "Achieve sales targets and objectives by effectively managing sales pipelines and closing deals.",
                "Stay updated on industry trends, market developments, and competitor activities.",
                "Provide feedback to product development teams based on customer insights and market feedback."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Engineering, Computer Science, or related field; MBA or equivalent preferred.",
                "Proven experience in technical sales, sales engineering, or related roles.",
                "Strong technical knowledge and understanding of technical products or services.",
                "Excellent communication and presentation skills, with the ability to articulate complex technical concepts to non-technical audiences.",
                "Demonstrated ability to build and maintain relationships with customers and internal stakeholders.",
                "Solid understanding of sales processes, sales methodologies, and CRM software.",
                "Results-oriented with a track record of achieving sales targets and objectives.",
                "Analytical and problem-solving skills, with the ability to identify customer needs and recommend solutions.",
                "Ability to work independently and as part of a team in a fast-paced, dynamic environment.",
                "Willingness to travel occasionally for customer meetings, trade shows, or industry events."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience and qualifications.",
                "Performance-based bonuses or incentives tied to sales targets and objectives.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesTrainerJobDescription = {
    title: "Sales Trainer Job Description",
    jobRole: "A Sales Trainer is responsible for developing and delivering training programs to sales teams to enhance their skills, knowledge, and performance. They design training materials, conduct training sessions, and provide coaching and feedback to sales representatives. Sales Trainers also evaluate training effectiveness, identify training needs, and continuously improve training programs to align with sales objectives and goals.",
    preRead: "Use this Sales Trainer job description template to attract experienced professionals who have a strong background in sales training and development. Customize the responsibilities and qualifications to align with your organization's needs and sales training objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Trainer, you will play a critical role in enhancing the skills and capabilities of the sales team. You will design and deliver training programs that address sales techniques, product knowledge, and sales processes. This role requires a combination of instructional design expertise, sales experience, and coaching abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and deliver sales training programs, including content creation, curriculum development, and training delivery.",
                "Conduct training needs assessments to identify skill gaps and training opportunities.",
                "Design and implement sales training initiatives, workshops, and coaching sessions to improve sales performance.",
                "Provide individual coaching and feedback to sales representatives to help them improve their sales skills and achieve their targets.",
                "Evaluate training effectiveness and make recommendations for program improvements.",
                "Collaborate with sales leadership and subject matter experts to ensure training materials are up-to-date and aligned with business objectives.",
                "Monitor sales performance metrics and provide insights and recommendations based on training outcomes.",
                "Stay updated on industry trends, best practices, and emerging technologies in sales training and development.",
                "Support the onboarding and integration of new sales hires into the organization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Education, or related field; Master’s degree or professional certification in training and development preferred.",
                "Proven experience in sales training, sales management, or related roles.",
                "Strong understanding of sales processes, sales methodologies, and sales techniques.",
                "Excellent presentation and communication skills, with the ability to engage and motivate sales teams.",
                "Experience with instructional design principles and adult learning theories.",
                "Coaching and mentoring abilities with a focus on developing sales talent.",
                "Ability to work effectively in a fast-paced, dynamic environment and manage multiple training initiatives simultaneously.",
                "Analytical and problem-solving skills, with the ability to identify training needs and recommend appropriate solutions.",
                "Proficiency in Microsoft Office Suite and learning management systems (LMS)."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience and qualifications.",
                "Performance-based bonuses or incentives tied to training outcomes and sales performance.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const CustomerSuccessManagerJobDescription = {
    title: "Customer Success Manager Job Description",
    jobRole: "A Customer Success Manager is responsible for building and maintaining strong relationships with customers to ensure their success and satisfaction with the company's products or services. They serve as the primary point of contact for customers, addressing their needs, resolving issues, and identifying opportunities for upselling or cross-selling. Customer Success Managers also work closely with internal teams to advocate for customer needs and drive product improvements.",
    preRead: "Use this Customer Success Manager job description template to attract skilled professionals who are passionate about customer advocacy and relationship management. Customize the responsibilities and qualifications to align with your organization's customer success objectives and values.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Customer Success Manager, you will play a crucial role in ensuring the success and satisfaction of our customers. You will work proactively to understand their needs, address their concerns, and identify opportunities to help them achieve their goals. This role requires strong interpersonal skills, problem-solving abilities, and a customer-centric mindset."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Serve as the main point of contact for assigned customers, building strong relationships and understanding their business needs.",
                "Proactively engage with customers to ensure they are maximizing the value of our products or services and achieving their desired outcomes.",
                "Identify opportunities for upselling or cross-selling additional products or services to meet customer needs and drive revenue growth.",
                "Monitor customer health metrics, including usage, satisfaction, and renewal rates, and take proactive steps to address any issues or risks.",
                "Collaborate with internal teams, including sales, support, and product development, to advocate for customer needs and drive product improvements.",
                "Provide training and onboarding support to customers to help them get the most out of our products or services.",
                "Handle customer escalations and resolve issues in a timely and satisfactory manner.",
                "Track and report on customer success metrics and key performance indicators (KPIs) to measure and improve customer satisfaction and retention.",
                "Stay updated on industry trends and best practices in customer success and share insights with the team."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Marketing, Communications, or related field; Master’s degree is a plus.",
                "Proven experience in customer success, account management, or related roles.",
                "Strong understanding of customer relationship management principles and practices.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and trust with customers.",
                "Problem-solving abilities and a proactive approach to addressing customer needs and concerns.",
                "Ability to work effectively in a fast-paced, dynamic environment and manage multiple customer relationships simultaneously.",
                "Experience with customer success software platforms or CRM systems is preferred.",
                "Passion for customer advocacy and a commitment to delivering exceptional customer experiences.",
                "Strong organizational and time management skills, with attention to detail."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $100,000 per year, depending on experience and qualifications.",
                "Performance-based bonuses or incentives tied to customer satisfaction and retention metrics.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const AccountManagerJobDescription = {
    title: "Account Manager Job Description",
    jobRole: "An Account Manager is responsible for maintaining and growing relationships with existing clients or customers. They serve as the main point of contact for clients, addressing their needs, resolving issues, and ensuring their satisfaction with the company's products or services. Account Managers also identify opportunities for upselling or cross-selling additional products or services to meet client needs and drive revenue growth.",
    preRead: "Use this Account Manager job description template to attract skilled professionals who are passionate about client relationship management and driving business growth. Customize the responsibilities and qualifications to align with your organization's objectives and values.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Account Manager, you will play a crucial role in maintaining and growing relationships with our clients. You will serve as their primary point of contact, addressing their needs, resolving issues, and ensuring their satisfaction with our products or services. This role requires strong communication skills, problem-solving abilities, and a customer-centric mindset."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Serve as the main point of contact for assigned clients, building strong relationships and understanding their business needs.",
                "Proactively engage with clients to ensure they are maximizing the value of our products or services and achieving their desired outcomes.",
                "Identify opportunities for upselling or cross-selling additional products or services to meet client needs and drive revenue growth.",
                "Monitor client satisfaction and retention metrics, and take proactive steps to address any issues or risks.",
                "Collaborate with internal teams, including sales, support, and product development, to advocate for client needs and drive product improvements.",
                "Provide training and onboarding support to clients to help them get the most out of our products or services.",
                "Handle client escalations and resolve issues in a timely and satisfactory manner.",
                "Track and report on client success metrics and key performance indicators (KPIs) to measure and improve client satisfaction and retention.",
                "Stay updated on industry trends and best practices in client relationship management and share insights with the team."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, Communications, or related field; Master’s degree is a plus.",
                "Proven experience in account management, client services, or related roles.",
                "Strong understanding of customer relationship management principles and practices.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and trust with clients.",
                "Problem-solving abilities and a proactive approach to addressing client needs and concerns.",
                "Ability to work effectively in a fast-paced, dynamic environment and manage multiple client relationships simultaneously.",
                "Experience with CRM systems or account management software is preferred.",
                "Passion for client advocacy and a commitment to delivering exceptional client experiences.",
                "Strong organizational and time management skills, with attention to detail."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience and qualifications.",
                "Performance-based bonuses or incentives tied to client satisfaction and retention metrics.",
                "Comprehensive health, dental, and vision insurance.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Professional development opportunities including training and certifications.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const FieldSalesRepresentativeJobDescription = {
    title: "Field Sales Representative Job Description",
    jobRole: "A Field Sales Representative is responsible for generating leads, prospecting new clients, and closing sales deals outside of the office or traditional sales environment. They travel to meet with potential clients, present products or services, negotiate terms, and secure contracts. Field Sales Representatives play a crucial role in expanding the company's customer base and driving revenue growth.",
    preRead: "Use this Field Sales Representative job description template to attract proactive and results-driven professionals who thrive in a dynamic sales environment. Customize the responsibilities and qualifications to align with your organization's sales objectives and industry requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Field Sales Representative, you will be responsible for identifying and engaging with potential clients in-person to promote our products or services and close sales deals. You will travel to client locations, attend industry events, and conduct product demonstrations or presentations to showcase our offerings and address client needs. This role requires strong communication skills, sales acumen, and the ability to build and maintain relationships with clients."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and prioritize sales opportunities in assigned territories or regions.",
                "Prospect for new clients through cold calling, networking, and referrals.",
                "Schedule and conduct face-to-face meetings with potential clients to present product demonstrations, samples, or proposals.",
                "Understand client needs and pain points, and tailor product or service offerings to meet their requirements.",
                "Negotiate terms and pricing, and close sales deals to meet or exceed sales targets.",
                "Build and maintain strong relationships with clients, providing ongoing support and addressing any issues or concerns.",
                "Collaborate with internal teams, including sales support, marketing, and product development, to ensure client needs are met and sales goals are achieved.",
                "Stay updated on industry trends, market conditions, and competitor activities to identify new opportunities and stay ahead of the competition.",
                "Track and report on sales activities, pipeline development, and performance metrics to management.",
                "Provide feedback and insights to help improve sales strategies, processes, and product offerings."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Bachelor’s degree in Business Administration, Marketing, or related field is preferred.",
                "Proven experience in field sales, outside sales, or related roles.",
                "Strong interpersonal and communication skills, with the ability to build rapport and trust with clients.",
                "Results-driven mindset with a track record of meeting or exceeding sales targets.",
                "Ability to work independently and manage time effectively, with a willingness to travel frequently.",
                "Excellent negotiation and persuasion skills, with the ability to overcome objections and close sales deals.",
                "Knowledge of sales techniques and strategies, with a focus on consultative selling and relationship building.",
                "Familiarity with CRM systems or sales automation tools is a plus.",
                "Valid driver's license and clean driving record.",
                "Flexibility to work evenings, weekends, or holidays as needed to accommodate client schedules."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $40,000 - $60,000 per year, with additional commission or bonus potential based on sales performance.",
                "Sales incentives such as bonuses, commissions, or sales contests.",
                "Car allowance or mileage reimbursement for travel expenses.",
                "Health, dental, and vision insurance benefits.",
                "401(k) retirement plan with employer matching.",
                "Paid time off and company holidays.",
                "Opportunities for career growth and advancement within the sales organization.",
                "Training and professional development resources to enhance sales skills and product knowledge.",
                "Employee discounts or perks related to company products or services."
            ]
        }
    ]
};

export const SalesSupportSpecialistJobDescription = {
    title: "Sales Support Specialist Job Description",
    jobRole: "A Sales Support Specialist plays a vital role in supporting the sales team by providing administrative assistance, managing sales-related inquiries, and coordinating sales activities. They help streamline the sales process, maintain customer relationships, and ensure efficient communication between internal teams and clients. Sales Support Specialists contribute to the overall success of the sales department by handling various tasks and providing valuable support to sales representatives.",
    preRead: "Use this Sales Support Specialist job description template to attract detail-oriented individuals who excel at multitasking and have strong communication skills. Customize the responsibilities and qualifications to align with your organization's sales support needs and objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Support Specialist, you will be responsible for assisting the sales team in managing customer inquiries, processing orders, and coordinating sales-related activities. You will serve as a point of contact for customers, providing product information, resolving issues, and ensuring a positive customer experience. This role requires excellent organizational skills, attention to detail, and the ability to prioritize tasks in a fast-paced environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Serve as the primary point of contact for customer inquiries, including product information, pricing, and order status.",
                "Respond to customer inquiries via phone, email, or chat in a timely and professional manner.",
                "Process sales orders, quotes, and invoices accurately and efficiently.",
                "Coordinate with internal departments, including sales, marketing, and logistics, to ensure seamless order fulfillment and delivery.",
                "Maintain accurate records of customer interactions, transactions, and inquiries in the CRM system.",
                "Assist sales representatives with administrative tasks, such as preparing sales reports, presentations, and proposals.",
                "Follow up with customers to ensure satisfaction with products or services and address any issues or concerns.",
                "Provide sales support during promotional campaigns, product launches, and trade shows.",
                "Contribute to the development and improvement of sales support processes, procedures, and documentation.",
                "Stay updated on product knowledge, sales promotions, and company policies to provide accurate information to customers."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; Bachelor’s degree in Business Administration, Marketing, or related field is a plus.",
                "Proven experience in sales support, customer service, or administrative roles.",
                "Strong communication and interpersonal skills, with the ability to interact professionally with customers and colleagues.",
                "Excellent organizational skills and attention to detail, with the ability to multitask and prioritize tasks effectively.",
                "Proficiency in Microsoft Office suite (Word, Excel, PowerPoint) and CRM software.",
                "Ability to work independently and as part of a team in a fast-paced environment.",
                "Problem-solving skills and the ability to resolve customer issues or complaints in a timely manner.",
                "Flexibility to adapt to changing priorities and business needs.",
                "Previous experience in the industry or familiarity with company products/services is a plus.",
                "Willingness to learn and grow in a sales support role."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $35,000 - $50,000 per year, commensurate with experience and qualifications.",
                "Sales bonuses or incentives based on individual and team performance.",
                "Health, dental, and vision insurance benefits.",
                "401(k) retirement plan with employer matching.",
                "Paid time off, including vacation, sick leave, and company holidays.",
                "Training and professional development opportunities to enhance sales support skills and knowledge.",
                "Employee discounts or perks related to company products or services.",
                "Opportunities for career growth and advancement within the sales support department."
            ]
        }
    ]
};

export const SalesDevelopmentRepresentativeJobDescription = {
    title: "Sales Development Representative Job Description",
    jobRole: "A Sales Development Representative (SDR) plays a crucial role in generating leads and qualifying prospects for the sales team. They are responsible for outbound prospecting, cold calling, and email outreach to identify potential customers and create opportunities for the sales pipeline. SDRs help build relationships with prospects, gather valuable information, and set appointments or demos for the sales team. Their efforts contribute to the growth and success of the sales organization by creating a steady stream of qualified leads.",
    preRead: "Use this Sales Development Representative job description template to attract motivated individuals with strong communication skills and a proactive attitude. Customize the responsibilities and qualifications to align with your organization's sales development needs and objectives.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Development Representative, you will be responsible for prospecting and qualifying leads to generate sales opportunities for the sales team. You will conduct outbound calls, emails, and social outreach to engage with potential customers, identify their needs, and assess their fit for our products or services. This role requires persistence, resilience, and the ability to work independently to achieve sales targets."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct outbound prospecting activities to generate new leads and opportunities.",
                "Research target accounts and decision-makers to gather relevant information and identify potential leads.",
                "Initiate contact with prospects through cold calling, email outreach, and social selling techniques.",
                "Qualify leads by assessing their interest, needs, and buying intent through effective questioning and active listening.",
                "Educate prospects about the company's products or services, value proposition, and benefits.",
                "Schedule appointments, demos, or meetings for the sales team with qualified leads.",
                "Track and manage lead information, activities, and interactions in the CRM system.",
                "Collaborate with sales and marketing teams to optimize lead generation strategies and campaigns.",
                "Meet or exceed monthly and quarterly quotas for lead generation and opportunity creation.",
                "Continuously learn and develop sales skills and product knowledge to enhance performance and effectiveness."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field preferred.",
                "Proven experience in sales development, inside sales, or telemarketing roles is a plus.",
                "Excellent communication skills, both verbal and written, with the ability to articulate value propositions and overcome objections.",
                "Strong interpersonal skills and the ability to build rapport and relationships with prospects.",
                "Self-motivated and results-driven, with a passion for sales and achieving targets.",
                "Resilience, persistence, and the ability to handle rejection and overcome obstacles.",
                "Familiarity with CRM software and sales prospecting tools is preferred.",
                "Ability to work independently and as part of a team in a fast-paced environment.",
                "Attention to detail and organizational skills to manage lead data and follow-up activities.",
                "Willingness to adapt to changing priorities, processes, and strategies.",
                "Positive attitude, coachability, and a desire to learn and grow in a sales career."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $40,000 - $60,000 per year, with potential for performance-based bonuses or incentives.",
                "Sales commissions or bonuses based on lead generation and opportunity creation.",
                "Health, dental, and vision insurance benefits.",
                "401(k) retirement plan with employer matching.",
                "Paid time off, including vacation, sick leave, and company holidays.",
                "Training and professional development opportunities to enhance sales skills and knowledge.",
                "Employee discounts or perks related to company products or services.",
                "Opportunities for career growth and advancement within the sales organization."
            ]
        }
    ]
};

export const SalesSupervisorJobDescription = {
    title: "Sales Supervisor Job Description",
    jobRole: "A Sales Supervisor is responsible for leading and managing a team of sales representatives to achieve sales targets and objectives. They provide guidance, coaching, and support to their team members to maximize their performance and productivity. Sales Supervisors develop sales strategies, implement sales processes, and monitor the performance of individual team members and the overall sales team. They play a key role in driving revenue growth, building customer relationships, and ensuring customer satisfaction.",
    preRead: "Use this Sales Supervisor job description template to attract experienced sales professionals with strong leadership skills and a track record of success in sales management. Customize the responsibilities and qualifications to align with your organization's sales goals and expectations.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Supervisor, you will lead and manage a team of sales representatives to achieve sales targets and drive revenue growth. You will be responsible for recruiting, training, and developing sales talent, as well as setting sales goals, objectives, and performance targets. This role requires strong leadership, communication, and coaching skills to motivate and empower your team members to succeed."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Lead and manage a team of sales representatives to achieve sales targets and revenue goals.",
                "Set sales goals, targets, and quotas for individual team members and the overall sales team.",
                "Develop and implement sales strategies, plans, and initiatives to drive revenue growth and market penetration.",
                "Recruit, onboard, train, and develop sales talent to build a high-performing sales team.",
                "Provide coaching, feedback, and performance evaluations to sales representatives to improve their sales skills and effectiveness.",
                "Monitor and analyze sales performance metrics, KPIs, and trends to identify areas for improvement and opportunities for growth.",
                "Collaborate with cross-functional teams, including marketing, product development, and customer service, to support sales initiatives and meet customer needs.",
                "Build and maintain strong relationships with key customers, partners, and stakeholders to drive sales and enhance customer satisfaction.",
                "Stay informed about market trends, industry developments, and competitive landscape to inform sales strategies and decision-making.",
                "Ensure compliance with company policies, procedures, and ethical standards in sales activities and customer interactions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Marketing, or related field preferred.",
                "Proven experience in sales management, team leadership, or related roles.",
                "Strong understanding of sales principles, techniques, and methodologies.",
                "Excellent leadership, coaching, and motivational skills to inspire and empower sales teams.",
                "Effective communication, negotiation, and interpersonal skills to build relationships and collaborate with internal and external stakeholders.",
                "Analytical and problem-solving skills to assess sales performance, identify opportunities, and develop effective strategies.",
                "Ability to thrive in a fast-paced, dynamic environment and adapt to changing priorities and business needs.",
                "Proficiency in sales CRM software, sales analytics tools, and Microsoft Office suite.",
                "Results-oriented mindset with a focus on driving sales performance and achieving targets.",
                "High level of integrity, professionalism, and ethical conduct in all business interactions."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $60,000 - $80,000 per year, depending on experience and qualifications.",
                "Sales commissions or bonuses based on individual and team performance.",
                "Health, dental, and vision insurance benefits.",
                "401(k) retirement plan with employer matching.",
                "Paid time off, including vacation, sick leave, and company holidays.",
                "Training and professional development opportunities for career growth and advancement.",
                "Employee discounts or perks related to company products or services.",
                "Opportunities for leadership development and advancement within the sales organization."
            ]
        }
    ]
};

export const TelemarketingRepresentativeJobDescription = {
    title: "Telemarketing Representative Job Description",
    jobRole: "A Telemarketing Representative is responsible for making outbound calls to potential customers to promote products or services and generate sales leads. They engage with prospects, deliver sales pitches, and provide information about products or services to persuade them to make a purchase or schedule a sales appointment. Telemarketing Representatives use effective communication and persuasion techniques to build rapport with customers and overcome objections. They maintain accurate records of calls and sales activities in a CRM system and meet sales targets and performance goals.",
    preRead: "Use this Telemarketing Representative job description template to attract motivated individuals with excellent communication skills and sales experience. Customize the responsibilities and qualifications to match your company's telemarketing needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Telemarketing Representative, you will be responsible for reaching out to potential customers via telephone to promote products or services and generate sales leads. You will engage with prospects, deliver persuasive sales pitches, and address customer inquiries to generate interest and drive sales. This role requires strong communication skills, sales acumen, and the ability to work effectively in a fast-paced, target-driven environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Make outbound calls to prospective customers to promote products or services and generate sales leads.",
                "Deliver scripted or customized sales pitches to communicate product features, benefits, and pricing.",
                "Engage with prospects to build rapport, identify needs, and address objections to persuade them to make a purchase or schedule a sales appointment.",
                "Provide accurate and detailed information about products or services, including pricing, specifications, and availability.",
                "Follow up with leads generated through marketing campaigns, events, or other sources to nurture relationships and drive conversions.",
                "Maintain a high level of product knowledge and stay informed about industry trends, competitor offerings, and market developments.",
                "Enter and update customer information, call notes, and sales activities in a CRM system or database.",
                "Meet or exceed daily, weekly, or monthly sales targets and performance metrics, such as call volume, conversion rates, and revenue goals.",
                "Adhere to company policies, procedures, and compliance standards in telemarketing activities and customer interactions.",
                "Collaborate with sales and marketing teams to optimize lead generation strategies, refine sales scripts, and improve sales performance."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; college degree preferred.",
                "Proven experience in telemarketing, sales, or customer service roles.",
                "Excellent verbal communication and interpersonal skills.",
                "Ability to effectively engage with prospects, build rapport, and overcome objections.",
                "Strong persuasion and negotiation skills to close sales and generate leads.",
                "Comfortable making cold calls and engaging with potential customers over the phone.",
                "Results-oriented mindset with a focus on achieving sales targets and performance goals.",
                "Ability to work independently and as part of a team in a fast-paced, target-driven environment.",
                "Familiarity with CRM software or sales automation tools is a plus.",
                "Positive attitude, resilience, and persistence in pursuing sales opportunities."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $30,000 - $40,000 per year, depending on experience and qualifications.",
                "Sales commissions or bonuses based on individual and team performance.",
                "Health, dental, and vision insurance benefits.",
                "401(k) retirement plan with employer matching.",
                "Paid time off, including vacation, sick leave, and company holidays.",
                "Training and professional development opportunities for career growth and advancement.",
                "Employee discounts or perks related to company products or services.",
                "Opportunities for advancement to senior telemarketing roles or sales positions."
            ]
        }
    ]
};

export const RetailSalesAssociateJobDescription = {
    title: "Retail Sales Associate Job Description",
    jobRole: "A Retail Sales Associate is responsible for providing excellent customer service, assisting shoppers, and generating sales in a retail environment. They greet customers, offer product recommendations, and help them find the right items based on their needs and preferences. Retail Sales Associates handle transactions, process payments, and maintain a clean and organized store layout. They also contribute to achieving sales targets and enhancing the overall shopping experience for customers.",
    preRead: "Use this Retail Sales Associate job description template to attract customer-focused individuals with strong communication skills and a passion for retail sales. Customize the responsibilities and qualifications to align with your company's needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Retail Sales Associate, you will play a key role in delivering exceptional customer service and driving sales in a retail setting. You will engage with shoppers, assist them in finding products, and offer personalized recommendations to enhance their shopping experience. This role requires strong interpersonal skills, product knowledge, and the ability to thrive in a fast-paced, customer-focused environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Welcome and greet customers as they enter the store, providing a friendly and positive shopping experience.",
                "Assist customers in locating products, answering questions, and offering product recommendations based on their needs.",
                "Provide information about product features, pricing, and promotions to help customers make informed purchasing decisions.",
                "Process sales transactions accurately and efficiently, including cash, credit card, and other payment methods.",
                "Handle customer inquiries, requests, and concerns professionally and promptly, resolving issues to ensure customer satisfaction.",
                "Maintain a neat and organized store environment, including restocking shelves, arranging merchandise displays, and keeping the sales floor clean.",
                "Monitor inventory levels and notify management of low stock or product shortages to ensure adequate supplies for customers.",
                "Collaborate with team members to achieve sales targets, meet performance goals, and contribute to the overall success of the store.",
                "Stay up-to-date on product knowledge, sales promotions, and store policies to provide accurate information to customers.",
                "Adhere to company policies, procedures, and safety guidelines to maintain a safe and secure work environment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; additional education or training in retail sales is a plus.",
                "Previous experience in retail sales, customer service, or a related field is preferred.",
                "Strong communication and interpersonal skills, with the ability to engage with customers in a friendly and professional manner.",
                "Excellent customer service skills, including active listening, problem-solving, and conflict resolution.",
                "Basic math skills and the ability to handle cash transactions accurately.",
                "Attention to detail and the ability to multitask in a fast-paced retail environment.",
                "Positive attitude, enthusiasm, and a willingness to learn and adapt to changing priorities.",
                "Ability to work flexible hours, including evenings, weekends, and holidays, as required by the retail schedule.",
                "Physical stamina to stand, walk, and lift merchandise for extended periods."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Hourly wage range: $10 - $15 per hour, depending on experience and location.",
                "Opportunities for performance-based incentives, bonuses, or commissions based on individual and store sales performance.",
                "Employee discounts on merchandise or services offered by the retail store.",
                "Flexible scheduling options, including full-time, part-time, and seasonal positions.",
                "Training and development programs to enhance retail sales skills and career growth opportunities.",
                "Benefits such as health insurance, retirement plans, and paid time off may be available for full-time employees.",
                "Opportunities for advancement to supervisory or management roles within the retail organization."
            ]
        }
    ]
};

export const InternationalSalesManagerJobDescription = {
    title: "International Sales Manager Job Description",
    jobRole: "An International Sales Manager is responsible for overseeing sales activities in international markets to drive revenue growth and expand the company's global presence. They develop and implement sales strategies, identify new business opportunities, and build relationships with clients and partners worldwide. International Sales Managers lead a team of sales professionals, provide guidance and support, and ensure that sales targets and objectives are met or exceeded.",
    preRead: "Use this International Sales Manager job description template to attract experienced sales professionals with a proven track record of success in international markets. Customize the responsibilities and qualifications to align with your company's needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an International Sales Manager, you will play a pivotal role in driving sales growth and expanding market share in international territories. You will develop and execute sales strategies to penetrate new markets, identify key accounts, and establish strong business relationships with customers and partners worldwide. This role requires strong leadership skills, business acumen, and a deep understanding of global sales dynamics."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement sales strategies and initiatives to drive revenue growth and expand market share in international markets.",
                "Identify new business opportunities, market trends, and customer needs to maximize sales potential and achieve business objectives.",
                "Build and maintain relationships with key clients, distributors, and channel partners in international territories to promote products and services.",
                "Lead, coach, and motivate a team of international sales professionals to achieve sales targets and performance goals.",
                "Provide guidance, training, and support to sales team members, including setting sales targets, monitoring performance, and conducting performance reviews.",
                "Manage and oversee the sales process, including prospecting, lead generation, negotiation, and closing deals.",
                "Collaborate with cross-functional teams, including marketing, product development, and operations, to ensure alignment and support for international sales initiatives.",
                "Monitor market trends, competitor activities, and customer feedback to identify opportunities for product improvements or new market segments.",
                "Prepare and present sales forecasts, budgets, and reports to senior management to track progress and performance against targets.",
                "Travel to international markets as needed to meet with clients, attend trade shows, and participate in business development activities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, marketing, or a related field; MBA or advanced degree is a plus.",
                "Proven track record of success in international sales management, with a minimum of [X] years of experience in a similar role.",
                "Strong leadership and management skills, with the ability to motivate and inspire a team to achieve sales targets and objectives.",
                "Excellent communication, negotiation, and interpersonal skills, with the ability to build and maintain relationships with clients and partners globally.",
                "In-depth knowledge of international markets, business practices, and cultural nuances, with experience in selling to diverse customer segments.",
                "Strategic thinker with analytical skills and the ability to develop and implement effective sales strategies and initiatives.",
                "Results-oriented with a focus on driving sales growth, meeting targets, and delivering financial results.",
                "Ability to travel internationally as needed to support sales activities and business development efforts."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses, commissions, or incentives based on individual and team sales performance.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Travel allowances or expense reimbursement for international travel.",
                "Professional development and training opportunities to enhance sales skills and career growth.",
                "Opportunities for advancement to senior management or executive roles within the organization."
            ]
        }
    ]
};

export const SalesAdministratorJobDescription = {
    title: "Sales Administrator Job Description",
    jobRole: "A Sales Administrator plays a crucial role in supporting the sales team and ensuring the smooth operation of sales activities within an organization. They handle administrative tasks, manage sales-related documents and records, and provide assistance to sales representatives and managers. Sales Administrators help streamline processes, coordinate sales initiatives, and maintain customer databases to facilitate efficient sales operations.",
    preRead: "Use this Sales Administrator job description template to attract detail-oriented individuals with strong organizational skills to support your sales team. Customize the responsibilities and qualifications to meet your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Administrator, you will be responsible for providing administrative support to the sales team and ensuring that sales operations run smoothly. You will assist in managing sales-related documents and records, coordinating sales activities, and communicating with internal and external stakeholders. This role requires strong organizational skills, attention to detail, and the ability to multitask in a fast-paced environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process sales orders, quotes, and invoices accurately and in a timely manner.",
                "Maintain and update customer databases and sales records to ensure data accuracy and integrity.",
                "Respond to customer inquiries, resolve issues, and provide support to sales representatives and customers as needed.",
                "Coordinate sales meetings, appointments, and travel arrangements for the sales team.",
                "Assist in preparing sales presentations, proposals, and reports for clients and stakeholders.",
                "Collaborate with other departments, such as marketing, finance, and logistics, to support sales initiatives and projects.",
                "Monitor and track sales performance metrics, such as sales pipeline, conversion rates, and revenue targets.",
                "Assist in organizing and executing sales events, trade shows, and promotional activities.",
                "Prepare and distribute sales-related documentation, including contracts, agreements, and product information.",
                "Contribute to the development and implementation of sales strategies and processes to improve efficiency and effectiveness."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate or bachelor's degree is a plus.",
                "Proven experience in a sales support or administrative role, preferably in a sales or customer service environment.",
                "Proficiency in Microsoft Office Suite (Word, Excel, PowerPoint, Outlook) and CRM software.",
                "Strong organizational and time management skills, with the ability to prioritize tasks and meet deadlines.",
                "Excellent communication and interpersonal skills, with the ability to interact with sales team members, customers, and stakeholders.",
                "Detail-oriented with a focus on accuracy and data integrity.",
                "Ability to work independently with minimal supervision and as part of a team.",
                "Flexibility and adaptability to handle changing priorities and work in a fast-paced environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on individual and team performance.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development within the sales or administrative field."
            ]
        }
    ]
};

export const SeniorAccountExecutiveJobDescription = {
    title: "Senior Account Executive Job Description",
    jobRole: "A Senior Account Executive is a seasoned professional responsible for managing and growing client accounts within an organization. They serve as the primary point of contact for clients, understanding their needs, and developing strategic solutions to meet their goals. Senior Account Executives build strong relationships with clients, identify opportunities for upselling or cross-selling, and collaborate with internal teams to deliver exceptional service and achieve revenue targets.",
    preRead: "Use this Senior Account Executive job description template to attract experienced candidates with a proven track record of success in account management and sales. Customize the responsibilities and qualifications to align with your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Senior Account Executive, you will be responsible for managing and growing a portfolio of client accounts. You will serve as the main point of contact for clients, understanding their business objectives, and developing customized solutions to meet their needs. This role requires strong interpersonal skills, strategic thinking, and the ability to drive results in a fast-paced environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and grow a portfolio of client accounts by understanding their needs and providing strategic solutions.",
                "Build and maintain strong relationships with clients, serving as their trusted advisor and main point of contact.",
                "Develop and execute account plans to achieve revenue targets and business objectives.",
                "Identify opportunities for upselling or cross-selling additional products or services to existing clients.",
                "Collaborate with internal teams, including sales, marketing, and product development, to deliver value-added solutions to clients.",
                "Monitor and track client satisfaction, resolving any issues or concerns in a timely manner.",
                "Stay informed about industry trends, market developments, and competitor activities to identify new business opportunities.",
                "Prepare and deliver sales presentations, proposals, and contracts to clients.",
                "Provide regular reports and updates to management on sales activities, pipeline status, and client feedback.",
                "Contribute to the development of sales strategies and tactics to drive business growth and market expansion."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, marketing, or a related field; MBA is a plus.",
                "Proven track record of success in account management or sales, with at least X years of experience in a similar role.",
                "Strong communication and negotiation skills, with the ability to build rapport and influence key stakeholders.",
                "Strategic thinker with the ability to identify opportunities and develop innovative solutions to meet client needs.",
                "Excellent organizational and time management skills, with the ability to prioritize tasks and manage multiple projects simultaneously.",
                "Ability to work independently with minimal supervision and as part of a collaborative team.",
                "Proficiency in CRM software and Microsoft Office Suite (Word, Excel, PowerPoint, Outlook).",
                "Willingness to travel as needed to meet with clients and attend industry events.",
                "Strong business acumen and understanding of market dynamics and customer behavior."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on individual and team performance.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development within the company."
            ]
        }
    ]
};

export const SalesEnablementManagerJobDescription = {
    title: "Sales Enablement Manager Job Description",
    jobRole: "A Sales Enablement Manager is responsible for equipping sales teams with the tools, resources, and training they need to effectively sell products or services. They work closely with sales, marketing, and product teams to develop and implement sales enablement programs that improve sales productivity, effectiveness, and efficiency. Sales Enablement Managers play a crucial role in aligning sales strategies with business objectives and ensuring that sales teams have the knowledge and skills to meet their targets.",
    preRead: "Use this Sales Enablement Manager job description template to attract qualified candidates who can drive sales excellence and enablement initiatives within your organization. Customize the responsibilities and qualifications to align with your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Enablement Manager, you will be responsible for developing and implementing sales enablement programs that support the sales team in achieving their targets. You will collaborate with cross-functional teams to identify training needs, develop sales tools and resources, and implement best practices to improve sales performance. This role requires strong communication skills, strategic thinking, and the ability to drive change and adoption within the sales organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with sales, marketing, and product teams to develop and implement sales enablement programs and initiatives.",
                "Identify training needs and develop sales training materials, presentations, and resources to support the sales team.",
                "Create and maintain sales collateral, including sales decks, case studies, product guides, and competitive analysis.",
                "Develop and implement sales processes, tools, and technologies to streamline sales operations and improve efficiency.",
                "Provide sales coaching and mentoring to help sales reps improve their selling skills and performance.",
                "Measure and analyze the effectiveness of sales enablement programs and initiatives, and make recommendations for improvement.",
                "Manage the sales enablement content repository and ensure that sales teams have access to the latest sales materials and resources.",
                "Stay informed about industry trends, market developments, and competitor activities to identify new opportunities for sales enablement.",
                "Collaborate with sales leadership to set goals and targets for sales enablement initiatives and track progress against objectives.",
                "Provide regular reports and updates to management on sales enablement activities, including training completion rates, sales performance metrics, and feedback from sales teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, marketing, or a related field; MBA is a plus.",
                "Proven experience in sales enablement, sales operations, or a related field, with at least X years of experience in a similar role.",
                "Strong understanding of sales processes, methodologies, and best practices.",
                "Excellent communication and presentation skills, with the ability to communicate complex concepts clearly and concisely.",
                "Strong project management skills, with the ability to manage multiple projects simultaneously and meet deadlines.",
                "Ability to build relationships and collaborate effectively with cross-functional teams.",
                "Proficiency in sales enablement tools and technologies, such as CRM systems, sales training platforms, and content management systems.",
                "Strong analytical and problem-solving skills, with the ability to analyze data and metrics to inform decision-making.",
                "Ability to drive change and adoption within the sales organization and influence stakeholders at all levels of the organization."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on individual and team performance.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development within the company."
            ]
        }
    ]
};

export const SalesforceAdministratorJobDescription = {
    title: "Salesforce Administrator Job Description",
    jobRole: "A Salesforce Administrator is responsible for managing and maintaining the Salesforce platform within an organization. They work closely with sales, marketing, and customer support teams to configure and customize Salesforce to meet business requirements. Salesforce Administrators handle user management, security settings, data management, and system customization to ensure optimal performance and usability of the Salesforce platform.",
    preRead: "Use this Salesforce Administrator job description template to attract qualified candidates who can effectively manage and optimize the Salesforce platform within your organization. Customize the responsibilities and qualifications to align with your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Administrator, you will be responsible for configuring, customizing, and maintaining the Salesforce platform to support the organization's sales, marketing, and customer support activities. You will work closely with stakeholders to gather requirements, design solutions, and implement changes to the Salesforce platform. This role requires strong technical skills, attention to detail, and the ability to collaborate effectively with cross-functional teams."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Configure and customize Salesforce to meet business requirements, including creating custom objects, fields, workflows, and validation rules.",
                "Manage user permissions, roles, profiles, and security settings to ensure data integrity and compliance with security policies.",
                "Develop and maintain reports, dashboards, and analytics to provide insights into sales, marketing, and customer support performance.",
                "Collaborate with stakeholders to gather requirements, design solutions, and implement changes to the Salesforce platform.",
                "Provide technical support and troubleshooting assistance to users, including resolving issues, answering questions, and providing training.",
                "Manage data quality and integrity in Salesforce, including data cleansing, deduplication, and migration.",
                "Stay informed about new Salesforce features and functionality, and make recommendations for enhancements and improvements.",
                "Document system configurations, processes, and procedures to ensure consistency and facilitate knowledge transfer.",
                "Participate in Salesforce release management activities, including testing, deployment, and validation of new releases and patches.",
                "Provide guidance and support to junior Salesforce administrators or other team members as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in computer science, information technology, or a related field.",
                "Salesforce Administrator certification (ADM 201) is required; additional certifications such as Salesforce Advanced Administrator (ADM 211) or Salesforce Developer (DEV 401) are a plus.",
                "Proven experience as a Salesforce Administrator, with at least X years of experience managing and customizing Salesforce.",
                "Strong understanding of Salesforce architecture, data model, and security model.",
                "Proficiency in Salesforce configuration and customization, including creating custom objects, fields, workflows, and validation rules.",
                "Experience with Salesforce Lightning Experience is preferred.",
                "Excellent communication and interpersonal skills, with the ability to work effectively with stakeholders at all levels of the organization.",
                "Strong problem-solving and troubleshooting skills, with the ability to analyze complex issues and identify solutions.",
                "Ability to manage multiple projects and priorities simultaneously and meet deadlines in a fast-paced environment.",
                "Experience with Salesforce integrations with third-party systems and tools is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on individual and team performance.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development within the company."
            ]
        }
    ]
};

export const SalesExecutiveJobDescription = {
    title: "Sales Executive Job Description",
    jobRole: "A Sales Executive is responsible for driving sales and revenue growth by identifying and pursuing new business opportunities, building and maintaining relationships with clients, and achieving sales targets. Sales Executives play a key role in developing and implementing sales strategies, negotiating contracts, and delivering excellent customer service to clients.",
    preRead: "Use this Sales Executive job description template to attract qualified candidates who can effectively drive sales and revenue growth within your organization. Customize the responsibilities and qualifications to align with your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Executive, you will be responsible for generating new business opportunities, managing sales pipelines, and closing deals to meet and exceed sales targets. You will identify prospective clients, understand their needs and requirements, and present solutions that align with their business objectives. This role requires strong sales and negotiation skills, excellent communication abilities, and a proactive approach to driving sales."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and pursue new business opportunities through prospecting, networking, and cold calling.",
                "Build and maintain relationships with existing clients and key stakeholders to drive repeat business and customer satisfaction.",
                "Develop and implement sales strategies and plans to achieve sales targets and revenue goals.",
                "Conduct sales presentations and product demonstrations to prospective clients, highlighting the features and benefits of products or services.",
                "Negotiate contracts and agreements with clients, ensuring terms and conditions are favorable and in line with company policies.",
                "Manage sales pipelines, track sales activities, and update CRM systems with accurate and up-to-date information.",
                "Collaborate with internal teams, including marketing, product development, and customer support, to ensure seamless delivery of products or services to clients.",
                "Stay informed about industry trends, market developments, and competitor activities to identify opportunities for growth and differentiation.",
                "Provide timely and accurate sales forecasts and reports to management, highlighting sales performance and opportunities for improvement.",
                "Deliver excellent customer service to clients, addressing any issues or concerns in a timely and professional manner."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, marketing, or a related field.",
                "Proven experience as a Sales Executive, with at least X years of experience in sales or business development roles.",
                "Strong sales and negotiation skills, with a track record of achieving and exceeding sales targets.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and trust with clients and stakeholders.",
                "Ability to understand client needs and requirements and present solutions that meet their business objectives.",
                "Self-motivated and proactive, with the ability to work independently and as part of a team.",
                "Strong organizational and time management skills, with the ability to manage multiple priorities and meet deadlines.",
                "Proficiency in CRM systems and Microsoft Office Suite (Word, Excel, PowerPoint).",
                "Willingness to travel as needed to meet with clients and attend industry events or conferences."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on individual and team sales performance.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development within the company."
            ]
        }
    ]
};

export const CustomerServiceRepresentativeJobDescription = {
    title: "Customer Service Representative Job Description",
    jobRole: "A Customer Service Representative interacts with customers to provide information, address inquiries, resolve complaints, and process orders or requests. They serve as the first point of contact for customers and play a crucial role in ensuring positive customer experiences and satisfaction.",
    preRead: "Use this Customer Service Representative job description template to attract qualified candidates who can deliver exceptional customer service and support to your clients. Customize the responsibilities and qualifications to align with your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Customer Service Representative, you will handle incoming calls, emails, or chat inquiries from customers and address their questions, concerns, or issues in a professional and courteous manner. You will provide information about products or services, process orders, track shipments, and resolve complaints to ensure customer satisfaction and retention. This role requires strong communication skills, empathy, and problem-solving abilities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Answer incoming calls, emails, or chat inquiries from customers and provide assistance or information as needed.",
                "Listen to customers' questions or concerns, and provide accurate and timely responses or resolutions.",
                "Process orders, returns, or exchanges, and update customer accounts or records as required.",
                "Track shipments, verify delivery status, and address any issues or delays with logistics or carriers.",
                "Resolve customer complaints or issues effectively and efficiently, escalating complex or unresolved matters to appropriate teams or supervisors as needed.",
                "Identify and recommend products or services that meet customers' needs or preferences, and upsell or cross-sell additional offerings as appropriate.",
                "Maintain accurate and detailed records of customer interactions, transactions, inquiries, or complaints using CRM systems or databases.",
                "Follow up with customers to ensure satisfaction, address any outstanding issues, and gather feedback to improve service quality.",
                "Stay informed about company products, services, policies, and procedures to provide accurate and up-to-date information to customers.",
                "Adhere to established customer service standards, guidelines, and protocols to ensure consistent and high-quality service delivery."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework or degree preferred.",
                "Proven experience in customer service, call center, or related roles.",
                "Excellent communication and interpersonal skills, with a friendly and professional demeanor.",
                "Strong problem-solving and conflict resolution abilities.",
                "Ability to multitask and prioritize tasks in a fast-paced environment.",
                "Basic computer skills and proficiency in using customer service software or CRM systems.",
                "Ability to work independently and as part of a team, with a positive attitude and willingness to learn.",
                "Flexibility to work shifts, including evenings, weekends, or holidays as required."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per hour, depending on experience and location.",
                "Opportunities for performance-based bonuses or incentives based on customer satisfaction metrics or sales targets.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Training and development opportunities to enhance customer service skills and advance within the company."
            ]
        }
    ]
};

export const SalesAccountExecutiveJobDescription = {
    title: "Sales Account Executive Job Description",
    jobRole: "A Sales Account Executive is responsible for identifying, prospecting, and acquiring new business opportunities, as well as managing and nurturing relationships with existing clients. They work to meet sales targets, drive revenue growth, and maximize profitability for the organization.",
    preRead: "Use this Sales Account Executive job description template to attract qualified candidates who can drive sales and build strong relationships with clients. Customize the responsibilities and qualifications to align with your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Account Executive, you will be responsible for generating new leads, qualifying prospects, and closing sales deals to achieve revenue targets. You will develop and execute sales strategies, identify business opportunities, and build relationships with key decision-makers. This role requires strong communication skills, negotiation abilities, and a results-oriented mindset."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and research potential clients or business opportunities to generate new leads and expand the customer base.",
                "Prospect, qualify, and engage with potential customers through cold calling, networking, and other sales outreach efforts.",
                "Understand clients' business needs, challenges, and objectives, and position company products or services as solutions to address their requirements.",
                "Present product demonstrations, proposals, or presentations to prospective clients, and negotiate terms and pricing to close sales deals.",
                "Collaborate with internal teams, such as marketing, product development, and customer service, to ensure seamless client onboarding and ongoing support.",
                "Manage and nurture relationships with existing clients, identify upselling or cross-selling opportunities, and drive renewals or contract extensions.",
                "Track and report sales activities, pipeline, and performance metrics using CRM systems or sales software.",
                "Stay informed about industry trends, competitors, and market developments to identify new business opportunities and stay ahead of the competition.",
                "Attend industry events, conferences, or trade shows to network with prospects, build brand awareness, and generate leads."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Marketing, or related field; relevant sales experience may be considered in lieu of formal education.",
                "Proven track record of success in sales, business development, or account management roles, with a demonstrated ability to meet or exceed sales targets.",
                "Strong prospecting, negotiation, and closing skills, with a customer-focused approach.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and establish trust with clients.",
                "Ability to work independently and as part of a team, with a results-driven mindset and a sense of urgency.",
                "Proficiency in using CRM systems or sales software to manage leads, contacts, and sales activities.",
                "Willingness to travel to meet clients or attend business meetings as required.",
                "Industry-specific knowledge or experience may be preferred, depending on the nature of the business."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Commission or bonus structure based on individual sales performance and achievement of targets.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development, training, and career advancement within the organization."
            ]
        }
    ]
};

export const SalesforceDeveloperJobDescription = {
    title: "Salesforce Developer Job Description",
    jobRole: "A Salesforce Developer is responsible for designing, developing, customizing, and implementing Salesforce solutions to meet business requirements. They work closely with stakeholders to understand their needs and translate them into technical solutions using Salesforce technologies.",
    preRead: "Use this Salesforce Developer job description template to attract qualified candidates who can design and build scalable Salesforce solutions to drive business success. Customize the responsibilities and qualifications to align with your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Developer, you will be responsible for analyzing business requirements, designing technical solutions, and implementing customizations or enhancements to the Salesforce platform. You will collaborate with stakeholders to gather requirements, develop solution designs, and build and test Salesforce applications. This role requires strong technical skills, Salesforce expertise, and the ability to work effectively in a team environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze business requirements and translate them into technical solutions using Salesforce technologies.",
                "Design and develop custom Salesforce solutions, including configuration, customization, and integration with other systems.",
                "Build and maintain Salesforce applications, workflows, processes, and automation using Apex, Visualforce, Lightning Components, and other Salesforce development tools.",
                "Collaborate with stakeholders, including business users, administrators, and other developers, to gather requirements, define solution designs, and deliver high-quality solutions.",
                "Perform unit testing, integration testing, and debugging of Salesforce applications to ensure functionality, performance, and security.",
                "Participate in code reviews, documentation, and deployment activities following best practices and development standards.",
                "Stay updated on Salesforce releases, new features, and industry trends to recommend and implement enhancements or improvements.",
                "Provide technical support, troubleshooting, and resolution of issues related to Salesforce applications and integrations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Computer Science, Information Technology, or related field; Salesforce certifications may be preferred.",
                "Proven experience as a Salesforce Developer with hands-on experience in Salesforce configuration, customization, and development.",
                "Strong technical skills in Salesforce technologies, including Apex, Visualforce, Lightning Components, and Salesforce APIs.",
                "Experience with Salesforce development tools, such as Salesforce DX, Visual Studio Code, and Salesforce CLI.",
                "Knowledge of Salesforce data model, security model, and sharing settings.",
                "Understanding of Salesforce integration patterns and techniques, including REST, SOAP, and Salesforce Connect.",
                "Ability to analyze complex business requirements and design scalable and maintainable Salesforce solutions.",
                "Excellent communication, problem-solving, and collaboration skills with the ability to work effectively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and location.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development, training, and career advancement within the organization."
            ]
        }
    ]
};

export const InboundSalesRepresentativeJobDescription = {
    title: "Inbound Sales Representative Job Description",
    jobRole: "An Inbound Sales Representative is responsible for handling inbound sales inquiries and converting leads into customers. They provide product information, answer questions, and guide prospects through the sales process to close deals. In addition to selling products or services, they may also upsell or cross-sell to maximize sales opportunities.",
    preRead: "Use this Inbound Sales Representative job description template to attract qualified candidates who can effectively engage with prospects, understand their needs, and drive sales through inbound channels. Customize the responsibilities and qualifications to align with your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Inbound Sales Representative, you will be the first point of contact for inbound sales inquiries, including phone calls, emails, and online chats. You will engage with prospects to understand their needs, provide product or service information, and offer solutions to meet their requirements. Your goal is to build rapport, overcome objections, and close sales to achieve revenue targets."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Handle inbound sales inquiries via phone, email, and online chat in a timely and professional manner.",
                "Engage with prospects to understand their needs, provide product or service information, and offer solutions to address their requirements.",
                "Educate prospects on product features, benefits, pricing, and promotions to facilitate informed purchasing decisions.",
                "Utilize sales techniques, including active listening, probing, objection handling, and closing to convert leads into customers.",
                "Maintain accurate and up-to-date records of sales interactions, customer information, and sales activities in the CRM system.",
                "Follow up with prospects to nurture relationships, address additional questions or concerns, and overcome objections to close sales.",
                "Collaborate with cross-functional teams, including marketing, product, and support, to ensure alignment and maximize sales opportunities.",
                "Achieve or exceed sales targets, quotas, and key performance indicators (KPIs) to drive revenue growth."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Proven experience in inbound sales, telesales, or customer service roles.",
                "Strong communication, interpersonal, and negotiation skills with the ability to build rapport and trust with prospects.",
                "Ability to understand customer needs, articulate product features and benefits, and tailor solutions to meet individual requirements.",
                "Sales-oriented mindset with a focus on achieving sales targets, quotas, and KPIs.",
                "Excellent listening skills, attention to detail, and problem-solving abilities.",
                "Proficiency in CRM software and sales tools to manage leads, opportunities, and pipeline.",
                "Flexibility to adapt to changing priorities, workflows, and sales processes.",
                "High level of resilience, persistence, and determination to overcome objections and close sales."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, plus commission and performance-based incentives.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement, training, and professional development within the sales organization."
            ]
        }
    ]
};

export const PreSalesEngineerJobDescription = {
    title: "Pre-Sales Engineer Job Description",
    jobRole: "A Pre-Sales Engineer is responsible for providing technical expertise and support during the pre-sales process. They collaborate with sales teams to understand customer requirements, demonstrate product capabilities, and propose technical solutions that meet customer needs. Pre-Sales Engineers also play a key role in product demonstrations, presentations, and proof-of-concept activities to help close deals.",
    preRead: "Use this Pre-Sales Engineer job description template to attract qualified candidates who possess strong technical skills, sales acumen, and the ability to articulate complex concepts to both technical and non-technical audiences. Customize the responsibilities and qualifications to align with your company's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Pre-Sales Engineer, you will work closely with the sales team to understand customer requirements, assess technical feasibility, and design solutions that address customer needs. You will serve as the technical expert during customer interactions, providing product demonstrations, technical presentations, and solution proposals. Your goal is to support the sales process by showcasing the value of our products and services and helping to secure new business."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with sales teams to understand customer requirements and develop technical solutions that meet their needs.",
                "Conduct product demonstrations, technical presentations, and proof-of-concept activities to showcase product capabilities and benefits.",
                "Assist in the preparation of proposals, quotes, and technical documentation for customer presentations and submissions.",
                "Provide technical expertise and support during customer meetings, calls, and site visits to address technical questions and concerns.",
                "Work closely with product management and engineering teams to provide feedback on product features, functionality, and roadmap.",
                "Stay up-to-date on industry trends, competitive landscape, and emerging technologies to effectively position our products and services.",
                "Participate in trade shows, conferences, and other marketing events to promote our solutions and engage with prospects.",
                "Collaborate with cross-functional teams, including sales, marketing, and engineering, to ensure alignment and success in achieving sales targets."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Computer Science, Engineering, or related field.",
                "Proven experience as a Pre-Sales Engineer, Solutions Engineer, or Technical Sales Engineer in the software industry.",
                "Strong technical background with expertise in software architecture, systems integration, and cloud technologies.",
                "Excellent presentation, communication, and interpersonal skills with the ability to articulate complex technical concepts to both technical and non-technical audiences.",
                "Demonstrated ability to work independently and collaboratively in a fast-paced, dynamic environment.",
                "Experience with CRM systems, sales tools, and collaboration platforms for managing leads, opportunities, and customer interactions.",
                "Willingness to travel to customer sites, attend meetings, and participate in industry events as needed.",
                "Certifications in relevant technologies (e.g., AWS, Microsoft Azure, Cisco) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, plus performance-based incentives and bonuses.",
                "Comprehensive benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development, training, and career growth within the organization."
            ]
        }
    ]
};

export const SalesSupportAdministratorJobDescription = {
    title: "Sales Support Administrator Job Description",
    jobRole: "A Sales Support Administrator plays a crucial role in supporting the sales team by handling administrative tasks, coordinating sales activities, and providing operational assistance to ensure smooth sales operations. They are responsible for managing customer inquiries, processing orders, maintaining sales databases, and assisting with sales reports and forecasts.",
    preRead: "Use this Sales Support Administrator job description template to attract candidates who are detail-oriented, organized, and possess strong communication skills. Customize the responsibilities and qualifications to fit your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Support Administrator, you will be responsible for providing administrative support to the sales team, assisting with order processing, and managing customer inquiries. You will play a key role in ensuring that sales operations run smoothly by maintaining accurate records, coordinating sales activities, and facilitating communication between sales representatives and customers."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage incoming customer inquiries via phone, email, or chat and provide timely and accurate responses.",
                "Process sales orders, including order entry, order tracking, and order fulfillment.",
                "Coordinate with sales representatives to ensure timely delivery of products and services to customers.",
                "Maintain and update sales databases, CRM systems, and other sales-related tools and platforms.",
                "Assist in the preparation of sales proposals, quotes, and contracts.",
                "Generate and distribute sales reports, forecasts, and performance metrics to sales management.",
                "Coordinate sales meetings, conference calls, and other sales-related activities.",
                "Assist in the organization of sales events, trade shows, and customer presentations.",
                "Provide administrative support to the sales team, including scheduling meetings, managing calendars, and making travel arrangements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate or bachelor's degree preferred.",
                "Proven experience in sales support, customer service, or administrative roles.",
                "Strong organizational and multitasking skills with the ability to prioritize tasks effectively.",
                "Excellent communication and interpersonal skills, both written and verbal.",
                "Proficiency in Microsoft Office applications, CRM software, and other sales-related tools.",
                "Attention to detail and accuracy in data entry and record-keeping.",
                "Ability to work independently with minimal supervision and as part of a team.",
                "Customer-focused mindset with a commitment to providing exceptional service."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per hour/year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career growth and advancement within the sales or administrative field."
            ]
        }
    ]
};



export const SalesAssistantJobDescription = {
    title: "Sales Assistant Job Description",
    jobRole: "A Sales Assistant provides vital support to the sales team by assisting with administrative tasks, handling customer inquiries, and ensuring the smooth operation of sales processes. They play a key role in maintaining positive relationships with customers and contributing to the overall success of the sales department.",
    preRead: "Use this Sales Assistant job description template to attract candidates who are organized, customer-oriented, and eager to learn. Customize the responsibilities and qualifications to fit your company's specific needs and requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Assistant, you will be responsible for supporting the sales team in achieving sales targets, managing customer inquiries, and providing administrative assistance. You will help maintain accurate sales records, prepare sales documents, and assist with order processing to ensure efficient sales operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist sales representatives with administrative tasks such as preparing sales documents, processing orders, and maintaining sales databases.",
                "Handle incoming customer inquiries via phone, email, or chat and provide timely and helpful responses.",
                "Coordinate with internal teams to ensure timely delivery of products and services to customers.",
                "Maintain accurate and up-to-date sales records, including customer information, sales orders, and invoices.",
                "Assist in the preparation of sales proposals, quotes, and contracts.",
                "Monitor sales performance metrics and generate sales reports as needed.",
                "Provide general administrative support to the sales team, including scheduling meetings, managing calendars, and making travel arrangements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate or bachelor's degree preferred.",
                "Previous experience in a sales support or customer service role is a plus.",
                "Strong organizational and multitasking skills with the ability to prioritize tasks effectively.",
                "Excellent communication and interpersonal skills, both written and verbal.",
                "Proficiency in Microsoft Office applications, CRM software, and other sales-related tools.",
                "Detail-oriented with a high level of accuracy in data entry and record-keeping.",
                "Ability to work independently with minimal supervision and as part of a team.",
                "Customer-focused mindset with a commitment to providing exceptional service."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per hour/year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career growth and advancement within the sales or administrative field."
            ]
        }
    ]
};

export const SalesforceConsultantJobDescription = {
    title: "Salesforce Consultant Job Description",
    jobRole: "A Salesforce Consultant specializes in implementing and customizing Salesforce solutions to meet the unique needs of clients. They work closely with stakeholders to understand business requirements, configure Salesforce features, and provide ongoing support to optimize system performance and user adoption.",
    preRead: "Use this Salesforce Consultant job description template to attract candidates who have expertise in Salesforce implementation, configuration, and customization. Customize the responsibilities and qualifications to match your company's specific requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Consultant, you will be responsible for guiding clients through the implementation and customization of Salesforce solutions to improve business processes and drive operational efficiency. You will collaborate with stakeholders to gather requirements, configure Salesforce features, and provide training and support to ensure successful adoption."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with clients to gather business requirements and translate them into Salesforce solutions.",
                "Customize Salesforce features such as workflows, reports, dashboards, and custom objects to meet client needs.",
                "Configure and integrate third-party applications with Salesforce to extend its functionality.",
                "Lead Salesforce implementation projects from discovery to deployment, ensuring on-time delivery and client satisfaction.",
                "Provide training and support to end users to promote adoption and maximize the value of Salesforce investments.",
                "Troubleshoot and resolve technical issues related to Salesforce configuration, integration, and data migration.",
                "Stay informed about new Salesforce features and best practices to recommend solutions that align with client objectives.",
                "Document system configurations, processes, and procedures to support ongoing maintenance and future enhancements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in computer science, information systems, or related field; Salesforce certifications are a plus.",
                "Proven experience implementing and customizing Salesforce solutions for clients in various industries.",
                "Strong understanding of Salesforce architecture, data model, and security model.",
                "Proficiency in Salesforce administration, configuration, and customization, including Apex, Visualforce, and Lightning components.",
                "Experience with Salesforce integration tools and technologies such as APIs, REST, SOAP, and middleware platforms.",
                "Excellent communication and interpersonal skills, with the ability to effectively collaborate with clients and internal teams.",
                "Strong problem-solving and analytical skills, with the ability to troubleshoot complex technical issues.",
                "Ability to manage multiple projects simultaneously and prioritize tasks in a fast-paced environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development and career growth within the Salesforce ecosystem."
            ]
        }
    ]
};

export const SalesforceArchitectJobDescription = {
    title: "Salesforce Architect Job Description",
    jobRole: "A Salesforce Architect is responsible for designing scalable and robust Salesforce solutions that address complex business requirements. They work closely with stakeholders to understand business processes, architect technical solutions, and oversee the implementation of Salesforce projects to ensure alignment with best practices and industry standards.",
    preRead: "Use this Salesforce Architect job description template to attract candidates with expertise in Salesforce architecture, design, and implementation. Customize the responsibilities and qualifications to match your company's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Architect, you will be responsible for leading the design and architecture of Salesforce solutions that meet the needs of clients and stakeholders. You will collaborate with cross-functional teams to gather requirements, define technical specifications, and implement solutions that drive business outcomes and maximize the value of Salesforce investments."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Lead the design and architecture of Salesforce solutions, considering scalability, security, and performance requirements.",
                "Collaborate with stakeholders to gather and analyze business requirements, translating them into technical specifications and solution designs.",
                "Define and implement best practices for Salesforce development, including configuration, customization, and integration.",
                "Provide technical leadership and guidance to development teams, ensuring adherence to architectural principles and design patterns.",
                "Evaluate and recommend third-party tools and technologies that complement Salesforce solutions and enhance overall system functionality.",
                "Lead technical discussions and present solution designs to clients and project stakeholders, articulating the value proposition and benefits of proposed solutions.",
                "Conduct code reviews, performance tuning, and troubleshooting to ensure the quality and reliability of Salesforce implementations.",
                "Stay informed about new Salesforce features, technologies, and industry trends, incorporating them into solution designs and architecture decisions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in computer science, engineering, or related field; Salesforce certifications such as Certified Technical Architect (CTA) are preferred.",
                "Proven experience as a Salesforce Architect, with a track record of successful Salesforce implementations and solution designs.",
                "Deep understanding of Salesforce platform capabilities, including declarative features and programmatic development (Apex, Visualforce, Lightning).",
                "Experience with Salesforce integration patterns, REST/SOAP APIs, middleware platforms, and data migration strategies.",
                "Strong analytical and problem-solving skills, with the ability to translate business requirements into technical solutions.",
                "Excellent communication and interpersonal skills, with the ability to communicate complex technical concepts to non-technical stakeholders.",
                "Ability to work effectively in cross-functional teams, collaborating with developers, administrators, and business analysts.",
                "Solid understanding of software engineering principles, design patterns, and best practices."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development and career advancement within the Salesforce ecosystem."
            ]
        }
    ]
};

export const SalesOperationsAnalystJobDescription = {
    title: "Sales Operations Analyst Job Description",
    jobRole: "A Sales Operations Analyst plays a crucial role in optimizing sales processes, analyzing sales data, and providing insights to improve sales performance. They collaborate with sales teams to streamline workflows, implement sales strategies, and leverage data-driven approaches to drive revenue growth.",
    preRead: "Use this Sales Operations Analyst job description template to attract candidates with strong analytical skills, sales acumen, and experience in sales operations. Customize the responsibilities and qualifications to match your company's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Operations Analyst, you will support the sales organization by analyzing sales data, identifying trends, and recommending strategies to improve sales effectiveness. You will work closely with sales leaders to develop sales forecasts, track key performance metrics, and optimize sales processes to drive revenue growth and achieve business objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze sales data to identify trends, patterns, and insights that drive sales performance.",
                "Develop sales forecasts, pipeline projections, and performance metrics to support sales planning and decision-making.",
                "Monitor and report on key performance indicators (KPIs) such as sales conversion rates, win/loss ratios, and sales velocity.",
                "Provide recommendations for improving sales processes, workflows, and efficiency.",
                "Collaborate with sales teams to develop and implement sales strategies and initiatives.",
                "Assist in the development and implementation of sales compensation plans and incentive programs.",
                "Conduct market research and competitive analysis to identify opportunities for growth and differentiation.",
                "Prepare and present reports, dashboards, and presentations to communicate sales performance and insights to stakeholders."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, finance, economics, or related field.",
                "Proven experience in sales operations, business analysis, or related roles.",
                "Strong analytical skills with the ability to interpret complex sales data and extract actionable insights.",
                "Proficiency in data analysis tools and software such as Microsoft Excel, Salesforce, or Tableau.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with cross-functional teams.",
                "Detail-oriented and organized, with the ability to manage multiple projects and priorities.",
                "Knowledge of sales processes, methodologies, and best practices.",
                "Ability to thrive in a fast-paced and dynamic environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development and career growth within the sales operations field."
            ]
        }
    ]
};

export const SalesNegotiatorJobDescription = {
    title: "Sales Negotiator Job Description",
    jobRole: "A Sales Negotiator is responsible for facilitating property sales transactions by negotiating terms between buyers and sellers. They act as intermediaries to ensure smooth communication and agreement between parties, while also providing guidance and support throughout the sales process.",
    preRead: "Use this Sales Negotiator job description template to attract candidates with excellent communication skills, negotiation abilities, and a passion for real estate. Customize the responsibilities and qualifications to match your company's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Negotiator, you will play a key role in the real estate sales process, representing buyers and sellers to negotiate favorable terms and finalize property transactions. You will build relationships with clients, understand their needs and preferences, and guide them through the sales process from initial offer to closing."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Act as a liaison between buyers and sellers to negotiate terms and conditions of property sales.",
                "Assist clients in identifying suitable properties that meet their criteria and preferences.",
                "Conduct property viewings and provide information on features, amenities, and market trends.",
                "Prepare and present offers, counteroffers, and purchase agreements to clients.",
                "Coordinate property inspections, appraisals, and other necessary steps to finalize transactions.",
                "Provide guidance and support to clients throughout the sales process, addressing any questions or concerns.",
                "Maintain regular communication with clients, agents, and other stakeholders to ensure smooth transaction flow.",
                "Stay updated on real estate market trends, property values, and industry regulations.",
                "Build and maintain relationships with clients to generate repeat business and referrals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; additional education or certification in real estate is a plus.",
                "Proven experience in sales, negotiation, or customer service roles, preferably in real estate.",
                "Strong communication and interpersonal skills, with the ability to build rapport and trust with clients.",
                "Excellent negotiation skills and the ability to resolve conflicts and objections diplomatically.",
                "Knowledge of local real estate market trends, property values, and legal regulations.",
                "Detail-oriented and organized, with the ability to manage multiple tasks and priorities effectively.",
                "Proficiency in real estate software and CRM systems.",
                "Valid driver's license and access to reliable transportation."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Commission or bonus structure based on successful property sales.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for professional development and career advancement in the real estate industry."
            ]
        }
    ]
};

export const SalesSpecialistJobDescription = {
    title: "Sales Specialist Job Description",
    jobRole: "A Sales Specialist is responsible for driving sales revenue and achieving sales targets by identifying, prospecting, and closing new business opportunities. They focus on building and maintaining relationships with clients, understanding their needs, and providing tailored solutions to meet their requirements.",
    preRead: "Use this Sales Specialist job description template to attract candidates with strong sales skills, customer service experience, and a results-oriented mindset. Customize the responsibilities and qualifications to match your company's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Specialist, you will play a crucial role in driving revenue growth and expanding the customer base. You will identify potential clients, engage with them to understand their needs, and present solutions that meet their business objectives. Your goal is to build long-term relationships with clients, maximize sales opportunities, and achieve sales targets."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Identify and prospect potential clients through various channels, including cold calling, networking, and referrals.",
                "Build and maintain relationships with clients, understanding their business needs and objectives.",
                "Conduct needs assessments to identify opportunities for cross-selling or upselling additional products or services.",
                "Present product demonstrations, proposals, and quotes to clients, highlighting the features and benefits of solutions.",
                "Negotiate terms and pricing agreements with clients to close sales deals.",
                "Collaborate with internal teams, such as marketing and product development, to develop customized solutions for clients.",
                "Track sales activities and update CRM systems with client interactions, leads, and opportunities.",
                "Stay updated on industry trends, market developments, and competitor activities.",
                "Achieve sales targets and KPIs, such as revenue quotas and sales conversion rates."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business, marketing, or a related field; relevant work experience may be accepted in lieu of a degree.",
                "Proven track record of success in sales or business development roles, preferably in a B2B environment.",
                "Strong communication and interpersonal skills, with the ability to build rapport and trust with clients.",
                "Excellent negotiation and persuasion skills, with the ability to overcome objections and close sales.",
                "Self-motivated and results-oriented, with a proactive approach to achieving sales targets.",
                "Ability to work independently and as part of a team, collaborating with colleagues to achieve common goals.",
                "Proficiency in CRM software and Microsoft Office suite.",
                "Willingness to travel as needed to meet with clients and attend industry events."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Commission or bonus structure based on achieving sales targets and performance objectives.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development."
            ]
        }
    ]
};

export const SalesforceAnalystJobDescription = {
    title: "Salesforce Analyst Job Description",
    jobRole: "A Salesforce Analyst is responsible for optimizing and maintaining Salesforce systems to support sales operations and business processes. They work closely with sales teams and other stakeholders to gather requirements, design solutions, and implement changes or enhancements to Salesforce applications.",
    preRead: "Use this Salesforce Analyst job description template to attract candidates with experience in Salesforce administration, configuration, and support. Customize the responsibilities and qualifications to match your organization's specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Analyst, you will play a key role in managing and optimizing Salesforce systems to streamline sales operations and improve efficiency. You will collaborate with sales teams and business stakeholders to understand their requirements and translate them into Salesforce solutions. Your responsibilities may include system configuration, data management, user training, and ongoing support."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Gather and analyze business requirements from sales teams and other stakeholders.",
                "Design and configure Salesforce solutions to meet business needs, including custom objects, fields, workflows, and validation rules.",
                "Implement changes or enhancements to Salesforce applications, following best practices and governance standards.",
                "Manage Salesforce data, including data imports, exports, deduplication, and data quality initiatives.",
                "Create and maintain Salesforce reports and dashboards to provide insights into sales performance and business metrics.",
                "Provide user training and support to sales teams and other Salesforce users, ensuring adoption and proficiency with the system.",
                "Collaborate with IT and other departments to integrate Salesforce with other systems and applications.",
                "Stay updated on Salesforce releases, features, and best practices, and proactively identify opportunities for optimization and improvement."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in computer science, information systems, or a related field.",
                "Certification as a Salesforce Administrator or equivalent experience.",
                "Proven experience in Salesforce administration, configuration, and support.",
                "Strong analytical and problem-solving skills, with the ability to understand complex business processes and translate them into technical solutions.",
                "Excellent communication and collaboration skills, with the ability to work effectively with cross-functional teams.",
                "Detail-oriented with a focus on data accuracy and quality.",
                "Ability to manage multiple projects and priorities in a fast-paced environment.",
                "Experience with Salesforce Lightning Experience is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for career advancement and professional development.",
                "Flexible work arrangements and remote work options may be available."
            ]
        }
    ]
};

export const SalesRecruiterJobDescription = {
    title: "Sales Recruiter Job Description",
    jobRole: "A Sales Recruiter is responsible for sourcing, recruiting, and hiring top sales talent to drive business growth and meet revenue targets. They work closely with hiring managers to understand their staffing needs, develop recruiting strategies, and attract qualified candidates through various channels.",
    preRead: "Use this Sales Recruiter job description template to advertise open positions for a Sales Recruiter in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Recruiter, you will play a critical role in building and maintaining a high-performing sales team. You will partner with hiring managers to define job requirements, develop sourcing strategies, and execute recruitment plans to identify and attract top sales talent. Your responsibilities may include candidate sourcing, screening, interviewing, and coordinating the hiring process from start to finish."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with hiring managers to understand staffing needs and develop recruitment plans.",
                "Source and attract qualified candidates through various channels, including job boards, social media, networking events, and employee referrals.",
                "Screen resumes, conduct phone screens, and schedule interviews with hiring managers.",
                "Conduct in-depth interviews to assess candidates' qualifications, skills, and fit for the role and company culture.",
                "Coordinate and manage the interview process, including scheduling interviews, collecting feedback, and communicating with candidates.",
                "Manage candidate relationships and provide a positive candidate experience throughout the recruitment process.",
                "Maintain accurate and up-to-date candidate records in the applicant tracking system.",
                "Provide regular updates to hiring managers on recruitment status, candidate pipeline, and other key metrics.",
                "Participate in recruitment events, job fairs, and networking activities to promote the company's employer brand and attract top talent."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in human resources, business administration, or related field.",
                "Proven experience as a recruiter, preferably in sales or a related field.",
                "Strong understanding of sales roles and requirements, including sales skills, experience, and qualifications.",
                "Excellent sourcing and networking skills, with the ability to identify and engage passive candidates.",
                "Solid understanding of recruitment best practices, including candidate assessment and selection techniques.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and influence others.",
                "Highly organized with strong attention to detail and the ability to manage multiple tasks and priorities effectively.",
                "Proficiency in applicant tracking systems and other recruitment software tools.",
                "Certification in recruitment or human resources is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for performance-based bonuses and incentives.",
                "Flexible work arrangements and remote work options may be available."
            ]
        }
    ]
};

export const SalesforceProjectManagerJobDescription = {
    title: "Salesforce Project Manager Job Description",
    jobRole: "A Salesforce Project Manager oversees the planning, execution, and implementation of Salesforce projects within an organization. They collaborate with stakeholders to define project scope, objectives, and deliverables, and ensure projects are completed on time, within budget, and according to quality standards.",
    preRead: "Use this Salesforce Project Manager job description template to advertise open positions for a Salesforce Project Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Salesforce Project Manager, you will lead cross-functional teams to successfully deliver Salesforce projects that meet business objectives and drive customer success. You will be responsible for project planning, resource allocation, risk management, and stakeholder communication throughout the project lifecycle."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with stakeholders to define project scope, objectives, and deliverables.",
                "Develop detailed project plans, schedules, and budgets to track progress and ensure project milestones are met.",
                "Identify and allocate project resources, including team members, vendors, and third-party consultants.",
                "Manage project risks and issues, and develop mitigation strategies to address them.",
                "Lead and motivate cross-functional project teams to achieve project goals and deliver high-quality solutions.",
                "Monitor project progress, track key performance indicators (KPIs), and report on project status to stakeholders.",
                "Facilitate communication and collaboration among project team members, stakeholders, and business units.",
                "Ensure project deliverables are completed on time, within budget, and according to quality standards.",
                "Coordinate user acceptance testing (UAT) and ensure successful deployment and adoption of Salesforce solutions.",
                "Provide post-implementation support and ensure smooth transition to ongoing maintenance and support."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in business administration, computer science, or related field.",
                "Proven experience as a project manager, with a focus on Salesforce projects.",
                "Certification in project management (e.g., PMP, PRINCE2) is preferred.",
                "Solid understanding of Salesforce platform capabilities, including Sales Cloud, Service Cloud, and Community Cloud.",
                "Experience with Agile and waterfall project management methodologies.",
                "Strong leadership, communication, and interpersonal skills.",
                "Ability to effectively manage project scope, budget, and resources.",
                "Excellent problem-solving and decision-making abilities.",
                "Proficiency in project management tools and software (e.g., Microsoft Project, Jira)."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Base salary range: $X - $X per year, depending on experience and qualifications.",
                "Benefits package including health insurance, retirement plans, and paid time off.",
                "Opportunities for performance-based bonuses and incentives.",
                "Flexible work arrangements and remote work options may be available."
            ]
        }
    ]
};


