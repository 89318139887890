import CloseIcon from '@mui/icons-material/Close';
import { Alert, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { setLocalAccessToken } from 'helpers/TokenHelper';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { loginThruSocial, logoutAccount, resetLogoutType, setAccountLoginToTrue } from 'store/slices/auth-v2/auth-v2-slice';
import {
    applyForJob, resetApplyForJob, setPostingSource
} from "store/slices/candidate/apply-job/apply-job-home-slice";
import { getJobRequirements, resetGetJobRequirements } from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";
import { resetUpdateCandidateResume, resetUploadApplicantResume, setIsResumeUpdatedToTrue, uploadApplicantResume } from "store/slices/candidate/apply-job/resume-slice";
import {
    getCandidateDetails, resetGetCandidateDetails, resetSignUpCandidate, setCandidateAuthToken
} from "store/slices/candidate/candidate-profile-slice";
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';
import { useNotification } from '../Notification';
import { ApplyJobEmailValidation } from './ApplyJobEmailValidation';
import { ApplyJobLogin } from "./ApplyJobLogin";
import { TCurrentJobApplyStep } from "./ApplyJobModel";
import { ApplyJobResumeSelect } from './ApplyJobResumeSelect';
import { ApplyJobSignUp } from "./ApplyJobSignUp";
import { ApplyJobUploadResume } from "./ApplyJobUploadResume";

export const ApplyJobHome = () => {

    const hashInJobApplyUrl = useLocation().hash.substring(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { jobCode } = useParams();
    const notification = useNotification();
    const pS = new URLSearchParams(useLocation().search)?.get('ps');
    const {
        jobApplicationStatus, jobApplicationId, postingSource } = useAppSelector((state) => state.candidate.applyJobHome);
    const { getCandidateDetailsStatus, getCandidateDetailsResponse, candidateSignUpStatus, candidateSignUpErrorCode,
        candidateSignUpResponse, candidateAuthToken, candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { getJobApplicationRequirementsStatus, getJobApplicationRequirementsResponse
    } = useAppSelector((state) => state.candidate.applyJobQuestionnaire);
    const { jobDetailsByCode } = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const { checkIfAccountExistsStatus, isAccountLoggedIn, logoutType, accountAccess,
        checkIfAccountExistsResponse } = useAppSelector((state) => state.auth.auth);
    const { candidateSignUpPayload, socialLoginToApplyJobNavParams } = useAppSelector((state) => state.auth.socialLogin);
    const { uploadResumeStatus, uploadResumeResponse, candidateInfoInResume, isResumeUpdated,
        updateCandidateResumeStatus, updateCandidateResumeResponse } = useAppSelector((state) => state.candidate.resume);
    const [currentJobApplyStep, setCurrentJobApplyStep] = useState<TCurrentJobApplyStep>('email');
    const [candidateEmail, setCandidateEmail] = useState<string>('');
    const [selectedResume, setSelectedResume] = useState<File | undefined>(undefined);
    const [isFromCandidateDashboard, setIsFromCandidateDashboard] = useState<boolean>(true);

    // Update posting source in slice.
    useEffect(() => {
        if (pS) {
            dispatch(setPostingSource(pS));
        }
    }, [dispatch, pS]);

    useEffect(() => {
        const _jobApplicationId = parseInt(hashInJobApplyUrl);
        setIsFromCandidateDashboard(!isNaN(_jobApplicationId));
        if (!isNaN(_jobApplicationId)) {
            dispatch(getCandidateDetails({ candidateId: _jobApplicationId }))
        }
    }, [dispatch, hashInJobApplyUrl]);

    // Update posting source retrieved from state on navigation from social login in slice.
    useEffect(() => {
        if (socialLoginToApplyJobNavParams) {
            if (socialLoginToApplyJobNavParams.postingSource) {
                dispatch(setPostingSource(socialLoginToApplyJobNavParams.postingSource));
            }

            //Set token & refresh token in local storage
            setLocalAccessToken(socialLoginToApplyJobNavParams.authToken);

            dispatch(setCandidateAuthToken({ authToken: socialLoginToApplyJobNavParams.authToken }));
            setCurrentJobApplyStep('social_sign_up');
            if (jobDetailsByCode?.id && !jobApplicationId) {
                dispatch(applyForJob({ jobId: jobDetailsByCode?.id.toString(), postingSource: socialLoginToApplyJobNavParams?.postingSource }));
            }
        }
    }, [dispatch, jobApplicationId, jobDetailsByCode?.id, socialLoginToApplyJobNavParams]);

    const uploadResume = useCallback((isResumeUpdate: boolean) => {
        if (selectedResume && jobApplicationId) {
            const formData = new FormData();
            formData.append('resume', selectedResume);
            dispatch(uploadApplicantResume({ applicationId: jobApplicationId, resume: formData, isResumeUpdate: isResumeUpdate }));
        }
    }, [dispatch, jobApplicationId, selectedResume]);

    useEffect(() => {
        if (candidateAuthToken) {
            setLocalAccessToken(candidateAuthToken);
        }
    }, [candidateAuthToken, dispatch]);

    useEffect(() => {
        if (uploadResumeStatus === 'success' && candidateAuthToken) {
            if (socialLoginToApplyJobNavParams) {
                if (candidateSignUpPayload && !isAccountLoggedIn) {
                    dispatch(loginThruSocial({ loginResponse: candidateSignUpPayload, loginPage: 'candidate_apply_job' }));
                }
            } else {
                dispatch(setAccountLoginToTrue({ authToken: candidateAuthToken, loginPage: 'candidate_apply_job' }));
            }
        }
    }, [candidateAuthToken, candidateSignUpPayload, dispatch, isAccountLoggedIn,
        socialLoginToApplyJobNavParams, uploadResumeStatus]);

    // get candidate details after job application success
    useEffect(() => {
        if (jobApplicationStatus === 'success' && jobApplicationId) {
            dispatch(getCandidateDetails({ candidateId: jobApplicationId }));
        } else if (jobApplicationStatus === 'failed') {
            dispatch(logoutAccount({ logoutType: 'auto' }));
            setCurrentJobApplyStep('email');
            setSelectedResume(undefined);
        }
    }, [dispatch, jobApplicationId, jobApplicationStatus]);

    // get candidate details after resume upload success
    useEffect(() => {
        if (uploadResumeStatus === 'success' && jobApplicationId) {
            dispatch(getCandidateDetails({ candidateId: jobApplicationId }));
        }
    }, [dispatch, jobApplicationId, updateCandidateResumeStatus, uploadResumeStatus]);

    useEffect(() => {
        if (updateCandidateResumeStatus === 'success') {
            // notification.displayNotification({
            //     open: true,
            //     type: 'success',
            //     message: updateCandidateResumeResponse ?? DefaultAPISuccessMsg
            // });
            dispatch(setIsResumeUpdatedToTrue());
        }
    }, [dispatch, notification, updateCandidateResumeResponse, updateCandidateResumeStatus]);

    useEffect(() => {
        if (checkIfAccountExistsStatus === 'success') {
            if (checkIfAccountExistsResponse?.exists) {
                setCurrentJobApplyStep('login');
                setCandidateEmail(checkIfAccountExistsResponse?.email);
            } else {
                setCurrentJobApplyStep('sign_up');
            }
        }
    }, [checkIfAccountExistsResponse?.email, checkIfAccountExistsResponse?.exists, checkIfAccountExistsStatus]);

    useEffect(() => {
        if (getCandidateDetailsStatus === 'success' || (candidateDetails?.jobApplicationId && jobCode)) {
            if (candidateAuthToken) {
                dispatch(setAccountLoginToTrue({ authToken: candidateAuthToken, loginPage: 'candidate_apply_job' }));
            }
            if (candidateDetails?.resumeComplete) {
                if (candidateDetails.previousResumes?.length > 0 && !isResumeUpdated) {
                    setCurrentJobApplyStep('resume_update');
                } else {
                    if (candidateDetails?.skillsComplete) {
                        if (jobDetailsByCode?.ats_purchased) {
                            if (candidateDetails.submitted) {
                                navigate(CandidateLoginRedirect);
                            } else {
                                navigate(`/candidate/apply-job/${jobCode}/questionnaire`);
                            }
                        } else {
                            if (candidateDetails.surveyComplete) {
                                if (candidateDetails.submitted) {
                                    navigate(CandidateLoginRedirect);
                                } else {
                                    navigate(`/candidate/apply-job/${jobCode}/questionnaire`);
                                }
                            } else {
                                navigate(`/candidate/apply-job/${jobCode}/assessment`);
                            }
                        }
                    } else {
                        navigate(`/candidate/apply-job/${jobCode}/questionnaire`);
                    }
                }
            } else {
                setCurrentJobApplyStep('sign_up');
            }
        }
    }, [candidateAuthToken, candidateDetails?.jobApplicationId, candidateDetails?.previousResumes?.length,
        candidateDetails?.resumeComplete, candidateDetails?.skillsComplete, candidateDetails?.submitted,
        candidateDetails?.surveyComplete, dispatch, getCandidateDetailsStatus, isResumeUpdated, jobCode,
        jobDetailsByCode?.ats_purchased, navigate]);

    // set current step to email if logged out.
    useEffect(() => {
        if (!isAccountLoggedIn && logoutType === 'user') {
            setCurrentJobApplyStep('email');
            dispatch(resetLogoutType());
        }
    }, [dispatch, isAccountLoggedIn, logoutType]);

    // set social_sign_up as current step to show upload resume 
    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            currentJobApplyStep === 'social_sign_up' && !candidateDetails?.resumeComplete) {
            setCurrentJobApplyStep('social_sign_up');
        }
    }, [accountAccess.role, candidateDetails?.resumeComplete, currentJobApplyStep, isAccountLoggedIn]);

    // if flow is normal login
    useEffect(() => {
        if (isAccountLoggedIn && (accountAccess.role ? accountAccess.role === 'candidate' : true) &&
            currentJobApplyStep === 'login') {
            if (jobDetailsByCode?.id) {
                dispatch(applyForJob({ jobId: jobDetailsByCode?.id.toString(), postingSource: postingSource }));
            }
        }
    }, [accountAccess.role, currentJobApplyStep, dispatch, isAccountLoggedIn, jobDetailsByCode?.id, postingSource]);

    // Reset all on unmount
    useEffect(() => {
        return () => {
            dispatch(resetUploadApplicantResume());
            dispatch(resetSignUpCandidate());
            dispatch(resetApplyForJob());
        }
    }, [dispatch]);

    const renderCurrentAction = () => {
        switch (currentJobApplyStep) {
            case 'email':
                return (<>
                    <ApplyJobEmailValidation candidateEmail={candidateEmail} setCandidateEmail={setCandidateEmail}
                        setCurrentJobApplyStep={setCurrentJobApplyStep} />
                </>)
            case 'resume_upload':
                return (<>
                    <ApplyJobUploadResume selectedResume={selectedResume} setSelectedResume={setSelectedResume}
                        navigatedFrom='resume_upload' setCurrentJobApplyStep={setCurrentJobApplyStep} />
                </>)
            case 'resume_update':
                return (<>
                    <ApplyJobResumeSelect selectedResume={selectedResume} setSelectedResume={setSelectedResume}
                        navigatedFrom='resume_upload' setCurrentJobApplyStep={setCurrentJobApplyStep}
                        uploadResume={(isResumeUpdate: boolean) => uploadResume(isResumeUpdate)} />
                </>)
            case 'social_sign_up':
                return (<>
                    <ApplyJobUploadResume selectedResume={selectedResume} setSelectedResume={setSelectedResume}
                        navigatedFrom='social_sign_up' setCurrentJobApplyStep={setCurrentJobApplyStep}
                        uploadResume={(isResumeUpdate: boolean) => uploadResume(isResumeUpdate)} />
                </>)
            case 'login':
                return (<>
                    <ApplyJobLogin setCurrentJobApplyStep={setCurrentJobApplyStep} email={candidateEmail}
                        navigatedFrom={selectedResume ? 'resume_upload' : 'email'} />
                </>)
            case 'sign_up':
                return (<>
                    {uploadResumeStatus === 'failed' &&
                        <Alert severity='error' action={<ShButton onClick={() => uploadResume(false)}>Retry</ShButton>}>
                            {uploadResumeResponse}</Alert>
                    }
                    {((!checkIfAccountExistsResponse?.exists &&
                        checkIfAccountExistsStatus === 'success' &&
                        candidateInfoInResume?.email === undefined) ||
                        (candidateDetails?.jobApplicationId && !candidateDetails.resumeComplete)) &&
                        <ApplyJobUploadResume selectedResume={selectedResume} setSelectedResume={setSelectedResume}
                            navigatedFrom='email' />}
                    <ApplyJobSignUp setCurrentJobApplyStep={setCurrentJobApplyStep}
                        prefillEmail={candidateEmail} selectedResume={selectedResume}
                        uploadResume={(isResumeUpdate: boolean) => uploadResume(isResumeUpdate)}
                        navigatedFrom={(!checkIfAccountExistsResponse?.exists &&
                            checkIfAccountExistsStatus === 'success' &&
                            candidateInfoInResume?.email === undefined) ? 'email' :
                            getCandidateDetailsStatus === 'success' && candidateDetails?.jobApplicationId &&
                                !candidateDetails.resumeComplete ? 'login' : 'resume_upload'} />
                </>)
            default:
                break;
        }
    }

    return (<>
        {getJobApplicationRequirementsStatus === 'failed' &&
            <Alert severity='error' action={
                <Stack direction='row' columnGap={1}>
                    <ShButton
                        onClick={() => jobDetailsByCode?.id && dispatch(getJobRequirements({ jobId: jobDetailsByCode?.id }))}
                    >Retry</ShButton>
                    <IconButton size="small" onClick={() => dispatch(resetGetJobRequirements())}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            }>
                {getJobApplicationRequirementsResponse}</Alert>
        }
        {updateCandidateResumeStatus === 'failed' &&
            <Alert severity='error' onClose={() => dispatch(resetUpdateCandidateResume())}>
                {updateCandidateResumeResponse}</Alert>}
        {getCandidateDetailsStatus === 'failed' &&
            <Alert severity='error' onClose={() => dispatch(resetGetCandidateDetails())}>
                {getCandidateDetailsResponse}</Alert>
        }
        {uploadResumeStatus === 'pending' &&
            <Alert severity='info'>Uploading resume ...</Alert>
        }
        {uploadResumeStatus === 'failed' &&
            <Alert severity='error' onClose={() => dispatch(resetUploadApplicantResume())}>
                {uploadResumeResponse}</Alert>
        }
        {candidateSignUpStatus === 'failed' && <>
            {
                candidateSignUpErrorCode === 'USER_EXIST' ?
                    <Stack direction='row' alignItems='center' width={'100%'} justifyContent='center'>
                        <Alert severity='info'>{candidateSignUpResponse}</Alert>
                        <ShButton onClick={() => { setCurrentJobApplyStep('login'); dispatch(resetSignUpCandidate()) }}>Sign In</ShButton>
                    </Stack>
                    : <Alert severity='error'>{candidateSignUpResponse}</Alert>
            }
        </>}
        {jobApplicationStatus === 'pending' &&
            <Alert severity='info'>
                <Typography variant="body2">Applying for Job...</Typography>
            </Alert>
        }
        {jobApplicationStatus === 'pending' || getCandidateDetailsStatus === 'pending' || uploadResumeStatus === 'pending' ?
            <CircularProgress /> : !isFromCandidateDashboard && renderCurrentAction()}
    </>)
}