import { JobDetailsPage } from "Modules/Subdomain/CareerPages/JobDetailsPage";
import { EmployerSubDomainWrapper } from "Modules/Subdomain/EmployerSubDomainLayout/EmployerSubDomainWrapper";
import { Route, Routes } from "react-router-dom";
import { CareerPagesHome } from "../Modules/Subdomain/CareerPages/CareerPagesHome";
import { IsSmScreen } from "helpers/hooks";

export const EmployerSubDomainRouter = () => {
    const isMdScreen = IsSmScreen();

    return (<>
      {isMdScreen ? (
        <Routes>
            <Route path="/" element={<EmployerSubDomainWrapper />} >
                <Route path="/" element={<CareerPagesHome />} />
                <Route path="/:jobId" element={<JobDetailsPage />} />
            </Route>
        </Routes>
      ):(
          <Routes>
            <Route path="/" element={<EmployerSubDomainWrapper />}>
                <Route path="/" element={<CareerPagesHome />}>
                    <Route path=":jobId" element={<JobDetailsPage />} />
                </Route>
            </Route>
        </Routes>
      )
      }
    </>)
}
