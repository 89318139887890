export const HrManagerJobDescription = {
    title: "HR Manager Job Description",
    jobRole: "An HR Manager is responsible for overseeing all aspects of the human resources function within an organization. They develop and implement HR strategies, policies, and programs to support the organization's goals and objectives. HR Managers play a key role in managing employee relations, recruitment, performance management, training and development, and HR compliance.",
    preRead: "Use this HR Manager job description template to advertise open positions for an HR Manager in your company. Customize the responsibilities and qualifications to align with your organization's needs and culture.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Manager, you will lead the HR department and provide strategic guidance on HR initiatives. Your responsibilities will include recruiting and hiring employees, managing employee relations, developing HR policies and procedures, and ensuring compliance with employment laws and regulations. You will collaborate with senior management to align HR strategies with organizational goals and promote a positive work culture."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement HR strategies, policies, and programs to support organizational objectives.",
                "Manage the recruitment and selection process, including sourcing candidates, conducting interviews, and making hiring decisions.",
                "Oversee employee relations activities, including conflict resolution, disciplinary actions, and performance improvement plans.",
                "Develop and administer employee benefits programs, including health insurance, retirement plans, and leave policies.",
                "Provide guidance and support to managers and employees on HR-related matters, including performance management, career development, and employee relations.",
                "Ensure compliance with employment laws and regulations, including equal employment opportunity (EEO) laws, labor laws, and workplace safety regulations.",
                "Manage HR budget and resources effectively.",
                "Lead and mentor HR staff, fostering a collaborative and high-performing team environment.",
                "Conduct HR audits and assessments to identify areas for improvement and ensure HR practices are aligned with best practices and legal requirements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Human Resources, Business Administration, or related field; Master’s Degree preferred.",
                "Certification in HR management (e.g., PHR, SPHR) preferred.",
                "Proven experience as an HR Manager or similar role.",
                "In-depth knowledge of HR functions, policies, and best practices.",
                "Strong leadership and interpersonal skills.",
                "Excellent communication and negotiation abilities.",
                "Analytical and problem-solving skills.",
                "Ability to handle confidential information with discretion.",
                "Proficiency in HRIS (Human Resources Information Systems) and MS Office.",
                "Knowledge of employment laws and regulations."
            ]
        }
    ]
};

export const RecruitmentSpecialistJobDescription = {
    title: "Recruitment Specialist Job Description",
    jobRole: "A Recruitment Specialist is responsible for sourcing, screening, and hiring qualified candidates to meet the organization's staffing needs. They collaborate with hiring managers to understand job requirements, attract candidates through various channels, and manage the recruitment process from start to finish.",
    preRead: "Use this Recruitment Specialist job description template to advertise open positions for a Recruitment Specialist in your company. Customize the responsibilities and qualifications to match your organization's requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Recruitment Specialist, you will play a critical role in identifying and attracting top talent to the organization. You will work closely with hiring managers to understand their staffing needs, develop recruitment strategies, and source candidates through job boards, social media, and other channels. Your goal is to ensure a smooth and efficient recruitment process while attracting high-quality candidates who are the right fit for the organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Partner with hiring managers to understand staffing needs and develop recruitment strategies.",
                "Source candidates through various channels, including job boards, social media, networking, and employee referrals.",
                "Review resumes and applications, conduct initial screenings, and shortlist candidates for interviews.",
                "Coordinate and schedule interviews with hiring managers and candidates.",
                "Conduct reference checks and background screenings for selected candidates.",
                "Extend job offers and negotiate employment terms with selected candidates.",
                "Ensure a positive candidate experience throughout the recruitment process.",
                "Maintain accurate and up-to-date candidate records in the applicant tracking system (ATS).",
                "Collaborate with HR team members on recruitment-related projects and initiatives.",
                "Stay informed about industry trends and best practices in recruitment and talent acquisition."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Human Resources, Business Administration, or related field.",
                "Proven experience as a Recruitment Specialist or similar role.",
                "Knowledge of recruitment strategies, techniques, and best practices.",
                "Familiarity with applicant tracking systems (ATS) and HRIS.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational and time-management abilities.",
                "Ability to multitask and prioritize tasks effectively.",
                "Detail-oriented with a focus on accuracy.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Team player with a collaborative mindset."
            ]
        }
    ]
};

export const HrAssistantJobDescription = {
    title: "HR Assistant Job Description",
    jobRole: "An HR Assistant provides administrative support to the HR department and assists with various HR functions, including recruitment, employee onboarding, record-keeping, and HR compliance. They play a key role in maintaining accurate HR records, responding to employee inquiries, and supporting HR projects and initiatives.",
    preRead: "Use this HR Assistant job description template to advertise open positions for an HR Assistant in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Assistant, you will support the HR department in day-to-day administrative tasks and HR activities. Your responsibilities will include maintaining HR records, assisting with recruitment and onboarding processes, and responding to employee inquiries. This role requires strong organizational skills, attention to detail, and the ability to handle confidential information with discretion."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist with the recruitment process, including job postings, resume screening, and scheduling interviews.",
                "Coordinate employee onboarding activities, including new hire orientation and paperwork.",
                "Maintain HR records and databases, including employee files, attendance records, and HRIS.",
                "Respond to employee inquiries regarding HR policies, benefits, and procedures.",
                "Assist with HR projects and initiatives, such as performance management, employee engagement surveys, and HR compliance audits.",
                "Prepare and distribute HR-related documents, such as offer letters, contracts, and policy updates.",
                "Assist with employee offboarding processes, including exit interviews and paperwork.",
                "Support the HR team in organizing HR events, training sessions, and employee recognition programs.",
                "Ensure compliance with HR policies, procedures, and legal requirements.",
                "Perform other administrative tasks as assigned by HR management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Human Resources, Business Administration, or related field (or equivalent experience).",
                "Previous experience in an administrative role, preferably in HR.",
                "Knowledge of HR functions, policies, and procedures.",
                "Familiarity with HRIS (Human Resources Information Systems) and MS Office.",
                "Strong organizational and time-management skills.",
                "Excellent communication and interpersonal abilities.",
                "Ability to handle confidential information with discretion.",
                "Detail-oriented with a focus on accuracy.",
                "Ability to work effectively in a team environment.",
                "Willingness to learn and adapt to changing priorities."
            ]
        }
    ]
};

export const TalentAcquisitionSpecialistJobDescription = {
    title: "Talent Acquisition Specialist Job Description",
    jobRole: "A Talent Acquisition Specialist is responsible for sourcing, attracting, and hiring top talent to meet the organization's staffing needs. They collaborate with hiring managers to understand job requirements, develop recruitment strategies, and execute the recruitment process from start to finish.",
    preRead: "Use this Talent Acquisition Specialist job description template to advertise open positions for a Talent Acquisition Specialist in your company. Customize the responsibilities and qualifications to match your organization's requirements.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Talent Acquisition Specialist, you will play a critical role in identifying and engaging qualified candidates for open positions within the organization. You will work closely with hiring managers to develop recruitment strategies, source candidates through various channels, and manage the recruitment process. Your goal is to attract high-quality candidates who are the right fit for the organization and contribute to its success."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Partner with hiring managers to understand staffing needs and develop recruitment strategies.",
                "Source candidates through various channels, including job boards, social media, networking, and employee referrals.",
                "Review resumes and applications, conduct initial screenings, and shortlist candidates for interviews.",
                "Coordinate and schedule interviews with hiring managers and candidates.",
                "Conduct reference checks and background screenings for selected candidates.",
                "Extend job offers and negotiate employment terms with selected candidates.",
                "Ensure a positive candidate experience throughout the recruitment process.",
                "Maintain accurate and up-to-date candidate records in the applicant tracking system (ATS).",
                "Collaborate with HR team members on recruitment-related projects and initiatives.",
                "Stay informed about industry trends and best practices in recruitment and talent acquisition."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Human Resources, Business Administration, or related field.",
                "Proven experience as a Talent Acquisition Specialist or similar role.",
                "Knowledge of recruitment strategies, techniques, and best practices.",
                "Familiarity with applicant tracking systems (ATS) and HRIS.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational and time-management abilities.",
                "Ability to multitask and prioritize tasks effectively.",
                "Detail-oriented with a focus on accuracy.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Team player with a collaborative mindset."
            ]
        }
    ]
};

export const HrCoordinatorJobDescription = {
    title: "HR Coordinator Job Description",
    jobRole: "An HR Coordinator supports the HR department in various administrative and operational tasks, including recruitment, onboarding, employee records management, and HR compliance. They play a key role in ensuring the smooth functioning of HR processes and programs.",
    preRead: "Use this HR Coordinator job description template to advertise open positions for an HR Coordinator in your company. Customize the responsibilities and qualifications to align with your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Coordinator, you will provide administrative support to the HR department and assist with various HR functions. Your responsibilities will include coordinating recruitment activities, assisting with employee onboarding, maintaining HR records, and ensuring compliance with HR policies and procedures. This role requires strong organizational skills, attention to detail, and the ability to handle sensitive information with confidentiality."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist with recruitment activities, including job postings, resume screening, and scheduling interviews.",
                "Coordinate employee onboarding activities, including new hire orientation and paperwork.",
                "Maintain accurate employee records and HR databases.",
                "Assist with HR compliance activities, including updating policies and procedures as needed.",
                "Respond to employee inquiries regarding HR policies, benefits, and procedures.",
                "Prepare and distribute HR-related documents, such as offer letters, contracts, and policy updates.",
                "Assist with employee offboarding processes, including exit interviews and paperwork.",
                "Support HR projects and initiatives, such as performance management, employee engagement, and diversity initiatives.",
                "Coordinate HR events and training sessions, including scheduling and logistics.",
                "Perform other administrative tasks as assigned by HR management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Human Resources, Business Administration, or related field (or equivalent experience).",
                "Previous experience in an administrative role, preferably in HR.",
                "Knowledge of HR functions, policies, and procedures.",
                "Familiarity with HRIS (Human Resources Information Systems) and MS Office.",
                "Strong organizational and time-management skills.",
                "Excellent communication and interpersonal abilities.",
                "Ability to handle confidential information with discretion.",
                "Detail-oriented with a focus on accuracy.",
                "Ability to work effectively in a team environment.",
                "Willingness to learn and adapt to changing priorities."
            ]
        }
    ]
};


export const CompensationAndBenefitsManagerJobDescription = {
    title: "Compensation and Benefits Manager Job Description",
    jobRole: "A Compensation and Benefits Manager is responsible for designing, implementing, and managing compensation and benefits programs to attract, retain, and motivate employees. They analyze salary data, develop pay structures, administer employee benefits programs, and ensure compliance with labor laws and regulations.",
    preRead: "Use this Compensation and Benefits Manager job description template to advertise open positions for a Compensation and Benefits Manager in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Compensation and Benefits Manager, you will be responsible for designing, implementing, and managing compensation and benefits programs to attract, retain, and motivate employees. Your role will involve analyzing salary data, developing pay structures, administering employee benefits programs, and ensuring compliance with labor laws and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Design and implement compensation and benefits programs that align with organizational goals and industry best practices.",
                "Analyze salary data, market trends, and labor market conditions to determine competitive pay rates and salary ranges.",
                "Develop and maintain pay structures, salary ranges, and job classifications based on job evaluations and market data.",
                "Administer employee benefits programs, including health insurance, retirement plans, and other fringe benefits.",
                "Educate employees about their benefits options, enrollment procedures, and eligibility criteria.",
                "Manage the annual benefits enrollment process, including communication, enrollment, and changes.",
                "Ensure compliance with federal, state, and local laws and regulations governing compensation and benefits.",
                "Coordinate with HRIS and payroll teams to ensure accurate and timely processing of compensation and benefits-related transactions.",
                "Conduct salary surveys and benchmarking studies to evaluate the competitiveness of compensation and benefits programs.",
                "Monitor and analyze compensation and benefits costs, trends, and utilization to make recommendations for program improvements.",
                "Provide guidance and support to managers and employees on compensation and benefits-related matters."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, Finance, or related field; HR certification (e.g., CCP, CBP) preferred.",
                "Proven experience as a Compensation and Benefits Manager or similar role.",
                "Strong understanding of compensation and benefits principles, practices, and regulations.",
                "Experience with compensation benchmarking, salary surveys, and job evaluation methodologies.",
                "Knowledge of employee benefits programs, including health insurance, retirement plans, and wellness programs.",
                "Familiarity with HRIS, payroll systems, and benefits administration platforms.",
                "Excellent analytical and problem-solving skills, with the ability to analyze data, identify trends, and make data-driven recommendations.",
                "Strong communication and interpersonal skills, with the ability to effectively communicate complex information to diverse audiences.",
                "Ability to prioritize tasks, manage multiple projects simultaneously, and meet deadlines in a fast-paced environment.",
                "Attention to detail and accuracy in compensation and benefits administration and documentation."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and certifications.",
                "Opportunities for career advancement and growth within the HR field.",
                "Positive and inclusive work environment with a focus on employee well-being and engagement."
            ]
        }
    ]
};

export const EmployeeRelationsSpecialistJobDescription = {
    title: "Employee Relations Specialist Job Description",
    jobRole: "An Employee Relations Specialist is responsible for managing employee relations, resolving workplace conflicts, and promoting positive employee morale and engagement. They provide guidance and support to managers and employees on HR policies, procedures, and employment laws.",
    preRead: "Use this Employee Relations Specialist job description template to advertise open positions for an Employee Relations Specialist in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employee Relations Specialist, you will be responsible for managing employee relations, resolving workplace conflicts, and promoting positive employee morale and engagement. Your role will involve providing guidance and support to managers and employees on HR policies, procedures, and employment laws to create a positive and productive work environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Serve as the primary point of contact for employee relations issues, concerns, and inquiries.",
                "Investigate and resolve employee complaints, grievances, and conflicts in a fair and timely manner.",
                "Provide guidance and support to managers and employees on HR policies, procedures, and employment laws.",
                "Conduct workplace investigations and interviews to gather information and evidence related to employee relations issues.",
                "Advise management on employee discipline, performance improvement plans, and termination decisions.",
                "Develop and implement employee relations programs, initiatives, and training to promote positive employee relations and communication.",
                "Collaborate with HR partners, legal counsel, and other stakeholders to ensure compliance with employment laws and regulations.",
                "Monitor and analyze employee relations trends and metrics to identify areas for improvement and proactively address issues.",
                "Create and maintain documentation related to employee relations investigations, resolutions, and outcomes.",
                "Participate in HR projects and initiatives to support organizational goals and objectives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or related field; HR certification (e.g., PHR, SHRM-CP) preferred.",
                "Proven experience in employee relations, HR investigations, or related field.",
                "Strong understanding of employment laws, regulations, and HR best practices.",
                "Excellent interpersonal and communication skills, with the ability to build rapport and trust with employees at all levels of the organization.",
                "Ability to maintain confidentiality and handle sensitive information with discretion and professionalism.",
                "Strong problem-solving and conflict resolution skills, with the ability to assess situations objectively and provide effective solutions.",
                "Attention to detail and accuracy in documentation and record-keeping.",
                "Ability to work independently and collaboratively in a team environment.",
                "Proficiency in MS Office and HRIS software.",
                "Flexibility and adaptability to work in a fast-paced and dynamic environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $55,000 - $75,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and certifications.",
                "Opportunities for career advancement and growth within the HR field.",
                "Positive and inclusive work environment with a focus on employee well-being and engagement."
            ]
        }
    ]
};

export const TrainingAndDevelopmentManagerJobDescription = {
    title: "Training and Development Manager Job Description",
    jobRole: "A Training and Development Manager is responsible for designing, implementing, and evaluating training programs to enhance the skills and knowledge of employees. They assess training needs, develop curriculum, and coordinate training activities to support organizational goals and employee development.",
    preRead: "Use this Training and Development Manager job description template to advertise open positions for a Training and Development Manager in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Training and Development Manager, you will be responsible for designing, implementing, and evaluating training programs to enhance the skills and knowledge of employees. Your role will involve assessing training needs, developing curriculum, and coordinating training activities to support organizational goals and employee development."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess training needs through surveys, interviews, and consultations with managers and employees.",
                "Develop and implement training strategies, plans, and programs to address organizational needs and goals.",
                "Design training curriculum, materials, and resources based on identified needs and learning objectives.",
                "Coordinate and deliver training sessions, workshops, and seminars, both in-person and online.",
                "Evaluate the effectiveness of training programs through assessments, feedback, and performance metrics.",
                "Monitor training budgets, expenses, and resources to ensure cost-effective delivery of training initiatives.",
                "Collaborate with subject matter experts, trainers, and vendors to develop and deliver specialized training programs.",
                "Manage training logistics, including scheduling, facilities, equipment, and materials.",
                "Provide coaching and support to trainers and facilitators to ensure quality delivery of training.",
                "Stay updated on industry trends, best practices, and technologies in training and development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Education, Business Administration, or related field; Master’s degree preferred.",
                "Proven experience in training and development, instructional design, or related field.",
                "Strong understanding of adult learning principles, instructional design methodologies, and training techniques.",
                "Excellent presentation and facilitation skills, with the ability to engage and motivate learners.",
                "Ability to develop and deliver training programs using a variety of formats, including e-learning platforms.",
                "Experience with learning management systems (LMS) and training evaluation tools.",
                "Strong project management and organizational skills, with the ability to manage multiple priorities and deadlines.",
                "Excellent communication and interpersonal abilities, with the ability to collaborate effectively with stakeholders at all levels.",
                "Analytical mindset with the ability to assess training needs, evaluate program effectiveness, and make data-driven decisions.",
                "Commitment to continuous learning and professional development."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $65,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training programs and certifications.",
                "Opportunities for career advancement and growth within the HR and training field.",
                "Positive and inclusive work environment with a focus on employee development and engagement."
            ]
        }
    ]
};

export const HRGeneralistJobDescription = {
    title: "HR Generalist Job Description",
    jobRole: "An HR Generalist is responsible for providing support in various HR functions, including recruitment, employee relations, performance management, and HR administration. They assist with implementing HR policies and procedures, resolving employee issues, and ensuring compliance with labor laws and regulations.",
    preRead: "Use this HR Generalist job description template to advertise open positions for an HR Generalist in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Generalist, you will be responsible for providing support in various HR functions, including recruitment, employee relations, performance management, and HR administration. Your role will involve assisting with implementing HR policies and procedures, resolving employee issues, and ensuring compliance with labor laws and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist with recruitment processes, including job postings, screening resumes, scheduling interviews, and conducting background checks.",
                "Support employee onboarding and offboarding processes, including orientation sessions, paperwork, and exit interviews.",
                "Provide guidance and support to employees and managers on HR policies, procedures, and best practices.",
                "Assist with performance management processes, including goal setting, performance evaluations, and performance improvement plans.",
                "Handle employee relations issues, including conflicts, complaints, and disciplinary actions, in accordance with company policies and legal requirements.",
                "Maintain HR records and databases, including personnel files, time and attendance records, and HRIS data.",
                "Assist with benefits administration, including enrollment, changes, and inquiries related to health insurance, retirement plans, and other employee benefits.",
                "Coordinate training and development activities, including scheduling training sessions, tracking attendance, and evaluating training effectiveness.",
                "Ensure compliance with labor laws and regulations, including EEO, FMLA, ADA, and OSHA requirements.",
                "Support HR projects and initiatives, such as policy updates, HR audits, and employee engagement activities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or related field; HR certification (e.g., PHR, SHRM-CP) preferred.",
                "Proven experience in HR roles, with knowledge of HR principles, practices, and regulations.",
                "Strong understanding of recruitment, onboarding, performance management, and employee relations processes.",
                "Excellent communication and interpersonal skills, with the ability to interact effectively with employees at all levels.",
                "Strong organizational and time management skills, with the ability to prioritize tasks and manage multiple projects simultaneously.",
                "Attention to detail and accuracy in HR administration and recordkeeping.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Proficiency in HRIS systems, Microsoft Office Suite, and other HR software tools.",
                "Commitment to continuous learning and professional development in the field of HR.",
                "Team player with a positive attitude and willingness to collaborate with colleagues."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $70,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including HR certification programs and training workshops.",
                "Opportunities for career advancement and growth within the HR field.",
                "Positive and inclusive work environment with a focus on employee development and engagement."
            ]
        }
    ]
};

export const OrganizationalDevelopmentSpecialistJobDescription = {
    title: "Organizational Development Specialist Job Description",
    jobRole: "An Organizational Development Specialist is responsible for planning, implementing, and managing programs and initiatives to enhance organizational effectiveness and employee engagement. They assess organizational needs, develop strategies, and facilitate change management processes to support the organization's growth and development.",
    preRead: "Use this Organizational Development Specialist job description template to advertise open positions for an Organizational Development Specialist in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Organizational Development Specialist, you will be responsible for planning, implementing, and managing programs and initiatives to enhance organizational effectiveness and employee engagement. Your role will involve assessing organizational needs, developing strategies, and facilitating change management processes to support the organization's growth and development."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct organizational assessments to identify strengths, weaknesses, and areas for improvement.",
                "Develop and implement organizational development strategies and initiatives to drive performance improvement and employee engagement.",
                "Design and deliver training and development programs to enhance employee skills and competencies.",
                "Facilitate change management processes to support organizational change initiatives, including communication, stakeholder engagement, and training.",
                "Lead talent management initiatives, including succession planning, career development, and performance management.",
                "Facilitate team-building activities and workshops to foster collaboration, communication, and teamwork.",
                "Provide coaching and support to managers and employees on organizational development issues and initiatives.",
                "Evaluate the effectiveness of organizational development programs and initiatives through metrics and feedback mechanisms.",
                "Stay updated on industry trends and best practices in organizational development and change management.",
                "Collaborate with HR, leadership, and other stakeholders to align organizational development efforts with business goals and objectives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Organizational Development, Human Resources, Psychology, or related field; Master’s degree preferred.",
                "Proven experience in organizational development, change management, or related field.",
                "Strong understanding of organizational development principles, theories, and methodologies.",
                "Excellent communication and interpersonal skills, with the ability to engage and influence stakeholders at all levels.",
                "Strong project management skills, with the ability to plan, execute, and evaluate organizational development initiatives.",
                "Ability to work collaboratively in a team environment and facilitate group discussions and workshops.",
                "Analytical and problem-solving skills, with the ability to assess complex organizational issues and develop effective solutions.",
                "High level of emotional intelligence and empathy, with the ability to build trust and rapport with employees.",
                "Proficiency in Microsoft Office Suite and other relevant software tools.",
                "Commitment to continuous learning and professional development in the field of organizational development."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conference attendance.",
                "Opportunities for career advancement and growth within the organization.",
                "Positive and supportive work environment with a focus on employee development and engagement."
            ]
        }
    ]
};

export const WorkforcePlanningAnalystJobDescription = {
    title: "Workforce Planning Analyst Job Description",
    jobRole: "A Workforce Planning Analyst is responsible for analyzing workforce data, trends, and metrics to support strategic workforce planning and decision-making. They assess current and future workforce needs, develop staffing models, and recommend strategies to optimize workforce performance and efficiency.",
    preRead: "Use this Workforce Planning Analyst job description template to advertise open positions for a Workforce Planning Analyst in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Workforce Planning Analyst, you will be responsible for analyzing workforce data, trends, and metrics to support strategic workforce planning and decision-making. Your role will involve assessing current and future workforce needs, developing staffing models, and recommending strategies to optimize workforce performance and efficiency."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect, analyze, and interpret workforce data, including headcount, turnover, demographics, and performance metrics.",
                "Identify trends and patterns in workforce data to inform strategic workforce planning initiatives.",
                "Develop workforce forecasting models to project future staffing needs based on business goals and objectives.",
                "Collaborate with HR and business leaders to understand workforce requirements and priorities.",
                "Recommend strategies to address workforce gaps and optimize workforce utilization, including recruitment, training, and talent development.",
                "Evaluate the effectiveness of workforce planning initiatives and make recommendations for continuous improvement.",
                "Provide insights and recommendations to senior management on workforce planning strategies and initiatives.",
                "Stay updated on industry trends and best practices in workforce planning and talent management.",
                "Communicate workforce planning findings and recommendations to stakeholders through reports, presentations, and dashboards."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, Statistics, or related field; Master’s degree preferred.",
                "Proven experience in workforce planning, HR analytics, or related field.",
                "Strong analytical and quantitative skills, with the ability to collect, analyze, and interpret large datasets.",
                "Proficiency in data analysis tools and software, such as Excel, SQL, and statistical analysis software.",
                "Knowledge of workforce planning principles, methodologies, and best practices.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with cross-functional teams.",
                "Strong problem-solving skills, with the ability to identify issues, analyze root causes, and develop effective solutions.",
                "Ability to work independently and manage multiple projects simultaneously in a fast-paced environment.",
                "Attention to detail and accuracy in data analysis and reporting.",
                "Commitment to continuous learning and professional development in the field of workforce planning and analytics."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $65,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conference attendance.",
                "Opportunities for career advancement and growth within the organization.",
                "Positive and supportive work environment with a focus on employee development and engagement."
            ]
        }
    ]
};

export const HRISAnalystJobDescription = {
    title: "HRIS Analyst Job Description",
    jobRole: "An HRIS (Human Resources Information Systems) Analyst is responsible for managing and optimizing HRIS software and systems to support human resources functions within an organization. They oversee system implementation, configuration, and maintenance, as well as provide technical support and training to HR staff.",
    preRead: "Use this HRIS Analyst job description template to advertise open positions for an HRIS Analyst in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HRIS Analyst, you will be responsible for managing and optimizing HRIS software and systems to support human resources functions within an organization. Your role will involve overseeing system implementation, configuration, and maintenance, as well as providing technical support and training to HR staff."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and administer HRIS software and systems, including system configuration, customization, and maintenance.",
                "Collaborate with HR and IT teams to assess HRIS needs, identify system requirements, and recommend solutions.",
                "Lead HRIS implementation projects, including system upgrades, migrations, and integrations.",
                "Configure and customize HRIS modules and workflows to support HR processes, such as recruitment, onboarding, performance management, and payroll.",
                "Ensure data accuracy and integrity within HRIS systems by conducting regular audits and troubleshooting data discrepancies.",
                "Provide technical support and training to HR staff on HRIS functionality, processes, and best practices.",
                "Develop and maintain HRIS documentation, including user guides, policies, and procedures.",
                "Stay updated on HRIS trends, technologies, and best practices to recommend system enhancements and improvements.",
                "Collaborate with vendors and external partners to resolve technical issues and optimize HRIS performance.",
                "Support HR reporting and analytics initiatives by extracting and analyzing data from HRIS systems to generate reports and insights."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Information Technology, Computer Science, or related field.",
                "Proven experience in HRIS administration, configuration, and support.",
                "Strong technical skills, including proficiency in HRIS software and systems (e.g., Workday, SAP, Oracle), SQL, and reporting tools.",
                "Experience leading HRIS implementation projects from initiation to completion.",
                "Excellent analytical and problem-solving skills, with the ability to troubleshoot technical issues and propose effective solutions.",
                "Strong communication and interpersonal skills, with the ability to collaborate effectively with cross-functional teams.",
                "Detail-oriented approach with a focus on data accuracy and integrity.",
                "Ability to prioritize tasks and manage multiple projects simultaneously in a fast-paced environment.",
                "Commitment to continuous learning and professional development in the field of HR technology.",
                "Certifications in HRIS systems or related technologies (e.g., Workday Certified Professional) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $95,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certification programs.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const PayrollAdministratorJobDescription = {
    title: "Payroll Administrator Job Description",
    jobRole: "A Payroll Administrator is responsible for managing payroll processes, ensuring accurate and timely payment of wages to employees, and maintaining payroll records. They calculate salaries, wages, deductions, and taxes, and address payroll-related inquiries and issues.",
    preRead: "Use this Payroll Administrator job description template to advertise open positions for a Payroll Administrator in your company. Customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Payroll Administrator, you will be responsible for managing payroll processes, ensuring accurate and timely payment of wages to employees, and maintaining payroll records. Your role will involve calculating salaries, wages, deductions, and taxes, and addressing payroll-related inquiries and issues."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Process payroll transactions, including salary adjustments, bonuses, commissions, and overtime.",
                "Calculate salaries, wages, deductions, and taxes based on employee data and payroll regulations.",
                "Ensure accurate and timely payment of wages to employees through direct deposit, checks, or electronic payments.",
                "Maintain payroll records and documentation, including employee profiles, tax forms, and timesheets.",
                "Address payroll-related inquiries and issues from employees, managers, and external stakeholders.",
                "Verify and reconcile payroll data to ensure accuracy and compliance with regulatory requirements.",
                "Prepare and distribute payroll reports, summaries, and analyses to management and other stakeholders.",
                "Stay updated on payroll laws, regulations, and best practices to ensure compliance and mitigate risks.",
                "Collaborate with HR, finance, and accounting teams to ensure payroll data integrity and accuracy.",
                "Assist with payroll audits and investigations as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Accounting, Finance, Business Administration, or related field preferred.",
                "Proven experience as a Payroll Administrator or similar role.",
                "In-depth knowledge of payroll processes, regulations, and compliance requirements.",
                "Experience with payroll software and systems (e.g., ADP, Paychex, SAP, Workday).",
                "Strong mathematical and analytical skills, with attention to detail and accuracy.",
                "Excellent communication and interpersonal abilities.",
                "Ability to prioritize tasks and manage time effectively to meet payroll deadlines.",
                "Discretion and integrity in handling confidential payroll information.",
                "Problem-solving skills to address payroll-related issues and inquiries.",
                "Certifications in payroll administration or related fields (e.g., CPP, FPC) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $45,000 - $65,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certification programs.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmploymentLawAttorneyJobDescription = {
    title: "Employment Law Attorney Job Description",
    jobRole: "An Employment Law Attorney provides legal advice and representation to clients on matters related to employment law, including workplace discrimination, harassment, wrongful termination, wage and hour disputes, and employment contracts. They interpret labor laws, draft legal documents, and represent clients in court or during mediation and arbitration proceedings.",
    preRead: "Use this Employment Law Attorney job description template to advertise open positions for an Employment Law Attorney in your law firm or organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employment Law Attorney, you will provide legal counsel and representation to clients on a wide range of employment-related matters. Your role will involve interpreting labor laws, drafting legal documents, advising clients on compliance issues, and representing clients in litigation or alternative dispute resolution proceedings."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide legal advice and counsel to clients on employment law matters, including workplace policies, discrimination, harassment, and employee rights.",
                "Interpret and apply federal, state, and local labor laws and regulations to client situations.",
                "Draft and review employment-related documents, such as contracts, agreements, policies, and handbooks.",
                "Represent clients in negotiations, mediations, arbitrations, and administrative proceedings.",
                "Prepare and file legal documents, pleadings, and motions with courts and government agencies.",
                "Conduct legal research and analysis on employment law issues and precedent.",
                "Advise clients on compliance with employment laws, regulations, and best practices.",
                "Conduct investigations into workplace complaints and allegations of misconduct.",
                "Provide training to clients on employment law topics and legal developments.",
                "Stay updated on changes in employment laws and regulations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Juris Doctor (JD) degree from an accredited law school.",
                "Admission to the state bar and license to practice law in the jurisdiction.",
                "Specialization or certification in employment law preferred.",
                "Proven experience as an Employment Law Attorney or in a related legal field.",
                "In-depth knowledge of employment laws, regulations, and legal principles.",
                "Strong analytical and problem-solving skills.",
                "Excellent written and verbal communication skills.",
                "Ability to work independently and collaboratively in a team environment.",
                "Negotiation, mediation, and dispute resolution skills.",
                "Attention to detail and accuracy in legal analysis and documentation."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $150,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including continuing legal education.",
                "Opportunities for career advancement and growth within the firm or organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const DiversityAndInclusionCoordinatorJobDescription = {
    title: "Diversity and Inclusion Coordinator Job Description",
    jobRole: "A Diversity and Inclusion Coordinator develops and implements initiatives to promote diversity, equity, and inclusion within an organization. They collaborate with HR departments, management, and employee resource groups to create inclusive policies, programs, and training. Their goal is to foster a diverse and welcoming workplace culture where all employees feel valued and respected.",
    preRead: "Use this Diversity and Inclusion Coordinator job description template to advertise open positions for a Diversity and Inclusion Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Diversity and Inclusion Coordinator, you will play a key role in promoting diversity, equity, and inclusion within our organization. You will work closely with HR departments, management, and employee resource groups to develop and implement initiatives that foster a culture of belonging and respect."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement diversity and inclusion strategies, policies, and programs.",
                "Collaborate with HR departments to integrate diversity and inclusion into recruitment, hiring, and retention processes.",
                "Facilitate diversity training and workshops for employees and leadership teams.",
                "Provide guidance and support to employee resource groups and affinity networks.",
                "Conduct diversity assessments and analyze workforce demographics and trends.",
                "Promote awareness of diversity and inclusion issues through communication and education initiatives.",
                "Serve as a resource and advisor to management and employees on diversity and inclusion matters.",
                "Monitor and evaluate the effectiveness of diversity and inclusion initiatives and recommend improvements.",
                "Stay updated on diversity and inclusion best practices and legal requirements.",
                "Advocate for diversity and inclusion in organizational decision-making processes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Diversity Studies, Social Sciences, or related field; Master’s degree preferred.",
                "Proven experience in diversity and inclusion roles or related HR positions.",
                "In-depth understanding of diversity, equity, and inclusion concepts and best practices.",
                "Strong interpersonal and communication skills.",
                "Ability to build relationships and collaborate with stakeholders at all levels.",
                "Excellent facilitation and presentation skills.",
                "Analytical and problem-solving abilities.",
                "Project management skills with the ability to prioritize and manage multiple initiatives.",
                "Commitment to promoting diversity, equity, and inclusion in the workplace.",
                "Certifications in diversity and inclusion or related fields are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including diversity and inclusion training.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const LaborRelationsManagerJobDescription = {
    title: "Labor Relations Manager Job Description",
    jobRole: "A Labor Relations Manager oversees the relationship between an organization and its employees, including unions or employee representative groups. They negotiate collective bargaining agreements, resolve disputes, and ensure compliance with labor laws and regulations. Their role involves promoting positive labor relations, addressing grievances, and fostering a productive and harmonious work environment.",
    preRead: "Use this Labor Relations Manager job description template to advertise open positions for a Labor Relations Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Labor Relations Manager, you will be responsible for managing the relationship between our organization and its employees, unions, or employee representative groups. You will negotiate collective bargaining agreements, resolve labor disputes, and ensure compliance with labor laws and regulations to promote positive labor relations and maintain a productive work environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Negotiate and administer collective bargaining agreements.",
                "Represent the organization in labor negotiations and contract discussions.",
                "Resolve labor disputes, grievances, and conflicts through mediation and negotiation.",
                "Ensure compliance with labor laws, regulations, and contractual agreements.",
                "Advise management on labor relations issues and provide guidance on policies and procedures.",
                "Monitor and interpret labor legislation and industry trends to anticipate potential issues.",
                "Collaborate with HR departments to develop strategies for employee engagement and retention.",
                "Conduct training sessions on labor relations topics for management and staff.",
                "Maintain positive relationships with unions or employee representative groups.",
                "Participate in disciplinary hearings and investigations as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Labor Relations, Business Administration, or related field; Master’s degree preferred.",
                "Proven experience in labor relations roles, including negotiation and conflict resolution.",
                "Strong knowledge of labor laws, regulations, and collective bargaining processes.",
                "Excellent communication and negotiation skills.",
                "Ability to build relationships and work effectively with diverse stakeholders.",
                "Analytical and problem-solving abilities.",
                "Ability to handle confidential and sensitive information with discretion.",
                "Experience in employee relations or HR management is a plus.",
                "Certifications in labor relations or related fields are desirable.",
                "Strong ethical standards and integrity in decision-making."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training in labor relations.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRConsultantJobDescription = {
    title: "HR Consultant Job Description",
    jobRole: "An HR Consultant provides expert advice and guidance on human resources matters to organizations. They assess HR needs, develop HR strategies, and implement solutions to improve organizational effectiveness. Their role involves collaborating with HR teams, management, and employees to address HR challenges, enhance HR processes, and support business objectives.",
    preRead: "Use this HR Consultant job description template to advertise open positions for an HR Consultant in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Consultant, you will be responsible for providing expert advice and guidance on human resources matters to our organization. You will assess HR needs, develop HR strategies, and implement solutions to improve organizational effectiveness. Your role will involve collaborating with HR teams, management, and employees to address HR challenges, enhance HR processes, and support business objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess organizational HR needs and develop customized HR solutions and strategies.",
                "Provide expert advice and guidance on HR policies, procedures, and best practices.",
                "Conduct HR audits and assessments to identify areas for improvement.",
                "Develop and implement HR programs, initiatives, and training sessions.",
                "Assist with HR compliance, including employment laws, regulations, and policies.",
                "Conduct employee relations investigations and provide recommendations for resolution.",
                "Collaborate with HR teams, management, and employees to address HR challenges and enhance HR processes.",
                "Provide coaching and support to HR professionals and managers on HR-related matters.",
                "Stay updated on HR trends, industry best practices, and regulatory changes.",
                "Participate in HR projects and initiatives as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or related field; Master’s degree or HR certifications preferred.",
                "Proven experience as an HR Consultant or HR professional.",
                "Strong knowledge of HR principles, practices, and regulations.",
                "Excellent communication and interpersonal skills.",
                "Ability to build relationships and work effectively with diverse stakeholders.",
                "Analytical and problem-solving abilities.",
                "Experience in HR project management and change management.",
                "Ability to handle confidential and sensitive information with discretion.",
                "Proficiency in HRIS and other HR software.",
                "Strong ethical standards and integrity in decision-making."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including HR certifications and training.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const WorkforceDiversityManagerJobDescription = {
    title: "Workforce Diversity Manager Job Description",
    jobRole: "A Workforce Diversity Manager develops and implements diversity, equity, and inclusion (DEI) initiatives to promote a diverse and inclusive workplace. They assess diversity metrics, develop diversity strategies, and collaborate with HR teams to attract, retain, and develop diverse talent. Their role involves fostering a culture of inclusion, implementing diversity training programs, and measuring the effectiveness of diversity initiatives.",
    preRead: "Use this Workforce Diversity Manager job description template to advertise open positions for a Workforce Diversity Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Workforce Diversity Manager, you will be responsible for developing and implementing diversity, equity, and inclusion (DEI) initiatives to promote a diverse and inclusive workplace. You will assess diversity metrics, develop diversity strategies, and collaborate with HR teams to attract, retain, and develop diverse talent. Your role will involve fostering a culture of inclusion, implementing diversity training programs, and measuring the effectiveness of diversity initiatives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement diversity, equity, and inclusion (DEI) strategies and initiatives.",
                "Assess diversity metrics and analyze workforce demographics to identify areas for improvement.",
                "Collaborate with HR teams to integrate DEI principles into recruitment, hiring, and retention practices.",
                "Partner with business leaders to embed DEI into organizational policies, practices, and decision-making processes.",
                "Design and deliver diversity training programs and workshops for employees and managers.",
                "Foster a culture of inclusion by promoting awareness, understanding, and acceptance of diversity.",
                "Provide guidance and support to employee resource groups (ERGs) and diversity councils.",
                "Monitor and evaluate the effectiveness of diversity initiatives and make recommendations for improvement.",
                "Stay updated on DEI trends, best practices, and regulatory requirements.",
                "Advocate for diversity and inclusion both internally and externally."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Diversity and Inclusion, or related field; Master’s degree preferred.",
                "Proven experience in diversity, equity, and inclusion (DEI) management.",
                "Strong understanding of diversity concepts, theories, and best practices.",
                "Excellent communication and interpersonal skills.",
                "Ability to build relationships and influence stakeholders at all levels of the organization.",
                "Experience in designing and delivering diversity training programs.",
                "Analytical and problem-solving abilities.",
                "Project management skills and attention to detail.",
                "Ability to handle confidential and sensitive information with discretion.",
                "Passion for promoting diversity, equity, and inclusion in the workplace."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including DEI training and certifications.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const BenefitsAdministratorJobDescription = {
    title: "Benefits Administrator Job Description",
    jobRole: "A Benefits Administrator manages employee benefits programs, including health insurance, retirement plans, and wellness initiatives. They administer benefits enrollment, communicate benefit options to employees, and ensure compliance with regulations. Their role involves collaborating with HR teams, benefits providers, and employees to address inquiries, resolve issues, and enhance the overall employee experience.",
    preRead: "Use this Benefits Administrator job description template to advertise open positions for a Benefits Administrator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Benefits Administrator, you will be responsible for managing employee benefits programs, including health insurance, retirement plans, and wellness initiatives. You will administer benefits enrollment, communicate benefit options to employees, and ensure compliance with regulations. Your role will involve collaborating with HR teams, benefits providers, and employees to address inquiries, resolve issues, and enhance the overall employee experience."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Administer employee benefits programs, including health insurance, retirement plans, and wellness initiatives.",
                "Coordinate benefits enrollment processes, including new hires, life events, and open enrollment periods.",
                "Communicate benefit options, policies, and programs to employees and provide guidance on benefits-related inquiries.",
                "Process and track employee benefits transactions, such as enrollments, changes, and terminations.",
                "Ensure accurate and timely administration of benefits-related documentation and records.",
                "Collaborate with HR teams, benefits providers, and vendors to resolve benefits-related issues and inquiries.",
                "Monitor benefits eligibility and ensure compliance with regulatory requirements.",
                "Assist with benefits-related reporting and data analysis.",
                "Conduct benefits orientations and trainings for new employees.",
                "Stay updated on benefits trends, regulations, and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or related field.",
                "Proven experience in benefits administration or a similar role.",
                "Strong understanding of employee benefits programs, regulations, and compliance requirements.",
                "Excellent communication and interpersonal skills.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Detail-oriented with strong organizational and multitasking abilities.",
                "Proficiency in HRIS and benefits administration software.",
                "Problem-solving and analytical skills.",
                "Customer service orientation with a focus on employee satisfaction.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including HR and benefits training.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRComplianceSpecialistJobDescription = {
    title: "HR Compliance Specialist Job Description",
    jobRole: "An HR Compliance Specialist ensures that HR policies, procedures, and practices comply with legal and regulatory requirements. They conduct audits, investigations, and risk assessments to identify areas of non-compliance and develop corrective actions. Their role involves staying updated on employment laws and regulations, providing guidance to HR teams, and implementing compliance training programs.",
    preRead: "Use this HR Compliance Specialist job description template to advertise open positions for an HR Compliance Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Compliance Specialist, you will be responsible for ensuring that HR policies, procedures, and practices comply with legal and regulatory requirements. You will conduct audits, investigations, and risk assessments to identify areas of non-compliance and develop corrective actions. Your role will involve staying updated on employment laws and regulations, providing guidance to HR teams, and implementing compliance training programs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Monitor HR policies, procedures, and practices to ensure compliance with federal, state, and local laws and regulations.",
                "Conduct audits, investigations, and risk assessments to identify areas of non-compliance and develop corrective actions.",
                "Review and update HR policies, procedures, and employee handbooks to reflect changes in laws and regulations.",
                "Provide guidance and training to HR teams and managers on compliance requirements and best practices.",
                "Respond to complaints and allegations of non-compliance and conduct thorough investigations as needed.",
                "Collaborate with legal counsel and external consultants on compliance-related matters.",
                "Maintain accurate records and documentation related to compliance activities and investigations.",
                "Stay updated on changes in employment laws, regulations, and industry standards.",
                "Develop and implement compliance training programs for employees and HR staff.",
                "Participate in compliance-related meetings, committees, and initiatives."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or related field.",
                "Certification in HR compliance (e.g., HRCI, SHRM) preferred.",
                "Proven experience in HR compliance, audit, or related role.",
                "Thorough understanding of federal, state, and local employment laws and regulations.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to handle confidential information with integrity and discretion.",
                "Detail-oriented with strong organizational and multitasking abilities.",
                "Proficiency in HRIS and compliance management software.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including HR compliance training.",
                "Opportunities for career advancement and growth within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const RecruitingCoordinatorJobDescription = {
    title: "Recruiting Coordinator Job Description",
    jobRole: "A Recruiting Coordinator assists in the recruitment and hiring process by coordinating interviews, scheduling assessments, and communicating with candidates. They support recruiters and hiring managers by ensuring a smooth and efficient recruitment process from start to finish. Their role involves managing candidate databases, updating job postings, and providing administrative support to the recruitment team.",
    preRead: "Use this Recruiting Coordinator job description template to advertise open positions for a Recruiting Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Recruiting Coordinator, you will assist in the recruitment and hiring process by coordinating interviews, scheduling assessments, and communicating with candidates. You will support recruiters and hiring managers by ensuring a smooth and efficient recruitment process from start to finish. Your role will involve managing candidate databases, updating job postings, and providing administrative support to the recruitment team."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate and schedule interviews, assessments, and other recruitment activities.",
                "Communicate with candidates to provide information about job opportunities, interview schedules, and next steps in the hiring process.",
                "Manage candidate databases and applicant tracking systems (ATS) to ensure accurate and up-to-date candidate information.",
                "Assist in the preparation of job postings, job descriptions, and recruitment materials.",
                "Screen resumes and applications to identify qualified candidates for open positions.",
                "Conduct initial candidate screenings and assessments.",
                "Provide administrative support to recruiters and hiring managers, including managing calendars, organizing travel arrangements, and processing expenses.",
                "Assist with onboarding activities for new hires, including paperwork completion and orientation scheduling.",
                "Coordinate pre-employment background checks and drug screenings.",
                "Track recruitment metrics and prepare reports on recruitment activities and outcomes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Business Administration, or related field.",
                "Previous experience in recruiting, HR, or administrative role preferred.",
                "Familiarity with applicant tracking systems (ATS) and recruitment software.",
                "Strong organizational and time management skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Proficiency in Microsoft Office Suite (Word, Excel, Outlook).",
                "Detail-oriented with strong attention to accuracy.",
                "Ability to work effectively in a fast-paced environment and multitask.",
                "Customer service-oriented approach with a positive attitude."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contributions.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training in recruitment and HR.",
                "Opportunities for career advancement within the organization.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HumanResourcesDirectorJobDescription = {
    title: "Human Resources Director Job Description",
    jobRole: "A Human Resources Director is responsible for overseeing all aspects of the HR function within an organization. They develop and implement HR policies, programs, and initiatives to support the organization's goals and objectives. Their role involves managing recruitment, employee relations, performance management, training and development, compensation and benefits, and compliance with employment laws and regulations.",
    preRead: "Use this Human Resources Director job description template to advertise open positions for a HR Director in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Human Resources Director, you will be responsible for overseeing all aspects of the HR function within our organization. Your role will involve developing and implementing HR policies, programs, and initiatives to support the organization's goals and objectives. You will manage recruitment, employee relations, performance management, training and development, compensation and benefits, and ensure compliance with employment laws and regulations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement HR policies, procedures, and programs to support the organization's strategic objectives.",
                "Oversee recruitment and staffing activities, including sourcing, interviewing, and hiring qualified candidates.",
                "Manage employee relations issues, including conflict resolution, disciplinary actions, and grievance procedures.",
                "Lead performance management processes, including goal setting, performance appraisals, and development plans.",
                "Design and deliver training and development programs to enhance employee skills and competencies.",
                "Administer compensation and benefits programs, including salary reviews, bonus plans, and employee perks.",
                "Ensure compliance with employment laws and regulations at the federal, state, and local levels.",
                "Develop and maintain HR metrics and analytics to measure the effectiveness of HR programs and initiatives.",
                "Provide guidance and support to managers and employees on HR-related matters.",
                "Lead and develop the HR team, providing coaching, mentoring, and performance feedback."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or related field; Master's degree preferred.",
                "Certification in HR (e.g., SHRM-SCP, SPHR) is a plus.",
                "Proven experience as an HR Director or in a senior HR leadership role.",
                "Thorough knowledge of HR principles, practices, and regulations.",
                "Strong leadership and management skills with the ability to inspire and motivate a team.",
                "Excellent communication and interpersonal skills.",
                "Strategic thinker with the ability to align HR initiatives with organizational goals.",
                "Analytical and problem-solving skills with the ability to use data to drive decision-making.",
                "Ability to handle confidential information with discretion and integrity.",
                "Proficiency in HRIS systems and Microsoft Office Suite."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $90,000 - $150,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Generous paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const LearningAndDevelopmentSpecialistJobDescription = {
    title: "Learning and Development Specialist Job Description",
    jobRole: "A Learning and Development Specialist is responsible for designing, implementing, and evaluating training and development programs to enhance employee skills and competencies. They assess training needs, develop training materials, deliver training sessions, and measure the effectiveness of training initiatives to support organizational goals and objectives.",
    preRead: "Use this Learning and Development Specialist job description template to advertise open positions for a Learning and Development Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Learning and Development Specialist, you will be responsible for designing, implementing, and evaluating training and development programs to enhance employee skills and competencies. Your role will involve assessing training needs, developing training materials, delivering training sessions, and measuring the effectiveness of training initiatives to support organizational goals and objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess training needs through surveys, interviews, and consultations with managers and employees.",
                "Design and develop training programs, including course content, materials, and learning objectives.",
                "Deliver training sessions through various methods, such as instructor-led training, e-learning, and workshops.",
                "Coordinate training logistics, including scheduling, room setup, and equipment preparation.",
                "Evaluate the effectiveness of training programs through assessments, feedback, and performance metrics.",
                "Update and revise training materials and programs as needed to ensure relevance and effectiveness.",
                "Maintain training records and documentation in compliance with regulatory requirements.",
                "Collaborate with subject matter experts and external vendors to develop and deliver specialized training programs.",
                "Stay updated on industry trends and best practices in learning and development.",
                "Provide guidance and support to managers and employees on training and development opportunities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Education, Psychology, or related field; Master's degree preferred.",
                "Certification in training and development (e.g., CPLP, CPTM) is a plus.",
                "Proven experience as a Learning and Development Specialist or in a similar role.",
                "Knowledge of instructional design principles and adult learning theory.",
                "Experience with learning management systems (LMS) and e-learning platforms.",
                "Excellent presentation and facilitation skills.",
                "Strong communication and interpersonal skills.",
                "Analytical and problem-solving abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and organizational skills."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Generous paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmployeeEngagementManagerJobDescription = {
    title: "Employee Engagement Manager Job Description",
    jobRole: "An Employee Engagement Manager is responsible for developing and implementing strategies to enhance employee satisfaction, productivity, and retention. They assess employee engagement levels, design initiatives to improve workplace culture, and measure the effectiveness of engagement programs to foster a positive work environment and drive organizational success.",
    preRead: "Use this Employee Engagement Manager job description template to advertise open positions for an Employee Engagement Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employee Engagement Manager, you will be responsible for developing and implementing strategies to enhance employee satisfaction, productivity, and retention. Your role will involve assessing employee engagement levels, designing initiatives to improve workplace culture, and measuring the effectiveness of engagement programs to foster a positive work environment and drive organizational success."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess employee engagement levels through surveys, focus groups, and interviews.",
                "Develop and implement employee engagement strategies and initiatives.",
                "Design programs and activities to promote a positive workplace culture.",
                "Plan and coordinate events and activities to boost morale and team cohesion.",
                "Collaborate with HR and department managers to address employee feedback and concerns.",
                "Provide guidance and support to managers and employees on engagement-related matters.",
                "Monitor and measure the effectiveness of engagement programs and initiatives.",
                "Identify trends and opportunities for improvement in employee engagement.",
                "Communicate engagement initiatives and achievements to employees and stakeholders.",
                "Stay updated on industry trends and best practices in employee engagement."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Organizational Psychology, or related field; Master's degree preferred.",
                "Proven experience as an Employee Engagement Manager or in a similar role.",
                "Knowledge of employee engagement principles and best practices.",
                "Excellent communication and interpersonal skills.",
                "Strong project management and organizational skills.",
                "Analytical and problem-solving abilities.",
                "Ability to work independently and collaboratively in a team environment.",
                "Creative thinking and innovation mindset.",
                "Ability to build relationships and influence stakeholders.",
                "Passion for creating a positive work environment and driving employee satisfaction."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Generous paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const CompensationAnalystJobDescription = {
    title: "Compensation Analyst Job Description",
    jobRole: "A Compensation Analyst is responsible for analyzing and evaluating employee compensation data to ensure competitive pay practices and alignment with organizational objectives. They conduct salary surveys, develop compensation models, and make recommendations to management to attract and retain top talent while maintaining cost efficiency.",
    preRead: "Use this Compensation Analyst job description template to advertise open positions for a Compensation Analyst in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Compensation Analyst, you will be responsible for analyzing and evaluating employee compensation data to ensure competitive pay practices and alignment with organizational objectives. Your role will involve conducting salary surveys, developing compensation models, and making recommendations to management to attract and retain top talent while maintaining cost efficiency."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect and analyze salary and benefits data using internal and external sources.",
                "Conduct market research and benchmarking to determine competitive pay practices.",
                "Develop and maintain compensation models and structures.",
                "Evaluate job descriptions and classifications to ensure accuracy and consistency.",
                "Assist in the design and implementation of compensation programs and policies.",
                "Prepare reports and presentations on compensation trends and analysis.",
                "Collaborate with HR and hiring managers to review and approve salary offers.",
                "Provide guidance and support to managers and employees on compensation-related matters.",
                "Monitor and ensure compliance with federal, state, and local regulations.",
                "Stay updated on industry trends and best practices in compensation and benefits."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, Finance, or related field; Master's degree or Certified Compensation Professional (CCP) designation preferred.",
                "Proven experience as a Compensation Analyst or in a similar role.",
                "Strong analytical and quantitative skills.",
                "Proficiency in compensation analysis and modeling techniques.",
                "Knowledge of compensation laws, regulations, and best practices.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in data analysis and reporting.",
                "Ability to handle sensitive and confidential information with integrity.",
                "Proficient in Microsoft Excel and HRIS software."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Generous paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRBusinessPartnerJobDescription = {
    title: "HR Business Partner Job Description",
    jobRole: "An HR Business Partner works closely with business leaders to align HR strategies with organizational goals and objectives. They serve as a consultant to management on HR-related issues, including talent management, employee relations, performance management, and organizational development.",
    preRead: "Use this HR Business Partner job description template to advertise open positions for an HR Business Partner in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Business Partner, you will work closely with business leaders to align HR strategies with organizational goals and objectives. Your role will involve serving as a consultant to management on HR-related issues, including talent management, employee relations, performance management, and organizational development."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and maintain effective working relationships with business leaders and stakeholders.",
                "Partner with business leaders to understand their objectives and identify HR needs.",
                "Provide HR guidance and support to business leaders on a range of HR issues.",
                "Collaborate with HR specialists to deliver HR programs and initiatives.",
                "Advise on talent acquisition, including recruitment, selection, and onboarding processes.",
                "Facilitate performance management and development planning processes.",
                "Handle employee relations issues and provide coaching and guidance to managers.",
                "Conduct organizational assessments and develop strategies to address identified needs.",
                "Support change management initiatives and organizational restructuring efforts.",
                "Promote a culture of diversity, equity, and inclusion within the organization."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or related field; Master's degree or HR certification preferred.",
                "Proven experience as an HR Business Partner or in a similar HR role.",
                "Strong understanding of HR principles, practices, and employment laws.",
                "Excellent communication and interpersonal skills, with the ability to influence and build rapport.",
                "Ability to work collaboratively in a fast-paced, dynamic environment.",
                "Strong problem-solving and decision-making abilities.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Proficient in Microsoft Office and HRIS software."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Generous paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const TrainingCoordinatorJobDescription = {
    title: "Training Coordinator Job Description",
    jobRole: "A Training Coordinator is responsible for coordinating and administering training programs within an organization. They collaborate with various departments to identify training needs, develop training materials, schedule training sessions, and track training completion. Additionally, they may evaluate training effectiveness and make recommendations for improvement.",
    preRead: "Use this Training Coordinator job description template to advertise open positions for a Training Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Training Coordinator, you will be responsible for coordinating and administering training programs within the organization. Your role will involve collaborating with various departments to identify training needs, develop training materials, schedule training sessions, and track training completion. Additionally, you may evaluate training effectiveness and make recommendations for improvement."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with department heads and subject matter experts to identify training needs and objectives.",
                "Develop training materials, including presentations, handouts, and e-learning modules.",
                "Schedule and coordinate training sessions, including logistics and participant registration.",
                "Facilitate training sessions as needed, either in-person or virtually.",
                "Track training completion and maintain training records in a centralized system.",
                "Evaluate training effectiveness through participant feedback and assessments.",
                "Identify areas for improvement in training programs and make recommendations for enhancements.",
                "Stay updated on industry trends and best practices in training and development.",
                "Ensure compliance with regulatory requirements and company policies."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Education, Business Administration, or related field.",
                "Proven experience in training coordination or a related role.",
                "Strong organizational and project management skills.",
                "Excellent communication and interpersonal abilities.",
                "Proficiency in learning management systems (LMS) and other training software.",
                "Ability to work independently and collaboratively in a team environment.",
                "Attention to detail and accuracy in training administration.",
                "Flexible and adaptable to changing priorities and requirements.",
                "Certification in training and development (e.g., CPLP) is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $70,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmploymentSpecialistJobDescription = {
    title: "Employment Specialist Job Description",
    jobRole: "An Employment Specialist assists individuals in finding and securing employment opportunities. They assess job seekers' skills, interests, and goals, provide career counseling and guidance, and connect them with suitable job openings. Employment Specialists may also assist with resume writing, interview preparation, and job search strategies.",
    preRead: "Use this Employment Specialist job description template to advertise open positions for an Employment Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employment Specialist, you will be responsible for assisting individuals in finding and securing employment opportunities. Your role will involve assessing job seekers' skills, interests, and goals, providing career counseling and guidance, and connecting them with suitable job openings. Additionally, you may assist with resume writing, interview preparation, and job search strategies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assess job seekers' skills, qualifications, and career goals through interviews and assessments.",
                "Provide career counseling and guidance to help individuals identify suitable job opportunities.",
                "Develop and maintain relationships with employers to understand their hiring needs and preferences.",
                "Match job seekers with suitable employment opportunities based on their skills, experience, and preferences.",
                "Assist job seekers with resume writing, cover letter preparation, and interview techniques.",
                "Facilitate job readiness workshops and training sessions on topics such as resume writing, job search strategies, and interview skills.",
                "Maintain accurate records of job placements, client interactions, and employer relationships.",
                "Stay updated on labor market trends, job market demand, and industry developments.",
                "Provide ongoing support and follow-up to job seekers after placement to ensure job retention and satisfaction."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Counseling, Social Work, or a related field.",
                "Proven experience in employment services, career counseling, or a related field.",
                "Strong interpersonal and communication skills.",
                "Ability to assess individuals' skills, interests, and career goals.",
                "Knowledge of labor market trends, job search strategies, and employment resources.",
                "Familiarity with resume writing, interview preparation, and job placement techniques.",
                "Excellent organizational and time management abilities.",
                "Empathy and sensitivity when working with diverse populations.",
                "Ability to work independently and collaboratively in a team environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $35,000 - $60,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const TalentManagementSpecialistJobDescription = {
    title: "Talent Management Specialist Job Description",
    jobRole: "A Talent Management Specialist is responsible for developing and implementing strategies to attract, retain, and develop talent within an organization. They collaborate with HR professionals and department managers to identify staffing needs, design talent acquisition and retention programs, and provide guidance on employee development initiatives.",
    preRead: "Use this Talent Management Specialist job description template to advertise open positions for a Talent Management Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Talent Management Specialist, you will be responsible for developing and implementing strategies to attract, retain, and develop talent within the organization. Your role will involve collaborating with HR professionals and department managers to identify staffing needs, design talent acquisition and retention programs, and provide guidance on employee development initiatives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with HR professionals and department managers to identify staffing needs and develop workforce plans.",
                "Design and implement talent acquisition strategies, including sourcing, recruiting, and onboarding processes.",
                "Develop and administer employee retention programs and initiatives to foster employee engagement and loyalty.",
                "Provide guidance and support to managers on performance management processes, including goal setting, performance evaluations, and career development planning.",
                "Design and facilitate training and development programs to enhance employee skills and capabilities.",
                "Conduct talent assessments and succession planning activities to identify high-potential employees and future leaders.",
                "Stay updated on industry trends and best practices in talent management and recommend innovative approaches to enhance organizational effectiveness.",
                "Analyze workforce data and metrics to assess the effectiveness of talent management initiatives and make recommendations for improvement."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field; Master's degree preferred.",
                "Proven experience in talent management, human resources, or organizational development.",
                "Strong knowledge of talent acquisition, employee retention, and performance management practices.",
                "Excellent communication and interpersonal skills, with the ability to interact effectively with employees at all levels of the organization.",
                "Analytical and problem-solving skills, with the ability to interpret workforce data and metrics.",
                "Ability to work collaboratively in a team environment and influence stakeholders at all levels.",
                "Strong project management skills, with the ability to manage multiple priorities and meet deadlines.",
                "Knowledge of HRIS and talent management software preferred.",
                "Certification in talent management or human resources (e.g., SHRM-SCP, PHR, or similar) is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const CareerDevelopmentManagerJobDescription = {
    title: "Career Development Manager Job Description",
    jobRole: "A Career Development Manager is responsible for designing and implementing career development programs and initiatives to support employee growth and advancement within an organization. They work closely with HR professionals, department managers, and employees to identify career goals, assess skill gaps, and create development plans to help employees achieve their career objectives.",
    preRead: "Use this Career Development Manager job description template to advertise open positions for a Career Development Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Career Development Manager, you will be responsible for designing and implementing career development programs and initiatives to support employee growth and advancement within the organization. Your role will involve working closely with HR professionals, department managers, and employees to identify career goals, assess skill gaps, and create development plans to help employees achieve their career objectives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with HR professionals, department managers, and employees to identify career goals and development needs.",
                "Design and implement career development programs and initiatives, including training, mentoring, coaching, and rotational assignments.",
                "Conduct skills assessments and gap analyses to identify areas for skill development and training.",
                "Create individualized development plans for employees based on their career goals and development needs.",
                "Provide guidance and support to employees on career planning, advancement opportunities, and professional growth.",
                "Coordinate with external training providers, universities, and professional organizations to offer learning and development opportunities.",
                "Monitor and evaluate the effectiveness of career development programs and initiatives and make recommendations for improvement.",
                "Stay updated on industry trends and best practices in career development and recommend innovative approaches to enhance employee growth and advancement."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Organizational Development, or a related field; Master's degree preferred.",
                "Proven experience in career development, talent management, or organizational development.",
                "Strong knowledge of career development theories, practices, and tools.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and credibility with employees at all levels of the organization.",
                "Analytical and problem-solving skills, with the ability to assess employee development needs and design appropriate interventions.",
                "Ability to work collaboratively in a team environment and influence stakeholders at all levels.",
                "Strong project management skills, with the ability to manage multiple priorities and meet deadlines.",
                "Certification in career development, coaching, or organizational development (e.g., CCMP, PHR, or similar) is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRSystemsAdministratorJobDescription = {
    title: "HR Systems Administrator Job Description",
    jobRole: "An HR Systems Administrator is responsible for managing and maintaining human resources information systems (HRIS) and related technology platforms. They ensure data accuracy, system functionality, and compliance with HR policies and regulations. This role involves troubleshooting technical issues, implementing system upgrades, and providing training and support to HR staff and end-users.",
    preRead: "Use this HR Systems Administrator job description template to advertise open positions for an HR Systems Administrator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Systems Administrator, you will be responsible for managing and maintaining human resources information systems (HRIS) and related technology platforms. Your role will involve ensuring data accuracy, system functionality, and compliance with HR policies and regulations. You will also troubleshoot technical issues, implement system upgrades, and provide training and support to HR staff and end-users."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and maintain HRIS and related technology platforms, ensuring data accuracy, system functionality, and compliance with HR policies and regulations.",
                "Troubleshoot technical issues and provide timely resolution to ensure uninterrupted system operation.",
                "Collaborate with HR staff and IT professionals to implement system upgrades, enhancements, and integrations.",
                "Develop and maintain system documentation, including user guides, procedures, and policies.",
                "Provide training and support to HR staff and end-users on system functionality, processes, and best practices.",
                "Monitor system performance and security, implement data backups, and ensure compliance with data privacy regulations.",
                "Generate reports and analyze HR data to support decision-making and strategic initiatives.",
                "Stay updated on emerging HR technology trends and best practices, and make recommendations for system improvements and optimizations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Information Technology, or a related field.",
                "Proven experience in HRIS administration or related roles.",
                "Strong technical skills, including proficiency in HRIS platforms (e.g., Workday, SAP, Oracle), database management, and reporting tools.",
                "Knowledge of HR policies, regulations, and compliance requirements.",
                "Excellent troubleshooting and problem-solving skills, with the ability to analyze complex technical issues and provide effective solutions.",
                "Strong communication and interpersonal skills, with the ability to work collaboratively in a team environment.",
                "Attention to detail and accuracy in data management and system configuration.",
                "Ability to prioritize tasks, manage multiple projects simultaneously, and meet deadlines in a fast-paced environment."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const LaborRelationsSpecialistJobDescription = {
    title: "Labor Relations Specialist Job Description",
    jobRole: "A Labor Relations Specialist is responsible for managing and maintaining positive relationships between employers and employees. They handle labor-related issues, including negotiations, grievances, and disputes, and ensure compliance with labor laws and regulations. This role involves advising management on labor relations matters, developing strategies to promote a productive work environment, and fostering effective communication between stakeholders.",
    preRead: "Use this Labor Relations Specialist job description template to advertise open positions for a Labor Relations Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Labor Relations Specialist, you will be responsible for managing and maintaining positive relationships between employers and employees. Your role will involve handling labor-related issues, including negotiations, grievances, and disputes, and ensuring compliance with labor laws and regulations. You will advise management on labor relations matters, develop strategies to promote a productive work environment, and foster effective communication between stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Advise management on labor relations matters, including collective bargaining agreements, labor contracts, and union-related issues.",
                "Handle labor negotiations, including contract negotiations, mediation, and arbitration, to resolve disputes and reach agreements.",
                "Investigate and address employee grievances, complaints, and disciplinary actions, ensuring fair and consistent treatment.",
                "Monitor compliance with labor laws, regulations, and company policies, and recommend corrective actions as needed.",
                "Develop and implement strategies to promote positive labor-management relations and foster a productive work environment.",
                "Conduct training sessions for employees and management on labor relations policies, procedures, and best practices.",
                "Maintain accurate records and documentation related to labor relations activities, including grievances, negotiations, and settlements.",
                "Serve as a liaison between management, employees, and labor unions, facilitating communication and resolving conflicts."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Labor Relations, Industrial Relations, or a related field.",
                "Proven experience in labor relations, collective bargaining, and contract negotiations.",
                "Knowledge of labor laws, regulations, and industry standards, including the National Labor Relations Act (NLRA) and related statutes.",
                "Strong negotiation, mediation, and conflict resolution skills.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and collaborate effectively with diverse stakeholders.",
                "Analytical and problem-solving abilities, with a focus on finding practical solutions to complex labor issues.",
                "Attention to detail and accuracy in documentation and record-keeping.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Certification in labor relations or HR management (e.g., SHRM-CP, PHR) may be preferred."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $100,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const StaffingManagerJobDescription = {
    title: "Staffing Manager Job Description",
    jobRole: "A Staffing Manager oversees the recruitment and hiring process for an organization. They develop recruitment strategies, source candidates, conduct interviews, and make hiring decisions. This role involves collaborating with hiring managers to understand staffing needs, managing job postings, and ensuring compliance with employment laws and regulations. Additionally, Staffing Managers may be responsible for onboarding new employees and maintaining relationships with recruitment agencies and job boards.",
    preRead: "Use this Staffing Manager job description template to advertise open positions for a Staffing Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Staffing Manager, you will oversee the recruitment and hiring process for our organization. You will develop recruitment strategies, source candidates, conduct interviews, and make hiring decisions. Your role will involve collaborating with hiring managers to understand staffing needs, managing job postings, and ensuring compliance with employment laws and regulations. Additionally, you may be responsible for onboarding new employees and maintaining relationships with recruitment agencies and job boards."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement recruitment strategies to attract qualified candidates.",
                "Source candidates through various channels, including job boards, social media, and recruitment agencies.",
                "Review resumes and applications, conduct pre-screening interviews, and coordinate interview schedules.",
                "Collaborate with hiring managers to understand staffing needs and develop job descriptions.",
                "Manage job postings on company websites, job boards, and social media platforms.",
                "Conduct interviews, assess candidates' qualifications, and make hiring recommendations.",
                "Coordinate background checks, drug tests, and other pre-employment screenings.",
                "Facilitate the offer and negotiation process with selected candidates.",
                "Ensure compliance with employment laws and regulations throughout the recruitment and hiring process.",
                "Onboard new employees, including orientation sessions and training programs.",
                "Maintain relationships with recruitment agencies, job boards, and other external partners."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field.",
                "Proven experience in recruitment and staffing, preferably in a managerial role.",
                "Knowledge of recruitment strategies, sourcing techniques, and candidate assessment methods.",
                "Familiarity with applicant tracking systems (ATS) and HR software.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and collaborate effectively.",
                "Strong organizational and time management abilities, with the capacity to manage multiple tasks and priorities.",
                "Attention to detail and accuracy in candidate evaluation and documentation.",
                "Ability to work independently and as part of a team in a fast-paced environment.",
                "Certification in Human Resources (e.g., SHRM-CP, PHR) may be preferred."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const WorkforceDevelopmentCoordinatorJobDescription = {
    title: "Workforce Development Coordinator Job Description",
    jobRole: "A Workforce Development Coordinator is responsible for implementing and coordinating workforce development programs and initiatives within an organization. They collaborate with various stakeholders to assess training needs, develop training plans, and deliver relevant programs to enhance employee skills and performance. This role involves designing curriculum, scheduling training sessions, and evaluating the effectiveness of training programs. Workforce Development Coordinators also track training metrics, maintain training records, and provide support to employees participating in training activities.",
    preRead: "Use this Workforce Development Coordinator job description template to advertise open positions for a Workforce Development Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Workforce Development Coordinator, you will be responsible for implementing and coordinating workforce development programs and initiatives within our organization. You will collaborate with various stakeholders to assess training needs, develop training plans, and deliver relevant programs to enhance employee skills and performance. Your role will involve designing curriculum, scheduling training sessions, and evaluating the effectiveness of training programs. Additionally, you will track training metrics, maintain training records, and provide support to employees participating in training activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with department managers and HR to assess training needs and develop training plans aligned with organizational goals.",
                "Design and develop training curriculum, materials, and resources to support employee development.",
                "Schedule and coordinate training sessions, workshops, and seminars, ensuring optimal participation and resource allocation.",
                "Deliver training programs to employees using a variety of instructional techniques and formats.",
                "Evaluate the effectiveness of training programs through participant feedback, performance assessments, and other metrics.",
                "Track training metrics and maintain accurate training records using learning management systems (LMS) or other tracking tools.",
                "Provide guidance and support to employees participating in training activities, including troubleshooting technical issues and answering questions.",
                "Stay updated on industry trends, best practices, and emerging technologies in workforce development and training.",
                "Collaborate with external training providers, vendors, and consultants as needed to deliver specialized training programs.",
                "Conduct follow-up assessments and evaluations to measure the impact of training on employee performance and organizational outcomes."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Education, Training, or a related field.",
                "Proven experience in workforce development, training coordination, or related roles.",
                "Knowledge of instructional design principles, training methodologies, and adult learning theory.",
                "Familiarity with learning management systems (LMS) and other training delivery platforms.",
                "Strong organizational and project management skills, with the ability to manage multiple projects and deadlines.",
                "Excellent communication and interpersonal skills, with the ability to effectively convey complex information to diverse audiences.",
                "Analytical mindset with the ability to interpret data and metrics to assess training effectiveness.",
                "Problem-solving skills and the ability to address challenges and obstacles in training delivery.",
                "Certification in training and development (e.g., CPLP, CPTM) may be preferred.",
                "Experience in specific industries or sectors (e.g., healthcare, technology) may be advantageous."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $45,000 - $75,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const CompensationConsultantJobDescription = {
    title: "Compensation Consultant Job Description",
    jobRole: "A Compensation Consultant is responsible for designing, implementing, and managing compensation programs within an organization. They analyze market data to determine competitive salary ranges, develop compensation strategies to attract and retain talent, and ensure compliance with legal and regulatory requirements. This role involves conducting salary surveys, benchmarking jobs, and making recommendations for salary adjustments based on performance, market trends, and organizational objectives. Compensation Consultants also provide guidance to HR and management on compensation-related matters and may assist with incentive plan design, equity compensation, and executive compensation programs.",
    preRead: "Use this Compensation Consultant job description template to advertise open positions for a Compensation Consultant in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Compensation Consultant, you will be responsible for designing, implementing, and managing compensation programs within our organization. You will analyze market data to determine competitive salary ranges, develop compensation strategies to attract and retain talent, and ensure compliance with legal and regulatory requirements. Your role will involve conducting salary surveys, benchmarking jobs, and making recommendations for salary adjustments based on performance, market trends, and organizational objectives. Additionally, you will provide guidance to HR and management on compensation-related matters and may assist with incentive plan design, equity compensation, and executive compensation programs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct salary surveys and analyze market data to determine competitive salary ranges for various positions.",
                "Benchmark jobs internally and externally to ensure compensation alignment with organizational goals and industry standards.",
                "Develop and implement compensation strategies, policies, and procedures to attract, retain, and motivate employees.",
                "Collaborate with HR and hiring managers to determine appropriate salary offers for new hires and promotions.",
                "Provide guidance on salary structures, job classifications, and pay equity to ensure fair and equitable compensation practices.",
                "Assist with the design, implementation, and administration of incentive compensation plans, including sales commissions, bonuses, and profit-sharing programs.",
                "Ensure compliance with legal and regulatory requirements related to compensation, including wage and hour laws, pay equity legislation, and tax regulations.",
                "Conduct compensation analysis and make recommendations for salary adjustments based on performance, market trends, and budget considerations.",
                "Prepare and present reports, presentations, and recommendations to senior management and stakeholders.",
                "Stay updated on compensation trends, best practices, and regulatory changes to inform decision-making and program design."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, Finance, or a related field; Master's degree or certification (e.g., CCP, CECP) may be preferred.",
                "Proven experience in compensation analysis, design, and administration, preferably in a consulting or corporate environment.",
                "Strong analytical skills with the ability to interpret data, conduct market research, and perform financial analysis.",
                "Knowledge of compensation principles, practices, and legal requirements, including FLSA, IRS regulations, and pay equity legislation.",
                "Proficiency in compensation survey methodologies, statistical analysis, and data visualization techniques.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with stakeholders at all levels.",
                "Detail-oriented and organized, with the ability to manage multiple projects and deadlines.",
                "Proficiency in Microsoft Excel, PowerPoint, and HRIS software; experience with compensation management systems (CMS) is a plus.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Strong business acumen and the ability to align compensation strategies with organizational goals and objectives."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmployeeBenefitsSpecialistJobDescription = {
    title: "Employee Benefits Specialist Job Description",
    jobRole: "An Employee Benefits Specialist is responsible for managing employee benefits programs within an organization. They administer various benefits such as health insurance, retirement plans, and wellness programs, ensuring compliance with legal requirements and internal policies. This role involves educating employees about available benefits, processing enrollment and claims, and resolving benefit-related issues. Employee Benefits Specialists also collaborate with insurance providers, brokers, and HR to design and optimize benefits packages that attract and retain talent while controlling costs.",
    preRead: "Use this Employee Benefits Specialist job description template to advertise open positions for an Employee Benefits Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employee Benefits Specialist, you will be responsible for managing employee benefits programs within our organization. You will administer various benefits such as health insurance, retirement plans, and wellness programs, ensuring compliance with legal requirements and internal policies. Your role will involve educating employees about available benefits, processing enrollment and claims, and resolving benefit-related issues. Additionally, you will collaborate with insurance providers, brokers, and HR to design and optimize benefits packages that attract and retain talent while controlling costs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Administer employee benefits programs, including health insurance, retirement plans, and wellness initiatives.",
                "Ensure compliance with legal requirements, such as ERISA, COBRA, HIPAA, and ACA, as well as internal policies and procedures.",
                "Educate employees about available benefits options, enrollment procedures, and plan features.",
                "Process benefit enrollments, changes, and terminations accurately and in a timely manner.",
                "Coordinate with insurance providers, brokers, and HR to negotiate benefits contracts, resolve issues, and optimize program offerings.",
                "Manage employee communications related to benefits, including announcements, updates, and open enrollment materials.",
                "Assist employees with benefit-related inquiries, claims, and issues, providing timely and accurate information and resolutions.",
                "Conduct regular audits of benefits data and processes to ensure accuracy, integrity, and compliance.",
                "Stay updated on industry trends, regulatory changes, and best practices in employee benefits administration.",
                "Collaborate with HR and management to design and implement new benefits programs or enhancements.",
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field.",
                "Proven experience in employee benefits administration or a related role, preferably in a corporate environment.",
                "Knowledge of benefits laws and regulations, including ERISA, COBRA, HIPAA, and ACA.",
                "Familiarity with benefits plan design, implementation, and administration, including health insurance, retirement plans, and wellness programs.",
                "Excellent communication and interpersonal skills, with the ability to explain complex benefits concepts in a clear and concise manner.",
                "Strong organizational skills and attention to detail, with the ability to manage multiple tasks and deadlines.",
                "Proficiency in benefits administration software or HRIS systems; experience with payroll systems is a plus.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Customer service-oriented mindset, with a focus on delivering exceptional support to employees.",
                "Analytical and problem-solving skills, with the ability to identify issues, analyze data, and propose solutions.",
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $80,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const OnboardingCoordinatorJobDescription = {
    title: "Onboarding Coordinator Job Description",
    jobRole: "An Onboarding Coordinator is responsible for facilitating the onboarding process for new employees, ensuring a smooth transition into the organization. They work closely with HR, hiring managers, and various departments to coordinate pre-employment activities, orientation sessions, and training programs. This role involves guiding new hires through paperwork completion, explaining company policies and procedures, and providing support to address questions or concerns. Onboarding Coordinators also collaborate with IT to set up computer systems and access permissions, organize facility tours, and oversee the distribution of employee handbooks and welcome kits.",
    preRead: "Use this Onboarding Coordinator job description template to advertise open positions for an Onboarding Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Onboarding Coordinator, you will be responsible for facilitating the onboarding process for new employees, ensuring a smooth transition into our organization. You will work closely with HR, hiring managers, and various departments to coordinate pre-employment activities, orientation sessions, and training programs. Your role will involve guiding new hires through paperwork completion, explaining company policies and procedures, and providing support to address questions or concerns. Additionally, you will collaborate with IT to set up computer systems and access permissions, organize facility tours, and oversee the distribution of employee handbooks and welcome kits."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate pre-employment activities, including background checks, drug screenings, and new hire paperwork.",
                "Schedule and facilitate orientation sessions for new employees, covering company policies, benefits, and organizational culture.",
                "Assist new hires with completing required paperwork, such as tax forms, benefits enrollment, and confidentiality agreements.",
                "Provide support and guidance to new employees throughout the onboarding process, addressing questions, concerns, and needs.",
                "Collaborate with IT to ensure new hires have the necessary equipment, software, and access permissions to perform their job duties.",
                "Arrange facility tours and introductions to key personnel to help new employees acclimate to their surroundings.",
                "Coordinate training programs and workshops for new hires to develop job-related skills and knowledge.",
                "Maintain accurate records of onboarding activities, including employee information, completion status, and feedback.",
                "Contribute to the continuous improvement of the onboarding process by identifying opportunities for enhancement and efficiency.",
                "Assist with special projects or initiatives related to employee engagement, retention, and development as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field preferred.",
                "Proven experience in HR, onboarding, or administrative roles, preferably in a corporate environment.",
                "Knowledge of onboarding best practices, employment laws, and compliance requirements.",
                "Excellent communication and interpersonal skills, with the ability to interact effectively with individuals at all levels of the organization.",
                "Strong organizational and multitasking abilities, with the capacity to manage multiple priorities and deadlines.",
                "Attention to detail and accuracy in completing paperwork and maintaining records.",
                "Proficiency in MS Office applications and HRIS systems; experience with onboarding software is a plus.",
                "Customer service-oriented mindset, with a focus on providing a positive experience for new employees.",
                "Ability to maintain confidentiality and handle sensitive information with discretion.",
                "Flexibility and adaptability to accommodate changing priorities and business needs."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRRecruiterJobDescription = {
    title: "HR Recruiter Job Description",
    jobRole: "An HR Recruiter is responsible for sourcing, screening, and selecting qualified candidates to fill open positions within an organization. They work closely with hiring managers to understand staffing needs, develop recruitment strategies, and attract top talent. This role involves posting job openings, reviewing resumes, conducting interviews, and coordinating the hiring process from start to finish. HR Recruiters also play a key role in building employer branding, promoting diversity and inclusion initiatives, and ensuring a positive candidate experience throughout the recruitment process.",
    preRead: "Use this HR Recruiter job description template to advertise open positions for an HR Recruiter in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Recruiter, you will be responsible for sourcing, screening, and selecting qualified candidates to fill open positions within our organization. You will work closely with hiring managers to understand their staffing needs, develop recruitment strategies, and attract top talent. Your role will involve posting job openings, reviewing resumes, conducting interviews, and coordinating the hiring process from start to finish. Additionally, you will play a key role in building our employer branding, promoting diversity and inclusion initiatives, and ensuring a positive candidate experience throughout the recruitment process."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with hiring managers to identify staffing needs and develop recruitment plans and strategies.",
                "Post job openings on job boards, social media platforms, company website, and other relevant channels.",
                "Review resumes and applications, conduct initial screenings to assess candidate qualifications, and shortlist candidates for further evaluation.",
                "Conduct interviews, both in-person and virtual, to assess candidate skills, experience, and fit for the position and organization.",
                "Coordinate interview schedules, communicate with candidates, and provide timely updates throughout the hiring process.",
                "Administer pre-employment assessments and tests as needed to evaluate candidate competencies.",
                "Conduct reference checks, background checks, and employment verifications for selected candidates.",
                "Extend job offers, negotiate salary and benefits packages, and facilitate the onboarding process for new hires.",
                "Maintain accurate and up-to-date recruitment records and documentation in the applicant tracking system (ATS).",
                "Participate in employer branding activities, including attending job fairs, networking events, and campus recruitment initiatives.",
                "Promote diversity and inclusion in recruitment efforts, ensuring equal opportunity for all candidates.",
                "Contribute to the continuous improvement of recruitment processes and procedures.",
                "Stay informed about industry trends, best practices, and legal requirements related to recruitment and employment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field preferred.",
                "Proven experience as an HR Recruiter or in a similar recruitment role, preferably in a corporate environment.",
                "Familiarity with recruitment tools and techniques, including applicant tracking systems (ATS) and job boards.",
                "Excellent communication and interpersonal skills, with the ability to build rapport and relationships with candidates and hiring managers.",
                "Strong organizational and time management abilities, with the capacity to manage multiple priorities and deadlines.",
                "Ability to assess candidate qualifications, conduct interviews, and make objective hiring decisions.",
                "Knowledge of employment laws, regulations, and best practices related to recruitment and selection.",
                "Attention to detail and accuracy in evaluating resumes, conducting screenings, and maintaining recruitment records.",
                "Proficiency in MS Office applications and recruitment software.",
                "Customer service-oriented mindset, with a focus on providing a positive candidate experience.",
                "Flexibility and adaptability to accommodate changing priorities and business needs."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $45,000 - $70,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const OrganizationalChangeManagerJobDescription = {
    title: "Organizational Change Manager Job Description",
    jobRole: "An Organizational Change Manager is responsible for leading and managing organizational change initiatives within an organization. They work closely with stakeholders to assess the impact of changes, develop change management strategies, and facilitate the transition to new processes, systems, or structures. This role involves creating communication plans, conducting training sessions, and providing ongoing support to ensure successful change adoption. Organizational Change Managers also monitor progress, identify risks, and implement mitigation strategies to minimize disruptions and maximize change effectiveness.",
    preRead: "Use this Organizational Change Manager job description template to advertise open positions for an Organizational Change Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Organizational Change Manager, you will be responsible for leading and managing organizational change initiatives to drive successful change adoption within our organization. You will work closely with stakeholders to assess the impact of changes, develop change management strategies, and facilitate the transition to new processes, systems, or structures. Your role will involve creating communication plans, conducting training sessions, and providing ongoing support to ensure that changes are implemented smoothly and effectively. Additionally, you will monitor progress, identify risks, and implement mitigation strategies to minimize disruptions and maximize change effectiveness."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Lead and manage organizational change initiatives, ensuring alignment with strategic objectives and business goals.",
                "Collaborate with stakeholders to assess the impact of changes and develop change management strategies and plans.",
                "Create communication plans to inform and engage stakeholders at all levels of the organization throughout the change process.",
                "Conduct stakeholder analysis to identify key influencers and develop targeted communication and engagement strategies.",
                "Facilitate change readiness assessments and develop action plans to address gaps and barriers to change adoption.",
                "Plan and deliver training sessions, workshops, and other learning interventions to build change competency and capability.",
                "Provide ongoing support to employees and managers to navigate through the change process and address concerns or resistance.",
                "Monitor progress, track metrics, and analyze data to evaluate change effectiveness and identify areas for improvement.",
                "Identify risks and challenges related to change implementation and develop mitigation strategies to minimize disruptions.",
                "Collaborate with project teams and other stakeholders to integrate change management activities into project plans and timelines.",
                "Communicate change management progress, achievements, and challenges to key stakeholders and project sponsors.",
                "Stay informed about industry trends, best practices, and emerging technologies in change management and organizational development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Organizational Development, Business Administration, Human Resources, or a related field; Master's degree preferred.",
                "Proven experience in organizational change management, preferably in large-scale transformation initiatives.",
                "Certification in change management (e.g., Prosci, ACMP) is highly desirable.",
                "Strong understanding of change management principles, methodologies, and best practices.",
                "Excellent communication and interpersonal skills, with the ability to influence and engage stakeholders at all levels of the organization.",
                "Strategic thinking and problem-solving abilities, with the capacity to analyze complex situations and develop innovative solutions.",
                "Project management skills, with the ability to manage multiple priorities and deliver results in a fast-paced environment.",
                "Collaborative and team-oriented mindset, with the ability to work effectively with cross-functional teams and diverse stakeholders.",
                "Change leadership skills, with the ability to inspire and motivate others to embrace change and drive transformation.",
                "Proficiency in change management tools and software applications.",
                "Adaptability and resilience to navigate through ambiguity and uncertainty.",
                "Commitment to continuous learning and professional development in the field of change management."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const PerformanceImprovementSpecialistJobDescription = {
    title: "Performance Improvement Specialist Job Description",
    jobRole: "A Performance Improvement Specialist is responsible for analyzing organizational processes, identifying areas for improvement, and implementing strategies to enhance performance and efficiency. They work closely with stakeholders to gather data, assess current practices, and develop action plans to address gaps and optimize workflows. This role involves conducting performance evaluations, implementing performance improvement initiatives, and monitoring progress to ensure that objectives are met. Performance Improvement Specialists also provide training and support to employees to facilitate skill development and promote a culture of continuous improvement.",
    preRead: "Use this Performance Improvement Specialist job description template to advertise open positions for a Performance Improvement Specialist in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Performance Improvement Specialist, you will be responsible for analyzing organizational processes, identifying areas for improvement, and implementing strategies to enhance performance and efficiency. You will work closely with stakeholders to gather data, assess current practices, and develop action plans to address gaps and optimize workflows. Your role will involve conducting performance evaluations, implementing performance improvement initiatives, and monitoring progress to ensure that objectives are met. Additionally, you will provide training and support to employees to facilitate skill development and promote a culture of continuous improvement."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Analyze organizational processes and workflows to identify areas for improvement and optimization.",
                "Gather and analyze data to assess performance metrics and identify trends or patterns.",
                "Collaborate with stakeholders to develop action plans and strategies to address performance gaps and achieve organizational objectives.",
                "Implement performance improvement initiatives, including process redesign, automation, and workflow optimization.",
                "Conduct performance evaluations and assessments to measure the effectiveness of improvement efforts.",
                "Monitor progress and track performance metrics to ensure that objectives are met and identify areas for further improvement.",
                "Provide training and support to employees to develop skills and capabilities that align with performance improvement goals.",
                "Facilitate workshops, training sessions, and other learning interventions to promote a culture of continuous improvement.",
                "Communicate performance improvement strategies, progress, and outcomes to key stakeholders and leadership teams.",
                "Collaborate with cross-functional teams to implement performance improvement projects and initiatives.",
                "Stay informed about industry trends, best practices, and emerging technologies in performance improvement and organizational development."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Organizational Development, Industrial Engineering, or a related field; Master's degree preferred.",
                "Proven experience in performance improvement, process optimization, or related fields.",
                "Strong analytical skills, with the ability to gather and interpret data to identify trends and patterns.",
                "Excellent problem-solving abilities, with the capacity to develop innovative solutions to complex challenges.",
                "Project management skills, with the ability to manage multiple priorities and deliver results in a fast-paced environment.",
                "Excellent communication and interpersonal skills, with the ability to collaborate effectively with stakeholders at all levels of the organization.",
                "Change management skills, with the ability to facilitate change and drive adoption of performance improvement initiatives.",
                "Ability to work independently and as part of a team, with a proactive and results-driven approach.",
                "Proficiency in performance improvement methodologies, tools, and software applications.",
                "Commitment to continuous learning and professional development in the field of performance improvement."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const SuccessionPlanningManagerJobDescription = {
    title: "Succession Planning Manager Job Description",
    jobRole: "A Succession Planning Manager is responsible for developing and implementing strategies to identify and develop talent within an organization to ensure continuity in leadership positions. They work closely with senior management to assess current talent pools, identify high-potential employees, and create succession plans for key roles. This role involves collaborating with HR professionals to design and execute talent development programs, mentorship initiatives, and leadership training to groom future leaders. Succession Planning Managers also monitor the progress of succession plans, evaluate their effectiveness, and make adjustments as needed to align with changing organizational needs.",
    preRead: "Use this Succession Planning Manager job description template to advertise open positions for a Succession Planning Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Succession Planning Manager, you will be responsible for developing and implementing strategies to identify and develop talent within the organization to ensure continuity in leadership positions. You will work closely with senior management to assess current talent pools, identify high-potential employees, and create succession plans for key roles. Your role will involve collaborating with HR professionals to design and execute talent development programs, mentorship initiatives, and leadership training to groom future leaders. Additionally, you will monitor the progress of succession plans, evaluate their effectiveness, and make adjustments as needed to align with changing organizational needs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collaborate with senior management to assess current talent pools and identify future leadership needs.",
                "Develop and implement succession planning strategies and initiatives to identify high-potential employees and groom them for leadership positions.",
                "Design and execute talent development programs, mentorship initiatives, and leadership training to build a pipeline of future leaders.",
                "Identify key competencies and skills required for leadership roles and create competency models to guide succession planning efforts.",
                "Work closely with HR professionals to ensure that talent development programs align with organizational goals and objectives.",
                "Monitor the progress of succession plans, track the development of high-potential employees, and evaluate the effectiveness of talent development initiatives.",
                "Provide guidance and support to managers and employees involved in succession planning and talent development activities.",
                "Conduct regular reviews and assessments of succession plans to identify areas for improvement and make adjustments as needed.",
                "Stay informed about industry trends, best practices, and emerging technologies in succession planning and talent management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, Organizational Development, or a related field; Master's degree preferred.",
                "Proven experience in succession planning, talent management, or related fields.",
                "Strong understanding of talent development strategies, leadership principles, and organizational development concepts.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and collaborate effectively with stakeholders at all levels of the organization.",
                "Analytical and problem-solving abilities, with the capacity to assess talent needs, identify high-potential employees, and develop succession plans.",
                "Project management skills, with the ability to manage multiple priorities and deliver results in a fast-paced environment.",
                "Change management skills, with the ability to drive adoption of succession planning initiatives and facilitate organizational change.",
                "Proficiency in talent management software, HRIS systems, and other relevant tools and technologies.",
                "Commitment to diversity, equity, and inclusion in talent management and succession planning efforts.",
                "Certifications in talent management, organizational development, or related fields are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $80,000 - $120,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const HRAnalystJobDescription = {
    title: "HR Analyst Job Description",
    jobRole: "An HR Analyst is responsible for collecting, analyzing, and interpreting human resources data to support strategic decision-making and improve HR processes within an organization. This role involves gathering data on various HR metrics such as recruitment, retention, performance, and employee engagement. HR Analysts use statistical techniques and data visualization tools to generate reports, dashboards, and presentations for HR leaders and other stakeholders. They also conduct research, benchmarking, and trend analysis to identify opportunities for process improvement and optimization. Additionally, HR Analysts may assist in the development and implementation of HR policies, procedures, and programs.",
    preRead: "Use this HR Analyst job description template to advertise open positions for an HR Analyst in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an HR Analyst, you will be responsible for collecting, analyzing, and interpreting human resources data to support strategic decision-making and improve HR processes within the organization. You will gather data on various HR metrics such as recruitment, retention, performance, and employee engagement and use statistical techniques and data visualization tools to generate reports, dashboards, and presentations for HR leaders and other stakeholders. Additionally, you will conduct research, benchmarking, and trend analysis to identify opportunities for process improvement and optimization. You may also assist in the development and implementation of HR policies, procedures, and programs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Collect, compile, and analyze HR data from various sources, including HRIS, ATS, performance management systems, employee surveys, and other databases.",
                "Generate reports, dashboards, and presentations to communicate key HR metrics, trends, and insights to HR leaders and other stakeholders.",
                "Conduct research, benchmarking, and trend analysis to identify best practices, industry trends, and opportunities for process improvement in HR.",
                "Assist in the development, implementation, and evaluation of HR policies, procedures, and programs to support organizational goals and objectives.",
                "Collaborate with HR business partners, recruiters, and other stakeholders to gather data and provide analytical support for HR initiatives and projects.",
                "Ensure data accuracy, integrity, and security by performing regular audits, data validation, and quality assurance checks.",
                "Stay informed about relevant laws, regulations, and industry standards related to HR data privacy, confidentiality, and security.",
                "Provide support and training to HR staff and managers on data analysis tools, techniques, and best practices.",
                "Participate in cross-functional teams and projects to drive continuous improvement and innovation in HR processes and systems."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, Statistics, Data Science, or a related field; Master's degree preferred.",
                "Proven experience in HR analytics, data analysis, or a related field.",
                "Strong analytical and quantitative skills, with the ability to collect, organize, analyze, and interpret large datasets.",
                "Proficiency in statistical analysis software (e.g., SPSS, R, Python) and data visualization tools (e.g., Tableau, Power BI).",
                "Solid understanding of HR processes, policies, and practices, with the ability to translate business requirements into analytical solutions.",
                "Excellent communication and presentation skills, with the ability to effectively communicate complex data and insights to non-technical audiences.",
                "Attention to detail and accuracy in data analysis, reporting, and documentation.",
                "Ability to work independently and collaboratively in a fast-paced, dynamic environment.",
                "Strong problem-solving skills, with the ability to think critically, identify root causes, and propose solutions.",
                "Commitment to confidentiality, integrity, and ethical conduct in handling HR data and information."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $60,000 - $90,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmployeeAssistanceProgramEAPCoordinatorJobDescription = {
    title: "Employee Assistance Program (EAP) Coordinator Job Description",
    jobRole: "An Employee Assistance Program (EAP) Coordinator is responsible for administering and coordinating employee assistance programs to support the mental health, well-being, and productivity of employees within an organization. This role involves assessing employee needs, providing confidential counseling and referrals, and coordinating with external EAP providers and resources. EAP Coordinators also develop and implement wellness initiatives, conduct training sessions, and promote awareness of EAP services. They collaborate with HR, management, and other stakeholders to address workplace issues, support employee engagement, and foster a positive work environment.",
    preRead: "Use this Employee Assistance Program (EAP) Coordinator job description template to advertise open positions for an EAP Coordinator in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employee Assistance Program (EAP) Coordinator, you will be responsible for administering and coordinating employee assistance programs to support the mental health, well-being, and productivity of employees within the organization. You will assess employee needs, provide confidential counseling and referrals, and coordinate with external EAP providers and resources. Additionally, you will develop and implement wellness initiatives, conduct training sessions, and promote awareness of EAP services. You will collaborate with HR, management, and other stakeholders to address workplace issues, support employee engagement, and foster a positive work environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Administer and coordinate employee assistance programs to support the mental health, well-being, and productivity of employees.",
                "Assess employee needs through confidential counseling sessions, assessments, and surveys.",
                "Provide short-term counseling, crisis intervention, and referrals to external resources as needed.",
                "Coordinate with external EAP providers, mental health professionals, and community resources to ensure comprehensive support for employees.",
                "Develop and implement wellness initiatives, workshops, and training sessions on topics such as stress management, work-life balance, and mental health awareness.",
                "Promote awareness of EAP services through communication channels, presentations, and outreach activities.",
                "Maintain confidentiality and privacy of employee information and counseling sessions.",
                "Collaborate with HR, management, and other stakeholders to address workplace issues, conflicts, and concerns.",
                "Monitor and evaluate the effectiveness of EAP services and initiatives through feedback, surveys, and outcome measures.",
                "Stay informed about industry trends, best practices, and regulations related to employee assistance programs and mental health support."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Psychology, Counseling, Social Work, or a related field; Master's degree preferred.",
                "Certification or licensure as a counselor, therapist, or social worker preferred (e.g., LPC, LCSW, LMFT).",
                "Proven experience in employee assistance programs, counseling, or mental health support.",
                "Knowledge of counseling techniques, crisis intervention strategies, and mental health assessment tools.",
                "Strong interpersonal and communication skills, with the ability to build rapport, listen actively, and maintain empathy.",
                "Ability to maintain confidentiality, professionalism, and ethical conduct in all interactions.",
                "Excellent organizational and time management skills, with the ability to prioritize tasks and manage multiple responsibilities.",
                "Collaborative mindset and ability to work effectively in a team environment.",
                "Proficiency in Microsoft Office and other relevant software applications.",
                "Commitment to promoting diversity, equity, and inclusion in the workplace."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $50,000 - $70,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const RecruitmentManagerJobDescription = {
    title: "Recruitment Manager Job Description",
    jobRole: "A Recruitment Manager oversees the recruitment process within an organization, ensuring the timely and effective hiring of qualified candidates. This role involves developing recruitment strategies, sourcing candidates through various channels, and managing the recruitment team. Recruitment Managers collaborate with hiring managers to understand staffing needs, create job descriptions, and conduct interviews. They also oversee the selection process, negotiate job offers, and onboard new hires. Additionally, Recruitment Managers analyze recruitment metrics and trends to continuously improve the recruitment process and enhance the organization's talent acquisition efforts.",
    preRead: "Use this Recruitment Manager job description template to advertise open positions for a Recruitment Manager in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Recruitment Manager, you will be responsible for overseeing the recruitment process within the organization, ensuring the timely and effective hiring of qualified candidates. You will develop recruitment strategies, source candidates through various channels, and manage the recruitment team. Additionally, you will collaborate with hiring managers to understand staffing needs, create job descriptions, and conduct interviews. You will oversee the selection process, negotiate job offers, and onboard new hires. Furthermore, you will analyze recruitment metrics and trends to continuously improve the recruitment process and enhance the organization's talent acquisition efforts."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement recruitment strategies to attract and hire qualified candidates.",
                "Source candidates through various channels, including job boards, social media, networking, and employee referrals.",
                "Manage the recruitment team, providing guidance, support, and performance feedback.",
                "Collaborate with hiring managers to understand staffing needs and create job descriptions.",
                "Conduct interviews, assess candidate qualifications, and make hiring recommendations.",
                "Oversee the selection process, including reference checks, background screenings, and skills assessments.",
                "Negotiate job offers and manage the offer acceptance process.",
                "Coordinate with other departments, such as HR, finance, and legal, to ensure compliance with employment laws and regulations.",
                "Develop and maintain relationships with external recruitment agencies and vendors.",
                "Monitor and analyze recruitment metrics and trends to identify areas for improvement and optimize the recruitment process.",
                "Stay updated on industry trends, best practices, and innovations in recruitment and talent acquisition.",
                "Participate in career fairs, industry events, and networking activities to promote the organization and attract top talent."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Human Resources, Business Administration, or a related field; Master's degree preferred.",
                "Proven experience in recruitment, talent acquisition, or HR management.",
                "Strong knowledge of recruitment strategies, sourcing techniques, and candidate assessment methods.",
                "Excellent communication and interpersonal skills, with the ability to build relationships and collaborate effectively with internal and external stakeholders.",
                "Leadership and team management abilities, with a focus on coaching, mentoring, and developing team members.",
                "Analytical mindset and proficiency in data analysis tools to assess recruitment metrics and trends.",
                "Ability to prioritize tasks, manage multiple projects, and meet deadlines in a fast-paced environment.",
                "Proficiency in applicant tracking systems (ATS), HRIS, and other recruitment software.",
                "Commitment to diversity, equity, and inclusion in recruitment practices.",
                "Certifications in recruitment or HR management (e.g., PHR, SHRM-CP) are a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $70,000 - $100,000 per year, depending on experience, qualifications, and location.",
                "Comprehensive healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer match.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and certifications.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};

export const EmploymentCounselorJobDescription = {
    title: "Employment Counselor Job Description",
    jobRole: "An Employment Counselor provides guidance and support to individuals seeking employment or career advancement. They assess clients' skills, interests, and goals, and develop personalized employment plans to help them achieve their objectives. Employment Counselors offer career counseling, job search assistance, resume writing, interview preparation, and skills training. They also provide information about job opportunities, educational programs, and support services. Additionally, Employment Counselors may collaborate with employers to facilitate job placements and maintain relationships with community organizations and resources.",
    preRead: "Use this Employment Counselor job description template to advertise open positions for an Employment Counselor in your organization. Customize the responsibilities and qualifications according to your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Employment Counselor, you will provide guidance and support to individuals seeking employment or career advancement. You will assess clients' skills, interests, and goals, and develop personalized employment plans to help them achieve their objectives. Additionally, you will offer career counseling, job search assistance, resume writing, interview preparation, and skills training. You will provide information about job opportunities, educational programs, and support services. Furthermore, you may collaborate with employers to facilitate job placements and maintain relationships with community organizations and resources."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct assessments to evaluate clients' skills, interests, and career goals.",
                "Develop personalized employment plans based on clients' needs and objectives.",
                "Provide career counseling and guidance to help clients make informed decisions about their career paths.",
                "Assist clients with job search strategies, including resume writing, cover letter preparation, and interview skills.",
                "Offer skills training and workshops to enhance clients' employability.",
                "Connect clients to job opportunities, educational programs, and support services.",
                "Maintain accurate records of client interactions and progress toward employment goals.",
                "Collaborate with employers to identify job opportunities and facilitate job placements for clients.",
                "Maintain relationships with community organizations, educational institutions, and other resources to support clients' needs.",
                "Stay updated on labor market trends, job market demands, and industry developments to better assist clients.",
                "Adhere to ethical standards and confidentiality guidelines in working with clients' personal information."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Counseling, Psychology, Social Work, or a related field; Master's degree preferred.",
                "Proven experience in career counseling, employment services, or related field.",
                "Strong communication and interpersonal skills, with the ability to build rapport and establish trust with clients.",
                "Empathy, patience, and the ability to provide nonjudgmental support to clients.",
                "Knowledge of career development theories, job search strategies, and labor market trends.",
                "Ability to assess clients' skills, interests, and needs and develop appropriate interventions.",
                "Excellent organizational and time management skills, with the ability to prioritize tasks and meet deadlines.",
                "Proficiency in using computer software and online tools for career counseling and job search assistance.",
                "Commitment to diversity, equity, and inclusion in providing services to clients.",
                "Certification or licensure in counseling or related field (e.g., Certified Rehabilitation Counselor) is a plus."
            ]
        },
        {
            heading: "Salary and Benefits",
            paragraphs: [
                "Salary range: $40,000 - $60,000 per year, depending on experience, qualifications, and location.",
                "Healthcare benefits, including medical, dental, and vision insurance.",
                "Retirement savings plan with employer contribution.",
                "Paid time off, including vacation, sick leave, and holidays.",
                "Professional development opportunities, including training and conferences.",
                "Flexible work schedule and remote work options may be available."
            ]
        }
    ]
};
