import { SvgIcon } from '@mui/material';
import React from 'react';

export const LinkedInMcIcon = () => {
    return (<>
        <SvgIcon>
            <defs>
                <linearGradient id="linear0" gradientUnits="userSpaceOnUse" x1="-974.482" y1="1306.773" x2="-622.378" y2="1658.877" gradientTransform="matrix(0.0481975,0,0,0.0481975,50.482317,-59.468502)">
                    <stop offset="0" stopColor='#2489be' />
                    <stop offset="1" stopColor='#0575b3' />
                </linearGradient>
            </defs>
            <g id="surface1">
                <path d="M 12 0 C 5.371094 0 0 5.371094 0 12 C 0 18.628906 5.371094 24 12 24 C 18.628906 24 24 18.628906 24 12 C 24 5.371094 18.628906 0 12 0 Z M 8.796875 17.800781 L 6.164062 17.800781 L 6.164062 9.289062 L 8.796875 9.289062 Z M 7.46875 8.175781 C 6.609375 8.175781 5.910156 7.472656 5.910156 6.605469 C 5.910156 5.738281 6.609375 5.035156 7.46875 5.035156 C 8.328125 5.035156 9.027344 5.738281 9.027344 6.605469 C 9.027344 7.472656 8.328125 8.175781 7.46875 8.175781 Z M 18.671875 17.800781 L 16.050781 17.800781 L 16.050781 13.332031 C 16.050781 12.109375 15.585938 11.421875 14.617188 11.421875 C 13.5625 11.421875 13.011719 12.136719 13.011719 13.332031 L 13.011719 17.800781 L 10.484375 17.800781 L 10.484375 9.289062 L 13.011719 9.289062 L 13.011719 10.4375 C 13.011719 10.4375 13.769531 9.03125 15.574219 9.03125 C 17.378906 9.03125 18.671875 10.132812 18.671875 12.410156 Z M 18.671875 17.800781 " fill='url(#linear0)' />
            </g>
        </SvgIcon>
    </>)
};