import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { IAccountLoginResponse, ISecurityAnswerPayload, ITwoStepAuthState } from "./auth-v2-model";

const initialTwoStepState: ITwoStepAuthState = {};

// device auth with auth question and answer
export const verifySecurityAnswer = createAsyncThunk<IBaseResponse, ISecurityAnswerPayload,
    { rejectValue: IBaseResponse }>(
        "users/verifySecurityAnswer",
        async ({ question_id, auth_answer }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .put(`${EmployerApiEndpoints.DEVICE_AUTH}`, { question_id, auth_answer })
                .then((response: AxiosResponse<IBaseResponse>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const sendSecurityCode = createAsyncThunk<IBaseResponse, void, { rejectValue: IBaseResponse }>(
    "users/sendSecurityCode",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .post(`${EmployerApiEndpoints.SEND_SECURITY_CODE}`)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const validateSecurityCode = createAsyncThunk<IBaseResponse<IAccountLoginResponse>, { payload: { security_code: string } },
    { rejectValue: IBaseResponse }>(
        "users/validateSecurityCode",
        async ({ payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.VALIDATE_SECURITY_CODE}`, payload)
                .then((response: AxiosResponse<IBaseResponse<IAccountLoginResponse>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const twoStepAuthSlice = createSlice({
    name: "twoStepAuth",
    initialState: initialTwoStepState,
    reducers: {
        resetTwoStepAuthState: (state) => {
            state.deviceAuthApiStatus = 'idle';
            state.deviceAuthApiResponse = '';
            state.sendSecurityCodeApiStatus = 'idle';
            state.sendSecurityCodeApiResponse = '';
            state.validateSecurityCodeApiStatus = 'idle';
            state.validateSecurityCodeApiResponse = '';
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialTwoStepState;
        });
        //handle device authentication
        builder.addCase(verifySecurityAnswer.pending, (state) => {
            state.deviceAuthApiStatus = 'pending';
        });
        builder.addCase(verifySecurityAnswer.fulfilled, (state, action) => {
            if (action?.payload?.data.success) {
                state.deviceAuthApiStatus = 'success';
                state.twoStepAuthLoginResponse = action.payload?.data;
            } else {
                state.deviceAuthApiStatus = 'failed';
                state.deviceAuthApiResponse = action?.payload?.message;
            }
        });
        builder.addCase(verifySecurityAnswer.rejected, (state, action) => {
            state.deviceAuthApiStatus = 'failed';
            state.deviceAuthApiResponse = action?.payload?.message;
        });
        //send security code
        builder.addCase(sendSecurityCode.pending, (state) => {
            state.sendSecurityCodeApiStatus = 'pending';
        });
        builder.addCase(sendSecurityCode.fulfilled, (state, action) => {
            state.sendSecurityCodeApiStatus = 'success';
            state.sendSecurityCodeApiResponse = action?.payload?.message;
        });
        builder.addCase(sendSecurityCode.rejected, (state, action) => {
            state.sendSecurityCodeApiStatus = 'failed';
            state.sendSecurityCodeApiResponse = action?.payload?.message;
        });
        //validate security code
        builder.addCase(validateSecurityCode.pending, (state) => {
            state.validateSecurityCodeApiStatus = 'pending';
        });
        builder.addCase(validateSecurityCode.fulfilled, (state, action) => {
            state.validateSecurityCodeApiStatus = 'success';
            state.validateSecurityCodeApiResponse = action?.payload?.message;
            state.twoStepAuthLoginResponse = action.payload?.data;
        });
        builder.addCase(validateSecurityCode.rejected, (state, action) => {
            state.validateSecurityCodeApiStatus = 'failed';
            state.validateSecurityCodeApiResponse = action?.payload?.message;
        });
    },
});

export const { resetTwoStepAuthState } = twoStepAuthSlice.actions;
export default twoStepAuthSlice;
