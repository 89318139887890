import { Accordion, Dialog } from "@mui/material";
import { styled } from '@mui/material/styles';

export const FAQDialogStyled = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        maxWidth: '800px',
        minWidth: '350px'
    },
    '& .MuiDialogTitle-root, .MuiDialogContent-root': {
        backgroundColor: theme.palette.background.paper
    }
}));

export const FAQAccordion = styled(Accordion)(({ theme }) => ({
    '&.MuiAccordion-root:before': {
        backgroundColor: 'transparent'
    },
    '&.MuiAccordion-root.Mui-expanded': {
        margin: 0
    },
    '& .MuiAccordionSummary-root': {
        padding: 0,
        border: 'none'
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 'unset'
    },
    '& .MuiAccordionDetails-root': {
        padding: theme.spacing(1),
        overflow: 'auto'
    }
}));