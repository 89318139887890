import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { BlackDivider, BlockDisable, StyledLink, StyledStack, StyledTableCell, ThemedBtnRed } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails.styled';
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ApiStates } from 'shared/constants';
import { fetchAccountsList } from 'store/slices/admin/employer/emp-accounts-slice';
import { getPasswordResetEmail, resetGetPassReset } from 'store/slices/auth-v2/password-slice';

export const EmployerAccounts = ({ employerId }) => {
    const dispatch = useDispatch();
    const { accountsList, fetchingAccList } = useSelector((state) => state.employer.employerAccounts);
    const { getPasswordResetResponse, getPasswordResetStatus } = useSelector((state) => state.auth.password);
    const empEmail = useSelector((state) => state.employer.employer.empEmail);

    useEffect(() => {
        if (employerId) {
            dispatch(fetchAccountsList({ employerId }));
        }
    }, [employerId, dispatch]);

    const sendPassReset = () => {
        dispatch(getPasswordResetEmail({ email: empEmail }));
    };

    const Alerts = () => {
        switch (getPasswordResetStatus) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Sending password reset link to mail...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetGetPassReset()) }} severity="success">{getPasswordResetResponse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetGetPassReset()) }} severity="error">Error occurred while sending password reset to mail!</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <StyledStack id='employer-accounts'>
            {fetchingAccList && <BlockDisable><CircularProgress /></BlockDisable>}
            <Typography variant="h6" gutterBottom>
                Employer Accounts
            </Typography>
            <StyledStack>
                <Table size="small" aria-label="Employer Accounts">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Email</StyledTableCell>
                            <StyledTableCell>Role</StyledTableCell>
                            <StyledTableCell>Enter Account</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountsList && accountsList.map(al =>
                        (<TableRow key={al.id}>
                            <StyledTableCell>
                                {al.account?.first_name + (al.account?.middle_name !== null ? ' ' + al.account?.middle_name + ' ' : ' ')
                                    + al.account?.last_name}
                            </StyledTableCell>
                            <StyledTableCell>{al.account.email}</StyledTableCell>
                            <StyledTableCell>{al.role?.name}</StyledTableCell>
                            <StyledTableCell>
                                <StyledLink>
                                    Enter Account
                                </StyledLink>
                            </StyledTableCell>
                        </TableRow>)
                        )}
                    </TableBody>
                </Table>
            </StyledStack>
        </StyledStack>
        <BlackDivider />
        <Typography variant="subtitle2" gutterBottom>
            Send Password Reset Instructions
        </Typography>
        <Box>
            <ThemedBtnRed variant="contained" disabled={getPasswordResetStatus === ApiStates.pending} onClick={sendPassReset}>Send Password Reset</ThemedBtnRed>
        </Box>
        {Alerts()}
        <BlackDivider />
    </>);
};