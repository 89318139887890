import { Box, Divider, Grid, List, ListItemText, Stack, Typography } from '@mui/material';
import { ResourceTypography, TemplateHomesStack } from 'Modules/Marketing/Resources/Resources.styled';
import { PolicyTemplate, PolicyTemplates, requirePolicyTemplateContext } from 'Modules/Marketing/Resources/Templates/TemplateModel';
import { useAppDispatch } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { setCurrentBreadCrumb } from 'store/slices/app/breadcrumb-slice';

const policyDescriptions: PolicyTemplates = requirePolicyTemplateContext.keys().reduce((acc: PolicyTemplates, key: string) => {
    const moduleExports = requirePolicyTemplateContext(key);
    Object.keys(moduleExports).forEach((exportKey) => {
        const moduleName = exportKey.toLowerCase();
        acc[moduleName] = moduleExports[exportKey];
    });
    return acc;
}, {});

export const EmployerPolicyPage = () => {

    const { templateName } = useParams<{ templateName: string | undefined }>();
    const [similarTemplates, setSimilarTemplates] = useState<string[]>([]);
    const [policyTemplate, setPolicyTemplate] = useState<PolicyTemplate | null>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const location = useLocation();

    const setBreadcrumbInJobDescriptionTemplate = useCallback((templateName: string) => {
        const crumbName = templateName.split('/').pop()?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || '';
        const bc = {
            pathname: location.pathname,
            breadcrumbs: [
                { displayName: 'Home', href: '/employer/dashboard', isActive: true },
                { displayName: 'Resources', href: '/employer/resources', isActive: true },
                { displayName: 'Policy Templates Library', href: '/employer/resources/policy-templates', isActive: true },
                { displayName: crumbName, href: '', isActive: false }
            ]
        };
        dispatch(setCurrentBreadCrumb({ breadcrumb: bc }));
    }, [dispatch, location]);

    const truncateString = (str: string, maxLength: number) => {
        if (str.length > maxLength) {
            return str.substring(0, maxLength) + "...";
        }
        return str;
    };

    useEffect(() => {
        if (templateName) {
            const formattedTemplateName = templateName.replace(/-/g, '').replace(/[()]/g, '').toLowerCase();
            const matchedPolicyTemplate = policyDescriptions[formattedTemplateName];
            setPolicyTemplate(matchedPolicyTemplate ?? null);
            if (!matchedPolicyTemplate) {
                // Navigate to the error page if the job description is empty
                navigate('/error');
                return;
            }
            setBreadcrumbInJobDescriptionTemplate(templateName)
        }
    }, [navigate, templateName, setBreadcrumbInJobDescriptionTemplate]);

    const { title = "", policyBlurb = "", preRead = "", sections = [] } = policyTemplate || {};

    const findSimilarTemplates = useCallback((templateName: string): string[] => {
        try {
            const templateWords = templateName.toLowerCase().split('-');
            Object.keys(policyDescriptions).forEach((key) => {
                const template = policyDescriptions[key];
                const templateWordsToMatch = template.title.toLowerCase().split(" ");
                const matches = templateWords.filter(word => templateWordsToMatch.includes(word)).length;
                if (matches >= 2) {
                    similarTemplates.push(template.title);
                }
            });

            return similarTemplates;
        } catch (error) {
            console.error('Error finding similar templates:', error);
            return ["Nothing similar currently, Check back later!"];
        }
    }, [similarTemplates]);

    useEffect(() => {
        const findSimilarTemplates = (templateName: string): string[] => {
            try {
                const templateWords = templateName.toLowerCase().split('-');
                Object.keys(policyDescriptions).forEach((key) => {
                    const template = policyDescriptions[key];
                    const templateWordsToMatch = template.title.toLowerCase().split(" ");
                    const matches = templateWords.filter(word => templateWordsToMatch.includes(word)).length;
                    if (matches >= 2) {
                        similarTemplates.push(template.title);
                    }
                });

                return similarTemplates;
            } catch (error) {
                console.error('Error finding similar templates:', error);
                return ["Nothing similar currently, Check back later!"];
            }
        };
        if (templateName) {
            const createSimilarTemplates = async () => {
                const templates = findSimilarTemplates(templateName);
                setSimilarTemplates(templates)
            };
            createSimilarTemplates();
        }
    }, [templateName, similarTemplates, findSimilarTemplates]);

    return (
        <Stack padding={3}>
            <TemplateHomesStack justifyContent="center" alignItems="center" backgroundColor="#F0F8FF">
                <ResourceTypography textAlign="center" gutterBottom variant="body2" fontWeight={700} color={PrimaryThemeColor}>
                    HR Templates | Company Policy Templates
                </ResourceTypography>
                <Typography textAlign="center" padding={2} maxWidth={500} variant="h4" gutterBottom fontWeight={800}>
                    {title}
                </Typography>
                <ResourceTypography textAlign="center" maxWidth={1000} variant="body1" paragraph>
                    {policyBlurb}
                </ResourceTypography>
            </TemplateHomesStack>

            <ShContainer maxWidth="md" height="100%" margin="auto">
                <Stack padding={3}>
                    <ResourceTypography gutterBottom variant="subtitle1" paragraph>
                        {preRead}
                    </ResourceTypography>
                    <ResourceTypography variant="h6" fontWeight='bold'>
                        Table of Contents
                    </ResourceTypography>
                    <List>
                        {sections.map((section, index) => (
                            !section.heading.toLowerCase().includes('table of contents') && (
                                <ShMuiLink key={index} component={RouterLink} to={`#section${index}`} onClick={(e) => {
                                    e.preventDefault();
                                    const element = document.getElementById(`section${index}`);
                                    if (element) {
                                        element.scrollIntoView({ behavior: 'smooth' });
                                    }
                                }}>
                                    <ResourceTypography> {index}. {section.heading} </ResourceTypography>
                                </ShMuiLink>
                            )
                        ))}
                    </List>
                    {sections.map((section, index) => (
                        <Box key={index} marginBottom={3} id={`section${index}`}>
                            {!section.heading.toLowerCase().includes('table of contents') && (
                                <ResourceTypography fontWeight="bold" variant="h5" gutterBottom>
                                    {section.heading}
                                </ResourceTypography>
                            )}
                            {Array.isArray(section.paragraphs) ? (
                                <List>
                                    {section.paragraphs.map((bullet, bulletIndex) => (
                                        !section.heading.toLowerCase().includes('table of contents') && (
                                            <ListItemText key={bulletIndex}>
                                                <ResourceTypography variant="body1" paddingTop={bullet.length > 80 ? 1 : 0}>
                                                    {bullet.length < 60 && `${bulletIndex + 1}.`} {bullet}
                                                </ResourceTypography>
                                            </ListItemText>
                                        )
                                    ))}
                                </List>
                            ) : (
                                <ResourceTypography variant="body1" paragraph>
                                    {section.paragraph}
                                </ResourceTypography>
                            )}
                        </Box>
                    ))}
                </Stack>
                {policyTemplate && (
                    <Stack padding={3}>
                        <ResourceTypography variant="h6" fontWeight="bold" paddingBottom={2}> Similar Policy Templates</ResourceTypography>
                        <Divider />
                        <Grid container spacing={1} paddingTop={2} >
                            {[...new Set(similarTemplates)].slice(0, 20).map((template, index) => (
                                <Grid item sm={12} md={6} key={index}>
                                    <ShMuiLink noWrap component={RouterLink}
                                        to={`/employer/resources/policy-templates/${template.toLowerCase().split(" ").join("-")}`}
                                        variant="subtitle2" paddingTop={1} fontWeight='bold' noUnderline>
                                        {truncateString(template, 40)}
                                    </ShMuiLink>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                )}
            </ShContainer>
        </Stack>
    );
};

export default EmployerPolicyPage;