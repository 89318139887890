import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ICandidateJobNotificationsPayload, ICandidateNotificationsState } from "Modules/Core/Candidate/CandidateModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from "shared/constants";

const initialCandidateNotificationsState: ICandidateNotificationsState = {
    getCandidateNotificationResponse: '',
    getCandidateNotificationStatus: 'idle',
    updateCandidateNotificationResponse: '',
    updateCandidateNotificationStatus: 'idle'
};

export const getCandidateNotificationSettings = createAsyncThunk<IBaseResponse<ICandidateJobNotificationsPayload>,
    void, { rejectValue: IBaseResponse }>(
        "getCandidateNotificationSettings",
        async (_, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.CANDIDATE_NOTIFICATIONS}`)
                .then((response: AxiosResponse<IBaseResponse<ICandidateJobNotificationsPayload>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const updateCandidateNotificationSettings = createAsyncThunk<IBaseResponse, { payload: ICandidateJobNotificationsPayload }, { rejectValue: IBaseResponse }>(
    "updateCandidateNotificationSettings",
    async ({ payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.CANDIDATE_NOTIFICATIONS}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const candidateNotificationsSlice = createSlice({
    name: "candidateNotifications",
    initialState: initialCandidateNotificationsState,
    reducers: {
        resetGetCandidateNotificationSettings: (state) => {
            state.getCandidateNotificationStatus = 'idle';
            state.getCandidateNotificationResponse = ''
        },
        resetUpdateCandidateNotificationSettings: (state) => {
            state.updateCandidateNotificationStatus = 'idle';
            state.updateCandidateNotificationResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialCandidateNotificationsState;
        });
        // get notifications
        builder.addCase(getCandidateNotificationSettings.pending, (state) => {
            state.getCandidateNotificationStatus = 'pending'
        });
        builder.addCase(getCandidateNotificationSettings.fulfilled, (state, action) => {
            state.getCandidateNotificationStatus = 'success';
            state.candidateNotifications = action.payload?.data;
        });
        builder.addCase(getCandidateNotificationSettings.rejected, (state, action) => {
            state.getCandidateNotificationStatus = 'failed';
            state.getCandidateNotificationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // update notifications
        builder.addCase(updateCandidateNotificationSettings.pending, (state) => {
            state.updateCandidateNotificationStatus = 'pending'
        });
        builder.addCase(updateCandidateNotificationSettings.fulfilled, (state, action) => {
            state.updateCandidateNotificationStatus = 'success';
            state.updateCandidateNotificationResponse = action?.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(updateCandidateNotificationSettings.rejected, (state, action) => {
            state.updateCandidateNotificationStatus = 'failed';
            state.updateCandidateNotificationResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetCandidateNotificationSettings, resetUpdateCandidateNotificationSettings } = candidateNotificationsSlice.actions;
export default candidateNotificationsSlice;
