import WarningIcon from '@mui/icons-material/Warning';
import { DistortionIcon, FitIcon, StrongFitIcon, WeakFitIcon } from 'assets/Icons';
import { ISummaryPanelMatchType, JobsSortCols } from "Modules/Core/JobsTs/JobsModel";
import { IConfirmationDialogLabels } from "shared/SharedComponents/ConfirmationDialog/ConfirmationDialogModel";
import { ISortParamsBase, ITableHeadersBase, ITableHeadersOptions } from "shared/SharedModels";
import { JobType } from 'store/slices/employer/jobs/jobs-slice-model';

export const JobsHeaders: (ITableHeadersBase<JobsSortCols, JobsSortCols> & ITableHeadersOptions<JobType>)[] = [
    { columName: 'city', label: 'City', order: 4, isSortable: false, columNameInDb: 'city', hideColumnFor: ['template'] },
    {
        columName: 'hiring_manager', label: 'Hiring Manager', order: 3, isSortable: false,
        columNameInDb: 'hiring_manager', hideColumnFor: ['template']
    },
    // { columName: 'owner', label: 'Owner', order: 2, isSortable: false, columNameInDb: 'owner' },
    { columName: 'state', label: 'State', order: 5, isSortable: false, columNameInDb: 'state', hideColumnFor: ['template'] },
    { columName: 'name', label: 'Title', order: 1, isSortable: true, columNameInDb: 'name' },
    { columName: 'starts_at', label: 'Start Date', order: 6, isSortable: true, columNameInDb: 'created_at', hideColumnFor: ['template'] },
    // { columName: 'ends', label: 'End Date', order: 7, isSortable: false, columNameInDb: 'updated_at', hideColumnFor: ['template'] },
    { columName: 'job_id', label: 'Job ID', order: 8, isSortable: false, columNameInDb: 'job_id' },

];

export const DefaultJobsSortParams: ISortParamsBase<JobsSortCols> = {
    orderBy: 'created_at', order: 'desc'
}

export const StopHiringConfirmationLabels: IConfirmationDialogLabels = {
    title: <WarningIcon color='warning' fontSize='large' />,
    contentText: `If you stop your job, people won't be able to apply anymore. Also, you'd have to pay for another posting if you wanted to start hiring again. Be super sure!`,
    confirmButtonLabel: `Yes, stop hiring`,
    cancelButtonLabel: `Continue Hiring`
}

export const DeleteJobConfirmationLabels: IConfirmationDialogLabels = {
    title: 'Delete This Job',
    contentText: `Confirm that you want to delete this Job. This action is irreversible.`,
    confirmButtonLabel: `Confirm`,
    cancelButtonLabel: `Cancel`
}

export const EditJobConfirmationLabels: IConfirmationDialogLabels = {
    title: 'Edit Job',
    contentText: `Alert! Using 'Edit Job' will carry applicants of this job to the new posting. If you don't want applicants, use 'Re-post this Job'.`,
    confirmButtonLabel: `Ok`,
    cancelButtonLabel: `Cancel`
}

export const SummaryPanelMatchTypes: ISummaryPanelMatchType[] = [
    {
        matchLabel: 'Strong Fit',
        matchType: 'STRONG',
        matchTypeForFilter: 'STRONG',
        matchTypeIcon: <StrongFitIcon />
    },
    {
        matchLabel: 'Good Fit',
        matchType: ['GOOD', 'MATCH'],
        matchTypeForFilter: 'GOOD',
        matchTypeIcon: <FitIcon />
    },
    {
        matchLabel: 'Fair Fit',
        matchType: 'FAIR',
        matchTypeForFilter: 'FAIR',
        matchTypeIcon: <FitIcon />
    },
    {
        matchLabel: 'Weak Fit',
        matchType: ['WEAK', 'NONE'],
        matchTypeForFilter: 'WEAK',
        matchTypeIcon: <WeakFitIcon />
    },
    {
        matchLabel: 'Distortion',
        matchType: 'DISTORTION',
        matchTypeForFilter: 'DISTORTION',
        matchTypeIcon: <DistortionIcon />
    }
];