import { INavLinks } from "shared/SharedModels";

export const CoreSideNavLinks: INavLinks[] = [
  {
    redirectTo: "/employer/dashboard",
    label: "Dashboard",
    feature: 'Dashboard'
  },
  {
    redirectTo: "/employer/job/new",
    label: "Create Job",
    feature: 'Create Job'
  },
  {
    redirectTo: "/employer/jobs",
    label: "My Jobs",
    feature: 'View Jobs'
  },
  {
    redirectTo: "/employer/interviews",
    label: "My Interviews",
    feature: 'Interviews'
  },
  // Temporary, change to ShResourcesIcon
  {
    redirectTo: "/employer/resources",
    label: "Resources",
    feature: 'Resources'
  },
  // Temporary, change to ShCalendarIcon
  {
    redirectTo: "/employer/calendar",
    label: "My Calendar",
    feature: 'Calendar'
  },
  {
    redirectTo: "/employer/settings/account-information",
    label: "Settings",
    feature: 'Settings'
  },
]