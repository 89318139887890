import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { ApiStates } from "shared/constants";

const initialEmpAccState = {
    ftchngAccList: ApiStates.idle,
    accountsList: []
};

export const fetchAccountsList = createAsyncThunk(
    "accountsList",
    async ({ employerId }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.EMPLOYERS}/${employerId}/accounts/list?skip=0&limit=10000`)
            .then((response) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const empAccountsSlice = createSlice({
    name: "employerAccounts",
    initialState: initialEmpAccState,
    reducers: {
        resetGetPassReset: (state) => { state.getPasswordResetStatus = ApiStates.idle; state.getPasswordResetResponse = '' }
    },
    extraReducers: (builder) => {
        //accounts list
        builder.addCase(fetchAccountsList.pending, (state, action) => {
            state.ftchngAccList = ApiStates.pending;
        });
        builder.addCase(fetchAccountsList.fulfilled, (state, action) => {
            state.accountsList = action?.payload?.data;
            state.ftchngAccList = ApiStates.success;
        });
        builder.addCase(fetchAccountsList.rejected, (state, action) => {
            state.accountsList = [];
            state.ftchngAccList = ApiStates.failed;
        });
    },
});

export const { resetGetPassReset } = empAccountsSlice.actions;
export default empAccountsSlice;
