import { Box } from "@mui/material";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { DefaultAPIErrorMsg } from "shared/constants";

export const ShFailureAlert = ({ msg }: { msg: string }) => {
    return (<>
        <Box marginBottom={2} width='100%'>
            <ShAlert severity="error">
                {msg ?? DefaultAPIErrorMsg}
            </ShAlert>
        </Box>
    </>);
};