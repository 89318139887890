import { DashboardDraftJobWidget } from '../DashboardWidgets/DashboardDraftJobsWidget';
import { IWidget } from '../DashboardModel';
import { DashboardApplicantSummaryWidget } from '../DashboardWidgets/DashboardApplicantSummaryWidget';
import { DashboardEmployerWelcomeWidget } from '../DashboardWidgets/DashboardEmployerWelcomeWidget';
import { DashboardQuickActionTilesWidget } from '../DashboardWidgets/DashboardQuickActionTilesWidget';
import { DashboardRecentActivityWidget } from '../DashboardWidgets/DashboardRecentyActivityWidget';
import { DashboardUpcomingInterviewsWidget } from '../DashboardWidgets/DashboardUpcomingInterviewsWidget';
import { DashboardJobSummaryWidget } from '../DashboardWidgets/DashbordJobSummaryWidget';

export const WidgetsConfig: IWidget[] = [

    {
        id: 'employerWelcome',
        component: DashboardEmployerWelcomeWidget,
        gridProps: { xs: 12, sm: 12, lg: 12, xl: 4.5 },
        row: 0,
    },
    {
        id: 'applicantSummary',
        component: DashboardApplicantSummaryWidget,
        gridProps: { xs: 12, sm: 12, lg: 6, xl: 4.5 },
        row: 0,
    },
    {
        id: 'jobSummary',
        component: DashboardJobSummaryWidget,
        gridProps: { xs: 12, sm: 12, lg: 6, xl: 3 },
        row: 0,
    },
    {
        id: 'quickActionTiles',
        component: DashboardQuickActionTilesWidget,
        gridProps: { xs: 12 },
        row: 1,
    },
    {
        id: 'recentActivity',
        component: DashboardRecentActivityWidget,
        gridProps: { xs: 12, sm: 12, lg: 4, xl: 3, height: 550 },
        row: 2,
    },
    {
        id: 'upcomingInterviews',
        component: DashboardUpcomingInterviewsWidget,
        gridProps: { xs: 12, sm: 12, lg: 4, xl: 5, height: 550 },
        row: 2,
    },
    {
        id: 'draftJobs',
        component: DashboardDraftJobWidget,
        gridProps: { xs: 12, sm: 12, lg: 4, xl: 4, height: 550 },
        row: 2,
    },
];
