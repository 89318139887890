import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Alert, Box, Divider, FormControlLabel, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { ICandidateSignUpPayload } from "Modules/Core/Candidate/CandidateModel";
import { setLocalAccessToken } from "helpers/TokenHelper";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PasswordValidator } from 'shared/SharedComponents/PasswordValidator';
import { ShButton, ShGreenBtn, ShGreenCheckbox } from 'shared/SharedStyles/ShInputs';
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import { EmailRegEx } from "shared/constants";
import { setAccountLoginToTrue, setAccountRememberMe } from 'store/slices/auth-v2/auth-v2-slice';
import { resetSignUpCandidate, signUpCandidate } from "store/slices/candidate/candidate-profile-slice";
import { CandidatePrivacyPolicyStatement } from '../ApplyJob/ApplyJobConstants';


export const SurveySignUp = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showCurPass, setShowCurPass] = useState<boolean>(false);
    const [_isRememberMe, _setIsRememberMe] = useState<boolean>(false);
    const { candidateSignUpResponse, candidateSignUpStatus, candidateAuthToken } = useAppSelector((state) => state.candidate.candidateProfile);
    const [candidateSignUpPayload, setCandidateSignUpPayload] = useState<ICandidateSignUpPayload>(() => {
        return {
            email: state?.email ?? '',
            password: '',
            first_name: '',
            last_name: '',
            job_id: undefined,
            ps: undefined
        }
    });
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [isTOSChecked, setIsTOSChecked] = useState<boolean>(false)

    const validateEmail = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInvalid(!reg.test(e.target.value));
    };

    useEffect(() => {
        const { email, first_name, last_name, password } = candidateSignUpPayload;
        setIsSubmitDisabled(!email || !first_name || !last_name || !password || isEmailInvalid || !isTOSChecked);
    }, [candidateSignUpPayload, isEmailInvalid, isTOSChecked]);

    useEffect(() => {
        if (candidateSignUpStatus === 'success' && candidateAuthToken) {
            //Set token & refresh token in local storage
            setLocalAccessToken(candidateAuthToken);
            dispatch(setAccountLoginToTrue({ authToken: candidateAuthToken, loginPage: 'candidate_survey' }));
            if (_isRememberMe) {
                // Store Remember me in store only if sign up is complete.
                dispatch(setAccountRememberMe({
                    isRememberMe: true,
                    email: candidateSignUpPayload.email
                }));
            }
        }
    }, [candidateAuthToken, candidateSignUpPayload.email, candidateSignUpStatus, dispatch, _isRememberMe]);

    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

    const handleValidityChange = (isValid: boolean) => {
        setIsPasswordValid(isValid);
    };

    // reset on unmount
    useEffect(() => {
        return () => {
            dispatch(resetSignUpCandidate());
        }
    }, [dispatch]);

    return (<>
        <Typography variant='h6' gutterBottom fontWeight="bold" color={PrimaryThemeColor}>
            Take the SmoothHiring Survey
        </Typography>
        <Divider />
        <Box maxWidth={700}>
            {candidateSignUpStatus === 'failed' &&
                <Alert severity='error'>{candidateSignUpResponse}</Alert>
            }
            <Typography my={2} variant="body1">
                Welcome! Enter the following information to create your account and start the survey
            </Typography>
            <Divider />
            <Typography my={2} variant="body1" fontWeight='bold' color={PrimaryThemeColor}>Personal Details</Typography>
            <Grid maxWidth={950} container spacing={{ xs: 2, sm: 2, md: 3, lg: 3 }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField label="First name" variant="outlined" fullWidth size='small' required
                        value={candidateSignUpPayload.first_name}
                        onChange={(e) => {
                            setCandidateSignUpPayload({ ...candidateSignUpPayload, first_name: e.target.value })
                        }}></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField label="Last name" variant="outlined" fullWidth size='small' required
                        value={candidateSignUpPayload.last_name}
                        onChange={(e) => {
                            setCandidateSignUpPayload({ ...candidateSignUpPayload, last_name: e.target.value })
                        }}></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField label="Email Address" variant="outlined" fullWidth size='small' required
                        value={candidateSignUpPayload.email}
                        onChange={(e) => {
                            setCandidateSignUpPayload({ ...candidateSignUpPayload, email: e.target.value });
                            validateEmail(e)
                        }}
                        error={isEmailInvalid}></TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <TextField label="Password" variant="outlined" fullWidth size='small' required type={showCurPass ? 'text' : 'password'}
                        value={candidateSignUpPayload.password}
                        onChange={(e) => { setCandidateSignUpPayload({ ...candidateSignUpPayload, password: e.target.value }) }}
                        InputProps={{
                            endAdornment: (<InputAdornment position='end'> <IconButton
                                edge='end' onClick={() => setShowCurPass(!showCurPass)}>
                                {showCurPass ? <VisibilityIcon /> : <VisibilityOffIcon />}</IconButton>
                            </InputAdornment>)
                        }}></TextField>
                    <PasswordValidator password={candidateSignUpPayload.password}
                        confirmPassword={candidateSignUpPayload.password} onValidityChange={handleValidityChange} />
                </Grid>
            </Grid>
            <Stack marginTop={2}>
                <FormControlLabel control={<ShGreenCheckbox size='small' name="Remember Me"
                    checked={_isRememberMe} onChange={(e) => _setIsRememberMe(e.target.checked)} />}
                    label={<Typography variant='body2'>Remember Me</Typography>} />
                <FormControlLabel control={<ShGreenCheckbox size='small' checked={isTOSChecked}
                    onChange={() => setIsTOSChecked(!isTOSChecked)} />}
                    label={<Typography variant='body2'>I agree to the terms and conditions and privacy policy</Typography>} />
            </Stack>

            <Stack marginY={2} direction='row' justifyContent='space-between' width='100%' >
                <ShButton variant='outlined' onClick={() => navigate('/candidate/new')}>Back</ShButton>
                <ShGreenBtn variant='outlined' size="small" disabled={candidateSignUpStatus === 'pending'
                    || isSubmitDisabled || !isPasswordValid}
                    onClick={() => dispatch(signUpCandidate({ payload: candidateSignUpPayload }))}>
                    {candidateSignUpStatus === 'pending' ? 'Signing up...' : 'Sign up'}
                </ShGreenBtn>
            </Stack>
            {CandidatePrivacyPolicyStatement}
        </Box>
    </>);
}