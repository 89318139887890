import { Stack, Toolbar } from "@mui/material";
import { ContactInfo } from "shared/SharedComponents/ContactInfo";
import { ShLogo } from "shared/SharedComponents/ShLogo";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { DesktopOnlyStack } from "shared/SharedStyles/ShLayouts";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";

export const EmployerSubDomainTopNav = () => {
    return (
        <ShAppBar position="sticky">
            <Toolbar>
                <Stack width='100%' direction='row' justifyContent='space-between' alignItems='center'>
                    <ShLogo link='/careers' />
                    <Stack direction='row' alignItems='center'>
                        <DesktopOnlyStack direction='row' columnGap={2}>
                            <ContactInfo showDivider dividerOrientation='vertical' />
                        </DesktopOnlyStack>
                        <ThemeToggler />
                    </Stack>
                </Stack>
            </Toolbar>
        </ShAppBar>
    )
}