import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import NearMeIcon from '@mui/icons-material/NearMe';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import ResourcesHome from 'Modules/Marketing/Resources/ResourceHome';
import { IsMdScreen, IsSmScreen } from 'helpers/hooks';
import { Link as RouterLink } from 'react-router-dom';
import { ShButton, ShWordpressButton } from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { getEmployerResourcesRedirect } from 'shared/utils';

export const EmployerResourcesHome = () => {

    const isSmScreen = IsSmScreen();
    const isMdScreen = IsMdScreen();

    return (
        <>
            <ShContainer maxWidth='xl'>
                <Typography fontWeight={'bold'} paddingTop={2} variant="h6">
                    <Box display="flex" alignItems="center">
                        <AutoAwesomeIcon color='primary' /> &nbsp;&nbsp; AI Generation Tools
                    </Box>
                </Typography>
                <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2} paddingTop={3}>
                    <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                        <Stack spacing={1} padding={2}>
                            <Typography fontWeight='bold' variant="h6" noWrap>
                                Interview Kit Generator
                            </Typography>
                            <Divider/>
                            <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                Create interview kits with questions & answers, designed to help you conduct organized interviews.
                            </Typography>
                            <ShButton size='small' variant='contained' endIcon={<NearMeIcon />}
                                component={RouterLink} to={getEmployerResourcesRedirect('aiInterviewKit')}>
                                Generate Interview Kit
                            </ShButton>
                        </Stack>
                    </ShPaper>
                    <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                        <Stack spacing={1} padding={2}>
                            <Typography fontWeight='bold' variant="h6" noWrap>
                                Job Description Generator
                            </Typography>
                            <Divider/>
                            <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                Create customizable and optimized job descriptions quickly, designed to attract top talent.
                            </Typography>
                            <ShWordpressButton size='small' variant='contained' color='success' endIcon={<NearMeIcon />}
                                component={RouterLink} to={getEmployerResourcesRedirect('aiJobDescription')}>
                                Generate Job Descriptions
                            </ShWordpressButton>
                        </Stack>
                    </ShPaper>
                </Stack>
                <Typography fontWeight={'bold'} variant="h6" paddingTop={4}>
                    <Box display="flex" alignItems="center">
                        <WysiwygIcon color='primary' /> &nbsp;&nbsp; HR Templates
                    </Box>
                </Typography>
                <Grid container spacing={2} paddingTop={3}>
                    <Grid item xs={12} lg={3} md={4} sm={5} display="flex" flexDirection="column">
                    <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                            <Stack spacing={1} padding={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h6" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Job Description Templates
                                </Typography>
                                <Divider/>
                                <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                    Hire faster with our 500+ hiring templates, primed and ready to go.
                                </Typography>
                                <ShButton size="small" variant="contained" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getEmployerResourcesRedirect('jobTemplatesHome')}>
                                    See Job Description Templates
                                </ShButton>
                            </Stack>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} lg={3} md={4} sm={5} display="flex" flexDirection="column">
                    <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                            <Stack spacing={1} padding={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h6" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Offer Letter Templates
                                </Typography>
                                <Divider/>
                                <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                    Streamline your hiring process with our collection of offer letter templates.
                                </Typography>
                                <ShButton size="small" variant="contained" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getEmployerResourcesRedirect('offerLetterTemplates')}>
                                    Create Offer Template
                                </ShButton>
                            </Stack>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} lg={3} md={4} sm={5} display="flex" flexDirection="column">
                    <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                            <Stack spacing={1} padding={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h6" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Rejection Letter Templates
                                </Typography>
                                <Divider/>
                                <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                    Streamline your rejection process with our collection of rejection letter templates.
                                </Typography>
                                <ShButton size="small" variant="contained" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getEmployerResourcesRedirect('rejectionLetterTemplate')}>
                                    Create Rejection Template
                                </ShButton>
                            </Stack>
                        </ShPaper>
                    </Grid>
                    <Grid item xs={12} lg={3} md={4} sm={5} display="flex" flexDirection="column">
                        <ShPaper height='100%' marginBottom='10px' variant='outlined'>
                            <Stack spacing={1} padding={2} flexGrow={1}>
                                <Typography fontWeight="bold" variant="h6" {...(isMdScreen ? {} : { noWrap: true })}>
                                    Policy Templates
                                </Typography>
                                <Divider/>
                                <Typography variant="caption" color='gray' maxWidth={'300px'}>
                                    Streamline your policy creation with our library of policy templates.
                                </Typography>
                                <ShWordpressButton size="small" variant="contained" color="success" endIcon={<NearMeIcon />}
                                    component={RouterLink} to={getEmployerResourcesRedirect('policyTemplatesHome')}>
                                    View Policy Templates
                                </ShWordpressButton>
                            </Stack>
                        </ShPaper>
                    </Grid>
                </Grid>
            </ShContainer>
        </>
    );
};

export default ResourcesHome;
