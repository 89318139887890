import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Divider, Link, Typography } from "@mui/material";

export const ContactInfo = ({ showDivider, dividerOrientation }:
    { showDivider?: boolean, dividerOrientation?: "vertical" | "horizontal" }) => {
    return (<>
        <Link href='tel:18777898767' fontSize='small' underline='hover' >
            <Typography variant='body2' display='flex' alignItems='center'>
                <PhoneOutlinedIcon fontSize='small' />1 (877) 789-8767
            </Typography>
        </Link>
        {
            showDivider && <Divider orientation='vertical' flexItem={dividerOrientation === 'vertical'} />
        }
        <Link href='mailto:help@smoothhiring.com' fontSize='small' underline='hover'>
            <Typography variant='body2' display='flex' alignItems='center'>
                <MailOutlinedIcon fontSize='small' />help@smoothhiring.com
            </Typography>
        </Link>
    </>);
};