import { AppBar, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BorderColorDark, BorderColorLight, LogoutRedColor, TextPrimary } from 'shared/SharedStyles/styleConstants';

export const MenuToggleBtnStyled = styled(IconButton)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    })
);


export const ShAppBar = styled(AppBar)(
    ({ theme }) => ({
        backgroundColor: 'white',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,

        '& .MuiToolbar-root': {
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap'
        },

        '& .MuiToolbar-root img': {
            margin: 'auto',
        }
    })
);

export const TopNavItemsContainerStyled = styled(Box)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        },
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: TextPrimary,
        },
        '& .MuiSvgIcon-root.logout-icon': {
            color: LogoutRedColor
        },
    })
);

export const SearchInput = styled(Box)(
    ({ theme }) => ({
        borderRadius: '5px',
        height: 'fit-content',
        overflow: 'hidden',
        border: '1px solid #C2C2C2',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiInputBase-input': {
            color: '#C2C2C2',
            padding: '0 5px'
        },
        '& .MuiIconButton-root': {
            borderRadius: 0,
            padding: '5px',
            backgroundColor: '#319AEB',
            color: 'white',
            '& .MuiSvgIcon-root': {
                color: 'white',
            },
            '&:hover': {
                '& .MuiSvgIcon-root': {
                    color: '#319AEB',
                },
            }
        }
    })
);