import ClearIcon from '@mui/icons-material/Clear';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { convert } from 'html-to-text';
import { InterviewDialogStyled } from 'Modules/Core/Interviews/Interviews.styled';
import { TJobShare } from 'Modules/Core/JobsTs/JobsModel';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FacebookIcon, LinkedinIcon, XIcon } from 'react-share';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';
import { IAllJobDetails } from 'store/slices/employer/jobs/jobs-slice-model';
import { IconWrapper } from './JobsListTs/Jobs.styled';

export interface JobShareDialogProps {
  onClose?: (toggle: boolean) => void;
  jobId: number;
  navBtn?: {
    title: string;
    path: string;
  };
}

const convertHtmlToText = (html: string) => {
  return convert(html, {
    wordwrap: 130,
    selectors: [
      { selector: 'br', format: 'inline' },
      { selector: 'strong', format: 'inline' },
      { selector: 'li', format: 'liFormatter', options: { itemPrefix: '- ' } },
      { selector: 'ul', format: 'listFormatter' },
      { selector: 'ol', format: 'listFormatter' },
      {
        selector: 'p',
        format: 'pFormatter',
        options: { leadingLineBreaks: 0, trailingLineBreaks: 1 },
      },
    ],
    formatters: {
      pFormatter: function (elem, walk, builder, formatOptions) {
        builder.openBlock({
          leadingLineBreaks: formatOptions.leadingLineBreaks,
        });
        walk(elem.children, builder);
        builder.closeBlock({
          trailingLineBreaks: formatOptions.trailingLineBreaks,
        });
      },
      listFormatter: function (elem, walk, builder, formatOptions) {
        builder.openList();
        walk(elem.children, builder);
        builder.closeList({ trailingLineBreaks: 1 });
      },
      liFormatter: function (elem, walk, builder, formatOptions) {
        builder.openListItem({ prefix: '- ' });
        walk(elem.children, builder);
        builder.closeListItem();
      },
    },
    longWordSplit: { forceWrapOnLimit: true },
  });
};

const generateJobDescriptionForSharing = (jobDetails: IAllJobDetails) => {
  const { employer_company_name, experience, city, code, description, name } =
    jobDetails;

  const jobDescription = description ? convertHtmlToText(description) : '';

  const jobSummaryInfo = [
    `We are hiring!!\n`,
    name ? `Job Title: ${name}` : '',
    employer_company_name ? `Company: ${employer_company_name}` : '',
    experience ? `Experience: ${experience}` : '',
    city ? `Location: ${city}` : '',
  ]
    .filter(Boolean)
    .join('\n');

  const jobLink = code
    ? `${window.location.origin}/candidate/apply-job/${code}`
    : '';

  return {
    jobLink,
    jobSummaryInfo,
    jobDescription,
  };
};

const shareOnPlatform = (shareIn: TJobShare, jobDetails: IAllJobDetails) => {
  const { jobDescription, jobSummaryInfo, jobLink } =
    generateJobDescriptionForSharing(jobDetails);

  let url = '';
  let content = '';
  let encodedText = '';

  switch (shareIn) {
    case 'linkedIn':
      content = `${jobSummaryInfo}\n\n${jobDescription}\n\nApply now: ${jobLink}`;
      encodedText = encodeURIComponent(content);
      url = `https://www.linkedin.com/shareArticle?mini=true&text=${encodedText}`;
      break;

    case 'X':
      content = `${jobSummaryInfo}\n\nApply now: ${jobLink}`;
      encodedText = encodeURIComponent(content);
      url = `https://x.com/intent/tweet?text=${encodedText}`;
      break;

    case 'facebook':
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        jobLink
      )}`;
      break;

    default:
      return;
  }

  window.open(url, '_blank');
};

const JobShareDialog: React.FC<JobShareDialogProps> = ({ onClose, jobId, navBtn, }) => {
  const [shareIn, setShareIn] = useState<TJobShare | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { jobDetailsInInfo, getJobDetailsStatus } = useAppSelector(
    (state) => state.employer.employerJobs.jobInfo
  );

  useEffect(() => {
    if (jobDetailsInInfo?.id !== jobId) {
      dispatch(getJobDetails({ jobId: jobId }));
    }
  }, [jobId, jobDetailsInInfo?.id, dispatch]);

  useEffect(() => {
    if (shareIn && jobDetailsInInfo && getJobDetailsStatus !== 'pending') {
      shareOnPlatform(shareIn, jobDetailsInInfo);
      setShareIn(undefined);
    }
  }, [shareIn, getJobDetailsStatus, jobDetailsInInfo]);

  return (
    <>
      <InterviewDialogStyled
        onClose={() => onClose && onClose(false)}
        open={true}
        onClick={(e: { stopPropagation: () => any }) => e.stopPropagation()}
        aria-labelledby="title"
        viewSize={400}
      >
        <DialogTitle id="title">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack>
              <Typography variant="h5" component="div">
                Post to Social media
                {/* {jobDetailsInInfo?.name} */}
              </Typography>
            </Stack>

            {onClose && (
              <Stack padding={2}>
                <IconButton onClick={() => onClose(false)} aria-label="close">
                  <ClearIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="row" spacing={3}>
            <IconWrapper onClick={() => setShareIn('linkedIn')}>
              <LinkedinIcon borderRadius={50} size={40} color="primary" />
            </IconWrapper>
            <IconWrapper onClick={() => setShareIn('X')}>
              <XIcon borderRadius={50} size={40} color="primary" />
            </IconWrapper>
            <IconWrapper onClick={() => setShareIn('facebook')}>
              <FacebookIcon borderRadius={50} size={40} />
            </IconWrapper>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" gap={1}>
            {onClose && (
              <ShButton onClick={() => onClose(false)} variant="contained">
                I will do it later
              </ShButton>
            )}
            {navBtn && (
              <ShGreenBtn
                variant="contained"
                component={RouterLink}
                to={navBtn.path}
              >
                {navBtn.title}
              </ShGreenBtn>
            )}
          </Stack>
        </DialogActions>
      </InterviewDialogStyled>
    </>
  );
};

export default JobShareDialog;
