import { Avatar, Stack, Tooltip } from "@mui/material";
import { stringToColor } from "Modules/Core/Dashboard/Dashboard.styled";
import { useAppSelector } from "helpers/hooks";
import { UserEmailStyled, UserNameStyled } from "shared/SharedComponents/UserAvatar/UserAvatarBio.styled";
import { getInitialsFromName } from "shared/utils";

const UserAvatarBio = ({ isMiniDrawer }: { isMiniDrawer?: boolean }) => {
  const { firstName, middleName, lastName, email } = useAppSelector((state) => state.auth.auth);

  const userInitials = getInitialsFromName(`${firstName} ${lastName}` || "SK", true, true); 
  const avatarBackgroundColor = stringToColor(userInitials); 

  if (!email) {
    return <>User Data not found!</>;
  }

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} py={3} px={1}>
      {firstName && 
      <Avatar style={{ backgroundColor: avatarBackgroundColor }}>
        {firstName[0]?.toUpperCase()}
      </Avatar>
      }
      {!isMiniDrawer && <Stack direction="column" justifyContent="center" alignItems="flex-start" width={'calc(100% - 50px)'}>
        <Tooltip placement="top" title={firstName + (middleName && middleName?.length > 0 ? " " + middleName : "") + " " + lastName}>
          <UserNameStyled variant="h6">
            {firstName + (middleName && middleName?.length > 0 ? " " + middleName : "") + " " + lastName}
          </UserNameStyled>
        </Tooltip>
        <Tooltip title={email}>
          <UserEmailStyled variant="caption">{email}</UserEmailStyled>
        </Tooltip>
      </Stack>}
    </Stack>
  );
};

export default UserAvatarBio;