import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Container, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton } from 'shared/SharedStyles/ShInputs';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import { getCandidateApplicationHistory, resetGetCandidateApplicationHistory } from 'store/slices/candidate/candidate-profile-slice';
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';

export const CandidateHistory = () => {

    const { getCandidateHistoryStatus, getCandidateHistoryResponse,
        candidateJobApplicationHistory
    } = useAppSelector(state => state.candidate.candidateProfile);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // load history
    useEffect(() => {
        dispatch(getCandidateApplicationHistory());
    }, [dispatch]);


    const showAlerts = () => {
        switch (getCandidateHistoryStatus) {
            case 'pending':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="info">Loading Your history...</ShAlert>
                    </Box>
                </>);
            case 'failed':
                return (<>
                    <ShAlert severity='error' action={
                        <ShButton color="inherit" size="small"
                            onClick={() => dispatch(getCandidateApplicationHistory())}>
                            Retry
                        </ShButton>
                    } onClose={() => dispatch(resetGetCandidateApplicationHistory())}>
                        {getCandidateHistoryResponse}
                    </ShAlert>
                </>);
            default:
                break;
        }
    };

    return (<>
        <Container maxWidth='sm'>
            <Box padding={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                <Typography variant='h6' color={PrimaryThemeColor} gutterBottom>Your Application history</Typography>
                {showAlerts()}
                {getCandidateHistoryStatus !== 'pending' &&
                    <Stack rowGap={1} marginBottom={2}>
                        {candidateJobApplicationHistory?.length === 0 && getCandidateHistoryStatus === 'success' &&
                            <Typography variant='body2'>No job applications!</Typography>}
                        {candidateJobApplicationHistory?.map((application) => (
                            <ShPaper variant='outlined' cursor='pointer' key={application.id}>
                                <Typography variant='body2'>
                                    {application.employerjob.company_alias}
                                </Typography>
                                <Typography variant="body1">
                                    {application.employerjob.name}
                                </Typography>
                                {<Typography variant="body2">
                                    {application.employerjob.city}
                                    {application.submitted_at ? ` • Applied on ` +
                                        moment(application.submitted_at).format("MMM D, YYYY") : ""}
                                </Typography>}
                                {application.completed ? (
                                    <ShMuiLink component={RouterLink} to={`/candidate/${application.id}/resume`}>
                                        <Typography variant='subtitle2'>View / Update Resume</Typography>
                                    </ShMuiLink>
                                ) : (
                                    <>
                                        <Typography variant='body2' gutterBottom>Application Incomplete!</Typography>
                                        <ShMuiLink component={RouterLink} to={`${application.job_link}#${application.id}`}
                                            noUnderline underLineOnHover>
                                            <Typography variant='subtitle2' display='flex' alignItems='center'>
                                                Submit Application <ChevronRightIcon fontSize='small' />
                                            </Typography>
                                        </ShMuiLink>
                                    </>
                                )}
                            </ShPaper>
                        ))}
                    </Stack>
                }
                <ShButton variant="outlined" onClick={() => navigate(CandidateLoginRedirect)}>Back</ShButton>
            </Box>
        </Container>
    </>)
}