import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAccountInfo, IAccountInfoState, IAccountInfoUpdatePayload } from "Modules/Core/SettingsTs/SettingsModel";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";

const initialAccountInfoState: IAccountInfoState = {
    getAccInfoApiStatus: 'idle',
    getAccInfoApiResponse: '',
    accountInfo: {
        applicant_notifications: false,
        calendly_token: '',
        email: '',
        first_name: '',
        id: 0,
        last_name: '',
        candidate: { candidate_id: null }
    },
    updateAccInfoApiStatus: 'idle',
    updateAccInfoApiResponse: '',
};

export const getAccInfo = createAsyncThunk<IBaseResponse<IAccountInfo>, void, { rejectValue: IBaseResponse }>(
    "getAccInfo",
    async (_, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.GET_ACC_INFO}`)
            .then((response: AxiosResponse<IBaseResponse<IAccountInfo>>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

export const updateAccountInfo = createAsyncThunk<IBaseResponse, IAccountInfoUpdatePayload, { rejectValue: IBaseResponse }>(
    "updateAccountInfo",
    async ({ userId, payload }, { rejectWithValue }) => {
        return await httpAdapterInstance
            .put(`${EmployerApiEndpoints.USERS_USER}/${userId}`, payload)
            .then((response: AxiosResponse<IBaseResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const accInfoSlice = createSlice({
    name: "accInfo",
    initialState: initialAccountInfoState,
    reducers: {
        resetUpdateAccInfo: (state) => { state.updateAccInfoApiStatus = 'idle'; state.updateAccInfoApiResponse = '' }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialAccountInfoState;
        });
        //handle account information
        builder.addCase(getAccInfo.pending, (state) => {
            state.getAccInfoApiStatus = 'pending';
        });
        builder.addCase(getAccInfo.fulfilled, (state, action) => {
            state.getAccInfoApiStatus = 'success';
            state.getAccInfoApiResponse = action?.payload?.message;
            state.accountInfo = action?.payload?.data;
        });
        builder.addCase(getAccInfo.rejected, (state, action) => {
            state.getAccInfoApiStatus = 'failed';
            state.getAccInfoApiResponse = action?.payload?.message;
        });
        //handle update account information
        builder.addCase(updateAccountInfo.pending, (state) => {
            state.updateAccInfoApiStatus = 'pending';
        });
        builder.addCase(updateAccountInfo.fulfilled, (state, action) => {
            state.updateAccInfoApiStatus = 'success';
            state.updateAccInfoApiResponse = action?.payload?.message;
        });
        builder.addCase(updateAccountInfo.rejected, (state, action) => {
            state.updateAccInfoApiStatus = 'failed';
            state.updateAccInfoApiResponse = action?.payload?.message;
        });
    },
});

export const { resetUpdateAccInfo } = accInfoSlice.actions;
export default accInfoSlice;
