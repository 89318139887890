import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { PURGE } from "redux-persist";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";
import { IBaseResponse } from "shared/SharedModels";
import { DefaultAPIErrorMsg } from "shared/constants";
import { IAssessmentMetaState, ICandidateAssessmentMeta } from "./meta-data-model";

const initialAssessmentMetaState: IAssessmentMetaState = {};

export const getCandidateAssessmentMetadata = createAsyncThunk<IBaseResponse<ICandidateAssessmentMeta>,
    void, { rejectValue: IBaseResponse }>(
        "getCandidateAssessmentMetadata",
        async (_, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.ASSESSMENT_META}`)
                .then((response: AxiosResponse<IBaseResponse<ICandidateAssessmentMeta>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

const assessmentMetaSlice = createSlice({
    name: "assessmentMeta",
    initialState: initialAssessmentMetaState,
    reducers: {
        resetGetCandidateAssessmentMetadata: (state) => {
            state.getCandidateAssessmentMetaStatus = 'idle';
            state.getCandidateAssessmentMetaResponse = ''
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialAssessmentMetaState;
        });
        // get assessment meta data
        builder.addCase(getCandidateAssessmentMetadata.pending, (state) => {
            state.getCandidateAssessmentMetaStatus = 'pending'
        });
        builder.addCase(getCandidateAssessmentMetadata.fulfilled, (state, action) => {
            state.getCandidateAssessmentMetaStatus = 'success';
            state.candidateAssessmentMetadata = action.payload?.data;
        });
        builder.addCase(getCandidateAssessmentMetadata.rejected, (state, action) => {
            state.getCandidateAssessmentMetaStatus = 'failed';
            state.getCandidateAssessmentMetaResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
    }
});

export const { resetGetCandidateAssessmentMetadata } = assessmentMetaSlice.actions;
export default assessmentMetaSlice;
