
export const similarTemplatesSeed = "765765765765765765765765765" // Date: 06/27/2024
export const JobDescriptions = {
  Administrative: [
    "Administrative Assistant Job description",
    "Office Manager Job description",
    "Receptionist Job description",
    "Executive Assistant Job description",
    "Data Entry Clerk Job description",
    "Administrative Coordinator Job description",
    "Office Administrator Job description",
    "Administrative Support Specialist Job description",
    "Front Desk Coordinator Job description",
    "Administrative Services Manager Job description",
    "Personal Assistant Job description",
    "Secretary Job description",
    "Administrative Officer Job description",
    "Clerical Assistant Job description",
    "Administrative Clerk Job description",
    "Senior Administrative Assistant Job description",
    "Junior Administrative Assistant Job description",
    "Virtual Assistant Job description",
    "Records Management Clerk Job description",
    "Administrative Program Coordinator Job description",
    "Executive Secretary Job description",
    "Reception Supervisor Job description",
    "Facilities Coordinator Job description",
    "Mailroom Clerk Job description",
    "Project Administrator Job description",
    "Legal Secretary Job description",
    "Medical Administrative Assistant Job description",
    "Human Resources Administrative Assistant Job description",
    "Office Coordinator Job description",
    "Customer Service Administrator Job description",
    "Events Coordinator Job description",
    "Document Control Specialist Job description",
    "Scheduling Coordinator Job description",
    "Billing Coordinator Job description",
    "Inventory Control Clerk Job description",
    "File Clerk Job description",
    "Corporate Receptionist Job description",
    "Real Estate Administrative Assistant Job description",
    "Training Coordinator Job description",
    "Marketing Administrative Assistant Job description",
    "Operations Administrative Assistant Job description",
    "Research Administrative Assistant Job description",
    "Education Administrative Assistant Job description",
    "Library Assistant Job description",
    "Facilities Manager Job description",
    "Property Management Administrative Assistant Job description",
    "Compliance Administrator Job description",
    "Procurement Administrative Assistant Job description",
    "Travel Coordinator Job description",
    "Sales Administrative Assistant Job description",
    "Quality Assurance Administrative Assistant Job description",
    "Technical Administrative Assistant Job description",
    "Communications Administrative Assistant Job description",
    "Logistics Coordinator Job description",
    "Safety Coordinator Job description",
    "Payroll Administrative Assistant Job description"
  ],
  Accounting: [
    "Billing Manager Job description",
    "Accounts Payable Specialist Job description",
    "Financial Accountant Job Description",
    "Senior Tax and Accounting Specialist Job description",
    "Accounting Intern Job description",
    "Tax Manager Job Description",
    "Cost Accountant Job Description",
    "Senior Auditor Job Description",
    "Accounts Receivable Clerk Job Description",
    "Credit Analyst Job Description",
    "Bookkeeper Job Description",
    "Budget Analyst Job Description",
    "Management Accountant Job Description",
    "Certified Public Accountant (CPA) Job Description",
    "Accounts Receivable Manager Job Description",
    "Forensic Accountant Job Description",
    "Payroll Administrator Job Description",
    "Internal Auditor Job Description",
    "Revenue Accountant Job Description",
    "Financial Controller Job Description",
    "Financial Reporting Manager Job Description",
    "Tax Accountant Job Description",
    "Cost Analyst Job Description",
    "Finance Director Job Description",
    "Billing Coordinator Job Description",
    "Audit Manager Job Description",
    "Treasury Analyst Job Description",
    "Accounts Payable Clerk Job Description",
    "Financial Analyst Job Description",
    "Corporate Accountant Job Description",
    "Accounts Payable Manager Job Description",
    "Credit Manager Job Description",
    "Senior Accountant Job Description",
    "Tax Preparer Job Description",
    "Mortgage Loan Officer Job Description",
    "Budget Manager Job Description",
    "Accounting Supervisor Job Description",
    "Billing Analyst Job Description",
    "Loan Processor Job Description",
    "Investment Accountant Job Description",
    "Accounting Clerk Job Description",
    "Payroll Specialist Job Description",
    "Audit Associate Job Description",
    "Collections Specialist Job Description",
    "Financial Planner Job Description",
    "Cost Controller Job Description",
    "Revenue Analyst Job Description",
    "Credit Controller Job Description",
    "Tax Analyst Job Description",
    "Accounts Payable Analyst Job Description",
    "Payroll Coordinator Job Description",
  ],
  Education: [
    "Teacher Job description",
    "School Counselor Job description",
    "Librarian Job description",
    "Education Coordinator Job description",
    "Special Education Teacher Job description",
    "Principal Job Description",
    "Assistant Principal Job Description",
    "School Administrator Job Description",
    "Curriculum Developer Job Description",
    "Instructional Designer Job Description",
    "Educational Consultant Job Description",
    "School Psychologist Job Description",
    "Education Specialist Job Description",
    "Guidance Counselor Job Description",
    "Career Counselor Job Description",
    "Admissions Counselor Job Description",
    "Academic Advisor Job Description",
    "Student Affairs Coordinator Job Description",
    "Registrar Job Description",
    "Enrollment Specialist Job Description",
    "Education Program Manager Job Description",
    "Educational Technologist Job Description",
    "Learning Support Specialist Job Description",
    "ESL Teacher Job Description",
    "Language Arts Teacher Job Description",
    "Mathematics Teacher Job Description",
    "Science Teacher Job Description",
    "Social Studies Teacher Job Description",
    "Physical Education Teacher Job Description",
    "Art Teacher Job Description",
    "Music Teacher Job Description",
    "Drama Teacher Job Description",
    "Computer Science Teacher Job Description",
    "Health Teacher Job Description",
    "Specialty Subject Teacher Job Description",
    "Early Childhood Educator Job Description",
    "Elementary School Teacher Job Description",
    "Middle School Teacher Job Description",
    "High School Teacher Job Description",
    "Online Instructor Job Description",
    "Tutor Job Description",
    "Education Researcher Job Description",
    "School Librarian Media Specialist Job Description",
    "Instructional Coach Job Description",
    "Educational Program Coordinator Job Description",
    "Community Education Coordinator Job Description",
    "After School Program Coordinator Job Description",
    "Summer School Teacher Job Description",
    "Alternative Education Teacher Job Description",
    "Vocational Education Instructor Job Description"
],
  Finance: [
    "Financial Analyst Job description",
    "Investment Banker Job description",
    "Finance Manager Job description",
    "Risk Manager Job description",
    "Financial Advisor Job description",
    "Financial Controller Job Description",
    "Accounting Manager Job Description",
    "Treasury Analyst Job Description",
    "Budget Analyst Job Description",
    "Credit Analyst Job Description",
    "Financial Reporting Analyst Job Description",
    "Investment Analyst Job Description",
    "Portfolio Manager Job Description",
    "Hedge Fund Analyst Job Description",
    "Equity Research Analyst Job Description",
    "Fixed Income Analyst Job Description",
    "Derivatives Analyst Job Description",
    "Compliance Officer Job Description",
    "Regulatory Reporting Analyst Job Description",
    "Internal Auditor Job Description",
    "External Auditor Job Description",
    "Tax Manager Job Description",
    "Tax Consultant Job Description",
    "Financial Planner Job Description",
    "Wealth Management Advisor Job Description",
    "Personal Financial Advisor Job Description",
    "Financial Consultant Job Description",
    "Financial Risk Analyst Job Description",
    "Credit Risk Analyst Job Description",
    "Market Risk Analyst Job Description",
    "Operational Risk Analyst Job Description",
    "Quantitative Analyst Job Description",
    "Actuarial Analyst Job Description",
    "Mergers and Acquisitions Analyst Job Description",
    "Corporate Finance Analyst Job Description",
    "Investment Banking Associate Job Description",
    "Venture Capital Analyst Job Description",
    "Private Equity Analyst Job Description",
    "Commercial Banking Analyst Job Description",
    "Corporate Treasury Analyst Job Description",
    "Financial Modelling Analyst Job Description",
    "Financial Systems Analyst Job Description",
    "Financial Accountant Job Description",
    "Cost Accountant Job Description",
    "Management Accountant Job Description",
    "Fund Accountant Job Description",
    "Real Estate Analyst Job Description",
    "Credit Manager Job Description",
    "Collections Analyst Job Description",
    "Financial Operations Manager Job Description"
  ],
    Healthcare: [
    "Registered Nurse Job description",
    "Medical Assistant Job description",
    "Healthcare Administrator Job description",
    "Pharmacy Technician Job description",
    "Physical Therapist Job description",
    "Radiologic Technologist Job description",
    "Occupational Therapist Job description",
    "Speech-Language Pathologist Job description",
    "Dental Hygienist Job description",
    "Respiratory Therapist Job description",
    "Clinical Laboratory Technician Job description",
    "Emergency Medical Technician (EMT) Job description",
    "Dietitian Job description",
    "Licensed Practical Nurse (LPN) Job description",
    "Surgical Technologist Job description",
    "Phlebotomist Job description",
    "Medical Records Technician Job description",
    "Certified Nursing Assistant (CNA) Job description",
    "Home Health Aide Job description",
    "Genetic Counselor Job description",
    "Cardiovascular Technologist Job description",
    "Biomedical Engineer Job description",
    "Health Information Technician Job description",
    "Nurse Practitioner Job description",
    "Orthopedic Technician Job description",
    "Patient Care Coordinator Job description",
    "Medical Billing and Coding Specialist Job description",
    "Health Services Manager Job description",
    "Medical Transcriptionist Job description",
    "Anesthesiologist Assistant Job description",
    "Clinical Research Coordinator Job description",
    "Public Health Administrator Job description",
    "Veterinary Technician Job description",
    "Massage Therapist Job description",
    "Chiropractor Job description",
    "Occupational Health and Safety Specialist Job description",
    "Medical Equipment Preparer Job description",
    "Psychiatric Technician Job description",
    "Rehabilitation Counselor Job description",
    "Substance Abuse Counselor Job description",
    "Clinical Nurse Specialist Job description",
    "Health Educator Job description",
    "Medical Secretary Job description",
    "Orthotist and Prosthetist Job description",
    "Radiation Therapist Job description",
    "Nuclear Medicine Technologist Job description",
  ],
  Law: [
    "Corporate Lawyer Job description",
    "Paralegal Job description",
    "Legal Secretary Job description",
    "Compliance Officer Job description",
    "Contract Manager Job description",
    "Litigation Attorney Job description",
    "Legal Assistant Job description",
    "Legal Counsel Job description",
    "Intellectual Property Lawyer Job description",
    "Legal Analyst Job description",
    "Tax Attorney Job description",
    "Immigration Lawyer Job description",
    "Real Estate Attorney Job description",
    "Environmental Lawyer Job description",
    "Labor and Employment Lawyer Job description",
    "Family Lawyer Job description",
    "Criminal Defense Attorney Job description",
    "Healthcare Compliance Officer Job description",
    "Corporate Compliance Specialist Job description",
    "Bankruptcy Lawyer Job description",
    "Civil Rights Attorney Job description",
    "Estate Planning Lawyer Job description",
    "Insurance Defense Attorney Job description",
    "Workers' Compensation Attorney Job description",
    "Securities Lawyer Job description",
    "Government Lawyer Job description",
    "Banking and Finance Lawyer Job description",
    "Privacy Lawyer Job description",
    "Antitrust Lawyer Job description",
    "Legal Researcher Job description",
    "Arbitrator Job description",
    "Mediator Job description",
    "Legal Investigator Job description",
    "Judicial Clerk Job description",
    "Law Firm Administrator Job description",
    "Regulatory Affairs Specialist Job description",
    "Patent Attorney Job description",
    "Trademark Attorney Job description",
    "International Law Attorney Job description",
    "Legal Recruiter Job description",
    "Legal Compliance Analyst Job description",
    "Immigration Paralegal Job description",
    "Court Reporter Job description",
    "Legal Ethics Consultant Job description",
    "Legal Operations Manager Job description",
    "Legislative Assistant Job description",
  ],
  Human_Resources: [
    "HR Manager Job description",
    "Recruitment Specialist Job description",
    "HR Assistant Job description",
    "Talent Acquisition Specialist Job description",
    "HR Coordinator Job description",
    "Compensation and Benefits Manager Job description",
    "Employee Relations Specialist Job description",
    "Training and Development Manager Job description",
    "HR Generalist Job description",
    "Organizational Development Specialist Job description",
    "Workforce Planning Analyst Job description",
    "HRIS Analyst Job description",
    "Payroll Administrator Job description",
    "Employment Law Attorney Job description",
    "Diversity and Inclusion Coordinator Job description",
    "Labor Relations Manager Job description",
    "HR Consultant Job description",
    "Workforce Diversity Manager Job description",
    "Benefits Administrator Job description",
    "HR Compliance Specialist Job description",
    "Recruiting Coordinator Job description",
    "Human Resources Director Job description",
    "Learning and Development Specialist Job description",
    "Employee Engagement Manager Job description",
    "Compensation Analyst Job description",
    "HR Business Partner Job description",
    "Training Coordinator Job description",
    "Employment Specialist Job description",
    "Talent Management Specialist Job description",
    "Career Development Manager Job description",
    "HR Systems Administrator Job description",
    "Labor Relations Specialist Job description",
    "Staffing Manager Job description",
    "Workforce Development Coordinator Job description",
    "Compensation Consultant Job description",
    "Employee Benefits Specialist Job description",
    "Onboarding Coordinator Job description",
    "HR Recruiter Job description",
    "Organizational Change Manager Job description",
    "Performance Improvement Specialist Job description",
    "Succession Planning Manager Job description",
    "HR Analyst Job description",
    "Employee Assistance Program (EAP) Coordinator Job description",
    "Recruitment Manager Job description",
    "Employment Counselor Job description",
  ],
  Marketing: [
    "Marketing Manager Job description",
    "Content Strategist Job description",
    "SEO Specialist Job description",
    "Social Media Manager Job description",
    "Brand Manager Job description",
    "Digital Marketing Specialist Job description",
    "Product Marketing Manager Job description",
    "Marketing Coordinator Job description",
    "Copywriter Job description",
    "Market Research Analyst Job description",
    "Email Marketing Specialist Job description",
    "PPC Specialist Job description",
    "Marketing Analyst Job description",
    "Marketing Director Job description",
    "Affiliate Marketing Manager Job description",
    "Event Marketing Manager Job description",
    "Public Relations Specialist Job description",
    "Creative Director Job description",
    "Marketing Communications Manager Job description",
    "Web Content Manager Job description",
    "Advertising Manager Job description",
    "Media Planner Job description",
    "Content Marketing Manager Job description",
    "Community Manager Job description",
    "Growth Hacker Job description",
    "Influencer Marketing Manager Job description",
    "Graphic Designer Job description",
    "Video Content Creator Job description",
    "E-commerce Marketing Manager Job description",
    "Mobile Marketing Manager Job description",
    "UX Designer Job description",
    "Marketing Automation Specialist Job description",
    "Digital Advertising Specialist Job description",
    "Customer Relationship Manager Job description",
    "Campaign Manager Job description",
    "Marketing Operations Manager Job description",
    "Product Manager Job description",
    "Conversion Rate Optimization (CRO) Specialist Job description",
    "Marketing Consultant Job description",
    "Analytics Manager Job description",
    "Creative Strategist Job description",
    "Search Engine Marketing (SEM) Specialist Job description",
    "Trade Marketing Manager Job description",
    "Retail Marketing Manager Job description",
    "Direct Marketing Specialist Job description",
    "Field Marketing Manager Job description",
  ],
  IT: [
    "Software Developer Job description",
    "IT Support Specialist Job description",
    "Network Administrator Job description",
    "Cybersecurity Analyst Job description",
    "Data Scientist Job description",
    "Systems Administrator Job description",
    "Database Administrator Job description",
    "Cloud Engineer Job description",
    "DevOps Engineer Job description",
    "Front-End Developer Job description",
    "Back-End Developer Job description",
    "Full-Stack Developer Job description",
    "IT Project Manager Job description",
    "IT Security Specialist Job description",
    "Business Analyst Job description",
    "Data Analyst Job description",
    "Software Engineer Job description",
    "Machine Learning Engineer Job description",
    "Artificial Intelligence Engineer Job description",
    "Mobile App Developer Job description",
    "Technical Support Engineer Job description",
    "IT Manager Job description",
    "Web Developer Job description",
    "Systems Analyst Job description",
    "Network Engineer Job description",
    "Information Security Manager Job description",
    "Cloud Architect Job description",
    "Technical Writer Job description",
    "UX/UI Designer Job description",
    "Solutions Architect Job description",
    "IT Consultant Job description",
    "Big Data Engineer Job description",
    "Penetration Tester Job description",
    "Software Tester Job description",
    "Help Desk Technician Job description",
    "IT Operations Manager Job description",
    "IT Coordinator Job description",
    "ERP Consultant Job description",
    "Scrum Master Job description",
    "IT Auditor Job description",
    "Application Support Analyst Job description",
    "Security Consultant Job description",
    "IT Trainer Job description",
    "Data Engineer Job description",
    "Network Security Engineer Job description",
    "Site Reliability Engineer Job description",
  ],
  Sales: [
    "Sales Manager Job description",
    "Account Executive Job description",
    "Sales Representative Job description",
    "Business Development Manager Job description",
    "Sales Analyst Job description",
    "Sales Director Job description",
    "Inside Sales Representative Job description",
    "Sales Consultant Job description",
    "Regional Sales Manager Job description",
    "Key Account Manager Job description",
    "Sales Coordinator Job description",
    "Channel Sales Manager Job description",
    "Sales Engineer Job description",
    "Sales Operations Manager Job description",
    "Technical Sales Representative Job description",
    "Sales Trainer Job description",
    "Customer Success Manager Job description",
    "Account Manager Job description",
    "Field Sales Representative Job description",
    "Sales Support Specialist Job description",
    "Sales Development Representative Job description",
    "Sales Supervisor Job description",
    "Telemarketing Representative Job description",
    "Retail Sales Associate Job description",
    "International Sales Manager Job description",
    "Sales Administrator Job description",
    "Senior Account Executive Job description",
    "Sales Enablement Manager Job description",
    "Salesforce Administrator Job description",
    "Sales Executive Job description",
    "Customer Service Representative Job description",
    "Sales Account Executive Job description",
    "Salesforce Developer Job description",
    "Inbound Sales Representative Job description",
    "Pre-Sales Engineer Job description",
    "Sales Support Administrator Job description",
    "Sales Assistant Job description",
    "Salesforce Consultant Job description",
    "Salesforce Architect Job description",
    "Sales Operations Analyst Job description",
    "Sales Negotiator Job description",
    "Sales Specialist Job description",
    "Salesforce Analyst Job description",
    "Sales Recruiter Job description",
    "Salesforce Project Manager Job description",
  ],
};
