import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { clearTokensFromStorage, getAppContextInLocalStorage, setAppContextInLocalStorage, setLocalAccessToken } from 'helpers/TokenHelper';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultAPISuccessMsg } from 'shared/constants';
import { IAccountExistsResponse, IAccountLoginPayload, IAccountLoginResponse, IAuthState, ILoginFailedResponse, ITwoStepAuthQuestion, TAppContext, TLogoutType } from './auth-v2-model';

const initialAuthState: IAuthState = {
    accountLoginStatus: 'idle',
    isRememberMe: false,
    isAccountLoggedIn: false,
    accountAccess: {},
    twoStepAuth: {},
    currentAppContext: getAppContextInLocalStorage()
};

export const checkIfAccountExists = createAsyncThunk<IBaseResponse<IAccountExistsResponse>,
    { email: string }, { rejectValue: IBaseResponse }>(
        "checkIfAccountExists",
        async ({ email }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .get(`${EmployerApiEndpoints.IS_ACCOUNT_EXISTS}?email=${email}`)
                .then((response: AxiosResponse<IBaseResponse<IAccountExistsResponse>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

export const loginAccount = createAsyncThunk<IBaseResponse<IAccountLoginResponse>, { payload: IAccountLoginPayload, loginPage: TAppContext },
    { rejectValue: ILoginFailedResponse }>(
        "loginAccount",
        async ({ payload }, { rejectWithValue }) => {
            return await httpAdapterInstance
                .post(`${EmployerApiEndpoints.ACCOUNT_LOGIN}`, payload)
                .then((response: AxiosResponse<IBaseResponse<IAccountLoginResponse>>) => response?.data)
                .catch((error) => {
                    throw rejectWithValue(error.response.data);
                });
        }
    );

/**
  * Reusable function to set state variables like Name,mail etc once user is logged in.
  * Returns the modified state with login data.
*/
const setAccountLogin = (state: IAuthState, loginData: IAccountLoginResponse, loginPage: TAppContext): IAuthState => {
    if (loginData) {
        const { first_name, last_name, token, access, middle_name, id, email, signup_source, employer_id, candidate,
            last_login_devise, last_sign_in_at, last_sign_in_ip, lat, long, auth_question_id,
            email_varification, security_varification, question, country
        } = loginData;
        if (access) {
            state.isAccountLoggedIn = true;
            state.loggedInPage = loginPage;
            state.employerId = employer_id;
            state.socialSignUpSource = signup_source;
            state.accountAuthToken = token;
            // Set access token in local storage
            if (token) {
                setLocalAccessToken(token);
            }
            state.accountId = id;
            state.firstName = first_name;
            state.middleName = middle_name;
            state.lastName = last_name;
            state.email = email;
            state.accountAccess = access;
            state.accountCountry = country;
            state.candidateId = candidate?.id;
            state.accountLoginInfo = { last_login_devise, last_sign_in_at, last_sign_in_ip, lat, long };

            state.twoStepAuth = {
                ...state.twoStepAuth,
                authQuestionId: auth_question_id,
                emailVerification: email_varification,
                securityVerification: security_varification,
                showTwoStepAuthWindow: false,
                twoStepAuthQuestion: question
            };
        }
    }
    return state;
};

const authV2 = createSlice({
    name: 'authV2',
    initialState: initialAuthState,
    reducers: {
        resetAuth: () => initialAuthState,
        resetCheckIfAccountExists: (state) => {
            state.checkIfAccountExistsStatus = 'idle';
            state.checkIfAccountExistsResponse = undefined;
            state.checkIfAccountExistsResponseMsg = '';
        },
        setAccountLoginToTrue: (state, action: PayloadAction<{ authToken: string, loginPage: TAppContext }>) => {
            state.isAccountLoggedIn = true;
            state.loggedInPage = action.payload.loginPage;
            state.accountAuthToken = action.payload.authToken;
        },
        logoutAccount: (state, action: PayloadAction<{ logoutType: TLogoutType }>) => {
            state.isAccountLoggedIn = false;
            state.accountId = undefined;
            state.employerId = undefined;
            state.accountLoginStatus = 'idle';
            state.accountLoginResponseMsg = undefined;
            state.accountAuthToken = undefined;
            state.accountAccess = {};
            state.firstName = undefined;
            state.middleName = undefined;
            state.lastName = undefined;
            state.twoStepAuth = {};
            state.checkIfAccountExistsStatus = 'idle';
            state.checkIfAccountExistsResponseMsg = '';
            if (!state.isRememberMe) {
                state.email = undefined;
            }
            state.logoutType = action.payload.logoutType;
            if (action.payload.logoutType !== 'auto') {
                state.checkIfAccountExistsResponse = undefined;
            }
            state.accountCountry = undefined;
            state.accountLoginInfo = undefined;
            state.currentAppContext = getAppContextInLocalStorage();
            clearTokensFromStorage();
        },
        setAccountRememberMe: (state, action: PayloadAction<{ isRememberMe: boolean, email: string }>) => {
            state.isRememberMe = action.payload?.isRememberMe;
            state.email = action.payload?.email;
        },
        setTwoStepAuthForSocial: (state, action: PayloadAction<{
            emailVerification?: boolean,
            securityVerification?: boolean,
            question?: ITwoStepAuthQuestion
        }>) => {
            state.twoStepAuth = {
                ...state.twoStepAuth,
                twoStepAuthQuestion: action.payload.question,
                emailVerification: action.payload.emailVerification,
                securityVerification: action.payload.securityVerification
            }
        },
        /**
         * Invoked when login with Auth providers is successful which in turn calls 'setAccountLogin' to set the state.
        */
        loginThruSocial: (state, action: PayloadAction<{ loginResponse: IAccountLoginResponse, loginPage: TAppContext }>) => {
            if (action.payload) {
                state = setAccountLogin(state, action.payload.loginResponse, action.payload.loginPage);
            }
        },
        loginWithTwoStepAuth: (state, action: PayloadAction<{ twoStepLoginResponse: IAccountLoginResponse, loginPage: TAppContext }>) => {
            if (action.payload.twoStepLoginResponse) {
                /*
                * The current implementation of two-step doesn't consider device identification or time.
                * This manually setting two-step related values to false once logged in.
                * If session is expired or user logs out, then they will be cleared from state.
                * On security answer validation response the login payload is received in 'action.payload.twoStepLoginResponse.user'.
                * For Security code valid response the login payload is received in 'action.payload.twoStepLoginResponse'.
                * Thus first spread 'action.payload.twoStepLoginResponse' followed by 'action.payload.twoStepLoginResponse.user'
                * to avoid properties being overridden.
                */
                const _twoStepLoginResponse = { ...action.payload.twoStepLoginResponse, ...action.payload.twoStepLoginResponse.user };
                state = setAccountLogin(state, _twoStepLoginResponse, action.payload.loginPage);
            }
        },
        updateUserAuthPreferences: (state, action) => {
            state.twoStepAuth = {
                ...state.twoStepAuth,
                emailVerification: action.payload.emailVerification,
                securityVerification: action.payload.securityVerification,
                authQuestionId: action.payload.authQuestionId
            }
        },
        updateAccountDetails: (state, action: PayloadAction<{ firstName: string, lastName: string, middle?: string }>) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.middleName = action.payload.middle
        },
        setAppContext: (state, action: PayloadAction<{ currentAppContext: TAppContext }>) => {
            state.currentAppContext = action.payload.currentAppContext;
            setAppContextInLocalStorage(action.payload.currentAppContext);
        },
        resetLogoutType: (state) => {
            state.logoutType = undefined;
        }
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, (state) => {
            let _initialAuthState: IAuthState = JSON.parse(JSON.stringify(initialAuthState));
            _initialAuthState.isRememberMe = state.isRememberMe;
            if (_initialAuthState.isRememberMe) {
                _initialAuthState.email = state.email;
            }
            return _initialAuthState;
        });
        // check if account exists
        builder.addCase(checkIfAccountExists.pending, (state) => {
            state.checkIfAccountExistsStatus = 'pending';
        });
        builder.addCase(checkIfAccountExists.fulfilled, (state, action) => {
            state.checkIfAccountExistsStatus = 'success';
            state.checkIfAccountExistsResponse = action.payload?.data;
            state.checkIfAccountExistsResponseMsg = action?.payload?.message ?? DefaultAPISuccessMsg;
        });
        builder.addCase(checkIfAccountExists.rejected, (state, action) => {
            state.checkIfAccountExistsStatus = 'failed';
            state.checkIfAccountExistsResponseMsg = action?.payload?.message ?? DefaultAPIErrorMsg;
        });
        // loginAccount
        builder.addCase(loginAccount.pending, (state) => {
            state.accountLoginStatus = 'pending'
        });
        builder.addCase(loginAccount.fulfilled, (state, action) => {
            state.accountLoginStatus = 'success';
            state.accountLoginResponseMsg = action.payload?.message ?? DefaultAPISuccessMsg;
            const { email_varification, security_varification, showTwoStepAuthWindow, question,
                email, token } = action.payload?.data;
            state.twoStepAuth = {
                ...state.twoStepAuth,
                emailVerification: email_varification,
                securityVerification: security_varification,
                showTwoStepAuthWindow: showTwoStepAuthWindow
            }
            state.email = email ?? '';
            // Set access token in local storage
            if (token) {
                setLocalAccessToken(token);
            }
            if (showTwoStepAuthWindow) {
                state.twoStepAuth = {
                    ...state.twoStepAuth,
                    twoStepAuthQuestion: question
                }
            }
            if (action.payload?.data && !state.twoStepAuth?.showTwoStepAuthWindow) {
                state = setAccountLogin(state, action.payload?.data, action.meta.arg.loginPage);
            }
        });
        builder.addCase(loginAccount.rejected, (state, action) => {
            state.accountLoginStatus = 'failed';
            state.accountLoginResponseMsg = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.socialSignUpSource = action.payload?.signup_source;
        });
    }
});
export const { resetAuth, setAccountLoginToTrue, logoutAccount, setAccountRememberMe, updateUserAuthPreferences,
    loginThruSocial, loginWithTwoStepAuth, updateAccountDetails, resetCheckIfAccountExists, setAppContext,
    resetLogoutType, setTwoStepAuthForSocial } = authV2.actions;
export default authV2;
