import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";
import SvgIcon from "@mui/material/SvgIcon";
import { StrongFitIcon, WeakFitIcon, DistortionIcon, FitIcon } from "assets/Icons";
import { FairFitIcon } from "assets/Icons/FairFitIcon";

export const renderFingerprintImage = (imageUrl: string, parentElementId: string): void => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(imageUrl ?? '', 'image/svg+xml');
    const svgEle = doc.querySelector('svg');
    const parentEle = document.getElementById(parentElementId);
    if (svgEle && !parentEle?.hasChildNodes()) {
        parentEle?.appendChild(svgEle);
    }
};

interface ChipInfo {
    chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default';
    chipLabel: string;
    chipVariant: 'filled' | 'outlined';
    iconComponent: JSX.Element;
}

export const getApplicantChipInfo = (matchType: string): ChipInfo => {
    let chipColor: 'success' | 'error' | 'primary' | 'warning' | 'default' = 'default';
    let chipLabel = matchType;
    let chipVariant: 'filled' | 'outlined' = 'filled';
    let iconComponent = <></>; 

    switch (matchType) {
        case 'strong':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'strong fit':
            chipColor = 'default';
            chipLabel = 'Strong Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={StrongFitIcon} fontSize='small' />;
            break;
        case 'good':
            chipColor = 'default';
            chipLabel = 'Good Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'match':
            chipColor = 'default';
            chipLabel = 'Good Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FitIcon} fontSize='small' />;
            break;
        case 'fair':
            chipColor = 'default';
            chipLabel = 'Fair Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={FairFitIcon} fontSize='small' />;
            break;
        case 'none':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'weak':
            chipColor = 'default';
            chipLabel = 'Weak Fit';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={WeakFitIcon} fontSize='small' />;
            break;
        case 'distortion':
            chipColor = 'default';
            chipLabel = 'Distortion';
            chipVariant = 'outlined';
            iconComponent = <SvgIcon component={DistortionIcon} fontSize='small' />;
            break;
        default:
            chipColor = 'default';
            chipLabel = 'Unknown';
            break;
    }

    return { chipColor, chipLabel, chipVariant, iconComponent };
};

interface ResumeScoreGaugeProps {
    score: number; // Expecting a score between 0 and 100
}

export const ResumeScoreGauge: React.FC<ResumeScoreGaugeProps> = ({ score }) => {
    const value = Math.min(Math.max(score, 0), 100);

    if (value < 10) {
        return (
            <Box width='100px' textAlign="center">
                <Typography variant="body2">NA</Typography>
            </Box>
        );
    }

    let arcColor;
    if (value >= 80) {
        arcColor = '#4CAF50'; // Green
    } else if (value >= 60) {
        arcColor = '#8BC34A'; // Light Green
    } else if (value >= 40) {
        arcColor = '#FFC107'; // Amber/Yellow
    } else if (value >= 20) {
        arcColor = '#FF9800'; // Orange
    } else {
        arcColor = '#F44336'; // Red
    }

    return (
        <Box width='100px'>
            <Typography variant="body2"> {value}% </Typography>
            <LinearProgress sx={{ 
                 bgcolor: '#e0e0e0',
                 '& .MuiLinearProgress-bar': {
                        backgroundColor: arcColor,
                    }}} 
            variant="determinate" value={value} />
        </Box>
    );
};