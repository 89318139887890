import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Alert, Button, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useRef } from "react";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { checkIfAccountExists, resetCheckIfAccountExists } from 'store/slices/auth-v2/auth-v2-slice';
import { getCandidateInfoFromResume, resetGetCandidateInfoFromResume } from "store/slices/candidate/apply-job/resume-slice";
import { ShFileUpload } from "./ApplyJob.styled";
import { IApplyJobUploadResume } from './ApplyJobModel';

export const ApplyJobUploadResume = ({ selectedResume, setSelectedResume, setCurrentJobApplyStep,
    navigatedFrom, uploadResume }: IApplyJobUploadResume) => {
    const dispatch = useAppDispatch();
    const dropRef = useRef<HTMLDivElement>(null);

    const { getCandidateInfoFromResumeResponse, candidateInfoInResume,
        getCandidateInfoFromResumeStatus, uploadResumeStatus } = useAppSelector((state) => state.candidate.resume);
    const { checkIfAccountExistsStatus, checkIfAccountExistsResponseMsg } = useAppSelector((state) => state.auth.auth);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(resetGetCandidateInfoFromResume());
        if (event.target.files && event.target.files.length > 0 && setSelectedResume) {
            setSelectedResume(event.target.files[0]);
        }
    };

    const handleResumeUpload = () => {
        if (selectedResume) {
            const formData = new FormData();
            formData.append('resume', selectedResume);
            if (navigatedFrom === 'social_sign_up' || navigatedFrom === 'candidate_resume_update') {
                if (uploadResume) {
                    uploadResume(false);
                }
            } else {
                dispatch(getCandidateInfoFromResume({ payload: formData }));
            }
        }
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0 && setSelectedResume) {
            setSelectedResume(files[0]);
        }
    };

    // Check if user exists with email id retrieved from resume.
    useEffect(() => {
        if (getCandidateInfoFromResumeStatus === 'success' && candidateInfoInResume?.email) {
            dispatch(checkIfAccountExists({ email: candidateInfoInResume.email }));
        }
    }, [candidateInfoInResume?.email, dispatch, getCandidateInfoFromResumeStatus]);

    // Reset on unmount.
    useEffect(() => {
        return () => {
            dispatch(resetGetCandidateInfoFromResume());
            dispatch(resetCheckIfAccountExists());
        };
    }, [dispatch]);

    return (
        <Stack rowGap={2} marginTop={2} minWidth={{ xs: 320, sm: 400, md: 500, lg: 550 }} alignItems='center' alignSelf='center'>
            <Typography variant="subtitle2">
                {!selectedResume && <>
                    {navigatedFrom === 'candidate_resume_update' ? 'Upload your Updated Resume here' :
                        navigatedFrom !== 'email' ? 'Upload your resume to continue' :
                            'Upload your resume'}
                    {navigatedFrom !== 'candidate_resume_update' && <sup>*</sup>}
                </>}
            </Typography>
            {getCandidateInfoFromResumeStatus === 'failed' &&
                <Alert severity='error'>{getCandidateInfoFromResumeResponse}</Alert>
            }
            {checkIfAccountExistsStatus === 'failed' &&
                <Alert severity='error'>{checkIfAccountExistsResponseMsg}</Alert>
            }
            <ShFileUpload ref={dropRef} onDragEnter={handleDragEnter} onDragOver={handleDragOver}
                onDragLeave={handleDragLeave} onDrop={handleDrop} >
                <input type="file" accept=".doc, .docx, .txt, .rtf, .pdf" id="file-input"
                    disabled={getCandidateInfoFromResumeStatus === "pending"} onChange={handleFileChange} />
                <label htmlFor="file-input">
                    <Button startIcon={<UploadFileIcon />} component="span"
                        disabled={getCandidateInfoFromResumeStatus === "pending"}>
                        <Typography variant="body2" textTransform='none'>
                            {selectedResume?.name ? selectedResume.name : 'Drag and drop or browse files'}
                        </Typography>
                    </Button>
                </label>
            </ShFileUpload>
            {setCurrentJobApplyStep && navigatedFrom !== 'email' &&
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    <ShButton variant='outlined' onClick={() => {
                        setCurrentJobApplyStep('email');
                        setSelectedResume && setSelectedResume(undefined)
                    }}>Back</ShButton>
                    <ShGreenBtn size='small' disabled={!selectedResume}
                        variant="outlined" onClick={handleResumeUpload}>{
                            getCandidateInfoFromResumeStatus === 'pending' ? 'Uploading...' :
                                getCandidateInfoFromResumeStatus === 'failed' ? 'Retry' : 'Upload'}
                    </ShGreenBtn>
                </Stack>
            }
            {navigatedFrom === 'candidate_resume_update' &&
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    <ShButton variant='outlined' component={RouterLink} to='/candidate/history'>Back</ShButton>
                    <ShGreenBtn size='small' disabled={!selectedResume || uploadResumeStatus === 'pending'}
                        variant='outlined' onClick={handleResumeUpload}>
                        {uploadResumeStatus === 'pending' ? 'Updating Resume...' : 'Update Resume'}
                    </ShGreenBtn>
                </Stack>}
        </Stack>
    );
};