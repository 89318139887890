import CircularProgress from '@mui/material/CircularProgress';
import InputBase from '@mui/material/InputBase';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { ActionsCell, BlockDisable, StyledStack, StyledTableCell, TableActionsContainer, ThemedBtn } from 'Modules/Core/Admin/EmployerProfile/EmployerDetails.styled';
import moment from 'moment-timezone';
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ApiStates } from 'shared/constants';
import { fetchAvailableCredits, resetCreditUpdation, updateAvailableCredits } from 'store/slices/admin/employer/credits-slice';

export const Credits = ({ employerId }) => {
    const dispatch = useDispatch();
    const { availableCredits, updatingCreditsRspnse, updatingCredits, fetchingCredits } = useSelector((state) => state.employer.credits);
    const [avlblCredits, setAvlblCredits] = useState([]);

    useEffect(() => {
        if (employerId) {
            dispatch(fetchAvailableCredits({ employerId }));
        }
    }, [employerId, dispatch]);

    useEffect(() => {
        if (availableCredits) {
            setAvlblCredits(availableCredits);
        }
    }, [availableCredits]);

    const updateTableCellValues = useCallback((e, indx) => {
        const { name, value } = e.target;
        const newArr = avlblCredits.map((pnp, ind) => {
            if (indx === ind) {
                return { ...pnp, [name]: isNaN(parseInt(value)) ? '' : parseInt(value) };
            }
            return pnp;
        });
        setAvlblCredits(newArr);
    }, [avlblCredits]);

    const updateCredits = () => {
        const payload = {
            'credits': avlblCredits.map(ac => { return { order_item_id: ac.order_item_id, quantity: ac.quantity } })
        };
        dispatch(updateAvailableCredits({ employerId, credits: payload }));
    };

    useEffect(() => {
        if (updatingCredits === ApiStates.success) {
            dispatch(fetchAvailableCredits({ employerId }));
        }
    }, [dispatch, employerId, updatingCredits]);

    const Alerts = () => {
        switch (updatingCredits) {
            case ApiStates.pending:
                return (<ShAlert severity="info">Updating...</ShAlert>);
            case ApiStates.success:
                return (<ShAlert onClose={() => { dispatch(resetCreditUpdation()) }} severity="success">{updatingCreditsRspnse}</ShAlert>);
            case ApiStates.failed:
                return (<ShAlert onClose={() => { dispatch(resetCreditUpdation()) }} severity="error">Error occurred while updating credits!</ShAlert>);
            default:
                return (<></>);
        }
    };

    return (<>
        <StyledStack>
            {(fetchingCredits === ApiStates.pending || updatingCredits === ApiStates.pending) && <BlockDisable><CircularProgress /></BlockDisable>}
            <Typography variant="h6" gutterBottom>
                Available Credits
            </Typography>
            <Table size="small" aria-label="Available Credits">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Date</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Credits</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {avlblCredits.map((ac, indx) => (
                        <TableRow key={ac.order_item_id}>
                            <StyledTableCell>
                                {moment(ac.date).format('MMM D, YYYY HH:mm')}
                            </StyledTableCell>
                            <StyledTableCell>
                                {ac.name}
                            </StyledTableCell>
                            <StyledTableCell>
                                <InputBase className='credits' type='number' name='quantity' value={ac.quantity}
                                    onChange={(e) => updateTableCellValues(e, indx)}
                                    placeholder="Credits"
                                    inputProps={{ 'aria-label': 'Credits' }}
                                />
                            </StyledTableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <ActionsCell colSpan={4}>
                            <TableActionsContainer>
                                <ThemedBtn disabled={updateCredits === ApiStates.pending} variant="contained" onClick={updateCredits}>Update Quantities</ThemedBtn>
                            </TableActionsContainer>
                            {Alerts()}
                        </ActionsCell>
                    </TableRow>
                </TableBody>
            </Table>
        </StyledStack>
    </>);
};