import { Stack, Typography } from "@mui/material";
import { useAppSelector } from 'helpers/hooks';
import { useOutletContext } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";

export const PaymentRedirect = () => {

    const { firstName } = useAppSelector((state) => state.auth.auth);
    const isPaymentSuccess = useOutletContext();

    return (<>
        <Stack rowGap={1}>
            {isPaymentSuccess ? <>
                <ShAlert color='success'>
                    <Typography variant="body2">Payment Successful</Typography>
                </ShAlert>
                <Typography variant="h6">Thank you, {firstName}!</Typography>
                <Typography variant="body2">Your order has been processed.</Typography>
                <Typography variant="body2">Your job posting will be activated soon and you'll
                    get an Email whenever someone applies.</Typography>
            </> :
                <>
                    <ShAlert color='error' severity="error">
                        <Typography variant="body2">Payment Failed</Typography>
                    </ShAlert>
                </>}
        </Stack>
    </>);
};