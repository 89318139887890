import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { AdminApiEndpoints } from "shared/ApiEndpoints";
import { ApiState, IAdminNotificationResponse, IBaseResponse } from "shared/SharedModels";

interface IInitialNotificationStates {
  notificationApiStatus: ApiState,
  notificationInfo: IAdminNotificationResponse,
  unreadNotificationApiStatus: ApiState,
  unreadNotifications: IAdminNotificationResponse[],
}

const InitialNotificationStates: IInitialNotificationStates = {
  notificationApiStatus: 'idle',
  notificationInfo: {
    id: null,
    accountId: null,
    isRead: true,
    message: '',
  },
  unreadNotificationApiStatus: 'idle',
  unreadNotifications: [],
}

export const getLatestNotification = createAsyncThunk<
  IBaseResponse<IAdminNotificationResponse>, void, { rejectValue: IBaseResponse }>(
    "getLatestNotification",
    async (_, { rejectWithValue }) => {
      return await httpAdapterInstance
        .get(`${AdminApiEndpoints.ADMIN_NOTIFICATIONS}`)
        .then((response: AxiosResponse<IBaseResponse<any>>) => response.data)
        .catch((error) => {
          throw rejectWithValue(error.response.data);
        });
    }
  );

export const getUnreadNotifications = createAsyncThunk<
  IBaseResponse<IAdminNotificationResponse[]>, void, { rejectValue: IBaseResponse }>(
    "getUnreadNotifications",
    async (_, { rejectWithValue }) => {
      return await httpAdapterInstance
        .get(`${AdminApiEndpoints.ADMIN_UNREAD_NOTIFICATIONS}`)
        .then((response: AxiosResponse<IBaseResponse<any>>) => response.data)
        .catch((error) => {
          throw rejectWithValue(error.response.data);
        });
    }
  )

export const clearNotification = createAsyncThunk<
  IBaseResponse<IAdminNotificationResponse>, IAdminNotificationResponse, { rejectValue: IBaseResponse }>(
    "clearNotification",
    async ({ id }, { rejectWithValue }) => {
      return await httpAdapterInstance
        .put(`${AdminApiEndpoints.ADMIN_NOTIFICATIONS}${id}/read`)
        .then((response: AxiosResponse<IBaseResponse<any>>) => response.data)
        .catch((error) => {
          throw rejectWithValue(error.response.data);
        });
    }
  );

const adminNotificationSlice = createSlice({
  name: "notifications",
  initialState: InitialNotificationStates,
  reducers: {
    resetNotifications: (state) => {
      state.notificationApiStatus = 'idle';
      state.notificationInfo = {
        id: null,
        accountId: null,
        isRead: true,
        message: '',
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestNotification.pending, (state) => {
      state.notificationApiStatus = 'pending';
    });
    builder.addCase(getLatestNotification.fulfilled, (state, action) => {
      state.notificationApiStatus = 'success';
      state.notificationInfo = action.payload.data;
    });
    builder.addCase(getLatestNotification.rejected, (state) => {
      state.notificationApiStatus = 'failed';
      state.notificationInfo = { id: null, accountId: null, isRead: true, message: "" }
    });
    builder.addCase(clearNotification.pending, (state) => {
      state.notificationApiStatus = 'pending';
    });
    builder.addCase(clearNotification.fulfilled, (state, action) => {
      state.notificationApiStatus = 'success';
      state.notificationInfo = action.payload.data;
    });
    builder.addCase(clearNotification.rejected, (state) => {
      state.notificationApiStatus = 'failed';
      state.notificationInfo = { id: null, accountId: null, isRead: true, message: "" }
    });
    builder.addCase(getUnreadNotifications.pending, (state, action) => {
      state.unreadNotificationApiStatus = 'pending';
    });
    builder.addCase(getUnreadNotifications.fulfilled, (state, action) => {
      state.unreadNotificationApiStatus = 'success';
      state.unreadNotifications = action.payload.data;
    });
    builder.addCase(getUnreadNotifications.rejected, (state, action) => {
      state.unreadNotificationApiStatus = 'failed';
      state.unreadNotifications = [];
    })
  }
})

export const { resetNotifications } = adminNotificationSlice.actions;
export default adminNotificationSlice;