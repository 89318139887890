import { MandatoryRequirementCategoryIds } from "Modules/Core/CreateJob/CreateJobConstants";
import { IRequirementCategory, IRequirementsMetaData } from "Modules/Core/CreateJob/CreateJobModel";
import { IRequirement } from "store/slices/employer/jobs/jobs-slice-model";

/**
 * Retrieves a list of invalid requirement categories based on the input requirements
 * and their associated metadata.
 *
 * @param {IRequirement[]} requirements - The array of job requirements.
 * @param {IRequirementsMetaData} requirementsMetaData - Metadata containing category information.
 * @returns {IRequirementCategory[]} - An array of requirement categories that have invalid requirements.
 */

export const getInvalidCategories = (requirements: IRequirement[], requirementsMetaData: IRequirementsMetaData): IRequirementCategory[] => {
    const requirementsByCategory: IRequirement[] = [];
    const mandatoryCategories = requirementsMetaData?.categories?.filter(c => MandatoryRequirementCategoryIds.includes(c.id));
    /*
        Get at least one requirement from job details requirements with the input filled for each category. 
        If found, push into 'requirementsByCategory' array and if the length isn't equal to actual no.of categories then the form is invalid.
    */
    mandatoryCategories?.forEach(ct => {
        const obj = requirements?.find(req => req.requirement_category_id === ct.id);
        if (obj && obj.name?.trim().length === 0) {
            requirementsByCategory.push(obj);
        }
    });

    const invalidCategories: IRequirementCategory[] = [];
    requirementsByCategory.forEach(rBC => {
        const invalidCategory = mandatoryCategories.find(category => category.id === rBC.requirement_category_id);
        if (invalidCategory) {
            invalidCategories.push(invalidCategory);
        }
    });
    return invalidCategories;
};

/**
 * Generates a formatted string from an array of strings, joining them with commas
 * and using "and" for the last element.
 *
 * @param {string[]} strings - The array of strings to be joined.
 * @returns {string} - A formatted string representing the joined input strings.
 */

export const getInvalidCategoriesString = (strings: string[]): string => {
    if (!strings || strings.length === 0) {
        return "";
    } else if (strings.length === 1) {
        return strings[0];
    } else {
        const joinedString = strings.slice(0, -1).join(', ');
        return `${joinedString} and ${strings[strings.length - 1]}`;
    }
}
