import { Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { resetUploadApplicantResume, uploadApplicantResume } from "store/slices/candidate/apply-job/resume-slice";
import { ApplyJobUploadResume } from "../ApplyJob/ApplyJobUploadResume";

export const CandidateResume = () => {

    const dispatch = useAppDispatch();
    const { applicantId } = useParams();
    const { uploadResumeResponse, uploadResumeStatus } = useAppSelector((state) => state.candidate.resume);
    const [selectedResume, setSelectedResume] = useState<File | undefined>(undefined);

    const uploadResume = () => {
        if (selectedResume && applicantId) {
            const formData = new FormData();
            formData.append('resume', selectedResume);
            dispatch(uploadApplicantResume({ applicationId: parseInt(applicantId), resume: formData, isResumeUpdate: false }));
        }
    };

    // Reset resume upload on unmount
    useEffect(() => {
        return () => {
            dispatch(resetUploadApplicantResume());
        }
    }, [dispatch]);

    return (<>
        <Stack height='100%' padding={1} rowGap={1}>
            <Outlet />
            {(uploadResumeStatus && uploadResumeStatus !== 'idle' && uploadResumeStatus !== 'pending') &&
                <ShAlert severity={uploadResumeStatus === 'failed' ? 'error' : 'success'}
                    action={<ShButton onClick={() => window.location.reload()} variant="contained">
                        Show Updated Resume</ShButton>}>
                    {uploadResumeResponse}
                </ShAlert>}
            <ApplyJobUploadResume navigatedFrom="candidate_resume_update" uploadResume={uploadResume}
                selectedResume={selectedResume} setSelectedResume={setSelectedResume} />
        </Stack>
    </>);
}