import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import httpAdapterInstance from "configs/HttpAdapterConfig";
import { EmployerApiEndpoints } from "shared/ApiEndpoints";

const initialJobsState = {
  loading: false,
  jobsList: [],
  stateSummary: [],
  summaryCounts: {
    activeCount: 0,
    draftCount: 0,
    expiredCount: 0,
    allCount: 0
  },
  jobSummary: {},
  error: "",
  currentJobsType: ''
};

//fetch jobs based on job type passed
export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async (
    { sort, pageNumber, pageSize, sortBy, jobType },
    { rejectWithValue }
  ) => {
    let apiUrl = "";

    switch (jobType) {
      case "active":
        apiUrl = EmployerApiEndpoints.ACTIVE_JOBS;
        break;
      case "draft":
        apiUrl = EmployerApiEndpoints.DRAFT_JOBS;
        break;
      case "expired":
        apiUrl = EmployerApiEndpoints.EXPIRED_JOBS;
        break;
      default:
        apiUrl = "";
    }
    if (apiUrl) {
      return await httpAdapterInstance
        .get(`${apiUrl}`, { params: { sort, pageNumber, pageSize, sortBy } })
        .then((response) => response?.data)
        .catch((error) => {
          throw rejectWithValue(error.response.data);
        });
    }
  }
);

//get jobs state summary with draft, active, expired jobs count
export const fetchStateSummary = createAsyncThunk(
  "jobs/stateSummary",
  async (_, { rejectWithValue }) => {
    return await httpAdapterInstance
      .get(`${EmployerApiEndpoints.STATE_SUMMARY}`)
      .then((response) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);

//get job summary when user click on jobs
export const fetchJobSummary = createAsyncThunk(
  "jobs/jobSummary",
  async (jobId, { rejectWithValue }) => {
    return await httpAdapterInstance
      .get(`${EmployerApiEndpoints.JOB_SUMMARY}`, {
        params: { jobId },
      })
      .then((response) => response?.data)
      .catch((error) => {
        throw rejectWithValue(error.response.data);
      });
  }
);

const jobsSlice = createSlice({
  name: "jobs",
  initialState: initialJobsState,
  reducers: {
    // resetJobs: (state) =>{
    //   state.jobsList = [];
    //   state.error = "";
    // }
    setCurrentJobsType: (state, action) => {
      state.currentJobsType = action.payload;
    }
  },
  extraReducers: (builder) => {
    //handle add cases for fetchJobs
    builder.addCase(fetchJobs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchJobs.fulfilled, (state, action) => {
      state.jobsList = action?.payload?.data;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(fetchJobs.rejected, (state, action) => {
      state.jobsList = [];
      state.error = action?.payload?.message || "Error";
      state.loading = false;
    });

    //handle add cases for state summary
    builder.addCase(fetchStateSummary.fulfilled, (state, action) => {
      state.stateSummary = action?.payload?.data;
      if (state.stateSummary) {
        let active = state.stateSummary.filter((item) => item.jobState === "ACTIVE")?.[0]?.count || 0;
        let draft = state.stateSummary.filter((item) => item.jobState === "DRAFT")?.[0]?.count || 0;
        let expired = state.stateSummary.filter((item) => item.jobState === "EXPIRED")?.[0]?.count || 0;
        state.summaryCounts.activeCount = active;
        state.summaryCounts.draftCount = draft;
        state.summaryCounts.expiredCount = expired;
        state.summaryCounts.allCount = active + draft + expired;
      }
      state.error = "";
    });
    builder.addCase(fetchStateSummary.rejected, (state, action) => {
      state.stateSummary = [];
      state.error = action?.payload?.message || "Error";
    });

    //handle add cases for job summary
    builder.addCase(fetchJobSummary.fulfilled, (state, action) => {
      state.jobSummary = action?.payload?.data;
      state.error = "";
    });
    builder.addCase(fetchJobSummary.rejected, (state, action) => {
      state.jobSummary = {};
      state.error = action?.payload?.message || "Error";
    });
  },
});

export const { setCurrentJobsType } = jobsSlice.actions;
export default jobsSlice;
