import { combineReducers } from '@reduxjs/toolkit';
import interviewsActionsSlice from 'store/slices/employer/interviews/interviews-actions-slice';
import interviewsDetailsSlice from 'store/slices/employer/interviews/interviews-details-slice';
import interviewsListSlice from 'store/slices/employer/interviews/interviews-list-slice';
import interviewsSharedSlice from 'store/slices/employer/interviews/interviews-shared-slice';

const interviewsReducer = combineReducers({
    interviewsActions: interviewsActionsSlice.reducer,
    interviewsDetails: interviewsDetailsSlice.reducer,
    interviewsList: interviewsListSlice.reducer,
    interviewsShared: interviewsSharedSlice.reducer,
});
export default interviewsReducer;
