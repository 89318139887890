import { SvgIcon } from '@mui/material';
import React from 'react';

export const OutlookMcIcon = () => {
    return (<>
        <SvgIcon viewBox="0 0 28 26">
            <path d="M26.6609 5.15625H14.9972C14.7762 5.15625 14.5643 5.24403 14.4081 5.40027C14.2518 5.55651 14.1641 5.76842 14.1641 5.98937C14.1641 6.21033 14.2518 6.42224 14.4081 6.57848C14.5643 6.73472 14.7762 6.8225 14.9972 6.8225H24.2332L19.1145 10.8032L15.4437 8.46875L14.5506 9.87507L18.7163 12.5244C18.8609 12.6173 19.0308 12.6628 19.2025 12.6547C19.3742 12.6467 19.5391 12.5854 19.6744 12.4794L25.8278 7.69228V18.4862H14.9972C14.7762 18.4862 14.5643 18.574 14.4081 18.7303C14.2518 18.8865 14.1641 19.0984 14.1641 19.3194C14.1641 19.5403 14.2518 19.7522 14.4081 19.9085C14.5643 20.0647 14.7762 20.1525 14.9972 20.1525H26.6609C26.8819 20.1525 27.0938 20.0647 27.25 19.9085C27.4063 19.7522 27.4941 19.5403 27.4941 19.3194V5.98937C27.4941 5.76842 27.4063 5.55651 27.25 5.40027C27.0938 5.24403 26.8819 5.15625 26.6609 5.15625Z" fill="#1976D2" />
            <path d="M15.5306 0.348308C15.4369 0.269514 15.3268 0.212741 15.2083 0.182173C15.0897 0.151605 14.9659 0.148021 14.8458 0.171685L1.51577 2.67106C1.32471 2.7065 1.15209 2.80773 1.02791 2.95719C0.903716 3.10664 0.835795 3.29487 0.835938 3.48919V21.8179C0.836118 22.0122 0.904156 22.2002 1.02829 22.3496C1.15242 22.499 1.32485 22.6003 1.51577 22.6361L14.8458 25.1354C14.9661 25.1586 15.09 25.1548 15.2087 25.1243C15.3274 25.0938 15.4378 25.0375 15.5321 24.9593C15.6264 24.8811 15.7023 24.7829 15.7541 24.6719C15.806 24.5609 15.8327 24.4398 15.8322 24.3173V0.989814C15.8323 0.867268 15.8053 0.746212 15.7532 0.63531C15.701 0.524409 15.625 0.426403 15.5306 0.348308Z" fill="#2196F3" />
            <path d="M8.33359 18.4858C6.0375 18.4858 4.16797 16.243 4.16797 13.487C4.16797 10.7311 6.0375 8.48828 8.33359 8.48828C10.6297 8.48828 12.4992 10.7311 12.4992 13.487C12.4992 16.243 10.6297 18.4858 8.33359 18.4858ZM8.33359 10.1545C6.95561 10.1545 5.83422 11.6492 5.83422 13.487C5.83422 15.3249 6.95561 16.8195 8.33359 16.8195C9.71158 16.8195 10.833 15.3249 10.833 13.487C10.833 11.6492 9.71158 10.1545 8.33359 10.1545Z" fill="#FAFAFA" />
        </SvgIcon>
    </>)
};