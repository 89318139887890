import { Box, Typography } from "@mui/material";
import { AdminJobFeaturesCellOrder, AdminJobFeaturesTableHeaders, AdminJobReviewCellOrder, AdminJobReviewTableHeaders } from "Modules/Core/Admin/AdminDashboard/adminDashboardConstants";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShAdminTable from "shared/SharedComponents/ShAdminTable/ShAdminTable";
import { IJobFeatures, IJobInfoResponse, IPostingSourceLinks } from "shared/SharedModels";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { approveJob, generateFeeds, getEmployerJob } from "store/slices/admin/jobs-reviews-slice";

const JobReview = () => {
  const [tableRows, setTableRows] = useState<IPostingSourceLinks[]>([]);
  const [jobFeatures, setJobFeatures] = useState<IJobFeatures[]>([]);
  const [jobInfo, setJobInfo] = useState<IJobInfoResponse>();

  const dispatch = useAppDispatch();
  const { employerJob, isFetchingJobs, approveJobApiStatus } = useAppSelector((state) => state.admin.jobReviews);
  const pathname = useLocation().pathname;
  const jobId = pathname.split("/")[3];
  const navigate = useNavigate();

  useEffect(() => {
    const jobInfo = employerJob as IJobInfoResponse;
    setJobInfo(jobInfo);
    setTableRows(jobInfo.postingSourceLinks);
    setJobFeatures(jobInfo.jobFeatures);
  }, [employerJob]);

  useEffect(() => {
    dispatch(getEmployerJob({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    if (approveJobApiStatus === 'success') {
      dispatch(generateFeeds())
      navigate("/admin");
    }
  }, [approveJobApiStatus, navigate, dispatch])

  return (
    <Box p={4}>
      <Box>
        <ShAdminTable
          title={"Job Review"}
          headers={AdminJobReviewTableHeaders}
          rows={isFetchingJobs === "success" && tableRows?.length
            ? tableRows.map((row) => ({ ...row, html: `<a href="${row.link}">Click Here</a>` }))
            : []}
          cellOrder={AdminJobReviewCellOrder}
          redirectionUrls={["link"]}
          generateUrl={(value: string) => value}
          isLoading={isFetchingJobs === 'pending'}
        />
      </Box>
      <Box mt={2}>
        <ShAdminTable
          title={"Features"}
          headers={AdminJobFeaturesTableHeaders}
          rows={isFetchingJobs === 'success' && jobFeatures?.length ? jobFeatures : []}
          cellOrder={AdminJobFeaturesCellOrder}
          isLoading={isFetchingJobs === 'pending'}
          redirectionUrls={[]}
        />
      </Box>
      <Box mt={2}>
        {jobInfo && jobInfo.review_state === "pending" && (
          <ShButton variant="contained"
            onClick={() => { if (jobId) dispatch(approveJob({ jobId: parseInt(jobId) })) }}
            disabled={isFetchingJobs === 'pending' || approveJobApiStatus === 'pending'}>
            <Typography>Approve Job</Typography>
          </ShButton>
        )}
      </Box>
    </Box>
  );
};

export default JobReview;