import { Stack, Typography } from '@mui/material';

const ActiveAdministrators = () => {
  return (
    <Stack padding={4} direction="column">
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
        Manage Admins
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
        Active Administrators
      </Typography>
    </Stack>
  );
};

export default ActiveAdministrators;
