import { combineReducers } from '@reduxjs/toolkit';
import applicantActionsSlice from 'store/slices/employer/applicants/applicant-actions-slice';
import applicantCommentsSlice from 'store/slices/employer/applicants/applicant-comments-slice';
import applicantsListActionsSlice from 'store/slices/employer/applicants/applicants-list-actions-slice';
import incompleteApplicantsSlice from 'store/slices/employer/applicants/incomplete-applicants-slice';
import applicantProfileSlice from './applicant-profile-slice';
import applicantsListSlice from './applicants-list-slice';

export const applicantsReducer = combineReducers({
    applicantActions: applicantActionsSlice.reducer,
    applicantComments: applicantCommentsSlice.reducer,
    applicantProfile: applicantProfileSlice.reducer,
    applicantsListActions: applicantsListActionsSlice.reducer,
    applicantsList: applicantsListSlice.reducer,
    incompleteApplicants: incompleteApplicantsSlice.reducer,
});
