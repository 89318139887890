import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShDashboardIcon } from "assets/Icons";
import JobDetailsFields from 'assets/Images/JobDetailsFields.svg';
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink } from 'react-router-dom';
import { ShButton, ShWordpressButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import { CommonQuestionSidebar } from "../CommonQuestionSidebar";
import { TextFieldsInfo } from "../HelpConstants";

export const PostAJob = () => {

    const isMdScreen = IsMdScreen();
    const paddingValue = IsXsScreen() ? 1 : 3;

    return (

        <>
            <Stack padding={paddingValue} direction={'row'}>
                <ShContainer margin={'auto'}>
                    <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Overview</ResourceTypography>
                    {/* Overview */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                                <CreateIcon color='primary' />
                                <Stack spacing={2}>
                                    <Typography variant="h6" gutterBottom>Create Job</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Create a new job listing in SmoothHiring platform.
                                    </Typography>
                                    <ShButton variant="outlined" color="primary" component={RouterLink}
                                        to="/employer/job/new">Create a Job</ShButton>
                                </Stack>
                            </ShPaper>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4} lg={3}>
                            <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                                <DynamicFeedIcon color='primary' />
                                <Stack spacing={2}>
                                    <Typography variant="h6" gutterBottom>Post a Job</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Post the job listing to make it visible to candidates.
                                    </Typography>
                                    <ShButton size='small' variant="contained" color="primary" component={RouterLink}
                                        to="/employer/job/active">See my Jobs</ShButton>
                                </Stack>
                            </ShPaper>
                        </Grid>
                    </Grid>
                    <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Creating a Job </ResourceTypography>
                    <ResourceTypography variant="body1">
                        When creating a job, you need to click on the {
                            <ShWordpressButton size='small' variant="outlined" color="primary" component={RouterLink}
                                to="/employer/job/new">Create a Job </ShWordpressButton>}
                        &nbsp; button. This can be found in many places including the  &nbsp; {<ShDashboardIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />}  &nbsp;
                        icon on the left hand side.
                    </ResourceTypography>
                    <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Filling in Job Details</ResourceTypography>
                    <ResourceTypography variant="body1">
                        Their are many details that must be filled for a job to be succesfully created. Note that * fields are required.
                        You can not move on without completing them.
                    </ResourceTypography>
                    <Grid paddingTop={3} container spacing={2}>
                        {TextFieldsInfo.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                <Chip size='medium' label={item} color='primary' />
                            </Grid>
                        ))}
                    </Grid>
                    <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Job Duties and Skills </ResourceTypography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5}>
                            <ResourceTypography variant="body1">
                                It's important to define requirements for your job posting. This can help attract highly qualified candidates,
                                however, it's important to have extremely strict requirements as it can lead to fewer candidates.
                            </ResourceTypography>
                            <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Job Duties </ResourceTypography>
                            <ResourceTypography variant="body1">
                                Job duties are official responsiblties and tasks a candidate would perform if they were hired.
                                What does a day in the life look like for the person holding this job.
                            </ResourceTypography>
                            <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Education</ResourceTypography>
                            <ResourceTypography variant="body1">
                                Type in the education requirments or fields you see fit for the job. Select the minimum required education for your position.
                                Note that the more strict your requirements, the fewer candidates you'll attract.
                            </ResourceTypography>
                            <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Skills</ResourceTypography>
                            <ResourceTypography variant="body1">
                                Use some of the prefilled skills or add your own required skills with the {<AddIcon color='primary' />} icon.
                                You may also delete skills using the {<RemoveIcon color='primary' />} icon.
                            </ResourceTypography>

                            <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Salary, benefits</ResourceTypography>
                            <ResourceTypography variant="body1">
                                You may add a salary based per annum or per hour. In addition add work benefits. List out many benefits to attract more candidates.
                            </ResourceTypography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <img src={JobDetailsFields} alt="TopCandidates" style={{ height: '100%', width: '100%', maxWidth: '100%' }} />
                        </Grid>
                    </Grid>
                    <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom> Modify/Preview Job Description</ResourceTypography>
                    <ResourceTypography variant="body1">
                        Clicking the &nbsp;<ShButton size='small' variant="contained"> Preview </ShButton> &nbsp; Button in the top menu bar will allow you to see the currently generated
                        job description given your requirments, salary, and job details entered earlier. You may edit it as well.
                        In addition you can use our AI Generator to create a job description using AI. Simply Click the  <EditIcon fontSize='small' />.
                    </ResourceTypography>

                    <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Choosing a Package</ResourceTypography>
                    <ResourceTypography variant="body1">
                        Choose between the available packages:
                    </ResourceTypography>
                    <Box paddingLeft={2}>
                        <Typography variant="body1" component="ul">
                            <li><strong>Candidate Fit Package:</strong></li>
                            <ul>
                                <li>Access to 1000s of SmoothHiring Job Matching Profiles</li>
                                <li>Instant Candidate Filtering & Ranking based on Fit</li>
                                <li>Custom Interview Questions</li>
                                <li>Dedicated Account Manager</li>
                            </ul>
                            <li><strong>Job Posting Package:</strong></li>
                            <ul>
                                <li>Post your job to many job boards</li>
                            </ul>
                            <li><strong>LinkedIn Posting:</strong></li>
                            <ul>
                                <li>Allows you to post to LinkedIn</li>
                                <li>This package is recommended as many candidates come from LinkedIn</li>
                            </ul>
                        </Typography>
                    </Box>
                </ShContainer>
                {!isMdScreen && <CommonQuestionSidebar />}
            </Stack>
        </>
    );
};
