import PropTypes from "prop-types";
import { Tabs, Tab } from "@mui/material";
import { StyledTaps } from "../../styles/styles";
import { ButtonTabsProps, TabPanelProps } from "./calenderModel";


function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  return value === index ? <>{children}</> : <></>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: string | number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}


export type ButtonTabProps = {
  id: string | number;
  label: string | JSX.Element;
  component: JSX.Element;
};


const ButtonTabs = ({
  tabs,
  variant = "scrollable",
  tab,
  setTab,
  indicator = "primary",
  style,
}: ButtonTabsProps) => {
  return (
    <StyledTaps style={style}>
      <Tabs
        value={tab}
        variant={variant}
        scrollButtons
        className="tabs"
        classes={{ indicator: indicator }}
      >
        {tabs.map((tab: ButtonTabProps, i: number) => (
          <Tab
            key={tab.id || i}
            label={tab.label}
            // icon={tab.icon}
            value={tab.id}
            {...a11yProps(tab.id)}
            onClick={() => setTab(tab.id)}
            onDragEnter={() => setTab(tab.id)}
          />
        ))}
      </Tabs>
      {tabs.map(
        (t: ButtonTabProps, i: number) =>
          t.component && (
            <TabPanel key={i} value={tab} index={t.id}>
              {t.component}
            </TabPanel>
          )
      )}
    </StyledTaps>
  );
};

export { ButtonTabs };
