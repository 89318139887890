import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "store/slices/auth-v2/auth-v2-slice";
import passwordSlice from "store/slices/auth-v2/password-slice";
import socialLoginSlice from 'store/slices/auth-v2/social-login-slice';
import twoStepAuthSlice from "store/slices/auth-v2/two-step-auth-slice";
import { IAuthState } from './auth-v2-model';

const authPersistConfigV2: PersistConfig<IAuthState> = {
    key: 'authV2',
    storage,
    whitelist: ['accountId', 'accountLoginStatus', 'isRememberMe', 'isAccountLoggedIn', 'accountAuthToken', 'accountLoginInfo',
        'socialSignUpSource', 'email', 'firstName', 'middleName', 'lastName', 'accountAccess', 'employerId', 'currentAppContext',
        'candidateId', 'twoStepAuth', 'accountCountry']
};

const authReducer = combineReducers({
    password: passwordSlice.reducer,
    socialLogin: socialLoginSlice.reducer,
    auth: persistReducer(authPersistConfigV2, authSlice.reducer),
    twoStepAuth: twoStepAuthSlice.reducer
});

export { authSlice, passwordSlice, socialLoginSlice };
export default authReducer;