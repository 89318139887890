import { SvgIcon } from '@mui/material';
import { IShIcon } from 'shared/SharedModels';

export const ShDashboardIcon = ({ primaryColor, secondaryColor }: IShIcon) => {
    return (<>
        <SvgIcon viewBox='0 0 20 20'>
            <path d="M7.31249 0H1.38341C0.620557 0 0 0.642364 0 1.43202V5.11449C0 5.9043 0.620557 6.54666 1.38341 6.54666H7.31249C8.07549 6.54666 8.69604 5.9043 8.69604 5.11449V1.43202C8.69604 0.642364 8.07549 0 7.31249 0Z" fill={secondaryColor} />
            <path d="M7.31249 8.31418H1.38341C0.620557 8.31418 0 8.95654 0 9.74635V18.3388C0 19.1284 0.620557 19.7708 1.38341 19.7708H7.31249C8.07549 19.7708 8.69604 19.1284 8.69604 18.3388V9.74635C8.69604 8.95654 8.07549 8.31418 7.31249 8.31418Z" fill={primaryColor} />
            <path d="M18.6168 13.4306L12.6885 13.3295C11.9256 13.3164 11.2949 13.9481 11.2824 14.7378L11.2237 18.4198C11.2111 19.2093 11.8214 19.8622 12.5843 19.8752L18.5126 19.9764C19.2753 19.9894 19.906 19.3577 19.9186 18.5682L19.9772 14.8862C19.9898 14.0965 19.3796 13.4437 18.6168 13.4306Z" fill={secondaryColor} />
            <path d="M18.2858 0H12.3567C11.5937 0 10.9731 0.642364 10.9731 1.43202V10.0244C10.9731 10.8143 11.5937 11.4566 12.3567 11.4566H18.2858C19.0486 11.4566 19.6692 10.8143 19.6692 10.0244V1.43202C19.6692 0.642364 19.0486 0 18.2858 0Z" fill={primaryColor} />
        </SvgIcon>
    </>)
};