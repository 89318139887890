import { SvgIcon } from '@mui/material';
import { IShIcon } from 'shared/SharedModels';

export const ShMyJobsIcon = ({ primaryColor, secondaryColor }: IShIcon) => {
    return (<>
        <SvgIcon viewBox='0 0 20 20'>
            <path d="M14.6514 5.11628H5.34904C5.16401 5.11628 4.98655 5.04277 4.85571 4.91193C4.72487 4.7811 4.65137 4.60364 4.65137 4.4186V2.55814C4.65186 1.87983 4.92154 1.22944 5.40117 0.749806C5.88081 0.270169 6.5312 0.000492779 7.20951 0H12.7909C13.4692 0.000492779 14.1196 0.270169 14.5992 0.749806C15.0789 1.22944 15.3485 1.87983 15.349 2.55814V4.4186C15.349 4.60364 15.2755 4.7811 15.1447 4.91193C15.0139 5.04277 14.8364 5.11628 14.6514 5.11628ZM6.04672 3.72093H13.9537V2.55814C13.9532 2.2499 13.8305 1.95442 13.6126 1.73647C13.3946 1.51851 13.0991 1.39584 12.7909 1.39535H7.20951C6.90127 1.39584 6.60579 1.51851 6.38783 1.73647C6.16987 1.95442 6.04721 2.2499 6.04672 2.55814V3.72093Z" fill={primaryColor} />
            <path d="M7.6093 9.30259C7.73894 9.02459 7.94524 8.78938 8.20395 8.6246C8.46266 8.45981 8.76303 8.3723 9.06977 8.37235H10.9302C11.237 8.3723 11.5373 8.45981 11.7961 8.6246C12.0548 8.78938 12.2611 9.02459 12.3907 9.30259H20V8.1398C19.9985 6.96836 19.5325 5.84533 18.7042 5.017C17.8759 4.18867 16.7528 3.72267 15.5814 3.72119H4.4186C3.24717 3.72267 2.12414 4.18867 1.29581 5.017C0.467481 5.84533 0.00147691 6.96836 0 8.1398V9.30259H7.6093Z" fill={primaryColor} />
            <path d="M12.5581 10.6978V11.8605C12.5562 12.2917 12.384 12.7046 12.0792 13.0095C11.7743 13.3144 11.3614 13.4865 10.9302 13.4885H9.06977C8.63862 13.4865 8.22569 13.3144 7.92083 13.0095C7.61596 12.7046 7.44382 12.2917 7.44186 11.8605V10.6978H0V15.5815C0.00147691 16.7529 0.467481 17.8759 1.29581 18.7043C2.12414 19.5326 3.24717 19.9986 4.4186 20.0001H15.5814C16.7528 19.9986 17.8759 19.5326 18.7042 18.7043C19.5325 17.8759 19.9985 16.7529 20 15.5815V10.6978H12.5581Z" fill={secondaryColor} />
            <path d="M9.06997 9.76759C9.03936 9.76734 9.00901 9.77318 8.98068 9.78478C8.95235 9.79638 8.92662 9.8135 8.90497 9.83515C8.88333 9.85679 8.86621 9.88253 8.85461 9.91086C8.84301 9.93918 8.83716 9.96953 8.83741 10.0001V11.8606C8.83716 11.8912 8.84301 11.9216 8.85461 11.9499C8.86621 11.9782 8.88333 12.004 8.90497 12.0256C8.92662 12.0472 8.95235 12.0644 8.98068 12.076C9.00901 12.0876 9.03936 12.0934 9.06997 12.0932H10.9304C10.961 12.0934 10.9914 12.0876 11.0197 12.076C11.048 12.0644 11.0738 12.0472 11.0954 12.0256C11.1171 12.004 11.1342 11.9782 11.1458 11.9499C11.1574 11.9216 11.1632 11.8912 11.163 11.8606V10.0001C11.1632 9.96953 11.1574 9.93918 11.1458 9.91086C11.1342 9.88253 11.1171 9.85679 11.0954 9.83515C11.0738 9.8135 11.048 9.79638 11.0197 9.78478C10.9914 9.77318 10.961 9.76734 10.9304 9.76759H9.06997Z" fill={primaryColor} />
        </SvgIcon>
    </>)
};