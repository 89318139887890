import { Box, Typography, Stack } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import CommonQuestionSidebar from "../CommonQuestionSidebar";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";

export const BillingAndPayments = () => {

  const isMdScreen = IsMdScreen();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Billing and Payments</ResourceTypography>
              <ResourceTypography variant="body1" paragraph>
                Our ATS platform uses Stripe, a leading and highly secure payment processing service, to handle all billing and payments. Stripe ensures that your payment information is handled securely and supports a wide range of payment methods.
              </ResourceTypography>

              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Payment Methods</ResourceTypography>
              <Box paddingTop={2} paddingBottom={2}>
                <Stack spacing={2}>
                  <Typography variant="body1" paragraph>
                    With Stripe, you can pay using major credit cards, debit cards, and other payment methods supported by Stripe. This includes Visa, MasterCard, American Express, and more. Stripe's secure infrastructure ensures that all transactions are encrypted and safe.
                  </Typography>
                </Stack>
              </Box>

              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Billing Information</ResourceTypography>
              <Box paddingTop={2} paddingBottom={2}>
                <Stack spacing={2}>
                  <Typography variant="body1" paragraph>
                    To update your billing information, navigate to the Billing section in your account settings. Here, you can update your payment method, view past invoices, and download receipts for your records.
                  </Typography>
                </Stack>
              </Box>

              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Subscription Management</ResourceTypography>
              <Box paddingTop={2} paddingBottom={2}>
                <Stack spacing={2}>
                  <Typography variant="body1" paragraph>
                    In the Subscription Management section, you can manage your current plan, upgrade or downgrade your subscription, and view your subscription details. All changes are processed securely through Stripe.
                  </Typography>
                </Stack>
              </Box>

              <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Invoices</ResourceTypography>
              <Typography variant="body1" paragraph>
                 Invoices and recipt of payment confirmation will be sent to the email that paid for the job.
              </Typography>

            
        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};

export default BillingAndPayments;
