import CommentIcon from '@mui/icons-material/Comment';
import { Box, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IApplicantCommentComponent } from 'Modules/Core/Applicants/ApplicantsModel';
import { stringToColor } from 'Modules/Core/Dashboard/Dashboard.styled';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { MouseEvent, useEffect, useState } from 'react';
import { ApiState } from 'shared/SharedModels';
import { ShAvatar } from 'shared/SharedStyles/ShDataDisplay';
import { ShAlert, ShDialog } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn, ShResizableTextField } from 'shared/SharedStyles/ShInputs';
import { DefaultAPIErrorMsg } from 'shared/constants';
import { getInitialsFromName } from 'shared/utils';
import { getApplicantComments, postApplicantComments, resetCommentsState } from 'store/slices/employer/applicants/applicant-comments-slice';

export const ApplicantComment = ({ applicantId, label, isAddition }: IApplicantCommentComponent) => {

    const dispatch = useAppDispatch();
    const {getCommentsStatus, getCommentsResponse, postCommentsResponse, postCommentsStatus } = useAppSelector((state) => state.employer.applicants.applicantComments);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [commentBody, setCommentBody] = useState<string>(''); 
    const { firstName, lastName } = useAppSelector((state) => state.auth.auth);
    const userInitials = getInitialsFromName(`${firstName} ${lastName}`, true, true)
    const getAvatarBackgroundColor = stringToColor(userInitials);

    const openDialog = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        dispatch(resetCommentsState());
        setIsDialogOpen(true);
        e.stopPropagation();
        if (!isAddition) {
            dispatch(getApplicantComments({ applicantId: applicantId }));
        }
    };

    const closeDialog = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        setIsDialogOpen(false);
        e.stopPropagation();
        setCommentBody(''); 
    };

    const addComment = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.stopPropagation();
        dispatch(postApplicantComments({
            applicantId: applicantId,
            payload: { body: commentBody }
        }));
        setCommentBody('');  
    };

    useEffect(() => {
        if (postCommentsStatus === 'success') {
            setIsDialogOpen(false);
        }
    }, [postCommentsStatus]);
    

    const showCommentAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'pending':
                return (<><ShAlert severity="info">Saving...</ShAlert></>);
            case 'success':
                return (<>
                    <ShAlert severity="success">
                        <Typography fontWeight={600}>{msg ?? DefaultAPIErrorMsg}</Typography>
                    </ShAlert>
                </>);
            case 'failed':
                return (<><ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert></>);
            default:
                break;
        }
    };

    const showFailureAlerts = (apiState: ApiState, msg: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };


    return (<>
        <Tooltip title={`${label ? label : 'Comments'}`} onClick={e => e.stopPropagation()}>
            {label ? <ShButton variant='outlined' disableElevation onClick={(e) => { openDialog(e) }}>
                {label}
            </ShButton> :
                <IconButton className='more-btn' size='small' aria-label='More options' onClick={(e) => { openDialog(e) }}>
                    <CommentIcon fontSize='small' />
                </IconButton>
            }
        </Tooltip>
        <ShDialog open={isDialogOpen} onClose={closeDialog} onClick={e => e.stopPropagation()}>
            <DialogTitle textAlign='center'>Comments</DialogTitle>
            {getCommentsStatus === 'pending' ?
                <>
                    <Stack justifyContent='center' alignItems='center'>
                        <CircularProgress />
                    </Stack>
                </> : <>
                    <DialogContent dividers>
                        {showFailureAlerts(getCommentsStatus, getCommentsResponse)}
                        <Box marginBottom={2} width='100%'>
                            {showCommentAlerts(postCommentsStatus, postCommentsResponse)}
                        </Box>
                        <Stack direction='row' alignItems='center' justifyContent='right' spacing={1}>
                            <ShAvatar height={30} width={30} backgroundColor={getAvatarBackgroundColor}>
                                <Typography variant='body2' fontWeight='bold'>
                                    {userInitials.toUpperCase()}
                                </Typography>
                            </ShAvatar>
                            <Typography variant='body2'>
                                <strong>Author: </strong> {firstName} {lastName}
                            </Typography>
                        </Stack>
                        <ShResizableTextField onClick={e => e.stopPropagation()} multiline rows={3} maxRows={10} autoFocus margin="dense" id="comment"
                            label="Add Comment"
                            fullWidth
                            variant="outlined"
                            value={commentBody}
                            onChange={e => setCommentBody(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <ShButton size='small' variant='outlined' onClick={(e) => closeDialog(e)}>
                            Cancel
                        </ShButton>
                        <ShGreenBtn size='small' disabled={!commentBody || postCommentsStatus === 'pending'} onClick={(e) => addComment(e)}>
                            {postCommentsStatus === 'pending' ? 'Saving...' : 'Add Comment'}
                        </ShGreenBtn>
                    </DialogActions>
                </>}
        </ShDialog>
    </>);
};
