import { Box, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/system';
import heroBackground from 'assets/Images/HelpImages/Help_Hero.png';

// Hero Section
const HeroHelp = styled(Box)(
    ({ theme }) => ({
        position: 'relative',
        width: '100%',
        padding: '40px 0 55px',
        backgroundSize: 'cover',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${heroBackground})`,
            backgroundSize: 'cover',
            opacity: 1,
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: "linear-gradient(90deg, #89C8EE 0.2%, #A1D78C 99.79%)",
            opacity: 0.5,
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            padding: '40px 0 60px'
        },
    })
);

// Hero Content
const HeroTitle = styled(Box)(
    ({ theme }) => ({
        fontSize: '36px',
        background: 'transparent',
        boxShadow: "none",
        color: '#fff',
        marginBottom: '2rem',
        zIndex: '1',
        [theme.breakpoints.down('md')]: {
            fontSize: '4vmax'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '3vmax'
        },
    })
);

const SearchInput = styled(Paper)(
    ({ theme }) => ({
        width: '100%',
        maxWidth: '400px',
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '',
        boxShadow: "none",
        zIndex: "1",
        borderRadius: '999px',
        borderBottom: '0 !important',
        '& input': {
            padding: '0.7rem',
            width: '280px',
            borderRadius: '999px',
        },
        '& .MuiInputBase-root.MuiInput-root::before': {
            borderBottom: '0 !important',
        },
        '& .MuiInputBase-root.MuiInput-root::after': {
            borderBottom: '0 !important',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '250px',
            '& input': {
                padding: '0.8rem',
                width: '160px'
            }
        },
    })
);


// Grid
const HelpGrid = styled(Paper)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            padding: '4rem 1rem',
        },
        [theme.breakpoints.up('md')]: {
            padding: '7rem 7rem',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '7rem 14rem',
        },
        boxShadow: 'none',
        background: 'transparent'
    })
);


const ItemCard = styled(Box)({
    borderRadius: '15px',
    border: '1px solid #C4CFDE',
    padding: '1.4rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    },
  });
  


const ItemImage = styled('img')({
    width: '70px',
    height: '70px',
    objectFit: 'cover',
    marginBottom: '30px'
});

const ItemTitle = styled(Box)(
    ({ theme }) => ({
        color: theme.palette.mode === 'light' ? "#334D6E" : "#fff",
        fontWeight: "bold",
        fontSize: '21px',
        borderBottom: 0
    })
);

const ItemDescription = styled(Box)(
    ({ theme }) => ({
        color: theme.palette.mode === 'light' ? '#334D6E' : "rgba(255, 255, 255, 0.555)",
        fontSize: '17px',
        margin: 0
    })
);


const ContactIcon = styled(Box)({
    position: 'fixed',
    bottom: '20px',
    right: '30px'
})

export const SidebarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    transition: 'width 0.3s',
    width: '250px', // default expanded width
  }));
  
  export const CollapsedSidebarContainer = styled(Box)(({ theme }) => ({
    width: '60px', // width when collapsed
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    transition: 'width 0.3s',
  }));
  
  export const SidebarButton = styled(IconButton)(({ theme }) => ({
    alignSelf: 'flex-end',
  }));



export { ContactIcon, HelpGrid, HeroHelp, HeroTitle, ItemCard, ItemDescription, ItemImage, ItemTitle, SearchInput };

