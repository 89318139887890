import { SvgIcon } from '@mui/material';

export const EmailShareIcon = () => {
    return (<>
        <SvgIcon>
            {/* Different Icon to be decided which one to use after discussion */}
            {/* <svg height='24px' width='24px' version="1.1" id="Layer_1"
                viewBox="0 0 501.551 501.551">
                <path fill='#FF7058' d="M501.551,208.98l-28.212,20.898l-41.796,30.302L263.314,384.522c-7.314,5.224-17.763,5.224-25.078,0
	L70.008,260.18l-31.347-22.988L0,208.98l38.661-33.437l31.347-27.167L237.192,5.224C241.371,2.09,245.551,0,250.776,0
	s9.404,2.09,13.584,5.224L431.543,149.42l41.796,35.527L501.551,208.98z"/>
                <path fill='#FFD15C' d="M263.314,383.478c-7.314,5.225-17.763,5.225-25.078,0L0,208.98v271.673
	c0,11.494,9.404,20.898,20.898,20.898h459.755c11.494,0,20.898-9.404,20.898-20.898V208.98L263.314,383.478z"/>
                <path fill='#F2F2F2' d="M473.339,84.637v144.196l-41.796,30.302L263.314,384.522c-7.314,5.224-17.763,5.224-25.078,0
	L70.008,260.18l-31.347-22.988V20.898C38.661,9.404,48.065,0,59.559,0h329.143L473.339,84.637z"/>
                <g>
                    <path fill='#CDD6E0' d="M409.6,84.637h63.739L388.702,0v63.739C388.702,75.233,398.106,84.637,409.6,84.637z" />
                    <rect x="88.816" y="99.265" fill='#CDD6E0' width="334.367" height="26.122" />
                </g>
                <rect x="88.816" y="36.571" fill='#ACB3BA' width="242.416" height="26.122" />
                <rect x="88.816" y="159.869" fill='#CDD6E0' width="334.367" height="26.122" />
                <rect x="271.673" y="222.563" fill='#ACB3BA' width="151.51" height="26.122" />
            </svg> */}
            <svg version="1.1" id="Layer_1" viewBox="0 0 508.025 508.025">
                <path fill='#54C0EB' d="M254.012,348.025l241.7,152.2c7.5-6.2,12.2-15.5,12.2-26v-286.2L254.012,348.025z" />
                <path fill='#84DBFF' d="M0.013,188.025v286.2c0,10.5,4.8,19.8,12.3,26c5.9,4.8,13.4,7.8,21.6,7.8h440.2
	c8.2,0,15.7-2.9,21.6-7.8L0.013,188.025z"/>
                <path fill='#40596B' d="M266.513,4.125c-7.5-5.5-17.6-5.5-25.1,0l-241.4,183.9l49.8,31.4l24.5,15.5l179.7,113.1l179.7-113.2
	l24.5-15.4l49.8-31.4L266.513,4.125z"/>
                <path fill='#F2F2F2' d="M371.013,33.825h-300c-11.7,0-21.2,9.4-21.2,21.1v164.5l24.5,15.5l179.7,113.1l179.7-113.2l24.5-15.4
	v-98.8L371.013,33.825z"/>
                <g>
                    <path fill='#CDD6E0' d="M371.013,33.825v65.8c0,11.6,9.5,21.1,21.2,21.1h66L371.013,33.825z" />
                    <rect fill='#CDD6E0' x="137.012" y="100.325" width="173.7" height="20.3" />
                    <rect fill='#CDD6E0' x="137.012" y="161.125" width="234" height="20.3" />
                    <rect fill='#CDD6E0' x="137.012" y="221.925" width="234" height="20.3" />
                </g>
            </svg>
        </SvgIcon>
    </>)
};