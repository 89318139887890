import ClearIcon from '@mui/icons-material/Clear';
import { Box, Checkbox, DialogContent, DialogTitle, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { ForgotAuthDialog } from 'Modules/Core/SettingsTs/Security/ForgotAuthDialog';
import { SettingsDialog } from 'Modules/Core/SettingsTs/Settings.styled';
import { IAuthQuestionAndAnswer, IAuthQuestionDialog, IUpdateAuthQuestion } from 'Modules/Core/SettingsTs/SettingsModel';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from 'react';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import { BorderColorDark, BorderColorLight } from 'shared/SharedStyles/styleConstants';
import { changeAuthQuestion, resetAnsValidation, resetAuthQuestionChange, resetSetAuthQs, setAuthQuestion, updateSecurityAuth, validateAuthAnswer } from 'store/slices/employer/settingsTs/security-slice';

export const AuthQuestionDialog = ({ authQuestionId, isAuthQstnDlgOpen, setIsAuthQstnDlgOpen,
    securityVerification, showNotification, updateUserAuth }: IAuthQuestionDialog) => {
    const dispatch = useAppDispatch();
    const { getSecurityQuestionsStatus, getSecurityQuestionsResponse, securityQuestions, isValidAnswer,
        validateAuthAnsApiStatus, validateAuthAnsApiResponse, changeAuthQuestionStatus, changeAuthQuestionResponse,
        setAuthQsApiStatus, setAuthQsApiResponse
    } = useAppSelector((state) => state.employer.employerSettings.security);
    const [newAuthQstn, setNewAuthQstn] = useState<IAuthQuestionAndAnswer>({ question_id: undefined, auth_answer: '' });
    const [updateAuthQstn, setUpdateAuthQstn] = useState<IUpdateAuthQuestion>({ current_question_id: authQuestionId, current_answer: '', new_question_id: undefined, new_answer: '' });
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
    const [isBoxChecked, setIsBoxChecked] = useState<boolean>(false);
    const [isForgotAuthOpen, setIsForgotAuthOpen] = useState<boolean>(false);

    // Update state if question id is updated.
    useEffect(() => {
        setUpdateAuthQstn({ current_question_id: authQuestionId, current_answer: '', new_question_id: undefined, new_answer: '' });
    }, [authQuestionId]);

    // On closing dialog reset the states of new/update Auth question objects.
    const closeSecurityQstnDlg = useCallback(() => {
        setIsAuthQstnDlgOpen(false);
        setIsBoxChecked(false);
        setNewAuthQstn({ question_id: undefined, auth_answer: '' });
        setUpdateAuthQstn({ current_question_id: authQuestionId, current_answer: '', new_question_id: undefined, new_answer: '' });
        dispatch(resetAnsValidation());
    }, [authQuestionId, dispatch, setIsAuthQstnDlgOpen]);

    // Disable save button if form is invalid.
    useEffect(() => setIsSaveDisabled(!isBoxChecked || (securityVerification ? (!updateAuthQstn.new_question_id ||
        updateAuthQstn.new_answer?.length === 0 || !isValidAnswer) : !newAuthQstn.question_id || newAuthQstn.auth_answer?.length === 0)),
        [isBoxChecked, isValidAnswer, newAuthQstn.auth_answer?.length, newAuthQstn.question_id,
            securityVerification, updateAuthQstn.new_answer?.length, updateAuthQstn.new_question_id]);

    const validateAuthAns = () => {
        if (updateAuthQstn.current_answer.trim()?.length > 0) {
            const payload = { question_id: authQuestionId, auth_answer: updateAuthQstn.current_answer.trim() }
            dispatch(validateAuthAnswer(payload));
        }
    };

    /*
        Set new Auth question.
        show success notification and update auth slice with latest auth question and security verification flags.
    */
    useEffect(() => {
        if (setAuthQsApiStatus === 'success') {
            dispatch(resetAnsValidation());
            updateUserAuth();
            closeSecurityQstnDlg();
            showNotification(setAuthQsApiStatus, resetSetAuthQs, setAuthQsApiResponse);
            dispatch(updateSecurityAuth({ security_varification: true, email_varification: false }));
        }
    }, [closeSecurityQstnDlg, dispatch, setAuthQsApiResponse, setAuthQsApiStatus, showNotification, updateUserAuth]);

    /*
    Change Auth question.
    show success notification and update auth slice with latest auth question and security verification flags.
*/
    useEffect(() => {
        if (changeAuthQuestionStatus === 'success') {
            dispatch(resetAnsValidation());
            updateUserAuth();
            closeSecurityQstnDlg();
            showNotification(changeAuthQuestionStatus, resetAuthQuestionChange, changeAuthQuestionResponse);
        }
    }, [changeAuthQuestionResponse, changeAuthQuestionStatus, closeSecurityQstnDlg, dispatch, showNotification, updateUserAuth]);

    const NewQuestionForm = () => {
        return (<>
            <FormControl size="small" fullWidth>
                <InputLabel>New Question</InputLabel>
                <Select size='small' label='New Question'
                    value={securityVerification ? (updateAuthQstn.new_question_id ?? '') : (newAuthQstn.question_id ?? '')}
                    onChange={e => securityVerification ?
                        setUpdateAuthQstn({ ...updateAuthQstn, new_question_id: e.target.value as number }) :
                        setNewAuthQstn({ ...newAuthQstn, question_id: e.target.value as number })}>
                    {getSecurityQuestionsStatus === 'pending' && <MenuItem>Loading...</MenuItem>}
                    {/* Hide Current auth question from the list */}
                    {securityQuestions.map((qs) => (qs.id !== authQuestionId &&
                        <MenuItem key={qs.id} value={qs.id}>{qs.title}</MenuItem>))}
                </Select>
            </FormControl>
            <TextField id='new_auth_ans' size='small' variant='outlined' label='Answer' fullWidth
                value={securityVerification ? updateAuthQstn.new_answer : newAuthQstn.auth_answer}
                onChange={e => securityVerification ? setUpdateAuthQstn({ ...updateAuthQstn, new_answer: e.target.value }) :
                    setNewAuthQstn({ ...newAuthQstn, auth_answer: e.target.value })} />
            <FormControlLabel control={<Checkbox checked={isBoxChecked} onChange={() => setIsBoxChecked(!isBoxChecked)} />}
                label={<Typography variant='body2'>I understand my account will be locked if I am unable to answer this question</Typography>} />
            {/* Call set Auth question API if there is no security question set else call update API */}
            <ShGreenBtn className='self-center' variant='contained' disableElevation
                disabled={isSaveDisabled || changeAuthQuestionStatus === 'pending' || setAuthQsApiStatus === 'pending'}
                onClick={() => securityVerification ? dispatch(changeAuthQuestion(updateAuthQstn)) :
                    dispatch(setAuthQuestion(newAuthQstn))}>
                {changeAuthQuestionStatus === 'pending' || setAuthQsApiStatus === 'pending' ? 'Saving Changes...' : 'Save Changes'}
            </ShGreenBtn>
        </>);
    }

    return (<>
        <SettingsDialog open={isAuthQstnDlgOpen} aria-label='Security question dialog'>
            <DialogTitle fontWeight={600} padding={`5px 16px !important`}
                borderBottom={(theme) => `1px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`}>
                Security Question
                <IconButton className='close-btn' onClick={closeSecurityQstnDlg}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack rowGap={{ xs: 2, sm: 3, md: 3, lg: 3 }} paddingTop={3}
                    paddingX={{ xs: 0.5, sm: 2, md: 2, lg: 2 }} alignItems='center' justifyContent='center'>
                    <Box width='100%'>
                        {/* Alerts for getting auth questions and changing/setting auth question APIs*/}
                        {getSecurityQuestionsStatus === 'failed' &&
                            <ShAlert severity='error'>{getSecurityQuestionsResponse ?? 'Error occurred while fetching Auth questions'}</ShAlert>}
                        {changeAuthQuestionStatus === 'failed' && <ShAlert severity="error">
                            {changeAuthQuestionResponse ?? 'Error occurred while changing Auth question'}
                        </ShAlert>}
                        {setAuthQsApiStatus === 'failed' && <ShAlert severity="error">
                            {setAuthQsApiResponse ?? 'Error occurred while setting Auth question'}
                        </ShAlert>}
                    </Box>
                    {/* If there is an Auth question already set, display current question and answer fields with API validation */}
                    {securityVerification && !isValidAnswer && <>
                        <Typography variant='body2'>
                            Before you can set a new security question, you’ll have to answer your current one correctly
                        </Typography>
                        <TextField disabled size='small' label='Current Question' variant='outlined' fullWidth
                            value={securityQuestions.find(aq => aq.id === authQuestionId)?.title ?? ''} />
                        <TextField id='current_answer' size='small' variant='outlined' label='Answer' fullWidth
                            error={validateAuthAnsApiStatus === 'failed' || isValidAnswer === false}
                            helperText={validateAuthAnsApiResponse ?? ''} value={updateAuthQstn.current_answer}
                            onChange={e => setUpdateAuthQstn({ ...updateAuthQstn, current_answer: e.target.value })}
                            onBlur={validateAuthAns} />
                        {/* Validate button to manually trigger validate call to compensate in cases where blur event might fail */}
                        <Stack flexDirection='row' justifyContent='space-between' rowGap={1}
                            alignItems='center' width='100%' flexWrap='wrap'>
                            <ShButton size='small' variant='outlined' onClick={validateAuthAns}
                                disabled={updateAuthQstn?.current_answer?.trim()?.length === 0 || validateAuthAnsApiStatus === 'pending'}>
                                {validateAuthAnsApiStatus === 'pending' ? 'Validating Answer...' : 'Validate Answer'}
                            </ShButton>
                            {isValidAnswer === false && <>
                                {/* Toggle forgot Auth answer dialog */}
                                <ShButton size='small' variant='text' onClick={() => setIsForgotAuthOpen(true)}>
                                    <Typography variant='body1'>Forgot Answer?</Typography>
                                </ShButton></>}
                        </Stack>
                    </>}
                    {/* If a security question is already set, show new question form only if the previously set question is answered. */}
                    {securityVerification ? isValidAnswer && NewQuestionForm() : NewQuestionForm()}
                </Stack>
            </DialogContent>
        </SettingsDialog >
        {/* Reset Auth answer dialog */}
        <ForgotAuthDialog authQuestionId={authQuestionId} isForgotAuthOpen={isForgotAuthOpen} setIsForgotAuthOpen={setIsForgotAuthOpen}
            showNotification={showNotification} />
    </>);
};