import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BoxColumn, ThemedBtn } from './ManageAccounts.styled';
import {
  hardResetPassword,
  resetValuesOfHardReset,
} from 'store/slices/admin/manage-accounts/manage-accounts-slice';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';

const HardResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState({
    isSuccess: false,
    message: '',
  });
  const [error, setError] = useState({
    isError: false,
    message: '',
  });

  const { response, status } = useAppSelector(
    (state) => state.admin.manageAccounts.hardResetPassword
  );

  useEffect(() => {
    if (status === 'success') {
      setEmail('');
      setPassword('');
      setError({ isError: false, message: '' });
      setSuccess({
        isSuccess: true,
        message: response,
      });
      setTimeout(() => {
        setSuccess({
          isSuccess: false,
          message: '',
        });
      }, 2000);
    }
  }, [status, response]);

  useEffect(() => {
    if (status === 'failed') {
      setSuccess({ isSuccess: false, message: '' });
      setError({
        isError: true,
        message: response,
      });
    }
  }, [status, response]);

  useEffect(() => {
    if (status === 'failed' || status === 'success') {
      dispatch(resetValuesOfHardReset());
    }
  }, [dispatch, status]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(hardResetPassword({ email, password }));
  };

  return (
    <Container maxWidth="xs">
      <BoxColumn>
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
          Reset Password
        </Typography>

        {success.isSuccess && (
          <Alert severity="success" sx={{ mt: 2, width: '100%' }}>
            {success.message}
          </Alert>
        )}

        {error.isError && (
          <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
            {error.message}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Enter Customer Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="New Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <ThemedBtn
            type="submit"
            variant="contained"
            disabled={status === 'pending'}
          >
            {status === 'pending' ? (
              <CircularProgress size={20} />
            ) : (
              'Update Password'
            )}
          </ThemedBtn>
        </Box>
      </BoxColumn>
    </Container>
  );
};

export default HardResetPassword;
