import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IJobsApiParams, IJobsListResponse, IJobsListState } from 'Modules/Core/JobsTs/JobsModel';
import { AxiosResponse } from 'axios';
import httpAdapterInstance from 'configs/HttpAdapterConfig';
import { PURGE } from 'redux-persist';
import { EmployerApiEndpoints } from 'shared/ApiEndpoints';
import { IBaseResponse } from 'shared/SharedModels';
import { DefaultAPIErrorMsg, DefaultPageSize } from 'shared/constants';


const initialDashboardJobSummaryWidgetJobsListState: IJobsListState = {
    getJobsListStatus: 'idle',
    getJobsListResponse: '',
    getJobsCountsStatus: 'idle',
    getJobsCountsResponse: '',
    jobsList: [],
    paginationParams: {
        count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0
    },
    jobsSummary: {},
    activeCount: 0,
    draftCount: 0,
    expiredCount: 0,
    templateCount: 0
};

export const fetchJobSummaryWidgetActiveJobsList = createAsyncThunk<IJobsListResponse, IJobsApiParams, { rejectValue: IBaseResponse }>(
    'fetchJobSummaryWidgetActiveJobsList',
    async ({ sortDirection, pageNumber, pageSize, sortColumn, jobType, search }: IJobsApiParams, { rejectWithValue }) => {
        return await httpAdapterInstance
            .get(`${EmployerApiEndpoints.JOBS_LIST}/active?pageNo=${pageNumber}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}${search && '&search=' + search}`)
            .then((response: AxiosResponse<IJobsListResponse>) => response?.data)
            .catch((error) => {
                throw rejectWithValue(error.response.data);
            });
    }
);

const dashboardJobSummaryListJobsSlice = createSlice({
    name: 'dashboardJobSummaryListJobsSlice',
    initialState: initialDashboardJobSummaryWidgetJobsListState,
    reducers: {
        toggleJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsList[action.payload].isExpanded = !state.jobsList[action.payload].isExpanded
        },
        resetJobsState: () => initialDashboardJobSummaryWidgetJobsListState,
        resetFetchJobSummary: (state, action: PayloadAction<number>) => {
            state.jobsSummary[action.payload].getSummaryStatus = 'idle';
            state.jobsSummary[action.payload].getSummaryResponse = '';
        },
    },
    extraReducers: (builder) => {
        // On Store PURGE reset the state
        builder.addCase(PURGE, () => {
            return initialDashboardJobSummaryWidgetJobsListState;
        });

        // Fetch jobs list
        builder.addCase(fetchJobSummaryWidgetActiveJobsList.pending, (state) => {
            state.getJobsListStatus = 'pending'
        });
        builder.addCase(fetchJobSummaryWidgetActiveJobsList.fulfilled, (state, action) => {
            state.getJobsListStatus = 'success';
            state.jobsList = action?.payload.data;
            state.paginationParams = action?.payload.stats;
            // Add total records counts in the response.
            state.paginationParams.count = action.payload.data?.length;
        });
        builder.addCase(fetchJobSummaryWidgetActiveJobsList.rejected, (state, action) => {
            state.getJobsListStatus = 'failed';
            state.getJobsListResponse = action?.payload?.message ?? DefaultAPIErrorMsg;
            state.jobsList = [];
            state.paginationParams = { count: 0, requestedPageNumber: 1, requestedPageSize: DefaultPageSize, totalPages: 0 }
        });

    }
});

export const { toggleJobSummary, resetJobsState, resetFetchJobSummary } = dashboardJobSummaryListJobsSlice.actions;
export default dashboardJobSummaryListJobsSlice;
