import { combineReducers } from '@reduxjs/toolkit';
import accInfoSlice from "store/slices/employer/settingsTs/acc-info-slice";
import accessLevelsSlice from 'store/slices/employer/settingsTs/access-levels-slice';
import companyInfoSlice from "store/slices/employer/settingsTs/company-info-slice";
import securitySlice from "store/slices/employer/settingsTs/security-slice";

const employerSettingsReducer = combineReducers({
    accInfo: accInfoSlice.reducer,
    accessLevels: accessLevelsSlice.reducer,
    companyInfo: companyInfoSlice.reducer,
    security: securitySlice.reducer,
});
export default employerSettingsReducer;
