import { Drawer, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { WhiteColor } from 'shared/SharedStyles/styleConstants';

const drawerWidth = 250;

export const MainContainer = styled(Stack)(
    ({ theme }) => ({
        height: '100vh',
        overflow: 'hidden'
    })
);

export const LeftNavAndMain = styled(Grid)(
    ({ theme }) => ({
        flex: 1,
        overflow: 'hidden'
    })
);

export const OutletContainer = styled(Grid)(
    ({ theme }) => ({
        height: '100%',
        overflow: 'auto'
    })
);

export const LeftDrawerStyled = styled(Drawer)(
    ({ theme }) => ({
        width: drawerWidth,
        height: '100vh',
        '& .MuiDrawer-paperAnchorLeft': {
            backgroundColor: WhiteColor,
            width: drawerWidth,
        },
        overflow: 'auto',
    })
);

export const SideNavContainer = styled(Grid)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'none'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    })
);

export const MobileOnlyStack = styled(Box)(
    ({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            display: 'block'
        },
        [theme.breakpoints.up('sm')]: {
            display: 'block'
        },
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    })
);
