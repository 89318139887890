import App from "App";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persister } from "store/index";

const rootElement = document.getElementById("root");

if (rootElement) {

  const root = ReactDOM.createRoot(rootElement);

  root.render(<Provider store={store}>
    <PersistGate persistor={persister}>
      <App />
    </PersistGate>
  </Provider>);
}