import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Grid, Stack, Typography } from "@mui/material";
import { ResourceTypography } from "Modules/Marketing/Resources/Resources.styled";
import { ShMyInterviewsIcon } from "assets/Icons";
import { IsMdScreen, IsXsScreen } from "helpers/hooks";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import { CommonQuestionSidebar } from "../CommonQuestionSidebar";

export const ManageInterviews = () => {
  const isMdScreen = IsMdScreen();
  const navigate = useNavigate();
  const paddingValue = IsXsScreen() ? 1 : 3;

  return (
    <>
      <Stack padding={paddingValue} direction={'row'}>
        <ShContainer margin={'auto'}>
          <ResourceTypography variant="h6" fontWeight={'bold'} gutterBottom>Manage Interviews</ResourceTypography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                <ScheduleIcon color='primary' />
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>Schedule Interview</Typography>
                  <Typography variant="body2" color="textSecondary">
                    View your scheduled interviews
                  </Typography>
                  <ShButton onClick={() => navigate('/employer/interviews/scheduled')} variant="outlined"
                    color="primary" component={RouterLink} to="/employer/interviews/scheduled">Scheduled Interview</ShButton>
                </Stack>
              </ShPaper>
            </Grid>
            <Grid item xs={12} sm={5} md={4} lg={3}>
              <ShPaper minHeight={180} borderRadius={10} elevation={3} >
                <CalendarTodayIcon color='primary' />
                <Stack spacing={2}>
                  <Typography variant="h6" gutterBottom>View Calendar</Typography>
                  <Typography variant="body2" color="textSecondary">
                    See your interview calendar
                  </Typography>
                  <ShButton size='small' variant="contained" color="primary"
                    component={RouterLink} to="employer/calendar">View Calendar</ShButton>
                </Stack>
              </ShPaper>
            </Grid>
          </Grid>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'}
            gutterBottom>Scheduling an Interview</ResourceTypography>
          <ResourceTypography variant="body1">
            To schedule an interview, navigate to <strong> My Jobs &rsaquo; Click a Job &rsaquo; View Applicants</strong>.
            Then change the stage of a candidate to Phone Screening, Interview, Second Interview.
            &nbsp; button. You may then view the interview the calendar icon in the left hand  &nbsp; {<CalendarTodayIcon color='primary' />} &nbsp;
            icon on the left-hand side OR the Interviews icon.
          </ResourceTypography>


          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Re-scheduling an Interview</ResourceTypography>
          <ResourceTypography variant="body1">
            To Reschedule click on the  &nbsp; <ShMyInterviewsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />, then
            click on the candidate and hover over the edit button.
          </ResourceTypography>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Interview Preparation</ResourceTypography>

          <ResourceTypography variant="body1">
            Proper interview preparation is crucial. This includes defining the interview format, preparing questions, and outlining follow-up actions.
            Ensure you have a clear understanding of the role and the candidate's profile.
          </ResourceTypography>

          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Interview Questions</ResourceTypography>
          <ResourceTypography variant="body1">
            Prepare a set of questions that align with the job requirements.
            You may see the custom generated questions based on their predictive analytics survey if you click into their profile.
          </ResourceTypography>


          <ResourceTypography paddingTop={4} variant="h6" fontWeight={'bold'} gutterBottom>Follow-up Actions</ResourceTypography>
          <ResourceTypography variant="body1">
            Define the follow-up actions post-interview. This can include debriefing sessions, feedback collection, and next steps for the candidate.
          </ResourceTypography>


        </ShContainer>
        {!isMdScreen && <CommonQuestionSidebar />}
      </Stack>
    </>
  );
};
