import { AccessLevelTableHead, IRoleAccessMap, RoleAccessLevelTableHead } from "Modules/Core/SettingsTs/SettingsModel";
import { ITableHeadersBase } from "shared/SharedModels";

export const AccessLevelsTableHeads: (ITableHeadersBase<AccessLevelTableHead>)[] = [
    { columName: 'NAME', label: 'Name' },
    { columName: 'EMAIL', label: 'Email' },
    { columName: 'ROLE', label: 'Role' }
];

export const RoleAccessLevelsTableHeads: (ITableHeadersBase<RoleAccessLevelTableHead>)[] = [
    { columName: 'ALL_JOBS', label: 'All Jobs' },
    { columName: 'CREATE', label: 'Create' },
    { columName: 'ASSIGN', label: 'Assign' },
    { columName: 'VIEW', label: 'View' },
    { columName: 'COMMENT', label: 'Comment' }
];

export const RoleAccessMap: IRoleAccessMap = {
    1: ['ALL_JOBS', 'CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    2: ['ALL_JOBS', 'CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    3: ['CREATE', 'ASSIGN', 'VIEW', 'COMMENT'],
    4: ['VIEW', 'COMMENT'],
    5: ['VIEW', 'COMMENT'],
};

export const AccessLevelBullets: string[] = [
    `Administrators can create, assign, view and comment on all jobs company-wide.`,
    `Recruiters can create and assign jobs, plus view and comment on jobs they have created.`,
    `Hiring Managers can't create jobs, but they can view and comment on jobs assigned to them.`,
    `Observers can't create jobs, but can view and comment on all jobs company-wide.`
];