import { Stack, TableBody, TableCell, TableHead, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ShStyledTableRow, ShTable, ShTableContainer } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShMuiLink } from "shared/SharedStyles/ShNavigation";
import { getScoreCategories } from "store/slices/admin/assessments-and-reports/score-categories-slice";

export const ScoreCategories = () => {

    const dispatch = useAppDispatch();
    const { scoreCategoryId } = useParams();
    const { getScoreCategoriesStatus, getScoreCategoriesResponse, scoreCategories
    } = useAppSelector(state => state.admin.assessmentsAndReports.scoreCategories);

    useEffect(() => {
        dispatch(getScoreCategories());
    }, [dispatch]);

    useEffect(() => {
        if (getScoreCategoriesStatus === 'success' && scoreCategoryId) {
            const currRow = document.getElementById(`score_category_row_${scoreCategoryId}`);
            currRow?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [getScoreCategoriesStatus, scoreCategoryId]);

    return (<>
        <Stack rowGap={1} padding={2} flex={1} overflow='auto'>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h6" gutterBottom>Score Categories</Typography>
                <ShButton variant="contained" disableElevation size="small"
                    component={RouterLink} to={`/admin/score-categories/new`}>
                    Add New
                </ShButton>
            </Stack>
            {getScoreCategoriesStatus === 'failed' && <ShAlert severity='error'>{getScoreCategoriesResponse}</ShAlert>}
            {getScoreCategoriesStatus === 'pending' ? <ShAlert severity="info">Loading Score Categories...</ShAlert>
                : <ShTableContainer>
                    <ShTable size="small" columnBorders>
                        <TableHead>
                            <ShStyledTableRow cursor='pointer'>
                                <TableCell>ID</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Position</TableCell>
                                <TableCell>Scoring Type</TableCell>
                            </ShStyledTableRow>
                        </TableHead>
                        <TableBody>
                            {scoreCategories.map((category) => (
                                <ShStyledTableRow cursor="default" key={category.id} id={`score_category_row_${category.id}`}>
                                    <TableCell>
                                        <ShMuiLink component={RouterLink} to={`/admin/score-categories/${category.id}/edit`}>
                                            <Typography variant='subtitle2'>{category.id}</Typography>
                                        </ShMuiLink>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{category.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{category.code}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{category.position}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">{category.scoring_type}</Typography>
                                    </TableCell>
                                </ShStyledTableRow>
                            ))}
                        </TableBody>
                    </ShTable>
                </ShTableContainer>}
        </Stack>
    </>);
}