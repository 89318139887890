import { Box, FormGroup, styled } from '@mui/material';
import { LightModeBackground } from 'shared/SharedStyles/styleConstants';

export const DashboardContentWrapper = styled(Box)(
  ({ theme }) => ({
    "& .dashboard-link": {
      textDecoration: "none",
      color: LightModeBackground,
      textTransform: "capitalize",
    },
    "& .dashboard-link-button": {
      marginRight: 4,
    },
  })
);

export const SearchFilterGroup = styled(FormGroup)(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
  })
);