import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from '@mui/icons-material/Schedule';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {
    Alert,
    Box,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import CtWellandLogo from 'assets/logos/ct-welland-logo.svg';
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ShGreenBtn, ShTextField } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { toTitleCase, truncateText } from "shared/utils";
import { getEmployerPostedJobs } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";
import { getJobDetails } from "store/slices/employer/jobs/job-info-slice";

export const CareerPagesHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerPostedJobs, employerByMask, getEmployerPostedJobsStatus, getEmployerPostedJobsResponse } = useAppSelector(state => state.employer.employerSubDomain);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOption, setSortOption] = useState('recent');
    const [selectedCities, setSelectedCities] = useState<string[]>([]);
    const [openCityFilter, setOpenCityFilter] = useState(false);
    const [pageSize] = useState(8);
    const [defaultJobId, setDefaultJobId] = useState<string | undefined>(undefined);
    const filteredJobs = employerPostedJobs?.filter(job =>
        job.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCities.length > 0 ? selectedCities.includes(job.city) : true)
    ) || [];
    const sortedJobs = [...filteredJobs].sort((a, b) => {
        if (sortOption === 'recent') {
            return new Date(b.starts_at).getTime() - new Date(a.starts_at).getTime();
        } else if (sortOption === 'oldest') {
            return new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime();
        } else if (sortOption === 'city') {
            return a.city.localeCompare(b.city);
        }
        return 0;
    });
    const cities = [...new Set(employerPostedJobs?.map(job => job.city).filter(city => city.trim() !== '') || [])];
    const handleCityToggle = (city: string) => {
        setSelectedCities(prevSelectedCities =>
            prevSelectedCities.includes(city)
                ? prevSelectedCities.filter(c => c !== city)
                : [...prevSelectedCities, city]
        );
    };
    const jobsToDisplay = sortedJobs.length > 0 ? sortedJobs : employerPostedJobs || [];
    const totalPages = Math.ceil(jobsToDisplay.length / pageSize);
    const paginatedJobs = jobsToDisplay.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();

    useEffect(() => {
        if (!isSmScreen && employerPostedJobs && employerPostedJobs.length > 0) {
            setDefaultJobId(employerPostedJobs[0]?.id.toString());
        }
    }, [employerPostedJobs, isSmScreen]);

    useEffect(() => {
        if (!isSmScreen && defaultJobId) {
            dispatch(getJobDetails({ jobId: parseInt(defaultJobId) }));
            navigate(`${defaultJobId}`);
        }
    }, [defaultJobId, dispatch, isSmScreen, navigate]);


    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Get jobs list on page load.
    useEffect(() => {
        if (employerByMask?.id) {
            dispatch(getEmployerPostedJobs({ employerId: employerByMask?.id }))
        }
    }, [dispatch, employerByMask?.id]);

    return (
        <Stack height='100%'>
            <Box overflow='auto'>
                <ShContainer maxWidth='xl' margin='auto'>
                    <Box marginY={1}>
                        <ShPaper height='unset' variant='outlined'>
                            <Stack direction='row' alignItems='center' columnGap={1}>
                                <img height={100} width={100} src={CtWellandLogo} alt="Logo" />
                                <Stack >
                                    <Typography variant="h6">Welcome to CTwelland jobs.</Typography>
                                    <Typography variant="subtitle2" textAlign='right'>Powered by Smoothhiring</Typography>
                                </Stack>
                            </Stack>
                        </ShPaper>
                        <Box paddingTop={2} alignItems="center" justifyContent='center'>
                            <ShPaper variant="outlined">
                                <Stack direction={isSmScreen ? 'column' : 'row'} justifyContent='space-between' spacing={2}>
                                    <Box flex={1} maxWidth={400}>
                                        <ShTextField
                                            size="small"
                                            label="Search for Jobs"
                                            variant="outlined"
                                            fullWidth
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </Box>
                                    <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2}>
                                        <Box marginTop={2} maxWidth={400} minWidth={250}>
                                            <FormControl id="city-filter-label" variant="outlined" fullWidth size="small">
                                                <InputLabel>Filter by City</InputLabel>
                                                <Select
                                                    labelId="city-filter-label"
                                                    multiple
                                                    open={openCityFilter}
                                                    onOpen={() => setOpenCityFilter(true)}
                                                    onClose={() => setOpenCityFilter(false)}
                                                    value={selectedCities}
                                                    input={
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <TuneOutlinedIcon />
                                                                </InputAdornment>
                                                            }
                                                            label="Filter by City"
                                                        />
                                                    }
                                                    renderValue={(selected) => (
                                                        <Box>
                                                            {selected.join(', ').length === 2 ? 'Filter by City' : truncateText(selected.join(', '), 20)}
                                                        </Box>
                                                    )}>
                                                    {cities.length > 0 ? (
                                                        cities.map(city => (
                                                            <MenuItem
                                                                key={city}
                                                                value={city}
                                                                onClick={() => handleCityToggle(city)}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedCities.includes(city)}
                                                                />
                                                                <Typography variant="body2">
                                                                    {city}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>No Cities Available</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flex={1} maxWidth={250} minWidth={150}>
                                            <FormControl variant="outlined" fullWidth size="small">
                                                <InputLabel> Sort By</InputLabel>
                                                <Select
                                                    input={
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <SwapVertOutlinedIcon />
                                                                </InputAdornment>
                                                            }
                                                            label="Sort By"
                                                        />
                                                    }
                                                    value={sortOption} onChange={(e) => setSortOption(e.target.value)} label="Sort By" >
                                                    <MenuItem value="recent">Recent</MenuItem>
                                                    <MenuItem value="oldest">Oldest</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </ShPaper>
                        </Box>
                        <Stack direction={isSmScreen ? 'column' : 'row'} >
                            <Stack marginTop={1} rowGap={2} >
                                <Typography textAlign='center' variant="subtitle1" paddingTop={1}> {filteredJobs?.length} Jobs found</Typography>
                                {getEmployerPostedJobsStatus === 'failed' && <Alert severity='error'>{getEmployerPostedJobsResponse}</Alert>}
                                {getEmployerPostedJobsStatus === 'pending' ?
                                    <Box marginTop={2} width='100%' display='flex' justifyContent='center'><CircularProgress size={100} color='success' /></Box> :
                                    paginatedJobs?.map(job =>
                                        <Stack minWidth={isXsScreen ? 200 : 400} maxWidth={isSmScreen ? '100%' : 400} key={job.id}>
                                            <ShPaper variant='outlined' key={job.id} sx={{ width: '100%' }} transElevateOnHover onClick={() => navigate(`/careers/${job.id}`)}>
                                                <CardHeader
                                                    action={
                                                        <ShGreenBtn size="small" onClick={() => { navigate(`/careers/${job.id}`) }}>
                                                            <Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                                                                View Job
                                                                <ArrowForwardIcon sx={{ marginLeft: .5, fontSize: 15 }} />
                                                            </Typography>
                                                        </ShGreenBtn>
                                                    }
                                                    title={
                                                        <Typography variant="body2" paddingBottom={1} maxWidth={200}>
                                                            {job.name}
                                                        </Typography>
                                                    }
                                                    subheader={
                                                        <>
                                                            <Stack direction='row' alignItems='center' columnGap={1}>
                                                                <ScheduleIcon fontSize='small' />
                                                                <Typography variant="body2" color="text.secondary" alignItems='center' justifyContent='center'>
                                                                    {toTitleCase(moment(job.starts_at).fromNow())}
                                                                </Typography>
                                                            </Stack>
                                                            <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                                                <PlaceIcon fontSize='small' />
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {`${job.city}, ${job.state}`}
                                                                </Typography>
                                                            </Stack>
                                                        </>
                                                    }
                                                />
                                            </ShPaper>
                                        </Stack>
                                    )}
                                {!employerPostedJobs &&
                                    <ShPaper variant="outlined">
                                        No Jobs Currently.
                                    </ShPaper>
                                }
                                {employerPostedJobs && totalPages > 1 && (
                                    <Box display="flex" justifyContent="center" margin={2}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={(e, value) => setCurrentPage(value)}
                                            color="primary"
                                        />
                                    </Box>
                                )}
                            </Stack>
                            {!isSmScreen &&
                                <Stack paddingTop={1} rowGap={2} width='100%'>
                                    <Outlet />
                                </Stack>
                            }
                        </Stack>
                    </Box>
                </ShContainer>
            </Box>
        </Stack>
    );
}