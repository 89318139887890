import { Box, Chip, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, IconButton, Stack, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { useNotification } from "Modules/Core/Notification";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGreenBtn, ShTextareaWrapper } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { EmailRegEx } from "shared/constants";
import { inviteApplicants, resetInviteApplicants } from "store/slices/employer/applicants/applicants-list-actions-slice";
import { IInviteApplicants, IInviteApplicantsPayload } from "./ApplicantsModel";

export const InviteApplicants = ({ isInviteApplicantsOpen, setIsInviteApplicantsOpen, job }: IInviteApplicants) => {

    const dispatch = useAppDispatch();
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const { inviteApplicantsStatus, inviteApplicantsResponse } = useAppSelector(state => state.employer.applicants.applicantsListActions);
    const notification = useNotification();
    const [inviteApplicantsPayload, setInviteApplicantsPayload] = useState<IInviteApplicantsPayload>({
        body: 'Hi! We invite you to apply to our job. We think you would be a great fit!',
        subject: `${employerDetails?.first_name} ${employerDetails?.last_name}, from ${employerDetails?.company_name}, has invited you to apply for ${job?.name} job`,
        to: ''
    });

    const [toEmails, setToEmails] = useState<string>('');
    const [areEmailsInvalid, setAreEmailsInvalid] = useState<boolean>(false);

    const onEmailsChange = (value: string) => {
        setToEmails(value);
        const emails = value.split(',').map(email => email.trim()); // Trim each email
        const regX = new RegExp(EmailRegEx);
        const isValidEmails = emails.every(email => regX.test(email));
        setAreEmailsInvalid(!isValidEmails)
    }

    const sendInvite = () => {
        const payload = { ...inviteApplicantsPayload };
        payload.to = toEmails;
        dispatch(inviteApplicants({ jobId: job.id, body: payload }));
    }

    useEffect(() => {
        if (inviteApplicantsStatus === 'success' || inviteApplicantsStatus === 'failed') {
            notification.displayNotification({
                message: inviteApplicantsResponse ?? '',
                open: true,
                type: inviteApplicantsStatus === 'success' ? 'success' : 'error'
            });
            setIsInviteApplicantsOpen(false);
            dispatch(resetInviteApplicants());
        }
    }, [dispatch, inviteApplicantsResponse, inviteApplicantsStatus, notification, setIsInviteApplicantsOpen]);

    return (<>
        <ShDialog open={isInviteApplicantsOpen} onClose={(e) => setIsInviteApplicantsOpen(false)}
            onClick={e => e.stopPropagation()} scroll='paper' aria-labelledby='job-desc-title'>
            <DialogTitle id='title' textAlign='center' fontWeight={600}>Invite Applicants
                <IconButton className='close-btn' onClick={() => setIsInviteApplicantsOpen(false)}><ClearIcon /></IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Stack rowGap={2}>
                    <FormControl fullWidth>
                        <FormLabel id="to_emails">
                            <Typography component={'span'} variant="body2" gutterBottom>
                                Invite people to apply for your job! Make sure emails are comma(,) separated and you can send upto 25:
                            </Typography>
                        </FormLabel>
                        <ShTextareaWrapper>
                            <textarea id='to_emails' value={toEmails}
                                onChange={(e) => onEmailsChange(e.target.value)} />
                            {
                                toEmails?.length > 4 && areEmailsInvalid &&
                                <Typography variant="caption" color='red'>Please enter valid Emails.</Typography>
                            }
                            {
                                toEmails.split(',').length > 25 &&
                                <Typography variant="caption" color='red'> You can only send up to 25 emails. Please remove some emails.</Typography>
                            }
                        </ShTextareaWrapper>
                    </FormControl>


                    <Stack direction="row" spacing={1} flexWrap="wrap" rowGap={1}>
                        {toEmails.split(',').slice(0,25).map(email => email.trim()).filter(email => email.length > 0).map((email, index) => (
                            <Chip size='small' key={index} label={email} />
                        ))}
                    </Stack>

                    <FormControl fullWidth>
                        <FormLabel id="subject_line">
                            <Typography component={'span'} variant="body2" gutterBottom>
                                Subject Line
                            </Typography>
                        </FormLabel>
                        <ShTextareaWrapper>
                        <textarea
                            id="subject"
                            value={inviteApplicantsPayload.subject}
                            onChange={(e) =>
                                setInviteApplicantsPayload({
                                    ...inviteApplicantsPayload,
                                    subject: e.target.value
                                })
                            }
                        />
                        </ShTextareaWrapper>
                    </FormControl>
                    {/* to be added once link is available in jobs list apis */}
                    {/* <Box>
                        <Typography variant="body2">Invite link:</Typography>
                        <Typography variant="body2">Invite link:dsfsdfsf</Typography>
                    </Box> */}
                    <FormControl fullWidth>
                        <FormLabel id="email_body">
                            <Typography component={'span'} variant="body2" gutterBottom>
                                Zap some life into the invite message by customizing it!
                            </Typography>
                        </FormLabel>
                        <ShTextareaWrapper>
                            <textarea id='email_body' value={inviteApplicantsPayload.body}
                                onChange={(e) => setInviteApplicantsPayload({ ...inviteApplicantsPayload, body: e.target.value})}/>
                        </ShTextareaWrapper>
                    </FormControl>
                    <ShGreenBtn onClick={sendInvite} disabled={inviteApplicantsStatus === 'pending'}>{
                        inviteApplicantsStatus === 'pending' ? 'Sending invites...' : 'Send invitation emails'
                    }</ShGreenBtn>
                    <Box>
                        <Typography variant="body2" gutterBottom>Standard footer:</Typography>
                        <ShPaper variant="outlined">
                            <Typography variant="body2" gutterBottom>POWERED BY SMOOTHHIRING</Typography>
                            <Typography variant="body2" gutterBottom>
                                Privacy Policy: http://www.SmoothHiring.com/terms/privacy-policy/
                            </Typography>
                            <Typography variant="body2" gutterBottom>Technical issues? info@smoothhiring.com</Typography>
                            <Typography variant="body2">
                                SmoothHiring, Suite #106, 6797 N High Street, Worthington, Ohio 43085 | info@smoothhiring.com
                            </Typography>
                        </ShPaper>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                <ShButton variant='outlined' disableElevation size='small'
                    onClick={(e) => { setIsInviteApplicantsOpen(false); e.stopPropagation() }}>
                    Cancel</ShButton>
            </DialogActions>
        </ShDialog >
    </>);
}