import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Typography } from '@mui/material';
import { ShMuiLink } from 'shared/SharedStyles/ShNavigation';
import { IApplyJobStep } from "./ApplyJobModel";

export enum ECandidateFlow {
    ApplyJob = 'apply-job',
    Survey = 'survey',
}

export const ApplyJobSteps: IApplyJobStep[] = [
    {
        label: 'Details',
        path: '',
        value: 0,
        stepIcon: <AccountCircleIcon />

    },
    {
        label: 'Questionnaire',
        path: 'questionnaire',
        value: 1,
        stepIcon: <QuestionAnswerIcon />
    },
    {
        label: 'Assessment',
        path: 'assessment',
        value: 2,
        stepIcon: <AssessmentIcon />
    }
];

export const CandidatePrivacyPolicyStatement = <Typography variant='caption'>
    At SmoothHiring, your information is never sold to advertisers.
    Any information you share with us will be kept private, and only used for the purpose of securing you employment opportunities.
    If you have any questions or concerns, please contact us through our live chat or at &nbsp;
    <ShMuiLink href='mailto:info@smoothhiring.com' fontSize='small' noUnderline underLineOnHover>
        info@smoothhiring.com
    </ShMuiLink>
</Typography>