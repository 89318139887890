export const AdministrativeAssistantJobDescription = {
    title: "Administrative Assistant Job Description",
    jobRole: "An Administrative Assistant provides support to managers, employees, and visitors by handling a variety of administrative tasks. They assist in daily office operations, manage correspondence, and maintain organized filing systems.",
    preRead: "Use this Administrative Assistant job description template to advertise open positions for an Administrative Assistant in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Assistant, you will be responsible for providing administrative support to ensure efficient office operations. Your role will involve managing schedules, coordinating meetings, and handling correspondence to facilitate smooth communication within the organization."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Answer and direct phone calls to appropriate individuals.",
                "Manage incoming and outgoing correspondence, including emails and mail.",
                "Schedule and coordinate appointments, meetings, and travel arrangements.",
                "Prepare and distribute documents, reports, and presentations.",
                "Maintain organized filing systems and ensure document accuracy.",
                "Assist in the preparation of expense reports and budget tracking.",
                "Monitor office supplies inventory and place orders as needed.",
                "Provide administrative support to managers and executives as required.",
                "Handle administrative requests and queries from employees and clients.",
                "Perform data entry tasks and update databases or records as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High School Diploma or equivalent; Associate or Bachelor’s Degree preferred.",
                "Proven experience as an Administrative Assistant or similar role.",
                "Proficiency in Microsoft Office suite (Word, Excel, PowerPoint, Outlook).",
                "Strong organizational and multitasking skills.",
                "Excellent communication and interpersonal abilities.",
                "Attention to detail and problem-solving skills.",
                "Ability to prioritize tasks and work independently.",
                "Discretion and confidentiality in handling sensitive information.",
                "Familiarity with office equipment and procedures."
            ]
        }
    ]
};

export const OfficeManagerJobDescription = {
    title: "Office Manager Job Description",
    jobRole: "An Office Manager oversees the administrative operations of an office, ensuring efficiency and productivity. They manage office staff, handle administrative tasks, and oversee facility maintenance to create a productive work environment.",
    preRead: "Use this Office Manager job description template to advertise open positions for an Office Manager in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Office Manager, you will be responsible for managing office operations, supervising staff, and maintaining a productive work environment. Your role will involve handling administrative tasks, coordinating office activities, and ensuring compliance with company policies and procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Supervise office staff and assign tasks to ensure efficient operations.",
                "Oversee office administrative functions, including mail handling, scheduling, and filing.",
                "Manage office supplies inventory and order replenishments as needed.",
                "Coordinate office maintenance and repairs, including equipment and facilities.",
                "Ensure compliance with company policies and procedures.",
                "Handle employee inquiries and provide administrative support as needed.",
                "Organize and schedule meetings, events, and appointments.",
                "Maintain accurate records and prepare reports as required.",
                "Assist in budget preparation and expense tracking.",
                "Implement office policies and procedures to maintain a safe and productive work environment."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration, Management, or related field.",
                "Proven experience as an Office Manager or similar role.",
                "Strong leadership and managerial skills.",
                "Excellent communication and interpersonal abilities.",
                "Proficiency in Microsoft Office suite (Word, Excel, PowerPoint, Outlook).",
                "Ability to multitask and prioritize tasks effectively.",
                "Detail-oriented with strong organizational skills.",
                "Problem-solving and decision-making abilities.",
                "Familiarity with office equipment and procedures.",
                "Knowledge of safety and compliance regulations."
            ]
        }
    ]
};

export const ReceptionistJobDescription = {
    title: "Receptionist Job Description",
    jobRole: "A Receptionist serves as the first point of contact for visitors and callers to an organization. They greet guests, answer inquiries, and provide information about the company. Receptionists also perform administrative tasks to support office operations.",
    preRead: "Use this Receptionist job description template to advertise open positions for a Receptionist in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Receptionist, you will be responsible for providing a positive first impression to visitors and callers. Your role will involve greeting guests, answering inquiries, and directing calls to the appropriate individuals. Additionally, you will assist in administrative tasks to support office operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Greet visitors and direct them to the appropriate person or department.",
                "Answer and direct phone calls to the appropriate individuals.",
                "Provide information about the organization to visitors and callers.",
                "Manage incoming and outgoing mail and packages.",
                "Maintain a tidy and organized reception area.",
                "Schedule appointments and meetings as requested.",
                "Assist in administrative tasks, such as data entry and filing.",
                "Handle inquiries and requests from employees and clients.",
                "Maintain office security by following procedures and monitoring visitor access.",
                "Adhere to company policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High School Diploma or equivalent.",
                "Proven experience as a Receptionist or similar role.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office suite (Word, Excel, PowerPoint, Outlook).",
                "Ability to multitask and prioritize tasks effectively.",
                "Detail-oriented with strong organizational skills.",
                "Professional appearance and demeanor.",
                "Customer service-oriented mindset.",
                "Ability to handle sensitive information with confidentiality.",
                "Familiarity with office equipment and procedures."
            ]
        }
    ]
};

export const ExecutiveAssistantJobDescription = {
    title: "Executive Assistant Job Description",
    jobRole: "An Executive Assistant provides high-level administrative support to executives and senior management. They manage schedules, coordinate meetings, and handle confidential information with discretion. Executive Assistants also assist in project management and communication.",
    preRead: "Use this Executive Assistant job description template to advertise open positions for an Executive Assistant in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Executive Assistant, you will be responsible for providing comprehensive administrative support to executives and senior management. Your role will involve managing schedules, coordinating meetings, and handling confidential information with discretion. Additionally, you will assist in project management and communication to support the executive team."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage executives' schedules and coordinate appointments, meetings, and travel arrangements.",
                "Screen and prioritize incoming communications, including emails and phone calls.",
                "Prepare and edit correspondence, presentations, and reports for executives.",
                "Handle sensitive information and maintain confidentiality at all times.",
                "Assist in project management and follow up on action items as needed.",
                "Conduct research and compile data for various projects and reports.",
                "Coordinate logistics for meetings, conferences, and events.",
                "Provide administrative support to other team members as required.",
                "Maintain organized filing systems and document management."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration, Management, or related field.",
                "Proven experience as an Executive Assistant or similar role supporting senior management.",
                "Exceptional organizational and time management skills.",
                "Strong communication and interpersonal abilities.",
                "Proficiency in Microsoft Office suite (Word, Excel, PowerPoint, Outlook).",
                "Ability to handle multiple tasks and prioritize effectively in a fast-paced environment.",
                "Discretion and confidentiality in handling sensitive information.",
                "Attention to detail and accuracy in all work.",
                "Problem-solving and decision-making abilities.",
                "Flexibility and adaptability to changing priorities."
            ]
        }
    ]
};

export const DataEntryClerkJobDescription = {
    title: "Data Entry Clerk Job Description",
    jobRole: "A Data Entry Clerk is responsible for entering and updating data into computer systems and maintaining accurate records. They perform data entry tasks with speed and accuracy to ensure the integrity of information.",
    preRead: "Use this Data Entry Clerk job description template to advertise open positions for a Data Entry Clerk in your company. Be sure to customize the responsibilities and qualifications according to your organization's needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Data Entry Clerk, you will be responsible for inputting and updating data into computer systems and databases. Your role will involve ensuring the accuracy and integrity of information by performing data entry tasks with speed and precision."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Enter data into computer systems and databases accurately and efficiently.",
                "Review data for errors, completeness, and compliance with established standards.",
                "Maintain accurate and up-to-date records and databases.",
                "Organize and file documents and data as needed.",
                "Verify data by comparing it to source documents.",
                "Respond to inquiries and requests for information from colleagues or clients.",
                "Generate reports and extract data from databases as required.",
                "Assist in data cleanup and quality assurance activities.",
                "Adhere to data entry procedures and guidelines.",
                "Protect confidential and sensitive information."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High School Diploma or equivalent.",
                "Proven experience as a Data Entry Clerk or similar role.",
                "Fast and accurate typing skills.",
                "Attention to detail and accuracy in data entry tasks.",
                "Ability to work independently and meet deadlines.",
                "Basic understanding of data management and database systems.",
                "Proficiency in Microsoft Office suite (Word, Excel, Outlook).",
                "Strong organizational and time management skills.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Excellent communication and interpersonal abilities."
            ]
        }
    ]
};


export const AdministrativeCoordinatorJobDescription = {
    title: "Administrative Coordinator Job Description",
    jobRole: "An Administrative Coordinator ensures the smooth operation of office processes and administrative functions. They coordinate various administrative tasks, manage office supplies, and provide support to other staff members to ensure efficiency.",
    preRead: "Use this Administrative Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Coordinator, you will be responsible for coordinating administrative tasks, managing office supplies, and providing support to staff members. You will ensure efficient office operations and assist in various administrative duties."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate administrative tasks and office activities.",
                "Manage office supplies and inventory.",
                "Provide support to staff members and management.",
                "Maintain office records and documentation.",
                "Schedule and organize meetings and appointments.",
                "Assist in preparing reports and presentations.",
                "Handle incoming and outgoing correspondence.",
                "Ensure compliance with company policies and procedures.",
                "Liaise with vendors and service providers.",
                "Perform other administrative duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration or related field.",
                "Proven experience as an Administrative Coordinator or similar role.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office Suite.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and problem-solving skills.",
                "Experience with office management software is a plus."
            ]
        }
    ]
};


export const OfficeAdministratorJobDescription = {
    title: "Office Administrator Job Description",
    jobRole: "An Office Administrator is responsible for ensuring the smooth running of daily office operations. They handle administrative tasks, manage office supplies, and provide support to staff and management.",
    preRead: "Use this Office Administrator job description template to attract capable candidates for your organization. Customize the responsibilities and requirements to suit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Office Administrator, you will manage daily office operations, handle administrative tasks, and support staff and management. Your role is crucial in maintaining a well-organized and efficient office environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee daily office operations and procedures.",
                "Manage office supplies and inventory.",
                "Provide administrative support to staff and management.",
                "Maintain office records and documentation.",
                "Schedule and coordinate meetings and appointments.",
                "Handle incoming and outgoing correspondence.",
                "Assist in preparing reports and presentations.",
                "Ensure compliance with company policies and procedures.",
                "Liaise with vendors and service providers.",
                "Perform other administrative duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration or related field.",
                "Proven experience as an Office Administrator or similar role.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office Suite.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and problem-solving skills.",
                "Experience with office management software is a plus."
            ]
        }
    ]
};


export const AdministrativeSupportSpecialistJobDescription = {
    title: "Administrative Support Specialist Job Description",
    jobRole: "An Administrative Support Specialist provides comprehensive administrative support to ensure efficient operation of the office. They assist in various tasks, manage correspondence, and support the team with administrative duties.",
    preRead: "Use this Administrative Support Specialist job description template to find qualified candidates for your organization. Customize the responsibilities and requirements based on your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Support Specialist, you will provide comprehensive administrative support to ensure efficient office operations. You will assist in various tasks, manage correspondence, and support the team with administrative duties."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Provide administrative support to staff and management.",
                "Manage incoming and outgoing correspondence.",
                "Assist in preparing reports, presentations, and documents.",
                "Maintain office records and documentation.",
                "Schedule and coordinate meetings and appointments.",
                "Handle office supplies and inventory management.",
                "Support in organizing company events and activities.",
                "Ensure compliance with company policies and procedures.",
                "Liaise with vendors and service providers.",
                "Perform other administrative duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration or related field.",
                "Proven experience as an Administrative Support Specialist or similar role.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office Suite.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and problem-solving skills.",
                "Experience with office management software is a plus."
            ]
        }
    ]
};


export const FrontDeskCoordinatorJobDescription = {
    title: "Front Desk Coordinator Job Description",
    jobRole: "A Front Desk Coordinator is responsible for managing the front desk operations, providing excellent customer service, and ensuring a welcoming environment for visitors and staff. They handle administrative tasks, manage communications, and coordinate front desk activities.",
    preRead: "Use this Front Desk Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Front Desk Coordinator, you will be the first point of contact for visitors and staff. You will manage front desk operations, handle administrative tasks, and ensure a welcoming environment. Your role is crucial in providing excellent customer service and ensuring smooth office operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage front desk operations and activities.",
                "Greet and welcome visitors in a professional manner.",
                "Handle incoming calls, emails, and correspondence.",
                "Assist with scheduling and coordinating appointments.",
                "Maintain a clean and organized reception area.",
                "Provide administrative support to staff and management.",
                "Manage office supplies and inventory.",
                "Ensure compliance with company policies and procedures.",
                "Coordinate with other departments for office needs.",
                "Perform other front desk and administrative duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; additional education or certification in office administration is a plus.",
                "Proven experience as a Front Desk Coordinator or similar role.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational and multitasking abilities.",
                "Proficiency in Microsoft Office Suite.",
                "Ability to work independently and as part of a team.",
                "Attention to detail and problem-solving skills.",
                "Friendly and professional demeanor."
            ]
        }
    ]
};


export const AdministrativeServicesManagerJobDescription = {
    title: "Administrative Services Manager Job Description",
    jobRole: "An Administrative Services Manager oversees the administrative operations of an organization, ensuring efficient office procedures, managing administrative staff, and coordinating various office services. They play a key role in optimizing administrative functions and supporting the overall management.",
    preRead: "Use this Administrative Services Manager job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Services Manager, you will oversee the administrative operations of the organization. You will manage administrative staff, coordinate office services, and ensure efficient office procedures. Your role is crucial in optimizing administrative functions and supporting overall management."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee daily administrative operations and office services.",
                "Manage and supervise administrative staff.",
                "Develop and implement office policies and procedures.",
                "Coordinate office maintenance and facility management.",
                "Manage office budgets and expenses.",
                "Ensure compliance with company policies and regulations.",
                "Support management with administrative tasks and projects.",
                "Evaluate and improve administrative processes for efficiency.",
                "Liaise with vendors and service providers.",
                "Perform other administrative management duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s Degree in Business Administration, Management, or related field.",
                "Proven experience as an Administrative Services Manager or similar role.",
                "Strong leadership and supervisory skills.",
                "Excellent communication and interpersonal abilities.",
                "Proficiency in Microsoft Office Suite and office management software.",
                "Strong organizational and multitasking skills.",
                "Attention to detail and problem-solving abilities.",
                "Ability to work independently and as part of a team."
            ]
        }
    ]
};

export const PersonalAssistantJobDescription = {
    title: "Personal Assistant Job Description",
    jobRole: "A Personal Assistant provides personalized administrative support to senior executives or individuals, managing schedules, handling correspondence, and performing various tasks to ensure efficient daily operations.",
    preRead: "Use this Personal Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Personal Assistant, you will provide personalized administrative support to senior executives or individuals. You will manage schedules, handle correspondence, and perform various tasks to ensure efficient daily operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and organize schedules and appointments.",
                "Handle incoming calls, emails, and correspondence.",
                "Prepare reports, presentations, and other documents.",
                "Coordinate travel arrangements and itineraries.",
                "Assist with personal tasks and errands as needed.",
                "Maintain confidentiality and discretion in handling sensitive information.",
                "Liaise with internal and external contacts.",
                "Provide administrative support for meetings and events.",
                "Ensure timely completion of tasks and assignments.",
                "Perform other personal and administrative duties as assigned."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; additional education or certification in office administration is a plus.",
                "Proven experience as a Personal Assistant or similar role.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational and multitasking abilities.",
                "Proficiency in Microsoft Office Suite.",
                "Ability to maintain confidentiality and discretion.",
                "Attention to detail and problem-solving skills.",
                "Friendly and professional demeanor."
            ]
        }
    ]
};

export const SecretaryJobDescription = {
    title: "Secretary Job Description",
    jobRole: "A Secretary provides administrative support to ensure efficient operation of the office. They support managers and employees through a variety of tasks related to organization and communication.",
    preRead: "Use this Secretary job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Secretary, you will provide administrative support to ensure efficient operation of the office. You will support managers and employees through a variety of tasks related to organization and communication. Your role is crucial in ensuring the smooth running of office operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Answer and direct phone calls.",
                "Organize and schedule appointments.",
                "Plan meetings and take detailed minutes.",
                "Write and distribute email, correspondence memos, letters, faxes, and forms.",
                "Assist in the preparation of regularly scheduled reports.",
                "Develop and maintain a filing system.",
                "Update and maintain office policies and procedures.",
                "Order office supplies and research new deals and suppliers.",
                "Maintain contact lists.",
                "Book travel arrangements.",
                "Submit and reconcile expense reports.",
                "Provide general support to visitors.",
                "Act as the point of contact for internal and external clients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Proven experience as a Secretary or Administrative Assistant.",
                "Familiarity with office organization and optimization techniques.",
                "High degree of multi-tasking and time management capability.",
                "Excellent written and verbal communication skills.",
                "Integrity and professionalism.",
                "Proficiency in MS Office."
            ]
        }
    ]
};

export const AdministrativeOfficerJobDescription = {
    title: "Administrative Officer Job Description",
    jobRole: "An Administrative Officer manages the day-to-day administrative operations of an organization. They ensure that the office runs efficiently by coordinating administrative services and supporting staff and management.",
    preRead: "Use this Administrative Officer job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Officer, you will manage the day-to-day administrative operations of the organization. You will ensure that the office runs efficiently by coordinating administrative services and supporting staff and management."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage office supplies stock and place orders.",
                "Prepare regular reports on expenses and office budgets.",
                "Maintain and update company databases.",
                "Organize a filing system for important and confidential company documents.",
                "Answer queries by employees and clients.",
                "Update office policies as needed.",
                "Maintain a company calendar and schedule appointments.",
                "Book meeting rooms as required.",
                "Distribute and store correspondence (e.g. letters, emails, and packages).",
                "Prepare reports and presentations with statistical data, as assigned.",
                "Arrange travel and accommodations."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Proven work experience as an Administrative Officer, Administrator, or similar role.",
                "Solid knowledge of office procedures.",
                "Experience with office management software like MS Office (MS Excel and MS Word, specifically).",
                "Strong organization skills with a problem-solving attitude.",
                "Excellent written and verbal communication skills.",
                "Attention to detail and the ability to prioritize tasks.",
                "A high school diploma; additional qualifications in Office Administration are a plus."
            ]
        }
    ]
};

export const ClericalAssistantJobDescription = {
    title: "Clerical Assistant Job Description",
    jobRole: "A Clerical Assistant performs various administrative tasks to support the smooth operation of the office. They handle office tasks such as filing, generating reports and presentations, setting up meetings, and reordering supplies.",
    preRead: "Use this Clerical Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Clerical Assistant, you will perform various administrative tasks to support the smooth operation of the office. Your responsibilities include handling office tasks such as filing, generating reports and presentations, setting up meetings, and reordering supplies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Perform clerical duties such as filing, photocopying, and scanning documents.",
                "Generate reports, presentations, and other documents as needed.",
                "Set up meetings and appointments.",
                "Answer and direct phone calls and emails.",
                "Order and maintain office supplies inventory.",
                "Assist in preparing regularly scheduled reports.",
                "Update and maintain office policies and procedures.",
                "Assist with travel arrangements and expense reports.",
                "Provide general administrative support to staff and management.",
                "Handle incoming and outgoing mail and packages."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a Clerical Assistant or in a similar administrative role.",
                "Proficiency in MS Office (Word, Excel, PowerPoint).",
                "Strong organizational skills and attention to detail.",
                "Excellent communication and interpersonal skills.",
                "Ability to multitask and prioritize tasks effectively.",
                "Dependable and punctual with a positive attitude."
            ]
        }
    ]
};

export const AdministrativeClerkJobDescription = {
    title: "Administrative Clerk Job Description",
    jobRole: "An Administrative Clerk provides general office support by performing a variety of administrative and clerical tasks. They ensure the smooth operation of office activities and help maintain efficiency.",
    preRead: "Use this Administrative Clerk job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Clerk, you will perform a variety of administrative and clerical tasks to support the office's smooth operation. Your duties include answering phones, managing office supplies, and maintaining records."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Answer and direct phone calls.",
                "Organize and schedule appointments.",
                "Maintain filing systems and update records.",
                "Assist in the preparation of regularly scheduled reports.",
                "Order office supplies and research new deals and suppliers.",
                "Maintain contact lists.",
                "Provide general support to visitors.",
                "Act as the point of contact for internal and external clients."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as an Administrative Clerk or in a similar role.",
                "Proficiency in MS Office (Word, Excel, PowerPoint).",
                "Strong organizational skills and attention to detail.",
                "Excellent communication and interpersonal skills.",
                "Ability to multitask and prioritize tasks effectively."
            ]
        }
    ]
};

export const SeniorAdministrativeAssistantJobDescription = {
    title: "Senior Administrative Assistant Job Description",
    jobRole: "A Senior Administrative Assistant provides high-level administrative support to executives and senior managers. They handle a variety of tasks to ensure the smooth running of the office and support strategic initiatives.",
    preRead: "Use this Senior Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Senior Administrative Assistant, you will provide high-level administrative support to executives and senior managers. You will manage schedules, organize meetings, and handle a wide range of administrative tasks."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage executives’ schedules and arrange meetings.",
                "Prepare reports, memos, letters, and other documents.",
                "Coordinate travel arrangements and itineraries.",
                "Handle confidential information with discretion.",
                "Assist in preparing presentations and reports.",
                "Conduct research and prepare briefing materials.",
                "Liaise with internal and external stakeholders.",
                "Oversee office supplies and manage inventory.",
                "Support the planning and execution of company events."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration or related field.",
                "Proven experience as a Senior Administrative Assistant or in a similar role.",
                "Excellent organizational and time management skills.",
                "Strong written and verbal communication skills.",
                "Proficiency in MS Office (Word, Excel, PowerPoint).",
                "Ability to handle multiple tasks and prioritize effectively.",
                "Discretion and confidentiality."
            ]
        }
    ]
};

export const JuniorAdministrativeAssistantJobDescription = {
    title: "Junior Administrative Assistant Job Description",
    jobRole: "A Junior Administrative Assistant provides support to the office by performing basic administrative tasks. They assist senior administrative staff and ensure the smooth operation of office activities.",
    preRead: "Use this Junior Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Junior Administrative Assistant, you will provide support to the office by performing basic administrative tasks. You will assist senior administrative staff and ensure the smooth operation of office activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Answer and direct phone calls.",
                "Assist in organizing and scheduling appointments.",
                "Prepare and update documents, reports, and correspondence.",
                "Maintain filing systems and databases.",
                "Assist in preparing meeting materials and taking minutes.",
                "Help manage office supplies and place orders.",
                "Perform data entry and maintain accurate records.",
                "Provide general support to visitors and staff."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a Junior Administrative Assistant or in a similar role.",
                "Basic understanding of office procedures and practices.",
                "Proficiency in MS Office (Word, Excel, PowerPoint).",
                "Good organizational and time management skills.",
                "Strong written and verbal communication skills.",
                "Ability to work independently and as part of a team."
            ]
        }
    ]
};

export const VirtualAssistantJobDescription = {
    title: "Virtual Assistant Job Description",
    jobRole: "A Virtual Assistant provides remote administrative support to clients or employers. They handle a variety of tasks including managing emails, scheduling appointments, and conducting research.",
    preRead: "Use this Virtual Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities and requirements to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Virtual Assistant, you will provide remote administrative support to clients or employers. Your duties will include managing emails, scheduling appointments, conducting research, and performing various administrative tasks."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage and respond to emails and phone calls.",
                "Schedule meetings and manage calendars.",
                "Conduct research and prepare reports.",
                "Handle administrative tasks such as data entry, filing, and document management.",
                "Prepare and organize databases and records.",
                "Assist in creating and maintaining online content.",
                "Provide customer support and manage client inquiries.",
                "Coordinate travel arrangements and itineraries.",
                "Support special projects and initiatives as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Proven experience as a Virtual Assistant or in a similar role.",
                "Proficiency in MS Office and familiarity with online tools and software.",
                "Excellent time management and organizational skills.",
                "Strong written and verbal communication skills.",
                "Ability to work independently and manage multiple tasks.",
                "High degree of confidentiality and professionalism.",
                "Reliable internet connection and a dedicated home office setup."
            ]
        }
    ]
};

export const RecordsManagementClerkJobDescription = {
    title: "Records Management Clerk Job Description",
    jobRole: "A Records Management Clerk is responsible for maintaining and organizing company records, ensuring they are accurate, accessible, and secure. They play a critical role in the smooth operation of an organization's information management system.",
    preRead: "Use this Records Management Clerk job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Records Management Clerk, you will manage and maintain company records, ensuring they are accurate, accessible, and secure. Your responsibilities include organizing records, maintaining filing systems, and assisting with data entry and retrieval."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Organize and maintain filing systems.",
                "Ensure records are accurate, up-to-date, and accessible.",
                "Assist with data entry and retrieval of records.",
                "Manage physical and electronic records.",
                "Prepare reports and documentation as needed.",
                "Ensure compliance with record-keeping regulations.",
                "Assist with audits and inspections of records."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a Records Management Clerk or similar role.",
                "Proficiency in MS Office and record management software.",
                "Strong organizational skills and attention to detail.",
                "Excellent written and verbal communication skills.",
                "Ability to maintain confidentiality and handle sensitive information.",
                "Strong problem-solving and analytical skills."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $30,000 - $40,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for professional development and career growth."
            ]
        }
    ]
};

export const AdministrativeProgramCoordinatorJobDescription = {
    title: "Administrative Program Coordinator Job Description",
    jobRole: "An Administrative Program Coordinator oversees and coordinates administrative aspects of a specific program or project within an organization. They ensure program activities run smoothly and efficiently.",
    preRead: "Use this Administrative Program Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Administrative Program Coordinator, you will oversee and coordinate administrative aspects of a specific program or project within the organization. Your duties include managing schedules, organizing events, and liaising with stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate program activities and administrative tasks.",
                "Manage schedules and organize meetings and events.",
                "Prepare and maintain program documentation and reports.",
                "Communicate with stakeholders and program participants.",
                "Assist with budget preparation and monitoring.",
                "Ensure compliance with program policies and procedures.",
                "Provide administrative support to program staff."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Public Administration, or related field.",
                "Proven experience as an Administrative Program Coordinator or similar role.",
                "Strong organizational and multitasking skills.",
                "Excellent written and verbal communication skills.",
                "Proficiency in MS Office and project management software.",
                "Ability to work independently and as part of a team.",
                "Strong problem-solving and analytical skills."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $40,000 - $50,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Professional development opportunities."
            ]
        }
    ]
};

export const ExecutiveSecretaryJobDescription = {
    title: "Executive Secretary Job Description",
    jobRole: "An Executive Secretary provides high-level administrative support to executives and senior management. They handle a variety of tasks to ensure the smooth running of executive offices and assist in strategic initiatives.",
    preRead: "Use this Executive Secretary job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Executive Secretary, you will provide high-level administrative support to executives and senior management. Your duties include managing schedules, organizing meetings, and handling confidential information."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage executives’ schedules and arrange meetings.",
                "Prepare reports, memos, letters, and other documents.",
                "Coordinate travel arrangements and itineraries.",
                "Handle confidential information with discretion.",
                "Assist in preparing presentations and reports.",
                "Conduct research and prepare briefing materials.",
                "Liaise with internal and external stakeholders.",
                "Oversee office supplies and manage inventory.",
                "Support the planning and execution of company events."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration or related field.",
                "Proven experience as an Executive Secretary or similar role.",
                "Excellent organizational and time management skills.",
                "Strong written and verbal communication skills.",
                "Proficiency in MS Office (Word, Excel, PowerPoint).",
                "Ability to handle multiple tasks and prioritize effectively.",
                "Discretion and confidentiality."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $50,000 - $70,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for professional development and career growth."
            ]
        }
    ]
};

export const ReceptionSupervisorJobDescription = {
    title: "Reception Supervisor Job Description",
    jobRole: "A Reception Supervisor oversees the front desk operations, ensuring excellent customer service and smooth administrative processes. They manage reception staff, handle client inquiries, and coordinate front office activities.",
    preRead: "Use this Reception Supervisor job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Reception Supervisor, you will oversee front desk operations, manage reception staff, and ensure excellent customer service. You will coordinate front office activities and handle client inquiries efficiently."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Supervise reception staff and oversee daily front desk operations.",
                "Ensure excellent customer service and handle client inquiries.",
                "Coordinate front office activities and manage scheduling.",
                "Maintain a clean and organized reception area.",
                "Assist with administrative tasks and documentation.",
                "Train and support reception staff.",
                "Handle any issues or complaints promptly and professionally."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate degree preferred.",
                "Proven experience as a Reception Supervisor or similar role.",
                "Excellent communication and interpersonal skills.",
                "Strong organizational and multitasking abilities.",
                "Proficiency in MS Office and office management software.",
                "Ability to handle customer complaints and issues professionally."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $35,000 - $45,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for professional development."
            ]
        }
    ]
};

export const FacilitiesCoordinatorJobDescription = {
    title: "Facilities Coordinator Job Description",
    jobRole: "A Facilities Coordinator is responsible for managing the day-to-day operations of building facilities. They ensure the maintenance, safety, and efficiency of facilities, coordinating with vendors and service providers.",
    preRead: "Use this Facilities Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Facilities Coordinator, you will manage the day-to-day operations of building facilities, ensuring their maintenance, safety, and efficiency. You will coordinate with vendors and service providers to address facility needs."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee daily operations and maintenance of facilities.",
                "Coordinate with vendors and service providers.",
                "Ensure safety and compliance with regulations.",
                "Manage facility-related budgets and expenses.",
                "Schedule and supervise maintenance and repair work.",
                "Maintain records of facility operations and maintenance.",
                "Assist with space planning and facility improvements."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Facility Management, Business Administration, or related field.",
                "Proven experience as a Facilities Coordinator or similar role.",
                "Strong organizational and multitasking skills.",
                "Excellent communication and negotiation abilities.",
                "Proficiency in MS Office and facility management software.",
                "Knowledge of safety regulations and building codes."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $45,000 - $55,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Professional development opportunities."
            ]
        }
    ]
};

export const MailroomClerkJobDescription = {
    title: "Mailroom Clerk Job Description",
    jobRole: "A Mailroom Clerk handles the sorting, processing, and delivery of mail and packages within an organization. They ensure that all correspondence is delivered accurately and timely to the appropriate recipients.",
    preRead: "Use this Mailroom Clerk job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Mailroom Clerk, you will handle the sorting, processing, and delivery of mail and packages within the organization. You will ensure that all correspondence is delivered accurately and timely to the appropriate recipients."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Sort and distribute incoming mail and packages.",
                "Process outgoing mail and shipments.",
                "Maintain records of deliveries and shipments.",
                "Ensure timely and accurate delivery of correspondence.",
                "Assist with administrative tasks as needed.",
                "Operate mailroom equipment and machinery.",
                "Ensure the mailroom is organized and secure."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a Mailroom Clerk or similar role.",
                "Strong organizational and time management skills.",
                "Attention to detail and accuracy.",
                "Ability to lift and move packages.",
                "Proficiency in MS Office and mailroom equipment."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $25,000 - $35,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const ProjectAdministratorJobDescription = {
    title: "Project Administrator Job Description",
    jobRole: "A Project Administrator supports project managers and teams in planning, coordinating, and executing projects. They ensure projects are completed on time, within budget, and according to specifications.",
    preRead: "Use this Project Administrator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Project Administrator, you will support project managers and teams in planning, coordinating, and executing projects. You will ensure projects are completed on time, within budget, and according to specifications."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in project planning and scheduling.",
                "Coordinate project activities and resources.",
                "Monitor project progress and report on status.",
                "Maintain project documentation and records.",
                "Assist with budget management and cost control.",
                "Communicate with stakeholders and team members.",
                "Ensure compliance with project policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Project Management, or related field.",
                "Proven experience as a Project Administrator or similar role.",
                "Strong organizational and multitasking skills.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in MS Office and project management software.",
                "Strong problem-solving and analytical skills."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $45,000 - $55,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Professional development opportunities."
            ]
        }
    ]
};

export const LegalSecretaryJobDescription = {
    title: "Legal Secretary Job Description",
    jobRole: "A Legal Secretary provides administrative support to lawyers and legal teams, handling tasks such as document preparation, scheduling, and communication with clients. They ensure the efficient operation of legal offices.",
    preRead: "Use this Legal Secretary job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Legal Secretary, you will provide administrative support to lawyers and legal teams. Your responsibilities include document preparation, scheduling, and client communication, ensuring the efficient operation of legal offices."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Prepare and format legal documents and correspondence.",
                "Manage schedules and coordinate meetings.",
                "Handle communication with clients and other parties.",
                "Maintain legal files and records.",
                "Assist with legal research and preparation for court proceedings.",
                "Transcribe and proofread legal documents.",
                "Ensure compliance with legal procedures and protocols."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in Legal Studies or related field.",
                "Proven experience as a Legal Secretary or similar role.",
                "Strong knowledge of legal terminology and procedures.",
                "Excellent organizational and multitasking skills.",
                "Proficiency in MS Office and legal software.",
                "Excellent written and verbal communication abilities."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $40,000 - $50,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const MedicalAdministrativeAssistantJobDescription = {
    title: "Medical Administrative Assistant Job Description",
    jobRole: "A Medical Administrative Assistant provides administrative support in healthcare settings, handling tasks such as patient scheduling, record-keeping, and communication with medical staff and patients.",
    preRead: "Use this Medical Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Medical Administrative Assistant, you will provide administrative support in healthcare settings. Your duties include patient scheduling, record-keeping, and communication with medical staff and patients."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Schedule patient appointments and manage calendars.",
                "Maintain and update patient records.",
                "Handle communication with patients and medical staff.",
                "Assist with billing and insurance processing.",
                "Prepare and process medical documents and forms.",
                "Ensure compliance with healthcare regulations and protocols.",
                "Provide administrative support to medical staff."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in Medical Administration or related field.",
                "Proven experience as a Medical Administrative Assistant or similar role.",
                "Knowledge of medical terminology and healthcare procedures.",
                "Excellent organizational and multitasking skills.",
                "Proficiency in MS Office and medical software.",
                "Excellent written and verbal communication abilities."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $35,000 - $45,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const HumanResourcesAdministrativeAssistantJobDescription = {
    title: "Human Resources Administrative Assistant Job Description",
    jobRole: "A Human Resources Administrative Assistant supports the HR department with administrative tasks, including maintaining employee records, assisting with recruitment processes, and coordinating HR activities.",
    preRead: "Use this Human Resources Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Human Resources Administrative Assistant, you will support the HR department with administrative tasks, including maintaining employee records, assisting with recruitment processes, and coordinating HR activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Maintain and update employee records.",
                "Assist with recruitment and onboarding processes.",
                "Coordinate HR activities and events.",
                "Prepare HR documents and correspondence.",
                "Assist with payroll and benefits administration.",
                "Handle employee inquiries and provide support.",
                "Ensure compliance with HR policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in Human Resources, Business Administration, or related field.",
                "Proven experience as an HR Administrative Assistant or similar role.",
                "Strong organizational and multitasking skills.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in MS Office and HR software.",
                "Knowledge of HR policies and procedures."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $35,000 - $45,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const OfficeCoordinatorJobDescription = {
    title: "Office Coordinator Job Description",
    jobRole: "An Office Coordinator ensures the smooth operation of office activities, handling administrative tasks, coordinating office services, and providing support to staff. They maintain an efficient and organized work environment.",
    preRead: "Use this Office Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Office Coordinator, you will ensure the smooth operation of office activities, handling administrative tasks, coordinating office services, and providing support to staff. You will maintain an efficient and organized work environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate office activities and services.",
                "Manage office supplies and inventory.",
                "Assist with scheduling and calendar management.",
                "Handle correspondence and communication.",
                "Maintain office records and documentation.",
                "Support staff with administrative tasks.",
                "Ensure compliance with office policies and procedures."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in Business Administration or related field.",
                "Proven experience as an Office Coordinator or similar role.",
                "Strong organizational and multitasking skills.",
                "Excellent written and verbal communication abilities.",
                "Proficiency in MS Office and office management software.",
                "Ability to maintain an organized and efficient work environment."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $35,000 - $45,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const CustomerServiceAdministratorJobDescription = {
    title: "Customer Service Administrator Job Description",
    jobRole: "A Customer Service Administrator handles customer inquiries, resolves issues, and provides support to ensure customer satisfaction. They manage customer service operations and maintain records of customer interactions.",
    preRead: "Use this Customer Service Administrator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Customer Service Administrator, you will handle customer inquiries, resolve issues, and provide support to ensure customer satisfaction. You will manage customer service operations and maintain records of customer interactions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Handle customer inquiries and resolve issues promptly.",
                "Manage customer service operations and support staff.",
                "Maintain records of customer interactions and transactions.",
                "Provide accurate information about products and services.",
                "Assist with customer service training and development.",
                "Ensure compliance with customer service policies and procedures.",
                "Analyze customer feedback and identify improvement areas."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Associate degree in Business Administration or related field.",
                "Proven experience as a Customer Service Administrator or similar role.",
                "Excellent communication and interpersonal skills.",
                "Strong problem-solving and conflict resolution abilities.",
                "Proficiency in MS Office and customer service software.",
                "Ability to manage multiple tasks and prioritize effectively."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $35,000 - $45,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for career advancement."
            ]
        }
    ]
};

export const EventsCoordinatorJobDescription = {
    title: "Events Coordinator Job Description",
    jobRole: "An Events Coordinator plans, organizes, and executes events, ensuring they run smoothly and meet organizational objectives. They handle logistics, coordinate with vendors, and manage event budgets.",
    preRead: "Use this Events Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Events Coordinator, you will plan, organize, and execute events, ensuring they run smoothly and meet organizational objectives. You will handle logistics, coordinate with vendors, and manage event budgets."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Plan and coordinate events from start to finish.",
                "Manage event logistics and operations.",
                "Coordinate with vendors and service providers.",
                "Develop event budgets and track expenses.",
                "Promote events and manage attendee registration.",
                "Ensure events meet organizational objectives and expectations.",
                "Handle post-event follow-up and evaluation."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Event Management, Hospitality, or related field.",
                "Proven experience as an Events Coordinator or similar role.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and negotiation skills.",
                "Proficiency in MS Office and event management software.",
                "Ability to manage budgets and track expenses effectively."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $40,000 - $50,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for professional development."
            ]
        }
    ]
};

export const DocumentControlSpecialistJobDescription = {
    title: "Document Control Specialist Job Description",
    jobRole: "A Document Control Specialist manages the documentation process, ensuring all documents are accurate, up-to-date, and properly stored. They maintain records, support audits, and ensure compliance with document control policies.",
    preRead: "Use this Document Control Specialist job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Document Control Specialist, you will manage the documentation process, ensuring all documents are accurate, up-to-date, and properly stored. You will maintain records, support audits, and ensure compliance with document control policies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage document control processes and systems.",
                "Ensure documents are accurate, up-to-date, and properly stored.",
                "Maintain records and documentation databases.",
                "Support internal and external audits.",
                "Ensure compliance with document control policies and procedures.",
                "Train staff on document control processes and systems.",
                "Identify areas for process improvement and efficiency."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor's degree in Business Administration, Information Management, or related field.",
                "Proven experience as a Document Control Specialist or similar role.",
                "Strong knowledge of document management systems and software.",
                "Attention to detail and accuracy in document processing.",
                "Excellent organizational and multitasking abilities.",
                "Ability to work effectively in a team environment.",
                "Understanding of regulatory requirements for document control."
            ]
        },
        {
            heading: "Benefits and Salary",
            paragraphs: [
                "Competitive salary range: $40,000 - $50,000 per year.",
                "Health, dental, and vision insurance.",
                "Paid time off and holidays.",
                "Retirement plan with company match.",
                "Opportunities for professional development."
            ]
        }
    ]
};


export const SchedulingCoordinatorJobDescription = {
    title: "Scheduling Coordinator Job Description",
    jobRole: "A Scheduling Coordinator manages appointment schedules, ensuring efficient utilization of time and resources. They coordinate appointments, meetings, and events, and communicate schedules to relevant stakeholders.",
    preRead: "Use this Scheduling Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Scheduling Coordinator, you will manage appointment schedules, ensuring efficient utilization of time and resources. You will coordinate appointments, meetings, and events, and communicate schedules to relevant stakeholders."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage appointment schedules for clients, staff, and resources.",
                "Coordinate meetings, events, and appointments.",
                "Communicate schedules to relevant stakeholders.",
                "Ensure accuracy and efficiency in scheduling processes.",
                "Resolve scheduling conflicts and issues.",
                "Maintain up-to-date records of appointments and schedules.",
                "Provide administrative support as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate's or bachelor's degree preferred.",
                "Proven experience as a Scheduling Coordinator or similar role.",
                "Excellent organizational and time management skills.",
                "Strong communication and interpersonal abilities.",
                "Ability to multitask and prioritize tasks effectively.",
                "Proficiency in scheduling software and tools.",
                "Attention to detail and accuracy in scheduling processes."
            ]
        }
    ]
};

export const BillingCoordinatorJobDescription = {
    title: "Billing Coordinator Job Description",
    jobRole: "A Billing Coordinator manages billing processes, ensuring accurate and timely invoicing. They coordinate with internal departments and external clients to resolve billing issues and discrepancies.",
    preRead: "Use this Billing Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Billing Coordinator, you will manage billing processes, ensuring accurate and timely invoicing. You will coordinate with internal departments and external clients to resolve billing issues and discrepancies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Manage billing processes and systems.",
                "Generate and send invoices to clients.",
                "Coordinate with internal departments to gather billing information.",
                "Resolve billing discrepancies and issues.",
                "Communicate with clients regarding billing inquiries.",
                "Maintain accurate records of billing transactions.",
                "Assist in financial reporting and analysis as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate's or bachelor's degree preferred.",
                "Proven experience as a Billing Coordinator or similar role.",
                "Knowledge of billing processes and systems.",
                "Strong attention to detail and accuracy in billing transactions.",
                "Excellent communication and interpersonal skills.",
                "Ability to work effectively in a team environment.",
                "Proficiency in Microsoft Office and accounting software."
            ]
        }
    ]
};

export const InventoryControlClerkJobDescription = {
    title: "Inventory Control Clerk Job Description",
    jobRole: "An Inventory Control Clerk manages inventory levels and stock movements, ensuring accuracy and efficiency in inventory management. They monitor inventory levels, track stock movements, and reconcile discrepancies.",
    preRead: "Use this Inventory Control Clerk job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Inventory Control Clerk, you will manage inventory levels and stock movements, ensuring accuracy and efficiency in inventory management. You will monitor inventory levels, track stock movements, and reconcile discrepancies."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Monitor inventory levels and stock movements.",
                "Track incoming and outgoing shipments.",
                "Update inventory records and databases.",
                "Perform regular inventory audits and reconciliations.",
                "Identify and resolve inventory discrepancies.",
                "Coordinate with purchasing and warehouse teams as needed.",
                "Maintain accurate documentation of inventory transactions."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; associate's or bachelor's degree preferred.",
                "Proven experience as an Inventory Control Clerk or similar role.",
                "Strong attention to detail and accuracy in inventory management.",
                "Excellent organizational and time management skills.",
                "Ability to multitask and prioritize tasks effectively.",
                "Proficiency in inventory management software and tools.",
                "Good communication and interpersonal abilities."
            ]
        }
    ]
};

export const FileClerkJobDescription = {
    title: "File Clerk Job Description",
    jobRole: "A File Clerk organizes and maintains physical and electronic files, ensuring easy retrieval and access to information. They label, categorize, and store files according to established procedures.",
    preRead: "Use this File Clerk job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a File Clerk, you will organize and maintain physical and electronic files, ensuring easy retrieval and access to information. You will label, categorize, and store files according to established procedures."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Organize and maintain physical and electronic files.",
                "Label, categorize, and store files according to established procedures.",
                "Ensure accurate and efficient file organization.",
                "Retrieve and provide access to files upon request.",
                "Assist in file archiving and disposal processes.",
                "Maintain confidentiality and security of files and information.",
                "Update and maintain file tracking systems and databases."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a File Clerk or similar role.",
                "Familiarity with file organization and management principles.",
                "Attention to detail and accuracy in file handling.",
                "Ability to prioritize tasks and manage time effectively.",
                "Strong organizational and multitasking abilities.",
                "Proficiency in computer and file management software."
            ]
        }
    ]
};

export const CorporateReceptionistJobDescription = {
    title: "Corporate Receptionist Job Description",
    jobRole: "A Corporate Receptionist welcomes visitors, handles incoming calls, and provides administrative support to the corporate office. They ensure a positive and professional experience for visitors and staff.",
    preRead: "Use this Corporate Receptionist job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Corporate Receptionist, you will welcome visitors, handle incoming calls, and provide administrative support to the corporate office. You will ensure a positive and professional experience for visitors and staff."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Welcome visitors and direct them to the appropriate person or department.",
                "Answer and direct incoming calls in a professional manner.",
                "Manage the reception area, ensuring cleanliness and organization.",
                "Receive and distribute mail and packages.",
                "Provide administrative support to the corporate office as needed.",
                "Assist with scheduling appointments and meetings.",
                "Maintain visitor logs and issue visitor badges."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as a Receptionist or similar role.",
                "Excellent communication and interpersonal skills.",
                "Professional appearance and demeanor.",
                "Ability to multitask and prioritize tasks effectively.",
                "Strong organizational and time management abilities.",
                "Proficiency in Microsoft Office and telephone systems."
            ]
        }
    ]
};

export const RealEstateAdministrativeAssistantJobDescription = {
    title: "Real Estate Administrative Assistant Job Description",
    jobRole: "A Real Estate Administrative Assistant provides administrative support to real estate agents and brokers. They assist in property listings, client communications, and administrative tasks to facilitate real estate transactions.",
    preRead: "Use this Real Estate Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Real Estate Administrative Assistant, you will provide administrative support to real estate agents and brokers. You will assist in property listings, client communications, and administrative tasks to facilitate real estate transactions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in property listing activities, including entering data into listing databases.",
                "Coordinate client communications, including scheduling appointments and showings.",
                "Prepare and distribute marketing materials for property listings.",
                "Assist in administrative tasks, such as preparing contracts and agreements.",
                "Maintain client and property databases, ensuring accuracy and completeness.",
                "Provide general administrative support to real estate agents and brokers as needed."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent.",
                "Proven experience as an Administrative Assistant or similar role.",
                "Familiarity with real estate terminology and processes.",
                "Excellent communication and customer service skills.",
                "Strong organizational and multitasking abilities.",
                "Proficiency in Microsoft Office and real estate software.",
                "Ability to work effectively in a fast-paced environment."
            ]
        }
    ]
};

export const TrainingCoordinatorJobDescription = {
    title: "Training Coordinator Job Description",
    jobRole: "A Training Coordinator oversees training programs and schedules for employees. They coordinate training sessions, track attendance, and evaluate training effectiveness to ensure employees receive relevant and effective training.",
    preRead: "Use this Training Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Training Coordinator, you will oversee training programs and schedules for employees. You will coordinate training sessions, track attendance, and evaluate training effectiveness to ensure employees receive relevant and effective training."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement training programs and schedules for employees.",
                "Coordinate training sessions, including scheduling trainers and participants.",
                "Track attendance and participation in training sessions.",
                "Evaluate training effectiveness and make recommendations for improvement.",
                "Maintain training records and documentation.",
                "Assist in the development of training materials and resources.",
                "Coordinate logistics for training events, such as room setup and equipment.",
                "Communicate training schedules and requirements to employees."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Human Resources, Education, or related field.",
                "Proven experience in training coordination or related role.",
                "Knowledge of training principles and techniques.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and interpersonal skills.",
                "Proficiency in Microsoft Office and training software.",
                "Ability to work independently and collaboratively as part of a team."
            ]
        }
    ]
};

export const MarketingAdministrativeAssistantJobDescription = {
    title: "Marketing Administrative Assistant Job Description",
    jobRole: "A Marketing Administrative Assistant provides administrative support to the marketing department. They assist in coordinating marketing campaigns, maintaining marketing materials, and handling administrative tasks to support marketing initiatives.",
    preRead: "Use this Marketing Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Marketing Administrative Assistant, you will provide administrative support to the marketing department. You will assist in coordinating marketing campaigns, maintaining marketing materials, and handling administrative tasks to support marketing initiatives."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in coordinating marketing campaigns and initiatives.",
                "Maintain marketing materials, such as brochures, flyers, and promotional items.",
                "Assist in organizing marketing events and activities.",
                "Handle administrative tasks, such as scheduling meetings and managing correspondence.",
                "Assist in preparing marketing reports and presentations.",
                "Update and maintain marketing databases and contact lists.",
                "Monitor and report on marketing activities and results.",
                "Provide general administrative support to the marketing department."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Marketing, Communications, or related field.",
                "Proven experience in administrative support or marketing coordination role.",
                "Knowledge of marketing principles and practices.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and interpersonal skills.",
                "Proficiency in Microsoft Office and marketing software.",
                "Ability to work effectively in a fast-paced environment."
            ]
        }
    ]
};

export const OperationsAdministrativeAssistantJobDescription = {
    title: "Operations Administrative Assistant Job Description",
    jobRole: "An Operations Administrative Assistant provides administrative support to the operations department. They assist in coordinating operations activities, maintaining documentation, and handling administrative tasks to support operational efficiency.",
    preRead: "Use this Operations Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Operations Administrative Assistant, you will provide administrative support to the operations department. You will assist in coordinating operations activities, maintaining documentation, and handling administrative tasks to support operational efficiency."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in coordinating operations activities and initiatives.",
                "Maintain documentation, such as process manuals and standard operating procedures.",
                "Handle administrative tasks, such as scheduling meetings and managing correspondence.",
                "Assist in preparing operational reports and presentations.",
                "Update and maintain operations databases and records.",
                "Monitor and report on operations activities and results.",
                "Provide general administrative support to the operations department."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Operations Management, or related field.",
                "Proven experience in administrative support or operations coordination role.",
                "Knowledge of operations principles and practices.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and interpersonal skills.",
                "Proficiency in Microsoft Office and operations software.",
                "Ability to work effectively in a fast-paced environment."
            ]
        }
    ]
};

export const ResearchAdministrativeAssistantJobDescription = {
    title: "Research Administrative Assistant Job Description",
    jobRole: "A Research Administrative Assistant provides administrative support to research departments or teams. They assist in coordinating research projects, maintaining research documentation, and handling administrative tasks to support research activities.",
    preRead: "Use this Research Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Research Administrative Assistant, you will provide administrative support to research departments or teams. You will assist in coordinating research projects, maintaining research documentation, and handling administrative tasks to support research activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in coordinating research projects and activities.",
                "Maintain research documentation, such as protocols, data records, and reports.",
                "Handle administrative tasks, such as scheduling meetings and managing correspondence.",
                "Assist in preparing research reports and presentations.",
                "Update and maintain research databases and records.",
                "Monitor and report on research activities and results.",
                "Provide general administrative support to the research department or team."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in a relevant field, such as Science, Social Science, or Humanities.",
                "Proven experience in administrative support or research coordination role.",
                "Knowledge of research principles and methodologies.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and interpersonal skills.",
                "Proficiency in Microsoft Office and research software.",
                "Ability to work effectively in a collaborative research environment."
            ]
        }
    ]
};

export const EducationAdministrativeAssistantJobDescription = {
    title: "Education Administrative Assistant Job Description",
    jobRole: "An Education Administrative Assistant provides administrative support to educational institutions or departments. They assist in coordinating educational programs, maintaining educational records, and handling administrative tasks to support educational activities.",
    preRead: "Use this Education Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As an Education Administrative Assistant, you will provide administrative support to educational institutions or departments. You will assist in coordinating educational programs, maintaining educational records, and handling administrative tasks to support educational activities."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in coordinating educational programs and activities.",
                "Maintain educational records, such as student files, transcripts, and course schedules.",
                "Handle administrative tasks, such as scheduling meetings and managing correspondence.",
                "Assist in preparing educational reports and presentations.",
                "Update and maintain educational databases and records.",
                "Monitor and report on educational activities and results.",
                "Provide general administrative support to the education department or institution."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Education, Administration, or related field.",
                "Proven experience in administrative support or educational coordination role.",
                "Knowledge of educational principles and practices.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and interpersonal skills.",
                "Proficiency in Microsoft Office and educational software.",
                "Ability to work effectively in an educational environment."
            ]
        }
    ]
};

export const LibraryAssistantJobDescription = {
    title: "Library Assistant Job Description",
    jobRole: "A Library Assistant provides support to library staff in managing library operations and services. They assist in cataloging library materials, assisting patrons, and handling administrative tasks to support library functions.",
    preRead: "Use this Library Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Library Assistant, you will provide support to library staff in managing library operations and services. You will assist in cataloging library materials, assisting patrons, and handling administrative tasks to support library functions."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in cataloging library materials, such as books, periodicals, and media.",
                "Assist patrons with locating and accessing library resources.",
                "Handle administrative tasks, such as checking materials in and out, processing library cards, and collecting fines.",
                "Maintain library records and databases.",
                "Assist in shelving and organizing library materials.",
                "Assist in preparing displays and exhibits.",
                "Provide general administrative support to library staff."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Library Science or related field is preferred.",
                "Proven experience in a library or customer service role is advantageous.",
                "Knowledge of library systems and procedures.",
                "Excellent organizational and customer service skills.",
                "Strong communication and interpersonal skills.",
                "Ability to work effectively in a team environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const FacilitiesManagerJobDescription = {
    title: "Facilities Manager Job Description",
    jobRole: "A Facilities Manager oversees the maintenance and operation of buildings and facilities. They are responsible for ensuring that facilities are safe, functional, and compliant with regulations. Facilities Managers coordinate maintenance activities, manage service contracts, and oversee facility budgets.",
    preRead: "Use this Facilities Manager job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Facilities Manager, you will oversee the maintenance and operation of buildings and facilities. You will ensure that facilities are safe, functional, and compliant with regulations. You will coordinate maintenance activities, manage service contracts, and oversee facility budgets."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Oversee the maintenance and operation of buildings and facilities.",
                "Ensure facilities are safe, functional, and compliant with regulations.",
                "Coordinate maintenance activities, such as repairs, inspections, and renovations.",
                "Manage service contracts with vendors and contractors.",
                "Develop and implement facility maintenance plans and schedules.",
                "Monitor facility budgets and expenses.",
                "Conduct regular inspections of facilities to identify maintenance needs.",
                "Respond to facility-related emergencies and issues.",
                "Ensure compliance with health and safety regulations.",
                "Manage facility security systems and protocols."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Facilities Management, Engineering, Business Administration, or related field.",
                "Proven experience in facilities management or related field.",
                "Knowledge of building systems and maintenance practices.",
                "Strong leadership and management skills.",
                "Excellent organizational and problem-solving abilities.",
                "Effective communication and interpersonal skills.",
                "Ability to prioritize tasks and manage multiple projects.",
                "Familiarity with relevant health, safety, and environmental regulations."
            ]
        }
    ]
};

export const PropertyManagementAdministrativeAssistantJobDescription = {
    title: "Property Management Administrative Assistant Job Description",
    jobRole: "A Property Management Administrative Assistant provides administrative support to property management professionals. They assist in managing rental properties, coordinating maintenance activities, and handling administrative tasks to support property management operations.",
    preRead: "Use this Property Management Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Property Management Administrative Assistant, you will provide administrative support to property management professionals. You will assist in managing rental properties, coordinating maintenance activities, and handling administrative tasks to support property management operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in managing rental properties, including tenant relations, leasing, and rent collection.",
                "Coordinate maintenance activities, repairs, and renovations for rental properties.",
                "Handle administrative tasks, such as scheduling appointments, managing correspondence, and maintaining records.",
                "Assist in preparing lease agreements, rental applications, and other property-related documents.",
                "Respond to tenant inquiries and requests in a timely and professional manner.",
                "Assist in conducting property inspections and assessments.",
                "Assist in marketing rental properties and attracting prospective tenants.",
                "Provide general administrative support to property management professionals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Real Estate, Property Management, or related field is preferred.",
                "Proven experience in administrative support or property management role.",
                "Knowledge of property management principles and practices.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and customer service skills.",
                "Proficiency in Microsoft Office and property management software.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const ComplianceAdministratorJobDescription = {
    title: "Compliance Administrator Job Description",
    jobRole: "A Compliance Administrator ensures that organizations comply with laws, regulations, and industry standards. They develop and implement compliance policies and procedures, conduct compliance audits, and provide guidance to employees on compliance-related matters.",
    preRead: "Use this Compliance Administrator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Compliance Administrator, you will ensure that organizations comply with laws, regulations, and industry standards. You will develop and implement compliance policies and procedures, conduct compliance audits, and provide guidance to employees on compliance-related matters."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Develop and implement compliance policies and procedures.",
                "Monitor changes in laws, regulations, and industry standards to ensure organizational compliance.",
                "Conduct compliance audits and assessments to identify areas of non-compliance.",
                "Provide guidance and training to employees on compliance-related matters.",
                "Investigate and resolve compliance issues and violations.",
                "Maintain accurate records and documentation related to compliance activities.",
                "Prepare reports and presentations on compliance activities and findings.",
                "Collaborate with internal teams and external stakeholders to address compliance challenges and opportunities."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "Bachelor’s degree in Business Administration, Legal Studies, or related field.",
                "Proven experience in compliance management or related field.",
                "Knowledge of relevant laws, regulations, and industry standards.",
                "Strong analytical and problem-solving skills.",
                "Excellent communication and interpersonal skills.",
                "Ability to work independently and collaboratively as part of a team.",
                "Attention to detail and accuracy in performing tasks.",
                "Certification in compliance management is advantageous."
            ]
        }
    ]
};

export const ProcurementAdministrativeAssistantJobDescription = {
    title: "Procurement Administrative Assistant Job Description",
    jobRole: "A Procurement Administrative Assistant provides administrative support to procurement professionals. They assist in purchasing goods and services, managing supplier relationships, and handling administrative tasks to support procurement operations.",
    preRead: "Use this Procurement Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Procurement Administrative Assistant, you will provide administrative support to procurement professionals. You will assist in purchasing goods and services, managing supplier relationships, and handling administrative tasks to support procurement operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in purchasing goods and services for the organization.",
                "Maintain accurate records of purchases, orders, and supplier information.",
                "Coordinate with suppliers to obtain quotes, place orders, and track deliveries.",
                "Assist in managing supplier relationships and negotiating contracts.",
                "Handle administrative tasks, such as processing invoices, expense reports, and purchase orders.",
                "Assist in evaluating supplier performance and conducting supplier audits.",
                "Assist in identifying cost-saving opportunities and optimizing procurement processes.",
                "Provide general administrative support to procurement professionals."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Business Administration, Supply Chain Management, or related field is preferred.",
                "Proven experience in administrative support or procurement role.",
                "Knowledge of procurement principles and practices.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and negotiation skills.",
                "Proficiency in Microsoft Office and procurement software.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const TravelCoordinatorJobDescription = {
    title: "Travel Coordinator Job Description",
    jobRole: "A Travel Coordinator is responsible for organizing and coordinating travel arrangements for individuals or groups. They handle bookings, reservations, and logistics to ensure smooth and efficient travel experiences.",
    preRead: "Use this Travel Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Travel Coordinator, you will be responsible for organizing and coordinating travel arrangements for individuals or groups. You will handle bookings, reservations, and logistics to ensure smooth and efficient travel experiences."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate travel arrangements, including flights, accommodations, and transportation.",
                "Research and book travel options that meet the needs and preferences of travelers.",
                "Ensure compliance with travel policies and procedures.",
                "Provide assistance and support to travelers before, during, and after their trips.",
                "Handle travel-related inquiries and issues in a timely and professional manner.",
                "Manage travel budgets and expenses.",
                "Maintain accurate records of travel bookings and expenses.",
                "Stay updated on travel trends, regulations, and best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Hospitality Management, Travel and Tourism, or related field is preferred.",
                "Proven experience in travel coordination or related role.",
                "Knowledge of travel booking systems and procedures.",
                "Excellent organizational and multitasking abilities.",
                "Strong communication and customer service skills.",
                "Attention to detail and accuracy in making travel arrangements.",
                "Ability to work effectively under pressure and meet deadlines.",
                "Proficiency in Microsoft Office and travel booking software."
            ]
        }
    ]
};

export const SalesAdministrativeAssistantJobDescription = {
    title: "Sales Administrative Assistant Job Description",
    jobRole: "A Sales Administrative Assistant provides administrative support to sales teams. They assist in managing sales processes, coordinating sales activities, and handling administrative tasks to support sales operations.",
    preRead: "Use this Sales Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Sales Administrative Assistant, you will provide administrative support to sales teams. You will assist in managing sales processes, coordinating sales activities, and handling administrative tasks to support sales operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in managing sales processes, such as lead generation, prospecting, and pipeline management.",
                "Coordinate sales activities, such as client meetings, presentations, and demos.",
                "Handle administrative tasks, such as preparing sales reports, processing orders, and maintaining customer records.",
                "Assist in preparing sales proposals, contracts, and presentations.",
                "Respond to customer inquiries and provide support as needed.",
                "Collaborate with internal teams, such as marketing and customer service, to support sales efforts.",
                "Assist in organizing sales events, trade shows, and conferences.",
                "Provide general administrative support to sales teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Business Administration, Marketing, or related field is preferred.",
                "Proven experience in administrative support or sales role.",
                "Knowledge of sales processes and procedures.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office and CRM software.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const QualityAssuranceAdministrativeAssistantJobDescription = {
    title: "Quality Assurance Administrative Assistant Job Description",
    jobRole: "A Quality Assurance Administrative Assistant provides administrative support to quality assurance teams. They assist in managing quality control processes, coordinating inspections, and handling administrative tasks to support quality assurance operations.",
    preRead: "Use this Quality Assurance Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Quality Assurance Administrative Assistant, you will provide administrative support to quality assurance teams. You will assist in managing quality control processes, coordinating inspections, and handling administrative tasks to support quality assurance operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in managing quality control processes, such as inspections, audits, and testing.",
                "Coordinate quality assurance activities, such as document control, recordkeeping, and reporting.",
                "Handle administrative tasks, such as preparing quality reports, processing documentation, and maintaining records.",
                "Assist in preparing quality documentation, including standard operating procedures (SOPs) and work instructions.",
                "Respond to quality-related inquiries and provide support as needed.",
                "Collaborate with internal teams to ensure compliance with quality standards and regulations.",
                "Assist in organizing quality training sessions and workshops.",
                "Provide general administrative support to quality assurance teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Quality Assurance, Engineering, or related field is preferred.",
                "Proven experience in administrative support or quality assurance role.",
                "Knowledge of quality control processes and procedures.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office and quality management software.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const TechnicalAdministrativeAssistantJobDescription = {
    title: "Technical Administrative Assistant Job Description",
    jobRole: "A Technical Administrative Assistant provides administrative support to technical teams or departments. They assist in managing technical projects, coordinating meetings, and handling administrative tasks to support technical operations.",
    preRead: "Use this Technical Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Technical Administrative Assistant, you will provide administrative support to technical teams or departments. You will assist in managing technical projects, coordinating meetings, and handling administrative tasks to support technical operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in managing technical projects, such as software development, engineering, or research initiatives.",
                "Coordinate technical meetings, including scheduling, agenda preparation, and document distribution.",
                "Handle administrative tasks, such as preparing technical reports, processing documentation, and maintaining records.",
                "Assist in preparing technical documentation, including manuals, specifications, and technical drawings.",
                "Respond to technical inquiries and provide support as needed.",
                "Collaborate with technical teams to ensure project deadlines and objectives are met.",
                "Assist in organizing technical training sessions and workshops.",
                "Provide general administrative support to technical teams or departments."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Engineering, Information Technology, or related field is preferred.",
                "Proven experience in administrative support or technical role.",
                "Knowledge of technical projects and processes.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and problem-solving skills.",
                "Proficiency in Microsoft Office and technical software/tools.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const CommunicationsAdministrativeAssistantJobDescription = {
    title: "Communications Administrative Assistant Job Description",
    jobRole: "A Communications Administrative Assistant provides administrative support to communications or public relations departments. They assist in managing communication activities, coordinating events, and handling administrative tasks to support communications operations.",
    preRead: "Use this Communications Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Communications Administrative Assistant, you will provide administrative support to communications or public relations departments. You will assist in managing communication activities, coordinating events, and handling administrative tasks to support communications operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in managing communication activities, such as drafting press releases, newsletters, and social media posts.",
                "Coordinate communication events, including conferences, webinars, and media interviews.",
                "Handle administrative tasks, such as preparing communication materials, processing documentation, and maintaining media lists.",
                "Assist in preparing communication plans, schedules, and reports.",
                "Respond to communication inquiries and provide support as needed.",
                "Collaborate with internal teams to ensure consistency in messaging and branding.",
                "Assist in organizing communication training sessions and workshops.",
                "Provide general administrative support to communications or public relations departments."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Communications, Public Relations, or related field is preferred.",
                "Proven experience in administrative support or communications role.",
                "Knowledge of communication principles and practices.",
                "Strong organizational and multitasking abilities.",
                "Excellent writing and editing skills.",
                "Proficiency in Microsoft Office and communication tools/software.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const LogisticsCoordinatorJobDescription = {
    title: "Logistics Coordinator Job Description",
    jobRole: "A Logistics Coordinator assists in managing logistics and supply chain operations. They coordinate transportation, monitor inventory levels, and handle administrative tasks to support efficient logistics operations.",
    preRead: "Use this Logistics Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Logistics Coordinator, you will assist in managing logistics and supply chain operations. You will coordinate transportation, monitor inventory levels, and handle administrative tasks to support efficient logistics operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Coordinate transportation activities, including scheduling shipments, arranging deliveries, and tracking shipments.",
                "Monitor inventory levels and manage stock movement to ensure adequate supply levels.",
                "Handle administrative tasks, such as processing orders, maintaining records, and generating reports.",
                "Assist in optimizing logistics processes to improve efficiency and reduce costs.",
                "Communicate with suppliers, carriers, and internal teams to coordinate logistics activities.",
                "Respond to logistics inquiries and provide support as needed.",
                "Assist in implementing safety and compliance measures in logistics operations.",
                "Provide general administrative support to logistics teams."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Logistics, Supply Chain Management, or related field is preferred.",
                "Proven experience in logistics coordination or related role.",
                "Knowledge of logistics processes and transportation regulations.",
                "Strong organizational and multitasking abilities.",
                "Excellent communication and problem-solving skills.",
                "Proficiency in Microsoft Office and logistics software/tools.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};

export const SafetyCoordinatorJobDescription = {
    title: "Safety Coordinator Job Description",
    jobRole: "A Safety Coordinator assists in implementing safety programs and policies in the workplace. They conduct safety inspections, provide safety training, and handle administrative tasks to promote a safe working environment.",
    preRead: "Use this Safety Coordinator job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Safety Coordinator, you will assist in implementing safety programs and policies in the workplace. You will conduct safety inspections, provide safety training, and handle administrative tasks to promote a safe working environment."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Conduct safety inspections and audits to identify hazards and ensure compliance with safety regulations.",
                "Provide safety training to employees on topics such as hazard recognition, emergency procedures, and personal protective equipment (PPE).",
                "Assist in developing and implementing safety policies, procedures, and programs.",
                "Investigate accidents and incidents to determine root causes and recommend corrective actions.",
                "Handle administrative tasks, such as maintaining safety records, preparing reports, and coordinating safety meetings.",
                "Respond to safety inquiries and provide support as needed.",
                "Collaborate with management and employees to promote a culture of safety.",
                "Monitor changes in safety regulations and industry best practices."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Occupational Health and Safety, Environmental Science, or related field is preferred.",
                "Proven experience in safety coordination or related role.",
                "Knowledge of safety regulations and standards.",
                "Strong organizational and analytical abilities.",
                "Excellent communication and interpersonal skills.",
                "Proficiency in Microsoft Office and safety management software/tools.",
                "Ability to work effectively in a fast-paced, dynamic environment.",
                "Attention to detail and accuracy in performing tasks."
            ]
        }
    ]
};


export const PayrollAdministrativeAssistantJobDescription = {
    title: "Payroll Administrative Assistant Job Description",
    jobRole: "A Payroll Administrative Assistant provides administrative support to payroll departments. They assist in processing payroll, maintaining payroll records, and handling administrative tasks to support accurate and timely payroll operations.",
    preRead: "Use this Payroll Administrative Assistant job description template to attract qualified candidates for your organization. Customize the responsibilities, requirements, and benefits to fit your specific needs.",
    sections: [
        {
            heading: "Role Overview",
            paragraph: "As a Payroll Administrative Assistant, you will provide administrative support to payroll departments. You will assist in processing payroll, maintaining payroll records, and handling administrative tasks to support accurate and timely payroll operations."
        },
        {
            heading: "Responsibilities",
            paragraphs: [
                "Assist in processing payroll for employees, including calculating wages, deductions, and taxes.",
                "Maintain accurate payroll records, including employee information, timecards, and earnings.",
                "Handle administrative tasks, such as preparing payroll reports, processing documentation, and responding to payroll inquiries.",
                "Assist in reconciling payroll discrepancies and resolving payroll-related issues.",
                "Ensure compliance with payroll regulations and policies.",
                "Collaborate with HR and finance teams to coordinate payroll activities.",
                "Provide general administrative support to payroll departments."
            ]
        },
        {
            heading: "Requirements and Skills",
            paragraphs: [
                "High school diploma or equivalent; some college coursework in Accounting, Finance, or related field is preferred.",
                "Proven experience in payroll administration or related role.",
                "Knowledge of payroll processing procedures and regulations.",
                "Strong numerical and analytical abilities.",
                "Excellent attention to detail and accuracy in performing tasks.",
                "Proficiency in Microsoft Office and payroll software.",
                "Ability to communicate effectively and work collaboratively in a team environment.",
                "Organizational and time management skills to handle multiple tasks and deadlines."
            ]
        },
        {
            heading: "Benefits",
            paragraph: "The benefits of working as a Payroll Administrative Assistant may include health insurance, retirement plans, paid time off, and opportunities for professional development."
        },
        {
            heading: "Salary",
            paragraph: "The salary for a Payroll Administrative Assistant varies depending on experience, location, and organization. On average, Payroll Administrative Assistants earn between $30,000 and $45,000 per year."
        }
    ]
};


