import { combineReducers } from '@reduxjs/toolkit';
import dashboardActiveJobsSlice from './dashboard-active-jobs-slice';
import dashboardApplicantsSlice from './dashboard-applicants-slice';
import dashboardJobSlice from './dashboard-draft-jobs-slice';
import selectedJobForRecentActivitySlice from './dashboard-recent-activity-selected-job-slice';
import dashboardJobSummaryWidgetApplicantsSlice from './dashboard-summaray-widget-applicant-list';
import dashboardJobSummaryListJobsSlice from './dashboard-summary-widget-active-jobs.slice';
import selectedJobForJobSummaryWidgetSlice from './dashboard-summary-widget-selected-job-slice';

const dashboardReducer = combineReducers({
    dashboardDraftJobs: dashboardJobSlice.reducer,
    dashboardApplicants: dashboardApplicantsSlice.reducer,
    dashboardActiveJobs: dashboardActiveJobsSlice.reducer,
    dashboardSummaryWidgetJobList: dashboardJobSummaryListJobsSlice.reducer,
    dashboardSummaryWidgetApplicantList: dashboardJobSummaryWidgetApplicantsSlice.reducer,
    selectedJobForJobSummaryWidget: selectedJobForJobSummaryWidgetSlice.reducer,
    selectedJobForRecentActivity: selectedJobForRecentActivitySlice.reducer,
});

export default dashboardReducer;